import { connect } from "react-redux";
import React, { Component } from "react";
import { Images } from "../../../theme";
import { DotsLoader } from "../../../components";
import {
  getAllSaloonsForEmployee,
  addCompanyByEmployee,
  getCompanyThatEmployeeAdd,
  deleteSalonByEmployee,
  editCompanyEmploye,
} from "../../../config/simpleApiCalls";
import { SuccessHelper, ErrorHelper } from "../../../helpers";
import Swal from "sweetalert2";
import { getNestedValue } from "../../../util/objectMethods";
// import {close_icon} from /
import { Translation } from "react-i18next";
import i18n from "../../../i18n";
import "./styles.css";
import Sytles from "./sytles";
import _ from "lodash";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from "@fortawesome/free-solid-svg-icons";

class ActiveSaloon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employeeSalon: [],
      isloading: false,
      showAddEmployeePopup: false,
      allSaloons: [],
      searchText: "",
      isPopupOpen: false,
      companyToEdit: undefined,
    };
  }

  async componentDidMount() {
    const employeeData = await JSON.parse(sessionStorage.getItem("employee"));
    const saloonId = getNestedValue(
      JSON.parse(sessionStorage.getItem("company")),
      ["_id"]
    );
    console.log("employeeData", saloonId);
    if (employeeData) {
      this.setState(
        { access_token: employeeData.access_token, saloonId },
        () => {
          this.gettingAllSaloons();
          this.getEmployeeSalon();
        }
      );
    }
  }

  addCompanies = () => {
    const { access_token, allSaloons } = this.state;

    let companyId = "";

    allSaloons.map((v) => {
      if (v.isSelected) {
        companyId = v._id;
      }
    });

    const payload = {
      companyId,
    };

    addCompanyByEmployee(payload, access_token)
      .then((res) => {
        if (res.data.success) {
          SuccessHelper.handleSuccess(res.data.msg, true);
          this.getEmployeeSalon();
          this.setState({ showAddEmployeePopup: false });
        }
      })
      .catch((error) => {
        console.log("error", error);
        // if (error?.data?.success === false) {
        Swal.fire({
          title: error.data.msg,
          text: "-",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#ff3600",
          cancelButtonColor: "#354e68",
          confirmButtonText: "Okay",
        }).then((result) => {
          if (result.value) {
            this.closeEditServiceCard();
          }
        });
        // }
        console.log(error, "error");
      });
  };

  editCompanies = () => {
    const { access_token, companyToEdit } = this.state;
    this.setState({ isloading: true });
    const payload = {
      ...companyToEdit,
      companyServices: companyToEdit.companyServices.map((companyService) => ({
        ...companyService,
        serviceId: companyService.serviceId._id,
      })),
    };
    editCompanyEmploye(payload, access_token)
      .then((res) => {
        if (res.data.success) {
          this.getEmployeeSalon();
          this.setState({ isloading: false, isPopupOpen: false });
          SuccessHelper.handleSuccess("Update Succesful", true);
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
      });
  };

  gettingAllSaloons = () => {
    const { access_token } = this.state;

    this.setState({ isloading: true });

    getAllSaloonsForEmployee(access_token)
      .then((res) => {
        if (res.data.success) {
          console.log(res.data.saloons, "res.data.data,res.data.data,");
          this.setState({
            allSaloons: res.data.saloons,
            isloading: false,
          });
        }
      })
      .catch((error) => {
        if (error.data.success === false) {
          Swal.fire({
            title: error,
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#ff3600",
            cancelButtonColor: "#354e68",
            confirmButtonText: "Error!",
          });
        }
      });
  };

  filterAppoinment = (searchString) => {
    const { allSaloons } = this.state;
    let text = searchString.toLowerCase();
    let filteredAppoinment = allSaloons.filter((item) => {
      let CompanyName = item?.name;
      return CompanyName && CompanyName.toString().toLowerCase().includes(text);
    });
    if (searchString) {
      this.setState({ filteredAppoinment });
    } else {
      this.setState({ filteredAppoinment: null });
    }
  };

  deleteSalon = (item) => {
    const { access_token } = this.state;
    this.setState({ isloading: true });

    deleteSalonByEmployee(item._id, access_token)
      .then((res) => {
        console.log("deleteSalon -> resssssssssssssssss", res);
        if (res.data.success) {
          this.setState({ isloading: false });
          this.getEmployeeSalon();
        }
      })
      .catch((error) => {
        if (error.data.success === false) {
          Swal.fire({
            title: error,
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#ff3600",
            cancelButtonColor: "#354e68",
            confirmButtonText: "Error!",
          });
        }
      });
  };

  getEmployeeSalon = async () => {
    const { access_token } = this.state;
    const params = { relations: JSON.stringify(["services"]) };
    try {
      const res = await getCompanyThatEmployeeAdd(access_token, params);
      this.setState({ employeeSalon: res.data.data });
    } catch (error) {
      alert("Something went wrong ;(");
    }
  };

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };
  handleRoutToChat = (firstName, salonId, imgURL) => {
    this.props.history.push({
      pathname: "/employee-chat",
      state: { name: firstName, _id: salonId, imgURL },
    });
  };

  _renderTable = () => {
    const { employeeSalon, searchText, saloonId } = this.state;
    let newArr = employeeSalon.filter((val) =>
      val.companyId.name
        ? val.companyId.name.toLocaleLowerCase().includes(searchText)
        : "".toLocaleLowerCase().includes(searchText)
    );

    return (
      <div className="col-12 px-0 px-md-3 active_saloon_header">
        <div className="form-group search_active_input ">
          <div className="pt-3 justify-content-center align-items-center">
            <input
              style={{ backgroundColor: "#FFFFFF", borderColor: "#EBEBEB" }}
              type="text"
              id="addEmployee_search_bar"
              className="form-control form-control-sm pl-5"
              placeholder="Search here"
              onChange={(e) =>
                this.setState({
                  searchText: e.target.value.toLocaleLowerCase(),
                })
              }
            />
            <img
              src={Images.saloon_search}
              className="search-bar-active-saloon-icon"
            />
          </div>
        </div>

        <table className="table table-responsive w-100 d-block d-md-table">
          <thead>
            <tr style={{ borderTop: "3px solid #F7F7F7" }}>
              <th scope="col">Salon Name</th>
              <th scope="col">ID</th>
              <th scope="col">
                <Translation>
                  {(t) => t("container.employe_phone_number")}
                </Translation>
              </th>
              {/* <th scope="col">Service</th> */}
              <th scope="col"></th>
            </tr>
          </thead>
          {newArr?.map((val, index) => {
            return (
              <tbody>
                <tr>
                  <td>
                    <div className="d-flex align-items-center">
                      <img
                        src={val.companyId.companyLogo}
                        className="appointment-details-employee-pic"
                        style={{ width: 50, height: 50, borderRadius: 30 }}
                      />
                      <span
                        style={{ fontWeight: "bold" }}
                        className="appointment-details-employee-name"
                      >
                        {val?.companyId?.name}
                      </span>
                    </div>
                  </td>
                  <td>{val?.companyId?._id}</td>
                  <td>{val?.companyId?.businessTelephone}</td>
                  {/* <td>Hair Cutting</td> */}
                  <td>
                    {/* <span onClick={() => this.deleteSalon(val?.companyId)}> */}
                    <span>
                      {console.log("val", val)}
                      {/* <FontAwesomeIcon
                        onClick={() =>
                          this.handleRoutToChat(
                            val?.companyId?.name,
                            val._id,
                            val.companyId?.previewImageUrl.url
                          )
                        }
                        size="1x"
                        icon={faComment}
                        className="chatSystemIcon"
                      /> */}
                    </span>
                    <span
                      class="px-2"
                      onClick={() => {
                        this.setState({
                          isPopupOpen: true,
                          companyToEdit: {
                            ..._.pick(val, [
                              "companyId",
                              "employeeId",
                              "companyServices",
                              "weekPlans",
                              "isEmployeeIndependent",
                            ]),
                          },
                        });
                      }}
                    >
                      <img
                        src={Images.saloon_working_hour_edit}
                        style={{ width: 22 }}
                      />
                    </span>
                    {(!saloonId ||
                      saloonId !=
                        getNestedValue(val, ["companyId", "_id"])) && (
                      <span
                        onClick={() => {
                          Swal.fire({
                            title: "Delete This?",
                            text: "Are you sure you want to delete this!",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#ff3600",
                            cancelButtonColor: "#354e68",
                            confirmButtonText: "Yes, delete it!",
                          }).then((result) => {
                            if (result.value) {
                              this.deleteSalon(val?.companyId);
                            }
                          });
                        }}
                      >
                        <img src={Images.close_icon} style={{ width: 18 }} />
                      </span>
                    )}
                  </td>
                </tr>
              </tbody>
            );
          })}
        </table>
      </div>
    );
  };

  closeEditServiceCard = () => {
    this.setState({
      showAddEmployeePopup: false,
    });
  };

  renderServicesWorkingHours = () => {
    const { allSaloons, filteredAppoinment } = this.state;
    return (
      <div className="col-12 px-0 px-md-3">
        <h3 className="form-heading">Select Salon:</h3>

        {filteredAppoinment
          ? this.renderSaloons(filteredAppoinment)
          : allSaloons.length > 0 && this.renderSaloons(allSaloons)}
        {/* {this.renderServicesTable()} */}
        <div className="col-12 text-right mt-3">
          <button
            type="button"
            // className="btn btn-light btn-sm mb-2"
            id="active-saloon-save-social-link"
            onClick={() => this.closeEditServiceCard()}
          >
            Cancel
          </button>
          <button
            onClick={() => this.addCompanies()}
            type="button"
            className="btn btn-light btn-sm mb-2"
            id="saloon-save-social-link"
          >
            Add
          </button>
        </div>
      </div>
    );
  };

  onChangeCheckbox = (e, ind) => {
    const { allSaloons } = this.state;
    let newArr = allSaloons.map((val) => {
      return { ...val, isSelected: false };
    });
    if (e.target.checked) {
      newArr[ind].isSelected = true;
      this.setState({ allSaloons: newArr });
    } else {
      newArr[ind].isSelected = false;
      this.setState({ allSaloons: newArr });
    }
  };

  onChangeShouldSendTextReminders = (e, index) => {
    const { companyToEdit } = this.state;
    companyToEdit.companyServices[index] = {
      ...companyToEdit.companyServices[index],
      shouldSendTextReminders: e.target.checked,
    };
    this.setState({ companyToEdit });
  };

  onChangeFrequency = (e, index) => {
    const { companyToEdit } = this.state;
    companyToEdit.companyServices[index] = {
      ...companyToEdit.companyServices[index],
      textReminderFrequency: parseInt(e.target.value),
    };
    this.setState({ companyToEdit });
  };

  renderSaloons = (allSaloons) => {
    return (
      <div>
        <div class="form-group">
          <input
            style={{
              backgroundColor: "#FFFFFF",
              borderColor: "#EBEBEB",
              borderRadius: "0px !important",
            }}
            type="text"
            placeholder="Select Salon"
            class="form-control"
            id="usr"
            onChange={(searchString) => {
              this.filterAppoinment(searchString.target.value);
            }}
          />
        </div>
        <div style={{ overflowY: "scroll" }}>
          <div
            className="col-12"
            style={{
              height: 300,
              backgroundColor: "white",
            }}
          >
            <div className="row">
              {allSaloons?.map((val, i) => {
                return (
                  <div className="col-12 custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id={val?._id}
                      checked={val.isSelected ? true : false}
                      onChange={(e) => this.onChangeCheckbox(e, i)}
                    />
                    <label class="custom-control-label" for={val?._id}>
                      {val.name}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderSelectSaloonPopup = () => {
    const { showAddEmployeePopup } = this.state;
    return (
      <div
        className="employee-service-card-wrapper "
        style={{ display: showAddEmployeePopup ? "flex" : "none" }}
      >
        <div className="container ">
          <div
            className="col-6 p-3 my-5 bg-white pop_container"
            style={{ borderRadius: "1rem" }}
          >
            <div className="py-0 px-2 text-right">
              <span
                onClick={() => this.closeEditServiceCard()}
                className="h4 cursor-pointer"
              >
                &times;
              </span>
            </div>
            {this.renderServicesWorkingHours()}
          </div>
        </div>
      </div>
    );
  };

  renderEditCompanyPopup = () => {
    const { isPopupOpen, companyToEdit } = this.state;
    return (
      isPopupOpen && (
        <div
          className="employee-service-card-wrapper "
          style={{ display: "flex" }}
        >
          <div className="container ">
            <div
              className="col-11 py-3 my-5 bg-white"
              style={{ borderRadius: "1rem" }}
            >
              <div className="py-0 px-2 text-right">
                <span
                  onClick={() => {
                    this.setState({
                      isPopupOpen: false,
                      companyToEdit: undefined,
                    });
                  }}
                  className="h4 cursor-pointer"
                >
                  &times;
                </span>
              </div>
              <table className="table table-responsive w-100 d-block d-md-table">
                <thead>
                  <tr style={{ borderTop: "3px solid #F7F7F7" }}>
                    <th scope="col">
                      {i18n.t("container.profile_service")} Name
                    </th>
                    <th scope="col">Text Reminder Frequency</th>
                  </tr>
                </thead>
                <tbody>
                  {companyToEdit &&
                    companyToEdit.companyServices &&
                    companyToEdit.companyServices.map((service, index) => {
                      return (
                        service?.serviceId?.name && (
                          <tr>
                            <td scope="col">{service?.serviceId?.name}</td>
                            <td scope="col">
                              {this.renderShouldSendTextReminderInput(
                                service,
                                index
                              )}
                            </td>
                            <td scope="col">
                              {this.renderServiceTextFrequencyInput(
                                service,
                                index
                              )}
                            </td>
                          </tr>
                        )
                      );
                    })}
                </tbody>
              </table>
              <div className="col-12 text-right mt-3">
                <button
                  type="button"
                  // className="btn btn-light btn-sm mb-2"
                  id="active-saloon-save-social-link"
                  onClick={() => {
                    this.setState({
                      isPopupOpen: false,
                      companyToEdit: undefined,
                    });
                  }}
                >
                  Cancel
                </button>
                <button
                  onClick={() => this.editCompanies()}
                  type="button"
                  className="btn btn-light btn-sm mb-2"
                  id="saloon-save-social-link"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      )
    );
  };

  renderShouldSendTextReminderInput = (service, index) => {
    const { companyToEdit } = this.state;
    let value = false;
    let disabled = false;
    if (companyToEdit.isEmployeeIndependent) {
      value =
        typeof service?.shouldSendTextReminders === "boolean"
          ? service.shouldSendTextReminders
          : service?.serviceId?.shouldSendTextReminders;
    } else {
      disabled = true;
      value = service?.serviceId?.shouldSendTextReminders
        ? service?.serviceId?.shouldSendTextReminders
        : false;
    }
    return (
      <div class="custom-control custom-checkbox form-group">
        <input
          type="checkbox"
          class="custom-control-input"
          id={"shouldSendTextReminders" + index}
          checked={value}
          disabled={disabled}
          onChange={(e) => this.onChangeShouldSendTextReminders(e, index)}
        />
        <label
          class="custom-control-label"
          for={"shouldSendTextReminders" + index}
        >
          {i18n.t("container.services_send_text_reminders")}
        </label>
      </div>
    );
  };

  renderServiceTextFrequencyInput = (service, index) => {
    const { companyToEdit } = this.state;
    let value = 0;
    let disabled = false;
    if (companyToEdit.isEmployeeIndependent) {
      value = service?.textReminderFrequency
        ? service.textReminderFrequency
        : service?.serviceId?.textReminderFrequency;
    } else {
      disabled = true;
      value = service?.serviceId?.textReminderFrequency || 0;
    }
    const frequencies = [];
    for (let i = 2; i < 26; i++) {
      frequencies.push(
        <option key={i} value={i}>
          {i18n.t("container.every_x_weeks", { value: i })}
        </option>
      );
    }

    return (
      <select
        id="frequencies"
        disabled={disabled}
        value={value}
        class="form-control saloon-edit-category-input"
        onChange={(e) => this.onChangeFrequency(e, index)}
      >
        {frequencies}
      </select>
    );
  };

  render() {
    return (
      <div>
        {this.renderLoader()}
        <div className="content-container">
          <div className="row">
            <div className="col-6 pt-3">
              <h1 className="Dashboard_heading">
                <Translation>{(t) => t("container.active_salon")}</Translation>
              </h1>
            </div>
            <div className="col-6 mt-4">
              <div>
                <div className="d-flex justify-content-end align-items-center pb-3">
                  <span
                    className="add_employee_btn_active align-items-center justify-content-center"
                    onClick={() =>
                      this.setState({ showAddEmployeePopup: true })
                    }
                  >
                    + Add Salon
                  </span>
                </div>
              </div>
            </div>
          </div>
          {this.renderSelectSaloonPopup()}
          {this.renderEditCompanyPopup()}
          {this._renderTable()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(ActiveSaloon);
