import React, { useState, useEffect } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import { TextField } from "@material-ui/core";
import i18n from "../../../i18n";

export default ({
  label,
  value = "",
  defaultValue,
  onChange = () => {},
  options = [],
  props = {},
  error,
  excludeEmptyValue,
  fullWidth = true,
}) => {
  const [_value, setValue] = useState("");
  useEffect(() => {
    setValue(value || defaultValue);
  }, []);
  return (
    <TextField
      {...props}
      labelId={label}
      id={`${label}-select`}
      value={value || _value}
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e);
      }}
      fullWidth={fullWidth}
      select
      label={label}
      error={!!error}
      helperText={error}
    >
      {!excludeEmptyValue ? (
        <MenuItem value="">{i18n.t("select_value")}</MenuItem>
      ) : null}
      {options.map(({ name, value }) => {
        return (
          <MenuItem key={value} value={value}>
            {name}
          </MenuItem>
        );
      })}
    </TextField>
  );
};
