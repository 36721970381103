import { connect } from "react-redux";
import React, { Component } from "react";
import { Translation } from "react-i18next";
import i18n from "../../i18n";

import { Images } from "../../theme";
import { SidebarContent, Footer } from "../../components/Saloon";
import { DotsLoader, Header } from "../../components";
import { request as SaloonAppointment } from "../../redux/actions/Saloon/SaloonAppointment";
import { ErrorHelper, SuccessHelper } from "../../helpers";
import NumberFormat from "react-number-format";
import DatePicker from "react-datepicker";
import { request as get_available_timeslots } from "../../redux/actions/GetAvailableTimeslots.js";
import {
  editBooking,
  getSaloonCategories,
  getSaloonById,
  getEmployeesBySaloonAndService,
  getSaloonServices,
  gitWaitingAppoinments,
  addCustomBooking,
} from "../../config/simpleApiCalls";
import Swal from "sweetalert2";
import moment from "moment";
import Sytles from "./styles";
import "./styles.css";

class AdvancedSearchDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: "",
    };
    this.name = React.createRef();
    this.phoneNumber = React.createRef();
    this.service = React.createRef();
    this.employee = React.createRef();
    this.status = React.createRef();
  }

  onChangeSelectDateAndTime = (date) => {
    this.setState({ date: moment(date).format("MM-DD-YYYY") });
  };

  setStartDate = (val) => {
    const { endDate } = this.state;
    if (val > endDate) {
      this.setState({
        startDate: endDate,
        endDate: val,
      });
    } else {
      this.setState({
        startDate: val,
      });
    }
  };

  setEndDate = (val) => {
    const { startDate } = this.state;
    if (val < startDate) {
      this.setState({
        startDate: val,
        endDate: startDate,
      });
    } else {
      this.setState({
        endDate: val,
      });
    }
  };

  render = () => {
    const {
      hideFitlerDetails,
      services = [],
      employees,
      extraStatues = [],
    } = this.props;
    const { startDate, endDate } = this.state;
    const statuses = [
      { text: "Done", value: 4 },
      { text: "Cancel", value: 3 },
      ...extraStatues,
    ];
    return (
      <div className="col-12 px-0 px-md-3 advanced-search-dialog">
        <div
          className="employee-service-card-wrapper"
          style={{ display: "flex" }}
        >
          <div className="container">
            <div
              className="col-12 pt-3 pb-3 pl-md-5 pr-md-5 my-5 bg-white pop_container"
              style={{ borderRadius: "1rem" }}
            >
              <div className="d-flex justify-content-between">
                <div style={{ fontSize: 25 }}>
                  {" "}
                  <Translation>
                    {(t) => t("container.advancedSearchDialog_advanced_search")}
                  </Translation>
                </div>
                <div className="py-0 px-2 text-right ">
                  <span
                    onClick={() => this.props.onClose()}
                    className="h4 cursor-pointer"
                  >
                    &times;
                  </span>
                </div>
              </div>
              <div className="row mb-2">
                <div className="form-label-group col-md-6 col-xs-12">
                  <div>
                    {" "}
                    <Translation>
                      {(t) => t("container.advancedSearchDialog_name")}
                    </Translation>
                  </div>
                  <input
                    type="text"
                    className="form-control mt-1"
                    ref={this.name}
                  />
                </div>
                <div className="form-label-group col-md-6 col-xs-12">
                  <div>
                    <Translation>
                      {(t) => t("container.advancedSearchDialog_phone_number")}
                    </Translation>
                  </div>
                  <NumberFormat
                    type="text"
                    className="form-control mt-1"
                    ref={this.phoneNumber}
                    format="### ### ####"
                  />
                </div>
                <div className="form-label-group col-md-6 col-xs-12">
                  <div>
                    <Translation>
                      {(t) => t("container.profile_service")}
                    </Translation>
                  </div>
                  <select
                    id="function-role"
                    className="form-control"
                    ref={this.service}
                  >
                    <option className="form-group" value="" id="">
                      Select a Service
                    </option>
                    {services.map((service) => (
                      <option
                        className="form-group"
                        value={service}
                        id={service}
                      >
                        {service}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-label-group col-md-6 col-xs-12">
                  <div>{i18n.t('date_range')}</div>
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <div className="mx-1">
                      <DatePicker
                        className="form-control"
                        placeholderText="Select Start Date"
                        onChange={(data) => this.setStartDate(data)}
                        dateFormat="MM/dd/yyyy"
                        selected={startDate}
                      />
                    </div>
                    <div className="mx-1">
                      <DatePicker
                        className="form-control"
                        placeholderText="Select End Date"
                        onChange={(data) => this.setEndDate(data)}
                        dateFormat="MM/dd/yyyy"
                        selected={endDate}
                      />
                    </div>
                  </div>
                </div>
                {employees && (
                  <div className="form-label-group col-md-6 col-xs-12">
                    <div>
                      <Translation>
                        {(t) => t("container.employee")}
                      </Translation>
                    </div>
                    <select
                      id="function-role"
                      className="form-control"
                      ref={this.employee}
                    >
                      <option className="form-group" value="" id="">
                        Select an Employee
                      </option>
                      {employees.map((employee) => (
                        <option
                          className="form-group"
                          value={employee.value}
                          id={employee.value}
                        >
                          {employee.text}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                <div className="form-label-group col-md-6 col-xs-12">
                  <div>
                    <Translation>
                      {(t) => t("container.profile_status")}
                    </Translation>
                  </div>
                  <select
                    id="function-role"
                    className="form-control"
                    ref={this.status}
                  >
                    <option className="form-group" value="" id="">
                      Select a Status
                    </option>
                    {statuses.map((status) => (
                      <option
                        className="form-group"
                        value={status.value}
                        id={status.value}
                      >
                        {status.text}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div>
                <button
                  onClick={() => {
                    const extra = {};
                    if (this.employee.current) {
                      extra.employee = this.employee.current.value;
                    }
                    this.props.onFilter({
                      name: this.name.current.value,
                      phoneNumber: this.phoneNumber.current
                        ? this.phoneNumber.current.state.numAsString
                        : "",
                      service: this.service.current.value,
                      startDate: this.state.startDate,
                      endDate: this.state.endDate,
                      status: this.status.current.value,
                      ...extra,
                    });
                  }}
                  type="button"
                  className="btn btn-light btn-sm"
                  id="saloon-save-social-link"
                >
                  <Translation>
                    {(t) => t("container.advancedSearchDialog_filter")}
                  </Translation>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(AdvancedSearchDialog);
