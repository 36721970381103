import { connect } from "react-redux";
import React, { Component, useEffect, useState } from "react";
import { DotsLoader } from "../../../components";
import RequestFunds from "../../../components/Employee/RequestFunds";
import { SuccessHelper } from "../../../helpers";
import i18n from "../../../i18n";
// import "./styles.css";

export default () => {
  const [accessToken, setAccessToken] = useState("");
  useEffect(() => {
    const employeeData = JSON.parse(sessionStorage.getItem("employee"));
    setAccessToken(employeeData.access_token);
  }, []);
  return (
    <div className="content-container">
      <RequestFunds accessToken={accessToken} />
    </div>
  );
};
