import React, { useEffect } from "react";
import i18n from "../../i18n";
import Swal from "sweetalert2";

export default (props) => {
  useEffect(() => {
    Swal.fire({
      title: i18n.t("hello"),
      html:
        `Votre rendez-vous été annulé:` +
        `<br/><br/>Your booking has been cancelled:`,
      confirmButtonColor: "#ff3600",
      confirmButtonText: i18n.t("ok"),
      allowOutsideClick: true,
    });
    props.history.push("/");
  }, []);
  return null;
};
