import { put, takeEvery } from "redux-saga/effects";

import * as types from "../actions/ActionTypes";
import {
  update,
  insert,
  remove,
  set,
  requestFailure,
} from "../actions/CompanyCard";

import axios from "axios";
import { company_card as card } from "../../config/WebServices";

function* getSaga(action) {
  const { payload, callbackMap = {} } = action;
  const accessId = sessionStorage.getItem("financialAccessId");
  let data;
  try {
    const token = payload.access_token;
    delete payload.access_token;
    data = yield axios.get(card, {
      params: payload,
      headers: {
        Authorization: token,
        accessId,
      },
    });
    data = data.data;

    if (data.success) {
      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data);
      }
      yield put(
        set({ cards: data.cards?.data, defaultCard: data.defaultGatewayId })
      );
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data);
      }
      yield put(requestFailure(data));
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data);
    }
    yield put(requestFailure(data));
  }
}

function* updateSaga(action) {
  const { payload, callbackMap = {} } = action;
  const accessId = sessionStorage.getItem("financialAccessId");
  let data;
  try {
    const token = payload.access_token;
    delete payload.access_token;
    data = yield axios.post(card, payload, {
      headers: {
        Authorization: token,
        accessId,
      },
    });
    data = data.data;
    if (data.success) {
      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data);
      }
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data);
      }
      yield put(requestFailure(data));
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data);
    }
    yield put(requestFailure(data));
  }
}

function* insertSaga(action) {
  const { payload, callbackMap = {} } = action;
  const accessId = sessionStorage.getItem("financialAccessId");
  let data;
  try {
    const token = payload.access_token;
    delete payload.access_token;
    data = yield axios.post(card, payload, {
      headers: {
        Authorization: token,
        accessId,
      },
    });
    data = data.data;
    if (data.success) {
      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data);
      }
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data);
      }
      yield put(requestFailure(data));
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data);
    }
    yield put(requestFailure(data));
  }
}

function* removeSaga(action) {
  const { payload, callbackMap = {} } = action;
  const accessId = sessionStorage.getItem("financialAccessId");
  let data;
  try {
    const token = payload.access_token;
    delete payload.access_token;
    data = yield axios.delete(card, {
      data: payload,
      headers: {
        Authorization: token,
        accessId,
      },
    });
    data = data.data;
    if (data.success) {
      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data);
      }
      yield put(remove(payload.cardId));
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data);
      }
      yield put(requestFailure(data));
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data);
    }
    yield put(requestFailure(data));
  }
}

function* setDefaultSaga(action) {
  const { payload, callbackMap = {} } = action;
  const accessId = sessionStorage.getItem("financialAccessId");
  let data;
  try {
    const token = payload.access_token;
    delete payload.access_token;
    data = yield axios.post(`${card}/setDefaultPaymentMethod`, payload, {
      headers: {
        Authorization: token,
        accessId,
      },
    });
    data = data.data;
    if (data.success) {
      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data);
      }
      yield put(remove(payload.cardId));
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data);
      }
      yield put(requestFailure(data));
    }
  } catch (err) {
    console.log(err);
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data);
    }
    yield put(requestFailure(data));
  }
}

function* removeFinancialAccessIdOnExpiree(action, callBack) {
  const { callbackMap = {} } = action;
  const original =
    callbackMap.failure && typeof callbackMap.failure == "function"
      ? callbackMap.failure
      : () => {};
  callbackMap.failure = (err) => {
    console.log("asdasdasd", err);
    sessionStorage.removeItem("financialAccessId");
    original();
  };
  action.callbackMap = callbackMap;
  yield callBack(action);
}

export default function* root() {
  yield takeEvery(types.COMPANY_CARD.GET_SAGA, (action) =>
    removeFinancialAccessIdOnExpiree(action, getSaga)
  );
  yield takeEvery(types.COMPANY_CARD.UPDATE_SAGA, (action) =>
    removeFinancialAccessIdOnExpiree(action, updateSaga)
  );
  yield takeEvery(types.COMPANY_CARD.INSERT_SAGA, (action) =>
    removeFinancialAccessIdOnExpiree(action, insertSaga)
  );
  yield takeEvery(types.COMPANY_CARD.DELETE_SAGA, (action) =>
    removeFinancialAccessIdOnExpiree(action, removeSaga)
  );
  yield takeEvery(types.COMPANY_CARD.SET_DEFAULT_SAGA, (action) =>
    removeFinancialAccessIdOnExpiree(action, setDefaultSaga)
  );
}
