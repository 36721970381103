import { take, put, call, fork } from "redux-saga/effects";

import * as types from "../../actions/ActionTypes";
import { success, failure } from "../../actions/Saloon/SaloonByUserId";

import ApiSauce from "../../../services/apiSauce";
import { get_saloon_user_id_Api } from "../../../config/WebServices";
import { ErrorHelper } from "../../../helpers";

function callRequest(data) {
    const access_token = data.access_token;
    delete data.access_token;
    return ApiSauce.getWithToken(get_saloon_user_id_Api, data, access_token);
}
function* watchRequest() {
    while (true) {
        const { payload } = yield take(types.SALOON_USER_ID.REQUEST);
        try {
            const response = yield call(callRequest, payload);
            yield put(success(response));
        } catch (err) {
            yield put(failure(err));
            ErrorHelper.handleErrors(err, true);
        }
    }
}

export default function* root() {
    yield fork(watchRequest);
}
