// @flow
const REQUEST = "REQUEST";
const SUCCESS = "SUCCESS";
const FAILURE = "FAILURE";
const CANCEL = "CANCEL";

function createRequestTypes(base) {
  const res = {};
  [REQUEST, SUCCESS, FAILURE, CANCEL].forEach((type) => {
    res[type] = `${base}_${type}`;
  });
  return res;
}
export const CLEAR_LOGOUT = "CLEAR_LOGOUT";

// USER
export const USER = { SET_USER: "SET_USER" };
export const LOGIN = createRequestTypes("LOGIN");
export const REGISTER = createRequestTypes("REGISTER");
export const FORGOT_PASSWORD = createRequestTypes("FORGOT_PASSWORD");
export const VERIFY_RESET_CODE = createRequestTypes("VERIFY_RESET_CODE");
export const RESET_PASSWORD = createRequestTypes("RESET_PASSWORD");
export const SOCIAL_LOGIN = createRequestTypes("SOCIAL_LOGIN");
export const LOGOUT = createRequestTypes("LOGOUT");
export const GETAVAILABLETIMESLOTS = createRequestTypes(
  "GETAVAILABLETIMESLOTS"
);
export const GETGYMTIMESLOTS = createRequestTypes("GETGYMTIMESLOTS");
export const GET_HOME_PAGE_INFO = createRequestTypes("GET_HOME_PAGE_INFO");
export const GET_HOME = createRequestTypes("GET_HOME");

// SALOON
export const SALOON_LOGIN = createRequestTypes("SALOON_LOGIN");
export const SALOON_REGISTER = createRequestTypes("SALOON_REGISTER");
export const CREATE_SALOON = createRequestTypes("CREATE_SALOON");
export const CREATE_SALOON_PLAN = createRequestTypes("CREATE_SALOON_PLAN");
export const SALOON_PAYMENT_METHOD = createRequestTypes(
  "SALOON_PAYMENT_METHOD"
);
export const CREATE_SALOON_SOCIAL_MEDIA = createRequestTypes(
  "CREATE_SALOON_SOCIAL_MEDIA"
);
export const GET_SALOON_APPOINTMENT = createRequestTypes(
  "GET_SALOON_APPOINTMENT"
);
export const SALOON_USER_ID = createRequestTypes("SALOON_USER_ID");
export const SALOON_LOGOUT = createRequestTypes("SALOON_LOGOUT");

// EMPLOYEE
export const EMPLOYEE_REGISTER = createRequestTypes("EMPLOYEE_REGISTER");
export const EMPLOYEE_LOGIN = createRequestTypes("EMPLOYEE_LOGIN");
export const EMPLOYEE_LOGOUT = createRequestTypes("EMPLOYEE_LOGOUT");

// TEMPLATE
export const TEMPLATE_NAVIGATION = "TEMPLATE_NAVIGATION";

// NOTIFICATIONS
export const GET_NOTIFICATIONS = {
  ...createRequestTypes("GET_NOTIFICATIONS"),
  UPDATE_BY_ID: "GET_NOTIFICATIONS_UPDATE_BY_ID",
};
export const UPDATE_NOTIFICATION = createRequestTypes("UPDATE_NOTIFICATION");
export const SET_ACTIVE_BOOKING = "SET_ACTIVE_BOOKING";
export const CLEAR_ACTIVE_BOOKING = "CLEAR_ACTIVE_BOOKING";
// IMPERSONATE
export const IMPERSONATE_USER = createRequestTypes("IMPERSONATE_USER");
export const IMPERSONATE_SALON = createRequestTypes("IMPERSONATE_SALON");
export const IMPERSONATE_EMPLOYEE = createRequestTypes("IMPERSONATE_EMPLOYEE");

export const ANNOUNCEMENTS = createRequestTypes("ANNOUNCEMENTS");
export const SIDEBAR = { SET_OPENED: "SET_OPENED", SET_DOCKED: "SET_DOCKED" };

export const CARD = {
  GET_SAGA: "GET_SAGA",
  SET: "SET",
  INSERT: "INSERT",
  INSERT_SAGA: "INSERT_SAGA",
  UPDATE: "UPDATE",
  UPDATE_SAGA: "UPDATE_SAGA",
  DELETE: "DELETE",
  DELETE_SAGA: "DELETE_SAGA",
  REQUEST_FAILURE: "REQUEST_FAILURE",
  SET_DEFAULT_SAGA: "SET_DEFAULT_SAGA",
};
export const COMPANY_CARD = {
  GET_SAGA: "COMPANY_CARD_GET_SAGA",
  SET: "COMPANY_CARD_SET",
  INSERT: "COMPANY_CARD_INSERT",
  INSERT_SAGA: "COMPANY_CARD_INSERT_SAGA",
  UPDATE: "COMPANY_CARD_UPDATE",
  UPDATE_SAGA: "COMPANY_CARD_UPDATE_SAGA",
  DELETE: "COMPANY_CARD_DELETE",
  DELETE_SAGA: "COMPANY_CARD_DELETE_SAGA",
  REQUEST_FAILURE: "COMPANY_CARD_REQUEST_FAILURE",
  SET_DEFAULT_SAGA: "COMPANY_CARD_SET_DEFAULT_SAGA",
};

export const BILLING = {
  GET_SAGA: "BILLING_GET_SAGA",
  SET: "BILLING_SET",
  REQUEST_FAILURE: "BILLING_REQUEST_FAILURE",
};

export const EASY1_PRODUCTS = {
  GET_SAGA: "EASY1_PRODUCTS_GET_SAGA",
  SET: "EASY1_PRODUCTS_SET",
  REQUEST_FAILURE: "EASY1_PRODUCTS_REQUEST_FAILURE",
};

export const SUBSCRIPTION = {
  GET_SAGA: "SUBSCRIPTION_GET_SAGA",
  SET: "SUBSCRIPTION_SET",
  INSERT: "SUBSCRIPTION_INSERT",
  INSERT_SAGA: "SUBSCRIPTION_INSERT_SAGA",
  UPDATE: "SUBSCRIPTION_UPDATE",
  UPDATE_SAGA: "SUBSCRIPTION_UPDATE_SAGA",
  DELETE: "SUBSCRIPTION_DELETE",
  DELETE_SAGA: "SUBSCRIPTION_DELETE_SAGA",
  REQUEST_FAILURE: "SUBSCRIPTION_REQUEST_FAILURE",
};

function newCreateRequestTypes(base, types) {
  const res = {};
  types.map((type) => {
    if (typeof type == "object") {
      const { label } = type;
      if (!type.noSaga) {
        res[`${label}_SAGA`] = `${base}_${label}_SAGA`;
      }
      if (!type.onlySaga) {
        res[label] = `${base}_${label}`;
      }
    } else {
      const label = type;
      res[label] = `${base}_${label}`;
      res[`${label}_SAGA`] = `${base}_${label}_SAGA`;
    }
  });
  res[`REQUEST_FAILURE`] = `${base}_REQUEST_FAILURE`;

  return res;
}

export const BOOKING_TRANSACTION = newCreateRequestTypes(
  "BOOKING_TRANSACTION",
  [
    "GET",
    "INSERT",
    { label: "REVERSE", onlySaga: true },
    { label: "SET_COLLECT_MONEY", noSaga: true },
  ]
);

export const EMPLOYEE_FUNDS_REQUEST = newCreateRequestTypes(
  "EMPLOYEE_FUNDS_REQUEST",
  ["GET", "INSERT", "ACCOUNT_DETAILS"]
);

export const ALL_FUNDS_REQUEST = newCreateRequestTypes(
  "GET_ALL_FUNDS_REQUEST",
  ["GET", "INSERT", "UPDATE", "EMPLOYEE_ACCOUNT_DETAILS"]
);

export const ORDERS = newCreateRequestTypes("ORDERS", ["GET"]);

export const BOOKING_REF_CODE = newCreateRequestTypes("BOOKING_REF_CODE", [
  { label: "SET", noSaga: true },
  { label: "CLEAR", noSaga: true },
  { label: "VALIDATE", onlySaga: true },
]);

export const PACKAGES = newCreateRequestTypes("PACKAGES", [
  "GET",
  "INSERT",
  "UPDATE",
  "REMOVE_ITEMS",
  "INSERT_ITEMS",
  { label: "SET_ACTIVE", noSaga: true },
  "GET_USER_PACKAGES",
  "DELETE",
  "GET_SOLD",
  "SOLD",
  "GET_USER_PACKAGE_BY_PHONE",
  "ADD_TRANSACTION",
  "REVERSE_TRANSACTION",
]);

export const FORM_BUILDER = newCreateRequestTypes("FORM_BUILDER", [
  "GET",
  "GET_BY_ID",
  "INSERT",
  "UPDATE",
  "DELETE",
  "DELETE_QUESTION",
  "UPDATE_QUESTION",
  "INSERT_QUESTION",
]);

export const FORM_ANSWER = newCreateRequestTypes("FORM_ANSWER", [
  "GET",
  "GET_BY_ID",
  "SUBMIT_FILLED_FORM",
  "MARK_AS_VIEWED",
]);

export const REOCCURING_UNAVAILABILITIES = newCreateRequestTypes(
  "REOCCURING_UNAVAILABILITIES",
  ["GET", "DELETE", "INSERT"]
);

export const VENDOR = newCreateRequestTypes("VENDOR", [
  "GET",
  "DELETE",
  "UPSERT",
  { label: "SET_ACTIVE", noSaga: true },
]);

export const EXPENSE = newCreateRequestTypes("EXPENSE", [
  "GET",
  "GET_REPORT",
  "DELETE",
  "INSERT",
  { label: "SET_ACTIVE", noSaga: true },
]);

export const COMMUNICATION_SETTINGS = newCreateRequestTypes(
  "COMMUNICATION_SETTINGS",
  ["GET", "UPSERT"]
);

export const RECEPTIONIST_ACCESS = newCreateRequestTypes(
  "RECEPTIONIST_ACCESS",
  ["GET", "UPDATE"]
);

export const MANAGER_ACCESS = newCreateRequestTypes("MANAGER_ACCESS", [
  "GET",
  "UPDATE",
]);

export const CLASS = newCreateRequestTypes("CLASS", [
  "GET",
  "DELETE",
  "UPSERT",
  "DELETE_GRADE",
  "ADD_GRADE",
]);

export const PROGRAM = newCreateRequestTypes("PROGRAM", [
  "GET",
  "DELETE",
  "UPSERT",
  "DELETE_CLASS",
  "ADD_CLASS",
]);

export const STUDENT = newCreateRequestTypes("STUDENT", [
  "GET",
  "DELETE",
  "UPSERT",
  "SET_GRADE",
  "SET_FINALIZED",
]);

export const STUDENT_GROUP = newCreateRequestTypes("STUDENT_GROUP", [
  "GET",
  "DELETE",
  "UPSERT",
  "DELETE_STUDENT",
  "ADD_STUDENT",
]);

export const COURSE_PLANNER = newCreateRequestTypes("COURSE_PLANNER", [
  "GET",
  "DELETE",
  "UPSERT",
  "SET_ATTENDANCE",
]);

export const PROGRAM_ENROLLMENT = newCreateRequestTypes("PROGRAM_ENROLLMENT", [
  "GET",
  "DELETE",
  "INSERT",
  "ADD_TRANSACTION",
  "REVERSE_TRANSACTION",
  "SET_STUDENT_ENROLLMENT",
]);

export const SEMESTER = newCreateRequestTypes("SEMESTER", [
  "GET",
  "DELETE",
  "UPSERT",
  "DELETE_CLASS",
  "ADD_CLASS",
  "ADD_STUDENT_TO_CLASS",
  "ADD_STUDENT_GROUP_TO_CLASS",
  "DELETE_STUDENT_FROM_CLASS",
]);

export const NO_SHOW_REPORT = newCreateRequestTypes("NO_SHOW_REPORT", ["GET"]);
