import React, { useState } from "react";
import moment from "moment";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";

import "./styles.scss";
import i18n from "../../../i18n";
import { Images } from "../../../theme";
import { useEffect } from "react";

moment.locale("en");

const ManageExtras = ({
  products = [],
  services = [],
  readonly,
  onProductChange = () => {},
  onProductDelete = () => {},
  onServiceDelete = () => {},
  onAddClick = () => {},
  transactions = [],
}) => {
  const [collectedMap, setCollectedMap] = useState({
    extras: {},
    products: {},
  });
  useEffect(() => {
    transactions.map((transaction) => {
      const { metadata = {}, wasReversed, type } = transaction;
      if (!wasReversed && type !== "Reverse") {
        Object.keys(metadata).map((key) => {
          let mappedKey;
          switch (key) {
            case "Extras":
            case "Products": {
              mappedKey = key.toLowerCase();
              if (!collectedMap[mappedKey]) {
                collectedMap[mappedKey] = {};
              }
              if (!collectedMap[mappedKey][metadata[key]]) {
                collectedMap[mappedKey][metadata[key]] = true;
              }
              break;
            }
          }
        });
      }
    });
    setCollectedMap({ ...collectedMap });
  }, [transactions]);
  return (
    <div className="manage-extras my-1">
      <div className="row mx-0 py-2 justify-content-between">
        <h5>{i18n.t("extras")}</h5>
        {!readonly && (
          <Button
            onClick={() => onAddClick()}
            size="small"
            style={{
              backgroundColor: "#ff3600",
              color: "white",
            }}
          >
            {i18n.t("add")}
          </Button>
        )}
      </div>
      {products.length || services.length ? (
        <>
          <div className="mt-1">
            <Table
              // className={classes.table}
              aria-labelledby="tableTitle"
              size={"small"}
              aria-label="enhanced table"
            >
              <TableHead style={{ backgroundColor: "rgb(0, 33, 71)" }}>
                <TableRow>
                  <TableCell style={{ color: "white" }}>Name</TableCell>
                  <TableCell style={{ color: "white" }}>Quantity</TableCell>
                  <TableCell style={{ color: "white" }}>Price</TableCell>
                  {!readonly && (
                    <TableCell style={{ color: "white" }}></TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {products.map((product, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={product._id}
                    >
                      <TableCell>{product.name}</TableCell>
                      <TableCell>
                        {!readonly && !product.reserved ? (
                          <input
                            type="number"
                            style={{ width: "60px" }}
                            disabled={readonly}
                            defaultValue={product.quantity}
                            min={1}
                            onBlur={(e) => {
                              let change = parseInt(e.target.value);
                              if (change < 0) {
                                change = 0;
                              }
                              onProductChange(change, index);
                            }}
                          />
                        ) : (
                          <>{product.quantity}</>
                        )}
                      </TableCell>
                      <TableCell>
                        {(product.price * product.quantity).toFixed(2)}
                      </TableCell>
                      {!readonly &&
                      !product.reserved &&
                      !collectedMap.products[product._id] ? (
                        <TableCell style={{ width: 30 }}>
                          <img
                            onClick={() => onProductDelete(index)}
                            className="category-table-action-icon"
                            src={Images.saloon_working_hour_delete}
                          />
                        </TableCell>
                      ) : (
                        <TableCell style={{ width: 30 }} />
                      )}
                    </TableRow>
                  );
                })}
                {services.map((service, index) => (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={service._id}
                  >
                    <TableCell>
                      {service.serviceId
                        ? service.serviceId.name
                        : service.name}
                    </TableCell>
                    <TableCell>1</TableCell>
                    <TableCell>{service.price}</TableCell>
                    {!readonly && !service.paid ? (
                      <TableCell style={{ width: 30 }}>
                        <img
                          onClick={() => onServiceDelete(index)}
                          className="category-table-action-icon"
                          src={Images.saloon_working_hour_delete}
                        />
                      </TableCell>
                    ) : (
                      <TableCell style={{ width: 30 }} />
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default ManageExtras;
