// @flow
import Immutable from "seamless-immutable";
import * as types from "../../actions/ActionTypes";

const initialState = Immutable({
  isFetching: false,
  errorMessage: "",
  data: [],
});

export default (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case types.STUDENT_GROUP.GET_SAGA:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case types.STUDENT_GROUP.GET:
      return Immutable.merge(state, {
        isFetching: false,
        data: action.data,
      });
    case types.STUDENT_GROUP.UPSERT_SAGA:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case types.STUDENT_GROUP.UPSERT: {
      const newState = { ...state, isFetching: false, data: [...state.data] };
      const { _id } = action.data;
      const index = state.data.findIndex((element) => element._id == _id);
      if (index != -1) {
        newState.data[index] = action.data;
      } else {
        newState.data.push(action.data);
      }
      return newState;
    }
    case types.STUDENT_GROUP.DELETE_SAGA:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case types.STUDENT_GROUP.DELETE: {
      const newState = { ...state, isFetching: false, data: [...state.data] };
      const { _id } = action.data;
      const index = state.data.findIndex((element) => element._id == _id);
      if (index != -1) {
        newState.data.splice(index, 1);
      }
      return newState;
    }
    case types.STUDENT_GROUP.DELETE_SAGA:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case types.PROGRAM.ADD_STUDENT:
    case types.PROGRAM.DELETE_STUDENT: {
      const newState = { ...state, isFetching: false, data: [...state.data] };
      const { _id } = action.data;
      const index = state.data.findIndex((element) => element._id == _id);
      if (index != -1) {
        newState.data[index] = action.data;
      }
      return newState;
    }

    case types.LOGOUT:
      return initialState;
    case types.CLEAR_LOGOUT:
      return initialState;
    default:
      return state;
  }
};
