import React, { Component, useEffect, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import i18n from "../../i18n";
import { ErrorHelper } from "../../helpers";
import TextField from "@material-ui/core/TextField";

import "./style.scss";

export default function CustomPlacesAutocomplete({
  addressSelected,
  defaultAddress = "",
  className,
  helperText,
  addressError,
  error,
}) {
  const [address, setAddress] = useState("");
  const [typedAddress, setTypedAddress] = useState("");
  const [addressMap, setAddressMap] = useState({});
  useEffect(() => {
    setAddress(defaultAddress);
  }, []);

  const onAddressSelect = (address, key) => {
    const requireComponentsMap = {
      street_number: true,
      route: true,
      locality: true,
      administrative_area_level_1: true,
      country: true,
      postal_code: true,
    };
    let addressMap = {
      street_number: undefined,
      route: undefined,
      locality: undefined,
      administrative_area_level_1: undefined,
      country: undefined,
      postal_code: undefined,
    };
    geocodeByAddress(address)
      .then((results) => {
        const shortNameMap = {};
        results[0].address_components.map((component) => {
          const relevant = component.types.find(
            (type) => requireComponentsMap[type]
          );
          if (relevant && !addressMap[relevant]) {
            addressMap[relevant] = component.long_name;
            shortNameMap[relevant] = component.short_name;
          }
        });
        if (addressMap.street_number && addressMap.route) {
          addressMap = {
            address: `${addressMap.street_number} ${addressMap.route}`,
            postalCode: addressMap.postal_code,
            city: addressMap.locality,
            province: addressMap.administrative_area_level_1,
            country: addressMap.country,
          };
          let address = Object.values(addressMap)
            .filter((element) => !!element)
            .join(", ");
          setAddress(address);
          addressSelected({
            ...addressMap,
            country_abbr: shortNameMap.country,
            province_abbr: shortNameMap.administrative_area_level_1,
          });
          setAddressMap(addressMap);
        } else {
          ErrorHelper.handleErrors(i18n.t("invalid_address"), true);
        }
      })
      .catch((error) => console.error("Error", error));
  };

  return (
    <div className={`custom-places-autocomplete ${className}`}>
      <PlacesAutocomplete
        value={address}
        id="address"
        onChange={(e) => {
          setAddress(e);
        }}
        onSelect={(e) => {
          onAddressSelect(e);
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <TextField
              label={i18n.t("container.address")}
              {...getInputProps({
                onBlur: () => {
                  let address = Object.values(addressMap)
                    .filter((element) => !!element)
                    .join(", ");
                  setAddress(address);
                },
                onFocus: () => {
                  setTypedAddress(address);
                  setAddress();
                },
                className: "w-100",
              })}
              helperText={addressError}
              error={!!addressError}
              helperText={helperText}
              error={!!error}
            />

            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion, index) => {
                const className = suggestion.active
                  ? "suggestion-item suggestion-item--active border"
                  : "suggestion-item border";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: "#fafafa", cursor: "pointer" }
                  : { backgroundColor: "#ffffff", cursor: "pointer" };
                return (
                  <div
                    key={index}
                    {...getSuggestionItemProps(suggestion)}
                    className={className}
                    style={style}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
}
