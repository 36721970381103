// @flow
import Immutable from "seamless-immutable";
import * as types from "../actions/ActionTypes";

const initialState = Immutable({
  isFetching: false,
  errorMessage: "",
  data: [],
  userPackages: [],
  soldPackages: [],
  active: null,
});

export default (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case types.PACKAGES.GET_SAGA:
    case types.PACKAGES.ADD_TRANSACTION_SAGA:
    case types.PACKAGES.REVERSE_TRANSACTION_SAGA:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case types.PACKAGES.GET:
      return Immutable.merge(state, {
        isFetching: false,
        data: action.data,
      });
    case types.PACKAGES.INSERT: {
      const newState = { ...state, isFetching: false, data: [...state.data] };
      const { _id } = action.data;
      const index = state.data.findIndex((element) => element._id == _id);
      newState.data.unshift(action.data);
      return newState;
    }
    case types.PACKAGES.INSERT_SAGA:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case types.PACKAGES.DELETE: {
      const newState = { ...state, isFetching: false, data: [...state.data] };
      const { _id } = action.data;
      const index = state.data.findIndex((element) => element._id == _id);
      if (index != -1) {
        newState.data.splice(index, 1);
      }
      return newState;
    }
    case types.PACKAGES.DELETE_SAGA:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case types.PACKAGES.UPDATE: {
      const newState = { ...state, isFetching: false, data: [...state.data] };
      const { _id } = action.data;
      const index = state.data.findIndex((element) => element._id == _id);
      if (index != -1) {
        newState.data[index] = action.data;
      } else {
        newState.data.unshift(action.data);
      }
      return newState;
    }
    case types.PACKAGES.UPDATE_SAGA:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case types.PACKAGES.REMOVE_ITEMS: {
      const active = action.data;
      const newData = [...state.data];
      const { _id } = active;
      const index = state.data.findIndex((element) => element._id == _id);
      if (index != -1) {
        newData[index] = action.data;
      }
      return Immutable.merge(state, {
        isFetching: false,
        active,
        data: newData,
      });
    }
    case types.PACKAGES.REMOVE_ITEMS_SAGA:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case types.PACKAGES.INSERT_ITEMS: {
      const active = action.data;
      const newData = [...state.data];
      const { _id } = active;
      const index = state.data.findIndex((element) => element._id == _id);
      if (index != -1) {
        newData[index] = action.data;
      }
      return Immutable.merge(state, {
        isFetching: false,
        active,
        data: newData,
      });
    }
    case types.PACKAGES.INSERT_ITEMS_SAGA:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case types.PACKAGES.GET_USER_PACKAGES: {
      const userPackages = action.data;
      return Immutable.merge(state, {
        isFetching: false,
        userPackages,
      });
    }
    case types.PACKAGES.GET_USER_PACKAGES_SAGA:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case types.PACKAGES.GET_USER_PACKAGE_BY_PHONE: {
      const userPackages = action.data;
      return Immutable.merge(state, {
        isFetching: false,
        userPackages,
      });
    }
    case types.PACKAGES.ADD_TRANSACTION:
    case types.PACKAGES.REVERSE_TRANSACTION: {
      const newState = {
        ...state,
        isFetching: false,
        soldPackages: [...state.soldPackages],
      };
      const { _id } = action.data;
      const index = state.soldPackages.findIndex(
        (element) => element._id == _id
      );
      if (index != -1) {
        newState.soldPackages[index] = action.data;
      } else {
        newState.soldPackages.unshift(action.data);
      }
      return newState;
    }
    case types.PACKAGES.GET_USER_PACKAGE_BY_PHONE_SAGA:
      return Immutable.merge(state, {
        isFetching: true,
      });

    case types.PACKAGES.SOLD: {
      return Immutable.merge(state, {
        isFetching: false,
      });
    }
    case types.PACKAGES.SOLD_SAGA:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case types.PACKAGES.GET_SOLD: {
      return Immutable.merge(state, {
        soldPackages: action.data,
        isFetching: false,
      });
    }
    case types.PACKAGES.GET_SOLD_SAGA:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case types.PACKAGES.REQUEST_FAILURE:
      return Immutable.merge(state, {
        isFetching: false,
      });
    case types.PACKAGES.SET_ACTIVE:
      return Immutable.merge(state, {
        active: action.data,
      });
    case types.LOGOUT:
      return initialState;
    case types.CLEAR_LOGOUT:
      return initialState;
    default:
      return state;
  }
};
