import { connect } from "react-redux";
import React, { useMemo, useRef, useState } from "react";
import { DropdownList, DateTimePicker } from "react-widgets";
import DatePicker from "react-datepicker";
import moment from "moment";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ManageExtras from "../Extras/ManageExtras";
import Autocomplete from "react-autocomplete";

import i18n from "../../i18n";
import { getNestedValue } from "../../util/objectMethods";
import { getUserPackagesRequest } from "../../redux/actions/Packages";
import InputAdornment from "@material-ui/core/InputAdornment";
import AccountCircle from "@material-ui/icons/AccountCircle";
import PrimaryButton from "../Core/PrimaryButton";
import CollapsibleTable from "../CollapsibleTable";
import BaseModal from "../BaseModal";

moment.locale("en");

const UserPackages = ({
  userPackages = [],
  renderDateTimeEmployeeSection,
  employeeId,
  name,
  email,
  phoneNo,
  date,
  time,
  onChangeSelectServices,
  onChangeSelectSalon,
  bookNow,
}) => {
  const [selected, setSelected] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState(null);

  const onSelect = async (val, row) => {
    setSelected(val);
    setSelectedPackage(row);
    await onChangeSelectSalon({
      target: { value: row.companyId },
    });
    onChangeSelectServices(JSON.stringify(val.serviceId));
  };
  const onBookNow = () => {
    const payload = {
      companyId: selectedPackage.companyId,
      services: [
        {
          serviceId: selected.serviceId._id,
          categoryId: selected.serviceId.serviceId,
          employeeId,
          date,
          time,
        },
      ],
      userName: name,
      email,
      phoneNo,
      status: 1,
      totalAmount: 0,
      depositRequired: false,
      userPackageId: selectedPackage._id,
    };
    bookNow(payload).then(() => {
      setSelected(null);
      setSelectedPackage(null);
    });
  };
  return (
    <div className="my-1">
      {selected ? (
        <BaseModal
          open={Boolean(selected)}
          onClose={() => setSelected(null)}
          {...{
            content: renderDateTimeEmployeeSection(),
            contentClassName: "modify-modal-content",
            title: `${name} - ${selected.serviceId.name}`,
            actions: (
              <div className="row mx-0 px-0 justify-content-end">
                <PrimaryButton label={i18n.t("book_now")} onClick={onBookNow} />
              </div>
            ),
          }}
        />
      ) : null}
      <CollapsibleTable
        rows={userPackages}
        childKey="services"
        schema={[
          {
            key: "package",
            accessor: (val) => getNestedValue(val, ["packageId", "name"], ""),
          },
          {
            key: "numberOfSessions",
            accessor: (val) =>
              val.numberOfSessions - val.sessionBooked - val.sessionCompleted,
          },
          {
            key: "expiryDate",
            accessor: (val) =>
              val.expiryDate
                ? moment(val.expiryDate).format("MM-DD-YYYY hh:mm A")
                : "",
          },
        ]}
        childSchema={{
          title: i18n.t("services"),
          columns: [
            {
              key: "name",
              accessor: (val) => getNestedValue(val, ["serviceId", "name"], ""),
            },
            {
              key: "action",
              header: "",
              render: (val, row) => {
                const available =
                  row.numberOfSessions -
                  row.sessionBooked -
                  row.sessionCompleted;
                if (available > 0) {
                  return (
                    <PrimaryButton
                      label={i18n.t("redeem")}
                      onClick={() => {
                        onSelect(val, row);
                      }}
                    />
                  );
                }
              },
            },
          ],
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  userPackages: state.packages.userPackages,
});

const action = { getUserPackagesRequest };

export default connect(mapStateToProps, action)(UserPackages);
