import { connect } from "react-redux";
import React, { Component } from "react";

import { Images } from "../../../theme";
import { DotsLoader } from "../../../components";
import moment from "moment";
// import { adminGetUsers } from '../../../config/simpleApiCalls';
import {
  addCollectionWith,
  getDataByKey,
  saveMsg,
  getMsgs,
} from "../../../config/firebase";

import { ErrorHelper } from "../../../helpers";

import "./styles.css";
import Sytles from "./sytles";

const messages_data = [];

const _limit = 5;
class ChatBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messages_data: [],
      user: null,
    };
    this.messagesEndRef = React.createRef();
    this.messagesStartRef = React.createRef();
  }

  async componentDidMount() {
    const user = await JSON.parse(sessionStorage.getItem("employee"));
    this.setState({ user });
    this.checkRoomFounded(user);
    this.scrollUp();
  }

  componentDidUpdate() {
    this.scrollUp();
  }

  checkRoomFounded = async (user) => {
    const payload = {
      collectionName: "chatRooms",
      key: "userId",
      value: user._id,
    };

    const res = await getDataByKey(payload);
    console.log("ChatBox -> checkRoomFounded -> res", res.size);
    if (!res.size) {
      this.createRoom();
      return;
    }
    res.forEach((doc) => {
      const roomData = { _id: doc.id, ...doc.data() };
      this.setState({ roomData });
      this.getMsg(roomData);
    });
  };

  async componentDidMount() {
    const user = await JSON.parse(sessionStorage.getItem("employee"));
    this.setState({ user });
    this.checkRoomFounded(user);
    this.scrollUp();
  }

  componentDidUpdate() {
    this.scrollUp();
  }

  checkRoomFounded = async (user) => {
    const payload = {
      collectionName: "chatRooms",
      key: "userId",
      value: user._id,
    };

    const res = await getDataByKey(payload);
    console.log("ChatBox -> checkRoomFounded -> res", res.size);
    if (!res.size) {
      this.createRoom();
      return;
    }
    res.forEach((doc) => {
      const roomData = { _id: doc.id, ...doc.data() };
      this.setState({ roomData });
      this.getMsg(roomData);
    });
  };

  createRoom = async () => {
    const { user } = this.state;
    try {
      const payload = {
        collectionName: "chatRooms",
        data: {
          createdAt: Date.now(),
          userData: user,
          userId: user._id,
        },
      };
      await addCollectionWith(payload);
      this.checkRoomFounded(user);
    } catch (error) {
      console.log("ChartBox -> createRoom -> error", error);
    }
  };

  getMsg = async (roomData) => {
    const { user } = this.state;
    if (!user || !roomData) return;

    const payload = {
      collectionName: "messages",
      id: roomData._id,
    };

    try {
      getMsgs(payload).onSnapshot((querySnapshot) => {
        let allMsg = [];
        querySnapshot.forEach(function (doc) {
          allMsg.push(doc.data());
        });
        allMsg = allMsg.sort((a, b) => a.createdAt - b.createdAt);
        this.setState({ messages_data: allMsg });
      });
    } catch (error) {
      console.log("ChartBox -> sendMsg -> error", error);
    }
  };

  sendMsg = async () => {
    const { textMsg, user, roomData, isLoading } = this.state;
    if (!textMsg || !user || !roomData || isLoading) return;
    this.setState({ isLoading: true });
    const payload = {
      collectionName: "messages",
      id: roomData._id,
      data: {
        createdAt: Date.now(),
        type: "text",
        msg: textMsg,
        userId: user._id,
        senderName: user.firstName,
      },
    };
    try {
      this.setState({ isLoading: false, textMsg: "" });
      await saveMsg(payload);
    } catch (error) {
      console.log("ChartBox -> sendMsg -> error", error);
      this.setState({ isLoading: false });
    }
  };

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  onSetSidebarOpen = (open) => {
    this.setState({ sidebarOpen: open });
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  renderSaloonDashboardHeading = () => {
    return (
      <div className="col-12 p-3">
        <h1 className="saloon-dashboard-heading">Online Support Chat</h1>
      </div>
    );
  };

  scrollUp = () => {
    console.log("ChatBox -> scrollUp -> scrollUp");
    this.messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  scrollDown = () => {
    this.messagesStartRef.current.scrollIntoView({ behavior: "smooth" });
  };

  renderSupportMessage = (val, id) => {
    return (
      <div key={id} className="row mb-3 mb-md-4 support-message">
        <div className="col-10 col-md-6">
          <div className="col-12 py-3">
            <div className="row mb-2">
              <div className="col-6">
                <span className="support-user-name">{val.senderName}</span>
              </div>
              <div className="col-6 text-right">
                <span className="support-message-time">
                  {moment(val.createdDate).fromNow()}
                </span>
              </div>
            </div>
            <p className="support-text-message">{val.msg}</p>
          </div>
        </div>
      </div>
    );
  };

  renderUserMessage = (val, id) => {
    return (
      <div key={id} className="row mb-3 mb-md-4 user-message">
        <div className="col-10 col-md-6 ml-auto">
          <div className="col-12 py-3">
            <div className="row mb-2">
              <div className="col-6">
                <span className="support-user-name">{val.senderName}</span>
              </div>
              <div className="col-6 text-right">
                <span className="support-message-time">
                  {moment(val.createdDate).fromNow()}
                </span>
              </div>
            </div>
            <p className="support-text-message">{val.msg}</p>
          </div>
        </div>
      </div>
    );
  };

  renderMessagesContainer = () => {
    const { messages_data, user } = this.state;
    return (
      <div className="col-12 aaaaaaa" id="messages-container">
        <div ref={this.messagesStartRef} />
        {messages_data &&
          !!messages_data.length &&
          messages_data.map((val, id) => {
            console.log(val);
            if (val.userId === user._id) {
              return this.renderUserMessage(val, id);
            } else {
              return this.renderSupportMessage(val, id);
            }
          })}
        <div ref={this.messagesEndRef} />
      </div>
    );
  };

  renderChatBoxCard = () => {
    const { textMsg } = this.state;
    return (
      <div className="col-12">
        <div className="col-12 py-3" id="chat-box-card">
          {this.renderMessagesContainer()}
          <div className="row" id="chat-box-input-cont">
            <div className="col-10 col-md-11 col-lg-11">
              <input
                type="text"
                value={textMsg || ""}
                placeholder="Type a message"
                id="chat-box-input"
                onChange={(e) => this.setState({ textMsg: e.target.value })}
              />
              <img src={Images.saloon_chat_clip} id="clip-icon" />
            </div>
            <div
              onClick={() => this.sendMsg()}
              className="col-2 col-md-1 col-lg-1 d-flex align-items-center pl-0"
            >
              <img src={Images.saloon_chat_send} id="send-icon" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.renderLoader()}
        <div
          className="container-fluid min-vh-100 px-0"
          style={{ backgroundColor: "#F7F7F7" }}
        >
          <div className="content-container py-5 mb-4 mb-md-5">
            <div className="row">
              {this.renderSaloonDashboardHeading()}
              {this.renderChatBoxCard()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(ChatBox);
