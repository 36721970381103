import { connect } from "react-redux";
import React, { Component } from "react";
import i18n from "../../../i18n";
import { DotsLoader } from "../../../components";
import {
  saloonGetCategories,
  saloonCreateService,
  getProduct,
} from "../../../config/simpleApiCalls";
import { getRequest as getForms } from "../../../redux/actions/Saloon/FormBuilder";
import { ErrorHelper, SuccessHelper } from "../../../helpers";

import "./styles.css";
import Swal from "sweetalert2";
import ServiceForm from "../../../components/Forms/Service/ServiceForm.component";
// src\components\Forms\Service\ServiceForm.component.jsx
class AddService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceName: "",
      price: 0,
      subcategory: "",
      categoryList: [],
      categoryName: "",
      categoryId: "",
      isActive: 1,
      imageUrl: "",
      imageFile: "",
      minimumTime: 0,
      shouldSendTextReminders: false,
      textReminderFrequency: 2,
      requireDeposit: false,
      formId: "",

      // ERRORS
      serviceNameError: "",
      minimumTimeError: "",
      priceError: "",
      categoryNameError: "",
      subcategory: "",
      description: "",
    };
  }

  componentDidMount = async () => {
    const saloonData = await JSON.parse(sessionStorage.getItem("saloon"));
    const company = JSON.parse(sessionStorage.getItem("company"));
    if (saloonData) {
      this.setState({
        access_token: saloonData.access_token,
        companyId: company._id,
      });
      this.handleSaloonGetCategories();
      this.handleSaloonGetAllProducts();
      this.props.getForms({
        access_token: saloonData.access_token,
        companyId: company._id,
      });
    }
    const isGym = company.function == "gym";
    this.setState({ isGym, timeSlotLength: company.timeSlotLength });
  };

  handleSaloonGetCategories = () => {
    this.setState({ isloading: true });
    const { access_token } = this.state;
    saloonGetCategories(access_token)
      .then((res) => {
        if (res.data.success) {
          this.setState({
            isloading: false,
            categoryList: res.data.data.map(({ name, _id }) => ({
              name,
              value: _id,
            })),
          });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
        console.log(error, "errorrrrrrrrrrrrrrrrrrr");
      });
  };

  handleSaloonGetAllProducts = () => {
    this.setState({ isloading: true });
    const { access_token, companyId } = this.state;
    getProduct({ companyId }, access_token)
      .then((res) => {
        if (res.data.success) {
          this.setState({
            isloading: false,
            products: res.data.data.map((product) => ({
              text: product.name,
              value: product._id,
              ...product,
            })),
          });
        }
      })
      .catch((error) => {
        console.log(error, "get all services error");
      });
  };

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  handleSaloonCreateService = (data) => {
    const { access_token, isGym } = this.state;
    const {
      name,
      price,
      category,
      status,
      minimumTime,
      imageFile = "",
      shouldSendTextReminders,
      textReminderFrequency,
      subcategory,
      relatedProductIds = [],
      description = "",
      depositAmount,
      requireDeposit,
      depositTarget,
      forms = [],
      hiddenToCustomers,
      hidePrice,
    } = data;
    const sendRequst = () => {
      this.setState({ isloading: true });
      let payloadFormData = new FormData();
      payloadFormData.append("name", name);
      if (!isGym) {
        payloadFormData.append(
          "shouldSendTextReminders",
          shouldSendTextReminders
        );
        payloadFormData.append("textReminderFrequency", textReminderFrequency);
      }
      payloadFormData.append("serviceId", category);
      payloadFormData.append("isActive", status);
      payloadFormData.append("image", imageFile);
      payloadFormData.append("price", price);
      payloadFormData.append("subcategory", subcategory);
      payloadFormData.append(
        "relatedProductIds",
        relatedProductIds.map((product) => product.value).join(",")
      );
      payloadFormData.append(
        "selectedFormIds",
        forms.map((form) => form._id).join(",")
      );
      payloadFormData.append("duration", minimumTime);
      payloadFormData.append("description", description);
      if (depositAmount) {
        payloadFormData.append("depositAmount", depositAmount);
      }
      payloadFormData.append("requireDeposit", requireDeposit);

      payloadFormData.append("depositTarget", depositTarget);
      payloadFormData.append("hiddenToCustomers", hiddenToCustomers);
      payloadFormData.append("hidePrice", hidePrice);

      saloonCreateService(payloadFormData, access_token)
        .then((res) => {
          if (res.data.success) {
            SuccessHelper.handleSuccess(
              "Your service is successfully added.",
              true
            );
            this.setState(
              {
                imageUrl: "",
                imageFile: "",
                isloading: false,
                serviceName: "",
                price: "",
                subcategory: "",
                formId: "",
                categoryName: "",
                categoryId: "",
                isActive: 1,
                shouldSendTextReminders: false,
                textReminderFrequency: 2,
                description: "",
                resetForm: true,
              },
              () => {
                this.setState({ resetForm: false });
              }
            );
          }
          if (!res.data.success) {
            ErrorHelper.handleErrors(res.data.msg, true);
            this.setState({ isloading: false });
          }
        })
        .catch((error) => {
          console.log(error, "errorrrrrrrrrrrrrrrrrrr");
          this.setState({ isloading: false });
        });
    };
    const productsThatDontAllowBackorder = relatedProductIds.filter(
      (product) => !product.allowBackorder
    );
    if (productsThatDontAllowBackorder.length) {
      Swal.fire({
        icon: "warning",
        text: i18n.t("product_dont_allow_backorder_warning", {
          products: productsThatDontAllowBackorder
            .map((product) => product.text)
            .join(", "),
        }),
        showCancelButton: true,
        cancelButtonText: i18n.t("no"),
        confirmButtonText: i18n.t("yes"),
      }).then((e) => {
        const { dismiss, isConfirmed, isDismissed } = e;
        if (!isDismissed || dismiss != "backdrop") {
          if (isConfirmed) {
            sendRequst();
          }
        }
      });
    } else {
      sendRequst();
    }
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  renderSaloonDashboardHeading = () => {
    return (
      <div className="col-12 p-3">
        <h1 className="saloon-dashboard-heading">
          {i18n.t("container.add_service")}:
        </h1>
      </div>
    );
  };

  render() {
    const { products, isGym, categoryList, resetForm } = this.state;
    const { forms } = this.props;
    return (
      <div>
        {this.renderLoader()}
        <div className="container">
          <div className="row">
            {this.renderSaloonDashboardHeading()}
            {/* {this.renderAddServiceForm()} */}
            <div className="m-2 p-2 bg-white">
              {!resetForm && (
                <ServiceForm
                  defaultValues={{}}
                  products={products}
                  formOptions={forms}
                  categories={categoryList}
                  isGym={isGym}
                  onSubmit={this.handleSaloonCreateService}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  forms: state.formBuilder.data,
});

const action = { getForms };

export default connect(mapStateToProps, action)(AddService);
