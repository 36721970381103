import React, { Component } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Swal from "sweetalert2";
import _ from "lodash";

import { getAllBookingsByUser } from "../../../config/simpleApiCalls";
import i18n from "../../../i18n";

import "../styles/bookingHistory.sass";
import DialogBox from "./DialogBox.component";
class BookingHistory extends Component {
  state = {
    bookingHistory: [],
    showPopUp: false,
    selectBooking: null,
  };
  async componentDidMount() {
    const { token } = this.props;
    try {
      const bookings = await getAllBookingsByUser(token);
      this.setState({ bookingHistory: bookings.data.data });
    } catch {
      alert("Something went wronge ;(");
    }
  }

  loadSaloonTableCell = (booking) => {
    return booking.companyId ? booking.companyId.name : "";
  };
  loadEmployessTableCell = (booking) => {
    return booking?.services["0"]?.employeeId?.userId?.userName || "";
  };

  loadPriceTableCell = (booking) => {
    return booking.totalAmount || 0;
  };
  loadStatusTableCell = (booking) => {
    if (booking.status) {
      switch (booking.status) {
        case 1:
          return "Pending";
        case 2:
          return "Now serving";
        case 3:
          return "Cancelled";
        case 4:
          return "Done";
        default:
          return "";
      }
    } else {
      return "";
    }
  };

  loadMoreinfoTableCell = (booking) => {
    return (
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          this.setState({
            showPopUp: true,
            selectBooking: booking,
          });
        }}
      >
        Details
      </Button>
    );
  };
  closePopUp = () => {
    this.setState({ showPopUp: false });
  };

  loadCancelService = (booking) => {
    return (
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          Swal.fire({
            title: "Are you sure you want to cancel?",
            text: "You cannot undo this",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#ff3600",
            cancelButtonColor: "#354e68",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
          }).then((result) => {
            if (result.value) {
              this.props.handleUpdate(booking);
              if (this.props.updateBookings) {
                this.getBookingHistory();
              }
            }
          });
        }}
      >
        cancel Booking
      </Button>
    );
  };

  getBookingHistory = async () => {
    try {
      const respose = await getAllBookingsByUser(this.props.token);
      this.setState({ bookingHistory: respose.data.data });
    } catch (error) {
      alert("Something went wronge ;(");
    }
  };

  render() {
    const { bookingHistory, showPopUp, selectBooking } = this.state;
    // bookingHistory && console.log("bookingHistory", bookingHistory);

    let orderedBookings = _.orderBy(bookingHistory, ["updatedDate"], ["desc"]);
    // console.log({ orderedBookings });
    return (
      <div className="bookingHistory">
        <h2>Booking History</h2>

        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="booking history table">
            <TableHead>
              <TableRow>
                <TableCell className="tableHead">
                  {i18n.t("container.profile_salon")}
                </TableCell>
                <TableCell className="tableHead">
                  {i18n.t("container.employee")}
                </TableCell>
                <TableCell className="tableHead">
                  {i18n.t("container.profile_price")}
                </TableCell>
                <TableCell className="tableHead">
                  {i18n.t("container.profile_status")}
                </TableCell>
                <TableCell className="tableHead">
                  {i18n.t("container.profile_more_info")}
                </TableCell>
                <TableCell className="tableHead">
                  {i18n.t("container.profile_more_info")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!orderedBookings.length ? (
                <h1>Loading Bookings...</h1>
              ) : (
                orderedBookings.map((booking) => {
                  return (
                    <TableRow key={`booking-${booking._id}`}>
                      <TableCell>{this.loadSaloonTableCell(booking)}</TableCell>
                      <TableCell>
                        {this.loadEmployessTableCell(booking)}
                      </TableCell>
                      <TableCell>{this.loadPriceTableCell(booking)}</TableCell>
                      <TableCell>{this.loadStatusTableCell(booking)}</TableCell>
                      <TableCell>
                        {this.loadMoreinfoTableCell(booking)}
                      </TableCell>
                      <TableCell>
                        {booking.status === 1
                          ? this.loadCancelService(booking)
                          : ""}
                      </TableCell>

                      {/* <TableCell>{this.loadStatusTableCell(booking)}</TableCell> */}
                    </TableRow>
                  );
                })
              )}
            </TableBody>
            <DialogBox
              open={showPopUp}
              selectBooking={selectBooking}
              onClose={this.closePopUp}
            />
            {/* <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.calories}</TableCell>
              <TableCell align="right">{row.fat}</TableCell>
              <TableCell align="right">{row.carbs}</TableCell>
              <TableCell align="right">{row.protein}</TableCell>
            </TableRow>
          ))}
        </TableBody> */}
          </Table>
        </TableContainer>
      </div>
    );
  }
}

export default BookingHistory;
