// @flow
import Immutable from "seamless-immutable";
import * as types from "../actions/ActionTypes";

const initialState = Immutable({
    data: null
});

export default (state: Object = initialState, action: Object) => {
    switch (action.type) {
        case types.SET_ACTIVE_BOOKING:
            return Immutable.merge(state, {
                data: action.data
            });
        case types.CLEAR_ACTIVE_BOOKING:
            return Immutable.merge(state, {
                data: null
            });
        case types.LOGOUT:
            return initialState;
        case types.CLEAR_LOGOUT:
            return initialState;
        default:
            return state;
    }
};
