// @flow

import { FORM_BUILDER } from "../ActionTypes";

export function getRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: FORM_BUILDER.GET_SAGA,
  };
}

export function set(data) {
  return {
    data,
    type: FORM_BUILDER.GET,
  };
}
export function getByIdRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: FORM_BUILDER.GET_BY_ID_SAGA,
  };
}

export function getById(data) {
  return {
    data,
    type: FORM_BUILDER.GET_BY_ID,
  };
}

export function removeRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: FORM_BUILDER.DELETE_SAGA,
  };
}

export function remove(data) {
  return {
    data,
    type: FORM_BUILDER.DELETE,
  };
}

export function insertRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: FORM_BUILDER.INSERT_SAGA,
  };
}

export function insert(data) {
  return {
    data,
    type: FORM_BUILDER.INSERT,
  };
}

export function updateRequest(payload, params, callbackMap) {
  return {
    payload,
    params,
    callbackMap,
    type: FORM_BUILDER.UPDATE_SAGA,
  };
}

export function update(data) {
  return {
    data,
    type: FORM_BUILDER.UPDATE,
  };
}

export function removeQuestionsRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: FORM_BUILDER.DELETE_QUESTION_SAGA,
  };
}

export function removeQuestions(data) {
  return {
    data,
    type: FORM_BUILDER.DELETE_QUESTION,
  };
}

export function insertQuestionsRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: FORM_BUILDER.INSERT_QUESTION_SAGA,
  };
}

export function insertQuestions(data) {
  return {
    data,
    type: FORM_BUILDER.INSERT_QUESTION,
  };
}

export function updateQuestionsRequest(payload, params, callbackMap) {
  return {
    payload,
    params,
    callbackMap,
    type: FORM_BUILDER.UPDATE_QUESTION_SAGA,
  };
}

export function updateQuestions(data) {
  return {
    data,
    type: FORM_BUILDER.UPDATE_QUESTION,
  };
}

export function requestFailure() {
  return {
    type: FORM_BUILDER.REQUEST_FAILURE,
  };
}
