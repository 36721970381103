import { connect } from "react-redux";
import React, { Component } from "react";

import i18n from "../../../i18n";
import { Translation, withTranslation } from "react-i18next";

import { Images } from "../../../theme";
import { request as employee_logout } from "../../../redux/actions/Employee/LogOut";
import { clearLogout as clear_logout } from "../../../redux/actions/LogOut";
import { SuccessHelper } from "../../../helpers";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {
  ExpandLess,
  ExpandMore,
  Send as SendIcon,
  DashboardOutlined,
  AccountCircleOutlined,
  ChatBubbleOutlineOutlined,
  PeopleAltOutlined,
  EventOutlined,
  HistoryOutlined,
  BusinessCenterOutlined,
  AccessTimeOutlined,
  PermMediaOutlined,
  LinkOutlined,
  StarBorderOutlined,
  AccountBalanceWalletOutlined,
  ExitToAppOutlined,
  DateRangeOutlined,
  SendOutlined,
} from "@material-ui/icons";
import Collapse from "@material-ui/core/Collapse";

import "./styles.scss";

const routeParentMap = {
  "/employee-my-appointment": "appointments",
  "/employee-booking": "appointments",
};

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // user: null,
      routeName: "",
      showDropdown: "",
    };
  }

  async componentDidMount() {
    const employeeData = await JSON.parse(sessionStorage.getItem("employee"));
    const hasSaloonAccess =
      sessionStorage.getItem("saloon") &&
      !!Object.keys(JSON.parse(sessionStorage.getItem("saloon"))).length;
    const { access_token } = employeeData;

    let routeName = "";
    if (
      this.props.history.location.pathname.split("/")[1].split("-")[0] ===
      "employee"
    ) {
      routeName = this.props.history.location.pathname;
    }
    this.setState({
      hasSaloonAccess,
      routeName,
      openDropdown: routeParentMap[routeName],
      access_token,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.employeeLogout) {
      if (
        !nextProps.employeeLogout.failure &&
        !nextProps.employeeLogout.isFetching &&
        !nextProps.employeeLogout.errorMessage &&
        nextProps.employeeLogout.data &&
        nextProps.employeeLogout.data.success
      ) {
        nextProps.clear_logout();
        SuccessHelper.handleSuccess("You have successfully logged out.", true);
        this.handleNavigation("/employee-login");
      } else if (
        nextProps.employeeLogout.failure &&
        !nextProps.employeeLogout.isFetching &&
        nextProps.employeeLogout.errorMessage
      ) {
        this.setState({ isloading: false });
      }
    }
  }

  handleNavigation = (routeName) => {
    this.setState({ routeName, openDropdown: routeParentMap[routeName] });
    this.props.history.push(routeName);
  };

  handleLogout = () => {
    const { access_token } = this.state;
    this.props.employee_logout({ access_token });
    SuccessHelper.handleSuccess("You have successfully logged out.", true);
    this.props.history.push("/");
  };

  renderLogout = () => {
    // const { routeName } = this.state;
    return (
      <ListItem
        button
        className={`side-bar-item`}
        onClick={() => this.handleLogout()}
      >
        <ListItemIcon>
          <ExitToAppOutlined />
        </ListItemIcon>
        <ListItemText primary={i18n.t("logout")} />
      </ListItem>
    );
  };

  renderListItem = (route, Icon = SendIcon, title, className) => {
    const { routeName } = this.state;
    return (
      <ListItem
        button
        className={`side-bar-item ${className} ${
          routeName == route ? "active" : ""
        }`}
        onClick={() => this.handleNavigation(route)}
      >
        <ListItemIcon>{Icon && <Icon />}</ListItemIcon>
        <ListItemText primary={title} />
      </ListItem>
    );
  };

  handleClick = (openDropdown) => {
    this.setState({ openDropdown });
  };

  renderDropdownListItem = (key, Icon = SendIcon, title, children) => {
    const { openDropdown } = this.state;
    return (
      <>
        <ListItem
          button
          onClick={() => this.handleClick(openDropdown == key ? "" : key)}
          className={`side-bar-item ${openDropdown == key ? "active" : ""}`}
        >
          <ListItemIcon>
            <Icon />
          </ListItemIcon>
          <ListItemText primary={title} />
          {openDropdown == key ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openDropdown == key} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children &&
              children.map(({ route, icon, title }, ind) => {
                return this.renderListItem(route, icon, title, "nested");
              })}
          </List>
        </Collapse>
      </>
    );
  };

  render() {
    const { hasSaloonAccess } = this.state;
    return (
      <div className="col-12 px-0 min-vh-100">
        <div
          className="text-center py-1"
          style={{ zIndex: 3, borderBottom: "1px solid rgba(0, 0, 0, .15)" }}
        >
          <img src={Images.easy1_logo_800x300} style={{ width: 94 }} />
        </div>
        <div className="">
          <ul className="sidebar-list">
            {this.renderListItem(
              "/employee-dashboard",
              DashboardOutlined,
              <Translation>{(t) => t("container.dashboard")}</Translation>
            )}
            {this.renderListItem(
              "/employee-edit-profile",
              AccountCircleOutlined,
              <Translation>{(t) => t("container.profile")}</Translation>
            )}
            {this.renderListItem(
              "/employee-chatbox",
              ChatBubbleOutlineOutlined,
              <Translation>{(t) => t("container.chat_box")}</Translation>
            )}
            {this.renderDropdownListItem(
              "appointments",
              DateRangeOutlined,
              i18n.t("container.my_appointments"),
              [
                {
                  title: i18n.t("container.my_calendar"),
                  icon: EventOutlined,
                  route: "/employee-my-appointment",
                },
                {
                  title: i18n.t("container.profile_booking_history"),
                  icon: HistoryOutlined,
                  route: "/employee-booking",
                },
              ]
            )}
            {this.renderListItem(
              "/employee-add-working-hour",
              AccessTimeOutlined,
              <Translation>{(t) => t("container.working_hours")}</Translation>
            )}
            {this.renderListItem(
              "/employee-rating",
              StarBorderOutlined,
              <Translation>{(t) => t("container.rating")}</Translation>
            )}
            {this.renderListItem(
              "/employee-active-saloon",
              BusinessCenterOutlined,
              <Translation>{(t) => t("container.active_salon")}</Translation>
            )}
            {this.renderListItem(
              "/employee-customers",
              PeopleAltOutlined,
              "Customers"
            )}
            {this.renderListItem(
              "/employee-social-media",
              LinkOutlined,
              <Translation>
                {(t) => t("container.dashboard_social_media_icons")}
              </Translation>
            )}
            {this.renderListItem(
              "/employee-upload-media",
              PermMediaOutlined,
              <Translation>{(t) => t("container.upload_media")}</Translation>
            )}
            {this.renderListItem(
              "/employee-wallet",
              AccountBalanceWalletOutlined,
              <Translation>{(t) => t("container.wallet")}</Translation>
            )}
            {this.renderListItem(
              "/employee-request-funds",
              SendOutlined,
              <Translation>{(t) => t("container.request_funds")}</Translation>
            )}
            {this.renderReceptionistMenu()}
            {hasSaloonAccess
              ? this.renderListItem(
                  "/saloon-dashboard",
                  PeopleAltOutlined,
                  "Switch To Saloon"
                )
              : null}
            {this.renderLogout()}
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  employeeLogout: state.employeeLogout,
});

const action = {
  employee_logout,
  clear_logout,
};

export default connect(mapStateToProps, action)(SidebarContent);
