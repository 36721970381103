import React, { createRef, useEffect, useRef, useState } from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import i18n from "../../../i18n";

export default function CustomTextInput({
  label,
  key,
  defaultValue,
  value: parentValue,
  onChange,
}) {
  const onSuneditorChange = (value) => {
    var regex = /(<([^>]+)>)/gi;
    var whitespaceRegex =
      /&nbsp;|\u2028|\u2029|\u200Bb|\u200Cc|\u200Dd|\uFEFFe/gi;
    let hasText = !!value.replaceAll(regex, "").replaceAll(whitespaceRegex, "")
      .length;
    console.log(hasText);
    value = hasText ? value : "";
    onChange(value);
  };
  return (
    <div className="py-2" key={key}>
      {i18n.t(label)}
      <SunEditor
        defaultValue={parentValue}
        id={key}
        onChange={(e) => {
          onSuneditorChange(e);
        }}
        autoFocus={false}
      />
    </div>
  );
}
