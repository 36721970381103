import React from "react";
import TextField from "@material-ui/core/TextField";

export default ({
  field,
  label,
  defaultValue,
  onChange = () => {},
  onBlur = () => {},
  props = {},
  key,
  error,
  helperText,
  type = "text",
}) => {
  return (
    <TextField
      {...props}
      key={key}
      defaultValue={defaultValue}
      key={`edit-input-${field}`}
      margin="dense"
      label={label}
      type={type}
      fullWidth
      variant="standard"
      onChange={(e) => onChange(e, field)}
      onBlur={(e) => onBlur(e, field)}
      error={!!error}
      helperText={error || helperText}
    />
  );
};
