import { put, takeEvery } from "redux-saga/effects";

import * as types from "../../actions/ActionTypes";
import {
  upsert,
  get,
  remove,
  requestFailure,
  setGrade,
  setFinalized,
} from "../../actions/Saloon/Student";

import axios from "axios";
import { student } from "../../../config/WebServices";

function* getSaga(action) {
  const { payload, callbackMap = {} } = action;
  let data;
  try {
    const token = payload.access_token;
    delete payload.access_token;
    data = yield axios.get(student, {
      params: payload,
      headers: {
        Authorization: token,
      },
    });
    data = data.data;

    if (data.success) {
      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data);
      }
      yield put(get(data.data));
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data);
      }
      yield put(requestFailure(data));
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data);
    }
    yield put(requestFailure(data));
  }
}

function* upsertSaga(action) {
  const { payload, callbackMap = {} } = action;
  let data;
  try {
    const token = payload.access_token;
    delete payload.access_token;
    data = yield axios.post(student, payload, {
      headers: {
        Authorization: token,
      },
    });
    data = data.data;
    if (data.success) {
      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data);
      }
      yield put(upsert(data.data));
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data);
      }
      yield put(requestFailure(data));
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data);
    }
    yield put(requestFailure(data));
  }
}

function* removeSaga(action) {
  const { payload, callbackMap = {} } = action;
  let data;
  try {
    const token = payload.access_token;
    delete payload.access_token;
    data = yield axios.delete(student, {
      params: payload,
      headers: {
        Authorization: token,
      },
    });
    data = data.data;
    if (data.success) {
      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data);
      }
      yield put(remove(data.data));
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data);
      }
      yield put(requestFailure(data));
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data);
    }
    yield put(requestFailure(data));
  }
}

function* setGradeSaga(action) {
  const { payload, callbackMap = {} } = action;
  let data;
  try {
    const token = payload.access_token;
    delete payload.access_token;
    data = yield axios.post(`${student}/setGrades`, payload, {
      headers: {
        Authorization: token,
      },
    });
    data = data.data;
    if (data.success) {
      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data);
      }
      yield put(setGrade(data.data));
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data);
      }
      yield put(requestFailure(data));
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data);
    }
    yield put(requestFailure(data));
  }
}

function* setFinalizedSaga(action) {
  const { payload, callbackMap = {} } = action;
  let data;
  try {
    const token = payload.access_token;
    delete payload.access_token;
    data = yield axios.post(`${student}/setFinalized`, payload, {
      headers: {
        Authorization: token,
      },
    });
    data = data.data;
    if (data.success) {
      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data);
      }
      yield put(setFinalized(data.data));
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data);
      }
      yield put(requestFailure(data));
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data);
    }
    yield put(requestFailure(data));
  }
}

export default function* root() {
  yield takeEvery(types.STUDENT.GET_SAGA, getSaga);
  yield takeEvery(types.STUDENT.UPSERT_SAGA, upsertSaga);
  yield takeEvery(types.STUDENT.DELETE_SAGA, removeSaga);
  yield takeEvery(types.STUDENT.SET_GRADE_SAGA, setGradeSaga);
  yield takeEvery(types.STUDENT.SET_FINALIZED_SAGA, setFinalizedSaga);
}
