import { put, takeEvery } from "redux-saga/effects";

import * as types from "../actions/ActionTypes";
import { clear, requestFailure } from "../actions/ValidateBookingTransfer";

import axios from "axios";
import { validate_booking_reference_code } from "../../config/WebServices";

function* validateSaga(action) {
  const { payload, callbackMap = {} } = action;
  let data;
  try {
    data = yield axios.post(validate_booking_reference_code, payload);
    data = data.data;
    if (data.success) {
      yield put(clear());

      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data);
      }
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data);
      }
      yield put(requestFailure(data));
    }
  } catch (err) {
    console.log(err);
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data);
    }
    yield put(requestFailure(data));
  }
}

export default function* root() {
  yield takeEvery(types.BOOKING_REF_CODE.VALIDATE_SAGA, validateSaga);
}
