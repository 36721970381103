import React, { Component, Fragment } from "react";

import { Translation } from "react-i18next";
import i18n from "../../../i18n";
import { Images } from "../../../theme";
import { RiEditFill } from "react-icons/ri";
import { DotsLoader } from "../../../components";
import {
  getSaloonTemplateData,
  getCompanyPorfolio,
  getEmployeeByServiceCompany,
  createBooking,
  createUpdateTemplate,
  getTemplateData,
} from "../../../config/simpleApiCalls";
import ReactStars from "react-rating-stars-component";

import SideNav from "../Template2/SideNav";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import Modal from "react-bootstrap/Modal";
import Lightbox from "react-lightbox-component";
import { SuccessHelper, ErrorHelper } from "../../../helpers";
import { baseUrl } from "../../../config/WebServices";

import "./styles.css";

class Template3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      routeName: "",
      showSidebar: false,
      showDropdownMenu: false,
      companyId: "",
      access_token: "",
      portfolioImages: [],
      portfolioVideos: [],
      employeeTimings: [],
      selectedCategoryId: "",
      selecteedEmployeeId: "",
      selectedValue: "",
      selectedServiceId: "",
      selectedTime: "",
      selectedPrice: "",
      jumbotronImgFile: null,
      jumbotronImgUrl: null,
      aboutImgUrl: null,
      aboutImgFile: null,
      aboutFirstHeading: "GET YOUR STYLE",
      aboutSecondHeading: "YOU DESERVE",
      jumbotronFirstHeading: "GET YOUR STYLE",
      jumbotronSecHeading: "YOU DESERVE",
      isEditTemplate: false,
      serviceImgUrl: null,
      serviceImgFile: null,

      companyList: [],
      companyPortfolio: [],
      employeeData: [],
      checkInTime: "",
      isloading: false,
      employeData: [],
    };

    this.services = React.createRef();
    this.aboutUs = React.createRef();
    this.pricing = React.createRef();
    this.appointment = React.createRef();
    this.term = React.createRef();
    this.footer = React.createRef();
    this.home = React.createRef();
  }

  componentDidMount = async () => {
    const {
      location: { pathname },
    } = this.props;
    const pathPieces = pathname.split("/");
    let companyId = pathPieces[2];
    if (!companyId) {
    }
    if (pathname === "/saloon-edit-template-3") {
      this.setState({ isEditTemplate: true });
      const { _id } = JSON.parse(sessionStorage.getItem("company"));
      companyId = _id;
    }

    const user = await JSON.parse(sessionStorage.getItem("user"));
    this.setState({
      companyId,
      access_token: user?.access_token,
      user,
    });
    if (companyId) {
      this.getAllData();
    } else {
      this.getAllTemplateData();
    }

    this.getPortfolioData();
  };

  getAllTemplateData = () => {
    let token = JSON.parse(sessionStorage.getItem("saloon"));
    const { state } = this.props.location;

    getTemplateData(token.access_token, state)
      .then((res) => {
        this.setState({ isloading: true });
        if (res.data.success) {
          this.setState({ isloading: false });

          if (res.data.data != null) {
            this.setState({
              jumbotronFirstHeading: res.data.data.headerFirstHeading,
              jumbotronSecHeading: res.data.data.headerSecondHeading,
              jumbotronImgFile: res.data.data.headerImage,
              jumbotronImgUrl: res.data.data.headerImage,
              aboutFirstHeading: res.data.data.aboutFirstHeading,
              aboutSecondHeading: res.data.data.aboutSecondHeading,
              aboutImgFile: res.data.data.aboutFirstImg,
              aboutImgUrl: res.data.data.aboutFirstImg,
              serviceImgUrl: res.data.data.footerImage,
              serviceImgFile: res.data.data.footerImage,
              isloading: false,
            });
          }
        }
      })
      .catch((err) => {
        this.setState({ isloading: false });
      });
  };

  getAllData = () => {
    this.setState({
      isloading: true,
    });
    const { companyId } = this.state;
    const payload = { companyId };
    console.log(payload, "pAYLoAd");
    getSaloonTemplateData(payload)
      .then((res) => {
        console.log("payload:www ", res.data.data[0].customTempalte);
        if (res.data.success) {
          if (res.data.data[0] != null) {
            this.setState({ companyList: res.data.data });
            this.setState({
              companyList: res.data.data,
              employeData: res.data.data[0],
              jumbotronFirstHeading:
                res.data.data[0].customTempalte.headerFirstHeading,
              jumbotronSecHeading:
                res.data.data[0].customTempalte.headerSecondHeading,
              jumbotronImgFile: res.data.data[0].customTempalte.headerImage,
              jumbotronImgUrl: res.data.data[0].customTempalte.headerImage,
              aboutFirstHeading:
                res.data.data[0].customTempalte.aboutFirstHeading,
              aboutSecHeading: res.data.data[0].customTempalte.aboutSecondImg,
              aboutImgFile: res.data.data[0].customTempalte.aboutFirstImg,
              aboutImgUrl: res.data.data[0].customTempalte.aboutFirstImg,
              serviceImgUrl: res.data.data[0].customTempalte.footerImage,
              serviceImgFile: res.data.data[0].customTempalte.footerImage,
              isloading: false,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error, "errorrrrrrrrrrrrrrrrrrr");
      });
  };

  getPortfolioData = () => {
    this.setState({ isloading: true });
    const { companyId } = this.state;
    const payload = { companyId };
    getCompanyPorfolio(payload)
      .then((res) => {
        if (res.data.success) {
          // this.setState({ isloading: false, companyPortfolio: res.data.data });
          let portfolioImages = [];
          let portfolioVideos = [];
          res.data.data.map((val) => {
            if (val.post && val.post.length > 0) {
              val.post.map((val2, ind2) => {
                if (val2.images.length > 0) {
                  portfolioImages.push(val2.images[0]);
                } else if (val2.videos.length > 0) {
                  portfolioVideos.push(val2.videos[0]);
                }
              });
            }
          });
          this.setState({ isloading: false, portfolioImages, portfolioVideos });
        }
      })
      .catch((error) => {
        console.log(error, "errorrrrrrrrrrrrrrrrrrr");
      });
  };

  handleDropdownChange = (e) => {
    this.setState({
      selectedCategoryId: e.target.value,
    });
    this.state.companyList.map(
      (val) =>
        val.services &&
        val.services.map((serviceVal) => {
          console.log(
            "Template3 -> handleDropdownChange -> serviceVal",
            serviceVal
          );
          if (e.target.value === serviceVal._id) {
            this.setState({
              selectedServiceId: serviceVal.serviceId,
              selectedPrice: serviceVal.price,
            });
          }
        })
    );
    this.getEmployeeData(e.target.value);
  };

  handlePublishBtn = () => {
    const {
      jumbotronImgFile,
      jumbotronFirstHeading,
      jumbotronSecHeading,
      aboutFirstHeading,
      aboutSecondHeading,
      serviceImgFile,
      aboutImgFile,
    } = this.state;
    const { state } = this.props.location;
    this.setState({ isloading: true });

    let payloadFormdata = new FormData();
    payloadFormdata.append("headerImage", jumbotronImgFile);
    payloadFormdata.append("headerFirstHeading", jumbotronFirstHeading);
    payloadFormdata.append("headerSecondHeading", jumbotronSecHeading);
    payloadFormdata.append("sinceText", "");
    payloadFormdata.append("aboutFirstHeading", aboutFirstHeading);
    payloadFormdata.append("aboutSecondHeading", aboutSecondHeading);
    payloadFormdata.append("aboutFirstImg", aboutImgFile);
    payloadFormdata.append("aboutSecondImg", "");
    payloadFormdata.append("footerImage", serviceImgFile);
    payloadFormdata.append("templateID", state);

    let token = JSON.parse(sessionStorage.getItem("saloon"));
    createUpdateTemplate(payloadFormdata, token.access_token).then((res) => {
      if (res.data.success) {
        this.setState({ isloading: false });

        SuccessHelper.handleSuccess("Published successfully", true);
      } else {
        this.setState({ isloading: false });

        ErrorHelper.handleErrors("Failed to publish", true);
      }
    });

    // for (var key of payloadFormdata.entries()) {
    //   console.log(key[0] + ', ' + key[1]);
    // }
  };

  renderEditButtons = (onPresEdit, onPressRemove) => {
    return (
      <span className="templateEditBtnDiv">
        <span onClick={onPresEdit} className="templateEditBtns">
          <RiEditFill color="#fff" size={17} />
        </span>
        <span onClick={onPressRemove} className="templateEditBtns">
          &times;
        </span>
      </span>
    );
  };

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  renderEditTemplateHeader = () => (
    <div className="editTemplateHeaderDiv">
      <span
        onClick={() => this.handlePublishBtn()}
        className="editTemplateHeaderBtn"
      >
        Publish
      </span>
      <span
        onClick={() => this.handleNavigation("/saloon-choose-a-template")}
        className="editTemplateHeaderBtn"
      >
        Cancel
      </span>
    </div>
  );

  renderEditTextInputs = (value, onChange, classes) => (
    <input
      type="text"
      value={value}
      onChange={(text) => onChange(text)}
      className={classes}
    />
  );

  handleEmployeeDropDown = (e) => {
    this.setState({
      selecteedEmployeeId: e.target.value,
    });
    this.getEmployeeTimings(e.target.value);
  };

  handleCheckbox = (e) => {
    this.setState({
      selectedTime: e.target.value,
    });
  };

  getEmployeeData = (serviceId) => {
    this.setState({
      isloading: true,
    });
    const { companyId, selectedValue } = this.state;
    const payload = { companyId, serviceId };
    getEmployeeByServiceCompany(payload)
      .then((res) => {
        if (res.data.success) {
          this.setState({ isloading: false, employeeData: res.data.data });
        }
        // this.state.employeeData.map(val => {
        //     console.log("ValueEmployee", val)
        // })
      })
      .catch((error) => {
        console.log(error, "errorrrrrrrrrrrrrrrrrrr");
      });
  };

  getEmployeeTimings = (employeeId) => {
    const { employeeData, employeeTimings } = this.state;
    employeeData.map((val) => {
      if (employeeId == val.employeeId.userId._id) {
        this.setState({
          employeeTimings: val.weekPlans,
        });
      }
    });
  };

  // getCategoryId = (serviceId) => {
  //     const {employeeData, employeeTimings} = this.state
  //     companyData.map(val => {
  //         if(employeeId == val.employeeId.userId._id){
  //             this.setState({
  //                 employeeTimings : val.weekPlans
  //             })
  //         }
  //     })
  // }

  goToAppointmentForm = async () => {
    // const { user } = this.state;
    // const {
    //   location: { pathname },
    //   history: { push },
    // } = this.props;
    // push(`${pathname}/create-appointment`);
    const { companyId } = this.state;
    const user = await JSON.parse(sessionStorage.getItem("user"));
    window.location.assign(
      baseUrl +
        `booking/getForm?id=${companyId}&phoneNo=${
          user ? user.phoneNo : ""
        }&name=${user ? user.userName : ""}`
    );
  };
  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  handleView = (key) => {
    this[key].current.scrollIntoView({ behavior: "smooth" });
  };

  aboutImgHandler = (event) => {
    const imageUrlRegex = /([a-zA-Z0-9\s_\\.\-:])+(.png|.jpeg|.jpg|.gif)$/gm;
    // const imageUrlRegex = (/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i)

    if (event.target.files[0]) {
      let isValidateType = event.target.files[0].type;
      if (isValidateType.match(imageUrlRegex)) {
        const file = URL.createObjectURL(event.target.files[0]);
        this.setState({
          aboutImgUrl: file,
          aboutImgFile: event.target.files[0],
        });
      } else {
        ErrorHelper.handleErrors("Image format not supported", true);
      }
    }
  };

  renderLargeNavbar = () => {
    const {
      location: { pathname },
    } = this.props;

    const { user, companyList } = this.state;

    let CompanyLogo = companyList[0]?.saloons[0]?.companyLogo;
    return (
      <div
        id="large-navbar"
        style={{ backgroundColor: "#141616", opacity: 0.8 }}
        className="d-flex align-items-center justify-content-between"
      >
        <img
          style={{
            height: "80px",
            marginLeft: "50px",
            marginTop: "10px",
            padding: "1rem",
          }}
          alt="easy1 logo 800x300"
          src={CompanyLogo}
          onClick={() => this.handleNavigation("/")}
          className="logo cursor-pointer"
        />
        <span className="d-flex align-items">
          <div class="nav-white">
            <span
              onClick={() => this.handleView("home")}
              className="navbar-link active"
            >
              <a>Home</a>
            </span>
            <span
              onClick={() => this.handleView("services")}
              className="navbar-link"
            >
              <a>Services</a>
            </span>
            <span
              onClick={() => this.handleView("aboutUs")}
              className="navbar-link"
            >
              <a>About Us</a>
            </span>
            <span
              onClick={() => this.handleView("term")}
              className="navbar-link"
            >
              <a>Team</a>
            </span>
            <span
              onClick={() => this.handleView("pricing")}
              className="navbar-link"
            >
              <a>Pricing</a>
            </span>
            <span
              onClick={() => this.handleView("appointment")}
              className="navbar-link"
            >
              <a>Appointment</a>
            </span>
            <span
              onClick={() => this.handleView("footer")}
              className="navbar-link"
            >
              <a>Contact</a>
            </span>
          </div>
        </span>
      </div>
    );
  };

  renderSmallNavbar = () => {
    const { showSidebar } = this.state;
    let data = {
      services: this.services,
      aboutUs: this.aboutUs,
      pricing: this.pricing,
      appointment: this.appointment,
      term: this.term,
      footer: this.footer,
      home: this.home,
    };
    return (
      <div id="small-navbar" style={{ backgroundColor: "#222227" }}>
        <div className="py-2 d-flex align-items-center justify-content-between">
          <img
            style={{ width: "100px", marginLeft: "10px" }}
            alt="easy1 logo 800x300"
            src={Images.saloon_template2_nav}
            className="logo cursor-pointer"
            onClick={() => this.handleNavigation("/")}
          />
          <span
            className="d-flex align-items-center"
            onClick={() =>
              this.setState({ showSidebar: showSidebar ? false : true })
            }
          >
            <img
              src={Images.saloon_navbar_menu}
              style={{ width: 35, height: 35, cursor: "pointer" }}
            />
          </span>
        </div>
        <div className="px-2">
          <SideNav refe={data} {...this.props} showSidebar={showSidebar} />
        </div>
      </div>
    );
  };

  jumbotronImgHandler = (event) => {
    const imageUrlRegex = /([a-zA-Z0-9\s_\\.\-:])+(.png|.jpeg|.jpg|.gif)$/gm;
    if (event.target.files[0]) {
      let isValidateType = event.target.files[0].type;
      if (isValidateType.match(imageUrlRegex)) {
        const file = URL.createObjectURL(event.target.files[0]);
        this.setState({
          jumbotronImgUrl: file,
          jumbotronImgFile: event.target.files[0],
        });
      } else {
        ErrorHelper.handleErrors("Image format not supported", true);
      }
    }
  };

  renderJumbotron = () => {
    const {
      isEditTemplate,
      jumbotronImgUrl,
      jumbotronImgFile,
      jumbotronFirstHeading,
      jumbotronSecHeading,
    } = this.state;

    return (
      <div
        className="container-fluid px-0 min-vh-100 background"
        style={{
          backgroundImage: `url(${
            jumbotronImgFile != null
              ? jumbotronImgUrl
              : Images.saloon_template3_main
          })`,
          backgroundSize: "cover",
        }}
      >
        {this.renderSmallNavbar()}
        {this.renderLargeNavbar()}
        {isEditTemplate && (
          <input
            type="file"
            style={{ display: "none" }}
            onChange={this.jumbotronImgHandler}
            onClick={(event) => {
              event.target.value = null;
            }}
            ref={(fileInput) => (this.jumbotronImgInput = fileInput)}
            accept="image/*"
          />
        )}
        {isEditTemplate &&
          this.renderEditButtons(
            () => this.jumbotronImgInput.click(),
            () =>
              this.setState({ jumbotronImgFile: null, jumbotronImgUrl: null })
          )}

        <div className="container">
          <div ref={this.home} id="home" className="row">
            <div className="centered">
              {!isEditTemplate && (
                <h1
                  className="text-green"
                  style={{
                    fontSize: "60px",
                    textTransform: "uppercase",
                  }}
                >
                  {jumbotronFirstHeading
                    ? jumbotronFirstHeading
                    : "Let yor hair do the"}
                </h1>
              )}
              {isEditTemplate &&
                this.renderEditTextInputs(
                  jumbotronFirstHeading,
                  this.onChangeJumbotronFirstHeading,
                  "jumbotronHeadingInp jumbotronFirstHeading"
                )}
              <br />
              {!isEditTemplate && (
                <h1
                  className="text-green"
                  style={{
                    fontSize: "60px",
                    textTransform: "uppercase",
                  }}
                >
                  {jumbotronSecHeading ? jumbotronSecHeading : "Talking"}
                </h1>
              )}
              {isEditTemplate &&
                this.renderEditTextInputs(
                  jumbotronSecHeading,
                  this.onChangeJumbotronSecHeading,
                  "jumbotronHeadingInp jumbotronSecHeading"
                )}
            </div>
            <div class="row">
              <div class="drop-centered">
                <a href="#home">
                  <img
                    class="text-center"
                    src={Images.saloon_template_dropdown}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  onChangeaboutSecondHeading = (text) =>
    this.setState({ aboutSecondHeading: text.target.value });
  onChangeaboutFirstHeading = (text) =>
    this.setState({ aboutFirstHeading: text.target.value });
  onChangeJumbotronFirstHeading = (text) =>
    this.setState({ jumbotronFirstHeading: text.target.value });
  onChangeJumbotronSecHeading = (text) =>
    this.setState({ jumbotronSecHeading: text.target.value });

  renderAboutUs = () => {
    const {
      companyList,
      aboutFirstHeading,
      aboutSecondHeading,
      isEditTemplate,
      aboutImgFile,
      aboutImgUrl,
    } = this.state;

    let CompanyDescription =
      companyList[0]?.saloons[0]?.companyShortDescription;

    return (
      <div ref={this.aboutUs} id="AboutUs">
        <div style={{ backgroundColor: "#F4F2EB" }}>
          <div class="container">
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <div style={{ paddingTop: 50 }}>
                  {isEditTemplate && (
                    <input
                      type="file"
                      style={{ display: "none" }}
                      onChange={this.aboutImgHandler}
                      onClick={(event) => {
                        event.target.value = null;
                      }}
                      ref={(fileInput) => (this.aboutImgInput = fileInput)}
                      accept="image/*"
                    />
                  )}
                  <div className={isEditTemplate ? "editImg" : ""}>
                    {isEditTemplate &&
                      this.renderEditButtons(
                        () => this.aboutImgInput.click(),
                        () =>
                          this.setState({
                            aboutImgFile: null,
                            aboutImgUrl: null,
                          })
                      )}
                    <img
                      class="img-fluid"
                      src={
                        aboutImgUrl != null
                          ? aboutImgUrl
                          : Images.saloon_template3_about
                      }
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div style={{ paddingTop: 90 }}>
                  {!isEditTemplate && (
                    <h2 class="text-center" id="aboutHeading">
                      {aboutFirstHeading}
                    </h2>
                  )}
                  {isEditTemplate &&
                    this.renderEditTextInputs(
                      aboutFirstHeading,
                      this.onChangeaboutFirstHeading,
                      "aboutHeadingInput"
                    )}
                  {/* <h2 class="text-center" id="aboutHeading">
                    {getDeserveText}
                  </h2> */}
                  {!isEditTemplate && (
                    <h2 class="text-center" id="aboutHeading">
                      {aboutSecondHeading}
                    </h2>
                  )}
                  {isEditTemplate &&
                    this.renderEditTextInputs(
                      aboutSecondHeading,
                      this.onChangeaboutSecondHeading,
                      "aboutHeadingInput"
                    )}

                  <div class="text-center" style={{ marginTop: 20 }}>
                    <img
                      class="img-fluid"
                      src={Images.saloon_template3_seprator}
                    />
                  </div>
                  <p
                    className="text-center"
                    style={{ paddingTop: 40, color: "#323232", lineHeight: 2 }}
                  >
                    {CompanyDescription}
                  </p>
                  <div class="text-center" style={{ marginTop: 30 }}>
                    <button class="btn-about">Book Appointment</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderServiceComponent = (img, heading, text, duration) => {
    return (
      <div class="col-12 col-md-3">
        <div
          class="item d-flex justify-content-center flex-column align-items-center cursor-pointer"
          style={{
            padding: "1rem",
            marginBottom: 15,
            backgroundColor: "#FFFFFF",
            width: 200,
          }}
          onClick={this.goToAppointmentForm}
        >
          <div className="imgBorder">
            <img className="serviceImg" src={img} />
          </div>
          <h5
            class="text-center mb-0"
            style={{ color: "#2E2D2A", fontWeight: "bold" }}
          >
            {heading} (Estimated Time: {duration})
          </h5>
          <div>
            <p style={{ textAlign: "center", color: "#2E2D2A" }}>{text}</p>
          </div>
        </div>
      </div>
    );
  };

  renderServices = () => {
    const { companyList } = this.state;
    return (
      <div ref={this.services} id="Services">
        <div class="feature-clean" style={{ paddingBottom: 50 }}>
          <div class="container">
            <div class="intro">
              <h2
                style={{
                  fontSize: "30px",
                  fontWeight: "bold",
                  paddingTop: 90,
                  paddingBottom: 20,
                }}
                class="text-center mb-0"
              >
                OUR POPULAR SERVICES
              </h2>
              <div class="text-center" style={{ marginBottom: 40 }}>
                <img src={Images.saloon_template3_seprator} />
              </div>
              <div class="row features justify-content-center">
                {companyList.map(
                  (val) =>
                    val.services &&
                    val.services.map((serviceVal) => {
                      return (
                        <div>
                          {this.renderServiceComponent(
                            serviceVal.image,
                            serviceVal.name,
                            serviceVal?.duration
                          )}
                        </div>
                      );
                    })
                )}
                {/* {this.renderServiceComponent(Images.saloon_template3_service1,'Lorem Ipsum',"Barber is a person whose occupation is mainly to cut dress style")}
                            {this.renderServiceComponent(Images.saloon_template3_service2,'Lorem Ipsum',"Barber is a person whose occupation is mainly to cut dress style")}
                            {this.renderServiceComponent(Images.saloon_template3_service3,'Lorem Ipsum',"Barber is a person whose occupation is mainly to cut dress style")}
                            {this.renderServiceComponent(Images.saloon_template3_service5,'Lorem Ipsum',"Barber is a person whose occupation is mainly to cut dress style")}
                            {this.renderServiceComponent(Images.saloon_template3_service6,'Lorem Ipsum',"Barber is a person whose occupation is mainly to cut dress style")}
                            {this.renderServiceComponent(Images.saloon_template3_service7,'Lorem Ipsum',"Barber is a person whose occupation is mainly to cut dress style")}
                            {this.renderServiceComponent(Images.saloon_template3_service8,'Lorem Ipsum',"Barber is a person whose occupation is mainly to cut dress style")}
                            {this.renderServiceComponent(Images.saloon_template3_service9,'Lorem Ipsum',"Barber is a person whose occupation is mainly to cut dress style")} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderMenuItems = (style, price, info) => {
    return (
      <div style={{ marginTop: 30 }}>
        <p style={{ marginBottom: 0, color: "#FFFFFF", fontWeight: "bold" }}>
          {style}
          <span>
            <p style={{ float: "right", marginBottom: 0, color: "#E3AA41" }}>
              {price}
            </p>
          </span>
        </p>
        <p style={{ color: "#A8BDC2" }}>{info}</p>
      </div>
    );
  };

  ServiceMenuImgHandler = (event) => {
    const imageUrlRegex = /([a-zA-Z0-9\s_\\.\-:])+(.png|.jpeg|.jpg|.gif)$/gm;
    if (event.target.files[0]) {
      let isValidateType = event.target.files[0].type;
      if (isValidateType.match(imageUrlRegex)) {
        const file = URL.createObjectURL(event.target.files[0]);
        this.setState({
          serviceImgUrl: file,
          serviceImgFile: event.target.files[0],
        });
      } else {
        ErrorHelper.handleErrors("Image format not supported", true);
      }
    }
  };

  renderMenuBackground = () => {
    const { companyList, isEditTemplate, serviceImgFile, serviceImgUrl } =
      this.state;
    return (
      <div ref={this.pricing} id="Menu">
        <div
          className="container-fluid px-0 min-vh-100"
          style={{
            backgroundImage: `url(${
              serviceImgFile != null
                ? serviceImgUrl
                : Images.saloon_template3_menu
            })`,
            backgroundSize: "cover",
          }}
        >
          {isEditTemplate && (
            <input
              type="file"
              style={{ display: "none" }}
              onChange={this.ServiceMenuImgHandler}
              onClick={(event) => {
                event.target.value = null;
              }}
              ref={(fileInput) => (this.priceBgImgInput = fileInput)}
              accept="image/*"
            />
          )}
          {isEditTemplate &&
            this.renderEditButtons(
              () => this.priceBgImgInput.click(),
              () => this.setState({ serviceImgFile: null, serviceImgUrl: null })
            )}
          <div class="intro">
            <h2
              style={{
                fontSize: "30px",
                fontWeight: "bold",
                paddingTop: 60,
                color: "#FFFFFF",
              }}
              class="text-center mb-0"
            >
              SERVICE MENU
            </h2>
            <div class="text-center" style={{ paddingTop: 20 }}>
              <img src={Images.saloon_template3_menu_seprator} />
              <div
                class="text-center"
                style={{ paddingTop: 20, paddingBottom: 20 }}
              >
                {/* <p style={{ color: "#FFFFFF" }} class="text-center">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. <br /> Lorem Ipsum has been the
                  industry's standard dummy text ever since the 1500s
                </p> */}
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-lg-4">
                <div class="menuBorder">
                  <div class="boxMenu">
                    {companyList.map(
                      (val) =>
                        val.services &&
                        val.services.map((serviceVal) => {
                          return (
                            <div>
                              {this.renderMenuItems(
                                serviceVal.name,
                                serviceVal.price
                              )}
                            </div>
                          );
                        })
                    )}
                    {/* {this.renderMenuItems('Classic Haircut', '10$', 'Classic Haircut for Men and Kids')}
                        {this.renderMenuItems('Classic Haircut', '10$', 'Classic Haircut for Men and Kids')}
                        {this.renderMenuItems('Classic Haircut', '10$', 'Classic Haircut for Men and Kids')}
                        {this.renderMenuItems('Classic Haircut', '10$', 'Classic Haircut for Men and Kids')} */}
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                {/* <div class="menuBorder">
                    <div class="boxMenu">
                        {this.renderMenuItems('Classic Haircut', '10$', 'Classic Haircut for Men and Kids')}
                        {this.renderMenuItems('Classic Haircut', '10$', 'Classic Haircut for Men and Kids')}
                        {this.renderMenuItems('Classic Haircut', '10$', 'Classic Haircut for Men and Kids')}
                        {this.renderMenuItems('Classic Haircut', '10$', 'Classic Haircut for Men and Kids')}
                    </div>
                </div> */}
              </div>
              <div class="col-lg-4">
                {/* <div class="menuBorder">
                    <div class="boxMenu">
                        {this.renderMenuItems('Classic Haircut', '10$', 'Classic Haircut for Men and Kids')}
                        {this.renderMenuItems('Classic Haircut', '10$', 'Classic Haircut for Men and Kids')}
                        {this.renderMenuItems('Classic Haircut', '10$', 'Classic Haircut for Men and Kids')}
                        {this.renderMenuItems('Classic Haircut', '10$', 'Classic Haircut for Men and Kids')}
                    </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderDropDown = (text) => {
    return (
      <select class="form-group" id="appointmentTemplate3">
        <option>{text}</option>
      </select>
    );
  };

  renderAppointmentTags = (type, name) => {
    return (
      <input
        class="form-group"
        id="appointmentTemplate3"
        type={type}
        placeholder={name}
      />
    );
  };

  renderAppointment = () => {
    const { companyList, employeeData, employeeTimings } = this.state;
    return (
      <div ref={this.appointment} id="Appointment">
        <div style={{ backgroundColor: "#ECE8DD", paddingBottom: 80 }}>
          <div class="container">
            <div class="intro">
              <h2
                style={{ fontSize: "30px", fontWeight: "bold", paddingTop: 60 }}
                class="text-center mb-0"
              >
                MAKE AN APPOINTMENT
              </h2>
              <div class="text-center" style={{ paddingTop: 20 }}>
                <img src={Images.saloon_template3_seprator} />
                {/* <div class="text-center" style={{ paddingTop: 20, paddingBottom: 20 }}>
                                    <p class="text-center">Lorem Ipsum is simply dummy text of the printing and typesetting industry. <br /> Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                </div> */}
              </div>
            </div>
            {/* <div class="text-center" id="appointmentTemplate3Form">
                            {this.renderAppointmentTags("text", "YOUR NAME")}
                            {this.renderAppointmentTags("text", "EMAIL ADDRESS")}
                        </div>
                        <div class="text-center" id="appointmentTemplate3Form">
                            {this.renderAppointmentTags("text", "PHONE NUMBER")}
                            {this.renderAppointmentTags("text", "MM/DD/YYYY")}
                        </div>
                        <div class="text-center" id="appointmentTemplate3Form">
                            <select onChange={this.handleDropdownChange} class="form-group" id="appointmentTemplate3">
                                {
                                    companyList.map(val => (
                                        val.services && val.services.map(serviceVal => {
                                            return (
                                                <option value={serviceVal._id}>{serviceVal.name} Price - {serviceVal.price}</option>
                                            )
                                        })
                                    ))
                                }
                            </select>
                            <select onChange={this.handleEmployeeDropDown} class="form-group" id="appointmentTemplate3">
                                {
                                    employeeData.map(val => {
                                        return (
                                            <option value={val.employeeId.userId._id}>{val.employeeId.userId.firstName}</option>
                                        )
                                    })
                                }
                            </select>
                            <div>
                                {
                                    employeeTimings.map(val => {
                                        if (val.availableStatus == 1) {
                                            return (
                                                <div>
                                                    <input type="checkbox" value={val.checkIn} onChange={this.handleCheckbox} />
                                                    <p>Day Of Week <span style={{ fontWeight: 'bold' }}>{val.dayOfWeek}</span></p>
                                                    <p>Check In <span style={{ fontWeight: 'bold' }}>{val.checkIn}</span>- CheckOut <span style={{ fontWeight: 'bold' }}>{val.checkOut}</span></p>
                                                </div>
                                            )
                                        }
                                    })
                                }
                            </div>
                        </div>
                        <div class="text-center" id="appointmentTemplate3Form">
                            <textarea class="form-group" placeholder="YOUR MESSAGE" id="appointmentTemplate3text">
                            </textarea>
                        </div> */}
            <div class="text-center" style={{ marginTop: 30 }}>
              <button
                onClick={() => this.goToAppointmentForm()}
                class="btn-about"
              >
                MAKE AN APPOINTMENT
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderEmployeeInfo = (image, name, type) => {
    return (
      <div
        class="box"
        style={{
          backgroundImage: `url(${image})`,
          height: "400px",
          width: "220px",
        }}
      >
        <div
          style={{
            backgroundColor: "#333333",
            position: "absolute",
            bottom: 0,
            width: "100%",
            padding: "1rem",
          }}
        >
          <p
            class="text-center mb-0"
            style={{ color: "#FFFFFF", fontWeight: "bold" }}
          >
            {name}
          </p>
          <p
            class="text-center mb-0"
            style={{ color: "#BF9456", fontWeight: "bold" }}
          >
            -{type}
          </p>
        </div>
      </div>
    );
  };

  renderEmployees = () => {
    const { companyList, employeData } = this.state;

    return (
      <div ref={this.term} id="Team">
        <div
          className="team-grid"
          style={{ backgroundColor: "#272e32", paddingBottom: "60px" }}
        >
          <div class="container">
            <div class="intro">
              <h2
                style={{
                  fontSize: "30px",
                  fontWeight: "bold",
                  color: "#FFFFFF",
                  paddingTop: "100px",
                }}
                class="text-center mb-0"
              >
                OUR EMPLOYEES
              </h2>
              <hr
                style={{
                  height: "10px",
                  border: "none",
                  backgroundColor: "#BAD26D",
                  width: "220px",
                }}
              />
              <div className="row" style={{ paddingTop: 50 }}>
                {employeData.employees &&
                  employeData.employees.length &&
                  employeData?.employees
                    .filter(
                      (employee) =>
                        !!employee && typeof employee.userId == "object"
                    )
                    .map((val) => {
                      let firstName = val?.employeeId?.userId.firstName;
                      let lastName = val?.employeeId?.userId.lastName;
                      let rating = val?.employeeId?.averageRating;

                      return (
                        <div class="col-lg-4 col-md-4" id="price-grid">
                          <Fragment>
                            <h6 class="price-heading">
                              {firstName + " " + lastName}
                            </h6>

                            {/* <p className="text-center mb-0">{"price"}$</p> */}
                            <div
                              className="pl-4"
                              style={{
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <ReactStars
                                count={5}
                                // onChange={ratingChanged}
                                size={24}
                                activeColor="orange"
                                value={rating}
                                edit={false}
                              />
                            </div>
                          </Fragment>
                        </div>
                      );
                    })}
              </div>
            </div>
            <div class="row" style={{ paddingTop: 50 }}>
              {companyList.map(
                (val) =>
                  val.employeeDetails &&
                  val.employeeDetails.map((employeeVal) => {
                    return (
                      <div class="col-md-6 col-lg-4 item">
                        {this.renderEmployeesItem(
                          employeeVal.profile_img,
                          employeeVal.firstName,
                          employeeVal.lastName
                        )}
                      </div>
                    );
                  })
              )}

              {/* <div class="col-md-6 col-lg-4 item"> */}
              {/* {this.renderEmployeesItem()} */}
              {/* {this.renderEmployeesItem(Images.saloon_template_employee1,"Ben Johnson","Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s")} */}
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderContactTags = (type, name) => {
    return (
      <input
        class="form-group"
        id="contactTemplate3"
        type={type}
        placeholder={name}
      />
    );
  };

  renderContact = () => {
    return (
      <div ref={this.footer} id="Contact">
        <div style={{ paddingBottom: 80 }}>
          <div class="container">
            <div class="intro">
              <h2
                style={{ fontSize: "30px", fontWeight: "bold", paddingTop: 60 }}
                class="text-center mb-0"
              >
                GET IN TOUCH
              </h2>
              <div
                class="text-center"
                style={{ paddingTop: 20, marginBottom: 40 }}
              >
                <img src={Images.saloon_template3_seprator} />
              </div>
              <div class="text-center" id="contactTemplate3Form">
                {this.renderContactTags("text", "YOUR NAME")}
              </div>
              <div class="text-center" id="contactTemplate3Form">
                {this.renderContactTags("text", "YOUR EMAIL")}
              </div>
              <div class="text-center" id="contactTemplate3Form">
                <textarea
                  class="form-group"
                  placeholder="YOUR MESSAGE"
                  id="contactTemplate3text"
                ></textarea>
                <div
                  class="text-center"
                  style={{ marginTop: 30, marginBottom: 30 }}
                >
                  <button
                    class="form-group"
                    style={{
                      backgroundColor: "#BF9456",
                      color: "#ffffff",
                      border: "none",
                      padding: "1rem",
                    }}
                  >
                    SEND
                  </button>
                </div>
              </div>
              {/* <div class="text-center">
                       <div class="row">
                           <div class="col-lg-12">
                           <p style={{color:'#222222', fontWeight:'bold'}}>Where To Find Us</p>
                        <p style={{color:'#222222', fontWeight:'bold'}}>577 Bellivue Aveneue</p>
                        <p style={{color:'#222222', fontWeight:'bold'}}>Malden, MA, 2148</p>
                        <p style={{color:'#222222', fontWeight:'bold'}}>info@email.com</p>
                        <p style={{color:'#222222', fontWeight:'bold'}}>+1222-345-0000</p>
                           </div>
                       </div>
                       
                   </div> */}
              {/* <div class="row">
                    <div class="col-lg-6 d-flex justify-content-end" style={{justifyContent:'end'}}>
                        <div class="text-center"style={{}}>
                        <p style={{color:'#222222', fontWeight:'bold'}}>Where To Find Us</p>
                        <p style={{color:'#222222', fontWeight:'bold'}}>577 Bellivue Aveneue</p>
                        <p style={{color:'#222222', fontWeight:'bold'}}>Malden, MA, 2148</p>
                        </div>
                    </div>
                    <div class="col-lg-6 d-flex justify-content-start">
                    <div class="text-center" style={{}}>
                        <p style={{color:'#222222', fontWeight:'bold'}}>info@email.com</p>
                        <p style={{color:'#222222', fontWeight:'bold'}}>+1222-345-0000</p>
                    </div>
                    </div>
                    </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderPortfolioItem = (type, image) => {
    return (
      <div class="col-lg-3 col-md-4">
        <a href={type} data-attribute="SRL">
          <img class="img-fluid" src={image} style={{ marginBottom: 20 }} />
        </a>
      </div>
    );
  };
  renderPortfolioItemVideo = (image) => {
    return (
      <div>
        <div class="col-lg-3 col-md-4">
          <video width="320" height="240" controls>
            <source src={image} />
          </video>
        </div>
      </div>
    );
  };

  renderPortfolio = () => {
    const { portfolioImages } = this.state;
    return (
      <div class="photo-gallery" style={{ paddingBottom: "80px" }}>
        <div class="container">
          <div class="intro">
            <h2
              style={{ fontSize: "30px", fontWeight: "bold", paddingTop: 60 }}
              class="text-center mb-0"
            >
              OUR PORTFOLIO
            </h2>
            <div
              class="text-center"
              style={{ paddingTop: 20, marginBottom: 40 }}
            >
              <img src={Images.saloon_template3_seprator} />
            </div>
          </div>
          <SRLWrapper>
            <div class="row">
              {portfolioImages &&
                portfolioImages.map((val) => {
                  return (
                    <div class="col-lg-3 col-md-4">
                      <img class="img-fluid1" src={val.url} />
                    </div>
                  );
                })}
            </div>
          </SRLWrapper>
        </div>
      </div>
    );
  };

  renderPortfolioVideo = () => {
    const { portfolioVideos } = this.state;
    return (
      <div class="photo-gallery" style={{ paddingBottom: "80px" }}>
        <div class="container">
          <div class="intro">
            <h2
              style={{ fontSize: "30px", fontWeight: "bold", paddingTop: 60 }}
              class="text-center mb-0"
            >
              OUR VIDEOS
            </h2>
            <div
              class="text-center"
              style={{ paddingTop: 20, marginBottom: 40 }}
            >
              <img src={Images.saloon_template3_seprator} />
            </div>
          </div>
          <div class="row">
            {portfolioVideos &&
              portfolioVideos.map((val) =>
                this.renderPortfolioItemVideo(val.url)
              )}
          </div>
        </div>
      </div>
    );
  };

  renderFooter = () => {
    const { companyList } = this.state;
    return (
      <div
        class="container-fluid"
        style={{
          backgroundColor: "#222227",
          width: "100%",
          marginTop: 80,
          paddingBottom: 80,
        }}
      >
        <div class="">
          {companyList.map((saloonsVal) => {
            console.log("renderFooter -> saloonsVal", saloonsVal);

            return (
              <div class="row">
                <div
                  class="col-lg-6"
                  style={{ paddingTop: 50, paddingLeft: 50 }}
                >
                  <div class="container">
                    <img
                      style={{ marginBottom: 15, height: 80 }}
                      src={saloonsVal?.saloons[0]?.companyLogo}
                    />
                    <div>
                      <p style={{ color: "#FFFFFF", marginTop: 20 }}>
                        <span style={{ color: "#BF9456", fontWeight: "bold" }}>
                          Address:{" "}
                        </span>
                        {saloonsVal?.saloons[0]?.address}
                      </p>
                      <p style={{ color: "#FFFFFF", marginTop: 20 }}>
                        <span style={{ color: "#BF9456", fontWeight: "bold" }}>
                          <Translation>
                            {(t) => t("container.employe_phone_number")}
                          </Translation>
                          :{" "}
                        </span>
                        {saloonsVal?.saloons[0]?.businessTelephone}
                      </p>
                      <p style={{ color: "#FFFFFF", marginTop: 20 }}>
                        <span style={{ color: "#BF9456", fontWeight: "bold" }}>
                          Email:{" "}
                        </span>
                        {saloonsVal?.saloons[0]?.businessEmail}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  class="col-lg-6"
                  style={{ paddingTop: 50, paddingLeft: 50 }}
                >
                  <p
                    style={{
                      color: "#FFFFFF",
                      fontWeight: "bold",
                      fontSize: "18px",
                    }}
                  >
                    <Translation>
                      {(t) => t("container.working_hours")}
                    </Translation>
                  </p>
                  {saloonsVal &&
                    saloonsVal.schedule &&
                    saloonsVal.schedule.map(
                      (val) =>
                        val &&
                        val.weekPlans &&
                        val.weekPlans.map((weekData) => {
                          return (
                            <div>
                              {weekData.dayOfWeek === 1 ? (
                                <p style={{ color: "#fff" }}>
                                  Monday
                                  {weekData.availableStatus === 1 ? (
                                    <span>
                                      <span style={{ paddingLeft: 20 }}>
                                        {weekData.checkIn}
                                      </span>
                                      -<span>{weekData.checkOut}</span>
                                    </span>
                                  ) : (
                                    <span
                                      style={{ paddingLeft: 10, color: "red" }}
                                    >
                                      Off
                                    </span>
                                  )}
                                </p>
                              ) : null}
                              {weekData.dayOfWeek === 2 ? (
                                <p style={{ color: "#fff" }}>
                                  Tuesday
                                  {weekData.availableStatus === 1 ? (
                                    <span>
                                      <span style={{ paddingLeft: 20 }}>
                                        {weekData.checkIn}
                                      </span>
                                      -<span>{weekData.checkOut}</span>
                                    </span>
                                  ) : (
                                    <span
                                      style={{ paddingLeft: 10, color: "red" }}
                                    >
                                      Off
                                    </span>
                                  )}
                                </p>
                              ) : null}
                              {weekData.dayOfWeek === 3 ? (
                                <p style={{ color: "#fff" }}>
                                  Wednesday
                                  {weekData.availableStatus === 1 ? (
                                    <span>
                                      <span style={{ paddingLeft: 20 }}>
                                        {weekData.checkIn}
                                      </span>
                                      -<span>{weekData.checkOut}</span>
                                    </span>
                                  ) : (
                                    <span
                                      style={{ paddingLeft: 10, color: "red" }}
                                    >
                                      Off
                                    </span>
                                  )}
                                </p>
                              ) : null}
                              {weekData.dayOfWeek === 4 ? (
                                <p style={{ color: "#fff" }}>
                                  Thursday
                                  {weekData.availableStatus === 1 ? (
                                    <span>
                                      <span style={{ paddingLeft: 20 }}>
                                        {weekData.checkIn}
                                      </span>
                                      -<span>{weekData.checkOut}</span>
                                    </span>
                                  ) : (
                                    <span
                                      style={{ paddingLeft: 10, color: "red" }}
                                    >
                                      Off
                                    </span>
                                  )}
                                </p>
                              ) : null}
                              {weekData.dayOfWeek === 5 ? (
                                <p style={{ color: "#fff" }}>
                                  Friday
                                  {weekData.availableStatus === 1 ? (
                                    <span>
                                      <span style={{ paddingLeft: 20 }}>
                                        {weekData.checkIn}
                                      </span>
                                      -<span>{weekData.checkOut}</span>
                                    </span>
                                  ) : (
                                    <span
                                      style={{ paddingLeft: 10, color: "red" }}
                                    >
                                      Off
                                    </span>
                                  )}
                                </p>
                              ) : null}
                              {weekData.dayOfWeek === 6 ? (
                                <p style={{ color: "#fff" }}>
                                  Saturday
                                  {weekData.availableStatus === 1 ? (
                                    <span>
                                      <span style={{ paddingLeft: 20 }}>
                                        {weekData.checkIn}
                                      </span>
                                      -<span>{weekData.checkOut}</span>
                                    </span>
                                  ) : (
                                    <span
                                      style={{ paddingLeft: 10, color: "red" }}
                                    >
                                      Off
                                    </span>
                                  )}
                                </p>
                              ) : null}
                              {weekData.dayOfWeek === 7 ? (
                                <p style={{ color: "#fff" }}>
                                  Sunday
                                  {weekData.availableStatus === 1 ? (
                                    <span>
                                      <span style={{ paddingLeft: 20 }}>
                                        {weekData.checkIn}
                                      </span>
                                      -<span>{weekData.checkOut}</span>
                                    </span>
                                  ) : (
                                    <span
                                      style={{ paddingLeft: 10, color: "red" }}
                                    >
                                      Off
                                    </span>
                                  )}
                                </p>
                              ) : null}
                            </div>
                          );
                        })
                    )}
                </div>
                {/* <div class="col-lg-3" style={{ paddingTop: 50, paddingLeft: 50 }}>
                    <div class="container">
                      <p
                        style={{
                          color: "#FFFFFF",
                          fontWeight: "bold",
                          fontSize: "18px",
                        }}
                      >
                        Latest News
              </p>
                    </div>
                  </div> */}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  render() {
    console.log("TEMPLATE_3_STATE", this.state);
    const { isEditTemplate, isloading } = this.state;
    return (
      <div>
        {isloading && this.renderLoader()}
        {isEditTemplate && this.renderEditTemplateHeader()}
        {!isloading && this.renderJumbotron()}
        {!isloading && this.renderAboutUs()}
        {!isloading && this.renderServices()}
        {!isloading && this.renderMenuBackground()}
        {!isloading && this.renderAppointment()}
        {!isloading && this.renderEmployees()}
        {!isloading && this.renderContact()}
        <SimpleReactLightbox>{this.renderPortfolio()}</SimpleReactLightbox>
        {!isloading && this.renderPortfolioVideo()}
        {!isloading && this.renderFooter()}
      </div>
    );
  }
}

export default Template3;
