import { translate, Trans } from "react-i18next";
import i18n from "../../i18n";
import { ErrorHelper, SuccessHelper } from "../../helpers";
import {
  confirmBooking,
  quickCancelBooking,
} from "../../config/simpleApiCalls";

import "react-dropdown/style.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import UserBookingDetails from "../../components/UserBookingDetails/UserBookingDetails.component";
class Confirm extends Component {
  constructor(props) {
    super(props);
  }

  state = {};

  confirmBooking = (id) => {
    confirmBooking({ id })
      .then(({ data }) => {
        if (data.success) {
          SuccessHelper.handleSuccess(i18n.t("booking_confirmed"), true);
        } else {
          ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
        }
      })
      .catch(() => {
        ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
      });
  };

  cancelBooking = (id) => {
    quickCancelBooking({ bookingId: id })
      .then(({ data }) => {
        if (data.success) {
          SuccessHelper.handleSuccess(i18n.t("booking_cancelled"), true);
        } else {
          if (data.err_code == "must_auth") {
            ErrorHelper.handleErrors(i18n.t("must_login"), true);
          } else {
            ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
          }
        }
      })
      .catch(() => {
        ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
      });
  };

  render() {
    const { match, history } = this.props;
    return (
      <div style={{ backgroundColor: "#F8FDFF" }}>
        <UserBookingDetails
          modalCallback={(bookingId, { isConfirmed }) => {
            if (isConfirmed) {
              this.confirmBooking(bookingId);
            } else {
              this.cancelBooking(bookingId);
            }
          }}
          match={match}
          history={history}
          showConfirm
          showCancel
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(Confirm);
