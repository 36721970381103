import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import SemesterTable from "../../../components/Saloon/Semester/SemesterTable";
import SemesterUpsert from "../../../components/Saloon/Semester/SemesterUpsert";
import i18n from "../../../i18n";
import {
  getRequest,
  upsertRequest,
  removeRequest,
} from "../../../redux/actions/Saloon/Semester";
import { getRequest as getStudentGroups } from "../../../redux/actions/Saloon/StudentGroup";
import { getRequest as getStudentRequest } from "../../../redux/actions/Saloon/Student";
import PrimaryButton from "../../../components/Core/PrimaryButton";
import { DotsLoader } from "../../../components";
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import { getCompanyTaxes } from "../../../config/simpleApiCalls";
import "./Semester.scss";
const Semester = ({
  getRequest,
  getStudentRequest,
  getStudentGroups,
  upsertRequest,
  vendors = [],
  loading: propsLoading,
}) => {
  const [accessToken, setAccessToken] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [allServices, setAllServices] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [taxes, setTaxes] = useState([]);
  const [active, setActive] = useState();
  useEffect(() => {
    const saloonData = JSON.parse(sessionStorage.getItem("saloon"));
    const company = JSON.parse(sessionStorage.getItem("company"));
    setCompanyId(company._id);
    setAccessToken(saloonData.access_token);
    setLoading(true);
    getRequest(
      { access_token: saloonData.access_token, relations: ["enrollments"] },
      {
        success: () => setLoading(false),
      }
    );
    getStudentRequest({
      access_token: saloonData.access_token,
      relations: ["classes"],
    });
    getStudentGroups({ access_token: saloonData.access_token });
    getCompanyTaxes(
      { companyId: saloonData.companyId },
      saloonData.access_token
    ).then(({ data }) => {
      setTaxes(data.data);
    });
  }, []);
  const onClose = () => {
    setOpen(false);
  };
  const onSave = (data) => {
    setLoading(true);
    const request = upsertRequest;
    request(
      { access_token: accessToken, companyId, ...data },
      {
        success: (data) => {
          SuccessHelper.handleSuccess(
            i18n.t(data.id ? "successfully_updated" : "successfully_added"),
            true
          );
          setActive(data.data);
          setLoading(false);
        },
        failure: () => {
          ErrorHelper.handleErrors(
            i18n.t(data.id ? "failed_to_update" : "failed_to_add"),
            true
          );
          setLoading(false);
        },
      }
    );
  };
  const onEdit = (data) => {
    setActive(data);
    setOpen(true);
  };
  return (
    <div className="content-container program">
      <DotsLoader isloading={loading || propsLoading} />
      <div className="d-flex justify-content-between align-items-center">
        <h1 className="saloon-dashboard-heading pb-3">{i18n.t("semesters")}</h1>
        <PrimaryButton
          label={i18n.t("create")}
          onClick={() => {
            setActive();
            setOpen(true);
          }}
        />
      </div>
      <SemesterTable
        accessToken={accessToken}
        rows={vendors}
        onEdit={onEdit}
        allServices={allServices}
      />
      {open && (
        <SemesterUpsert
          open={open}
          active={active}
          setActive={setActive}
          onClose={onClose}
          onSave={onSave}
          allServices={allServices}
          accessToken={accessToken}
          companyId={companyId}
          taxes={taxes}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.semester.isFetching,
});

const action = {
  getRequest,
  upsertRequest,
  removeRequest,
  getStudentRequest,
  getStudentGroups,
};

export default connect(mapStateToProps, action)(Semester);
