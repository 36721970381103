import { connect } from "react-redux";
import React, { Component } from "react";
import Swal from "sweetalert2";
import { Translation } from "react-i18next";
import i18n from "../../../i18n";
import { Images } from "../../../theme";
import { BaseModal, DotsLoader } from "../../../components";
import {
  saloonGetAllService,
  saloonGetCategories,
  saloonEditService,
  updateMultipleService,
  saloonDeleteService,
  getProduct,
} from "../../../config/simpleApiCalls";
import { getRequest as getForms } from "../../../redux/actions/Saloon/FormBuilder";
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import { List, arrayMove } from "react-movable";
import "./styles.css";

import DepositeOptions from "../../../components/DepositeOptions/DepositeOptions.component";
import ServiceForm from "../../../components/Forms/Service/ServiceForm.component";

class AllServices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // GENERAL
      searchText: "",
      showEditServiceCard: false,

      // FOR SERVICES
      serviceList: [],

      // FOR EDIT SERVICE
      serviceId: "",
      filterUsersList: [],
      defaultValues: {},
    };
  }

  componentDidMount = async () => {
    const saloonData = await JSON.parse(sessionStorage.getItem("saloon"));
    const company = JSON.parse(sessionStorage.getItem("company"));
    if (saloonData) {
      this.setState({
        access_token: saloonData.access_token,
        companyId: company._id,
      });
      this.handleSaloonGetCategories();
      this.handleSaloonGetAllProducts();
      this.props.getForms({
        access_token: saloonData.access_token,
        companyId: company._id,
      });
    }
    const isGym = company.function == "gym";
    this.setState({ isGym, timeSlotLength: company.timeSlotLength });
  };

  handleSaloonGetAllService = () => {
    this.setState({ isloading: true });
    const { access_token } = this.state;
    saloonGetAllService(access_token)
      .then((res) => {
        if (res.data.success) {
          this.setState({ isloading: false, serviceList: res.data.data });
        }
      })
      .catch((error) => {
        console.log(error, "get all services error");
      });
  };

  handleSaloonGetCategories = () => {
    this.setState({ isloading: true });
    const { access_token } = this.state;
    saloonGetCategories(access_token)
      .then((res) => {
        if (res.data.success) {
          this.setState(
            {
              categoryList: res.data.data.map(({ name, _id }) => ({
                name,
                value: _id,
              })),
            },
            () => this.handleSaloonGetAllService()
          );
        }
      })
      .catch((error) => {
        console.log(error, "get all category error");
      });
  };

  handleSaloonGetAllProducts = () => {
    this.setState({ isloading: true });
    const { access_token, companyId } = this.state;
    getProduct({ companyId }, access_token)
      .then((res) => {
        if (res.data.success) {
          this.setState({
            isloading: false,
            products: res.data.data.map((product) => ({
              text: product.name,
              value: product._id,
              ...product,
            })),
          });
        }
      })
      .catch((error) => {
        console.log(error, "get all services error");
      });
  };

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  onChangeSearchText = (text) => {
    this.setState({
      searchText: text.target.value,
      activePage: 1,
    });
  };

  handleSaloonEditService = (data) => {
    const { access_token, isGym, serviceId } = this.state;
    const {
      name,
      price,
      category,
      status,
      minimumTime,
      imageFile,
      shouldSendTextReminders,
      textReminderFrequency,
      subcategory,
      relatedProductIds = [],
      description,
      depositAmount,
      requireDeposit,
      forms = [],
      depositTarget,
      hiddenToCustomers,
      hidePrice,
    } = data;
    const sendRequst = () => {
      this.setState({ isloading: true });
      let payloadFormData = new FormData();
      payloadFormData.append("name", name);
      // payloadFormData.append('categoryName', categoryName);
      if (!isGym) {
        payloadFormData.append(
          "shouldSendTextReminders",
          shouldSendTextReminders
        );
        payloadFormData.append("textReminderFrequency", textReminderFrequency);
      }
      payloadFormData.append("serviceId", category);
      payloadFormData.append("isActive", status);
      payloadFormData.append("duration", minimumTime);
      payloadFormData.append("price", price);
      payloadFormData.append("subcategory", subcategory);
      payloadFormData.append("description", description);
      payloadFormData.append(
        "relatedProductIds",
        relatedProductIds.map((product) => product.value).join(",")
      );
      payloadFormData.append(
        "selectedFormIds",
        forms.map((form) => form._id).join(",")
      );
      payloadFormData.append("requireDeposit", requireDeposit);
      if (depositAmount) {
        payloadFormData.append("depositAmount", depositAmount);
      }
      payloadFormData.append("depositTarget", depositTarget);
      payloadFormData.append("hiddenToCustomers", hiddenToCustomers);
      payloadFormData.append("hidePrice", hidePrice);

      if (imageFile) {
        payloadFormData.append("image", imageFile);
      }
      saloonEditService(payloadFormData, serviceId, access_token)
        .then((res) => {
          if (res.data.success) {
            SuccessHelper.handleSuccess(
              "Your service is successfully updated.",
              true
            );
            this.setState(
              {
                showEditServiceCard: false,
                serviceId: "",
                defaultValues: {},
              },
              () => this.handleSaloonGetAllService()
            );
          } else if (!res.data.success) {
            ErrorHelper.handleErrors(res.data.msg, true);
            this.setState({ isloading: false });
          }
        })
        .catch((error) => {
          this.setState({ isloading: false });
          console.log(error, "errorrrrrrrrrrrrrrrrrrr");
        });
    };
    const productsThatDontAllowBackorder = relatedProductIds.filter(
      (product) => !product.allowBackorder
    );
    if (productsThatDontAllowBackorder.length) {
      Swal.fire({
        icon: "warning",
        text: i18n.t("product_dont_allow_backorder_warning", {
          products: productsThatDontAllowBackorder
            .map((product) => product.text)
            .join(", "),
        }),
        showCancelButton: true,
        cancelButtonText: i18n.t("no"),
        confirmButtonText: i18n.t("yes"),
      }).then((e) => {
        const { dismiss, isConfirmed, isDismissed } = e;
        if (!isDismissed || dismiss != "backdrop") {
          if (isConfirmed) {
            sendRequst();
          }
        }
      });
    } else {
      sendRequst();
    }
  };

  handleSaloonDeleteService = (serviceId) => {
    const { access_token } = this.state;
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ff3600",
      cancelButtonColor: "#354e68",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        this.setState({ isloading: true });
        saloonDeleteService(serviceId, access_token)
          .then((res) => {
            if (res.data.success) {
              SuccessHelper.handleSuccess(
                "Your service is successfully deleted.",
                true
              );
              this.handleSaloonGetAllService();
            } else if (!res.data.success) {
              ErrorHelper.handleErrors(res.data.msg, true);
              this.setState({ isloading: false });
            }
          })
          .catch((error) => {
            console.log(error, "errorrrrrrrrrrrrrrrrrrr");
          });
      }
    });
  };

  openEditServiceCard = (data) => {
    const { products } = this.state;
    const { forms } = this.props;
    const hours = parseInt(data.duration / 60);
    const minutes = data.duration % 60;
    const intersect = [];
    if (data.relatedProductIds && data.relatedProductIds.length) {
      data.relatedProductIds.map((element) => {
        const relevant = products.find(({ value }) => value === element);
        if (relevant) {
          intersect.push(relevant);
        }
      });
    }

    const selectedFormObjects = data.selectedFormIds.map((id) =>
      forms.find(({ _id }) => _id === id)
    );

    this.setState({
      showEditServiceCard: true,
      serviceId: data._id,
      defaultValues: {
        name: data.name,
        hours,
        minutes,
        category: data._id,
        price: data.price,
        subcategory: data.subcategory ? data.subcategory : "",
        imageUrl: data.image,
        isActive: data.isActive,
        category: data.serviceId._id,
        shouldSendTextReminders: data.shouldSendTextReminders,
        textReminderFrequency: data.textReminderFrequency,
        requireDeposit: data.requireDeposit,
        depositAmount: data.depositAmount ? data.depositAmount : 0,
        description: data.description,
        relatedProductIds: intersect,
        forms: selectedFormObjects,
        depositTarget: data.depositTarget,
        hiddenToCustomers: data.hiddenToCustomers,
        hidePrice: data.hidePrice,
      },
    });
  };

  closeEditServiceCard = () => {
    this.setState({
      showEditServiceCard: false,
      defaultValues: {},
    });
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  renderSaloonDashboardHeading = () => {
    return (
      <div className="col-12 p-3">
        <h1 className="saloon-dashboard-heading">
          <Translation>{(t) => t("container.services")}</Translation>
        </h1>
      </div>
    );
  };

  renderSearchBar = () => {
    return (
      <div class="form-group col-md-5 px-0 px-md-3">
        <input
          type="text"
          class="form-control saloon-category-input"
          placeholder={i18n.t("container.services_search_by_name")}
          value={this.state.searchText}
          onChange={(text) => this.onChangeSearchText(text)}
        />
      </div>
    );
  };

  renderCategorySelect = () => {
    return (
      <div class="form-group col-md-5 px-0 px-md-3">
        <select
          id="select-category"
          class="form-control saloon-category-input"
          onChange={(text) => {}}
        >
          <option selected>{i18n.t("container.all_categories")}</option>
        </select>
      </div>
    );
  };

  renderCategoryForm = () => {
    return (
      <div class="form-row align-items-center">
        {this.renderSearchBar()}
        {this.renderCategorySelect((text) => {
          this.setState({ searchCategory: text.target.value });
        })}
        <div class="form-group col-md-2 px-0 px-md-3 text-right text-md-left">
          <button
            type="button"
            className="btn btn-light btn-sm"
            id="saloon-category-search-btn"
          >
            <Translation>{(t) => t("search")}</Translation>
          </button>
        </div>
      </div>
    );
  };

  renderTableHead = () => {
    const { isGym } = this.state;
    return (
      <thead className="bg-white">
        <tr>
          <th scope="col">
            <span className="category-table-th-text">
              <Translation>{(t) => t("container.profile_service")}</Translation>
            </span>
          </th>
          {!isGym && (
            <th scope="col">
              <span className="category-table-th-text">
                <Translation>{(t) => t("starting_price")}</Translation>
              </span>
            </th>
          )}
          <th scope="col">
            <span className="category-table-th-text">
              <Translation>{(t) => t("subcategory")}</Translation>
            </span>
          </th>
          {!isGym && (
            <th scope="col">
              <span className="category-table-th-text">
                {i18n.t("container.services_duration")}
              </span>
            </th>
          )}
          <th scope="col">
            <span className="category-table-th-text">
              {i18n.t("container.services_category")}
            </span>
          </th>
          <th scope="col">
            <span className="category-table-th-text">
              {i18n.t("container.profile_status")}
            </span>
          </th>
          <th scope="col">
            <span className="category-table-th-text"></span>
          </th>
        </tr>
      </thead>
    );
  };

  renderTableBody = (serviceList) => {
    const { isGym, widths = [], access_token } = this.state;
    return (
      <List
        transitionDuration={10}
        lockVertically={true}
        beforeDrag={({ elements, index }) => {
          const cells = Array.from(elements[index].children);
          const widths = cells.map(
            (cell) => window.getComputedStyle(cell).width
          );
          this.setState({ widths });
        }}
        values={serviceList}
        onChange={({ oldIndex, newIndex }) => {
          const toUpdate = {};
          let newServiceList = arrayMove(serviceList, oldIndex, newIndex);
          newServiceList = newServiceList.map((val, index) => {
            if (index + 1 !== newServiceList[index].displayOrder) {
              toUpdate[val._id] = index + 1;
            }
            return { ...val, displayOrder: index + 1 };
          });
          this.setState({ isloading: true });
          updateMultipleService(toUpdate, access_token)
            .then(({ data }) => {
              if (data.success) {
                SuccessHelper.handleSuccess(
                  i18n.t("updated_display_order"),
                  true
                );
              } else {
                ErrorHelper.handleErrors(i18n.t("someting_went_wrong"), true);
              }
              this.setState({ isloading: false });
            })
            .catch(() => {
              ErrorHelper.handleErrors(i18n.t("someting_went_wrong"), true);
              this.setState({ isloading: false });
            });
          this.setState({ serviceList: newServiceList });
        }}
        renderList={({ children, props }) => (
          <tbody {...props} style={{ textAlign: "center" }}>
            {children}
          </tbody>
        )}
        renderItem={({ value, props, isDragged, isSelected }) => {
          const val = value;
          return (
            <tr
              key={val._id}
              {...props}
              style={{
                ...props.style,
                cursor: isDragged ? "grabbing" : "grab",
                backgroundColor: isDragged || isSelected ? "#EEE" : "#FFF",
              }}
            >
              <td style={{ width: widths[0] }}>
                <div className="d-flex align-items-center">
                  <img
                    className="category-table-pic"
                    src={val?.image ? val.image : Images.saloon_category_pic}
                  />
                  <span className="category-table-td-text">{val.name}</span>
                </div>
              </td>
              {!isGym && (
                <td style={{ width: widths[0] }}>
                  <span className="category-table-td-text">
                    ${val.price.toFixed(2)}
                  </span>
                </td>
              )}
              <td style={{ width: widths[0] }}>
                <span className="category-table-td-text">
                  {val.subcategory}
                </span>
              </td>
              {!isGym && (
                <td style={{ width: widths[0] }}>
                  <span className="category-table-td-text">
                    {val?.duration} Minutes
                  </span>
                </td>
              )}
              <td style={{ width: widths[0] }}>
                <span className="category-table-td-text">
                  {val?.serviceId?.name}
                </span>
              </td>
              <td style={{ width: widths[0] }}>
                <span className="category-table-td-status">
                  {val.isActive === 1 ? (
                    <Translation>{(t) => t("container.active")}</Translation>
                  ) : (
                    <Translation>{(t) => t("container.inactive")}</Translation>
                  )}
                </span>
              </td>
              <td style={{ width: widths[0] }}>
                <div style={{ width: 70 }}>
                  <img
                    role="button"
                    onClick={() => this.openEditServiceCard(val)}
                    className="category-table-action-icon"
                    src={Images.saloon_working_hour_edit}
                  />
                  <img
                    role="button"
                    onClick={() => this.handleSaloonDeleteService(val._id)}
                    className="category-table-action-icon"
                    src={Images.saloon_working_hour_delete}
                  />
                </div>
              </td>
            </tr>
          );
        }}
      />
    );
  };

  renderCategoryTable = () => {
    const { serviceList, searchText, searchCategory } = this.state;
    let filterUsersList = undefined;
    if (searchText || searchCategory) {
      filterUsersList = serviceList;
      if (searchText) {
        filterUsersList = filterUsersList.filter((element) => {
          return element.name.toLowerCase().includes(searchText.toLowerCase());
        });
      }
      if (searchCategory) {
        filterUsersList = filterUsersList.filter((element) => {
          return element.serviceId._id == searchCategory;
        });
      }
    }
    return (
      <div className="col-12 p-md-3 p-0">
        <div
          className="table-responsive sticky-header"
          style={{ borderRadius: "1rem", border: "1px solid #E0E2E4" }}
        >
          <table class="table table-borderless saloon-category-table mb-0">
            {this.renderTableHead()}
            {this.renderTableBody(filterUsersList || serviceList)}
          </table>
        </div>
      </div>
    );
  };

  renderCategoryCard = () => {
    return (
      <div className="col-12 p-3">
        <div
          className="col-12 px-3 py-2 bg-white"
          style={{ borderRadius: "1rem" }}
        >
          {this.renderCategoryForm()}
          {this.renderCategoryTable()}
        </div>
      </div>
    );
  };

  renderEditServiceCard = () => {
    const {
      defaultValues,
      showEditServiceCard,
      isGym,
      products,
      categoryList,
    } = this.state;
    const { forms } = this.props;
    return (
      <BaseModal
        open={true}
        onClose={this.closeEditServiceCard}
        {...{
          content: (
            <ServiceForm
              defaultValues={defaultValues}
              products={products}
              formOptions={forms}
              categories={categoryList}
              isGym={isGym}
              onSubmit={this.handleSaloonEditService}
            />
          ),
          title: i18n.t("service"),
        }}
      />
    );
  };

  render() {
    return (
      <div>
        {this.renderLoader()}
        <div className="content-container">
          {this.state.showEditServiceCard && this.renderEditServiceCard()}
          <div className="row">
            {this.renderSaloonDashboardHeading()}
            {this.renderCategoryCard()}
          </div>
          <div className="col-12 text-right">
            <button
              onClick={() => this.props.history.push("saloon-add-service")}
              type="button"
              className="btn btn-light btn-sm mb-2"
              id="saloon-save-social-link"
            >
              <Translation>{(t) => t("container.add_service")}</Translation>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  forms: state.formBuilder.data,
});

const action = { getForms };

export default connect(mapStateToProps, action)(AllServices);
