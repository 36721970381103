import React, { useEffect, useState } from "react";
import i18n from "../../../i18n";
import { DotsLoader } from "../../../components";
import DatePicker from "react-datepicker";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getAllEmployeeAssociatedWithCompany,
  getProduct,
  getProductReport,
} from "../../../config/simpleApiCalls";
import BasicTable from "../../../components/Core/BasicTable";
import { FormControl } from "@material-ui/core";
import { InputLabel } from "@material-ui/core";
import Select from "../../../components/Core/Select";
import PrimaryButton from "../../../components/Core/PrimaryButton";
import { Button } from "@material-ui/core";

export default function ProdeuctReport() {
  const [accessToken, setAccessToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(
    moment(moment.now()).subtract(7, "d").toDate()
  );
  const [endDate, setEndDate] = useState(moment(moment.now()).toDate());
  const [products, setProducts] = useState([]);
  const [productId, setProductId] = useState("");
  const [employees, setEmployees] = useState([]);
  const [employeeId, setEmployeeId] = useState("");
  const getReport = async (exportToXlsx) => {
    setLoading(true);
    const data = {
      from: startDate,
      to: endDate,
      exportToXlsx,
    };
    if (employeeId) {
      data.employeeId = employeeId;
    }
    if (productId) {
      data.productId = productId;
    }
    getProductReport(data, accessToken)
      .then(({ data }) => {
        console.log(data.data);
        setData(data.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    const saloonData = JSON.parse(sessionStorage.getItem("saloon"));
    setAccessToken(saloonData.access_token);
    setLoading(true);
    Promise.all([
      getAllEmployeeAssociatedWithCompany(saloonData.access_token).then(
        (res) => {
          if (res.data.success) {
            setEmployees(
              res.data.Data.map((employee) => ({
                value: employee.employee._id,
                name: employee.employee.userId.firstName,
              }))
            );
          }
        }
      ),
      getProduct(
        { companyId: saloonData.companyId },
        saloonData.access_token
      ).then((res) => {
        if (res.data.success) {
          setProducts(
            res.data.data.map((element) => ({
              value: element._id,
              name: element.name,
            }))
          );
        }
      }),
    ]).finally(() => {
      setLoading(false);
    });
  }, []);

  useEffect(async () => {
    if (accessToken) {
      getReport();
    }
  }, [accessToken]);

  const setSelectedValue = (val) => {
    let currentDate = moment(Date.now());
    if (val == 1) {
      let day = currentDate.day();
      const convertedStart = moment(currentDate).subtract(day, "days");
      const convertedEnd = moment(convertedStart).add(6, "days");
      setStartDate(convertedStart.toDate());
      setEndDate(convertedEnd.toDate());
    } else if (val == 2) {
      const startOfMonth = moment(currentDate).startOf("month");
      const endOfMonth = moment(currentDate).endOf("month");
      setStartDate(startOfMonth.toDate());
      setEndDate(endOfMonth.toDate());
    } else if (val == 3) {
      const convertedStart = moment(
        `01-01-${currentDate.year()}`,
        "MM-DD-YYYY"
      );
      const convertedEnd = moment(`12-31-${currentDate.year()}`, "MM-DD-YYYY");
      setStartDate(convertedStart.toDate());
      setEndDate(convertedEnd.toDate());
    }
  };

  const onStartDateChange = (val) => {
    if (val > endDate) {
      setStartDate(endDate);
      setEndDate(val);
    } else {
      setStartDate(val);
    }
  };

  const onEndDateChange = (val) => {
    if (val < startDate) {
      setStartDate(val);
      setEndDate(startDate);
    } else {
      setEndDate(val);
    }
  };

  return (
    <div className="content-container">
      <DotsLoader isloading={loading} />
      <div className="d-flex justify-content-between align-items-center">
        <h1 className="saloon-dashboard-heading pb-3">
          {i18n.t("product_report")}
        </h1>
      </div>
      <div
        style={{ flex: 1, display: "flex", justifyContent: "space-between" }}
        className="employee-report-filters"
      >
        <div>{i18n.t("date_range")}</div>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <div className="mx-1">
            <DatePicker
              className="form-control saloon-form-input"
              placeholderText="Select Start Date"
              selected={startDate}
              onChange={onStartDateChange}
              dateFormat="MM/dd/yyyy"
            />
          </div>
          <div className="mx-1">
            <DatePicker
              className="form-control saloon-form-input"
              placeholderText="Select End Date"
              selected={endDate}
              onChange={onEndDateChange}
              dateFormat="MM/dd/yyyy"
            />
          </div>
        </div>
      </div>
      <div class="px-0">
        <div class="row employee-report-filters pt-3 px-0 mx-0">
          <div className="col-12 col-sm-12 col-md-3 d-flex px-0">
            {i18n.t("container.quick_filter")}
          </div>
          <div className="col-12 col-md-9 px-0 justify-content-end">
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-light btn-sm my-1"
                onClick={() => setSelectedValue(1)}
              >
                {i18n.t("container.week")}
              </button>
              <button
                type="button"
                className="btn btn-light btn-sm my-1"
                onClick={() => setSelectedValue(2)}
              >
                {i18n.t("container.month")}
              </button>
              <button
                type="button"
                className="btn btn-light btn-sm my-1"
                onClick={() => setSelectedValue(3)}
              >
                {i18n.t("container.year")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="px-0 row mx-0 justify-content-end">
        <button
          type="button"
          className="btn btn-accept btn-sm"
          onClick={() => getReport(true)}
        >
          {i18n.t("export")}
          <FontAwesomeIcon className="ml-1" icon={["far", "file-excel"]} />
        </button>
        <button
          type="button"
          className="btn btn-light btn-sm"
          onClick={() => getReport()}
        >
          {i18n.t("search")}
        </button>
      </div>
      <div className="row mx-0 py-2">
        <div style={{ flex: 11 }} className="pr-2">
          <Select
            value={employeeId}
            onChange={(e) => setEmployeeId(e.target.value)}
            label={i18n.t("container.employee")}
            options={employees}
          />
        </div>
        <div style={{ flex: 11 }} className="pr-2">
          <Select
            value={productId}
            onChange={(e) => setProductId(e.target.value)}
            label={i18n.t("products")}
            options={products}
          />
        </div>
        <Button
          onClick={() => {
            setEmployeeId("");
            setProductId("");
          }}
          size="small"
          style={{
            backgroundColor: "#c6c6c6",
            color: "black",
            flex: 1,
          }}
        >
          {i18n.t("clear")}
        </Button>
      </div>
      <div className="mt-2">
        <BasicTable
          rows={data}
          schema={[
            {
              header: i18n.t("employee"),
              field: "employee",
            },
            {
              header: i18n.t("product"),
              field: "productName",
            },
            {
              header: i18n.t("total"),
              field: "total",
            },
            {
              header: i18n.t("commission"),
              field: "commission",
              accessor: ({ commission }) => `${commission || 0} %`,
            },
            {
              header: i18n.t("commission_total"),
              field: "commissionTotal",
            },
            {
              header: i18n.t("sold_on"),
              field: "closedOn",
              accessor: ({ closedOn }) => {
                if (!closedOn) {
                  return "";
                }
                return moment(closedOn).format("MM-DD-YYYY hh:mma");
              },
            },
          ]}
        />
      </div>
    </div>
  );
}
