import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import i18n from "../../i18n";
import InputAdornment from "@material-ui/core/InputAdornment";

const DollarInput = ({
  value,
  onChange = () => {},
  error,
  readOnly,
  label = i18n.t("amount"),
  max,
}) => {
  const handleChange = (text) => {
    console.log(text.target.value);
    onChange(text.target.value);
  };
  const handleBlur = (text) => {
    let num = parseFloat(text.target.value);
    if (isNaN(num) || num < 0) {
      num = 0;
    }
    if (max && num > max) {
      num = max;
    }
    num = Math.round((num + Number.EPSILON) * 100) / 100;

    onChange(num);
  };
  return (
    <TextField
      type="numeric"
      label={label}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={(e) => {
        if (!readOnly) {
          e.target.select();
        }
      }}
      value={value}
      InputProps={{
        readOnly,
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }}
      helperText={error}
      error={Boolean(error)}
    />
  );
};

export default DollarInput;
