import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import i18n from "../../../../i18n";
import BaseModal from "../../../BaseModal";
import { Button } from "@material-ui/core";
import { getNestedValue } from "../../../../util/objectMethods";
import moment from "moment";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import { reverseRequest } from "../../../../redux/actions/BookingTransaction";
import SuccessHelper from "../../../../helpers/SuccessHelper";
import ErrorHelper from "../../../../helpers/ErrorHelper";
import InsertPayment from "../InsertPayment";
import DotsLoader from "../../../DotsLoader";

const PaymentList = ({
  open,
  handleClose,
  transactions = [],
  isloading,
  reverseRequest,
  readOnly,
  setData,
  products,
  services,
  collectedMoney = [],
}) => {
  const [isAddTransactionOpen, setIsAddTransactionOpen] = useState(false);
  const [active, setActive] = useState({});
  const [collectedCash, setCollectedCash] = useState(0);
  const [collectedCard, setCollectedCard] = useState(0);
  useEffect(() => {
    let totalCash = 0;
    let totalCard = 0;
    collectedMoney.map((element) => {
      totalCash += element.cash;
      totalCard += element.creditDebit;
    });
    setCollectedCash(totalCash);
    setCollectedCard(totalCard);
  }, [collectedMoney]);
  const onSubmit = (body) => {
    const access_token = sessionStorage.getItem("access_token");
    const { _id, bookingId } = active;
    reverseRequest(
      {
        access_token,
        ...body,
        _id,
        bookingId,
      },
      {
        success: (data) => {
          setIsAddTransactionOpen(false);
          SuccessHelper.handleSuccess(i18n.t("successfully_added"), true);
          setData(data);
        },
        failure: () => {
          ErrorHelper.handleErrors(i18n.t("failed_to_add"), true);
        },
      }
    );
  };
  const calculateFor = (metadata = {}) => {
    if (metadata.Products) {
      const product = products.find(
        (product) => product._id === metadata.Products[0]
      );
      if (product) {
        return product.productId?.name;
      }
    } else if (metadata.Extras) {
      const extra = services.find(
        (service) => service._id === metadata.Extras[0]
      );
      if (extra) {
        return extra.name ? extra.name : extra.serviceId?.name;
      }
    } else if (metadata.deposit) {
      return i18n.t("deposit");
    } else if (metadata.ServiceRemainder) {
      return i18n.t("service");
    }
    return "";
  };
  return (
    <>
      <BaseModal
        open={open}
        containerClassName={"small-modal"}
        {...{
          title: <div>{i18n.t("payments")}</div>,
          content: (
            <div>
              {isAddTransactionOpen && (
                <InsertPayment
                  isReverse={true}
                  defaultAmount={active.amount}
                  open={isAddTransactionOpen}
                  onSubmit={onSubmit}
                  handleClose={() => setIsAddTransactionOpen(false)}
                  type="reverse"
                />
              )}
              <DotsLoader isloading={isloading} />
              <div className="row px-0">
                <div className="col-6">{i18n.t("cash")}</div>
                <div className="col-6 text-right font-bold">
                  $ {collectedCash.toFixed(2)}
                </div>
                <div className="col-6">{i18n.t("credit_debit")}</div>
                <div className="col-6 text-right font-bold">
                  $ {collectedCard.toFixed(2)}
                </div>
              </div>
              <table className="table table-responsive w-100 d-block d-md-table">
                <thead>
                  <tr style={{ borderTop: "3px solid #F7F7F7" }}>
                    <th>{i18n.t("type")}</th>
                    <th>{i18n.t("for")}</th>
                    <th>{i18n.t("amount")}</th>
                    <th>{i18n.t("note")}</th>
                    <th>{i18n.t("created_by")}</th>
                    <th>{i18n.t("created_at")}</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {transactions.map((transaction, index) => {
                    const {
                      type = "",
                      amount,
                      createdBy,
                      createdDate,
                      note,
                      wasReversed,
                      metadata,
                    } = transaction;
                    return (
                      <tr key={index}>
                        <td>{i18n.t(type.toLowerCase())}</td>
                        <td>{calculateFor(metadata)}</td>
                        <td>$ {amount.toFixed(2)}</td>
                        <td>{note}</td>
                        <td>{getNestedValue(createdBy, ["firstName"], "")}</td>
                        <td>
                          {moment(createdDate).format("MM-DD-YYYY HH:mm A")}
                        </td>
                        <td>
                          {type != "Reverse" && !wasReversed && !readOnly ? (
                            <div className="cursor-pointer">
                              <SettingsBackupRestoreIcon
                                onClick={() => {
                                  setActive(transaction);
                                  setIsAddTransactionOpen(true);
                                }}
                              />
                            </div>
                          ) : null}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ),
        }}
        onClose={handleClose}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  isloading: state.bookingTransaction.isFetching,
  collectedMoney: state.bookingTransaction.collectedMoney,
});

const action = { reverseRequest };

export default connect(mapStateToProps, action)(PaymentList);
