import { connect } from "react-redux";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import Select from "../../components/Core/Select";
import DraggableDialog from "../../components/DraggableDialog/DraggableDialog";

import i18n from "../../i18n";
import Swal from "sweetalert2";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import PrimaryButton from "../../components/Core/PrimaryButton";
import { insertRequest as insertEnrollment } from "../../redux/actions/Saloon/ProgramEnrollment";
import moment from "moment";
import DatePicker from "react-datepicker";
import { MenuItem } from "@material-ui/core";
import { BaseModal } from "../../components";
import _ from "lodash";
import { ErrorHelper, SuccessHelper } from "../../helpers";
import PaymentForm from "./PaymentForm";
import TransactionDetails from "./TransactionDetails/TransactionDetails";
import Autocomplete from "@material-ui/lab/Autocomplete";

const roundTo2Decimals = (x) => Math.round((x + Number.EPSILON) * 100) / 100;

function PurchaseClassModal({
  open,
  setOpen,
  students = [],
  programs = [],
  classes = [],
  active = {},
  program,
  student,
  insertEnrollment,
  accessToken,
  taxes = [],
  enrolledStudentMap = {},
  enrolledCourseMap = {},
}) {
  const form = useRef();
  const [data, setData] = useState({});
  const [type, setType] = useState("program");
  const [step, setStep] = useState(0);
  useEffect(() => {
    ValidatorForm.addValidationRule("hasElements", (value) => {
      if (!data.classes?.length) {
        return false;
      }
      return true;
    });
    return () => ValidatorForm.removeValidationRule("hasElements");
  }, [data]);
  const cummulativeTaxRate = useMemo(
    () => taxes.reduce((accum, tax) => accum + tax.percentage, 0) / 100,
    [taxes]
  );
  useEffect(() => {
    if (program) {
      setStep(1);
      setData({
        ...data,
        program,
        balanceDue: program.price,
        subtotal: program.price,
        includeTaxes: Boolean(taxes.length),
        taxDue: roundTo2Decimals(program.price * cummulativeTaxRate),
      });
    }
  }, [program]);

  const handleClose = () => {
    setData(null);
    setOpen(false);
  };

  const handleChange = (key, value) => {
    data[key] = value;
    setData({ ...data });
  };

  const handleSubmit = () => {
    const {
      cash,
      creditDebit,
      discountAmount: discount,
      includeTaxes,
      note,
      tip,
      price,
    } = data;
    const newTransaction =
      cash || creditDebit || discount
        ? {
            cash,
            creditDebit,
            discount,
            note,
            tip,
          }
        : null;
    insertEnrollment(
      {
        access_token: accessToken,
        programId: program?._id || data.programId,
        classIds: (data.classes || []).map(({ _id }) => _id),
        studentId: student?._id || data.studentId,
        newTransaction,
        price,
        includeTaxes,
      },
      {
        success: (data) => {
          SuccessHelper.handleSuccess(i18n.t("successfully_added"), true);
          setData(null);
          setOpen(false);
        },
        failure: () => {
          ErrorHelper.handleErrors(i18n.t("failed_to_add"), true);
        },
      }
    );
  };
  if (!open || !data) {
    return null;
  }
  return (
    <div>
      <BaseModal
        title={"enroll"}
        content={
          <div className="row mx-0">
            {step == 0 ? (
              <ValidatorForm
                onSubmit={() => {
                  setStep(1);
                }}
                ref={form}
                className="row col-12 mx-0"
                onError={(error) => {
                  console.log(error);
                }}
              >
                {!data._id ? (
                  <SelectValidator
                    containerProps={{ className: "col-12 px-0" }}
                    className="mb-1"
                    label={i18n.t("type")}
                    value={type}
                    onChange={(e) => {
                      setType(e.target.value);
                      setData({
                        programId: "",
                        classes: [],
                      });
                    }}
                    select
                  >
                    <MenuItem value={"program"}>{i18n.t("program")}</MenuItem>
                    <MenuItem value={"class"}>{i18n.t("class")}</MenuItem>
                  </SelectValidator>
                ) : null}
                {program?._id || type != "program" ? null : (
                  <SelectValidator
                    containerProps={{ className: "col-12 px-0" }}
                    className="mb-1"
                    label={i18n.t("program")}
                    value={data.stringifiedCourse}
                    onChange={(e) => {
                      if (e.target.value) {
                        const raw = e.target.value;
                        const data = JSON.parse(e.target.value);
                        setData({
                          programId: data._id,
                          stringifiedCourse: raw,
                          program: data,
                          subtotal: data.price,
                          price: data.price,
                          taxDue: roundTo2Decimals(
                            data.price * cummulativeTaxRate
                          ),
                        });
                      }
                    }}
                    disabled={Boolean(data._id)}
                    select
                    validators={["required"]}
                    errorMessages={[i18n.t("this_field_is_required")]}
                  >
                    {programs.map((program) => {
                      return (
                        <MenuItem value={JSON.stringify(program)}>
                          {program.name}
                        </MenuItem>
                      );
                    })}
                  </SelectValidator>
                )}
                {program?._id || type != "class" ? null : (
                  <div className="col-12 px-0 mb-1">
                    <Autocomplete
                      multiple
                      id="forms-standard"
                      options={classes}
                      getOptionLabel={(option) => option.name}
                      value={data.classes || []}
                      onChange={(e, value = []) => {
                        const subtotal = value.reduce((accum, element) => {
                          return accum + (element.price || 0);
                        }, 0);
                        setData({
                          classes: value,
                          subtotal,
                          price: subtotal,
                          taxDue: roundTo2Decimals(
                            subtotal * cummulativeTaxRate
                          ),
                        });
                      }}
                      disableCloseOnSelect={true}
                      disabled={Boolean(data._id)}
                      renderInput={(params) => (
                        <TextValidator
                          {...params}
                          containerProps={{ className: "col-12 px-0" }}
                          className="mb-1"
                          label={i18n.t("classes")}
                          validators={["hasElements"]}
                          errorMessages={[i18n.t("this_field_is_required")]}
                        />
                      )}
                    />
                  </div>
                )}
                {student?._id ? null : (
                  <SelectValidator
                    containerProps={{ className: "col-12 px-0" }}
                    className="mb-1"
                    label={i18n.t("student")}
                    onChange={(e) => handleChange("studentId", e.target.value)}
                    value={data.studentId || ""}
                    select
                    validators={["required"]}
                    errorMessages={[i18n.t("this_field_is_required")]}
                  >
                    {students.map((student) => {
                      if (enrolledStudentMap[student._id]) {
                        return null;
                      }
                      return (
                        <MenuItem value={student._id}>{student.name}</MenuItem>
                      );
                    })}
                  </SelectValidator>
                )}

                <div className="row mx-0 px-0 justify-content-end mt-2 col-12">
                  <PrimaryButton
                    props={{
                      type: "submit",
                    }}
                    label={data._id ? i18n.t("save") : i18n.t("create")}
                  />
                </div>
              </ValidatorForm>
            ) : (
              <>
                <ValidatorForm
                  onSubmit={handleSubmit}
                  ref={form}
                  className="row col-6 mx-0"
                  onError={(error) => {
                    console.log(error);
                  }}
                >
                  <PaymentForm handleChange={handleChange} data={data} />
                  <div className="col-12 row mx-0 justify-content-end">
                    <PrimaryButton
                      label={i18n.t("apply")}
                      props={{
                        type: "submit",
                      }}
                    />
                  </div>
                </ValidatorForm>
                {data.price ? (
                  <TransactionDetails
                    handleChange={handleChange}
                    data={data}
                    colClass="col-6"
                  />
                ) : null}
              </>
            )}
          </div>
        }
        onClose={handleClose}
        open={open}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  programs: state.program.data,
  students: state.student.data,
  classes: state._class.data,
});

const action = { insertEnrollment };

export default connect(mapStateToProps, action)(PurchaseClassModal);
