import { connect } from "react-redux";
import React from "react";
import moment from "moment";
import TextField from "@material-ui/core/TextField";

import i18n from "../../../i18n";
import CollapsibleTable from "../../CollapsibleTable";
import PrimaryButton from "../../Core/PrimaryButton";

const PayrollReportTable = ({ data = [], onAdjust = () => {} }) => {
  return (
    <div className="my-1">
      <CollapsibleTable
        rows={data}
        childKey="details"
        schema={[
          {
            key: "date",
            accessor: (val) => {
              const date = moment(val.date);
              return `${i18n.t(date.format("dddd"))} (${date.format(
                "MM-DD-YYYY"
              )})`;
            },
          },
          {
            key: "hours_worked",
            accessor: (val) => (val.totalWorkMinutes / 60).toFixed(2),
          },
          {
            key: "adjusted_hours",
            style: { width: 120 },
            render: (val, index) => {
              return (
                <div className="row mx-0 px-0">
                  <TextField
                    id={`adjustment-${val._id}-${val.date}`}
                    value={(val.totalWorkMinutes / 60).toFixed(2)}
                    variant="outlined"
                    onChange={(e) => {
                      let num = parseFloat(e.target.value);
                      onAdjust(num * 60, val, index, val._id);
                    }}
                    onBlur={(e) => {
                      let num = parseFloat(e.target.value);
                      if (isNaN(num) || num < 0) {
                        num = 0;
                      }
                      num = Math.round((num * 60 + Number.EPSILON) * 100) / 100;
                      onAdjust(num, val, index);
                    }}
                  />
                </div>
              );
            },
          },
        ]}
        childSchema={{
          title: i18n.t("details"),
          columns: [
            {
              key: "punch_in",
              accessor: (val) => {
                if (val[0]) {
                  return moment(val[0]).format("MM-DD-YYYY hh:mm A");
                }
              },
            },
            {
              key: "punch_out",
              accessor: (val) => {
                if (val[1]) {
                  return moment(val[1]).format("MM-DD-YYYY hh:mm A");
                }
              },
            },
          ],
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(PayrollReportTable);
