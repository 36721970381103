import React, { useEffect, useState } from "react";
import i18n from "../../i18n";
import { getBookingById } from "../../config/simpleApiCalls";
import Swal from "sweetalert2";
import PrimaryButton from "../../components/Core/PrimaryButton";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { DotsLoader } from "../../components";
import {
  confirmBooking,
  quickCancelBooking,
  getMessageTemplate,
} from "../../config/simpleApiCalls";
import { ErrorHelper, SuccessHelper } from "../../helpers";
import { MenuItem } from "@material-ui/core";

export default (props) => {
  const {
    match: {
      params: { id },
    },
  } = props;
  const [loading, setLoading] = useState(false);
  const [booking, setBooking] = useState({});
  useEffect(() => {
    setLoading(true);
    getBookingById({ id, relations: ["forms"] })
      .then(({ data }) => {
        const booking = data.data;
        console.log(booking);
        setBooking(booking);
      })
      .catch((e) => {
        ErrorHelper.handleErrors("Something went wrong", true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div>
      {/* <Home {...props} /> */}
      <DotsLoader isloading={loading} />
      <Dialog
        open={true}
        onClose={() => {
          props.history.push("/");
        }}
        aria-labelledby="form-send-title"
        aria-describedby="form-send-description"
      >
        <DialogTitle id="form-send-title" className="primary-header">
          Formulaire | Forms
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="form-send-description">
            {(booking.formTemplateAnswerIds || []).map((form) => {
              return (
                <MenuItem
                  onClick={() => {
                    window.open(`/form-fill/${form._id}`, "_blank");
                  }}
                >
                  {form.formId.formTitle}
                </MenuItem>
              );
            })}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};
