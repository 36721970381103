import { connect } from "react-redux";
import React, { Component } from "react";

import { DotsLoader } from "../index";

import {
  getPlains,
  buyPlains,
  getCardUserInfo,
  getCardMethods,
  addPlansPayment,
  getAllBundle,
  createAllBundles,
  getCompaniesBundle,
  updateBundlePlan,
} from "../../config/simpleApiCalls";
// import {} from "../../config/WebServices";
import { Images } from "../../theme";
import { SuccessHelper, ErrorHelper } from "../../helpers";
import Swal from "sweetalert2";

import Sytles from "./styles";
import "./styles.css";

class PricingPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isloading: false,
      eQuipmentTotalPrice: 0,
      totalPrice: 0,
      licensingTotalPrice: 0,
      package_planTotalPrice: 0,
      showPopUp: false,
      superAdminData: null,
      categoryName: "",
      title: "",
      categroyImageUrl: null,
      categroyImageFile: null,
      categroyImageName: "",
      formErrorText: "",
      freeTrial: false,
      twoToFive: false,
      fiveToTen: false,
      tenToFifteen: false,
      allPlans: [],
      showModal: false,
      isloading: true,
      customPlans: true,
      plans: [],
      device_equipment: [],
      all_total_ammount: 0,
      package_plan: [],
      myBundle: [],
      quarter: {
        monthly: 1,
        quarterly: 0,
        yearly: 0,
      },
    };
  }

  async componentDidMount() {
    const salonInfo = await JSON.parse(sessionStorage.getItem("saloon"));
    this.setState({ salonInfo }, () => {
      this.getAllPlans();
    });
  }

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  getAllPlans = () => {
    const { salonInfo } = this.state;
    getPlains()
      .then((res) => {
        if (res.data.success) {
          this.setState({
            isloading: false,
            allPlans: res.data.data,
          });
        } else {
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        ErrorHelper.handleErrors("Something Went Wrong", true);
        this.setState({ isloading: false });
      });
  };

  onSetSidebarOpen = (open) => {
    this.setState({ sidebarOpen: open });
  };

  closeModal() {
    this.setState({ showPopUp: false });
  }

  renderTickMark = (data) => {
    if (data.isActive === true) {
      return (
        <img
          onClick={() => {}}
          style={{ height: 18, width: 18 }}
          src={Images.mark_tick}
        />
      );
    } else if (data.isActive === false) {
      return (
        <img
          style={{ height: 18, width: 18 }}
          onClick={() => {}}
          src={Images.cancel_cross}
        />
      );
    }
  };

  handleNavigation = (route) => {
    this.props.props.history.push(route);
  };

  handleBuyApi(val) {
    console.log("val: ", val);
    sessionStorage.setItem("pricing_plans", JSON.stringify(val));
    //
    const { salonInfo } = this.state;

    if (salonInfo !== null && salonInfo.access_token) {
      this.handleNavigation("/saloon-pricing-plains");
    } else {
      this.handleNavigation("/saloon-login");
    }
  }

  pricingCard = () => {
    const { allPlans } = this.state;

    return (
      <div class="pricing">
        <div class="row pr-2 pl-2 pricing-div-align">
          {/* <h2>sdfsdfsdfdfsdfsf</h2> */}
          {allPlans.map((val, index) => {
            return (
              <div class="col-lg-3">
                <div class="card mb-5 mb-lg-0">
                  <div class="card-body">
                    <div>
                      <h5 class="card-title text-muted text-uppercase text-center">
                        {val.plan}
                      </h5>
                      <h6 class="card-price text-center">${val?.price}</h6>
                      <h5 class="card-title text-muted text-uppercase text-center per-staff">
                        Per Staff
                      </h5>
                    </div>
                    <hr />
                    <div>
                      <ul style={{ listStyleType: "none" }}>
                        {val?.items?.map((itemVal, index) => {
                          return (
                            <li style={{ marginLeft: "-34px" }}>
                              <div className="d-flex align-items-center">
                                <div style={{ marginRight: 10 }}>
                                  {this.renderTickMark(itemVal)}
                                </div>
                                <div style={{ fontSize: 10 }}>
                                  {itemVal.name}
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div>
                      {!val.isActive ? (
                        <button
                          data-toggle="modal"
                          data-target="#exampleModal"
                          className="btn btn-block btn-primary"
                          // onClick={() =>
                          //   this.setState({
                          //     showPopUp: true,
                          //     ammount: val.price,
                          //     _planInfo: val,
                          //   })
                          // }
                          onClick={() => this.handleBuyApi(val)}
                          style={
                            val.price
                              ? {
                                  backgroundColor: "#FF3600",
                                  borderColor: "#FF3600",
                                }
                              : {
                                  backgroundColor: "lightgray",
                                  borderColor: "lightgray",
                                }
                          }
                          disabled={!val.price ? true : false}
                        >
                          Buy
                        </button>
                      ) : (
                        <button
                          className="btn btn-block btn-primary"
                          style={{
                            backgroundColor: "lightgray",
                            borderColor: "lightgray",
                          }}
                          disabled
                        >
                          Activated!
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  render() {
    let { customPlans, salonInfo, myBundle } = this.state;

    return (
      <div
        className="container-fluid min-vh-100 px-0"
        style={{ flex: 1, backgroundColor: "#F7F7F7" }}
      >
        <div className="container py-5">
          <div className="row">
            <div className="col-12 mt-4 mb-5 bg-white shadow rounded px-0">
              {customPlans && myBundle?._id && this.renderMyBundles()}
              {this.pricingCard()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(PricingPlan);
