import React, { Component, useState } from "react";
import { connect } from "react-redux";
// import {
//   getRequest,
//   removeRequest,
//   updateRequest,
//   insertRequest,
//   setDefaultRequest,
// } from "../../../redux/actions/CompanyCard";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import Button from "@material-ui/core/Button";
import i18n from "../../../i18n";
import BaseModal from "../../BaseModal";
import Images from "../../../theme/Images";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { SuccessHelper } from "../../../helpers";
import { ErrorHelper } from "../../../helpers";
import Swal from "sweetalert2";
import PlacesAutocomplete from "../../PlacesAutocomplete";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import "./style.scss";
import { getNestedValue } from "../../../util/objectMethods";

const {
  saloon_form_master_card: master_card,
  saloon_form_american_express: amex,
  saloon_form_visa: visa,
} = Images;
const cardBrandImageMap = {
  master_card,
  amex,
  visa,
};
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};
// end points:
// company cards
// cards

const CardManagement = ({
  access_token,
  cards = [],
  defaultCard,
  insertRequest,
  removeRequest,
  getRequest,
  setDefaultRequest,
  removeDefault,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [showAddModal, setShowAddModal] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [name, setName] = useState("");
  const [addressMap, setAddressMap] = useState({});
  const [errorMap, setErrorMap] = useState({});

  console.log("access token", access_token);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const createIntentForAdd = () => {
    const newErrorMap = {};
    if (Object.keys(addressMap).length < 2) {
      newErrorMap.address = i18n.t("full_address_is_required");
    }
    if (!completed) {
      newErrorMap.card = true;
    }
    if (!name) {
      newErrorMap.name = i18n.t("name_is_required");
    }
    if (Object.keys(newErrorMap) == 0) {
      insertRequest(
        { access_token },
        {
          success: async (data) => {
            const result = await stripe
              .confirmCardSetup(data.client_secret, {
                payment_method: {
                  card: elements.getElement(CardElement),
                  billing_details: {
                    name,
                    address: {
                      city: addressMap.city,
                      country: addressMap.country_abbr,
                      line1: addressMap.address,
                      line2: addressMap.address_line_2,
                      postal_code: addressMap.postalCode,
                      state: addressMap.province_abbr,
                    },
                  },
                },
              })
              .then(() => {
                getRequest({ access_token });
                SuccessHelper.handleSuccess(i18n.t("successfully_added"), true);
                setShowAddModal(false);
              });
          },
        }
      );
    }
    setErrorMap(newErrorMap);
  };

  const removeCard = (body) => {
    Swal.fire({
      title: "Delete This?",
      text: "Are you sure you want to delete this!",
      showCancelButton: true,
      cancelButtonText: i18n.t("no"),
      confirmButtonText: i18n.t("yes"),
    }).then((result) => {
      if (result.value) {
        removeRequest(body, {
          success: () => {
            SuccessHelper.handleSuccess(i18n.t("successfully_deleted"), true);
          },
          failure: () => {
            ErrorHelper.handleErrors(i18n.t("failed_to_delete"), true);
          },
        });
      }
    });
  };

  const onSetDefaultCard = (paymentMethodId) => {
    setDefaultRequest(
      { access_token, paymentMethodId },
      {
        success: () => {
          SuccessHelper.handleSuccess(
            i18n.t("successfully_set_default_card"),
            true
          );
          getRequest({ access_token });
        },
        failure: () => {
          ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
        },
      }
    );
  };

  return (
    <div className="p-3 card-management">
      <BaseModal
        open={showAddModal}
        {...{
          title: i18n.t("add_card"),
          actions: (
            <div className="row justify-content-end mx-0">
              <Button
                className="primary-button"
                onClick={() => {
                  createIntentForAdd();
                }}
              >
                {i18n.t("save")}
              </Button>
            </div>
          ),
          content: (
            <div className="row mx-0">
              <TextField
                id="name"
                className="col-12 col-md-6 px-0 px-md-1"
                label={i18n.t("name")}
                value={name}
                onChange={(e) => setName(e.target.value)}
                helperText={errorMap.name}
                error={!!errorMap.name}
              />
              <PlacesAutocomplete
                className="pt-2 pt-md-0 col-12 col-md-6 px-0 px-md-1"
                addressSelected={(newMap) =>
                  setAddressMap({
                    ...newMap,
                    address_line_2: getNestedValue(
                      addressMap,
                      ["address_line_2"],
                      ""
                    ),
                  })
                }
                helperText={errorMap.address}
                error={!!errorMap.address}
              />
              <TextField
                id="address_line_32"
                className="pt-2 col-12 col-md-6 px-0 px-md-1"
                label={i18n.t("address_line_2")}
                value={getNestedValue(addressMap, ["address_line_2"], "")}
                onChange={(e) =>
                  setAddressMap({
                    ...addressMap,
                    address_line_2: e.target.value,
                  })
                }
                helperText={errorMap.address_line_2}
                error={!!errorMap.address_line_2}
              />
              <FormControl
                className="pt-3 col-12 col-md-6 px-0 px-md-1 align-self-end"
                error={!!errorMap.card}
              >
                <CardElement
                  options={CARD_ELEMENT_OPTIONS}
                  onChange={(e) => {
                    setCompleted(e.complete);
                  }}
                />
                {!!errorMap.card && (
                  <FormHelperText>{i18n.t("card_is_required")}</FormHelperText>
                )}
              </FormControl>
            </div>
          ),
        }}
        onClose={() => setShowAddModal(false)}
      />
      <div className="row mx-0 justify-content-end">
        <Button
          onClick={() => setShowAddModal(true)}
          className="primary-button"
        >
          {i18n.t("add")}
        </Button>
      </div>
      {cards.length ? (
        <div className="pt-3">
          {cards.map((card) => {
            const { brand, last4, exp_month, exp_year } = card.card;
            const {
              billing_details: {
                address: { city, country, line1, line2, postal_code, state },
                name,
              },
            } = card;
            return (
              <Accordion
                key={card.id}
                expanded={expanded === card.id}
                onChange={handleChange(card.id)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <div className="row mx-0 justify-content-between w-100">
                    <div className="row mx-0">
                      <div
                        style={{
                          backgroundImage: `url(${cardBrandImageMap[brand]})`,
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          width: 40,
                          height: 40,
                        }}
                      ></div>
                      <div
                        style={{
                          textTransform: "capitalize",
                          alignSelf: "center",
                        }}
                        className="pl-1"
                      >
                        {i18n.t("card_ending_in", { brand, last4 })}
                      </div>
                    </div>
                    <div style={{ alignSelf: "center" }}>
                      {exp_month}/{exp_year}
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails className="d-block">
                  <div className="row mx-0">
                    <div className="col-6 col-xs-12">
                      {removeDefault ? (
                        ""
                      ) : (
                        <React.Fragment>
                          <div>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={defaultCard == card.id}
                                  disabled={defaultCard == card.id}
                                  onChange={() => onSetDefaultCard(card.id)}
                                />
                              }
                              label={i18n.t("default_card")}
                            />
                          </div>
                          <div>
                            {defaultCard == card.id &&
                              i18n.t("cannot_delete_default_card")}
                          </div>
                        </React.Fragment>
                      )}

                      <strong>{i18n.t("name_on_card")}</strong>
                      <div>{name}</div>
                    </div>
                    <div className="col-6 col-xs-12">
                      <strong>{i18n.t("billing_address")}</strong>
                      <div>
                        {line2} {line1}
                      </div>
                      <div>
                        {city}, {state}, {postal_code}
                      </div>
                      <div>{country}</div>
                    </div>
                  </div>
                  {cards.length > 1 && (
                    <div className="row mx-0 justify-content-end">
                      <Button
                        className="primary-button"
                        onClick={() => {
                          removeCard({ cardId: card.id, access_token });
                        }}
                        disabled={defaultCard == card.id}
                      >
                        Remove
                      </Button>
                    </div>
                  )}
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      ) : (
        <div className="pt-3"></div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({});

// const action = {
//   removeRequest,
//   updateRequest,
//   insertRequest,
//   getRequest,
//   setDefaultRequest,
// };

export default connect(mapStateToProps)(CardManagement);
