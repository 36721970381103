import React, { setState } from "react";
import TextField from "@material-ui/core/TextField";
import styled from "styled-components";
import DetailsField from "./DetailsField.component";

const TextfieldWrapper = styled.div`
  width: 100%;
`;
const GenerateTextField = ({
  field,
  handleSubmit,
  error,
  readonly,
  answer,
  detailsRequired,
  details,
}) => {
  const handleOnChange = (e, choice = null, type) => {
    if (handleSubmit !== undefined) {
      handleSubmit(e.target.value, field._id, type);
    }
  };

  // console.log({ details });
  return (
    <TextfieldWrapper>
      <h6>{field.fieldName}</h6>
      <TextField
        onChange={(e) => handleOnChange(e, null, "answer")}
        value={answer}
        type={field.type}
        variant="outlined"
        error={!!error}
        helperText={error}
        inputProps={{ readOnly: readonly }}
        name
      />
      {detailsRequired && (
        // <TextField
        //   onChange={(e) => handleOnChange(e, "details")}
        //   value={details}
        //   type="text"
        //   variant="outlined"
        //   inputProps={{ readOnly: readonly }}
        // />
        <DetailsField
          handleOnChange={handleOnChange}
          details={details}
          readonly={readonly}
        />
      )}
    </TextfieldWrapper>
  );
};

export default GenerateTextField;
