import { connect } from "react-redux";
import React, { Component } from "react";
import { Translation } from "react-i18next";
import i18n from "../../../i18n";
import { DotsLoader } from "../../../components";
import { request as SaloonAppointment } from "../../../redux/actions/Saloon/SaloonAppointment";
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import frenchIcon from "../../../../src/assets/Images/france.png";
import englishIcon from "../../../../src/assets/Images/eng.png";
import { request as get_available_timeslots } from "../../../redux/actions/GetAvailableTimeslots.js";
import { request as get_gym_timeslots } from "../../../redux/actions/GetGymTimeslots.js";
import {
  createLiveDisplaySettings,
  getLiveDisplaySettings,
  createLiveDisplaySlideResource,
  deleteLiveDisplaySlideResource,
} from "../../../config/simpleApiCalls";
import "./styles.css";
import { SRLWrapper } from "simple-react-lightbox";
import PrimaryButton from "../../../components/Core/PrimaryButton";
import Swal from "sweetalert2";
import Select from "../../../components/Core/Select";
import NumberInput from "../../../components/NumberInput/index";

const imageSizeLimit = 10485760;
const videoSizeLimit = 104857600;

class LiveDisplaySettings extends Component {
  constructor(props) {
    i18n.changeLanguage(sessionStorage.getItem("language"));

    super(props);
    this.state = {
      rowsMap: {},
      language: "English",
      slides: [],
      fields: [
        { value: "category", text: i18n.t("container.category") },
        { value: "time", text: i18n.t("container.time") },
        { value: "employee", text: i18n.t("container.employee") },
        {
          value: "name",
          text: i18n.t("container.advancedSearchDialog_name"),
        },
        { value: "status", text: i18n.t("container.profile_status") },
        { value: "seat", text: i18n.t("seat") },
      ],
      slideDuration: 15,
      showEntireDaysBookings: "no",
    };
  }

  async componentDidMount() {
    const saloonData = await JSON.parse(sessionStorage.getItem("saloon"));
    this.setState({
      access_token: saloonData.access_token,
      isloading: true,
    });
    getLiveDisplaySettings({}, saloonData.access_token).then((res) => {
      let { language, slides, slideDuration, showEntireDaysBookings } =
        this.state;
      const rowsMap = {};
      if (res) {
        res.columns.map((column) => {
          rowsMap[column.key] = column.hidden;
        });
        language = res.language;
        slides = res.slides;
        slideDuration = res.slideDuration || 15;
        showEntireDaysBookings = res.showEntireDaysBookings ? "yes" : "no";
      }
      this.setState({
        rowsMap,
        language,
        slides,
        slideDuration,
        showEntireDaysBookings,
        isloading: false,
      });
    });
  }

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  onCheckChange = (e, field) => {
    const { rowsMap } = this.state;
    rowsMap[field.value] = e.target.checked;
    this.setState({
      rowsMap,
    });
  };

  save = () => {
    const {
      access_token,
      rowsMap,
      fields,
      language,
      slideDuration,
      showEntireDaysBookings,
    } = this.state;
    this.setState({ isloading: true });
    const columns = fields.map(({ value }) => {
      return {
        key: value,
        hidden: value in rowsMap ? rowsMap[value] : false,
      };
    });
    createLiveDisplaySettings(
      {
        columns,
        language,
        slideDuration,
        showEntireDaysBookings: showEntireDaysBookings == "yes",
      },
      access_token
    )
      .then(() => {
        this.setState({ isloading: false });
      })
      .catch(() => {
        this.setState({ isloading: false });
      });
  };

  LanguageSelector = () => {
    const languageOptions = [
      {
        value: "English",
        name: "English",
      },
      {
        value: "Français",
        name: "Français",
      },
    ];
    return (
      <Select
        label={i18n.t("language")}
        value={this.state.language}
        options={languageOptions}
        onChange={(e) => {
          this.setState({ language: e.target.value });
        }}
      />
    );
  };

  fileSelectedHandler = (event) => {
    const { access_token, slides } = this.state;
    const imageUrlRegex = /([a-zA-Z0-9\s_\\.\-:])+(.png|.jpeg|.jpg|.gif)$/gm;
    const videoUrlRegex = /([a-zA-Z0-9\s_\\.\-:])+(.mp4)$/gm;
    let payloadFormdata = new FormData();
    const file = event.target.files[0];
    if (file) {
      console.log(file, imageSizeLimit);
      let checkFile = event.target.files[0].type.split("/");
      let isValidateType = file.type;
      if (checkFile[0] === "image") {
        if (isValidateType.match(imageUrlRegex)) {
          if (file.size > imageSizeLimit) {
            return ErrorHelper.handleErrors(i18n.t("image_too_large"), true);
          }
          payloadFormdata.append("image", file);
        } else {
          ErrorHelper.handleErrors("Image Format not supported", true);
        }
      } else if (checkFile[0] === "video") {
        if (isValidateType.match(videoUrlRegex)) {
          if (file.size > videoSizeLimit) {
            return ErrorHelper.handleErrors(i18n.t("video_too_large"), true);
          }
          payloadFormdata.append("video", file);
        } else {
          ErrorHelper.handleErrors(
            "Video Format not supported,Please upload an mp4 file",
            true
          );
        }
      } else {
        return;
      }
      this.setState({ isloading: true });
      createLiveDisplaySlideResource(payloadFormdata, access_token)
        .then(({ data }) => {
          this.setState({ isloading: false });
          if (data.success) {
            this.setState({ slides: [...slides, data.data] });
            SuccessHelper.handleSuccess(i18n.t("successfully_added"), true);
          } else {
            if (data.code) {
              ErrorHelper.handleErrors(i18n.t(data.code, data.errorData), true);
            } else {
              throw "";
            }
          }
        })
        .catch((e) => {
          ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
        });
      //send requiest
    }
  };

  handleDelete = ({ _id }, index) => {
    Swal.fire({
      title: i18n.t("are_you_sure_delete"),
      text: i18n.t("cannmot_reverse"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ff3600",
      cancelButtonColor: "#354e68",
      confirmButtonText: i18n.t("yes"),
    }).then((result) => {
      if (result.value) {
        const { access_token, slides } = this.state;
        this.setState({ isloading: true });
        deleteLiveDisplaySlideResource({ id: _id }, access_token)
          .then(({ data }) => {
            this.setState({ isloading: false });
            if (data.success) {
              slides.splice(index, 1);
              this.setState({ slides });
              SuccessHelper.handleSuccess(i18n.t("successfully_deleted"), true);
            } else {
              throw "";
            }
          })
          .catch((e) => {
            ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
          });
      }
    });
  };

  renderSlideImages = () => {
    const { slides } = this.state;
    return (
      <div>
        <div
          class="row justify-content-between mx-0"
          style={{ marginTop: "14px" }}
        >
          <h5>{i18n.t("live_display_slides")}</h5>
          <PrimaryButton
            label={i18n.t("add")}
            onClick={() => this.fileInput.click()}
          />
        </div>
        {i18n.t("maximum_file_size_warning")}
        <SRLWrapper>
          <div class="row mx-0">
            {slides.map((val, index) => {
              return (
                <div
                  key={val._id}
                  class="imageParent"
                  style={{ marginTop: "8px" }}
                >
                  <>
                    {val.content.resourceType == "video" ? (
                      <video width="100%" height="145px" controls>
                        <source src={val.content.url} />
                      </video>
                    ) : (
                      <img className="img-fluid1" src={val.content.url} />
                    )}

                    <div
                      class="middle"
                      onClick={() => {
                        this.handleDelete(val, index);
                      }}
                    >
                      <div class="text">{i18n.t("delete")}</div>
                    </div>
                  </>
                </div>
              );
            })}
          </div>
        </SRLWrapper>
      </div>
    );
  };

  render() {
    const { rowsMap, fields } = this.state;
    return (
      <div>
        <input
          type="file"
          style={{ display: "none" }}
          onChange={this.fileSelectedHandler}
          ref={(fileInput) => (this.fileInput = fileInput)}
          accept="image/*,video/*"
        />
        {this.renderLoader()}
        <div className="content-container">
          <div className="row mx-0">
            <div className="col-12 mb-2">
              <span className="saloon-dashboard-heading">
                {i18n.t("container.live_display_settings")}
              </span>
            </div>
            <table className="table table-borderless bg-white mb-0">
              <thead>
                <th>
                  <Translation>{(t) => t("field")}</Translation>
                </th>
                <th>
                  <Translation>{(t) => t("hidden")}</Translation>
                </th>
              </thead>
              <tbody>
                {fields.map((field) => {
                  return (
                    <tr>
                      <td
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        {field.text}
                      </td>
                      <td>
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id={field.value}
                            checked={rowsMap[field.value]}
                            onChange={(e) => this.onCheckChange(e, field)}
                          />
                          <label
                            class="custom-control-label"
                            for={field.value}
                          ></label>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="full-width bg-white divider">
              <div className="p-2">
                {this.LanguageSelector()}
                <div className="pt-2">
                  <NumberInput
                    label={i18n.t("slide_durations")}
                    value={this.state.slideDuration}
                    onChange={(slideDuration) => {
                      this.setState({ slideDuration });
                    }}
                    limits={{ min: 5, max: 30 }}
                  />
                </div>
                <div className="pt-2">
                  <Select
                    label={i18n.t("show_entire_days_booking")}
                    value={this.state.showEntireDaysBookings}
                    excludeEmptyValue
                    options={[
                      {
                        value: "yes",
                        name: i18n.t("yes"),
                      },
                      {
                        value: "no",
                        name: i18n.t("no"),
                      },
                    ]}
                    onChange={(e) => {
                      this.setState({ showEntireDaysBookings: e.target.value });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex full-width bg-white justify-content-end divider">
              <button
                onClick={() => this.save()}
                type="button"
                className="btn btn-light btn-sm m-1"
                id="saloon-save-social-link"
              >
                Save
              </button>
            </div>
          </div>
          {this.renderSlideImages()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  booking: state.SalonAppointment,
  getAvailableTimeslots: state.getAvailableTimeslots,
  getGymTimeslots: state.getGymTimeslots,
});

const action = {
  SaloonAppointment,
  get_available_timeslots,
  get_gym_timeslots,
};

export default connect(mapStateToProps, action)(LiveDisplaySettings);
