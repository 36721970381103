import React from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import "../Styles/fields.sass";

const Fields = ({
  fieldsToRender,
  column,
  handleDeletField,
  handleEditField,
}) => {
  // const editQuestionRef = useRef(null);
  // const scrollToEdit = () => editQuestionRef.current.scrollIntoView();

  const handleDelet = (fieldId) => {
    handleDeletField(fieldId);
  };

  const renderPreviewfields = () => {
    return fieldsToRender.map((field, i) => {
      return (
        <Draggable key={field.id} draggableId={field.id} index={i}>
          {(provided) => (
            <div
              key={`daragable-${field.id}`}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              className="fieldWrapper"
            >
              <div className="previewField">
                <DragIndicatorIcon />

                <span
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    maxHeight: "1em",
                  }}
                >
                  {field.fieldName}
                </span>
                <EditRoundedIcon
                  className="fieldIcon"
                  onClick={() => handleEditField(field)}
                />
                <DeleteOutlineRoundedIcon
                  onClick={() => handleDelet(field.id)}
                  className="fieldIcon"
                />
              </div>
            </div>
          )}
        </Draggable>
      );
    });
  };

  return (
    <Droppable droppableId={column.id}>
      {(provided) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          className="fieldsContainer"
        >
          {renderPreviewfields()}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default Fields;
