import { put, takeEvery } from "redux-saga/effects";

import * as types from "../../actions/ActionTypes";
import { set, requestFailure } from "../../actions/Saloon/Billing";

import axios from "axios";
import { billing } from "../../../config/WebServices";


function* getSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    const token = payload.access_token
    delete payload.access_token
    data = yield axios.get(`${billing}/getInvoices`, {
      params: payload,
      headers: {
        Authorization: token,
      },
    });
    data = data.data
    
    if(data.success) {
      if(callbackMap.success && typeof callbackMap.success == 'function') {
        callbackMap.success(data)
      }
      yield put(set(data.invoices));
    } else {
      if(callbackMap.failure && typeof callbackMap.failure == 'function') {
        callbackMap.failure(data)
      }
      yield put(requestFailure(data));
    }
  } catch (err) {
    if(callbackMap.failure && typeof callbackMap.failure == 'function') {
      callbackMap.success(data)
    }
    yield put(requestFailure(data));
  }
}

export default function* root() {
  yield takeEvery(types.BILLING.GET_SAGA, getSaga);
}
