import { connect } from "react-redux";
import React, { Component, useEffect, useMemo, useRef, useState } from "react";
import i18n from "../../../i18n";
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import NumberFormat from "react-number-format";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import PrimaryButton from "../../Core/PrimaryButton";
import BasicTable from "../../Core/BasicTable";
import { getNestedValue } from "../../../util/objectMethods";
import { TableCell, TableRow } from "@material-ui/core";
import Avatar from "react-avatar";
import BaseModal from "../../BaseModal";
import moment from "moment";
import BookingNotes from "../../BookingNotes";

const bookingStatuses = [
  { text: "Waiting", value: 1, styles: { color: "#aa43b5" } },
  { text: "Now Serving", value: 3, styles: { color: "#FFE478" } },
  { text: "Cancel", value: 2, styles: { color: "#FF7082" } },
  { text: "Done", value: 4, styles: { color: "#95DA47" } },
];

export default function CustomerDetails({ bookings, isGym, access_token }) {
  const [selectBooking, setSelectBooking] = useState(null);
  const [openNotes, setOpenNotes] = useState(null);
  const [openInfo, setOpenInfo] = useState(null);
  const [summary, renderBooking] = useMemo(() => {
    const summary = {
      total: 0,
      tip: 0,
      taxes: 0,
      grandTotal: 0,
    };
    let renderBooking = bookings
      .map((booking) => {
        if (booking.status == 1) {
          return null;
        }
        booking.taxTotal = getNestedValue(booking, "taxes", []).reduce(
          (accum, element) => accum + element.amount,
          0
        );
        booking.total =
          booking.totalAmount + (booking.tip || 0) + booking.taxTotal;
        if (booking.status == 4) {
          summary.total += booking.totalAmount || 0;
          summary.tip += booking.tip || 0;
          summary.taxes += booking.taxTotal;
          summary.grandTotal += booking.total;
        }
        return booking;
      })
      .filter((booking) => !!booking);
    return [summary, renderBooking];
  }, []);
  const additionalColumn = useMemo(() => {
    if (isGym) {
      return [];
    }
    return [
      {
        header: i18n.t("by_price"),
        field: "price",
        accessor: (val) => {
          return `$ ${val.totalAmount.toFixed(2)}`;
        },
      },
      {
        header: i18n.t("taxes"),
        field: "taxes",
        accessor: (val) => {
          return `$ ${val.totalAmount.toFixed(2)}`;
        },
      },
      {
        header: i18n.t("tip"),
        field: "tip",
        accessor: (val) => {
          return `$ ${(val.tip || 0).toFixed(2)}`;
        },
      },

      {
        header: i18n.t("grand_total"),
        field: "grand_total",
        accessor: (val) => {
          return `$ ${val.total.toFixed(2)}`;
        },
      },
    ];
  }, [isGym]);
  return (
    <div>
      <BasicTable
        rows={renderBooking}
        prependRows={
          summary ? (
            <TableRow>
              <TableCell>
                <strong>{i18n.t("summary")}: </strong>
              </TableCell>
              <TableCell />
              <TableCell />
              <TableCell>
                <PrimaryButton label={`$${summary?.total.toFixed(2)}`} />
              </TableCell>
              <TableCell>
                <PrimaryButton label={`$${summary?.taxes.toFixed(2)}`} />
              </TableCell>
              <TableCell>
                <PrimaryButton label={`$${summary?.tip.toFixed(2)}`} />
              </TableCell>
              <TableCell>
                <PrimaryButton label={`$${summary?.grandTotal.toFixed(2)}`} />
              </TableCell>
              <TableCell />
              <TableCell />
              <TableCell />
            </TableRow>
          ) : null
        }
        schema={[
          {
            header: i18n.t("container.advancedSearchDialog_name"),
            field: "name",
            render: (val) => {
              return (
                <div className="d-flex align-items-center">
                  <Avatar
                    name={val.userId && val.userId.userName}
                    size="30"
                    color="#4862FF"
                    round={true}
                    textSizeRatio={2.0}
                  />
                  <div>
                    {
                      <span
                        style={{ marginLeft: "10px" }}
                        className="appointment-details-employee-name"
                      >
                        {val.userId && val.userId.userName}
                      </span>
                    }
                    <br />
                    <span
                      style={{ marginLeft: "10px", fontSize: "14px" }}
                      className="appointment-details-employee-other"
                    >
                      {getNestedValue(val, ["services", 0, "date"], "")}{" "}
                      {getNestedValue(val, ["services", 0, "time"], "")}
                    </span>
                  </div>
                </div>
              );
            },
          },
          {
            header: i18n.t("container.employe_phone_number"),
            field: "phone",
            render: (val) => {
              return (
                <div className="d-flex align-items-center mt-1">
                  <span className="appointment-details-employee-name">
                    {" "}
                    <span>
                      <NumberFormat
                        type={"text"}
                        style={{ border: 0, backgroundColor: "white" }}
                        value={val.phoneNo}
                        disabled
                        format="### ### ####"
                      />
                      <div className="appointment-details-employee-name">
                        {" "}
                        {val.email || ""}{" "}
                      </div>
                    </span>
                  </span>
                </div>
              );
            },
          },
          {
            header: i18n.t("container.profile_service"),
            field: "service",
            accessor: (val) => {
              return getNestedValue(
                val,
                ["services", 0, "serviceId", "name"],
                ""
              );
            },
          },
          ...additionalColumn,
          {
            header: i18n.t("container.profile_status"),
            field: "status",
            render: (val) => {
              return (
                <span
                  style={{
                    fontWeight: "bold",
                    ...getNestedValue(
                      bookingStatuses,
                      [val.status - 1, "styles"],
                      {}
                    ),
                  }}
                  className="appointment-details-employee-name"
                >
                  {getNestedValue(
                    bookingStatuses,
                    [val.status - 1, "text"],
                    ""
                  )}
                  {val.isNoShow ? ` - ${i18n.t("no_show")}` : ""}
                </span>
              );
            },
          },
          {
            header: i18n.t("Info"),
            field: "info",
            render: (val) => {
              return (
                <PrimaryButton
                  label={i18n.t("Info")}
                  onClick={() => {
                    setSelectBooking(val);
                    setOpenInfo(true);
                  }}
                />
              );
            },
          },
          {
            header: i18n.t("Notes"),
            field: "notes",
            render: (val) => {
              return (
                <PrimaryButton
                  label={i18n.t("Notes")}
                  onClick={() => {
                    setSelectBooking(val);
                    setOpenNotes(true);
                  }}
                />
              );
            },
          },
        ]}
      />
      <BaseModal
        open={!!selectBooking && openInfo}
        onClose={() => {
          setSelectBooking();
          setOpenInfo(false);
        }}
        title={i18n.t("info")}
        content={
          <div>
            {selectBooking &&
              selectBooking.services.map((service, index) => (
                <>
                  <div
                    className="myappoint-input-container row mx-0"
                    style={{
                      width: "auto",
                      flexDirection: "row",
                    }}
                  >
                    <div className="form-group my-apointment-input">
                      <div className="pt-3 justify-content-center align-items-center">
                        <div className="spaceAround">
                          <span>
                            {index + 1}. {i18n.t("container.profile_service")}
                          </span>
                          <span className="textSize">
                            {moment(`${service.date} ${service.time}`).format(
                              "MMMM Do YYYY h:mm a"
                            )}
                          </span>
                        </div>

                        <input
                          disabled
                          type="text"
                          id="addEmployee_search_bar leftSpace"
                          className="form-control form-control-sm appointment-input lowLeftSpace"
                          placeholder="Service"
                          value={service.serviceId.name}
                        />
                      </div>
                    </div>
                    {!isGym && (
                      <div className="form-group  my-apointment-input">
                        <div className="pt-3 justify-content-center align-items-center">
                          <div className="spaceAround">
                            <span> Employee Name</span>
                          </div>

                          <input
                            disabled
                            type="text"
                            id="addEmployee_search_bar leftSpace"
                            className="form-control form-control-sm appointment-input lowLeftSpace"
                            placeholder="Employe Name"
                            value={
                              getNestedValue(service.employeeId, "userId")
                                ? getNestedValue(
                                    service.employeeId.userId,
                                    "userName"
                                  ) ||
                                  `${getNestedValue(
                                    service.employeeId.userId,
                                    "firstName",
                                    ""
                                  )} ${getNestedValue(
                                    service.employeeId.userId,
                                    "lastName",
                                    ""
                                  )}`
                                : ""
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </>
              ))}
          </div>
        }
      />
      <BaseModal
        open={!!selectBooking && openNotes}
        onClose={() => {
          setSelectBooking();
          setOpenNotes(false);
        }}
        title={i18n.t("info")}
        content={
          <BookingNotes
            bookingId={selectBooking?._id}
            access_token={access_token}
            hasEditPermission={true}
          />
        }
      />
    </div>
  );
}
