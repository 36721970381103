import initializedFirebaseApp from "./dataBase/firebase";
var isChrome =
  /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
let messaging;
if (isChrome) {
  messaging = initializedFirebaseApp.messaging();
  messaging.usePublicVapidKey(
    // Project Settings => Cloud Messaging => Web Push certificates
    "BPyjyTdvnAn9PqWV3nUC7CnFdUb5faxeBHr_tOI8wGNtd7lbYIQH4PX-ltOHbOLx9k3QbOPbQOVXes8znkah_Q4"
  );
} else {
  messaging = false;
}
export { messaging };
