import { connect } from "react-redux";
import React, { Component } from "react";
import { Translation } from "react-i18next";
import i18n from "../../../i18n";
import {
  requestFinancialAccess
} from "../../../config/simpleApiCalls";
import { getRequest, removeRequest, updateRequest, insertRequest } from "../../../redux/actions/Card";
import { Images } from "../../../theme";
import {
  DotsLoader,
  Header,
  AdvancedSearchDialog,
  BookingNotes,
} from "../../../components";
import moment from "moment";

// import Sytles from "./sytles";
class AccountSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      access_token: "",
      activeTab: 0
    };
  }

  componentDidMount = async () => {    
    const saloonData = await JSON.parse(sessionStorage.getItem("saloon"));
    this.setState({
      access_token: saloonData.access_token,
    });
    this.props.getRequest({ access_token: saloonData.access_token })
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  render() {
    return (
      <div>
        {this.renderLoader()}
        <div className="content-container">
          <div className="row mx-0">
            <div className="col-12">
              <span className="saloon-dashboard-heading">
                {i18n.t("container.billing_history")}
              </span>
            </div>
            <span className="p-3 w-100">
            </span>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ cards: state.card.data, defaultCard: state.card.defaultCard });

const action = { getRequest, removeRequest, updateRequest, insertRequest };

export default connect(mapStateToProps, action)(AccountSettings);
