import Immutable from "seamless-immutable";
import * as types from "../actions/ActionTypes";

const initialState = Immutable({
    data: {}
});

export default (state: Object = initialState, action: Object) => {
    switch (action.type) {
        case types.TEMPLATE_NAVIGATION:
            return Immutable.merge(state, {
                data: action.data
            });
        default:
            return state;
    }
};