import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Translation } from 'react-i18next';
import i18n from '../../../i18n';

import { logout as user_logout } from "../../../redux/actions/Login";
import { SuccessHelper } from "../../../helpers";



class SideNav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            routeName: ''
        }
    }

    static propTypes = {
        showSidebar: PropTypes.bool
    };

    static defaultProps = {
        showSidebar: false,
    };

    handleNavigation = (route) => {
        this.props.history.push(route)
    }

    handleLogout = () => {
        this.props.user_logout()
        sessionStorage.removeItem("impersonationId")
        SuccessHelper.handleSuccess('You have successfully logged out.', true);
        this.setState({ user: null })
        this.handleNavigation("/")
    }

    componentDidMount() {
        let routeName = "";
        if (this.props.location.pathname.split('/')[1]) {
            routeName = this.props.location.pathname.split('/')[1];
        } else {
            routeName = this.props.location.pathname;
        }
        const user = JSON.parse(sessionStorage.getItem('user'));
        this.setState({ user, routeName });
    }

    renderNavbarItem = (route, title) => {
        const { routeName } = this.state
        return (
            <li
                className={routeName == route ? 'navbar-item-active' : 'navbar-item'}
                onClick={() => this.handleNavigation(route)}
            >{title}</li>
        )
    }

    renderLoginRegister = () => {
        return (
            <>
                {this.renderNavbarItem('login', "Login")}
                {this.renderNavbarItem('register', "Register")}
            </>
        )
    }

    renderLogout = () => {
        return (
            <li className='navbar-item' onClick={() => this.handleLogout()} >{i18n.t('logout')}</li>
        )
    }

    handleView = (key) => {
        console.log("SideNav -> handleView -> this.props.refe", this.props.refe)
        this.props.refe[key].current.scrollIntoView({ behavior: "smooth" });
      };

    render() {
        const { user } = this.state
        const { showSidebar } = this.props;
        return (
            <div className="sidebar-wrapper" style={{ display: showSidebar ? 'block' : 'none' }}>
                <ul className="navbar-list">
                    <li class="navbar-item" onClick={() => this.handleView("home")}>Home</li>
                    <li class="navbar-item" onClick={() => this.handleView("aboutUs")}><a style={{color:'#FFFFFF'}} >About</a></li>
                    <li class="navbar-item" onClick={() => this.handleView("term")}><a style={{color:'#FFFFFF'}} >Team</a></li>
                    <li class="navbar-item" onClick={() => this.handleView("pricing")}><a style={{color:'#FFFFFF'}} >Pricing</a></li>
                    <li class="navbar-item" onClick={() => this.handleView("services")}><a style={{color:'#FFFFFF'}} ><Translation>{t=>t('container.services')}</Translation></a></li>
                    <li class="navbar-item" onClick={() => this.handleView("footer")}><a style={{color:'#FFFFFF'}} >Contact</a></li>
                    <li class="navbar-item" onClick={() => this.handleView("appointment")}><a style={{color:'#FFFFFF'}} >Appointment</a></li>
                </ul>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});

const action = { user_logout };

export default connect(null, action)(SideNav);