import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import i18n from "../../../i18n";
import BaseModal from "../../BaseModal";
import PrimaryButton from "../../Core/PrimaryButton";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Select from "../../Core/Select";

const SendFormModal = ({
  forms = [],
  sendForm,
  handleClose,
  email,
  company,
  resend,
}) => {
  const [formIds, setFormIds] = useState([]);
  const [method, setMethod] = useState();
  const [methodError, setMethodError] = useState(false);
  const options = [];
  if (email) {
    options.push({ value: "email", name: i18n.t("profile_email") });
  }
  if (company.hasTextPlan || !email) {
    options.push({ value: "sms", name: "SMS" });
  }

  const handleSend = () => {
    if (!method) {
      setMethodError(i18n.t("required"));
    } else {
      sendForm(formIds, method);
    }
  };
  return (
    <BaseModal
      open={true}
      {...{
        title: <div>{i18n.t("form")}</div>,
        content: (
          <>
            <Select
              options={options}
              label={i18n.t("how_to_send")}
              onChange={(e) => setMethod(e.target.value)}
              error={methodError}
            />
            {!resend ? (
              <Autocomplete
                multiple
                id="send-form-selection"
                options={forms.map((form) => ({
                  value: form._id,
                  name: form.formTitle,
                }))}
                getOptionLabel={(option) => option.name}
                disableCloseOnSelect={true}
                onChange={(e, value) => {
                  setFormIds(value.map(({ value }) => value));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label={i18n.t("form")}
                  />
                )}
              />
            ) : null}
          </>
        ),
        actions: (
          <div className="row mx-0 px-0 justify-content-end">
            <PrimaryButton onClick={handleSend} label={i18n.t("send")} />
          </div>
        ),
      }}
      onClose={() => handleClose()}
    />
  );
};

const mapStateToProps = (state) => ({
  forms: state.formBuilder.data,
});

const action = {};

export default connect(mapStateToProps, action)(SendFormModal);
