import { connect } from "react-redux";
import React, { Component } from "react";

import { Images } from "../../../theme";
import { SidebarContent, Footer } from "../../../components/Employee";
import {
  DotsLoader,
  Header,
  AdvancedSearchDialog,
  BaseModal,
} from "../../../components";
import {
  getUnavailabilites,
  deleteEmployeeUnavailability,
  createEmployeeUnavailability,
} from "../../../config/simpleApiCalls";
import {
  getRequest,
  removeRequest,
} from "../../../redux/actions/ReoccuringUnavailabilties";
import { getNestedValue } from "../../../util/objectMethods";
import NumberFormat from "react-number-format";
import Avatar from "react-avatar";
import moment from "moment";
import DatePicker from "react-datepicker";
import { SuccessHelper, ErrorHelper } from "../../../helpers";
import "./styles.css";

import Sytles from "./sytles";
import i18n from "../../../i18n";
import ReoccuringUnavailabilities from "../../../components/ReoccuringUnavailabilities/ReoccuringUnavailabilities.component";
import BasicTable from "../../../components/Core/BasicTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import PrimaryButton from "../../../components/Core/PrimaryButton";

class ManageUnavailibilites extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: Date.now(),
      dateAvailabiltiesMap: {},
      selectionMap: {},
      token: "",
      isLoading: false,
      markedDates: {},
      toAddMap: {},
      toDeleteMap: {},
    };
  }

  async componentDidMount() {
    const employeeData = await JSON.parse(sessionStorage.getItem("employee"));
    this.setState({ isLoading: true, token: employeeData.access_token });
    this.getUnavailabilitesFromDate(this.state.date);
    this.props.getRequest({ access_token: employeeData.access_token });
    this.setState({ token: employeeData.access_token });
  }

  getUnavailabilitesFromDate(date) {
    const params = {
      date: moment(date).format("MM-DD-YYYY"),
    };
    this.setState({ isLoading: true, date });
    getUnavailabilites(params, this.state.token)
      .then((res) => {
        this.setState({
          isLoading: false,
          availabilities: res,
          toAddMap: {},
          toDeleteMap: {},
        });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }

  renderLoader = () => {
    const { isLoading } = this.state;
    return <DotsLoader isloading={isLoading || this.props.isFetching} />;
  };

  toggleSelection(key) {
    const { toAddMap, toDeleteMap, availabilities = {} } = this.state;
    if (availabilities[key] != "booked") {
      if (typeof availabilities[key] == "object") {
        if (toDeleteMap[key]) {
          delete toDeleteMap[key];
        } else {
          toDeleteMap[key] = availabilities[key];
        }
      } else {
        if (toAddMap[key]) {
          delete toAddMap[key];
        } else {
          toAddMap[key] = true;
        }
      }
      this.setState({ toAddMap, toDeleteMap });
    }
  }

  onSetSidebarOpen = (open) => {
    this.setState({ sidebarOpen: open });
  };

  async save() {
    const { toAddMap, toDeleteMap, date, token } = this.state;
    let formattedDate = moment(date).format("MM-DD-YYYY");
    this.setState({ isLoading: true });
    const toAdd = [];
    Object.keys(toAddMap).map((timeRange) => {
      const time = timeRange.split(" ")[0];
      toAdd.push({ time, date: formattedDate });
    });
    const toDelete = [];
    Object.keys(toDeleteMap).map((timeRange) => {
      toDelete.push(toDeleteMap[timeRange]._id);
    });
    const promises = [];
    if (toAdd.length) {
      promises.push(createEmployeeUnavailability(toAdd, token));
    }
    if (toDelete.length) {
      promises.push(
        deleteEmployeeUnavailability({ ids: toDelete.join(",") }, token)
      );
    }
    Promise.all(promises).then(() => {
      this.getUnavailabilitesFromDate(this.state.date);
    });
  }

  markAllAsAvailable = () => {
    const { availabilities = {}, token } = this.state;
    let toDelete = [];
    Object.keys(availabilities).map((key) => {
      let availability = availabilities[key];
      console.log(availability);
      if (typeof availability == "object") {
        toDelete.push(availability._id);
      }
    });
    deleteEmployeeUnavailability({ ids: toDelete.join(",") }, token).then(
      () => {
        this.getUnavailabilitesFromDate(this.state.date);
      }
    );
  };

  markAllAsUnavailable = () => {
    const { availabilities = {}, token, date } = this.state;
    let formattedDate = moment(date).format("MM-DD-YYYY");
    let toAdd = [];
    Object.keys(availabilities).map((key) => {
      let availability = availabilities[key];
      console.log(availability);
      if (availabilities[key] != "booked" && typeof availability != "object") {
        const time = key.split(" ")[0];
        toAdd.push({ time, date: formattedDate });
      }
    });
    createEmployeeUnavailability(toAdd, token).then(() => {
      this.getUnavailabilitesFromDate(this.state.date);
    });
  };

  openAdvancedModal = () => {
    this.setState({ advancedModalOpen: true });
  };

  handleDelete = (id) => {
    Swal.fire({
      title: i18n.t("are_you_sure_delete"),
      text: i18n.t("cannmot_reverse"),
      showCancelButton: true,
      cancelButtonText: i18n.t("no"),
      confirmButtonText: i18n.t("yes"),
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          title: i18n.t("undo_blocked_times"),
          showCancelButton: true,
          cancelButtonText: i18n.t("no"),
          confirmButtonText: i18n.t("yes"),
        }).then((result) => {
          this.setState({ isloading: true });
          this.props.removeRequest(
            {
              access_token: this.state.token,
              id,
              clear: Boolean(result.value),
            },
            {
              success: () => {
                SuccessHelper.handleSuccess(
                  i18n.t("successfully_deleted"),
                  true
                );
                this.setState({ isloading: false });
              },
              failure: () => {
                ErrorHelper.handleErrors(i18n.t("failed_to_delete"), true);
                this.setState({ isloading: false });
              },
            }
          );
        });
      }
    });
  };

  render() {
    const {
      date,
      availabilities = {},
      toAddMap,
      toDeleteMap,
      advancedModalOpen,
      showAddAdvancedModal,
    } = this.state;
    return (
      <div>
        {this.renderLoader()}
        <div
          className="content-container"
          style={{ flexDirection: "row", textAlign: "center" }}
        >
          <div className="col-12 row d-flex full-width">
            <div className="col-12 col-md-3">
              <DatePicker
                className="form-control saloon-form-input"
                minDate={new Date()}
                selected={date}
                onChange={(date) => this.getUnavailabilitesFromDate(date)}
                value={date}
                placeholderText={i18n.t(
                  "container.services_click_to_select_a_date"
                )}
              />
            </div>
            <div className="col-12 col-md-3 pt-2">
              <button
                onClick={() => this.markAllAsAvailable()}
                type="button"
                className="btn btn-light btn-sm"
                id="saloon-save-social-link"
              >
                Mark all as available
              </button>
            </div>
            <div className="col-12 col-md-3 pt-2">
              <button
                onClick={() => this.markAllAsUnavailable()}
                type="button"
                className="btn btn-light btn-sm"
                id="saloon-save-social-link"
              >
                Mark all as unavailable
              </button>
            </div>
            <div className="col-12 col-md-3 pt-2">
              <button
                onClick={() => this.openAdvancedModal()}
                type="button"
                className="btn btn-light btn-sm"
                id="saloon-save-social-link"
              >
                {i18n.t("advanced")}
              </button>
            </div>
          </div>
          <div>
            {Object.keys(availabilities).length ? (
              <div className="row my-2 px-2">
                {Object.keys(availabilities).map((availability) => {
                  let slotClass = "available ";
                  if (typeof availabilities[availability] == "object") {
                    slotClass = "unavailable ";
                  } else if (availabilities[availability] == "booked") {
                    slotClass = "booked ";
                  }
                  if (toAddMap[availability]) {
                    slotClass += "selected";
                  } else if (toDeleteMap[availability]) {
                    slotClass += "unselected";
                  }
                  return (
                    <div
                      className="col-md-6 half-size"
                      onClick={() => this.toggleSelection(availability)}
                    >
                      <div className={"my-1 mx-1 py-3 " + slotClass}>
                        <div>{availability}</div>
                        <div>
                          {typeof availabilities[availability] == "object"
                            ? "unavailable"
                            : availabilities[availability]}
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div
                  className="my-2 px-2 col-12"
                  style={{ flexDirection: "row", textAlign: "center" }}
                >
                  <button
                    onClick={() => this.save()}
                    type="button"
                    className="btn btn-light btn-sm"
                    id="saloon-save-social-link"
                  >
                    Save
                  </button>
                </div>
              </div>
            ) : (
              <div className="col-12">Nothing found for this day</div>
            )}
          </div>
          {advancedModalOpen ? (
            <BaseModal
              open
              {...{
                title: i18n.t("advanced"),
                content: (
                  <>
                    {showAddAdvancedModal ? (
                      <BaseModal
                        open
                        {...{
                          contentClassName: "modify-modal-content",
                          title: i18n.t("add"),
                          content: (
                            <>
                              <ReoccuringUnavailabilities
                                accessToken={this.state.token}
                                onClose={() => {
                                  this.setState({
                                    showAddAdvancedModal: false,
                                  });
                                }}
                              />
                            </>
                          ),
                        }}
                        onClose={() => {
                          this.setState({ showAddAdvancedModal: false });
                        }}
                      />
                    ) : null}
                    <div className="row mx-0 px-0 justify-content-end mb-2">
                      <PrimaryButton
                        label={i18n.t("add")}
                        onClick={() => {
                          this.setState({ showAddAdvancedModal: true });
                        }}
                      />
                    </div>
                    <BasicTable
                      rows={this.props.reoccuringUnavailabilties}
                      schema={[
                        {
                          field: "startDate",
                          header: i18n.t("start_date"),
                          accessor: (val) =>
                            moment(val.startDate).format("MM-DD-YYYY"),
                        },
                        {
                          field: "endDate",
                          header: i18n.t("end_date"),
                          accessor: (val) =>
                            moment(val.endDate).format("MM-DD-YYYY"),
                        },
                        {
                          field: "startTime",
                          header: i18n.t("start_time"),
                        },
                        {
                          field: "endTime",
                          header: i18n.t("end_time"),
                        },
                        {
                          field: "createdDate",
                          header: i18n.t("created_date"),
                          accessor: (val) =>
                            moment(val.createdDate).format("MM-DD-YYYY"),
                        },
                        {
                          key: "action",
                          header: i18n.t("actions"),
                          render: (val) => {
                            return (
                              <>
                                <FontAwesomeIcon
                                  className="view-icon"
                                  style={{ backgroundColor: "red" }}
                                  color="white"
                                  icon={["fas", "trash"]}
                                  onClick={() => this.handleDelete(val._id)}
                                />
                              </>
                            );
                          },
                        },
                      ]}
                    />
                  </>
                ),
              }}
              onClose={() => {
                this.setState({
                  advancedModalOpen: false,
                  showAddAdvancedModal: false,
                });
              }}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  reoccuringUnavailabilties: state.reoccuringUnavailabilties.data,
  isFetching: state.reoccuringUnavailabilties.isFetching,
});

const action = {
  getRequest,
  removeRequest,
};

export default connect(mapStateToProps, action)(ManageUnavailibilites);
