// @flow

import { CREATE_SALOON, SALOON_LOGOUT } from "../ActionTypes";

export function request(payload) {
  return {
    payload,
    type: CREATE_SALOON.REQUEST,
  };
}

export function success(data: Object) {
  return {
    data,
    type: CREATE_SALOON.SUCCESS,
  };
}

export function failure(errorMessage: Object) {
  return {
    errorMessage,
    type: CREATE_SALOON.FAILURE,
  };
}

export function logout() {
  return {
    type: SALOON_LOGOUT,
  };
}
