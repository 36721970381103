import { connect } from "react-redux";
import React, { Component } from "react";
import Pagination from "react-js-pagination";
import i18n from "../../../i18n";
import {
  deleteCustomer,
  getCompanyCustomers,
  getAllNotesByUser,
  removeFromBlackList,
  addToBlackList,
} from "../../../config/simpleApiCalls";
import { Images } from "../../../theme";
import { DotsLoader, Header, BookingNotes } from "../../../components";
import NumberFormat from "react-number-format";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import { ErrorHelper, SuccessHelper } from "../../../helpers";

import "./styles.css";
import Sytles from "./sytles";
import PrimaryButton from "../../../components/Core/PrimaryButton";
import CustomerEdit from "../../../components/Saloon/Customer/CustomerEdit";
export default class AllCustomers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      access_token: "",
      user: null,
      isloading: false,
      sidebarOpen: false,
      showEditDelete: "",
      searchText: "",
      activePage: 1,
      usersList: null,
      selectedEmployeeId: null,
      filterUsersList: undefined,
      formErrors: {},
      bookingStauses: [
        { text: "Waiting", value: 1, styles: { color: "#aa43b5" } },
        { text: "Now Serving", value: 3, styles: { color: "#FFE478" } },
        { text: "Cancel", value: 2, styles: { color: "#FF7082" } },
        { text: "Done", value: 4, styles: { color: "#95DA47" } },
      ],
    };
  }

  componentDidMount = async () => {
    const saloonData = await JSON.parse(sessionStorage.getItem("saloon"));
    if (saloonData) {
      this.setState({
        access_token: saloonData.access_token,
        companyId: saloonData.companyId,
        isloading: true,
      });
      this.getCompanyCustomers();
    }
    console.log("hereee");
  };

  getCompanyCustomers = () => {
    const { access_token, companyId } = this.state;
    getCompanyCustomers(
      { companyId, relations: ["transactions"] },
      access_token
    ).then((res) => {
      this.setState({ usersList: res, isloading: false });
    });
  };

  displayNoteHistory = () => {
    const { userId, access_token } = this.state;
    if (userId) {
      this.setState({ isloading: true });
      getAllNotesByUser({ userId: userId }, access_token).then(({ data }) => {
        if (data.success) {
          this.setState({
            isloading: false,
            notes: data.data,
            showNoteHistory: true,
          });
        } else {
          this.setState({ isloading: false });
          ErrorHelper.handleErrors("An error occured", true);
        }
      });
    } else {
      this.setState({
        notes: [],
        showNoteHistory: true,
      });
    }
  };

  handleSaloonDeleteCustomer = (id, ind) => {
    const { access_token, usersList } = this.state;
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ff3600",
      cancelButtonColor: "#354e68",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        this.setState({ isloading: true });
        deleteCustomer({ ids: id }, access_token)
          .then((res) => {
            if (res.data.success) {
              SuccessHelper.handleSuccess(
                "Your service is successfully deleted.",
                true
              );
              usersList.splice(ind, 1);
              this.setState({ isloading: false, usersList });
            } else if (!res.data.success) {
              ErrorHelper.handleErrors(res.data.msg, true);
              this.setState({ isloading: false });
            }
          })
          .catch((error) => {
            this.setState({ isloading: false });
            console.log(error, "errorrrrrrrrrrrrrrrrrrr");
          });
      }
    });
  };

  onChangeSearchText = (text) => {
    const { usersList } = this.state;
    let searchText = text.target.value.toUpperCase();

    let filterUsersList =
      usersList &&
      (searchText
        ? usersList.filter((val) => {
            let username = (val.name || "").toUpperCase();
            let email = (val.email || "").toUpperCase();
            let phoneNo = `${val.phoneNo}`;
            return (
              username.indexOf(searchText) > -1 ||
              phoneNo.indexOf(searchText) > -1 ||
              email.indexOf(searchText) > -1
            );
          })
        : undefined);
    this.setState({
      filterUsersList,
      searchText: text.target.value,
      activePage: 1,
    });
  };

  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber });
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  renderSearchBar = () => {
    const { searchText, onlyBlackList } = this.state;
    return (
      <>
        <div className="col-12 col-lg-4 col-md-4">
          <div>
            <span className="saloon-dashboard-heading">Customers</span>
          </div>
          <PrimaryButton
            onClick={() => this.setState({ onlyBlackList: !onlyBlackList })}
            label={i18n.t(onlyBlackList ? "all_customers" : "only_black_list")}
          />
        </div>
        <div className="col-12 col-lg-8 col-md-8">
          <div className=" d-flex justify-content-end row">
            <div className="col-12 col-lg-7 col-md-6">
              <div className="form-group">
                <input
                  style={{ backgroundColor: "#FFFFFF", borderColor: "#EBEBEB" }}
                  type="text"
                  id="addEmployee_search_bar"
                  className="form-control form-control-sm pl-5"
                  placeholder="Search by name, phone and email"
                  onChange={(text) => this.onChangeSearchText(text)}
                  value={searchText}
                />
                <img src={Images.saloon_search} className="search-bar-icon" />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  removeFromBlackList = (val) => {
    const { access_token } = this.state;
    Swal.fire({
      text: i18n.t("are_you_sure"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ff3600",
      cancelButtonColor: "#354e68",
      confirmButtonText: i18n.t("yes"),
    }).then((result) => {
      if (result.value) {
        this.setState({ isloading: true });
        removeFromBlackList({ _id: val._id }, access_token)
          .then((res) => {
            if (res.data.success) {
              SuccessHelper.handleSuccess(
                "Customer successfully updated.",
                true
              );
              this.getCompanyCustomers();
            }
            if (!res.data.success) {
              ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
              this.setState({ isloading: false });
            }
          })
          .catch((error) => {
            ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
            this.setState({ isloading: false });
          });
      }
    });
  };

  addToBlackList = (val) => {
    const { access_token } = this.state;
    Swal.fire({
      text: i18n.t("are_you_sure"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ff3600",
      cancelButtonColor: "#354e68",
      confirmButtonText: i18n.t("yes"),
    }).then((result) => {
      if (result.value) {
        this.setState({ isloading: true });
        addToBlackList({ _id: val._id }, access_token)
          .then((res) => {
            if (res.data.success) {
              SuccessHelper.handleSuccess(
                "Customer successfully updated.",
                true
              );
              this.getCompanyCustomers();
            }
            if (!res.data.success) {
              ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
              this.setState({ isloading: false });
            }
          })
          .catch((error) => {
            ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
            this.setState({ isloading: false });
          });
      }
    });
  };

  renderTableBody = () => {
    const { activePage, usersList, filterUsersList, onlyBlackList } =
      this.state;
    let previousPageNumber = activePage - 1;
    let list = filterUsersList ? filterUsersList : usersList;
    if (onlyBlackList) {
      list = list.filter((e) => e.isBlackListed);
    }
    let startDay = null;
    let endDay = null;
    let currentWorkingHr = { checkIn: "", checkOut: "" };
    return (
      <tbody>
        {list &&
          list.map((val, ind) => {
            if (ind >= previousPageNumber * 10 && ind < activePage * 10) {
              return (
                <tr key={val._id} style={{ borderTop: "3px solid #F7F7F7" }}>
                  <td>
                    <div className="d-flex align-items-center">
                      {val && (
                        <span className="appointment-details-employee-name">
                          {val.name}
                        </span>
                      )}
                    </div>
                  </td>
                  <td className="Table_data ">
                    <div className="d-flex align-items-center mt-1">
                      {val && (
                        <span className="appointment-details-employee-other">
                          <NumberFormat
                            value={val.phoneNo}
                            displayType={"text"}
                            format="### ### #### ####"
                          />
                        </span>
                      )}
                    </div>
                  </td>
                  <td className="Table_data ">
                    <div className="d-flex align-items-center mt-1">
                      {val && (
                        <span className="appointment-details-employee-other">
                          {val.email}
                        </span>
                      )}
                    </div>
                  </td>
                  <td className="Table_data ">
                    <div className="d-flex align-items-center mt-1">
                      {val && (
                        <span className="appointment-details-employee-other">
                          {val.isBlackListed ? (
                            <div className="row">
                              {i18n.t("yes")}
                              <FontAwesomeIcon
                                className="black-list-undo"
                                color="white"
                                icon={["fas", "minus"]}
                                onClick={() =>
                                  this.removeFromBlackList(val, ind)
                                }
                              />
                            </div>
                          ) : (
                            <div className="row">
                              {i18n.t("no")}
                              <FontAwesomeIcon
                                className="black-list-undo"
                                color="white"
                                icon={["fas", "plus"]}
                                onClick={() => this.addToBlackList(val, ind)}
                              />
                            </div>
                          )}
                        </span>
                      )}
                    </div>
                  </td>
                  <td style={{ width: "120px" }}>
                    <div className="row">
                      <PrimaryButton
                        label={i18n.t("view_edit")}
                        onClick={() => this.openEditCustomerCard(val, ind)}
                      />
                      <img
                        onClick={() =>
                          this.handleSaloonDeleteCustomer(val._id, ind)
                        }
                        className="category-table-action-icon"
                        style={{ width: 30, height: 30 }}
                        src={Images.saloon_working_hour_delete}
                      />
                    </div>
                  </td>
                </tr>
              );
            }
          })}
      </tbody>
    );
  };

  openEditCustomerCard = (val, index, readOnly = false) => {
    this.setState({
      showEditCustomerCard: true,
      active: val,
      index,
      readOnly,
    });
  };
  closeEditCustomerCard = () => {
    this.setState({
      showEditCustomerCard: false,
      index: undefined,
    });
  };

  renderUserTable = () => {
    return (
      <div className="col-12 p-3">
        <div className="table-responsive sticky-header">
          <table className="table table-borderless appointment-details-table">
            <thead>
              <tr>
                <th scope="col">
                  <span className="appointment-details-th-text">Name</span>
                </th>
                <th scope="col">
                  <span className="appointment-details-th-text">Phone</span>
                </th>
                <th scope="col">
                  <span className="appointment-details-th-text">Email</span>
                </th>
                <th scope="col">
                  <span className="appointment-details-th-text">
                    {i18n.t("is_black_listed")}
                  </span>
                </th>
                <th scope="col" style={{ width: "120px" }}>
                  <span className="appointment-details-th-text">Actions</span>
                </th>
              </tr>
            </thead>
            {this.renderTableBody()}
          </table>
        </div>
      </div>
    );
  };

  renderPagination = () => {
    const { searchText, usersList, filterUsersList } = this.state;

    return (
      <div className="col-12 py-2 mb-3 d-flex justify-content-center">
        <Pagination
          hideNavigation
          activePage={this.state.activePage}
          itemsCountPerPage={10}
          totalItemsCount={
            searchText ? filterUsersList.length : usersList && usersList.length
          }
          // pageRangeDisplayed={3}
          onChange={(val) => this.handlePageChange(val)}
          firstPageText={
            <img src={Images.pagination_back} style={{ width: 15 }} />
          }
          lastPageText={
            <img src={Images.pagination_forward} style={{ width: 15 }} />
          }
          itemClass="page-item"
          linkClass="page-link"
        />
      </div>
    );
  };

  renderEditCustomerCard = () => {
    const { showEditCustomerCard, active, access_token, usersList, index } =
      this.state;
    return (
      <CustomerEdit
        active={active}
        open={showEditCustomerCard}
        setOpen={() => this.setState({ showEditCustomerCard: false })}
        accessToken={access_token}
        onUpdate={() => {
          this.getCompanyCustomers();
        }}
        setAccountBalance={(accountBalance) => {
          usersList[index] = { ...usersList[index], accountBalance };
          this.setState({ usersList });
        }}
      />
    );
  };

  render() {
    return (
      <div>
        {this.renderLoader()}
        <div className="content-container pt-5">
          <div className="row">
            {this.renderSearchBar()}
            {this.renderUserTable()}
            {this.renderPagination()}
            {this.renderEditCustomerCard()}
          </div>
          <div className="col-12 text-right">
            <PrimaryButton
              className="mx-2"
              onClick={() => this.props.history.push("saloon-add-customer")}
              label={i18n.t("container.add_customer")}
            />
            <PrimaryButton
              onClick={() => this.props.history.push("saloon-import-customers")}
              label={
                <div className="row mx-0 align-items-center">
                  {i18n.t("container.import")}
                  <FontAwesomeIcon
                    className="ml-1"
                    icon={["far", "file-excel"]}
                  />
                </div>
              }
            />
          </div>
        </div>
      </div>
    );
  }
}
