// @flow

import { IMPERSONATE_EMPLOYEE } from "../ActionTypes";

export function request(payload) {
  return {
    payload,
    type: IMPERSONATE_EMPLOYEE.REQUEST
  };
}

export function success(data: Object) {
  return {
    data,
    type: IMPERSONATE_EMPLOYEE.SUCCESS
  };
}

export function failure(errorMessage: Object) {
  return {
    errorMessage,
    type: IMPERSONATE_EMPLOYEE.FAILURE
  };
}