import { connect } from "react-redux";
import React, { Component } from "react";
import { Translation } from "react-i18next";
import i18n from "../../../i18n";
import { Images } from "../../../theme";

import "./styles.css";

class ProductForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onChange = (event, key) => {
    this.props.setValue({ [key]: event.target.value });
  };

  fileSelectedHandler = (event, type) => {
    const file = URL.createObjectURL(event.target.files[0]);
    this.props.setValue({ image: event.target.files[0], imageUrl: file });
  };

  renderUploadPicture = () => {
    const { imageUrl } = this.props.values;
    return (
      <div className="col-12 d-md-flex align-items-md-center text-center mb-3">
        <input
          type="file"
          style={{ display: "none" }}
          onChange={(event) => this.fileSelectedHandler(event)}
          ref={(fileInput) => (this.fileInput = fileInput)}
          accept="image/*"
        />
        <span
          onClick={() => this.fileInput.click()}
          style={{
            backgroundImage: `url(${
              imageUrl ? imageUrl : Images.saloon_upload_media_icon
            })`,
          }}
          id="saloon-edit-profile"
        />
      </div>
    );
  };

  renderInput = (cols, id, lable, inputType, placeholder) => {
    const { values = {}, formErrors = {} } = this.props;
    return (
      <div class={`form-group ${cols} px-0 px-md-3`}>
        <label htmlFor={id} className="saloon-edit-category-lable">
          {lable}
        </label>
        <input
          type={inputType}
          class="form-control saloon-edit-category-input"
          id={id}
          value={values[id]}
          onChange={(text) => this.onChange(text, id)}
          placeholder={placeholder}
        />
        {formErrors[id] ? (
          <small className="form-text text-danger ml-3">{formErrors[id]}</small>
        ) : null}
      </div>
    );
  };

  renderYesNoSelect = (cols, id, lable, placeholder) => {
    const { values = {}, formErrors = {} } = this.props;
    return (
      <div class={`form-group ${cols} px-0 px-md-3`}>
        <label htmlFor={id} className="saloon-edit-category-lable">
          {lable}
        </label>
        <select
          class="form-control saloon-edit-category-input"
          id={id}
          defaultValue="false"
          value={values[id]}
          onChange={(text) => this.onChange(text, id)}
          placeholder={placeholder}
        >
          <option value={true}>{i18n.t("yes")}</option>
          <option value={false}>{i18n.t("no")}</option>
        </select>
        {formErrors[id] ? (
          <small className="form-text text-danger ml-3">{formErrors[id]}</small>
        ) : null}
      </div>
    );
  };

  render() {
    const { isEdit } = this.props;
    const successCallback = () => {
      this.props.setValue({
        name: "",
        description: "",
        cost: 0,
        charge: 0,
        quantity: 0,
        reorderTrigger: 0,
        taxable: false,
        allow_backorder: false,
        image: "",
        imageUrl: "",
      });
    };
    const { renderCloseButton = () => {} } = this.props;
    return (
      <div className="container">
        <div className="col-12 p-3 bg-white" style={{ borderRadius: "1rem" }}>
          {renderCloseButton()}
          <div class="form-row mb-3">
            {this.renderUploadPicture()}
            {this.renderInput(
              "col-12 col-md-6",
              "name",
              <Translation>{(t) => t("name")}</Translation>,
              "text",
              i18n.t("name")
            )}
            {this.renderInput(
              "col-12 col-md-6",
              "description",
              <Translation>{(t) => t("description")}</Translation>,
              "text",
              i18n.t("description")
            )}
            {!isEdit &&
              this.renderInput(
                "col-12 col-md-6",
                "cost",
                <Translation>{(t) => t("cost")}</Translation>,
                "number",
                i18n.t("cost")
              )}
            {this.renderInput(
              "col-12 col-md-6",
              "charge",
              <Translation>{(t) => t("sale_price")}</Translation>,
              "number",
              i18n.t("sale_price")
            )}
            {!isEdit &&
              this.renderInput(
                "col-12 col-md-6",
                "quantity",
                <Translation>{(t) => t("quantity")}</Translation>,
                "number",
                i18n.t("quantity")
              )}
            {this.renderInput(
              "col-12 col-md-6",
              "reorderTrigger",
              <Translation>{(t) => t("reorder_trigger")}</Translation>,
              "number",
              i18n.t("reorder_trigger")
            )}
            {this.renderYesNoSelect(
              "col-12 col-md-6",
              "taxable",
              <Translation>{(t) => t("taxable")}</Translation>,
              i18n.t("taxable")
            )}
            {this.renderYesNoSelect(
              "col-12 col-md-6",
              "allow_backorder",
              <Translation>{(t) => t("allow_backorder")}</Translation>,
              i18n.t("allow_backorder")
            )}
            {this.renderInput(
              "col-12 col-md-6",
              "commission",
              i18n.t("commission") + " (%)",
              "number",
              i18n.t("commission")
            )}
          </div>
          <div className="col-12 text-right">
            <button
              onClick={() => this.props.checkValidation(successCallback)}
              type="button"
              className="btn btn-light btn-sm mb-2"
              id="saloon-edit-category-btn"
            >
              <Translation>{(t) => t("container.update_product")}</Translation>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(ProductForm);
