import { connect } from "react-redux";
import React, { Component } from "react";
import Pagination from "react-js-pagination";
import i18n from "../../../i18n";
import {
  getEmployeeCustomers,
  getCustomerBookingHistory,
  getCompanyThatEmployeeAdd,
} from "../../../config/simpleApiCalls";
import { Images } from "../../../theme";
import { DotsLoader, BookingNotes } from "../../../components";
import { SidebarContent } from "../../../components/Employee";
import { Translation } from "react-i18next";
import NumberFormat from "react-number-format";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import { getNestedValue } from "../../../util/objectMethods";
import Avatar from "react-avatar";
import moment from "moment";

import "./styles.css";
import Sytles from "./sytles";

class AllCustomers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      access_token: "",
      user: null,
      isloading: false,
      showEditDelete: "",
      searchText: "",
      activePage: 1,
      usersList: null,
      selectedEmployeeId: null,
      formErrors: {},
      bookingStauses: [
        { text: "Waiting", value: 1, styles: { color: "#aa43b5" } },
        { text: "Now Serving", value: 3, styles: { color: "#FFE478" } },
        { text: "Cancel", value: 2, styles: { color: "#FF7082" } },
        { text: "Done", value: 4, styles: { color: "#95DA47" } },
      ],
      filterUsersList: undefined,
      allSaloonMap: {},
    };
  }

  componentDidMount = async () => {
    const employeeData = await JSON.parse(sessionStorage.getItem("employee"));
    if (employeeData) {
      this.setState({
        access_token: employeeData.access_token,
        isloading: true,
      });
      this.getEmployeeCustomers();
      this.gettingAllSaloons();
    }
  };

  getEmployeeCustomers = () => {
    const { access_token } = this.state;
    getEmployeeCustomers(access_token).then((res) => {
      this.setState({ usersList: res, isloading: false });
    });
  };

  gettingAllSaloons = () => {
    const { access_token } = this.state;

    this.setState({ isloading: true });

    const params = { relations: JSON.stringify(["services"]) };
    getCompanyThatEmployeeAdd(access_token, params).then((res) => {
      if (res.data.success) {
        const allSaloons = res.data.data.filter((salon) => !!salon.companyId);
        const allSaloonMap = {};
        allSaloons.map((saloon) => {
          allSaloonMap[saloon.companyId._id] = saloon;
        });
        this.setState({
          allSaloons,
          isloading: false,
          allSaloonMap,
        });
      }
    });
  };

  displayBookingHistory = () => {
    const { editId, access_token } = this.state;
    this.setState({ isloading: true });
    getCustomerBookingHistory({ _id: editId }, access_token).then(
      ({ data }) => {
        console.log(data);
        if (data.success) {
          this.setState({
            isloading: false,
            bookings: data.data.reverse(),
            showBookingHistory: true,
          });
        } else {
          this.setState({ isloading: false });
          ErrorHelper.handleErrors("An error occured", true);
        }
      }
    );
  };

  onChangeSearchText = (text) => {
    const { usersList } = this.state;
    let searchText = text.target.value.toUpperCase();

    let filterUsersList =
      usersList &&
      (searchText
        ? usersList.filter((val) => {
            let name = (val.name || "").toUpperCase();
            let email = (val.email || "").toUpperCase();
            let phoneNo = `${val.phoneNo}`;
            return (
              name.indexOf(searchText) > -1 ||
              phoneNo.indexOf(searchText) > -1 ||
              email.indexOf(searchText) > -1
            );
          })
        : undefined);
    this.setState({
      filterUsersList,
      searchText: text.target.value,
      activePage: 1,
    });
  };

  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber });
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  renderSearchBar = () => {
    const { searchText } = this.state;
    return (
      <>
        <div className="col-12 col-lg-4 col-md-4 mt-4">
          <span>
            <span className="saloon-dashboard-heading">Customers</span>
          </span>
        </div>
        <div className="col-12 col-lg-8 col-md-8 mt-4">
          <div className=" d-flex justify-content-end row">
            <div className="col-12 col-lg-7 col-md-6">
              <div className="form-group">
                <input
                  style={{ backgroundColor: "#FFFFFF", borderColor: "#EBEBEB" }}
                  type="text"
                  id="addEmployee_search_bar"
                  className="form-control form-control-sm pl-5"
                  placeholder="Search by name, phone and email"
                  onChange={(text) => this.onChangeSearchText(text)}
                  value={searchText}
                />
                <img src={Images.saloon_search} className="search-bar-icon" />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  renderTableBody = () => {
    const { activePage, usersList, filterUsersList } = this.state;
    let previousPageNumber = activePage - 1;
    const list = filterUsersList ? filterUsersList : usersList;
    let startDay = null;
    let endDay = null;
    let currentWorkingHr = { checkIn: "", checkOut: "" };
    return (
      <tbody>
        {list &&
          list.map((val, ind) => {
            if (ind >= previousPageNumber * 10 && ind < activePage * 10) {
              return (
                <tr key={val._id} style={{ borderTop: "3px solid #F7F7F7" }}>
                  <td>
                    <div className="d-flex align-items-center">
                      {val && (
                        <span className="appointment-details-employee-other">
                          {val.name}
                        </span>
                      )}
                    </div>
                  </td>
                  <td className="Table_data ">
                    <div className="d-flex align-items-center mt-1">
                      {val && (
                        <span className="appointment-details-employee-other">
                          <NumberFormat
                            value={val.phoneNo}
                            displayType={"text"}
                            format="### ### #### ####"
                          />
                        </span>
                      )}
                    </div>
                  </td>
                  <td className="Table_data ">
                    <div className="d-flex align-items-center mt-1">
                      {val && (
                        <span className="appointment-details-employee-other">
                          {val.email}
                        </span>
                      )}
                    </div>
                  </td>
                  <td style={{ width: "45px" }}>
                    <div className="row justify-content-center mx-0">
                      <FontAwesomeIcon
                        className="view-icon"
                        color="white"
                        icon={["fas", "eye"]}
                        onClick={() =>
                          this.openEditCustomerCard(val, ind, true)
                        }
                      />
                    </div>
                  </td>
                </tr>
              );
            }
          })}
      </tbody>
    );
  };

  openEditCustomerCard = (val, index, readOnly = false) => {
    console.log(val);
    this.setState({
      showEditCustomerCard: true,
      showBookingHistory: false,
      username: val.name,
      telephone: val.phoneNo,
      email: val.email,
      editId: val._id,
      index,
      readOnly,
    });
  };

  closeEditCustomerCard = () => {
    this.setState({
      showEditCustomerCard: false,
      username: "",
      telephone: "",
      email: "",
      editId: "",
      index: undefined,
    });
  };

  renderUserTable = () => {
    const { searchText, filterUsersList, usersList } = this.state;
    return (
      <div className="col-12 p-3">
        <div className="table-responsive sticky-header">
          <table className="table table-borderless appointment-details-table">
            <thead>
              <tr>
                <th scope="col">
                  <span className="appointment-details-th-text">Name</span>
                </th>
                <th scope="col">
                  <span className="appointment-details-th-text">Phone</span>
                </th>
                <th scope="col">
                  <span className="appointment-details-th-text">Email</span>
                </th>
                <th scope="col">
                  <span className="appointment-details-th-text">Actions</span>
                </th>
              </tr>
            </thead>
            {this.renderTableBody()}
          </table>
        </div>
      </div>
    );
  };

  renderPagination = () => {
    const { searchText, usersList, filterUsersList } = this.state;
    return (
      <div className="col-12 py-2 mb-3 d-flex justify-content-center">
        <Pagination
          hideNavigation
          activePage={this.state.activePage}
          itemsCountPerPage={10}
          totalItemsCount={
            searchText ? filterUsersList.length : usersList && usersList.length
          }
          // pageRangeDisplayed={3}
          onChange={(val) => this.handlePageChange(val)}
          firstPageText={
            <img src={Images.pagination_back} style={{ width: 15 }} />
          }
          lastPageText={
            <img src={Images.pagination_forward} style={{ width: 15 }} />
          }
          itemClass="page-item"
          linkClass="page-link"
        />
      </div>
    );
  };
  renderEmail = () => {
    const { formErrors, readOnly } = this.state;
    return (
      <div className="form-label-group col-12 col-md-6 col-lg-4">
        <input
          type="email"
          className="form-control px-4 px-md-4 px-lg-4"
          placeholder={i18n.t("container.profile_email")}
          readOnly={readOnly}
          onChange={(text) => {
            this.setState({ email: text.target.value });
          }}
          disabled={this.state.provider == "google" ? true : false}
          value={this.state.email}
        />
        {formErrors.emailError ? (
          <small className="form-text text-danger ml-3">
            {formErrors.emailError}
          </small>
        ) : null}
      </div>
    );
  };
  renderPhone = () => {
    const { formErrors, telephone, readOnly } = this.state;
    return (
      <div className="form-label-group col-12 col-md-6 col-lg-4">
        <NumberFormat
          type="telephone"
          placeholder="Telephone"
          className="form-control px-4 px-md-4 px-lg-4"
          id="xyz"
          readOnly={readOnly}
          value={telephone}
          onChange={(text) => {
            this.setState({ telephone: text.target.value });
          }}
          format="### ### ####"
        />

        {formErrors.telephoneError ? (
          <small className="form-text text-danger ml-3">
            {formErrors.telephoneError}
          </small>
        ) : null}
      </div>
    );
  };
  renderUsername = () => {
    const { formErrors, readOnly } = this.state;
    return (
      <div className="form-label-group col-12 col-md-6 col-lg-4">
        <input
          type="text"
          className="form-control px-4 px-md-4 px-lg-4"
          placeholder={i18n.t("name")}
          readOnly={readOnly}
          onChange={(text) => {
            this.setState({ username: text.target.value });
          }}
          value={this.state.username}
        />
        {formErrors.nameError ? (
          <small className="form-text text-danger ml-3">
            {formErrors.nameError}
          </small>
        ) : null}
      </div>
    );
  };

  renderAddCustomerForm = () => {
    const { readOnly } = this.state;
    return (
      <div className="col-12 p-3">
        <div className="col-12 p-3">
          <div class="row mb-3">
            {this.renderUsername()}
            {this.renderEmail()}
            {this.renderPhone()}
          </div>
          <div className="col-12 text-right">
            {readOnly && (
              <button
                onClick={() => this.displayBookingHistory()}
                type="button"
                className="btn btn-light btn-sm mb-2"
                id="saloon-edit-category-btn"
              >
                {i18n.t("container.profile_booking_history")}
              </button>
            )}
          </div>
        </div>
      </div>
    );
  };

  renderEditCustomerCard = () => {
    const {
      showEditCustomerCard,
      serviceNameError,
      priceError,
      minimumTimeError,
      isGym,
      showBookingHistory,
      username,
    } = this.state;
    return (
      <div
        className="edit-service-card-wrapper"
        style={{ display: showEditCustomerCard ? "flex" : "none" }}
      >
        <div
          className={`${
            showBookingHistory ? "content-container" : "container"
          } bg-white`}
          style={{ borderRadius: "1rem" }}
        >
          <div className="py-2 px-2 row mx-0 justify-content-between">
            <span className="saloon-dashboard-heading">
              {showBookingHistory
                ? i18n.t("container.profile_booking_history")
                : i18n.t("container.customer")}
            </span>
            <span
              onClick={() => this.closeEditCustomerCard()}
              className="h4 cursor-pointer"
            >
              &times;
            </span>
          </div>
          {showBookingHistory
            ? this.renderBookingTable()
            : this.renderAddCustomerForm()}
        </div>
      </div>
    );
  };

  renderBookingTableBody = () => {
    const { activePage, bookings, bookingStauses, searchText, isGym } =
      this.state;
    if (!bookings) return null;
    let renderBooking = bookings;
    console.log(renderBooking);
    const summary = {
      total: 0,
      tip: 0,
      taxes: 0,
      grandTotal: 0,
    };
    renderBooking = renderBooking.map((booking) => {
      booking.taxTotal = getNestedValue(booking, "taxes", []).reduce(
        (accum, element) => accum + element.amount,
        0
      );
      booking.total =
        booking.totalAmount + (booking.tip || 0) + booking.taxTotal;
      if (booking.status == 4) {
        summary.total += booking.totalAmount || 0;
        summary.tip += booking.tip || 0;
        summary.taxes += booking.taxTotal;
        summary.grandTotal += booking.total;
      }
      return booking;
    });

    return (
      <tbody>
        {!!renderBooking.length && (
          <tr>
            <td>
              <strong>{i18n.t("summary")}: </strong>
            </td>
            <td></td>
            <td></td>
            <td className="px-0">
              <button
                className="btn btn-light btn-sm mx-0"
                style={{ width: "7rem" }}
              >
                $ {summary.total.toFixed(2)}
              </button>
            </td>
            <td className="px-0">
              <button
                className="btn btn-light btn-sm mx-0"
                style={{ width: "7rem" }}
              >
                $ {summary.taxes.toFixed(2)}
              </button>
            </td>
            <td className="px-0">
              <button
                className="btn btn-light btn-sm mx-0"
                style={{ width: "7rem" }}
              >
                $ {summary.tip.toFixed(2)}
              </button>
            </td>
            <td className="px-0">
              <button
                className="btn btn-light btn-sm mx-0"
                style={{ width: "7rem" }}
              >
                $ {summary.grandTotal.toFixed(2)}
              </button>
            </td>
          </tr>
        )}
        {renderBooking.map((val, ind) => {
          if (val.status == 1) return null;
          return (
            <tr key={val._id} style={{ borderTop: "3px solid #F7F7F7" }}>
              <td>
                <div className="d-flex align-items-center">
                  <Avatar
                    name={val.userId && val.userId.userName}
                    size="30"
                    color="#4862FF"
                    round={true}
                    textSizeRatio={2.0}
                  />
                  <div>
                    {
                      <span
                        style={{ marginLeft: "10px" }}
                        className="appointment-details-employee-name"
                      >
                        {val.userId && val.userId.userName}
                      </span>
                    }
                    <br />
                    <span
                      style={{ marginLeft: "10px", fontSize: "14px" }}
                      className="appointment-details-employee-other"
                    >
                      {getNestedValue(val, ["services", 0, "date"], "")}{" "}
                      {getNestedValue(val, ["services", 0, "time"], "")}
                    </span>
                  </div>
                </div>
              </td>
              <td className=" Table_data">
                <div className="d-flex align-items-center mt-1">
                  <span className="appointment-details-employee-name">
                    {" "}
                    <span>
                      <NumberFormat
                        type={"text"}
                        style={Sytles.removeInputStyle}
                        value={val.phoneNo}
                        disabled
                        format="### ### ####"
                      />
                      <div className="appointment-details-employee-name">
                        {" "}
                        {val.email || ""}{" "}
                      </div>
                    </span>
                  </span>
                </div>
              </td>
              <td className=" Table_data">
                <div className="d-flex align-items-center mt-1">
                  <span className="appointment-details-employee-name service-column">
                    {getNestedValue(
                      val,
                      ["services", 0, "serviceId", "name"],
                      ""
                    )}
                  </span>
                </div>
              </td>
              <td className=" Table_data">
                <div className="d-flex align-items-center mt-1">
                  <span className="appointment-details-employee-name">
                    {`$ ${val.totalAmount.toFixed(2)}`}
                  </span>
                </div>
              </td>
              <td className=" Table_data">
                <div className="d-flex align-items-center mt-1">
                  <span className="appointment-details-employee-name">
                    {`$ ${val.taxTotal.toFixed(2)}`}
                  </span>
                </div>
              </td>
              <td className=" Table_data">
                <div className="d-flex align-items-center mt-1">
                  <span className="appointment-details-employee-name">
                    {`$ ${val.tip.toFixed(2) || 0}`}
                  </span>
                </div>
              </td>
              <td className=" Table_data">
                <div className="d-flex align-items-center mt-1">
                  <span className="appointment-details-employee-name">
                    {`$ ${val.total.toFixed(2)}`}
                  </span>
                </div>
              </td>
              <td className=" Table_data">
                <div className="d-flex align-items-center mt-1">
                  <span
                    style={{
                      ...Sytles.makeBold,
                      ...bookingStauses[val.status - 1].styles,
                    }}
                    className="appointment-details-employee-name"
                  >
                    {bookingStauses[val.status - 1].text}
                  </span>
                </div>
              </td>
              <td className=" Table_data">
                <div className="d-flex align-items-center mt-1">
                  <button
                    className="btn btn-blue btn-sm px-0"
                    style={{ width: "5rem" }}
                    onClick={() =>
                      this.setState({ showPopUp: true, selectBooking: val })
                    }
                  >
                    Info
                  </button>
                </div>
              </td>
              <td className=" Table_data">
                <div className="d-flex align-items-center mt-1">
                  <button
                    className="btn btn-blue btn-sm px-0"
                    style={{ width: "5rem" }}
                    onClick={() =>
                      this.setState({ showNotePopUp: true, selectBooking: val })
                    }
                  >
                    Notes
                  </button>
                </div>
              </td>
            </tr>
          );
          // }
        })}
        {this.renderPopup()}
        {this.renderNotePopup()}
      </tbody>
    );
  };
  renderPopup = () => {
    const { showPopUp } = this.state;
    return (
      <div
        className="employee-service-card-wrapper "
        style={{ display: showPopUp ? "flex" : "none" }}
      >
        <div className="container ">
          <div
            className="col-8 p-3 my-5 bg-white pop_container"
            style={{ borderRadius: "1rem", margin: "auto" }}
          >
            <div className="py-0 px-2 text-right">
              <span
                onClick={() => this.setState({ showPopUp: false })}
                className="h4 cursor-pointer"
              >
                &times;
              </span>
            </div>
            {this.renderBookingMoreInfo()}
          </div>
        </div>
      </div>
    );
  };

  renderNotePopup = () => {
    const { showNotePopUp, selectBooking, access_token, allSaloonMap } =
      this.state;
    return (
      showNotePopUp && (
        <div
          className="employee-service-card-wrapper "
          style={{ display: "flex" }}
        >
          <div className="container ">
            <div
              className="col-8 p-3 my-5 bg-white employee-service-card-wrapper"
              style={{ borderRadius: "1rem", margin: "auto" }}
            >
              <div
                className="py-0 px-2"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span>
                  <b>Notes</b>
                </span>
                <span
                  onClick={() => this.setState({ showNotePopUp: false })}
                  className="h4 cursor-pointer"
                >
                  &times;
                </span>
              </div>

              <BookingNotes
                bookingId={selectBooking._id}
                access_token={access_token}
                hasEditPermission={
                  allSaloonMap[selectBooking.companyId._id]
                    .isEmployeeIndependent
                }
              />
            </div>
          </div>
        </div>
      )
    );
  };

  renderBookingTable = () => {
    const { searchText, filterUsersList, usersList, bookings, isGym } =
      this.state;
    return (
      <div className="col-12 p-3">
        <div className="table-responsive sticky-header">
          <table className="table table-borderless appointment-details-table">
            <thead>
              <tr>
                <th scope="col">
                  <span className="appointment-details-th-text">
                    <Translation>
                      {(t) => t("container.advancedSearchDialog_name")}
                    </Translation>
                  </span>
                </th>
                <th scope="col">
                  <span className="appointment-details-th-text">
                    <Translation>
                      {(t) => t("container.employe_phone_number")}
                    </Translation>
                    /
                  </span>
                  <span className="appointment-details-th-text">
                    <Translation>
                      {(t) => t("container.profile_email")}
                    </Translation>
                  </span>
                </th>
                <th scope="col">
                  <span className="appointment-details-th-text">
                    <Translation>
                      {(t) => t("container.profile_service")}
                    </Translation>
                  </span>
                </th>
                <th scope="col">
                  <div className="row">
                    <span className="appointment-details-th-text">
                      <Translation>{(t) => t("taxes")}</Translation>
                    </span>
                  </div>
                </th>
                <th scope="col">
                  <div className="row">
                    <span className="appointment-details-th-text">
                      <Translation>{(t) => t("by_price")}</Translation>
                    </span>
                  </div>
                </th>
                <th scope="col">
                  <div className="row">
                    <span className="appointment-details-th-text">
                      <Translation>{(t) => t("tip")}</Translation>
                    </span>
                  </div>
                </th>
                <th scope="col">
                  <div className="row">
                    <span className="appointment-details-th-text">
                      <Translation>{(t) => t("grand_total")}</Translation>
                    </span>
                  </div>
                </th>
                <th scope="col">
                  <span className="appointment-details-th-text">
                    <Translation>
                      {(t) => t("container.profile_status")}
                    </Translation>
                  </span>
                </th>
                <th scope="col">
                  <span className="appointment-details-th-text">Info</span>
                </th>
                <th scope="col">
                  <span className="appointment-details-th-text">Notes</span>
                </th>
              </tr>
            </thead>
            {this.renderBookingTableBody()}
          </table>
        </div>
      </div>
    );
  };

  renderBookingMoreInfo = () => {
    const { selectBooking, bookingStaus, bookingStauses, isGym } = this.state;

    return (
      <div>
        <div class="form-group justify-content-center align-items-center">
          <div>
            {selectBooking &&
              selectBooking.services.map((service, index) => (
                <>
                  <div
                    className="myappoint-input-container row "
                    style={{
                      width: "auto",
                      flexDirection: "row",
                    }}
                  >
                    <div className="form-group my-apointment-input">
                      <div className="pt-3 justify-content-center align-items-center">
                        <div className="spaceAround">
                          <span>
                            {index + 1}.{" "}
                            <Translation>
                              {(t) => t("container.profile_service")}
                            </Translation>
                          </span>
                          <span className="textSize">
                            {moment(`${service.date} ${service.time}`).format(
                              "MMMM Do YYYY h:mm a"
                            )}
                          </span>
                        </div>

                        <input
                          disabled
                          type="text"
                          id="addEmployee_search_bar leftSpace"
                          className="form-control form-control-sm appointment-input lowLeftSpace"
                          placeholder="Service"
                          value={service.serviceId.name}
                        />
                      </div>
                    </div>
                    {!isGym && (
                      <div className="form-group  my-apointment-input">
                        <div className="pt-3 justify-content-center align-items-center">
                          <div className="spaceAround">
                            <span> Employee Name</span>
                          </div>

                          <input
                            disabled
                            type="text"
                            id="addEmployee_search_bar leftSpace"
                            className="form-control form-control-sm appointment-input lowLeftSpace"
                            placeholder="Employe Name"
                            value={
                              getNestedValue(service.employeeId, "userId")
                                ? getNestedValue(
                                    service.employeeId.userId,
                                    "userName"
                                  ) ||
                                  `${getNestedValue(
                                    service.employeeId.userId,
                                    "firstName",
                                    ""
                                  )} ${getNestedValue(
                                    service.employeeId.userId,
                                    "lastName",
                                    ""
                                  )}`
                                : ""
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </>
              ))}
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.renderLoader()}
        <div className="content-container">
          <div className="row">
            {this.renderSearchBar()}
            {this.renderUserTable()}
            {this.renderPagination()}
            {this.renderEditCustomerCard()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(AllCustomers);
