import "react-dropdown/style.css";
import React from "react";
import UserBookingDetails from "../../components/UserBookingDetails/UserBookingDetails.component";
import { confirmBooking } from "../../config/simpleApiCalls";
import i18n from "../../i18n";
import { ErrorHelper, SuccessHelper } from "../../helpers";
export default ({ match, history }) => {
  return (
    <div style={{ backgroundColor: "#F8FDFF" }}>
      <UserBookingDetails
        match={match}
        history={history}
        showConfirm
        modalCallback={(id, { isConfirmed }) => {
          if (isConfirmed) {
            confirmBooking({ id })
              .then(({ data }) => {
                if (data.success) {
                  SuccessHelper.handleSuccess(
                    i18n.t("booking_confirmed"),
                    true
                  );
                } else {
                  ErrorHelper.handleErrors(
                    i18n.t("something_went_wrong"),
                    true
                  );
                }
              })
              .catch(() => {
                ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
              });
          }
        }}
      />
    </div>
  );
};
