import React, { useState } from "react";
import "./DepositeOptions.sass";
import i18n from "../../i18n";
import DollarInput from "../DollarInput";
import Select from "../Core/Select";

const yesNoOptions = [
  { value: true, label: i18n.t("yes") },
  { value: false, label: i18n.t("no") },
];
const DepositeOptions = ({
  requireDeposit,
  handleRequireDeposite,
  price,
  handleDepositAmount,
  className,
  requireDepositError,
  depositAmountError,
  depositAmount,
  labels,
  handleDepositTarget,
  depositTarget,
}) => {
  const [localAmountError, setlocalAmountError] = useState("");
  const handleUserAmount = (event) => {
    const value = event;
    const userAmount = parseInt(value);
    if (userAmount <= price) {
      handleDepositAmount(userAmount);
      setlocalAmountError("");
    } else {
      handleDepositAmount(userAmount);
      setlocalAmountError(
        "Deposit amount should be less than the service price"
      );
    }
  };
  return (
    <section className={`container mb-3 ${className}`}>
      <div className="row">
        <div className="col-md-4">
          <Select
            options={[
              { value: true, name: i18n.t("yes") },
              { value: false, name: i18n.t("no") },
            ]}
            label={labels[0]}
            value={requireDeposit}
            error={requireDepositError}
            onChange={(e) => handleRequireDeposite(e)}
          />
        </div>
        <div className="col-md-4">
          <Select
            options={[
              { value: "everyone", name: i18n.t("everyone") },
              { value: "blacklisted", name: i18n.t("only_black_list") },
            ]}
            label={labels[1]}
            value={depositTarget}
            // error={requireDepositError}
            onChange={(e) => handleDepositTarget(e)}
          />
        </div>
        <div className="col-md-4">
          <DollarInput
            value={depositAmount}
            label={labels[2]}
            error={depositAmountError || localAmountError}
            onChange={handleUserAmount}
          />
        </div>
      </div>
    </section>
  );
};

export default DepositeOptions;
