import { connect } from "react-redux";
import React, { Component } from "react";
import { Translation } from 'react-i18next';
import i18n from '../../../i18n';
import { Images } from "../../../theme";
import { DotsLoader } from "../../../components";
import { gitWaitingAppoinments, getCompanyThatEmployeeAdd } from '../../../config/simpleApiCalls';
import { ErrorHelper } from "../../../helpers";
import "./styles.css";
import Sytles from "./sytles";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usersList: [],
      allAppoin: [],
      isloading: false,
      employeeSalon: [],
      blockUsersList: [],
      unblockUsersList: [],
    };
  }

  async componentDidMount() {
    //     const user = JSON.parse(sessionStorage.getItem('admin'));
    //     if (user && user.access_token) {
    //         this.setState({ user }, this.getAllUsers(user.access_token));
    //     } else {
    //         ErrorHelper.handleErrors("Please login your account!", true)
    //     }
    const employeeData = await JSON.parse(sessionStorage.getItem("employee"));

    if (employeeData) {
      this.setState({ access_token: employeeData.access_token }, () => {
        this.gitWaitingAppoinments(employeeData.access_token);
        this.getEmployeeSalon(employeeData.access_token);
      });
    }
  }


  gitWaitingAppoinments = async (access_token) => {
    this.setState({ isloading: true })
    try {
      const res = await gitWaitingAppoinments({type: 'employee'}, access_token);
      this.setState({ allAppoin: res.data.data, isloading: false });
    } catch (error) {
      alert("Something went wrong ;(");
      this.setState({ isloading: false });
    }
  };

  getEmployeeSalon = async (access_token) => {
    this.setState({ isloading: true })
    try {
      const res = await getCompanyThatEmployeeAdd(access_token);
      this.setState({ employeeSalon: res.data.data, isloading: false });
    } catch (error) {
      alert("Something went wrong ;(");
      this.setState({ isloading: false });
    }
  };


  // getAllUsers = (accessToken) => {
  //     this.setState({ isloading: true })
  //     adminGetUsers(accessToken).then(res => {
  //         let blockUsersList = res && res.filter(val => {
  //             return val.isBlock;
  //         })
  //         let unblockUsersList = res && res.filter(val => {
  //             return !val.isBlock;
  //         })
  //         this.setState({ isloading: false, usersList: res, blockUsersList, unblockUsersList })
  //     }).catch(error => {
  //         this.setState({ isloading: false })
  //         console.log(error, "Get all users error")
  //     })
  // }

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  _renderEmployeeCard = () => {
    const { allAppoin } = this.state
    return (
      <>
        <div className="col-12 col-md-5 col-lg-4 offset-md-0 offset-lg-0 p-3">
          <div className="py-3 px-3 px-md-4 px-lg-4 px-xl-5 shadow-sm mb-5 bg-white rounded cards">
            <div className="row">
              <div className="col-4 col-md-2 col-lg-4 col-sm-12">
                <div className="row ">
                  <span className="dashboard-card-title mt-1">{i18n.t('container.booking')}</span>

                  <span className="dashboard-card-subtitle">{i18n.t('container.total_booking')}</span>

                  <div className="text-left">
                    <span className="dashboard-card-number">{allAppoin?.length ? allAppoin.length : "0"}</span>
                  </div>
                </div>
              </div>
              <div className="col-8 col-md-10 col-lg-8 col-sm-12">
                <div
                  className=" d-flex justify-content-between"
                  style={{ width: "100%" }}
                >
                  <img className="mt-2 Employee_img" src={Images._preview} />
                  {/* <span>
                    <img src={Images.dots} className="dots_img" />
                  </span> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  _renderCategoriesCard = () => {
    const { employeeSalon } = this.state
    return (
      <>
        <div className="col-12 col-md-5 col-lg-4 offset-md-0 offset-lg-0 p-3">
          <div
            className="py-3 px-3 px-md-4 px-lg-4 px-xl-5 shadow-sm mb-5 bg-white rounded cards"
            style={{ backgroundColor: "#FFFFFF" }}
          >
            <div className="row">
              <div className="col-4 col-md-2 col-lg-6 col-sm-6">
                <div className="row ">
                  <span  className="dashboard-card-title mt-1"><Translation>{t=>t('container.active_salon')}</Translation></span>

                  <span className="dashboard-card-subtitle">{i18n.t('container.total_salon')}</span>

                  <div className="text-left">
                    <span className="dashboard-card-number">{employeeSalon?.length ? employeeSalon.length : "0"}</span>
                  </div>
                </div>
              </div>
              <div className="col-8 col-md-10 col-lg-4 col-sm-6">
                <div
                  className=" d-flex justify-content-between"
                  style={{ width: "80%" }}
                >
                  <img className="mt-3 Categories_img" src={Images.Layer5} />
                  {/* <span>
                    <img src={Images.dots} className="dots_img" />
                  </span> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  _renderSupportCard = () => {
    return (
      <>
        <div className="col-10 col-md-5 col-lg-4 offset-1 offset-md-0 offset-lg-0 p-3">
          <div
            className="py-3 px-3 px-md-4 px-lg-4 px-xl-5 shadow-sm mb-5 bg-white rounded cards"
            style={{ backgroundColor: "#FFFFFF" }}
          >
            <div className="row">
              <div className="col-4 col-md-2 col-lg-4 col-sm-12">
                <div className="row ">
                  <span className="dashboard-card-title mt-1">Supports</span>

                  {/* <span className="dashboard-card-subtitle">Lorem ipsum</span> */}

                  <div className="text-left">
                    <span className="dashboard-card-number">25</span>
                  </div>
                </div>
              </div>
              <div className="col-8 col-md-10 col-lg-8 col-sm-12">
                <div
                  className=" d-flex justify-content-between"
                  style={{ width: "100%" }}
                >
                  <img className=" mt-2 Supports_img" src={Images.Layer6} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  renderAppointmentCard = () => {
    const { allAppoin } = this.state


    return (
      <>
        <div className="col-12 col-md-6 col-lg-4  offset-md-0 offset-lg-0 ">

          <div className="py-3 shadow-sm mb-5 bg-white rounded cards">
            <div className="d-flex ml-0 px-md-2 px-lg-2 px-xl-3">
              <div className=" w-100 ">
                <span className="dashboard-card-title"><Translation>{t=>t('container.employe_dashboard_appointment')}</Translation></span>
                <p className="dashboard-card-subtitle">                
                  <Translation>{t=>t('container.employe_dashboard_appointment_waiting_title')}</Translation>
                </p>
              </div>
              <div>
                <img src={Images.dots} style={{ width: 30 }}></img>
              </div>
            </div>
            <hr className="separator"></hr>
            {allAppoin.map((item, index) => {
              let customerName = item?.userId?.firstName
                ? `${item?.userId?.firstName} ${item?.userId?.lastName}`
                : item?.userId?.userName;
              let customeProfileImage = item?.userId?.profile_img;

              if (item?.status === 1 && index > 2) {
                return (
                  <div className="d-flex align-items-center mt-2 mt-lg-1 mt-md-1 px-3">
                    {/* <img src={customeProfileImage ? customeProfileImage : Images.pic1} className="appointment_profile"></img> */}
                    <div className="mr-1 mr-lg-4 mr-md-3 ml-2 mt-3">
                      <span className="Title">{customerName ? customerName : "No Name"}</span>
                      <p className="time" style={{ color: "rgb(170, 67, 181)" }}>{item?.status === 1 ? "Waiting" : null}</p>
                    </div>
                    {/* <div className="row">
                      <img className="icons" src={Images.tick}></img>
                      <img className="icons" src={Images.cross}></img>
                    </div> */}
                  </div>
                );
              }

            })}

            <hr className="separator" />
            <div className="mt-3 ml-4 d-flex justify-content-between">
              <span className="add_employee_btn_active align-items-center justify-content-center"
                onClick={() => this.props.history.push("employee-my-appointment")}>
             {i18n.t('container.employe_dashboard_see_all_salon')} &gt; &gt;
              </span>
            </div>
          </div>
        </div>
      </>
    );
  };
  renderAvaiableEmployee = () => {
    const { employeeSalon } = this.state
    return (
      <>
        <div className="col-12 col-md-6 col-lg-8 offset-md-0 offset-lg-0">
          <div className="py-3 px-3 px-md-4 px-lg-4 px-xl-5 shadow-sm mb-5 bg-white rounded cards">
            <div className="d-flex row justify-content-between ">
              <span className="ml-3 dashboard-card-title"><Translation>{t=>t('container.employe_dashboard_active_salon')}</Translation></span>
              <span
                className="mr-3 add_employee_btn_active align-items-center justify-content-center"
                onClick={() =>
                  this.props.history.push("/employee-active-saloon")
                }
              >
              <Translation>{t=>t('container.employe_dashboard_see_all_salon')}</Translation>&gt; &gt;
              </span>
            </div>
            <div className="d-flex active-saloon-row ">
              <div className="col-md-3"><Translation>{t=>t('container.employe_dashboard_salon_name')}</Translation></div>
              <div className="col-md-5">ID</div>
              <div className="col-md-4"><Translation>{t=>t('container.employe_dashboard_phone_number')}</Translation></div>
            </div>
            {employeeSalon?.map((val, index) => {
              if (index > 3) return
              return (
                <div className="d-md-flex">
                  <div className="col-md-3">{val?.companyId?.name}</div>
                  <div className="col-md-5">{val?.companyId?._id}</div>
                  <div className="col-md-4">{val?.companyId?.businessTelephone}</div>
                </div>
              );
            })
            }
          </div>
        </div>
      </>
    );
  };
  _renderCard = () => {
    return (
      <>
        <div className="row">
          {this._renderEmployeeCard()}
          {this._renderCategoriesCard()}
          {/* {this._renderSupportCard()} */}
        </div>
        <div className="row ">
          {this.renderAppointmentCard()}
          {this.renderAvaiableEmployee()}
        </div>
      </>
    );
  };
  render() {
    const { usersList, blockUsersList, unblockUsersList } = this.state;
    return (
      <div>
        {this.renderLoader()}
        <div className="content-container">
          <div className="row">
            <div className=" col-12 col-lg-10 col-md-10  pt-3">
              <h1 className="Dashboard_heading"><Translation>{t=>t('container.employe_dashboard_for_employee')}</Translation></h1>
            </div>
          </div>
          {this._renderCard()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(Dashboard);
