import React from "react";
import Button from "@material-ui/core/Button";
import BasicTable from "../../Core/BasicTable";
import i18n from "../../../i18n";
import moment from "moment";

export default function FormTable({ data = [], handleView }) {
  const schema = [
    {
      header: i18n.t("name"),
      field: "formTitle",
      accessor: (row, field) => row.formId.formTitle,
    },
    {
      header: i18n.t("customer_name"),
      field: "customerName",
      accessor: (row, field) => row.userId?.firstName,
    },
    {
      header: i18n.t("status"),
      field: "status",
      accessor: (row, field) => i18n.t(row.status),
    },
    {
      header: i18n.t("submitted_date"),
      field: "submittedDate",
      accessor: (row, field) =>
        row[field] ? moment(row[field]).format("MM-DD-YYYY hh:mm a") : "",
    },
    {
      header: i18n.t("viewed_date"),
      field: "viewedDate",
      accessor: (row, field) =>
        row[field] ? moment(row[field]).format("MM-DD-YYYY hh:mm a") : "",
    },
    {
      header: i18n.t("actions"),
      field: "actions",
      render: (element, index) => {
        if (element.status === "Submitted") {
          return (
            <div>
              <span>
                <Button
                  key={`table-body-button-${element._id}`}
                  variant="outlined"
                  color="primary"
                  onClick={() => handleView(element)}
                >
                  {i18n.t("view")}
                </Button>
              </span>
            </div>
          );
        }
      },
    },
  ];

  return <BasicTable rows={data} schema={schema} />;
}
