import { connect } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import Select from "../../../components/Core/Select";
import DraggableDialog from "../../../components/DraggableDialog/DraggableDialog";

import i18n from "../../../i18n";
import Swal from "sweetalert2";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import PrimaryButton from "../../../components/Core/PrimaryButton";
import { addTransactionRequest as addTransaction } from "../../../redux/actions/Saloon/ProgramEnrollment";
import moment from "moment";
import DatePicker from "react-datepicker";
import { MenuItem } from "@material-ui/core";
import { BaseModal } from "../../../components";
import _ from "lodash";
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import PaymentForm from "../PaymentForm";
import TransactionDetails from "../TransactionDetails/TransactionDetails";

function CollectPaymentModal({
  open,
  setOpen,
  enrollment,
  accessToken,
  addTransaction,
  onSuccess,
}) {
  const form = useRef();
  const [data, setData] = useState({});

  const handleClose = () => {
    setData(null);
    setOpen(false);
  };

  const handleChange = (key, value) => {
    data[key] = value;
    setData({ ...data });
  };

  useEffect(() => {
    if (enrollment) {
      const {
        amount = 0,
        balanceDue = 0,
        courseId,
        includeTaxes,
        taxes = [],
      } = enrollment;
      const program = { ...courseId, price: amount };
      const collected = amount - balanceDue;
      setData({
        ...data,
        program,
        collected,
        balanceDue: program.price,
        subtotal: program.price,
        includeTaxes,
        taxDue: taxes.reduce((accum, tax) => accum + tax.amount, 0),
      });
    }
  }, [enrollment]);

  const handleSubmit = () => {
    const {
      cash,
      creditDebit,
      discountAmount: discount,
      taxes,
      includeTaxes,
      note,
      tip,
    } = data;
    const newTransaction =
      cash || creditDebit || discount
        ? {
            cash,
            creditDebit,
            discount,
            taxes: includeTaxes ? taxes : [],
            note,
            tip,
          }
        : null;
    if (newTransaction) {
      addTransaction(
        {
          access_token: accessToken,
          _id: enrollment._id,
          newTransaction,
        },
        {
          success: (data) => {
            SuccessHelper.handleSuccess(i18n.t("successfully_added"), true);
            onSuccess(data.data);
            setData(null);
            setOpen(false);
          },
          failure: () => {
            ErrorHelper.handleErrors(i18n.t("failed_to_add"), true);
          },
        }
      );
    }
  };
  if (!open || !data) {
    return null;
  }
  return (
    <div>
      <BaseModal
        title={i18n.t("collect_paymnet")}
        content={
          <div className="row mx-0">
            <ValidatorForm
              onSubmit={handleSubmit}
              ref={form}
              className="row col-6 mx-0"
              onError={(error) => {
                console.log(error);
              }}
            >
              <PaymentForm handleChange={handleChange} data={data} />
              <div className="row mx-0 px-0 justify-content-end mt-2 col-12">
                <PrimaryButton
                  props={{
                    type: "submit",
                  }}
                  label={data._id ? i18n.t("save") : i18n.t("create")}
                />
              </div>
            </ValidatorForm>
            {data.program ? (
              <TransactionDetails
                handleChange={handleChange}
                data={data}
                colClass="col-6"
              />
            ) : null}
          </div>
        }
        onClose={handleClose}
        open={open}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({});

const action = { addTransaction };

export default connect(mapStateToProps, action)(CollectPaymentModal);
