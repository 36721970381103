import { connect } from "react-redux";
import React, { Component } from "react";
import { SuccessHelper } from "../../helpers";
import i18n from "../../i18n";
import "./styles.css";
import { Images } from "../../theme";
import {Translation, withTranslation } from 'react-i18next';
import { Route, withRouter } from "react-router-dom";
import {
  getAdminSocialLinksForHome,
  subcribeEmail,
} from "../../config/simpleApiCalls";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterDescription: [],
      socialLinks: [],
      email: "",
      currentLanguage :sessionStorage.getItem('language')
    };

    //i18n.changeLanguage(this.state.currentLanguage)
  }

  componentDidMount() {
    this.getSocialLinks();
  }

  getSocialLinks() {
    getAdminSocialLinksForHome()
      .then((res) => {
        this.setState({ socialLinks: res.data.data[0].socialURL });
      })
      .catch((err) => console.log("err: ", err));
  }

  handleSubcribeEmail = () => {
    const { email } = this.state;

    let payload = {
      email,
      isActive: true,
    };

    subcribeEmail(payload)
      .then((res) => {
        if (res.data.success) {
          SuccessHelper.handleSuccess(<Translation>{t=>t('component.footer_thanks_subscribe')}</Translation>, true);
          this.setState({ email: "" });
        }
      })
      .catch((error) => {
        console.log("error:subcribeEmail ", error);
      });
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      filterDescription: nextProps.homePageData,
      currentLanguage: nextProps.language
    });
  }

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  renderPowerfulAdminPanel = () => {
    let { cardDetail, renderHeaderInfo } = this.props;

    return (
      <div>
        {renderHeaderInfo && (
          <div
          className="container-fluid py-5"
          style={{ backgroundColor: "#F7F8FA" }}
        >
            <div className="container mt-lg-3 my-xl-5">
              <div className="row">
                <div className="col-12 col-lg-6 order-lg-2 text-lg-left text-center pb-5">
                  <img
                    className=""
                    src={cardDetail?.body.img_url}
                    id="admin-panel-laptop"
                  />
                </div>
                <div className="col-12 col-lg-6">
                  <h2 id="admin-panel-heading">{cardDetail?.body.mainTitle}</h2>
                  {this.renderAdminPanelCard()}
                </div>
              </div>
            </div>
          )}
        </div>
        )}
      </div>
    );
  };

  renderAdminPanelCard = () => {
    let { cardDetail } = this.props;
    console.log("cardDetail: ", cardDetail);

    return cardDetail?.body.listItem.map((val, ind) => {
      return (
        <div key={ind} className="row mb-4 align-items-center">
          <div className="col-12 col-md-2 mb-3 mb-md-0">
            <span
              className="feature-icon shadow mx-auto"
              style={{ backgroundColor: val.imgBgColor }}
            >
              <img src={val.img_url} style={{ width: 50, height: 50 }} />
            </span>
          </div>
          <div className="col-12 col-md-10">
            <p className="mb-0 feature-heading">{val.title}</p>
            <p className="mb-0 feature-text">{val.des}</p>
          </div>
        </div>
      );
    });
  };

  renderMuchMore = () => {
    let { cardDetail, renderHeaderInfo } = this.props;

    return (
      <div className="container-fluid py-md-5 py-3">
        {renderHeaderInfo && (
          <div className="container mt-md-5 mt-4">
            <div className="col-12 mb-5">
              <h2 id="much-more-heading">{cardDetail?.muchMore.mainTitle}</h2>
            </div>
            <div className="col-lg-10 mx-auto">
              <div className="row">
                {cardDetail?.muchMore.listItem.map((val) =>
                  this.renderMuchMoreCard(val)
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  renderMuchMoreCard = (val) => {
    return (
      <div className="col-md-4 mb-4">
        <span className="much-more-icon mx-auto mb-3">
          <img src={val.img_url} style={{ width: 70, height: 70 }} />
        </span>
        <p className="much-more-sub-heading">{val.title}</p>
        <p className="much-more-des">{val.des}</p>
      </div>
    );
  };

  openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  renderDownloadSection = () => {
    let { cardDetail } = this.props;

    return (
      <div
        className="container-fluid py-md-5 py-3"
        style={{ backgroundColor: "#ff3600" }}
      >
        <div className="container my-lg-5 ">
          <div className="row">
            <div className="col-md-6 order-md-2 text-center">
              <img src={cardDetail?.footer.img_url} id="download-iphone-ipad" />
            </div>
            <div className="col-md-6">
              <h2 id="download-easy1-heading">
              { i18n.t(cardDetail?.footer.mainTitle)}                
              </h2>
              <p className="text-white mb-4 text-left">
                {i18n.t(cardDetail?.footer.des)}
              </p>
              {/* {cardDetail?.footer.listItem.map((v) =>
                v.title != "iOS" ? ( */}
              <span
                className="download-btn m-2"
                onClick={() =>
                  this.openInNewTab(cardDetail?.footer.android_link)
                }
              >
                <img src={Images.download_andriod} id="android-btn-img" />
              </span>
              {/* ) : ( */}
              <span
                className="download-btn m-2"
                onClick={() => this.openInNewTab(cardDetail?.footer.iOS_link)}
              >
                <img src={Images.download_ios} id="ios-btn-img" />
              </span>
              {/* )
              )} */}
              {/* <div className={"row"}>
                {cardDetail?.footer[0].listItem.map((v) => (
                  <button className="download-btn mr-2 row">
                    <img src={v.icon} id="android-btn-img" />
                    <p style={{ color: "white" }}>{v?.title}</p>
                  </button>
                ))}
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderNewletterCard = () => {
    const { email } = this.state;

    return (
      <div className="container-fluid py-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 py-md-5 py-3 mx-auto" id="newletter-card">
              <h3 className="mb-md-5 mb-4" id="subscribe-heading">
              {i18n.t('subscribe_newsletter')}
              </h3>
              <div className="d-flex flex-md-row flex-column justify-content-center align-items-center">
                <img
                  className="mb-3 mb-md-0"
                  src={Images.email_icon}
                  style={{ width: 30 }}
                />
                <input
                  type="email"
                  id="subscribe-input"
                  className="mb-3 mb-md-0"
                  name="email"
                  placeholder="email@example.com"
                  value={email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
                <button
                  id="subscribe-btn"
                  className="mb-3 mb-md-0"
                  onClick={() => this.handleSubcribeEmail()}
                >
                  {i18n.t('subscribe')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderAboutSection = () => {
    const { filterDescription, socialLinks } = this.state;
    return (
      <div className="container-fluid pb-4" id="about-sec">
        <div className="container">
          <div className="row mb-2">
            <div className="col-md-5 col-lg-3">
              <img src={Images.easy1_logo_800x300} style={{ width: 200 }} />
              <p>
                {i18n.t(filterDescription?.footerDescription)}
              </p>
            </div>
            <div className="col-md-5 col-lg-3 text-md-right ml-auto pt-md-4 pb-2 pb-md-0">
              {socialLinks.length &&
                socialLinks.map((v) => {
                  // console.log("v: ", v)

                  return (
                    <>
                      {v.socialType == "facebook" && v.url && (
                        <span onClick={() => this.openInNewTab(v.url)}>
                          <img
                            src={Images.facebook_icon}
                            style={{ width: 45, marginRight: 15 }}
                          />
                        </span>
                      )}

                      {v.socialType == "instagram" && v.url && (
                        <span onClick={() => this.openInNewTab(v.url)}>
                          <img
                            src={Images.instagram_icon}
                            style={{ width: 45, marginRight: 15 }}
                          />
                        </span>
                      )}

                      {v.socialType == "youtube" && v.url && (
                        <span onClick={() => this.openInNewTab(v.url)}>
                          <img
                            src={Images.youtube_icon}
                            style={{ width: 45, marginRight: 15 }}
                          />
                        </span>
                      )}
                      {v.socialType == "linkedin" && v.url && (
                        <span onClick={() => this.openInNewTab(v.url)}>
                          <img
                            src={Images.saloon_link_social_linkedin}
                            style={{ width: 45, marginRight: 15 }}
                          />
                        </span>
                      )}
                    </>
                  );
                })}

              {/* <span>
                <img
                  src={Images.facebook_icon}
                  style={{ width: 45, marginRight: 15 }}
                />
              </span>

              <span>
                <img
                  src={Images.instagram_icon}
                  style={{ width: 45, marginRight: 15 }}
                />
              </span>

              <span>
                <img
                  src={Images.youtube_icon}
                  style={{ width: 45, marginRight: 15 }}
                />
              </span>
              <img src={Images.google_plus_icon} style={{ width: 45 }} /> */}
            </div>
          </div>
          <div
            className="row pt-2 px-md-4"
            style={{ borderTop: "1px solid #000" }}
          >
            <div className="col-md-5 col-lg-4">
              <p>
                <small><Translation>{t=>t('copyright')}</Translation></small>
              </p>
            </div>
            <div className="col-md-6 mh-3 col-lg-5 text-md-right ml-auto">
              {/* <button
                type="button"
                className="btn btn-outline-primary btn-hov"
                onClick={() => this.props.history.push("/legal-info")}
              >
                <small>Legal Info</small>
              </button> */}

              {/* <button
                type="button"
                className="btn btn-outline-primary btn-hov"
                onClick={() => this.props.history.push("/privacy-policy")}
              >
            </button> */}
              <span
                className="mx-2 "
                onClick={() => this.props.history.push("/legal-info")}
              >
                <small className={"hvr-name"}><Translation>{t=>t('legal_infos')}</Translation></small>
              </span>
              <span className="mx-2">
                <small>&ndash;</small>
              </span>
              <span onClick={() => this.props.history.push("/privacy-policy")}>
                <small className={"hvr-name"}><Translation>{t=>t('privacy')}</Translation></small>
              </span>
              <span className="mx-2">
                <small>&ndash;</small>
              </span>
              <span onClick={() => this.props.history.push("/cookies-policy")}>
                <small className={"hvr-name"}><Translation>{t=>t('cookies_policy')}</Translation></small>
              </span>
              <span className="mx-2">
                <small>&ndash;</small>
              </span>
              <span onClick={() => this.props.history.push("/faqs")}>
                {/* <small className={"hvr-name"}><Translation>{t=>t('faq')}</Translation></small> */}
              </span>
              {/* <button
                type="button"
                className="btn btn-outline-primary btn-hov"
                onClick={() => this.props.history.push("/cookies-policy")}
              >
                <small>Cookie Policy</small>
              </button> */}
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.props.renderServices}
        {this.renderPowerfulAdminPanel()}
        {/* {this.props.renderBenefitsSection} */}
        {this.renderMuchMore()}
        {this.props.renderPricing_Plans}

        {this.renderDownloadSection()}
        {this.renderNewletterCard()}
        {this.renderAboutSection()}
      </div>
    );
  }
}

export default withRouter(Footer);
