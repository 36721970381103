import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import PayrollReportTable from "../../../components/Saloon/PayrollReport/PayrollReportTable.component";
import i18n from "../../../i18n";
import PrimaryButton from "../../../components/Core/PrimaryButton";
import { DotsLoader } from "../../../components";
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import { addWorkingHours } from "../../../config/simpleApiCalls";
import { getReportRequest } from "../../../redux/actions/Saloon/expense";
import DatePicker from "react-datepicker";
import moment from "moment";
import ExpenseTable from "../../../components/Saloon/Expense/ExpenseTable.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const PayrollReport = ({
  expenseReport = {},
  propsLoading,
  getReportRequest,
}) => {
  const [accessToken, setAccessToken] = useState("");
  const [startDate, setStartDate] = useState(
    moment(moment.now()).subtract(7, "d").toDate()
  );
  const [endDate, setEndDate] = useState(moment(moment.now()).toDate());
  const getReport = async (exportToXlsx) => {
    getReportRequest({
      from: startDate,
      to: endDate,
      access_token: accessToken,
      exportToXlsx,
    });
  };

  useEffect(() => {
    const saloonData = JSON.parse(sessionStorage.getItem("saloon"));
    setAccessToken(saloonData.access_token);
  }, []);

  useEffect(async () => {
    if (accessToken) {
      getReport();
    }
  }, [accessToken]);

  const setSelectedValue = (val) => {
    let currentDate = moment(Date.now());
    if (val == 1) {
      let day = currentDate.day();
      const convertedStart = moment(currentDate).subtract(day, "days");
      const convertedEnd = moment(convertedStart).add(6, "days");
      setStartDate(convertedStart.toDate());
      setEndDate(convertedEnd.toDate());
    } else if (val == 2) {
      const startOfMonth = moment(currentDate).startOf("month");
      const endOfMonth = moment(currentDate).endOf("month");
      setStartDate(startOfMonth.toDate());
      setEndDate(endOfMonth.toDate());
    } else if (val == 3) {
      const convertedStart = moment(
        `01-01-${currentDate.year()}`,
        "MM-DD-YYYY"
      );
      const convertedEnd = moment(`12-31-${currentDate.year()}`, "MM-DD-YYYY");
      setStartDate(convertedStart.toDate());
      setEndDate(convertedEnd.toDate());
    }
  };

  const onStartDateChange = (val) => {
    if (val > endDate) {
      setStartDate(endDate);
      setEndDate(val);
    } else {
      setStartDate(val);
    }
  };

  const onEndDateChange = (val) => {
    if (val < startDate) {
      setStartDate(val);
      setEndDate(startDate);
    } else {
      setEndDate(val);
    }
  };

  return (
    <div className="content-container">
      <DotsLoader isloading={propsLoading} />
      <div className="d-flex justify-content-between align-items-center">
        <h1 className="saloon-dashboard-heading pb-3">
          {i18n.t("expense_report")}
        </h1>
      </div>
      <div
        style={{ flex: 1, display: "flex", justifyContent: "space-between" }}
        className="employee-report-filters"
      >
        <div>{i18n.t('date_range')}</div>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <div className="mx-1">
            <DatePicker
              className="form-control saloon-form-input"
              placeholderText="Select Start Date"
              selected={startDate}
              onChange={onStartDateChange}
              dateFormat="MM/dd/yyyy"
            />
          </div>
          <div className="mx-1">
            <DatePicker
              className="form-control saloon-form-input"
              placeholderText="Select End Date"
              selected={endDate}
              onChange={onEndDateChange}
              dateFormat="MM/dd/yyyy"
            />
          </div>
        </div>
      </div>
      <div class="px-0">
        <div class="row employee-report-filters pt-3 px-0 mx-0">
          <div className="col-12 col-sm-12 col-md-3 d-flex px-0">
            {i18n.t("container.quick_filter")}
          </div>
          <div className="col-12 col-md-9 px-0 justify-content-end">
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-light btn-sm my-1"
                onClick={() => setSelectedValue(1)}
              >
                {i18n.t("container.week")}
              </button>
              <button
                type="button"
                className="btn btn-light btn-sm my-1"
                onClick={() => setSelectedValue(2)}
              >
                {i18n.t("container.month")}
              </button>
              <button
                type="button"
                className="btn btn-light btn-sm my-1"
                onClick={() => setSelectedValue(3)}
              >
                {i18n.t("container.year")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="px-0 row mx-0 justify-content-end">
        <button
          type="button"
          className="btn btn-accept btn-sm"
          onClick={() => getReport(true)}
        >
          {i18n.t("export")}
          <FontAwesomeIcon className="ml-1" icon={["far", "file-excel"]} />
        </button>
        <button
          type="button"
          className="btn btn-light btn-sm"
          onClick={() => getReport()}
        >
          {i18n.t("search")}
        </button>
      </div>
      <div className="mt-2">
        <ExpenseTable
          accessToken={accessToken}
          rows={expenseReport?.data}
          summary={expenseReport?.summary}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  expenseReport: state.expense.report,
  propsLoading: state.expense.isFetching,
});

const action = { getReportRequest };

export default connect(mapStateToProps, action)(PayrollReport);
