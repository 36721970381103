import { connect } from "react-redux";
import React, { Component } from "react";
import { Translation } from "react-i18next";
import i18n from "../../../i18n";
import { editBooking, getWaitingList } from "../../../config/simpleApiCalls";
import { Images } from "../../../theme";
import {
  DotsLoader,
  Header,
  AdvancedSearchDialog,
  BookingNotes,
} from "../../../components";
import Avatar from "react-avatar";
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import NumberFormat from "react-number-format";
import { getNestedValue } from "../../../util/objectMethods";
import moment from "moment";
import Sytles from "./sytles";

const weekNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
class WaitingList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      access_token: "",
      user: null,
      isloading: false,
      showEditDelete: "",
      searchText: "",
      activePage: 1,
      usersList: [],
      // employeeList: [],
      filterUsersList: [],
      showPopUp: false,
      showNotePopUp: false,
      bookingStauses: [
        { text: "Waiting", value: 1, styles: { color: "#aa43b5" } },
        { text: "Now Serving", value: 3, styles: { color: "#FFE478" } },
        { text: "Cancel", value: 2, styles: { color: "#FF7082" } },
        { text: "Done", value: 4, styles: { color: "#95DA47" } },
      ],
    };
  }

  componentDidMount = async () => {
    const saloonData = await JSON.parse(sessionStorage.getItem("saloon"));
    if (saloonData) {
      this.setState({
        access_token: saloonData.access_token,
        isloading: true,
      });
      getWaitingList(
        { companyId: JSON.parse(sessionStorage.getItem("company"))._id },
        saloonData.access_token
      )
        .then(({ data }) => {
          let waitingListEntries = [];
          if (data.success) {
            waitingListEntries = data.data;
          }
          this.setState({ waitingListEntries });
        })
        .finally(() => {
          this.setState({ isloading: false });
        });
    }
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  TemplateOption = () => {
    return (
      <ul
        style={{ marginLeft: "220px" }}
        class="list-group list-group-horizontal"
      >
        <li class="list-group-item li-padding li-radius-left">Day</li>
        <li class="list-group-item li-padding">Month</li>
        <li class="list-group-item li-padding li-radius-right">Year</li>
      </ul>
    );
  };

  onChangeSearchText = (searchText) => {
    this.setState({ searchText });
  };

  renderSearchBar = () => {
    const { searchText, filteredBookings } = this.state;
    return (
      <>
        <div className="col-12 col-lg-4 col-md-4 mt-4">
          <span>
            <span className="saloon-dashboard-heading">
              {i18n.t("waiting_list")}
            </span>
          </span>
        </div>
        <div className="col-12 col-lg-8 col-md-8 mt-4">
          <div className=" d-flex justify-content-end row">
            <div className="form-group">
              <input
                style={{ backgroundColor: "#FFFFFF", borderColor: "#EBEBEB" }}
                type="text"
                id="addEmployee_search_bar"
                className="form-control form-control-sm pl-5"
                placeholder={i18n.t(
                  "container.services_search_by_name_phone_number"
                )}
                onChange={(text) => this.onChangeSearchText(text.target.value)}
                value={searchText}
              />
              <img
                src={Images.saloon_search}
                className="ml-1 booking-search-bar-icon"
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  renderTableBody = (waitingList) => {
    const { bookingStauses, isGym } = this.state;

    return (
      <tbody>
        {waitingList.map((val, ind) => {
          return (
            <tr key={val._id} style={{ borderTop: "3px solid #F7F7F7" }}>
              <td>
                <div className="d-flex align-items-center">
                  {getNestedValue(val, ["userId", "userName"], "")}
                </div>
              </td>
              <td className=" Table_data">
                <div className="d-flex align-items-center mt-1">
                  <span className="appointment-details-employee-name">
                    {" "}
                    <span>
                      <NumberFormat
                        type={"text"}
                        style={Sytles.removeInputStyle}
                        value={getNestedValue(val, ["userId", "phoneNo"], "")}
                        disabled
                        format="### ### ####"
                      />
                      <div className="appointment-details-employee-name">
                        {" "}
                        {val.email || ""}{" "}
                      </div>
                    </span>
                  </span>
                </div>
              </td>
              <td className=" Table_data">
                <div className="d-flex align-items-center mt-1">
                  <span className="appointment-details-employee-name service-column">
                    {val.date}
                  </span>
                </div>
              </td>
              <td className=" Table_data">
                <div className="d-flex align-items-center mt-1">
                  <span className="appointment-details-employee-name service-column">
                    {val.startTime} - {val.endTime}
                  </span>
                </div>
              </td>
              <td className=" Table_data">
                <div className="d-flex align-items-center mt-1">
                  <span className="appointment-details-employee-name service-column">
                    {getNestedValue(val, ["serviceId", "name"], "")}
                  </span>
                </div>
              </td>
              <td className=" Table_data">
                <div className="d-flex align-items-center mt-1">
                  <span className="appointment-details-employee-name service-column">
                    {getNestedValue(
                      val,
                      ["employeeId", "userId", "firstName"],
                      ""
                    )}{" "}
                    {getNestedValue(
                      val,
                      ["employeeId", "userId", "lastName"],
                      ""
                    )}
                  </span>
                </div>
              </td>
            </tr>
          );
          // }
        })}
      </tbody>
    );
  };

  renderUserTable = () => {
    const {
      activePage,
      bookings,
      waitingListEntries = [],
      searchText,
      filtered,
      isGym,
    } = this.state;
    let toRender = waitingListEntries;
    if (searchText) {
      toRender = waitingListEntries.filter((val) => {
        if (
          getNestedValue(val.userId, "userName") &&
          (val.userId.userName
            ?.toLowerCase()
            .includes(searchText.toLowerCase()) ||
            val.userId.phoneNo?.includes(searchText))
        )
          return true;
        return false;
      });
    }
    return (
      <div className="col-12 p-3">
        <div className="table-responsive sticky-header">
          <table className="table table-borderless appointment-details-table">
            <thead>
              <tr>
                <th scope="col">
                  <span className="appointment-details-th-text">
                    <Translation>
                      {(t) => t("container.advancedSearchDialog_name")}
                    </Translation>
                  </span>
                </th>
                <th scope="col">
                  <span className="appointment-details-th-text">
                    <Translation>
                      {(t) => t("container.employe_phone_number")}
                    </Translation>
                    /
                  </span>
                  <span className="appointment-details-th-text">
                    <Translation>
                      {(t) => t("container.profile_email")}
                    </Translation>
                  </span>
                </th>
                <th scope="col">
                  <span className="appointment-details-th-text">
                    <Translation>{(t) => t("date")}</Translation>
                  </span>
                </th>
                <th scope="col">
                  <span className="appointment-details-th-text">
                    <Translation>{(t) => t("time")}</Translation>
                  </span>
                </th>
                <th scope="col">
                  <span className="appointment-details-th-text">
                    <Translation>
                      {(t) => t("container.profile_service")}
                    </Translation>
                  </span>
                </th>
                <th scope="col">
                  <span className="appointment-details-th-text">
                    <Translation>{(t) => t("container.employee")}</Translation>
                  </span>
                </th>
              </tr>
            </thead>
            {this.renderTableBody(toRender)}
          </table>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.renderLoader()}
        <div className="content-container pt-5">
          <div className="row">
            {this.renderSearchBar()}
            {this.renderUserTable()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(WaitingList);
