import { connect } from "react-redux";
import React, { Component } from "react";
import { set } from "../../redux/actions/ValidateBookingTransfer";

class Confirm extends Component {
  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const paramId = urlParams.get("id");
    const {
      match: {
        params: { id: urlId },
      },
    } = this.props;
    this.props.set({ id: urlId ? urlId : paramId, type: "details" });
    this.props.history.push("/");
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state) => ({});

const action = { set };

export default connect(mapStateToProps, action)(Confirm);
