import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import i18n from "../../../i18n";
import {
  updateRequest as updateReceptionistRequest,
  getRequest as getReceptionistAccess,
} from "../../../redux/actions/ReceptionistAccess";
import {
  updateRequest as updateManagerRequest,
  getRequest as getManagerAccess,
} from "../../../redux/actions/ManagerAccess";

import PrimaryButton from "../../../components/Core/PrimaryButton";
import { DotsLoader } from "../../../components";
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import TextField from "../../../components/Core/TextField";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Select from "../../../components/Core/Select";
import TabPanel from "../../../components/TabPanel/TabPanel.component";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const validResources = [
  "WorkingHours",
  "UploadMedia",
  "SocialLinks",
  "SiteSetUp",
  "RefundPolicy",
  "BlackListSettings",
  "LiveDisplaySettings",
  "CommunicationSettings",
  "EmployeeList",
  "AddEmployee",
  "FundRequests",
  "Booking",
  "WaitingList",
  "Inventory",
  "AddInvetory",
  "AllServices",
  "AddService",
  "Packages",
  "Orders",
  "Vendor",
  "Expense",
  "Form",
  "Customers",
  "AddCustomer",
  "ImportCustomer",
  "BookingReport",
  "EmployeeReport",
  "TaxReport",
  "SalesReport",
  "PayrollReport",
  "ExpenseReport",
  "ProductReport",
  "Rating",
  "Wallet",
  "TaxList",
  "AddTax",
  "Program",
];

const Roles = ({
  updateReceptionistRequest,
  updateManagerRequest,
  loading: propsLoading,
  receptionistAccess,
  managerAccess,
  getReceptionistAccess,
  getManagerAccess,
}) => {
  const [accessToken, setAccessToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [localManagerAccess, setLocalManagerAccess] = useState({});
  const [localReceptionistAccess, setLocalReceptionistAccess] = useState({});
  useEffect(() => {
    const saloonData = JSON.parse(sessionStorage.getItem("saloon"));
    setAccessToken(saloonData.access_token);
    if (!receptionistAccess?.resourceMap) {
      getReceptionistAccess({ access_token: saloonData.access_token });
    }
    if (!managerAccess?.resourceMap) {
      getManagerAccess({ access_token: saloonData.access_token });
    }
  }, []);
  useEffect(() => {
    const { _id, resourceMap = {} } = managerAccess;
    setLocalManagerAccess({ _id, resourceMap });
  }, [managerAccess]);
  useEffect(() => {
    const { _id, resourceMap = {} } = receptionistAccess;
    setLocalReceptionistAccess({ _id, resourceMap });
  }, [receptionistAccess]);
  const onSave = (data, action) => {
    action(
      {
        ...data,
        access_token: accessToken,
      },
      {
        success: () => {
          SuccessHelper.handleSuccess(i18n.t("successfully_updated"), true);
        },
        failure: () => {
          ErrorHelper.handleErrors(i18n.t("failed_to_update"), true);
        },
      }
    );
  };
  const onSaveManager = () => {
    const { _id, resourceMap = {} } = localManagerAccess;
    onSave({ _id, resourceMap }, updateManagerRequest);
  };
  const onSaveReceptionist = () => {
    const { _id, resourceMap = {} } = localReceptionistAccess;
    onSave({ _id, resourceMap }, updateReceptionistRequest);
  };
  const [tab, setTab] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };
  const togglePermissionSelection = (role, permission) => {
    if (role == "manager") {
      const copy = { ...(localManagerAccess.resourceMap || {}) };
      copy[permission] = !copy[permission];
      setLocalManagerAccess({ ...localManagerAccess, resourceMap: copy });
    } else {
      const copy = { ...(localReceptionistAccess.resourceMap || {}) };
      copy[permission] = !copy[permission];
      setLocalReceptionistAccess({
        ...localReceptionistAccess,
        resourceMap: copy,
      });
    }
  };
  return (
    <div className="content-container">
      <DotsLoader isloading={loading || propsLoading} />
      <div className="d-flex justify-content-between align-items-center">
        <h1 className="saloon-dashboard-heading pb-3">
          {i18n.t("container.roles")}
        </h1>
      </div>
      <AppBar position="static">
        <Tabs
          value={tab}
          onChange={handleTabChange}
          aria-label="simple tabs example"
        >
          <Tab label={i18n.t("manager")} {...a11yProps(0)} />
          <Tab label={i18n.t("receptionist")} {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={tab} index={0}>
        <div className="light-border-b mb-2">
          {validResources.map((value) => {
            return (
              <MenuItem
                key={value}
                onClick={() => togglePermissionSelection("manager", value)}
                className="light-border-x light-border-t"
                disableGutters
              >
                <div className="row mx-0 px-0"></div>
                <Checkbox
                  checked={Boolean(localManagerAccess?.resourceMap?.[value])}
                  onChange={() => togglePermissionSelection("manager", value)}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
                {i18n.t(value)}
              </MenuItem>
            );
          })}
        </div>
        <div className="row mx-0 justify-content-end">
          <PrimaryButton label={i18n.t("save")} onClick={onSaveManager} />
        </div>
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <div className="light-border-b mb-2">
          {validResources.map((value) => {
            return (
              <MenuItem
                key={value}
                onClick={() => togglePermissionSelection("receptionist", value)}
                className="light-border-x light-border-t"
                disableGutters
              >
                <div className="row mx-0 px-0"></div>
                <Checkbox
                  checked={Boolean(
                    localReceptionistAccess?.resourceMap?.[value]
                  )}
                  onChange={() =>
                    togglePermissionSelection("receptionist", value)
                  }
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
                {i18n.t(value)}
              </MenuItem>
            );
          })}
        </div>
        <div className="row mx-0 px-0 justify-content-end">
          <PrimaryButton label={i18n.t("save")} onClick={onSaveReceptionist} />
        </div>
      </TabPanel>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading:
    state.receptionistAccess.isUpdating || state.managerAccess.isUpdating,
  receptionistAccess: state.receptionistAccess.data,
  managerAccess: state.managerAccess.data,
});

const action = {
  getReceptionistAccess,
  getManagerAccess,
  updateReceptionistRequest,
  updateManagerRequest,
};

export default connect(mapStateToProps, action)(Roles);
