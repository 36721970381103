import { takeEvery, put, call } from 'redux-saga/effects';

import axios from 'axios';
import { announcements } from '../../config/WebServices';
import * as types from '../actions/ActionTypes';

import {success, failure} from '../actions/Announcements';

function callRequest() {
  return axios
    .get(
      announcements, 
      {
        headers: {
          Authorization: sessionStorage.getItem("access_token"),
        },
      }
    )
}

function* watchRequest(action) {
  try {
    const { data } = yield call(callRequest);
    if(data.success) {
      yield put(success(data.data));
    } else {
      yield put(failure());
    }
  } catch (err) {
    yield put(failure(err));
  }
}

export default function* root() {
  yield takeEvery(types.ANNOUNCEMENTS.REQUEST, watchRequest);
}
