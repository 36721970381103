import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import "./styles.scss";
import { getNestedValue } from "../../util/objectMethods";
import TextField from "@material-ui/core/TextField";
import i18n from "../../i18n";
import moment from "moment";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import ManageExtras from "../Extras/ManageExtras";
import BaseModal from "../BaseModal";
import TransactionDetails from "../TransactionDetails";
import Chip from "@material-ui/core/Chip";
import { getRequest as getTransactions } from "../../redux/actions/BookingTransaction";
import {
  confirmBooking,
  sendForm,
  resendForm,
  getFormsByBookingId,
  getCustomerByUserId,
} from "../../config/simpleApiCalls";
import { ErrorHelper, SuccessHelper } from "../../helpers";
import { getRequest as getForms } from "../../redux/actions/Saloon/FormBuilder";
import SendFormModal from "./components/SendFormModal.component";
import SendCommunicationsModal from "./components/SendCommunicationsModal.component";
import CancelModal from "./components/CancelModal";
import InfoIcon from "@material-ui/icons/Info";
import Swal from "sweetalert2";
import MailIcon from "@material-ui/icons/Mail";
import PrimaryButton from "../Core/PrimaryButton";
import CustomerCollectPayment from "../Saloon/Customer/CustomerCollectPayment";

const relatedStatusMap = {
  1: [2, 3, 6, 7],
  2: [3, 7],
};

const AppointmentModal = ({
  open,
  selectBooking,
  employeeMap = {},
  handleDrawerClose,
  statusButtonClicked,
  isSalon,
  isIndependent,
  onProductChange,
  onProductDelete,
  onServiceDelete,
  onNotesClick,
  onModifyClick,
  onAddClick,
  companyTaxes,
  toggleDepositRequired,
  onChangeTip,
  getTransactions,
  transactions = [],
  showInsertPayment,
  paymentCompleteCallback,
  setData,
  toggleTaxes,
  getForms,
  forms = [],
}) => {
  const access_token = sessionStorage.getItem("access_token");
  const statusButtonMap = {
    1: {
      label: i18n.t("container.services_waiting_status"),
    },
    2: {
      label: i18n.t("container.services_now_serving_status"),
    },
    3: {
      label: i18n.t("container.services_cancelled_status"),
    },
    4: {
      label: i18n.t("container.services_done_status"),
    },
    5: {
      label: i18n.t("finalize"),
    },
    6: {
      label: i18n.t("confirm"),
    },
    7: {
      label: i18n.t("no_show"),
    },
  };
  const readOnly = !!selectBooking.isFinalized;
  const status = selectBooking.status;
  const date = getNestedValue(selectBooking, ["services", 0, "date"], "");
  const email =
    getNestedValue(selectBooking, ["email"], "") ||
    getNestedValue(selectBooking, ["userId", "email"], "");
  let formattedDate;
  if (date) {
    formattedDate = moment(date, "MM-DD-YYYY");
  }
  const depositRequired = selectBooking.depositRequired;
  const depositAmount = selectBooking.depositAmount;
  const selectBookingAmount = parseFloat(selectBooking.totalAmount);
  const tip = selectBooking.tip ? parseFloat(selectBooking.tip) : 0;
  const [bookingForms, setBookingForms] = React.useState({});
  const [resendForms, setResendForms] = React.useState({});
  const [customer, setCustomer] = React.useState({});
  const [collectCustomerPaymentOpen, setCollectCustomerPaymentOpen] =
    React.useState(false);
  const getBookingForms = () => {
    getFormsByBookingId(access_token, { bookingId: selectBooking._id }).then(
      ({ data }) => {
        setBookingForms(data.data);
      }
    );
  };
  useEffect(() => {
    getTransactions({ access_token, bookingId: selectBooking._id });
    if (!forms.length) {
      getForms({
        access_token,
        companyId: selectBooking.companyId?._id,
      });
    }
    getBookingForms();
    getCustomerByUserId(
      { userId: selectBooking.userId._id },
      access_token
    ).then(({ data }) => {
      setCustomer(data.data);
    });
  }, []);
  const [formModalOpen, setFormModalOpen] = React.useState(false);
  const [communicationModalOpen, setCommunicationModalOpen] =
    React.useState(false);
  const [cancelModalOpen, setCancelModalOpen] = React.useState(false);

  const [communicationType, setCommunicationType] = useState();
  const onSendForm = (formIds, method) => {
    const getPromise = () => {
      if (resendForms) {
        return resendForm(access_token, {
          method,
          bookingId: selectBooking._id,
        });
      }
      if (!formIds.length) {
        setFormModalOpen(false);
        return;
      }
      return sendForm(access_token, {
        method,
        formIds,
        userId: selectBooking.userId._id,
        bookingId: selectBooking._id,
        companyId: selectBooking.companyId._id,
      });
    };
    const promise = getPromise();
    if (!promise) {
      return;
    }
    promise
      .then(() => {
        SuccessHelper.handleSuccess(i18n.t("successfully_added"), true);
        getBookingForms();
        setFormModalOpen(false);
      })
      .catch(() => {
        ErrorHelper.handleErrors(i18n.t("failed_to_add"), true);
      });
  };
  const service = getNestedValue(selectBooking, ["services", 0], undefined);
  const [statusAnchorEl, setStatusAnchorEl] = React.useState(null);
  const [formAnchorEl, setFormAnchorEl] = React.useState(null);
  const [sendAnchorEl, setSendAnchorEl] = React.useState(null);
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down("xs"));
  const buttonSize = isXS ? "small" : "medium";
  let history = useHistory();

  const handleRoutToChat = (firstName, salonId, img) => {
    if (isSalon) {
      history.push({
        pathname: "/saloon-chat",
        state: { name: firstName, _id: salonId, img },
      });
    } else {
      history.push({
        pathname: "/employee-chat",
        state: { name: firstName, _id: salonId, img },
      });
    }
  };

  const handleStatusClick = (event) => {
    setStatusAnchorEl(event.currentTarget);
  };
  const handleStatusClose = () => {
    setStatusAnchorEl(null);
  };

  const handleFormClick = (event) => {
    setFormAnchorEl(event.currentTarget);
  };
  const handleFormClose = () => {
    setFormAnchorEl(null);
  };

  const handleSendClick = (event) => {
    setSendAnchorEl(event.currentTarget);
  };
  const handleSendClose = () => {
    setSendAnchorEl(null);
  };

  let productsTotal = 0;
  const products = getNestedValue(
    selectBooking,
    ["cartId", "products"],
    []
  ).map((product) => {
    productsTotal += product.price * product.quantity;
    return {
      ...product,
      name: product.productId.name,
    };
  });
  let servicesTotal = parseFloat(selectBookingAmount);
  let extrasTotal = 0;
  getNestedValue(selectBooking, "extras", []).map((service) => {
    extrasTotal += service.price;
  });
  const total = servicesTotal + extrasTotal + productsTotal;
  const accountBalance =
    customer?.accountBalance && customer?.accountBalance > 0
      ? customer?.accountBalance
      : 0;
  return (
    <span className="appointment-drawer">
      <BaseModal
        open={open}
        {...{
          title: (
            <div>
              <div>
                {moment(`${service.date} ${service.time}`).format(
                  "MMMM Do YYYY h:mm a"
                )}
              </div>
              <Chip
                className="status-chip"
                size="small"
                label={statusButtonMap[status]?.label}
              />
              {selectBooking.userPackageId ? (
                <Chip
                  className="status-chip ml-1"
                  size="small"
                  label={i18n.t("prepaid")}
                />
              ) : null}
              {selectBooking.transferValidated ? (
                <Chip
                  className="status-chip ml-1"
                  size="small"
                  label={i18n.t("transfer_confirmed")}
                />
              ) : null}
              {selectBooking.isConfirmed ? (
                <Chip
                  className="status-chip ml-1"
                  size="small"
                  label={i18n.t("confirmed")}
                  icon={<InfoIcon />}
                  onClick={() => {
                    Swal.fire({
                      title:
                        selectBooking.confirmedBy == selectBooking.userId
                          ? i18n.t("confirmed_by_user")
                          : i18n.t("confirmed_an_employee"),
                      text: i18n.t("confirmed_by", {
                        name: selectBooking.confirmedBy?.firstName,
                      }),
                      confirmButtonText: "Okay",
                    });
                  }}
                ></Chip>
              ) : null}
            </div>
          ),
          content: (
            <div className={"my-appointment-drawer"}>
              {formModalOpen ? (
                <SendFormModal
                  sendForm={onSendForm}
                  handleClose={() => {
                    setFormModalOpen(false);
                  }}
                  company={selectBooking.companyId}
                  email={email}
                  resend={resendForms}
                />
              ) : null}
              {cancelModalOpen ? (
                <CancelModal
                  handleClose={() => setCancelModalOpen(false)}
                  onSubmit={(data) => {
                    statusButtonClicked(3, data);
                  }}
                />
              ) : null}
              {communicationModalOpen ? (
                <SendCommunicationsModal
                  company={selectBooking.companyId}
                  email={email}
                  handleClose={() => setCommunicationModalOpen(false)}
                  accessToken={access_token}
                  bookingId={selectBooking._id}
                  communicationType={communicationType}
                />
              ) : null}
              <div className="row mx-0">
                <div className="col-md-6 col-lg-8 px-1">
                  <form
                    className="form row mx-0 px-0"
                    noValidate
                    autoComplete="off"
                  >
                    <div className="col-xs-12 col-lg-6">
                      <TextField
                        id="standard-basic"
                        label={i18n.t("name")}
                        value={getNestedValue(
                          selectBooking,
                          ["userId", "userName"],
                          ""
                        )}
                        helperText={`ID: ${selectBooking._id}`}
                        readOnly
                      />
                    </div>
                    {isSalon || isIndependent ? (
                      <div className="col-xs-12 col-lg-6">
                        <TextField
                          id="standard-basic"
                          label={i18n.t("container.employe_phone_number")}
                          value={getNestedValue(selectBooking, ["phoneNo"], "")}
                          readOnly
                        />
                      </div>
                    ) : null}
                    {isSalon || isIndependent ? (
                      <div className="col-xs-12 col-lg-6">
                        <TextField
                          id="standard-basic"
                          label={i18n.t("container.profile_email")}
                          value={email}
                          readOnly
                        />
                      </div>
                    ) : null}
                    {service && (
                      <>
                        {isSalon && (
                          <div className="col-xs-12 col-lg-6">
                            <TextField
                              id="standard-basic"
                              label={i18n.t("container.profile_employe_name")}
                              value={employeeMap[service.employeeId]}
                              readOnly
                            />
                          </div>
                        )}
                      </>
                    )}
                  </form>
                  <div className="manage-extras-container">
                    <ManageExtras
                      products={products}
                      services={selectBooking.extras}
                      onProductChange={onProductChange}
                      onProductDelete={onProductDelete}
                      onServiceDelete={onServiceDelete}
                      onAddClick={onAddClick}
                      readonly={status == 3 || readOnly}
                      transactions={transactions}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 px-1">
                  <div className="row mx-0 justify-content-between account-balance">
                    {i18n.t("account_balance_with_amount", {
                      balance: accountBalance.toFixed(2),
                    })}
                    <PrimaryButton
                      label={i18n.t("collect")}
                      onClick={() => {
                        setCollectCustomerPaymentOpen(true);
                      }}
                    />
                  </div>
                  {collectCustomerPaymentOpen ? (
                    <CustomerCollectPayment
                      open={collectCustomerPaymentOpen}
                      handleClose={() => {
                        setCollectCustomerPaymentOpen(false);
                      }}
                      onSuccess={(result) => {
                        setCollectCustomerPaymentOpen(false);
                        setCustomer({
                          ...customer,
                          accountBalance:
                            result.companyCustomer?.accountBalance,
                        });
                      }}
                      userId={customer.userId}
                      accountBalance={accountBalance}
                      access_token={access_token}
                    />
                  ) : null}
                  <TransactionDetails
                    bookingId={selectBooking._id}
                    products={products}
                    services={selectBooking.extras}
                    service={getNestedValue(
                      selectBooking,
                      ["services", 0, "serviceId"],
                      null
                    )}
                    serviceAmount={selectBookingAmount}
                    depositRequired={depositRequired}
                    depositAmount={depositAmount}
                    companyTaxes={companyTaxes}
                    toggleDepositRequired={toggleDepositRequired}
                    toggleTaxes={toggleTaxes}
                    tip={parseFloat(tip)}
                    showInsertPayment={showInsertPayment}
                    paymentCompleteCallback={paymentCompleteCallback}
                    readOnly={readOnly}
                    setData={setData}
                    includeTaxes={selectBooking.includeTaxes}
                    onChangeTip={onChangeTip}
                    total={total}
                    onCompleteStatusUpdate={() => {
                      statusButtonClicked(4);
                    }}
                    discount={selectBooking.discount || 0}
                    accountBalance={accountBalance}
                  />
                </div>
              </div>
              <div className="row justify-content-between button-row my-1 mt-2 px-0">
                <Button
                  onClick={() => onNotesClick()}
                  size={buttonSize}
                  style={{
                    backgroundColor: "#ff3600",
                    color: "white",
                  }}
                >
                  {i18n.t("notes")}
                </Button>
                {/* <span>
                  <FontAwesomeIcon
                    onClick={() =>
                      handleRoutToChat(
                        getNestedValue(selectBooking, ["userId"], "").firstName,
                        getNestedValue(selectBooking, ["userId"], "")._id,
                        getNestedValue(selectBooking, ["userId"], "")
                          .profile_img
                      )
                    }
                    size="2x"
                    icon={faComment}
                    className="chatSystemIcon"
                  />
                </span> */}

                {/* {selectBooking._id} */}
                {status == 1 && (
                  <Button
                    onClick={() => onModifyClick()}
                    size={buttonSize}
                    style={{
                      backgroundColor: "#ff3600",
                      color: "white",
                    }}
                  >
                    {i18n.t("modify")}
                  </Button>
                )}
                <>
                  <Button
                    size={buttonSize}
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    style={{
                      backgroundColor: "#ff3600",
                      color: "white",
                    }}
                    variant="contained"
                    color="primary"
                    onClick={handleFormClick}
                  >
                    {i18n.t("form")}
                  </Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={formAnchorEl}
                    keepMounted
                    open={Boolean(formAnchorEl)}
                    onClose={handleFormClose}
                  >
                    <MenuItem
                      onClick={() => {
                        setFormModalOpen(true);
                        setResendForms(true);
                        handleFormClose();
                      }}
                    >
                      {i18n.t("resend_form")}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setFormModalOpen(true);
                        setResendForms(false);
                        handleFormClose();
                      }}
                    >
                      {i18n.t("new_form")}
                    </MenuItem>
                    <div className="row mx-0 px-0 justify-content-center font-bold light-border-b">
                      {i18n.t("sent_forms")}
                    </div>
                    {Object.keys(bookingForms).map((status) => {
                      return (
                        <>
                          <div className="MuiListItem-gutters MuiListItem-root font-bold">
                            {i18n.t(status)}
                          </div>
                          {bookingForms[status].map((form) => {
                            return (
                              <MenuItem
                                onClick={() => {
                                  window.open(
                                    `/form-fill/${form._id}`,
                                    "_blank"
                                  );
                                }}
                              >
                                {form.formId.formTitle}
                              </MenuItem>
                            );
                          })}
                        </>
                      );
                    })}
                  </Menu>
                </>
                <Button
                  onClick={handleSendClick}
                  size={buttonSize}
                  style={{
                    backgroundColor: "#ff3600",
                    color: "white",
                  }}
                >
                  <MailIcon />
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={sendAnchorEl}
                  keepMounted
                  open={Boolean(sendAnchorEl)}
                  onClose={handleSendClose}
                >
                  <MenuItem
                    onClick={() => {
                      setCommunicationType("confirmation");
                      setCommunicationModalOpen(true);
                      setSendAnchorEl();
                    }}
                  >
                    {i18n.t("confirmation")}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setCommunicationType("invoice");
                      setCommunicationModalOpen(true);
                      setSendAnchorEl();
                    }}
                  >
                    {i18n.t("invoice")}
                  </MenuItem>
                </Menu>
                <div>
                  {relatedStatusMap[status] && !selectBooking.isFinalized ? (
                    <>
                      <Button
                        size={buttonSize}
                        aria-controls="customized-menu"
                        aria-haspopup="true"
                        style={{
                          backgroundColor: "#ff3600",
                          color: "white",
                        }}
                        variant="contained"
                        color="primary"
                        onClick={handleStatusClick}
                      >
                        {i18n.t("status")}
                      </Button>
                      <Menu
                        id="simple-menu"
                        anchorEl={statusAnchorEl}
                        keepMounted
                        open={Boolean(statusAnchorEl)}
                        onClose={handleStatusClose}
                      >
                        {relatedStatusMap[status].map((element) => {
                          if (element == 6) {
                            if (!selectBooking.isConfirmed) {
                              return (
                                <MenuItem
                                  onClick={() => {
                                    handleStatusClose();
                                    confirmBooking(
                                      { id: selectBooking._id },
                                      access_token
                                    )
                                      .then(({ data }) => {
                                        if (data.success) {
                                          SuccessHelper.handleSuccess(
                                            i18n.t("success"),
                                            true
                                          );
                                        } else {
                                          ErrorHelper.handleErrors(
                                            i18n.t("something_went_wrong"),
                                            true
                                          );
                                        }
                                      })
                                      .catch(() => {
                                        ErrorHelper.handleErrors(
                                          i18n.t("something_went_wrong"),
                                          true
                                        );
                                      });
                                  }}
                                >
                                  {statusButtonMap[element].label}
                                </MenuItem>
                              );
                            }
                          } else {
                            return (
                              <MenuItem
                                onClick={() => {
                                  handleStatusClose();
                                  if (element == 3) {
                                    setCancelModalOpen(true);
                                  } else {
                                    statusButtonClicked(
                                      element == 5 ? 4 : element
                                    );
                                  }
                                }}
                              >
                                {statusButtonMap[element].label}
                              </MenuItem>
                            );
                          }
                        })}
                      </Menu>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          ),
        }}
        onClose={() => handleDrawerClose()}
      />
    </span>
  );
};

const mapStateToProps = (state) => ({
  transactions: state.bookingTransaction.data,
  forms: state.formBuilder.data,
});

const action = { getTransactions, getForms };

export default connect(mapStateToProps, action)(AppointmentModal);
