import { connect } from "react-redux";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Chip } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import Select from "../../../components/Core/Select";
import DraggableDialog from "../../../components/DraggableDialog/DraggableDialog";

import i18n from "../../../i18n";
import Swal from "sweetalert2";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import PrimaryButton from "../../../components/Core/PrimaryButton";
import moment from "moment";
import DatePicker from "react-datepicker";
import { MenuItem } from "@material-ui/core";
import { BaseModal } from "../../../components";
import _ from "lodash";
import BasicTable from "../../../components/Core/BasicTable";
import { Checkbox } from "@material-ui/core";

function EventModal({
  open,
  setOpen,
  studentGroups,
  scheduledStudents,
  students,
  active = {},
  onSubmit,
  filteredStudents,
}) {
  const [scheduledMap, setScheduledMap] = useState({});
  const [search, setSearch] = useState("");

  useEffect(() => {
    const map = {};
    if (scheduledStudents) {
      scheduledStudents.map((student) => {
        map[student.studentId._id] = true;
      });
    }
    setScheduledMap(map);
  }, [scheduledStudents]);
  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    onSubmit({
      students: Object.keys(scheduledMap).map((studentId) => ({ studentId })),
    });
  };

  const availableStudentMap = useMemo(() => {
    const map = {};
    filteredStudents.map((student) => {
      map[student._id] = true;
    });
    return map;
  }, [filteredStudents]);

  const textFilteredStudents = useMemo(() => {
    if (!search) {
      return filteredStudents;
    }
    return filteredStudents.filter((student) => {
      return student.name.toLowerCase().includes(search.toLowerCase());
    });
  }, [search]);

  if (!open) {
    return null;
  }

  return (
    <div>
      <BaseModal
        title={i18n.t("manage_scheduled_students")}
        contentContainerStyle={{
          overflowY: "visible",
        }}
        content={
          <>
            <div className="row mx-0">
              <div className="col-12 px-0 mb-2">
                <TextField
                  label={i18n.t("search")}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="row mx-0">
              {studentGroups.map((studentGroup) => {
                return (
                  <Chip
                    key={studentGroup._id}
                    className="status-chip"
                    onClick={() => {
                      studentGroup.students.map((student) => {
                        if (availableStudentMap) {
                          scheduledMap[student._id] = true;
                        }
                      });
                      setScheduledMap({ ...scheduledMap });
                    }}
                    style={{
                      backgroundColor: "#e1e1e1",
                      margin: 4,
                      cursor: "pointer",
                    }}
                    size="small"
                    label={studentGroup.name}
                  />
                );
              })}
            </div>
            <BasicTable
              rows={textFilteredStudents}
              schema={[
                {
                  header: i18n.t("scheduled"),
                  style: { width: 80 },
                  field: "scheduled",
                  render: (val) => {
                    return (
                      <Checkbox
                        checked={scheduledMap[val._id] || false}
                        onChange={() => {
                          if (scheduledMap[val._id]) {
                            delete scheduledMap[val._id];
                          } else {
                            scheduledMap[val._id] = true;
                          }
                          setScheduledMap({ ...scheduledMap });
                        }}
                        inputProps={{ "aria-label": "enrolled" }}
                      />
                    );
                  },
                },
                {
                  header: i18n.t("student"),
                  field: "student",
                  style: { verticalAlign: "middle" },
                  accessor: (val) => val.name,
                },
              ]}
            />
            <div className="row mx-0 justify-content-end mt-1">
              <PrimaryButton label={i18n.t("save")} onClick={handleSubmit} />
            </div>
          </>
        }
        onClose={handleClose}
        open={open}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  students: state.student.data,
  studentGroups: state.studentGroup.data,
  programs: state.program.data,
  classes: state._class.data,
});

const action = {};

export default connect(mapStateToProps, action)(EventModal);
