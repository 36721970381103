import React, { Component, Fragment } from "react";
import  {Translation}  from 'react-i18next';
import i18n from '../../../i18n';
import { Images } from "../../../theme";
import "./styles.css";
import {
  getSaloonTemplateData,
  getCompanyPorfolio,
  createUpdateTemplate,
  getTemplateData
}
  from "../../../config/simpleApiCalls";
import SideNav from "./SideNav";
import ReactStars from "react-rating-stars-component";
import { RiEditFill } from 'react-icons/ri';
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import { SuccessHelper, ErrorHelper } from "../../../helpers";
import Modal from "react-bootstrap/Modal";
import { DotsLoader } from "../../../components";
import {
  baseUrl
} from "../../../config/WebServices";


class Template2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      routeName: "",
      showSidebar: false,
      showDropdownMenu: false,
      companyId: "",
      portfolioImages: [],
      portfolioVideos: [],
      // weekPlans: [
      //     { dayName: "Sunday", dayOfWeek: 1, availableStatus: 0, },
      //     { dayName: "Monday", dayOfWeek: 2, availableStatus: 0, },
      //     { dayName: "Tuesday", dayOfWeek: 3, availableStatus: 0, },
      //     { dayName: "Wednesday", dayOfWeek: 4, availableStatus: 0, },
      //     { dayName: "Thursday", dayOfWeek: 5, availableStatus: 0, },
      //     { dayName: "Friday", dayOfWeek: 6, availableStatus: 0, },
      //     { dayName: "Saturday", dayOfWeek: 7, availableStatus: 0, },
      // ],
      companyList: [],
      companyPortfolio: [],
      isloading: false,
      employeData: [],

      // edit template
      isEditTemplate: false,
      mainHeading: 'Classic Hairstyles and Shaves',
      sub_heading_1: 'Our Hair Styles',
      sub_heading_2: 'enhances your smile',
      jumbotronImgFile: null,
      jumbotronImgUrl: null,
      headerFirstHeading: 'Classic Hairstyles and Shaves',
      headerSecondHeading: 'Our Hair Styles',
      sinceText: 'Since 1991',
      aboutFirstHeading: ' Introducing',
      aboutSecHeading: '  The Barber Shop',
      aboutImgUrl: null,
      aboutImgFile: null,
      priceBgImgUrl: null,
      priceBgImgFile: null,
      aboutSecBgImgFile: null,
      aboutSecBgImgUrl: null

    };

    this.services = React.createRef();
    this.aboutUs = React.createRef();
    this.pricing = React.createRef();
    this.appointment = React.createRef();
    this.term = React.createRef();
    this.footer = React.createRef();
    this.home = React.createRef();
  }

  componentDidMount = async () => {
    this.setState({ isLoading: true })

    const { location: { pathname } } = this.props;
    const pathPieces = pathname.split('/')
    let companyId = pathPieces[2];
    if (pathname === '/saloon-edit-template-2') {
      this.setState({ isEditTemplate: true });
      const { _id } = JSON.parse(sessionStorage.getItem("company"))
      companyId = _id
    }

    const user = await JSON.parse(sessionStorage.getItem("user"));
    this.setState({
      companyId,
      user: user,
    });
    if (companyId) {
      this.getAllData();
    } else {
      this.getAllTemplateData()
    }

    this.getPortfolioData();
  };


  getAllTemplateData = () => {
    let token = JSON.parse(sessionStorage.getItem('saloon'))
    const { state } = this.props.location

    getTemplateData(token.access_token, state).then((res) => {
      this.setState({ isloading: true })
      if (res.data.success) {
        if (res.data.data != null) {
          this.setState({
            headerFirstHeading: res.data.data.headerFirstHeading,
            headerSecondHeading: res.data.data.headerSecondHeading,
            jumbotronImgFile: res.data.data.headerImage,
            jumbotronImgUrl: res.data.data.headerImage,
            aboutFirstHeading: res.data.data.aboutFirstHeading,
            aboutSecHeading: res.data.data.aboutSecondHeading,
            aboutImgFile: res.data.data.aboutFirstImg,
            aboutImgUrl: res.data.data.aboutFirstImg,
            aboutSecBgImgUrl: res.data.data.aboutSecondImg,
            aboutSecBgImgFile: res.data.data.aboutSecondImg,
            priceBgImgUrl: res.data.data.footerImage,
            sinceText: res.data.data.sinceText,
            
            priceBgImgFile: res.data.data.footerImage,
            isloading: false

          })
        }else{
          this.setState({isloading: false})
        }
      }
    }).catch((err) => { this.setState({ isloading: false }) })
  }

  getAllData = () => {
    this.setState({
      isloading: true,
    });
    const { companyId } = this.state;
    const payload = { companyId };
    getSaloonTemplateData(payload)
      .then((res) => {

        console.log(res.data)
        if (res.data.success) {
          if (res.data.data[0] != null) {
            this.setState({companyList: res.data.data})

            this.setState({

              companyList: res.data.data,
              employeData: res.data.data[0],
              headerFirstHeading: res.data.data[0].customTempalte.headerFirstHeading,
              headerSecondHeading: res.data.data[0].customTempalte.headerSecondHeading,
              jumbotronImgFile: res.data.data[0].customTempalte.headerImage,
              jumbotronImgUrl: res.data.data[0].customTempalte.headerImage,
              aboutFirstHeading: res.data.data[0].customTempalte.aboutFirstHeading,
              aboutSecHeading: res.data.data[0].customTempalte.aboutSecondHeading,
              aboutImgFile: res.data.data[0].customTempalte.aboutFirstImg,
              aboutImgUrl: res.data.data[0].customTempalte.aboutFirstImg,
              aboutSecBgImgUrl: res.data.data[0].customTempalte.aboutSecondImg,
              sinceText:res.data.data[0].customTempalte.sinceText,
              aboutSecBgImgFile: res.data.data[0].customTempalte.aboutSecondImg,
              priceBgImgUrl: res.data.data[0].customTempalte.footerImage,
              priceBgImgFile: res.data.data[0].customTempalte.footerImage,
              isloading: false
            });
          }
        }
      })
      .catch((error) => {
        console.log(error, "errorrrrrrrrrrrrrrrrrrr");
      });
  };


  getPortfolioData = () => {
    this.setState({ isloading: true });
    const { companyId } = this.state;
    const payload = { companyId };
    getCompanyPorfolio(payload)
      .then((res) => {
        if (res.data.success) {
          // this.setState({ isloading: false, companyPortfolio: res.data.data });
          let portfolioImages = [];
          let portfolioVideos = [];
          res.data.data.map((val) => {
            if (val.post && val.post.length > 0) {
              val.post.map((val2, ind2) => {
                if (val2.images.length > 0) {
                  portfolioImages.push(val2.images[0]);
                } else if (val2.videos.length > 0) {
                  portfolioVideos.push(val2.videos[0]);
                }
              });
            }
          });
          this.setState({ isloading: false, portfolioImages, portfolioVideos });
        }
      })
      .catch((error) => {
        console.log(error, "errorrrrrrrrrrrrrrrrrrr");
      });
  };

  handleView = (key) => {
    this[key].current.scrollIntoView({ behavior: "smooth" });
  };
  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };
  renderLargeNavbar = () => {
    const {
      location: { pathname },
    } = this.props;

    const { user, companyList } = this.state;

    let CompanyLogo = companyList[0]?.saloons[0]?.companyLogo;

    return (
      <div
        id="large-navbar"
        style={{ backgroundColor: "#222227" }}
        className="d-flex align-items-center justify-content-between"
      >
        <img
          style={{
            height: "80px",
            marginLeft: "50px",
            marginTop: "10px",
            padding: "1rem",
          }}
          alt="easy1 logo 800x300"
          src={CompanyLogo}
          onClick={() => this.handleNavigation("/")}
          className="logo cursor-pointer"
        />
        <span className="d-flex align-items">
          <div class="nav-white">
            <span
              onClick={() => this.handleView("home")}
              className="navbar-link active"
            >
              <a>Home</a>
            </span>
            <span
              onClick={() => this.handleView("services")}
              className="navbar-link"
            >
              <a><Translation>{t=>t('container.services')}</Translation></a>
            </span>
            <span
              onClick={() => this.handleView("aboutUs")}
              className="navbar-link"
            >
              <a>About Us</a>
            </span>
            <span
              onClick={() => this.handleView("term")}
              className="navbar-link"
            >
              <a>Team</a>
            </span>
            <span
              onClick={() => this.handleView("pricing")}
              className="navbar-link"
            >
              <a>Pricing</a>
            </span>
            <span
              onClick={() => this.handleView("appointment")}
              className="navbar-link"
            >
              <a>Appointment</a>
            </span>
            <span
              onClick={() => this.handleView("footer")}
              className="navbar-link"
            >
              <a>Contact</a>
            </span>
          </div>
        </span>
      </div>
    );
  };
  onChangeMainHeadingText = (text) => this.setState({ mainHeading: text.target.value })
  onChangeSubHeading_1 = (text) => this.setState({ sub_heading_1: text.target.value })
  onChangeSubHeading_2 = (text) => this.setState({ sub_heading_2: text.target.value })
  onChangeAboutFirstHeading = (text) => this.setState({ aboutFirstHeading: text.target.value })
  onChangeAboutSecHeading = (text) => this.setState({ aboutSecHeading: text.target.value })
  onChangeSinceText = (text) => this.setState({ sinceText: text.target.value })




  renderEditButtons = (onPresEdit, onPressRemove) => {
    return (
      <span className="templateEditBtnDiv">
        <span onClick={onPresEdit} className="templateEditBtns"><RiEditFill color="#fff" size={17} /></span>
        <span onClick={onPressRemove} className="templateEditBtns">&times;</span>
      </span>
    )
  }
  renderEditTextInputs = (value, onChange, classes) => (
    <input
      type="text"
      value={value}
      onChange={(text) => onChange(text)}
      className={classes}
    />
  )

  renderEditTemplateHeader = () => (
    <div className="editTemplateHeaderDiv">
      <span
        onClick={() => this.handlePublishBtn()}
        className="editTemplateHeaderBtn"
      >Publish</span>
      <span
        onClick={() => this.handleNavigation('/saloon-choose-a-template')}
        className="editTemplateHeaderBtn"
      >Cancel</span>
    </div>
  )
  handlePublishBtn = () => {
    const {
      jumbotronImgFile,
      headerFirstHeading,
      headerSecondHeading,
      sinceText,
      aboutFirstHeading,
      aboutSecHeading,
      aboutImgFile,
      priceBgImgFile,
      aboutSecBgImgFile
    } = this.state;
    // return;

    const { state } = this.props.location
    this.setState({ isloading: true })




    let payloadFormdata = new FormData();
    payloadFormdata.append('headerImage', jumbotronImgFile);
    payloadFormdata.append('headerFirstHeading', headerFirstHeading);
    payloadFormdata.append('headerSecondHeading', headerSecondHeading);
    payloadFormdata.append('sinceText', sinceText);
    payloadFormdata.append('aboutFirstHeading', aboutFirstHeading);
    payloadFormdata.append('aboutSecondHeading', aboutSecHeading);
    payloadFormdata.append('aboutFirstImg', aboutImgFile);
    payloadFormdata.append('aboutSecondImg', aboutSecBgImgFile);
    payloadFormdata.append('footerImage', priceBgImgFile);
    payloadFormdata.append('templateID', state);


    let token = JSON.parse(sessionStorage.getItem('saloon'))


    createUpdateTemplate(payloadFormdata, token.access_token).then((res) => {
      if (res.data.success) {
        this.setState({ isloading: false })

        SuccessHelper.handleSuccess("Published successfully", true);
      } else {
        this.setState({ isloading: false })

        ErrorHelper.handleErrors("Failed to publish", true);
      }
    })

    // for (var key of payloadFormdata.entries()) {
    //   console.log(key[0] + ', ' + key[1]);
    // }
  }

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  jumbotronImgHandler = (event) => {
    const imageUrlRegex = /([a-zA-Z0-9\s_\\.\-:])+(.png|.jpeg|.jpg|.gif)$/gm;
    if (event.target.files[0]) {
      let isValidateType = event.target.files[0].type
      if (isValidateType.match(imageUrlRegex)) {
        const file = URL.createObjectURL(event.target.files[0]);
        this.setState({ jumbotronImgUrl: file, jumbotronImgFile: event.target.files[0] });
      } else {
        ErrorHelper.handleErrors('Image format not supported', true)
      }
    }

  };
  priceBgImgHandler = (event) => {
    const imageUrlRegex = /([a-zA-Z0-9\s_\\.\-:])+(.png|.jpeg|.jpg|.gif)$/gm;
    if (event.target.files[0]) {
      let isValidateType = event.target.files[0].type
      if (isValidateType.match(imageUrlRegex)) {
        const file = URL.createObjectURL(event.target.files[0]);
        this.setState({ priceBgImgUrl: file, priceBgImgFile: event.target.files[0] });
      } else {
        ErrorHelper.handleErrors('Image format not supported', true)
      }
    }
  };

  aboutImgHandler = (event) => {
    const imageUrlRegex = /([a-zA-Z0-9\s_\\.\-:])+(.png|.jpeg|.jpg|.gif)$/gm;
    if (event.target.files[0]) {
      let isValidateType = event.target.files[0].type
      if (isValidateType.match(imageUrlRegex)) {
        const file = URL.createObjectURL(event.target.files[0]);
        this.setState({ aboutImgUrl: file, aboutImgFile: event.target.files[0] });
      } else {
        ErrorHelper.handleErrors('Image format not supported', true)
      }
    }
  };

  abourSecondImageHandler = (event) => {
    const imageUrlRegex = /([a-zA-Z0-9\s_\\.\-:])+(.png|.jpeg|.jpg|.gif)$/gm;

    if (event.target.files[0]) {
      let isValidateType = event.target.files[0].type
      if (isValidateType.match(imageUrlRegex)) {
        const file = URL.createObjectURL(event.target.files[0]);
        this.setState({ aboutSecBgImgUrl: file, aboutSecBgImgFile: event.target.files[0] });
      } else {
        ErrorHelper.handleErrors('Image format not supported', true)
      }
    }

  };

  renderSmallNavbar = () => {
    const { showSidebar } = this.state;
    let data = {
      services: this.services,
      aboutUs: this.aboutUs,
      pricing: this.pricing,
      appointment: this.appointment,
      term: this.term,
      footer: this.footer,
      home: this.home,
    };
    return (
      <div id="small-navbar" style={{ backgroundColor: "#222227" }}>
        <div className="py-2 d-flex align-items-center justify-content-between">
          <img
            style={{ width: "100px", marginLeft: "10px" }}
            alt="easy1 logo 800x300"
            src={Images.saloon_template2_nav}
            className="logo cursor-pointer"
            onClick={() => this.handleNavigation("/")}
          />
          <span
            className="d-flex align-items-center"
            onClick={() =>
              this.setState({ showSidebar: showSidebar ? false : true })
            }
          >
            <img
              src={Images.saloon_navbar_menu}
              style={{ width: 35, height: 35, cursor: "pointer" }}
            />
          </span>
        </div>
        <div className="px-2">
          <SideNav refe={data} {...this.props} showSidebar={showSidebar} />
        </div>
      </div>
    );
  };
  
  goToAppointmentForm = async () => {
    // const { user } = this.state;
    // const {
    //   location: { pathname },
    //   history: { push },
    // } = this.props;
    // push(`${pathname}/create-appointment`);
    const { companyId } = this.state;
    const user = await JSON.parse(sessionStorage.getItem("user"));
    window.location.assign(baseUrl+`booking/getForm?id=${companyId}&phoneNo=${user ? user.phoneNo : ''}&name=${user ? user.userName : ''}`); 
  };

  // renderJumbotron = () => {
  //   const { isEditTemplate, mainHeading, sub_heading_1, sub_heading_2, jumbotronImgFile, jumbotronImgUrl } = this.state
  //   return (
  //     <div
  //       ref={this.home}
  //       id="home"
  //       id="carouselExampleIndicators"
  //       class="carousel slide"
  //       data-ride="carousel"
  //     >
  //       <ol class="carousel-indicators">
  //         <li
  //           data-target="#carouselExampleIndicators"
  //           data-slide-to="0"
  //           class="active"
  //         ></li>
  //         <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
  //         <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
  //       </ol>
  //       <div class="container-fluid px-0">
  //         <div class="carousel-inner">
  //           <div class="carousel-item active">
  //             <div
  //               className="container-fluid px-0 min-vh-100"
  //               style={{
  //                 backgroundImage: `url(${jumbotronImgFile ? jumbotronImgUrl : Images.saloon_template2_slider})`,
  //                 backgroundSize: "cover",
  //                 backgroundPosition: "center",
  //               }}
  //             >

  //               {isEditTemplate && (
  //                 <input
  //                   type="file"
  //                   style={{ display: "none" }}
  //                   onChange={this.jumbotronImgHandler}
  //                   onClick={(event) => { event.target.value = null }}
  //                   ref={(fileInput) => (this.jumbotronImgInput = fileInput)}
  //                   accept="image/*"
  //                 />
  //               )}
  //               {isEditTemplate && this.renderEditButtons(
  //                 () => this.jumbotronImgInput.click(),
  //                 () => this.setState({ jumbotronImgFile: null, jumbotronImgUrl: null })
  //               )}

  //               {/* <img class="d-block w-100 " src={Images.saloon_template2_slider} alt="First slide"/> */}
  //               {/* <div class="carousel-caption text-left"> */}
  //             </div>
  //             <div class="row">
  //               <div className="left-centered" style={{ width: "50%" }}>
  //                 <div class="col-lg-8 col-md-8">
  //                   {!isEditTemplate && (
  //                     <h3
  //                       class="main-heading"
  //                     >{mainHeading}</h3>
  //                   )}
  //                   {isEditTemplate && this.renderEditTextInputs(mainHeading, this.onChangeMainHeadingText, "mainInp")}
  //                   {/* <h3 class="main-heading">Classic Hairstyles and Shaves</h3> */}

  //                   {!isEditTemplate && (
  //                     <h1
  //                       class="sub-heading"
  //                     >{sub_heading_1}</h1>
  //                   )}
  //                   {isEditTemplate && this.renderEditTextInputs(sub_heading_1, this.onChangeSubHeading_1, "subInp")}
  //                   {/* <h1 class="sub-heading">Our Hair Styles</h1> */}


  //                   {!isEditTemplate && (
  //                     <h1
  //                       class="sub-heading"
  //                     >{sub_heading_2}</h1>
  //                   )}
  //                   {isEditTemplate && this.renderEditTextInputs(sub_heading_2, this.onChangeSubHeading_2, "subInp")}
  //                   {/* <h1 class="sub-heading">enhances your smile</h1> */}



  //                   <br></br>
  //                   <p class="sub-text"></p>
  //                   <button onClick={this.goToAppointmentForm} class="btn-text">
  //                     Make Appointment
  //                   </button>
  //                 </div>
  //               </div>
  //             </div>
  //             {/* </div> */}
  //           </div>
  //           <div class="carousel-item">
  //             <div
  //               className="container-fluid px-0 min-vh-100"
  //               style={{
  //                 backgroundImage: `url(${Images.saloon_template2_slider})`,
  //                 backgroundSize: "cover",
  //                 backgroundPosition: "center",
  //               }}
  //             >
  //               {/* <img class="d-block w-100 " src={Images.saloon_template2_slider} alt="First slide"/> */}
  //               {/* <div class="carousel-caption text-left"> */}
  //             </div>
  //             <div class="row">
  //               <div className="left-centered">
  //                 <div class="col-lg-8 col-md-8">
  //                   <h3 class="main-heading">Hairstyles and Shaves</h3>
  //                   <h1 class="sub-heading">Our Hair Styles</h1>
  //                   <h1 class="sub-heading">enhances your smile</h1>
  //                   <br></br>
  //                   <p class="sub-text">
  //                     Lorem Ipsum is simply dummy text of the printing and
  //                     typesetting industry. Lorem Ipsum has been the industry's
  //                     standard dummy text ever since the 1500s
  //                   </p>
  //                   <button onClick={this.goToAppointmentForm} class="btn-text">
  //                     Make ----- Appointment
  //                   </button>
  //                 </div>
  //               </div>
  //             </div>
  //             {/* </div> */}
  //           </div>
  //         </div>
  //       </div>
  //       <a
  //         class="carousel-control-prev"
  //         // href="#carouselExampleIndicators"
  //         role="button"
  //         data-slide="prev"
  //       >
  //         <span class="carousel-control-prev-icon" aria-hidden="true"></span>
  //         <span class="sr-only">Previous</span>
  //       </a>
  //       <a
  //         class="carousel-control-next"
  //         href="#carouselExampleIndicators"
  //         role="button"
  //         data-slide="next"
  //       >
  //         <span class="carousel-control-next-icon" aria-hidden="true"></span>
  //         <span class="sr-only">Next</span>
  //       </a>
  //     </div>
  //   );
  // };
  onChangeJumbotronSecHeading = (text) => this.setState({ headerSecondHeading: text.target.value })
  onChangeJumbotronFirstHeading = (text) => this.setState({ headerFirstHeading: text.target.value })



  renderJumbotron = () => {
    const {
      isEditTemplate,
      jumbotronImgUrl,
      jumbotronImgFile,
      headerFirstHeading,
      headerSecondHeading,
      jumbotronFirstHeading,
      jumbotronSecHeading
    } = this.state;
    return (
      <div
        className={`container-fluid px-0 min-vh-100 background ${isEditTemplate ? 'editImg' : ''}`}
        style={{
          backgroundImage: `url(${jumbotronImgFile != null ? jumbotronImgUrl : Images.saloon_template2_slider})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {isEditTemplate && (
          <input
            type="file"
            style={{ display: "none" }}
            onChange={this.jumbotronImgHandler}
            onClick={(event) => { event.target.value = null }}
            ref={(fileInput) => (this.jumbotronImgInput = fileInput)}
            accept="image/*"

          />
        )}
        {isEditTemplate && this.renderEditButtons(
          () => this.jumbotronImgInput.click(),
          () => this.setState({ jumbotronImgFile: null, jumbotronImgUrl: null })
        )}
        {/* {this.renderHeaderSm()} */}
        <div className="container">
          {/* {this.renderHeaderLg()} */}
          <div ref={this.home} id="home" className="row">
            <div className="left-centered">
              {!isEditTemplate && (
                <h2 className="template-2-main-header" style={{
                  textTransform: 'uppercase',
                }}>{headerFirstHeading != null ? headerFirstHeading : 'Let yor hair do the'}</h2>
              )}
              {isEditTemplate && this.renderEditTextInputs(
                headerFirstHeading,
                this.onChangeJumbotronFirstHeading,
                "mainInp"
              )}
              {/* <br /> */}
              {!isEditTemplate && (
                <h1 className="template-2-main-header" style={{
                  textTransform: 'uppercase'
                }}>{headerSecondHeading != null ? headerSecondHeading : 'Talking'}</h1>
              )}
              {isEditTemplate && this.renderEditTextInputs(
                headerSecondHeading,
                this.onChangeJumbotronSecHeading,
                "subInp"
              )}
              <br></br>
              <p class="sub-text"></p>
              <button onClick={this.goToAppointmentForm} class="btn-text">
                Make Appointment
               </button>
            </div>
            {/* <div class="row">
              <div class="drop-centered">
                <a href="#home">
                  <img
                    class="text-center"
                    src={Images.saloon_template_dropdown}
                  />
                </a>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    );
  };
  renderAboutUs = () => {
    const {
      companyList,
      isEditTemplate,
      sinceText,
      aboutFirstHeading,
      aboutSecHeading,
      aboutImgUrl,
      aboutSecBgImgFile,
      aboutSecBgImgUrl,
      aboutImgFile
    } = this.state;
    return (
      <div ref={this.aboutUs} id="AboutUs">
        <div class="container" style={{ paddingTop: 80, paddingBottom: 60 }}>
          <div class="row">
            <div class="col-lg-6 col-md-6">

              {!isEditTemplate && (
                <h3
                  class="text-center"
                  style={{ color: "#222227", fontWeight: "bold" }}
                >{aboutFirstHeading ? aboutFirstHeading : 'ABOUT'}</h3>
              )}
              {isEditTemplate && this.renderEditTextInputs(aboutFirstHeading, this.onChangeAboutFirstHeading, "aboutInp")}

              {/* <h3
                class="text-center"
                style={{ color: "#222227", fontWeight: "bold" }}
              >
                Introducing
                      </h3> */}

              {!isEditTemplate && (
                <h2
                  class="text-center"
                  style={{ color: "#9D8977", fontWeight: "bold" }}
                >{aboutSecHeading ? aboutSecHeading : 'ABOUT'}</h2>
              )}
              {isEditTemplate && this.renderEditTextInputs(aboutSecHeading, this.onChangeAboutSecHeading, "aboutSecInp")}

              {!isEditTemplate && (
                <h2
                  class="text-center"
                  style={{ color: "#9D8977", fontWeight: "bold" }}
                >{sinceText != null ? sinceText : 'ABOUT'}</h2>
              )}
              {isEditTemplate && this.renderEditTextInputs(sinceText, this.onChangeSinceText, "aboutSecInp")}
              {/* <h2
                class="text-center"
                style={{ color: "#9D8977", fontWeight: "bold" }}
              >
                The Barber Shop
                      </h2>
              <h2
                class="text-center"
                style={{ color: "#9D8977", fontWeight: "bold" }}
              >
                Since 1991
                      </h2> */}
              <div class="text-center">
                {isEditTemplate && (
                  <input
                    type="file"
                    style={{ display: "none" }}
                    class="img-fluid"
                    onChange={this.abourSecondImageHandler}
                    onClick={(event) => { event.target.value = null }}
                    ref={(fileInput) => (this.aboutsecImageInput = fileInput)}
                    accept="image/*"
                  />
                )}
                {isEditTemplate && this.renderEditButtons(
                  () => this.aboutsecImageInput.click(),
                  () => this.setState({ aboutSecBgImgFile: null, aboutSecBgImgUrl: null })
                )}
                <img class="img-fluid" src={aboutSecBgImgFile != null ? aboutSecBgImgUrl : Images.saloon_template2_aboutlogo} />

                {/* <img
                  class="img-fluid"
                  src={Images.saloon_template2_aboutlogo}
                /> */}

              </div>
            </div>
            {companyList.map(
              (val) =>
                val.saloons &&
                val.saloons.map((saloonsVal) => {
                  return (
                    <div class="col-lg-6 col-md-6" style={{ padding: "1rem" }}>

                      <p class="text-center" style={{ color: "#222227" }}>
                        {saloonsVal.companyShortDescription}
                      </p>
                      <div class="text-center">
                        <button class="btn-text">Make Appointment</button>
                      </div>
                    </div>
                  );
                })
            )}
            <div
              class="col-lg-6 col-md-6"
              style={{ padding: "1rem", marginTop: 40 }}
            >
              {isEditTemplate && (
                <input
                  type="file"
                  style={{ display: "none" }}
                  onChange={this.aboutImgHandler}
                  onClick={(event) => { event.target.value = null }}
                  ref={(fileInput) => (this.aboutImgInput = fileInput)}
                  accept="image/*"
                />
              )}
              <div className={isEditTemplate ? 'editImg' : ''}>
                {isEditTemplate && this.renderEditButtons(
                  () => this.aboutImgInput.click(),
                  () => this.setState({ aboutImgFile: null, aboutImgUrl: null })
                )}
                <img class="img-fluid" src={aboutImgFile != null ? aboutImgUrl : Images.saloon_template2_aboutus} />
              </div>
              {/* <div>
                <img class="img-fluid" src={Images.saloon_template2_aboutus} />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderServiceComponent = (img, heading, duration) => {
    return (
      <div class="col-12 col-md-3">
        <div
          class="item d-flex justify-content-center flex-column align-items-center cursor-pointer"
          style={{
            padding: "1rem",
            marginBottom: 15,
            backgroundColor: "#FFFFFF",
            width: 200,
          }}
          onClick={this.goToAppointmentForm}
        >
          <img style={{ marginBottom: 15, width: 80 }} src={img} />
          <h5 class="text-center">
            {heading} ({duration}{" "}{i18n.t("minutes_short")})
          </h5>
          <div>
            <p style={{ textAlign: "center", padding: "1rem" }}>{}</p>
          </div>
        </div>
      </div>
    );
  };

  renderServices = () => {
    const { companyList } = this.state;
    return (
      <div ref={this.services} id="Services">
        <div
          class="feature-clean"
          style={{ backgroundColor: "#EEEDED", paddingBottom: 50 }}
        >
          <div class="container">
            <div class="intro">
              <h2
                style={{ fontSize: "30px", fontWeight: "bold", paddingTop: 50 }}
                class="text-center mb-0"
              >
                OUR SERVICES
              </h2>
              <div class="text-center" style={{ marginBottom: 40 }}>
                <img src={Images.saloon_template2_headline} />
              </div>
              <div class="row features justify-content-center">
                {companyList.map(
                  (val) =>
                    val.services &&
                    val.services.map((serviceVal) => {
                      return (
                        <div>
                          {this.renderServiceComponent(
                            serviceVal.image,
                            serviceVal.name,
                            serviceVal?.duration
                          )}
                        </div>
                      );
                    })
                )}
                {/* {this.renderServiceComponent(Images.saloon_template2_service_haircut,'Lorem Ipsum',"Barber is a person whose occupation is mainly to cut dress style")}
                            {this.renderServiceComponent(Images.saloon_template2_service_hair_dryer,'Lorem Ipsum',"Barber is a person whose occupation is mainly to cut dress style")}
                            {this.renderServiceComponent(Images.saloon_template2_service_electric_razor,'Lorem Ipsum',"Barber is a person whose occupation is mainly to cut dress style")}
                            {this.renderServiceComponent(Images.saloon_template2_service_razor_1,'Lorem Ipsum',"Barber is a person whose occupation is mainly to cut dress style")}
                            {this.renderServiceComponent(Images.saloon_template2_service_razor,'Lorem Ipsum',"Barber is a person whose occupation is mainly to cut dress style")}
                            {this.renderServiceComponent(Images.saloon_template2_service_cream,'Lorem Ipsum',"Barber is a person whose occupation is mainly to cut dress style")}
                            {this.renderServiceComponent(Images.saloon_template2_service_brush,'Lorem Ipsum',"Barber is a person whose occupation is mainly to cut dress style")}
                            {this.renderServiceComponent(Images.saloon_template2_service_straightener,'Lorem Ipsum',"Barber is a person whose occupation is mainly to cut dress style")} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderInput = (text) => {
    return (
      <div class="col-lg-6 item">
        <input class="appointmentFormInput" type="text" placeholder={text} />
      </div>
    );
  };

  renderDropDown = (text) => {
    return (
      <div class="col-lg-6 item">
        <select class="appointmentFormInput">
          <option>{text}</option>
        </select>
      </div>
    );
  };

  renderAppointment = () => {
    const { isEditTemplate, priceBgImgFile, priceBgImgUrl } = this.state
    return (
      <div ref={this.appointment} id="Appointment">
        {isEditTemplate && (
          <input
            type="file"
            style={{ display: "none" }}
            onChange={this.priceBgImgHandler}
            onClick={(event) => { event.target.value = null }}
            ref={(fileInput) => (this.priceBgImgInput = fileInput)}
            accept="image/*"
          />
        )}
        {isEditTemplate && this.renderEditButtons(
          () => this.priceBgImgInput.click(),
          () => this.setState({ priceBgImgFile: null, priceBgImgUrl: null })
        )}
        <div class="row">
          <div class="col-lg-6 col-md-6 px-0">
            <div
              className="container-fluid px-0 min-vh-100"
              style={{
                backgroundImage: `url(${Images.saloon_template2_img1})`,
                backgroundImage: `url(${priceBgImgFile != null ? priceBgImgUrl : Images.saloon_template2_img1})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
            </div>
          </div>
          <div class="col-lg-6 col-md-6 px-0">
            <div
              className="container-fluid px-0 min-vh-100"
              style={{
                backgroundImage: `url(${Images.saloon_template2_img2})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div
                class="col-lg-8 item"
                style={{ color: "#9D8977", padding: "2rem", paddingTop: 60 }}
              >
                <h2 class="">Make Appointment</h2>
                {/* <p class="">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p> */}
              </div>
              <div class="row" style={{ marginLeft: 10 }}>
                {/* <div class="col-lg-6 item" id="appointmentForm" style={{paddingLeft:0}}>
                        {this.renderInput('Your Name')}
                    </div>
                    <div class="col-lg-6 item" id="appointmentForm" style={{paddingLeft:0}}>
                        {this.renderInput('Your Postal Code')}
                    </div>
                    <div class="col-lg-6 item" id="appointmentForm" style={{paddingLeft:0}}>
                        {this.renderInput('Your Telephone')}
                    </div>
                    <div class="col-lg-6 item" id="appointmentForm" style={{paddingLeft:0}}>
                        {this.renderInput('Your Email')}
                    </div> */}
                {/* <div class="col-lg-6 item" id="appointmentForm" style={{paddingLeft:0}}>
                        {this.renderDropDown('Timings')}
                    </div>
                    <div class="col-lg-6 item" id="appointmentForm" style={{paddingLeft:0}}>
                        {this.renderDropDown('Services')}
                    </div>
                    <div class="col-lg-6 item" id="appointmentForm" style={{paddingLeft:0}}>
                        {this.renderDropDown('Employer')}
                    </div> */}
                <div class="col-lg-12">
                  <button
                    onClick={this.goToAppointmentForm}
                    class="appointmentBtn"
                  >
                    Make Appointment
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderPrices = (style, price, info) => {
    return (
      <div class="col-lg-4" style={{}}>
        <div id="borderPrice">
          <div
            class="container"
            style={{
              height: "40px",
              width: "90px",
              marginTop: -30,
              backgroundColor: "#EEEDED",
            }}
          >
            <img src={Images.saloon_template2_mustache} class="borderTop" />
          </div>
          <div class="boxPricing">
            <div style={{ marginTop: 30 }}>
              <p style={{ marginBottom: 0, color: "#222227" }}>
                {style}
                <span>
                  <p
                    style={{
                      float: "right",
                      marginBottom: 0,
                      color: "#E3AA41",
                    }}
                  >
                    {price}
                  </p>
                </span>
              </p>
              <hr style={{ borderTop: "1px dotted #222227" }} />
              {/* <p>{info}</p>
                    <p style={{marginBottom:0,color:'#222227'}}>{style}<span><p style={{float:'right', marginBottom:0, color:'#E3AA41'}}>{price}</p></span></p>
                     <hr style={{borderTop:'1px dotted #222227'}}/>
                    <p>{info}</p>
                    <p style={{marginBottom:0,color:'#222227'}}>{style}<span><p style={{float:'right', marginBottom:0, color:'#E3AA41'}}>{price}</p></span></p>
                     <hr style={{borderTop:'1px dotted #222227'}}/>
                    <p>{info}</p>
                    <p style={{marginBottom:0,color:'#222227'}}>{style}<span><p style={{float:'right', marginBottom:0, color:'#E3AA41'}}>{price}</p></span></p>
                     <hr style={{borderTop:'1px dotted #222227'}}/>
                    <p>{info}</p> */}
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderPricing = () => {
    const { companyList } = this.state;
    return (
      <div ref={this.pricing} id="Prices">
        <div
          class="feature-clean"
          style={{
            backgroundColor: "#EEEDED",
            width: "100%",
            paddingBottom: 50,
            paddingTop: 20,
          }}
        >
          <div class="container">
            <div class="intro">
              <h2
                style={{ fontSize: "30px", fontWeight: "bold", paddingTop: 50 }}
                class="text-center mb-0"
              >
                OUR PRICING
              </h2>
              <div class="text-center" style={{ marginBottom: 40 }}>
                <img src={Images.saloon_template2_headline} />
              </div>
              <div class="row justify-content-center">
                {companyList.map(
                  (val) =>
                    val.services &&
                    val.services.map((serviceVal) => {
                      return (
                        <div>
                          {this.renderPrices(serviceVal.name, serviceVal.price)}
                        </div>
                      );
                    })
                )}
                {/* {this.renderPrices('HAIR CUT','19$','Hair Cutting, Washing, Drying')}
                            {this.renderPrices('HAIR CUT','19$','Hair Cutting, Washing, Drying')}
                            {this.renderPrices('HAIR CUT','19$','Hair Cutting, Washing, Drying')} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderStarImage = () => {
    return (
      <img
        src={Images.saloon_template2_star}
        style={{ width: "20px", marginRight: 5, marginTop: "20px" }}
      />
    );
  };

  renderEmployeeInfo = (image, name) => {
    return (
      <div class="col-md-6 col-lg-3 item" style={{ height: "400px" }}>
        <div class="boxEmployee">
          <div>
            <img src={image} style={{ width: "150px", borderRadius: "50px" }} />
            <h3>{name}</h3>
            {this.renderStarImage()}
            {this.renderStarImage()}
            {this.renderStarImage()}
            {this.renderStarImage()}
            {this.renderStarImage()}
            <div style={{ marginTop: "50px" }}>
              <hr />
            </div>
            <div>
              {/* <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p> */}
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderEmployees = () => {
    const { companyList, employeData } = this.state;

    return (
      <div ref={this.term} id="Team">
        <div
          className="team-grid"
          style={{ backgroundColor: "#272e32", paddingBottom: "60px" }}
        >
          <div class="container">
            <div class="intro">
              <h2
                style={{
                  fontSize: "30px",
                  fontWeight: "bold",
                  color: "#FFFFFF",
                  paddingTop: "100px",
                }}
                class="text-center mb-0"
              >
                OUR EMPLOYEES
              </h2>
              <hr
                style={{
                  height: "10px",
                  border: "none",
                  backgroundColor: "#BAD26D",
                  width: "220px",
                }}
              />
              <div className="row" style={{ paddingTop: 50 }}>
                {employeData.employees &&
                  employeData.employees.length &&
                  employeData?.employees.filter(employee => !!employee && typeof employee.userId == 'object').map((val) => {
                    let firstName = val?.employeeId?.userId.firstName;
                    let lastName = val?.employeeId?.userId.lastName;
                    let rating = val?.employeeId?.averageRating;

                    return (
                      <div class="col-lg-4 col-md-4" id="price-grid">
                        <Fragment>
                          <h6 class="price-heading">
                            {firstName + " " + lastName}
                          </h6>

                          {/* <p className="text-center mb-0">{"price"}$</p> */}
                          <div
                            className="pl-4"
                            style={{
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <ReactStars
                              count={5}
                              // onChange={ratingChanged}
                              size={24}
                              activeColor="orange"
                              value={rating}
                              edit={false}
                            />
                          </div>
                        </Fragment>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div class="row" style={{ paddingTop: 50 }}>
              {companyList.map(
                (val) =>
                  val.employeeDetails &&
                  val.employeeDetails.map((employeeVal) => {
                    return (
                      <div class="col-md-6 col-lg-4 item">
                        {this.renderEmployeesItem(
                          employeeVal.profile_img,
                          employeeVal.firstName,
                          employeeVal.lastName
                        )}
                      </div>
                    );
                  })
              )}

              {/* <div class="col-md-6 col-lg-4 item"> */}
              {/* {this.renderEmployeesItem()} */}
              {/* {this.renderEmployeesItem(Images.saloon_template_employee1,"Ben Johnson","Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s")} */}
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderContact = () => {
    const { companyList } = this.state;
    return (
      <div ref={this.footer} id="Contact">
        <div class="container">
          <div class="row" style={{ alignItems: "center" }}>
            <div class="col-lg-6 col-md-6">
              <div class="contactInfo" style={{ paddingTop: 100 }}>
                <h3>Get in touch with us & send us message today</h3>
                {companyList.map(
                  (val) =>
                    val.saloons &&
                    val.saloons.map((saloonsVal) => {
                      return (
                        <div>
                          <p>{saloonsVal.address}</p>
                          <p><Translation>{t=>t('container.profile_email')}</Translation>: {saloonsVal?.businessEmail}</p>
                          <p><Translation>{t=>t('container.employe_phone_number')}</Translation>: {saloonsVal?.businessTelephone}</p>
                        </div>
                      );
                    })
                )}
              </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="boxContact">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-6" id="contactForm">
                      <input
                        class="form-group"
                        style={{ padding: "1rem", border: "1px solid #EDEDED" }}
                        type="text"
                        placeholder="Name"
                      />
                    </div>
                    <div class="col-lg-6">
                      <input
                        class="form-group"
                        style={{ padding: "1rem", border: "1px solid #EDEDED" }}
                        type="text"
                        placeholder="Email"
                      />
                    </div>
                    <div class="col-lg-12">
                      <textarea
                        class="form-group"
                        style={{
                          padding: "1rem",
                          width: "100%",
                          height: "100px",
                          border: "1px solid #EDEDED",
                        }}
                        placeholder="Message"
                      />
                    </div>
                    <div class="col-lg-6">
                      <button class="btn-text" style={{ fontWeight: "bold" }}>
                        Send Message
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderPortfolioItem = (type, image) => {
    return (
      <div class="col-lg-3 col-md-4">
        <a href={type} data-attribute="SRL">
          <img class="img-fluid" src={image} style={{ marginBottom: 20 }} />
        </a>
      </div>
    );
  };
  renderPortfolioItemVideo = (image) => {
    return (
      <div>
        <div class="col-lg-3 col-md-4">
          <video width="320" height="240" controls>
            <source src={image} />
          </video>
        </div>
      </div>
    );
  };

  renderPortfolio = () => {
    const { portfolioImages } = this.state;
    return (
      <div class="photo-gallery" style={{ paddingBottom: "80px" }}>
        <div class="container">
          <div class="intro">
            <h2
              style={{ fontSize: "30px", fontWeight: "bold", paddingTop: 50 }}
              class="text-center mb-0"
            >
              OUR PORTFOLIO
            </h2>
            <div class="text-center" style={{ marginBottom: 40 }}>
              <img src={Images.saloon_template2_headline} />
            </div>
          </div>
          <SRLWrapper>
            <div class="row">
              {portfolioImages &&
                portfolioImages.map((val) => {
                  return (
                    <div class="col-lg-3 col-md-4">
                      <img class="img-fluid1" src={val.url} />
                    </div>
                  );
                })}
            </div>
          </SRLWrapper>
        </div>
      </div>
    );
  };

  // renderPortfolio = () => {
  //     return (
  //     <div class="photo-gallery" style={{paddingBottom:'80px'}}>
  //     <div class="container">
  //         <div class="intro">
  //         <h2 style={{ fontSize: '30px', fontWeight: 'bold', paddingTop:50}} class="text-center mb-0">OUR PORFOLIO</h2>
  //             <div class="text-center" style={{marginBottom:40}}>
  //                 <img src={Images.saloon_template2_headline}/>
  //             </div>
  //         </div>
  //         <SRLWrapper>
  //         <div class="row">
  //             {this.renderPortfolioItem(Images.saloon_template3_portfolio1,Images.saloon_template3_portfolio1)}
  //             {this.renderPortfolioItem(Images.saloon_template3_portfolio2,Images.saloon_template3_portfolio2)}
  //             {this.renderPortfolioItem(Images.saloon_template3_portfolio3,Images.saloon_template3_portfolio3)}
  //             {this.renderPortfolioItem(Images.saloon_template3_portfolio4,Images.saloon_template3_portfolio4)}
  //             {this.renderPortfolioItem(Images.saloon_template3_portfolio5,Images.saloon_template3_portfolio5)}
  //             {this.renderPortfolioItem(Images.saloon_template3_portfolio6,Images.saloon_template3_portfolio6)}
  //             {this.renderPortfolioItem(Images.saloon_template3_portfolio7,Images.saloon_template3_portfolio7)}
  //             {this.renderPortfolioItem(Images.saloon_template3_portfolio1,Images.saloon_template3_portfolio1)}
  //             {this.renderPortfolioItem(Images.saloon_template3_portfolio9,Images.saloon_template3_portfolio9)}
  //         </div>
  //         </SRLWrapper>

  //     </div>
  // </div>
  //     )
  // }

  // Example = () => {
  //     return (
  //       <>
  //         <button variant="primary" onClick={()=>this.handleOpen()}>
  //           Launch demo modal
  //         </button>

  //         <Modal show={this.state.show} onHide={()=> this.handleClose()}>
  //           <Modal.Header closeButton>
  //             <Modal.Title>Modal heading</Modal.Title>
  //           </Modal.Header>
  //           <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
  //           <Modal.Footer>
  //             <button variant="secondary" onClick={()=>this.handleClose()}>
  //               Close
  //             </button>
  //             <button variant="primary" onClick={()=>this.handleClose()}>
  //               Save Changes
  //             </button>
  //           </Modal.Footer>
  //         </Modal>
  //       </>
  //     );
  //   }

  renderPortfolioVideo = () => {
    const { portfolioVideos, sinceText, isEditTemplate } = this.state;
    return (
      <div class="photo-gallery" style={{ paddingBottom: "80px" }}>
        <div class="container">
          <div class="intro">
            <h2
              style={{ fontSize: "30px", fontWeight: "bold", paddingTop: 50 }}
              class="text-center mb-0"
            >
              OUR VIDEO
            </h2>
            <div class="text-center" style={{ marginBottom: 40 }}>
              <img src={Images.saloon_template2_headline} />
            </div>
          </div>
          <div class="row">
            {portfolioVideos &&
              portfolioVideos.map((val) =>
                this.renderPortfolioItemVideo(val.url)
              )}
          </div>
        </div>
      </div>
    );
  };

  renderFooter = () => {
    const { companyList } = this.state;


    return (
      <div
        class="container-fluid"
        style={{
          backgroundColor: "#222227",
          width: "100%",
          marginTop: 80,
          paddingBottom: 80,
        }}
      >
        {companyList.map((saloonsVal) => {

          return (
            <div class="row">
              <div class="col-lg-4" style={{ paddingTop: 50, paddingLeft: 50 }}>
                <div class="container">
                  <img
                    style={{ marginBottom: 15, height: 80 }}
                    src={saloonsVal?.saloons[0]?.companyLogo}
                  />
                  <div>
                    <p style={{ color: "#818D86", marginTop: 10 }}>
                      {saloonsVal?.saloons[0]?.companyShortDescription}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4" style={{ paddingTop: 50, paddingLeft: 50 }}>
                <div class="container">
                  <p
                    style={{
                      color: "#FFFFFF",
                      fontWeight: "bold",
                      fontSize: "18px",
                    }}
                  >
                    Headquaters
                  </p>
                  <div>
                    <p style={{ color: "#818D86" }}>
                      {saloonsVal?.saloons[0]?.address}
                    </p>
                  </div>
                  <div>
                    <p style={{ color: "#818D86" }}>
                      {saloonsVal?.saloons[0]?.businessEmail}
                    </p>
                    <p style={{ color: "#818D86" }}>
                      {saloonsVal?.saloons[0]?.businessTelephone}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4" style={{ paddingTop: 50, paddingLeft: 50 }}>
                <div class="container">
                  <p
                    style={{
                      color: "#FFFFFF",
                      fontWeight: "bold",
                      fontSize: "18px",
                    }}
                  >
                    Opening Hours
                  </p>
                  {saloonsVal &&
                    saloonsVal.schedule &&
                    saloonsVal.schedule.map(
                      (val) =>
                        val &&
                        val.weekPlans &&
                        val.weekPlans.map((weekData) => {
                          return (
                            <div>
                              {weekData.dayOfWeek === 1 ? (
                                <p style={{ color: "#818D86" }}>
                                  Monday
                                  {weekData.availableStatus === 1 ? (
                                    <span>
                                      <span style={{ paddingLeft: 20 }}>
                                        {weekData.checkIn}
                                      </span>
                                      -<span>{weekData.checkOut}</span>
                                    </span>
                                  ) : (
                                      <span
                                        style={{ paddingLeft: 10, color: "red" }}
                                      >
                                        Off
                                      </span>
                                    )}
                                </p>
                              ) : null}
                              {weekData.dayOfWeek === 2 ? (
                                <p style={{ color: "#818D86" }}>
                                  Tuesday
                                  {weekData.availableStatus === 1 ? (
                                    <span>
                                      <span style={{ paddingLeft: 20 }}>
                                        {weekData.checkIn}
                                      </span>
                                      -<span>{weekData.checkOut}</span>
                                    </span>
                                  ) : (
                                      <span
                                        style={{ paddingLeft: 10, color: "red" }}
                                      >
                                        Off
                                      </span>
                                    )}
                                </p>
                              ) : null}
                              {weekData.dayOfWeek === 3 ? (
                                <p style={{ color: "#818D86" }}>
                                  Wednesday
                                  {weekData.availableStatus === 1 ? (
                                    <span>
                                      <span style={{ paddingLeft: 20 }}>
                                        {weekData.checkIn}
                                      </span>
                                      -<span>{weekData.checkOut}</span>
                                    </span>
                                  ) : (
                                      <span
                                        style={{ paddingLeft: 10, color: "red" }}
                                      >
                                        Off
                                      </span>
                                    )}
                                </p>
                              ) : null}
                              {weekData.dayOfWeek === 4 ? (
                                <p style={{ color: "#818D86" }}>
                                  Thursday
                                  {weekData.availableStatus === 1 ? (
                                    <span>
                                      <span style={{ paddingLeft: 20 }}>
                                        {weekData.checkIn}
                                      </span>
                                      -<span>{weekData.checkOut}</span>
                                    </span>
                                  ) : (
                                      <span
                                        style={{ paddingLeft: 10, color: "red" }}
                                      >
                                        Off
                                      </span>
                                    )}
                                </p>
                              ) : null}
                              {weekData.dayOfWeek === 5 ? (
                                <p style={{ color: "#818D86" }}>
                                  Friday
                                  {weekData.availableStatus === 1 ? (
                                    <span>
                                      <span style={{ paddingLeft: 20 }}>
                                        {weekData.checkIn}
                                      </span>
                                      -<span>{weekData.checkOut}</span>
                                    </span>
                                  ) : (
                                      <span
                                        style={{ paddingLeft: 10, color: "red" }}
                                      >
                                        Off
                                      </span>
                                    )}
                                </p>
                              ) : null}
                              {weekData.dayOfWeek === 6 ? (
                                <p style={{ color: "#818D86" }}>
                                  Saturday
                                  {weekData.availableStatus === 1 ? (
                                    <span>
                                      <span style={{ paddingLeft: 20 }}>
                                        {weekData.checkIn}
                                      </span>
                                      -<span>{weekData.checkOut}</span>
                                    </span>
                                  ) : (
                                      <span
                                        style={{ paddingLeft: 10, color: "red" }}
                                      >
                                        Off
                                      </span>
                                    )}
                                </p>
                              ) : null}
                              {weekData.dayOfWeek === 7 ? (
                                <p style={{ color: "#818D86" }}>
                                  Sunday
                                  {weekData.availableStatus === 1 ? (
                                    <span>
                                      <span style={{ paddingLeft: 20 }}>
                                        {weekData.checkIn}
                                      </span>
                                      -<span>{weekData.checkOut}</span>
                                    </span>
                                  ) : (
                                      <span
                                        style={{ paddingLeft: 10, color: "red" }}
                                      >
                                        Off
                                      </span>
                                    )}
                                </p>
                              ) : null}
                            </div>
                          );
                        })
                    )}
                </div>
              </div>
              {/* <div class="col-lg-3" style={{ paddingTop: 50, paddingLeft: 50 }}>
                  <div class="container">
                    <p
                      style={{
                        color: "#FFFFFF",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      Subscribe to our contents Hours
                      </p>
                    <div id="footerEmail">
                      <input
                        type="Text"
                        placeholder="Email Address"
                        class="form-group"
                        style={{ backgroundColor: "#7A7A7D", padding: "0.5rem" }}
                      />
                    </div>
                    <div>
                      <button class="form-group" id="footerBtn-text">
                        Subscribe
                        </button>
                    </div>
                  </div>
                </div> */}
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    const { isEditTemplate, isloading } = this.state
    return (
      <div>
        { isEditTemplate && this.renderEditTemplateHeader()}
        {isloading && this.renderLoader()}
        {this.renderLargeNavbar()}
        {this.renderSmallNavbar()}
        {!isloading && this.renderJumbotron()}
        { !isloading && this.renderAboutUs()}
        { !isloading && this.renderServices()}
        {!isloading && this.renderAppointment()}
        {!isloading && this.renderEmployees()}
        {!isloading && this.renderPricing()}
        {!isloading && this.renderContact()}
        <SimpleReactLightbox>{!isloading && this.renderPortfolio()}</SimpleReactLightbox>
        {!isloading && this.renderPortfolioVideo()}
        {this.renderFooter()}
      </div>
    );
  }
}

export default Template2;