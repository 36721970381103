// @flow
import Immutable from "seamless-immutable";
import * as types from "../../actions/ActionTypes";

const initialState = Immutable({
  isFetching: false,
  errorMessage: "",
  data: [],
  active: {},
});

export default (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case types.STUDENT.GET_SAGA:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case types.STUDENT.GET:
      return Immutable.merge(state, {
        isFetching: false,
        data: action.data,
      });
    case types.STUDENT.UPSERT_SAGA:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case types.STUDENT.UPSERT: {
      const newState = { ...state, isFetching: false, data: [...state.data] };
      const { _id } = action.data;
      const index = state.data.findIndex((element) => element._id == _id);
      if (index != -1) {
        newState.data[index] = action.data;
      } else {
        newState.data.push(action.data);
      }
      return newState;
    }
    case types.STUDENT.DELETE: {
      const newState = { ...state, isFetching: false, data: [...state.data] };
      const { _id } = action.data;
      const index = state.data.findIndex((element) => element._id == _id);
      if (index != -1) {
        newState.data.splice(index, 1);
      }
      return newState;
    }
    case types.STUDENT.DELETE_SAGA:
    case types.STUDENT.SET_GRADE_SAGA:
    case types.STUDENT.SET_FINALIZED_SAGA:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case types.STUDENT.REQUEST_FAILURE:
      return Immutable.merge(state, {
        isFetching: false,
      });
    case types.STUDENT.SET_GRADE:
    case types.STUDENT.SET_FINALIZED: {
      try {
        const { data } = action;
        const { studentId } = data;
        const newState = { ...state, isFetching: false, data: [...state.data] };
        const index = state.data.findIndex(
          (element) => element._id == studentId
        );
        if (index != -1) {
          const student = {
            ...newState.data[index],
            studentClasses: [...newState.data[index].studentClasses],
          };
          console.log(student, data);
          const studentClassIndex = student.studentClasses.findIndex(
            (element) => element._id == data._id
          );

          if (studentClassIndex != -1) {
            student.studentClasses[studentClassIndex] = data;
          }
          newState.data[index] = student;
        }
        console.log("newwwww ", newState);
        return newState;
      } catch (e) {
        console.log(e);
      }
    }
    case types.PROGRAM_ENROLLMENT.DELETE: {
      const data = [...state.data];
      const { _id, studentId } = action.data;
      const index = data.findIndex((element) => element._id == studentId);
      if (index != -1) {
        if (data[index].enrollments) {
          const nestedIndex = data[index].enrollments?.findIndex(
            (enrollment) => enrollment._id == _id
          );

          if (nestedIndex != -1) {
            const enrollments = [...data[index].enrollments];
            enrollments.splice(nestedIndex, 1);
            data[index] = { ...data[index], enrollments };
          }
        }
      }
      return Immutable.merge(state, { data });
    }

    case types.LOGOUT:
      return initialState;
    case types.CLEAR_LOGOUT:
      return initialState;
    default:
      return state;
  }
};
