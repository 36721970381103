import { connect } from "react-redux";
import React, { useState } from "react";
import i18n from "../../i18n";
import DatePicker from "react-datepicker";
import moment from "moment";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {
  getRequest,
  removeRequest,
  insertRequest,
} from "../../redux/actions/ReoccuringUnavailabilties";
import Select from "../Core/Select";
import "./styles.scss";
import { TextField } from "@material-ui/core";
import PrimaryButton from "../Core/PrimaryButton";
import { ErrorHelper, SuccessHelper } from "../../helpers";
function ReoccuringUnavailabilities({ accessToken, onClose, insertRequest }) {
  const [startDate, setStartDate] = useState(moment(moment.now()).toDate());
  const [endDate, setEndDate] = useState(
    moment(moment.now()).add(1, "w").toDate()
  );
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [frequency, setFrequency] = useState(1);
  const [formErrors, setFormErrors] = useState({});
  const onStartDateChange = (val) => {
    if (val > endDate) {
      setStartDate(endDate);
      setEndDate(val);
    } else {
      setStartDate(val);
    }
  };
  const onEndDateChange = (val) => {
    if (val < startDate) {
      setStartDate(val);
      setEndDate(startDate);
    } else {
      setEndDate(val);
    }
  };

  const onStartTimeChange = (val) => {
    const start = moment(val, "HH:mm");
    const remainder = start.minute() % 30;
    start.add(remainder <= 15 ? -1 * remainder : 30 - remainder, "m");
    setStartTime(start.format("HH:mm"));
  };
  const onStartTimeBlur = (val) => {
    if (val > endTime) {
      setStartTime(endTime);
      setEndTime(val);
    } else {
      setStartTime(val);
    }
  };
  const onEndTimeChange = (val) => {
    const end = moment(val, "HH:mm");
    const remainder = end.minute() % 30;
    end.add(remainder <= 15 ? -1 * remainder : 30 - remainder, "m");
    setEndTime(end.format("HH:mm"));
  };
  const onEndTimeBlur = (val) => {
    if (val < startTime) {
      setStartTime(val);
      setEndTime(startTime);
    } else {
      setEndTime(val);
    }
  };

  const onSubmit = () => {
    let hasErrors = false;
    let newFormErrors = {};
    if (!startDate) {
      newFormErrors.startDate = i18n.t("required");
      hasErrors = true;
    }
    if (!endDate) {
      newFormErrors.endDate = i18n.t("required");
      hasErrors = true;
    }
    if (!startTime) {
      newFormErrors.startTime = i18n.t("required");
      hasErrors = true;
    }
    if (!endTime) {
      newFormErrors.endTime = i18n.t("required");
      hasErrors = true;
    }
    if (hasErrors) {
      setFormErrors(newFormErrors);
      setTimeout(() => {
        setFormErrors({});
      }, 6000);
    } else {
      insertRequest(
        {
          access_token: accessToken,
          startDate,
          endDate,
          startTime,
          endTime,
          frequency,
        },
        {
          success: () => {
            SuccessHelper.handleSuccess(i18n.t("successfully_added"), true);
            onClose();
          },
          failure: () => {
            ErrorHelper.handleErrors(i18n.t("failed_to_add"), true);
          },
        }
      );
    }
  };
  return (
    <div className="reoccuring-unavailabilities text-left">
      <div className="employee-report-filters row mx-0 px-0">
        <div className="col-12">{i18n.t("date_range")}</div>
        <div className="col-6">
          <DatePicker
            placeholderText="Select Start Date"
            selected={startDate}
            onChange={onStartDateChange}
            dateFormat="MM/dd/yyyy"
            customInput={
              <TextField
                value={startDate}
                className="full-width"
                readOnly={true}
                error={!!formErrors.startDate}
                helperText={formErrors.startDate}
              />
            }
          />
        </div>
        <div className="col-6">
          <DatePicker
            placeholderText="Select End Date"
            selected={endDate}
            onChange={onEndDateChange}
            dateFormat="MM/dd/yyyy"
            customInput={
              <TextField
                value={endDate}
                className="full-width"
                readOnly={true}
                error={!!formErrors.endDate}
                helperText={formErrors.endDate}
              />
            }
          />
        </div>
      </div>
      <div className="employee-report-filters mt-2 row mx-0 px-0">
        <div className="col-12">{i18n.t("time_range")}</div>
        <div className="col-6">
          <TextField
            type="time"
            value={startTime}
            onChange={(e) => onStartTimeChange(e.target.value)}
            onBlur={(e) => onStartTimeBlur(e.target.value)}
            step="1800"
            error={!!formErrors.startTime}
            helperText={formErrors.startTime}
          />
        </div>
        <div className="col-6">
          <TextField
            type="time"
            value={endTime}
            onChange={(e) => onEndTimeChange(e.target.value)}
            onBlur={(e) => onEndTimeBlur(e.target.value)}
            step="1800"
            error={!!formErrors.endTime}
            helperText={formErrors.endTime}
          />
        </div>
      </div>
      <div className="employee-report-filters mt-2 row mx-0 px-0">
        <div className="col-12">{i18n.t("frequency")}</div>
        <div className="col-12">
          <Select
            value={frequency}
            defaultValue={frequency}
            onChange={(e) => {
              setFrequency(e.target.value);
            }}
            options={[
              { name: i18n.t("every_week"), value: 1 },
              { name: i18n.t("every_x_weeks", { weeks: 2 }), value: 2 },
              { name: i18n.t("every_x_weeks", { weeks: 3 }), value: 3 },
              { name: i18n.t("every_x_weeks", { weeks: 4 }), value: 4 },
            ]}
          />
        </div>
      </div>
      <div className="row mx-0 justify-content-end mt-2 col-12">
        <PrimaryButton label={i18n.t("submit")} onClick={onSubmit} />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({});

const action = { getRequest, removeRequest, insertRequest };

export default connect(mapStateToProps, action)(ReoccuringUnavailabilities);
