import React, { useEffect, useState } from "react";
import { ErrorHelper } from "../../helpers";
import i18n from "../../i18n";
import { getBookingById } from "../../config/simpleApiCalls";
import Swal from "sweetalert2";

export default (props) => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    const urlParams = new URLSearchParams(window.location.search);
    const paramId = urlParams.get("id");
    const {
      match: {
        params: { id: urlId },
      },
    } = props;
    const id = urlId ? urlId : paramId;
    getBookingById({ id })
      .then(({ data }) => {
        const booking = data.data;
        let name = " User";
        if (booking.userId) {
          if (booking.userId.firstName || booking.userId.lastName) {
            name =
              `${
                booking.userId.firstName ? " " + booking.userId.firstName : " "
              }` + ` ${booking.userId.lastName ? booking.userId.lastName : ""}`;
          } else if (booking.userId.userName) {
            name = " " + booking.userId.userName;
          }
        }
        setLoading(false);
        Swal.fire({
          title: i18n.t("hello") + name,
          html:
            `Téléchargez la facture de votre rendez-vous: ${booking.companyId.name} ` +
            `avec ${booking.services[0].employeeId.userId.firstName} à ${booking.services[0].date} ${booking.services[0].time}` +
            `<br/><br/>Download the invoice for your appointment: ${booking.companyId.name} ` +
            `with ${booking.services[0].employeeId.userId.firstName} at ${booking.services[0].date} ${booking.services[0].time}`,
          confirmButtonColor: "#ff3600",
          cancelButtonColor: "#ff3600",
          confirmButtonText: "Téléchargez",
          cancelButtonText: "Download",
          showCancelButton: true,
          allowOutsideClick: true,
        }).then((e) => {
          if (e.dismiss != "dismiss") {
            window.location.replace(
              `https://pdf-service-q3s5v4pl4q-uc.a.run.app/${
                e.value ? "fr" : "en"
              }/invoice/${booking.shortenedId || booking._id}`
            );
          }
        });
      })
      .catch(() => {
        ErrorHelper.handleErrors("Something went wrong", true);
      });
    props.history.push("/");
  }, []);
  return null;
};
