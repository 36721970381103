import React, { useEffect, useState } from "react";
import { ErrorHelper } from "../../helpers";
import i18n from "../../i18n";
import { getCompanyEmployeeById } from "../../config/simpleApiCalls";
import Swal from "sweetalert2";

export default (props) => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    const {
      match: {
        params: { companyEmployeeId: id },
      },
    } = props;
    getCompanyEmployeeById({ id })
      .then(({ data }) => {
        const employee = data.data;
        setLoading(false);

        Swal.fire({
          html:
            `Bonjour, ${
              employee.employeeId.userId.firstName
                ? employee.employeeId.userId.firstName
                : ""
            }${
              employee.employeeId.userId.lastName
                ? " " + employee.employeeId.userId.lastName
                : ""
            } de ${employee.companyId.name} tient à vous informer que nous ` +
            `avons maintenant un système de réservation en ligne via Easy1!` +
            `<br/><br/>` +
            `Hello, ${
              employee.employeeId.userId.firstName
                ? employee.employeeId.userId.firstName
                : ""
            }${
              employee.employeeId.userId.lastName
                ? " " + employee.employeeId.userId.lastName
                : ""
            } from ${employee.companyId.name} would like ` +
            `to inform you that we are now have online booking through Easy1!<br/>`,
          confirmButtonColor: "#ff3600",
          confirmButtonText: i18n.t("book_anytime"),
          showConfirmButton: true,
          allowOutsideClick: true,
        }).then((result) => {
          if (result.value) {
            window.open(
              `https://book.easy1booking.com/${employee.companyId._id}`,
              "_blank"
            );
          }
        });
      })
      .catch((e) => {
        console.log(e);
        ErrorHelper.handleErrors("Something went wrong", true);
      });
    props.history.push("/");
  }, []);
  return null;
};
