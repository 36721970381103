import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import i18n from "../../i18n";
import BaseModal from "../BaseModal";
import { request } from "../../redux/actions/Announcements";
import "./style.scss";
import { getNestedValue } from "../../util/objectMethods";

const Annoucements = ({ request, announcements, user, type }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  useEffect(() => {
    request();
  }, []);
  const language = getNestedValue(i18n, "language", "-en").split("-")[0];
  const title = getNestedValue(announcements, [0, "header", language], "");
  let content = "";
  if (user.saloon) {
    content = getNestedValue(announcements, [0, "clientMessage", language], "");
    if (!content) {
      content = getNestedValue(
        announcements,
        [0, "employeeMessage", language],
        ""
      );
    }
  } else if (user.employee) {
    content = getNestedValue(
      announcements,
      [0, "employeeMessage", language],
      ""
    );
  }
  return (
    <>
      {announcements && announcements.length ? (
        <>
          <div className="announcements row mx-0 justify-content-between">
            <div className="announcements-title">
              <div
                onClick={() => setModalOpen(true)}
                dangerouslySetInnerHTML={{ __html: title }}
              />
            </div>
          </div>
          <BaseModal
            style={{ zIndex: 1249 }}
            open={isModalOpen}
            {...{
              title: <div dangerouslySetInnerHTML={{ __html: title }} />,
              content: (
                <>
                  {content ? (
                    <div
                      className="h-100"
                      style={{ color: "black" }}
                      dangerouslySetInnerHTML={{ __html: content }}
                    />
                  ) : (
                    <div />
                  )}
                </>
              ),
            }}
            onClose={() => setModalOpen(false)}
          />
        </>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => ({
  announcements: state.announcements.data,
  user: state.user.data,
});

const action = { request };

export default connect(mapStateToProps, action)(Annoucements);
