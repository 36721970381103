import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import i18n from "../../../../i18n";
import BaseModal from "../../../BaseModal";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import DollarInput from "../../../DollarInput";
import PercentInput from "../../../PercentInput";
import { Button } from "@material-ui/core";
import TransactionDetails from "../..";
import { SuccessHelper } from "../../../../helpers";
import "./styles.scss";
const roundTo2Decimals = (x) => Math.round((x + Number.EPSILON) * 100) / 100;
const InsertPayment = ({
  open,
  onSubmit,
  handleClose,
  defaultAmount,
  amountReadonly,
  transactionDetailsProps,
  type,
  transactions,
  onChangeTip,
  tip,
  total,
  accountBalance,
}) => {
  const [localTip, setLocalTip] = useState(tip);
  useEffect(() => {
    setLocalTip(tip);
  }, [tip]);
  const [amount, setAmount] = useState(0);
  const [creditDebit, setCreditDebit] = useState(0);
  const [balanceDue, setBalanceDue] = useState(0);
  const [discountPercent, setDiscountPercent] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [accountBalanceToCollect, setAccountBalanceToCollect] = useState(0);

  const [cash, setCash] = useState(0);
  useEffect(() => {
    setAmount(defaultAmount);
  }, []);
  const [note, setNote] = useState("");
  const [manualPaymentMap, setManualPaymentMap] = useState({});
  const [formErrors, setFormErrors] = useState("");
  const difference = roundTo2Decimals(
    balanceDue - parseFloat(cash) - parseFloat(creditDebit) - discountAmount
  );
  const validate = () => {
    let hasErrors = false;
    let newFormErrors = {};
    if (!amount && type == "manual") {
      newFormErrors.amount = i18n.t("amount_is_required");
      hasErrors = true;
    }
    if (type === "reverse" && !note) {
      newFormErrors.note = i18n.t("note_is_required");
      hasErrors = true;
    }
    if (difference > 0) {
      newFormErrors.money = i18n.t("must_be_greater_than", {
        amount: balanceDue,
      });
      hasErrors = true;
    }
    if (hasErrors) {
      setFormErrors(newFormErrors);
      setTimeout(() => {
        setFormErrors({});
      }, 6000);
    } else {
      onSubmit(
        {
          amount,
          note,
          cash: parseFloat(cash),
          creditDebit: parseFloat(creditDebit),
          discount: discountAmount,
          accountBalanceToCollect,
        },
        manualPaymentMap,
        () => {
          if (difference < 0) {
            SuccessHelper.handleSuccess(
              i18n.t("change_due_prompt", { amount: difference }),
              true
            );
          } else {
            SuccessHelper.handleSuccess("", true);
          }
        }
      );
    }
  };
  const hasPaidForTip = Boolean(
    transactions.find(
      (transaction) =>
        transaction.metadata?.tip &&
        !transaction.wasReversed &&
        transaction.type != "Reverse"
    )
  );
  const colClass = type === "manual" ? "col-md-6 col-xs-12" : "col-12";
  return (
    <BaseModal
      style={{ zIndex: 1249 }}
      open={open}
      containerClassName={type === "manual" ? "container" : ""}
      {...{
        title: (
          <div>
            {type === "reverse"
              ? `${i18n.t("reversal_amount")} ($ ${amount.toFixed(2)})`
              : `${i18n.t("amount_to_be_paid")} ($ ${balanceDue.toFixed(2)})`}
          </div>
        ),
        content: (
          <div className="transactions-details-insert-payment">
            <div className="row mx-0">
              <div className={colClass}>
                <div className="row mx-0">
                  {accountBalance ? (
                    <>
                      <div className="mb-2 col-sm-6 col-xs-12 px-1">
                        <DollarInput
                          value={accountBalance}
                          label={i18n.t("account_balance")}
                          readOnly={true}
                        />
                      </div>
                      <div className="mb-2 col-sm-6 col-xs-12 px-1">
                        <DollarInput
                          value={accountBalanceToCollect}
                          onChange={(e) =>
                            setAccountBalanceToCollect(parseFloat(e))
                          }
                          label={i18n.t("to_collect")}
                          max={accountBalance}
                        />
                      </div>
                    </>
                  ) : null}
                  {type === "manual" && (
                    <>
                      <div className="mb-2 col-sm-6 col-xs-12 px-1">
                        <DollarInput
                          value={cash}
                          onChange={setCash}
                          label={i18n.t("cash")}
                          readOnly={amountReadonly}
                          error={formErrors.money}
                        />
                      </div>
                      <div className="mb-2 col-sm-6 col-xs-12 px-1">
                        <DollarInput
                          value={creditDebit}
                          onChange={setCreditDebit}
                          label={i18n.t("credit_debit")}
                          readOnly={amountReadonly}
                          error={formErrors.money}
                        />
                      </div>
                      <div className="mb-2 col-sm-6 col-xs-12 px-1">
                        <DollarInput
                          value={discountAmount}
                          onChange={(value) => {
                            setDiscountAmount(value);
                            setDiscountPercent(
                              roundTo2Decimals((100 * value) / balanceDue)
                            );
                          }}
                          label={i18n.t("discount_amount")}
                          readOnly={amountReadonly}
                          error={formErrors.discount}
                        />
                      </div>
                      <div className="mb-2 col-sm-6 col-xs-12 px-1">
                        <PercentInput
                          value={discountPercent}
                          onChange={(percent) => {
                            setDiscountPercent(percent);
                            setDiscountAmount(
                              roundTo2Decimals((balanceDue * percent) / 100)
                            );
                          }}
                          label={i18n.t("discount_percent")}
                          readOnly={amountReadonly}
                          error={formErrors.discount}
                        />
                      </div>
                      <div className="col-12 mb-2 px-1">
                        <TextField
                          id="standard-error-helper-text"
                          label={i18n.t("tip")}
                          onChange={(e) => setLocalTip(e.target.value)}
                          onBlur={(e) => {
                            const newTip = parseFloat(e.target.value);
                            onChangeTip(
                              newTip && newTip > 0
                                ? Math.round((newTip + Number.EPSILON) * 100) /
                                    100
                                : 0
                            );
                          }}
                          value={`${localTip}`}
                          inputProps={{ readOnly: hasPaidForTip }}
                        />
                        {!hasPaidForTip ? (
                          <div className="col-12 pb-1 row mx-0 justify-content-between">
                            <Button
                              style={{
                                backgroundColor: "#ff3600",
                                color: "white",
                              }}
                              onClick={() =>
                                onChangeTip(((15.0 / 100) * total).toFixed(2))
                              }
                            >
                              15 %
                            </Button>
                            <Button
                              style={{
                                backgroundColor: "#ff3600",
                                color: "white",
                              }}
                              onClick={() =>
                                onChangeTip(((20.0 / 100) * total).toFixed(2))
                              }
                            >
                              20 %
                            </Button>
                            <Button
                              style={{
                                backgroundColor: "#ff3600",
                                color: "white",
                              }}
                              onClick={() =>
                                onChangeTip(((35.0 / 100) * total).toFixed(2))
                              }
                            >
                              35 %
                            </Button>
                          </div>
                        ) : null}
                      </div>
                    </>
                  )}
                  {type === "manual" && (
                    <>
                      <div className="mb-2 col-sm-6 col-xs-12 px-1">
                        <DollarInput
                          value={roundTo2Decimals(
                            parseFloat(cash) + parseFloat(creditDebit)
                          )}
                          label={i18n.t("total")}
                          readOnly={true}
                        />
                      </div>
                      <div className="mb-2 col-sm-6 col-xs-12 px-1 balance-due">
                        <DollarInput
                          value={difference}
                          label={
                            difference < 0
                              ? i18n.t("change_due")
                              : i18n.t("balance_due")
                          }
                          readOnly={true}
                        />
                      </div>
                    </>
                  )}
                  <div className="mb-2 col-12 px-1">
                    <TextField
                      label={i18n.t("note")}
                      onChange={(e) => setNote(e.target.value)}
                      helperText={formErrors.note}
                      error={Boolean(formErrors.note)}
                    />
                  </div>
                </div>
              </div>
              {type === "manual" && (
                <div className={colClass}>
                  <TransactionDetails
                    {...transactionDetailsProps}
                    paymentAmount={roundTo2Decimals(
                      parseFloat(cash) + parseFloat(creditDebit)
                    )}
                    manualPayment={type === "manual"}
                    accountBalanceToCollect={accountBalanceToCollect}
                    onBalanceDueChange={(balanceDue) => {
                      setBalanceDue(
                        parseFloat(balanceDue) ? parseFloat(balanceDue) : 0
                      );
                    }}
                    manualPaymentMapChange={setManualPaymentMap}
                  />
                </div>
              )}
            </div>
          </div>
        ),
        actions: (
          <div className="row mx-0 justify-content-end">
            <Button
              className="primary-button ml-1"
              onClick={() => {
                validate();
              }}
            >
              {i18n.t("apply")}
            </Button>
          </div>
        ),
      }}
      onClose={handleClose}
    />
  );
};

const mapStateToProps = (state) => ({
  transactions: state.bookingTransaction.data,
});

const action = {};

export default connect(mapStateToProps, action)(InsertPayment);
