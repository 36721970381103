import { connect } from "react-redux";
import React, { Component, useCallback, useState } from "react";
import { Translation } from "react-i18next";
import i18n from "../../../i18n";
import { Images } from "../../../theme";
import {
  DotsLoader,
  AdvancedSearchDialog,
  BookingNotes,
  BaseModal,
  AppointmentModal,
  CompletedForm,
  CreateAppointment,
} from "../../../components";
import { request as SaloonAppointment } from "../../../redux/actions/Saloon/SaloonAppointment";
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import DatePicker from "react-datepicker";
import { request as get_available_timeslots } from "../../../redux/actions/GetAvailableTimeslots.js";
import { request as get_gym_timeslots } from "../../../redux/actions/GetGymTimeslots.js";
import { insertRequest as createTransactions } from "../../../redux/actions/BookingTransaction";
import { clear } from "../../../redux/actions/ActiveBooking";
import {
  editBooking,
  getSaloonCategories,
  getSaloonById,
  getEmployeesBySaloonAndService,
  getSaloonServices,
  gitWaitingAppoinments,
  addCustomBooking,
  saloonGetAllService,
  getCompanyCustomers,
  getAllEmployeeAssociatedWithCompany,
  getSaloonSchedule,
  getCompanyTaxes,
  createWaitingList,
  addProduct,
  addExtra,
  removeProduct,
  updateCartProduct,
  removeExtra,
  getProduct,
  dismissCancelledBooking,
  getCompanyEmployeeUnavaiabilities,
} from "../../../config/simpleApiCalls";
import { getNestedValue } from "../../../util/objectMethods";
import Swal from "sweetalert2";
import moment, { invalid } from "moment";
import "./styles.scss";
import Sytles from "./styles";
import CalendarSidebar from "../../../components/CalendarView/components/Sidebar";
import CalendarSidebarContent from "../../../components/CalendarView/components/SidebarContent";
import PlanView from "../../../components/CalendarView/components/PlanView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddExtra from "../../../components/Extras/AddExtra";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";

import Button from "@material-ui/core/Button";
import { computeBookingTotals } from "../../../util/bookingMethods";
import InsertPayment from "../../../components/TransactionDetails/components/InsertPayment";
import AddExtrasModal from "../../../components/Extras/AddExtrasModal";
import RefreshIcon from "@material-ui/icons/Refresh";

moment.locale(i18n.language);

const statusMap = {
  2: "container.services_now_serving_status",
  3: "cancelled",
  4: "container.services_done_status",
  7: "cancelled_no_show",
};
class SalonAppointment extends Component {
  constructor(props) {
    i18n.changeLanguage(sessionStorage.getItem("language"));

    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      postalCode: "",
      email: "",
      phoneNumber: "",
      totalAmount: 0,
      selectedTime: "",
      selectPaymentMethod: undefined,
      selectedCategory: null,
      selectSalon: "",
      selectServices: "",
      selectedServiceId: "",
      companyId: null,
      selectEmployee: "",
      categoryList: [],
      getSalonServices: [],
      selectDateAndTime: undefined,
      daysMock: [
        { name: "---" },
        { name: "Sun" },
        { name: "Mon" },
        { name: "Tue" },
        { name: "Wed" },
        { name: "Thu" },
        { name: "Fri" },
        { name: "Sat" },
      ],
      weekPlans: [],
      formErrors: {
        firstNameError: "",
        lastNameError: "",
        postalCodeError: "",
        phoneNumberError: "",
        emailError: "",
        totalAmountError: "",
        selectPaymentMethodError: "",
        selectServicesError: "",
        selectEmployeeError: "",
        selectDateAndTimeError: "",
        selectedTimeError: "",
      },

      bookingStatuses: [
        {
          text: i18n.t("container.services_waiting_status"),
          value: 1,
          styles: { color: "#aa43b5" },
        },
        {
          text: i18n.t("container.services_now_serving_status"),
          value: 3,
          styles: { color: "#FFE478" },
        },
        {
          text: i18n.t("container.services_cancelled_status"),
          value: 2,
          styles: { color: "#FF7082" },
        },
        {
          text: i18n.t("container.services_done_status"),
          value: 4,
          styles: { color: "#95DA47" },
        },
      ],

      access_token: "",
      bookings: null,
      employeesWorkingHoursData: [
        { dayName: "Sunday", dayOfWeek: 1, availableStatus: 0 },
        { dayName: "Monday", dayOfWeek: 2, availableStatus: 0 },
        { dayName: "Tuesday", dayOfWeek: 3, availableStatus: 0 },
        { dayName: "Wednesday", dayOfWeek: 4, availableStatus: 0 },
        { dayName: "Thursday", dayOfWeek: 5, availableStatus: 0 },
        { dayName: "Friday", dayOfWeek: 6, availableStatus: 0 },
        { dayName: "Saturday", dayOfWeek: 7, availableStatus: 0 },
      ],
      showPopUp: false,

      serviceList: [
        { name: "Hair Cutting", isSelected: false },
        { name: "Hair Cutting", isSelected: true },
        { name: "Hair Cutting", isSelected: false },
        { name: "Hair Cutting", isSelected: false },
      ],
      isVerified: false,
      employeeServicesList: [],
      employeesWorkingHoursError: false,
      hasSchedule: false,
      selectBookingAmount: "",
      tip: 0,
      filtersMap: {
        status: {
          1: true,
          2: true,
          3: true,
          4: true,
        },
      },
      displayCalendar: true,
      activeDate: null,
    };
  }

  // onChangeSelectPaymentMethod = (text) => this.setState({ selectPaymentMethod: text.target.value });
  onChangeSelectServices = (text) => {
    const { lockFields, isBlackListed } = this.state;
    const { price, depositAmount, requireDeposit, depositTarget } =
      JSON.parse(text);
    let amount = 0;
    if (
      requireDeposit &&
      depositAmount &&
      (depositTarget != "blacklisted" || isBlackListed)
    ) {
      amount = depositAmount;
    }
    this.setState({
      selectServices: text,
      isloading: true,
      selectEmployee: "",
      totalAmount: price,
      depositAmount: amount,
      selectedEmployee: "",
      ...(lockFields
        ? {}
        : {
            newDate: "",
            selectedTime: undefined,
            availableTimeslots: {},
            selectDateAndTime: undefined,
          }),
    });
    this.getEmployee(text);
  };
  onChangeSelectEmployee = (text) => {
    const { lockFields } = this.state;
    let employee = JSON.parse(text.target.value);
    if (employee) {
      let employeeId = employee.employeeId._id;
      this.setState({
        selectEmployee: employeeId,
        weekPlans: employee.weekPlans,
        selectedEmployee: text.target.value,
        ...(lockFields
          ? {}
          : {
              newDate: "",
              selectedTime: undefined,
              availableTimeslots: {},
              selectDateAndTime: undefined,
              newDate: "",
              selectedTime: undefined,
            }),
      });
    }
  };

  onChangeTime = (text) => {
    this.setState({
      selectedTime: text.target.value,
      newTime: text.target.value.split(" ")[0],
    });
  };

  onChangeTimeText = (text, key = "newTime") => {
    this.setState({
      selectedTime: text.target.value,
      [key]: text.target.value,
    });
  };

  onDateChange = async (selectDate) => {
    const {
      isGym,
      selectEmployee,
      selectedTime,
      enableDoubleBooking,
      enableWaitingList,
    } = this.state;

    this.setState({
      selectedTime: enableDoubleBooking ? selectedTime : undefined,
      availableTimeslots: {},
      datePickerOpen: true,
    });
    let date = undefined;
    if (selectDate) {
      date = moment(selectDate).format("MM-DD-YYYY");
      if (isGym) {
        const { companyData, selectServices } = this.state;
        await this.props.get_gym_timeslots({
          companyId: companyData._id,
          date,
        });
      } else {
        const { companyData, selectServices } = this.state;
        await this.props.get_available_timeslots({
          data: {
            companyId: companyData._id,
            companyServiceId: JSON.parse(selectServices)._id,
            date,
            companyEmployeeId: selectEmployee,
            showUnavailable: enableWaitingList,
          },
        });
      }
    }
    this.setState({ selectDateAndTime: selectDate, newDate: date });
  };

  async componentDidMount() {
    const companyData = await JSON.parse(sessionStorage.getItem("company"));
    const saloonData = await JSON.parse(sessionStorage.getItem("saloon"));
    const viewWorkingHoursUnavailabilities =
      localStorage.getItem("viewWorkingHoursUnavailabilities") == "true";
    const viewUnavailabilities =
      localStorage.getItem("viewUnavailabilities") == "true";
    this.setState({ viewWorkingHoursUnavailabilities, viewUnavailabilities });
    if (saloonData) {
      this.setState({
        company: companyData,
        access_token: saloonData.access_token,
      });
    }
    const { timeSlotLength, _id } = companyData;
    const isGym =
      JSON.parse(sessionStorage.getItem("company")).function == "gym";
    this.setState(
      { isGym, timeSlotLength, companyData, activeDate: Date.now() },
      () => {
        const { selectServices, filtersMap } = this.state;
        Promise.all([
          getAllEmployeeAssociatedWithCompany(saloonData.access_token)
            .then((res) => {
              if (res.data.success) {
                let employeeColorMap = {};
                let employeeMap = {};
                filtersMap.employee = {};
                const employees = res.data.Data.map((companyEmployee) => {
                  const { employee, user } = companyEmployee;
                  employeeColorMap[employee._id] =
                    companyEmployee.color || "#000000";
                  employeeMap[employee._id] = user.userName
                    ? user.userName
                    : `${getNestedValue(
                        user,
                        "firstName",
                        ""
                      )} ${getNestedValue(user, "lastName", "")}`;
                  filtersMap.employee[employee._id] = true;
                  return {
                    text: user.userName
                      ? user.userName
                      : `${getNestedValue(
                          user,
                          "firstName",
                          ""
                        )} ${getNestedValue(user, "lastName", "")}`,
                    value: employee._id,
                  };
                });
                this.setState({
                  isloading: false,
                  employees,
                  filtersMap,
                  employeeColorMap,
                  employeeMap,
                  rawEmployees: res.data.Data,
                });
              }
            })
            .catch((error) => {
              this.setState({ isloading: false });
            }),

          getSaloonSchedule(saloonData.access_token)
            .then((res) => {
              if (res.data.success && res.data.data) {
                let start = undefined;
                res.data.data.weekPlans.map((dayPlan) => {
                  const { checkIn } = dayPlan;
                  if (checkIn) {
                    let hour = parseInt(checkIn.split(":")[0]);
                    if (start) {
                      if (start > hour) {
                        start = hour;
                      }
                    } else {
                      start = hour;
                    }
                  }
                });
                this.setState({
                  start,
                });
              } else {
                this.setState({ isloading: false });
              }
            })
            .catch((error) => {
              console.log(error, "error");
            }),
        ]).then(() => {
          this.setState({ initialized: true });

          getCompanyCustomers(
            { companyId: saloonData.companyId },
            saloonData.access_token
          ).then((res) => {
            this.setState({ usersList: res });
          });
          getCompanyTaxes(
            { companyId: saloonData.companyId },
            saloonData.access_token
          )
            .then(({ data }) => {
              if (data.success) {
                this.setState({
                  companyTaxes: data.data.filter(({ isActive }) => isActive),
                });
              } else {
                this.setState({ companyTaxes: [] });
              }
            })
            .catch((error) => {
              console.log(error, "error");
            });

          getProduct({ companyId: _id }, saloonData.access_token)
            .then((res) => {
              if (res.data.success) {
                this.setState({ isloading: false, products: res.data.data });
              }
            })
            .catch((error) => {
              console.log(error, "get all services error");
            });
          this.getSalonCategories();
          saloonGetAllService(saloonData.access_token).then(({ data }) => {
            let allServices = [];
            if (data.success) {
              filtersMap.service = {};
              data.data.map((service) => {
                if (getNestedValue(service.serviceId, ["name"])) {
                  allServices.push(service);
                  filtersMap.service[getNestedValue(service, ["name"])] = true;
                }
              });
            }
            this.setState({ allServices, filtersMap });
          });
        });
      }
    );
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let toSet = {};
    if (nextProps.getAvailableTimeslots) {
      if (
        !nextProps.getAvailableTimeslots.failure &&
        !nextProps.getAvailableTimeslots.isFetching &&
        !nextProps.getAvailableTimeslots.errorMessage &&
        nextProps.getAvailableTimeslots.data &&
        nextProps.getAvailableTimeslots.data.data
      ) {
        const { lockFields } = prevState;
        if (lockFields) {
          const {
            selectDateAndTime,
            selectServices,
            timeSlotLength,
            getEmployeeData,
          } = prevState;
          if (getEmployeeData) {
            const duration = parseInt(
              getNestedValue(
                JSON.parse(selectServices || "{}"),
                "duration",
                timeSlotLength
              )
            );
            const matchingEmployees = getNestedValue(
              nextProps.getAvailableTimeslots.data.data,
              [
                `${moment(selectDateAndTime).format("HH:mm")} - ${moment(
                  selectDateAndTime
                )
                  .add(duration, "m")
                  .format("HH:mm")}`,
              ],
              []
            );
            const filteredEmployeeData = getEmployeeData.filter((employee) =>
              matchingEmployees.includes(
                getNestedValue(employee, ["employeeId", "_id"])
              )
            );
            toSet = { ...toSet, isloading: false, filteredEmployeeData };
          }
        } else {
          toSet = {
            ...toSet,
            isloading: false,
            availableTimeslots: nextProps.getAvailableTimeslots.data.data,
          };
        }
      } else if (nextProps.getAvailableTimeslots.isFetching) {
        toSet = { ...toSet, isloading: true };
      }
    }
    if (nextProps.getGymTimeslots) {
      if (
        !nextProps.getGymTimeslots.failure &&
        !nextProps.getGymTimeslots.isFetching &&
        !nextProps.getGymTimeslots.errorMessage &&
        nextProps.getGymTimeslots.data &&
        nextProps.getGymTimeslots.data.data
      ) {
        toSet = {
          ...toSet,
          isloading: false,
          availableTimeslots: nextProps.getGymTimeslots.data.data,
        };
      } else if (nextProps.getGymTimeslots.isFetching) {
        toSet = { ...toSet, isloading: false };
      }
    }
    if (nextProps.booking) {
      nextProps.clear();
      const service = getNestedValue(nextProps.booking, ["services", 0], {});
      const booking = { ...nextProps.booking };
      booking.services = booking.services.map((service) => {
        return {
          ...service,
          employeeId: service.employeeId._id,
        };
      });

      toSet = {
        ...toSet,
        showBookingModal: true,
        selectBooking: booking,
        activeDate: moment(service.date, "MM-DD-YYYY").toDate(),
        selectBookingAmount: nextProps.booking.totalAmount,
        paymentMethod: nextProps.booking.paymentMethod,
        bookingStaus: nextProps.booking.status,
        estimateDuration: service.duration,
        editDetails: false,
      };
    }
    return toSet;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (this.state.activeDate &&
        this.state.activeDate !== prevState.activeDate) ||
      (!prevState.initialized && this.state.initialized)
    ) {
      this.getSaloonAppoint();
      this.getCompanyEmployeeUnavaiabilities();
    }
  }

  getSalonCategories = async () => {
    const { companyData } = this.state;
    // this.setState({ isloading: true })

    if (!companyData._id) {
      this.setState({ isloading: false });
      return;
    }
    const payload = {
      companyId: companyData._id,
    };
    return getSaloonCategories(payload)
      .then((res) => {
        if (res.data.success) {
          this.setState({
            categoryList: res.data.data,
            isloading: false,
          });
        }
      })
      .catch((error) => {
        ErrorHelper.handleErrors(
          "Aww somthing went wrong for getting Categories",
          true
        );
        this.setState({ isloading: false });
      });
  };

  getServices = async (categoryId) => {
    const { lockFields } = this.state;
    const { companyData } = this.state;
    this.setState({
      isloading: true,
      selectServices: "",
      selectEmployee: "",
      selectedEmployee: "",
      ...(lockFields
        ? {}
        : {
            newDate: "",
            selectedTime: undefined,
            availableTimeslots: {},
            selectDateAndTime: undefined,
          }),
    });
    try {
      const res = await getSaloonServices({
        companyId: companyData?._id,
        categoryId: categoryId?._id,
      });
      this.setState({ getSalonServices: res.data.data, isloading: false });
      return res.data.data;
    } catch (error) {
      this.setState({ isloading: false });
      ErrorHelper.handleErrors(
        "Aww somthing went wrong for getting Services",
        true
      );
    }
  };

  getEmployee = async (selectServices) => {
    const serviceData = selectServices
      ? JSON.parse(selectServices)
      : selectServices;
    this.setState({ serviceData });
    const { companyData, lockFields } = this.state;

    this.setState({ isloading: true });
    try {
      const res = await getEmployeesBySaloonAndService({
        companyId: companyData._id,
        serviceId: serviceData._id,
      });
      this.setState({
        getEmployeeData: res.data.data,
        isloading: false,
        selectServices,
      });
      if (lockFields) {
        const { newDate } = this.state;
        let date = newDate;
        const { companyData } = this.state;
        this.setState({ isloading: true });
        this.props.get_available_timeslots({
          data: {
            companyId: companyData._id,
            companyServiceId: serviceData._id,
            date,
          },
        });
      }
      return res.data.data;
    } catch (error) {
      ErrorHelper.handleErrors(
        "Aww somthing went wrong for getting Employees",
        true
      );
      this.setState({ isloading: false });
    }
  };

  checkValidationForAddAppointment() {
    const {
      firstName,
      lastName,
      postalCode,
      email,
      phoneNumber,
      totalAmount,
      selectedCategory,
      selectServices,
      selectEmployee,
      selectedEmployee,
      selectDateAndTime,
      selectedTime,
      isGym,
      newTime,
      enableDoubleBooking,
      enableWaitingList,
      startTime,
      endTime,
    } = this.state;
    const nameRegex =
      /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{2,}$/u;
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const telephoneRegex =
      /[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]/g;
    if (email && !email.match(emailRegex)) {
      this.setState({
        formErrors: {
          emailError: "Invalid Email",
        },
      });
      setTimeout(() => {
        this.setState({
          formErrors: {
            emailError: "",
          },
        });
      }, 8000);
    } else if (firstName.length < 2) {
      this.setState({
        formErrors: {
          firstNameError: "First name must be atleast 2 characters",
        },
      });
      setTimeout(() => {
        this.setState({
          formErrors: {
            firstNameError: "",
          },
        });
      }, 8000);
    } else if (!phoneNumber) {
      this.setState({
        formErrors: {
          phoneNumberError: "Please fill valid phone No",
        },
      });
      setTimeout(() => {
        this.setState({
          formErrors: {
            phoneNumberError: "",
          },
        });
      }, 8000);
    } else if (!phoneNumber.replace(/\s/g, "").match(telephoneRegex)) {
      this.setState({
        formErrors: {
          phoneNumberError: (
            <Translation>{(t) => t("invalid_phone_number")}</Translation>
          ),
        },
      });

      setTimeout(() => {
        this.setState({
          telephoneError: "",
        });
      }, 6000);
    } else if (!selectedCategory) {
      this.setState({
        formErrors: {
          selectPaymentMethodError: alert("please select Category"),
        },
      });
      setTimeout(() => {
        this.setState({
          formErrors: {
            selectPaymentMethodError: "",
          },
        });
      }, 8000);
    } else if (!selectServices) {
      this.setState({
        formErrors: {
          selectServicesError: "Please select Services",
        },
      });
      setTimeout(() => {
        this.setState({
          formErrors: {
            selectServicesError: "",
          },
        });
      }, 8000);
    } else if (
      !isGym &&
      (() => {
        if (!selectEmployee) {
          this.setState({
            formErrors: {
              selectEmployeeError: "Please select Employee",
            },
          });
          setTimeout(() => {
            this.setState({
              formErrors: {
                selectEmployeeError: "",
              },
            });
          }, 8000);
          return true;
        } else {
          return false;
        }
      })()
    ) {
    } else if (!selectDateAndTime) {
      this.setState({
        formErrors: {
          selectDateAndTimeError: "Please select Date",
        },
      });
      setTimeout(() => {
        this.setState({
          formErrors: {
            selectDateAndTimeError: "",
          },
        });
      }, 8000);
    } else if (!enableWaitingList && !selectedTime) {
      this.setState({
        formErrors: {
          selectedTimeError: "Please select Time",
        },
      });
      setTimeout(() => {
        this.setState({
          formErrors: {
            selectedTimeError: "",
          },
        });
      }, 8000);
    } else if (
      !enableWaitingList &&
      (!newTime || !newTime.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/))
    ) {
      this.setState({
        formErrors: {
          selectedTimeError: "Invalid, expected hh:mm",
        },
      });
      setTimeout(() => {
        this.setState({
          formErrors: {
            selectedTimeError: "",
          },
        });
      }, 8000);
    } else if (
      enableWaitingList &&
      (!startTime || !startTime.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/))
    ) {
      this.setState({
        formErrors: {
          startTimeError: "Invalid, expected hh:mm",
        },
      });
      setTimeout(() => {
        this.setState({
          formErrors: {
            startTimeError: "",
          },
        });
      }, 8000);
    } else if (
      enableWaitingList &&
      (!endTime || !endTime.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/))
    ) {
      this.setState({
        formErrors: {
          endTimeError: "Invalid, expected hh:mm",
        },
      });
      setTimeout(() => {
        this.setState({
          formErrors: {
            endTimeError: "",
          },
        });
      }, 8000);
    } else {
      this.handleAddNewAppointment();
    }
  }
  handleAddNewAppointment = () => {
    const {
      firstName,
      lastName,
      postalCode,
      email,
      phoneNumber,
      totalAmount,
      selectedCategory,
      selectServices,
      selectEmployee,
      selectDateAndTime,
      companyData,
      serviceData,
      access_token,
      selectedTime,
      newTime,
      newDate,
      notes,
      enableWaitingList,
      availableTimeslots,
      startTime,
      endTime,
      enableDoubleBooking,
      depositAmount,
    } = this.state;

    const service = {
      serviceId: serviceData._id,
      categoryId: selectedCategory._id,
      date: newDate,
      time: newTime,
      ...(selectEmployee
        ? {
            employeeId: selectEmployee,
          }
        : {}),
    };
    this.setState({ isloading: true });

    if (!enableWaitingList) {
      const createBooking = (depositRequired) => {
        const payload = {
          companyId: companyData._id,
          // postalCode,
          // firstName,
          // lastName,
          userName: firstName,
          email,
          phoneNo: phoneNumber,
          status: 1,
          totalAmount,
          notes,
          forceBooking: !!enableDoubleBooking,
          depositRequired,
        };
        addCustomBooking({ ...payload, services: [service] }, access_token)
          .then((res) => {
            this.setState({
              isloading: false,
              totalAmount: 0,
              selectPaymentMethod: "",
            });

            if (res.data.success) {
              SuccessHelper.handleSuccess("Booking Created", true);
              window.location.reload();
            } else {
              ErrorHelper.handleErrors("Something went wrong", true);
            }
          })

          .catch((error) => {
            this.setState({ isloading: false, isUpdate: false });

            if (error.response) {
              // Alert.alert("Error", error.response.data.msg);
              ErrorHelper.handleErrors(error.response.data.msg, true);

              // Request made and server responded
            } else if (error.request) {
              ErrorHelper.handleErrors("Something Went Wrong", true);

              // The request was made but no response was received
            } else {
              ErrorHelper.handleErrors(error.msg, true);
            }
          });
      };
      if (depositAmount) {
        Swal.fire({
          title: i18n.t("require_deposit", { depositAmount }),
          showCancelButton: true,
          cancelButtonText: "No",
          confirmButtonText: "Yes",
        }).then((e) => {
          const { dismiss, isConfirmed, isDismissed } = e;
          if (!isDismissed || dismiss != "backdrop") {
            createBooking(isConfirmed);
          }
        });
      } else {
        createBooking(false);
      }
    } else {
      const payload = {
        companyId: companyData._id,
        email,
        phoneNo: phoneNumber,
        status: 1,
        serviceId: serviceData._id,
        date: newDate,
        startTime: startTime,
        endTime: endTime,
        employeeId: selectEmployee,
      };
      createWaitingList({ ...payload }, access_token)
        .then((res) => {
          this.setState({
            isloading: false,
            totalAmount: 0,
            selectPaymentMethod: "",
          });

          if (res.data.success) {
            SuccessHelper.handleSuccess("Added to waiting list", true);
            window.location.reload();
          } else {
            ErrorHelper.handleErrors("Something went wrong", true);
          }
          this.setState({
            showBookingPopup: false,
            selectDateAndTime: undefined,
            newDate: "",
            selectedTime: undefined,
            selectServices: "",
            availableTimeslots: {},
            selectEmployee: "",
            selectedEmployee: "",
            notes: "",
          });
        })

        .catch((error) => {
          this.setState({ isloading: false, isUpdate: false });

          if (error.response) {
            // Alert.alert("Error", error.response.data.msg);
            ErrorHelper.handleErrors(error.response.data.msg, true);

            // Request made and server responded
          } else if (error.request) {
            ErrorHelper.handleErrors("Something Went Wrong", true);

            // The request was made but no response was received
          } else {
            ErrorHelper.handleErrors(error.msg, true);
          }
        });
    }
  };

  handleFilters = (data) => {
    let filteredBookings = this.state.bookings;
    if (data) {
      const normalize = (value) => {
        value = value ? value : "";
        return value.toLowerCase();
      };
      data.name = normalize(data.name);
      data.phoneNumber = normalize(data.phoneNumber).replace(/ /g, "");
      filteredBookings = filteredBookings.filter((booking) => {
        if (data.name) {
          if (
            !normalize(getNestedValue(booking, ["userId", "userName"])).match(
              data.name
            ) &&
            !normalize(getNestedValue(booking, "name", "")).match(data.name) &&
            !normalize(getNestedValue(booking, ["userId", "firstName"])).match(
              data.name
            ) &&
            !normalize(getNestedValue(booking, ["userId", "lastNme"])).match(
              data.name
            )
          ) {
            return false;
          }
        }
        if (data.shortenedId) {
          if (
            !getNestedValue(booking, ["shortenedId"]).match(data.shortenedId)
          ) {
            return false;
          }
        }
        if (data.phoneNumber) {
          if (
            !normalize(
              getNestedValue(booking, ["userId", "phoneNo"], "")
            ).match(data.phoneNumber) &&
            !normalize(getNestedValue(booking, "phoneNo", "")).match(
              data.phoneNumber
            )
          ) {
            return false;
          }
        }
        if (data.service && data.service.length) {
          if (
            !getNestedValue(booking, ["services"], []).filter((value) =>
              data.service.includes(
                getNestedValue(value, ["serviceId", "name"], "").trim()
              )
            ).length
          ) {
            return false;
          }
        }
        if (data.employee && data.employee.length) {
          if (
            !getNestedValue(booking, ["services"], []).filter((value) =>
              data.employee.includes(value.employeeId)
            ).length
          ) {
            return false;
          }
        }
        if (data.startDate || data.endDate) {
          // moment
          if (data.startDate && data.endDate) {
            const date = moment(
              getNestedValue(booking, ["services", 0, "date"], ""),
              "MM-DD-YYYY"
            );
            if (
              !moment(data.startDate).isSameOrBefore(date) ||
              !moment(data.endDate).isSameOrAfter(date)
            ) {
              return false;
            }
          } else {
            if (
              (data.startDate &&
                normalize(
                  getNestedValue(booking, ["services", 0, "date"], "")
                ) != moment(data.startDate).format("MM-DD-YYYY")) ||
              (data.endDate &&
                normalize(
                  getNestedValue(booking, ["services", 0, "date"], "")
                ) != moment(data.endDate).format("MM-DD-YYYY"))
            ) {
              return false;
            }
          }
        }
        if (data.status && data.status.length) {
          if (
            !data.status.includes(`${getNestedValue(booking, ["status"], 0)}`)
          ) {
            return false;
          }
        }
        return true;
      });
    }
    this.setState({ filteredBookings, showAdvancedSearchPopup: false });
  };

  generateDates = () => {
    const { activeDate } = this.state;
    const date = moment(activeDate).startOf("week");
    let dates = [date.format("MM-DD-YYYY")];
    for (let i = 0; i < 7; i++) {
      date.add(1, "d");
      dates.push(date.format("MM-DD-YYYY"));
    }
    return dates;
  };

  getSaloonAppoint = async (getAll) => {
    const { activeDate } = this.state;
    const query = {};
    if (!getAll) {
      const dates = this.generateDates();
      query.dates = dates.join(",");
    }
    try {
      this.setState({ isloading: true });
      const res = await gitWaitingAppoinments(query, this.state.access_token);
      this.setState({
        bookings: res.data.data.map((element, index) => {
          element.services = element.services.map((service) => {
            return {
              ...service,
              employeeId: service.employeeId?._id,
            };
          });
          return { ...element, index };
        }),
        isloading: false,
      });
    } catch (error) {
      console.log(error);
      alert("Something went wrong:(");
    }
  };

  renderEditDetail = () => {
    return {
      title: i18n.t("edit"),
      content: this.renderEmployeeDetail(),
      contentClassName: "modify-modal-content",
      actions: (
        <div className="row mx-0 justify-content-end">
          <Button
            onClick={() => this.handleUpdate()}
            style={{
              backgroundColor: "#ff3600",
              color: "white",
            }}
          >
            {i18n.t("update")}
          </Button>
        </div>
      ),
    };
  };

  handleClosePopup = () => {
    this.setState({
      showProductsServicesModal: false,
      showNotesModal: false,
      showExtrasModal: false,
      editDetails: false,
      showPopUp: false,
    });
  };

  handleAddProductsServices = async () => {
    this.setState({
      showProductsServicesModal: true,
      showPopUp: true,
    });
  };

  handleNotes = async () => {
    this.setState({
      showNotesModal: true,
      showPopUp: true,
    });
  };

  handleUpdate = async (extras = {}) => {
    const {
      paymentMethod,
      selectBookingAmount,
      selectBooking,
      bookingStaus,
      access_token,
      notes,
      tip,
      companyTaxes = [],
      editDetails,
    } = this.state;
    let { bookings } = this.state;
    if (editDetails) {
      const {
        newDate,
        selectDateAndTime,
        selectedTime,
        newTime,
        selectEmployee,
      } = this.state;

      if (!selectEmployee) {
        this.setState({
          formErrors: {
            selectEmployeeError: "Please select Employee",
          },
        });
        setTimeout(() => {
          this.setState({
            formErrors: {
              selectEmployeeError: "",
            },
          });
        }, 8000);
        return true;
      } else if (!selectDateAndTime) {
        this.setState({
          formErrors: {
            selectDateAndTimeError: "Please select Date",
          },
        });
        setTimeout(() => {
          this.setState({
            formErrors: {
              selectDateAndTimeError: "",
            },
          });
        }, 8000);
      } else if (!selectedTime) {
        this.setState({
          formErrors: {
            selectedTimeError: "Please select Time",
          },
        });
        setTimeout(() => {
          this.setState({
            formErrors: {
              selectedTimeError: "",
            },
          });
        }, 8000);
      } else if (
        !newTime ||
        !newTime.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)
      ) {
        this.setState({
          formErrors: {
            selectedTimeError: "Invalid, expected hh:mm",
          },
        });
        setTimeout(() => {
          this.setState({
            formErrors: {
              selectedTimeError: "",
            },
          });
        }, 8000);
      } else {
        const services = [
          {
            serviceId: selectBooking.services[0].serviceId._id,
            categoryId: selectBooking.services[0].categoryId._id,
            date: newDate,
            time: newTime,
            employeeId: selectEmployee,
          },
        ];

        const { data } = await editBooking(
          {
            bookingId: selectBooking._id,
            services,
          },
          access_token
        );
        let newSelectBooking = selectBooking;
        if (data.success) {
          const newBooking = data.data;
          newBooking.services = newBooking.services.map((service) => {
            return {
              ...service,
              employeeId: service.employeeId._id,
            };
          });
          newSelectBooking = { ...newBooking, index: selectBooking.index };
          if (typeof selectBooking.index === "number") {
            bookings[selectBooking.index] = newSelectBooking;
          }
          this.handleClosePopup();
          this.setState({
            isloading: false,
            bookings: [...bookings],
            selectBooking: newSelectBooking,
            editDetails: false,
          });
          SuccessHelper.handleSuccess("Updated Successfully", true);
        }
      }
    } else {
      const sendRequst = async (isFinalized) => {
        const taxes = [];
        const payload = {
          bookingId: selectBooking._id,
          services: selectBooking.services.map((service) => ({
            employeeId:
              typeof service.employeeId == "object"
                ? service.employeeId._id
                : service.employeeId,
            serviceId: service.serviceId._id,
            categoryId: service.categoryId._id,
            time: service.time,
            date: service.date,
          })),
          totalAmount: parseInt(selectBookingAmount),
          paymentMethod,
          status: bookingStaus,
          notes,
          tip: parseFloat(tip),
          taxes,
          isFinalized,
          ...extras,
        };

        try {
          this.setState({ isloading: true });
          const { data } = await editBooking(payload, access_token);
          let newSelectBooking = selectBooking;
          if (data.success) {
            data.data.services = data.data.services.map((service) => {
              return {
                ...service,
                employeeId: service.employeeId._id,
              };
            });
            if (
              (data.data.status == 4 && data.data.isFinalized) ||
              data.data.status == 3
            ) {
              if (typeof selectBooking.index === "number") {
                bookings.splice(selectBooking.index, 1);
                bookings = bookings.map((booking, index) => ({
                  ...booking,
                  index,
                }));
              }
            } else {
              newSelectBooking = {
                ...selectBooking,
                ...data.data,
                index: selectBooking.index,
              };
              if (typeof selectBooking.index === "number") {
                bookings[selectBooking.index] = {
                  ...newSelectBooking,
                  index: selectBooking.index,
                };
              }
            }
            this.setState({
              isloading: false,
              bookings: [...bookings],
              selectBooking: newSelectBooking,
            });
            this.handleClosePopup();
            SuccessHelper.handleSuccess("Updated Successfully", true);
            this.setState({ showBookingModal: false });
          } else {
            this.setState({ isloading: false });
            ErrorHelper.handleErrors(data.msg, true);
          }
        } catch (error) {
          console.log(error);
          this.setState({ isloading: false });
          if (error.data.msg) {
            ErrorHelper.handleErrors(error.data.msg, true);
          } else if (error.response) {
            alert(error.response.data.msg);

            // Request made and server responded
          } else if (error.request) {
            alert("Something Went Wrong ");

            // The request was made but no response was received
          } else {
            alert(error.msg);
            // Something happened in setting up the request that triggered an Error
          }
        }
      };
      if (bookingStaus == 4) {
        Swal.fire({
          title: i18n.t("are_you_sure_you_want_to_finalize_this_booking"),
          showCancelButton: true,
          cancelButtonText: "No",
          confirmButtonText: "Yes",
        }).then((e) => {
          const { dismiss, isConfirmed, isDismissed } = e;
          if (!isDismissed || dismiss != "backdrop") {
            if (isConfirmed) {
              this.setState(
                {
                  showInsertPayment: true,
                  paymentCompleteCallback: () => sendRequst(isConfirmed),
                },
                () => {
                  this.setState({
                    showInsertPayment: false,
                  });
                }
              );
            } else {
              sendRequst(isConfirmed);
            }
          }
        });
      } else {
        sendRequst(false);
      }
    }
  };

  renderExtras = () => {
    const { selectBooking, products, access_token, bookings, allServices } =
      this.state;
    const onUpdate = (data) => {
      this.setState({ ...data });
    };
    return {
      title: i18n.t("container.add_extras"),
      content: (
        <AddExtrasModal
          selectBooking={selectBooking}
          products={products}
          access_token={access_token}
          bookings={bookings}
          allServices={allServices}
          onUpdate={onUpdate}
          handleClose={this.handleClosePopup}
        />
      ),
    };
  };

  renderBookingNotes = () => {
    const { selectBooking, access_token } = this.state;
    return {
      title: i18n.t("container.booking_notes"),
      content: (
        <BookingNotes
          bookingId={selectBooking._id}
          access_token={access_token}
          hasEditPermission={true}
        />
      ),
    };
  };

  handleEditDetails = async () => {
    const { selectBooking, companyData } = this.state;
    const selectService = getNestedValue(
      selectBooking,
      ["services", 0, "serviceId"],
      {}
    );
    let selectEmployee = getNestedValue(
      selectBooking,
      ["services", 0, "employeeId"],
      {}
    );
    const momentDate = moment(
      `${getNestedValue(
        selectBooking,
        ["services", 0, "date"],
        ""
      )} ${getNestedValue(selectBooking, ["services", 0, "time"], "")}`,
      "MM-DD-YYYY HH:mm"
    );
    Promise.all([
      new Promise((resolve) => {
        this.props.get_available_timeslots({
          data: {
            companyId: companyData._id,
            companyServiceId: selectService._id,
            date: momentDate.format("MM-DD-YYYY"),
            bookingIdToExclude: selectBooking._id,
            companyEmployeeId: selectEmployee,
          },
          resolve,
        });
      }),
      this.getEmployee(JSON.stringify(selectService)),
    ]).then(() => {
      setTimeout(() => {
        const { getEmployeeData, availableTimeslots } = this.state;
        let selectedEmployee = getEmployeeData.find(
          (employee) => employee.employeeId._id == selectEmployee
        );
        this.setState({
          showPopUp: true,
          enableDoubleBooking: false,
          enableWaitingList: false,
          editDetails: true,
          lockFields: false,
          selectedEmployee: JSON.stringify(selectedEmployee),
          selectEmployee,
          newDate: momentDate.format("MM-DD-YYYY"),
          newTime: momentDate.format("HH:mm"),
          selectDateAndTime: momentDate.toDate(),
          selectedTime: `${momentDate.format("HH:mm")} - ${moment(momentDate)
            .add(parseInt(selectService.duration), "minutes")
            .format("HH:mm")}`,
        });
      }, 50);
    });
  };

  handleAddProductsServices = () => {
    this.setState({
      showExtrasModal: true,
      showPopUp: true,
    });
  };

  renderPopup = () => {
    const {
      showPopUp,
      allSaloonMap,
      showNotesModal,
      showExtrasModal,
      editDetails,
    } = this.state;
    let modalFunction;
    if (showNotesModal) {
      modalFunction = this.renderBookingNotes;
    } else if (showExtrasModal) {
      modalFunction = this.renderExtras;
    } else if (editDetails) {
      modalFunction = this.renderEditDetail;
    }
    const modalProps = modalFunction ? modalFunction() : {};
    return (
      <BaseModal
        style={{ zIndex: 1302 }}
        open={showPopUp}
        {...modalProps}
        onClose={() => this.handleClosePopup()}
      />
    );
  };

  renderLoader = () => {
    const { isloading, initialized } = this.state;
    return <DotsLoader isloading={isloading || !initialized} />;
  };

  onChangeSearchText = (searchText) => {
    this.setState({ searchText });
  };

  renderSaloonDashboardHeading = () => {
    const { searchText, filteredBookings } = this.state;
    return (
      <div className="row mx-0 py-3">
        <div className="col-12 col-lg-3 col-md-3">
          <span className="saloon-dashboard-heading">
            {i18n.t("container.my_appointments")}
          </span>
        </div>
        <div className="col-12 col-lg-9 col-md-9 mt-8">
          <div className="d-flex justify-content-end align-content-center row full-height">
            <div className="col-12 col-lg-7 col-md-7">
              <div className="">
                <input
                  style={{ backgroundColor: "#FFFFFF", borderColor: "#EBEBEB" }}
                  type="text"
                  id="addEmployee_search_bar"
                  className="form-control form-control-sm pl-5"
                  placeholder={i18n.t(
                    "container.services_search_by_name_phone_number_ref"
                  )}
                  onChange={(text) =>
                    this.onChangeSearchText(text.target.value)
                  }
                  value={searchText}
                />
                <img src={Images.saloon_search} className="search-bar-icon" />
              </div>
            </div>
            <div>
              <button
                type="button"
                onClick={() => {
                  if (!filteredBookings) {
                    this.setState({ showAdvancedSearchPopup: true });
                  } else {
                    this.setState({ filteredBookings: undefined });
                  }
                }}
                className="btn btn-light btn-sm mb-2 "
                id="saloon-save-social-link"
              >
                {filteredBookings ? (
                  <Translation>
                    {(t) =>
                      t("container.advancedSearchDialog_clear_advanced_search")
                    }
                  </Translation>
                ) : (
                  <Translation>
                    {(t) => t("container.advancedSearchDialog_advanced_search")}
                  </Translation>
                )}
              </button>
            </div>
            <div>
              <button
                type="button"
                onClick={() =>
                  this.setState({
                    showBookingPopup: true,
                    lockFields: false,
                    selectDateAndTime: null,
                    selectedTime: null,
                    notes: "",
                  })
                }
                className="btn btn-light btn-sm mb-2 ml-1"
                id="saloon-save-social-link"
              >
                <Translation>
                  {(t) => t("container.create_appointment")}
                </Translation>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  handleFilterChange = (key, value) => {
    const { filtersMap } = this.state;
    if (key == "employee" || key == "service" || key == "status") {
      const keyMap = filtersMap[key] ? filtersMap[key] : {};
      if (keyMap[value]) {
        delete keyMap[value];
      } else {
        keyMap[value] = true;
      }
      filtersMap[key] = keyMap;
    } else {
      filtersMap[key] = value.target.value;
    }

    this.handleFilters({
      ...filtersMap,
      status: Object.keys(filtersMap.status ? filtersMap.status : {}),
      employee: Object.keys(filtersMap.employee ? filtersMap.employee : {}),
      service: Object.keys(filtersMap.service ? filtersMap.service : {}),
    });
    this.setState({ filtersMap });
  };

  getContrastYIQ = (hexcolor) => {
    if (hexcolor) {
      hexcolor = hexcolor.replace("#", "");
      var r = parseInt(hexcolor.substr(0, 2), 16);
      var g = parseInt(hexcolor.substr(2, 2), 16);
      var b = parseInt(hexcolor.substr(4, 2), 16);
      var yiq = (r * 299 + g * 587 + b * 114) / 1000;
      return yiq >= 128 ? "black" : "white";
    } else {
      return "black";
    }
  };

  onCalendarClick = (date, employeeId = "") => {
    const { timeSlotLength, rawEmployees } = this.state;
    let employee = "";
    if (employeeId) {
      employee = rawEmployees.find((employee) => {
        return employee.employeeId._id == employeeId;
      });
      employee = employee ? JSON.stringify(employee) : "";
    }
    const momentDate = moment(date);
    let remainder = parseInt(momentDate.format("mm")) % timeSlotLength;
    momentDate.subtract(remainder, "m");
    this.setState({
      lockFields: true,
      showBookingModal: false,
      showBookingPopup: true,
      newDate: momentDate.format("MM-DD-YYYY"),
      selectedTime: momentDate.format("HH:mm"),
      newTime: momentDate.format("HH:mm"),
      availableTimeslots: {},
      selectDateAndTime: momentDate.toDate(),
      availableTimeslots: { [momentDate.format("HH:mm")]: [true] },
      selectServices: "",
      selectEmployee: employeeId,
      selectedEmployee: employee,
      categoryId: "",
      selectedCategory: "",
      notes: "",
    });
  };

  setViewWorkingHoursUnavailabilities = (viewWorkingHoursUnavailabilities) => {
    localStorage.setItem(
      "viewWorkingHoursUnavailabilities",
      viewWorkingHoursUnavailabilities
    );
    this.setState({
      viewWorkingHoursUnavailabilities,
    });
  };
  setViewUnavailabilities = (viewUnavailabilities) => {
    localStorage.setItem("viewUnavailabilities", viewUnavailabilities);
    this.setState({
      viewUnavailabilities,
    });
  };

  Plan = (bookingsToRender) => {
    const {
      employees,
      allServices = [],
      start,
      employeeColorMap = {},
      employeeMap = {},
      selectBooking = {},
      activeDate,
      company,
      companyTaxes,
      unavailabilities = [],
      workingDayUnavailabilities = [],
      viewWorkingHoursUnavailabilities,
      viewUnavailabilities,
    } = this.state;
    let formattedUnavailabilities = viewUnavailabilities
      ? unavailabilities.map((element) => ({
          ...element,
          textColor: this.getContrastYIQ(employeeColorMap[element.employeeId]),
          resourceId: element.employeeId,
          color: employeeColorMap[element.employeeId] || "#000",
          title: ` ${employeeMap[element.employeeId]} - ${i18n.t(
            "unavailable"
          )}`,
        }))
      : [];
    let formattedViewWorkingHoursUnavailabilities =
      viewWorkingHoursUnavailabilities
        ? workingDayUnavailabilities.map((element) => ({
            ...element,
            textColor: this.getContrastYIQ(
              employeeColorMap[element.employeeId]
            ),
            resourceId: element.employeeId,
            color: employeeColorMap[element.employeeId] || "#000",
            title: ` ${employeeMap[element.employeeId]} - ${i18n.t(
              "unavailable"
            )}`,
          }))
        : [];
    const statusClassMap = {
      1: "calendar-event-waiting",
      2: "calendar-event-now-serving",
      3: "calendar-event-cancel",
      4: "calendar-event-done",
    };
    const date = getNestedValue(selectBooking, ["services", 0, "date"], "");
    const plans = bookingsToRender.map((booking) => {
      const { services, _id, name } = booking;
      return services.map((service) => {
        let employeeName = "";
        if (employeeMap[service.employeeId]) {
          employeeName = employeeMap[service.employeeId].split(" ")[0];
        }
        const date = moment(
          `${service.date} ${service.time}`,
          "MM-DD-YYYY HH:mm"
        );
        const start = date.toDate();
        const end = date
          .add(parseInt(service.serviceId.duration), "minutes")
          .toDate();
        return {
          ...booking,
          id: _id,
          title: `${name} - ${service.serviceId.name}`,
          employeeName,
          allDay: false,
          start,
          end,
          textColor: this.getContrastYIQ(employeeColorMap[service.employeeId]),
          color: employeeColorMap[service.employeeId],
          duration: service.serviceId.duration,
          className: statusClassMap[booking.status],
          type: "booking",
          resourceId: service.employeeId,
        };
      });
    });
    const onEventClick = (val) => {
      if (val.status != 3) {
        this.setState({
          showBookingModal: true,
          selectBooking: val,
          selectBookingAmount: val.totalAmount,
          paymentMethod: val.paymentMethod,
          bookingStaus: val.status, //41 45 24
          estimateDuration: val.duration,
          notes: val.notes,
          editDetails: false,
          tip: val.tip,
        });
      }
    };
    return (
      <div className="plan-container">
        <CalendarSidebar open={this.state.isOpen} width="17vw">
          <CalendarSidebarContent
            onChange={this.handleFilterChange}
            employees={employees}
            services={allServices.map((service) =>
              getNestedValue(service, ["name"])
            )}
            setViewWorkingHoursUnavailabilities={
              this.setViewWorkingHoursUnavailabilities
            }
            setViewUnavailabilities={this.setViewUnavailabilities}
            viewWorkingHours={viewWorkingHoursUnavailabilities}
            viewUnavailabilities={viewUnavailabilities}
          />
        </CalendarSidebar>
        <PlanView
          open={this.state.isOpen}
          width="17vw"
          slideSideNav={(open) => {
            this.setState({
              isOpen: typeof open == "boolean" ? open : !this.state.isOpen,
            });
          }}
          companies={[company]}
          companyTaxMap={{ [company?._id]: companyTaxes }}
          onEventClick={onEventClick}
          plans={[
            ...plans,
            formattedUnavailabilities,
            formattedViewWorkingHoursUnavailabilities,
          ]}
          createAppointment={() => {
            this.setState({
              showBookingPopup: true,
              lockFields: false,
              selectDateAndTime: null,
              selectedTime: null,
              showBookingModal: false,
              notes: "",
            });
          }}
          onDateClick={this.onCalendarClick}
          start={start}
          date={activeDate}
          onDateChange={(e) => {
            this.setState({ activeDate: e });
          }}
          onDismiss={(_id, index) => {
            const { access_token, bookings } = this.state;
            this.setState({ isloading: true });
            dismissCancelledBooking({ id: _id }, access_token)
              .then(({ data }) => {
                if (data.success) {
                  let newBookings = bookings;
                  newBookings.splice(index, 1);
                  this.setState({
                    bookings: newBookings.map((booking, index) => ({
                      ...booking,
                      index,
                    })),
                    isloading: false,
                  });
                }
              })
              .finally(() => {
                this.setState({ isloading: false });
              });
          }}
          employees={employees}
          isSaloon
        />
      </div>
    );
  };
  renderAppointmentCalendar = (bookings) => {
    const { searchText, filteredBookings } = this.state;
    if (!bookings) return null;
    let renderBooking = bookings;
    if (filteredBookings) {
      renderBooking = filteredBookings;
    } else if (searchText)
      renderBooking = bookings?.filter((val) => {
        var ll = getNestedValue(val, ["userId", "userName"], "") || "";
        if (
          ll.toLowerCase().includes(searchText.toLowerCase()) ||
          val?.phoneNo?.includes(searchText)
        )
          return true;
        return false;
      });
    return <div className="col-12 pt-2 px-0">{this.Plan(renderBooking)}</div>;
  };
  renderAppointmentTable = (bookings) => {
    return (
      <div className="col-12 px-0">
        {this.renderSaloonDashboardHeading()}
        <div className="table-responsive">
          <table className="table table-borderless saloon-working-hour-table">
            {this.renderTableHead()}
            {bookings && this.renderTableBody(bookings)}
          </table>
        </div>
      </div>
    );
  };

  renderAdvancedSearchPopup = () => {
    const { showAdvancedSearchPopup, allServices = [], employees } = this.state;
    return (
      showAdvancedSearchPopup && (
        <AdvancedSearchDialog
          services={allServices.map((service) =>
            getNestedValue(service, ["name"])
          )}
          employees={employees}
          onFilter={(data) => this.handleFilters(data)}
          hideFitlerDetails={false}
          extraStatues={[
            { text: "Waiting", value: 1 },
            { text: "Now Serving", value: 2 },
          ]}
          onClose={() => this.setState({ showAdvancedSearchPopup: false })}
        ></AdvancedSearchDialog>
      )
    );
  };

  renderFOBs = () => {
    const { bookings = [], displayCalendar } = this.state;
    return (
      <>
        <button
          className="apt-fob px-2 py-1"
          onClick={() => {
            if (displayCalendar) {
              this.getSaloonAppoint(true);
            }
            this.setState({ displayCalendar: !displayCalendar });
            if (!displayCalendar) {
              setTimeout(() => {
                this.handleFilters();
              }, 500);
            }
          }}
        >
          {displayCalendar
            ? i18n.t("all_appointments")
            : i18n.t("weekly_appointments")}
        </button>
        <button
          className="refresh-fob p-1"
          onClick={() => {
            this.getSaloonAppoint();
            this.getCompanyEmployeeUnavaiabilities();
          }}
        >
          <RefreshIcon />
        </button>
      </>
    );
  };

  onChange = (data, then = () => {}) => {
    this.setState(data, then);
  };

  renderBookingPopup = () => {
    const {
      showBookingPopup,
      enableDoubleBooking,
      enableWaitingList,
      lockFields,
      selectedEmployee,
      selectDateAndTime,
      selectedTime,
      usersList,
      phoneNumber,
      email,
      firstName,
      totalAmount,
      formErrors,
      categoryList,
      selectedCategory,
      getServices,
      selectServices,
      getSalonServices,
      isGym,
      access_token,
      companyData = {},
      newTime,
      newDate,
      selectEmployee,
      getEmployeeData,
      filteredEmployeeData,
      availableTimeslots,
    } = this.state;
    return (
      <div className="col-12 px-0 px-md-3">
        <div
          className="employee-service-card-wrapper"
          style={{ display: showBookingPopup ? "flex" : "none" }}
        >
          <div className="container ">
            <div
              className="col-12 my-5 p-3 bg-white pop_container"
              style={{ borderRadius: "1rem" }}
            >
              <div className="d-flex justify-content-between">
                <div style={{ fontSize: 25 }}>
                  {i18n.t("container.add_booking")}
                </div>
                <div className="row mx-0">
                  <div className="row mx-1" style={{ flexDirection: "column" }}>
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        id="double_booking"
                        className="custom-control-input"
                        checked={enableDoubleBooking}
                        onChange={(e) => {
                          if (enableWaitingList) {
                            this.setState({ enableWaitingList: false }, () => {
                              this.onDateChange(selectDateAndTime);
                            });
                          }
                          this.setState({
                            enableDoubleBooking: e.target.checked,
                          });
                        }}
                      />
                      <label class="custom-control-label" for="double_booking">
                        {i18n.t("double_booking")}
                      </label>
                    </div>
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        id="waiting_list"
                        className="custom-control-input"
                        checked={enableWaitingList}
                        disabled={lockFields}
                        onChange={(e) => {
                          this.setState(
                            {
                              enableWaitingList: e.target.checked,
                              enableDoubleBooking: false,
                            },
                            () => {
                              if (selectDateAndTime) {
                                this.onDateChange(selectDateAndTime);
                              }
                            }
                          );
                        }}
                      />
                      <label class="custom-control-label" for="waiting_list">
                        {i18n.t("enable_waiting_list")}
                      </label>
                    </div>
                  </div>
                  <div className="py-0 px-2 text-right ">
                    <span
                      onClick={() =>
                        this.setState({ showBookingPopup: false, notes: "" })
                      }
                      className="h4 cursor-pointer"
                    >
                      &times;
                    </span>
                  </div>
                </div>
              </div>
              <CreateAppointment
                customers={usersList}
                {...{
                  phoneNumber,
                  email,
                  firstName,
                  totalAmount,
                  formErrors,
                  categoryList,
                  selectedCategory,
                  getServices,
                  selectServices,
                  getSalonServices,
                  lockFields,
                  isGym,
                }}
                isSalon={true}
                getServices={this.getServices}
                onChange={this.onChange}
                onChangeSelectServices={this.onChangeSelectServices}
                renderDateTimeEmployeeSection={this.renderEmployeeDetail}
                accessToken={access_token}
                companyId={companyData._id}
                date={newDate}
                time={newTime}
                getEmployeeData={getEmployeeData}
                availableTimeslots={availableTimeslots}
                employeeId={selectEmployee}
                filteredEmployeeData={filteredEmployeeData}
                enableWaitingList={enableWaitingList}
                enableDoubleBooking={enableDoubleBooking}
              />
              <div className="row mx-0 justify-content-end">
                {lockFields && (
                  <Button
                    onClick={() => this.removeLock()}
                    style={{
                      backgroundColor: "#ff3600",
                      color: "white",
                    }}
                    className="mr-1"
                  >
                    {i18n.t("remove_lock")}
                  </Button>
                )}
                <Button
                  onClick={() => this.checkValidationForAddAppointment()}
                  style={{
                    backgroundColor: "#ff3600",
                    color: "white",
                  }}
                >
                  {i18n.t("book_now")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  removeLock = () => {
    const { selectedEmployee } = this.state;
    if (selectedEmployee) {
      const {
        companyData,
        serviceData,
        newDate,
        selectServices,
        timeSlotLength,
        selectDateAndTime,
      } = this.state;
      const duration = parseInt(
        getNestedValue(
          JSON.parse(selectServices || "{}"),
          "duration",
          timeSlotLength
        )
      );
      this.setState({ isloading: true });
      this.props.get_available_timeslots({
        data: {
          companyId: companyData._id,
          companyServiceId: serviceData._id,
          date: newDate,
        },
        resolve: () => {
          this.setState({
            selectedTime: `${moment(selectDateAndTime).format(
              "HH:mm"
            )} - ${moment(selectDateAndTime)
              .add(duration, "m")
              .format("HH:mm")}`,
          });
        },
      });
    } else {
      this.setState({
        newDate: "",
        selectedTime: "",
        newTime: "",
        selectDateAndTime: undefined,
        availableTimeslots: {},
      });
    }
    this.setState({ lockFields: false });
  };

  renderTimeSlot = (time) => {
    const {
      allSelectedDateOfAllAvailTime,
      selectDateAndTime,
      daysMock,
      weekPlans,
    } = this.state;

    if (!selectDateAndTime) return;
    const selectedTime = moment(time).format("h:mm:ss a");
    document.querySelectorAll(".disabled-times").forEach(function (a) {
      a.remove();
    });

    const getDay = moment(selectDateAndTime).format("ddd");
    const getDayTime = weekPlans.find(
      (val) => daysMock[val.dayOfWeek].name === getDay
    );

    const checkIn = new Date(`01/01/2001 ${getDayTime.checkIn}`).getTime();
    const checkOut = new Date(`01/01/2001 ${getDayTime.checkOut}`).getTime();
    const a = new Date(`01/01/2001 ${selectedTime}`).getTime();

    if (
      Math.min(checkIn, checkOut) <= Math.max(a, a) &&
      Math.max(checkIn, checkOut) >= Math.min(a, a)
    )
      return undefined;

    return "disabled-times";
  };

  renderTimeSelection = () => {
    const {
      formErrors,
      availableTimeslots,
      enableDoubleBooking,
      enableWaitingList,
      newTime,
      timeSlotLength,
      startTime,
      endTime,
      lockFields,
      selectedTime,
    } = this.state;
    if (enableDoubleBooking) {
      const label =
        i18n.t("container.time") + (lockFields ? ` (${i18n.t("locked")})` : "");
      return (
        <div className="form-label-group mb-3 col-md-4 col-6 ">
          <TextField
            id="time"
            label={label}
            value={newTime}
            type="time"
            className="full-width"
            onChange={(text) => this.onChangeTimeText(text)}
            onBlur={() => {
              if (newTime) {
                let hour = newTime.split(":")[0];
                let minute = parseInt(newTime.split(":")[1]);
                const remainder = minute % timeSlotLength;
                if (remainder) {
                  minute =
                    minute -
                    remainder +
                    ((minute % timeSlotLength) / timeSlotLength >= 0.5
                      ? timeSlotLength
                      : 0);
                  if (minute >= 60) {
                    hour++;
                    minute %= 60;
                  }
                  const time = `${hour}:${minute < 10 ? "0" + minute : minute}`;
                  this.setState({ newTime: time, selectedTime: time });
                }
              }
            }}
            helperText={formErrors.selectedTimeError}
            error={!!formErrors.selectedTimeError}
          />
        </div>
      );
    } else if (enableWaitingList) {
      return (
        <>
          <div className="form-label-group mb-3 row mx-0 col-12">
            <div className="col-6 pl-0">
              <TextField
                id="start-time"
                label={i18n.t("container.start_time")}
                placeholder={i18n.t("container.start_time")}
                value={startTime}
                className="full-width"
                onChange={(text) => this.onChangeTimeText(text, "startTime")}
                onBlur={() => {
                  if (startTime) {
                    let hour = startTime.split(":")[0];
                    let minute = parseInt(startTime.split(":")[1]);
                    const remainder = minute % timeSlotLength;
                    if (remainder) {
                      minute =
                        minute -
                        remainder +
                        ((minute % timeSlotLength) / timeSlotLength > 0.5
                          ? timeSlotLength
                          : 0);
                      if (minute >= 60) {
                        hour++;
                        minute %= 60;
                      }
                      const time = `${hour}:${
                        minute < 10 ? "0" + minute : minute
                      }`;
                      this.setState({ startTime: time });
                    }
                  }
                }}
                disabled={lockFields}
                type="time"
                helperText={formErrors.startTimeError}
                error={!!formErrors.startTimeError}
              />
            </div>
            <div className="col-6 pl-0">
              <TextField
                id="time"
                label={i18n.t("container.end_time")}
                placeholder={i18n.t("container.end_time")}
                value={endTime}
                className="full-width"
                onChange={(text) => this.onChangeTimeText(text, "endTime")}
                onBlur={() => {
                  if (endTime) {
                    let hour = endTime.split(":")[0];
                    let minute = parseInt(endTime.split(":")[1]);
                    const remainder = minute % timeSlotLength;
                    if (remainder) {
                      minute =
                        minute -
                        remainder +
                        ((minute % timeSlotLength) / timeSlotLength > 0.5
                          ? timeSlotLength
                          : 0);
                      if (minute >= 60) {
                        hour++;
                        minute %= 60;
                      }
                      const time = `${hour}:${
                        minute < 10 ? "0" + minute : minute
                      }`;
                      this.setState({ endTime: time });
                    }
                  }
                }}
                type="time"
                disabled={lockFields}
                helperText={formErrors.endTimeError}
                error={!!formErrors.endTimeError}
              />
            </div>
          </div>
        </>
      );
    } else {
      return (
        <div className="form-label-group mb-3 col-md-4 col-6">
          <FormControl
            error={!!formErrors.selectedTimeError}
            className="full-width"
          >
            <InputLabel id="services">{i18n.t("container.time")}</InputLabel>
            <Select
              value={selectedTime}
              disabled={lockFields}
              onChange={(text) => this.onChangeTime(text)}
              placeholder={i18n.t("container.select_time")}
            >
              {Object.keys(availableTimeslots ? availableTimeslots : {})?.map(
                (val, ind) => {
                  return (
                    <MenuItem key={ind} value={val}>
                      {val.split(" ")[0]}{" "}
                      {availableTimeslots[val].length
                        ? ""
                        : `(${i18n.t("not_available")})`}
                    </MenuItem>
                  );
                }
              )}
            </Select>
            {!!formErrors.selectedTimeError && (
              <FormHelperText>{formErrors.selectedTimeError}</FormHelperText>
            )}
          </FormControl>
        </div>
      );
    }
  };

  renderEmployeeDetail = () => {
    const {
      formErrors,
      selectedEmployee,
      selectDateAndTime,
      selectedTime,
      isGym,
      getEmployeeData,
      rawEmployees,
      filteredEmployeeData,
      enableDoubleBooking,
      enableWaitingList,
      newTime,
      timeSlotLength,
      lockFields,
      newDate,
    } = this.state;
    let employees = [];
    if (enableDoubleBooking) {
      employees = rawEmployees;
    } else if (filteredEmployeeData) {
      employees = filteredEmployeeData;
    } else {
      employees = getEmployeeData;
    }
    return (
      <div className="row px-0 mx-0">
        {!isGym && (
          <div
            className={
              "form-label-group mb-3 col-6 " +
              (enableWaitingList ? "col-md-6" : "col-md-4")
            }
          >
            <FormControl
              error={!!formErrors.selectEmployeeError}
              className="full-width"
            >
              <InputLabel id="services">
                {i18n.t("container.employee")}
              </InputLabel>
              <Select
                value={selectedEmployee}
                onChange={(e) => this.onChangeSelectEmployee(e)}
                placeholder={i18n.t("select_employee")}
              >
                {employees?.map((val, ind) => {
                  let employeeName = val?.employeeId?.userId?.firstName
                    ? `${val?.employeeId?.userId?.firstName} ${val?.employeeId?.userId?.lastName}`
                    : val?.employeeId?.userId?.userName;
                  return (
                    <MenuItem key={val._id} value={JSON.stringify(val)}>
                      {employeeName}
                    </MenuItem>
                  );
                })}
              </Select>
              {!!formErrors.selectEmployeeError && (
                <FormHelperText>
                  {formErrors.selectEmployeeError}
                </FormHelperText>
              )}
            </FormControl>
          </div>
        )}
        <div
          className={
            "form-label-group mb-3 fullwidth col-6 " +
            (enableWaitingList ? "col-md-6" : "col-md-4")
          }
        >
          <DatePicker
            className="full-width"
            minDate={new Date()}
            selected={selectDateAndTime}
            disabled={lockFields}
            onChange={(date) => this.onDateChange(date)}
            value={this.state.selectDateAndTime}
            customInput={
              <TextField
                id="time"
                label={"Date" + (lockFields ? ` (${i18n.t("locked")})` : "")}
                value={this.state.newDate}
                className="full-width"
                readOnly={true}
                disabled={lockFields}
                helperText={formErrors.selectDateAndTimeError}
                error={!!formErrors.selectDateAndTimeError}
              />
            }
            placeholderText={i18n.t(
              "container.services_click_to_select_a_date"
            )}
          />
        </div>

        {this.renderTimeSelection()}
      </div>
    );
  };

  renderTableHead = () => {
    const { isGym } = this.state;
    return (
      <thead>
        <tr>
          <th scope="col">
            <span className="working-day-th-text">
              {i18n.t("container.advancedSearchDialog_name")}
            </span>
          </th>
          <th className="text-center" scope="col">
            <span className="working-day-th-text">Date</span>
          </th>
          <th className="text-center" scope="col">
            <span className="working-day-th-text">
              <Translation>{(t) => t("container.profile_service")}</Translation>
            </span>
          </th>
          <th className="text-center" scope="col">
            <span className="working-day-th-text">
              <Translation>{(t) => t("container.employee")}</Translation>
            </span>
          </th>
          <th className="text-center" scope="col">
            <span className="working-day-th-text">
              <Translation>
                {(t) => t("container.employe_custom_details")}
              </Translation>
            </span>
          </th>
          {!isGym && (
            <th className="text-center" scope="col">
              <span className="working-day-th-text">
                {i18n.t("container.profile_payment_method")}
              </span>
            </th>
          )}
          {!isGym && (
            <>
              <th className="text-center" scope="col">
                <span className="working-day-th-text">
                  <Translation>{(t) => t("by_price")}</Translation>
                </span>
              </th>
              <th className="text-center" scope="col">
                <span className="working-day-th-text">
                  <Translation>{(t) => t("tip")}</Translation>
                </span>
              </th>
            </>
          )}
          <th className="text-center" scope="col">
            <span className="working-day-th-text">
              {i18n.t("container.profile_status")}
            </span>
          </th>
          <th className="text-center" scope="col">
            <span className="working-day-th-text"></span>
          </th>
        </tr>
      </thead>
    );
  };

  renderTableBody = (bookings) => {
    const {
      searchText,
      bookingStatuses,
      filteredBookings,
      isGym,
      employeeMap,
    } = this.state;
    if (!bookings) return null;
    let renderBooking = bookings;
    if (filteredBookings) {
      renderBooking = filteredBookings;
    } else if (searchText)
      renderBooking = bookings?.filter((val) => {
        var ll = getNestedValue(val, ["userId", "userName"], "") || "";
        if (
          ll.toLowerCase().includes(searchText.toLowerCase()) ||
          val?.phoneNo?.includes(searchText) ||
          val?.shortenedId?.includes(searchText)
        )
          return true;
        return false;
      });
    return (
      <tbody>
        {renderBooking.map((val, ind) => {
          return (
            <tr key={ind} style={{ borderTop: "3px solid #F7F7F7" }}>
              <td>
                {val && val.userId && (
                  <span className="working-days-name">
                    {getNestedValue(val.userId, "userName")
                      ? val.userId.userName
                      : ""}
                  </span>
                )}
              </td>
              <td style={{ textAlign: "center" }}>
                <span className="working-days-name">
                  {" "}
                  {getNestedValue(val, ["services", 0, "date"], "")}{" "}
                  {getNestedValue(val, ["services", 0, "time"], "")}{" "}
                </span>
              </td>
              <td style={{ textAlign: "center" }}>
                <span className="working-days-name">
                  {" "}
                  {getNestedValue(
                    val,
                    ["services", 0, "serviceId", "name"],
                    ""
                  )}{" "}
                </span>
              </td>
              <td style={{ textAlign: "center" }}>
                <span className="working-days-name">
                  {" "}
                  {getNestedValue(
                    employeeMap,
                    getNestedValue(val, ["services", 0, "employeeId"]),
                    ""
                  )}{" "}
                </span>
              </td>
              <td style={{ textAlign: "center" }}>
                <span>
                  <div className="working-days-name"> {val.phoneNo || ""} </div>
                  <div className="working-days-name"> {val.email || ""} </div>
                </span>
              </td>
              {!isGym && (
                <td className="text-center">
                  <span className="working-days-name">
                    {val.paymentMethod || ""}
                  </span>
                </td>
              )}
              {!isGym && (
                <>
                  <td className="text-center">
                    {val && (
                      <span className="working-days-name">
                        {val.totalAmount ? `$ ${val.totalAmount}` : "$ 0"}
                      </span>
                    )}
                  </td>
                  <td className="text-center">
                    {val && (
                      <span className="working-days-name">
                        {val.tip ? `$ ${val.tip}` : "$ 0"}
                      </span>
                    )}
                  </td>
                </>
              )}
              <td className="text-center">
                <span
                  className="working-days-name aBold"
                  style={bookingStatuses[val.status - 1].styles}
                >
                  {bookingStatuses[val.status - 1].text}
                </span>
              </td>
              <td
                className="text-center"
                onClick={() =>
                  this.setState({
                    showBookingModal: true,
                    selectBooking: val,
                    selectBookingAmount: val.totalAmount,
                    paymentMethod: val.paymentMethod,
                    bookingStaus: val.status,
                    estimateDuration: val.services[0].serviceId.duration,
                    notes: val.notes,
                    tip: val.tip,
                  })
                }
              >
                <img
                  src={Images.saloon_working_hour_edit}
                  style={{ width: 18 }}
                  className="working-days-name"
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  };

  renderEditAppointment = () => {
    const {
      employees,
      allServices = [],
      start,
      employeeColorMap = {},
      timeSlotLength,
      showBookingModal,
      bookingStatuses,
      selectBooking = {},
      employeeMap,
      isGym,
      access_token,
      bookings,
      companyTaxes,
      selectBookingAmount,
      tip,
      showInsertPayment,
      paymentCompleteCallback,
    } = this.state;
    if (showBookingModal) {
      return (
        <AppointmentModal
          showInsertPayment={showInsertPayment}
          paymentCompleteCallback={paymentCompleteCallback}
          setData={({ cartId, discount }) => {
            if (
              typeof selectBooking.index === "number" &&
              bookings[selectBooking.index]
            ) {
              if (discount || typeof discount == "number") {
                bookings[selectBooking.index].discount = discount;
                this.setState({
                  selectBooking: { ...selectBooking, discount },
                  bookings,
                });
              }
              if (cartId) {
                bookings[selectBooking.index].cartId = cartId;
                this.setState({
                  selectBooking: { ...selectBooking, cartId },
                  bookings,
                });
              }
            }
          }}
          open={showBookingModal}
          selectBooking={selectBooking}
          employeeMap={employeeMap}
          isGym={isGym}
          isSalon={true}
          handleDrawerClose={(ev) => {
            this.setState({ showBookingModal: false });
          }}
          statusButtonClicked={(status, additional) => {
            const sendUpdate = (result, append = {}) => {
              if (result.value) {
                this.setState({ bookingStaus: status }, () => {
                  this.handleUpdate({ ...append });
                });
              } else {
                this.setState(
                  {
                    bookingStaus: status,
                  },
                  this.handleUpdate
                );
              }
            };
            if (status == 3) {
              return sendUpdate({ value: true }, additional);
            }
            if (status != 4) {
              Swal.fire({
                title: i18n.t("change_status"),
                text: `${i18n.t("confirm_status_change")} ${i18n.t(
                  statusMap[status]
                )}`,
                showCancelButton: true,
                cancelButtonText: i18n.t("no"),
                confirmButtonText: i18n.t("yes"),
              }).then((result) => {
                if (result.value) {
                  if (status == 7) {
                    status = 3;
                    const price = getNestedValue(
                      selectBooking,
                      ["totalAmount"],
                      ""
                    );
                    const noShowFeePercentage = getNestedValue(
                      selectBooking,
                      ["companyId", "noShowFeePercentage"],
                      0
                    );
                    const noShowFee = (price * noShowFeePercentage) / 100;

                    if (noShowFee) {
                      Swal.fire({
                        title: i18n.t("charge_no_show_fee"),
                        text: `$ ${noShowFee.toFixed(2)}`,
                        showCancelButton: true,
                        cancelButtonText: i18n.t("no"),
                        confirmButtonText: i18n.t("yes"),
                      }).then((result) => {
                        sendUpdate(result, { isNoShow: result.value });
                      });
                    } else {
                      sendUpdate({ value: false });
                    }
                  } else {
                    sendUpdate({ value: false });
                  }
                }
              });
            } else {
              this.setState(
                {
                  bookingStaus: status,
                },
                this.handleUpdate
              );
            }
          }}
          onProductChange={(e, index) => {
            this.setState({ isloading: true });
            updateCartProduct(
              {
                productId: selectBooking.cartId.products[index]._id,
                cartId: selectBooking.cartId._id,
              },
              { quantity: e },
              access_token
            )
              .then(({ data }) => {
                if (data.success) {
                  selectBooking.cartId.products[index].quantity =
                    data.data.quantity;
                  if (typeof selectBooking.index === "number") {
                    bookings[selectBooking.index] = selectBooking;
                  }
                  this.setState({
                    selectBooking: { ...selectBooking },
                    bookings,
                  });
                } else {
                  if (data.code) {
                    ErrorHelper.handleErrors(
                      i18n.t(data.code, { ...data.data }),
                      true
                    );
                  } else {
                    ErrorHelper.handleErrors("Failed to Add Item", true);
                  }
                }
              })
              .finally(() => {
                this.setState({ isloading: false });
              });
          }}
          onProductDelete={(index) => {
            this.setState({ isloading: true });
            removeProduct(
              {
                productId: selectBooking.cartId.products[index]._id,
                cartId: selectBooking.cartId._id,
              },
              access_token
            )
              .then(({ data }) => {
                if (data.success) {
                  if (
                    typeof selectBooking.index === "number" &&
                    bookings[selectBooking.index]
                  ) {
                    bookings[selectBooking.index].cartId = data.data;
                  }
                  this.setState({
                    selectBooking: { ...selectBooking, cartId: data.data },
                    bookings,
                  });
                }
              })
              .finally(() => {
                this.setState({ isloading: false });
              });
          }}
          onServiceDelete={(index) => {
            this.setState({ isloading: true });
            removeExtra(
              {
                extraId: selectBooking.extras[index]._id,
                bookingId: selectBooking._id,
              },
              access_token
            )
              .then(({ data }) => {
                if (data.success) {
                  if (
                    typeof selectBooking.index === "number" &&
                    bookings[selectBooking.index]
                  ) {
                    bookings[selectBooking.index].extras = data.data.extras;
                  }
                  this.setState({
                    selectBooking: {
                      ...selectBooking,
                      extras: data.data.extras,
                    },
                    bookings,
                  });
                }
              })
              .finally(() => {
                this.setState({ isloading: false });
              });
          }}
          onNotesClick={() => this.handleNotes()}
          onModifyClick={() => this.handleEditDetails()}
          onAddClick={() => this.handleAddProductsServices()}
          companyTaxes={companyTaxes}
          toggleDepositRequired={this.toggleDepositRequired}
          toggleTaxes={this.toggleTaxes}
          onChangeTip={this.onChangeTip}
        ></AppointmentModal>
      );
    } else {
      return null;
    }
  };

  genericUpdate = async (body) => {
    const { selectBooking, access_token } = this.state;
    this.setState({
      isloading: true,
    });
    let { bookings } = this.state;
    const services = [
      {
        serviceId: selectBooking.services[0].serviceId._id,
        categoryId: selectBooking.services[0].categoryId._id,
        date: selectBooking.services[0].date,
        time: selectBooking.services[0].time,
        employeeId: selectBooking.services[0].employeeId,
      },
    ];
    const { data } = await editBooking(
      {
        bookingId: selectBooking._id,
        status: selectBooking.status,
        services,
        ...body,
      },
      access_token
    );
    let newSelectBooking = selectBooking;
    if (data.success) {
      const newBooking = data.data;
      newBooking.services = newBooking.services.map((service) => {
        return {
          ...service,
          employeeId: service.employeeId._id,
        };
      });
      newSelectBooking = { ...newBooking, index: selectBooking.index };
      if (typeof selectBooking.index === "number") {
        bookings[selectBooking.index] = newSelectBooking;
      }
      this.handleClosePopup();
      this.setState({
        isloading: false,
        bookings: [...bookings],
        selectBooking: newSelectBooking,
        editDetails: false,
        ...body,
      });
    }
  };

  onChangeTip = (tip) => {
    const { tip: oldTip } = this.state;
    if (oldTip != tip) {
      this.genericUpdate({ tip });
    }
  };

  toggleDepositRequired = (depositRequired) => {
    this.genericUpdate({ depositRequired });
  };

  toggleTaxes = (includeTaxes) => {
    this.genericUpdate({ includeTaxes });
  };

  getCompanyEmployeeUnavaiabilities = () => {
    const { access_token, rawEmployees = [] } = this.state;
    const employeeIdCompanyEmployeeMap = {};
    rawEmployees.map((element) => {
      employeeIdCompanyEmployeeMap[element.employeeId._id] = element;
    });
    const dates = this.generateDates();
    getCompanyEmployeeUnavaiabilities(
      { startDate: dates[0], endDate: dates[dates.length - 1] },
      access_token
    ).then((data) => {
      let unavailabilities = [];
      data = data.map((element) => {
        const { date, employeeId } = element._id;
        if (employeeIdCompanyEmployeeMap[employeeId]?.displayWorkingHours) {
          const timeMap = {};
          element.entries.map((entry) => {
            timeMap[entry.time] = true;
          });
          let keys = Object.keys(timeMap).sort();
          let key = keys[0];
          while (keys.length) {
            let dateTime = moment(key, "HH:mm");
            let previous = dateTime.format("HH:mm");

            while (timeMap[dateTime.format("HH:mm")]) {
              keys.splice(0, 1);
              dateTime.add(30, "m");
              previous = dateTime.format("HH:mm");
            }
            unavailabilities.push({
              start: moment(`${date} ${key}`, "MM-DD-YYYY HH:mmm").toDate(),
              end: moment(`${date} ${previous}`, "MM-DD-YYYY HH:mmm").toDate(),
              date,
              employeeId,
              allDay: false,
              type: "unavailability",
              className: "unavailability-event",
            });
            key = keys[0];
          }
        }
      });
      const workingDayUnavailabilities = [];
      dates.map((date) => {
        rawEmployees.map((employee) => {
          if (employee.displayWorkingHours) {
            const momentedDate = moment(date, "MM-DD-YYYY");
            const workingDay = employee.weekPlans[momentedDate.day()];
            if (workingDay?.availableStatus) {
              workingDayUnavailabilities.push({
                start: moment(`${date} 00:00`, "MM-DD-YYYY HH:mmm").toDate(),
                end: moment(
                  `${date} ${workingDay.checkIn}`,
                  "MM-DD-YYYY HH:mmm"
                ).toDate(),
                date,
                employeeId: employee.employeeId._id,
                allDay: false,
                type: "unavailability",
                className: "unavailability-event un",
              });
              workingDayUnavailabilities.push({
                start: moment(
                  `${date} ${workingDay.checkOut}`,
                  "MM-DD-YYYY HH:mmm"
                ).toDate(),
                end: moment(`${date} 23:59`, "MM-DD-YYYY HH:mmm").toDate(),
                date,
                employeeId: employee.employeeId._id,
                allDay: false,
                type: "unavailability",
                className: "unavailability-event",
              });
            } else {
              workingDayUnavailabilities.push({
                start: moment(`${date} 00:00`, "MM-DD-YYYY HH:mmm").toDate(),
                end: moment(`${date} 23:59`, "MM-DD-YYYY HH:mmm").toDate(),
                date,
                employeeId: employee.employeeId._id,
                allDay: false,
                type: "unavailability",
                className: "unavailability-event",
              });
            }
          }
        });
      });
      this.setState({ unavailabilities, workingDayUnavailabilities });
    });
  };

  render() {
    const {
      bookings,
      access_token,
      selectBooking,
      displayCalendar,
      showPopUp,
    } = this.state;
    return (
      <div>
        {this.renderLoader()}
        <div className="content-container ">
          <div className="row mx-0">
            {showPopUp && this.renderPopup()}
            {displayCalendar
              ? this.renderAppointmentCalendar(bookings ? bookings : [])
              : this.renderAppointmentTable(bookings ? bookings : [])}
            {this.renderEditAppointment()}
            {this.renderBookingPopup()}
            {this.renderAdvancedSearchPopup()}
            {this.renderFOBs()}
            {/* {this.renderPricingCard()} */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  booking: state.activeBooking.data,
  getAvailableTimeslots: state.getAvailableTimeslots,
  getGymTimeslots: state.getGymTimeslots,
  transactions: state.bookingTransaction.data,
});

const action = {
  SaloonAppointment,
  get_available_timeslots,
  get_gym_timeslots,
  clear,
  createTransactions,
};

export default connect(mapStateToProps, action)(SalonAppointment);
