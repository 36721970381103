import { connect } from "react-redux";
import React, { Component } from "react";
import i18n from "../../i18n";

import { request as saloon_user_id } from "../../redux/actions/Saloon/SaloonByUserId";
import { request as get_notifications } from "../../redux/actions/GetNotifications";
import { DotsLoader, Navbar, Footer } from "../../components";
import { SuccessHelper, ErrorHelper } from "../../helpers";
import { Translation } from "react-i18next";
import { Images } from "../../theme";
import { partnerLogin } from "../../config/simpleApiCalls";
import { set } from "../../redux/actions/User";
import TextField from "@material-ui/core/TextField";

import "./styles.css";

class SaloonLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      handle: "",
      username: "",
      password: "",
      isloading: false,
      access_token: "",
      messaging_token: "",
      platform_name: "",
      formErrors: {
        emailError: "",
        usernameError: "",
        passwordError: "",
      },
    };

    i18n.changeLanguage(sessionStorage.getItem("language"));
  }

  componentDidMount() {
    const saloonData = JSON.parse(sessionStorage.getItem("saloon"));
    const companyData = JSON.parse(sessionStorage.getItem("company"));
    const messaging_token = sessionStorage.getItem("messaging_token");
    const platform_name = window.navigator.platform;
    this.setState({ messaging_token, platform_name });
    if (companyData && saloonData && saloonData.access_token) {
      this.handleNavigation("/saloon-dashboard");
    }
  }

  onChangeHandle = (text) => this.setState({ handle: text.target.value });
  onChangeUserName = (text) => this.setState({ username: text.target.value });
  onChangePassword = (text) => this.setState({ password: text.target.value });

  checkValidation() {
    const { username, handle, password } = this.state;
    const nameRegex =
      /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{2,}$/u;
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const telephoneRegex =
      /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/g;
    if (!handle) {
      this.setState({
        formErrors: {
          emailError: i18n.t("handle_required"),
        },
      });
      setTimeout(() => {
        this.setState({
          formErrors: {
            emailError: "",
          },
        });
      }, 8000);
    } else if (!handle.match(emailRegex) && !handle.match(telephoneRegex)) {
      this.setState({
        formErrors: {
          emailError: i18n.t("invalid_login_handle"),
        },
      });
      setTimeout(() => {
        this.setState({
          formErrors: {
            emailError: "",
          },
        });
      }, 8000);
    } else if (!password) {
      this.setState({
        formErrors: {
          passwordError: "Password is required!",
        },
      });
      setTimeout(() => {
        this.setState({
          formErrors: {
            passwordError: "",
          },
        });
      }, 6000);
    } else if (password.length < 6) {
      this.setState({
        formErrors: {
          passwordError: "Password must be contain 6 characters!",
        },
      });
      setTimeout(() => {
        this.setState({
          formErrors: {
            passwordError: "",
          },
        });
      }, 6000);
    } else {
      this.handleLogin();
    }
  }

  getSaloonByUserId = () => {
    const { access_token } = this.state;
    const payload = {
      access_token,
    };
    this.props.saloon_user_id(payload);
  };

  handleLogin = () => {
    this.setState({ isloading: true });
    const { handle, password, messaging_token, platform_name } = this.state;
    const payload = {
      handle,
      password,
      gcm_id: messaging_token ? messaging_token : "123",
      platform: platform_name,
    };
    partnerLogin(payload).then(({ data, success, msg }) => {
      if (success) {
        const { isCompanyProfile } = data;
        if (data.role) {
          console.log("data", data);
          if (data.role.includes("3")) {
            if (isCompanyProfile) {
              this.props.set({
                saloon: JSON.stringify(data),
                employee: JSON.stringify(data),
                company: JSON.stringify(data.company),
              });
              SuccessHelper.handleSuccess(
                "You have successfully logged in.",
                true
              );

              let companyKey = localStorage.getItem("isAvailable");

              !data.company.isFreeTrial
                ? this.handleNavigation("/saloon-dashboard")
                : this.handleNavigation("/saloon-dashboard");
              this.setState({
                isloading: false,
                handle: "",
                password: "",
                access_token: data.access_token,
              });
            } else {
              this.props.set({
                saloon: JSON.stringify(data),
                employee: JSON.stringify(data),
                // company: JSON.stringify(data.company)
              });
              this.handleNavigationTo("/saloon-register", data);
              // redirec
            }
          } else if (data.role.includes("4")) {
            const toSet = {
              employee: JSON.stringify({ ...data }),
            };
            if (data.role.includes("6") || data.role.includes("7")) {
              toSet.saloon = JSON.stringify({ ...data });
              toSet.company = JSON.stringify(data.company);
            }
            this.props.set(toSet);
            if (data.isEmployeeProfile) {
              SuccessHelper.handleSuccess(
                "You have successfully logged in.",
                true
              );
              this.handleNavigation("/employee-dashboard");
            } else {
              SuccessHelper.handleSuccess(
                i18n.t("container.employe_working_hours_message"),
                true,
                i18n.t("container.employe_login_successfully")
              );
              this.handleNavigation("/employee-add-working-hour");
            }
            this.setState({
              isloading: false,
              handle: "",
              password: "",
              access_token: data.access_token,
            });
          } else {
            ErrorHelper.handleErrors("Access Denied", true);
            this.setState({ isloading: false });
          }
          this.props.set({
            access_token: data.access_token,
          });
          setTimeout(() => {
            this.props.get_notifications();
          }, 100);
        } else {
          ErrorHelper.handleErrors("Access Denied", true);
          this.setState({ isloading: false });
        }
      } else {
        ErrorHelper.handleErrors(msg, true);
        this.setState({ isloading: false });
      }
    });
  };
  handleNavigationTo = (route, user) => {
    this.props.history.push({
      pathname: route,
      state: { access_token: user.access_token, continueRegistration: true },
    });
  };

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  renderSocialbutton = () => {
    return (
      <div>
        <div
          className="row px-3"
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <span className=" mb-2 google-btn">
            <img className="ml-0 google-btn-img" src={Images.google_signin} />
            <span className="google-btn-text ">Sign In With Google</span>
          </span>
        </div>
        <div
          className="row px-3"
          style={{ justifyContent: "center", alignItems: "center" }}
        >
          <span className=" mb-2 facebook-btn">
            <img className="facebook-btn-img" src={Images.facebook_signin} />
            <span className="facebook-btn-text">Facebook</span>
          </span>
        </div>
        <div
          className="row px-3"
          style={{ justifyContent: "center", alignItems: "center" }}
        >
          <span className="  py-1 py-md-2 py-lg-2  email-btn">
            <img className="email-btn-img" src={Images.mail_signin} />
            <span className="email-btn-text">
              {i18n.t("container.profile_email")}
            </span>
          </span>
        </div>
      </div>
    );
  };

  renderEmail = () => {
    const { formErrors } = this.state;
    return (
      <div className="col-xs-12 col-sm-12">
        <TextField
          id="inputusername"
          label={i18n.t("container.handle")}
          className="w-100 mt-1"
          onChange={(text) => this.onChangeHandle(text)}
          value={this.state.handle}
          error={!!formErrors.emailError}
          helperText={formErrors.emailError}
        />
      </div>
    );
  };

  renderPassword = () => {
    const { formErrors } = this.state;
    return (
      <div className="col-xs-12 col-sm-12">
        <TextField
          id="inputPassword"
          // label={i18n.t("container.handle")}
          className="form-control mt-1"
          label={i18n.t("password")}
          placeholder={i18n.t("enter_password_placeholder")}
          onChange={(text) => this.onChangePassword(text)}
          value={this.state.password}
          type="password"
          error={!!formErrors.passwordError}
          helperText={formErrors.passwordError}
        />
      </div>
    );
  };

  renderLoginBtn = () => {
    return (
      <button
        className="btn btn-lg"
        type="submit"
        onClick={() => this.checkValidation()}
      >
        {i18n.t("login_now")}
      </button>
    );
  };

  renderForgetBtn = () => {
    return (
      <div className="form-signin  px-8">
        <span
          style={{
            color: "#ff3600",
            fontWeight: "700",
            cursor: "pointer",
            marginBottom: "20px",
            marginTop: "20px",
            display: "inherit",
          }}
          onClick={() => this.handleNavigation("/saloon-forgot-password")}
        >
          {i18n.t("forgot_password")}
        </span>
      </div>
    );
  };

  renderLoginForm = () => {
    return (
      <form action="javascript:void(0)" className="form-signin  px-4">
        {this.renderEmail()}
        {this.renderPassword()}
        {this.renderForgetBtn()}
        {this.renderLoginBtn()}
        <p
          style={{
            fontSize: "1rem",
            color: "#3b3b3b",
            marginTop: "1rem",
          }}
        >
          {i18n.t("login_no_account")} &nbsp;
          <span
            style={{
              color: "#ff3600",
              fontWeight: "700",
              cursor: "pointer",
              marginleft: "10px",
            }}
            onClick={() => {
              this.setState({ showRegister: true });
            }}
            // onClick={() => this.handleNavigation("/saloon-register")}
          >
            {i18n.t("sign_up")}
          </span>
        </p>
      </form>
    );
  };

  renderRegisterSelection = () => {
    return (
      <div className="row justify-content-center m-3">
        <div
          className="col-12 mx-3 p-2 text-center selection-box"
          style={{ backgroundColor: "orange" }}
          onClick={() => this.handleNavigation("/saloon-register")}
        >
          <h4>
            <Translation>{(t) => t("owner_registration")}</Translation>
          </h4>
        </div>
        <div className="col-12 my-1 text-center">
          <h5>
            <Translation>{(t) => t("or")}</Translation>
          </h5>
        </div>
        <div
          className="col-12 mx-3 p-2 text-center selection-box"
          style={{ backgroundColor: "red" }}
          onClick={() => this.handleNavigation("/employee-register")}
        >
          <h4>
            <Translation>{(t) => t("employeee_registration")}</Translation>
          </h4>
        </div>
      </div>
    );
  };

  renderLoginCard = () => {
    const { showRegister } = this.state;
    return (
      <div className="card card-signin my-5">
        <div className="card-body px-0">
          <h5 className="card-title text-center">
            {i18n.t("sign_in_as_salon")}
          </h5>
          {/* {this.renderSocialbutton()} */}
          <div className="row">
            <hr className="my-4 mx-3 " style={{ width: "100%" }} />
          </div>
          {showRegister
            ? this.renderRegisterSelection()
            : this.renderLoginForm()}
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="container-fluid px-0">
        {this.renderLoader()}
        <Navbar {...this.props} isBackgroundColor={true} />
        <div className="container" style={{ backgroundColor: "#F8FDFF" }}>
          <div className="row mt-5">
            <div className="col-sm-9 col-md-7 col-lg-5 mx-auto mt-5">
              {this.renderLoginCard()}
            </div>
          </div>
        </div>
        <Footer {...this.props} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  login: state.saloonLogin,
  saloonUserId: state.saloonUserId,
});

const action = { saloon_user_id, get_notifications, set };

export default connect(mapStateToProps, action)(SaloonLogin);
