import { connect } from "react-redux";
import React, { Component } from "react";
import { Translation } from "react-i18next";
import i18n from "../../../i18n";
import { Images } from "../../../theme";
import { DotsLoader } from "../../../components";
import {
  employeeEditProfile,
  editSaloonSchedule,
  getEmployeeDetails,
  getCompanyEmployeeInfo,
  getServices,
} from "../../../config/simpleApiCalls";
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import Swal from "sweetalert2";

import "./styles.css";
import Sytles from "./sytles";

const weekNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednessday",
  "Thursday",
  "Friday",
  "Saturday",
];
class AddWorkingHours extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employeesWorkingHoursData: [
        { dayName: i18n.t("Sunday"), dayOfWeek: 1, availableStatus: 0 },
        { dayName: "Monday", dayOfWeek: 2, availableStatus: 0 },
        { dayName: "Tuesday", dayOfWeek: 3, availableStatus: 0 },
        { dayName: "Wednesday", dayOfWeek: 4, availableStatus: 0 },
        { dayName: "Thursday", dayOfWeek: 5, availableStatus: 0 },
        { dayName: "Friday", dayOfWeek: 6, availableStatus: 0 },
        { dayName: "Saturday", dayOfWeek: 7, availableStatus: 0 },
      ],
      // workingDays: [
      //     { day: 'Mon', isSelected: true },
      //     { day: 'Tue', isSelected: true },
      //     { day: 'Wed', isSelected: true },
      //     { day: 'Thu', isSelected: true },
      //     { day: 'Fri', isSelected: true },
      //     { day: 'Sat', isSelected: false },
      //     { day: 'Sun', isSelected: false },
      // ],
      serviceList: [],
      isVerified: false,
      employeeServicesList: [],
      selectedEmployees: [],
      employeesWorkingHoursError: false,
      hasSchedule: false,
    };
  }

  componentDidMount = async () => {
    const employeeData = await JSON.parse(sessionStorage.getItem("employee"));
    if (employeeData) {
      this.setState({ access_token: employeeData.access_token }, () => {
        this.getEmployeeData();
        this.getAllServices();
      });
    }
  };

  getAllServices = () => {
    this.setState({ isloading: true });
    getServices()
      .then((res) => {
        if (res.data.success) {
          this.setState({ isloading: false, serviceList: res.data.data });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
      });
  };

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  onChangeFacebook = (text) =>
    this.setState({ facebookLink: text.target.value });

  onChangeCheckbox = (e, ind) => {
    const { employeesWorkingHoursData } = this.state;
    if (e.target.checked) {
      employeesWorkingHoursData[ind].availableStatus = 1;
      this.setState({ employeesWorkingHoursData });
    } else {
      employeesWorkingHoursData[ind].availableStatus = 0;
      this.setState({ employeesWorkingHoursData });
    }
  };

  onChangeCheckIn = (text, ind) => {
    const { employeesWorkingHoursData } = this.state;
    if (text.target.value) {
      employeesWorkingHoursData[ind].checkIn = text.target.value;
    }
    this.setState({ employeesWorkingHoursData });
  };

  onChangeCheckOut = (text, ind) => {
    const { employeesWorkingHoursData } = this.state;
    if (text.target.value) {
      employeesWorkingHoursData[ind].checkOut = text.target.value;
    }

    this.setState({ employeesWorkingHoursData });
  };

  checkValidation = () => {
    const { employeesWorkingHoursData, isVerified, hasSchedule } = this.state;
    for (let i = 0; i < employeesWorkingHoursData.length; i++) {
      if (employeesWorkingHoursData[i].availableStatus === 1) {
        if (!employeesWorkingHoursData[i].checkIn) {
          return this.setState({
            isVerified: false,
            employeesWorkingHoursError: true,
          });
        } else if (!employeesWorkingHoursData[i].checkOut) {
          return this.setState({
            isVerified: false,
            employeesWorkingHoursError: true,
          });
        } else {
          this.setState({
            isVerified: true,
            employeesWorkingHoursError: false,
          });
        }
      }
    }
    if (isVerified && !hasSchedule) {
      this.handleCreateSaloonSchedule();
    } else if (isVerified && hasSchedule) {
      this.handleEditSaloonSchedule();
    }
  };

  handleCreateSaloonSchedule = () => {
    this.setState({ isloading: true });
    const { employeesWorkingHoursData, access_token, serviceList } = this.state;
    const employeesWorkingHoursSorted = employeesWorkingHoursData.map(function (
      obj
    ) {
      delete obj["dayName"]; // Delete old key
      delete obj["_id"]; // Delete old key
      return obj;
    });
    let serviceListSorted = [];
    for (let i = 0; i < serviceList.length; i++) {
      if (serviceList[i].isSelected) {
        let myService = {
          charges: 1,
          serviceId: serviceList[i]._id,
        };
        serviceListSorted.push(myService);
      }
    }
    const payload = {
      services: serviceListSorted,
      weekPlans: employeesWorkingHoursSorted,
    };
    employeeEditProfile(payload, access_token)
      .then((res) => {
        if (res.data.success) {
          SuccessHelper.handleSuccess("Saved Successfully", true);
          this.getEmployeeData();
        } else {
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: error,
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#ff3600",
          cancelButtonColor: "#354e68",
          confirmButtonText: "Yes, delete it!",
        });
      });
  };

  gettingCompanyEmployeeInfo = (e) => {
    let allServices = [];

    const { access_token } = this.state;

    this.setState({ showAddEmployeePopup: true });

    const payload = {};

    getCompanyEmployeeInfo(payload, access_token)
      .then((res) => {
        if (res.data.success) {
          res.data.details.services.map((v) => {
            res.data.services.map((services) => {
              if (v.serviceId._id === services._id) allServices.push(services);
            });
          });

          for (let i = 0; i < allServices.length; i++) {
            allServices[i].isSelected = allServices[i]["isActive"];
          }

          // for (let i = 0; i < res.data.result.companyServices.length; i++) {
          //   res.data.result.companyServices[i].isSelected =
          //     res.data.result.companyServices[i]["isActive"];
          // }

          this.setState({
            isloading: false,
            employeesWorkingHoursData: res.data.result.weekPlans,
            serviceList: this.state.allServices,
            companyServices: allServices,
            selectedEmployeeId: e.employee._id,
            companyServices: res.data.result.companyServices,
          });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
      });
  };

  getEmployeeData = () => {
    const { access_token } = this.state;
    this.setState({ isloading: true });

    let allServices = [];

    getEmployeeDetails(access_token)
      .then((res) => {
        // if (res.data.success) {
        //   console.log("EMPLOYEEE DAATAAAA", res.data);
        //   for (let i = 0; i < res.data.data.details.weekPlans.length; i++) {
        //     res.data.data.details.weekPlans[i].dayName = weekNames[i];
        //   }
        //   this.setState({
        //     isloading: false,
        //     employeesWorkingHoursData: res.data.data.details.weekPlans,
        //     serviceList: res.data.data.services,
        //     employeeServicesList: res.data.data.details.services,
        //   });
        //   this.getSpecificData();
        // }

        // })

        //
        const serviceList = res.data.data.services;
        if (res.data.success) {
          res.data.data.details.services.forEach((value) => {
            res.data.data.services.forEach((companyServices, index) => {
              if (companyServices._id === value.serviceId._id) {
                serviceList[index].charges = value.charges;
              }
            });
          });

          this.setState({
            isloading: false,
            serviceList,
            employeesWorkingHoursData: res.data.data.details.weekPlans,
          });

          res.data.data.details.services.map((v) => {
            res.data.data.services.map((services) => {
              if (v.serviceId._id === services._id) allServices.push(services);
            });
          });

          for (let i = 0; i < res.data.data.details.weekPlans.length; i++) {
            res.data.data.details.weekPlans[i].dayName = weekNames[i];
          }

          for (let i = 0; i < allServices.length; i++) {
            allServices[i].isSelected = true;
          }
        }

        this.setState({
          isloading: false,
          serviceList: this.state.serviceList,
          employeesWorkingHoursData: res.data.data.details.weekPlans,
        });
      })

      .catch((error) => {
        this.setState({ isloading: false });
        console.log(error, "errorrrrrrrrrrrrrrrrrrr");
      });
  };

  getSpecificData = () => {
    const { serviceList, employeeServicesList, selectedEmployees } = this.state;
    let followingIds = employeeServicesList.map((group) => group.serviceId._id);
    this.setState({
      selectedEmployees: selectedEmployees.push(
        serviceList.map((group) => ({
          ...group,
          following: followingIds.includes(group._id),
        }))
      ),
    });
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  renderSaloonDashboardHeading = () => {
    return (
      <div className="col-12 pl-3 pt-3 ">
        <div className="row">
          <div className="col-12 row justify-space-between p-0 full-width mx-0">
            <h1 className="saloon-dashboard-heading col-6 col-xs-8 ">
              <Translation>
                {(t) => t("container.add_working_hours")}
              </Translation>{" "}
              &amp; <Translation>{(t) => t("container.hours")}</Translation>
            </h1>
            <div
              className="mt-2 col-6 col-xs-4  d-flex"
              style={{ justifyContent: "flex-end" }}
            >
              <button
                className="btn btn-light btn-sm"
                type="submit"
                onClick={() =>
                  this.props.history.push("/employee-manage-unavailabilities")
                }
              >
                {i18n.t("container.employe_dashboard_manage_unavailabilities")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  onChangeServiceCheckbox = (e, ind) => {
    const { serviceList } = this.state;

    if (e.target.checked) {
      serviceList[ind].isSelected = true;
      this.setState({ serviceList });
    } else {
      serviceList[ind].isSelected = false;
      this.setState({ serviceList });
    }
  };

  renderEditWorkingHours = (ind, data) => {
    return (
      <div className="form-row working-days-table">
        <div className=" px-0 pr-md-3 d-flex align-items-center">
          <input
            value={data.checkIn && data.checkIn}
            type="time"
            className="form-control edit-employee-input"
            onChange={(text) => this.onChangeCheckIn(text, ind)}
          />
        </div>
        <div className="px-3 px-md-0 d-flex align-items-center">
          <span>to</span>
        </div>
        <div
          className="  px-0 pl-md-3 d-flex align-items-center"
          style={{ marginBottom: "0px !important" }}
        >
          <input
            value={data.checkOut && data.checkOut}
            type="time"
            className="form-control edit-employee-input"
            onChange={(text) => this.onChangeCheckOut(text, ind)}
          />
        </div>
      </div>
    );
  };

  renderServicesPrice = () => {
    return (
      <div className="">
        <div className=" px-0 pr-md-3 d-flex align-items-center">
          <input
            type="number"
            min="1.0"
            step="0.5"
            max="250000"
            className="form-control edit-employee-input"
            onChange={(text) => {}}
          />
        </div>
      </div>
    );
  };

  renderTableHead = () => {
    return (
      <thead>
        <tr>
          <th scope="col">
            <span className="working-day-th-text">
              <Translation>
                {(t) => t("container.add_employee_working_days")}
              </Translation>
            </span>
          </th>
          <th className="text-center" scope="col">
            <span className="working-day-th-text">
              <Translation>{(t) => t("container.hours")}</Translation>
            </span>
          </th>
          <th className="text-center" scope="col">
            <span className="working-day-th-text">
              <Translation>{(t) => t("container.availability")}</Translation>
            </span>
          </th>
          {/* <th scope="col"><span className="working-day-th-text ">Services/Speciality</span></th> */}
          {/* <th scope="col"><span className="working-day-th-text">Set Pricing</span></th> */}
        </tr>
      </thead>
    );
  };

  renderWorkingHourTableCard = () => {
    return (
      <div className="col-12 p-3">
        <div className="table-responsive">
          <table class="table table-borderless saloon-working-hour-table">
            {this.renderTableHead()}
            {this.renderTableBody()}
          </table>
        </div>
      </div>
    );
  };

  renderTableBody = () => {
    const { employeesWorkingHoursData } = this.state;
    return (
      <tbody>
        {employeesWorkingHoursData.map((val, ind) => {
          return (
            <tr key={ind} style={{ borderTop: "3px solid #F7F7F7" }}>
              <td>
                <span className="working-days-name">{val.dayName}</span>
              </td>
              <td style={{ display: "flex", justifyContent: "center" }}>
                {val.availableStatus === 1 &&
                  this.renderEditWorkingHours(ind, val)}
                {val.availableStatus === 0 && (
                  <button className="btn btn-light btn-sm" id="working-off-btn">
                    OFF
                  </button>
                )}
              </td>
              <td className="text-center">
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id={ind}
                    checked={val.availableStatus ? true : false}
                    onChange={(e) => this.onChangeCheckbox(e, ind)}
                  />
                  <label class="custom-control-label" for={ind}></label>
                </div>
              </td>
              {/* <td><span className="working-employee-timing">{val.ServicesAndSpeciality}</span></td> */}
              {/* <td style={{ display: 'flex', justifyContent: 'center' }}>
                            {val.Price &&
                                <span className="working-employee-timing">{this.renderServicesPrice()}</span>}
                            {!val.hours && <p></p>}
                        </td> */}
            </tr>
          );
        })}
      </tbody>
    );
  };

  ServicesSaveChanges = () => {
    const { employeesWorkingHoursError, hasSchedule } = this.state;
    return (
      <div
        className="col-12 px-0 px-md-3"
        style={{
          display: "flex",
          justifyContent: employeesWorkingHoursError
            ? "space-between"
            : "flex-end",
          alignItems: "center",
        }}
      >
        {employeesWorkingHoursError && (
          <span className="text-danger">
            <Translation>
              {(t) => t("container.working_hours_required")}
            </Translation>
          </span>
        )}
        <button
          onClick={() => this.checkValidation()}
          type="button"
          className="btn btn-light btn-sm mb-2"
          id="saloon-save-social-link"
        >
          {hasSchedule ? (
            <Translation>{(t) => t("container.update_schedule")}</Translation>
          ) : (
            <Translation>{(t) => t("container.save_changes")}</Translation>
          )}
        </button>
      </div>
    );
  };

  renderServicesTable = () => {
    let { selectedEmployees, serviceList, allServices } = this.state;
    return (
      <div className="col-12 px-3">
        <div className="table-responsive">
          <table class="table table-borderless saloon-working-hour-table">
            <thead>
              <tr>
                <th scope="col">
                  <span className="working-day-th-text">
                    <Translation>
                      {(t) => t("container.profile_service")}
                    </Translation>
                  </span>
                </th>
                <th className="text-center" scope="col"></th>
                <th className="text-center" scope="col">
                  <span className="working-day-th-text">
                    <Translation>{(t) => t("container_choose")}</Translation>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {serviceList &&
                serviceList.map((val, ind) => {
                  return (
                    <tr key={ind} style={{ borderTop: "3px solid #F7F7F7" }}>
                      <td>
                        <span className="working-days-name text-capitalize">
                          {val.name}
                        </span>
                      </td>
                      <td style={{ display: "flex", justifyContent: "center" }}>
                        {/* {val.isSelected && (
                          <div className=" px-0 pr-md-3 d-flex align-items-center">
                            <input
                              placeholder="Price"
                              value={val.charges}
                              type="number"
                              min="1.0"
                              step="0.5"
                              max="250000"
                              className="form-control edit-employee-input"
                              onChange={(text) => this.onChangePrice(text, ind)}
                            />
                          </div>
                        )} */}
                        {!val.isSelected && (
                          <button
                            className="btn btn-light btn-sm"
                            id="working-off-btn"
                          >
                            <Translation>
                              {(t) => t("container.not_selected")}
                            </Translation>
                          </button>
                        )}
                      </td>
                      <td className="text-center">
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id={val._id}
                            checked={val.isSelected}
                            onChange={(e) =>
                              this.onChangeServiceCheckbox(e, ind)
                            }
                          />
                          <label
                            class="custom-control-label"
                            for={val._id}
                          ></label>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.renderLoader()}
        <div className="content-container">
          <div className="row">
            {this.renderSaloonDashboardHeading()}
            {/* {this.renderEditWorkingDayCard()} */}
            {/* {this.renderEditWorkingHoursCard()} */}
            {/* {this.renderAddServicesCard()} */}
            {this.renderWorkingHourTableCard()}
            {this.renderServicesTable()}
            {this.ServicesSaveChanges()}
            {/* {this.renderPricingCard()} */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(AddWorkingHours);
