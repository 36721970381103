import { connect } from "react-redux";
import React, { Component } from "react";
import { Translation } from "react-i18next";
import i18n from "../../../i18n";
import { Images } from "../../../theme";
import { SidebarContent, Footer } from "../../../components/Saloon";
import { DotsLoader, Header } from "../../../components";
import { upsertTax } from "../../../config/simpleApiCalls";
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import NumberFormat from "react-number-format";

import "./styles.css";
import Sytles from "./sytles";

class AddTax extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      percentage: 0,
      isActive: true,

      isloading: false,
      formErrors: {
        nameError: "",
        emailError: "",
        telephoneError: "",
      },
    };
  }

  componentDidMount = async () => {
    const saloonData = await JSON.parse(sessionStorage.getItem("saloon"));
    if (saloonData) {
      this.setState({
        access_token: saloonData.access_token,
      });
    }
  };

  onSetSidebarOpen = (open) => {
    this.setState({ sidebarOpen: open });
  };

  checkValidation = () => {
    const { name, percentage } = this.state;

    if (!name) {
      this.setState({
        formErrors: {
          nameError: <Translation>{(t) => t("name_required")}</Translation>,
        },
      });
      setTimeout(() => {
        this.setState({
          formErrors: {
            nameError: "",
          },
        });
      }, 8000);
    } else if (!percentage) {
      this.setState({
        formErrors: {
          percentageError: (
            <Translation>{(t) => t("percentage_is_required")}</Translation>
          ),
        },
      });
      setTimeout(() => {
        this.setState({
          formErrors: {
            emailError: "",
          },
        });
      }, 6000);
    } else {
      this.handleSaloonCreateTax();
    }
  };

  handleSaloonCreateTax = () => {
    this.setState({ isloading: true });
    const { access_token, name, percentage, isActive } = this.state;
    const payload = {
      name,
      percentage,
      isActive,
    };

    upsertTax(payload, access_token)
      .then((res) => {
        if (res.data.success) {
          console.log(res.data);
          SuccessHelper.handleSuccess("Tax successfully added.", true);
          this.setState({
            isloading: false,
            name: "",
            percentage: 0,
            isActive: true,
          });
        }
        if (!res.data.success) {
          ErrorHelper.handleErrors(res.data.msg, true);
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        ErrorHelper.handleErrors("An error occured", true);
        this.setState({ isloading: false });
        console.log(error, "errorrrrrrrrrrrrrrrrrrr");
      });
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  renderSaloonDashboardHeading = () => {
    return (
      <div className="col-12 p-3">
        <h1 className="saloon-dashboard-heading">{i18n.t("add_tax")}:</h1>
        {/* <p className="saloon-dashboard-sub-heading">
          Lorem ipsum dolor sit amet, consectetur
        </p> */}
      </div>
    );
  };
  renderName = () => {
    const { formErrors, name } = this.state;
    return (
      <div className="form-label-group col-12 col-md-6 col-lg-4">
        <div>
          <Translation>{(t) => t("Name")}</Translation>
        </div>
        <input
          className="form-control px-4 px-md-4 px-lg-4"
          placeholder={i18n.t("Name")}
          onChange={(text) => {
            this.setState({ name: text.target.value.toUpperCase() });
          }}
          value={name}
        />
        {formErrors.nameError ? (
          <small className="form-text text-danger ml-3">
            {formErrors.nameError}
          </small>
        ) : null}
      </div>
    );
  };
  renderPercentage = () => {
    const { formErrors, percentage } = this.state;
    return (
      <div className="form-label-group col-12 col-md-6 col-lg-4">
        <div>
          <Translation>{(t) => t("percentage")}</Translation>
        </div>
        <input
          type="number"
          className="form-control px-4 px-md-4 px-lg-4"
          placeholder={i18n.t("percentage")}
          onChange={(text) => {
            this.setState({ percentage: text.target.value });
          }}
          onBlur={() => {
            const newPercentage = parseFloat(percentage);
            if (newPercentage < 0) {
              this.setState({ percentage: 0 });
            } else {
              this.setState({ percentage: newPercentage.toFixed(3) });
            }
          }}
          value={percentage}
        />
        {formErrors.percentageError ? (
          <small className="form-text text-danger ml-3">
            {formErrors.percentageError}
          </small>
        ) : null}
      </div>
    );
  };
  renderIsActive = () => {
    const { formErrors } = this.state;
    return (
      <div className="form-label-group col-12 col-md-6 col-lg-4">
        <div>
          <Translation>{(t) => t("isActive")}</Translation>
        </div>
        <select
          id="function-role"
          className="form-control"
          onChange={(e) => this.setState({ isActive: e.target.value })}
        >
          <option className="form-group" value={true} id={"Yes"}>
            Yes
          </option>
          <option className="form-group" value={false} id={"No"}>
            No
          </option>
        </select>
      </div>
    );
  };

  renderAddTaxForm = () => {
    const {
      serviceNameError,
      priceError,
      minimumTimeError,
      isGym,
    } = this.state;
    return (
      <div className="col-12 p-3">
        <div className="col-12 p-3 bg-white" style={{ borderRadius: "1rem" }}>
          <div class="row mb-3">
            {this.renderName()}
            {this.renderPercentage()}
            {this.renderIsActive()}
          </div>
          <div className="col-12 text-right">
            <button
              onClick={() => this.checkValidation()}
              type="button"
              className="btn btn-light btn-sm mb-2"
              id="saloon-edit-category-btn"
            >
              {i18n.t("add_tax")}
            </button>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.renderLoader()}
        <div className="container">
          <div className="row">
            {this.renderSaloonDashboardHeading()}
            {this.renderAddTaxForm()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(AddTax);
