import { connect } from "react-redux";
import React, { useEffect, useMemo, useRef, useState } from "react";
import i18n from "../../../i18n";
import BaseModal from "../../BaseModal";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import PrimaryButton from "../../Core/PrimaryButton";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import {
  getRequest as getClassesRequest,
  upsertRequest as upsertClassRequest,
} from "../../../redux/actions/Saloon/Class";
import {
  getRequest as getStudentRequest,
  setFinalizedRequest,
} from "../../../redux/actions/Saloon/Student";
import {
  addClassRequest,
  removeClassRequest,
  addStudentToClassRequest,
  addStudentGroupToClassRequest,
  removeStudentFromClassRequest,
} from "../../../redux/actions/Saloon/Semester";
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import DotsLoader from "../../DotsLoader";
import ClassUpsert from "../Class/ClassUpsert";
import { MenuItem } from "@material-ui/core";
import { InputAdornment } from "@material-ui/core";
import BasicTable from "../../Core/BasicTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Chip } from "@material-ui/core";
import CollapsibleTable from "../../CollapsibleTable";
import { Checkbox } from "@material-ui/core";
import StudentGrading from "./StudentGrading";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      className="light-border"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={2}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function ProgramUpsert({
  open,
  onClose,
  onSave,
  active = {},
  setActive,
  getClassesRequest,
  accessToken,
  upsertClassRequest,
  addClassRequest,
  removeClassRequest,
  getStudentRequest,
  addStudentToClassRequest,
  addStudentGroupToClassRequest,
  removeStudentFromClassRequest,
  setFinalizedRequest,
  companyId,
  classes,
  students,
  studentGroups,
}) {
  const form = useRef();
  const newClassForm = useRef();
  const [data, setData] = useState({});
  const [addClassOpen, setAddClassOpen] = useState(false);
  const [gradingProps, setGradingProps] = useState(null);

  const [loading, setLoading] = useState(false);
  const [activeClass, setActiveClass] = useState({});
  const [newCourseClass, setNewCourseClass] = useState({ numberOfTimes: 1 });

  const init = (data) => {
    setData({ ...data });
    setTab(0);
    getClassesRequest({ access_token: accessToken });
  };
  const onSaveClass = (params) => {
    setLoading(true);
    upsertClassRequest(
      { access_token: accessToken, companyId, ...params },
      {
        success: (data) => {
          SuccessHelper.handleSuccess(
            i18n.t(data.id ? "successfully_updated" : "successfully_added"),
            true
          );
          setAddClassOpen(false);
          setLoading(false);
        },
        failure: () => {
          ErrorHelper.handleErrors(
            i18n.t(data.id ? "failed_to_update" : "failed_to_add"),
            true
          );
          setLoading(false);
        },
      }
    );
  };
  useEffect(() => {
    init(active);
  }, []);
  useEffect(() => {
    if (active._id != data._id) {
      init(active);
    }
  }, [active]);
  const handleChange = (key, value) => {
    data[key] = value;
    setData({ ...data });
  };
  const handleSubmit = () => {
    onSave(data);
  };
  const handleClassAdd = () => {
    addClassRequest(
      {
        _id: active._id,
        classId: newCourseClass,
        access_token: accessToken,
      },
      {
        success: (data) => {
          SuccessHelper.handleSuccess(i18n.t("successfully_added"), true);
          setData({ ...data.data });
          setActive(data.data);
          setNewCourseClass();
        },
        failure: () => {
          ErrorHelper.handleErrors(i18n.t("failed_to_add"), true);
        },
      }
    );
  };
  const handleClassRemoval = (classId) => {
    Swal.fire({
      title: i18n.t("are_you_sure_delete"),
      text: i18n.t("cannmot_reverse"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ff3600",
      cancelButtonColor: "#354e68",
      confirmButtonText: i18n.t("yes"),
    }).then((result) => {
      if (result.value) {
        removeClassRequest(
          {
            _id: active._id,
            classId,
            access_token: accessToken,
          },
          {
            success: (result) => {
              SuccessHelper.handleSuccess(i18n.t("successfully_deleted"), true);
              setData({ ...result.data });
              setActive(result.data);
            },
            failure: () => {
              ErrorHelper.handleErrors(i18n.t("failed_to_delete"), true);
            },
          }
        );
      }
    });
  };

  const handleFinalization = (params) => {
    Swal.fire({
      title: i18n.t("are_you_sure"),
      text: i18n.t("finalize_course_warning"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ff3600",
      cancelButtonColor: "#354e68",
      confirmButtonText: i18n.t("yes"),
    }).then((result) => {
      if (result.value) {
        onSave({ ...params, finalized: true });
      }
    });
  };

  const [tab, setTab] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const usedClassMap = useMemo(() => {
    const { classes = [] } = data;
    const map = {};
    classes.map((_class) => {
      map[_class.classId?._id] = true;
    });
    return map;
  }, [data.classes]);
  const [studentMap, semesterStudentClassMap] = useMemo(() => {
    const semesterStudentClassMap = {};
    const map = {};
    students.map((student) => {
      map[student._id] = student;
      const { studentClasses = [] } = student;
      studentClasses.map((studentClass) => {
        if (!semesterStudentClassMap[studentClass.classId]) {
          semesterStudentClassMap[studentClass.classId] = {};
        }
        if (studentClass.activeSemester == data._id) {
          semesterStudentClassMap[studentClass.classId][student._id] = true;
        } else {
          semesterStudentClassMap[studentClass.classId][student._id] = false;
        }
      });
    });
    return [map, semesterStudentClassMap];
  }, [students, data]);

  const findAttempt = (val, parent) => {
    const studentClass = val.student.studentClasses.find((element) => {
      return (
        element.classId == parent.classId._id &&
        element.activeSemester == data._id
      );
    });
    return (
      studentClass?.attempts?.find(
        (attempt) => attempt.semesterId == data._id
      ) || {}
    );
  };

  return (
    <div>
      <DotsLoader isloading={loading} />
      {open ? (
        <BaseModal
          open={open}
          title={
            <div className="row mx-0">
              <div className="pr-2">
                {data._id ? i18n.t("edit_semester") : i18n.t("add_semester")}
              </div>
              {data._id &&
                (!data.finalized ? (
                  <PrimaryButton
                    label={i18n.t("graded_and_finalized")}
                    onClick={() => handleFinalization(data)}
                  />
                ) : (
                  <Chip
                    className="status-chip"
                    size="small"
                    label={i18n.t("finalized")}
                  />
                ))}
            </div>
          }
          containerClassName="container"
          content={
            <>
              <AppBar position="static">
                <Tabs
                  value={tab}
                  onChange={handleTabChange}
                  aria-label="simple tabs example"
                >
                  <Tab label={i18n.t("details")} {...a11yProps(0)} />
                  <Tab
                    label={i18n.t("classes")}
                    {...a11yProps(1)}
                    disabled={!data._id}
                  />
                </Tabs>
              </AppBar>
              <TabPanel value={tab} index={0}>
                <div>
                  <ValidatorForm
                    onSubmit={handleSubmit}
                    ref={form}
                    className="w-100"
                    onError={(error) => {
                      if (!error.length) {
                        onSave(data);
                      }
                    }}
                  >
                    <TextValidator
                      className="mb-1"
                      label={i18n.t("name")}
                      onChange={(e) => handleChange("name", e.target.value)}
                      value={data.name || ""}
                      validators={["required"]}
                      errorMessages={[i18n.t("name_is_required")]}
                      InputProps={{
                        readOnly: data.finalized,
                      }}
                    />
                    <DatePicker
                      wrapperClassName="w-100 mb-1"
                      selected={
                        data.startDate ? moment(data.startDate).toDate() : ""
                      }
                      onChange={(e) => handleChange("startDate", e)}
                      value={
                        data.startDate ? moment(data.startDate).toDate() : ""
                      }
                      customInput={
                        <TextValidator
                          label={i18n.t("start_date")}
                          onChange={(e) =>
                            handleChange("startDate", e.target.value)
                          }
                          value={data.startDate}
                          validators={["required"]}
                          errorMessages={[i18n.t("this_field_is_required")]}
                          InputProps={{
                            readOnly: data.finalized,
                          }}
                        />
                      }
                      placeholderText={i18n.t("start_date")}
                      readOnly={data.finalized}
                    />
                    <DatePicker
                      wrapperClassName="w-100 mb-1"
                      selected={
                        data.endDate ? moment(data.endDate).toDate() : ""
                      }
                      onChange={(e) => handleChange("endDate", e)}
                      value={data.endDate ? moment(data.endDate).toDate() : ""}
                      customInput={
                        <TextValidator
                          label={i18n.t("end_date")}
                          onChange={(e) =>
                            handleChange("endDate", e.target.value)
                          }
                          value={data.endDate}
                          validators={["required"]}
                          errorMessages={[i18n.t("this_field_is_required")]}
                          InputProps={{
                            readOnly: data.finalized,
                          }}
                        />
                      }
                      placeholderText={i18n.t("end_date")}
                      readOnly={data.finalized}
                    />
                    <div className="row mx-0 px-0 justify-content-end mt-2 col-12">
                      {!data.finalized ? (
                        <PrimaryButton
                          props={{
                            type: "submit",
                          }}
                          label={data._id ? i18n.t("save") : i18n.t("create")}
                        />
                      ) : null}
                    </div>
                  </ValidatorForm>
                </div>
              </TabPanel>
              <TabPanel value={tab} index={1}>
                {!data.finalized ? (
                  <ValidatorForm
                    onSubmit={() => {
                      handleClassAdd();
                    }}
                    ref={newClassForm}
                    className="w-100"
                  >
                    <div className="row mb-2 mx-0 px-0 align-items-end">
                      <div style={{ flex: 11 }} className="pr-1">
                        <SelectValidator
                          label={i18n.t("class")}
                          onChange={(e) => {
                            setNewCourseClass(e.target?.value);
                          }}
                          value={newCourseClass || ""}
                          select
                          validators={["required"]}
                          errorMessages={[i18n.t("required")]}
                        >
                          <MenuItem disabled={true}>{i18n.t("class")}</MenuItem>
                          {classes.map((_class) => {
                            return (
                              <MenuItem
                                key={_class._id}
                                value={_class._id}
                                disabled={usedClassMap[_class._id]}
                              >
                                {_class.name}
                              </MenuItem>
                            );
                          })}
                        </SelectValidator>
                      </div>
                      <PrimaryButton
                        label={i18n.t("new")}
                        onClick={() => {
                          setAddClassOpen(true);
                        }}
                      />
                    </div>
                    <div className="row mx-0 px-0 justify-content-end mt-2 col-12 mb-1">
                      <PrimaryButton
                        props={{
                          type: "submit",
                        }}
                        label={i18n.t("add")}
                      />
                    </div>
                  </ValidatorForm>
                ) : null}
                <CollapsibleTable
                  rows={data.classes}
                  childKey="students"
                  childrenGetter={(row) => {
                    const map = semesterStudentClassMap[row.classId._id] || {};
                    const children = Object.keys(map).map((studentId) => {
                      const student = studentMap[studentId];

                      let attempt;
                      student.studentClasses.map((_class) => {
                        _class.attempts.map((element) => {
                          if (element.semesterId == data._id) {
                            attempt = element;
                          }
                        });
                      });
                      const finalized = attempt?.status == "Complete";
                      console.log(attempt);
                      return {
                        student,
                        selected: map[studentId],
                        finalized,
                      };
                    });

                    return children;
                  }}
                  prependChildren={(parent) =>
                    data.finalized ? null : (
                      <div className="row mx-0">
                        {studentGroups.map((studentGroup) => {
                          return (
                            <Chip
                              key={studentGroup._id}
                              className="status-chip"
                              onClick={() => {
                                const body = {
                                  access_token: accessToken,
                                  _id: data._id,
                                  studentGroupId: studentGroup._id,
                                  classId: parent.classId._id,
                                };
                                const callbackMap = {
                                  success: ({ data }) => {
                                    SuccessHelper.handleSuccess(
                                      i18n.t("added_students", {
                                        amount: data.length,
                                      }),
                                      true
                                    );
                                    console.log(data);
                                    getStudentRequest({
                                      access_token: accessToken,
                                      relations: ["classes"],
                                    });
                                  },
                                };
                                addStudentGroupToClassRequest(
                                  body,
                                  callbackMap
                                );
                              }}
                              style={{
                                backgroundColor: "#e1e1e1",
                                margin: 4,
                                cursor: "pointer",
                              }}
                              size="small"
                              label={studentGroup.name}
                            />
                          );
                        })}
                      </div>
                    )
                  }
                  schema={[
                    {
                      header: "class",
                      key: "class",
                      accessor: (val) => val.classId?.name,
                    },
                    {
                      key: "",
                      style: { width: 60 },
                      render: (val) => {
                        if (data.finalized) {
                          return null;
                        }
                        return (
                          <FontAwesomeIcon
                            className="view-icon"
                            style={{ backgroundColor: "red" }}
                            color="white"
                            icon={["fas", "trash"]}
                            onClick={() => handleClassRemoval(val._id)}
                          />
                        );
                      },
                    },
                  ]}
                  childSchema={{
                    title: i18n.t("students"),
                    columns: [
                      ...(data.finalized
                        ? []
                        : [
                            {
                              key: "enrolled",
                              style: { width: 80 },
                              render: (val, parent) => {
                                if (val.finalized) {
                                  return null;
                                }
                                return (
                                  <Checkbox
                                    checked={val.selected}
                                    onChange={() => {
                                      const body = {
                                        access_token: accessToken,
                                        _id: data._id,
                                        studentId: val.student._id,
                                        classId: parent.classId._id,
                                      };
                                      const callbackMap = {
                                        success: () => {
                                          getStudentRequest({
                                            access_token: accessToken,
                                            relations: ["classes"],
                                          });
                                        },
                                      };
                                      if (val.selected) {
                                        removeStudentFromClassRequest(
                                          body,
                                          callbackMap
                                        );
                                      } else {
                                        addStudentToClassRequest(
                                          body,
                                          callbackMap
                                        );
                                      }
                                    }}
                                    inputProps={{ "aria-label": "enrolled" }}
                                  />
                                );
                              },
                            },
                          ]),
                      {
                        key: "name",
                        style: { verticalAlign: "middle" },
                        accessor: (val, parent) => {
                          return val.student?.name;
                        },
                      },
                      {
                        key: "average",
                        style: { verticalAlign: "middle" },
                        accessor: (val, parent) => {
                          const studentClass = val.student.studentClasses.find(
                            (element) => {
                              return (
                                element.classId == parent.classId._id &&
                                element.activeSemester == data._id
                              );
                            }
                          );
                          const attempt =
                            studentClass?.attempts?.find(
                              (attempt) => attempt.semesterId == data._id
                            ) || {};

                          let values = 0;
                          let results = 0;
                          (attempt.grades || []).map((grades) => {
                            values += grades.value || 0;
                            results += grades.result || 0;
                          });
                          if (!values) {
                            return `N/A`;
                          }
                          return `${((results / values) * 100).toFixed(2)} %`;
                        },
                      },
                      ...(data.finalized
                        ? [
                            {
                              key: "passed",
                              style: { verticalAlign: "middle" },
                              accessor: (val, parent) => {
                                const attempt = findAttempt(val, parent);

                                let values = 0;
                                let results = 0;
                                (attempt.grades || []).map((grades) => {
                                  values += grades.value || 0;
                                  results += grades.result || 0;
                                });
                                return (results / values) * 100 >=
                                  attempt.passingGrade
                                  ? "Yes"
                                  : "No";
                              },
                            },
                          ]
                        : []),
                      {
                        style: { width: 180 },
                        render: (val, parent) => {
                          return (
                            <div className="row mx-0">
                              <PrimaryButton
                                label={i18n.t("grade")}
                                onClick={() => {
                                  setGradingProps({
                                    student: val.student,
                                    class: parent.classId,
                                  });
                                }}
                              />
                              {val.finalized ? null : (
                                <PrimaryButton
                                  label={i18n.t("finalize")}
                                  className="ml-1"
                                  onClick={() => {
                                    Swal.fire({
                                      title: i18n.t("are_you_sure"),
                                      icon: "warning",
                                      showCancelButton: true,
                                      confirmButtonColor: "#ff3600",
                                      cancelButtonColor: "#354e68",
                                      confirmButtonText: i18n.t("yes"),
                                    }).then((result) => {
                                      if (result.value) {
                                        const attempt = findAttempt(
                                          val,
                                          parent
                                        );
                                        setFinalizedRequest({
                                          access_token: accessToken,
                                          attemptId: attempt._id,
                                          studentId: val.student._id,
                                        });
                                      }
                                    });
                                  }}
                                />
                              )}
                            </div>
                          );
                        },
                      },
                    ],
                  }}
                />
              </TabPanel>
            </>
          }
          onClose={onClose}
        />
      ) : null}
      {addClassOpen && (
        <ClassUpsert
          open={addClassOpen}
          element={activeClass}
          onClose={() => {
            setAddClassOpen(false);
            setActiveClass({});
          }}
          onSave={onSaveClass}
          accessToken={accessToken}
          presetVendor
        />
      )}
      {gradingProps && (
        <StudentGrading
          {...gradingProps}
          accessToken={accessToken}
          open={Boolean(gradingProps)}
          onClose={() => setGradingProps(null)}
          semester={data}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  classes: state._class.data,
  students: state.student.data,
  studentGroups: state.studentGroup.data,
  loading: state.semester.isFetching,
});

const action = {
  getClassesRequest,
  upsertClassRequest,
  addClassRequest,
  removeClassRequest,
  getStudentRequest,
  addStudentToClassRequest,
  addStudentGroupToClassRequest,
  removeStudentFromClassRequest,
  setFinalizedRequest,
};

export default connect(mapStateToProps, action)(ProgramUpsert);
