import { connect } from 'react-redux';
import React, { Component } from 'react';
import  {Translation}  from 'react-i18next';
import i18n from '../../../../i18n';
import axios from 'axios'
import { Images } from '../../../../theme'
import { resetPassword, upsertCheckinSettings } from '../../../../config/simpleApiCalls'

import { DotsLoader} from "../../../../components";
// import { adminGetUsers } from '../../../config/simpleApiCalls';
import { SuccessHelper, ErrorHelper } from "../../../../helpers";
import { request as create_social_media } from '../../../../redux/actions/Saloon/CreateSaloonSocialMedia';

import './styles.css'
import Sytles from './sytles'

class LinkSocialMedia extends Component {
    constructor(props) {
        super(props);
        this.state = {
            facebookLink: '',
            instagramLink: '',
            twitterLink: '',
            youtubeLink: '',
            snapchatLink: '',
            linkedinLink: '',
            // facebookLink: 'https://www.facebook.com/test-user-91',
            // instagramLink: 'https://www.instagram.com/test-user-91',
            // twitterLink: 'https://twitter.com/test-user-91',
            // youtubeLink: 'https://www.youtube.com/test-user-91',
            // snapchatLink: 'https://www.snapchat.com/test-user-91',
            // linkedinLink: 'https://www.linkedin.com/test-user-91',
            hasLink: false,
            access_token: '',
            companyId: '',
            isloading: false,
            formErrors: {}
        }
    }

    componentDidMount = async () => {
        const saloonData = await JSON.parse(sessionStorage.getItem('saloon'))
        const companyData = await JSON.parse(sessionStorage.getItem('company'))
        if (saloonData && companyData) {
            this.setState({
                access_token: saloonData.access_token,
                companyId: companyData._id
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.createSaloonSocialMedia) {
            console.log(nextProps.createSaloonSocialMedia, "nextProps.createSaloonSocialMedia")
            if (
                !nextProps.createSaloonSocialMedia.failure &&
                !nextProps.createSaloonSocialMedia.isFetching &&
                !nextProps.createSaloonSocialMedia.errorMessage &&
                nextProps.createSaloonSocialMedia.data &&
                nextProps.createSaloonSocialMedia.data.data &&
                nextProps.createSaloonSocialMedia.data.success
            ) {
                this.getAllSocialMedia()
                SuccessHelper.handleSuccess('You have successfully added.', true);
            } else if (
                nextProps.createSaloonSocialMedia.failure &&
                !nextProps.createSaloonSocialMedia.isFetching &&
                nextProps.createSaloonSocialMedia.errorMessage
            ) {
                this.setState({ isloading: false });
            }
        }
    }

    savePin = () => {
        const { newPin, access_token, companyId } = this.state
        const pinRegex = /^[0-9]{4,6}$}/g
        console.log(newPin)
        if(!newPin) {
            this.setState({
                formErrors: {
                    newPin: <Translation>{t=>t('pin_required')}</Translation>,
                },
            });
            setTimeout(() => {
                this.setState({
                    formErrors: {
                        newPin: "",
                    },
                });
            }, 6000);
        } else if (newPin.match(pinRegex)) {
            this.setState({
                formErrors: {
                    newPin: <Translation>{t=>t('pin_length')}</Translation>,
                },
            });
            setTimeout(() => {
                this.setState({
                    formErrors: {
                        newPin: "",
                    },
                });
            }, 6000);
        } else {
        
            this.setState({ isloading: true });
        
            const payload = {
                companyId,
                pin: newPin,
            };
        
            upsertCheckinSettings(payload, access_token)
              .then((res) => {
                console.log("res: ", res);
                if (res.data.success) {
                  this.setState({ isloading: false, confirmNewPin: "", newPin: "" });
        
                  SuccessHelper.handleSuccess(i18n.t('successfully_updated'), true);
                }
              })
              .catch((err) => {
                this.setState({ isloading: false });
        
                ErrorHelper.handleErrors("Something Went Wrong", true);
              });
        
            this.setState({ isloading: false });
        }
    }

    savePassword = () => {
        const { newPassword, confirmNewPassword } = this.state
        if(!newPassword) {
            this.setState({
                formErrors: {
                    newPassword: <Translation>{t=>t('password_required')}</Translation>,
                },
            });
            setTimeout(() => {
                this.setState({
                    formErrors: {
                        newPassword: "",
                    },
                });
            }, 6000);
        } else if (newPassword.length < 6) {
            this.setState({
                formErrors: {
                    newPassword: <Translation>{t=>t('password_min_length')}</Translation>,
                },
            });
            setTimeout(() => {
                this.setState({
                    formErrors: {
                        newPassword: "",
                    },
                });
            }, 6000);
        } else if (!confirmNewPassword) {
            this.setState({
                formErrors: {
                    confirmNewPassword: <Translation>{t=>t('confirm_password_required')}</Translation>,
                },
            });
            setTimeout(() => {
                this.setState({
                    formErrors: {
                        confirmNewPassword: "",
                    },
                });
            }, 6000);
        } else if (confirmNewPassword !== newPassword) {
            this.setState({
                formErrors: {
                    confirmNewPassword: <Translation>{t=>t('confirm_password_not_match')}</Translation>,
                },
            });
            setTimeout(() => {
                this.setState({
                    formErrors: {
                        confirmNewPassword: "",
                    },
                });
            }, 6000);
        } else {
        
            this.setState({ isloading: true });
        
            const payload = {
              userId: JSON.parse(sessionStorage.getItem("saloon"))._id,
              password: newPassword,
            };
        
            resetPassword(payload)
              .then((res) => {
                console.log("res: ", res);
                if (res.data.success) {
                  this.setState({ isloading: false, confirmNewPassword: "", newPassword: "" });
        
                  SuccessHelper.handleSuccess(res.data.msg, true);
                }
              })
              .catch((err) => {
                this.setState({ isloading: false });
        
                ErrorHelper.handleErrors("Something Went Wrong", true);
              });
        
            this.setState({ isloading: false });
        }
    }

    handleNavigation = (route) => {
        this.props.history.push(route)
    }

    onChange = (field, text) => this.setState({ [field]: text.target.value })

    renderLoader = () => {
        const { isloading } = this.state;
        return <DotsLoader isloading={isloading} />;
    };

    renderSaloonDashboardHeading = () => {
        return (
            <div className="col-12 p-3">
                <h1 className="saloon-dashboard-heading">{i18n.t('container.security')}</h1>
                {/* <p className="saloon-dashboard-sub-heading">Lorem ipsum dolor sit amet, consectetur</p> */}
            </div>
        )
    }

    renderInputCol6 = (inputType, placeholder, key) => {
        const { formErrors } = this.state
        console.log(formErrors[key])
        return (
            <div className="form-group col-md-6 px-0 px-md-3 align-items-start row mx-0">
                <input
                    value={this.state[key]}
                    type={inputType}
                    placeholder={placeholder}
                    className="form-control saloon-link-social-input"
                    onChange={(text) => this.onChange(key, text)}
                />
                {formErrors[key] ? (
                    <small className="form-text text-danger ml-3">
                        {formErrors[key]}
                    </small>
                ) : null}
            </div>
        )
    }

    renderSocialLinkCard = () => {
        return (
            <div className="col-12 p-3">
                <div className="col-12 p-3 bg-white" style={{ borderRadius: '1rem' }}>
                    <div className="col-12 px-0 px-md-3">
                        <p id="social-media-text" className="mb-3" >{i18n.t('password')}</p>
                    </div>
                    <div className="form-row mb-3">
                        {this.renderInputCol6("password", i18n.t('new_password'),  'newPassword')}
                        {this.renderInputCol6("password", i18n.t('confirm_password'),  'confirmNewPassword')}
                    </div>
                    <div className="col-12 px-0 px-md-3 row mx-0 justify-content-end">
                        <button type="button" onClick={() => this.savePassword()} className="btn btn-light btn-sm mb-2 " id="saloon-save-social-link"><Translation>{t=>t('container.save_changes')}</Translation></button>
                    </div>
                    <div className="divider py-1" />
                    <div className="col-12 px-0 px-md-3">
                        <p id="social-media-text" className="mb-3" >{i18n.t('pin')}</p>
                    </div>
                    <div className="form-row mb-3">
                        {this.renderInputCol6("numeric", i18n.t('new_pin'), 'newPin')}
                    </div>
                    <div className="col-12 px-0 px-md-3 row mx-0 justify-content-end">
                        <button type="button" onClick={() => this.savePin()} className="btn btn-light btn-sm mb-2 " id="saloon-save-social-link"><Translation>{t=>t('container.save_changes')}</Translation></button>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div>
                {this.renderLoader()}
                <div className="content-container">
                    <div className="row" >
                        {this.renderSaloonDashboardHeading()}
                        {this.renderSocialLinkCard()}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    createSaloonSocialMedia: state.createSaloonSocialMedia
});

const action = {
    create_social_media
};

export default connect(mapStateToProps, action)(LinkSocialMedia);