import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import i18n from "../../../i18n";
import BaseModal from "../../BaseModal";
import PrimaryButton from "../../Core/PrimaryButton";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Select from "../../Core/Select";
import {
  resendConfirmation,
  sendInvoice,
} from "../../../config/simpleApiCalls";
import { ErrorHelper, SuccessHelper } from "../../../helpers";

const SendCommunicationsModal = ({
  handleClose,
  bookingId,
  accessToken,
  company,
  email,
  communicationType,
}) => {
  const [method, setMethod] = useState();
  const [methodError, setMethodError] = useState(false);
  const options = [];
  if (email) {
    options.push({ value: "email", name: i18n.t("profile_email") });
  }
  if (company.hasTextPlan || !email) {
    options.push({ value: "sms", name: "SMS" });
  }
  const handleSend = () => {
    if (!method) {
      setMethodError(i18n.t("required"));
    } else {
      (communicationType == "confirmation" ? resendConfirmation : sendInvoice)(
        { bookingId, method },
        accessToken
      ).then(({ data }) => {
        if (data.success) {
          SuccessHelper.handleSuccess(i18n.t("success"), true);
          handleClose();
        } else {
          ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
        }
      });
    }
  };
  return (
    <BaseModal
      open={true}
      containerClassName={"half-container"}
      {...{
        title: (
          <div>
            {i18n.t(
              communicationType == "confirmation"
                ? "resend_confirmation"
                : "send_invoice"
            )}
          </div>
        ),
        content: (
          <div>
            {/* <div>{i18n.t("resend_confirmation_email")}</div> */}
            <Select
              options={options}
              label={i18n.t("how_to_send")}
              onChange={(e) => setMethod(e.target.value)}
              error={methodError}
            />
          </div>
        ),
        actions: (
          <div className="row mx-0 px-0 justify-content-end">
            <PrimaryButton
              onClick={() => handleSend()}
              label={i18n.t("send")}
            />
          </div>
        ),
      }}
      onClose={() => handleClose()}
    />
  );
};

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(SendCommunicationsModal);
