// @flow

import { SIDEBAR } from "./ActionTypes";

export function setOpened(isOpen) {
  return {
    isOpen,
    type: SIDEBAR.SET_OPENED
  };
}

export function setDocked(isDocked) {
  return {
    isDocked,
    type: SIDEBAR.SET_DOCKED
  };
}