import React, { useState, useCallback, useMemo, useEffect } from "react";
import ReactDOM from "react-dom";

import PlanFilter from "./PlanFilter";
import PlanCalendar from "./PlanCalendar";

const PlanView = ({
  open,
  width,
  slideSideNav,
  onEventClick,
  plans,
  createAppointment,
  start,
  date,
  onDateClick,
  onDateChange = () => {},
  onDismiss = () => {},
  renderSidebar = () => {},
  companies = [],
  companyTaxMap,
  employees = [],
  isSaloon,
}) => {
  const [calView, setCalView] = useState(
    isSaloon ? "resourceTimeGrid" : "timeGridDay"
  );
  const [calDate, setCalDate] = useState(new Date()); //init current date
  let allPlans = [];
  plans.forEach((plan) => {
    allPlans.push(...plan);
  });

  const handleCalendarView = useCallback((value) => {
    switch (value) {
      case "day":
        setCalView(isSaloon ? "resourceTimeGrid" : "timeGridDay");
        break;
      case "work week":
        setCalView("timeGridWorkWeek");
        break;
      case "week":
        setCalView("timeGridWeek");
        break;
      default:
        setCalView("timeGridWorkWeek");
        break;
    }
  }, []);
  useEffect(() => {
    setCalDate(date);
  });

  const handleCalendarDate = useCallback((value) => {
    setCalDate(value);
    onDateChange(value);
  }, []);

  return (
    <div
      className="plan-view"
      style={{
        marginLeft: !open ? 0 : width,
      }}
    >
      <PlanFilter
        handleCalendarView={handleCalendarView}
        handleCalendarDate={handleCalendarDate}
        calDate={calDate}
        slideSideNav={slideSideNav}
        createAppointment={createAppointment}
        companies={companies}
        companyTaxMap={companyTaxMap}
      />
      <div
        onClick={() => slideSideNav(false)}
        className="plan-calendar-wrapper"
      >
        <PlanCalendar
          calView={calView}
          calDate={calDate}
          planData={allPlans}
          onEventClick={onEventClick}
          start={start}
          onDateClick={onDateClick}
          onDismiss={onDismiss}
          employees={employees}
        />
        {renderSidebar && renderSidebar()}
      </div>
    </div>
  );
};

export default PlanView;
