import { put, takeEvery } from "redux-saga/effects";

import * as types from "../../actions/ActionTypes";
import { get, requestFailure } from "../../actions/Saloon/NoShowReport";

import axios from "axios";
import { get_no_show_report } from "../../../config/WebServices";
import FileDownload from "js-file-download";

function* getSaga(action) {
  const { payload, callbackMap = {} } = action;
  let data;
  try {
    const token = payload.access_token;
    delete payload.access_token;
    data = yield axios.get(get_no_show_report, {
      params: payload,
      headers: {
        Authorization: token,
      },
    });
    data = data.data;

    if (payload.exportToXlsx) {
      yield put(requestFailure(data));
      return FileDownload(data, "report.xlsx");
    }
    if (data.success) {
      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data);
      }
      yield put(get(data.data));
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data);
      }
      yield put(requestFailure(data));
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data);
    }
    yield put(requestFailure(data));
  }
}

export default function* root() {
  yield takeEvery(types.NO_SHOW_REPORT.GET_SAGA, getSaga);
}
