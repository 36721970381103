import { connect } from "react-redux";
import React, { useEffect, useMemo, useState } from "react";
import FormTable from "../../../components/Saloon/Form/FormTable";
import FilledFormTable from "../../../components/Saloon/Form/FilledFormTable";
import FormInsert from "../../../components/Saloon/Form/FormInsert";
import i18n from "../../../i18n";
import {
  getRequest,
  insertRequest,
  removeRequest,
} from "../../../redux/actions/Saloon/FormBuilder";
import { getRequest as getFilledFromsRequest } from "../../../redux/actions/FormAnswer";
import PrimaryButton from "../../../components/Core/PrimaryButton";
import { BaseModal, DotsLoader } from "../../../components";
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import Swal from "sweetalert2";
import FormGenerator from "../../../components/FormBuilder/Components/FormGenerator.component";
import _ from "lodash";
import { TextField } from "@material-ui/core";
import { InputAdornment } from "@material-ui/core";
import { Search } from "@material-ui/icons";

const Form = ({
  forms = [],
  getRequest,
  insertRequest,
  removeRequest,
  loading,
  history,
  getFilledFromsRequest,
  filledForms = [],
}) => {
  const [accessToken, setAccessToken] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [addOpen, setAddOpen] = useState(false);
  const [previewForm, setPreviewForm] = useState(false);
  const [fieldsToRender, setFieldsToRender] = useState({});
  const [formTitle, setFormTitle] = useState("");
  const [search, setSearch] = useState("");

  useEffect(() => {
    const saloonData = JSON.parse(sessionStorage.getItem("saloon"));
    const company = JSON.parse(sessionStorage.getItem("company"));
    setAccessToken(saloonData.access_token);
    setCompanyId(company._id);
    getRequest({
      access_token: saloonData.access_token,
      companyId: company._id,
    });
    getFilledFromsRequest({
      access_token: saloonData.access_token,
    });
  }, []);
  const onAdd = (data) => {
    insertRequest(
      { access_token: accessToken, ...data, companyId },
      {
        success: ({ data }) => {
          history.push(`/saloon-form-builder/${data._id}`);
          SuccessHelper.handleSuccess(i18n.t("add_succesfully"), true);
          setAddOpen(false);
        },
        failure: () => {
          ErrorHelper.handleErrors(i18n.t("failed_to_add"), true);
        },
      }
    );
  };
  const onDelete = (form) => {
    Swal.fire({
      title: i18n.t("are_you_sure_delete"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ff3600",
      cancelButtonColor: "#354e68",
      confirmButtonText: i18n.t("yes"),
    }).then((result) => {
      if (result.value) {
        removeRequest({ access_token: accessToken, _id: form._id });
        SuccessHelper.handleSuccess(i18n.t("successfully_deleted"), true);
      }
    });
  };
  const onEdit = (form) => {
    history.push(`/saloon-form-builder/${form._id}`);
  };
  const handleView = (form) => {
    window.open(`/form-fill/${form._id}`, "_blank");
  };

  const handlePreview = (input) => {
    let sortedListToPreview = _.sortBy(input.fieldsToRender, ["displayOrder"]);

    setFieldsToRender(sortedListToPreview);
    setPreviewForm(!previewForm);
    setFormTitle(input.formTitle);
  };

  const filteredForms = useMemo(() => {
    if (!search) {
      return forms;
    }
    return forms.filter((form) =>
      form.formTitle.toLowerCase().includes(search.toLowerCase())
    );
  }, [forms, search]);
  const filteredFilledForms = useMemo(() => {
    if (!search) {
      return filledForms;
    }
    return filledForms.filter(
      (form) =>
        form.formId.formTitle.toLowerCase().includes(search.toLowerCase()) ||
        (form.userId?.firstName || "")
          .toLowerCase()
          .includes(search.toLowerCase())
    );
  }, [filledForms, search]);
  return (
    <div className="content-container">
      <DotsLoader isloading={loading} />
      <div className="row mx-0 justify-content-between">
        <h1 className="saloon-dashboard-heading pb-3">{i18n.t("forms")}</h1>
        <div className="row mx-0 align-items-center">
          <TextField
            style={{ width: 200, paddingRight: 4 }}
            id="search"
            placeholder={i18n.t("Search")}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          <PrimaryButton
            label={i18n.t("add")}
            onClick={() => setAddOpen(true)}
          />
        </div>
      </div>
      <FormTable
        handlePreview={handlePreview}
        data={filteredForms}
        handleDelete={onDelete}
        handleEdit={onEdit}
      />
      <div className="mt-2">
        <FilledFormTable data={filteredFilledForms} handleView={handleView} />
      </div>
      {addOpen && (
        <FormInsert
          open={addOpen}
          onClose={() => setAddOpen(false)}
          onSave={onAdd}
        />
      )}
      {previewForm && (
        <BaseModal
          open={previewForm}
          onClose={() => {
            setPreviewForm();
          }}
          {...{
            title: formTitle,
            content: (
              <FormGenerator
                fieldsToRender={fieldsToRender}
                preview={previewForm}
                readonly={true}
              />
            ),
          }}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  forms: state.formBuilder.data,
  filledForms: state.formAnswer.data,
  loading: state.formBuilder.isFetching,
});

const action = {
  getRequest,
  insertRequest,
  removeRequest,
  getFilledFromsRequest,
};

export default connect(mapStateToProps, action)(Form);
