import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getRequest } from "../../../../redux/actions/Employee/EmployeeFundsRequest";
import i18n from "../../../../i18n";
import moment from "moment";

function ListFundsRequests({
  getRequest,
  accessToken: access_token,
  requests,
}) {
  useEffect(() => {
    if (access_token) {
      getRequest({ access_token });
    }
  }, [access_token]);
  return (
    <table className="table table-borderless table-layout-fixed">
      <thead>
        <tr>
          <th>
            <span className="tab-heading">{i18n.t("amount")}</span>
          </th>
          <th>
            <span className="tab-heading">{i18n.t("approved_by")}</span>
          </th>
          <th>
            <span className="tab-heading">{i18n.t("created_on")}</span>
          </th>
          <th>
            <span className="tab-heading">{i18n.t("status")}</span>
          </th>
        </tr>
      </thead>
      <tbody className="bg-white">
        {requests.map((request) => {
          return (
            <tr key={request._id}>
              <td>
                <span className="tab-text">
                  $ {request?.amount?.toFixed(2)}
                </span>
              </td>
              <td>
                <span className="tab-text">{request.approver.firstName}</span>
              </td>
              <td>
                <span className="tab-text">
                  {moment(request.createdDate).format("MM-DD-YYYY hh:mm A")}
                </span>
              </td>
              <th>
                <span className="tab-text">{i18n.t(request.status)}</span>
              </th>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

const mapStateToProps = (state) => ({
  requests: state.employeeFundsRequest.data,
});

const action = { getRequest };

export default connect(mapStateToProps, action)(ListFundsRequests);
