import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import PackagesTable from "../../../components/Packages/PackagesTable.component";
import PackagesUpsert from "../../../components/Packages/PackagesUpsert.component";
import i18n from "../../../i18n";
import {
  getRequest,
  insertRequest,
  updateRequest,
  setActive,
} from "../../../redux/actions/Packages";
import PrimaryButton from "../../../components/Core/PrimaryButton";
import { DotsLoader } from "../../../components";
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import { saloonGetAllService } from "../../../config/simpleApiCalls";

const Packcages = ({
  getRequest,
  insertRequest,
  updateRequest,
  packages = [],
  setActive,
  loading: propsLoading,
  active,
}) => {
  const [accessToken, setAccessToken] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [allServices, setAllServices] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState();
  useEffect(() => {
    const saloonData = JSON.parse(sessionStorage.getItem("saloon"));
    const company = JSON.parse(sessionStorage.getItem("company"));
    setCompanyId(company._id);
    setAccessToken(saloonData.access_token);
    setLoading(true);
    Promise.all([
      new Promise((resolve) => {
        getRequest(
          { access_token: saloonData.access_token, companyId: company._id },
          {
            success: resolve,
          }
        );
      }),
      saloonGetAllService(saloonData.access_token).then(({ data }) => {
        if (data.success) {
          setAllServices(data.data);
        }
      }),
    ]).then(() => {
      setLoading(false);
    });
  }, []);
  const onClose = () => {
    setOpen(false);
  };
  const onSave = (data) => {
    setLoading(true);
    const request = id ? updateRequest : insertRequest;
    request(
      { access_token: accessToken, companyId, ...data },
      {
        success: (data) => {
          SuccessHelper.handleSuccess(
            i18n.t(id ? "successfully_updated" : "successfully_added"),
            true
          );
          setActive(data.data);
          setLoading(false);
        },
        failure: () => {
          ErrorHelper.handleErrors(
            i18n.t(id ? "failed_to_update" : "failed_to_add"),
            true
          );
          setLoading(false);
        },
      }
    );
  };
  const onEdit = (data) => {
    setActive(data);
    setId(data._id);
    setOpen(true);
  };
  return (
    <div className="content-container">
      <DotsLoader isloading={loading || propsLoading} />
      <div className="d-flex justify-content-between align-items-center">
        <h1 className="saloon-dashboard-heading pb-3">
          {i18n.t("packages_classes")}
        </h1>
        <PrimaryButton
          label={i18n.t("create")}
          onClick={() => {
            setActive();
            setOpen(true);
          }}
        />
      </div>
      <PackagesTable
        accessToken={accessToken}
        rows={packages}
        onEdit={onEdit}
        allServices={allServices}
      />
      {open && (
        <PackagesUpsert
          open={open}
          element={active}
          onClose={onClose}
          onSave={onSave}
          allServices={allServices}
          accessToken={accessToken}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  active: state.packages.active,
  packages: state.packages.data,
  loading: state.packages.isFetching,
});

const action = {
  getRequest,
  insertRequest,
  updateRequest,
  setActive,
};

export default connect(mapStateToProps, action)(Packcages);
