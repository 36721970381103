import { connect } from "react-redux";
import React, { Component, useState } from "react";
import i18n from "../../../i18n";
import Rating from "react-rating";
import { Images } from "../../../theme";
import { DotsLoader } from "../../../components";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { Translation } from "react-i18next";
import { getCompanyRatings } from "../../../config/simpleApiCalls";

import "./styles.css";

class SaloonRating extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      setOpen: false,
      tabOpen: true,
      access_token: null,
      companyId: null,
      companyRating: null,
    };
  }
  componentDidMount = async () => {
    const saloonData = await JSON.parse(sessionStorage.getItem("saloon"));
    const companyData = await JSON.parse(sessionStorage.getItem("company"));
    if (saloonData && companyData) {
      this.setState({
        access_token: saloonData.access_token,
        companyId: companyData._id,
      });
      this.getRatings();
    }
  };

  getRatings() {
    this.setState({ isloading: true });

    const { access_token } = this.state;
    getCompanyRatings(access_token)
      .then((res) => {
        if (res.data) {
          res.data.data &&
            this.setState({ companyRating: res.data?.data, isloading: false });
        }
      })
      .catch((err) => {
        this.setState({ isloading: false });
      });
  }

  openTab = () => {
    this.setState({ tabOpen: !this.state.tabOpen });
  };

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  renderEmployeeDashboardHeading = () => {
    return (
      <div className="col-12 p-3">
        <h1 className="saloon-dashboard-heading">
          {i18n.t("container.dashboard_company_ratings")}:
        </h1>
        {/* <p className="saloon-dashboard-sub-heading">Lorem ipsum dolor sit amet, consectetur</p> */}
      </div>
    );
  };

  renderRatingHeader = () => {
    const { companyRating } = this.state;
    const company = companyRating?.company;
    return (
      <div
        className="row bg-white pr-md-5 pl-md-5 pl-0 pr-0 pt-5 ml-0"
        style={{ width: "100%" }}
      >
        <div className="col-md-6">
          <div class="d-flex">
            <div class="">
              <img
                src={
                  company && company.companyLogo
                    ? company.companyLogo
                    : Images.pic1
                }
                id="user-img"
                class="rounded-circle"
                height="100px"
                width="100px"
                alt="avatar"
              />
            </div>
            <div class="ml-2 mt-3">
              <h4 className="username">{company && company.name}</h4>
              {/* <p className="usertitle">Lorem Ipsum dolor sit</p> */}
            </div>
          </div>
        </div>
        <div className="col-md-6 d-flex justify-content-center">
          <div className="avg-score">
            <h6>
              <strong>{i18n.t("average_score")}</strong>
            </h6>
            <p class="font-size-45">
              {`(${company ? company?.averageRating : i18n.t("no_ratings")})`}
              <img
                src={Images.rating_star}
                style={{ width: 100 }}
                className="ml-3"
              />
            </p>
          </div>
        </div>
      </div>
      //     <div className="col-12 column bg-white">
      //         <div class="d-flex bd-highlight ml-5 mr-5 mt-4">
      //     <div class="p-2 bd-highlight">
      //         <img src={Images.pic1} class="rounded-circle" height="100px" width="100px" alt="avatar"/>
      //     </div>
      //     <div class="p-2 bd-highlight mt-3">
      //         <h4>Lorem Ipsum</h4>
      //         <p>Lorem Ipsum dolor sit</p></div>
      //     <div class="ml-auto p-2 bd-highlight" style={{marginRight: 200}}>
      //         <h6><strong>Average Score</strong></h6>
      //         <p class="font-size-45">
      //         (4.8)
      //         <img src={Images.rating_star} style={{width: 100}} className="ml-3"/>
      //         </p>
      //     </div>
      //   </div>
      //     </div>
    );
  };
  renderRatingHeader2 = (val) => {
    console.log(val, "valval");

    return (
      <div
        className="row bg-white pr-md-5 pl-md-5 pl-0 pr-0 pt-5 ml-0"
        style={{ width: "100%" }}
      >
        <div className="col-md-6">
          <div class="d-flex">
            <div class="">
              <img
                src={
                  val && val.sender && val.sender
                    ? val.sender.profile_img
                    : Images.pic1
                }
                id="user-img"
                class="rounded-circle"
                height="100px"
                width="100px"
                alt="avatar"
              />
            </div>
            <div class="ml-2 mt-3">
              <h4 className="username">
                {val && val.sender && val.sender.firstName !== null
                  ? `${val.sender.firstName}${val.sender.firstName}`
                  : val.sender.userName}
              </h4>
              {/* <p className="usertitle">Lorem Ipsum dolor sit</p> */}
            </div>
          </div>
        </div>
        <div className="col-md-6 d-flex justify-content-center">
          <div className="avg-score">
            <h6>
              <strong>Score</strong>
            </h6>
            <p class="font-size-45">
              {`(${val ? val?.rate : "No Ratings for this"})`}
              <img
                src={Images.rating_star}
                style={{ width: 100 }}
                className="ml-3"
              />
            </p>
          </div>
        </div>
      </div>
    );
  };
  renderFeedbackSummery = (val) => {
    return (
      <div className="col-md-12 col-lg-12 bg-white">
        <div class="ml-md-5 mr-md-5 ml-0 mr-0 mt-4">
          <div>
            <h3>
              <strong>{val && val.review}</strong>
            </h3>
          </div>
          {/* <div class="mt-4">
                        <p>Personal Skills and competence</p>
                    </div>
                    <div class="d-flex mr-5" style={{ justifyContent: 'space-between' }}>
                        <div class="mt-1">
                            <p>Lorem ipsum dolor</p>
                        </div>
                        <div>
                            <h3 className="star">
                                <StarRating />
                            </h3>
                        </div>
                    </div>
                    <div class="d-flex mr-5" style={{ justifyContent: 'space-between' }}>
                        <div class="mt-1">
                            <p>Lorem ipsum dolor</p>
                        </div>
                        <div class="">
                            <h3>
                                <StarRating />
                            </h3>
                        </div>
                    </div>
                    <div class="d-flex mr-5" style={{ justifyContent: 'space-between' }}>
                        <div class="mt-1">
                            <p>Lorem ipsum dolor</p>
                        </div>
                        <div class="">
                            <h3>
                                <StarRating />
                            </h3>
                        </div>
                    </div>
                    <div class="d-flex mr-5" style={{ justifyContent: 'space-between' }}>
                        <div class="mt-1">
                            <p>Lorem ipsum dolor</p>
                        </div>
                        <div class="">
                            <h3>
                                <StarRating />
                            </h3>
                        </div>
                    </div>
                    <div class="d-flex mr-5" style={{ justifyContent: 'space-between' }}>
                        <div class="mt-1">
                            <p>Lorem ipsum dolor</p>
                        </div>
                        <div class="">
                            <h3>
                                <StarRating />
                            </h3>
                        </div>
                    </div> */}
        </div>
      </div>
    );
  };
  renderFeedbackSummery2 = (val) => {
    return (
      <>
        <div className="col-md-12 col-lg-12 bg-white mb-3">
          <div
            class="d-flex ml-3 mr-3"
            style={{ justifyContent: "space-between" }}
          >
            <div>{/* <p>Feedback (2)</p> */}</div>
            <div>
              {/* <img onClick={() => this.openTab()} src={Images.saloon_headingDropdown} /> */}
            </div>
          </div>
          <hr className="mt-0 ml-3 mr-3" />
          {/* {this.renderRatingHeader()} */}
        </div>

        <div style={{ width: "100%" }}>
          {this.state.tabOpen ? this.renderRatingHeader2(val) : null}
        </div>
      </>
    );
  };
  renderCollapse = () => {
    return (
      <Accordion>
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="0">
              Feedback
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <div className="renderCard">
                <div className="row">
                  <div className="column">
                    <h4
                      style={{ float: "right", marginRight: "230px" }}
                      class="card-title font-weight-bold mt-4"
                    >
                      Lorem Ipsum
                    </h4>
                    <p style={{ float: "right", marginRight: "220px" }}>
                      Lorem Ipsum dolor sit
                    </p>
                    <img
                      style={{ marginLeft: "20px" }}
                      src={Images.pic1}
                      class="rounded-circle mr-3"
                      height="100px"
                      width="100px"
                      alt="avatar"
                    />
                  </div>
                  <div className="column">
                    <h2>Average</h2>
                    <Rating
                      style={{ float: "right", marginRight: "280px" }}
                      emptySymbol={<img src={Images.star_empty} />}
                    />
                    <h1
                      style={{ marginRight: "210px" }}
                      class="card-title font-weight-bold"
                    >
                      4.8
                    </h1>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    );
  };

  render() {
    const { companyRating } = this.state;
    const ratings = companyRating && companyRating?.companyRating;
    return (
      <div>
        {this.renderLoader()}
        <div className="content-container">
          <div className="row mx-0">
            {this.renderEmployeeDashboardHeading()}
            {this.renderRatingHeader()}
            {ratings &&
              ratings.map((val) => {
                return this.renderFeedbackSummery(val);
              })}
            {ratings &&
              ratings.map((val) => {
                return this.renderFeedbackSummery2(val);
              })}
            {/* {this.renderFeedbackSummery2()} */}
            {/* {this.renderFeedbackSummery2()} */}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(SaloonRating);
