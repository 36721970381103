import React, { useEffect, useState } from "react";
import i18n from "../../i18n";
import TextField from "@material-ui/core/TextField";
import DollarInput from "../DollarInput";
import { Button, Checkbox } from "@material-ui/core";
import PercentInput from "../PercentInput";
const roundTo2Decimals = (x) => Math.round((x + Number.EPSILON) * 100) / 100;

export default function PaymentForm({ handleChange, data }) {
  const formErrors = {};
  const {
    subtotal = 0,
    balanceDue = 0,
    tip = 0,
    includeTaxes,
    taxDue = 0,
  } = data;
  useEffect(() => {
    const newTotal = roundTo2Decimals(
      parseFloat(subtotal) + parseFloat(tip) + (includeTaxes ? taxDue : 0)
    );
    handleChange("balanceDue", newTotal);
  }, [subtotal, tip, includeTaxes, taxDue]);
  const difference = roundTo2Decimals(
    balanceDue -
      parseFloat(data.cash || 0) -
      parseFloat(data.creditDebit || 0) -
      (data.discountAmount || 0) -
      (data.collected || 0)
  );
  return (
    <>
      <div className="mb-2 col-sm-6 col-xs-12 px-1">
        <DollarInput
          value={data.cash}
          onChange={(value) => handleChange("cash", value)}
          label={i18n.t("cash")}
          error={formErrors.money}
        />
      </div>
      <div className="mb-2 col-sm-6 col-xs-12 px-1">
        <DollarInput
          value={data.creditDebit}
          onChange={(value) => handleChange("creditDebit", value)}
          label={i18n.t("credit_debit")}
          error={formErrors.money}
        />
      </div>
      <div className="mb-2 col-sm-6 col-xs-12 px-1">
        <DollarInput
          value={data.discountAmount}
          onChange={(value) => {
            handleChange("discountAmount", parseFloat(value));
            handleChange(
              "discountPercent",
              roundTo2Decimals((100 * value) / balanceDue)
            );
          }}
          label={i18n.t("discount_amount")}
          error={formErrors.discount}
        />
      </div>
      <div className="mb-2 col-sm-6 col-xs-12 px-1">
        <PercentInput
          value={data.discountPercent}
          onChange={(percent) => {
            handleChange("discountAmount", percent);
            handleChange(
              "discountPercent",
              roundTo2Decimals((balanceDue * percent) / 100)
            );
          }}
          label={i18n.t("discount_percent")}
          error={formErrors.discount}
        />
      </div>
      <div className="col-12 mb-2 px-1">
        <DollarInput
          value={data.tip}
          onChange={(e) => handleChange("tip", parseFloat(e))}
          label={i18n.t("tip")}
        />
        <div className="col-12 py-1 px-0 row mx-0 justify-content-between">
          <Button
            style={{
              backgroundColor: "#ff3600",
              color: "white",
            }}
            onClick={() =>
              handleChange("tip", roundTo2Decimals((15.0 / 100) * subtotal))
            }
          >
            15 %
          </Button>
          <Button
            style={{
              backgroundColor: "#ff3600",
              color: "white",
            }}
            onClick={() =>
              handleChange("tip", roundTo2Decimals((20.0 / 100) * subtotal))
            }
          >
            20 %
          </Button>
          <Button
            style={{
              backgroundColor: "#ff3600",
              color: "white",
            }}
            onClick={() =>
              handleChange("tip", roundTo2Decimals((35.0 / 100) * subtotal))
            }
          >
            35 %
          </Button>
        </div>
      </div>
      <div className="mb-2 col-12 px-1">
        <DollarInput
          value={difference}
          label={difference < 0 ? i18n.t("change_due") : i18n.t("balance_due")}
          readOnly={true}
        />
      </div>
      <div className="mb-2 col-12 px-1">
        <TextField
          label={i18n.t("note")}
          onChange={(e) => handleChange("note", e.target.value)}
          helperText={formErrors.note}
          error={Boolean(formErrors.note)}
        />
      </div>
    </>
  );
}
