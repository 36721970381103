// @flow
import Immutable from "seamless-immutable";
import * as types from "../../actions/ActionTypes";

const initialState = Immutable({
  failure: false,
  isFetching: false,
  errorMessage: "",
  data: {},
});

export default (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case types.EMPLOYEE_REGISTER.REQUEST:
      return Immutable.merge(state, {
        data: {},
        isFetching: true,
      });
    case types.EMPLOYEE_REGISTER.SUCCESS:
      return Immutable.merge(state, {
        failure: false,
        isFetching: false,
        errorMessage: "",
        data: action.data,
      });
    case types.EMPLOYEE_REGISTER.FAILURE:
      return Immutable.merge(state, {
        failure: true,
        isFetching: false,
        errorMessage: action.errorMessage,
      });
    case types.EMPLOYEE_LOGOUT:
      return initialState;
    default:
      return state;
  }
};
