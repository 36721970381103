// @flow
import Immutable from "seamless-immutable";
import * as types from "../actions/ActionTypes";

const initialState = Immutable({
  data: {},
});

const resetSessionStorage = () => {
  sessionStorage.removeItem("user")
  sessionStorage.removeItem("employee");
  sessionStorage.removeItem("saloon");
  sessionStorage.removeItem("company");
  sessionStorage.removeItem("access_token");
  sessionStorage.removeItem("impersonationId")
}

export default (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case types.USER.SET_USER:
      const data = {...state.data}
      Object.keys(action.data).map(key => {
        data[key] = action.data[key]
        if(action.data[key]) {
          sessionStorage.setItem(key, action.data[key])
        } else {
          sessionStorage.removeItem(key)
        }
      })
      return Immutable.merge(state, {
        data
      });
    case types.CLEAR_LOGOUT:
      resetSessionStorage();
      return initialState;
    case types.LOGOUT.SUCCESS:
      resetSessionStorage();
      return initialState;      
    case types.SALOON_LOGOUT.SUCCESS: 
      resetSessionStorage();
      return initialState;    
    case types.EMPLOYEE_LOGOUT.SUCCESS:   
      resetSessionStorage();
      return initialState;    
    case types.LOGOUT:
      resetSessionStorage();
      return initialState;    
    default:
      return state;
  }
};
