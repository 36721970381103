import { connect } from "react-redux";
import React, { useEffect, useMemo, useRef, useState } from "react";
import i18n from "../../../i18n";
import BaseModal from "../../BaseModal";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import PrimaryButton from "../../Core/PrimaryButton";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import {
  getRequest as getClassesRequest,
  upsertRequest as upsertClassRequest,
} from "../../../redux/actions/Saloon/Class";
import { getRequest as getStudentRequest } from "../../../redux/actions/Saloon/Student";
import {
  addClassRequest,
  removeClassRequest,
} from "../../../redux/actions/Saloon/Program";
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import DotsLoader from "../../DotsLoader";
import ClassUpsert from "../Class/ClassUpsert";
import { MenuItem } from "@material-ui/core";
import { InputAdornment } from "@material-ui/core";
import BasicTable from "../../Core/BasicTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import moment from "moment";
import PurchaseClassModal from "../../PurchaseClassModal/PurchaseClassModal";
import {
  removeRequest as removeEnrollmentRequest,
  setStudentEnrollmentRequest,
} from "../../../redux/actions/Saloon/ProgramEnrollment";
import CollectPaymentModal from "../../PurchaseClassModal/CollectPaymentModal/CollectPaymentModal";
import { Chip } from "@material-ui/core";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      className="light-border"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={2}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function ProgramUpsert({
  open,
  onClose,
  onSave,
  active = {},
  setActive,
  getClassesRequest,
  accessToken,
  upsertClassRequest,
  addClassRequest,
  removeClassRequest,
  getStudentRequest,
  removeEnrollmentRequest,
  setStudentEnrollmentRequest,
  companyId,
  classes,
  taxes = [],
}) {
  const form = useRef();
  const newClassForm = useRef();
  const [data, setData] = useState({});
  const [addClassOpen, setAddClassOpen] = useState(false);
  const [addEnrollmentOpen, setAddEnrollmentOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [classReadOnly, setClassReadOnly] = useState(false);
  const [activeClass, setActiveClass] = useState({});
  const [newCourseClass, setNewCourseClass] = useState({ numberOfTimes: 1 });
  const [collectPaymentModalOpen, setCollectPaymentModalOpen] = useState(false);
  const [enrollmentToCollect, setEnrollmentToCollect] = useState();

  const init = (data) => {
    setData({ ...data });
    setTab(0);
    getClassesRequest({ access_token: accessToken });
    getStudentRequest({ access_token: accessToken });
  };
  const onSaveClass = (params) => {
    setLoading(true);
    upsertClassRequest(
      { access_token: accessToken, companyId, ...params },
      {
        success: (data) => {
          SuccessHelper.handleSuccess(
            i18n.t(data.id ? "successfully_updated" : "successfully_added"),
            true
          );
          setAddClassOpen(false);
          setLoading(false);
        },
        failure: () => {
          ErrorHelper.handleErrors(
            i18n.t(data.id ? "failed_to_update" : "failed_to_add"),
            true
          );
          setLoading(false);
        },
      }
    );
  };
  const handleEnrollmentChange = (newEnrollement) => {
    const index = data.enrollments.findIndex(
      (element) => element._id == newEnrollement._id
    );
    if (index != -1) {
      const newEnrollments = [...data.enrollments];
      newEnrollments[index] = newEnrollement;
      setData({ ...data, enrollments: newEnrollments });
    }
  };
  useEffect(() => {
    init(active);
  }, []);
  useEffect(() => {
    if (active._id != data._id) {
      init(active);
    }
  }, [active]);
  const handleChange = (key, value) => {
    data[key] = value;
    setData({ ...data });
  };
  const handleSubmit = () => {
    onSave(data);
  };
  const handleClassAdd = () => {
    addClassRequest(
      {
        _id: active._id,
        classId: newCourseClass,
        access_token: accessToken,
      },
      {
        success: (data) => {
          SuccessHelper.handleSuccess(i18n.t("successfully_added"), true);
          setData({ ...data.data });
          setActive(data.data);
          setNewCourseClass();
        },
        failure: () => {
          ErrorHelper.handleErrors(i18n.t("failed_to_add"), true);
        },
      }
    );
  };
  const handleClassRemoval = (classId) => {
    Swal.fire({
      title: i18n.t("are_you_sure_delete"),
      text: i18n.t("cannmot_reverse"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ff3600",
      cancelButtonColor: "#354e68",
      confirmButtonText: i18n.t("yes"),
    }).then((result) => {
      if (result.value) {
        removeClassRequest(
          {
            _id: active._id,
            classId,
            access_token: accessToken,
          },
          {
            success: (result) => {
              SuccessHelper.handleSuccess(i18n.t("successfully_deleted"), true);
              console.log(result.data);
              setData({ ...result.data });
              setActive(result.data);
            },
            failure: () => {
              ErrorHelper.handleErrors(i18n.t("failed_to_delete"), true);
            },
          }
        );
      }
    });
  };

  const [tab, setTab] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const usedClassMap = useMemo(() => {
    const { classes = [] } = data;
    const map = {};
    classes.map((_class) => {
      map[_class._id] = true;
    });
    return map;
  }, [data.classes]);

  const allClassMap = useMemo(() => {
    const map = {};
    classes.map((_class) => {
      map[_class._id] = _class;
    });
    return map;
  }, [classes]);

  const enrolledStudentMap = useMemo(() => {
    const { enrollments = [] } = data;
    const map = {};
    enrollments.map((enrollment) => {
      map[enrollment.studentId._id] = true;
    });
    return map;
  }, [data.enrollments]);
  return (
    <div>
      <DotsLoader isloading={loading} />
      {open ? (
        <BaseModal
          open={open}
          title={
            <div className="row mx-0">
              <div className="pr-2">
                {data._id ? i18n.t("edit_program") : i18n.t("add_course")}
              </div>
            </div>
          }
          containerClassName="container"
          content={
            <>
              <AppBar position="static">
                <Tabs
                  value={tab}
                  onChange={handleTabChange}
                  aria-label="simple tabs example"
                >
                  <Tab label={i18n.t("details")} {...a11yProps(0)} />
                  <Tab
                    label={i18n.t("classes")}
                    {...a11yProps(1)}
                    disabled={!data._id}
                  />
                </Tabs>
              </AppBar>
              <TabPanel value={tab} index={0}>
                <div>
                  <ValidatorForm
                    onSubmit={handleSubmit}
                    ref={form}
                    className="w-100"
                    onError={(error) => {
                      if (!error.length) {
                        onSave(data);
                      }
                    }}
                  >
                    <TextValidator
                      className="mb-1"
                      label={i18n.t("name")}
                      onChange={(e) => handleChange("name", e.target.value)}
                      value={data.name || ""}
                      validators={["required"]}
                      errorMessages={[i18n.t("name_is_required")]}
                    />
                    <TextValidator
                      className="mb-1"
                      type="number"
                      label={i18n.t("price")}
                      onChange={(e) => handleChange("price", e.target.value)}
                      validators={["required"]}
                      errorMessages={[i18n.t("price_is_required")]}
                      onBlur={(text) => {
                        let num = parseFloat(text.target.value);
                        if (isNaN(num) || num < 0) {
                          num = 0;
                        }
                        num = Math.round((num + Number.EPSILON) * 100) / 100;
                        handleChange("price", num);
                      }}
                      value={data.price}
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      validators={["required", "minNumber:1"]}
                      errorMessages={[
                        i18n.t("price_is_required"),
                        i18n.t("must_be_positive"),
                      ]}
                    />
                    <TextValidator
                      className="mb-1"
                      type="number"
                      label={i18n.t("hours_per_week")}
                      onChange={(e) =>
                        handleChange("hoursPerWeek", e.target.value)
                      }
                      validators={["required"]}
                      errorMessages={[i18n.t("this_field_is_required")]}
                      onBlur={(text) => {
                        let num = parseFloat(text.target.value);
                        if (isNaN(num) || num < 0) {
                          num = 0;
                        }
                        handleChange("hoursPerWeek", num);
                      }}
                      value={data.hoursPerWeek}
                      type="number"
                      validators={["required", "minNumber:0"]}
                      errorMessages={[
                        i18n.t("price_is_required"),
                        i18n.t("must_be_positive"),
                      ]}
                    />
                    <DatePicker
                      wrapperClassName="w-100 mb-1"
                      selected={
                        data.startDate ? moment(data.startDate).toDate() : ""
                      }
                      onChange={(e) => handleChange("startDate", e)}
                      value={
                        data.startDate ? moment(data.startDate).toDate() : ""
                      }
                      customInput={
                        <TextValidator
                          label={i18n.t("start_date")}
                          onChange={(e) =>
                            handleChange("startDate", e.target.value)
                          }
                          value={data.startDate}
                          validators={["required"]}
                          errorMessages={[i18n.t("this_field_is_required")]}
                        />
                      }
                      placeholderText={i18n.t("start_date")}
                    />
                    <DatePicker
                      wrapperClassName="w-100 mb-1"
                      selected={
                        data.endDate ? moment(data.endDate).toDate() : ""
                      }
                      onChange={(e) => handleChange("endDate", e)}
                      value={data.endDate ? moment(data.endDate).toDate() : ""}
                      customInput={
                        <TextValidator
                          label={i18n.t("end_date")}
                          onChange={(e) =>
                            handleChange("endDate", e.target.value)
                          }
                          value={data.endDate}
                          validators={["required"]}
                          errorMessages={[i18n.t("this_field_is_required")]}
                        />
                      }
                      placeholderText={i18n.t("end_date")}
                    />
                    <TextValidator
                      className="mb-1"
                      label={i18n.t("description")}
                      onChange={(e) =>
                        handleChange("description", e.target.value)
                      }
                      value={data.description || ""}
                      validators={["required"]}
                      errorMessages={[i18n.t("description_is_required")]}
                      multiline
                      rows={3}
                    />
                    <div className="row mx-0 px-0 justify-content-end mt-2 col-12">
                      <PrimaryButton
                        props={{
                          type: "submit",
                        }}
                        label={data._id ? i18n.t("save") : i18n.t("create")}
                      />
                    </div>
                  </ValidatorForm>
                </div>
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <ValidatorForm
                  onSubmit={() => {
                    handleClassAdd();
                  }}
                  ref={newClassForm}
                  className="w-100"
                >
                  <div className="row mb-2 mx-0 px-0 align-items-end">
                    <div style={{ flex: 11 }} className="pr-1">
                      <SelectValidator
                        label={i18n.t("class")}
                        onChange={(e) => {
                          setNewCourseClass(e.target?.value);
                        }}
                        value={newCourseClass || ""}
                        select
                        validators={["required"]}
                        errorMessages={[i18n.t("required")]}
                      >
                        <MenuItem disabled={true}>{i18n.t("class")}</MenuItem>
                        {classes.map((_class) => {
                          return (
                            <MenuItem
                              key={_class._id}
                              value={_class._id}
                              disabled={usedClassMap[_class._id]}
                            >
                              {_class.name}
                            </MenuItem>
                          );
                        })}
                      </SelectValidator>
                    </div>
                    <PrimaryButton
                      label={i18n.t("new")}
                      onClick={() => {
                        setAddClassOpen(true);
                      }}
                    />
                  </div>
                  <div className="row mx-0 px-0 justify-content-end mt-2 col-12">
                    <PrimaryButton
                      props={{
                        type: "submit",
                      }}
                      label={i18n.t("add")}
                    />
                  </div>
                </ValidatorForm>
                <BasicTable
                  rows={data.classes}
                  schema={[
                    {
                      header: i18n.t("class"),
                      field: "class",
                      accessor: (val) => val.name,
                    },
                    {
                      key: "action",
                      header: "",
                      style: { width: 60 },
                      render: (val) => {
                        return (
                          <FontAwesomeIcon
                            className="view-icon"
                            style={{ backgroundColor: "red" }}
                            color="white"
                            icon={["fas", "trash"]}
                            onClick={() => handleClassRemoval(val._id)}
                          />
                        );
                      },
                    },
                  ]}
                />
              </TabPanel>
            </>
          }
          onClose={onClose}
        />
      ) : null}
      {addClassOpen && (
        <ClassUpsert
          open={addClassOpen}
          element={activeClass}
          onClose={() => {
            setAddClassOpen(false);
            setActiveClass({});
          }}
          onSave={onSaveClass}
          accessToken={accessToken}
          presetVendor
          readOnly={classReadOnly}
        />
      )}
      {addEnrollmentOpen && (
        <PurchaseClassModal
          open={addEnrollmentOpen}
          setOpen={setAddEnrollmentOpen}
          onClose={() => {
            setAddEnrollmentOpen(false);
          }}
          accessToken={accessToken}
          program={data}
          taxes={taxes}
          enrolledStudentMap={enrolledStudentMap}
        />
      )}
      {collectPaymentModalOpen ? (
        <CollectPaymentModal
          open={collectPaymentModalOpen}
          setOpen={setCollectPaymentModalOpen}
          enrollment={enrollmentToCollect}
          accessToken={accessToken}
          onSuccess={handleEnrollmentChange}
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  classes: state._class.data,
  loading: state.program.isFetching,
});

const action = {
  getClassesRequest,
  upsertClassRequest,
  addClassRequest,
  removeClassRequest,
  getStudentRequest,
  removeEnrollmentRequest,
  setStudentEnrollmentRequest,
};

export default connect(mapStateToProps, action)(ProgramUpsert);
