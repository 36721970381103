import { connect } from "react-redux";
import React, { Component } from "react";
import { DotsLoader } from "../../../components";
import {Translation } from 'react-i18next';

import i18n from "../../../i18n";

class SiteSetUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  renderTemplates = () => {
    const { templateList, activeTemplateId } = this.state;
    return (
      <div className="row mx-0">
        <div className="col-12 p-2 text-center selection-box" style={{backgroundColor: "orange"}} onClick={() => this.handleNavigation('/saloon-my-site')}>
            <h4>I want to use my own website</h4>
        </div>
        <div className="col-12 my-1 text-center">
            <h5><Translation>{t=> t('or')}</Translation></h5>
        </div>
        <div className="col-12 p-2 text-center selection-box" style={{backgroundColor: "red"}} onClick={() => this.handleNavigation('/saloon-choose-a-template')}>
            <h4>I want to use an Easy1 Template</h4>
        </div>
      </div>
    );
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };
  
  renderSaloonDashboardHeading = () => {
    return (
      <div className="col-12 px-3 pt-3">
        <div className="row">
          <div className="col-md-8 col-lg-9 d-flex flex-column justify-content-center">
            <h1 className="saloon-dashboard-heading">{i18n.t('container.site_set_up')}</h1>
            {/* <p className="saloon-dashboard-sub-heading">
              Lorem ipsum dolor sit amet, consectetur
            </p> */}
          </div>
        </div>
      </div>
    );
  };



  render() {
    return (
      <div>
        {this.renderLoader()}
        <div className="container">
          <div className="row mx-0">
            {this.renderSaloonDashboardHeading()}
            <div
              className="col-12 p-3 mt-4 bg-white"
              style={{ borderRadius: "1rem" }}
            >
              {/* {this.TemplateOptionheader()} */}
              {this.renderTemplates()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(SiteSetUp);
