import { connect } from "react-redux";
import React, { Component } from "react";
import { Translation } from "react-i18next";
import i18n from "../../../i18n";
import { Images } from "../../../theme";
import {
  DotsLoader,
  AdvancedSearchDialog,
  BookingNotes,
  AppointmentModal,
  AddExtrasModal,
} from "../../../components";
import {
  getCompletedBooking,
  getCompanyThatEmployeeAdd,
  editBooking,
  removeProduct,
  removeExtra,
  updateCartProduct,
} from "../../../config/simpleApiCalls";
import { getNestedValue } from "../../../util/objectMethods";
import NumberFormat from "react-number-format";
import Button from "@material-ui/core/Button";
import Avatar from "react-avatar";
import moment from "moment";
import { SuccessHelper, ErrorHelper } from "../../../helpers";
import Swal from "sweetalert2";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import Pagination from "react-js-pagination";
import {
  Select,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import DatePicker from "react-datepicker";

import Sytles from "./sytles";

class Booking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pending: true,
      cancelled: true,
      confirmed: true,
      bookingTime: "7:00PM TO 8:00PM",
      bookingDate: "16 Feb 2017",
      showBooking: [],
      bookings: [],
      showPopUp: false,
      bookingStauses: [
        { text: "Waiting", value: 1, styles: { color: "#aa43b5" } },
        { text: "Now Serving", value: 3, styles: { color: "#FFE478" } },
        { text: "Cancel", value: 2, styles: { color: "#FF7082" } },
        { text: "Done", value: 4, styles: { color: "#95DA47" } },
      ],
      searchText: "",
      allSaloonMap: {},
      showNotePopUp: false,
      activePage: 1,
    };
  }

  async componentDidMount() {
    const employeeData = await JSON.parse(sessionStorage.getItem("employee"));
    if (employeeData) {
      this.setState(
        { access_token: employeeData.access_token, isloading: true },
        () => {
          Promise.all([this.handleGetAllBooking(), this.gettingAllSaloons()])
            .then(() => {
              this.setState({ isloading: false });
            })
            .catch(() => {
              this.setState({ isloading: false });
            });
        }
      );
    }
  }

  gettingAllSaloons = () => {
    const { access_token } = this.state;

    this.setState({ isloading: true });

    const params = { relations: JSON.stringify(["services", "taxes"]) };
    return getCompanyThatEmployeeAdd(access_token, params).then((res) => {
      if (res.data.success) {
        const allSaloons = res.data.data.filter((salon) => !!salon.companyId);
        const allSaloonMap = {};
        const allSaloonTaxMap = {};
        res.data.companyTaxes.map((companyTax) => {
          if (!allSaloonTaxMap[companyTax.companyId]) {
            allSaloonTaxMap[companyTax.companyId] = [];
          }
          allSaloonTaxMap[companyTax.companyId].push(companyTax);
        });
        allSaloons.map((saloon) => {
          allSaloonMap[saloon.companyId._id] = saloon;
        });
        this.setState({
          allSaloons,
          allSaloonMap,
          allSaloonTaxMap,
        });
      }
    });
  };

  handleGetAllBooking = async () => {
    this.setState({ isloading: true });
    const { access_token } = this.state;
    return getCompletedBooking(access_token)
      .then((res) => {
        if (res.data.success) {
          this.setState({
            showBooking: res.data.data,
            bookings: res.data.data,
          });
        }
      })
      .catch((error) => {
        alert("Somthing went wrong ;(");
      });
  };

  handleFilters = (data) => {
    const { allSaloonMap } = this.state;
    const normalize = (value) => {
      value = value ? value : "";
      return value.toLowerCase();
    };
    data.name = normalize(data.name);
    data.phoneNumber = normalize(data.phoneNumber).replace(/ /g, "");
    const filteredBookings = this.state.bookings.filter((booking) => {
      const isEmployeeIndependent = getNestedValue(
        allSaloonMap,
        [
          getNestedValue(booking, ["companyId", "_id"]),
          "isEmployeeIndependent",
        ],
        true
      );
      if (data.name) {
        if (
          !normalize(getNestedValue(booking, ["userId", "userName"])).match(
            data.name
          ) &&
          !normalize(getNestedValue(booking, "name", "")).match(data.name) &&
          !normalize(getNestedValue(booking, ["userId", "firstName"])).match(
            data.name
          ) &&
          !normalize(getNestedValue(booking, ["userId", "lastNme"])).match(
            data.name
          )
        ) {
          return false;
        }
      }
      if (data.phoneNumber) {
        if (!isEmployeeIndependent) {
          return false;
        } else if (
          !normalize(getNestedValue(booking, ["userId", "phoneNo"], "")).match(
            data.phoneNumber
          ) &&
          !normalize(getNestedValue(booking, "phoneNo", "")).match(
            data.phoneNumber
          )
        ) {
          return false;
        }
      }
      if (data.selectedService) {
        if (
          getNestedValue(booking, ["services", 0, "serviceId", "name"], "") !==
          data.selectedService
        ) {
          return false;
        }
      }
      if (data.startDate || data.endDate) {
        // moment
        if (data.startDate && data.endDate) {
          const date = moment(
            getNestedValue(booking, ["services", 0, "date"], ""),
            "MM-DD-YYYY"
          );
          if (
            !moment(data.startDate).isSameOrBefore(date) ||
            !moment(data.endDate).isSameOrAfter(date)
          ) {
            return false;
          }
        } else {
          if (
            (data.startDate &&
              normalize(getNestedValue(booking, ["services", 0, "date"], "")) !=
                moment(data.startDate).format("MM-DD-YYYY")) ||
            (data.endDate &&
              normalize(getNestedValue(booking, ["services", 0, "date"], "")) !=
                moment(data.endDate).format("MM-DD-YYYY"))
          ) {
            return false;
          }
        }
      }
      if (data.selectedStatus) {
        if (getNestedValue(booking, ["status"], "") != data.selectedStatus) {
          return false;
        }
      }
      return true;
    });
    this.setState({ filteredBookings });
  };

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  handleUpdate = async () => {
    const { access_token, selectBooking } = this.state;
    const payload = {
      ...selectBooking,
      bookingId: selectBooking._id,
      services: selectBooking.services.map((service) => {
        return {
          ...service,
          categoryId:
            typeof service.categoryId == "object"
              ? service.categoryId._id
              : service.categoryId,
          employeeId:
            typeof service.employeeId == "object"
              ? service.employeeId._id
              : service.employeeId,
          serviceId:
            typeof service.serviceId == "object"
              ? service.serviceId._id
              : service.categoryId,
        };
      }),
    };
    delete payload._id;
    delete payload.__v;
    delete payload.userId;
    delete payload.companyId;
    delete payload.updatedDate;
    delete payload.createdDate;
    delete payload.email;

    const res = await editBooking(payload, access_token)
      .then(() => {
        this.setState({ showNotePopUp: false });
        SuccessHelper.handleSuccess("Updated Successfully", true);
        this.handleGetAllBooking();
      })
      .catch(() => {
        ErrorHelper.handleErrors("Failed to update", true);
      });
  };

  onSetSidebarOpen = (open) => {
    this.setState({ sidebarOpen: open });
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };
  handleRoutToChat = (name, employeeID) => {
    this.props.history.push({
      pathname: "/employee-chat",
      state: { name: name, _id: employeeID },
    });
  };

  renderTableBody = (renderBooking) => {
    const {
      activePage,
      allSaloonMap,
      bookings,
      bookingStauses,
      searchText,
      filteredBookings,
      allSaloonTaxMap,
    } = this.state;
    const summary = {
      total: 0,
      tip: 0,
      grandTotal: 0,
    };
    renderBooking = renderBooking.map((booking) => {
      booking.total = booking.totalAmount + (booking.tip || 0);
      if (booking.status == 4) {
        summary.total += booking.totalAmount || 0;
        summary.tip += booking.tip || 0;
        summary.grandTotal += booking.total;
      }
      return booking;
    });
    let previousPageNumber = activePage - 1;
    return (
      <tbody>
        <tr>
          <td />
          <td />
          <td />
          <td className="px-1">
            <Button
              disabled={true}
              size="small"
              style={{
                backgroundColor: "#ff3600",
                color: "white",
                width: "7rem",
              }}
            >
              $ {summary.total.toFixed(2)}
            </Button>
          </td>
          <td className="px-1">
            <Button
              disabled={true}
              size="small"
              style={{
                backgroundColor: "#ff3600",
                color: "white",
                width: "7rem",
              }}
            >
              $ {summary.tip.toFixed(2)}
            </Button>
          </td>
          <td className="px-1">
            <Button
              disabled={true}
              size="small"
              style={{
                backgroundColor: "#ff3600",
                color: "white",
                width: "7rem",
              }}
            >
              $ {summary.grandTotal.toFixed(2)}
            </Button>
          </td>
        </tr>
        {renderBooking.map((val, ind) => {
          if (val.status == 1) return null;
          if (ind >= previousPageNumber * 100 && ind < activePage * 100) {
            return (
              <tr key={val._id} style={{ borderTop: "3px solid #F7F7F7" }}>
                <td>
                  <div className="d-flex align-items-center">
                    <Avatar
                      name={val.userId && val.userId.userName}
                      size="30"
                      color="#4862FF"
                      round={true}
                      textSizeRatio={2.0}
                    />
                    <div>
                      {
                        <span
                          style={{ marginLeft: "10px" }}
                          className="appointment-details-employee-name"
                        >
                          {val.userId && val.userId.userName}
                        </span>
                      }
                      <br />
                      <span
                        style={{ marginLeft: "10px", fontSize: "14px" }}
                        className="appointment-details-employee-other"
                      >
                        {getNestedValue(val, ["services", 0, "date"], "")}{" "}
                        {getNestedValue(val, ["services", 0, "time"], "")}
                      </span>
                    </div>
                  </div>
                </td>
                <td className=" Table_data">
                  <div className="d-flex align-items-center mt-1">
                    <span className="appointment-details-employee-name">
                      {" "}
                      {(getNestedValue(allSaloonMap, [
                        val.companyId._id,
                        "isEmployeeIndependent",
                      ])
                        ? true
                        : false) && (
                        <span>
                          <NumberFormat
                            type={"text"}
                            style={Sytles.removeInputStyle}
                            value={val.phoneNo}
                            disabled
                            format="### ### ####"
                          />
                          <div className="appointment-details-employee-name">
                            {" "}
                            {val.email || ""}{" "}
                          </div>
                        </span>
                      )}
                    </span>
                  </div>
                </td>
                <td className=" Table_data">
                  <div className="d-flex align-items-center mt-1">
                    <span className="appointment-details-employee-name service-column">
                      {getNestedValue(
                        val,
                        ["services", 0, "serviceId", "name"],
                        ""
                      )}
                    </span>
                  </div>
                </td>
                <td className=" Table_data">
                  <div className="d-flex align-items-center mt-1">
                    <span className="appointment-details-employee-name">
                      {`$ ${val.totalAmount}`}
                    </span>
                  </div>
                </td>
                <td className=" Table_data">
                  <div className="d-flex align-items-center mt-1">
                    <span className="appointment-details-employee-name">
                      {`$ ${val.tip || 0}`}
                    </span>
                  </div>
                </td>
                <td className=" Table_data">
                  <div className="d-flex align-items-center mt-1">
                    <span className="appointment-details-employee-name">
                      {`$ ${val.total.toFixed(2) || 0}`}
                    </span>
                  </div>
                </td>
                <td className=" Table_data">
                  <div className="d-flex align-items-center mt-1">
                    <span
                      style={{
                        ...Sytles.makeBold,
                        ...bookingStauses[val.status - 1].styles,
                      }}
                      className="appointment-details-employee-name"
                    >
                      {bookingStauses[val.status - 1].text}
                      {val.isNoShow ? ` - ${i18n.t("no_show")}` : ""}
                    </span>
                  </div>
                </td>
                <td className=" Table_data">
                  <div className="d-flex align-items-center mt-1">
                    <button
                      className="btn btn-blue btn-sm px-0"
                      style={{ width: "5rem" }}
                      onClick={() =>
                        this.setState({ showPopUp: true, selectBooking: val })
                      }
                    >
                      Info
                    </button>
                  </div>
                </td>
                <td className=" Table_data">
                  <div className="d-flex align-items-center mt-1">
                    <button
                      className="btn btn-blue btn-sm px-0"
                      style={{ width: "5rem" }}
                      onClick={() =>
                        this.setState({
                          showNotePopUp: true,
                          selectBooking: val,
                        })
                      }
                    >
                      Notes
                    </button>
                  </div>
                </td>
              </tr>
            );
          }
          // }
        })}
      </tbody>
    );
  };

  renderFilters = () => {
    const {
      allSaloonMap = {},
      employees = [],
      name,
      phoneNumber,
      selectedService,
      startDate,
      endDate,
      selectedEmployee,
      selectedStatus,
    } = this.state;
    const allServices = [];
    Object.values(allSaloonMap).map((saloon) => {
      saloon.companyServices.map((companyService) => {
        if (getNestedValue(companyService.serviceId, ["name"]))
          allServices.push(getNestedValue(companyService.serviceId, ["name"]));
      });
    });
    const set = (data) => {
      this.setState(data);
      const filters = {
        name,
        phoneNumber,
        selectedService,
        startDate,
        endDate,
        selectedEmployee,
        selectedStatus,
        ...data,
      };
      this.handleFilters(filters);
    };
    return (
      <div className="row mx-0 w-100 p-2 mt-1 bg-white">
        <div className="col-md-6 col-xs-12">
          <TextField
            fullWidth
            label={i18n.t("name")}
            value={name}
            onChange={(e) => set({ name: e.target.value })}
          ></TextField>
        </div>
        <div className="col-md-6 col-xs-12">
          <TextField
            fullWidth
            label={i18n.t("container.employe_phone_number")}
            value={phoneNumber}
            onChange={(e) => set({ phoneNumber: e.target.value })}
          ></TextField>
        </div>
        <div className="col-md-6 col-xs-12 align-self-end">
          <div className="row mx-0 px-0 w-100">
            <DatePicker
              wrapperClassName="col-6 pl-0 pr-1"
              selected={startDate}
              onChange={(startDate) => set({ startDate })}
              value={startDate}
              customInput={<TextField id="start" fullWidth />}
              placeholderText={i18n.t("start_date")}
            />
            <DatePicker
              wrapperClassName="col-6 pr-0 pl-1"
              selected={endDate}
              onChange={(endDate) => set({ endDate })}
              value={endDate}
              customInput={<TextField id="end" fullWidth />}
              placeholderText={i18n.t("end_date")}
            />
          </div>
        </div>
        <div className="col-md-6 col-xs-12">
          <FormControl fullWidth>
            <InputLabel>{i18n.t("services")}</InputLabel>
            <Select
              value={selectedService}
              onChange={(e) => set({ selectedService: e.target.value })}
            >
              <MenuItem value={null}>{i18n.t("select_service")}</MenuItem>
              {allServices.map((service) => {
                return (
                  <MenuItem key={service} value={service}>
                    {service}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div className="col-md-6 col-xs-12">
          <FormControl fullWidth>
            <InputLabel>{i18n.t("status")}</InputLabel>
            <Select
              value={selectedStatus}
              onChange={(e) => set({ selectedStatus: e.target.value })}
            >
              <MenuItem value={null}>{i18n.t("Status")}</MenuItem>
              <MenuItem value={4}>{i18n.t("done")}</MenuItem>
              <MenuItem value={3}>{i18n.t("cancel")}</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
    );
  };

  renderPopup = () => {
    const { showPopUp } = this.state;
    return (
      <div
        className="active-service-card-wrapper "
        style={{ display: showPopUp ? "flex" : "none" }}
      >
        <div className="container ">
          <div
            className="col-8 p-3 my-5 bg-white pop_container"
            style={{ borderRadius: "1rem", margin: "auto" }}
          >
            <div className="py-0 px-2 text-right">
              <span
                onClick={() => this.setState({ showPopUp: false })}
                className="h4 cursor-pointer"
              >
                &times;
              </span>
            </div>
            {this.renderBookingMoreInfo()}
          </div>
        </div>
      </div>
    );
  };

  renderNotePopup = () => {
    const {
      showNotePopUp,
      selectBooking = {},
      access_token,
      allSaloonMap = {},
    } = this.state;
    if (showNotePopUp) {
      return (
        <div
          className="active-service-card-wrapper "
          style={{ display: "flex" }}
        >
          <div className="container ">
            <div
              className="col-8 p-3 my-5 bg-white pop_container"
              style={{ borderRadius: "1rem", margin: "auto" }}
            >
              <div
                className="py-0 px-2 mb-2"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span>
                  <h1>{i18n.t("container.booking_notes")}</h1>
                </span>
                <span
                  onClick={() => this.setState({ showNotePopUp: false })}
                  className="h4 cursor-pointer"
                >
                  &times;
                </span>
              </div>

              <BookingNotes
                bookingId={selectBooking._id}
                access_token={access_token}
                hasEditPermission={
                  allSaloonMap[selectBooking.companyId._id]
                    ?.isEmployeeIndependent
                }
              />
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  renderExtras = () => {
    const { showExtrasModal } = this.state;
    if (showExtrasModal) {
      const {
        selectBooking,
        allSaloonMap,
        access_token,
        bookings,
        allProductMap,
      } = this.state;
      let products = getNestedValue(
        allProductMap,
        [selectBooking.companyId._id],
        []
      );
      let services = getNestedValue(
        allSaloonMap,
        [selectBooking.companyId._id, "companyServices"],
        []
      ).map((service) => ({
        _id: service.serviceId._id,
        price: service.price,
        serviceId: service.serviceId,
        name: service.serviceId.name,
      }));
      const onUpdate = (data) => {
        this.setState({ ...data });
      };
      return (
        <AddExtrasModal
          selectBooking={selectBooking}
          products={products}
          access_token={access_token}
          bookings={bookings}
          allServices={services}
          onUpdate={onUpdate}
          handleClose={() => {
            this.setState({ showExtrasModal: false });
          }}
        />
      );
    } else {
      return null;
    }
  };

  genericUpdate = async (body) => {
    const { selectBooking, access_token } = this.state;
    this.setState({
      isloading: true,
    });
    let { bookings } = this.state;
    const services = [
      {
        serviceId: selectBooking.services[0].serviceId?._id,
        categoryId: selectBooking.services[0].categoryId?._id,
        date: selectBooking.services[0].date,
        time: selectBooking.services[0].time,
        employeeId: selectBooking.services[0].employeeId?._id,
      },
    ];
    const { data } = await editBooking(
      {
        bookingId: selectBooking._id,
        status: selectBooking.status,
        services,
        ...body,
      },
      access_token
    );
    let newSelectBooking = selectBooking;
    if (data.success) {
      const newBooking = data.data;
      newBooking.services = newBooking.services.map((service) => {
        return {
          ...service,
          employeeId: service.employeeId._id,
        };
      });
      newSelectBooking = { ...newBooking, index: selectBooking.index };
      if (typeof selectBooking.index === "number") {
        bookings[selectBooking.index] = newSelectBooking;
      }
      this.handleClosePopup();
      this.setState({
        isloading: false,
        bookings: [...bookings],
        selectBooking: newSelectBooking,
        editDetails: false,
        ...body,
      });
    }
  };

  onChangeTip = (tip) => {
    const { tip: oldTip } = this.state;
    if (oldTip != tip) {
      this.genericUpdate({ tip });
    }
  };

  toggleDepositRequired = (depositRequired) => {
    this.genericUpdate({ depositRequired });
  };

  toggleTaxes = (includeTaxes) => {
    this.genericUpdate({ includeTaxes });
  };

  renderBookingMoreInfo = () => {
    const {
      showPopUp,
      selectBooking,
      bookingStatus,
      bookingStatuses,
      isGym,
      showInsertPayment,
      paymentCompleteCallback,
      employeeMap,
      access_token,
      bookings,
      allProductMap,
      allSaloonMap,
      allSaloonTaxMap,
    } = this.state;

    if (showPopUp) {
      return (
        <>
          {this.renderExtras()}
          <AppointmentModal
            showInsertPayment={showInsertPayment}
            paymentCompleteCallback={paymentCompleteCallback}
            setCart={(cartId) => {
              if (cartId) {
                if (
                  typeof selectBooking.index === "number" &&
                  bookings[selectBooking.index]
                ) {
                  bookings[selectBooking.index].cartId = cartId;
                }
                this.setState({
                  selectBooking: { ...selectBooking, cartId },
                  bookings,
                });
              }
            }}
            open={showPopUp}
            selectBooking={selectBooking}
            employeeMap={employeeMap}
            isGym={isGym}
            isSalon={true}
            handleDrawerClose={(ev) => {
              this.setState({ showPopUp: false });
            }}
            statusButtonClicked={(status) => {
              Swal.fire({
                title: i18n.t("are_you_sure_you_want_to_finalize_this_booking"),
                showCancelButton: true,
                cancelButtonText: "No",
                confirmButtonText: "Yes",
              }).then((e) => {
                const { dismiss, isConfirmed, isDismissed } = e;
                if (!isDismissed || dismiss != "backdrop") {
                  if (isConfirmed) {
                    this.setState(
                      {
                        showInsertPayment: true,
                        paymentCompleteCallback: () =>
                          this.genericUpdate({ isFinalized: true, status }),
                      },
                      () => {
                        this.setState({
                          showInsertPayment: false,
                        });
                      }
                    );
                  } else {
                    this.genericUpdate({ isFinalized: isConfirmed, status });
                  }
                }
              });
            }}
            onProductChange={(e, index) => {
              this.setState({ isloading: true });
              updateCartProduct(
                {
                  productId: selectBooking.cartId.products[index]._id,
                  cartId: selectBooking.cartId._id,
                },
                { quantity: e },
                access_token
              )
                .then(({ data }) => {
                  if (data.success) {
                    selectBooking.cartId.products[index].quantity =
                      data.data.quantity;
                    if (typeof selectBooking.index === "number") {
                      bookings[selectBooking.index] = selectBooking;
                    }
                    this.setState({
                      selectBooking: { ...selectBooking },
                      bookings,
                    });
                  } else {
                    if (data.code) {
                      ErrorHelper.handleErrors(
                        i18n.t(data.code, { ...data.data }),
                        true
                      );
                    } else {
                      ErrorHelper.handleErrors("Failed to Add Item", true);
                    }
                  }
                })
                .finally(() => {
                  this.setState({ isloading: false });
                });
            }}
            onProductDelete={(index) => {
              this.setState({ isloading: true });
              removeProduct(
                {
                  productId: selectBooking.cartId.products[index]._id,
                  cartId: selectBooking.cartId._id,
                },
                access_token
              )
                .then(({ data }) => {
                  if (data.success) {
                    if (
                      typeof selectBooking.index === "number" &&
                      bookings[selectBooking.index]
                    ) {
                      bookings[selectBooking.index].cartId = data.data;
                    }
                    this.setState({
                      selectBooking: { ...selectBooking, cartId: data.data },
                      bookings,
                    });
                  }
                })
                .finally(() => {
                  this.setState({ isloading: false });
                });
            }}
            onServiceDelete={(index) => {
              this.setState({ isloading: true });
              removeExtra(
                {
                  extraId: selectBooking.extras[index]._id,
                  bookingId: selectBooking._id,
                },
                access_token
              )
                .then(({ data }) => {
                  if (data.success) {
                    if (
                      typeof selectBooking.index === "number" &&
                      bookings[selectBooking.index]
                    ) {
                      bookings[selectBooking.index].extras = data.data.extras;
                    }
                    this.setState({
                      selectBooking: {
                        ...selectBooking,
                        extras: data.data.extras,
                      },
                      bookings,
                    });
                  }
                })
                .finally(() => {
                  this.setState({ isloading: false });
                });
            }}
            onNotesClick={() => this.setState({ showNotePopUp: true })}
            onAddClick={() => this.setState({ showExtrasModal: true })}
            companyTaxes={allSaloonTaxMap[selectBooking.companyId._id]}
            toggleDepositRequired={this.toggleDepositRequired}
            toggleTaxes={this.toggleTaxes}
            onChangeTip={this.onChangeTip}
          ></AppointmentModal>
        </>
      );
    } else {
      return null;
    }
  };

  renderUserTable = () => {
    const { bookings, searchText, filteredBookings } = this.state;
    if (!bookings) return null;
    let renderBooking = bookings;
    if (filteredBookings) {
      renderBooking = filteredBookings;
    } else if (searchText)
      renderBooking = bookings.filter((val) => {
        if (
          getNestedValue(val.userId, "userName") &&
          (val.userId.userName
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
            val.phoneNo.includes(searchText))
        )
          return true;
        return false;
      });
    return (
      <div className="col-12 p-3">
        <div className="table-responsive sticky-header">
          <table className="table table-borderless appointment-details-table">
            <thead>
              <tr>
                <th scope="col">
                  <span className="appointment-details-th-text">
                    <Translation>
                      {(t) => t("container.advancedSearchDialog_name")}
                    </Translation>
                  </span>
                </th>
                <th scope="col">
                  <span className="appointment-details-th-text">
                    <Translation>
                      {(t) => t("container.employe_custom_details")}
                    </Translation>
                  </span>
                </th>
                <th scope="col">
                  <span className="appointment-details-th-text">
                    <Translation>
                      {(t) => t("container.profile_service")}
                    </Translation>
                  </span>
                </th>
                <th scope="col">
                  <div className="row">
                    <span className="appointment-details-th-text">
                      <Translation>{(t) => t("by_price")}</Translation>
                    </span>
                  </div>
                </th>
                <th scope="col">
                  <div className="row">
                    <span className="appointment-details-th-text">
                      <Translation>{(t) => t("tip")}</Translation>
                    </span>
                  </div>
                </th>
                <th scope="col">
                  <div className="row">
                    <span className="appointment-details-th-text">
                      <Translation>{(t) => t("grand_total")}</Translation>
                    </span>
                  </div>
                </th>
                <th scope="col">
                  <span className="appointment-details-th-text">
                    <Translation>
                      {(t) => t("container.profile_status")}
                    </Translation>
                  </span>
                </th>
                <th scope="col">
                  <span className="appointment-details-th-text">Info</span>
                </th>
                <th scope="col">
                  <span className="appointment-details-th-text">Notes</span>
                </th>
              </tr>
            </thead>
            {this.renderTableBody(renderBooking)}
          </table>
        </div>
      </div>
    );
  };

  renderUserTable = () => {
    const { bookings, searchText, filteredBookings } = this.state;
    if (!bookings) return null;
    let renderBooking = bookings;
    if (filteredBookings) {
      renderBooking = filteredBookings;
    } else if (searchText)
      renderBooking = bookings.filter((val) => {
        if (
          getNestedValue(val.userId, "userName") &&
          (val.userId.userName
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
            val.phoneNo.includes(searchText))
        )
          return true;
        return false;
      });
    const summary = {
      total: 0,
      tip: 0,
      grandTotal: 0,
    };
    renderBooking = renderBooking.map((booking) => {
      booking.total = booking.totalAmount + (booking.tip || 0);
      if (booking.status == 4) {
        summary.total += booking.totalAmount || 0;
        summary.tip += booking.tip || 0;
        summary.grandTotal += booking.total;
      }
      return booking;
    });
    return (
      <div className="col-12 p-3">
        <div className="table-responsive sticky-header">
          <table
            style={{ marginBottom: 100 }}
            className="table table-borderless appointment-details-table"
          >
            <thead>
              <tr>
                <th scope="col">
                  <span className="appointment-details-th-text">
                    <Translation>
                      {(t) => t("container.advancedSearchDialog_name")}
                    </Translation>
                  </span>
                </th>
                <th scope="col">
                  <span className="appointment-details-th-text">
                    <Translation>
                      {(t) => t("container.employe_custom_details")}
                    </Translation>
                  </span>
                </th>
                <th scope="col">
                  <span className="appointment-details-th-text">
                    <Translation>
                      {(t) => t("container.profile_service")}
                    </Translation>
                  </span>
                </th>
                <th scope="col">
                  <div className="row">
                    <span className="appointment-details-th-text">
                      <Translation>{(t) => t("by_price")}</Translation>
                    </span>
                    <button
                      className="btn btn-light btn-sm mx-0"
                      style={{ width: "7rem" }}
                    >
                      $ {summary.total.toFixed(2)}
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div className="row">
                    <span className="appointment-details-th-text">
                      <Translation>{(t) => t("tip")}</Translation>
                    </span>
                    <button
                      className="btn btn-light btn-sm mx-0"
                      style={{ width: "7rem" }}
                    >
                      $ {summary.tip.toFixed(2)}
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div className="row">
                    <span className="appointment-details-th-text">
                      <Translation>{(t) => t("grand_total")}</Translation>
                    </span>
                    <button
                      className="btn btn-light btn-sm mx-0"
                      style={{ width: "7rem" }}
                    >
                      $ {summary.grandTotal.toFixed(2)}
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <span className="appointment-details-th-text">
                    <Translation>
                      {(t) => t("container.profile_status")}
                    </Translation>
                  </span>
                </th>
                <th scope="col">
                  <span className="appointment-details-th-text">Info</span>
                </th>
                <th scope="col">
                  <span className="appointment-details-th-text">Notes</span>
                </th>
              </tr>
            </thead>
            {this.renderTableBody(renderBooking)}
          </table>
        </div>
      </div>
    );
  };

  onChangeSearchText = (searchText) => {
    this.setState({ searchText });
  };

  renderSearchBar = () => {
    const { searchText, filteredBookings } = this.state;
    return (
      <>
        <div className="col-12 col-lg-4 col-md-4">
          <span>
            <span className="saloon-dashboard-heading">
              {i18n.t("container.profile_booking_history")}:
            </span>
          </span>
        </div>
      </>
    );
  };

  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber });
  };

  renderPagination = () => {
    const { bookings, activePage, filteredBookings } = this.state;
    return (
      <div className="col-12 py-2 mb-3 d-flex justify-content-center">
        <Pagination
          hideNavigation
          activePage={activePage}
          itemsCountPerPage={100}
          totalItemsCount={
            filteredBookings ? filteredBookings.length : bookings.length
          }
          // pageRangeDisplayed={3}
          onChange={(val) => this.handlePageChange(val)}
          firstPageText={
            <img src={Images.pagination_back} style={{ width: 15 }} />
          }
          lastPageText={
            <img src={Images.pagination_forward} style={{ width: 15 }} />
          }
          itemClass="page-item"
          linkClass="page-link"
        />
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.renderLoader()}
        <div className="content-container">
          <div className="row mx-0">
            {this.renderSearchBar()}
            {this.renderFilters()}
            {this.renderUserTable()}
            {this.renderPagination()}
            {this.renderPopup()}
            {this.renderNotePopup()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(Booking);
