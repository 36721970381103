// @flow
import Immutable from "seamless-immutable";
import * as types from "../../actions/ActionTypes";

const initialState = Immutable({
  isFetching: false,
  errorMessage: "",
  data: [],
  accountDetails: [],
});

export default (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case types.ORDERS.GET_SAGA:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case types.ORDERS.GET:
      return Immutable.merge(state, {
        isFetching: false,
        data: action.data,
      });
    case types.LOGOUT:
      return initialState;
    case types.CLEAR_LOGOUT:
      return initialState;
    default:
      return state;
  }
};
