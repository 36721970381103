import { connect } from "react-redux";
import React, { Component } from "react";
import { Images } from "../../../theme";
import { DotsLoader } from "../../../components";
import { ErrorHelper } from "../../../helpers";
import {
  getTemplates,
  companyUpdateTemplate,
} from "../../../config/simpleApiCalls";

import "./styles.css";
import Sytles from "./sytles";
import i18n from "../../../i18n";
import { set } from "../../../redux/actions/User";

class ChooseTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTemplatePreview: false,
      templatePreviewImage: null,
      activeTemplateId: null,
      templateList: [],
      templateRoute: null,
      selectedId: ''
    };
  }

  async componentDidMount() {
    const saloonData = await JSON.parse(sessionStorage.getItem("saloon"));
    const companyData = await JSON.parse(sessionStorage.getItem("company"));
    if (saloonData && companyData) {
      this.setState(
        {
          access_token: saloonData.access_token,
          activeTemplateId: companyData.templateID,
        },
        () => {
          this.getAllTemplate();
        }
      );
    }
  }

  getAllTemplate = () => {
    this.setState({ isloading: true });
    const { access_token } = this.state;
    getTemplates(access_token)
      .then((res) => {


        if (res.data.success && res.data.data) {
          this.setState({ isloading: false, templateList: res.data.data });
        } else {
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        console.log(error, "errorrrrrrrrrrrrrrrrrrr");
      });
  };

  handleCompanyUpdateTemplate = (id) => {
    this.setState({ isloading: true });
    const { access_token } = this.state;
    const payload = { id };
    companyUpdateTemplate(payload, access_token)
      .then((res) => {
        if (res.data.success) {
          this.props.set({
            company: JSON.stringify(res.data.data),
          })
          this.setState(
            { isloading: false, activeTemplateId: res.data.data.templateID },
            () => this.getAllTemplate()
          );
        }
      })
      .catch((error) => {
        console.log(error, "errorrrrrrrrrrrrrrrrrrr");
      });
  };

  handleNavigation = (route) => {
    this.props.history.push(route, this.state.selectedId)
  };

  handlePreviewTemplate = (fullImage, ind, temp_id) => {
    const { showTemplatePreview } = this.state;
    this.setState({
      showTemplatePreview: showTemplatePreview ? false : true,
      templatePreviewImage: fullImage,
      templateRoute: ind + 1,
      selectedId: temp_id
    });
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  renderSaloonDashboardHeading = () => {
    return (
      <div className="col-12 px-3 pt-3">
        <div className="row">
          <div className="col-md-8 col-lg-9 d-flex flex-column justify-content-center">
            <h1 className="saloon-dashboard-heading">{i18n.t('container.dashboard_select_template')}</h1>
            {/* <p className="saloon-dashboard-sub-heading">
              Lorem ipsum dolor sit amet, consectetur
            </p> */}
          </div>
        </div>
      </div>
    );
  };

  ResentlyAddedDropdown = () => {
    return (
      <div class="row form-group px-0 ">
        <select
          id="select-category"
          class="form-control saloon-category-input"
          onChange={(text) => { }}
        >
          <option selected>Resently Added</option>
          <option selected>Resently Added</option>
          <option selected>Resently Added</option>
        </select>
      </div>
    );
  };

  TemplateOption = () => {
    return (
      <ul class="list-group list-group-horizontal">
        <li class="list-group-item li-padding li-radius-left">{i18n.t('container.dashboard_all')}</li>
        <li class="list-group-item li-padding">{i18n.t('container.dashboard_commercial')}</li>
        <li class="list-group-item li-padding li-radius-right">{i18n.t('container.dashboard_free')}</li>
      </ul>
    );
  };

  TemplateOptionheader = () => {
    return (
      <div
        className="row Templates-padding-top_bottom Templates-margin-left-right"
        style={{ marginBottom: 30 }}
      >
        <div className="col-md-4">{this.TemplateOption()}</div>
        <div className="col-md-3"></div>
        <div className="col-md-2">
          <div className="Templates-option-padding">{i18n.t('container.dashboard_sort_by')}</div>
        </div>
        <div className="col-md-3">
          <div>{this.ResentlyAddedDropdown()}</div>
        </div>
      </div>
    );
  };


  renderTemplates = () => {
    const { templateList, activeTemplateId } = this.state;
    return (
      <div className="row">
        {templateList.map((val, ind) => {
          let index = ind + 1
          console.log(val, "VALUESS IN RENDER TEMP");
          if (val._id === activeTemplateId) {
            return (
              <div className="col-12 col-md-6 col-lg-4 p-3">
                <div class="card selling-card shadow">
                  <img
                    
                    style={{height:300,flex:1,width:null}}
                    // src={val.coverImage && val.coverImage.url}
                    src={val.coverImage.url != "null" ?
                      val.coverImage.url : index == 1 ?
                        Images.saloon_template_barber : index == 2 ?
                          Images.saloon_template2_slider : Images.saloon_template3_main}
                    alt="Card image cap"
                  />
                  <div class="card-body">
                    <h5 class="card-title">
                      <strong>{val.name}</strong>
                    </h5>
                    <p class="card-text">{val.description}</p>
                  </div>
                  <div class="card-footer bg-white border-0">
                    <div
                      className={
                        "d-flex justify-content-start align-items-center"
                      }
                    >
                      <button
                        type="button"
                        className="btn btn-sm"
                        id="activated-btn"
                        onClick={() => { }}
                      >{i18n.t('container.dashboard.activated')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        })}
        {templateList.map((val, ind) => {
          let index = ind + 1
          if (val._id !== activeTemplateId) {
            return (
              <div className="col-12 col-md-6 col-lg-4 p-3">
                <div class="card selling-card shadow">
                  <img
                    
                    style={{height:300,flex:1,width:null}}
                    src={val.coverImage.url != "null" ?
                      val.coverImage.url : index == 1 ?
                        Images.saloon_template_barber : index == 2 ?
                          Images.saloon_template2_slider : Images.saloon_template3_main}
                    alt="Card image cap"
                    alt="Card image cap"
                  />
                  <div class="card-body">
                    <h5 class="card-title">
                      <strong>{val.name}</strong>
                    </h5>
                    <p class="card-text">{val.description}</p>
                  </div>
                  <div class="card-footer bg-white border-0">
                    <div
                      className={
                        "d-flex justify-content-end align-items-center"
                      }
                    >
                      <button
                        type="button"
                        className="btn btn-outline-light btn-sm"
                        onClick={() =>
                          this.handleCompanyUpdateTemplate(val._id)
                        }
                      >
                        {i18n.t('container.active')}
                      </button>
                      <button
                        type="button"
                        className="btn btn-light btn-sm"
                        onClick={() =>
                          this.handlePreviewTemplate(val.fullImage.url, ind, val._id)
                        }
                      >
                        {i18n.t('container.dashboard_preview')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        })}
      </div>
    );
  };

  renderPreviewTemplate = () => {
    const { showTemplatePreview, templatePreviewImage } = this.state;
    console.log('this.state====', this.state);
    return (
      <div
        className="popup-wrapper"
        style={{ display: showTemplatePreview ? "flex" : "none" }}
      >
        <div className="container">
          <div className="col-12 p-3 my-3 bg-white">
            <div className="d-flex justify-content-between align-items-center mb-2">
              <button
                type="button"
                className="btn btn-light btn-sm"
                onClick={() => this.handleNavigation(`/saloon-edit-template-${this.state.templateRoute}`)}
              >{i18n.t('container.dashboard_edit')}</button>
              <span
                className="template-cross-btn"
                onClick={() => this.handlePreviewTemplate()}
              >&times;</span>
            </div>
            <img
              src={templatePreviewImage}
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.renderLoader()}
        <div className="content-container">
          <div className="row mx-0">
            {this.renderSaloonDashboardHeading()}
            <div
              className="col-12 p-3 mt-4 bg-white"
              style={{ borderRadius: "1rem" }}
            >
              {/* {this.TemplateOptionheader()} */}
              {this.renderTemplates()}
            </div>
          </div>
        </div>
        {this.renderPreviewTemplate()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const action = { set };

export default connect(mapStateToProps, action)(ChooseTemplate);
