import React, { useState } from "react";
import { connect } from "react-redux";
import i18n from "../../../i18n";
import BaseModal from "../../BaseModal";
import PrimaryButton from "../../Core/PrimaryButton";
import Select from "../../Core/Select";

const SendCommunicationsModal = ({ handleClose, onSubmit }) => {
  const yesNoOptions = [
    { value: "yes", name: i18n.t("yes") },
    { value: "no", name: i18n.t("no") },
  ];
  const [customerCancelled, setCustomerCancelled] = useState(true);
  const [notifyCustomer, setNotifyCustomer] = useState(false);

  return (
    <BaseModal
      open={true}
      containerClassName={"half-container"}
      {...{
        title: <div>{i18n.t("cancel")}</div>,
        content: (
          <div>
            <Select
              options={yesNoOptions}
              defaultValue={"yes"}
              excludeEmptyValue
              label={i18n.t("customer_cancelled")}
              onChange={(e) => setCustomerCancelled(e.target.value == "yes")}
            />
            <Select
              options={yesNoOptions}
              defaultValue={"no"}
              excludeEmptyValue
              label={i18n.t("notify_customer")}
              onChange={(e) => setNotifyCustomer(e.target.value == "yes")}
            />
          </div>
        ),
        actions: (
          <div className="row mx-0 px-0 justify-content-end">
            <PrimaryButton
              onClick={() => onSubmit({ customerCancelled, notifyCustomer })}
              label={i18n.t("confirm")}
            />
          </div>
        ),
      }}
      onClose={() => handleClose()}
    />
  );
};

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(SendCommunicationsModal);
