import { connect } from "react-redux";
import React, { Component } from "react";
import i18n from "../../../i18n";
import { Translation, withTranslation } from "react-i18next";
import "./styles.css";

class Footer extends Component {
  render() {
    return (
      <div
        className="container-fluid"
        style={{
          backgroundColor: "#EBEBEB",
          position: "fixed",
          bottom: 0,
          right: 0,
          left: 0,
        }}
      >
        <div className="container">
          <div className="col-12 py-3">
            <p className="saloon-footer-text">
              &copy; <Translation>{(t) => t("copyright")}</Translation>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(Footer);
