import React from "react";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import styled from "styled-components";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import DetailsField from "./DetailsField.component";

const SelectfieldWrapper = styled.div`
  width: 100%;
`;
const GenerateSelect = ({
  field,
  handleSubmit,
  readonly,
  error,
  answer,
  detailsRequired,
  details,
}) => {
  const { fieldOptions = "" } = field;
  const inputTypes = fieldOptions.split("-");

  const inputTypeOptions = inputTypes.map((option) => {
    const cap = option.charAt(0).toUpperCase() + option.slice(1);
    return { value: option, label: cap };
  });

  const handleOnChange = (e, choice = null, type) => {
    if (handleSubmit !== undefined) {
      handleSubmit(e.target.value, field._id, type);
    }
  };
  return (
    <>
      <SelectfieldWrapper>
        <h6>{field.fieldName}</h6>
        <FormControl
          variant="outlined"
          style={{ width: "100%" }}
          error={Boolean(error)}
        >
          <Select
            disabled={readonly}
            defaultValue={answer}
            onChange={(e) => handleOnChange(e, null, "answer")}
          >
            {inputTypeOptions.map((type, i) => {
              return (
                <MenuItem key={`${type}-${i}`} value={type.value}>
                  {type.label}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>{error}</FormHelperText>
        </FormControl>
        {detailsRequired && (
          // <TextField
          //   onChange={(e) => handleOnChange(e, "details")}
          //   value={details}
          //   type="text"
          //   variant="outlined"
          //   inputProps={{ readOnly: readonly }}
          // />
          <DetailsField
            handleOnChange={handleOnChange}
            details={details}
            readonly={readonly}
          />
        )}
      </SelectfieldWrapper>
    </>
  );
};

export default GenerateSelect;
