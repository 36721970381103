import React, { useState } from "react";
import _ from "lodash";
// material UI components
import {
  Drawer as MUIDrawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core/";

// Material UI icons for the side drawer fields
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import DateRangeIcon from "@material-ui/icons/DateRange";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import ListAltIcon from "@material-ui/icons/ListAlt";

// styles
import "../styles/sideDrawer.sass";

// variables
const menuItems = [
  {
    menuTitle: "Profile",
    icon: <AccountCircleIcon />,
    display: "displayProfile",
  },
  {
    menuTitle: "Booking History",
    icon: <DateRangeIcon />,
    display: "displayBooking",
  },
  {
    menuTitle: "Wallet",
    icon: <CreditCardIcon />,
    display: "displayWallet",
  },
  {
    menuTitle: "Chat",
    icon: <QuestionAnswerIcon />,
    display: "displayChat",
  },
  {
    menuTitle: "Waiting List",
    icon: <ListAltIcon />,
    display: "displayWaitingList",
  },
  {
    menuTitle: "Packages",
    icon: <ListAltIcon />,
    display: "displayPackages",
  },
  // {
  //   menuTitle: "Cards",
  //   icon: <CreditCardIcon />,
  //   display: "displayCards",
  // },
];

const SideDrawer = ({ display, handleDisplay }) => {
  const [selectedMenu, setselectedMenu] = useState("displayProfile");

  const changeDisplay = (section) => {
    setselectedMenu(section);
    let newDisplay = { ...display };
    Object.keys(newDisplay).map((field) => {
      return section === field
        ? (newDisplay[field] = true)
        : (newDisplay[field] = false);
    });
    handleDisplay(newDisplay);
  };
  return (
    <div className="sideDrawer">
      <MUIDrawer variant="permanent" anchor="left">
        <List className="drawerList">
          {menuItems.map((text, index) => {
            const { menuTitle, icon, display } = text;

            return (
              <ListItem
                className={
                  selectedMenu === display
                    ? "drawerListItems selected"
                    : "drawerListItems"
                }
                button
                key={`menuTitle-${menuTitle}`}
                onClick={() => changeDisplay(display)}
              >
                <ListItemIcon key={`listIcon-${menuTitle}`}>
                  {icon}
                </ListItemIcon>
                <ListItemText
                  key={`listTitle-${menuTitle}`}
                  primary={menuTitle}
                />
              </ListItem>
            );
          })}
        </List>
      </MUIDrawer>
    </div>
  );
};

export default SideDrawer;
