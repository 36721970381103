// @flow

import { FORM_ANSWER } from "./ActionTypes";

export function getByIdRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: FORM_ANSWER.GET_BY_ID_SAGA,
  };
}

export function getById(data) {
  return {
    data,
    type: FORM_ANSWER.GET_BY_ID,
  };
}

export function getRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: FORM_ANSWER.GET_SAGA,
  };
}

export function get(data) {
  return {
    data,
    type: FORM_ANSWER.GET,
  };
}

export function submitFilledFormRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: FORM_ANSWER.SUBMIT_FILLED_FORM_SAGA,
  };
}

export function submitFilledForm(data) {
  return {
    data,
    type: FORM_ANSWER.SUBMIT_FILLED_FORM,
  };
}

export function markAsViewedRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: FORM_ANSWER.MARK_AS_VIEWED_SAGA,
  };
}

export function markAsViewed(data) {
  return {
    data,
    type: FORM_ANSWER.MARK_AS_VIEWED,
  };
}

export function requestFailure() {
  return {
    type: FORM_ANSWER.REQUEST_FAILURE,
  };
}
