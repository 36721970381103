import { connect } from "react-redux";
import React, { useMemo, useRef, useState } from "react";
import { DropdownList, DateTimePicker } from "react-widgets";
import DatePicker from "react-datepicker";
import moment from "moment";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ManageExtras from "../../Extras/ManageExtras";
import Autocomplete from "react-autocomplete";

import "./styles.scss";
import i18n from "../../../i18n";
import { Images } from "../../../theme";
import { Translation } from "react-i18next";
import { getNestedValue } from "../../../util/objectMethods";
import { getUserPackagesByPhoneRequest as getUserPackagesRequest } from "../../../redux/actions/Packages";
import PrimaryButton from "../../Core/PrimaryButton";
import BaseModal from "../../BaseModal";
import UserPackages from "../../UserPackages/UserPackages.component";
import { DotsLoader } from "../..";
import { addCustomBooking } from "../../../config/simpleApiCalls";
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import MUAutocomplete from "@material-ui/lab/Autocomplete";
import CustomerEdit from "../../Saloon/Customer/CustomerEdit";
import { AccountCircle } from "@material-ui/icons";
import { InputAdornment } from "@material-ui/core";

moment.locale("en");

const CreateAppointment = ({
  accessToken,
  customers,
  phoneNumber,
  email,
  firstName,
  totalAmount,
  formErrors,
  onChange,
  categoryList,
  selectedCategory,
  includeTaxes,
  getServices,
  selectServices,
  getSalonServices,
  allSaloons,
  lockFields,
  onChangeSelectServices,
  onChangeSelectSalon = () => {},
  renderDateTimeEmployeeSection,
  isGym,
  isSalon,
  getUserPackagesRequest,
  userPackages = [],
  companyId,
  employeeId,
  date,
  time,
}) => {
  selectServices = selectServices ? JSON.parse(selectServices) : "";
  const [packageModalOpen, setPackageModalOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [showCustomerModal, setShowCustomerModal] = useState(null);

  const inputAutocomplete = !!window.chrome ? "chrome-off" : "off";
  const [loading, setLoading] = useState(false);
  const bookNow = async (payload) => {
    setLoading(true);
    await addCustomBooking(payload, accessToken)
      .then((res) => {
        setLoading(false);
        if (res.data.success) {
          SuccessHelper.handleSuccess(
            res?.data?.msg || "Booking Created",
            true
          );
        } else {
          ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
        }
      })
      .catch((e) => {
        setLoading(false);
        ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
      });
  };
  const subcategoryServicesMap = {};

  (getSalonServices || []).map((service) => {
    if (!subcategoryServicesMap[service.subcategory]) {
      subcategoryServicesMap[service.subcategory] = [];
    }
    subcategoryServicesMap[service.subcategory].push(service);
  });
  const serviceOptions = [].concat(...Object.values(subcategoryServicesMap));
  return (
    <div className="create-appointment my-1">
      <DotsLoader isloading={loading} />
      {showCustomerModal ? (
        <CustomerEdit
          open={showCustomerModal}
          setOpen={() => setShowCustomerModal(null)}
          active={selectedCustomer}
          accessToken={accessToken}
          isGym={isGym}
          handleEdit={() => {}}
          setAccountBalance={() => {}}
        />
      ) : null}
      <BaseModal
        open={packageModalOpen}
        onClose={() => setPackageModalOpen(false)}
        {...{
          content: (
            <UserPackages
              accessToken={accessToken}
              renderDateTimeEmployeeSection={renderDateTimeEmployeeSection}
              isSalon={isSalon}
              companyId={companyId}
              employeeId={employeeId}
              phoneNo={phoneNumber}
              email={email}
              name={firstName}
              date={date}
              time={time}
              onChangeSelectServices={onChangeSelectServices}
              onChangeSelectSalon={onChangeSelectSalon}
              bookNow={bookNow}
            />
          ),
          title: i18n.t("packages"),
        }}
      />
      <div>
        <div>
          <div className="row mx-0 justify-content-between align-items-center">
            <div
              style={{
                fontSize: 20,
                marginTop: 10,
                marginBottom: 10,
                fontWeight: "bold",
              }}
            >
              <Translation>
                {(t) => t("container.enter_customer_details")}
              </Translation>
            </div>
            {userPackages.length ? (
              <PrimaryButton
                label={i18n.t("packages")}
                onClick={() => setPackageModalOpen(true)}
              />
            ) : null}
          </div>
          <div className="row mx-0">
            <div className="form-label-group mb-3 col-12 col-md-6 pl-0 pr-md-15">
              <Autocomplete
                placeholder={i18n.t("enter_phone_number_placeholder_type")}
                renderInput={(props) => {
                  return (
                    <TextField
                      id="phoneNumber"
                      {...props}
                      onBlur={() => {
                        getUserPackagesRequest(
                          {
                            access_token: accessToken,
                            phoneNo: phoneNumber,
                            companyIds: isSalon
                              ? companyId
                              : allSaloons
                                  .map((saloon) => saloon._id)
                                  .join(","),
                          },
                          {}
                        );
                      }}
                      {...(isSalon && selectedCustomer
                        ? {
                            InputProps: {
                              endAdornment: (
                                <InputAdornment position="end">
                                  <AccountCircle
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setShowCustomerModal(true);
                                    }}
                                  />
                                </InputAdornment>
                              ),
                              ...props.InputProps,
                            },
                          }
                        : {})}
                    />
                  );
                }}
                items={phoneNumber ? customers : []}
                shouldItemRender={(item, value) =>
                  item.phoneNo.toLowerCase().indexOf(value.toLowerCase()) >
                    -1 ||
                  item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
                }
                getItemValue={(item) => item.phoneNo}
                renderItem={(item, highlighted) => (
                  <MenuItem key={item._id}>{item.name}</MenuItem>
                )}
                menuStyle={{
                  zIndex: "9998",
                  position: "absolute",
                  background: "white",
                  top: 64,
                  left: 0,
                }}
                inputProps={{
                  className: "full-width",
                  autoComplete: inputAutocomplete,
                  autocomplete: inputAutocomplete,
                  label: i18n.t("container.employe_phone_number"),
                  error: !!formErrors.phoneNumberError,
                }}
                wrapperStyle={{
                  width: "100%",
                }}
                renderMenu={(children) => {
                  return <div className="menu">{children.slice(0, 15)}</div>;
                }}
                value={phoneNumber}
                onChange={(e) => onChange({ phoneNumber: e.target.value })}
                onSelect={(value, data) => {
                  const { phoneNo, email, name, isBlackListed } = data;
                  setSelectedCustomer(data);
                  getUserPackagesRequest(
                    {
                      access_token: accessToken,
                      phoneNo,
                      companyIds: isSalon
                        ? companyId
                        : allSaloons.map((saloon) => saloon._id).join(","),
                    },
                    {}
                  );
                  onChange({
                    firstName: name,
                    phoneNumber: phoneNo,
                    email,
                    isBlackListed,
                  });
                }}
              />
              {!!formErrors.phoneNumberError && (
                <FormHelperText>{formErrors.phoneNumberError}</FormHelperText>
              )}
            </div>
            <div className="form-label-group col-12 col-md-6 pl-0">
              <TextField
                id="email"
                label={i18n.t("name")}
                value={firstName}
                placeholder={i18n.t("enter_name_placeholder_type")}
                onChange={(e) => onChange({ firstName: e.target.value })}
                helperText={formErrors.firstNameError}
                error={!!formErrors.firstNameError}
              />
            </div>
            <div className="form-label-group mb-3 col-md-6 pl-0">
              <TextField
                id="email"
                label={i18n.t("container.profile_email")}
                value={email}
                placeholder={i18n.t("enter_email_placeholder")}
                onChange={(e) => onChange({ email: e.target.value })}
                helperText={formErrors.emailError}
                error={!!formErrors.emailError}
              />
            </div>
            {!isGym && (
              <div className="form-label-group mb-3 col-md-6 pl-0">
                <TextField
                  id="totalAmount"
                  label={i18n.t("total_amount")}
                  value={totalAmount}
                  placeholder={0}
                  helperText={formErrors.totalAmountError}
                  error={!!formErrors.totalAmountError}
                />
              </div>
            )}
            <div className="form-label-group mb-3 col-md-12 pl-0 pb-2">
              <TextField
                id="notes"
                label={i18n.t("notes")}
                multiline
                rows={2}
                placeholder={i18n.t("enter_notes_placeholder")}
                onChange={(e) => onChange({ notes: e.target.value })}
              />
            </div>
          </div>

          <div
            style={{
              fontSize: 20,
              marginTop: 10,
              marginBottom: 10,
              fontWeight: "bold",
            }}
          >
            <Translation>
              {(t) => t("container.enter_appointment_details")}
            </Translation>
          </div>
          {!isSalon && (
            <div className="form-label-group form-group col-12 px-0 mt-2">
              <FormControl>
                <InputLabel id="salon">
                  {i18n.t("container.profile_salon")}
                </InputLabel>
                <Select
                  onChange={(e) => onChangeSelectSalon(e)}
                  placeholder={i18n.t("container.select_salon")}
                >
                  {allSaloons.map((val, ind) => (
                    <MenuItem key={val._id} value={val?.companyId?._id}>
                      {val.companyId?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
          <div>
            <Translation>{(t) => t("container.services_category")}</Translation>
          </div>
          <div className="row mx-0">
            {categoryList.map((val) => {
              return (
                <div className="col-md-4 px-2">
                  <Button
                    onClick={() => {
                      onChange(
                        {
                          selectedCategory: val,
                          ...(lockFields
                            ? {}
                            : { selectDateAndTime: "", newDate: "" }),
                        },
                        () => getServices(val)
                      );
                    }}
                    style={{
                      backgroundColor: "#002147",
                      color: "white",
                    }}
                    className="mt-3 full-width"
                  >
                    {val?.name ? (
                      val.name
                    ) : (
                      <div>
                        <Translation>
                          {(t) => "container.no_category"}
                        </Translation>
                      </div>
                    )}
                    {selectedCategory == val ? (
                      <img
                        src={Images.step_done_tick_orange}
                        style={{
                          height: 20,
                          width: 20,
                          zIndex: 3,
                          position: "absolute",
                          right: "-2px",
                          top: "-2px",
                          backgroundColor: "white",
                          borderRadius: "1000px",
                        }}
                        // styles={ImagePosition}
                      />
                    ) : null}
                  </Button>
                </div>
              );
            })}
          </div>
          <div className="form-label-group form-group col-12 px-0 mt-2">
            <MUAutocomplete
              options={serviceOptions}
              groupBy={(option) => {
                return option.subcategory;
              }}
              blurOnSelect
              value={selectServices}
              onChange={(e, value) => {
                onChangeSelectServices(JSON.stringify(value));
              }}
              getOptionLabel={(option) => {
                if (option) {
                  return (
                    option.name +
                    (!isGym
                      ? " (" +
                        (i18n.t("container.add_service_estimate_time") +
                          " " +
                          (option.duration
                            ? option.duration
                            : i18n.t("container.no_estimate_time")) +
                          ")")
                      : "")
                  );
                }
                return "";
              }}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={i18n.t("container.services")}
                  fullWidth
                  helperText={formErrors.selectServicesError}
                  error={!!formErrors.selectServicesError}
                  placeholder={i18n.t("container.select_services")}
                />
              )}
            />
          </div>
          <div
            style={{
              fontSize: 20,
              marginTop: 10,
              marginBottom: 10,
              fontWeight: "bold",
            }}
          >
            <Translation>
              {(t) => t("container.enter_employee_details")}
            </Translation>
          </div>
          {renderDateTimeEmployeeSection()}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userPackages: state.packages.userPackages,
});

const action = { getUserPackagesRequest };

export default connect(mapStateToProps, action)(CreateAppointment);
