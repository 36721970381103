import { connect } from "react-redux";
import React, { useState, useEffect, useMemo, useRef } from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import i18n from "../../../i18n";

import EventAddModal from "./EventAddModal";
import EventEditModal from "./EventEditModal";
import "./CalendarView.scss";

import { getRequest as getStudentGroups } from "../../../redux/actions/Saloon/StudentGroup";
import { getRequest as getStudents } from "../../../redux/actions/Saloon/Student";
import { getRequest as getClass } from "../../../redux/actions/Saloon/Class";
import {
  getRequest,
  upsertRequest,
  removeRequest,
  setAttendanceRequest,
} from "../../../redux/actions/Saloon/CoursePlanner";

import moment from "moment";
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import { IconButton } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import DatePicker from "react-datepicker";
import { TextField } from "@material-ui/core";
import Select from "../../../components/Core/Select";
import { DotsLoader } from "../../../components";
import { getAllEmployeeAssociatedWithCompany } from "../../../config/simpleApiCalls";
import PrimaryButton from "../../../components/Core/PrimaryButton";
const getContrastYIQ = (hexcolor) => {
  if (hexcolor) {
    let color = hexcolor.replace("#", "");
    const r = parseInt(color.substr(0, 2), 16);
    const g = parseInt(color.substr(2, 2), 16);
    const b = parseInt(color.substr(4, 2), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? "black" : "white";
  }
  return "#000";
};

const opetionFormatter = (element) => ({ ...element, value: element._id });
const formatEvent = (event) => {
  const start = moment(event.startDateTime);
  return {
    ...event,
    _id: event._id,
    date: start.toDate(),
    startTime: start.format("HH:mm"),
    endTime: moment(event.endDateTime).format("HH:mm"),
  };
};
function CalendarView({
  getStudentGroups,
  getStudents,
  getClass,
  upsertRequest,
  getRequest,
  setAttendanceRequest,
  events,
  loading,
  removeRequest,
}) {
  const [accessToken, setAccessToken] = useState("");
  const [dates, setDates] = useState({});
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState();
  const [date, setDate] = useState(Date.now());
  const [calView, setCalView] = useState("timeGridWeek");
  const [employees, setEmployees] = useState("");

  const calViews = [
    { name: i18n.t("day"), value: "timeGridDay" },
    { name: i18n.t("work week"), value: "timeGridWorkWeek" },
    { name: i18n.t("week"), value: "timeGridWeek" },
  ];
  const calendarRef = useRef(null);
  useEffect(() => {
    const saloonData = JSON.parse(sessionStorage.getItem("saloon"));
    const { access_token } = saloonData;
    setAccessToken(saloonData.access_token);
    getClass({ access_token });
    getStudentGroups({ access_token });
    getStudents({ access_token, relations: ["classes"] });
    getRequest({ access_token });
    getAllEmployeeAssociatedWithCompany(access_token).then((res) => {
      if (res.data.success) {
        setEmployees(
          res.data.Data.map((companyEmployee) => {
            const { employee, user } = companyEmployee;
            return {
              name: user.userName
                ? user.userName
                : `${user.firstName || ""} ${user.lastName || ""}`,
              value: employee._id,
            };
          })
        );
      }
    });
  }, []);

  const onAdd = (data) => {
    upsertRequest(
      {
        access_token: accessToken,
        ...data,
      },
      {
        success: (data) => {
          SuccessHelper.handleSuccess(
            i18n.t(data.id ? "successfully_updated" : "successfully_added"),
            true
          );
          setActive(formatEvent(data.data));
        },
        failure: () => {
          ErrorHelper.handleErrors(
            i18n.t(data._id ? "failed_to_update" : "failed_to_add"),
            true
          );
        },
      }
    );
  };

  const handleSetAttendance = (data) => {
    setAttendanceRequest(
      {
        access_token: accessToken,
        ...data,
      },
      {
        success: (data) => {
          setActive(formatEvent(data.data));
        },
        failure: () => {
          ErrorHelper.handleErrors(i18n.t("failed_to_update"), true);
        },
      }
    );
  };

  const handleDelete = (ids) => {
    removeRequest(
      {
        access_token: accessToken,
        ids,
      },
      {
        success: (data) => {
          setOpen(false);
          SuccessHelper.handleSuccess(i18n.t("successfully_deleted", true));
        },
        failure: () => {
          ErrorHelper.handleErrors(i18n.t("failed_to_delete"), true);
        },
      }
    );
  };

  const formattedEvents = useMemo(() => {
    return events
      .map((event) => {
        const color = event.classId?.color || "#000";
        return {
          title: `${event.classId?.name}`,
          start: event.startDateTime,
          end: event.endDateTime,
          backgroundColor: color,
          textColor: getContrastYIQ(color),
          event: {
            ...event,
          },
        };
      })
      .filter((event) => !!event);
  }, [events]);
  const onEventMouseEnter = (e) => {};
  const onEventMouseLeave = (e) => {};
  const onEvenClick = (e) => {
    const event = e.event._def.extendedProps.event;
    setOpen(true);
    setActive(formatEvent(event));
  };
  const onArrowClick = (forward) => {
    const displacement = calView == "timeGridDay" ? 1 : 7;
    setDate(
      moment(date)
        .add((forward ? 1 : -1) * displacement, "d")
        .toDate()
    );
  };
  const weekends = useMemo(() => {
    let toReturn;
    let computedCalView = calView;
    if (calView == "timeGridWorkWeek") {
      computedCalView = "timeGridWeek";
      toReturn = false;
    } else {
      toReturn = true;
    }
    if (calendarRef.current) {
      calendarRef.current.getApi().changeView(computedCalView, date);
    }
    return toReturn;
  }, [date, calView]);
  return (
    <div className="content-container">
      <DotsLoader isloading={loading} />
      <div className="CalendarView">
        <div className="d-flex TopBar justify-content-between">
          <div className="d-flex align-items-center">
            <IconButton
              aria-label="previous"
              className="arrow-btn"
              onClick={() => onArrowClick()}
            >
              <ArrowBackIosIcon />
            </IconButton>
            <DatePicker
              selected={date}
              dateFormat="MM/dd/yyyy"
              onChange={(val) => {
                setDate(val);
              }}
              customInput={
                <TextField
                  value={date}
                  className="full-width"
                  readOnly={true}
                />
              }
            />
            <IconButton
              aria-label="next"
              className="arrow-btn"
              onClick={() => onArrowClick(true)}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </div>
          <div className="d-flex align-items-center">
            <div className="m-1 mb-2 program-select">
              <Select
                value={calView}
                label={i18n.t("type")}
                options={calViews}
                onChange={(e) => {
                  setCalView(e.target.value);
                }}
                fullWidth={false}
                excludeEmptyValue
              />
            </div>
            <PrimaryButton
              className="ml-1"
              label={i18n.t("add")}
              onClick={() => {
                setOpen(true);
                setActive({});
              }}
            />
          </div>
        </div>
        <div className="CalendarContainer plan-calendar-wrapper">
          <FullCalendar
            events={formattedEvents}
            eventMouseEnter={onEventMouseEnter}
            eventMouseLeave={onEventMouseLeave}
            eventClick={onEvenClick}
            slotDuration="00:15:00"
            contentHeight={3400}
            expandRows
            allDaySlot={false}
            stickyHeaderDates
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView="timeGridWeek"
            dateClick={(e) => {
              const date = moment(e.date);
              setOpen(true);
              setActive(
                formatEvent({
                  startDateTime: date.format("YYYY-MM-DDTHH:mm"),
                  endDateTime: date.add(60, "m"),
                })
              );
            }}
            headerToolbar={false}
            stickyHeaderDates={true}
            eventOverlap={false}
            ref={calendarRef}
            weekends={weekends}
          />
        </div>
        {active &&
          (active._id ? (
            <EventEditModal
              active={active}
              open={open}
              setOpen={setOpen}
              onSubmit={onAdd}
              onDelete={handleDelete}
              setAttendance={handleSetAttendance}
              employees={employees}
            />
          ) : (
            <EventAddModal
              active={active}
              dates={dates}
              open={open}
              setOpen={setOpen}
              onSubmit={onAdd}
              employees={employees}
            />
          ))}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  loading:
    state.coursePlanner.isFetching ||
    state._class.isFetching ||
    state.program.isFetching ||
    state.studentGroup.isFetching,
  events: state.coursePlanner.data,
});

const action = {
  getStudentGroups,
  getStudents,
  getClass,
  upsertRequest,
  getRequest,
  setAttendanceRequest,
  removeRequest,
};

export default connect(mapStateToProps, action)(CalendarView);
