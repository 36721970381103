// @flow

import { EMPLOYEE_LOGOUT } from "../ActionTypes";

export function request(payload) {
  return {
    payload,
    type: EMPLOYEE_LOGOUT.REQUEST
  };
}

export function success(data: Object) {
  return {
    data,
    type: EMPLOYEE_LOGOUT.SUCCESS
  };
}

export function failure(errorMessage: Object) {
  return {
    errorMessage,
    type: EMPLOYEE_LOGOUT.FAILURE
  };
}