import { connect } from "react-redux";
import React, { Component } from "react";
import { Translation } from "react-i18next";
import i18n from "../../../i18n";
import Avatar from "react-avatar";
import moment from "moment";
import NumberFormat from "react-number-format";
import Autocomplete from "react-autocomplete";
import { SuccessHelper } from "../../../helpers";
import DatePicker from "react-datepicker";
import { request as get_available_timeslots } from "../../../redux/actions/GetAvailableTimeslots.js";
import { clear } from "../../../redux/actions/ActiveBooking";

import { Images } from "../../../theme";
import {
  DotsLoader,
  AdvancedSearchDialog,
  BookingNotes,
  BaseModal,
  AppointmentModal,
  CompletedForm,
  CreateAppointment,
} from "../../../components";
import {
  employeeEditProfile,
  editSaloonSchedule,
  getEmployeeDetails,
  getServices,
  gitWaitingAppoinments,
  editBooking,
  getSaloonServices,
  getEmployeesBySaloonAndService,
  getSaloonCategories,
  addCustomBooking,
  getAllSaloonsForEmployee,
  getCompanyThatEmployeeAdd,
  getEmployeeCustomers,
  createWaitingList,
  addProduct,
  addExtra,
  removeProduct,
  updateCartProduct,
  removeExtra,
  dismissCancelledBooking,
} from "../../../config/simpleApiCalls";
import { ErrorHelper } from "../../../helpers";
import { getNestedValue } from "../../../util/objectMethods";
import Swal from "sweetalert2";
import "./styles.css";
import Sytles from "./styles";
import CalendarSidebar from "../../../components/CalendarView/components/Sidebar";
import CalendarSidebarContent from "../../../components/CalendarView/components/SidebarContent";
import PlanView from "../../../components/CalendarView/components/PlanView";
import ManageExtras from "../../../components/Extras/ManageExtras";
import AddExtra from "../../../components/Extras/AddExtra";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";

import Button from "@material-ui/core/Button";
import RefreshIcon from "@material-ui/icons/Refresh";

const weekNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednessday",
  "Thursday",
  "Friday",
  "Saturday",
];
const statusMap = {
  2: "container.services_now_serving_status",
  3: "cancelled",
  4: "container.services_done_status",
  7: "cancelled_no_show",
};
class AddWorkingHours extends Component {
  constructor(props) {
    super(props);
    const today = moment(moment.now()).format("DD-MM-YYYY");
    this.state = {
      firstName: "",
      lastName: "",
      postalCode: "",
      email: "",
      daysMock: [
        { name: "---" },
        { name: "Sun" },
        { name: "Mon" },
        { name: "Tue" },
        { name: "Wed" },
        { name: "Thu" },
        { name: "Fri" },
        { name: "Sat" },
      ],
      weekPlans: [],
      phoneNumber: "",
      totalAmount: "",
      allSaloons: [],
      selectPaymentMethod: "",
      selectedCategory: null,
      selectSalon: "",
      selectServices: "",
      selectedServiceId: "",
      companyId: null,
      selectEmployee: "ddddd",
      categoryList: [],
      getSalonServices: [],
      selectDateAndTime: "",
      selectedTime: "",

      formErrors: {
        firstNameError: "",
        lastNameError: "",
        postalCodeError: "",
        phoneNumberError: "",
        emailError: "",
        totalAmountError: "",
        selectPaymentMethodError: "",
        selectServicesError: "",
        selectDateAndTimeError: "",
        selectedTimeError: "",
      },

      bookingStauses: [
        { text: "Waiting", value: 1, styles: { color: "#aa43b5" } },
        { text: "Now Serving", value: 3, styles: { color: "#FFE478" } },
        { text: "Cancel", value: 2, styles: { color: "#FF7082" } },
        { text: "Done", value: 4, styles: { color: "#95DA47" } },
      ],
      employeesWorkingHoursData: [
        { dayName: "Sunday", dayOfWeek: 1, availableStatus: 0 },
        { dayName: "Monday", dayOfWeek: 2, availableStatus: 0 },
        { dayName: "Tuesday", dayOfWeek: 3, availableStatus: 0 },
        { dayName: "Wednesday", dayOfWeek: 4, availableStatus: 0 },
        { dayName: "Thursday", dayOfWeek: 5, availableStatus: 0 },
        { dayName: "Friday", dayOfWeek: 6, availableStatus: 0 },
        { dayName: "Saturday", dayOfWeek: 7, availableStatus: 0 },
      ],
      showPopUp: false,

      serviceList: [
        { name: "Hair Cutting", isSelected: false },
        { name: "Hair Cutting", isSelected: true },
        { name: "Hair Cutting", isSelected: false },
        { name: "Hair Cutting", isSelected: false },
      ],
      isVerified: false,
      employeeServicesList: [],
      employeesWorkingHoursError: false,
      hasSchedule: false,
      allAppoin: [],
      searchText: "",
      availableEmployees: [],
      notes: "",
      filtersMap: {
        status: {
          1: true,
          2: true,
          3: true,
          4: true,
        },
      },
      displayCalendar: true,
      activeDate: null,
    };
  }

  onChangeFirstName = (text) => this.setState({ firstName: text.target.value });
  // onChangeLastName = (text) => this.setState({ lastName: text.target.value });
  onChangeEmail = (text) => this.setState({ email: text.target.value });
  onChangePostalCode = (text) =>
    this.setState({ postalCode: text.target.value });
  onChangePhoneNumber = (text) =>
    this.setState({ phoneNumber: text.target.value });
  onChangeSelectSalon = (text) => {
    const { allSaloonMap, allSaloonTaxMap, lockFields } = this.state;

    this.getSalonCategories(text.target.value);
    const { _id, employeeId } = this.state.allSaloonMap[text.target.value];
    this.setState({
      selectSalon: text.target.value,
      employeeId,
      selectServices: "",
      ...(lockFields
        ? {}
        : {
            selectDateAndTime: "",
            newDate: "",
            selectedTime: undefined,
            availableTimeslots: {},
          }),
    });
  };
  onChangeSelectServices = (text) => {
    const { lockFields, isBlackListed } = this.state;
    const { price, depositAmount, requireDeposit, depositTarget } =
      JSON.parse(text);
    let amount = 0;
    if (
      requireDeposit &&
      depositAmount &&
      (depositTarget == "everyone" ||
        (depositTarget == "blacklisted" && isBlackListed))
    ) {
      amount = depositAmount;
    }
    this.setState({
      selectServices: text,
      isloading: true,
      selectEmployee: "",
      selectedEmployee: "",
      availableEmployees: [],
      totalAmount: price,
      depositAmount: amount,
      ...(lockFields
        ? {}
        : {
            selectDateAndTime: "",
            newDate: "",
            selectedTime: undefined,
            availableTimeslots: {},
          }),
    });
    this.getEmployee(text);
  };
  onChangeSelectEmployee = (text) => {
    const { selectServices } = this.state;
    let employee = JSON.parse(text.target.value);
    let employeeId = employee.employeeId._id;
    let pasrseService = JSON.parse(selectServices);
    let serviceId = pasrseService._id;
    let service = employee.companyServices.filter((val) => {
      return val.serviceId == serviceId;
    });
    let serviceAmount = service[0].price;
    this.setState({
      selectEmployee: employeeId,
      totalAmount: serviceAmount,
      weekPlans: employee.weekPlans,
      selectedEmployee: text.target.value,
    });
  };
  onChangeTime = (text) => {
    const { availableTimeslots, getEmployeeBySalonData } = this.state;
    const availableEmployees = availableTimeslots[text.target.value]
      .map((employeeId) => {
        return getEmployeeBySalonData.find(
          (employee) =>
            getNestedValue(employee, ["employeeId", "_id"], "") == employeeId
        );
      })
      .filter((employee) => !!employee);
    this.setState({
      selectedTime: text.target.value,
      newTime: text.target.value.split(" ")[0],
    });
  };

  onChangeTimeText = (text, key = "newTime") => {
    this.setState({
      selectedTime: text.target.value,
      [key]: text.target.value,
    });
  };

  onPaymentMethodChange = (value) => {
    if (value) {
      this.setState({ selectPaymentMethod: value });
    }
  };

  async componentDidMount() {
    const employeeData = await JSON.parse(sessionStorage.getItem("employee"));

    if (employeeData) {
      this.setState({ access_token: employeeData.access_token }, () => {
        this.setState({ activeDate: Date.now() });
        this.getEmployeeData();
        this.getAllServices();
        getEmployeeCustomers(employeeData.access_token).then((res) => {
          this.setState({ usersList: res });
        });
        this.gettingAllSaloons();
      });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let toSet = {};
    if (nextProps.getAvailableTimeslots) {
      if (
        !nextProps.getAvailableTimeslots.failure &&
        !nextProps.getAvailableTimeslots.isFetching &&
        !nextProps.getAvailableTimeslots.errorMessage &&
        nextProps.getAvailableTimeslots.data &&
        nextProps.getAvailableTimeslots.data.data
      ) {
        toSet = {
          ...toSet,
          isloading: false,
        };
        if (!prevState.lockFields) {
          toSet.availableTimeslots = nextProps.getAvailableTimeslots.data.data;
        }
      } else if (nextProps.getAvailableTimeslots.isFetching) {
        toSet = { ...toSet, isloading: true };
      }
    }
    if (nextProps.booking) {
      nextProps.clear();
      const service = getNestedValue(nextProps.booking, ["services", 0], {});
      const booking = { ...nextProps.booking };

      toSet = {
        ...toSet,
        showBookingModal: true,
        selectBooking: booking,
        activeDate: moment(service.date, "MM-DD-YYYY").toDate(),
        selectBookingAmount: nextProps.booking.totalAmount,
        paymentMethod: nextProps.booking.paymentMethod,
        bookingStaus: nextProps.booking.status,
        estimateDuration: service.duration,
        editDetails: false,
      };
    }
    return toSet;
  }

  componentDidUpdate(prevProps, prevState) {
    const { activeDate, lockFields, availableTimeslots } = this.state;
    if (activeDate && activeDate !== prevState.activeDate) {
      this.gitWaitingAppoinments();
    }
  }

  gettingAllSaloons = () => {
    const { access_token, filtersMap = {} } = this.state;

    this.setState({ isloading: true });
    const params = {
      relations: JSON.stringify(["services", "taxes", "products"]),
    };

    getCompanyThatEmployeeAdd(access_token, params).then((res) => {
      if (res.data.success) {
        const allSaloonTaxMap = {};
        const allProductMap = {};
        res.data.companyTaxes.map((companyTax) => {
          if (!allSaloonTaxMap[companyTax.companyId]) {
            allSaloonTaxMap[companyTax.companyId] = [];
          }
          allSaloonTaxMap[companyTax.companyId].push(companyTax);
        });
        res.data.products.map((product) => {
          if (!allProductMap[product.companyId]) {
            allProductMap[product.companyId] = [];
          }
          allProductMap[product.companyId].push(product);
        });
        const allSaloons = res.data.data.filter((salon) => !!salon.companyId);
        const allSaloonMap = {};
        let start = undefined;
        allSaloons.map((saloon) => {
          saloon.weekPlans.map((dayPlan) => {
            const { checkIn } = dayPlan;
            if (checkIn) {
              let hour = parseInt(checkIn.split(":")[0]);
              if (start) {
                if (start > hour) {
                  start = hour;
                }
              } else {
                start = hour;
              }
            }
          });
          allSaloonMap[saloon.companyId._id] = saloon;
        });
        const allServices = [];
        filtersMap.service = {};
        Object.values(allSaloonMap).map((saloon) => {
          saloon.companyServices.map((companyService) => {
            if (getNestedValue(companyService.serviceId, ["name"])) {
              allServices.push(
                getNestedValue(companyService.serviceId, ["name"])
              );
              filtersMap.service[
                getNestedValue(companyService.serviceId, ["name"])
              ] = true;
            }
          });
        });
        this.setState({
          allSaloons,
          isloading: false,
          allSaloonMap,
          allSaloonTaxMap,
          allProductMap,
          start,
          filtersMap,
          allServices,
        });
      }
    });
  };

  getSalonCategories = async (selectedCompanyId) => {
    this.setState({ isloading: true, selectedCompanyId });

    const payload = {
      companyId: selectedCompanyId,
    };
    getSaloonCategories(payload)
      .then((res) => {
        if (res.data.success) {
          this.setState({
            categoryList: res.data.data,
            isloading: false,
          });
        }
      })
      .catch((error) => {
        ErrorHelper.handleErrors(
          "Aww somthing went wrong for getting Categories",
          true
        );
        this.setState({ isloading: false });
      });
  };

  getEmployee = async (selectServices) => {
    const serviceData = selectServices
      ? JSON.parse(selectServices)
      : selectServices;
    this.setState({ serviceData });
    const { selectSalon, lockFields, employeeId } = this.state;

    this.setState({ isloading: true });
    const payload = {
      companyId: selectSalon,
      serviceId: serviceData._id,
    };
    try {
      const res = await getEmployeesBySaloonAndService(payload);
      this.setState({
        getEmployeeBySalonData: res.data.data,
        isloading: false,
      });
      if (lockFields) {
        const { newDate } = this.state;
        let date = newDate;
        this.setState({ isloading: true });
        this.props.get_available_timeslots({
          data: {
            companyId: selectSalon,
            companyServiceId: serviceData._id,
            companyEmployeeId: employeeId,
            date,
          },
          resolve: (data) => {
            if (data.success) {
              const availableTimeslots = data.data;
              const {
                selectDateAndTime,
                selectServices,
                timeSlotLength,
                getEmployeeData,
              } = this.state;
              const duration = parseInt(
                getNestedValue(
                  JSON.parse(selectServices || "{}"),
                  "duration",
                  timeSlotLength
                )
              );
              const slot = getNestedValue(
                availableTimeslots,
                [
                  `${moment(selectDateAndTime).format("HH:mm")} - ${moment(
                    selectDateAndTime
                  )
                    .add(duration, "m")
                    .format("HH:mm")}`,
                ],
                []
              );
              if (!slot.length) {
                Swal.fire({
                  title: i18n.t("you_are_not_available"),
                  confirmButtonText: i18n.t("remove_lock"),
                }).then((e) => {
                  this.removeLock();
                });
              }
            }
          },
        });
      }
      return res.data.data;
    } catch (error) {
      console.log(error);
      ErrorHelper.handleErrors(
        "Aww somthing went wrong for getting Employees",
        true
      );
      this.setState({ isloading: false });
    }
  };

  checkValidationForAddAppointment() {
    const {
      firstName,
      lastName,
      postalCode,
      email,
      phoneNumber,
      totalAmount,
      selectedCategory,
      selectServices,
      selectEmployee,
      selectDateAndTime,
      selectedTime,
      enableWaitingList,
      startTime,
      endTime,
    } = this.state;

    const nameRegex =
      /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{2,}$/u;
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const telephoneRegex =
      /[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]/g;
    if (email && !email.match(emailRegex)) {
      this.setState({
        formErrors: {
          emailError: "Invalid Email",
        },
      });
      setTimeout(() => {
        this.setState({
          formErrors: {
            emailError: "",
          },
        });
      }, 8000);
    } else if (firstName.length < 2) {
      this.setState({
        formErrors: {
          firstNameError: "First name must be atleast 2 characters",
        },
      });
      setTimeout(() => {
        this.setState({
          formErrors: {
            firstNameError: "",
          },
        });
      }, 8000);
    } else if (!phoneNumber) {
      this.setState({
        formErrors: {
          phoneNumberError: "Please fill valid phone No",
        },
      });
      setTimeout(() => {
        this.setState({
          formErrors: {
            phoneNumberError: "",
          },
        });
      }, 8000);
    } else if (!phoneNumber.replace(/\s/g, "").match(telephoneRegex)) {
      this.setState({
        formErrors: {
          phoneNumberError: (
            <Translation>{(t) => t("invalid_phone_number")}</Translation>
          ),
        },
      });

      setTimeout(() => {
        this.setState({
          telephoneError: "",
        });
      }, 6000);
    } else if (!totalAmount) {
      this.setState({
        formErrors: {
          totalAmountError: "Please fill Amount",
        },
      });
      setTimeout(() => {
        this.setState({
          formErrors: {
            totalAmountError: "",
          },
        });
      }, 8000);
    } else if (!selectedCategory) {
      this.setState({
        formErrors: {
          selectPaymentMethodError: alert("please select Category"),
        },
      });
      setTimeout(() => {
        this.setState({
          formErrors: {
            selectPaymentMethodError: "",
          },
        });
      }, 8000);
    } else if (!selectServices) {
      this.setState({
        formErrors: {
          selectServicesError: "Please select Services",
        },
      });
      setTimeout(() => {
        this.setState({
          formErrors: {
            selectServicesError: "",
          },
        });
      }, 8000);
    } else if (!selectDateAndTime) {
      this.setState({
        formErrors: {
          selectDateAndTimeError: "Please select Date",
        },
      });
      setTimeout(() => {
        this.setState({
          formErrors: {
            selectDateAndTimeError: "",
          },
        });
      }, 8000);
    } else if (!enableWaitingList && !selectedTime) {
      this.setState({
        formErrors: {
          selectedTimeError: "Please select Time",
        },
      });
      setTimeout(() => {
        this.setState({
          formErrors: {
            selectedTimeError: "",
          },
        });
      }, 8000);
    } else if (
      enableWaitingList &&
      (!startTime || !startTime.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/))
    ) {
      this.setState({
        formErrors: {
          startTimeError: "Invalid, expected hh:mm",
        },
      });
      setTimeout(() => {
        this.setState({
          formErrors: {
            startTimeError: "",
          },
        });
      }, 8000);
    } else if (
      enableWaitingList &&
      (!endTime || !endTime.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/))
    ) {
      this.setState({
        formErrors: {
          endTimeError: "Invalid, expected hh:mm",
        },
      });
      setTimeout(() => {
        this.setState({
          formErrors: {
            endTimeError: "",
          },
        });
      }, 8000);
    } else {
      this.handleAddNewAppointment();
    }
  }

  handleAddNewAppointment = () => {
    const {
      firstName,
      lastName,
      postalCode,
      email,
      phoneNumber,
      totalAmount,
      selectedCategory,
      selectServices,
      employeeId,
      selectDateAndTime,
      selectSalon,
      serviceData,
      access_token,
      selectedTime,
      newTime,
      newDate,
      notes,
      enableWaitingList,
      startTime,
      endTime,
      depositAmount,
    } = this.state;

    if (!enableWaitingList) {
      const createBooking = (depositRequired) => {
        const payload = {
          companyId: selectSalon,
          services: [
            {
              serviceId: serviceData._id,
              employeeId,
              categoryId: selectedCategory._id,
              date: newDate,
              time: newTime,
            },
          ],
          userName: firstName,
          email,
          phoneNo: phoneNumber,
          status: 1,
          totalAmount,
          notes,
          depositRequired,
        };
        this.setState({ isloading: true });
        addCustomBooking(payload, access_token)
          .then((res) => {
            this.setState({ showBookingPopup: false, isloading: false });
            if (res.data.success) {
              SuccessHelper.handleSuccess(
                res?.data?.msg || "Booking Created",
                true
              );
              window.location.reload();
            }
          })
          .catch((e) => {
            this.setState({ isloading: false });
            ErrorHelper.handleErrors(
              "A booking already exists with testing employee",
              true
            );
            this.setState({ showBookingPopup: false });
          });
      };
      if (depositAmount) {
        Swal.fire({
          title: i18n.t("require_deposit", { depositAmount }),
          showCancelButton: true,
          cancelButtonText: "No",
          confirmButtonText: "Yes",
        })
          .then((e) => {
            const { dismiss, isConfirmed, isDismissed } = e;
            if (!isDismissed || dismiss != "backdrop") {
              createBooking(isConfirmed);
            }
          })
          .catch((e) => {
            this.setState({ isloading: false });
            ErrorHelper.handleErrors(
              "A booking already exists with testing employee",
              true
            );
            this.setState({ showBookingPopup: false, notes: "" });
          });
      } else {
        createBooking(false);
      }
    } else {
      const payload = {
        companyId: selectSalon,
        email,
        phoneNo: phoneNumber,
        status: 1,
        serviceId: serviceData._id,
        date: newDate,
        startTime: startTime,
        endTime: endTime,
        employeeId,
      };
      createWaitingList({ ...payload }, access_token)
        .then((res) => {
          this.setState({
            isloading: false,
            totalAmount: 0,
            selectPaymentMethod: "",
          });

          if (res.data.success) {
            SuccessHelper.handleSuccess("Added to waiting list", true);
            window.location.reload();
          } else {
            ErrorHelper.handleErrors("Something went wrong", true);
          }
          this.setState({
            showBookingPopup: false,
            selectServices: "",
            selectEmployee: "",
            selectedEmployee: "",
            selectDateAndTime: "",
            newDate: "",
            selectedTime: undefined,
            availableTimeslots: {},
            notes: "",
          });
        })

        .catch((error) => {
          this.setState({ isloading: false, isUpdate: false });

          if (error.response) {
            // Alert.alert("Error", error.response.data.msg);
            ErrorHelper.handleErrors(error.response.data.msg, true);

            // Request made and server responded
          } else if (error.request) {
            ErrorHelper.handleErrors("Something Went Wrong", true);

            // The request was made but no response was received
          } else {
            ErrorHelper.handleErrors(error.msg, true);
          }
        });
    }
  };

  onDateChange = async (selectDate) => {
    this.setState({
      selectedTime: undefined,
      availableTimeslots: {},
    });
    let date = undefined;
    if (selectDate) {
      date = moment(selectDate).format("MM-DD-YYYY");
      const { serviceData, selectSalon, employeeId } = this.state;
      await this.props.get_available_timeslots({
        data: {
          companyId: selectSalon,
          companyServiceId: serviceData._id,
          companyEmployeeId: employeeId,
          date,
        },
      });
    }
    this.setState({
      selectDateAndTime: selectDate,
      newDate: date,
    });
  };

  gitWaitingAppoinments = async (getAll) => {
    const { access_token, activeDate } = this.state;
    const date = moment(activeDate).startOf("week");
    let dates = [date.format("MM-DD-YYYY")];
    this.setState({ isloading: true });
    for (let i = 0; i < 7; i++) {
      date.add(1, "d");
      dates.push(date.format("MM-DD-YYYY"));
    }
    try {
      const query = { type: "employee" };
      if (!getAll) {
        const date = moment(activeDate).startOf("week");
        let dates = [date.format("MM-DD-YYYY")];
        for (let i = 0; i < 7; i++) {
          date.add(1, "d");
          dates.push(date.format("MM-DD-YYYY"));
        }
        query.dates = dates.join(",");
      }
      const res = await gitWaitingAppoinments(query, access_token);
      this.setState({
        allAppoin: res.data.data.map((element, index) => ({
          ...element,
          index,
        })),
        isloading: false,
      });
    } catch (error) {
      alert("Something went wrong ;(");
    }
  };

  getAllServices = () => {
    this.setState({ isloading: true });
    getServices()
      .then((res) => {
        if (res.data.success) {
          this.setState({ isloading: false, serviceList: res.data.data });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
      });
  };

  getSalonServicesByCategory = async (val) => {
    const { selectSalon, selectedCategory, lockFields } = this.state;

    this.setState({
      isloading: true,
      selectServices: "",
      ...(lockFields
        ? {}
        : {
            selectDateAndTime: "",
            newDate: "",
            selectedTime: undefined,
            availableTimeslots: {},
          }),
    });
    const payload = {
      companyId: selectSalon,
      categoryId: val._id,
    };
    getSaloonServices(payload)
      .then((res) => {
        if (res.data.success) {
          this.setState({ getSalonServices: res.data.data, isloading: false });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
      });
  };

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  handleFilters = (data) => {
    let filteredBookings = this.state.allAppoin;
    if (data) {
      const { allSaloonMap } = this.state;
      const normalize = (value) => {
        value = value ? value : "";
        return value.toLowerCase();
      };
      data.name = normalize(data.name);
      data.phoneNumber = normalize(data.phoneNumber).replace(/ /g, "");
      filteredBookings = filteredBookings.filter((booking) => {
        const isEmployeeIndependent = getNestedValue(
          allSaloonMap,
          [
            getNestedValue(booking, ["companyId", "_id"]),
            "isEmployeeIndependent",
          ],
          true
        );
        if (data.name) {
          if (
            !normalize(getNestedValue(booking, ["userId", "userName"])).match(
              data.name
            ) &&
            !normalize(getNestedValue(booking, "name", "")).match(data.name) &&
            !normalize(getNestedValue(booking, ["userId", "firstName"])).match(
              data.name
            ) &&
            !normalize(getNestedValue(booking, ["userId", "lastNme"])).match(
              data.name
            )
          ) {
            return false;
          }
        }
        if (data.shortenedId) {
          if (
            !getNestedValue(booking, ["shortenedId"]).match(data.shortenedId)
          ) {
            return false;
          }
        }
        if (data.phoneNumber) {
          if (!isEmployeeIndependent) {
            return false;
          } else if (
            !normalize(
              getNestedValue(booking, ["userId", "phoneNo"], "")
            ).match(data.phoneNumber) &&
            !normalize(getNestedValue(booking, "phoneNo", "")).match(
              data.phoneNumber
            )
          ) {
            return false;
          }
        }
        if (data.service && data.service.length) {
          if (
            !getNestedValue(booking, ["services"], []).filter((value) =>
              data.service.includes(
                getNestedValue(value, ["serviceId", "name"], "").trim()
              )
            ).length
          ) {
            return false;
          }
        }
        if (data.startDate || data.endDate) {
          // moment
          if (data.startDate && data.endDate) {
            const date = moment(
              getNestedValue(booking, ["services", 0, "date"], ""),
              "MM-DD-YYYY"
            );
            if (
              !moment(data.startDate).isSameOrBefore(date) ||
              !moment(data.endDate).isSameOrAfter(date)
            ) {
              return false;
            }
          } else {
            if (
              (data.startDate &&
                normalize(
                  getNestedValue(booking, ["services", 0, "date"], "")
                ) != moment(data.startDate).format("MM-DD-YYYY")) ||
              (data.endDate &&
                normalize(
                  getNestedValue(booking, ["services", 0, "date"], "")
                ) != moment(data.endDate).format("MM-DD-YYYY"))
            ) {
              return false;
            }
          }
        }
        if (data.status && data.status.length) {
          if (
            !data.status.includes(`${getNestedValue(booking, ["status"], 0)}`)
          ) {
            return false;
          }
        }
        return true;
      });
    }
    this.setState({ filteredBookings, showAdvancedSearchPopup: false });
  };

  onChangeFacebook = (text) =>
    this.setState({ facebookLink: text.target.value });

  onChangeCheckbox = (e, ind) => {
    const { employeesWorkingHoursData } = this.state;
    if (e.target.checked) {
      employeesWorkingHoursData[ind].availableStatus = 1;
      this.setState({ employeesWorkingHoursData });
    } else {
      employeesWorkingHoursData[ind].availableStatus = 0;
      this.setState({ employeesWorkingHoursData });
    }
  };

  onChangeCheckIn = (text, ind) => {
    const { employeesWorkingHoursData } = this.state;
    if (text.target.value) {
      employeesWorkingHoursData[ind].checkIn = text.target.value;
    }
  };

  onChangeCheckOut = (text, ind) => {
    const { employeesWorkingHoursData } = this.state;
    if (text.target.value) {
      employeesWorkingHoursData[ind].checkOut = text.target.value;
    }
  };

  checkValidation = () => {
    const { employeesWorkingHoursData, isVerified, hasSchedule } = this.state;
    for (let i = 0; i < employeesWorkingHoursData.length; i++) {
      if (employeesWorkingHoursData[i].availableStatus === 1) {
        if (!employeesWorkingHoursData[i].checkIn) {
          return this.setState({
            isVerified: false,
            employeesWorkingHoursError: true,
          });
        } else if (!employeesWorkingHoursData[i].checkOut) {
          return this.setState({
            isVerified: false,
            employeesWorkingHoursError: true,
          });
        } else {
          this.setState({
            isVerified: true,
            employeesWorkingHoursError: false,
          });
        }
      }
    }
    if (isVerified && !hasSchedule) {
      this.handleCreateSaloonSchedule();
    } else if (isVerified && hasSchedule) {
      this.handleEditSaloonSchedule();
    }
  };

  renderSaloons = () => {
    const {
      allSaloons,
      selectBooking,
      bookingStaus,
      bookingStauses,
      allSaloonMap,
      notes,
      allAppoin,
      access_token,
      editDetails,
    } = this.state;
    const date = getNestedValue(selectBooking, ["services", 0, "date"], "");
    const showCustomerDetails = selectBooking
      ? allSaloonMap &&
        allSaloonMap[selectBooking.companyId._id] &&
        allSaloonMap[selectBooking.companyId._id].isEmployeeIndependent
        ? true
        : false
      : false;
    if (editDetails) {
      return this.renderEditDetail();
    }
  };

  renderEditDetail = () => {
    return {
      title: i18n.t("edit"),
      content: this.renderEmployeeDetail(),
      contentClassName: "modify-modal-content",
      actions: (
        <div className="row mx-0 justify-content-end">
          <Button
            onClick={() => this.handleUpdate()}
            style={{
              backgroundColor: "#ff3600",
              color: "white",
            }}
          >
            {i18n.t("update")}
          </Button>
        </div>
      ),
    };
  };

  renderExtras = () => {
    const {
      selectBooking,
      allProductMap,
      allSaloonMap,
      access_token,
      allAppoin,
    } = this.state;
    let products = getNestedValue(
      allProductMap,
      [selectBooking.companyId._id],
      []
    );
    let services = getNestedValue(
      allSaloonMap,
      [selectBooking.companyId._id, "companyServices"],
      []
    ).map((service) => ({
      _id: service.serviceId._id,
      price: service.price,
      serviceId: service.serviceId,
      name: service.serviceId.name,
    }));
    const closaModal = () => {
      this.setState({ showExtrasModal: false, showPopUp: false });
    };
    const onAdd = async (type, values) => {
      this.setState({ isloading: true });
      if (type) {
        const { data } = await (type == "product" ? addProduct : addExtra)(
          { bookingId: selectBooking._id, ...values },
          access_token
        );
        if (data.success) {
          if (type == "product") {
            if (allAppoin[selectBooking.index]) {
              allAppoin[selectBooking.index].cartId = data.data;
              this.setState({
                selectBooking: { ...selectBooking, cartId: data.data },
              });
            }
          } else {
            allAppoin[selectBooking.index] = data.data;
            this.setState({ selectBooking: data.data });
          }
          this.setState({ allAppoin, showPopUp: false });
          SuccessHelper.handleSuccess("Added Item", true);
          closaModal();
        } else {
          if (data.code) {
            ErrorHelper.handleErrors(i18n.t(data.code, { ...data.data }), true);
          } else {
            ErrorHelper.handleErrors("Failed to Add Item", true);
          }
        }
      }
      this.setState({ isloading: false });
    };
    return {
      title: i18n.t("container.add_extras"),
      content: (
        <AddExtra
          services={services}
          products={products}
          closaModal={closaModal}
          onAdd={onAdd}
        />
      ),
    };
  };

  renderBookingNotes = () => {
    const { selectBooking = {}, allSaloonMap = {}, access_token } = this.state;
    return {
      title: i18n.t("container.booking_notes"),
      content: (
        <BookingNotes
          bookingId={selectBooking._id}
          access_token={access_token}
          hasEditPermission={
            allSaloonMap[selectBooking.companyId._id].isEmployeeIndependent
          }
        />
      ),
    };
  };

  renderServicesWorkingHours = () => {
    const { editDetails, selectBooking = {} } = this.state;
    return (
      <div className="col-12 px-0">
        <div className="py-0 px-2 mx-0 row justify-content-between">
          <h3>Edit</h3>
          <span
            onClick={() => this.closeEditServiceCard()}
            className="h4 cursor-pointer"
          >
            &times;
          </span>
        </div>
        {this.renderSaloons()}
        <div className="col-12 text-right">
          {editDetails ? (
            <button
              onClick={() => this.handleUpdate(true)}
              type="button"
              className="btn btn-light btn-sm mb-2"
              id="saloon-save-social-link"
            >
              Update
            </button>
          ) : (
            <>
              {selectBooking.status == 1 && (
                <button
                  onClick={() => this.handleEditDetails()}
                  type="button"
                  className="btn btn-light btn-sm mb-2 mx-1"
                  id="saloon-save-social-link"
                >
                  {i18n.t("modify")}
                </button>
              )}
              <button
                onClick={this.handleAddProductsServices}
                type="button"
                className="btn btn-light btn-sm mb-2 mx-1"
                id="saloon-save-social-link"
              >
                {i18n.t("container.add_extras")}
              </button>
              <button
                onClick={this.handleNotes}
                type="button"
                className="btn btn-light btn-sm mb-2 mx-1"
                id="saloon-save-social-link"
              >
                {i18n.t("notes")}
              </button>
              <button
                onClick={() => this.handleUpdate()}
                type="button"
                className="btn btn-light btn-sm mb-2"
                id="saloon-save-social-link"
              >
                Update
              </button>
            </>
          )}
        </div>
      </div>
    );
  };

  handleEditDetails = async () => {
    const { selectBooking } = this.state;
    const companyId = selectBooking.companyId._id;
    const selectService = getNestedValue(
      selectBooking,
      ["services", 0, "serviceId"],
      {}
    );
    this.setState({ selectSalon: companyId, enableWaitingList: false }, () => {
      let selectEmployee = getNestedValue(
        selectBooking,
        ["services", 0, "employeeId", "_id"],
        {}
      );
      const momentDate = moment(
        `${getNestedValue(
          selectBooking,
          ["services", 0, "date"],
          ""
        )} ${getNestedValue(selectBooking, ["services", 0, "time"], "")}`,
        "MM-DD-YYYY HH:mm"
      );
      Promise.all([
        new Promise((resolve) => {
          this.props.get_available_timeslots({
            data: {
              companyId,
              companyServiceId: selectService._id,
              date: momentDate.format("MM-DD-YYYY"),
              bookingIdToExclude: selectBooking._id,
              companyEmployeeId: selectEmployee,
            },
            resolve,
          });
        }),
        this.getEmployee(JSON.stringify(selectService)),
      ]).then(() => {
        setTimeout(() => {
          const { getEmployeeData, availableTimeslots } = this.state;
          // let selectedEmployee = getEmployeeData.find(employee => employee.employeeId._id == selectEmployee)
          this.setState({
            showPopUp: true,
            editDetails: true,
            lockFields: false,
            // selectedEmployee: JSON.stringify(selectedEmployee),
            selectEmployee,
            newDate: momentDate.format("MM-DD-YYYY"),
            newTime: momentDate.format("HH:mm"),
            selectDateAndTime: momentDate.toDate(),
            selectedTime: `${momentDate.format("HH:mm")} - ${moment(momentDate)
              .add(parseInt(selectService.duration), "minutes")
              .format("HH:mm")}`,
          });
        }, 50);
      });
    });
  };

  handleAddProductsServices = async () => {
    this.setState({
      showExtrasModal: true,
      showPopUp: true,
    });
    // addService,
    // addProduct({bookingId: selectBooking._id, quantity: 1, productId: '603ff8ad7226cf30e089fe08'}, access_token)
  };

  handleNotes = async () => {
    this.setState({
      showNotesModal: true,
      showPopUp: true,
    });
  };

  handleUpdate = async (extras = {}) => {
    const {
      paymentMethod,
      selectBookingAmount,
      selectBooking,
      bookingStaus,
      access_token,
      notes,
      tip,
      companyTaxes = [],
      editDetails,
    } = this.state;
    let { allAppoin } = this.state;
    if (editDetails) {
      const {
        newDate,
        selectDateAndTime,
        selectedTime,
        newTime,
        selectEmployee,
      } = this.state;
      if (!selectEmployee) {
        this.setState({
          formErrors: {
            selectEmployeeError: "Please select Employee",
          },
        });
        setTimeout(() => {
          this.setState({
            formErrors: {
              selectEmployeeError: "",
            },
          });
        }, 8000);
        return true;
      } else if (!selectDateAndTime) {
        this.setState({
          formErrors: {
            selectDateAndTimeError: "Please select Date",
          },
        });
        setTimeout(() => {
          this.setState({
            formErrors: {
              selectDateAndTimeError: "",
            },
          });
        }, 8000);
      } else if (!selectedTime) {
        this.setState({
          formErrors: {
            selectedTimeError: "Please select Time",
          },
        });
        setTimeout(() => {
          this.setState({
            formErrors: {
              selectedTimeError: "",
            },
          });
        }, 8000);
      } else if (
        !newTime ||
        !newTime.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)
      ) {
        this.setState({
          formErrors: {
            selectedTimeError: "Invalid, expected hh:mm",
          },
        });
        setTimeout(() => {
          this.setState({
            formErrors: {
              selectedTimeError: "",
            },
          });
        }, 8000);
      } else {
        const services = [
          {
            serviceId: selectBooking.services[0].serviceId._id,
            categoryId: selectBooking.services[0].categoryId._id,
            date: newDate,
            time: newTime,
            employeeId: selectEmployee,
          },
        ];
        try {
          const { data } = await editBooking(
            {
              bookingId: selectBooking._id,
              status: selectBooking.status,
              services,
            },
            access_token
          );
          let newSelectBooking = selectBooking;
          if (data.success) {
            const newBooking = data.data;
            this.setState({
              isloading: false,
              allAppoin: [...allAppoin],
              selectBooking: newSelectBooking,
              editDetails: false,
            });
            SuccessHelper.handleSuccess("Updated Successfully", true);
            if (
              (data.data.status == 4 && data.data.isFinalized) ||
              data.data.status == 3
            ) {
              allAppoin.splice(selectBooking.index, 1);
              allAppoin = allAppoin.map((booking, index) => ({
                ...booking,
                index,
              }));
            } else {
              newSelectBooking = {
                ...selectBooking,
                ...data.data,
                index: selectBooking.index,
              };
              allAppoin[selectBooking.index] = newSelectBooking;
            }
            this.setState({
              isloading: false,
              allAppoin: [...allAppoin],
              selectBooking: newSelectBooking,
              editDetails: false,
            });
            SuccessHelper.handleSuccess("Updated Successfully", true);
            this.handleClosePopup();
          } else {
            this.setState({ isloading: false });
            ErrorHelper.handleErrors(data.msg, true);
          }
        } catch (error) {
          this.setState({ isloading: false });
          if (error.data.msg) {
            ErrorHelper.handleErrors(error.data.msg, true);
          } else {
            alert(
              error.response ? error.response.data.msg : "Something Went Wrong!"
            );
          }
        }
      }
    } else {
      const sendRequst = async (isFinalized) => {
        const taxes = [];
        const payload = {
          bookingId: selectBooking._id,
          totalAmount: parseInt(selectBookingAmount),
          paymentMethod,
          status: bookingStaus,
          notes,
          tip: parseFloat(tip),
          taxes,
          isFinalized,
          ...extras,
        };
        console.log({ payload });

        this.setState({ isloading: true });

        try {
          const { data } = await editBooking(payload, access_token);
          let newSelectBooking = selectBooking;
          if (data.success) {
            const newBooking = data.data;
            newBooking.services = newBooking.services.map((service) => {
              return {
                ...service,
              };
            });
            if (
              (data.data.status == 4 && data.data.isFinalized) ||
              data.data.status == 3
            ) {
              allAppoin.splice(selectBooking.index, 1);
              allAppoin = allAppoin.map((booking, index) => ({
                ...booking,
                index,
              }));
            } else {
              newSelectBooking = { ...newBooking, index: selectBooking.index };
              allAppoin[selectBooking.index] = newSelectBooking;
            }
          }
          this.setState({
            isloading: false,
            allAppoin: [...allAppoin],
            selectBooking: newSelectBooking,
            editDetails: false,
          });
          SuccessHelper.handleSuccess("Updated Successfully", true);
          this.handleClosePopup();
          this.setState({
            showPopUp: false,
            isloading: false,
            showBookingModal: false,
          });
        } catch (error) {
          this.setState({ isloading: false });
          if (error.data.msg) {
            ErrorHelper.handleErrors(error.data.msg, true);
          } else {
            alert(
              error.response ? error.response.data.msg : "Something Went Wrong!"
            );
          }
        }
      };
      if (bookingStaus == 4) {
        Swal.fire({
          title: i18n.t("are_you_sure_you_want_to_finalize_this_booking"),
          showCancelButton: true,
          cancelButtonText: "No",
          confirmButtonText: "Yes",
        }).then((e) => {
          const { dismiss, isConfirmed, isDismissed } = e;
          if (!isDismissed || dismiss != "backdrop") {
            if (isConfirmed) {
              this.setState(
                {
                  showInsertPayment: true,
                  paymentCompleteCallback: () => sendRequst(isConfirmed),
                },
                () => {
                  this.setState({
                    showInsertPayment: false,
                  });
                }
              );
            } else {
              sendRequst(isConfirmed);
            }
          }
        });
      } else {
        sendRequst(false);
      }
    }
  };

  handleClosePopup = () => {
    this.setState({
      showProductsServicesModal: false,
      showNotesModal: false,
      showExtrasModal: false,
      editDetails: false,
      showPopUp: false,
    });
  };

  renderPopup = () => {
    const {
      showPopUp,
      allSaloonMap,
      showNotesModal,
      showExtrasModal,
      editDetails,
    } = this.state;
    let modalFunction;
    if (showNotesModal) {
      modalFunction = this.renderBookingNotes;
    } else if (showExtrasModal) {
      modalFunction = this.renderExtras;
    } else if (editDetails) {
      modalFunction = this.renderEditDetail;
    }
    const modalProps = modalFunction ? modalFunction() : {};
    return (
      <BaseModal
        style={{ zIndex: 1302 }}
        open={showPopUp}
        {...modalProps}
        onClose={() => this.handleClosePopup()}
      />
    );
  };

  handleCreateSaloonSchedule = () => {
    this.setState({ isloading: true });
    const { employeesWorkingHoursData, access_token, serviceList } = this.state;
    const employeesWorkingHoursSorted = employeesWorkingHoursData.map(function (
      obj
    ) {
      delete obj["dayName"]; // Delete old key
      delete obj["_id"]; // Delete old key
      return obj;
    });
    let serviceListSorted = [];
    for (let i = 0; i < serviceList.length; i++) {
      if (serviceList[i].isSelected) {
        let myService = {
          charges: serviceList[i].charges,
          serviceId: serviceList[i]._id,
        };
        serviceListSorted.push(myService);
      }
    }
    const payload = {
      services: serviceListSorted,
      weekPlans: employeesWorkingHoursSorted,
    };
    employeeEditProfile(payload, access_token)
      .then((res) => {
        if (res.data.success) {
        } else {
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        if (error.data.success === false) {
          Swal.fire({
            title: error,
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#ff3600",
            cancelButtonColor: "#354e68",
            confirmButtonText: "Yes, delete it!",
          });
        }
      });
  };

  getEmployeeData = () => {
    const { access_token } = this.state;
    this.setState({ isloading: true });
    getEmployeeDetails(access_token)
      .then((res) => {
        if (res.data.success) {
          for (let i = 0; i < res.data.data.details.weekPlans.length; i++) {
            res.data.data.details.weekPlans[i].dayName = weekNames[i];
          }
          this.setState({
            isloading: false,
            employeesWorkingHoursData: res.data.data.details.weekPlans,
            serviceList: res.data.data.details.services,
          });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
      });
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  onChangeSearchText = (searchText) => {
    this.setState({ searchText });
  };

  renderEmployDashboardHeading = () => {
    const { searchText, filteredBookings } = this.state;
    return (
      <div className="row mx-0 py-3">
        <div className="col-12 col-lg-4 col-md-4 mt-4">
          <span>
            <span className="saloon-dashboard-heading">
              {i18n.t("container.my_appointments")}
            </span>
          </span>
        </div>
        <div className="col-12 col-lg-8 col-md-8 mt-4">
          <div className=" d-flex justify-content-end row">
            <div className="col-12 col-lg-7 col-md-6">
              <div className="form-group">
                <input
                  style={{ backgroundColor: "#FFFFFF", borderColor: "#EBEBEB" }}
                  type="text"
                  id="addEmployee_search_bar"
                  className="form-control form-control-sm pl-5"
                  placeholder={i18n.t(
                    "container.services_search_by_name_phone_number_ref"
                  )}
                  onChange={(text) =>
                    this.onChangeSearchText(text.target.value)
                  }
                  value={searchText}
                />
                <img src={Images.saloon_search} className="search-bar-icon" />
              </div>
            </div>
            <div>
              <button
                type="button"
                onClick={() => {
                  if (!filteredBookings) {
                    this.setState({ showAdvancedSearchPopup: true });
                  } else {
                    this.setState({ filteredBookings: undefined });
                  }
                }}
                className="btn btn-light btn-sm mb-2 "
                id="saloon-save-social-link"
              >
                {filteredBookings ? (
                  <Translation>
                    {(t) =>
                      t("container.advancedSearchDialog_clear_advanced_search")
                    }
                  </Translation>
                ) : (
                  <Translation>
                    {(t) => t("container.advancedSearchDialog_advanced_search")}
                  </Translation>
                )}
              </button>
            </div>
            <div>
              <button
                type="button"
                onClick={() =>
                  this.setState({ showBookingPopup: true, notes: "" })
                }
                className="btn btn-light btn-sm mb-2 ml-1"
                id="saloon-save-social-link"
              >
                <Translation>{(t) => t("container.add_booking")}</Translation>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderTableHead = () => {
    return (
      <thead>
        <tr>
          <th scope="col">
            <span className="working-day-th-text">Name</span>
          </th>
          <th className="text-center" scope="col">
            <span className="working-day-th-text">Date</span>
          </th>
          <th className="text-center" scope="col">
            <span className="working-day-th-text">
              <Translation>
                {(t) => t("container.employe_custom_details")}
              </Translation>
            </span>
          </th>
          <th className="text-center" scope="col">
            <span className="working-day-th-text">
              <Translation>{(t) => t("container.profile_service")}</Translation>
            </span>
          </th>
          <th className="text-center" scope="col">
            <span className="working-day-th-text">Payment</span>
          </th>
          <th className="text-center" scope="col">
            <span className="working-day-th-text">
              <Translation>{(t) => t("by_price")}</Translation>
            </span>
          </th>
          <th className="text-center" scope="col">
            <span className="working-day-th-text">
              <Translation>{(t) => t("tip")}</Translation>
            </span>
          </th>
          {/* <th className="text-center" scope="col">
            <span className="working-day-th-text">Time/Date</span>
          </th> */}
          <th className="text-center" scope="col">
            <span className="working-day-th-text">Status</span>
          </th>
          <th className="text-center" scope="col">
            <span className="working-day-th-text"></span>
          </th>
        </tr>
      </thead>
    );
  };

  handleFilterChange = (key, value) => {
    const { filtersMap = {} } = this.state;
    if (key == "service" || key == "status") {
      const keyMap = filtersMap[key] ? filtersMap[key] : {};
      if (keyMap[value]) {
        delete keyMap[value];
      } else {
        keyMap[value] = true;
      }
      filtersMap[key] = keyMap;
    } else {
      filtersMap[key] = value.target.value;
    }

    this.handleFilters({
      ...filtersMap,
      status: Object.keys(filtersMap.status ? filtersMap.status : {}),
      employee: Object.keys(filtersMap.employee ? filtersMap.employee : {}),
      service: Object.keys(filtersMap.service ? filtersMap.service : {}),
    });
    this.setState({ filtersMap });
  };

  onCalendarClick = (date) => {
    const { timeSlotLength } = this.state;
    const momentDate = moment(date);
    let remainder = parseInt(momentDate.format("mm")) % timeSlotLength;
    momentDate.subtract(remainder, "m");
    this.setState({
      lockFields: true,
      showBookingPopup: true,
      newDate: momentDate.format("MM-DD-YYYY"),
      selectedTime: momentDate.format("HH:mm"),
      newTime: momentDate.format("HH:mm"),
      availableTimeslots: {},
      selectDateAndTime: momentDate.toDate(),
      availableTimeslots: { [momentDate.format("HH:mm")]: [true] },
      selectServices: "",
      selectEmployee: "",
      selectedEmployee: "",
      categoryId: "",
      selectedCategory: "",
      notes: "",
    });
  };

  Plan = (bookings) => {
    const {
      employees,
      allServices = [],
      start,
      filtersMap,
      allSaloonTaxMap,
      allSaloonMap,
      activeDate,
      allSaloons,
    } = this.state;
    // const [open, setOpen] = useState(false);
    // const slideSideNav = useCallback(() => {
    //   setOpen(!open);
    // }, [open]);
    const statusClassMap = {
      1: "calendar-event-waiting",
      2: "calendar-event-now-serving",
      3: "calendar-event-cancel",
      4: "calendar-event-done",
    };
    const plans = bookings.map((booking) => {
      const { services, _id, name } = booking;
      return services.map((service) => {
        const date = moment(
          `${service.date} ${service.time}`,
          "MM-DD-YYYY HH:mm"
        );
        const start = date.toDate();
        const end = date
          .add(parseInt(service.serviceId.duration), "minutes")
          .toDate();
        return {
          ...booking,
          id: _id,
          title: `${name} - ${service.serviceId.name}`,
          allDay: false,
          start,
          end,
          color: getNestedValue(
            allSaloonMap,
            [booking.companyId._id, "color"],
            "#000"
          ),
          duration: service.serviceId.duration,
          className: statusClassMap[booking.status],
          type: "booking",
        };
      });
    });
    const onEventClick = (val) => {
      if (val.status != 3) {
        this.setState({
          showBookingModal: true,
          selectBooking: val,
          selectBookingAmount: val.totalAmount,
          paymentMethod: val.paymentMethod,
          bookingStaus: val.status,
          estimateDuration: val.duration,
          notes: val.notes,
          editDetails: false,
          companyTaxes: allSaloonTaxMap[val.companyId._id],
          tip: val.tip,
        });
      }
    };
    return (
      <div className="plan-container">
        <CalendarSidebar open={this.state.isOpen} width="260px">
          <CalendarSidebarContent
            onChange={this.handleFilterChange}
            employees={employees}
            services={allServices}
          />
        </CalendarSidebar>
        <PlanView
          open={this.state.isOpen}
          width="17vw"
          slideSideNav={(open) => {
            this.setState({
              isOpen: typeof open == "boolean" ? open : !this.state.isOpen,
            });
          }}
          companies={allSaloons.map(({ companyId }) => companyId)}
          companyTaxMap={allSaloonTaxMap}
          onEventClick={onEventClick}
          plans={plans}
          start={start}
          createAppointment={() => {
            this.setState({
              showBookingPopup: true,
              lockFields: false,
              selectDateAndTime: null,
              selectedTime: null,
              showBookingModal: false,
              notes: "",
            });
          }}
          onDateClick={this.onCalendarClick}
          date={activeDate}
          onDateChange={(e) => {
            this.setState({ activeDate: e }, () => {
              this.gitWaitingAppoinments();
            });
          }}
          onDismiss={(_id, index) => {
            const { access_token, allAppoin } = this.state;
            this.setState({ isloading: true });
            dismissCancelledBooking({ id: _id }, access_token)
              .then(({ data }) => {
                if (data.success) {
                  let newBookings = allAppoin;
                  newBookings.splice(index, 1);
                  this.setState({
                    allAppoin: newBookings.map((booking, index) => ({
                      ...booking,
                      index,
                    })),
                    isloading: false,
                  });
                }
              })
              .finally(() => {
                this.setState({ isloading: false });
              });
          }}
        />
      </div>
    );
  };

  renderAppointmentCalendar = (bookings) => {
    const { searchText, filteredBookings } = this.state;
    if (!bookings) return null;
    let renderBooking = bookings;
    if (filteredBookings) {
      renderBooking = filteredBookings;
    } else if (searchText)
      renderBooking = bookings?.filter((val) => {
        var ll = getNestedValue(val, ["userId", "userName"], "") || "";
        if (
          ll.toLowerCase().includes(searchText.toLowerCase()) ||
          val?.phoneNo?.includes(searchText)
        )
          return true;
        return false;
      });
    return (
      <div className="col-12 pt-2 px-0">
        {this.Plan(renderBooking)}
        {/* <div className="table-responsive">
          <table className="table table-borderless saloon-working-hour-table">
            {this.renderTableHead()}
            {bookings && this.renderTableBody(bookings)} 
          </table>
        </div> */}
      </div>
    );
  };

  renderAppointmentTable = (bookings) => {
    return (
      <div className="col-12 px-0">
        {this.renderEmployDashboardHeading()}
        <div className="table-responsive">
          <table className="table table-borderless saloon-working-hour-table">
            {this.renderTableHead()}
            {bookings && this.renderTableBody(bookings)}
          </table>
        </div>
      </div>
    );
  };

  renderTableBody = () => {
    const {
      employeesWorkingHoursData,
      allAppoin,
      bookingStauses,
      searchText,
      allSaloons,
      allSaloonMap,
      filteredBookings,
      allSaloonTaxMap,
    } = this.state;
    if (!allAppoin) return null;
    let renderBooking = allAppoin;
    if (filteredBookings) {
      renderBooking = filteredBookings;
    } else if (searchText)
      renderBooking = allAppoin.filter((val) => {
        if (
          (getNestedValue(val.userId, "userName") &&
            (val.userId?.userName
              .toLowerCase()
              .includes(searchText.toLowerCase()) ||
              val.email.includes(searchText))) ||
          val?.shortenedId?.includes(searchText)
        )
          return true;
        return false;
      });
    return (
      <tbody>
        {renderBooking.map((val, ind) => {
          return (
            <tr key={ind} style={{ borderTop: "3px solid #F7F7F7" }}>
              <td>
                <div className="d-flex align-items-center">
                  <Avatar
                    name={val?.userId?.userName}
                    size="30"
                    color="#4862FF"
                    round={true}
                    textSizeRatio={2.0}
                  />
                  {/* {<img src={val.user.profile_img} className="appointment-details-employee-pic" style={{ width: 30, height: 30 }} />} */}
                  <div>
                    {
                      <span
                        style={{ marginLeft: "10px" }}
                        className="appointment-details-employee-name"
                      >
                        {val?.userId?.userName || ""}
                      </span>
                    }
                    <br />
                  </div>
                </div>
              </td>
              <td style={{ textAlign: "center" }}>
                <span className="working-days-name">
                  {" "}
                  {getNestedValue(val, ["services", 0, "date"], "")}{" "}
                  {getNestedValue(val, ["services", 0, "time"], "")}{" "}
                </span>
              </td>
              <td style={{ textAlign: "center" }}>
                {(getNestedValue(allSaloonMap, [
                  val.companyId._id,
                  "isEmployeeIndependent",
                ])
                  ? true
                  : false) && (
                  <span>
                    <div className="working-days-name">
                      {" "}
                      {val.phoneNo || ""}{" "}
                    </div>
                    <div className="working-days-name"> {val.email || ""} </div>
                  </span>
                )}
              </td>
              <td style={{ textAlign: "center" }}>
                <span className="working-days-name service-column">
                  {" "}
                  {getNestedValue(
                    val,
                    ["services", 0, "serviceId", "name"],
                    ""
                  )}{" "}
                </span>
              </td>
              <td className="text-center">
                <span className="working-days-name">
                  {val.paymentMethod || ""}
                </span>
              </td>
              <td className="text-center">
                <span className="working-days-name">{`$ ${
                  val.totalAmount || 0
                }`}</span>
              </td>
              <td className="text-center">
                <span className="working-days-name">{`$ ${val.tip || 0}`}</span>
              </td>
              {/* <td className="text-center">
                <span className="working-days-name">{val.dayName}</span>
              </td> */}
              <td className="text-center">
                {/* <span className="working-days-name">{val.dayName}</span> */}
                <span
                  className="working-days-name aBold"
                  style={bookingStauses[val.status - 1].styles}
                >
                  {bookingStauses[val.status - 1].text}
                  {val.isNoShow ? ` - ${i18n.t("no_show")}` : ""}
                </span>
              </td>
              <td
                className="text-center"
                onClick={() => {
                  this.setState({
                    showBookingModal: true,
                    selectBooking: val,
                    selectBookingAmount: val?.totalAmount,
                    paymentMethod: val?.paymentMethod,
                    bookingStaus: val.status,
                    notes: val.notes,
                    companyTaxes: allSaloonTaxMap[val.companyId],
                    tip: val.tip,
                  });
                }}
              >
                <img
                  src={Images.saloon_working_hour_edit}
                  style={{ width: 18 }}
                  className="working-days-name"
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  };

  closeEditServiceCard = () => {
    this.setState({
      showPopUp: false,
    });
  };

  onChange = (data, then = () => {}) => {
    this.setState(data, then);
  };

  renderBookingPopup = () => {
    const {
      showBookingPopup,
      enableWaitingList,
      usersList,
      phoneNumber,
      email,
      firstName,
      totalAmount,
      formErrors,
      categoryList,
      selectedCategory,
      selectServices,
      getSalonServices,
      allSaloons,
      lockFields,
      access_token,
      employeeId,
      newTime,
      newDate,
      availableTimeslots,
    } = this.state;
    return (
      <div className="col-12 px-0">
        <div
          className="employee-service-card-wrapper"
          style={{ display: showBookingPopup ? "flex" : "none" }}
        >
          <div className="container ">
            <div
              className="col-12 pt-3 pb-3 pl-md-5 pr-md-5 my-5 bg-white pop_container"
              style={{ borderRadius: "1rem" }}
            >
              <div className="d-flex justify-content-between">
                <div style={{ fontSize: 25 }}>Add Booking</div>
                <div className="row mx-0">
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      id="waiting_list"
                      className="custom-control-input"
                      checked={enableWaitingList}
                      disabled={lockFields}
                      onChange={(e) => {
                        this.setState({ enableWaitingList: e.target.checked });
                      }}
                    />
                    <label class="custom-control-label" for="waiting_list">
                      {i18n.t("enable_waiting_list")}
                    </label>
                  </div>
                  <div className="py-0 px-2 text-right ">
                    <span
                      onClick={() =>
                        this.setState({ showBookingPopup: false, notes: "" })
                      }
                      className="h4 cursor-pointer"
                    >
                      &times;
                    </span>
                  </div>
                </div>
              </div>
              <CreateAppointment
                customers={usersList}
                {...{
                  phoneNumber,
                  email,
                  firstName,
                  totalAmount,
                  formErrors,
                  categoryList,
                  selectedCategory,
                  getServices,
                  selectServices,
                  getSalonServices,
                  lockFields,
                  allSaloons,
                  employeeId,
                }}
                isGym={false}
                getServices={this.getSalonServicesByCategory}
                onChange={this.onChange}
                onChangeSelectServices={this.onChangeSelectServices}
                onChangeSelectSalon={this.onChangeSelectSalon}
                renderDateTimeEmployeeSection={this.renderEmployeeDetail}
                accessToken={access_token}
                date={newDate}
                time={newTime}
                availableTimeslots={availableTimeslots}
                enableWaitingList={enableWaitingList}
              />
              <div className="row mx-0 justify-content-end">
                {lockFields && (
                  <button
                    onClick={() => this.removeLock()}
                    type="button"
                    className="btn btn-light btn-sm mb-2 mr-2"
                    id="saloon-save-social-link"
                  >
                    <Translation>{(t) => t("remove_lock")}</Translation>
                  </button>
                )}
                <button
                  onClick={() => this.checkValidationForAddAppointment()}
                  type="button"
                  className="btn btn-light btn-sm mb-2 "
                  id="saloon-save-social-link"
                >
                  <Translation>{(t) => t("book_now")}</Translation>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  removeLock = () => {
    const { selectedEmployee, serviceData } = this.state;
    if (serviceData) {
      const {
        selectSalon,
        newDate,
        selectServices,
        timeSlotLength,
        selectDateAndTime,
        employeeId,
      } = this.state;
      const duration = parseInt(
        getNestedValue(
          JSON.parse(selectServices || "{}"),
          "duration",
          timeSlotLength
        )
      );
      this.setState({ isloading: true });
      this.props.get_available_timeslots({
        data: {
          companyId: selectSalon,
          companyServiceId: serviceData._id,
          date: newDate,
          companyEmployeeId: employeeId,
        },
        resolve: () => {
          this.setState({
            selectedTime: `${moment(selectDateAndTime).format(
              "HH:mm"
            )} - ${moment(selectDateAndTime)
              .add(duration, "m")
              .format("HH:mm")}`,
          });
        },
      });
    } else {
      this.setState({
        selectedTime: "",
        newTime: "",
        availableTimeslots: {},
      });
    }
    this.setState({ lockFields: false });
  };

  renderAdvancedSearchPopup = () => {
    const { showAdvancedSearchPopup, allSaloonMap = {} } = this.state;
    const allServices = [];
    Object.values(allSaloonMap).map((saloon) => {
      saloon.companyServices.map((companyService) => {
        if (getNestedValue(companyService.serviceId, ["name"]))
          allServices.push(getNestedValue(companyService.serviceId, ["name"]));
      });
    });
    const hideFitlerDetails = Object.values(allSaloonMap).find(
      (salon) => salon && !salon.isEmployeeIndependent
    );
    return (
      showAdvancedSearchPopup && (
        <AdvancedSearchDialog
          services={allServices}
          hideFitlerDetails={!!hideFitlerDetails}
          extraStatues={[
            { text: "Waiting", value: 1 },
            { text: "Now Serving", value: 2 },
          ]}
          onFilter={(data) => this.handleFilters(data)}
          onClose={() => this.setState({ showAdvancedSearchPopup: false })}
        ></AdvancedSearchDialog>
      )
    );
  };

  renderFOBs = () => {
    const { bookings = [], displayCalendar, access_token } = this.state;
    return (
      <>
        <button
          className="apt-fob px-2 py-1"
          onClick={() => {
            if (displayCalendar) {
              this.gitWaitingAppoinments(true);
            }
            this.setState({ displayCalendar: !displayCalendar });
            if (!displayCalendar) {
              setTimeout(() => {
                this.handleFilters();
              }, 500);
            }
          }}
        >
          {displayCalendar
            ? i18n.t("all_appointments")
            : i18n.t("weekly_appointments")}
        </button>
        <button
          className="refresh-fob p-1"
          onClick={() => {
            this.gitWaitingAppoinments();
          }}
        >
          <RefreshIcon />
        </button>
      </>
    );
  };

  checkDateIsAvil = (day) => {
    const { daysMock, weekPlans } = this.state;
    const getDay = moment(day).format("ddd");

    const companyDate = [];
    weekPlans.length &&
      weekPlans.forEach((val) => {
        if (val.availableStatus) return companyDate.push(val);
      });

    const availDay = [];
    companyDate.map((weekPlans) => {
      availDay.push(daysMock[weekPlans.dayOfWeek].name);
    });

    return !availDay.includes(getDay) ? "disabled-date" : undefined;
  };

  renderTimeSelection = () => {
    const {
      formErrors,
      availableTimeslots,
      selectedTime,
      enableWaitingList,
      newTime,
      timeSlotLength,
      startTime,
      endTime,
      lockFields,
    } = this.state;
    if (enableWaitingList) {
      return (
        <>
          <div className="form-label-group mb-3 col-6 pl-0">
            <TextField
              value={startTime}
              id="time"
              label={i18n.t("container.start_time")}
              disabled={lockFields}
              type="time"
              className="full-width"
              onChange={(text) => this.onChangeTimeText(text, "startTime")}
              onBlur={() => {
                if (startTime) {
                  let hour = startTime.split(":")[0];
                  let minute = parseInt(startTime.split(":")[1]);
                  const remainder = minute % timeSlotLength;
                  if (remainder) {
                    minute =
                      minute -
                      remainder +
                      ((minute % timeSlotLength) / timeSlotLength > 0.5
                        ? timeSlotLength
                        : 0);
                    if (minute >= 60) {
                      hour++;
                      minute %= 60;
                    }
                    const time = `${hour}:${
                      minute < 10 ? "0" + minute : minute
                    }`;
                    this.setState({ startTime: time });
                  }
                }
              }}
              helperText={formErrors.startTimeError}
              error={!!formErrors.startTimeError}
            />
          </div>
          <div className="form-label-group mb-3 col-6 pl-0">
            <TextField
              value={endTime}
              id="time"
              label={i18n.t("container.end_time")}
              disabled={lockFields}
              type="time"
              className="full-width"
              onChange={(text) => this.onChangeTimeText(text, "endTime")}
              onBlur={() => {
                if (endTime) {
                  let hour = endTime.split(":")[0];
                  let minute = parseInt(endTime.split(":")[1]);
                  const remainder = minute % timeSlotLength;
                  if (remainder) {
                    minute =
                      minute -
                      remainder +
                      ((minute % timeSlotLength) / timeSlotLength > 0.5
                        ? timeSlotLength
                        : 0);
                    if (minute >= 60) {
                      hour++;
                      minute %= 60;
                    }
                    const time = `${hour}:${
                      minute < 10 ? "0" + minute : minute
                    }`;
                    this.setState({ endTime: time });
                  }
                }
              }}
              helperText={formErrors.endTimeError}
              error={!!formErrors.endTimeError}
            />
          </div>
        </>
      );
    } else {
      return (
        <div className="form-label-group mb-3 col-6">
          <FormControl
            error={!!formErrors.selectedTimeError}
            className="full-width"
          >
            <InputLabel id="services">{i18n.t("container.time")}</InputLabel>
            <Select
              value={selectedTime}
              disabled={lockFields}
              onChange={(text) => this.onChangeTime(text)}
              placeholder={i18n.t("container.select_time")}
            >
              {Object.keys(availableTimeslots ? availableTimeslots : {})?.map(
                (val, ind) => {
                  return (
                    <MenuItem key={ind} value={val}>
                      {val.split(" ")[0]}
                    </MenuItem>
                  );
                }
              )}
            </Select>
            {!!formErrors.selectedTimeError && (
              <FormHelperText>{formErrors.selectedTimeError}</FormHelperText>
            )}
          </FormControl>
        </div>
      );
    }
  };
  renderEmployeeDetail = () => {
    const { formErrors, selectDateAndTime, lockFields, enableWaitingList } =
      this.state;
    return (
      <div className="row px-0 mx-0">
        <div
          className={
            "form-label-group mb-3 " +
            (enableWaitingList ? "col-12" : "col-sm-6 col-xs-12")
          }
        >
          <DatePicker
            className="full-width"
            minDate={new Date()}
            selected={selectDateAndTime}
            disabled={lockFields}
            onChange={(date) => this.onDateChange(date)}
            value={this.state.selectDateAndTime}
            customInput={
              <TextField
                id="time"
                label={"Date" + (lockFields ? ` (${i18n.t("locked")})` : "")}
                value={this.state.newDate}
                className="full-width"
                readOnly={true}
                disabled={lockFields}
                helperText={formErrors.selectDateAndTimeError}
                error={!!formErrors.selectDateAndTimeError}
              />
            }
            // dayClassName={(date) => this.checkDateIsAvil(date)}
            placeholderText={i18n.t(
              "container.services_click_to_select_a_date"
            )}
          />
        </div>

        {this.renderTimeSelection()}
      </div>
    );
  };

  renderEditAppointment = () => {
    const {
      employees,
      allServices = [],
      start,
      employeeColorMap = {},
      timeSlotLength,
      showBookingModal,
      bookingStatuses,
      selectBooking = {},
      employeeMap,
      isGym,
      access_token,
      allAppoin,
      allSaloonMap,
      companyTaxes,
      showInsertPayment,
      paymentCompleteCallback,
    } = this.state;

    if (showBookingModal) {
      return (
        <AppointmentModal
          showInsertPayment={showInsertPayment}
          paymentCompleteCallback={paymentCompleteCallback}
          setData={({ cartId, discount }) => {
            if (
              typeof selectBooking.index === "number" &&
              allAppoin[selectBooking.index]
            ) {
              if (discount || typeof discount == "number") {
                allAppoin[selectBooking.index].discount = discount;
                this.setState({
                  selectBooking: { ...selectBooking, discount },
                  allAppoin,
                });
              }
              if (cartId) {
                allAppoin[selectBooking.index].cartId = cartId;
                this.setState({
                  selectBooking: { ...selectBooking, cartId },
                  allAppoin,
                });
              }
            }
          }}
          open={showBookingModal}
          selectBooking={selectBooking}
          isIndependent={getNestedValue(
            allSaloonMap,
            [
              getNestedValue(selectBooking, ["companyId", "_id"]),
              "isEmployeeIndependent",
            ],
            true
          )}
          employeeMap={employeeMap}
          isGym={isGym}
          handleDrawerClose={(ev) => {
            this.setState({ showBookingModal: false });
          }}
          statusButtonClicked={(status, additional) => {
            const sendUpdate = (result, append = {}) => {
              if (result.value) {
                this.setState({ bookingStaus: status }, () => {
                  this.handleUpdate({ ...append });
                });
              } else {
                this.setState(
                  {
                    bookingStaus: status,
                  },
                  this.handleUpdate
                );
              }
            };
            if (status == 3) {
              return sendUpdate({ value: true }, additional);
            }
            if (status != 4) {
              Swal.fire({
                title: i18n.t("change_status"),
                text: `${i18n.t("confirm_status_change")} ${i18n.t(
                  statusMap[status]
                )}`,
                showCancelButton: true,
                cancelButtonText: i18n.t("no"),
                confirmButtonText: i18n.t("yes"),
              }).then((result) => {
                if (result.value) {
                  if (status == 7) {
                    status = 3;
                    const price = getNestedValue(
                      selectBooking,
                      ["totalAmount"],
                      ""
                    );
                    const noShowFeePercentage = getNestedValue(
                      selectBooking,
                      ["companyId", "noShowFeePercentage"],
                      0
                    );
                    const noShowFee = (price * noShowFeePercentage) / 100;

                    if (noShowFee) {
                      Swal.fire({
                        title: i18n.t("charge_no_show_fee"),
                        text: `$ ${noShowFee.toFixed(2)}`,
                        showCancelButton: true,
                        cancelButtonText: i18n.t("no"),
                        confirmButtonText: i18n.t("yes"),
                      }).then((result) =>
                        sendUpdate(result, { isNoShow: result.value })
                      );
                    } else {
                      sendUpdate({ value: false });
                    }
                  } else {
                    sendUpdate({ value: false });
                  }
                }
              });
            } else {
              this.setState(
                {
                  bookingStaus: status,
                },
                this.handleUpdate
              );
            }
          }}
          onProductChange={(e, index) => {
            this.setState({ isloading: true });
            updateCartProduct(
              {
                productId: selectBooking.cartId.products[index]._id,
                cartId: selectBooking.cartId._id,
              },
              { quantity: e },
              access_token
            )
              .then(({ data }) => {
                if (data.success) {
                  selectBooking.cartId.products[index].quantity =
                    data.data.quantity;
                  allAppoin[selectBooking.index] = selectBooking;
                  this.setState({
                    selectBooking: { ...selectBooking },
                    allAppoin,
                  });
                } else {
                  if (data.code) {
                    ErrorHelper.handleErrors(
                      i18n.t(data.code, { ...data.data }),
                      true
                    );
                  } else {
                    ErrorHelper.handleErrors("Failed to Add Item", true);
                  }
                }
              })
              .finally(() => {
                this.setState({ isloading: false });
              });
          }}
          onProductDelete={(index) => {
            this.setState({ isloading: true });
            removeProduct(
              {
                productId: selectBooking.cartId.products[index]._id,
                cartId: selectBooking.cartId._id,
              },
              access_token
            )
              .then(({ data }) => {
                if (data.success) {
                  if (allAppoin[selectBooking.index]) {
                    allAppoin[selectBooking.index].cartId = data.data;
                    this.setState({
                      selectBooking: { ...selectBooking, cartId: data.data },
                      allAppoin,
                    });
                  }
                }
              })
              .finally(() => {
                this.setState({ isloading: false });
              });
          }}
          onServiceDelete={(index) => {
            this.setState({ isloading: true });
            removeExtra(
              {
                extraId: selectBooking.extras[index]._id,
                bookingId: selectBooking._id,
              },
              access_token
            )
              .then(({ data }) => {
                if (data.success) {
                  allAppoin[selectBooking.index] = data.data;
                  this.setState({ selectBooking: data.data, allAppoin });
                }
              })
              .finally(() => {
                this.setState({ isloading: false });
              });
          }}
          onNotesClick={() => this.handleNotes()}
          onModifyClick={() => this.handleEditDetails()}
          onAddClick={() => this.handleAddProductsServices()}
          companyTaxes={companyTaxes}
          toggleDepositRequired={this.toggleDepositRequired}
          toggleTaxes={this.toggleTaxes}
          onChangeTip={this.onChangeTip}
        ></AppointmentModal>
      );
    } else {
      return null;
    }
  };

  genericUpdate = async (body) => {
    const { selectBooking, access_token } = this.state;
    this.setState({
      isloading: true,
    });
    let { allAppoin } = this.state;
    const services = [
      {
        serviceId: selectBooking.services[0].serviceId._id,
        categoryId: selectBooking.services[0].categoryId._id,
        date: selectBooking.services[0].date,
        time: selectBooking.services[0].time,
        employeeId: selectBooking.services[0].employeeId?._id,
      },
    ];
    const { data } = await editBooking(
      {
        bookingId: selectBooking._id,
        status: selectBooking.status,
        services,
        ...body,
      },
      access_token
    );
    let newSelectBooking = selectBooking;
    if (data.success) {
      const newBooking = data.data;
      newBooking.services = newBooking.services.map((service) => {
        return {
          ...service,
        };
      });
      newSelectBooking = { ...newBooking, index: selectBooking.index };
      if (typeof selectBooking.index === "number") {
        allAppoin[selectBooking.index] = newSelectBooking;
      }
      this.handleClosePopup();
      this.setState({
        isloading: false,
        allAppoin: [...allAppoin],
        selectBooking: newSelectBooking,
        editDetails: false,
        ...body,
      });
    }
  };

  onChangeTip = (tip) => {
    const { tip: oldTip } = this.state;
    if (oldTip != tip) {
      this.genericUpdate({ tip });
    }
  };

  toggleDepositRequired = (depositRequired) => {
    this.genericUpdate({ depositRequired });
  };

  toggleTaxes = (includeTaxes) => {
    this.genericUpdate({ includeTaxes });
  };

  render() {
    const { allAppoin, displayCalendar } = this.state;
    return (
      <div>
        {this.renderLoader()}
        <div className="content-container">
          <div className="row mx-0">
            {/* {this.renderEmployDashboardHeading()} */}
            {this.renderPopup()}
            {displayCalendar
              ? this.renderAppointmentCalendar(allAppoin ? allAppoin : [])
              : this.renderAppointmentTable(allAppoin ? allAppoin : [])}
            {this.renderEditAppointment()}
            {this.renderBookingPopup()}
            {this.renderAdvancedSearchPopup()}
            {this.renderFOBs()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  getAvailableTimeslots: state.getAvailableTimeslots,
  booking: state.activeBooking.data,
  transactions: state.bookingTransaction.data,
});

const action = {
  get_available_timeslots,
  clear,
};

export default connect(mapStateToProps, action)(AddWorkingHours);
