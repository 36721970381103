import { take, put, call, fork } from "redux-saga/effects";

import * as types from "../actions/ActionTypes";
import { success, failure } from "../actions/Register";

import { register_Api } from "../../config/WebServices";
import { ErrorHelper } from "../../helpers";
import axios from "axios";

function callRequest(data) {
  return axios.post(register_Api, data);
}
function* watchRequest() {
  while (true) {
    const { payload } = yield take(types.REGISTER.REQUEST);
    try {
      const { data } = yield call(callRequest, payload);
      yield put(success(data));
    } catch (err) {
      yield put(failure(err));
      ErrorHelper.handleErrors(err, true);
    }
  }
}

export default function* root() {
  yield fork(watchRequest);
}
