import { connect } from "react-redux";
import React, { Component } from "react";
import  Translation  from 'react-i18next';
import i18n from '../../../i18n';
import { BaseModal, DotsLoader } from "../../../components";
import {
  oneTimePayment
} from "../../../config/simpleApiCalls";
import { getRequest as getEasy1Products } from "../../../redux/actions/Saloon/Easy1Products"
import { getRequest as getCards } from "../../../redux/actions/Card"
import { getRequest as getBilling } from "../../../redux/actions/Saloon/Billing";
import { SuccessHelper, ErrorHelper } from "../../../helpers";
import Button from "@material-ui/core/Button";

import "./styles.css";
import { getNestedValue } from "../../../util/objectMethods";
import InvoiceTable from "../../../components/InvoiceTable";
import DevicesAndEquipmentOrder from "../../../components/DevicesAndEquipmentOrder";
import PricingFooterCard from "../../../components/PricingFooterCard";

class PricingPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isloading: false,
      taxPrices: {},
      timePerioud: 'monthly',
      numberOfAdditionalEmployees: 0,
      subscriptionTotal: 0,
      subtotal: 0,
      gst: 0,
      qst: 0,
      grandTotal: 0,
      equipementTotal: 0,
      selectDeviceQuantityMap: {},
      showNewOrderModal: false
    };
  }

  async componentDidMount() {
    const salonInfo = await JSON.parse(sessionStorage.getItem("saloon"));
    // this.getPricingPlans(false);
    Promise.all([
      new Promise((resolve) => {
        this.props.getBilling({ access_token: salonInfo.access_token, type: 'equipment' }, { success: resolve })
      }),
      new Promise((resolve) => {
        this.props.getEasy1Products({}, { success: resolve })
      }),
      new Promise((resolve) => {
        this.setState({ salonInfo }, () => {
          this.props.getCards({ access_token: salonInfo.access_token }, { success: resolve });
        })
      })
    ]).then(() => {
      setTimeout(() => {
        this.computeTotals()
      }, 0)
      this.setState({ isloading: false, salonInfo })
    })
  }

  handleSubmit = () => {
    const { selectDeviceQuantityMap, salonInfo } = this.state
    this.setState({ isloading: true })
    if(Object.keys(selectDeviceQuantityMap).length) {
      oneTimePayment(
        {
          prices: Object.entries(selectDeviceQuantityMap).map(([key, value]) => {
            return {
              id: key,
              quantity: value
            }
          }),
        }, 
        salonInfo.access_token
      ).then(({data}) => {
        if(data.success) {
          SuccessHelper.handleSuccess(i18n.t('order_placed'), true)
          this.props.getBilling({ access_token: salonInfo.access_token, type: 'equipment' }, { success: () => {
            this.setState({ selectDeviceQuantityMap: {}, showNewOrderModal: false, isloading: false })
          }})
        }
      })
      
    }
  }

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  renderFooter = () => {
    const {
      subtotal,
      gst, 
      qst,
      grandTotal
    } = this.state;
    const items = [
      { label: i18n.t('subtotal'), amount: `$${subtotal.toFixed(2)}`},
      { label: 'TPS / GST', amount: `$${gst.toFixed(2)}`},
      { label: 'TVQ / QST:', amount: `$${qst.toFixed(2)}`},
      { label: i18n.t('grand_total'), amount: `$${grandTotal.toFixed(2)}`},
    ]
    return (
      <div className="row mx-0 justify-content-end">
        <PricingFooterCard
          className='col-4 col-xs-12'
          items={items}
        />
      </div>
    )
  }

  computeTotals = () => {
    const { selectDeviceQuantityMap } = this.state
    const { equipement } = this.props
    const GST_rate = 5;
    const QST_rate = 9.975;
    let subtotal = 0
    equipement.map(element => {
      const price = element.prices[0]
      console.log(price)
      if(selectDeviceQuantityMap[price.id]) {
        subtotal += selectDeviceQuantityMap[price.id] * getNestedValue(price, ['unit_amount'], 0)
      }
    })
    subtotal = subtotal ? (subtotal / 100) : subtotal
    const gst = subtotal * (GST_rate / 100)
    const qst = subtotal * (QST_rate / 100)
    this.setState({ 
      subtotal,
      gst,
      qst,
      grandTotal: (subtotal + gst + qst)
    })
  }

  handleDeviceQuantityChange = (id, numberOfDevices) => {
    const { selectDeviceQuantityMap } = this.state
    if(numberOfDevices < 0) {
      numberOfDevices = 0
    }
    selectDeviceQuantityMap[id] = numberOfDevices
    this.setState({ selectDeviceQuantityMap }, this.computeTotals)
  }

  handleDeviceAndEquipment() {
    const {
      eQuipmentTotalPrice,
      selectDeviceQuantityMap,
      showNewOrderModal,
    } = this.state;
    const { equipement = [] } = this.props

    return (
      <BaseModal
        open={showNewOrderModal}
        onClose={() => this.setState({ showNewOrderModal: false })}
        title={i18n.t('new_order')}
        content={
          <>
            <DevicesAndEquipmentOrder 
              {...{
                selectDeviceQuantityMap,
                equipement,
              }}
              handleDeviceQuantityChange={this.handleDeviceQuantityChange}
            />
            {this.renderFooter()}
          </>
        }
        actions={
          <div className="row mx-0 justify-content-end">
            <Button
              onClick={this.handleSubmit}
              className="primary-button"
            >
              {i18n.t("send_order")}
            </Button>
          </div>
        }
      />
    );
  }

  renderOrderHistory = () => {
    const { billing } = this.props;
    return (
      <InvoiceTable invoices={billing} />
    )
  }

  render() {

    return (
      <div>
        {this.renderLoader()}
        <div className="content-container">
          <div className="row mx-0 justify-content-between pb-3">
            <h1 className="saloon-dashboard-heading">{i18n.t('container.devices_and_equipment')}:</h1>
            <Button
              onClick={() => {
                this.setState({ showNewOrderModal: true });
              }}
              className="primary-button"
            >
              {i18n.t("new_order")}
            </Button>
          </div>
          <div className="row mx-0">
            {this.renderOrderHistory()}
            {this.handleDeviceAndEquipment()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const products = state.easy1Products.data
  const equipement = getNestedValue(products, ['equipement'], [])
  return { 
    cards: state.card.data, 
    equipement,
    billing: state.billing.data
  }
};

const action = { getEasy1Products, getCards, getBilling };

export default connect(mapStateToProps, action)(PricingPlan);
