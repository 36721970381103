import { connect } from "react-redux";
import React, { Component } from "react";
import moment from "moment";
import {
  getUser,
  editUser,
  userCreateProfilePicture,
} from "../../../config/simpleApiCalls";
import { DotsLoader } from "../../../components";
import { Images } from "../../../theme";
import { SuccessHelper } from "../../../helpers";
import Input from "react-phone-number-input/input";
import i18n from "../../../i18n";
import NumberFormat from "react-number-format";
import { Translation } from "react-i18next";
import "./styles.css";
import Sytles from "./sytles";

class EditProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Personal Information
      imageFile: null,
      imageUrl: "",
      firstName: "",
      lastName: "",
      password: "",
      dateOfBirth: "",
      password: "",
      address: "",
      city: "",
      province: "",
      postalCode: "",
      telephone: "",
      functionRole: "",
      email: "",
      employee: "Employee",

      // Personal Information Error
      firstNameError: "",
      lastNameError: "",
      dateOfBirthError: "",
      passwordError: "",
      addressError: "",
      cityError: "",
      provinceError: "",
      postalCodeError: "",
      telephoneError: "",
      emailError: "",
      functionRoleError: "",
      isloading: false,
      access_token: "",
    };
  }

  async componentDidMount() {
    const employeeData = await JSON.parse(sessionStorage.getItem("employee"));
    if (employeeData) {
      this.setState({ access_token: employeeData.access_token }, () => {
        this.handleGetUser();
      });
    }
  }

  handleGetUser = async () => {
    this.setState({ isloading: true });
    const { access_token } = this.state;
    getUser(access_token)
      .then((res) => {
        if (res.data.success) {
          this.setState({
            isloading: false,
            firstName: res.data.data.user.firstName,
            lastName: res.data.data.user.lastName,
            dateOfBirth: res.data.data.user.dob,
            address: res.data.data.user.address,
            city: res.data.data.user.city,
            province: res.data.data.user.province,
            postalCode: res.data.data.user.postalCode,
            telephone: res.data.data.user.phoneNo,
            email: res.data.data.user.email,
            imageUrl: res.data.data.user.profile_img,
          });
        }
      })
      .catch((error) => {
        console.log(error, "errorrrrrrrrrrrrrrrrrrr");
      });
  };

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  // Personal Information
  onChangeFirstName = (text) => this.setState({ firstName: text.target.value });
  onChangeLastName = (text) => this.setState({ lastName: text.target.value });
  onChangeDateOfBirth = (text) =>
    this.setState({ dateOfBirth: text.target.value });
  onChangePassword = (text) => this.setState({ password: text.target.value });
  onChangeAddress = (text) => this.setState({ address: text.target.value });
  onChangeCity = (text) => this.setState({ city: text.target.value });
  onChangeProvince = (text) => this.setState({ province: text.target.value });
  onChangePostalCode = (text) =>
    this.setState({ postalCode: text.target.value });
  onChangeTelephone = (text) => this.setState({ telephone: text.target.value });
  onChangeFunctionRole = (text) =>
    this.setState({ functionRole: text.target.value });
  onChangeEmail = (text) => this.setState({ email: text.target.value });

  checkValidation = () => {
    const {
      firstName,
      lastName,
      dateOfBirth,
      password,
      address,
      city,
      province,
      postalCode,
      telephone,
      functionRole,
      email,
    } = this.state;

    // console.log(
    //   telephone.match(telephoneRegex),
    //   "dateOfBirth-_____________________________________________________________________________"
    // );

    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const currentDate = new Date().getDate();
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const nameRegex =
      /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{2,}$/u;
    const telephoneRegex =
      /[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]/g;
    if (!firstName) {
      this.setState({
        firstNameError: (
          <Translation>{(t) => t("first_name_required")}</Translation>
        ),
      });
      setTimeout(() => {
        this.setState({
          firstNameError: "",
        });
      }, 6000);
    } else if (!telephone.replace(/\s/g, "").match(telephoneRegex)) {
      this.setState({
        telephoneError: (
          <Translation>{(t) => t("invalid_phone_number")}</Translation>
        ),
      });

      setTimeout(() => {
        this.setState({
          telephoneError: "",
        });
      }, 6000);
    } else if (!lastName) {
      this.setState({
        lastNameError: (
          <Translation>{(t) => t("last_name_required")}</Translation>
        ),
      });
      setTimeout(() => {
        this.setState({
          lastNameError: "",
        });
      }, 6000);
    } else if (!address) {
      this.setState({
        addressError: <Translation>{(t) => t("address_required")}</Translation>,
      });
      setTimeout(() => {
        this.setState({
          addressError: "",
        });
      }, 6000);
    } else if (!city) {
      this.setState({
        cityError: <Translation>{(t) => t("city_required")}</Translation>,
      });
      setTimeout(() => {
        this.setState({
          cityError: "",
        });
      }, 6000);
    } else if (!province) {
      this.setState({
        provinceError: (
          <Translation>{(t) => t("province_required")}</Translation>
        ),
      });
      setTimeout(() => {
        this.setState({
          provinceError: "",
        });
      }, 6000);
    } else if (!postalCode) {
      this.setState({
        postalCodeError: (
          <Translation>{(t) => t("postal_code_required")}</Translation>
        ),
      });
      setTimeout(() => {
        this.setState({
          postalCodeError: "",
        });
      }, 6000);
    } else if (!telephone) {
      this.setState({
        telephoneError: (
          <Translation>{(t) => t("telephone_required")}</Translation>
        ),
      });
      setTimeout(() => {
        this.setState({
          telephoneError: "",
        });
      }, 6000);
    } else if (!email) {
      this.setState({
        emailError: (
          <Translation>{(t) => t("container.email_required")}</Translation>
        ),
      });
      setTimeout(() => {
        this.setState({
          emailError: "",
        });
      }, 6000);
    } else if (!email.match(emailRegex)) {
      this.setState({
        emailError: (
          <Translation>{(t) => t("container.invalid_email")}</Translation>
        ),
      });
      setTimeout(() => {
        this.setState({
          emailError: "",
        });
      }, 6000);
    } else {
      this.handleEditUser();
    }
  };

  handleEditUser = () => {
    this.setState({ isloading: true });

    const {
      firstName,
      lastName,
      dateOfBirth,
      city,
      address,
      province,
      telephone,
      postalCode,
      email,
      access_token,
    } = this.state;
    const payload = {
      firstName,
      lastName,
      dob: dateOfBirth,
      city,
      address,
      province,
      phoneNo: telephone,
      postalCode,
      userName: firstName + " " + lastName,
    };

    editUser(payload, access_token)
      .then((res) => {
        if (res.data.success) {
          SuccessHelper.handleSuccess(
            i18n.t("container.employe_update_profile"),
            true
          );
          // this.setState({ isloading: true });

          this.handleGetUser();
        }
      })
      .catch((error) => {
        console.log(error, "errorrrrrrrrrrrrrrrrrrr");
      });
  };

  fileSelectedHandler = (event) => {
    const file = URL.createObjectURL(event.target.files[0]);

    this.setState({ imageUrl: file, imageFile: event.target.files[0] });
  };

  handleUploadNewPicture = () => {
    this.setState({ isloading: true });
    const { imageUrl, imageFile, access_token } = this.state;
    let payloadFormdata = new FormData();
    payloadFormdata.append("profile_img", imageFile);
    userCreateProfilePicture(payloadFormdata, access_token)
      .then((res) => {
        if (res.data.success) {
          SuccessHelper.handleSuccess(
            <Translation>
              {(t) => t("container.employe_update_img_profile")}
            </Translation>,
            true
          );
          this.setState({ isloading: false, imageFile: null });
          //   , () =>
          // );}
          this.handleGetUser();
        }
      })
      .catch((error) => {
        console.log(error, "errorrrrrrrrrrrrrrrrrrr");
      });
  };

  renderSaloonDashboardHeading = () => {
    return (
      <div className="col-12 p-3">
        <h1 className="saloon-dashboard-heading">
          <Translation>
            {(t) => t("container.employe_profile_edit")}
          </Translation>
        </h1>
      </div>
    );
  };

  renderUploadPicture = () => {
    const { imageUrl, imageFile } = this.state;
    return (
      <div className="col-12 d-md-flex align-items-md-center text-center mb-3">
        <input
          type="file"
          style={{ display: "none" }}
          onChange={this.fileSelectedHandler}
          ref={(fileInput) => (this.fileInput = fileInput)}
          accept="image/*"
        />
        <span
          onClick={() => this.fileInput.click()}
          style={{
            backgroundImage: `url(${
              imageUrl ? imageUrl : Images.user_profile_image
            })`,
          }}
          id="saloon-edit-profile"
        />
        {imageFile ? (
          <button
            type="button"
            className="btn btn-light btn-sm"
            id="saloon-upload-picture"
            onClick={() => this.handleUploadNewPicture()}
          >
            <Translation>
              {(t) => t("container.employe_profile_upload_img")}
            </Translation>
          </button>
        ) : null}
        {/* <button type="button" className="btn btn-light btn-sm" id="saloon-delete-picture">Delete</button> */}
      </div>
    );
  };

  renderEditProfileCard = () => {
    const { formTypeId } = this.state;
    return (
      <div className="col-12">
        <div className="col-12 p-3 mb-3" id="saloon-edit-profile-card">
          {this.renderPersonalInformationForm()}
          <div className="col-12">
            <img
              onClick={() => this.checkValidation()}
              src={Images.saloon_form_submit}
              id="edit-saloon-form-submit"
            />
          </div>
        </div>
      </div>
    );
  };

  renderInputCol6 = (
    id,
    lable,
    inputType,
    onChange,
    val,
    disabled,
    errorMessage
  ) => {
    return (
      <div class="form-group col-md-6 px-0 px-md-3">
        <label htmlFor={id} className="saloon-form-lable">
          {lable}:
        </label>
        <input
          type={inputType}
          class="form-control saloon-form-input"
          id={id}
          onChange={(text) => onChange(text)}
          value={val}
          disabled={disabled}
        />
        {errorMessage ? (
          <small className="form-text text-danger ml-3">{errorMessage}</small>
        ) : null}
      </div>
    );
  };

  renderInputCol12 = (
    id,
    lable,
    inputType,
    onChange,
    val,
    disabled,
    errorMessage
  ) => {
    return (
      <div class="form-group col-12 px-0 px-md-3">
        <label htmlFor={id} className="saloon-form-lable">
          {lable}:
        </label>
        <input
          type={inputType}
          class="form-control saloon-form-input"
          id={id}
          onChange={(text) => onChange(text)}
          value={val}
        />
        {errorMessage ? (
          <small className="form-text text-danger ml-3">{errorMessage}</small>
        ) : null}
      </div>
    );
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  inputTypeNumber = (
    id,
    lable,
    inputType,
    onChange,
    val,
    errorMessageStatus,
    errorMessage
  ) => {
    return (
      <div class="form-group col-md-6 px-0 px-md-3">
        <label htmlFor={id} className="saloon-form-lable">
          {lable}:
        </label>
        <NumberFormat
          type={inputType}
          class="form-control saloon-form-input"
          id={id}
          onChange={(text) => onChange(text)}
          value={val}
          format="### ### ####"
        />

        {errorMessage ? (
          <small className="form-text text-danger ml-3">{errorMessage}</small>
        ) : null}
      </div>
    );
  };

  renderPersonalInformationForm = () => {
    return (
      <div className="col-12 px-0 px-md-3">
        <h3 className="form-heading">{i18n.t("container.personal_info")}</h3>
        <div class="form-row">
          {this.renderInputCol6(
            "first-name",
            i18n.t("first_name"),
            "text",
            this.onChangeFirstName,
            this.state.firstName,
            false,
            this.state.firstNameError
          )}
          {this.renderInputCol6(
            "last-name",
            i18n.t("last_name"),
            "text",
            this.onChangeLastName,
            this.state.lastName,
            false,
            this.state.lastNameError
          )}
        </div>
        <div class="form-row">
          <div class="form-group col-md-6 px-0 px-md-3">
            <label htmlFor="date-of-birth" className="saloon-form-lable">
              <Translation>{(t) => t("container.dob")}</Translation>
            </label>
            <input
              type="date"
              class="form-control saloon-form-input"
              value={moment(this.state.dateOfBirth).format("YYYY-MM-DD")}
              id="date-of-birth"
              min="1970-01-01"
              max={moment(Date.now()).format("YYYY-MM-DD")}
              onChange={(text) => this.onChangeDateOfBirth(text)}
            />
            {this.state.dateOfBirthError ? (
              <small className="form-text text-danger ml-3">
                {this.state.dateOfBirthError}
              </small>
            ) : null}
          </div>
          {this.renderInputCol6(
            "email",
            <Translation>{(t) => t("container.profile_email")}</Translation>,
            "email",
            this.onChangeEmail,
            this.state.email,
            true,
            this.state.emailError
          )}
        </div>
        <div class="form-row">
          {this.renderInputCol12(
            "address",
            <Translation>{(t) => t("container.address")}</Translation>,
            "text",
            this.onChangeAddress,
            this.state.address,
            false,
            this.state.addressError
          )}
        </div>
        <div class="form-row">
          {this.renderInputCol6(
            "city",
            <Translation>{(t) => t("container.city")}</Translation>,
            "text",
            this.onChangeCity,
            this.state.city,
            false,
            this.state.cityError
          )}
          {this.renderInputCol6(
            "province",
            <Translation>{(t) => t("container.province")}</Translation>,
            "text",
            this.onChangeProvince,
            this.state.province,
            false,
            this.state.provinceError
          )}
        </div>
        <div class="form-row">
          {this.renderInputCol6(
            "postal-code",
            <Translation>{(t) => t("container.postal_code")}</Translation>,
            "text",
            this.onChangePostalCode,
            this.state.postalCode,
            false,
            this.state.postalCodeError
          )}
          {this.inputTypeNumber(
            "telephone",
            <Translation>{(t) => t("container.telephone")}</Translation>,
            "telephone",
            this.onChangeTelephone,
            this.state.telephone,
            false,
            this.state.telephoneError
          )}
        </div>
        {/* <div class="form-row">
          {this.renderInputCol12(
            "Employee",
            <Translation>{(t) => t("container.functional_role")}</Translation>,
            "text",
            this.onChangeFunctionRole,
            this.state.employee,
            true
          )}
        </div> */}
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.renderLoader()}
        <div className="container">
          <div className="row">
            {this.renderSaloonDashboardHeading()}
            {this.renderUploadPicture()}
            {this.renderEditProfileCard()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(EditProfile);
