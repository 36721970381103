import { connect } from "react-redux";
import React, { Component } from "react";
import Swal from "sweetalert2";
import { Translation } from "react-i18next";
import i18n from "../../../../i18n";
import { Images } from "../../../../theme";
import { BaseModal, DotsLoader } from "../../../../components";
import ProductForm from "../../../../components/Forms/Product";
import {
  updateMultipleService,
  updateProduct,
  deleteProduct,
  getProduct,
  getInventoryTransactions,
  createIncreaseTransaction,
  createReduceTransaction,
} from "../../../../config/simpleApiCalls";
import { ErrorHelper, SuccessHelper } from "../../../../helpers";
import { List, arrayMove } from "react-movable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import moment from "moment";

import "./styles.css";
import Sytles from "./sytles";
import { getNestedValue } from "../../../../util/objectMethods";

class AllProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // GENERAL
      searchText: "",
      showEditProductCard: false,

      // FOR SERVICES
      serviceList: [],

      // FOR EDIT SERVICE
      categoryList: [],
      serviceName: "",
      serviceId: "",
      price: 0,
      categoryName: "",
      categoryId: "",
      isActive: 1,
      imageUrl: "",
      minimumTime: 0,
      imageFile: "",
      filteredList: [],
      shouldSendTextReminders: false,
      // ERRORS
      serviceNameError: "",
      priceError: "",
      minimumTimeError: "",
      categoryNameError: "",
      textReminderFrequency: 2,
      startDate: Date.now(),
      endDate: Date.now(),
    };
  }

  componentDidMount = async () => {
    const saloonData = await JSON.parse(sessionStorage.getItem("saloon"));
    const company = JSON.parse(sessionStorage.getItem("company"));
    const isGym = company.function == "gym";
    this.setState({
      isGym,
      timeSlotLength: company.timeSlotLength,
      companyId: company._id,
    });
    if (saloonData) {
      this.setState({
        access_token: saloonData.access_token,
      });
      this.handleSaloonGetAllProducts();
    }
  };

  handleSaloonGetAllProducts = () => {
    this.setState({ isloading: true });
    const { access_token, companyId } = this.state;
    getProduct({ companyId }, access_token)
      .then((res) => {
        if (res.data.success) {
          this.setState({ isloading: false, serviceList: res.data.data });
        }
      })
      .catch((error) => {
        console.log(error, "get all services error");
      });
  };

  retrieveTransactions = () => {
    const { startDate, endDate, access_token, selectedProductId } = this.state;
    this.setState({ isloading: true });
    getInventoryTransactions(
      { startDate, endDate, productId: selectedProductId },
      access_token
    ).then(({ data }) => {
      if (data.success) {
        this.setState({
          transactions: data.data.transactions,
          averageCost: data.data.average_cost ? data.data.average_cost : 0,
        });
      }
      this.setState({ isloading: false });
    });
  };

  handleTransaction = (type) => {
    const { costPerUnit, quantity, selectedProductId, access_token, notes } =
      this.state;
    if (!notes) {
      this.setState({ notesError: i18n.t("notes_required") });
      setTimeout(() => {
        this.setState({ notesError: "" });
      }, 6000);
    } else {
      this.setState({ isloading: true });
      if (type == "increase") {
        createIncreaseTransaction(
          { cost: costPerUnit, quantity, productId: selectedProductId, notes },
          access_token
        ).then(({ data }) => {
          if (data.success) {
            SuccessHelper.handleSuccess("Transaction Recorded", true);
            this.setState({
              quantity: "",
              costPerUnit: "",
              notes: "",
            });
          } else {
            ErrorHelper.handleErrors(data.data.msg, true);
          }
          this.setState({ isloading: false });
        });
      } else {
        createReduceTransaction(
          { quantity, productId: selectedProductId, notes },
          access_token
        ).then(({ data }) => {
          if (data.success) {
            SuccessHelper.handleSuccess("Transaction Recorded", true);
            this.setState({
              quantity: "",
              costPerUnit: "",
              notes: "",
            });
          } else {
            ErrorHelper.handleErrors(data.msg, true);
          }
          this.setState({ isloading: false });
        });
      }
    }
  };

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  onChangeSearchText = (text) => {
    const { serviceList, searchText } = this.state;
    let newList = [];
    let filteredList =
      serviceList &&
      serviceList.filter((val, ind) => {
        let name = val.name.toUpperCase();

        if (
          text.target.value.toUpperCase().charAt(0) === name.charAt(0) ||
          text.target.value.toUpperCase() === name
        ) {
          newList.push(val);
        } else {
          return newList;
        }
      });
    this.setState({
      filteredList: newList,
      searchText: text.target.value,
      activePage: 1,
    });
  };

  checkValidation = (successCallback) => {
    const { name, description, cost, charge, quantity, reorderTrigger } =
      this.state.values;
    if (!name) {
      this.setState({
        formErrors: {
          name: i18n.t("name_required"),
        },
      });
      setTimeout(() => {
        this.setState({ formErrors: {} });
      }, 6000);
    } else if (!description) {
      this.setState({
        formErrors: {
          description: i18n.t("container.description_required"),
        },
      });
      setTimeout(() => {
        this.setState({ formErrors: {} });
      }, 6000);
    } else {
      this.handleSaloonEditProduct(successCallback);
    }
  };

  handleSaloonEditProduct = (successCallback) => {
    this.setState({ isloading: true });
    const { access_token, editing } = this.state;
    const {
      name,
      description,
      cost,
      charge,
      quantity,
      reorderTrigger,
      taxable,
      allow_backorder,
      image,
      commission,
    } = this.state.values;
    let payload = new FormData();
    payload.append("name", name);
    payload.append("description", description);
    payload.append("charge", charge);
    payload.append("reorderTrigger", reorderTrigger);
    payload.append("_id", editing._id);
    payload.append("taxable", taxable == "true");
    payload.append("allowBackorder", allow_backorder == "true");
    if (image) {
      payload.append("image", image);
    }
    if (commission) {
      payload.append("commission", parseFloat(commission));
    }

    updateProduct(payload, access_token)
      .then((res) => {
        if (res.data.success) {
          SuccessHelper.handleSuccess(
            "Your product was successfully updated.",
            true
          );
          successCallback();
          this.setState(
            {
              showEditProductCard: false,
            },
            () => this.handleSaloonGetAllProducts()
          );
        } else if (!res.data.success) {
          ErrorHelper.handleErrors(res.data.msg, true);
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        console.log(error, "errorrrrrrrrrrrrrrrrrrr");
      });
  };

  handleDeleteProduct = (id) => {
    const { access_token } = this.state;
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ff3600",
      cancelButtonColor: "#354e68",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        this.setState({ isloading: true });
        deleteProduct({ id }, access_token)
          .then((res) => {
            if (res.data.success) {
              SuccessHelper.handleSuccess(
                "Your product was successfully deleted.",
                true
              );
              this.handleSaloonGetAllProducts();
            } else if (!res.data.success) {
              ErrorHelper.handleErrors(res.data.msg, true);
              this.setState({ isloading: false });
            }
          })
          .catch((error) => {
            console.log(error, "errorrrrrrrrrrrrrrrrrrr");
          });
      }
    });
  };

  openTransactionHistory = async (data) => {
    await this.setSelectedValue(1);
    this.setState(
      {
        selectedProductId: data._id,
      },
      () => {
        this.setState({ showTransactionHistory: true });
        this.retrieveTransactions();
      }
    );
  };

  openTransactionCreation = (data) => {
    this.setState(
      {
        originalQuantity: data.originalQuantity,
        selectedProductId: data._id,
        transactionType: this.state.transactionType
          ? this.state.transactionType
          : "increase",
      },
      () => {
        this.setState({ showTransactionCreation: true });
      }
    );
  };

  openEditProductCard = (data) => {
    this.setState(
      {
        editing: data,
        values: {
          name: data.name,
          description: data.description,
          charge: getNestedValue(data, "charge", 0),
          reorderTrigger: getNestedValue(data, "reorderTrigger", 0),
          status: data.status,
          taxable: `${!!getNestedValue(data, "taxable", false)}`,
          imageUrl: getNestedValue(data, "image", 0),
          allow_backorder: `${!!getNestedValue(data, "allowBackorder", false)}`,
          commission: data.commission || 0,
        },
      },
      () => {
        this.setState({ showEditProductCard: true });
      }
    );
  };

  closeEditServiceCard = () => {
    this.setState({
      showEditProductCard: false,
    });
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  renderSaloonDashboardHeading = () => {
    return (
      <div className="col-12 p-3">
        <h1 className="saloon-dashboard-heading">
          <Translation>{(t) => t("container.inventory")}</Translation>
        </h1>
      </div>
    );
  };

  renderCategoryForm = () => {
    return (
      <div class="form-row align-items-end justify-content-end">
        <div class="form-group col-md-5 px-0 px-md-3">
          <input
            type="text"
            class="form-control saloon-category-input"
            placeholder={i18n.t("container.services_search_by_name")}
            value={this.state.searchText}
            onChange={(text) => this.onChangeSearchText(text)}
          />
        </div>
      </div>
    );
  };

  renderTableHead = () => {
    const { isGym } = this.state;
    return (
      <thead className="bg-white">
        <tr>
          <th scope="col">
            <span className="category-table-th-text">
              <Translation>{(t) => t("name")}</Translation>
            </span>
          </th>
          <th scope="col">
            <span className="category-table-th-text">
              <Translation>{(t) => t("description")}</Translation>
            </span>
          </th>
          <th scope="col" className="text-center">
            <span className="category-table-th-text">
              {i18n.t("sale_price")}
            </span>
          </th>
          <th scope="col" className="text-center">
            <span className="category-table-th-text">{i18n.t("quantity")}</span>
          </th>
          <th scope="col" className="text-center">
            <span className="category-table-th-text">{i18n.t("reserved")}</span>
          </th>
          <th scope="col" className="text-center">
            <span className="category-table-th-text">
              {i18n.t("available")}
            </span>
          </th>
          <th scope="col" className="text-center">
            <span className="category-table-th-text">
              {i18n.t("reorder_trigger")}
            </span>
          </th>
          <th scope="col" style={{ minWidth: "130px" }}>
            <span className="category-table-th-text"></span>
          </th>
        </tr>
      </thead>
    );
  };

  renderTableBody = () => {
    const {
      serviceList,
      searchText,
      filteredList,
      isGym,
      widths = [],
      access_token,
    } = this.state;
    return (
      <List
        transitionDuration={10}
        lockVertically={true}
        beforeDrag={({ elements, index }) => {
          const cells = Array.from(elements[index].children);
          const widths = cells.map(
            (cell) => window.getComputedStyle(cell).width
          );
          this.setState({ widths });
        }}
        values={searchText ? filteredList : serviceList}
        onChange={({ oldIndex, newIndex }) => {
          const toUpdate = {};
          let newServiceList = arrayMove(serviceList, oldIndex, newIndex);
          newServiceList = newServiceList.map((val, index) => {
            if (index + 1 !== newServiceList[index].displayOrder) {
              toUpdate[val._id] = index + 1;
            }
            return { ...val, displayOrder: index + 1 };
          });
          this.setState({ isloading: true });
          updateMultipleService(toUpdate, access_token)
            .then(({ data }) => {
              if (data.success) {
                SuccessHelper.handleSuccess(
                  i18n.t("updated_display_order"),
                  true
                );
              } else {
                ErrorHelper.handleErrors(i18n.t("someting_went_wrong"), true);
              }
              this.setState({ isloading: false });
            })
            .catch(() => {
              ErrorHelper.handleErrors(i18n.t("someting_went_wrong"), true);
              this.setState({ isloading: false });
            });
          this.setState({ serviceList: newServiceList });
        }}
        renderList={({ children, props }) => (
          <tbody {...props} style={{ textAlign: "center" }}>
            {children}
          </tbody>
        )}
        renderItem={({ value, props, isDragged, isSelected }) => {
          const val = value;
          return (
            <tr
              key={val._id}
              {...props}
              style={{
                ...props.style,
                cursor: isDragged ? "grabbing" : "grab",
                backgroundColor: isDragged || isSelected ? "#EEE" : "#FFF",
              }}
            >
              <td style={{ width: widths[0] }}>
                <div className="d-flex align-items-center">
                  {val.image && (
                    <img className="category-table-pic" src={val?.image} />
                  )}
                  <span className="category-table-td-text">{val.name}</span>
                </div>
              </td>
              <td style={{ width: widths[0] }}>
                <div className="d-flex align-items-center">
                  <span className="category-table-td-text">
                    {val.description}
                  </span>
                </div>
              </td>
              <td style={{ width: widths[0] }}>
                <div className="d-flex align-items-center justify-content-center">
                  <span className="category-table-td-text">
                    ${parseFloat(val.charge).toFixed(2)}
                  </span>
                </div>
              </td>
              <td style={{ width: widths[0] }}>
                <div className="d-flex align-items-center justify-content-center">
                  <span className="category-table-td-text">
                    {val?.quantity}
                  </span>
                </div>
              </td>
              <td style={{ width: widths[0] }}>
                <div className="d-flex align-items-center justify-content-center">
                  <span className="category-table-td-text">
                    {val?.reserved}
                  </span>
                </div>
              </td>
              <td style={{ width: widths[0] }}>
                <div className="d-flex align-items-center justify-content-center">
                  <span className="category-table-td-text">
                    {val?.quantity - val?.reserved}
                  </span>
                </div>
              </td>
              <td style={{ width: widths[0] }}>
                <div className="d-flex align-items-center justify-content-center">
                  <span className="category-table-td-text">
                    {val?.reorderTrigger}
                  </span>
                </div>
              </td>
              <td style={{ minWidth: "130px" }}>
                <div className="row">
                  <FontAwesomeIcon
                    role="button"
                    className="view-icon"
                    color="white"
                    icon={["fas", "eye"]}
                    onClick={() => this.openTransactionHistory(val)}
                  />
                  <FontAwesomeIcon
                    role="button"
                    className="adjust-icon view-icon"
                    color="white"
                    icon={["fas", "sliders-h"]}
                    onClick={() => this.openTransactionCreation(val)}
                  />
                  <img
                    role="button"
                    onClick={() => this.openEditProductCard(val)}
                    className="category-table-action-icon"
                    src={Images.saloon_working_hour_edit}
                  />
                  <img
                    role="button"
                    onClick={() => this.handleDeleteProduct(val._id)}
                    className="category-table-action-icon"
                    src={Images.saloon_working_hour_delete}
                  />
                </div>
              </td>
            </tr>
          );
        }}
      />
    );
  };

  renderTable = () => {
    const { filteredList } = this.state;

    return (
      <div className="col-12 p-md-3 p-0">
        <div
          className="table-responsive sticky-header"
          style={{ borderRadius: "1rem", border: "1px solid #E0E2E4" }}
        >
          <table class="table table-borderless saloon-category-table mb-0">
            {this.renderTableHead()}
            {this.renderTableBody()}
          </table>
        </div>
      </div>
    );
  };

  renderCategoryCard = () => {
    return (
      <div className="col-12 p-3">
        <div
          className="col-12 px-3 py-4 bg-white"
          style={{ borderRadius: "1rem" }}
        >
          {this.renderCategoryForm()}
          {this.renderTable()}
        </div>
      </div>
    );
  };

  renderCloseButton = (key = "showEditProductCard") => {
    return (
      <span
        onClick={() => {
          this.setState({ [key]: false });
        }}
        className="h4 cursor-pointer close-cross"
      >
        &times;
      </span>
    );
  };

  renderTransactionCreation = () => {
    const {
      showTransactionCreation,
      transactions = [],
      transactionType,
      costPerUnit,
      quantity,
      notesError,
      notes,
    } = this.state;
    return (
      <div
        className="edit-service-card-wrapper"
        style={{ display: showTransactionCreation ? "flex" : "none" }}
      >
        <div className="container d-flex justify-content-center">
          <div className="col-6  bg-white" style={{ borderRadius: "1rem" }}>
            {this.renderCloseButton("showTransactionCreation")}
            <h3 className="pt-2">{i18n.t("new_transaction")}</h3>
            <div className="py-2">
              <div className="form-label-group col-md-12 col-xs-12 px-0 ">
                <div>
                  {" "}
                  <Translation>{(t) => t("type")}</Translation>
                </div>
                <select
                  id="function-role"
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ transactionType: e.target.value });
                  }}
                >
                  <option value="increase">{i18n.t("increase")}</option>
                  <option value="reduce">{i18n.t("reduce")}</option>
                </select>
              </div>
              <div className="form-label-group col-md-12 col-xs-12 px-0">
                <div>
                  {" "}
                  <Translation>{(t) => t("quantity")}</Translation>
                </div>
                <input
                  type="number"
                  className="form-control mt-1"
                  value={quantity}
                  onChange={(e) => {
                    this.setState({ quantity: e.target.value });
                  }}
                  onBlur={(e) => {
                    const value = parseInt(e.target.value);
                    if (value < 0 || isNaN(value)) {
                      this.setState({ quantity: 0 });
                    } else {
                      this.setState({ quantity: value });
                    }
                  }}
                />
              </div>
              {transactionType == "increase" && (
                <div className="form-label-group col-md-12 col-xs-12 px-0 ">
                  <div>
                    {" "}
                    <Translation>{(t) => t("cost_per_unit")}</Translation>
                  </div>
                  <input
                    type="number"
                    className="form-control mt-1"
                    value={costPerUnit}
                    onChange={(e) => {
                      this.setState({ costPerUnit: e.target.value });
                    }}
                    onBlur={(e) => {
                      const value = parseFloat(e.target.value);
                      if (value < 0 || isNaN(value)) {
                        this.setState({ costPerUnit: 0 });
                      } else {
                        this.setState({ costPerUnit: value.toFixed(2) });
                      }
                    }}
                  />
                </div>
              )}
              <div className="form-label-group col-md-12 col-xs-12 px-0 ">
                <div>
                  {" "}
                  <Translation>{(t) => t("notes")}</Translation>
                </div>
                <textarea
                  className="form-control pt-1 full-width"
                  placeholder={i18n.t("enter_notes_placeholder")}
                  value={notes}
                  onChange={(e) => {
                    this.setState({ notes: e.target.value });
                  }}
                ></textarea>
                {notesError ? (
                  <small className="form-text text-danger ml-3">
                    {notesError}
                  </small>
                ) : null}
              </div>
              <div className="row mx-0 justify-content-end">
                <button
                  type="button"
                  onClick={() => this.handleTransaction(transactionType)}
                  className="btn btn-light btn-sm mr-1"
                  id="saloon-save-social-link"
                >
                  <Translation>{(t) => t("add")}</Translation>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderTransactionHistory = () => {
    const {
      showTransactionHistory,
      transactions = [],
      averageCost = 0,
    } = this.state;
    return (
      <BaseModal
        open={showTransactionHistory}
        title={i18n.t("transactions")}
        onClose={() => {
          this.setState({ showTransactionHistory: false });
        }}
        containerClassName="container overflow-auto"
        {...{
          content: (
            <>
              <div className="py-2">
                {this.TemplateOption()}
                {this.ShowMenuValues()}
                <div class="px-0">
                  <ul class="nav nav-tabs employee-report-filters pt-3 justify-content-end ">
                    <li>
                      <button
                        type="button"
                        className="btn btn-light btn-sm"
                        onClick={() => this.retrieveTransactions()}
                      >
                        {i18n.t("search")}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                {i18n.t("cost_per_unit")}: ${averageCost}
              </div>
              <table class="table table-borderless saloon-category-table mb-0">
                <thead>
                  <th>{i18n.t("created_by")}</th>
                  <th>{i18n.t("date")}</th>
                  <th>{i18n.t("cost")}</th>
                  <th>{i18n.t("quantity")}</th>
                  <th>{i18n.t("notes")}</th>
                </thead>
                <tbody>
                  {transactions.map((transaction) => {
                    return this.renderTransactionRow(transaction);
                  })}
                </tbody>
              </table>
            </>
          ),
        }}
      />
      // <div
      //   className="edit-service-card-wrapper"
      //   style={{ display: showTransactionHistory ? "flex" : "none" }}
      // >
      //   <div className="container">
      //     <div className="col-12 p-3 bg-white" style={{ borderRadius: "1rem" }}>
      //       {this.renderCloseButton("showTransactionHistory")}
      //       <h3 className="pt-2">{i18n.t("transactions")}</h3>

      //     </div>
      //   </div>
      // </div>
    );
  };

  TemplateOption = () => {
    const { startDate, endDate } = this.state;
    return (
      <div
        style={{ flex: 1, display: "flex", justifyContent: "space-between" }}
        className="employee-report-filters"
      >
        <div>{i18n.t("date_range")}</div>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <div className="mx-1">
            <DatePicker
              className="form-control saloon-form-input"
              placeholderText="Select Start Date"
              selected={startDate}
              onChange={(data) => this.setStartDate(data)}
              dateFormat="MM/dd/yyyy"
            />
          </div>
          <div className="mx-1">
            <DatePicker
              className="form-control saloon-form-input"
              placeholderText="Select End Date"
              selected={endDate}
              onChange={(data) => this.setEndDate(data)}
              dateFormat="MM/dd/yyyy"
            />
          </div>
        </div>
      </div>
    );
  };

  setStartDate = (val) => {
    const { endDate } = this.state;
    if (val > endDate) {
      this.setState({
        startDate: endDate,
        endDate: val,
      });
    } else {
      this.setState({
        startDate: val,
      });
    }
  };

  setEndDate = (val) => {
    const { startDate } = this.state;
    if (val < startDate) {
      this.setState({
        startDate: val,
        endDate: startDate,
      });
    } else {
      this.setState({
        endDate: val,
      });
    }
  };

  ShowMenuValues = () => {
    return (
      <div class="px-0">
        <div class="row employee-report-filters pt-3 px-0 mx-0">
          <div className="col-12 col-sm-12 col-md-3 d-flex px-0">
            {i18n.t("container.quick_filter")}
          </div>
          <div className="col-12 col-md-9 px-0 justify-content-end">
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-light btn-sm my-1"
                onClick={() => this.setSelectedValue(1)}
              >
                {i18n.t("container.month")}
              </button>
              <button
                type="button"
                className="btn btn-light btn-sm my-1"
                onClick={() => this.setSelectedValue(2)}
              >
                {i18n.t("container.quarter")}
              </button>
              <button
                type="button"
                className="btn btn-light btn-sm my-1"
                onClick={() => this.setSelectedValue(3)}
              >
                {i18n.t("container.year")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  setSelectedValue = (val) => {
    const { startDate, endDate } = this.state;
    let currentDate = moment(Date.now());
    if (val == 1) {
      const startOfMonth = moment(currentDate).startOf("month");
      const endOfMonth = moment(currentDate).endOf("month");
      this.setState({
        startDate: startOfMonth.toDate(),
        endDate: endOfMonth.toDate(),
      });
    } else if (val == 2) {
      const startOfQuarter = moment(currentDate).startOf("quarter");
      const endOfQuarter = moment(currentDate).endOf("quarter");
      this.setState({
        startDate: startOfQuarter.toDate(),
        endDate: endOfQuarter.toDate(),
      });
    } else if (val == 3) {
      const convertedStart = moment(
        `01-01-${currentDate.year()}`,
        "MM-DD-YYYY"
      );
      const convertedEnd = moment(`12-31-${currentDate.year()}`, "MM-DD-YYYY");
      this.setState({
        startDate: convertedStart.toDate(),
        endDate: convertedEnd.toDate(),
      });
    }
  };

  renderTransactionRow = (transaction) => {
    if (transaction.type == "sale") {
      return (
        <tr>
          <td>{getNestedValue(transaction, ["createdBy", "userName"], "")}</td>
          <td>
            {moment(getNestedValue(transaction, ["createdDate"], "")).format(
              "MM-DD-YYYY"
            )}
          </td>
          <td></td>
          <td>-{getNestedValue(transaction, ["quantity"], "")}</td>
          <td>{getNestedValue(transaction, ["notes"], "")}</td>
        </tr>
      );
    } else if (transaction.type == "increase") {
      return (
        <tr>
          <td>{getNestedValue(transaction, ["createdBy", "userName"], "")}</td>
          <td>
            {moment(getNestedValue(transaction, ["createdDate"], "")).format(
              "MM-DD-YYYY"
            )}
          </td>
          <td>${getNestedValue(transaction, ["cost"], "")}</td>
          <td>{getNestedValue(transaction, ["quantity"], "")}</td>
          <td>{getNestedValue(transaction, ["notes"], "")}</td>
        </tr>
      );
    } else if (transaction.type == "decrease") {
      return (
        <tr>
          <td>{getNestedValue(transaction, ["createdBy", "userName"], "")}</td>
          <td>
            {moment(getNestedValue(transaction, ["createdDate"], "")).format(
              "MM-DD-YYYY"
            )}
          </td>
          <td></td>
          <td>-{getNestedValue(transaction, ["quantity"], "")}</td>
          <td>{getNestedValue(transaction, ["notes"], "")}</td>
        </tr>
      );
    }
  };

  renderEditServiceCard = () => {
    const {
      showEditProductCard,
      serviceNameError,
      priceError,
      minimumTimeError,
      isGym,
      editing = {},
      formErrors = {},
      values = {},
    } = this.state;
    return (
      <div
        className="edit-service-card-wrapper"
        style={{ display: showEditProductCard ? "flex" : "none" }}
      >
        <ProductForm
          formErrors={formErrors}
          checkValidation={this.checkValidation}
          setValue={(newValues) =>
            this.setState({ values: { ...this.state.values, ...newValues } })
          }
          values={values}
          isEdit={true}
          renderCloseButton={this.renderCloseButton}
        />
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.renderLoader()}
        {this.renderEditServiceCard()}
        {this.renderTransactionHistory()}
        {this.renderTransactionCreation()}

        <div className="content-container">
          <div className="row">
            {this.renderSaloonDashboardHeading()}
            {this.renderCategoryCard()}
          </div>
          <div className="col-12 text-right">
            <button
              onClick={() => this.props.history.push("saloon-add-inventory")}
              type="button"
              className="btn btn-light btn-sm mb-2"
              id="saloon-save-social-link"
            >
              <Translation>{(t) => t("container.add_product")}</Translation>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(AllProducts);
