import React from "react";
import Button from "@material-ui/core/Button";
import BasicTable from "../../Core/BasicTable";
import i18n from "../../../i18n";
import moment from "moment";

export default function FormTable({
  data = [],
  handleDelete,
  handleEdit,
  handlePreview,
}) {
  const prepareData = (data) => {
    const fieldsToRender = {};
    const fields = data.questions.map((question) => {
      question = {
        ...question,
        id: question._id,
        fieldOptions: question.fieldOptions.join("-"),
      };
      fieldsToRender[question._id] = question;
      return question;
    });

    const test = {
      data,
      toDelete: [],
      formTitle: data.formTitle,
      formDescription: data.formDescription,
      defaultStatus: data.status,
      formId: data._id,
      fieldsToRender,
      columns: {
        "column-1": {
          id: "column-1",
          fieldIds: fields.map((field) => field._id),
        },
      },
    };
    handlePreview(test);

    {
      /* <Dialog
        // onClose={handleClose}
        // aria-labelledby="simple-dialog-title"
        open={false}
      >
        <DialogTitle id="simple-dialog-title">Form Title</DialogTitle>
      </Dialog> */
    }
    {
      /* {this.state.preview && (
        <FormGenerator
        // fieldsToRender={sortedField}
        // preview={this.state.preview}
        // readonly={true}
        /> */
    }
  };
  const schema = [
    {
      header: i18n.t("name"),
      field: "formTitle",
    },
    {
      header: i18n.t("created_date"),
      field: "createdDate",
      accessor: (row, field) => moment(row[field]).format("MM-DD-YYYY hh:mm a"),
    },
    {
      header: i18n.t("status"),
      field: "status",
      accessor: (row, field) => i18n.t(row[field]),
    },
    {
      header: i18n.t("actions"),
      field: "actions",
      render: (row, index) => {
        const { field } = row;
        return (
          <div>
            <span>
              <Button
                className="mr-2"
                key={`table-body-button-${field}`}
                variant="outlined"
                color="primary"
                onClick={() => handleEdit(row)}
              >
                {i18n.t("edit")}
              </Button>
              <Button
                className="mr-2"
                key={`table-body-button-${field}`}
                variant="outlined"
                color="primary"
                onClick={() => handleDelete(row)}
              >
                {i18n.t("delete")}
              </Button>
              <Button
                key={`table-body-button-${field}`}
                variant="outlined"
                color="primary"
                onClick={() => prepareData(row)}
              >
                {i18n.t("Preview_form")}
              </Button>
            </span>
          </div>
        );
      },
    },
  ];

  return <BasicTable rows={data} schema={schema} />;
}
