import { connect } from "react-redux";
import React, { Component } from "react";
import Pagination from "react-js-pagination";
// import { getAllEmployee } from '../../../config/simpleApiCalls'
// import { hireCompanyEmployee } from '../../../config/simpleApiCalls'
import {
  getAllEmployeeAssociatedWithCompany,
  getBookingReports,
} from "../../../config/simpleApiCalls";
import { Images } from "../../../theme";
import { DotsLoader } from "../../../components";
import Avatar from "react-avatar";
// import { adminGetUsers, adminDeleteUser } from '../../../config/simpleApiCalls';
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import moment from "moment";
import Sytles from "./sytles";
import i18n from "../../../i18n";
import DatePicker from "react-datepicker";
import Tooltip from "@material-ui/core/Tooltip";
import { getNestedValue } from "../../../util/objectMethods";

import "./styles.scss";

class BookingReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      access_token: "",
      user: null,
      isloading: false,
      showEditDelete: "",
      searchText: "",
      activePage: 1,
      usersList: [],
      data: [],
      // employeeList: [],
      filterUsersList: [],
      date: Date.now(),
    };
  }
  componentDidMount() {
    this.getAllBookings();
  }
  getAllBookings = (date) => {
    this.setState({ isloading: true });
    const params = {};
    if (date) {
      params.date = date;
    }
    let token = JSON.parse(sessionStorage.getItem("saloon"));
    getBookingReports(token.access_token, params).then((res) => {
      this.setState({ data: res.data.data, isloading: false });
    });
  };

  onSetSidebarOpen = (open) => {
    this.setState({ sidebarOpen: open });
  };
  TemplateOption = () => {
    const { date } = this.state;
    return (
      <div
        style={{ flex: 1, display: "flex", justifyContent: "space-between" }}
        className="employee-report-filters pt-1 col-12"
      >
        <div>Date</div>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <div className="mx-1">
            <DatePicker
              className="form-control saloon-form-input"
              placeholderText="Select Start Date"
              selected={date}
              onChange={(data) => {
                const formattedDate = moment(data).format("MM-DD-YYYY");
                this.setState({ date: data });
                this.getAllBookings(formattedDate);
              }}
              dateFormat="MM/dd/yyyy"
            />
          </div>
        </div>
      </div>
    );
  };
  renderTableBody = () => {
    const { data, isLoading, masterTaxMap } = this.state;
    if (!isLoading) {
      if (data) {
        return (
          <tbody>
            {data.map((val, ind) => {
              const { EarnInCurrentMonth, NumberOfBookingsForCurrentMonth } =
                val.currentMonthData;
              const { EarnInCurrentWeek, NumberOfBookingsForCurrentWeek } =
                val.currentWeekData;
              const { EarnedLastMonth, NumberOfBookingsForPreviousMonth } =
                val.previousMonthData;
              const { EarnedLastWeek, NumberOfBookingsForPreviousWeek } =
                val.previousWeekData;
              return (
                <tr key={val._id} style={{ borderTop: "3px solid #F7F7F7" }}>
                  <td>
                    <span className="appointment-details-employee-name text-center">
                      <b>
                        {getNestedValue(
                          val.employee,
                          ["userId", "firstName"],
                          ""
                        )}
                      </b>
                    </span>
                  </td>
                  <td />
                  <td className="text-center">
                    ${EarnInCurrentWeek ? EarnInCurrentWeek.toFixed(2) : 0}
                  </td>
                  <td className="text-center">
                    {NumberOfBookingsForCurrentWeek}
                  </td>
                  <td />
                  <td className="text-center">
                    ${EarnedLastWeek ? EarnedLastWeek.toFixed(2) : 0}
                  </td>
                  <td className="text-center">
                    {NumberOfBookingsForPreviousWeek}
                  </td>
                  <td />
                  <td className="text-center">
                    ${EarnInCurrentMonth ? EarnInCurrentMonth.toFixed(2) : 0}
                  </td>
                  <td className="text-center">
                    {NumberOfBookingsForCurrentMonth}
                  </td>
                  <td />
                  <td className="text-center">
                    ${EarnedLastMonth ? EarnedLastMonth.toFixed(2) : 0}
                  </td>
                  <td className="text-center">
                    {NumberOfBookingsForPreviousMonth}
                  </td>
                </tr>
              );
            })}
          </tbody>
        );
      }
    } else {
      return <DotsLoader isloading={this.state.isloading} />;
    }
  };

  renderUserTable = () => {
    const { masterTaxMap = {}, data, date } = this.state;
    if (data && data.length) {
      const startOfPreviousMonth = moment(date)
        .subtract(1, "month")
        .startOf("month")
        .format("MM-DD-YYYY");
      const endOfPreviousMonth = moment(date)
        .subtract(1, "month")
        .endOf("month")
        .format("MM-DD-YYYY");
      const startOfMonth = moment(date).startOf("month").format("MM-DD-YYYY");
      const endOfMonth = moment(date).endOf("month").format("MM-DD-YYYY");
      const startOfCurrentWeek = moment(date).subtract(
        moment(date).day(),
        "days"
      );
      const endOfCurrentWeek = moment(startOfCurrentWeek)
        .add(6, "days")
        .format("MM-DD-YYYY");
      const endOfPreviousWeek = moment(startOfCurrentWeek).subtract(1, "days");
      const startOfPreviousWeek = moment(endOfPreviousWeek)
        .subtract(6, "days")
        .format("MM-DD-YYYY");
      return (
        <div className="col-12 p-3">
          <div className="table-responsive spacing-table">
            <table className="table table-borderless appointment-details-table">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <td />
                  <th scope="col" colSpan="2" className="text-center">
                    <Tooltip
                      title={`${startOfCurrentWeek.format(
                        "MM-DD-YYYY"
                      )} - ${endOfCurrentWeek}`}
                    >
                      <span className="appointment-details-th-text">
                        {i18n.t("current_week")}
                      </span>
                    </Tooltip>
                  </th>
                  <td />
                  <th scope="col" colSpan="2" className="text-center">
                    <Tooltip
                      title={`${startOfPreviousWeek} - ${endOfPreviousWeek.format(
                        "MM-DD-YYYY"
                      )}`}
                    >
                      <span className="appointment-details-th-text">
                        {i18n.t("previous_week")}
                      </span>
                    </Tooltip>
                  </th>
                  <td />
                  <th scope="col" colSpan="2" className="text-center">
                    <Tooltip title={`${startOfMonth} - ${endOfMonth}`}>
                      <span className="appointment-details-th-text">
                        {i18n.t("current_month")}
                      </span>
                    </Tooltip>
                  </th>
                  <td />
                  <th scope="col" colSpan="2" className="text-center">
                    <Tooltip
                      title={`${startOfPreviousMonth} - ${endOfPreviousMonth}`}
                    >
                      <span className="appointment-details-th-text">
                        {i18n.t("previous_month")}
                      </span>
                    </Tooltip>
                  </th>
                </tr>
                <tr>
                  <th>
                    <span className="appointment-details-th-text">
                      {i18n.t("container.profile_employe")}
                    </span>
                  </th>
                  <td />
                  <th scope="col" className="text-center">
                    <span className="appointment-details-th-text">
                      {i18n.t("earnings")}
                    </span>
                  </th>
                  <th scope="col" className="text-center">
                    <span className="appointment-details-th-text">
                      {i18n.t("number_of_bookings")}
                    </span>
                  </th>
                  <td />
                  <th scope="col" className="text-center">
                    <span className="appointment-details-th-text">
                      {i18n.t("earnings")}
                    </span>
                  </th>
                  <th scope="col" className="text-center">
                    <span className="appointment-details-th-text">
                      {i18n.t("number_of_bookings")}
                    </span>
                  </th>
                  <td />
                  <th scope="col" className="text-center">
                    <span className="appointment-details-th-text">
                      {i18n.t("earnings")}
                    </span>
                  </th>
                  <th scope="col" className="text-center">
                    <span className="appointment-details-th-text">
                      {i18n.t("number_of_bookings")}
                    </span>
                  </th>
                  <td />
                  <th scope="col" className="text-center">
                    <span className="appointment-details-th-text">
                      {i18n.t("earnings")}
                    </span>
                  </th>
                  <th scope="col" className="text-center">
                    <span className="appointment-details-th-text">
                      {i18n.t("number_of_bookings")}
                    </span>
                  </th>
                </tr>
              </thead>
              {this.renderTableBody()}
            </table>
          </div>
        </div>
      );
    } else {
      return (
        <div className="row full-width mx-2 justify-content-center mt-1">
          {i18n.t("nothing_found")}
        </div>
      );
    }
  };

  renderSearchBar = () => {
    const { searchText } = this.state;
    return (
      <>
        <div className="col-12 col-lg-4 col-md-4 mt-4">
          <span>
            <span className="saloon-dashboard-heading">
              {i18n.t("container.employee_report")}
            </span>
          </span>
        </div>
      </>
    );
  };

  render() {
    const { isloading } = this.state;
    return (
      <div>
        <div className="content-container ">
          {this.renderSearchBar()}
          <div className="row">
            {this.TemplateOption()}
            {this.state.data.length > 0 ? this.renderUserTable() : null}

            {}
          </div>
        </div>
        <DotsLoader isloading={isloading} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(BookingReport);
