import { connect } from "react-redux";
import React, { Component } from "react";
import { Translation } from 'react-i18next';
import i18n from '../../../i18n';
import DatePicker from "react-datepicker";
import moment from "moment";

import {
  getSaloonCategories,
  getSaloonServices,
  getEmployeesBySaloonAndService,
  createBooking,
  getSaloonTemplateData,
  getCompanyPorfolio,
} from "../../../config/simpleApiCalls";
import { DotsLoader, Header } from "../../../components";
import { SuccessHelper, ErrorHelper } from "../../../helpers";
import NumberFormat from "react-number-format";
import { request as get_available_timeslots } from '../../../redux/actions/GetAvailableTimeslots.js';
import { request as get_gym_timeslots } from '../../../redux/actions/GetGymTimeslots.js';
import { getNestedValue } from "../../../util/objectMethods";
import { Images } from "../../../theme";

class CreateAppointmentForm extends Component {
  constructor() {
    super();
    this.state = {
      user: null,
      routeName: "",
      showSidebar: false,
      showDropdownMenu: false,
      companyId: "",
      show: false,
      portfolioImages: [],
      portfolioVideos: [],
      companyCategories: [],
      companyServices: [],
      companyEmployee: [],
      companyList: [],
      isloading: false,
      arrServices: [],
      arrEmploye: [],
      arrSelectDate: [],
      arrSelectCategory: [{}],
      arrDataServices: [],
      arrAvailableTimeslots: [],
      daysMock: [
        { name: "---" },
        { name: "Sun" },
        { name: "Mon" },
        { name: "Tue" },
        { name: "Wed" },
        { name: "Thu" },
        { name: "Fri" },
        { name: "Sat" },
      ],
      selectedEmployee: [],
      arrSelectTime: [],
      selectedServices: [],
      amount: 0,
      employeData: [],
      arrFilteredEmployeeOptions: [],
      paymentMethod: undefined,
    };
  }

  componentDidMount = async () => {
    const companyData = await JSON.parse(
      sessionStorage.getItem("template_navigation")
    );
    const user = await JSON.parse(sessionStorage.getItem("user"));
    console.log(user, companyData)
    this.setState({
      companyId: companyData?._id || '',
      user,
      companyName: companyData.name,
      companyLogo: companyData.companyLogo,
      isGym: companyData.function == 'gym',
      postalCode: user ? user.postalCode : undefined, 
      email: user ? user.email : undefined, 
      phoneNo: user ? user.phoneNo : undefined
    });
    this.getAllData();
    this.getCategories();
  };

  
  
  componentWillReceiveProps(nextProps) {
    if (nextProps.getAvailableTimeslots) {
      if (
        !nextProps.getAvailableTimeslots.failure &&
        !nextProps.getAvailableTimeslots.isFetching &&
        !nextProps.getAvailableTimeslots.errorMessage &&
        nextProps.getAvailableTimeslots.data &&
        nextProps.getAvailableTimeslots.data.data
      ) {
        const { arrAvailableTimeslots } = this.state
        arrAvailableTimeslots[nextProps.getAvailableTimeslots.index] = nextProps.getAvailableTimeslots.data.data
        this.setState({ isloading: false});
      } else if (nextProps.getAvailableTimeslots.isFetching){
        this.setState({ isloading: true});
      }
    }
    if (nextProps.getGymTimeslots) {
      if (
        !nextProps.getGymTimeslots.failure &&
        !nextProps.getGymTimeslots.isFetching &&
        !nextProps.getGymTimeslots.errorMessage &&
        nextProps.getGymTimeslots.data &&
        nextProps.getGymTimeslots.data.data
      ) {
        const { arrAvailableTimeslots } = this.state
        arrAvailableTimeslots[nextProps.getGymTimeslots.index] = nextProps.getGymTimeslots.data.data
        this.setState({ isloading: false});
      } else if (nextProps.getGymTimeslots.isFetching){
        this.setState({ isloading: true});
      }
    }
  }
  

  getAllData = () => {
    this.setState({
      isloading: true,
    });
    const { companyId } = this.state;
    const payload = { companyId };
    getSaloonTemplateData(payload)
      .then((res) => {
        if (res.data.success)
          this.setState({
            isloading: false,
            companyList: res.data.data,
            employeData: res.data.data[0],
          });
      })
      .catch((error) => {
      });
  };

  getCategories = async () => {
    const { companyId } = this.state;

    try {
      const res = await getSaloonCategories({ companyId });
      this.setState({ companyCategories: res.data.data });
    } catch (error) {
    }
  };

  getServices = async (categoryId) => {
    const { companyId } = this.state;
    this.setState({ companyServices: [], selectDate: "", setTime: "" });

    try {
      const res = await getSaloonServices({ companyId, categoryId });
      this.setState({ companyServices: res.data.data });
      return res.data.data;
    } catch (error) {
    }
  };

  getEmployee = async (serviceId) => {
    const { companyId } = this.state;
    this.setState({ companyEmployee: [], selectDate: "", setTime: "" });

    try {
      const res = await getEmployeesBySaloonAndService({
        companyId,
        serviceId,
      });
      if (res.data.data.length) {
        const companyEmployee = res.data.data.map((val) => {
          const employe = val.employeeId.userId;
          employe._id = val.employeeId._id;
          employe.name = employe.firstName
            ? `${employe.firstName} ${employe.lastName}`
            : employe.userName || "";
          employe.weekPlans = val.weekPlans;
          return employe;
        });
        return companyEmployee;
      }
      return [{ name: "Employee Not Found", _id: false }];
    } catch (error) {
    }
  };

  renderAppointmentFormDisable = (type, text, value, isDisabled) => {
    return (
      <input
        disabled={!!isDisabled}
        value={value}
        class="form-group textStyle form-control"
        
        type={type}
        placeholder={text}
      />
    );
  };

  renderAppointmentForm = (type, text, key, value) => {
    return (
      <input
        onChange={(e) => this.setState({ [key]: e.target.value })}
        value={value}
        class="form-group textStyle form-control"
        
        type={type}
        placeholder={text}
      />
    );
  };

  renderAppointmentCol4 = (type, text) => {
    return (
      <input
        class="form-group"
        id="appointment-form-name"
        type={type}
        placeholder={text}
      />
    );
  };



  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };
  renderSelect = (values, name) => {
    return (
      <select
        onChange={(e) => this.handleChange(e.target.value, name)}
        class="col-md-6 mt-3 selectNew form-control"
        
      >
        <option class="form-group" value={false} >
          {values.length ? `Select ${name}` : `Wait ${name} Loading ...`}
        </option>
        {values.map((cat, i) => (
          <option
            key={i}
            value={JSON.stringify(cat)}
            class="form-group"
          >
            {cat.name}
          </option>
        ))}
      </select>
    );
  };

  handleChange = (value, name) => {
    console.group(this.state)
    if (!value) return this.setState({ [`selected${name}`]: data });
    const data = JSON.parse(value);

    if (name === "Category" && data) this.getServices(data._id);
    if (name === "Service" && data) this.getEmployee(data._id);
    if (name === "Employee") this.setState({ setTime: "", selectDate: "" });
    this.setState({ [`selected${name}`]: data });
  };

  onDateChange = async (selectDate, employee, index) => {
    const { arrSelectTime, arrSelectDate, arrServices, companyId, arrDataServices, arrFilteredEmployeeOptions, arrAvailableTimeslots, selectedEmployee, isGym } = this.state;
    
    delete arrFilteredEmployeeOptions[index + 1]
    delete arrAvailableTimeslots[index + 1]
    delete selectedEmployee[index + 1]
    delete arrSelectTime[index + 1]
    arrServices[index + 1] = {
      ...arrServices[index + 1],
      date: moment(selectDate).format("MM-DD-YYYY"),
    };
    arrSelectDate[index + 1] = { selectDate, formattedDate: moment(selectDate).format("MM-DD-YYYY")}
    let date = undefined
    if(selectDate) {
      date = moment(selectDate).format("MM-DD-YYYY");
      if(isGym) {
        await this.props.get_gym_timeslots({
          companyId,
          date,
        }, index + 1);
      } else {
        await this.props.get_available_timeslots({
          data: {
            companyId,
            companyServiceId: arrServices[index + 1].serviceId,
            date,
          }
        }, index + 1);
      }
    }
  };

  onChangeTime = (text, index) => {
    const { isGym, arrAvailableTimeslots, arrSelectTime, arrServices, arrEmploye, arrFilteredEmployeeOptions, selectedEmployee } = this.state
    delete selectedEmployee[index + 1]
    delete arrFilteredEmployeeOptions[index + 1]
    if(!isGym) {
      arrFilteredEmployeeOptions[index + 1] = arrAvailableTimeslots[index + 1][text.target.value].map(employeeId => {
        return arrEmploye[index + 1].find(employee => employee._id == employeeId)
      }).filter(employee => !!employee)
    }
    arrSelectTime[index + 1] = { time: text.target.value };
    arrServices[index + 1] = {
      ...arrServices[index + 1],
      time: text.target.value.split(' ')[0],
    };

    this.setState({ arrSelectTime, arrServices });
  }

  checkDateIsAvil = (day, index) => {
    const { daysMock, arrEmploye, isGym } = this.state;
    const getDay = moment(day).format("ddd");
    const companyDate = [];
    if(!isGym) {
      arrEmploye[index + 1].map(employee => {
        employee.weekPlans.length &&
        employee.weekPlans.forEach((val) => {
          if (val.availableStatus) {
            return companyDate.push(val);
          }
        });
      })

      const availDay = [];
      companyDate.map((weekPlans) => {
        availDay.push(daysMock[weekPlans.dayOfWeek].name);
      });
      return !availDay.includes(getDay) ? "disabled-date" : undefined;
    } else {
      return undefined
    }

  };

  renderTimeSlot = (time, index) => {
    const { arrSelectDate } = this.state;
    const selectDayOfTime = arrSelectDate[index + 1].selectDayOfTime;
    if (!selectDayOfTime) return;
    const selectedTime = moment(time).format("h:mm:ss a");
    document.querySelectorAll(".disabled-times").forEach(function (a) {
      a.remove();
    });

    const checkIn = new Date(`01/01/2001 ${selectDayOfTime.checkIn}`).getTime();
    const checkOut = new Date(
      `01/01/2001 ${selectDayOfTime.checkOut}`
    ).getTime();
    const a = new Date(`01/01/2001 ${selectedTime}`).getTime();

    if (
      Math.min(checkIn, checkOut) <= Math.max(a, a) &&
      Math.max(checkIn, checkOut) >= Math.min(a, a)
    )
      return undefined;

    return "disabled-times";
  };

  handleSubmit = async () => {
    const {
      selectedCategory,
      selectedService,
      selectedEmployee,
      companyId,
      phoneNo,
      postalCode,
      arrServices,
      email,
      setTime,
      selectDate,
      amount,
      user,
      isGym
    } = this.state;
    console.log(this.state)
    const payload = { status: 1, companyId };
    const services = [];

    // if (!postalCode) return ErrorHelper.handleErrors("Enter Postal Code", true);
    // payload.postalCode = postalCode;

    payload.email = email;

    // if (!phoneNo) return ErrorHelper.handleErrors("Enter Phone Number", true);
    payload.phoneNo = phoneNo;
    let ErrorMsg = [];

    arrServices.map((value, index) => {
      if (!value) return;
      let nth = 0;
      if (index == 1) {
        nth = "1st";
      } else if (index == 2) {
        nth = "2nd";
      } else if (index == 3) {
        nth = "3rd";
      } else {
        nth = `${index}th`;
      }

      const payloadServices = {};
      if (!value.serviceId) {
        ErrorMsg.push(false);
        ErrorHelper.handleErrors(`Select ${nth} Service`, true);
        return;
      }
      payloadServices.serviceId = value.serviceId;

      if (!isGym && !value.employeeId) {
        ErrorMsg.push(false);
        ErrorHelper.handleErrors(`Select ${nth} Employ`, true);
        return;
      }
      payloadServices.employeeId = value.employeeId;

      if (!value.categoryId) {
        ErrorMsg.push(false);
        ErrorHelper.handleErrors(`Select ${nth} Category`, true);
        return;
      }
      payloadServices.categoryId = value.categoryId;

      if (!value.date) {
        ErrorMsg.push(false);
        ErrorHelper.handleErrors(`Select ${nth} Date`, true);
        return;
      }
      payloadServices.date = value.date;

      if (!value.time) {
        ErrorMsg.push(false);
        ErrorHelper.handleErrors(`Select ${nth} Time`, true);
        return;
      }
      payloadServices.time = value.time;

      services.push(payloadServices);
    });

    if (ErrorMsg.includes(false)) return;
    if (!arrServices.length)
      return ErrorHelper.handleErrors("Select Any Service", true);
    
    const paymentMethod = getNestedValue(this.state, ["selectedPayment Method", "name"], "")
    if (!isGym && !paymentMethod)
      return ErrorHelper.handleErrors("Select Payment Method", true);
    payload.paymentMethod = paymentMethod;

    payload.totalAmount = amount;

    payload.services = services;
    const {
      companyList,
      selectDayOfTime,
      companyCategories,
      companyServices,
      companyEmployee,
      arrDataServices,
      arrSelectDate,
      arrSelectTime,
      arrEmploye,
      arrAvailableTimeslots,
      arrFilteredEmployeeOptions,
      companyName,
      arrSelectCategory
    } = this.state
    console.log(JSON.stringify({
      companyList,
      selectDayOfTime,
      companyCategories,
      selectedCategory,
      companyServices,
      companyEmployee,
      selectedService,
      selectedEmployee,
      arrServices,
      arrSelectCategory,
      arrDataServices,
      arrSelectDate,
      arrSelectTime,
      arrEmploye,
      amount,
      arrAvailableTimeslots,
      arrFilteredEmployeeOptions,
      isGym,
      companyName}))
    if (!user?.access_token) {
      ErrorHelper.handleErrors("Please login first", true);
      setTimeout(() => {
        this.props.history.push("/login");
      }, 2000);
    } else {
      this.setState({ isloading: true });
      createBooking(payload, user.access_token)
        .then((res) => {
          if (res.data.success) {
            SuccessHelper.handleSuccess("Appointment Created", true);
            window.location.reload();
            this.setState({ isloading: false });
          } else {
            this.setState({ isloading: false });
            ErrorHelper.handleErrors(res.data.msg, true);
          }
        }).catch((error) => {

          this.setState({ isloading: false });
          if (error.response) {
            ErrorHelper.handleErrors(error.response.data.msg, true);
          } else if (error.request) {
            ErrorHelper.handleErrors('Something Went Wrong', true);
          } else {
            ErrorHelper.handleErrors(error.msg, true);
          }
        });

    }
  };

  selectCategory = async (value, index) => {
    value = JSON.parse(value);
    const {
      arrSelectCategory,
      arrServices,
      arrDataServices,
      selectedServices,
      arrEmploye,
      selectedEmployee,
      arrSelectDate,
      arrSelectTime,
      arrFilteredEmployeeOptions,
      arrAvailableTimeslots
    } = this.state;
    delete arrServices[index + 1];
    delete arrDataServices[index + 1];
    delete selectedServices[index + 1];
    delete arrEmploye[index + 1];
    delete selectedEmployee[index + 1];
    delete arrSelectDate[index + 1];
    delete arrSelectTime[index + 1];
    delete arrFilteredEmployeeOptions[index + 1]
    delete arrAvailableTimeslots[index + 1]
    
    this.setState({
      arrServices,
      arrEmploye,
      selectedServices,
      selectedEmployee,
      arrSelectDate,
      arrSelectTime,
    });
    if (!value._id) {
      delete arrSelectCategory[index + 1];
      return;
    }

    arrServices[index + 1] = { categoryId: value._id };
    arrSelectCategory[index + 1] = value;

    try {
      this.setState({ isloading: true });
      const allServices = await this.getServices(value._id);
      arrDataServices[index + 1] = allServices;
      this.setState({
        arrServices,
        arrSelectCategory,
        arrDataServices,
        isloading: false,
      });
    } catch (error) {
      ErrorHelper.handleErrors("Category Not selected", true);
    }
  };

  selectMultiServices = async (value, index) => {
    value = JSON.parse(value);
    const {
      arrServices,
      arrEmploye,
      selectedServices,
      selectedEmployee,
      arrSelectDate,
      arrSelectTime,
      arrFilteredEmployeeOptions,
      arrAvailableTimeslots,
      isGym
    } = this.state;
    delete selectedServices[index + 1];
    delete arrEmploye[index + 1];
    delete selectedEmployee[index + 1];
    delete arrSelectDate[index + 1];
    delete arrSelectTime[index + 1];
    delete arrFilteredEmployeeOptions[index + 1]
    delete arrAvailableTimeslots[index + 1]

    this.setState(
      {
        arrServices,
        arrEmploye,
        selectedServices,
        selectedEmployee,
        arrSelectDate,
        arrSelectTime,
        arrAvailableTimeslots,
        arrFilteredEmployeeOptions
      },
      () => {
        let amount = 0;
        if(!isGym) {
          this.state.selectedServices.forEach((val) => {
            if (val) amount = amount + val.price;
          });
        }
        this.setState({ amount });
      }
    );
    if (!value._id) {
      arrServices[index + 1] = {
        ...arrServices[index + 1],
        serviceId: false,
        employeeId: false,
        time: false,
        date: false,
      };
      return;
    }
    this.setState({ isloading: true });

    arrServices[index + 1] = {
      ...arrServices[index + 1],
      serviceId: value._id,
    };
    selectedServices[index + 1] = value;
    let amount = 0;
    if(!isGym) {
      selectedServices.forEach((val) => {
        if (val) {
          amount = amount + val.price;
        }
      });
    }
    try {
      const employs = await this.getEmployee(value._id);
      arrSelectDate[index + 1] = {}
      arrEmploye[index + 1] = employs;
      this.setState({
        arrServices,
        isloading: false,
        arrEmploye,
        selectedServices,
        amount,
      });
    } catch (error) {
      this.setState({ isloading: false });
      ErrorHelper.handleErrors("Service Not selected", true);
    }
  };

  selectEmploy = async (value, index) => {
    const {
      arrServices,
      selectedEmployee,
    } = this.state;
    if (!JSON.parse(value)._id) {
      arrServices[index + 1] = {
        ...arrServices[index + 1],
        employeeId: false,
      };
      delete selectedEmployee[index + 1];
      this.setState({
        selectedEmployee,
      });
      return;
    }

    arrServices[index + 1] = {
      ...arrServices[index + 1],
      employeeId: JSON.parse(value)._id,
    };
    selectedEmployee[index + 1] = value;
    this.setState({ arrServices, selectedEmployee });
  };

  renderAppointment = () => {
    const {
      companyList,
      selectDayOfTime,
      companyCategories,
      selectedCategory,
      companyServices,
      companyEmployee,
      selectedService,
      selectedEmployee,
      arrServices,
      arrSelectCategory,
      arrDataServices,
      arrSelectDate,
      arrSelectTime,
      arrEmploye,
      amount,
      arrAvailableTimeslots,
      arrFilteredEmployeeOptions,
      arrSelectedEmployee,
      isGym,
      companyName
    } = this.state;
    let num = 0;

    return (
      <div ref={this.appointment} id="Appointment">
        <div class="pb-3 mx-2" style={{ }}>
          <div class="container my-2 px-0" style={{backgroundColor: "white"}}>
            <div className="card">
              <div className="card-title mt-2 mb-0">
                <div class="intro">
                  <h3
                    style={{
                      fontWeight: "bold",
                      color: "#495057"
                    }}
                    class="text-center mb-0"
                  >
                    Create Your Appointment
                  </h3>
                  { companyName && 
                    <>
                      <h4
                        style={{
                          fontWeight: "",
                          color: "#495057"
                        }}
                        class="text-center mb-0"
                      >
                        With
                      </h4>
                      <h3
                        style={{
                          fontWeight: "bold",
                          color: "#495057"
                        }}
                        class="text-center mb-0"
                      >
                        {companyName}
                      </h3>
                    </>
                  }
                  <hr
                    style={{
                      height: "3px",
                      border: "none",
                      backgroundColor: "#495057",
                      width: "350px",
                    }}
                  />
                </div>
              </div>
              <div className='px-2'>
              {arrSelectCategory.map((value, index) => (
                <div class="row mt-1 ml-2">
                  {/* render category */}
                  <p className="mt-3 serviceLable">{(num += 1)}</p>
                  <div class="row " style={{width: '100%'}}>
                    <div className="col-md-6 mt-3 selectNew">
                      <select
                        onChange={(e) => this.selectCategory(e.target.value, index)}
                        className="form-control mx-1"
                        
                      >
                        <option
                          value={false}
                          className="form-group"
                          
                        >
                          {i18n.t('container.select_category')}
                        </option>
                        {companyCategories.map((service, i) => {
                          return (
                            <option
                              value={JSON.stringify(service)}
                              key={i}
                              className="form-group"
                              
                            >
                              {service.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    {/* end render category */}
                    {/* render services */}
                    {!!arrDataServices.length && arrDataServices[index + 1] && (
                      <div className="col-md-6 mt-3 selectNew">
                        <select
                          onChange={(e) =>
                            this.selectMultiServices(e.target.value, index)
                          }
                          className="form-control mx-1"
                          
                        >
                          <option
                            value={false}
                            className="form-group"
                            
                          >
                            Select Services
                          </option>
                          {arrDataServices[index + 1].map((service, i) => {
                            return (
                              <option
                                value={JSON.stringify(service)}
                                key={i}
                                className="form-group"
                                
                              >
                                {`${service.name} ${isGym ? '' : '(Estimated Time' + service?.duration + ')'}`}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    )}
                    {/* end render services */}

                    {/* render date */}
                    {!!arrSelectDate.length && arrSelectDate[index + 1] && (
                      <div class="col-md-6 mt-3 selectNew" >
                        <DatePicker
                          className="form-control mx-1"
                          minDate={new Date()}
                          selected={
                            arrSelectDate[index + 1]
                              ? arrSelectDate[index + 1].selectDate
                              : ""
                          }
                          onChange={(date) =>
                            this.onDateChange(
                              date,
                              selectedEmployee[index + 1],
                              index
                            )
                          }
                          dayClassName={(date) =>
                            this.checkDateIsAvil(date, index)
                          }
                          placeholderText={i18n.t('container.services_click_to_select_a_date')}                    />
                      </div>
                    )}
                    {/* end render date */}

                    {/* render time */}
                    {!!arrAvailableTimeslots.length && arrAvailableTimeslots[index + 1] && (
                      <div class="col-md-6 mt-3 selectNew" >
                        <select
                          
                          value={arrSelectTime[index + 1]
                            ? arrSelectTime[index + 1].time
                            : ""}
                          className="form-control mx-1"
                          onChange={(text) => this.onChangeTime(text, index)}
                        >
                          <option>Select Time</option>
                          {Object.keys(arrAvailableTimeslots[index + 1] ? arrAvailableTimeslots[index + 1] : {})?.map((val, ind) => {
                            return (
                              <option
                                key={ind}
                                value={val}
                                className="form-group form-control"
                                
                              >
                                {val.split(' ')[0]}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    )}
                    {/* end render time */}

                    {/* render employ */}
                    {!!arrFilteredEmployeeOptions.length && arrFilteredEmployeeOptions[index + 1] && (
                      <div class="col-md-6 mt-3 selectNew" >
                        <select
                          onChange={(e) => this.selectEmploy(e.target.value, index)}
                          value={selectedEmployee[index + 1] ? selectedEmployee[index + 1] : false}
                          className="form-control mx-1"
                          
                        >
                          <option
                            value={false}
                            className="form-group form-control"
                          >
                            Select Employee
                          </option>
                          {arrFilteredEmployeeOptions[index + 1].map((employ, i) => {
                            return (
                              <option
                                value={JSON.stringify(employ)}
                                key={i}
                                className="form-group form-control"
                              >
                                {employ.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    )}
                    {/* end render employ */}
                  </div>
                </div>
              ))}

              <div class="row mx-0">
                {!isGym && this.renderSelect(
                  [{ name: "Cash" }, { name: "Points" }, { name: 'Credit/Debit' }],
                  "Payment Method"
                )}
                {!isGym && !!amount && (
                  <div class="col-md-6 mt-3" >

                    <input
                      value={amount}
                      class="form-group form-control "
                      disabled={true}
                    // type={type}
                    // placeholder={text}
                    />
                  </div>
                )}
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="row" style={{justifyContent: 'center'}}>
                    <div class="col-lg-6 col-md-8 col-xs-12 mt-3">
                      <textarea
                        class="form-group form-control"
                        placeholder="Notes"
                      />
                    </div>
                  </div>
                </div>
                <div
                  onClick={this.handleSubmit}
                  class="col-12 mt-3 book-now-wrapper"
                  
                >
                  <button class="form-group btn btn-sm btn-light" >
                    BOOK NOW
                  </button>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { companyLogo } = this.state
    return (
      <div style={{ height: "100vh", backgroundColor: "rgb(248, 253, 255)" }}>
        <div className="container-fluid shadow mx-0 py-2 mb-5" style={{backgroundColor: "white"}}>
          <img
            alt="company logo"
            src={companyLogo ? companyLogo : Images.easy1_logo_800x300}
            className="logo cursor-pointer"
            style={{zIndex: 2, width: 100, height: 100}}
            onClick={() => this.props.history.goBack()}
          />
        </div>
        {this.renderLoader()}
        {this.renderAppointment()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  getAvailableTimeslots: state.getAvailableTimeslots,
  getGymTimeslots: state.getGymTimeslots
});

const action = {
  get_available_timeslots,
  get_gym_timeslots
};

export default connect(mapStateToProps, action)(CreateAppointmentForm);

