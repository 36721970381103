import React from "react";
import Button from "@material-ui/core/Button";

export default ({
  label,
  onClick = () => {},
  props,
  style = {
    backgroundColor: "#ff3600",
    color: "white",
    maxHeight: 30,
  },
  className = "",
}) => {
  return (
    <Button {...props} onClick={onClick} style={style} className={className}>
      {label}
    </Button>
  );
};
