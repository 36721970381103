const Styles = {
  root: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: "hidden",
  },
  sidebar: {
    zIndex: 3,
    position: "absolute",
    top: 0,
    bottom: 0,
    transition: "transform .3s ease-out",
    WebkitTransition: "-webkit-transform .3s ease-out",
    willChange: "transform",
    overflowY: "auto",
    backgroundColor: "white",
  },
  content: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflowY: "auto",
    WebkitOverflowScrolling: "touch",
    transition: "left .3s ease-out, right .3s ease-out",
  },
  overlay: {
    zIndex: 2,
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
    visibility: "hidden",
    transition: "opacity .3s ease-out, visibility .3s ease-out",
    backgroundColor: "rgba(0,0,0,.3)",
  },
  dragHandle: {
    zIndex: 2,
    position: "fixed",
    top: 0,
    bottom: 0,
  },
  notification: {
    padding: "0px 5px 0px 0px",
    position: "relative",
    display: "inline-block",
    borderRadius: "2px",
  },
  badge: {
    position: "absolute",
    top: "-2px",
    right: "8px",
    padding: "6px",
    borderRadius: "50%",
    background: "red",
  },

  split: {
    marginLeft: "20px",
  },
};

export default Styles;
