import React from "react";
import { connect } from "react-redux";
import { DotsLoader, Navbar } from "../../../components";
import { Images } from "../../../theme";
import { Translation } from "react-i18next";
import i18n from "../../../i18n";
import moment from "moment";
import { SuccessHelper, ErrorHelper } from "../../../helpers";
import { request as employee_register } from "../../../redux/actions/Employee/Register";
import {
  getEmployeeServices,
  createEmployeeProfile,
  getAllSaloonsForEmployee,
} from "../../../config/simpleApiCalls";
import NumberFormat from "react-number-format";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import PlacesAutocomplete from "../../../components/PlacesAutocomplete";

class EmployeeRegister extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Personal Information
      firstName: "",
      lastName: "",
      password: "",
      dateOfBirth: "",
      password: "",
      confirmedPassword: "",
      address: "",
      city: "",
      province: "",
      postalCode: "",
      telephone: "",
      functionRole: "",
      selectSaloon: [],
      email: "",
      option: [],
      personalAddress: {},

      // Personal Information Error
      firstNameError: "",
      lastNameError: "",
      dateOfBirthError: "",
      passwordError: "",
      addressError: "",
      cityError: "",
      provinceError: "",
      postalCodeError: "",
      telephoneError: "",
      emailError: "",
      functionRoleError: "",
      selectSaloonError: "",
      // Working Hours
      employeesWorkingHoursData: [
        { dayOfWeek: 1, availableStatus: 0, dayName: "Sunday" },
        { dayOfWeek: 2, availableStatus: 0, dayName: "Monday" },
        { dayOfWeek: 3, availableStatus: 0, dayName: "Tuesday" },
        { dayOfWeek: 4, availableStatus: 0, dayName: "Wednesday" },
        { dayOfWeek: 5, availableStatus: 0, dayName: "Thursday" },
        { dayOfWeek: 6, availableStatus: 0, dayName: "Friday" },
        { dayOfWeek: 7, availableStatus: 0, dayName: "Saturday" },
      ],
      employeesWorkingHoursError: false,

      // Services
      serviceList: [],
      employeesServicesError: false,

      isWorkingHourVerified: false,
      isServicesVerified: false,
      isloading: false,
      formTypeId: 1,
      formTypeText: "Personal Information",
      // access_token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZWM1MjYxM2FiMWIyNTAwMTcxNjNiNTYiLCJpYXQiOjE1ODk5Nzg2NDN9._iclG8NRtrdB-YLvkDqFN4Wf_oVYuhA0oAqAeUxaKR0',
      access_token: "",

      // intializing for time select validations
      checkinval: null,
      checkoutval: null,
    };
  }

  componentDidMount() {
    const employeeData = JSON.parse(sessionStorage.getItem("employee"));
    if (employeeData && employeeData.access_token) {
      this.handleNavigation("/employee-booking");
    }
    // this.handleAllEmployee()
  }

  handleAllEmployee = async () => {
    this.setState({ isloading: true });
    const { access_token, option } = this.state;
    getAllSaloonsForEmployee(access_token)
      .then((res) => {
        if (res.data.success) {
          this.setState(
            {
              isloading: false,
              options: res.data.data,
            },
            () => {
              let abc = option;
              this.state.options.map((val) => {
                let obj = { key: val.saloon._id, label: val.saloon.name };
                abc.push(obj);
              });
              this.setState({
                option: abc,
              });
            }
          );
        }
        // console.log(this.state.option, "optionoptionoptionoptionoptionoption");
      })
      .catch((error) => {
        console.log(error, "errorrrrrrrrrrrrrrrrrrr");
      });
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.employeeRegister) {
      if (
        !nextProps.employeeRegister.failure &&
        !nextProps.employeeRegister.isFetching &&
        !nextProps.employeeRegister.errorMessage &&
        nextProps.employeeRegister.data &&
        nextProps.employeeRegister.data.data &&
        nextProps.employeeRegister.data.data.success &&
        nextProps.employeeRegister.data?.data?.data?.access_token
      ) {
        this.setState(
          {
            formTypeId: 2,
            formTypeText:
              <Translation>{(t) => t("container.services")}</Translation> +
              "/" +
              <Translation>{(t) => t("container.working_hours")}</Translation>,
            access_token:
              nextProps.employeeRegister.data.data.data.access_token,
          },
          () => this.getAllEmployeeServices()
        );
      } else if (
        (nextProps.employeeRegister.failure &&
          !nextProps.employeeRegister.isFetching &&
          nextProps.employeeRegister.errorMessage) ||
        !nextProps.employeeRegister.data?.data?.success
      ) {
        if (nextProps.employeeRegister.data?.data?.msg) {
          ErrorHelper.handleErrors(
            nextProps.employeeRegister.data?.data?.msg,
            true
          );
        }
        this.setState({ isloading: false });
      }
    }
  }

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  // Personal Information
  onChangeFirstName = (text) => this.setState({ firstName: text.target.value });
  onChangeLastName = (text) => this.setState({ lastName: text.target.value });
  OnChangePassword = (text) => this.setState({ password: text.target.value });
  OnChangeConfirmedPassword = (text) =>
    this.setState({ confirmedPassword: text.target.value });
  onChangeAddress = (text) => this.setState({ address: text.target.value });
  onChangeCity = (text) => this.setState({ city: text.target.value });
  onChangeProvince = (text) => this.setState({ province: text.target.value });
  onChangePostalCode = (text) =>
    this.setState({ postalCode: text.target.value });
  onChangeTelephone = (text) => this.setState({ telephone: text.target.value });
  onChangeFunctionRole = (text) =>
    this.setState({ functionRole: text.target.value });
  onChangeEmail = (text) => this.setState({ email: text.target.value });
  onChangeSelectSaloon = (selectSaloon) => {
    console.log(selectSaloon, "selectSaloonselectSaloon");
  };
  // {this.setState({ text })}

  handleServicesTag = (ind) => {
    const { serviceList } = this.state;
    serviceList[ind].isSelected = serviceList[ind].isSelected ? false : true;
    this.setState({ serviceList });
  };

  onChangeWorkingHrCheckbox = (e, ind) => {
    const { employeesWorkingHoursData } = this.state;
    if (e.target.checked) {
      employeesWorkingHoursData[ind].availableStatus = 1;
      this.setState({ employeesWorkingHoursData });
    } else {
      employeesWorkingHoursData[ind].availableStatus = 0;
      this.setState({ employeesWorkingHoursData });
    }
  };

  onChangeServiceCheckbox = (e, ind) => {
    const { serviceList } = this.state;
    if (e.target.checked) {
      serviceList[ind].isSelected = true;
      this.setState({ serviceList });
    } else {
      serviceList[ind].isSelected = false;
      this.setState({ serviceList });
    }
  };

  onChangeCheckIn = (text, ind) => {
    const { employeesWorkingHoursData } = this.state;
    console.log(text.target.value, "Checkin value");
    if (text.target.value) {
      employeesWorkingHoursData[ind].checkIn = text.target.value;
    }
    this.setState({ employeesWorkingHoursData, checkinval: text.target.value });
  };

  onChangeCheckOut = (text, ind) => {
    const { employeesWorkingHoursData, checkinval } = this.state;
    let isValidCheckin = checkinval != null ? checkinval.split(":") : "";
    let isValidCheckout = text.target.value.split(":");
    let checkoutVal = text.target.value;
    console.log(isValidCheckin, isValidCheckout, "checkoutvalue value");
    if (text.target.value) {
      if (
        isValidCheckout[0] === isValidCheckin[0] &&
        isValidCheckin[1] >= isValidCheckout[1]
      ) {
        employeesWorkingHoursData[ind].checkOut = "";
        setTimeout(() => {
          ErrorHelper.handleErrors(
            "Checkout time should be greater than checkin",
            true
          );
        }, 1000);
      } else if (isValidCheckin[0] > isValidCheckout[0]) {
        employeesWorkingHoursData[ind].checkOut = "";
        setTimeout(() => {
          ErrorHelper.handleErrors(
            "Checkout time should be greater than checkin",
            true
          );
        }, 1000);
      } else {
        employeesWorkingHoursData[ind].checkOut = text.target.value;
      }
    }
    this.setState({ employeesWorkingHoursData });
  };

  onChangePrice = (text, ind) => {
    const { serviceList } = this.state;
    serviceList[ind].charges = parseFloat(text.target.value);
    this.setState({ serviceList });
  };

  checkValidation = () => {
    const { formTypeId } = this.state;
    if (formTypeId === 1) {
      this.validatePersonalInformation();
    } else if (formTypeId === 2) {
      this.validateServicesWorkingHours();
    }
  };

  validatePersonalInformation = () => {
    const {
      firstName,
      lastName,
      dateOfBirth,
      password,
      address,
      city,
      province,
      postalCode,
      telephone,
      functionRole,
      email,
      confirmedPassword,
      addressMap,
      // selectSaloon,
    } = this.state;

    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const nameRegex =
      /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{2,}$/u;
    const telephoneRegex =
      /[0-9][0-9][0-9] [0-9][0-9][0-9] [0-9][0-9][0-9][0-9]/g;
    if (!firstName) {
      this.setState({
        firstNameError: (
          <Translation>{(t) => t("first_name_required")}</Translation>
        ),
      });
      setTimeout(() => {
        this.setState({
          firstNameError: "",
        });
      }, 6000);
    } else if (!firstName.match(nameRegex)) {
      this.setState({
        firstNameError: (
          <Translation>
            {(t) => t("first_name_lenght_minimum_required")}
          </Translation>
        ),
      });
      setTimeout(() => {
        this.setState({
          firstNameError: "",
        });
      }, 6000);
    } else if (!lastName) {
      this.setState({
        lastNameError: (
          <Translation>{(t) => t("last_name_required")}</Translation>
        ),
      });
      setTimeout(() => {
        this.setState({
          lastNameError: "",
        });
      }, 6000);
    } else if (!lastName.match(nameRegex)) {
      this.setState({
        lastNameError: (
          <Translation>
            {(t) => t("last_name_lenght_minimum_required")}
          </Translation>
        ),
      });
      setTimeout(() => {
        this.setState({
          lastNameError: "",
        });
      }, 6000);
    } else if (!email) {
      this.setState({
        emailError: <Translation>{(t) => t("email_required")}</Translation>,
      });
      setTimeout(() => {
        this.setState({
          emailError: "",
        });
      }, 6000);
    } else if (!email.match(emailRegex)) {
      this.setState({
        emailError: <Translation>{(t) => t("invalid_email")}</Translation>,
      });
      setTimeout(() => {
        this.setState({
          emailError: "",
        });
      }, 6000);
    } else if (!telephone) {
      this.setState({
        telephoneError: (
          <Translation>{(t) => t("telephone_required")}</Translation>
        ),
      });
      setTimeout(() => {
        this.setState({
          telephoneError: "",
        });
      }, 6000);
    } else if (!telephone.match(telephoneRegex)) {
      this.setState({
        telephoneError: (
          <Translation>{(t) => t("invalid_phone_number")}</Translation>
        ),
      });

      setTimeout(() => {
        this.setState({
          telephoneError: "",
        });
      }, 6000);
    } else if (!password) {
      this.setState({
        passwordError: (
          <Translation>{(t) => t("confirm_password_not_match")}</Translation>
        ),
      });
      setTimeout(() => {
        this.setState({
          passwordError: "",
        });
      }, 6000);
    } else if (password.length < 6) {
      this.setState({
        passwordError: (
          <Translation>{(t) => t("password_min_length")}</Translation>
        ),
      });
      setTimeout(() => {
        this.setState({
          passwordError: "",
        });
      }, 6000);
    } else if (password != confirmedPassword) {
      this.setState({
        passwordError: (
          <Translation>{(t) => t("confirm_password_not_match")}</Translation>
        ),
      });
      setTimeout(() => {
        this.setState({
          passwordError: "",
        });
      }, 6000);
    } else if (addressMap == undefined || Object.keys(addressMap) == 0) {
      // console.log(addressMap);
      this.setState({
        addressError: i18n.t("address_required"),
      });
      setTimeout(() => {
        this.setState({
          addressError: "",
        });
      }, 6000);
    } else {
      this.handleRegister();
    }
  };

  handleRegister = () => {
    this.setState({ isloading: true });
    const { firstName, lastName, password, telephone, email, addressMap } =
      this.state;
    let payload = {
      firstName,
      lastName,
      email,
      phoneNo: telephone,
      password,
      platform: "android",
      role: "4",
    };
    if (Object.keys(addressMap).length) {
      payload = { ...payload, ...addressMap };
    }
    this.props.employee_register(payload);
  };

  getAllEmployeeServices = () => {
    this.setState({ isloading: true });
    const { access_token } = this.state;
    getEmployeeServices(access_token)
      .then((res) => {
        if (res.data.success) {
          for (let i = 0; i < res.data.data.length; i++) {
            res.data.data[i].isSelected = false;
          }
          this.setState({ isloading: false, serviceList: res.data.data });
        }
      })
      .catch((error) => {
        console.log(error, "errorrrrrrrrrrrrrrrrrrr");
      });
  };

  validateServicesWorkingHours = () => {
    const {
      employeesWorkingHoursData,
      serviceList,
      isServicesVerified,
      isWorkingHourVerified,
    } = this.state;
    for (let i = 0; i < employeesWorkingHoursData.length; i++) {
      if (employeesWorkingHoursData[i].availableStatus === 1) {
        if (!employeesWorkingHoursData[i].checkIn) {
          this.setState({
            isWorkingHourVerified: false,
            employeesWorkingHoursError: true,
          });
          setTimeout(() => {
            this.setState({
              isWorkingHourVerified: false,
              employeesWorkingHoursError: false,
            });
          }, 6000);
          return;
        } else if (!employeesWorkingHoursData[i].checkOut) {
          this.setState({
            isWorkingHourVerified: false,
            employeesWorkingHoursError: true,
          });
          setTimeout(() => {
            this.setState({
              isWorkingHourVerified: false,
              employeesWorkingHoursError: false,
            });
          }, 6000);
          return;
        } else {
          this.setState({
            isWorkingHourVerified: true,
            employeesWorkingHoursError: false,
          });
        }
      }
    }
    for (let i = 0; i < serviceList.length; i++) {
      if (serviceList[i].isSelected) {
        this.setState({
          isServicesVerified: true,
          employeesServicesError: false,
        });
      }
    }
    if (isWorkingHourVerified && isServicesVerified) {
      this.handleCreateEmployeeProfile();
    }
  };

  handleCreateEmployeeProfile = () => {
    this.setState({ isloading: true });
    const { access_token, serviceList, employeesWorkingHoursData } = this.state;
    const employeesWorkingHoursSorted = employeesWorkingHoursData.map(function (
      obj
    ) {
      if (obj.availableStatus === 0) {
        delete obj["checkIn"]; // Delete old key
        delete obj["checkOut"]; // Delete old key
      }
      delete obj["dayName"]; // Delete old key
      delete obj["_id"]; // Delete old key
      return obj;
    });
    let serviceListSorted = [];
    for (let i = 0; i < serviceList.length; i++) {
      if (serviceList[i].isSelected) {
        let myService = { charges: 1, serviceId: serviceList[i]._id };
        serviceListSorted.push(myService);
      }
    }
    const payload = {
      services: serviceListSorted,
      weekPlans: employeesWorkingHoursSorted,
    };
    console.log(payload, "payloaaddddddddddddddddddddddddddddddd");
    createEmployeeProfile(payload, access_token)
      .then((res) => {
        if (res.data.success) {
          console.log(res.data, "res.datares.datares.datares.datares.data");
          this.setState({ isloading: false });
          SuccessHelper.handleSuccess("You have successfully Signup.", true);
          this.handleNavigation("/employee-login");
        }
      })
      .catch((error) => {
        console.log(error, "errorrrrrrrrrrrrrrrrrrr");
      });
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  // Card Heading
  renderCardHeading = () => {
    return (
      <>
        <h1 id="register-heading">
          <Translation>
            {(t) => t("container.register_yourself_employee")}
          </Translation>
        </h1>
        <hr id="register-heading-line" />
      </>
    );
  };

  renderStepSectionLg = () => {
    const { formTypeId } = this.state;
    return (
      <div
        className="col-12 p-4"
        id="step-section-lg"
        style={{ backgroundColor: "#FFF6EF" }}
      >
        <div className="row">
          <hr id="steps-dots-line" />
          <div className="col-3 d-flex align-items-center">
            {(formTypeId === 1 || formTypeId === 2) && (
              <img src={Images.step_done_tick} className="step_done_tick" />
            )}
            <span className="steps-text">
              <Translation>{(t) => t("container.personal_info")}</Translation>
            </span>
          </div>
          <div className="col-4 d-flex align-items-center">
            {formTypeId === 2 && (
              <img src={Images.step_done_tick} className="step_done_tick" />
            )}
            <span className="steps-text">
              <Translation>{(t) => t("container.services")}</Translation> /{" "}
              <Translation>{(t) => t("container.working_hours")}</Translation>
            </span>
          </div>
        </div>
      </div>
    );
  };

  renderStepSectionSm = () => {
    const { formTypeId, formTypeText } = this.state;
    return (
      <div
        className="col-12 p-3 py-md-4"
        id="step-section-sm"
        style={{ backgroundColor: "#FFF6EF" }}
      >
        <div className="row">
          <div className="col-12 d-flex align-items-center">
            <span className="step_no">{formTypeId}</span>
            <span className="steps-text-sm">{formTypeText}</span>
          </div>
        </div>
      </div>
    );
  };

  //Main Content
  renderContentSection = () => {
    const { formTypeId, employeesServicesError, employeesWorkingHoursError } =
      this.state;
    return (
      <div className="container-fluid min-vh-100" id="main-container">
        <div className="container">
          <div className="row">
            <div className="col-12 p-md-5 p-3" id="register-saloon-card">
              {this.renderCardHeading()}
              {this.renderStepSectionLg()}
              {this.renderStepSectionSm()}
              {formTypeId === 1 && <h2 id="started-heading">Get Started</h2>}
              {formTypeId === 1 && this.renderPersonalInformationForm()}
              {formTypeId === 2 && this.renderServicesWorkingHours()}
              <div className="col-12">
                {formTypeId === 2 && (
                  <p className="text-danger">
                    <strong>Note : </strong>
                    <Translation>
                      {(t) => t("service_working_hour_required")}
                    </Translation>
                    .
                  </p>
                )}
                {employeesWorkingHoursError && (
                  <p className="mb-0 text-danger">
                    <strong>Error : </strong>
                    <Translation>
                      {(t) => t("container.working_hours_required")}
                    </Translation>
                  </p>
                )}
                {employeesServicesError && (
                  <p className="mb-0 text-danger">
                    <strong>Error : </strong>
                    <Translation>
                      {(t) => t("container.add_service_price_required")}
                    </Translation>
                  </p>
                )}
              </div>
              <div className="col-12">
                <img
                  onClick={() => this.checkValidation()}
                  src={Images.saloon_form_submit}
                  id="saloon-form-submit"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderInputCol6 = (id, lable, inputType, onChange, errorType) => {
    return (
      <div class="form-group col-md-6 px-0 px-md-3">
        <label htmlFor={id} className="saloon-form-lable">
          {lable}:
        </label>
        <input
          type={inputType}
          class="form-control saloon-form-input"
          id={id}
          onChange={(text) => onChange(text)}
        />
        {errorType ? (
          <small className="form-text text-danger ml-3">{errorType}</small>
        ) : null}
      </div>
    );
  };

  renderInputCol12 = (id, lable, inputType, onChange, errorType) => {
    return (
      <div class="form-group col-12 px-0 px-md-3">
        <label htmlFor={id} className="saloon-form-lable">
          {lable}:
        </label>
        <input
          type={inputType}
          class="form-control saloon-form-input"
          id={id}
          onChange={(text) => onChange(text)}
        />
        {errorType ? (
          <small className="form-text text-danger ml-3">{errorType}</small>
        ) : null}
      </div>
    );
  };

  inputTypeNumber = (id, lable, inputType, onChange, errorMessage) => {
    return (
      <div class="form-group col-md-6 px-0 px-md-3">
        <label htmlFor={id} className="saloon-form-lable">
          {lable}:
        </label>
        <NumberFormat
          type={inputType}
          class="form-control saloon-form-input"
          id={id}
          onChange={(text) => onChange(text)}
          format="### ### ####"
          // value=""
        />

        {errorMessage ? (
          <small className="form-text text-danger ml-3">{errorMessage}</small>
        ) : null}
      </div>
    );
  };

  onAddressChange = (address) => {
    this.setState({ typedAddress: address, address, addressMap: {} });
  };

  onAddressSelect = (address) => {
    const requireComponentsMap = {
      street_number: true,
      route: true,
      locality: true,
      administrative_area_level_1: true,
      country: true,
      postal_code: true,
    };
    let addressMap = {
      street_number: undefined,
      route: undefined,
      locality: undefined,
      administrative_area_level_1: undefined,
      country: undefined,
      postal_code: undefined,
    };
    geocodeByAddress(address)
      .then((results) => {
        results[0].address_components.map((component) => {
          const relevant = component.types.find(
            (type) => requireComponentsMap[type]
          );
          if (relevant && !addressMap[relevant]) {
            addressMap[relevant] = component.long_name;
          }
        });
        addressMap = {
          address: `${addressMap.street_number} ${addressMap.route}`,
          postalCode: addressMap.postal_code,
          city: addressMap.locality,
          province: addressMap.administrative_area_level_1,
          country: addressMap.country,
        };
        let address = Object.values(addressMap)
          .filter((element) => !!element)
          .join(", ");
        this.setState({
          addressMap,
          address,
        });
      })
      .catch((error) => console.error("Error", error));

    this.setState({ address });
  };

  renderAddress = () => {
    return (
      <div class="form-group col-md-6 px-0 px-md-3">
        <label htmlFor="address" className="saloon-form-lable">
          {i18n.t("container.address")}:
        </label>

        <PlacesAutocomplete
          className="pt-2 pt-md-0 col-12 col-md-6 px-0 px-md-1"
          addressError={this.state.addressError}
          addressSelected={(newMap) => {
            // setAddressMap({
            //   ...newMap,
            //   address_line_2: getNestedValue(
            //     addressMap,
            //     ["address_line_2"],
            //     ""
            //   ),
            // })
            // this.onAddressChange(key)
            const addressMap = Object.assign(
              {},
              ...["address", "city", "country", "postalCode", "province"].map(
                (key) => ({
                  [key]: newMap[key],
                })
              )
            );
            this.setState({
              addressMap,
            });
            console.log(this.state.adressMap);
            // console.log(newObject);
            // console.log(newMap);
          }}
          // helperText={errorMap.address}
          // error={!!errorMap.address}
        />
      </div>
    );
  };

  renderPersonalInformationForm = () => {
    const { option } = this.state;
    return (
      <div className="col-12 px-0 px-md-3">
        <h3 className="form-heading">{i18n.t("container.personal_info")}:</h3>
        <div class="form-row">
          {this.renderInputCol6(
            "first-name",
            i18n.t("first_name"),
            "text",
            this.onChangeFirstName,
            this.state.firstNameError
          )}
          {this.renderInputCol6(
            "last-name",
            i18n.t("last_name"),
            "text",
            this.onChangeLastName,
            this.state.lastNameError
          )}
        </div>
        <div class="form-row">
          {this.renderInputCol6(
            "email",
            <Translation>{(t) => t("container.profile_email")}</Translation>,
            "email",
            this.onChangeEmail,
            this.state.emailError
          )}
          {this.inputTypeNumber(
            "telephone",
            <Translation>{(t) => t("container.telephone")}</Translation>,
            "text",
            this.onChangeTelephone,
            this.state.telephoneError
          )}
        </div>
        <div class="form-row">
          {this.renderInputCol6(
            "password",
            "Password",
            "password",
            this.OnChangePassword,
            this.state.passwordError
          )}
          {this.renderInputCol6(
            "confirm-password",
            "Confirm Password",
            "password",
            this.OnChangeConfirmedPassword,
            this.state.passwordError
          )}
        </div>
        <div class="form-row">{this.renderAddress()}</div>
        <div class="form-row"></div>
      </div>
    );
  };

  renderWorkingHoursInput = (ind, data) => {
    return (
      <div className="form-row working-days-table">
        <div className=" px-0 pr-md-3 d-flex align-items-center">
          <input
            value={data.checkIn}
            type="time"
            className="form-control edit-employee-input"
            onChange={(text) => this.onChangeCheckIn(text, ind)}
          />
        </div>
        <div className="px-3 px-md-0 d-flex align-items-center">
          <span>to</span>
        </div>
        <div
          className="  px-0 pl-md-3 d-flex align-items-center"
          style={{ marginBottom: "0px !important" }}
        >
          <input
            value={data.checkOut}
            type="time"
            className="form-control edit-employee-input"
            onChange={(text) => this.onChangeCheckOut(text, ind)}
            // disabled={this.state.checkinval !== null ? false : true}
          />
        </div>
      </div>
    );
  };

  renderWorkingHoursTable = () => {
    const { employeesWorkingHoursData } = this.state;
    return (
      <div className="col-12 p-0">
        <div className="table-responsive">
          <table class="table table-borderless saloon-working-hour-table">
            <thead>
              <tr>
                <th scope="col">
                  <span className="working-day-th-text">
                    <Translation>
                      {(t) => t("container.add_employee_working_days")}
                    </Translation>
                  </span>
                </th>
                <th className="text-center" scope="col">
                  <span className="working-day-th-text">
                    <Translation>{(t) => t("container.hours")}</Translation>
                  </span>
                </th>
                <th className="text-center" scope="col">
                  <span className="working-day-th-text">
                    <Translation>
                      {(t) => t("container.availability")}
                    </Translation>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {employeesWorkingHoursData.map((val, ind) => {
                return (
                  <tr key={ind} style={{ borderTop: "3px solid #F7F7F7" }}>
                    <td>
                      <span className="working-days-name">{val.dayName}</span>
                    </td>
                    <td style={{ display: "flex", justifyContent: "center" }}>
                      {val.availableStatus === 1 && (
                        <span style={{ alignItems: "center" }}>
                          {this.renderWorkingHoursInput(ind, val)}
                        </span>
                      )}
                      {val.availableStatus === 0 && (
                        <button
                          className="btn btn-light btn-sm"
                          id="working-off-btn"
                        >
                          OFF
                        </button>
                      )}
                    </td>
                    <td className="text-center">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id={ind}
                          checked={val.availableStatus ? true : false}
                          onChange={(e) =>
                            this.onChangeWorkingHrCheckbox(e, ind)
                          }
                        />
                        <label class="custom-control-label" for={ind}></label>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  renderServicesTable = () => {
    const { serviceList } = this.state;
    return (
      <div className="col-12 p-0">
        <div className="table-responsive">
          <table class="table table-borderless saloon-working-hour-table">
            <thead>
              <tr>
                <th scope="col">
                  <span className="working-day-th-text">
                    <Translation>
                      {(t) => t("container.profile_service")}
                    </Translation>
                  </span>
                </th>
                <th className="text-center" scope="col">
                  <span className="working-day-th-text">
                    <Translation>{(t) => t("by_price")}</Translation>
                  </span>
                </th>
                <th className="text-center" scope="col">
                  <span className="working-day-th-text">
                    <Translation>{(t) => t("container_choose")}</Translation>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {serviceList.map((val, ind) => {
                return (
                  <tr key={ind} style={{ borderTop: "3px solid #F7F7F7" }}>
                    <td>
                      <span className="working-days-name text-capitalize">
                        {val.name}
                      </span>
                    </td>
                    <td style={{ display: "flex", justifyContent: "center" }}>
                      {/* {val.isSelected &&
                                            <div className=" px-0 pr-md-3 d-flex align-items-center">
                                                <input
                                                    placeholder="Price"
                                                    value={val.charges}
                                                    type="number" min="1.0" step="0.5" max="250000"
                                                    className="form-control edit-employee-input"
                                                    onChange={(text) => this.onChangePrice(text, ind)}
                                                />
                                            </div>} */}
                      {!val.isSelected && (
                        <button
                          className="btn btn-light btn-sm"
                          id="working-off-btn"
                        >
                          <Translation>
                            {(t) => t("container.not_selected")}
                          </Translation>
                        </button>
                      )}
                    </td>
                    <td className="text-center">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id={val._id}
                          checked={val.isSelected ? true : false}
                          onChange={(e) => this.onChangeServiceCheckbox(e, ind)}
                        />
                        <label
                          class="custom-control-label"
                          for={val._id}
                        ></label>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  renderServicesWorkingHours = () => {
    const { option } = this.state;
    return (
      <div className="col-12 px-0 px-md-3">
        <h3 className="form-heading">
          <Translation>{(t) => t("container.working_hours")}</Translation>:
        </h3>
        {this.renderWorkingHoursTable()}
        <h3 className="form-heading">
          <Translation>{(t) => t("container.services")}</Translation>:
        </h3>
        {this.renderServicesTable()}
      </div>
    );
  };

  //Footer Social Icons
  renderFooterLogoSection = () => {
    const socialIcon = [
      Images.saloon_footer_facebook,
      Images.saloon_footer_twitter,
      Images.saloon_footer_instagram,
      Images.saloon_footer_youtube,
    ];
    return (
      <div
        className="container-fluid py-4"
        style={{ backgroundColor: "#fff3eb" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-5 col-md-6 px-0 px-md-3 d-flex align-item-center">
              <img src={Images.easy1_logo_800x300} id="footer-logo" />
            </div>
            <div className="col-7 col-md-6 px-0 px-md-3 d-flex justify-content-end align-items-center">
              {socialIcon.map((val, ind) => {
                return (
                  <span key={ind}>
                    <img src={val} className="saloon_footer_icon" />
                  </span>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  //Footer Text
  renderFooterTextSection = () => {
    return (
      <div className="container-fluid" style={{ backgroundColor: "#ff3600" }}>
        <div className="container">
          <div className="row">
            <div className="col-12 py-4">
              <h6 className="h6 mb-0 text-white text-center">
                &copy; <Translation>{(t) => t("copyright")}</Translation>
              </h6>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div style={{ backgroundColor: "#F7F8F7" }}>
        {this.renderLoader()}
        <div className="container-fluid">
          <div className="container">
            <Navbar {...this.props} isBackgroundColor={true} />
          </div>
        </div>
        {this.renderContentSection()}
        {this.renderFooterLogoSection()}
        {this.renderFooterTextSection()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  employeeRegister: state.employeeRegister,
});

const action = {
  employee_register,
};

export default connect(mapStateToProps, action)(EmployeeRegister);
