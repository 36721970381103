import { connect } from "react-redux";
import React, { Component } from "react";

import i18n from "../../i18n";
import { Translation, withTranslation } from "react-i18next";

import { DotsLoader, Navbar, Footer } from "./../../components";
import { SuccessHelper, ErrorHelper } from "./../../helpers";
import { request as get_home_page_info } from "../../redux/actions/GetHomePageInfo";
import { request as get_home } from "../../redux/actions/GetHome";

import { getEmployeeById } from "../../config/simpleApiCalls";
import { Images } from "./../../theme";
import { getNestedValue } from "./../../util/objectMethods";
import { Tab } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./styles.scss";

const socialLinkImageMAp = {
  facebook: "saloon_link_social_facebook",
  instagram: "saloon_link_social_instagram",
  twitter: "saloon_link_social_twitter",
  youtube: "saloon_link_social_youtube",
  snapchat: "saloon_link_social_snapchat",
  linkedin: "saloon_link_social_linkedin",
};

class EmployeeProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employeeId: this.props.match.params.employeeId,
      expandedCategoriesMap: {},
      imageCacheMap: {},
      imageContentLoading: true,
    };
  }

  async componentDidMount() {
    const { employeeId } = this.state;
    const toDataUrl = (url, callback) => {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    };
    getEmployeeById({ _id: employeeId }).then(({ data }) => {
      if (data.success) {
        let imageCacheMap = {};
        this.setState({
          socialLinks: data.data.socialLinks,
          posts: data.data.posts,
          services: data.data.services,
          employee: data.data.employee,
        });
        Promise.all(
          data.data.posts.map((post) => {
            return new Promise((resolve) => {
              toDataUrl(
                post.images.url.replace("http", "https"),
                (cachedImage) => {
                  imageCacheMap[post._id] = cachedImage;
                  resolve();
                }
              );
            });
          })
        ).then(() => {
          this.setState({ imageCacheMap, imageContentLoading: false });
        });
      } else {
        this.setState({
          isloading: false,
        });
        ErrorHelper.handleErrors("Something went wrong");
      }
    });

    if (!this.props.getHomePageInfo?.data?.data) {
      this.props.get_home_page_info();
    }
    if (!this.props.getHome?.data) {
      this.props.get_home();
    }
  }

  categoryClick = (_id) => {
    console.log(_id);
    const { expandedCategoriesMap } = this.state;
    if (expandedCategoriesMap[_id]) {
      delete expandedCategoriesMap[_id];
    } else {
      expandedCategoriesMap[_id] = true;
    }
    this.setState({ expandedCategoriesMap });
  };

  renderPanes = () => {
    const {
      isloading,
      employee,
      posts,
      services = [],
      expandedCategoriesMap,
    } = this.state;
    const categoryServiceMap = {};
    services.map((service) => {
      if (!categoryServiceMap[service.serviceId.serviceId._id]) {
        categoryServiceMap[service.serviceId.serviceId._id] = {
          ...service.serviceId.serviceId,
          services: [],
        };
      }
      categoryServiceMap[service.serviceId.serviceId._id].services.push(
        service.serviceId
      );
    });
    return [
      {
        menuItem: i18n.t("services"),
        render: () => {
          return (
            <Tab.Pane loading={isloading}>
              <div className="list">
                {Object.values(categoryServiceMap).map((category) => {
                  return (
                    <>
                      <div
                        key={category._id}
                        className="row mx-0 justify-content-between py-2 list-item px-1"
                        onClick={() => this.categoryClick(category._id)}
                      >
                        <div className="row mx-0">
                          <img
                            className="category-image mr-1"
                            src={category.image}
                          ></img>
                          <div className="category-name">{category.name}</div>
                        </div>
                        <FontAwesomeIcon
                          className={`caret-icon ${
                            expandedCategoriesMap[category._id]
                              ? "rotated-icon"
                              : ""
                          }`}
                          color="grey"
                          icon={["fas", "caret-up"]}
                        />
                      </div>
                      <div className="expanded">
                        {expandedCategoriesMap[category._id] &&
                          categoryServiceMap[category._id].services.map(
                            (sevice) => {
                              return (
                                <div
                                  key={sevice._id}
                                  className="row mx-0 justify-content-between py-2 sub-list-item px-3"
                                >
                                  <div>
                                    {sevice.image && (
                                      <img
                                        className="service-image mr-1"
                                        src={sevice.image}
                                      ></img>
                                    )}
                                    {sevice.name}
                                  </div>
                                </div>
                              );
                            }
                          )}
                      </div>
                    </>
                  );
                })}
              </div>
            </Tab.Pane>
          );
        },
      },
      {
        menuItem: i18n.t("portfolio"),
        render: () => {
          const { imageCacheMap, imageContentLoading } = this.state;
          return (
            <Tab.Pane loading={imageContentLoading || isloading}>
              <div className="row mx-0">
                {posts &&
                  posts.map((post) => {
                    return (
                      <div
                        key={post._id}
                        className="imageParent"
                        style={{ marginTop: "8px" }}
                      >
                        <img
                          className="img-fluid1"
                          src={imageCacheMap[post._id]}
                        />
                      </div>
                    );
                  })}
              </div>
            </Tab.Pane>
          );
        },
      },
      {
        menuItem: i18n.t("details"),
        render: () => {
          return (
            <Tab.Pane loading={isloading}>
              {employee?.userId?.bio && (
                <>
                  <h6>Bio</h6>
                  <div className="ml-1 mb-4">{employee?.userId?.bio}</div>
                </>
              )}
              <h6>Working Days</h6>
              <div className="row ml-1">
                {getNestedValue(
                  employee?.weekPlans,
                  [0, "availableStatus"],
                  0
                ) == 1 && (
                  <div className="dayOfWeekChip">
                    <div className="dayOfWeekChipText">S</div>
                  </div>
                )}
                {getNestedValue(
                  employee?.weekPlans,
                  [1, "availableStatus"],
                  0
                ) == 1 && (
                  <div className="dayOfWeekChip">
                    <div className="dayOfWeekChipText">M</div>
                  </div>
                )}
                {getNestedValue(
                  employee?.weekPlans,
                  [2, "availableStatus"],
                  0
                ) == 1 && (
                  <div className="dayOfWeekChip">
                    <div className="dayOfWeekChipText">T</div>
                  </div>
                )}
                {getNestedValue(
                  employee?.weekPlans,
                  [3, "availableStatus"],
                  0
                ) == 1 && (
                  <div className="dayOfWeekChip">
                    <div className="dayOfWeekChipText">W</div>
                  </div>
                )}
                {getNestedValue(
                  employee?.weekPlans,
                  [4, "availableStatus"],
                  0
                ) == 1 && (
                  <div className="dayOfWeekChip">
                    <div className="dayOfWeekChipText">TH</div>
                  </div>
                )}
                {getNestedValue(
                  employee?.weekPlans,
                  [5, "availableStatus"],
                  0
                ) == 1 && (
                  <div className="dayOfWeekChip">
                    <div className="dayOfWeekChipText">F</div>
                  </div>
                )}
                {getNestedValue(
                  employee?.weekPlans,
                  [6, "availableStatus"],
                  0
                ) == 1 && (
                  <div className="dayOfWeekChip">
                    <div className="dayOfWeekChipText">SA</div>
                  </div>
                )}
              </div>
            </Tab.Pane>
          );
        },
      },
    ];
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  render() {
    const { panes, socialLinks = [], employee } = this.state;
    const { getHomePageInfo, homeInfo } = this.props;
    console.log(getHomePageInfo);
    return (
      <div className="container-fluid px-0 employee-profile">
        {this.renderLoader()}
        <Navbar {...this.props} isBackgroundColor={true} />
        <div className="container">
          <div className="row mt-5">
            <div className="col-sm-11 mx-auto mt-5">
              <div className="mt-5">
                <div className="row mx-0 justify-content-between pb-2 align-items-center p-3 border">
                  <div className="row mx-0 align-items-center">
                    <img
                      src={employee?.userId?.profile_img}
                      className="profile-image mr-2"
                    />
                    <h4>{employee?.userId?.firstName}</h4>
                  </div>
                  <div className="row mx-0">
                    {socialLinks.map((link) => {
                      return (
                        <a
                          className="social-icon ml-1"
                          key={link._id}
                          href={"//" + link.url}
                          target="_blank"
                        >
                          <img
                            src={Images[socialLinkImageMAp[link.socialType]]}
                          ></img>
                        </a>
                      );
                    })}
                  </div>
                </div>
                <Tab panes={this.renderPanes()}></Tab>
              </div>
            </div>
          </div>
        </div>
        <Footer
          homePageData={getHomePageInfo}
          cardDetail={homeInfo.homePage}
          renderHeaderInfo={false}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getHomePageInfo: state.getHomePageInfo,
    homeInfo: state.getHome?.data,
  };
};

const action = { get_home_page_info, get_home };

export default connect(mapStateToProps, action)(EmployeeProfile);
