import axios from "axios";
import i18n from "i18next";
import FileDownload from "js-file-download";
import {
  get_saloon_plan_Api,
  get_saloon_payment_method_Api,
  get_social_links_Api,
  get_social_employee_link_api,
  update_saloon_social_links_Api,
  get_all_employees,
  create_saloon_schedule,
  get_company_by_employee,
  update_social_employee_link_api,
  delete_salon_by_employee,
  edit_saloon_schedule,
  add_custom_booking_api,
  get_saloon_schedule,
  get_employee_services_Api,
  create_social_employee_link_api,
  create_employee_profile_Api,
  hire_employee_by_company,
  get_all_employees_associate_with_company,
  get_all_company_employees,
  delete_employee_company,
  saloon_get_categories_Api,
  saloon_create_service_Api,
  saloon_get_all_service_Api,
  saloon_edit_service_Api,
  saloon_update_multiple_services_api,
  saloon_delete_service_Api,
  get_user_Api,
  edit_user_Api,
  get_template_Api,
  company_update_template_Api,
  company_post_upload_Api,
  company_post_get_Api,
  company_post_delete_Api,
  saloon_edit_company_Api,
  get_saloon_user_id_Api,
  get_saloon_plan_id,
  edit_saloon_plan_Api,
  get_Payment_Method_By_Saloon_Api,
  user_create_profile_picture_Api,
  edit_user_payment_method_Api,
  saloon_nearby_Api,
  edit_saloon_company_logo,
  get_all_booking_by_saloon,
  get_all_saloon_Api,
  get_saloon_template_data,
  get_all_booking_by_employee_Api,
  edit_company_employe,
  get_services,
  employee_post_upload,
  edit_employee_profile,
  get_employee_details,
  get_company_employee,
  get_employees_not_associate_with_company,
  add_Companies_By_Employee,
  get_company_portfolio,
  edit_company_user_Api,
  get_employee_by_service_company,
  confirm_booking,
  create_booking,
  edit_booking,
  git_waiting_appoinments,
  edit_customer_profile,
  get_all_bookings_by_user,
  get_all_bookings_by_id,
  get_booking_by_reference_code,
  quick_cancel_booking,
  dismiss_cancelled_booking,
  get_all_bookings_by_saloon,
  get_get_saloon_categories,
  get_saloon_services,
  get_employees_by_saloon_and_service,
  get_home_page_info,
  get_wallet,
  place_Autocomplete_URL,
  forget_password_Api,
  verify_reset_code_Api,
  reset_password_Api,
  get_saloons_by_category_api,
  search_by_name,
  get_home_service_info,
  get_privacy_policy,
  get_cookie_policy,
  get_legal_info,
  get_social_links_for_home,
  get_salon_plains,
  buy_plains,
  subcribe_email,
  get_faqs,
  add_card_info,
  get_card_info,
  get_methods,
  getAllBundles,
  palns_payment,
  create_Update_template,
  get_customer_template,
  createBundle,
  getCompanyBundles,
  updateBundle,
  get_booking_report,
  get_sales_report,
  get_employee_reports,
  get_report,
  google_Api,
  get_ratings_by_company,
  get_ratings_by_employee,
  get_employee_unavailibities,
  get_company_unavailibities,
  employee_unavailibities,
  company_unavailibities,
  get_company_customers,
  get_employee_customers,
  get_demonstration_slots,
  demonstration,
  partner_login,
  live_display_settings,
  company_customer,
  booking_note,
  update_site,
  company_taxes,
  waiting_list,
  products,
  cart,
  add_extra_to_booking,
  remove_extra_to_booking,
  company_checkin_settings,
  inventory_transaction,
  inventory_transaction_increase,
  inventory_transaction_reduce,
  get_employee_by_id,
  company_financial_access,
  one_time_pay,
  stripe_account,
  toggle_online_payments,
  update_interac_transfer_settings,
  update_refund_policy,
  employee_adjust_hours,
  employee_hours_report,
  employee_hours,
  get_company_employee_by_id,
  remove_from_black_list,
  add_to_black_list,
  update_saloon_black_list_Api,
  get_black_list_Api,
  communication_settings,
  form_builder,
  resend_confirmation,
  send_invoice,
  get_product_report,
} from "./WebServices";
import { google_secret_Key } from "./SecretKeys";
import { getNestedValue } from "../util/objectMethods";

export const objectCrawler = (obj) => {
  if (obj && typeof obj == "object") {
    try {
      if ("translations" in obj) {
        obj.name = getNestedValue(
          obj,
          ["translations", i18n.language],
          obj.name
        );
      }
      Object.keys(obj).map((key) => {
        if (obj[key] && typeof obj[key] == "object") {
          if (Array.isArray(obj[key])) {
            obj[key] = obj[key].map((element) => objectCrawler(element));
          } else {
            obj[key] = objectCrawler(obj[key]);
          }
        }
      });
    } catch (e) {}
  }
  return obj;
};

axios.interceptors.response.use((res) => objectCrawler(res));

// import moment from "moment";
// import qs from "qs";

// export const Header = {
//     headers: {
//         "Content-Type": "application/x-www-form-urlencoded"
//     }
// };

export const getBookingReports = (accessToken, params = {}) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_booking_report}`, {
        headers: {
          Authorization: accessToken,
        },
        params,
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getSaloonPlan = (accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_saloon_plan_Api}`, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getCompanyRatings = (accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_ratings_by_company}`, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const getEmployeeRatings = (accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_ratings_by_employee}`, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getSaloonPaymentMethod = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_saloon_payment_method_Api}`)
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getPlains = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_saloon_plan_Api}`)
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getSocialLinks = (accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_social_links_Api}`, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getEmployeeSocialLinks = (accessToken, companyId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_social_employee_link_api}`, {
        headers: {
          Authorization: accessToken,
        },
        params: {
          companyId: companyId,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const UpdateSaloonSocialMedia = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${update_saloon_social_links_Api}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else {
          reject(response.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const UpdateSaloonBlackList = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${update_saloon_black_list_Api}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else {
          reject(response.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const UpdateEmployeeSocialMediaLink = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${update_social_employee_link_api}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else {
          reject(response.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAllEmployee = (accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_all_employees}`, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const editCustomerProfile = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${edit_customer_profile}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const googleLogin = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${google_Api}`, data, {})
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const createSaloonSchedule = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${create_saloon_schedule}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createAllBundles = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${createBundle}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const addCustomBooking = (data, accessToken) => {
  console.log("addCustomBooking -> data, accessToken", data, accessToken);
  return new Promise((resolve, reject) => {
    axios
      .post(`${add_custom_booking_api}`, data, {
        headers: {
          Authorization: accessToken,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response, "responseresponseresponse");
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        console.log(error, "errorerrorerror");
        reject(error);
      });
  });
};

export const getSaloonCategories = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${get_get_saloon_categories}`, data, {
        headers: {
          // Authorization: accessToken,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const createEmployeeSocialMediaLink = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${create_social_employee_link_api}`, data, {
        headers: {
          Authorization: accessToken,
          // 'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        console.log("getSaloonCategories -> response", response);
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getSaloonServices = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${get_saloon_services}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getEmployeesBySaloonAndService = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${get_employees_by_saloon_and_service}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const addCardInfo = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${add_card_info}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        console.log("add_card_info -> response", response);
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const addCompanyByEmployee = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${add_Companies_By_Employee}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// add_Companies_By_Employee

export const editSaloonSchedule = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${edit_saloon_schedule}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const hireCompanyEmployee = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${hire_employee_by_company}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteEmployeeFromCompany = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios({
      method: "delete",
      // data,
      url: `${delete_employee_company}?employeeId=${data.employeeId}`,
      headers: { Authorization: accessToken },
    })
      // axios.delete(`${delete_employee_company}`, data, {
      //     headers: {
      //         Authorization: accessToken
      //     }
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const deleteCompanyPost = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios({
      method: "get",
      url: `${company_post_delete_Api}?id=${data._id}`,
      headers: { Authorization: accessToken },
    })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAllEmployeeAssociatedWithCompany = (accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_all_employees_associate_with_company}`, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getEmployeeNotAssociatedWithCompany = (accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_employees_not_associate_with_company}`, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const addPlansPayment = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${palns_payment}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getCardUserInfo = (accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_card_info}`, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const getAllBooking = (accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_all_booking_by_saloon}`, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getCompletedBooking = (accessToken, params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_all_bookings_by_saloon}`, {
        headers: {
          Authorization: accessToken,
        },
        params,
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getSaloonSchedule = (accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_saloon_schedule}`, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getEmployeeServices = (accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_employee_services_Api}`, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createEmployeeProfile = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${create_employee_profile_Api}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const forgetPassword = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${forget_password_Api}`, data, {})
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const verifyCode = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${verify_reset_code_Api}`, data, {})
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const resetPassword = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${reset_password_Api}`, data, {})
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const saloonGetCategories = (accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${saloon_get_categories_Api}`, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getCompaniesBundle = (accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${getCompanyBundles}`, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const saloonCreateService = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${saloon_create_service_Api}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const saloonGetAllService = (accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${saloon_get_all_service_Api}`, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const saloonEditService = (data, serviceId, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${saloon_edit_service_Api}?serviceId=${serviceId}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateMultipleService = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${saloon_update_multiple_services_api}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const saloonDeleteService = (serviceId, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${saloon_delete_service_Api}?serviceId=${serviceId}`, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const deleteSalonByEmployee = (companyId, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${delete_salon_by_employee}`, {
        headers: {
          Authorization: accessToken,
        },
        data: {
          companyId: companyId,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// Get User
export const getUser = (accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_user_Api}`, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//Edit User
export const editUser = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${edit_user_Api}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const editCompanyUser = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${edit_company_user_Api}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const companyPostUpload = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${company_post_upload_Api}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getEmployeeeReports = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${get_employee_reports}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getReports = (data, accessToken) => {
  const additional = {};
  if (data.exportToXlsx) {
    additional.responseType = "blob";
  }
  return new Promise((resolve, reject) => {
    axios
      .post(`${get_report}`, data, {
        headers: {
          Authorization: accessToken,
        },
        ...additional,
      })
      .then((response) => {
        if (data.exportToXlsx) {
          FileDownload(response.data, "report.xlsx");
          return resolve({});
        }
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getTemplates = (accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_template_Api}`, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const getCompanyPost = (accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${company_post_get_Api}`, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAllBookingsByUser = (accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_all_bookings_by_user}`, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getBookingById = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_all_bookings_by_id}`, {
        params,
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const quickCancelBooking = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${quick_cancel_booking}`,
        data,
        accessToken
          ? {
              headers: {
                Authorization: accessToken,
              },
            }
          : {}
      )
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const dismissCancelledBooking = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${dismiss_cancelled_booking}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const companyUpdateTemplate = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${company_update_template_Api}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getCompanyEmployeeInfo = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${get_company_employee}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// Edit Company
export const editCompany = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${saloon_edit_company_Api}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// Get Saloon By User Id
export const getSaloonById = (accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_saloon_user_id_Api}`, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// Get Saloon Plan By Id
export const getSaloonPlanById = (companyId, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_saloon_plan_id}?companyId=${companyId}`, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const editSaloonPlan = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${edit_saloon_plan_Api}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getPaymentMethodBySaloon = (accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_Payment_Method_By_Saloon_Api}`, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getWallet = (accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_wallet}`, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const userCreateProfilePicture = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${user_create_profile_picture_Api}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const editUserPaymentMethod = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${edit_user_payment_method_Api}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const saloonNearby = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${saloon_nearby_Api}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// Edit Saloon Company Logo
export const editCompanyLogo = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${edit_saloon_company_logo}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// Edit Company Employe
export const editCompanyEmploye = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${edit_company_employe}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// Edit Booking
export const editBooking = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${edit_booking}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        console.log("editCompanyEmploye -> response", response);
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// Edit waiting Booking
export const gitWaitingAppoinments = (params = {}, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${git_waiting_appoinments}`, {
        headers: {
          Authorization: accessToken,
        },
        params,
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const gitAllSaloonAppoinments = (accessToken, params = {}) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_all_booking_by_saloon}`, {
        params,
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const getHomePageInfo = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_home_page_info}`)
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAllSaloonsForEmployee = (token) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_all_saloon_Api}`)
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getSaloonTemplateData = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${get_saloon_template_data}`, data, {})
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const subcribeEmail = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${subcribe_email}`, data, {})
      .then((response) => {
        console.log("response: resolve", response);
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAllBookingByEmployee = (accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_all_booking_by_employee_Api}`, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getHomeServiceInfo = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_home_service_info}`)
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getPrivacyPolicy = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_privacy_policy}`)
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAdminSocialLinksForHome = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_social_links_for_home}`)
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getCookiePolicy = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_cookie_policy}`)
      .then((response) => {
        console.log("response: ", response);
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getFaqs = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_faqs}`)
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getLegalInfo = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_legal_info}`)
      .then((response) => {
        console.log("response: ", response);
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getServices = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_services}`, {})
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// Employee Upload Media
export const employeePostUpload = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${employee_post_upload}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//Employee Edit Profile
export const employeeEditProfile = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${edit_employee_profile}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateBundlePlan = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${updateBundle}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// Get Employee Details

export const getEmployeeDetails = (accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_employee_details}`, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getSalonPlains = (accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_salon_plains}`, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAllBundle = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${getAllBundles}`)
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getCompanyThatEmployeeAdd = (accessToken, params = {}) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_company_by_employee}`, {
        params,
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// Get Portfolio

export const getCompanyPorfolio = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${get_company_portfolio}`, data, {})
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// Get Employee By Services and Compnay
export const getEmployeeByServiceCompany = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${get_employee_by_service_company}`, data, {})
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const confirmBooking = (params, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${confirm_booking}`, {
        headers: {
          Authorization: accessToken,
        },
        params,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// Create Booking

export const createBooking = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${create_booking}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const buyPlains = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${buy_plains}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const createUpdateTemplate = (data, accessToken) => {
  console.log(accessToken, "accessToken");

  return new Promise((resolve, reject) => {
    axios
      .post(`${create_Update_template}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        }
      })

      .catch((error) => {
        reject(error);
      });
  });
};

export const getTemplateData = (accessToken, state) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_customer_template}`, {
        headers: {
          Authorization: accessToken,
        },
        params: {
          templateId: state,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getCardMethods = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_methods}`)
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const locationSuggestions = (value) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${place_Autocomplete_URL(
          value,
          "AIzaSyBN4xzZ_BqLc7v4USWM_DdIU5BsrpYJNyw"
        )}`
      )
      .then((response) => {
        console.log("locationSuggestions -> response", response);
        resolve(response);
      })
      .catch((error) => {
        console.log("locationSuggestions -> error", error);
        reject(error);
      });
  });
};

export const searchByName = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${search_by_name}`, data, {}) // POST METHOD ON DEMAND
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getSaloonsByCategory = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${get_saloons_by_category_api}`, data, {}) // POST METHOD ON DEMAND
      .then((response) => {
        console.log(i18n.language);
        if (response.data.success) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getUnavailabilites = (params, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_employee_unavailibities}`, {
        headers: {
          Authorization: accessToken,
        },
        params,
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response.data.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteEmployeeUnavailability = (params, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${employee_unavailibities}`, {
        headers: {
          Authorization: accessToken,
        },
        params,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createEmployeeUnavailability = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${employee_unavailibities}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const getCompanyUnavailabilites = (params, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_company_unavailibities}`, {
        headers: {
          Authorization: accessToken,
        },
        params,
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response.data.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getCompanyEmployeeUnavaiabilities = (params, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${employee_unavailibities}/getCompanyEmployeeUnavaiabilities`, {
        headers: {
          Authorization: accessToken,
        },
        params,
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response.data.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteCompanyUnavailability = (params, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${company_unavailibities}`, {
        headers: {
          Authorization: accessToken,
        },
        params,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createCompanyUnavailability = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${company_unavailibities}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getLiveDisplaySettings = (params, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${live_display_settings}`, {
        headers: {
          Authorization: accessToken,
        },
        params,
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response.data.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteLiveDisplaySettings = (params, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${live_display_settings}`, {
        headers: {
          Authorization: accessToken,
        },
        params,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createLiveDisplaySettings = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${live_display_settings}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createLiveDisplaySlideResource = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${live_display_settings}/slideResource`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteLiveDisplaySlideResource = (params, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${live_display_settings}/slideResource`, {
        headers: {
          Authorization: accessToken,
        },
        params,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getCompanyCustomers = (params, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_company_customers}`, {
        headers: {
          Authorization: accessToken,
        },
        params,
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response.data.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getEmployeeCustomers = (accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_employee_customers}`, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response.data.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getDemostrationSlots = (params = {}) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_demonstration_slots}`, {
        headers: {},
        params,
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response.data.body);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createDemostrationReservation = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${demonstration}`, data, {
        headers: {},
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const partnerLogin = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${partner_login}`, data, {
        headers: {},
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteCustomer = (params, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${company_customer}`, {
        headers: {
          Authorization: accessToken,
        },
        params,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const upsertCustomer = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${company_customer}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const importCustomers = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${company_customer}/import`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getBookingNote = (params, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${booking_note}`, {
        headers: {
          Authorization: accessToken,
        },
        params,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAllNotesByUser = (params, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${booking_note}/${params.userId}`, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getCustomerBookingHistory = (params, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${company_customer}/bookingHistory`, {
        headers: {
          Authorization: accessToken,
        },
        params,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getCustomerByUserId = (params, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${company_customer}/getCustomerByUserId`, {
        headers: {
          Authorization: accessToken,
        },
        params,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createCustomerTransaction = (payload, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${company_customer}/createTransaction`, payload, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateSite = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${update_site}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteBookingNote = (params, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${booking_note}`, {
        headers: {
          Authorization: accessToken,
        },
        params,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createBookingNote = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${booking_note}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateBookingNote = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${booking_note}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteTax = (params, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${company_taxes}`, {
        headers: {
          Authorization: accessToken,
        },
        params,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getCompanyTaxes = (params, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${company_taxes}`, {
        headers: {
          Authorization: accessToken,
        },
        params,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const upsertTax = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${company_taxes}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getTaxReports = (data, accessToken) => {
  const additional = {};
  if (data.exportToXlsx) {
    additional.responseType = "blob";
  }
  return new Promise((resolve, reject) => {
    axios
      .post(`${company_taxes}/report`, data, {
        headers: {
          Authorization: accessToken,
        },
        ...additional,
      })
      .then((response) => {
        if (response.data.success || data.exportToXlsx) {
          if (data.exportToXlsx) {
            FileDownload(response.data, "report.xlsx");
            return resolve({});
          }
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getSalesReport = (params, accessToken) => {
  const additional = {};
  if (params.exportToXlsx) {
    additional.responseType = "blob";
  }
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_sales_report}`, {
        headers: {
          Authorization: accessToken,
        },
        params,
        ...additional,
      })
      .then((response) => {
        if (response.data.success || params.exportToXlsx) {
          if (params.exportToXlsx) {
            FileDownload(response.data, "report.xlsx");
            return resolve({});
          }
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getProductReport = (data, accessToken) => {
  const additional = {};
  if (data.exportToXlsx) {
    additional.responseType = "blob";
  }
  return new Promise((resolve, reject) => {
    axios
      .post(`${get_product_report}`, data, {
        headers: {
          Authorization: accessToken,
        },
        ...additional,
      })
      .then((response) => {
        if (response.data.success || data.exportToXlsx) {
          if (data.exportToXlsx) {
            FileDownload(response.data, "report.xlsx");
            return resolve({});
          }
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteWaitingList = (params, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${waiting_list}`, {
        headers: {
          Authorization: accessToken,
        },
        params,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getWaitingList = (params, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${waiting_list}`, {
        headers: {
          Authorization: accessToken,
        },
        params,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createWaitingList = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${waiting_list}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteProduct = (params, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${products}`, {
        headers: {
          Authorization: accessToken,
        },
        params,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getProduct = (params, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${products}`, {
        headers: {
          Authorization: accessToken,
        },
        params,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createProduct = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${products}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateProduct = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${products}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getCart = (params, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${cart}`, {
        headers: {
          Authorization: accessToken,
        },
        params,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const addExtra = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${add_extra_to_booking}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const removeExtra = (params, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${remove_extra_to_booking}`, {
        headers: {
          Authorization: accessToken,
        },
        params,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const addProduct = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${cart}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const removeProduct = (params, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${cart}`, {
        headers: {
          Authorization: accessToken,
        },
        params,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateCartProduct = (params, data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${cart}`, data, {
        headers: {
          Authorization: accessToken,
        },
        params,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createCartSale = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${cart}/sale`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else {
          reject(response.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const upsertCheckinSettings = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${company_checkin_settings}/${data.companyId}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getInventoryTransactions = (params, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${inventory_transaction}`, {
        headers: {
          Authorization: accessToken,
        },
        params,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createIncreaseTransaction = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${inventory_transaction_increase}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createReduceTransaction = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${inventory_transaction_reduce}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getEmployeeById = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_employee_by_id}`, {
        params,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const requestFinancialAccess = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${company_financial_access}/request`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const validateFinancialAccess = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${company_financial_access}/validate`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const oneTimePayment = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${one_time_pay}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const addAccount = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${stripe_account}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const startAccountOnBoarding = (accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${stripe_account}/startOnBoarding`, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const toggleOnlinePayments = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${toggle_online_payments}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateInteracTransferSettings = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${update_interac_transfer_settings}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else {
          reject(response.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateRefundPolicy = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${update_refund_policy}`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else {
          reject(response.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const adjustWorkingHours = (data, access_token) => {
  return new Promise((resolve, reject) => {
    axios
      .post(employee_adjust_hours, data, {
        headers: {
          Authorization: access_token,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else {
          reject(response.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getPayrollReport = (data, access_token) => {
  const additional = {};
  if (data.exportToXlsx) {
    additional.responseType = "blob";
  }
  return new Promise((resolve, reject) => {
    axios
      .post(`${employee_hours_report}`, data, {
        headers: {
          Authorization: access_token,
        },
        ...additional,
      })
      .then((response) => {
        if (data.exportToXlsx) {
          FileDownload(response.data, "report.xlsx");
          return resolve({});
        }
        if (response.data.success) {
          resolve(response);
        } else {
          reject(response.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const addWorkingHours = (data, access_token) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${employee_hours}`, data, {
        headers: {
          Authorization: access_token,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else {
          reject(response.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getCompanyEmployeeById = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${get_company_employee_by_id}`, {
        params,
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else {
          reject(response.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const removeFromBlackList = (data, access_token) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${remove_from_black_list}`, data, {
        headers: {
          Authorization: access_token,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else {
          reject(response.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const addToBlackList = (data, access_token) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${add_to_black_list}`, data, {
        headers: {
          Authorization: access_token,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else {
          reject(response.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getMessageTemplate = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${communication_settings}/getCustomMessage`, data)
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else {
          reject(response.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const sendForm = (accessToken, data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${form_builder}/sendForm`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else {
          reject(response.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const resendForm = (accessToken, data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${form_builder}/resendForm`, data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else {
          reject(response.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getFormsByBookingId = (accessToken, params = {}) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${form_builder}/getFormsByBookingId`, {
        headers: {
          Authorization: accessToken,
        },
        params,
      })
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else {
          reject(response.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const resendConfirmation = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${resend_confirmation}`,
        data,
        accessToken
          ? {
              headers: {
                Authorization: accessToken,
              },
            }
          : {}
      )
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const sendInvoice = (data, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${send_invoice}`,
        data,
        accessToken
          ? {
              headers: {
                Authorization: accessToken,
              },
            }
          : {}
      )
      .then((response) => {
        if (response.data.success) {
          resolve(response);
        } else if (!response.data.success) {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
