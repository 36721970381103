import { connect } from "react-redux";
import React, { useEffect, useMemo, useRef, useState } from "react";
import i18n from "../../../i18n";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import PrimaryButton from "../../Core/PrimaryButton";
import {
  removeGradeRequest,
  addGradeRequest,
} from "../../../redux/actions/Saloon/Class";
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import { InputAdornment } from "@material-ui/core";
import BasicTable from "../../Core/BasicTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import BaseModal from "../../BaseModal";

function ClassUpsert({
  _class,
  onSave,
  active = {},
  setActive,
  addGradeRequest,
  accessToken,
  removeGradeRequest,
  updateClass,
}) {
  const form = useRef();
  const [data, setData] = useState({});
  const [addModalOpen, setAddModalOpen] = useState(false);

  const onSaveGrade = () => {
    addGradeRequest(
      { access_token: accessToken, _id: _class._id, ...data },
      {
        success: (data) => {
          SuccessHelper.handleSuccess(i18n.t("successfully_added"), true);
          updateClass(data.data);
          setAddModalOpen(false);
        },
        failure: () => {
          ErrorHelper.handleErrors(i18n.t("failed_to_add"), true);
        },
      }
    );
  };

  const handleChange = (key, value) => {
    data[key] = value;
    setData({ ...data });
  };

  const handleRemove = (gradeId) => {
    Swal.fire({
      title: i18n.t("are_you_sure_delete"),
      text: i18n.t("cannmot_reverse"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ff3600",
      cancelButtonColor: "#354e68",
      confirmButtonText: i18n.t("yes"),
    }).then((result) => {
      if (result.value) {
        removeGradeRequest(
          {
            _id: _class._id,
            gradeId,
            access_token: accessToken,
          },
          {
            success: (data) => {
              SuccessHelper.handleSuccess(i18n.t("successfully_deleted"), true);
              updateClass(data.data);
            },
            failure: () => {
              ErrorHelper.handleErrors(i18n.t("failed_to_delete"), true);
            },
          }
        );
      }
    });
  };

  return (
    <div>
      <div className="row mx-0 justify-content-end mb-1">
        <PrimaryButton
          label={i18n.t("add")}
          onClick={() => {
            setAddModalOpen(true);
            setData({});
          }}
        />
      </div>
      <BasicTable
        rows={_class.grades}
        schema={[
          {
            header: i18n.t("name"),
            field: "name",
            accessor: (val) => val.name,
          },
          {
            header: i18n.t("value"),
            field: "value",
            accessor: (val) => val.value,
          },
          {
            key: "action",
            header: "",
            style: { width: 60 },
            render: (val) => {
              if (data.finalized) {
                return null;
              }
              return (
                <FontAwesomeIcon
                  className="view-icon"
                  style={{ backgroundColor: "red" }}
                  color="white"
                  icon={["fas", "trash"]}
                  onClick={() => handleRemove(val._id)}
                />
              );
            },
          },
        ]}
      />
      <BaseModal
        open={addModalOpen}
        onClose={() => {
          setAddModalOpen(false);
        }}
        containerClassName="half-container"
        title={i18n.t("add_grade")}
        content={
          <div>
            <ValidatorForm
              onSubmit={onSaveGrade}
              ref={form}
              className="w-100"
              onError={(error) => {
                if (!error.length) {
                  onSave(data);
                }
              }}
            >
              <TextValidator
                className="mb-1"
                label={i18n.t("name")}
                onChange={(e) => handleChange("name", e.target.value)}
                value={data.name || ""}
                validators={["required"]}
                errorMessages={[i18n.t("name_is_required")]}
                InputProps={{
                  readOnly: data.finalized,
                }}
              />
              <TextValidator
                className="mb-1"
                type="number"
                label={i18n.t("value")}
                onChange={(e) => handleChange("value", e.target.value)}
                onBlur={(text) => {
                  let num = parseFloat(text.target.value);
                  if (isNaN(num) || num < 0) {
                    num = 0;
                  }
                  num = Math.round((num + Number.EPSILON) * 100) / 100;
                  handleChange("value", num);
                }}
                value={data.value || ""}
                type="number"
                validators={["required", "minNumber:1"]}
                errorMessages={[
                  i18n.t("value_is_required"),
                  i18n.t("must_be_positive"),
                ]}
              />
              <div className="row mx-0 px-0 justify-content-end mt-2 col-12">
                <PrimaryButton
                  props={{
                    type: "submit",
                  }}
                  label={data._id ? i18n.t("save") : i18n.t("create")}
                />
              </div>
            </ValidatorForm>
          </div>
        }
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  classes: state._class.data,
  loading: state._class.isFetching,
});

const action = {
  addGradeRequest,
  removeGradeRequest,
};

export default connect(mapStateToProps, action)(ClassUpsert);
