// @flow

import { COURSE_PLANNER } from "../ActionTypes";

export function getRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: COURSE_PLANNER.GET_SAGA,
  };
}

export function get(data) {
  return {
    data,
    type: COURSE_PLANNER.GET,
  };
}

export function removeRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: COURSE_PLANNER.DELETE_SAGA,
  };
}

export function remove(data) {
  return {
    data,
    type: COURSE_PLANNER.DELETE,
  };
}

export function upsertRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: COURSE_PLANNER.UPSERT_SAGA,
  };
}

export function upsert(data) {
  return {
    data,
    type: COURSE_PLANNER.UPSERT,
  };
}

export function setAttendanceRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: COURSE_PLANNER.SET_ATTENDANCE_SAGA,
  };
}

export function setAttendance(data) {
  return {
    data,
    type: COURSE_PLANNER.SET_ATTENDANCE,
  };
}

export function requestFailure() {
  return {
    type: COURSE_PLANNER.REQUEST_FAILURE,
  };
}
