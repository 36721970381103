import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import ExpenseTable from "../../../components/Saloon/Expense/ExpenseTable.component";
import ExpenseUpsert from "../../../components/Saloon/Expense/ExpenseUpsert.component";
import i18n from "../../../i18n";
import {
  getRequest,
  insertRequest,
  removeRequest,
  setActive,
} from "../../../redux/actions/Saloon/expense";
import { getRequest as getVendorRequest } from "../../../redux/actions/Saloon/vendor";

import { getCompanyTaxes } from "../../../config/simpleApiCalls";
import PrimaryButton from "../../../components/Core/PrimaryButton";
import { DotsLoader } from "../../../components";
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import { saloonGetAllService } from "../../../config/simpleApiCalls";

const Expense = ({
  getRequest,
  insertRequest,
  getVendorRequest,
  expenses = [],
  vendors = [],
  setActive = () => {},
  loading: propsLoading,
  active,
}) => {
  const [accessToken, setAccessToken] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [taxes, setTaxes] = useState(false);

  useEffect(() => {
    const saloonData = JSON.parse(sessionStorage.getItem("saloon"));
    const company = JSON.parse(sessionStorage.getItem("company"));
    setCompanyId(company._id);
    setAccessToken(saloonData.access_token);
    setLoading(true);
    Promise.all([
      new Promise((resolve) => {
        getRequest(
          { access_token: saloonData.access_token },
          {
            success: resolve,
          }
        );
      }),
      new Promise((resolve) => {
        getVendorRequest(
          { access_token: saloonData.access_token },
          {
            success: resolve,
          }
        );
      }),
      getCompanyTaxes(
        { companyId: saloonData.companyId },
        saloonData.access_token
      ).then(({ data }) => {
        setTaxes(data.data);
      }),
    ]).then(() => {
      setLoading(false);
    });
  }, []);
  const onClose = () => {
    setOpen(false);
  };
  const onSave = (data) => {
    setLoading(true);
    insertRequest(
      { access_token: accessToken, companyId, ...data },
      {
        success: (data) => {
          SuccessHelper.handleSuccess(
            i18n.t(data.id ? "successfully_updated" : "successfully_added"),
            true
          );
          setActive();
          setOpen(false);
          setLoading(false);
        },
        failure: () => {
          ErrorHelper.handleErrors(
            i18n.t(data.id ? "failed_to_update" : "failed_to_add"),
            true
          );
          setLoading(false);
        },
      }
    );
  };
  const onView = (data) => {
    data.taxes = data.taxes
      .map((setTax) => {
        return taxes.find((tax) => tax.name == setTax.type);
      })
      .filter((tax) => !!tax);
    setActive({ ...data, vendorId: data.vendorId._id });
    setReadOnly(true);
    setOpen(true);
  };
  return (
    <div className="content-container">
      <DotsLoader isloading={loading || propsLoading} />
      <div className="d-flex justify-content-between align-items-center">
        <h1 className="saloon-dashboard-heading pb-3">{i18n.t("expenses")}</h1>
        <PrimaryButton
          label={i18n.t("create")}
          onClick={() => {
            setActive();
            setOpen(true);
            setReadOnly(false);
          }}
        />
      </div>
      <ExpenseTable accessToken={accessToken} rows={expenses} onView={onView} />
      {open && (
        <ExpenseUpsert
          open={open}
          element={active}
          onClose={onClose}
          onSave={onSave}
          accessToken={accessToken}
          vendors={vendors}
          taxes={taxes}
          readOnly={readOnly}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  active: state.expense.active,
  expenses: state.expense.data,
  vendors: state.vendor.data,
  loading: state.packages.isFetching,
});

const action = {
  getRequest,
  insertRequest,
  removeRequest,
  setActive,
  getVendorRequest,
};

export default connect(mapStateToProps, action)(Expense);
