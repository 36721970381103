import { connect } from "react-redux";
import React, { Component } from "react";
import Pagination from "react-js-pagination";
import i18n from "../../../i18n";
import {
  deleteTax,
  upsertTax,
  getCompanyTaxes,
} from "../../../config/simpleApiCalls";
import { Images } from "../../../theme";
import { DotsLoader, Header, BookingNotes } from "../../../components";
import { Translation } from "react-i18next";
import NumberFormat from "react-number-format";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import { getNestedValue } from "../../../util/objectMethods";
import Avatar from "react-avatar";
import moment from "moment";

import "./styles.css";
import Sytles from "./sytles";

class AllTaxes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      access_token: "",
      user: null,
      isloading: false,
      showEditDelete: "",
      searchText: "",
      activePage: 1,
      taxesList: null,
      selectedEmployeeId: null,
      formErrors: {},
      bookingStauses: [
        { text: "Waiting", value: 1, styles: { color: "#aa43b5" } },
        { text: "Now Serving", value: 3, styles: { color: "#FFE478" } },
        { text: "Cancel", value: 2, styles: { color: "#FF7082" } },
        { text: "Done", value: 4, styles: { color: "#95DA47" } },
      ],
    };
  }

  componentDidMount = async () => {
    const saloonData = await JSON.parse(sessionStorage.getItem("saloon"));
    if (saloonData) {
      this.setState({
        access_token: saloonData.access_token,
        companyId: saloonData.companyId,
      });
      this.getCompanyTaxes();
    }
  };

  getCompanyTaxes = () => {
    const { access_token, companyId } = this.state;
    this.setState({ isloading: true });
    getCompanyTaxes({ companyId }, access_token)
      .then(({ data }) => {
        if (data.success) {
          this.setState({ taxesList: data.data });
        } else {
          this.setState({ taxesList: [] });
        }
      })
      .finally(() => {
        this.setState({ isloading: false });
      });
  };

  handleSaloonDeleteTax = (id, ind) => {
    const { access_token, taxesList } = this.state;
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ff3600",
      cancelButtonColor: "#354e68",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        this.setState({ isloading: true });
        deleteTax({ ids: id }, access_token)
          .then((res) => {
            if (res.data.success) {
              SuccessHelper.handleSuccess(
                "Your tax was successfully deleted.",
                true
              );
              taxesList.splice(ind, 1);
              this.setState({ isloading: false, taxesList });
            } else if (!res.data.success) {
              ErrorHelper.handleErrors(res.data.msg, true);
              this.setState({ isloading: false });
            }
          })
          .catch((error) => {
            this.setState({ isloading: false });
            console.log(error, "errorrrrrrrrrrrrrrrrrrr");
          });
      }
    });
  };

  checkValidation = () => {
    const { name, percentage } = this.state;

    if (!name) {
      this.setState({
        formErrors: {
          nameError: <Translation>{(t) => t("name_is_required")}</Translation>,
        },
      });
      setTimeout(() => {
        this.setState({
          formErrors: {
            nameError: "",
          },
        });
      }, 8000);
    } else if (!percentage) {
      this.setState({
        formErrors: {
          percentageError: (
            <Translation>{(t) => t("percentage_is_required")}</Translation>
          ),
        },
      });
      setTimeout(() => {
        this.setState({
          formErrors: {
            emailError: "",
          },
        });
      }, 6000);
    } else {
      this.handleSaloonUpdateTax();
    }
  };

  handleSaloonUpdateTax = () => {
    this.setState({ isloading: true });
    const {
      access_token,
      name,
      percentage,
      isActive,
      editId,
      index,
      taxesList,
    } = this.state;
    const payload = {
      name,
      percentage,
      isActive,
      _id: editId,
    };

    upsertTax(payload, access_token)
      .then((res) => {
        if (res.data.success) {
          taxesList[index] = payload;
          SuccessHelper.handleSuccess("Taxe successfully updated.", true);
          this.setState({
            isloading: false,
            showEditTaxCard: "",
            username: "",
            email: "",
            telephone: "",
            taxesList,
          });
          this.getCompanyTaxes();
        }
        if (!res.data.success) {
          ErrorHelper.handleErrors(res.data.msg, true);
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        ErrorHelper.handleErrors("An error occured", true);
        this.setState({ isloading: false });
        console.log(error, "errorrrrrrrrrrrrrrrrrrr");
      });
  };

  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber });
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  renderTableBody = () => {
    const { activePage, taxesList } = this.state;
    let previousPageNumber = activePage - 1;
    const list = taxesList;
    let startDay = null;
    let endDay = null;
    let currentWorkingHr = { checkIn: "", checkOut: "" };
    return (
      <tbody>
        {list &&
          list.map((val, ind) => {
            if (ind >= previousPageNumber * 10 && ind < activePage * 10) {
              return (
                <tr key={val._id} style={{ borderTop: "3px solid #F7F7F7" }}>
                  <td>
                    <div className="d-flex align-items-center">
                      {val && (
                        <span className="appointment-details-employee-name">
                          {val.name}
                        </span>
                      )}
                    </div>
                  </td>
                  <td className="Table_data ">
                    <div className="d-flex align-items-center mt-1">
                      {val && (
                        <span className="appointment-details-employee-name">
                          {val.percentage}%
                        </span>
                      )}
                    </div>
                  </td>
                  <td className="Table_data ">
                    <div className="d-flex align-items-center mt-1">
                      {val && (
                        <span className="appointment-details-employee-name">
                          {val.isActive ? "Yes" : "No"}
                        </span>
                      )}
                    </div>
                  </td>
                  <td style={{ width: "120px" }}>
                    <div className="row mx-0">
                      <img
                        onClick={() => this.openEditTaxCard(val, ind)}
                        className="category-table-action-icon"
                        src={Images.saloon_working_hour_edit}
                      />
                      <img
                        onClick={() => this.handleSaloonDeleteTax(val._id, ind)}
                        className="category-table-action-icon"
                        src={Images.saloon_working_hour_delete}
                      />
                    </div>
                  </td>
                </tr>
              );
            }
          })}
      </tbody>
    );
  };

  openEditTaxCard = (val, index) => {
    console.log(val);
    this.setState({
      showEditTaxCard: true,
      showBookingHistory: false,
      name: val.name,
      percentage: val.percentage,
      isActive: val.isActive,
      editId: val._id,
      index,
    });
  };

  closeEditTaxCard = () => {
    this.setState({
      showEditTaxCard: false,
      name: "",
      percentage: 0,
      isActive: true,
      editId: "",
      index: undefined,
    });
  };

  renderUserTable = () => {
    return (
      <div className="col-12 p-3">
        <div className="table-responsive sticky-header">
          <table className="table table-borderless appointment-details-table">
            <thead>
              <tr>
                <th scope="col">
                  <span className="appointment-details-th-text">
                    {i18n.t("name")}
                  </span>
                </th>
                <th scope="col">
                  <span className="appointment-details-th-text">
                    {i18n.t("percentage")}
                  </span>
                </th>
                <th scope="col">
                  <span className="appointment-details-th-text">
                    {i18n.t("isActive")}
                  </span>
                </th>
                <th scope="col" style={{ width: "120px" }}>
                  <span className="appointment-details-th-text">Actions</span>
                </th>
              </tr>
            </thead>
            {this.renderTableBody()}
          </table>
        </div>
      </div>
    );
  };

  renderPagination = () => {
    const { taxesList = [] } = this.state;
    return (
      <div className="col-12 py-2 mb-3 d-flex justify-content-center">
        <Pagination
          hideNavigation
          activePage={this.state.activePage}
          itemsCountPerPage={10}
          totalItemsCount={taxesList ? taxesList.length : 0}
          // pageRangeDisplayed={3}
          onChange={(val) => this.handlePageChange(val)}
          firstPageText={
            <img src={Images.pagination_back} style={{ width: 15 }} />
          }
          lastPageText={
            <img src={Images.pagination_forward} style={{ width: 15 }} />
          }
          itemClass="page-item"
          linkClass="page-link"
        />
      </div>
    );
  };
  renderName = () => {
    const { formErrors, name } = this.state;
    return (
      <div className="form-label-group col-12 col-md-6 col-lg-4">
        <div>
          <Translation>{(t) => t("name")}</Translation>
        </div>
        <input
          className="form-control px-4 px-md-4 px-lg-4"
          placeholder={i18n.t("name")}
          onChange={(text) => {
            this.setState({ name: text.target.value.toUpperCase() });
          }}
          value={name}
        />
        {formErrors.nameError ? (
          <small className="form-text text-danger ml-3">
            {formErrors.nameError}
          </small>
        ) : null}
      </div>
    );
  };
  renderPercentage = () => {
    const { formErrors, percentage } = this.state;
    return (
      <div className="form-label-group col-12 col-md-6 col-lg-4">
        <div>
          <Translation>{(t) => t("percentage")}</Translation>
        </div>
        <input
          type="number"
          className="form-control px-4 px-md-4 px-lg-4"
          placeholder={i18n.t("percentage")}
          onChange={(text) => {
            this.setState({ percentage: text.target.value });
          }}
          onBlur={() => {
            const newPercentage = parseFloat(percentage);
            if (newPercentage < 0) {
              this.setState({ percentage: 0 });
            } else {
              this.setState({ percentage: newPercentage.toFixed(3) });
            }
          }}
          value={percentage}
        />
        {formErrors.percentageError ? (
          <small className="form-text text-danger ml-3">
            {formErrors.percentageError}
          </small>
        ) : null}
      </div>
    );
  };
  renderIsActive = () => {
    const { formErrors, isActive } = this.state;
    return (
      <div className="form-label-group col-12 col-md-6 col-lg-4">
        <div>
          <Translation>{(t) => t("isActive")}</Translation>
        </div>
        <select
          id="function-role"
          className="form-control"
          value={isActive}
          onChange={(e) => this.setState({ isActive: e.target.value })}
        >
          <option className="form-group" value={true} id={"Yes"}>
            Yes
          </option>
          <option className="form-group" value={false} id={"No"}>
            No
          </option>
        </select>
      </div>
    );
  };

  renderAddTaxForm = () => {
    const { readOnly } = this.state;
    return (
      <div className="col-12 p-3">
        <div className="col-12 p-3">
          <div class="row mb-3">
            {this.renderName()}
            {this.renderPercentage()}
            {this.renderIsActive()}
          </div>
          <div className="col-12 text-right">
            <button
              onClick={() => this.checkValidation()}
              type="button"
              className="btn btn-light btn-sm mb-2"
              id="saloon-edit-category-btn"
            >
              {i18n.t("update_tax")}
            </button>
          </div>
        </div>
      </div>
    );
  };

  renderEditTaxCard = () => {
    const {
      showEditTaxCard,
      serviceNameError,
      priceError,
      minimumTimeError,
      isGym,
      showBookingHistory,
      username,
    } = this.state;
    return (
      <div
        className="edit-service-card-wrapper"
        style={{ display: showEditTaxCard ? "flex" : "none" }}
      >
        <div className={`container bg-white`} style={{ borderRadius: "1rem" }}>
          <div className="py-2 px-2 row mx-0 justify-content-between">
            <span className="saloon-dashboard-heading">
              {i18n.t("container.taxes")}
            </span>
            <span
              onClick={() => this.closeEditTaxCard()}
              className="h4 cursor-pointer"
            >
              &times;
            </span>
          </div>
          {this.renderAddTaxForm()}
        </div>
      </div>
    );
  };

  renderSearchBar = () => {
    const { searchText } = this.state;
    return (
      <>
        <div className="col-12 col-lg-4 col-md-4 mt-4">
          <span>
            <span className="saloon-dashboard-heading">
              {i18n.t("container.taxes")}
            </span>
          </span>
        </div>
      </>
    );
  };

  render() {
    return (
      <div>
        {this.renderLoader()}
        <div className="content-container ">
          <div className="row">
            {this.renderSearchBar()}
            {this.renderUserTable()}
            {this.renderPagination()}
            {this.renderEditTaxCard()}
          </div>
          <div className="col-12 text-right">
            <button
              onClick={() => this.props.history.push("saloon-add-tax")}
              type="button"
              className="btn btn-light btn-sm mb-2 mr-1"
              id="saloon-save-social-link"
            >
              <Translation>{(t) => t("add_tax")}</Translation>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(AllTaxes);
