import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import i18n from "../../../i18n";
import BaseModal from "../../BaseModal";
import TextField from "@material-ui/core/TextField";
import DollarInput from "../../DollarInput";
import PercentInput from "../../PercentInput";
import { Button } from "@material-ui/core";
import { SuccessHelper } from "../../../helpers";

import { createCustomerTransaction } from "../../../config/simpleApiCalls";
const roundTo2Decimals = (x) => Math.round((x + Number.EPSILON) * 100) / 100;
const InsertPayment = ({
  open,
  handleClose,
  onSuccess,
  accountBalance,
  access_token,
  userId,
}) => {
  const [creditDebit, setCreditDebit] = useState(0);
  const [discountPercent, setDiscountPercent] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [accountBalanceToCollect, setAccountBalanceToCollect] = useState(0);

  const [cash, setCash] = useState(0);
  const [note, setNote] = useState("");
  const [formErrors, setFormErrors] = useState("");
  const difference = roundTo2Decimals(
    parseFloat(accountBalanceToCollect) -
      parseFloat(cash) -
      parseFloat(creditDebit) -
      discountAmount
  );
  const validate = () => {
    let hasErrors = false;
    let newFormErrors = {};
    if (difference > 0) {
      newFormErrors.money = i18n.t("must_be_greater_than", {
        amount: accountBalanceToCollect,
      });
      hasErrors = true;
    }
    if (hasErrors) {
      setFormErrors(newFormErrors);
      setTimeout(() => {
        setFormErrors({});
      }, 6000);
    } else {
      createCustomerTransaction(
        {
          amount: accountBalanceToCollect,
          note,
          cash: parseFloat(cash),
          creditDebit: parseFloat(creditDebit),
          discount: discountAmount,
          userId,
        },
        access_token
      ).then(({ data }) => {
        onSuccess(data.data);
        if (difference < 0) {
          SuccessHelper.handleSuccess(
            i18n.t("change_due_prompt", { amount: difference }),
            true
          );
        } else {
          SuccessHelper.handleSuccess("", true);
        }
      });
    }
  };
  return (
    <BaseModal
      style={{ zIndex: 1249 }}
      open={open}
      containerClassName={"half-container"}
      {...{
        title: (
          <div>
            {i18n.t("amount_to_be_paid")} ($ {accountBalance?.toFixed(2)})
          </div>
        ),
        content: (
          <div className="transactions-details-insert-payment">
            <div className="row mx-0">
              {accountBalance ? (
                <>
                  <div className="mb-2 col-sm-6 col-xs-12 px-1">
                    <DollarInput
                      value={accountBalance}
                      label={i18n.t("account_balance")}
                      readOnly={true}
                    />
                  </div>
                  <div className="mb-2 col-sm-6 col-xs-12 px-1">
                    <DollarInput
                      value={accountBalanceToCollect}
                      onChange={setAccountBalanceToCollect}
                      label={i18n.t("to_collect")}
                      max={accountBalance}
                    />
                  </div>
                </>
              ) : null}
              <>
                <div className="mb-2 col-sm-6 col-xs-12 px-1">
                  <DollarInput
                    value={cash}
                    onChange={setCash}
                    label={i18n.t("cash")}
                    error={formErrors.money}
                  />
                </div>
                <div className="mb-2 col-sm-6 col-xs-12 px-1">
                  <DollarInput
                    value={creditDebit}
                    onChange={setCreditDebit}
                    label={i18n.t("credit_debit")}
                    error={formErrors.money}
                  />
                </div>
                <div className="mb-2 col-sm-6 col-xs-12 px-1">
                  <DollarInput
                    value={discountAmount}
                    onChange={(value) => {
                      setDiscountAmount(value);
                      setDiscountPercent(
                        roundTo2Decimals(
                          (100 * value) / accountBalanceToCollect
                        )
                      );
                    }}
                    label={i18n.t("discount_amount")}
                    error={formErrors.discount}
                  />
                </div>
                <div className="mb-2 col-sm-6 col-xs-12 px-1">
                  <PercentInput
                    value={discountPercent}
                    onChange={(percent) => {
                      setDiscountPercent(percent);
                      setDiscountAmount(
                        roundTo2Decimals(
                          (accountBalanceToCollect * percent) / 100
                        )
                      );
                    }}
                    label={i18n.t("discount_percent")}
                    error={formErrors.discount}
                  />
                </div>
              </>
              <>
                <div className="mb-2 col-sm-6 col-xs-12 px-1">
                  <DollarInput
                    value={roundTo2Decimals(
                      parseFloat(cash) + parseFloat(creditDebit)
                    )}
                    label={i18n.t("total")}
                    readOnly={true}
                  />
                </div>
                <div className="mb-2 col-sm-6 col-xs-12 px-1 balance-due">
                  <DollarInput
                    value={difference}
                    label={
                      difference < 0
                        ? i18n.t("change_due")
                        : i18n.t("balance_due")
                    }
                    readOnly={true}
                  />
                </div>
              </>

              <div className="mb-2 col-12 px-1">
                <TextField
                  label={i18n.t("note")}
                  onChange={(e) => setNote(e.target.value)}
                  helperText={formErrors.note}
                  error={Boolean(formErrors.note)}
                />
              </div>
            </div>
          </div>
        ),
        actions: (
          <div className="row mx-0 justify-content-end">
            <Button
              className="primary-button ml-1"
              onClick={() => {
                validate();
              }}
            >
              {i18n.t("apply")}
            </Button>
          </div>
        ),
      }}
      onClose={handleClose}
    />
  );
};

const mapStateToProps = (state) => ({
  transactions: state.bookingTransaction.data,
});

const action = {};

export default connect(mapStateToProps, action)(InsertPayment);
