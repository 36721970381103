// @flow
import Immutable from "seamless-immutable";
import * as types from "../../actions/ActionTypes";

const initialState = Immutable({
    failure: false,
    isFetching: false,
    errorMessage: "",
    data: {}
});

export default (state: Object = initialState, action: Object) => {
    switch (action.type) {
        case types.GET_SALOON_APPOINTMENT.REQUEST:
            return Immutable.merge(state, {
                isFetching: true
            });
        case types.GET_SALOON_APPOINTMENT.SUCCESS:
            console.log(state, "aaaaaaaaaaaaaaaaa")
            return Immutable.merge(state, {
                failure: false,
                isFetching: false,
                errorMessage: "",
                data: action.data
            });
        case types.GET_SALOON_APPOINTMENT.FAILURE:
            return Immutable.merge(state, {
                failure: true,
                isFetching: false,
                errorMessage: action.errorMessage
            });
        case types.SALOON_LOGOUT:
            return initialState;
        case types.CLEAR_LOGOUT:
            return initialState;
        default:
            return state;
    }
};
