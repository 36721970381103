import { connect } from "react-redux";
import React, { Component, useEffect, useRef, useState } from "react";
import i18n from "../../../i18n";
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import NumberFormat from "react-number-format";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import PrimaryButton from "../../Core/PrimaryButton";
import Tabs from "../../Core/Tabs/Tabs";
import BaseModal from "../../BaseModal";
import CustomerDetails from "./CustomerDetails";
import FilledFormTable from "../Form/FilledFormTable";
import BookingHistoryTable from "../BookingHistoryTable/BookingHistoryTable";

import { getRequest as getFilledFromsRequest } from "../../../redux/actions/FormAnswer";
import {
  getAllNotesByUser,
  getCustomerBookingHistory,
  upsertCustomer,
} from "../../../config/simpleApiCalls";
import BookingNotes from "../../BookingNotes";
import CustomerAccountTransactions from "./CustomerAccountTransactions";
import "./CustomerEdit.scss";
import DotsLoader from "../../DotsLoader";
function CustomerEdit({
  open,
  setOpen,
  active = {},
  getFilledFromsRequest,
  accessToken: access_token,
  isGym,
  onUpdate = () => {},
  setAccountBalance = () => {},
}) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [forms, setForms] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [notes, setNotes] = useState([]);
  const init = (data) => {
    if (data?.userId) {
      getFilledFromsRequest(
        { userId: data.userId, access_token },
        {
          success: (data) => {
            setForms(data.data);
          },
          failure: () => {
            ErrorHelper.handleErrors("An error occured", true);
          },
        }
      );
      getCustomerBookingHistory({ _id: data._id }, access_token).then(
        ({ data }) => {
          if (data.success) {
            setBookings(data.data.reverse());
          } else {
            ErrorHelper.handleErrors("An error occured", true);
          }
        }
      );
      getAllNotesByUser({ userId: data.userId }, access_token).then(
        ({ data }) => {
          if (data.success) {
            setNotes(data.data);
          } else {
            setNotes([]);
          }
        }
      );
    } else {
      setForms([]);
    }
    setData({ ...data });
  };
  useEffect(() => {
    init(active);
  }, []);
  useEffect(() => {
    if (active._id != data._id) {
      init(active);
    }
  }, [active]);
  const handleView = (index) => {
    const form = forms[index];
    window.open(`/form-fill/${form._id}`, "_blank");
  };

  const handleEdit = ({ name, email, phoneNo, _id }) => {
    const payload = {
      name,
      email,
      phoneNo,
      _id,
    };
    setLoading(true);
    return upsertCustomer(payload, access_token)
      .then((res) => {
        if (res.data.success) {
          SuccessHelper.handleSuccess("Customer successfully updated.", true);
          onUpdate();
        }
        if (!res.data.success) {
          ErrorHelper.handleErrors(res.data.msg, true);
        }
      })
      .catch((error) => {
        ErrorHelper.handleErrors("An error occured", true);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <BaseModal
      open={open}
      onClose={setOpen}
      title={i18n.t("container.customer")}
      content={
        open ? (
          <>
            <DotsLoader isloading={loading} />
            <Tabs
              tabs={[
                {
                  title: i18n.t("details"),
                  content: () => (
                    <CustomerDetails active={active} onSave={handleEdit} />
                  ),
                },
                {
                  title: i18n.t("forms"),
                  content: () => (
                    <FilledFormTable data={forms} handleView={handleView} />
                  ),
                },
                {
                  title: i18n.t("booking_history"),
                  content: () => (
                    <BookingHistoryTable
                      bookings={bookings}
                      isGym={isGym}
                      access_token={access_token}
                    />
                  ),
                },
                {
                  title: i18n.t("notes"),
                  content: () => (
                    <BookingNotes
                      notes={notes}
                      readonly={true}
                      hasEditPermission={true}
                    />
                  ),
                },
                {
                  title: (
                    <div className="row mx-0 align-items-center">
                      {i18n.t("account")}
                      {data?.accountBalance ? (
                        <div className="account-tab-header pl-3">{` ($ ${data.accountBalance.toFixed(
                          2
                        )})`}</div>
                      ) : (
                        ""
                      )}
                    </div>
                  ),
                  content: () => (
                    <CustomerAccountTransactions
                      active={data}
                      access_token={access_token}
                      accountBalance={data?.accountBalance}
                      setAccountBalance={(accountBalance) => {
                        setData({ ...data, accountBalance });
                        setAccountBalance(accountBalance);
                      }}
                    />
                  ),
                },
              ]}
            />
          </>
        ) : null
      }
    />
  );
}

const mapStateToProps = (state) => ({});

const action = { getFilledFromsRequest };

export default connect(mapStateToProps, action)(CustomerEdit);
