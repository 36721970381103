import React, { createRef, useState } from "react";
import i18n from '../../../i18n';
import './styles.scss'
import Button from "@material-ui/core/Button";
class AddExtra extends React.Component {
  constructor() {
    super();
    this.state = {}
    this.productIdRef = createRef();
    this.productQuantityRef = createRef();
    this.serviceIdRef = createRef();
    this.otherNameRef = createRef();
    this.otherPriceRef = createRef();
  }

  renderAdditional = () => {
    const { products, services, closaModal, onAdd } = this.props
    const { additionType } = this.state
    if(additionType == 'product') {
      return (
        <>
          <div className="form-label-group mb-1 col-12">
            <div className="mb-1">Product</div>
            <select
              id="function-role"
              ref={this.productIdRef}
              className="form-control saloon-form-input"
            >
              <option
                className="form-group"
                value={''}
              >
              {i18n.t('select_product')}
              </option>
              { products.map(product => {
                return (
                  <option
                    className="form-group"
                    value={JSON.stringify(
                        {
                            _id: product._id,
                            price: product.price,
                        }
                    )}
                  >
                    {product.name}
                  </option>
                )
              })}
            </select>
            {/* {formErrors.selectPaymentMethodError ? (
              <small className="form-text text-danger ml-3">
                {formErrors.selectPaymentMethodError}
              </small>
            ) : null} */}
          </div>
          <div className="form-label-group mb-1 col-12">
            <div className="mb-1">{i18n.t('quantity')}</div>
            <input
              type="nubmer"
              className="form-control"
              placeholder={i18n.t('quantity')}
              ref={this.productQuantityRef}
              defaultValue={1}
              min={1}
              step={1}
              // onChange= {(e) =>
              //   this.setState({ selectBookingAmount: e.target.value})
              // }
              onBlur={(e) => {
                const value = parseInt(e.target.value)
                if(value < 0 || isNaN(value)) {
                    this.productQuantityRef.current.value = 0
                } else {
                    this.productQuantityRef.current.value = value
                }
              }}
            />
            {/* {formErrors.selectPaymentMethodError ? (
              <small className="form-text text-danger ml-3">
                {formErrors.selectPaymentMethodError}
              </small>
            ) : null} */}
          </div>
        </>
      )
    } else if (additionType == 'service') {
      console.log(services)
      return (
        <>
          <div className="form-label-group mb-1 col-12">
            <div className="mb-1">{i18n.t('service')}</div>
            <select
              id="function-role"
              ref={this.serviceIdRef}
              className="form-control saloon-form-input"
            >
              <option
                className="form-group"
                value={''}
              >
              {i18n.t('select_service')}
              </option>
              { services.map(service => {
                return (
                  <option
                    className="form-group"
                    key={service._id}
                    value={JSON.stringify(
                        {
                            _id: service._id,
                            price: service.price,
                        }
                    )}
                  >
                    {service.name} (${service.price})
                  </option>
                )
              })}
            </select>
            {/* {formErrors.selectPaymentMethodError ? (
              <small className="form-text text-danger ml-3">
                {formErrors.selectPaymentMethodError}
              </small>
            ) : null} */}
          </div>
        </>
      )
    } else if (additionType == 'other') {
      return (
        <>
          <div className="form-label-group mb-1 col-12">
            <div className="mb-1">{i18n.t('name')}</div>
            <input
              type="nubmer"
              className="form-control"
              placeholder={i18n.t('name')}
              ref={this.otherNameRef}
            />
            {/* {formErrors.selectPaymentMethodError ? (
              <small className="form-text text-danger ml-3">
                {formErrors.selectPaymentMethodError}
              </small>
            ) : null} */}
          </div>
          <div className="form-label-group mb-1 col-12">
            <div className="mb-1">{i18n.t('by_price')}</div>
            <input
              type="nubmer"
              className="form-control"
              placeholder={i18n.t('by_price')}
              ref={this.otherPriceRef}
              defaultValue={0}
              min={0}
              onBlur={(e) => {
                const value = parseFloat(e.target.value)
                if(value < 0 || isNaN(value)) {
                    this.otherPriceRef.current.value = 0
                } else {
                    this.otherPriceRef.current.value = value.toFixed(2)
                }
              }}
            />
            {/* {formErrors.selectPaymentMethodError ? (
              <small className="form-text text-danger ml-3">
                {formErrors.selectPaymentMethodError}
              </small>
            ) : null} */}
          </div>
        </>
      )
    } else {
      return null
    }
  }

  onAdd = () => {
    const { onAdd } = this.props
    const { additionType } = this.state
    let values = {}
    if(additionType == 'product') {
        const product = JSON.parse(this.productIdRef.current.value)
        values = {
            productId: product._id,
            price: product.price,
            quantity: this.productQuantityRef.current.value
        }
    }else if (additionType == 'service') {
        const service = JSON.parse(this.serviceIdRef.current.value)
        values = {
            serviceId: service._id,
            price: service.price
        }
    } else if (additionType == 'other') {
        values = {
            name: this.otherNameRef.current.value,
            price: this.otherPriceRef.current.value
        }
    }
    onAdd(additionType, values)
  }

  render() {
    const { closaModal, onAdd } = this.props
    const { additionType } = this.state
    return (
      <>
        {additionType ? (
          <>
            <div className="row mx-0 justify-content-between">
              {this.renderAdditional()}
            </div>
          </>
        ) : (
          <div className="row mx-0 justify-content-between">
              <Button
                onClick={() => {
                  this.setState({ additionType: "product" });
                }}
                style={{
                  backgroundColor: "#ff3600",
                  color: "white",
                }}
              >
                {i18n.t("product")}
              </Button>
              <Button
                onClick={() => {
                  this.setState({ additionType: "service" });
                }}
                style={{
                  backgroundColor: "#ff3600",
                  color: "white",
                }}
              >
                {i18n.t("service")}
              </Button>
              <Button
                onClick={() => {
                  this.setState({ additionType: "other" });
                }}
                style={{
                  backgroundColor: "#ff3600",
                  color: "white",
                }}
              >
                {i18n.t("other")}
              </Button>
          </div>
        )}
        <div className="mt-1 col-12 text-right px-0">
          <Button
            onClick={() => {
              additionType
                ? this.setState({ additionType: null })
                : closaModal();
            }}
            style={{
              backgroundColor: "#e0e0e0",
              color: "#000",
              marginRight: 8
            }}
          >
            {i18n.t("cancel")}
          </Button>
          {additionType && (
            <Button
              onClick={this.onAdd}
              style={{
                backgroundColor: "#ff3600",
                color: "white",
              }}
            >
              {i18n.t("add")}
            </Button>
          )}
        </div>
      </>
    );
  }
}

export default AddExtra;
