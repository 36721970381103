import React, { Component } from "react";
import PropTypes from "prop-types";
// material UI components
import {
  Drawer as MUIDrawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core/";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Divider from "@material-ui/core/Divider";
import BusinessIcon from "@material-ui/icons/Business";
import PeopleIcon from "@material-ui/icons/People";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import ReceiptIcon from "@material-ui/icons/Receipt";
import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";
import AccessibilityIcon from "@material-ui/icons/Accessibility";
import ChatIcon from "@material-ui/icons/Chat";
import "../styles/appTemplate.sass";

import { Images } from "../../../theme";

// variables
const menuItems = [
  {
    menuTitle: "Profile",
    icon: <AccountCircleIcon />,
    display: "displayProfile",
  },
  {
    menuTitle: "My Business",
    icon: <BusinessIcon />,
    display: "displayProfile",
  },
  {
    menuTitle: "Staff",
    icon: <PeopleIcon />,
    display: "displayProfile",
  },
  {
    menuTitle: "Programs/Class",
    icon: <MeetingRoomIcon />,
    display: "displayProfile",
  },
  {
    menuTitle: "payment setting",
    icon: <ReceiptIcon />,
    display: "displayProfile",
  },
];

const paretnMenu = [
  {
    menuTitle: "contact Info",
    icon: <PermContactCalendarIcon />,
    display: "displayProfile",
  },
  {
    menuTitle: "kids",
    icon: <AccessibilityIcon />,
    display: "displayProfile",
  },
  {
    menuTitle: "billing",
    icon: <ReceiptIcon />,
    display: "displayProfile",
  },
  {
    menuTitle: "messaging",
    icon: <ChatIcon />,
    display: "displayProfile",
  },
];

export default class AppTemplate extends Component {
  static propTypes = {
    // prop: PropTypes
  };
  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  render() {
    return (
      <div className="menuTemplate">
        <MUIDrawer className="mainMenu" variant="permanent" anchor="left">
          <img
            alt="easy1 logo 800x300"
            src={Images.easy1_logo_800x300}
            className="cursor-pointer mainLogo"
            onClick={() => this.handleNavigation("/")}
          />
          <Divider />

          <List className="drawerList">
            {menuItems.map((text, index) => {
              const { menuTitle, icon, display } = text;

              return (
                <ListItem
                  // className={selectedMenu === display ? "drawerListItems selected":"drawerListItems" }
                  button
                  key={`menuTitle-${menuTitle}`}
                  // onClick={() => changeDisplay(display)}
                >
                  <ListItemIcon key={`listIcon-${menuTitle}`}>
                    {icon}
                  </ListItemIcon>
                  <ListItemText
                    key={`listTitle-${menuTitle}`}
                    primary={menuTitle}
                  />
                </ListItem>
              );
            })}
          </List>
          <Divider />
          <List className="drawerList">
            {paretnMenu.map((text, index) => {
              const { menuTitle, icon, display } = text;

              return (
                <ListItem
                  // className={selectedMenu === display ? "drawerListItems selected":"drawerListItems" }
                  button
                  key={`menuTitle-${menuTitle}`}
                  // onClick={() => changeDisplay(display)}
                >
                  <ListItemIcon key={`listIcon-${menuTitle}`}>
                    {icon}
                  </ListItemIcon>
                  <ListItemText
                    key={`listTitle-${menuTitle}`}
                    primary={menuTitle}
                  />
                </ListItem>
              );
            })}
          </List>
        </MUIDrawer>
        <div className="mainHeaderComponent">
          <ul>
            <li>Login</li>
          </ul>
        </div>
      </div>
    );
  }
}
