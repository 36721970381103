import React from "react";
import DotsLoader from "../DotsLoader";
import { connect } from "react-redux";
import {
  getRequest,
  addTransactionRequest,
} from "../../redux/actions/Packages";
import PrimaryButton from "../Core/PrimaryButton";
import i18n from "../../i18n";
import { useState } from "react";
import { ErrorHelper, SuccessHelper } from "../../helpers";
import InsertPayment from "./components/InsertPayment.component";
import { BaseModal } from "..";

const telephoneRegex = /[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]/g;

const CreatePackageInvoice = ({
  loading,
  open,
  setOpen,
  userPackage,
  accessToken,
  addTransactionRequest,
}) => {
  const [includeTaxes, setIncludeTaxes] = useState(true);

  const onSave = ({ note, cash, creditDebit, tip, discount }) => {
    addTransactionRequest(
      {
        access_token: accessToken,
        _id: userPackage._id,
        newTransaction: { note, cash, creditDebit, tip, discount },
        includeTaxes,
      },
      {
        success: () => {
          setOpen();
          SuccessHelper.handleSuccess(i18n.t("successfully_added"), true);
        },
        failure: () => {
          ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
        },
      }
    );
  };
  const items = [
    {
      name: userPackage.packageId.name,
      price: userPackage.price,
    },
  ];
  const { price = 0 } = userPackage;
  const taxDue = userPackage.taxes.reduce(
    (accum, tax) => accum + tax.amount,
    0
  );
  const collected = userPackage.transactions.reduce((accum, e) => {
    if (!e.wasReversed) {
      return accum + e.amount + e.discount - e.tip;
    }
    return accum;
  }, 0);
  const grandTotal = (includeTaxes ? taxDue : 0) + price - collected;
  return (
    <div className="insert-payment">
      <InsertPayment
        open={open}
        handleClose={setOpen}
        defaultAmount={grandTotal}
        onSubmit={onSave}
        toggleTaxes={() => setIncludeTaxes(!includeTaxes)}
        items={items}
        subtotal={userPackage.price}
        taxDue={taxDue}
        includeTaxes={includeTaxes}
        grandTotal={grandTotal}
        collected={collected}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.packages.isFetching,
});

const action = { addTransactionRequest, getRequest };

export default connect(mapStateToProps, action)(CreatePackageInvoice);
