// @flow

import { GET_HOME_PAGE_INFO, LOGOUT } from "./ActionTypes";

export function request(payload) {
    return {
        payload,
        type: GET_HOME_PAGE_INFO.REQUEST
    };
}

export function success(data: Object) {
    return {
        data,
        type: GET_HOME_PAGE_INFO.SUCCESS
    };
}

export function failure(errorMessage: Object) {
    return {
        errorMessage,
        type: GET_HOME_PAGE_INFO.FAILURE
    };
}

export function logout() {
    return {
        type: LOGOUT
    };
}
