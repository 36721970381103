import { connect } from "react-redux";
import React, { Component, useEffect, useRef, useState } from "react";
import i18n from "../../../i18n";
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import NumberFormat from "react-number-format";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import PrimaryButton from "../../Core/PrimaryButton";

export default function CustomerDetails({ onSave, active = {} }) {
  const form = useRef();
  const [data, setData] = useState({});
  const init = (data) => {
    setData({ ...data });
  };
  const handleSubmit = () => {
    onSave(data).then(() => {
      if (!data._id) {
        setData({});
      }
    });
  };
  const handleChange = (key, value) => {
    data[key] = value;
    setData({ ...data });
  };
  useEffect(() => {
    init(active);
  }, []);
  useEffect(() => {
    if (active._id != data._id) {
      init(active);
    }
  }, [active]);
  return (
    <ValidatorForm
      onSubmit={handleSubmit}
      ref={form}
      className="w-100 row mx-0"
      onError={(error) => {
        if (!error.length) {
          onSave(data);
        }
      }}
    >
      <TextValidator
        className="mb-1"
        containerProps={{ className: "col-12" }}
        label={i18n.t("name")}
        onChange={(e) => handleChange("name", e.target.value)}
        value={data.name || ""}
        validators={["required"]}
        errorMessages={[i18n.t("name_is_required")]}
      />
      <TextValidator
        className="mb-1"
        containerProps={{ className: "col-12" }}
        label={i18n.t("container.profile_email")}
        onChange={(e) => handleChange("email", e.target.value)}
        value={data.email || ""}
        validators={["isEmail"]}
        errorMessages={[i18n.t("invalid_email")]}
      />
      <TextValidator
        className="mb-1"
        containerProps={{ className: "col-12" }}
        value={data.phoneNo || ""}
        type="number"
        onChange={(e) => handleChange("phoneNo", e.target.value)}
        label={i18n.t("phoneNo")}
        validators={[
          "required",
          "matchRegexp:^[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]$",
        ]}
        errorMessages={[
          i18n.t("phone_is_required"),
          i18n.t("invalid_phone_number"),
        ]}
      />
      <div className="row mx-0 px-0 justify-content-end mt-2 col-12">
        <PrimaryButton
          props={{
            type: "submit",
          }}
          label={data._id ? i18n.t("save") : i18n.t("create")}
        />
      </div>
    </ValidatorForm>
  );
}
