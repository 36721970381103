import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import i18n from "../../../i18n";
import DotsLoader from "../../DotsLoader";
import Swal from "sweetalert2";
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import { removeRequest } from "../../../redux/actions/Saloon/Program";
import BasicTable from "../../Core/BasicTable";
function CourseTable({
  accessToken,
  rows,
  onEdit,
  allServices,
  removeRequest,
}) {
  const [companyId, setCompanyId] = useState("");
  const [loading, setLoading] = useState(false);
  const handleDelete = (_id) => {
    Swal.fire({
      title: i18n.t("are_you_sure_delete"),
      text: i18n.t("cannmot_reverse"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ff3600",
      cancelButtonColor: "#354e68",
      confirmButtonText: i18n.t("yes"),
    }).then((result) => {
      if (result.value) {
        setLoading(true);
        removeRequest(
          { access_token: accessToken, ids: _id },
          {
            success: () => {
              setLoading(false);
              SuccessHelper.handleSuccess(
                "Your service is successfully deleted.",
                true
              );
            },
            failure: () => {
              setLoading(false);
              ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
            },
          }
        );
      }
    });
  };
  return (
    <>
      <DotsLoader isloading={loading} />
      <BasicTable
        rows={rows}
        schema={[
          {
            header: i18n.t("name"),
            field: "name",
          },
          {
            header: i18n.t("total_hours"),
            field: "totalHours",
            accessor: ({ totalHours }) => {
              return totalHours;
            },
          },
          {
            header: i18n.t("price"),
            field: "phoneNo",
            accessor: ({ price }) => {
              if (!price) {
                return "";
              }
              return `$${price}`;
            },
          },
          {
            key: "action",
            header: i18n.t("actions"),
            style: { width: 120 },
            render: (val) => {
              return (
                <>
                  <FontAwesomeIcon
                    className="view-icon"
                    color="white"
                    icon={["fas", "eye"]}
                    onClick={() => onEdit(val)}
                  />
                  <FontAwesomeIcon
                    className="view-icon"
                    style={{ backgroundColor: "red" }}
                    color="white"
                    icon={["fas", "trash"]}
                    onClick={() => handleDelete(val._id)}
                  />
                </>
              );
            },
          },
        ]}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  rows: state.program.data,
});

const action = {
  removeRequest,
};

export default connect(mapStateToProps, action)(CourseTable);
