import { connect } from "react-redux";
import React, { Component } from "react";
import i18n from "../../i18n";
import { Translation, withTranslation } from "react-i18next";
import firebase from "firebase";
import { request as login_user } from "../../redux/actions/Login";
import { DotsLoader, Navbar, Footer } from "../../components";
import { SuccessHelper, ErrorHelper } from "../../helpers";
import { Images } from "../../theme/index";
import { googleLogin } from "../../config/simpleApiCalls";
import { set } from "../../redux/actions/User";
import TextField from "@material-ui/core/TextField";

import "./styles.css";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      handle: "",
      messaging_token: "",
      isloading: false,
      provider: "email",
      formErrors: {
        emailError: "",
        passwordError: "",
        gmailuid: "",
        gmailuid: "",
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.login) {
      if (
        !nextProps.login.failure &&
        !nextProps.login.isFetching &&
        !nextProps.login.errorMessage &&
        nextProps.login.data &&
        nextProps.login.data.data &&
        nextProps.login.data.data.access_token
      ) {
        if (nextProps.login.data.data.role == "5") {
          this.props.set({
            user: JSON.stringify(nextProps.login.data.data),
            access_token: nextProps.login.data.data.access_token,
          });
          SuccessHelper.handleSuccess("You have successfully logged in.", true);
          this.props.history.push("/");
          this.setState({ isloading: false, handle: "", password: "" });
        } else {
          ErrorHelper.handleErrors("Access Denied", true);
          this.setState({ isloading: false, handle: "", password: "" });
        }
      } else if (!nextProps.login.failure && !nextProps.login.isFetching) {
        this.setState({ isloading: false });
        ErrorHelper.handleErrors(nextProps.login.data.msg, true);
      }
    }
  }

  componentDidMount() {
    const user = JSON.parse(sessionStorage.getItem("user"));
    const messaging_token = sessionStorage.getItem("messaging_token");
    const platform_name = window.navigator.platform;
    this.setState({ messaging_token, platform_name });
    if (user && user.access_token) {
      this.handleNavigation("/");
    }
  }

  onChangeUserName = (text) => this.setState({ username: text.target.value });
  onChangeHandle = (text) => this.setState({ handle: text.target.value });
  onChangePassword = (text) => this.setState({ password: text.target.value });

  checkValidation() {
    const { username, password, handle, provider } = this.state;
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const telephoneRegex =
      /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/g;
    if (!handle) {
      this.setState({
        formErrors: {
          emailError: <Translation>{(t) => t("handle_required")}</Translation>,
        },
      });
      setTimeout(() => {
        this.setState({
          formErrors: {
            emailError: "",
          },
        });
      }, 8000);
    } else if (!handle.match(emailRegex) && !handle.match(telephoneRegex)) {
      this.setState({
        formErrors: {
          emailError: (
            <Translation>{(t) => t("invalid_login_handle")}</Translation>
          ),
        },
      });
      setTimeout(() => {
        this.setState({
          formErrors: {
            emailError: "",
          },
        });
      }, 6000);
    } else if (provider != "google" && !password) {
      this.setState({
        formErrors: {
          passwordError: (
            <Translation>{(t) => t("password_required")}</Translation>
          ),
        },
      });
      setTimeout(() => {
        this.setState({
          formErrors: {
            passwordError: "",
          },
        });
      }, 6000);
    } else if (provider != "google" && password.length < 6) {
      this.setState({
        formErrors: {
          passwordError: (
            <Translation>{(t) => t("password_min_length")}</Translation>
          ),
        },
      });
      setTimeout(() => {
        this.setState({
          formErrors: {
            passwordError: "",
          },
        });
      }, 6000);
    } else {
      this.handleLogin();
    }
  }

  handleLogin = () => {
    this.setState({ isloading: true });
    const {
      username,
      email,
      postalCode,
      handle,
      password,
      telephone,
      provider,
      gmailuid,
      messaging_token,
      platform_name,
    } = this.state;
    const payload = {
      handle,
      gcm_id: messaging_token ? messaging_token : "123",
      platform: platform_name,
    };
    if (provider === "google") {
      googleLogin({
        ...payload,
        provider,
        idToken: gmailuid,
        userName: username,
        role: "5",
      })
        .then((res) => {
          this.setState({
            isloading: false,
            provider: "",
            username: "",
            postalCode: "",
            handle: "",
            telephone: "",
          });
          const updatedUser = JSON.stringify({ ...res.data.data });
          this.props.set({
            user: updatedUser,
          });
          SuccessHelper.handleSuccess("Successfully Registered", true);
          this.handleNavigation("/");
        })
        .catch((err) => {
          ErrorHelper.handleErrors(err.message, true);
        });
    } else if (provider === "facebook") {
      googleLogin({
        ...payload,
        provider,
        idToken: gmailuid,
        userName: username,
        role: "5",
      })
        .then((res) => {
          this.setState({
            isloading: false,
            provider: "",
            username: "",
            postalCode: "",
            handle: "",
            telephone: "",
          });
          const updatedUser = JSON.stringify({ ...res.data.data });
          this.props.set({
            user: updatedUser,
          });
          SuccessHelper.handleSuccess("Successfully Registered", true);
          this.handleNavigation("/");
        })
        .catch((err) => {
          ErrorHelper.handleErrors(err.message, true);
        });
    } else {
      this.props.login_user({ ...payload, password: password });
    }
  };

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  signInFacebook = () => {
    var provider = new firebase.auth.FacebookAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((res) => {
        var data = res.user;
        this.setState({
          username: data.displayName,
          email: data.email,
          gmailuid: data.uid,
        });
        if (data.phoneNumber) {
          this.setState({ telephone: data.phoneNumber });
        }
        if (data.providerData[0].providerId == "facebook.com") {
          this.setState({ provider: "facebook" });
        }
        this.handleLogin();
      })
      .catch((error) => {
        ErrorHelper.handleErrors(error.message, true);
      });
  };

  signInWithGoogle = () => {
    const auth = firebase.auth();
    const googleProvider = new firebase.auth.GoogleAuthProvider();
    auth
      .signInWithPopup(googleProvider)
      .then((res) => {
        const data = res.user;
        this.setState({
          username: data.displayName,
          email: data.email,
          gmailuid: data.uid,
        });
        if (data.phoneNumber) {
          this.setState({ telephone: data.phoneNumber });
        }
        if (data.providerData[0].providerId == "google.com") {
          this.setState({ provider: "google" });
        }
        this.handleLogin();
      })
      .catch((error) => {
        ErrorHelper.handleErrors(error.message, true);
      });
  };

  renderSocialbutton = () => {
    return (
      <>
        <div
          className="row px-3"
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <span className=" mb-2 google-btn">
            <img className="ml-0 google-btn-img" src={Images.google_signin} />
            <span className="google-btn-text " onClick={this.signInWithGoogle}>
              <Translation>{(t) => t("login_with_google")}</Translation>
            </span>
          </span>
        </div>
        <div
          className="row px-3"
          style={{ justifyContent: "center", alignItems: "center" }}
        >
          <span className=" mb-2 facebook-btn">
            <img className="facebook-btn-img" src={Images.facebook_signin} />
            <span className="facebook-btn-text" onClick={this.signInFacebook}>
              <Translation>{(t) => t("login_with_facebook")}</Translation>
            </span>
          </span>
        </div>
        <div
          className="row px-3"
          style={{ justifyContent: "center", alignItems: "center" }}
        >
          <span className="  py-1 py-md-2 py-lg-2  email-btn">
            <img className="email-btn-img" src={Images.mail_signin} />
            <span className="email-btn-text">
              <Translation>{(t) => t("login_with_email")}</Translation>
            </span>
          </span>
        </div>
      </>
    );
  };

  renderEmail = () => {
    const { formErrors } = this.state;
    return (
      <div className="form-label-group mb-4 ">
        {/* <label for="inputemail">
          <Translation>{(t) => t("container.handle")}</Translation>
        </label>
        <input
          type="text"
          id="inputemail"
          className="form-control mt-1"
          placeholder={i18n.t("container.handle")}
          onChange={(text) => this.onChangeHandle(text)}
          value={this.state.handle}
        /> */}
        <TextField
          id="inputemail"
          label={<Translation>{(t) => t("container.handle")}</Translation>}
          className="form-control mt-1"
          onChange={(text) => this.onChangeHandle(text)}
          value={this.state.handle}
          type="text"
        />
        {formErrors.emailError ? (
          <small className="form-text text-danger ml-3">
            {formErrors.emailError}
          </small>
        ) : null}
      </div>
    );
  };

  renderPassword = () => {
    const { formErrors } = this.state;
    return (
      <div className="form-label-group">
        {/* <label for="inputPassword ">
          <Translation>{(t) => t("password")}</Translation>
        </label> */}
        {/* <input
          type="password"
          id="inputPassword"
          className="form-control mt-1"
          placeholder="********"
          onChange={(text) => this.onChangePassword(text)}
          value={this.state.password}
        /> */}
        <TextField
          type="password"
          id="inputPassword"
          className="form-control mt-1"
          // placeholder="********"
          onChange={(text) => this.onChangePassword(text)}
          value={this.state.password}
          label="password"
        />

        {formErrors.passwordError ? (
          <small className="form-text text-danger ml-3">
            {formErrors.passwordError}
          </small>
        ) : null}
      </div>
    );
  };

  renderForgetPswBtn = () => {
    return (
      <div className="form-signin  px-8">
        <span
          style={{
            color: "#ff3600",
            fontWeight: "700",
            cursor: "pointer",
            marginBottom: 20,
          }}
          onClick={() => this.handleNavigation("/forgot-password")}
        >
          <Translation>{(t) => t("forgot_password")}</Translation>
        </span>
      </div>
    );
  };

  renderLoginBtn = () => {
    return (
      <button
        className="login-btn btn btn-lg"
        type="submit"
        onClick={() => this.checkValidation()}
      >
        <Translation>{(t) => t("login_now")}</Translation>
      </button>
    );
  };

  renderLoginForm = () => {
    return (
      <form action="javascript:void(0)" className="form-signin  px-4">
        {this.renderEmail()}
        {this.renderPassword()}
        {this.renderForgetPswBtn()}
        {this.renderLoginBtn()}
        <div
          style={{
            fontSize: "1rem",
            color: "#3b3b3b",
            marginTop: "1rem",
          }}
        >
          {i18n.t("login_no_account")} &nbsp;
          <span
            style={{
              color: "#ff3600",
              fontWeight: "700",
              cursor: "pointer",
              marginleft: "10px",
            }}
            onClick={() => this.handleNavigation("/register")}
          >
            {i18n.t("sign_up")}
          </span>
        </div>
      </form>
    );
  };

  renderLoginCard = () => {
    return (
      <div className="card card-signin my-5">
        <div className="card-body px-0">
          <h5 className="card-title text-center">
            <Translation>{(t) => t("sign_in")}</Translation>
          </h5>
          {this.renderSocialbutton()}
          <div className="row">
            <hr className="my-4 mx-3 " style={{ width: "100%" }} />
          </div>
          {this.renderLoginForm()}
        </div>
      </div>
    );
  };

  render() {
    return (
      <>
        <div className="container-fluid">
          <div className="container">
            {this.renderLoader()}
            <Navbar {...this.props} isBackgroundColor={true} />
          </div>
          <div className="container" style={{ backgroundColor: "#F8FDFF" }}>
            <div className="row mt-5">
              <div className="col-sm-9 col-md-7 col-lg-5 mx-auto mt-5">
                {this.renderLoginCard()}
              </div>
            </div>
          </div>
        </div>
        <Footer {...this.props} />
      </>
    );
  }
}

const mapStateToProps = (state) => ({ login: state.login });

const action = { login_user, set };

export default connect(mapStateToProps, action)(Login);
