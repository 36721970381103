import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";

const PhoneNumberFormat = ({
  phoneUpdate,
  val = "",
  errorMessage,
  helperText,
}) => {
  const [formatedPhoneNumber, setFormatedPhoneNumber] = useState();

  const formatPhoneNumber = (input, format) => {
    // Strip non-numeric characters
    var digits = input.replace(/\D/g, "");

    // Replace each "X" with the next digit
    let count = 0;
    let newF = format.replace(/X/g, function () {
      return digits.charAt(count++);
    });
    return newF.replace("X", "");
  };

  const onChangeCompanyTelephone = (text) => {
    let phoneNumber = formatPhoneNumber(text.target.value, "XXX XXX XXXX");
    let res = phoneNumber.replaceAll(" ", "");
    phoneUpdate(res);
    setFormatedPhoneNumber(phoneNumber);
  };
  let originalNumber = formatPhoneNumber(val, "XXX XXX XXXX");
  console.log(errorMessage);
  return (
    <TextField
      label="Phone number"
      className="testss"
      onChange={(text) => onChangeCompanyTelephone(text)}
      value={!formatedPhoneNumber ? originalNumber : formatedPhoneNumber}
      // InputLabelProps={{ shrink: true }}

      error={!!errorMessage}
      helperText={errorMessage}

      // InputLabelProps={{
      //   shrink: true,
      // }}
      // type={inputType}
    />
  );
};

export default PhoneNumberFormat;
