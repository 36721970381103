import React, { useEffect } from "react";
import i18n from '../../i18n';
import { getNestedValue } from "../../util/objectMethods";

export default function DevicesAndEquipmentOrder({
  handleDeviceQuantityChange,
  equipement,
  selectDeviceQuantityMap,
}) {
  equipement = equipement.map(element => ({ price_id: element.prices[0].id, ...element}))
  return (
    
    <table class="table table-borderless table-layout-fixed">
      <thead>
        <tr>
          <th scope="col">
            {" "}
            <span className="tab-heading">{i18n.t('items')}</span>
          </th>
          <th scope="col">
            <span className="tab-heading">{i18n.t('by_price')}</span>
          </th>
          <th scope="col">
            <span className="tab-heading">Qty</span>
          </th>
        </tr>
      </thead>
      <tbody>
        {equipement.map((val, ind) => {
          const unitAmount = getNestedValue(val, ['prices', 0, 'unit_amount'], 0)
          const formattedUnitValue = unitAmount ? `$${(unitAmount/100).toFixed(2)}` : 0
          const deviceQuantity = getNestedValue(selectDeviceQuantityMap, [val.price_id], 0)
          return (
            <tr key={val.id}>
              <td>
                <span className="tab-text">{val.name}</span>
              </td>
              <td>
                <span className="tab-text">{formattedUnitValue}</span>
              </td>
              <td>  
                <span
                  className="qty-btn"
                  onClick={() =>
                    handleDeviceQuantityChange(val.price_id, deviceQuantity - 1)
                  }
                >
                  &minus;
                </span>                 
                <span className="mx-2 tab-text">{deviceQuantity}</span> 
                <span
                  className="qty-btn"
                  onClick={() =>
                    handleDeviceQuantityChange(val.price_id, deviceQuantity + 1)
                  }
                >
                  &#43;
                </span>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  )
}