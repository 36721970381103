import { connect } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
// import Packages from "../../components/Packages/Packages.component";
import i18n from "../../i18n";
import BaseModal from "../BaseModal";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import {
  insertItemsRequest,
  removeItemsRequest,
  setActive,
  insertRequest,
  updateRequest,
} from "../../redux/actions/Packages";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import PrimaryButton from "../Core/PrimaryButton";
import DatePicker from "react-datepicker";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Menu from "@material-ui/core/Menu";
import Checkbox from "@material-ui/core/Checkbox";
import { getNestedValue } from "../../util/objectMethods";
import moment from "moment";
import InputAdornment from "@material-ui/core/InputAdornment";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      className="light-border"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={2}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Packages({
  open,
  onClose,
  onSave,
  id,
  allServices = [],
  active = { status: "Active" },
  removeItemsRequest,
  insertItemsRequest,
  insertRequest,
  updateRequest,
  accessToken,
  setActive,
}) {
  const form = useRef();
  const [data, setData] = useState({});
  const [itemMap, setItemMap] = useState({});
  const init = (data) => {
    const newItemMap = {};
    getNestedValue(data, "services", []).map((item) => {
      newItemMap[item.serviceId] = true;
    });
    setData({ ...data });
    setItemMap(newItemMap);
    setTab(0);
  };
  useEffect(() => {
    init(active);
  }, []);
  useEffect(() => {
    if (active._id != data._id) {
      init(active);
    }
  }, [active]);
  const handleChange = (key, value) => {
    data[key] = value;
    setData({ ...data });
  };
  const handleSubmit = () => {
    onSave(data);
  };
  const [tab, setTab] = React.useState(-1);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const toggleServiceSelection = (serviceId) => {
    const newItemMap = { ...itemMap };
    if (newItemMap[serviceId]) {
      delete newItemMap[serviceId];
    } else {
      newItemMap[serviceId] = true;
    }
    setItemMap(newItemMap);
  };

  const onSaveItems = () => {
    const itemMapCopy = { ...itemMap };
    const toRemove = [];
    getNestedValue(active, "services", []).map((active) => {
      if (!itemMapCopy[active.serviceId]) {
        toRemove.push(active._id);
      } else {
        delete itemMapCopy[active.serviceId];
      }
    });
    insertItemsRequest(
      {
        access_token: accessToken,
        packageId: data._id,
        services: Object.keys(itemMapCopy),
      },
      {
        success: () => {
          removeItemsRequest(
            {
              access_token: accessToken,
              packageId: data._id,
              services: toRemove,
            },
            {
              success: ({ data }) => {
                setActive(data);
              },
            }
          );
        },
      }
    );
  };

  return (
    <div>
      {open ? (
        <BaseModal
          open={open}
          title={data._id ? i18n.t("edit_package") : i18n.t("add_package")}
          containerClassName="container"
          content={
            <>
              <AppBar position="static">
                <Tabs
                  value={tab}
                  onChange={handleTabChange}
                  aria-label="simple tabs example"
                >
                  <Tab label={i18n.t("details")} {...a11yProps(0)} />
                  <Tab
                    label={i18n.t("services")}
                    {...a11yProps(1)}
                    disabled={!data._id}
                  />
                </Tabs>
              </AppBar>
              <TabPanel value={tab} index={0}>
                <div className="d-flex">
                  <ValidatorForm
                    onSubmit={handleSubmit}
                    ref={form}
                    className="w-100"
                    onError={(error) => {
                      if (!error.length) {
                        onSave(data);
                      }
                    }}
                  >
                    <TextValidator
                      className="mb-1"
                      label={i18n.t("name")}
                      onChange={(e) => handleChange("name", e.target.value)}
                      value={data.name}
                      validators={["required"]}
                      errorMessages={[i18n.t("name_is_required")]}
                    />
                    <TextValidator
                      className="mb-1"
                      label={i18n.t("description")}
                      onChange={(e) =>
                        handleChange("description", e.target.value)
                      }
                      value={data.description}
                      validators={["required"]}
                      errorMessages={[i18n.t("name_is_required")]}
                      rows={2}
                    />
                    <SelectValidator
                      className="mb-1"
                      label={i18n.t("status")}
                      onChange={(e) => {
                        handleChange("status", e.target?.value);
                      }}
                      value={data.status}
                      validators={["required"]}
                      errorMessages={[i18n.t("status_is_required")]}
                      select
                    >
                      <MenuItem value="Active">{i18n.t("Active")}</MenuItem>
                      <MenuItem value="Inactive">{i18n.t("Inactive")}</MenuItem>
                    </SelectValidator>
                    <DatePicker
                      wrapperClassName="w-100 mb-1"
                      selected={
                        data.expiryDate ? moment(data.expiryDate).toDate() : ""
                      }
                      onChange={(e) => handleChange("expiryDate", e)}
                      value={
                        data.expiryDate ? moment(data.expiryDate).toDate() : ""
                      }
                      customInput={
                        <TextValidator
                          label={i18n.t("expiry_date")}
                          onChange={(e) =>
                            handleChange("expiryDate", e.target.value)
                          }
                          value={data.expiryDate}
                        />
                      }
                      placeholderText={i18n.t("expiry_date")}
                    />
                    <TextValidator
                      className="mb-1"
                      label={i18n.t("number_of_sessions")}
                      onChange={(e) =>
                        handleChange("numberOfSessions", e.target.value)
                      }
                      value={data.numberOfSessions}
                      type="number"
                      validators={["required", "minNumber:1"]}
                      errorMessages={[
                        i18n.t("number_of_session_is_required"),
                        i18n.t("must_be_positive"),
                      ]}
                    />
                    <SelectValidator
                      className="mb-1"
                      label={i18n.t("time_to_use")}
                      onChange={(e) => {
                        handleChange("timeToUse", e.target?.value);
                      }}
                      value={data.timeToUse}
                      select
                    >
                      <MenuItem value="" disabled={true}>
                        {i18n.t("time_to_use")}
                      </MenuItem>
                      <MenuItem value={0}>{i18n.t("no_limit")}</MenuItem>
                      <MenuItem value={7}>{i18n.t("1_week")}</MenuItem>
                      <MenuItem value={14}>{i18n.t("2_week")}</MenuItem>
                      <MenuItem value={30}>{i18n.t("1_month")}</MenuItem>
                      <MenuItem value={90}>{i18n.t("3_months")}</MenuItem>
                      <MenuItem value={180}>{i18n.t("6_months")}</MenuItem>
                      <MenuItem value={270}>{i18n.t("9_months")}</MenuItem>
                      <MenuItem value={365}>{i18n.t("1_year")}</MenuItem>
                    </SelectValidator>
                    <TextValidator
                      className="mb-1"
                      type="numeric"
                      label={i18n.t("price")}
                      onChange={(e) => handleChange("price", e.target.value)}
                      validators={["required"]}
                      errorMessages={[i18n.t("price_is_required")]}
                      onBlur={(text) => {
                        let num = parseFloat(text.target.value);
                        if (isNaN(num) || num < 0) {
                          num = 0;
                        }
                        num = Math.round((num + Number.EPSILON) * 100) / 100;
                        handleChange("price", num);
                      }}
                      value={data.price}
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      validators={["required", "minNumber:1"]}
                      errorMessages={[
                        i18n.t("price_is_required"),
                        i18n.t("must_be_positive"),
                      ]}
                    />
                    <div className="row mx-0 px-0 justify-content-end mt-2">
                      <PrimaryButton
                        props={{
                          type: "submit",
                        }}
                        label={data._id ? i18n.t("save") : i18n.t("create")}
                      />
                      {data._id ? (
                        <div className="ml-2">
                          <PrimaryButton
                            label={i18n.t("add_services")}
                            onClick={() => {
                              setTab(1);
                            }}
                          />
                        </div>
                      ) : null}
                    </div>
                  </ValidatorForm>
                </div>
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <div className="light-border-b">
                  {allServices.map((service) => {
                    return (
                      <MenuItem
                        key={service._id}
                        onClick={() => toggleServiceSelection(service._id)}
                        className="light-border-x light-border-t"
                        disableGutters
                      >
                        <div className="row mx-0 px-0"></div>
                        <Checkbox
                          checked={Boolean(itemMap?.[service._id])}
                          onChange={() => toggleServiceSelection(service._id)}
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                        {service.name} | $ {service.price ? service.price : 0}
                      </MenuItem>
                    );
                  })}
                </div>
                <div className="row mt-2 mx-0 px-0 justify-content-end">
                  <PrimaryButton label={i18n.t("save")} onClick={onSaveItems} />
                </div>
              </TabPanel>
            </>
          }
          onClose={onClose}
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  active: state.packages.active,
  loading: state.packages.isFetching,
});

const action = {
  removeItemsRequest,
  insertItemsRequest,
  setActive,
  insertRequest,
  updateRequest,
};

export default connect(mapStateToProps, action)(Packages);
