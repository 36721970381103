import React from "react";
import "./Sidebar.scss";

const Sidebar = ({ open, width, children }) => {
  return (
    <div
      className="side-bar"
      style={{
        width: !open ? 0 : width,
        marginTop: "65px",
        zIndex: 4,
        height: "calc(100% - 74px)",
      }}
    >
      <React.Fragment>{children}</React.Fragment>
    </div>
  );
};

export default Sidebar;
