import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import i18n from "../../../i18n";
import {
  getRequest,
  upsertRequest,
} from "../../../redux/actions/Saloon/CommunicationSettings";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

import { getCompanyTaxes } from "../../../config/simpleApiCalls";
import PrimaryButton from "../../../components/Core/PrimaryButton";
import { DotsLoader } from "../../../components";
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import { saloonGetAllService } from "../../../config/simpleApiCalls";
import TextField from "../../../components/Core/TextField";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Select from "../../../components/Core/Select";
import TabPanel from "../../../components/TabPanel/TabPanel.component";
import CustomTextInput from "../../../components/Saloon/CommunicationSettings/CustomTextInput.component";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Expense = ({
  getRequest,
  upsertRequest,
  loading: propsLoading,
  communicationSettings,
}) => {
  const [typeMap, setTypeMap] = useState({
    td: {
      label: "transfer_details",
    },
    bc: {
      label: "booking_confirmation",
    },
    bcd: {
      label: "booking_confirmation_with_deposit",
    },
    br: {
      label: "booking_reminder",
    },
    pr: {
      label: "payment_reminder",
    },
    dr: {
      label: "deposit_reminder",
    },
    tr: {
      label: "transfer_reminder",
    },
    in: {
      label: "invoice_message",
    },
    fs: {
      label: "form_send",
    },
  });
  const [accessToken, setAccessToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [bookingReminderTimes, setBookingReminderTimes] = useState({});
  const [invoiceMemo, setInvoiceMemo] = useState("");
  const [communicationLanguage, setCommunicationLanguage] = useState("en");

  useEffect(() => {
    const [first = 24, second = 3] =
      communicationSettings?.sendRemindersXHoursBeforeBooking || [];
    setBookingReminderTimes([Math.round(first / 24), second]);
    Object.keys(communicationSettings?.customMessageMap || {}).map((key) => {
      typeMap[key].value = communicationSettings?.customMessageMap[key];
    });
    setTypeMap({ ...typeMap });
    setInvoiceMemo(communicationSettings.invoiceMemo);
    setCommunicationLanguage(
      communicationSettings.communicationLanguage || communicationLanguage
    );
  }, [communicationSettings]);
  useEffect(() => {
    const saloonData = JSON.parse(sessionStorage.getItem("saloon"));
    setAccessToken(saloonData.access_token);
    setLoading(true);
    Promise.all([
      new Promise((resolve) => {
        getRequest(
          { access_token: saloonData.access_token },
          {
            success: resolve,
          }
        );
      }),
    ]).then(() => {
      setLoading(false);
    });
  }, []);
  const onSaveReminderSettings = () => {
    setLoading(true);
    upsertRequest(
      {
        access_token: accessToken,
        sendRemindersXHoursBeforeBooking: bookingReminderTimes,
        invoiceMemo,
        _id: communicationSettings?._id,
        communicationLanguage,
      },
      {
        success: () => {
          SuccessHelper.handleSuccess(i18n.t("successfully_updated"), true);
          setLoading(false);
        },
        failure: () => {
          ErrorHelper.handleErrors(i18n.t("failed_to_update"), true);
          setLoading(false);
        },
      }
    );
  };
  const onSaveCustomMessages = () => {
    setLoading(true);
    const customMessageMap = {};
    Object.keys(typeMap).map((key) => {
      if (typeMap[key].value) {
        customMessageMap[key] = typeMap[key].value;
      }
    });
    upsertRequest(
      {
        access_token: accessToken,
        customMessageMap,
        _id: communicationSettings?._id,
      },
      {
        success: () => {
          SuccessHelper.handleSuccess(i18n.t("successfully_updated"), true);
          setLoading(false);
        },
        failure: () => {
          ErrorHelper.handleErrors(i18n.t("failed_to_update"), true);
          setLoading(false);
        },
      }
    );
  };
  const [tab, setTab] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };
  return (
    <div className="content-container">
      <DotsLoader isloading={loading || propsLoading} />
      <div className="d-flex justify-content-between align-items-center">
        <h1 className="saloon-dashboard-heading pb-3">
          {i18n.t("container.communication_settings")}
        </h1>
      </div>
      <AppBar position="static">
        <Tabs
          value={tab}
          onChange={handleTabChange}
          aria-label="simple tabs example"
        >
          <Tab label={i18n.t("general")} {...a11yProps(0)} />
          <Tab label={i18n.t("custom_messages")} {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={tab} index={0}>
        <fieldset>
          <legend className="mb-0">{i18n.t("first_reminder_time")}</legend>
          <Select
            value={`${bookingReminderTimes[0]}`}
            options={[
              { value: 0, name: i18n.t("no_reminder") },
              { value: 1, name: i18n.t("1_day") },
              { value: 2, name: i18n.t("number_of_days", { number: 2 }) },
              { value: 3, name: i18n.t("number_of_days", { number: 3 }) },
              { value: 4, name: i18n.t("number_of_days", { number: 4 }) },
              { value: 5, name: i18n.t("number_of_days", { number: 5 }) },
              { value: 6, name: i18n.t("number_of_days", { number: 6 }) },
              { value: 7, name: i18n.t("number_of_days", { number: 7 }) },
              { value: 8, name: i18n.t("number_of_days", { number: 8 }) },
              { value: 9, name: i18n.t("number_of_days", { number: 9 }) },
              { value: 10, name: i18n.t("number_of_days", { number: 10 }) },
            ]}
            onChange={(e) => {
              setBookingReminderTimes([
                e.target.value,
                bookingReminderTimes[1],
              ]);
            }}
          />
        </fieldset>
        <fieldset>
          <legend className="mb-0">{i18n.t("second_reminder_time")}</legend>
          <TextField
            type="number"
            props={{
              value: bookingReminderTimes[1],
            }}
            onChange={(e) => {
              setBookingReminderTimes([
                bookingReminderTimes[0],
                e.target.value,
              ]);
            }}
            onBlur={(e) => {
              setBookingReminderTimes([
                bookingReminderTimes[0],
                Math.round(e.target.value),
              ]);
            }}
          />
        </fieldset>
        <legend className="mb-0">{i18n.t("invoice_memo")}</legend>
        <TextField
          props={{
            value: invoiceMemo,
          }}
          onChange={(e) => {
            setInvoiceMemo(e.target.value);
          }}
        />
        <fieldset className="mb-1">
          <legend className="mb-0">{i18n.t("communication_language")}</legend>
          <Select
            value={communicationLanguage}
            options={[
              { value: "fr", name: "Français" },
              { value: "en", name: "English" },
            ]}
            excludeEmptyValue
            onChange={(e) => {
              setCommunicationLanguage(e.target.value);
            }}
          />
        </fieldset>
        <div className="row mx-0 justify-content-end">
          <PrimaryButton
            label={i18n.t("save")}
            onClick={onSaveReminderSettings}
          />
        </div>
      </TabPanel>
      <TabPanel value={tab} index={1}>
        {tab === 1 ? (
          <>
            {Object.keys(typeMap).map((key) => {
              return (
                <CustomTextInput
                  label={typeMap[key].label}
                  key={key}
                  value={typeMap[key].value}
                  onChange={(value) => {
                    typeMap[key].value = value;
                    setTypeMap({ ...typeMap });
                  }}
                />
              );
            })}
            <div className="row mx-0 px-0 justify-content-end">
              <PrimaryButton
                label={i18n.t("save")}
                onClick={onSaveCustomMessages}
              />
            </div>
          </>
        ) : null}
      </TabPanel>
    </div>
  );
};

const mapStateToProps = (state) => ({
  communicationSettings: state.communicationSettings.data,
  loading: state.packages.isFetching,
});

const action = {
  getRequest,
  upsertRequest,
};

export default connect(mapStateToProps, action)(Expense);
