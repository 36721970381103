// @flow

import { BILLING } from "../ActionTypes";

export function getRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: BILLING.GET_SAGA
  };
}

export function set(data) {
  return {
    data,
    type: BILLING.SET
  };
}

export function requestFailure() {
  return {
    type: BILLING.REQUEST_FAILURE
  };
}