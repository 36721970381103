// @flow
import Immutable from "seamless-immutable";
import * as types from "../../actions/ActionTypes";

const initialState = Immutable({
  isFetching: false,
  errorMessage: "",
  active: null,
  data: [],
});

export default (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case types.FORM_BUILDER.GET_SAGA:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case types.FORM_BUILDER.GET:
      return Immutable.merge(state, {
        isFetching: false,
        data: action.data,
      });
    case types.FORM_BUILDER.GET_BY_ID_SAGA:
      return Immutable.merge(state, {
        active: null,
        isFetching: true,
      });
    case types.FORM_BUILDER.GET_BY_ID:
      return Immutable.merge(state, {
        isFetching: false,
        active: action.data,
      });
    case types.FORM_BUILDER.DELETE: {
      const newState = { ...state, isFetching: false, data: [...state.data] };
      const { _id } = action.data;
      const index = state.data.findIndex((element) => element._id == _id);
      if (index != -1) {
        newState.data.splice(index, 1);
      }
      return newState;
    }
    case types.FORM_BUILDER.DELETE_SAGA:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case types.FORM_BUILDER.INSERT: {
      const newState = { ...state, isFetching: false, data: [...state.data] };
      const { _id } = action.data;
      const index = state.data.findIndex((element) => element._id == _id);
      newState.data.push(action.data);
      return newState;
    }
    case types.FORM_BUILDER.INSERT_SAGA:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case types.FORM_BUILDER.UPDATE: {
      const newState = { ...state, isFetching: false, data: [...state.data] };
      const { _id } = action.data;
      const index = state.data.findIndex((element) => element._id == _id);
      if (index != -1) {
        newState.data[index] = action.data;
      } else {
        newState.data.push(action.data);
      }
      return newState;
    }
    case types.FORM_BUILDER.UPDATE_SAGA:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case types.FORM_BUILDER.DELETE: {
      const newState = { ...state, isFetching: false, data: [...state.data] };
      const { _id } = action.data;
      const index = state.data.findIndex((element) => element._id == _id);
      if (index != -1) {
        newState.data.splice(index, 1);
      }
      return newState;
    }
    case types.FORM_BUILDER.DELETE_SAGA:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case types.FORM_BUILDER.DELETE_QUESTION: {
      const active = {
        ...state.active,
        questions: [...state.active.questions],
      };
      const { _id } = action.data;
      const index = active.questions.findIndex((element) => element._id == _id);
      if (index != -1) {
        active.questions.splice(index, 1);
      }
      return Immutable.merge(state, {
        isFetching: false,
        active,
      });
    }
    case types.FORM_BUILDER.DELETE_QUESTION_SAGA:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case types.FORM_BUILDER.UPDATE_QUESTION: {
      const active = {
        ...state.active,
        questions: [...state.active.questions],
      };
      const { _id } = action.data;
      const index = active.questions.findIndex((element) => element._id == _id);
      if (index != -1) {
        active.questions[index] = action.data;
      } else {
        active.questions.push(action.data);
      }
      return Immutable.merge(state, {
        isFetching: false,
        active,
      });
    }
    case types.FORM_BUILDER.UPDATE_QUESTION_SAGA:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case types.FORM_BUILDER.INSERT_QUESTION: {
      const active = {
        ...state.active,
        questions: [...state.active.questions],
      };
      active.questions.push(action.data);
      return Immutable.merge(state, {
        isFetching: false,
        active,
      });
    }
    case types.FORM_BUILDER.INSERT_QUESTION_SAGA:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case types.FORM_BUILDER.REQUEST_FAILURE:
      return Immutable.merge(state, {
        isFetching: false,
      });
    default:
      return state;
  }
};
