import React from "react";

const SendMessage = ({ inputRef, onChange, onClick, toggleAppearance }) => {
  return (
    <div className="messageSendingContainer">
      <div className="messagingSendBotton">
        <input
          ref={inputRef}
          className={"textInput " + toggleAppearance}
          // toggleSearch={tSearch}
          type="text"
          onChange={onChange}
        />
        <input
          className={"submitBotton " + toggleAppearance}
          type="submit"
          value="send"
          onClick={onClick}
        />
      </div>
    </div>
  );
};

export default SendMessage;
