import React, { useEffect, useState } from "react";
import i18n from "../../../i18n";
import BaseModal from "../../BaseModal";
import TextField from "@material-ui/core/TextField";
import DollarInput from "../../DollarInput";
import { SuccessHelper } from "../../../helpers";
import PrimaryButton from "../../Core/PrimaryButton";
import { Button, Checkbox } from "@material-ui/core";
import PercentInput from "../../PercentInput";
const roundTo2Decimals = (x) => Math.round((x + Number.EPSILON) * 100) / 100;
const InsertPayment = ({
  open,
  onSubmit,
  handleClose,
  amountReadonly,
  toggleTaxes = () => {},
  items = [],
  subtotal,
  taxDue,
  includeTaxes,
  grandTotal,
  collected,
}) => {
  const [amount, setAmount] = useState(0);
  const [creditDebit, setCreditDebit] = useState(0);
  const [balanceDue, setBalanceDue] = useState(0);
  const [tip, setTip] = useState(0);
  const [discountPercent, setDiscountPercent] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);

  const [cash, setCash] = useState(0);
  useEffect(() => {
    const newTotal = roundTo2Decimals(grandTotal + parseFloat(tip));
    setAmount(newTotal);
    setBalanceDue(newTotal);
  }, [grandTotal, tip]);
  const [note, setNote] = useState("");
  const [manualPaymentMap, setManualPaymentMap] = useState({});
  const [formErrors, setFormErrors] = useState("");
  const validate = () => {
    let hasErrors = false;
    let newFormErrors = {};
    if (!amount) {
      newFormErrors.amount = i18n.t("amount_is_required");
      hasErrors = true;
    }
    if (hasErrors) {
      setFormErrors(newFormErrors);
      setTimeout(() => {
        setFormErrors({});
      }, 6000);
    } else {
      onSubmit({
        amount,
        note,
        cash: parseFloat(cash),
        creditDebit: parseFloat(creditDebit),
        tip,
        discount: discountAmount,
      });
    }
  };
  const colClass = "col-md-6 col-xs-12";
  return (
    <BaseModal
      style={{ zIndex: 1249 }}
      open={open}
      containerClassName={"container"}
      {...{
        title: (
          <div>
            {`${i18n.t("amount_to_be_paid")} ($ ${balanceDue.toFixed(2)})`}
          </div>
        ),
        content: (
          <div>
            <div className="row mx-0">
              <div className={colClass}>
                <div className="row mx-0">
                  <div className="mb-2 col-sm-6 col-xs-12 px-1">
                    <DollarInput
                      value={cash}
                      onChange={setCash}
                      label={i18n.t("cash")}
                      readOnly={amountReadonly}
                      error={formErrors.money}
                    />
                  </div>
                  <div className="mb-2 col-sm-6 col-xs-12 px-1">
                    <DollarInput
                      value={creditDebit}
                      onChange={setCreditDebit}
                      label={i18n.t("credit_debit")}
                      readOnly={amountReadonly}
                      error={formErrors.money}
                    />
                  </div>
                  <div className="mb-2 col-sm-6 col-xs-12 px-1">
                    <DollarInput
                      value={discountAmount}
                      onChange={(value) => {
                        setDiscountAmount(value);
                        setDiscountPercent(
                          roundTo2Decimals((100 * value) / balanceDue)
                        );
                      }}
                      label={i18n.t("discount_amount")}
                      readOnly={amountReadonly}
                      error={formErrors.discount}
                    />
                  </div>
                  <div className="mb-2 col-sm-6 col-xs-12 px-1">
                    <PercentInput
                      value={discountPercent}
                      onChange={(percent) => {
                        setDiscountPercent(percent);
                        setDiscountAmount(
                          roundTo2Decimals((balanceDue * percent) / 100)
                        );
                      }}
                      label={i18n.t("discount_percent")}
                      readOnly={amountReadonly}
                      error={formErrors.discount}
                    />
                  </div>
                  <div className="col-12 mb-2 px-1">
                    <DollarInput
                      value={tip}
                      onChange={(e) => setTip(parseFloat(e))}
                      label={i18n.t("tip")}
                    />
                    <div className="col-12 py-1 px-0 row mx-0 justify-content-between">
                      <Button
                        style={{
                          backgroundColor: "#ff3600",
                          color: "white",
                        }}
                        onClick={() =>
                          setTip(roundTo2Decimals((15.0 / 100) * subtotal))
                        }
                      >
                        15 %
                      </Button>
                      <Button
                        style={{
                          backgroundColor: "#ff3600",
                          color: "white",
                        }}
                        onClick={() =>
                          setTip(roundTo2Decimals((20.0 / 100) * subtotal))
                        }
                      >
                        20 %
                      </Button>
                      <Button
                        style={{
                          backgroundColor: "#ff3600",
                          color: "white",
                        }}
                        onClick={() =>
                          setTip(roundTo2Decimals((35.0 / 100) * subtotal))
                        }
                      >
                        35 %
                      </Button>
                    </div>
                  </div>
                  <div className="mb-2 col-sm-6 col-xs-12 px-1">
                    <DollarInput
                      value={parseFloat(cash) + parseFloat(creditDebit)}
                      label={i18n.t("total")}
                      readOnly={true}
                    />
                  </div>
                  <div className="mb-2 col-sm-6 col-xs-12 px-1">
                    <DollarInput
                      value={balanceDue}
                      label={i18n.t("balance_due")}
                      readOnly={true}
                    />
                  </div>
                  <div className="mb-2 col-12 px-1">
                    <TextField
                      label={i18n.t("note")}
                      onChange={(e) => setNote(e.target.value)}
                      helperText={formErrors.note}
                      error={Boolean(formErrors.note)}
                    />
                  </div>
                </div>
              </div>
              <div
                className={`${colClass} row mx-0 flex-column justify-content-between transaction-details-body`}
              >
                <div>
                  <div className="row mx-0 justify-content-center transaction-details-header">
                    <strong>{i18n.t("transaction_details")}</strong>
                  </div>
                  <table className="w-100 px-2 transaction-details-table">
                    <tbody>
                      {items.map((item) => {
                        return (
                          <tr className="summary-row">
                            <td className="name" colSpan={2}>
                              {item.name}
                            </td>
                            <td />
                            <td className="money-value">
                              $ {item.price.toFixed(2)}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div>
                  <table className="w-100 px-2 transaction-details-table">
                    <tr className="summary-row">
                      <td colSpan={2}>{i18n.t("subtotal")}</td>
                      <td />
                      <td className="money-value">${subtotal.toFixed(2)}</td>
                    </tr>
                    {taxDue ? (
                      <tr>
                        <td colSpan={2}>
                          <Checkbox
                            className="p-0 pr-1"
                            disabled={Boolean(collected)}
                            onChange={() => toggleTaxes(!includeTaxes)}
                            checked={Boolean(includeTaxes)}
                          />
                          {i18n.t("tax_total")}
                        </td>
                        <td />
                        <td className="money-value">${taxDue.toFixed(2)}</td>
                      </tr>
                    ) : null}
                    {tip ? (
                      <tr>
                        <td colSpan={2}>{i18n.t("tip")}</td>
                        <td />
                        <td className="money-value">${tip.toFixed(2)}</td>
                      </tr>
                    ) : null}
                    {collected ? (
                      <tr>
                        <td colSpan={2}>{i18n.t("collected")}</td>
                        <td />
                        <td className="money-value">
                          $ -{collected.toFixed(2)}
                        </td>
                      </tr>
                    ) : null}

                    {grandTotal ? (
                      <tr className="summary-row">
                        <td colSpan={2}>{i18n.t("grand_total")}</td>
                        <td />
                        <td className="money-value">
                          ${(grandTotal + tip).toFixed(2)}
                        </td>
                      </tr>
                    ) : null}
                  </table>
                </div>
              </div>
            </div>
          </div>
        ),
        actions: (
          <div className="row mx-0 justify-content-end">
            <Button
              className="primary-button ml-1"
              onClick={() => {
                validate();
              }}
            >
              {i18n.t("apply")}
            </Button>
          </div>
        ),
      }}
      onClose={handleClose}
    />
  );
};

export default InsertPayment;
