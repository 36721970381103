import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import StudentTable from "../../../components/Saloon/Student/StudentTable";
import StudentUpsert from "../../../components/Saloon/Student/StudentUpsert";
import i18n from "../../../i18n";
import {
  getRequest,
  upsertRequest,
  removeRequest,
} from "../../../redux/actions/Saloon/Student";
import { getRequest as getClassesRequest } from "../../../redux/actions/Saloon/Class";
import PrimaryButton from "../../../components/Core/PrimaryButton";
import { DotsLoader } from "../../../components";
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import {
  getCompanyCustomers,
  getCompanyTaxes,
} from "../../../config/simpleApiCalls";

const Student = ({
  getRequest,
  upsertRequest,
  students = [],
  getClassesRequest,
  loading: propsLoading,
}) => {
  const [accessToken, setAccessToken] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState();
  const [customers, setCustomer] = useState([]);
  const [taxes, setTaxes] = useState([]);
  useEffect(() => {
    const saloonData = JSON.parse(sessionStorage.getItem("saloon"));
    const company = JSON.parse(sessionStorage.getItem("company"));
    setCompanyId(company._id);
    setAccessToken(saloonData.access_token);
    setLoading(true);
    getRequest(
      { access_token: saloonData.access_token, relations: ["enrollments"] },
      {
        success: () => setLoading(false),
      }
    );
    getClassesRequest({ access_token: saloonData.access_token });
    getCompanyCustomers(
      { companyId: company._id },
      saloonData.access_token
    ).then((res) => {
      setCustomer(res);
    });
    getCompanyTaxes(
      { companyId: saloonData.companyId },
      saloonData.access_token
    ).then(({ data }) => {
      setTaxes(data.data);
    });
  }, []);
  const onClose = () => {
    setOpen(false);
  };
  const onSave = (data) => {
    setLoading(true);
    const request = upsertRequest;
    request(
      { access_token: accessToken, ...data },
      {
        success: (data) => {
          SuccessHelper.handleSuccess(
            i18n.t(data.id ? "successfully_updated" : "successfully_added"),
            true
          );
          setActive(data.data);
          setLoading(false);
        },
        failure: () => {
          ErrorHelper.handleErrors(
            i18n.t(data.id ? "failed_to_update" : "failed_to_add"),
            true
          );
          setLoading(false);
        },
      }
    );
  };
  const onEdit = (data) => {
    const keys = ["address", "postalCode", "city", "province", "country"];
    const addressText = keys
      .map((key) => data.address?.[key])
      .filter((element) => !!element)
      .join(", ");
    setActive({ ...data, addressText });
    setOpen(true);
  };
  return (
    <div className="content-container">
      <DotsLoader isloading={loading || propsLoading} />
      <div className="d-flex justify-content-between align-items-center">
        <h1 className="saloon-dashboard-heading pb-3">{i18n.t("student")}</h1>
        <PrimaryButton
          label={i18n.t("create")}
          onClick={() => {
            setActive();
            setOpen(true);
          }}
        />
      </div>
      <StudentTable accessToken={accessToken} rows={students} onEdit={onEdit} />
      {open && (
        <StudentUpsert
          open={open}
          active={active}
          onClose={onClose}
          onSave={onSave}
          accessToken={accessToken}
          customers={customers}
          taxes={taxes}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.student.isFetching,
});

const action = {
  getRequest,
  upsertRequest,
  removeRequest,
  getClassesRequest,
};

export default connect(mapStateToProps, action)(Student);
