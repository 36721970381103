import React, { Component } from "react";
import ProfileSection from "./Components/ProfileSection.component";
import SideDrawer from "./Components/SideDrawer.component";
import { SuccessHelper } from "../../helpers";
import i18n from "../../i18n";
import _ from "lodash";
import { request as get_available_timeslots } from "../../redux/actions/GetAvailableTimeslots.js";

import {
  editCustomerProfile,
  getWallet,
  getAllBookingsByUser,
  getWaitingList,
  // deleteWaitingList,
  editBooking,
  getEmployeesBySaloonAndService,
  createBooking,
} from "../../config/simpleApiCalls";
import { ErrorHelper } from "../../helpers";

import "./styles/profile.sass";
import BookingHistory from "./Components/BookingHistory.component";
import Wallet from "./Components/Wallet.component";
import WaitingList from "./Components/WaitingList.component";
import moment from "moment";
import { Images } from "../../theme";
import {
  addCollectionWith,
  getDataByKey,
  saveMsg,
  getMsgs,
} from "../../config/firebase";
import CardManagment from "../Card/CardManagment/index";
import { logout as user_logout } from "../../redux/actions/Login";
import { set } from "../../redux/actions/User";
import { getRequest as getCard } from "../../redux/actions/Card";
import { clear } from "../../redux/actions/ActiveBooking";
import { connect } from "react-redux";
import {
  getRequest,
  removeRequest,
  updateRequest,
  insertRequest,
} from "../../redux/actions/Card";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import UserPackages from "../UserPackages/UserPackages.component";
import { getUserPackagesRequest } from "../../redux/actions/Packages";
import { TextField } from "@material-ui/core";
import DatePicker from "react-datepicker";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
class Profile extends Component {
  state = {
    user: null,
    updateBookings: false,
    allWalletInfo: null,
    ammount: 0,
    display: {
      displayProfile: true,
      displayBooking: false,
      displayWallet: false,
      displayChat: false,
      displayWaitingList: false,
      displayCards: false,
      displayPackages: false,
    },
    textMsg: "",
    selectSalon: "",
  };

  async componentDidMount() {
    try {
      const user = await JSON.parse(sessionStorage.getItem("user"));
      this.setUserData(user);
      this.getUserWalletInfo(user.access_token);
      this.getWaitingList(user.access_token);
      this.props.getUserPackagesRequest({ access_token: user.access_token });
    } catch {
      alert("Something went wronge ;(");
    }
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    let toSet = {};
    if (nextProps.getAvailableTimeslots) {
      if (
        !nextProps.getAvailableTimeslots.failure &&
        !nextProps.getAvailableTimeslots.isFetching &&
        !nextProps.getAvailableTimeslots.errorMessage &&
        nextProps.getAvailableTimeslots.data &&
        nextProps.getAvailableTimeslots.data.data
      ) {
        toSet = {
          ...toSet,
          isloading: false,
        };
        if (!prevState.lockFields) {
          toSet.availableTimeslots = nextProps.getAvailableTimeslots.data.data;
        }
      } else if (nextProps.getAvailableTimeslots.isFetching) {
        toSet = { ...toSet, isloading: true };
      }
    }
    if (nextProps.booking) {
      nextProps.clear();
      const booking = { ...nextProps.booking };

      toSet = {
        ...toSet,
        displayProfile: false,
        displayBooking: true,
        displayWallet: false,
        displayWaitingList: false,
        displayChat: false,
        showPopUp: true,
        selectBooking: booking,
      };
    }
    return toSet;
  }

  setUserData = (userData) => {
    if (userData && userData.access_token) {
      this.setState({ user: userData });
    }
  };

  editProfile = async () => {
    const { user, firstName, lastName, phoneNo, postalCode } = this.state;
    let payload = {};
    if (postalCode != null) {
      payload.postalCode = postalCode;
      this.setState({ postalCode: null });
    }
    if (phoneNo != null) {
      payload.phoneNo = phoneNo;
      this.setState({ phoneNo: null });
    }
    if (firstName != null) {
      payload.firstName = firstName;
      this.setState({ firstName: null });
    }
    if (lastName != null) {
      payload.lastName = lastName;
      this.setState({ lastName: null });
    }

    try {
      if (!_.isEmpty(payload)) {
        // saving role and accesstoken to use it later for session storage
        // if access token is not saved, we log out after updating the server
        let currentSession = _.pick(
          JSON.parse(sessionStorage.getItem("user")),
          ["role", "access_token"]
        );

        // calling the backend to update the user information
        const respose = await editCustomerProfile(payload, user.access_token);
        // updating the session storage to prevent logging out
        const updatedUser = _.pick(respose.data.data, [
          "_id",
          "firstName",
          "lastName",
          "phoneNo",
          "postalCode",
          "userName",
          "email",
          "profile_img",
          "createdDate",
        ]);

        const joinedsession = { ...currentSession, ...updatedUser };
        const updatedUserString = JSON.stringify(joinedsession);

        sessionStorage.setItem("user", updatedUserString);

        SuccessHelper.handleSuccess(
          i18n.t("container.profile_edit_success"),

          true
        );
      }
    } catch (error) {
      alert(`Somthing went wronge ;( \n ${error.data.data}`);
    }
  };

  handleFields = (val, propName) => {
    this.setState({ [propName]: val });
  };

  handleUpdate = async (selectBooking) => {
    const {
      state: {
        user: { access_token },
      },
    } = this;
    const payload = {
      ...selectBooking,
      bookingId: selectBooking._id,
      status: 3,
      services: selectBooking.services.map((service) => {
        return {
          ...service,
          categoryId:
            typeof service.categoryId == "object"
              ? service.categoryId._id
              : service.categoryId,
          employeeId:
            typeof service.employeeId == "object"
              ? service.employeeId._id
              : service.employeeId,
          serviceId:
            typeof service.serviceId == "object"
              ? service.serviceId._id
              : service.categoryId,
        };
      }),
    };
    delete payload._id;
    delete payload.__v;
    delete payload.userId;
    delete payload.companyId;
    delete payload.updatedDate;
    delete payload.createdDate;
    delete payload.email;

    const res = await editBooking(payload, access_token)
      .then(() => {
        SuccessHelper.handleSuccess("Updated Successfully", true);
        this.setState({ updateBookings: true });
      })
      .catch(() => {
        ErrorHelper.handleErrors("Failed to update", true);
      });
  };

  getUserWalletInfo = (access_token) => {
    getWallet(access_token)
      .then((res) => {
        this.setState({
          allWalletInfo: res.data.data.transactions,
          ammount: res.data.data.totalAmount,
        });
      })
      .catch((error) => {
        console.log("Profile -> getUserWalletInfo -> error", error);
      });
  };
  getWaitingList = (access_token) => {
    getWaitingList({}, access_token)
      .then((res) => {
        this.setState({
          waitingList: res.data.data,
        });
      })
      .catch((error) => {});
  };

  handleDisplay = (display) => {
    this.setState({ display });
  };
  scrollUp = () => {
    this.messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  scrollDown = () => {
    this.messagesStartRef.current.scrollIntoView({ behavior: "smooth" });
  };

  renderSupportMessage = (val, id) => {
    return (
      <div key={id} className="row mb-3 mb-md-4 support-message">
        <div className="col-10 col-md-6">
          <div className="col-12 py-3">
            <div className="row mb-2">
              <div className="col-6">
                <span className="support-user-name">{val.senderName}</span>
              </div>
              <div className="col-6 text-right">
                <span className="support-message-time">
                  {moment(val.createdDate).fromNow()}
                </span>
              </div>
            </div>
            <p className="support-text-message">{val.msg}</p>
          </div>
        </div>
      </div>
    );
  };

  renderUserMessage = (val, id) => {
    return (
      <div key={id} className="row mb-3 mb-md-4 user-message">
        <div className="col-10 col-md-6 ml-auto">
          <div className="col-12 py-3">
            <div className="row mb-2">
              <div className="col-6">
                <span className="support-user-name">{val.senderName}</span>
              </div>
              <div className="col-6 text-right">
                <span className="support-message-time">
                  {moment(val.createdAt).fromNow()}
                </span>
              </div>
            </div>
            <p className="support-text-message">{val.msg}</p>
          </div>
        </div>
      </div>
    );
  };

  renderMessagesContainer = () => {
    const { messages_data, user } = this.state;
    return (
      <div className="col-12 aaaaaaa" id="messages-container">
        <div ref={this.messagesStartRef} />
        {messages_data &&
          !!messages_data.length &&
          messages_data.map((val, id) => {
            console.log(val);
            if (val.userId === user._id) {
              return this.renderUserMessage(val, id);
            } else {
              return this.renderSupportMessage(val, id);
            }
          })}
        <div ref={this.messagesEndRef} />
      </div>
    );
  };

  checkRoomFounded = async (user) => {
    const payload = {
      collectionName: "chatRooms",
      key: "userId",
      value: user._id,
    };

    const res = await getDataByKey(payload);
    if (!res.size) {
      this.createRoom();
      return;
    }
    res.forEach((doc) => {
      const roomData = { _id: doc.id, ...doc.data() };
      this.setState({ roomData });
      this.getMsg(roomData);
    });
  };

  createRoom = async () => {
    const { user } = this.state;
    try {
      const payload = {
        collectionName: "chatRooms",
        data: {
          createdAt: Date.now(),
          userData: user,
          userId: user._id,
        },
      };
      await addCollectionWith(payload);
      this.checkRoomFounded(user);
    } catch (error) {}
  };

  getMsg = async (roomData) => {
    const { user } = this.state;
    if (!user || !roomData) return;

    const payload = {
      collectionName: "messages",
      id: roomData._id,
    };

    try {
      getMsgs(payload).onSnapshot((querySnapshot) => {
        let allMsg = [];
        querySnapshot.forEach(function (doc) {
          allMsg.push(doc.data());
        });
        allMsg = allMsg.sort((a, b) => a.createdAt - b.createdAt);
        this.setState({ messages_data: allMsg });
      });
    } catch (error) {}
  };

  sendMsg = async () => {
    const { textMsg, user, roomData, isLoading } = this.state;
    if (!textMsg || !user || !roomData || isLoading) return;
    this.setState({ isLoading: true });
    const payload = {
      collectionName: "messages",
      id: roomData._id,
      data: {
        createdAt: Date.now(),
        type: "text",
        msg: textMsg,
        userId: user._id,
        senderName: user.firstName,
      },
    };
    try {
      this.setState({ isLoading: false, textMsg: "" });
      await saveMsg(payload);
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };
  onChangeSelectEmployee = (text) => {
    const { selectServices } = this.state;
    let employee = JSON.parse(text.target.value);
    let employeeId = employee.employeeId._id;
    let pasrseService = JSON.parse(selectServices);
    let serviceId = pasrseService._id;
    let service = employee.companyServices.filter((val) => {
      return val.serviceId == serviceId;
    });
    let serviceAmount = service[0].price;
    this.setState({
      selectEmployee: employeeId,
      totalAmount: serviceAmount,
      weekPlans: employee.weekPlans,
      selectedEmployee: text.target.value,
    });
  };
  renderChatBoxCard = () => {
    const { textMsg } = this.state;
    return (
      <div className="col-12 ">
        <div className="col-12 py-3 chatBoxProfile" id="chat-box-card">
          {this.renderMessagesContainer()}
          <div className="row" id="chat-box-input-cont">
            <div className="col-10 col-md-11 col-lg-11">
              <input
                type="text"
                value={textMsg || ""}
                placeholder="Type a message"
                id="chat-box-input"
                onChange={(e) => this.setState({ textMsg: e.target.value })}
              />
              <img src={Images.saloon_chat_clip} id="clip-icon" />
            </div>
            <div
              onClick={() => this.sendMsg()}
              className="col-2 col-md-1 col-lg-1 d-flex align-items-center pl-0"
            >
              {/* <div onClick={() => {}} className="col-2 col-md-1 col-lg-1 d-flex align-items-center pl-0"> */}
              <img src={Images.saloon_chat_send} id="send-icon" />
            </div>
          </div>
        </div>
      </div>
    );
  };
  handleLogout = () => {
    this.props.user_logout();
    SuccessHelper.handleSuccess("You have successfully logged out.", true);
    this.setState({ user: null });
    // this.handleNavigation("/");
  };

  onChangeSelectServices = (text) => {
    const { depositAmount } = JSON.parse(text);
    let amount = depositAmount;
    this.setState({
      selectServices: text,
      isloading: true,
      selectEmployee: "",
      selectedEmployee: "",
      availableEmployees: [],
      selectDateAndTime: "",
      newDate: "",
      selectedTime: undefined,
      availableTimeslots: {},
    });
    this.getEmployee(text);
  };

  getEmployee = async (selectServices) => {
    const serviceData = selectServices
      ? JSON.parse(selectServices)
      : selectServices;
    this.setState({ serviceData });
    const { selectSalon, lockFields, employeeId } = this.state;
    this.setState({ isloading: true });
    const payload = {
      companyId: selectSalon,
      serviceId: serviceData._id,
    };
    try {
      const res = await getEmployeesBySaloonAndService(payload);
      this.setState({
        getEmployeeData: res.data.data,
        isloading: false,
      });
      return res.data.data;
    } catch (error) {
      ErrorHelper.handleErrors(
        "Aww somthing went wrong for getting Employees",
        true
      );
      this.setState({ isloading: false });
    }
  };
  onChangeTime = (text) => {
    this.setState({
      selectedTime: text.target.value,
      newTime: text.target.value.split(" ")[0],
    });
  };
  onDateChange = async (selectDate) => {
    this.setState({
      selectedTime: undefined,
      availableTimeslots: {},
    });
    let date = undefined;
    if (selectDate) {
      date = moment(selectDate).format("MM-DD-YYYY");
      const { serviceData, selectSalon, employeeId } = this.state;
      this.props.get_available_timeslots({
        data: {
          companyId: selectSalon,
          companyServiceId: serviceData._id,
          companyEmployeeId: employeeId,
          date,
        },
      });
    }
    this.setState({
      selectDateAndTime: selectDate,
      newDate: date,
    });
  };

  renderPackages = () => {
    const {
      user = {},
      getEmployeeData,
      newDate,
      newTime,
      selectEmployee,
    } = this.state;

    return (
      <div className="packagesContainer">
        <h2>Packages</h2>
        <div className="mainBookingBorder px-3">
          <UserPackages
            accessToken={user?.access_token}
            renderDateTimeEmployeeSection={() => this.renderFillDetails()}
            employeeId={selectEmployee}
            phoneNo={user?.phoneNumber}
            email={user?.email}
            name={user?.firstName}
            date={newDate}
            time={newTime}
            onChangeSelectServices={this.onChangeSelectServices}
            onChangeSelectSalon={(text) => {
              return this.setState({
                selectSalon: text.target.value,
              });
            }}
            bookNow={this.bookNow}
            getEmployeeBySalonData={getEmployeeData}
          />
        </div>
      </div>
    );
  };

  renderFillDetails = () => {
    const {
      selectedEmployee,
      selectDateAndTime,
      selectedTime,
      isGym,
      getEmployeeData,
      filteredEmployeeData,
      enableDoubleBooking,
      enableWaitingList,
      newTime,
      timeSlotLength,
      lockFields,
      newDate,
      formErrors = {},
      availableTimeslots,
    } = this.state;
    console.log({ availableTimeslots });
    return (
      <div className="row mx-0 px-0">
        <div className="col-md-4 col-6">
          <FormControl
            error={!!formErrors.selectEmployeeError}
            className="full-width"
          >
            <InputLabel id="services">
              {i18n.t("container.employee")}
            </InputLabel>
            <Select
              value={selectedEmployee}
              onChange={(e) => this.onChangeSelectEmployee(e)}
              placeholder={i18n.t("select_employee")}
            >
              {(filteredEmployeeData && !enableDoubleBooking
                ? filteredEmployeeData
                : getEmployeeData
              )?.map((val, ind) => {
                let employeeName = val?.employeeId?.userId?.firstName
                  ? `${val?.employeeId?.userId?.firstName} ${val?.employeeId?.userId?.lastName}`
                  : val?.employeeId?.userId?.userName;
                return (
                  <MenuItem key={val._id} value={JSON.stringify(val)}>
                    {employeeName}
                  </MenuItem>
                );
              })}
            </Select>
            {!!formErrors.selectEmployeeError && (
              <FormHelperText>{formErrors.selectEmployeeError}</FormHelperText>
            )}
          </FormControl>
        </div>
        <div className="col-md-4 col-6">
          <DatePicker
            wrapperClassName="w-100"
            minDate={new Date()}
            selected={selectDateAndTime}
            onChange={(date) => this.onDateChange(date)}
            value={selectDateAndTime}
            customInput={
              <TextField
                id="time"
                label={"Date"}
                value={newDate}
                fullWidth
                readOnly={true}
                helperText={formErrors.selectDateAndTimeError}
                error={!!formErrors.selectDateAndTimeError}
              />
            }
            placeholderText={i18n.t(
              "container.services_click_to_select_a_date"
            )}
          />
        </div>
        <div className="col-md-4 col-6">
          <FormControl
            error={!!formErrors.selectedTimeError}
            className="full-width"
          >
            <InputLabel id="services">{i18n.t("container.time")}</InputLabel>
            <Select
              value={selectedTime}
              disabled={lockFields}
              onChange={(text) => this.onChangeTime(text)}
              placeholder={i18n.t("container.select_time")}
            >
              {Object.keys(availableTimeslots ? availableTimeslots : {})?.map(
                (val, ind) => {
                  return (
                    <MenuItem key={ind} value={val}>
                      {val.split(" ")[0]}{" "}
                      {availableTimeslots[val].length
                        ? ""
                        : `(${i18n.t("not_available")})`}
                    </MenuItem>
                  );
                }
              )}
            </Select>
            {!!formErrors.selectedTimeError && (
              <FormHelperText>{formErrors.selectedTimeError}</FormHelperText>
            )}
          </FormControl>
        </div>
      </div>
    );
  };
  bookNow = (payload) => {
    const { user = {} } = this.state;
    this.setState({ isloading: true });
    if (
      payload.services[0].time &&
      payload.services[0].date &&
      payload.services[0].employeeId
    ) {
      payload.name = payload.userName;
      payload.phoneNo = user?.phoneNo;
      delete payload.userName;
      return createBooking(payload, user.access_token)
        .then((res) => {
          if (res.data.success) {
            SuccessHelper.handleSuccess(i18n.t("booking_created"), true);
            this.setState({ isloading: false });
          } else {
            this.setState({ isloading: false });
            ErrorHelper.handleErrors(res.data.msg, true);
          }
        })
        .catch((error) => {
          this.setState({ isloading: false });
          ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
        });
    }
  };

  render() {
    const {
      user,
      updateBookings,
      allWalletInfo,
      ammount,
      waitingList,
      display,
    } = this.state;
    return (
      user && (
        <div className="profileContainer">
          <SideDrawer display={display} handleDisplay={this.handleDisplay} />
          <header className="profileHeader">
            <img
              style={{ height: "65%" }}
              alt="easy1 logo 800x300"
              src={Images.easy1_logo_800x300}
              className="cursor-pointer mainLogo newProfileLogo"
              onClick={() => this.handleNavigation("/")}
            />
            <div onClick={this.handleLogout} className="logOut">
              <span>Logout</span>
              <ExitToAppIcon />
            </div>
          </header>
          {display.displayProfile && (
            <ProfileSection
              user={user}
              editProfile={this.editProfile}
              handleFields={this.handleFields}
            />
          )}
          {display.displayBooking && (
            <BookingHistory
              token={user.access_token}
              handleUpdate={this.handleUpdate}
              updateBookings={updateBookings}
            />
          )}
          {display.displayWallet && (
            <Wallet allWalletInfo={allWalletInfo} ammount={ammount} />
          )}
          {display.displayWaitingList && (
            <WaitingList waitingList={waitingList} />
          )}
          {display.displayChat && this.renderChatBoxCard()}
          {display.displayPackages && this.renderPackages()}

          {/* {display.displayCards && (
            <CardManagment
              removeDefault={true}
              access_token={user?.access_token}
              getRequest={this.props.getRequest}
              removeRequest={this.props.removeRequest}
              updateRequest={this.props.updateRequest}
              insertRequest={this.props.insertRequest}
              cards={this.props.cards}
            />
          )} */}
        </div>
      )
    );
  }
}
const mapStateToProps = (state) => ({
  logout: state.logout,
  notifications: [...state.getNotifications.data],
  booking: state.activeBooking.data,
  cards: state.card.data,
  getAvailableTimeslots: state.getAvailableTimeslots,
});

const action = {
  user_logout,
  clear,
  set,
  getCard,
  removeRequest,
  updateRequest,
  insertRequest,
  getRequest,
  getUserPackagesRequest,
  get_available_timeslots,
};
export default connect(mapStateToProps, action)(Profile);
