// @flow
import Immutable from "seamless-immutable";
import * as types from "../../actions/ActionTypes";

const initialState = Immutable({
  isFetching: false,
  errorMessage: "",
  data: [],
});

export default (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case types.SEMESTER.GET_SAGA:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case types.SEMESTER.GET:
      return Immutable.merge(state, {
        isFetching: false,
        data: action.data,
      });
    case types.SEMESTER.UPSERT_SAGA:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case types.SEMESTER.UPSERT: {
      const newState = { ...state, isFetching: false, data: [...state.data] };
      const { _id } = action.data;
      const index = state.data.findIndex((element) => element._id == _id);
      if (index != -1) {
        newState.data[index] = action.data;
      } else {
        newState.data.push(action.data);
      }
      return newState;
    }
    case types.SEMESTER.DELETE_SAGA:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case types.SEMESTER.DELETE: {
      const newState = { ...state, isFetching: false, data: [...state.data] };
      const { _id } = action.data;
      const index = state.data.findIndex((element) => element._id == _id);
      if (index != -1) {
        newState.data.splice(index, 1);
      }
      return newState;
    }

    case types.SEMESTER.ADD_STUDENT_TO_CLASS_SAGA:
    case types.SEMESTER.DELETE_STUDENT_FROM_CLASS_SAGA:
    case types.SEMESTER.ADD_CLASS_SAGA:
    case types.SEMESTER.DELETE_CLASS_SAGA:
    case types.SEMESTER.ADD_STUDENT_GROUP_TO_CLASS_SAGA:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case types.SEMESTER.ADD_CLASS:
    case types.SEMESTER.DELETE_CLASS: {
      const newState = { ...state, isFetching: false, data: [...state.data] };
      const { _id } = action.data;
      const index = state.data.findIndex((element) => element._id == _id);
      if (index != -1) {
        newState.data[index] = action.data;
      }
      return newState;
    }
    case types.PROGRAM_ENROLLMENT.DELETE: {
      const data = [...state.data];
      const { _id, courseId } = action.data;
      const index = data.findIndex((element) => element._id == courseId);
      if (index != -1) {
        if (data[index].enrollments) {
          const nestedIndex = data[index].enrollments?.findIndex(
            (enrollment) => enrollment._id == _id
          );

          if (nestedIndex != -1) {
            const enrollments = [...data[index].enrollments];
            enrollments.splice(nestedIndex, 1);
            data[index] = { ...data[index], enrollments };
          }
        }
      }
      return Immutable.merge(state, { data });
    }

    case types.SEMESTER.ADD_STUDENT_TO_CLASS:
    case types.SEMESTER.DELETE_STUDENT_FROM_CLASS:
    case types.SEMESTER.REQUEST_FAILURE:
    case types.SEMESTER.ADD_STUDENT_GROUP_TO_CLASS:
      return Immutable.merge(state, {
        isFetching: false,
      });

    case types.LOGOUT:
      return initialState;
    case types.CLEAR_LOGOUT:
      return initialState;
    default:
      return state;
  }
};
