import { connect } from "react-redux";
import React, { Component } from "react";
import { Translation } from 'react-i18next';
import i18n from '../../i18n';
import { request as login_user } from "../../redux/actions/Login";
import { DotsLoader, Navbar, Footer } from "../../components";
import { SuccessHelper, ErrorHelper } from "../../helpers";
import { Images } from "../../theme/index";

import { forgetPassword } from "../../config/simpleApiCalls";
import { set } from "../../redux/actions/User";

import "./styles.css";

class ForgetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNum: "",
      isloading: false,
      formError: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.login) {
      if (
        !nextProps.login.failure &&
        !nextProps.login.isFetching &&
        !nextProps.login.errorMessage &&
        nextProps.login.data &&
        nextProps.login.data.data &&
        nextProps.login.data.data.access_token
      ) {
        console.log(nextProps.login.data.data.role);
        if (nextProps.login.data.data.role == "5") {
          this.props.set({
            user: JSON.stringify(nextProps.login.data.data),
          })
          SuccessHelper.handleSuccess("You have successfully logged in.", true);
          this.props.history.push("/");
          this.setState({ isloading: false, email: "", password: "" });
        } else {
          ErrorHelper.handleErrors("Access Denied", true);
          this.setState({ isloading: false, email: "", password: "" });
        }
      } else if (
        nextProps.login.failure &&
        !nextProps.login.isFetching &&
        nextProps.login.errorMessage
      ) {
        this.setState({ isloading: false });
      }
    }
  }

  hadleForgetPassword = () => {
    const { phoneNum } = this.state;

    const payload = {
      phoneNo: phoneNum,
    };

    this.setState({ isloading: true });

    forgetPassword(payload)
      .then((res) => {
        console.log(res,"RESs");
        if (res.data.success) {
          SuccessHelper.handleSuccess(res.data.msg, true);
          this.handleNavigation("/verify-code", res.data.data);
        }
      })
      .catch((err) => {
        ErrorHelper.handleErrors("Something Went Wrong", true);
      });

    this.setState({ isloading: false });
  };

  handleNavigation = (route, userObj) => {
    this.props.history.push(route, { userObj });
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  onPhoneNoChange = (text) => {
    var cleaned = ("" + text.target.value).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "",
        number = [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
          ""
        );
      this.setState({
        phoneNum: number,
        formError: null,
      });
      return;
    }
    this.setState({
      formError: "Please enter a valid phone no",
      phoneNum: text.target.value,
    });
  };

  renderEmail = () => {
    const { formError, phoneNum } = this.state;
    return (
      <div className="form-label-group mb-4 ">
        <label for="inputemail"><Translation>{t=>t('container.employe_phone_number')}</Translation></label>
        <input
          maxLength="14"
          type="text"
          id="inputemail"
          className="form-control mt-1"
          placeholder="Enter your number..."
          onChange={(text) => this.onPhoneNoChange(text)}
          value={phoneNum}
        />
        {formError ? (
          <small className="form-text text-danger ml-3">{formError}</small>
        ) : null}
      </div>
    );
  };

  renderLoginBtn = () => {
    return (
      <button
        className="login-btn btn btn-lg"
        type="submit"
        onClick={() => this.hadleForgetPassword()}
      >
        Send
      </button>
    );
  };

  renderLoginForm = () => {
    return (
      <form action="javascript:void(0)" className="form-signin  px-4">
        {this.renderEmail()}
        {this.renderLoginBtn()}
      </form>
    );
  };

  renderLoginCard = () => {
    return (
      <div className="card card-signin my-5">
        <div className="card-body px-0">
          <h5 className="card-title text-center">
           <Translation>{t=>t('container.employe_forgot_password_enter_number')}</Translation>
          </h5>
          <div className="row">
            <hr className="my-4 mx-3 " style={{ width: "100%" }} />
          </div>
          {this.renderLoginForm()}
        </div>
      </div>
    );
  };

  render() {
    return (
      <>
        <div className="container-fluid">
          <div className="container">
            {this.renderLoader()}
            <Navbar {...this.props} isBackgroundColor={true} />
          </div>
          <div className="container" style={{ backgroundColor: "#F8FDFF" }}>
            <div className="row mt-5">
              <div className="col-sm-9 col-md-7 col-lg-5 mx-auto mt-5">
                {this.renderLoginCard()}
              </div>
            </div>
          </div>
        </div>
        <Footer {...this.props} />
      </>
    );
  }
}

const mapStateToProps = (state) => ({ login: state.login });

const action = { login_user, set };

export default connect(mapStateToProps, action)(ForgetPassword);
