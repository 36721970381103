import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import i18n from '../../i18n';
import { DotsLoader, Header} from '../../components';
import { request as reset_password_request } from '../../redux/actions/ResetPassword';
import { SuccessHelper } from "../../helpers";

import './styles.css';

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: "",
            confirmPassword: "",
            isloading: false,
            formErrors: {
                passwordError: "",
                confirmPasswordError: ""
            }
        };
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.resetPassword) {
            if (
                !nextProps.resetPassword.failure &&
                !nextProps.resetPassword.isFetching &&
                nextProps.resetPassword.data &&
                nextProps.resetPassword.data.success
            ) {
                SuccessHelper.handleSuccess('Your password has been changed. You can now log in with your new credentials.', true);
                this.props.history.push('/login')
                this.setState({ isloading: false, password: '', confirmPassword: "" });
            } else if (
                nextProps.resetPassword.failure &&
                !nextProps.resetPassword.isFetching &&
                nextProps.resetPassword.errorMessage
            ) {
                this.setState({ isloading: false });
            }
        }
    }

    componentDidMount() {
        const user = JSON.parse(sessionStorage.getItem('user'));
        if (user && user.access_token) {
            this.handleNavigation('/')
        }
    }

    handleNavigation = (route) => {
        this.props.history.push(route);
    };

    // onChangePassword = (text) => this.setState({ password: text.target.value })
    // onChangeConfirmPassword=(text) => this.setState({ password: text.target.value })

    checkValidation() {
        const { confirmPassword, password } = this.state

        if (!password) {
            this.setState({
                formErrors: {
                    passwordError: <Translation>{t=>t('password_required')}</Translation>
                }
            })
            setTimeout(() => {
                this.setState({
                    formErrors: {
                        passwordError: ""
                    }
                })
            }, 6000)
        } else if (password.length < 6) {
            this.setState({
                formErrors: {
                    passwordError: <Translation>{t=>t('password_min_length')}</Translation>
                }
            })
            setTimeout(() => {
                this.setState({
                    formErrors: {
                        passwordError: ""
                    }
                })
            }, 6000)
        } else if (confirmPassword !== password) {
            this.setState({
                formErrors: {
                    confirmPasswordError: <Translation>{t=>t('confirm_password_not_match')}</Translation>
                }
            })
            setTimeout(() => {
                this.setState({
                    formErrors: {
                        confirmPasswordError: ""
                    }
                })
            }, 6000)
        } else {

            this.handleReset()
        }
    }

    handleReset = () => {
        this.setState({ isloading: true });
        const { password, } = this.state;
        const { forgotPassword } = this.props;
        const payload = {
            userId: forgotPassword.data.userId,
            password
        }
        this.props.reset_password_request(payload)

    }

    renderLoader = () => {
        const { isloading } = this.state
        return (
            <DotsLoader isloading={isloading} />
        )
    }

    render() {
        return (
            <div className="container-fluid">
                <h1 className="h1"><Translation>{t=>t('reset_password')}</Translation></h1>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({ resetPassword: state.resetPassword, forgotPassword: state.forgotPassword });

const action = { reset_password_request, };

export default connect(mapStateToProps, action)(ResetPassword);
