import { connect } from "react-redux";
import React, { Component } from "react";
import { Translation } from "react-i18next";
import i18n from "../../../i18n";
import { Images } from "../../../theme";
import {} from "../../../config/WebServices";
import {
  getAllEmployeeAssociatedWithCompany,
  gitWaitingAppoinments,
  gitAllSaloonAppoinments,
  saloonGetAllService,
  getSalesReport,
} from "../../../config/simpleApiCalls";
import { DotsLoader } from "../../../components";
// import { adminGetUsers } from '../../../config/simpleApiCalls';
import { ErrorHelper } from "../../../helpers";

import { Doughnut } from "react-chartjs-2";
import { Line } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";

import "./styles.css";
import Sytles from "./sytles";
import { getNestedValue } from "../../../util/objectMethods";
import Button from "@material-ui/core/Button";
import moment from "moment";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: false,
      usersList: [],
      blockUsersList: [],
      unblockUsersList: [],
      totalEmploye: [],
      totalCategories: [],
      totalAppointment: "",
    };
    i18n.changeLanguage(sessionStorage.getItem("language"));
  }

  componentDidMount = async () => {
    const saloonData = await JSON.parse(sessionStorage.getItem("saloon"));
    const isGym =
      JSON.parse(sessionStorage.getItem("company")).function == "gym";
    this.setState({ isGym, access_token: saloonData.access_token });
    if (saloonData) {
      this.setState({
        employeePromise: new Promise((resolve, reject) => {
          getAllEmployeeAssociatedWithCompany(saloonData.access_token)
            .then((res) => {
              this.setState({
                totalEmploye: res.data?.Data,
              });
              resolve(res.data?.Data);
            })
            .catch((error) => {
              this.setState({ isLoading: false });
              if (error.response) {
                alert("Error", error.response.data.msg);
              } else if (error.request) {
                alert("Error", "Something Went Wrong");
                // The request was made but no response was received
              } else {
                alert("Error", error.msg);
                // Something happened in setting up the request that triggered an Error
              }
              reject();
            });
        }),
      });
      saloonGetAllService(saloonData.access_token)
        .then((res) => {
          if (res.data.success) {
            this.setState({ isloading: false, totalCategories: res.data.data });
          }
        })
        .catch((error) => {
          console.log(error, "get all services error");
        });

      this.setSelectedValue(1);
    }

    // const user = JSON.parse(sessionStorage.getItem('admin'));
    // if (user && user.access_token) {
    //     this.setState({ user }, this.getAllUsers(user.access_token));
    // } else {
    //     ErrorHelper.handleErrors("Please login your account!", true)
    // }
  };

  convertToBarChartData = (map, label) => {
    const sortedData = Object.values(map).sort((a, b) =>
      a.amount < b.amount ? 1 : -1
    );
    console.log(label);
    const data = {
      label,
      labels: [],
    };
    const colors = [
      "rgba(160, 39, 164,1)",
      "rgba(75,0,192,1)",
      "rgba(75,192,0,1)",
      "rgba(0,192,192,1)",
      "rgba(255, 73, 91,1)",
    ];
    const dataset = {
      data: [],
      backgroundColor: [],
    };
    sortedData.splice(0, 5).map((element, index) => {
      data.labels.push(element.name);
      dataset.data.push(element.amount);
      dataset.backgroundColor.push(colors[index]);
    });

    data.datasets = [dataset];
    console.log(data);
    return data;
  };

  getAllAppointment(token) {
    const convertedStart = moment(this.state.startDate).format("MM-DD-YYYY");
    const convertedEnd = moment(this.state.endDate).format("MM-DD-YYYY");
    let data = {
      start: convertedStart,
      end: convertedEnd,
      relations: ["cart"],
    };
    return new Promise((resolve, reject) => {
      gitAllSaloonAppoinments(token, data)
        .then((res) => {
          const employeeMap = {};
          const productAmountMap = {};
          const serviceBookingAmountMap = {};
          if (res.data.data.length) {
            res.data.data.map((booking) => {
              const employee = getNestedValue(
                booking,
                ["services", 0, "employeeId"],
                {}
              );
              const service = getNestedValue(
                booking,
                ["services", 0, "serviceId"],
                {}
              );
              if (service._id) {
                if (!serviceBookingAmountMap[service._id]) {
                  serviceBookingAmountMap[service._id] = {
                    name: service.name,
                    amount: 0,
                  };
                }
                serviceBookingAmountMap[service._id].amount++;
              }
              if (booking.cartId) {
                getNestedValue(booking.cartId, ["products"], []).map(
                  (product) => {
                    if (!productAmountMap[product.productId._id]) {
                      productAmountMap[product.productId._id] = {
                        name: product.productId.name,
                        amount: 0,
                      };
                    }
                    productAmountMap[product.productId._id].amount++;
                  }
                );
              }
              if (employee._id) {
                if (!employeeMap[employee._id]) {
                  employeeMap[employee._id] = {
                    numberOfBookings: 0,
                    label: getNestedValue(
                      employee,
                      ["userId", "firstName"],
                      i18n.t("name")
                    ),
                  };
                }
                employeeMap[employee._id].numberOfBookings++;
              }
            });
          }
          const topProductData = this.convertToBarChartData(
            productAmountMap,
            i18n.t("top_products")
          );
          const topServiceData = this.convertToBarChartData(
            serviceBookingAmountMap,
            i18n.t("top_services")
          );
          this.setState({
            totalAppointment: res.data.data.length,
            topProductData,
            topServiceData,
          });
          resolve(employeeMap);
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          console.log("err", error);
          if (error.response) {
            alert("Error", error.response.data.msg);

            // Request made and server responded
          } else if (error.request) {
            alert("Error", "Something Went Wrong ");

            // The request was made but no response was received
          } else {
            alert("Error", error.msg);
            // Something happened in setting up the request that triggered an Error
          }
          reject();
        });
    });
  }

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  _renderEmployeeCard = () => {
    const { totalEmploye, totalCategories } = this.state;

    return (
      <>
        <div className="col-12 col-md-6 col-lg-4 p-3">
          <div className="py-3 px-3 px-md-4 px-lg-4 px-xl-5 mb-5 dashboard-box">
            <div className="row">
              <div className="col-4">
                <span className="dashboard-card-title mt-1">
                  {i18n.t("container.add_employe_employees")}
                </span>

                <div className="text-left">
                  <span className="dashboard-card-number">
                    {totalEmploye?.length ? totalEmploye.length : "0"}
                  </span>
                </div>
              </div>
              <div className="col-8">
                <div className="text-center">
                  <div className="text-center">
                    <img className="my-1" src={Images._preview} />
                  </div>
                  <Button
                    onClick={() =>
                      this.props.history.push("saloon-company-employee")
                    }
                    style={{
                      backgroundColor: "#002147",
                      color: "white",
                    }}
                    className="my-1"
                  >
                    {i18n.t("container.dashboard_view_all")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  baseCard = (content, title) => (
    <div className="p-3 p-md-4 dashboard-box mb-5">
      <span className="dashboard-card-title mt-1">
        <Translation>{(t) => t(title)}</Translation>
      </span>
      {content}
    </div>
  );
  _renderCategoriesCard = () => {
    const { totalEmploye, totalCategories } = this.state;

    return (
      <>
        <div className="col-12 col-md-6 col-lg-4 p-3">
          <div
            className="py-3 px-3 px-md-4 px-lg-4 px-xl-5 mb-5 dashboard-box"
            style={{ backgroundColor: "#FFFFFF" }}
          >
            <div className="row">
              <div className="col-4">
                <span className="dashboard-card-title mt-1">
                  <Translation>{(t) => t("container.services")}</Translation>
                </span>

                <div className="text-left">
                  <span className="dashboard-card-number">
                    {totalCategories?.length ? totalCategories.length : "0"}
                  </span>
                </div>
              </div>
              <div className="col-8">
                <div className="text-center">
                  <img className="my-1" src={Images.Layer5} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  _renderSupportCard = () => {
    const { totalAppointment } = this.state;

    return (
      <>
        <div className="col-12 col-md-6 col-lg-4 p-3">
          <div
            className="py-3 px-3 px-md-4 px-lg-4 px-xl-5 mb-5 dashboard-box"
            style={{ backgroundColor: "#FFFFFF" }}
          >
            <div className="row">
              <div className="col-4">
                <span className="dashboard-card-title mt-1">
                  <Translation>
                    {(t) => t("container.appointments")}
                  </Translation>
                </span>

                <div className="text-left">
                  <span className="dashboard-card-number">
                    {totalAppointment}
                  </span>
                </div>
              </div>
              <div className="col-8">
                <div className="text-center">
                  <div className="text-center">
                    <img className="my-1" src={Images.Layer6} />
                  </div>
                  <Button
                    onClick={() =>
                      this.props.history.push("saloon-my-appointment")
                    }
                    style={{
                      backgroundColor: "#002147",
                      color: "white",
                    }}
                    className="my-1"
                  >
                    {i18n.t("container.dashboard_view_all")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  _renderCard = () => {
    const { isGym } = this.state;
    return (
      <>
        <div className="row mx-0">
          {!isGym && this._renderEmployeeCard()}
          {this._renderCategoriesCard()}
          {this._renderSupportCard()}
        </div>
      </>
    );
  };

  setSelectedValue = (val, previous) => {
    let currentDate = moment(Date.now());
    const { employeePromise, access_token } = this.state;
    this.setState({ isloading: true });
    const callback = () => {
      Promise.all([
        this.getAllAppointment(access_token),
        employeePromise,
        this.getSalesReport(),
      ]).then((res) => {
        const data = {
          labels: [],
        };
        const employeeMap = res[0];
        const dataset = {
          data: [],
          backgroundColor: [],
          hoverBackgroundColor: [],
        };
        const companyEmployees = res[1];
        Object.keys(employeeMap).map((employeeId) => {
          if (employeeId) {
            data.labels.push(employeeMap[employeeId].label);
            dataset.data.push(employeeMap[employeeId].numberOfBookings);
            const companyEmployee = companyEmployees.find(
              (element) => element.employeeId._id == employeeId
            );
            if (companyEmployee) {
              dataset.backgroundColor.push(companyEmployee.color);
              dataset.hoverBackgroundColor.push(companyEmployee.color);
            }
          }
        });
        data.datasets = [dataset];
        this.setState({
          bookingByEmployeeData: data,
          isloading: false,
          salesReportData: res[2],
        });
      });
    };
    if (val == 1) {
      if (previous) {
        currentDate.subtract(1, "M");
      }
      const startOfMonth = moment(currentDate).startOf("month");
      const endOfMonth = moment(currentDate).endOf("month");
      this.setState(
        { startDate: startOfMonth.toDate(), endDate: endOfMonth.toDate() },
        callback
      );
    } else if (val == 2) {
      if (previous) {
        currentDate.subtract(1, "quarter");
      }
      const startOfQuarter = moment(currentDate).startOf("quarter");
      const endOfQuarter = moment(currentDate).endOf("quarter");
      this.setState(
        { startDate: startOfQuarter.toDate(), endDate: endOfQuarter.toDate() },
        callback
      );
    } else if (val == 3) {
      if (previous) {
        currentDate.subtract(1, "y");
      }
      const convertedStart = moment(
        `01-01-${currentDate.year()}`,
        "MM-DD-YYYY"
      );
      const convertedEnd = moment(`12-31-${currentDate.year()}`, "MM-DD-YYYY");
      this.setState(
        { startDate: convertedStart.toDate(), endDate: convertedEnd.toDate() },
        callback
      );
    }
  };

  getSalesReport = () => {
    this.setState({ isLoading: true }, () => {});
    const convertedStart = moment(this.state.startDate).format("MM-DD-YYYY");
    const convertedEnd = moment(this.state.endDate).format("MM-DD-YYYY");
    let data = {
      start: convertedStart,
      end: convertedEnd,
    };

    let token = JSON.parse(sessionStorage.getItem("saloon"));
    return new Promise((resolve, reject) => {
      getSalesReport(data, token.access_token)
        .then((res) => {
          const start = moment(this.state.startDate);
          const today = moment(moment.now()).format("MM-DD-YYYY");
          if (res.data.success) {
            this.setState({ isLoading: false });
          }
          const transactionDateMap = {};
          const baseAmounts = {
            extrasTotal: 0,
            servicesTotal: 0,
            productsTotal: 0,
            subtotal: 0,
            tip: 0,
          };
          getNestedValue(res.data, ["data", "transactions"], []).map(
            (transaction) => {
              const formattedDate = moment(transaction.createdDate).format(
                "MM-DD-YYYY"
              );
              if (!transactionDateMap[formattedDate]) {
                transactionDateMap[formattedDate] = { ...baseAmounts };
              }
              transactionDateMap[formattedDate].extrasTotal += parseFloat(
                transaction.extrasTotal
              );
              transactionDateMap[formattedDate].servicesTotal += parseFloat(
                transaction.servicesTotal
              );
              transactionDateMap[formattedDate].productsTotal += parseFloat(
                transaction.productsTotal
              );
              transactionDateMap[formattedDate].subtotal += parseFloat(
                transaction.subtotal
              );
              transactionDateMap[formattedDate].tip += parseFloat(
                transaction.tip
              );
            }
          );
          const labels = [];
          do {
            labels.push(start.format("MM-DD-YYYY"));
            start.add(1, "days");
          } while (
            start.format("MM-DD-YYYY") != convertedEnd &&
            start.format("MM-DD-YYYY") != today
          );
          const salesReportData = {
            labels,
            datasets: [
              {
                label: i18n.t("extras"),
                data: labels.map((date) =>
                  getNestedValue(transactionDateMap, [date, "extrasTotal"], 0)
                ),
                backgroundColor: "rgba(160, 39, 164,0.1)",
                borderColor: "rgba(160, 39, 164,1)",
                pointBorderColor: "rgba(160, 39, 164,1)",
                pointHoverBackgroundColor: "rgba(160, 39, 164,1)",
                pointHoverBorderColor: "rgba(160, 39, 164,1)",
              },
              {
                label: i18n.t("services"),
                data: labels.map((date) =>
                  getNestedValue(transactionDateMap, [date, "servicesTotal"], 0)
                ),
                backgroundColor: "rgba(75,0,192,0.1)",
                borderColor: "rgba(75,0,192,1)",
                pointBorderColor: "rgba(75,0,192,1)",
                pointHoverBackgroundColor: "rgba(75,0,192,1)",
                pointHoverBorderColor: "rgba(75,0,192,1)",
              },
              {
                label: i18n.t("products"),
                data: labels.map((date) =>
                  getNestedValue(transactionDateMap, [date, "productsTotal"], 0)
                ),
                backgroundColor: "rgba(75,192,0,0.1)",
                borderColor: "rgba(75,192,0,1)",
                pointBorderColor: "rgba(75,192,0,1)",
                pointHoverBackgroundColor: "rgba(75,192,0,1)",
                pointHoverBorderColor: "rgba(75,192,0,1)",
              },
              {
                label: i18n.t("subtotal"),
                data: labels.map((date) =>
                  getNestedValue(transactionDateMap, [date, "subtotal"], 0)
                ),
                backgroundColor: "rgba(0,192,192,0.1)",
                borderColor: "rgba(0,192,192,1)",
                pointBorderColor: "rgba(0,192,192,1)",
                pointHoverBackgroundColor: "rgba(0,192,192,1)",
                pointHoverBorderColor: "rgba(0,192,192,1)",
              },
              {
                label: i18n.t("tip"),
                data: labels.map((date) =>
                  getNestedValue(transactionDateMap, [date, "tip"], 0)
                ),
                backgroundColor: "rgba(255, 73, 91,0.1)",
                borderColor: "rgba(255, 73, 91,1)",
                pointBorderColor: "rgba(255, 73, 91,1)",
                pointHoverBackgroundColor: "rgba(255, 73, 91,1)",
                pointHoverBorderColor: "rgba(255, 73, 91,1)",
              },
            ],
          };
          resolve(salesReportData);
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          reject();
        });
    });
  };

  openMenu = (event, menuItems) => {
    this.setState({ anchor: event.currentTarget, menuItems });
  };

  render() {
    const {
      usersList,
      blockUsersList,
      unblockUsersList,
      bookingByEmployeeData = {},
      salesReportData = {},
      topProductData = {},
      topServiceData = {},
      anchor,
      menuItems = [],
    } = this.state;
    return (
      <div>
        {this.renderLoader()}
        <div className="content-container">
          <div className="row mx-0">
            <div className="col-12 pt-3 row mx-o justify-content-between">
              <h1 className="Dashboard_heading">
                {i18n.t("container.dashboard_for_salon")}
              </h1>
              <div className="row mx-0">
                <Menu
                  id="simple-menu"
                  anchorEl={anchor}
                  keepMounted
                  open={Boolean(anchor)}
                  onClose={() =>
                    this.setState({ anchor: undefined, menuItems: [] })
                  }
                >
                  {menuItems.map((item) => (
                    <MenuItem
                      onClick={() => {
                        this.setState({ anchor: undefined, menuItems: [] });
                        this.setSelectedValue(...item.params);
                      }}
                    >
                      {item.label}
                    </MenuItem>
                  ))}
                </Menu>
                <Button
                  onClick={(ev) =>
                    this.openMenu(ev, [
                      {
                        label: i18n.t("container.previous"),
                        params: [1, true],
                      },
                      {
                        label: i18n.t("container.current"),
                        params: [1, false],
                      },
                    ])
                  }
                  style={{
                    backgroundColor: "#ff3600",
                    color: "white",
                  }}
                  className="mr-1 mt-1"
                >
                  {i18n.t("container.dashboard_month")}
                </Button>
                <Button
                  onClick={(ev) =>
                    this.openMenu(ev, [
                      {
                        label: i18n.t("container.previous"),
                        params: [2, true],
                      },
                      {
                        label: i18n.t("container.current"),
                        params: [2, false],
                      },
                    ])
                  }
                  style={{
                    backgroundColor: "#ff3600",
                    color: "white",
                  }}
                  className="mr-1 mt-1"
                >
                  {i18n.t("container.dashboard_quarter")}
                </Button>
                <Button
                  onClick={(ev) =>
                    this.openMenu(ev, [
                      {
                        label: i18n.t("container.previous"),
                        params: [3, true],
                      },
                      {
                        label: i18n.t("container.current"),
                        params: [3, false],
                      },
                    ])
                  }
                  style={{
                    backgroundColor: "#ff3600",
                    color: "white",
                  }}
                  className="mt-1"
                >
                  {i18n.t("container.dashboard_year")}
                </Button>
              </div>
            </div>
            <div className="col-md-6 col-xs-12 pt-3">
              {this.baseCard(
                <Doughnut data={bookingByEmployeeData} />,
                "booking_by_employee"
              )}
            </div>
            <div className="col-md-6 col-xs-12 pt-3">
              {this.baseCard(<Line data={salesReportData} />, "sales_revenue")}
            </div>
            <div className="col-md-6 col-xs-12 pt-3">
              {this.baseCard(
                <Bar
                  data={topProductData}
                  options={{
                    legend: {
                      display: false,
                    },
                  }}
                />,
                "top_products"
              )}
            </div>
            <div className="col-md-6 col-xs-12 pt-3">
              {this.baseCard(
                <Bar
                  data={topServiceData}
                  options={{
                    legend: {
                      display: false,
                    },
                  }}
                />,
                "top_services"
              )}
            </div>
          </div>
          {this._renderCard()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(Dashboard);
