import { connect } from "react-redux";
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { useMemo, useState } from "react";
import { DotsLoader, Header } from "../../components";
import { Footer } from "../../components/Saloon";
import AppSidebar from "../../components/AppSidebar";
import { setOpened } from "../../redux/actions/Sidebar";
import { SidebarContent } from "../../components/Saloon";
import { useEffect } from "react";

function PrivateRoute(props) {
  const {
    user,
    sidebarOpen,
    docked,
    Component,
    setOpened,
    type,
    resource,
    receptionistAllowedResource = {},
    managerAllowedResource = {},
    resourcesLoading,
    ...rest
  } = props;
  const [hasReceptionAccess, setHasReceptionAccess] = useState(false);
  const [hasManagerAccess, setHasManagerAccess] = useState(false);
  useEffect(() => {
    setOpened(docked);
    const employeeData = JSON.parse(sessionStorage.getItem("employee"));
    setHasReceptionAccess(employeeData?.role?.includes("6"));
    setHasManagerAccess(employeeData?.role?.includes("7"));
  }, []);
  const hasAccess = useMemo(() => {
    if (!resource) {
      return true;
    }
    return (
      (hasReceptionAccess && receptionistAllowedResource[resource]) ||
      (hasManagerAccess && managerAllowedResource[resource]) ||
      user?.role?.includes("3")
    );
  }, [receptionistAllowedResource, managerAllowedResource, user, resource]);
  const isAuthenticated = useMemo(() => {
    if (type == "saloon") {
      return (
        user &&
        user.access_token &&
        user.role &&
        (user.role.includes("3") ||
          user.role.includes("6") ||
          user.role.includes("7"))
      );
    } else if (type == "employee") {
      return user && user.access_token && user.role && user.role.includes("4");
    }
  }, [type, user]);
  const onSetOpen = () => {
    setOpened(!sidebarOpen);
  };
  return (
    <Route
      exact
      {...{
        ...rest,
        props: { user },
      }}
      render={(routeProps) =>
        isAuthenticated && user ? (
          <>
            <Header {...props} onSetSidebarOpen={onSetOpen} docked={docked} />
            <AppSidebar
              children={<SidebarContent {...props} />}
              open={sidebarOpen}
              docked={docked && sidebarOpen}
              onSetOpen={onSetOpen}
              sidebarClassName="custom-sidebar"
              content={
                <>
                  <div
                    className="container-fluid px-0"
                    style={{ backgroundColor: "#F7F7F7" }}
                  >
                    <div className="routes-main-content">
                      {resourcesLoading ? (
                        <DotsLoader isloading={true} />
                      ) : (
                        // The null here sould be replaced with a 403 page
                        <>{hasAccess ? <Component {...routeProps} /> : null}</>
                      )}
                    </div>
                  </div>
                  <Footer />
                </>
              }
            ></AppSidebar>
          </>
        ) : (
          <Redirect
            to={{
              pathname: "/partner-login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

const mapStateToProps = (state) => ({
  saloonLogin: state.saloonLogin,
  impersonateSalon: state.impersonateSalon,
  user:
    (state.user.data.saloon
      ? JSON.parse(state.user.data.saloon)
      : state.user.data.saloon) ||
    (state.user.data.employee
      ? JSON.parse(state.user.data.employee)
      : state.user.data.employee),
  docked: state.sidebar.isDocked,
  sidebarOpen: state.sidebar.isOpen,
  receptionistAllowedResource: state.receptionistAccess.data?.resourceMap,
  managerAllowedResource: state.managerAccess.data?.resourceMap,
  resourcesLoading:
    state.receptionistAccess.isFetching || state.managerAccess.isFetching,
});

const action = { setOpened };

export default connect(mapStateToProps, action)(PrivateRoute);
