import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import i18n from "../../i18n";
import "./styles.scss";

const BaseModal = ({
  open,
  title,
  content,
  actions,
  onClose,
  style,
  containerClassName = "container",
  contentClassName = "",
}) => {
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down("xs"));
  const TitleTag = isXS ? "h5" : "h3";
  return (
    <div className="base-modal">
      <div
        className="employee-service-card-wrapper"
        style={{
          display: open ? "flex" : "none",
          zIndex: 1301,
          overflowY: "hidden",
          ...style,
        }}
      >
        <div
          className={containerClassName}
          style={{ alignSelf: "center", maxHeight: "100%" }}
        >
          <div
            className="col-12 px-0 my-3 bg-white pop_container base-modal-body"
            style={{ borderRadius: "4px" }}
          >
            <div className="row mx-0 mb-2 justify-content-between base-modal-title">
              <TitleTag className="title pr-2">{title}</TitleTag>
              <Button
                onClick={onClose}
                style={{
                  backgroundColor: "white",
                  color: "black",
                  fontWeight: "bold",
                  fontSize: 14,
                }}
                size="small"
              >
                {i18n.t("close")}
              </Button>
            </div>
            <div className={`base-modal-content ${contentClassName}`}>
              {content}
            </div>
            {actions && <div className="base-modal-actions">{actions}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BaseModal;
