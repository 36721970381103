// @flow

import { PROGRAM_ENROLLMENT } from "../ActionTypes";

export function getRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: PROGRAM_ENROLLMENT.GET_SAGA,
  };
}

export function get(data) {
  return {
    data,
    type: PROGRAM_ENROLLMENT.GET,
  };
}

export function removeRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: PROGRAM_ENROLLMENT.DELETE_SAGA,
  };
}

export function remove(data) {
  return {
    data,
    type: PROGRAM_ENROLLMENT.DELETE,
  };
}

export function insertRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: PROGRAM_ENROLLMENT.INSERT_SAGA,
  };
}

export function insert(data) {
  return {
    data,
    type: PROGRAM_ENROLLMENT.INSERT,
  };
}

export function reverseTransactionRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: PROGRAM_ENROLLMENT.REVERSE_TRANSACTION_SAGA,
  };
}

export function reverseTransaction(data) {
  return {
    data,
    type: PROGRAM_ENROLLMENT.REVERSE_TRANSACTION,
  };
}

export function addTransactionRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: PROGRAM_ENROLLMENT.ADD_TRANSACTION_SAGA,
  };
}

export function addTransaction(data) {
  return {
    data,
    type: PROGRAM_ENROLLMENT.ADD_TRANSACTION,
  };
}

export function setStudentEnrollmentRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: PROGRAM_ENROLLMENT.SET_STUDENT_ENROLLMENT_SAGA,
  };
}

export function setStudentEnrollment(data) {
  return {
    data,
    type: PROGRAM_ENROLLMENT.SET_STUDENT_ENROLLMENT,
  };
}

export function requestFailure() {
  return {
    type: PROGRAM_ENROLLMENT.REQUEST_FAILURE,
  };
}
