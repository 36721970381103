import React, { useEffect, useState } from "react";
import { BaseModal } from "../..";
import { addProduct, addExtra } from "../../../config/simpleApiCalls";
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import i18n from "../../../i18n";
import DotsLoader from "../../DotsLoader";
import AddExtra from "../AddExtra";

const AddExtrasModal = ({
  selectBooking,
  products = [],
  access_token,
  bookings,
  allServices,
  onUpdate = () => {},
  handleClose = () => {},
}) => {
  let services = allServices;
  const [isloading, setIsloading] = useState(false);
  const onAdd = async (type, values) => {
    setIsloading(true);
    if (type) {
      const { data } = await (type == "product" ? addProduct : addExtra)(
        { bookingId: selectBooking._id, ...values },
        access_token
      );
      if (data.success) {
        let newSelectBooking = selectBooking;
        if (type == "product") {
          if (
            typeof selectBooking.index === "number" &&
            bookings[selectBooking.index]
          ) {
            bookings[selectBooking.index].cartId = data.data;
          }
          newSelectBooking = { ...selectBooking, cartId: data.data };
        } else {
          if (
            typeof selectBooking.index === "number" &&
            bookings[selectBooking.index]
          ) {
            bookings[selectBooking.index] = {
              ...bookings[selectBooking.index],
              extras: data.data.extras,
            };
          }
          newSelectBooking = { ...selectBooking, extras: data.data.extras };
        }
        SuccessHelper.handleSuccess("Added Item", true);
        handleClose();
        onUpdate({ selectBooking: { ...newSelectBooking }, bookings });
      } else {
        console.log(data.code);
        if (data.code) {
          ErrorHelper.handleErrors(i18n.t(data.code, { ...data.data }), true);
        } else {
          ErrorHelper.handleErrors("Failed to Add Item", true);
        }
      }
    }
    setIsloading(false);
  };
  return (
    <>
      <DotsLoader />
      <BaseModal
        style={{ zIndex: 1302 }}
        open={true}
        title={i18n.t("container.add_extras")}
        content={
          <AddExtra
            services={services}
            products={products}
            closaModal={handleClose}
            onAdd={onAdd}
          />
        }
        onClose={() => handleClose()}
      />
    </>
  );
};

export default AddExtrasModal;
