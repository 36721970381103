import React, { useEffect, useState } from "react";
import i18n from "../../i18n";
import { getBookingById } from "../../config/simpleApiCalls";
import Swal from "sweetalert2";
import PrimaryButton from "../../components/Core/PrimaryButton";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { DotsLoader } from "../../components";
import {
  confirmBooking,
  quickCancelBooking,
  getMessageTemplate,
} from "../../config/simpleApiCalls";
import { ErrorHelper, SuccessHelper } from "../../helpers";

export default (props) => {
  const [open, setOpen] = useState(true);
  const urlParams = new URLSearchParams(window.location.search);
  const paramId = urlParams.get("id");
  const {
    match: {
      params: { id: urlId, type },
    },
  } = props;
  const id = urlId ? urlId : paramId;
  const confirmButton = {
    label: i18n.t("confirm"),
    type: "primary",
    onClick: () => {
      confirmBooking({ id: booking._id })
        .then(({ data }) => {
          if (data.success) {
            setBooking({ ...booking, isConfirmed: true });
            SuccessHelper.handleSuccess(i18n.t("booking_confirmed"), true);
          } else {
            ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
          }
        })
        .catch(() => {
          ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
        });
    },
  };
  const typeToActionsMap = {
    td: [
      {
        label: i18n.t("details"),
        type: "primary",
        onClick: (booking) => {
          props.history.push(
            `/transfer-details/${booking.shortenedId || booking._id}`
          );
        },
      },
    ],
    ff: [],
    bc: [confirmButton],
    bcd: [
      {
        label: i18n.t("pay"),
        type: "primary",
        onClick: (booking) => {
          window.open(
            `https://pay.easy1booking.com/${
              booking.shortenedId || booking._id
            }`,
            "_blank"
          );
        },
      },
    ],
    br: (booking) => {
      let actions = [
        {
          label: i18n.t("cancel_booking"),
          type: "primary",
          onClick: () => {
            quickCancelBooking({ bookingId: booking._id })
              .then(({ data }) => {
                if (data.success) {
                  SuccessHelper.handleSuccess(
                    i18n.t("booking_cancelled"),
                    true
                  );
                } else {
                  if (data.err_code == "must_auth") {
                    ErrorHelper.handleErrors(i18n.t("must_login"), true);
                  } else {
                    ErrorHelper.handleErrors(
                      i18n.t("something_went_wrong"),
                      true
                    );
                  }
                }
              })
              .catch(() => {
                ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
              });
          },
        },
      ];
      if (!booking.isConfirmed) {
        actions.push(confirmButton);
      } else {
        actions.push({
          label: i18n.t("keep_my_booking"),
          type: "primary",
          onClick: () => {
            setOpen(false);
          },
        });
      }
      return actions;
    },
    pr: [
      {
        label: i18n.t("details"),
        type: "primary",
        onClick: (booking) => {
          window.open(
            `https://pay.easy1booking.com/${
              booking.shortenedId || booking._id
            }`,
            "_blank"
          );
        },
      },
    ],
    dr: [
      {
        label: i18n.t("details"),
        type: "primary",
        onClick: (booking) => {
          window.open(
            `https://pay.easy1booking.com/${
              booking.shortenedId || booking._id
            }`,
            "_blank"
          );
        },
      },
    ],
    in: [
      {
        label: "Télécharger",
        type: "primary",
        onClick: (booking) => {
          window.location.replace(
            `https://pdf-service-q3s5v4pl4q-uc.a.run.app/fr/invoice/${
              booking.shortenedId || booking._id
            }`
          );
        },
      },
      {
        label: "Download",
        type: "primary",
        onClick: (booking) => {
          window.location.replace(
            `https://pdf-service-q3s5v4pl4q-uc.a.run.app/en/invoice/${
              booking.shortenedId || booking._id
            }`
          );
        },
      },
    ],
    fs: [
      {
        label: i18n.t("view_forms"),
        type: "primary",
        onClick: () => {
          props.history.push(`/f-group/${id}`);
        },
      },
    ],
  };
  const [loading, setLoading] = useState(false);
  const [booking, setBooking] = useState({});
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  useEffect(() => {
    setLoading(true);
    Promise.all([
      getBookingById({ id }).then(({ data }) => {
        const booking = data.data;
        setBooking(booking);
      }),
      getMessageTemplate({ bookingId: id, type }).then(({ data }) => {
        const message = data.data;
        setTitle(type);
        setMessage(message);
      }),
    ])
      .catch(() => {
        ErrorHelper.handleErrors("Something went wrong", true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  const actions =
    typeof typeToActionsMap[type] == "function"
      ? typeToActionsMap[type](booking)
      : typeToActionsMap[type];
  return (
    <div>
      {/* <Home {...props} /> */}
      <DotsLoader isloading={loading} />
      <Dialog
        open={open}
        onClose={() => {
          props.history.push("/");
        }}
        aria-labelledby="custom-message-title"
        aria-describedby="custom-message-description"
      >
        <DialogTitle id="custom-message-title">{i18n.t(title)}</DialogTitle>
        <DialogContent>
          <DialogContentText id="custom-message-description">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {actions.map((action, i) => {
            return (
              <div key={i}>
                <PrimaryButton
                  label={action.label}
                  onClick={() => action.onClick(booking)}
                />
              </div>
            );
          })}
        </DialogActions>
      </Dialog>
    </div>
  );
};
