import React, { useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  root: {
    flexGrow: 1,
  },
  tabsBar: {
    "& .MuiTabs-indicator": {
      // display: "none",
      backgroundColor: "orange",
    },
  },
}));

function TabPanel(props) {
  const { key, children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box key={key} p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default function _Tabs({ tabs }) {
  const classes = useStyles();
  const [currentTab, setTab] = React.useState(0);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          className={classes.tabsBar}
          value={currentTab}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          {tabs.map((tab, index) => {
            return <Tab key={tab.title + index} label={tab.title} />;
          })}
        </Tabs>
      </AppBar>
      {tabs.map((tab, index) => {
        return (
          <TabPanel key={tab.title + index} value={currentTab} index={index}>
            {tab.content ? tab.content() : null}
          </TabPanel>
        );
      })}
    </div>
  );
}
