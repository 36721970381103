var firebase = require("firebase");
// const firebaseConfig = {
//   apiKey: "AIzaSyChf15R8ZDTYmmuEEHGZUIdGEQhfF6bOiE",
//   authDomain: "esayone-9821d.firebaseapp.com",
//   databaseURL: "https://esayone-9821d.firebaseio.com",
//   projectId: "esayone-9821d",
//   storageBucket: "esayone-9821d.appspot.com",
//   messagingSenderId: "721316989519",
//   appId: "1:721316989519:web:b94a3ba5c485af1e43383a",
//   measurementId: "G-RWJMVC0VD9",
// };
// var app = firebase.initializeApp(firebaseConfig);
const firestoreN = firebase.firestore;

export { addCollectionWith, getDataByKey, saveMsg, getMsgs };

const addCollectionWith = ({ collectionName, data }) => {
  return firestoreN().collection(collectionName).add(data);
};

const getDataByKey = ({ collectionName, key, value }) => {
  return firestoreN().collection(collectionName).where(key, "==", value).get();
};

const saveMsg = ({ collectionName, id, data }) => {
  return firestoreN()
    .collection("chatRooms")
    .doc(id)
    .collection(collectionName)
    .add(data);
};

const getMsgs = ({ collectionName, id }) => {
  console.log("getMsgs -> {collectionName, id}", { collectionName, id });
  return firestoreN()
    .collection("chatRooms")
    .doc(id)
    .collection(collectionName);
};
