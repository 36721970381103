import { connect } from "react-redux";
import React, { Component } from "react";
import i18n from "../../../i18n";
import { Translation, withTranslation } from "react-i18next";

import Pagination from "react-js-pagination";
import { hireCompanyEmployee } from "../../../config/simpleApiCalls";
import {
  getAllEmployeeAssociatedWithCompany,
  saloonGetAllService,
  getAllEmployee,
  getEmployeeNotAssociatedWithCompany,
} from "../../../config/simpleApiCalls";
import { Images } from "../../../theme";
import { DotsLoader } from "../../../components";
// import { adminGetUsers, adminDeleteUser } from '../../../config/simpleApiCalls';
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import moment from "moment";
import NumberFormat from "react-number-format";
import { SketchPicker } from "react-color";
import styled from "styled-components";

import "./styles.css";
import Sytles from "./sytles";
const PinInput = styled.input`
  background-color: #ffffff;
  border-color: #ebebeb;
`;

const weekNames = moment.weekdaysShort();
class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      access_token: "",
      user: null,
      isloading: false,
      showEditDelete: "",
      searchText: "",
      activePage: 1,
      usersList: null,
      employeeList: [],
      selectedEmployeeId: null,
      filterUsersList: [],
      selectedMap: {},
      showAddEmployeePopup: false,
      employeesWorkingHoursData: [
        { dayName: "Sunday", dayOfWeek: 1, availableStatus: 0 },
        { dayName: "Monday", dayOfWeek: 2, availableStatus: 0 },
        { dayName: "Tuesday", dayOfWeek: 3, availableStatus: 0 },
        { dayName: "Wednesday", dayOfWeek: 4, availableStatus: 0 },
        { dayName: "Thursday", dayOfWeek: 5, availableStatus: 0 },
        { dayName: "Friday", dayOfWeek: 6, availableStatus: 0 },
        { dayName: "Saturday", dayOfWeek: 7, availableStatus: 0 },
      ],
      // Services
      serviceList: [],
      isChecked: false,
      userCheckInInp: null,
      userCheckOutInp: null,
    };
  }

  componentDidMount = async () => {
    const saloonData = await JSON.parse(sessionStorage.getItem("saloon"));
    if (saloonData) {
      this.setState({
        access_token: saloonData.access_token,
      });
      // this.getAllEmployees();
      this.handleSaloonGetAllService();
      this.getNotAssociateEmployee();
    }
  };

  handleSaloonGetAllService = () => {
    this.setState({ isloading: true });
    const { access_token } = this.state;
    saloonGetAllService(access_token)
      .then((res) => {
        if (res.data.success) {
          this.setState({
            isloading: false,
            serviceList: res.data.data.filter(
              (service) => service.isActive === 1 && service.serviceId
            ),
          });
        }
      })
      .catch((error) => {});
  };

  getAllEmployees = () => {
    let allUsersList = [];
    let verifiedUsersList = [];

    this.setState({ isloading: true });
    const { access_token } = this.state;
    getAllEmployee(access_token)
      .then((res) => {
        if (res.data.success) {
          this.setState(
            { isloading: false, usersList: res.data.data },
            () => {}
          );
          allUsersList.push(res.data.data);
        }
      })
      .catch((error) => {});
  };

  getNotAssociateEmployee = () => {
    const { access_token } = this.state;
    this.setState({ isloading: true });

    getEmployeeNotAssociatedWithCompany(access_token)
      .then((res) => {
        let response = res.data.data;
        let filteredData = response;
        // let newData = []
        this.setState({
          isloading: false,
          usersList: response,
          allusers: response,
        });

        // if (res.data.success) {
        //   response.map((val, ind) => {
        //     val.weekPlans.map(value => {
        //       newData.push({ ...value, isChecked: false })
        //       filteredData[ind].weekPlans = newData

        //     })
        //   })
        //   this.setState({ isloading: false, usersList: filteredData });
        // }
        // console.log(newData, 'newData');
      })
      .catch((error) => {
        this.setState({ isloading: false });
      });
  };

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  onChangeSearchText = (text) => {
    const { usersList } = this.state;
    let filterUsersList =
      usersList &&
      usersList.filter((val) => {
        if (!val.userId) {
          return false;
        }
        let firstName = val.userId.firstName.toUpperCase();
        let lastName = val.userId.lastName.toUpperCase();
        let email = val.userId.email.toUpperCase();
        let phoneNo = `${val.userId.phoneNo}`;
        let searchText = text.target.value.toUpperCase();
        return (
          firstName.indexOf(searchText) > -1 ||
          lastName.indexOf(searchText) > -1 ||
          phoneNo.indexOf(searchText) > -1 ||
          email.indexOf(searchText) > -1
        );
      });
    this.setState({
      filterUsersList,
      searchText: text.target.value,
      activePage: 1,
    });
  };

  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber });
  };

  onChangeWorkingHrCheckbox = (e, ind, val) => {
    const { selectedEmployeeId, usersList, employeesWorkingHoursData } =
      this.state;
    let employe = usersList.filter(
      (employe) => employe._id === selectedEmployeeId
    );

    if (employeesWorkingHoursData[ind].availableStatus === 1) {
      employe[0].weekPlans[ind].isChecked = true;
      employeesWorkingHoursData[ind].checkIn = "";
      employeesWorkingHoursData[ind].checkOut = "";
      this.setState({ usersList: employe });
    } else {
      employe[0].weekPlans[ind].isChecked = true;
      employeesWorkingHoursData[ind].checkIn = val.checkIn;
      employeesWorkingHoursData[ind].checkOut = val.checkOut;
    }

    if (e.target.checked) {
      employeesWorkingHoursData[ind].availableStatus = 1;
      this.setState({ employeesWorkingHoursData });
    } else {
      employeesWorkingHoursData[ind].availableStatus = 0;
      this.setState({ employeesWorkingHoursData });
    }
  };

  renderTimeSlot = (time, index) => {
    const { arrSelectDate } = this.state;
    const selectDayOfTime = arrSelectDate[index + 1].selectDayOfTime;
    if (!selectDayOfTime) return;
    const selectedTime = moment(time).format("h:mm:ss a");
    document.querySelectorAll(".disabled-times").forEach(function (a) {
      a.remove();
    });

    const checkIn = new Date(`01/01/2001 ${selectDayOfTime.checkIn}`).getTime();
    const checkOut = new Date(
      `01/01/2001 ${selectDayOfTime.checkOut}`
    ).getTime();
    const a = new Date(`01/01/2001 ${selectedTime}`).getTime();

    if (
      Math.min(checkIn, checkOut) <= Math.max(a, a) &&
      Math.max(checkIn, checkOut) >= Math.min(a, a)
    )
      return undefined;

    return "disabled-times";
  };
  onChangePrice = (text, ind, val) => {
    const { selectedMap } = this.state;
    selectedMap[val._id].price = parseFloat(text.target.value);
    this.setState({ selectedMap });
  };

  onChangeServiceCheckbox = (e, ind, val) => {
    const { serviceList, selectedMap } = this.state;
    const newSelectedMap = { ...selectedMap };
    if (e.target.checked) {
      newSelectedMap[val._id] = val;
    } else {
      delete newSelectedMap[val._id];
    }
    this.setState({ selectedMap: newSelectedMap });
    const { selectedServices } = this.state;
    if (e.target.checked) {
      serviceList[ind].isSelected = true;
      this.setState({ serviceList });
    } else {
      serviceList[ind].isSelected = false;
      this.setState({ serviceList });
    }
  };

  onChangeCustomerDetailsVisibility = (e) => {
    const { settings } = this.state;
    settings.isEmployeeIndependent = e.target.checked;
    this.setState({
      settings,
    });
  };

  onChangeReceptionist = (e) => {
    const { settings } = this.state;
    settings.isReceptionist = e.target.checked;
    this.setState({
      settings,
    });
  };

  onChangeManager = (e) => {
    const { settings } = this.state;
    settings.isManager = e.target.checked;
    this.setState({
      settings,
    });
  };

  onChangeCustomerCheckInSetting = (e) => {
    const { settings } = this.state;
    settings.allowCheckIn = e.target.checked;
    this.setState({
      settings,
    });
  };

  onChangeDisplayWorkHours = (e) => {
    const { settings } = this.state;
    settings.displayWorkingHours = e.target.checked;
    this.setState({
      settings,
    });
  };

  validateServicesWorkingHours = () => {
    const {
      employeesWorkingHoursData,
      serviceList,
      isServicesVerified,
      isWorkingHourVerified,
      selectedMap,
    } = this.state;
    let flag = false;
    employeesWorkingHoursData.forEach((val) => {
      if (val.availableStatus) {
        flag = true;
      }
    });
    if (!flag)
      return ErrorHelper.handleErrors("Please Add Working Hours", true);
    const companyServices = Object.values(selectedMap).map((element) => {
      return {
        serviceId: element._id,
        price: element.price,
      };
    });
    this.newHireCompanyEmployees(companyServices);
  };

  newHireCompanyEmployees = (companyServices) => {
    this.setState({ isloading: true });
    const {
      access_token,
      serviceList,
      employeesWorkingHoursData,
      selectedEmployeeId,
      settings,
    } = this.state;
    const employeesWorkingHoursSorted = employeesWorkingHoursData.map(function (
      obj
    ) {
      if (obj.availableStatus === 0) {
        delete obj["checkIn"]; // Delete old key
        delete obj["checkOut"]; // Delete old key
      }
      delete obj["dayName"]; // Delete old key
      delete obj["_id"]; // Delete old key
      return obj;
    });
    const payload = {
      employeeId: selectedEmployeeId,
      companyServices,
      weekPlans: employeesWorkingHoursSorted,
      ...settings,
    };

    hireCompanyEmployee(payload, access_token)
      .then((res) => {
        if (res.data.success) {
          SuccessHelper.handleSuccess(
            i18n.t("container.add_employe_success"),
            true
          );
          this.handleNavigation("/saloon-company-employee");
          this.setState({ isloading: false, employeeList: res.data.data });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
        if (error.response) {
          if (error.response.data.error) {
            const data = JSON.parse(error.response.data.error);
            ErrorHelper.handleErrors(
              i18n.t(data.code, { totalEmployees: data.total_employees }),
              true,
              {
                showCancelButton: true,
                cancelButtonText: "Ok",
                confirmButtonText: i18n.t("upgrade"),
              }
            ).then(({ value }) => {
              if (value) {
                this.handleNavigation("/saloon-saloon-pricing-plains");
              }
            });
          } else {
            ErrorHelper.handleErrors(error.response.data.msg, true);
          }
        } else if (error.request) {
          ErrorHelper.handleErrors("Something Went Wrong", true);
        } else {
          ErrorHelper.handleErrors(error.msg, true);
        }
      });
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  renderSearchBar = () => {
    const { searchText } = this.state;
    return (
      <>
        <div className="col-12 col-lg-4 col-md-4 mt-4">
          <span>
            <span className="saloon-dashboard-heading">
              {" "}
              <Translation>
                {(t) => t("container.add_employe_employees")}
              </Translation>
            </span>
          </span>
        </div>
        <div className="col-12 col-lg-8 col-md-8 mt-4">
          <div className=" d-flex justify-content-end row">
            <div className="col-12 col-lg-7 col-md-6">
              <div className="form-group">
                <input
                  style={{ backgroundColor: "#FFFFFF", borderColor: "#EBEBEB" }}
                  type="text"
                  id="addEmployee_search_bar"
                  className="form-control form-control-sm pl-5"
                  placeholder={i18n.t(
                    "container.add_employe_search_placeholder"
                  )}
                  onChange={(text) => this.onChangeSearchText(text)}
                  value={searchText}
                />
                <img src={Images.saloon_search} className="search-bar-icon" />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  renderTableBody = () => {
    const { activePage, usersList } = this.state;
    let previousPageNumber = activePage - 1;
    let startDay = null;
    let endDay = null;
    let currentWorkingHr = { checkIn: "", checkOut: "" };
    return (
      <tbody>
        {usersList &&
          usersList.map((val, ind) => {
            // if (val.userId) {
            //   var number = new Number(val.userId.phoneNo);
            // }
            if (ind >= previousPageNumber * 10 && ind < activePage * 10) {
              for (let i = 0; i < val.weekPlans.length; i++) {
                if (!startDay && parseInt(val.weekPlans[i].availableStatus)) {
                  startDay = val.weekPlans[i].dayOfWeek;
                } else {
                  endDay = val.weekPlans[i].dayOfWeek;
                }
              }
              if (val.weekPlans[new Date().getDay()].availableStatus) {
                currentWorkingHr.checkIn =
                  val.weekPlans[new Date().getDay()].checkIn;
                currentWorkingHr.checkOut =
                  val.weekPlans[new Date().getDay()].checkOut;
              }
              return (
                <tr key={val._id} style={{ borderTop: "3px solid #F7F7F7" }}>
                  <td>
                    <div className="d-flex align-items-center">
                      {val.userId && (
                        <img
                          src={val.userId.profile_img}
                          className="appointment-details-employee-pic"
                          style={{ width: 30, height: 30 }}
                        />
                      )}
                      {val.userId && (
                        <span className="appointment-details-employee-name">
                          {val.userId.firstName + " " + val.userId.lastName}
                        </span>
                      )}
                    </div>
                  </td>
                  <td className=" working_day_data ">
                    <div className="d-flex align-items-center mt-1">
                      <span className="appointment-details-employee-other">
                        {val.weekPlans.map(
                          (v, i) =>
                            v.checkIn &&
                            `${weekNames[v.dayOfWeek - 1]}, (${v.checkIn}-${
                              v.checkOut
                            }) `
                        )}
                      </span>
                    </div>
                  </td>
                  <td className="Table_data ">
                    <div className="d-flex align-items-center mt-1">
                      {val.userId && (
                        <span className="appointment-details-employee-other">
                          <NumberFormat
                            value={val.userId.phoneNo}
                            displayType={"text"}
                            format="### ### #### ####"
                          />
                        </span>
                      )}
                    </div>
                  </td>
                  <td className="Table_data ">
                    <div className="d-flex align-items-center mt-1">
                      {val.userId && (
                        <span className="appointment-details-employee-other">
                          {val.userId.email}
                        </span>
                      )}
                    </div>
                  </td>
                  <td>
                    <span
                      onClick={() =>
                        this.setState({
                          showAddEmployeePopup: true,
                          settings: {},
                          selectedEmployeeId: val._id,
                        })
                      }
                      className="add_employee_btn_active"
                    >
                      <Translation>
                        {(t) => t("container.add_employee_add_employe")}
                      </Translation>
                    </span>
                  </td>
                </tr>
              );
            }
          })}
      </tbody>
    );
  };

  closeEditServiceCard = () => {
    const { allusers } = this.state;
    this.setState({
      showAddEmployeePopup: false,
      usersList: allusers,
    });
  };

  renderWorkingHoursInput = (ind, data) => {
    return (
      <div className="form-row working-days-table">
        {/* <div className=" px-0 pr-md-3 d-flex align-items-center">
          <input
            // value={data.checkIn}
            type="time"
          
            className="form-control edit-employee-input"
            onChange={(text) => this.onChangeCheckIn(text, ind, data)}

          />
        </div> */}
        <div className="px-3 px-md-0 d-flex align-items-center">
          <span>
            <Translation>{(t) => t("container.add_employee_to")}</Translation>
          </span>
        </div>

        {/* <div
          className="  px-0 pl-md-3 d-flex align-items-center"
          style={{ marginBottom: "0px !important" }}
        >
          <input
            // value={data.checkOut}
          
            type="time"
            
            className="form-control edit-employee-input"
            onChange={(text) => this.onChangeCheckOut(text, ind, data)}
          />

        </div> */}
      </div>
    );
  };

  renderDayOfWeek = (dayOfWeek) => {
    switch (dayOfWeek) {
      case 1:
        return i18n.t("Sunday");
      case 2:
        return i18n.t("Monday");
      case 3:
        return i18n.t("Tuesday");
      case 4:
        return i18n.t("Wednesday");
      case 5:
        return i18n.t("Thursday");
      case 6:
        return i18n.t("Friday");
      case 7:
        return i18n.t("Saturday");
    }
  };

  renderWorkingHoursTable = (employee) => {
    const { employeesWorkingHoursData, isChecked } = this.state;
    return (
      <div className="col-12 p-0">
        <div className="table-responsive">
          <table class="table table-borderless saloon-working-hour-table">
            <thead>
              <tr>
                <th scope="col">
                  <span className="working-day-th-text">
                    <Translation>
                      {(t) => t("container.add_employee_working_days")}
                    </Translation>
                  </span>
                </th>
                <th className="text-center" scope="col">
                  <span className="working-day-th-text">
                    <Translation>{(t) => t("container.hours")}</Translation>
                  </span>
                </th>
                <th className="text-center" scope="col">
                  <span className="working-day-th-text">
                    <Translation>
                      {(t) => t("container.availability")}
                    </Translation>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {employee.map((val, ind) => {
                let data = val.weekPlans.map((val) => {
                  return { ...val };
                });

                return data.map((val, ind) => {
                  if (val.availableStatus == 0) {
                    return null;
                  }
                  let chikInFormat = val.checkIn.split(":");
                  let checkOutFormat = val.checkOut.split(":");
                  let checkInHour = chikInFormat[0] % 12;
                  checkInHour = checkInHour ? checkInHour : 12;
                  let checkOutHour = checkOutFormat[0] % 12;
                  checkOutHour = checkOutHour ? checkOutHour : 12;

                  return (
                    <tr key={ind} style={{ borderTop: "3px solid #F7F7F7" }}>
                      <td>
                        <span className="working-days-name">
                          {this.renderDayOfWeek(val.dayOfWeek)}
                        </span>
                      </td>

                      <td style={{ display: "flex", justifyContent: "center" }}>
                        <span style={{ marginTop: "5px", marginRight: "8px" }}>
                          {val.checkIn}
                          {chikInFormat[0] >= 12 ? "PM" : "AM"}
                        </span>
                        {val.availableStatus === 1 && (
                          <span
                            style={{
                              alignItems: "center",
                              display: "flex",
                              flex: 1,
                              justifyContent: "center",
                              textAlign: "center",
                            }}
                          >
                            to
                          </span>
                        )}
                        <span style={{ marginTop: "5px", marginLeft: "8px" }}>
                          {val.checkOut}
                          {checkOutFormat[0] >= 12 ? "PM" : "AM"}
                        </span>
                        {val.availableStatus === 0 && (
                          <button
                            className="btn btn-light btn-sm"
                            id="working-off-btn"
                          >
                            OFF
                          </button>
                        )}
                      </td>
                      <td className="text-center">
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id={ind}
                            // checked={val.isChecked}
                            onChange={(e) =>
                              this.onChangeWorkingHrCheckbox(e, ind, val)
                            }
                          />
                          <label class="custom-control-label" for={ind}></label>
                        </div>
                      </td>
                    </tr>
                  );
                });
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  renderServicesTable = (employee) => {
    let { serviceList, selectedMap } = this.state;
    if (!selectedMap) {
      selectedMap = {};
    }
    const potentialServices = [];
    employee = employee.map((emp) => {
      serviceList.filter((element) => {
        const result = emp.services.find((service) => {
          return element.serviceId._id == service.serviceId;
        });
        if (result) {
          potentialServices.push(element);
        }
      });
      emp.potentialServices = potentialServices;
      return emp;
    });
    console.log("potentialServices", potentialServices);
    return (
      <div className="col-12 p-0">
        <div className="table-responsive">
          <table class="table table-borderless saloon-working-hour-table">
            <thead>
              <tr>
                <th scope="col">
                  <span className="working-day-th-text">
                    <Translation>
                      {(t) => t("container.profile_service")}
                    </Translation>
                  </span>
                </th>
                <th className="text-center" scope="col">
                  <span className="working-day-th-text">
                    <Translation>{(t) => t("by_price")}</Translation>
                  </span>
                </th>
                <th className="text-center" scope="col">
                  <span className="working-day-th-text">
                    <Translation>{(t) => t("container_choose")}</Translation>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {potentialServices &&
                potentialServices.map((val, ind) => {
                  return (
                    <tr key={ind} style={{ borderTop: "3px solid #F7F7F7" }}>
                      <td>
                        <span className="working-days-name text-capitalize">
                          {val.name}
                        </span>
                      </td>
                      <td style={{ display: "flex", justifyContent: "center" }}>
                        {selectedMap[val._id] && (
                          <div className=" px-0 pr-md-3 d-flex align-items-center">
                            <input
                              placeholder="Price"
                              value={selectedMap[val._id].price}
                              type="number"
                              min="1.0"
                              step="0.5"
                              max="250000"
                              className="form-control edit-employee-input"
                              onChange={(text) =>
                                this.onChangePrice(text, ind, val)
                              }
                            />
                          </div>
                        )}
                        {!selectedMap[val._id] && (
                          <button
                            className="btn btn-light btn-sm"
                            id="working-off-btn"
                          >
                            <Translation>
                              {(t) => t("container.not_selected")}
                            </Translation>
                          </button>
                        )}
                      </td>
                      <td className="text-center">
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id={val._id}
                            checked={selectedMap[val._id] ? true : false}
                            onChange={(e) =>
                              this.onChangeServiceCheckbox(e, ind, val)
                            }
                          />
                          <label
                            class="custom-control-label"
                            for={val._id}
                          ></label>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  setCustomerPinCode = (e) => {
    const { settings } = this.state;
    settings.pinCode = e.target.value;
    this.setState({
      settings,
    });
  };

  renderPinCodeSetting = () => {
    const { settings = {} } = this.state;
    return (
      <div className="form-group row mx-0 px-0 pt-2">
        <div>
          <label style={{ display: "block" }} htmlFor="addEmployee_pin_code">
            {i18n.t("container.add_employee_enter_pincode")}:
          </label>
          <PinInput
            type="number"
            id="addEmployee_pin_code"
            className="form-control form-control-sm pl-2"
            value={settings.pinCode}
            onChange={(e) => this.setCustomerPinCode(e)}
          />
        </div>
        <div />
      </div>
    );
  };

  renderSettings = () => {
    const { selectedEmployeinpopup, settings, showColorPopup, newColor } =
      this.state;
    let color = newColor ? newColor : "#000000";
    return (
      <div className="col-12 pb-3">
        <div className="col-12 p-0">
          <div class="custom-control custom-checkbox">
            <input
              type="checkbox"
              id="allowCheckIn"
              className="custom-control-input"
              checked={settings ? settings.allowCheckIn : false}
              onChange={(e) => this.onChangeCustomerCheckInSetting(e)}
            />
            <label class="custom-control-label" for="allowCheckIn">
              Allow Clock in Clock out
            </label>
          </div>
          {settings && settings.allowCheckIn
            ? this.renderPinCodeSetting()
            : null}
          <div class="custom-control custom-checkbox">
            <input
              type="checkbox"
              id="isEmployeeIndependent"
              className="custom-control-input"
              checked={settings ? settings.isEmployeeIndependent : false}
              onChange={(e) => this.onChangeCustomerDetailsVisibility(e)}
            />
            <label class="custom-control-label" for="isEmployeeIndependent">
              Employee is independent
            </label>
          </div>
          <div class="custom-control custom-checkbox">
            <input
              type="checkbox"
              id="isReceptionist"
              className="custom-control-input"
              checked={settings ? settings.isReceptionist : false}
              onChange={(e) => this.onChangeReceptionist(e)}
            />
            <label class="custom-control-label" for="isReceptionist">
              {i18n.t("is_receptionist")}
            </label>
          </div>
          <div class="custom-control custom-checkbox">
            <input
              type="checkbox"
              id="isManager"
              className="custom-control-input"
              checked={settings ? settings.isManager : false}
              onChange={(e) => this.onChangeManager(e)}
            />
            <label class="custom-control-label" for="isManager">
              {i18n.t("is_manager")}
            </label>
          </div>
          <div class="custom-control custom-checkbox">
            <input
              type="checkbox"
              id="displayWorkingHours"
              className="custom-control-input"
              checked={settings ? settings.displayWorkingHours : false}
              onChange={(e) => this.onChangeDisplayWorkHours(e)}
            />
            <label class="custom-control-label" for="displayWorkingHours">
              {i18n.t("display_working_hours_on_calendar")}
            </label>
          </div>
          <div class="form-group row mx-0 pt-2">
            <div>
              <label htmlFor="seat">{i18n.t("seat/room")}:</label>
              <input
                class="form-control"
                id="seat"
                onChange={(e) =>
                  this.setState({
                    settings: { ...settings, seat: e.target.text },
                  })
                }
                value={settings ? settings.seat : undefined}
              />
            </div>

            <div className="split">
              <label htmlFor="split">{i18n.t("staff_split")}:</label>
              <input
                class="form-control"
                id="split"
                ref={(input) => (this.inputField = input)}
                onFocus={() => (this.inputField.value = "")}
                onChange={(e) => {
                  let res = parseInt(e.target.value);

                  if (res <= 100 && res >= 0) {
                    this.setState({
                      settings: { ...settings, split: e.target.value },
                    });
                  } else {
                    // handle error
                    console.log("split must be between 0 and 100");
                    this.setState({
                      settings: { ...settings, split: 0 },
                    });
                  }
                }}
                value={settings ? settings.split : ""}
              />
            </div>
            <div className="split">
              <label
                style={{ display: "block" }}
                htmlFor="base_product_commission"
              >
                {i18n.t("base_product_commission")} (%):
              </label>

              <input
                class="form-control"
                id="base_product_commission"
                ref={(input) => (this.inputField = input)}
                onFocus={() => (this.inputField.value = "")}
                onChange={(e) => {
                  let res = parseInt(e.target.value);

                  if (res <= 100 && res >= 0) {
                    this.setState({
                      settings: {
                        ...settings,
                        baseProductCommission: e.target.value,
                      },
                      baseCommissionError: "",
                    });
                  } else {
                    this.setState({
                      settings: {
                        ...settings,
                        baseProductCommission: "",
                      },
                      baseCommissionError: i18n.t("baseCommissionError"),
                    });
                  }
                }}
                value={settings ? settings.baseProductCommission : ""}
              />
              <span style={{ color: "red" }}>
                {this.state.baseCommissionError}
              </span>
            </div>
          </div>

          <div class="form-group col-12 px-0 mx-0 row pt-2">
            <label htmlFor="color">{i18n.t("color")}:</label>
            <div
              style={{
                width: "70px",
                height: "26px",
                border: "3px solid #E1E1E1",
                cursor: "pointer",
                backgroundColor: color,
              }}
              className="ml-1"
              onClick={() =>
                this.setState({ showColorPopup: true, newColor: color })
              }
            ></div>
            {showColorPopup && (
              <div
                className="employee-service-card-wrapper "
                style={{ display: "flex" }}
              >
                <div className="container ">
                  <div
                    className="col-8 p-3 my-5 bg-white pop_container"
                    style={{ borderRadius: "1rem", margin: "auto" }}
                  >
                    <div
                      className="py-0 px-2"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span>
                        <b>{i18n.t("employee_color")}</b>
                      </span>
                      <span
                        onClick={() => this.setState({ showColorPopup: false })}
                        className="h4 cursor-pointer"
                      >
                        &times;
                      </span>
                    </div>
                    <div
                      className="py-0 row mx-2"
                      style={{
                        alignItems: "center",
                      }}
                    >
                      <SketchPicker
                        id="color"
                        width="100%"
                        color={newColor}
                        presetColors={[
                          "#D0021B",
                          "#F5A623",
                          "#F8E71C",
                          "#8B572A",
                          "#7ED321",
                          "#417505",
                          "#BD10E0",
                          "#9013FE",
                          "#4A90E2",
                          "#50E3C2",
                          "#B8E986",
                          "#000000",
                          "#4A4A4A",
                          "#9B9B9B",
                        ]}
                        disableAlpha={true}
                        onChange={({ hex }) => this.setState({ newColor: hex })}
                      />
                      <div className="col-12 text-right mt-2">
                        <button
                          // onClick={() => }
                          onClick={() => {
                            this.setState({
                              settings: { ...settings, color: newColor },
                              showColorPopup: false,
                            });
                          }}
                          // onClick={() => this.validateServicesWorkingHours()}
                          type="button"
                          className="btn btn-light btn-sm mb-2"
                          id="saloon-save-social-link"
                        >
                          {i18n.t("update")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  renderServicesWorkingHours = (employee) => {
    return (
      <div className="col-12 px-0 px-md-3">
        <h3 className="form-heading">
          <Translation>{(t) => t("container.working_hours")}</Translation>:
        </h3>
        {this.renderWorkingHoursTable(employee)}
        <h3 className="form-heading">
          <Translation>{(t) => t("container.services")}</Translation>:
        </h3>
        {this.renderServicesTable(employee)}
        <h3 className="form-heading">Employee Settings:</h3>
        {this.renderSettings()}
        <div className="col-12 text-right">
          <button
            onClick={() => this.validateServicesWorkingHours()}
            type="button"
            className="btn btn-light btn-sm mb-2"
            id="saloon-save-social-link"
          >
            Add Employee
          </button>
        </div>
      </div>
    );
  };

  renderAddEmployeePopup = () => {
    const { showAddEmployeePopup, usersList, selectedEmployeeId, serviceList } =
      this.state;
    let employee = [];
    if (usersList != null) {
      employee = usersList.filter(
        (employe) => employe._id === selectedEmployeeId
      );
    }
    return (
      <div
        className="employee-service-card-wrapper"
        style={{ display: showAddEmployeePopup ? "flex" : "none" }}
      >
        <div className="container">
          <div
            className="col-12 p-3 my-5 bg-white"
            style={{ borderRadius: "1rem" }}
          >
            <div className="py-0 px-2 text-right">
              <span
                onClick={() => this.closeEditServiceCard()}
                className="h4 cursor-pointer"
              >
                &times;
              </span>
            </div>
            {this.renderServicesWorkingHours(employee)}
          </div>
        </div>
      </div>
    );
  };

  renderDropdownPopup = () => {
    const { serviceList } = this.state;
    return (
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <select class="selectpicker form-control form-control-lg">
              {serviceList.map((val, ind) => {
                return (
                  <option key={ind} className="options" value={val}>
                    {val}
                  </option>
                );
              })}
              {/* <option value="1">Mustard</option>
                            <option value="2">Ketchup</option>
                            <option value="3">Relish</option> */}
            </select>
          </div>
        </div>
      </div>
    );
  };

  // checkValidation = () => {
  //     const { employeesWorkingHoursData, isVerified, hasSchedule } = this.state
  //     for (let i = 0; i < employeesWorkingHoursData.length; i++) {
  //         if (employeesWorkingHoursData[i].availableStatus === 1) {
  //             if (!employeesWorkingHoursData[i].checkIn) {
  //                 return this.setState({ isVerified: false, employeesWorkingHoursError: true })
  //             } else if (!employeesWorkingHoursData[i].checkOut) {
  //                 return this.setState({ isVerified: false, employeesWorkingHoursError: true })
  //             } else {
  //                 this.setState({ isVerified: true, employeesWorkingHoursError: false })
  //             }
  //         }
  //     }
  //     if (isVerified && !hasSchedule) {
  //         this.handleCreateEmployeeSchedule()
  //     } else if (isVerified && hasSchedule) {
  //         this.handleEditSaloonSchedule()
  //     }
  // }

  // handleCreateEmployeeSchedule = () => {
  //     this.setState({ isloading: true });
  //     const { employeesWorkingHoursData, access_token } = this.state
  //     const employeesWorkingHoursSorted = employeesWorkingHoursData.map(function (obj) {
  //         delete obj['dayName']; // Delete old key
  //         delete obj['_id']; // Delete old key
  //         return obj;
  //     });
  //     const payload = { weekPlans: employeesWorkingHoursSorted }
  //     createEmployeeSchedule(payload, access_token)
  //         .then((res) => {
  //             if (res.data.success) {
  //                 this.handleGetSaloonSchedule()
  //             } else {
  //                 this.setState({ isloading: false });
  //             }
  //         })
  //         .catch((error) => {
  //             console.log(error, "error");
  //         });
  // }
  // handleEditSaloonSchedule = () => {
  //     this.setState({ isloading: true });
  //     const { employeesWorkingHoursData, access_token } = this.state
  //     const employeesWorkingHoursSorted = employeesWorkingHoursData.map(function (obj) {
  //         if (obj.availableStatus === 0) {
  //             delete obj['checkIn']; // Delete old key
  //             delete obj['checkOut']; // Delete old key
  //         }
  //         delete obj['dayName']; // Delete old key
  //         delete obj['_id']; // Delete old key
  //         return obj;
  //     });
  //     const payload = { weekPlans: employeesWorkingHoursSorted }
  //     editSaloonSchedule(payload, access_token)
  //         .then((res) => {
  //             if (res.data.success) {
  //                 this.handleGetSaloonSchedule()
  //             } else {
  //                 this.setState({ isloading: false });
  //             }
  //         })
  //         .catch((error) => {
  //             console.log(error, "error");
  //         });
  // }

  // ServicesSaveChanges = () => {
  //     const { employeesWorkingHoursError, hasSchedule } = this.state;
  //     return (
  //         <div className="col-12 px-0 px-md-3" style={{
  //             display: 'flex',
  //             justifyContent: employeesWorkingHoursError ? 'space-between' : 'flex-end',
  //             alignItems: 'center'
  //         }}>
  //             {employeesWorkingHoursError && <span className="text-danger">Working hours are required</span>}
  //             <button onClick={() => this.checkValidation()}
  //                 type="button"
  //                 className="btn btn-light btn-sm mb-2"
  //                 id="saloon-save-social-link"
  //             >{hasSchedule ? "<Translation>{t=>t('container.update_schedule')}</Translation>" : "Save Changes"}</button>
  //         </div>
  //     )
  // }

  renderWorkingHourTableCardPopup = () => {
    return (
      <div className="col-12 p-3">
        <div className="table-responsive">
          <table class="table table-borderless saloon-working-hour-table">
            {this.renderTablePopupTime()}
          </table>
        </div>
      </div>
    );
  };

  renderTablePopupTime = () => {
    const { employeesWorkingHoursData } = this.state;
    return (
      <tbody>
        {employeesWorkingHoursData.map((val, ind) => {
          return (
            <tr key={ind} style={{ borderTop: "3px solid #F7F7F7" }}>
              <td>
                <span className="working-days-name">{val.dayName}</span>
              </td>
              <td style={{ display: "flex", justifyContent: "center" }}>
                {val.availableStatus === 1 &&
                  this.renderEditWorkingHours(ind, val)}
                {val.availableStatus === 0 && (
                  <button className="btn btn-light btn-sm" id="working-off-btn">
                    OFF
                  </button>
                )}
              </td>
              <td className="text-center">
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id={ind}
                    checked={val.availableStatus ? true : false}
                    onChange={(e) => this.onChangeCheckbox(e, ind)}
                  />
                  <label class="custom-control-label" for={ind}></label>
                </div>
              </td>
              {/* <td><span className="working-employee-timing">{val.ServicesAndSpeciality}</span></td> */}
              {/* <td style={{ display: 'flex', justifyContent: 'center' }}>
                            {val.Price &&
                                <span className="working-employee-timing">{this.renderServicesPrice()}</span>}
                            {!val.hours && <p></p>}
                        </td> */}
            </tr>
          );
        })}
      </tbody>
    );
  };

  renderEditWorkingHours = (ind, data) => {
    return (
      <div className="form-row working-days-table">
        <div className=" px-0 pr-md-3 d-flex align-items-center">
          <input
            value={data.checkIn && data.checkIn}
            type="time"
            className="form-control edit-employee-input"
            onChange={(text) => this.onChangeCheckIn(text, ind)}
          />
        </div>
        <div className="px-3 px-md-0 d-flex align-items-center">
          <span>to</span>
        </div>
        <div
          className="  px-0 pl-md-3 d-flex align-items-center"
          style={{ marginBottom: "0px !important" }}
        >
          <input
            value={data.checkIn && data.checkOut}
            type="time"
            className="form-control edit-employee-input"
            onChange={(text) => this.onChangeCheckOut(text, ind)}
          />
        </div>
      </div>
    );
  };

  onChangeCheckIn = (text, ind, data) => {
    var dt = moment(`${text.target.value}`, ["h:mm"]).format("HH:mm");
    var dt2 = moment(`${data.checkIn}`, ["h:mm"]).format("HH:mm");
    let valueee1 = dt.split(":");
    let valueee2 = dt2.split(":");
    const { employeesWorkingHoursData } = this.state;
    if (text.target.value) {
      employeesWorkingHoursData[ind].checkIn = text.target.value;
      this.setState({ userCheckInInp: text.target.value });
    }
    // if(valueee1[0] > valueee2[0] ){
    //   if (text.target.value) {
    //     employeesWorkingHoursData[ind].checkIn = text.target.value;
    //     this.setState({text.target.value})

    //   }
    // }else{
    //   // ErrorHelper.handleErrors('Time slot unavilable,Please Select another Time', true)
    // }
  };

  onChangeCheckOut = (text, ind, data) => {
    var chkout1 = moment(`${text.target.value}`, ["h:mm"]).format("HH:mm");
    var chkout2 = moment(`${data.checkOut}`, ["h:mm"]).format("HH:mm");
    var chkIN1 = moment(`${this.state.userCheckInInp}`, ["h:mm"]).format(
      "HH:mm"
    );
    var chkIN2 = moment(`${data.checkIn}`, ["h:mm"]).format("HH:mm");
    let valueee1 = chkout1.split(":");
    let valueee2 = chkout2.split(":");
    valueee1[0] = valueee1[0] % 12;
    valueee1[0] = valueee1[0] ? valueee1[0] : 12;
    valueee2[0] = valueee2[0] % 12;
    valueee2[0] = valueee2[0] ? valueee2[0] : 12;
    let chkinValidate1 = chkIN1.split(":");
    let chkinValidate2 = chkIN2.split(":");
    chkinValidate1[0] = chkinValidate1[0] % 12;
    chkinValidate1[0] = chkinValidate1[0] ? chkinValidate1[0] : 12;

    chkinValidate2[0] = chkinValidate2[0] % 12;
    chkinValidate2[0] = chkinValidate2[0] ? chkinValidate2[0] : 12;

    const { employeesWorkingHoursData } = this.state;
    if (text.target.value) {
      employeesWorkingHoursData[ind].checkOut = text.target.value;
      this.setState({ userCheckOutInp: text.target.value });

      setTimeout(() => {
        // if (chkinValidate1[0] <= chkinValidate2[0] && valueee1[0] <= valueee2[0]  && valueee1[0] >= chkinValidate1[0] && valueee1[0] <= chkinValidate2[0]) {
        //   alert("IF FROM 1st")
        //   if (valueee1[0] === chkinValidate1[0]) {
        //     alert("HHOURS MATCHED")
        //     if (valueee1[1] > chkinValidate1[1]) {
        //       employeesWorkingHoursData[ind].checkOut = chkout1;
        //       this.setState({ userCheckInInp: chkout1 })
        //     }
        //     else {
        //       ErrorHelper.handleErrors('Invalid Time Slot', true)
        //     }
        //   }
        //   employeesWorkingHoursData[ind].checkOut = chkout1;
        //   this.setState({ userCheckInInp: chkout1 })
        //   //   if (valueee1[1] > chkinValidate1[1]) {
        //   //     employeesWorkingHoursData[ind].checkOut = chkout1;
        //   //     this.setState({ userCheckInInp: chkout1 })
        //   //     alert("WORKed in last if")
        //   //   } else {
        //   //     ErrorHelper.handleErrors('Time slot unavilable,Please Select another Time111', true)
        //   //   }
        //   // } else {
        //   //   employeesWorkingHoursData[ind].checkOut = chkout1;
        //   //   this.setState({ userCheckInInp: chkout1 })
        //   //   alert("IF FROM 1st")
        //   // }
        // }
        // else {
        //   ErrorHelper.handleErrors('Time slot unavilable,Please Select another Time22', true)
        // }
      }, 3000);
    }
    // if(valueee1[0] < valueee2[0] ){

    //   if (text.target.value) {
    //     employeesWorkingHoursData[ind].checkOut = text.target.value;
    //     this.setState({userCheckInInp :text.target.value})

    //   }
    // }else{
    //   ErrorHelper.handleErrors('Time slot unavilable,Please Select another Time', true)
    // }
  };

  onChangeCheckbox = (e, ind) => {
    const { employeesWorkingHoursData } = this.state;
    alert("clicked");
    employeesWorkingHoursData[ind].availableStatus = 1;
    this.setState({ employeesWorkingHoursData });
    // if (e.target.checked) {
    //   employeesWorkingHoursData[ind].availableStatus = 1;
    //   this.setState({ employeesWorkingHoursData });
    // } else {
    //   employeesWorkingHoursData[ind].availableStatus = 0;
    //   this.setState({ employeesWorkingHoursData });
    // }
  };

  renderFilterTableBody = () => {
    const { activePage, filterUsersList } = this.state;
    let previousPageNumber = activePage - 1;
    let startDay = null;
    let endDay = null;
    let currentWorkingHr = { checkIn: "", checkOut: "" };
    return (
      <tbody>
        {filterUsersList.map((val, ind) => {
          if (ind >= previousPageNumber * 10 && ind < activePage * 10) {
            for (let i = 0; i < val.weekPlans.length; i++) {
              if (!startDay && parseInt(val.weekPlans[i].availableStatus)) {
                startDay = val.weekPlans[i].dayOfWeek;
              } else {
                endDay = val.weekPlans[i].dayOfWeek;
              }
            }
            if (val.weekPlans[new Date().getDay()].availableStatus) {
              currentWorkingHr.checkIn =
                val.weekPlans[new Date().getDay()].checkIn;
              currentWorkingHr.checkOut =
                val.weekPlans[new Date().getDay()].checkOut;
            }
            return (
              <tr key={val._id} style={{ borderTop: "3px solid #F7F7F7" }}>
                <td>
                  <div className="d-flex align-items-center">
                    {val.userId && (
                      <img
                        src={val.userId.profile_img}
                        className="appointment-details-employee-pic"
                        style={{ width: 30, height: 30 }}
                      />
                    )}
                    {val.userId && (
                      <span className="appointment-details-employee-name">
                        {val.userId.firstName + " " + val.userId.lastName}
                      </span>
                    )}
                  </div>
                </td>
                <td className=" working_day_data ">
                  <div className="d-flex align-items-center mt-1">
                    <span className="appointment-details-employee-other">{`${
                      weekNames[startDay - 1]
                    } - ${weekNames[endDay - 1]} (${
                      currentWorkingHr.checkIn
                    } - ${currentWorkingHr.checkOut})`}</span>
                  </div>
                </td>
                <td className="Table_data ">
                  <div className="d-flex align-items-center mt-1">
                    {val.userId && (
                      <span className="appointment-details-employee-other">
                        {val.userId.phoneNo}
                      </span>
                    )}
                  </div>
                </td>
                <td className="Table_data ">
                  <div className="d-flex align-items-center mt-1">
                    {val.userId && (
                      <span className="appointment-details-employee-other">
                        {val.userId.email}
                      </span>
                    )}
                  </div>
                </td>
                <td>
                  {/* <span onClick={() => this.hireCompanyEmployees(val._id)} className="add_employee_btn_active">Add Employee</span> */}
                  <span
                    onClick={() =>
                      this.setState({
                        showAddEmployeePopup: true,
                        settings: {},
                        selectedEmployeeId: val._id,
                      })
                    }
                    className="add_employee_btn_active"
                  >
                    {i18n.t("container.add_employee_add_employe")}
                  </span>
                </td>
              </tr>
            );
          }
        })}
      </tbody>
    );
  };

  renderUserTable = () => {
    const { searchText, filterUsersList, usersList } = this.state;
    return (
      <div className="col-12 p-3">
        <div className="table-responsive">
          <table className="table table-borderless appointment-details-table">
            <thead>
              <tr>
                <th scope="col">
                  <span className="appointment-details-th-text">
                    {i18n.t("container.advancedSearchDialog_name")}
                  </span>
                </th>
                <th scope="col">
                  <span className="appointment-details-th-text">
                    {i18n.t("container.add_employee_working_days")}/
                    <Translation>{(t) => t("container.hours")}</Translation>
                  </span>
                </th>
                <th scope="col">
                  <span className="appointment-details-th-text">
                    <Translation>
                      {(t) => t("container.employe_phone_number")}
                    </Translation>
                  </span>
                </th>
                <th scope="col">
                  <span className="appointment-details-th-text">
                    <Translation>
                      {(t) => t("container.profile_email")}
                    </Translation>
                  </span>
                </th>
                <th scope="col">
                  <span className="appointment-details-th-text"></span>
                </th>
              </tr>
            </thead>
            {searchText
              ? filterUsersList && this.renderFilterTableBody()
              : usersList && this.renderTableBody()}
          </table>
        </div>
      </div>
    );
  };

  renderPagination = () => {
    const { searchText, usersList, filterUsersList } = this.state;
    return (
      <div className="col-12 py-2 mb-3 d-flex justify-content-center">
        <Pagination
          hideNavigation
          activePage={this.state.activePage}
          itemsCountPerPage={10}
          totalItemsCount={
            searchText ? filterUsersList.length : usersList && usersList.length
          }
          // pageRangeDisplayed={3}
          onChange={(val) => this.handlePageChange(val)}
          firstPageText={
            <img src={Images.pagination_back} style={{ width: 15 }} />
          }
          lastPageText={
            <img src={Images.pagination_forward} style={{ width: 15 }} />
          }
          itemClass="page-item"
          linkClass="page-link"
        />
      </div>
    );
  };

  // getFilterUsers = () => {
  //   const { usersLists, verifiedUsersList } = this.state;

  //   console.log("fffffffffffffff");

  //   let mergeArray = [];

  //   if (usersLists && verifiedUsersList) {
  //     mergeArray.push(usersLists);
  //     mergeArray.push(verifiedUsersList);

  //     usersLists.map((v) => {
  //       verifiedUsersList.map((vv) => {
  //         if (vv.user._id !== v.userId) {
  //           console.log("::::::::", v);

  //           this.setState({ usersList: v });
  //         }
  //       });
  //     });
  //   }
  // };

  render() {
    return (
      <div>
        {this.renderLoader()}
        <div className="content-container">
          <div className="row">
            {this.renderAddEmployeePopup()}
            {this.renderSearchBar()}
            {this.renderUserTable()}
            {this.renderPagination()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(Users);
