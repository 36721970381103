// @flow

import { GET_HOME, LOGOUT } from "./ActionTypes";

export function request(payload) {
    return {
        payload,
        type: GET_HOME.REQUEST
    };
}

export function success(data: Object) {
    return {
        data,
        type: GET_HOME.SUCCESS
    };
}

export function failure(errorMessage: Object) {
    return {
        errorMessage,
        type: GET_HOME.FAILURE
    };
}

export function logout() {
    return {
        type: LOGOUT
    };
}
