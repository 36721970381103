import { combineReducers } from "redux";

import user from "./User";

// CUSTOMER
import login from "./Login";
import register from "./Register";
import forgotPassword from "./ForgotPassword";
import verifyResetCode from "./VerifyResetCode";
import resetPassword from "./ResetPassword";
import socialLogin from "./SocialLogin";
import logout from "./LogOut";
import getHomePageInfo from "./GetHomePageInfo";
import getHome from "./GetHome";
import getNotifications from "./GetNotifications";
import updateNotification from "./UpdateNotification";
import activeBooking from "./ActiveBooking";

// SALOON
import saloonLogin from "./Saloon/Login";
import saloonRegister from "./Saloon/Register";
import createSaloon from "./Saloon/CreateSaloon";
import createSaloonPlan from "./Saloon/CreateSaloonPlan";
import saloonPaymentMethod from "./Saloon/SaloonPaymentMethod";
import saloonUserId from "./Saloon/SaloonByUserId";
import createSaloonSocialMedia from "./Saloon/CreateSaloonSocialMedia";
import SalonAppointment from "./Saloon/SalonAppointment";
import saloonLogout from "./Saloon/LogOut";
import getAvailableTimeslots from "./GetAvailableTimeslots";
import getGymTimeslots from "./GetGymTimeslots";
import billing from "./Saloon/Billing";
import easy1Products from "./Saloon/Easy1Products";
import subscription from "./Saloon/Subscription";
import allFundsRequest from "./Saloon/AllFundsRequest";
import orders from "./Saloon/Orders";
import formBuilder from "./Saloon/FormBuilder";
import vendor from "./Saloon/Vendor";
import expense from "./Saloon/Expense";
import communicationSettings from "./Saloon/CommunicationSettings";
import program from "./Saloon/Program";
import _class from "./Saloon/Class";
import student from "./Saloon/Student";
import studentGroup from "./Saloon/StudentGroup";
import coursePlanner from "./Saloon/CoursePlanner";
import ProgramEnrollment from "./Saloon/ProgramEnrollment";
import semester from "./Saloon/Semester";
import noShowReport from "./Saloon/NoShowReport";

//EMPLOYEE
import employeeRegister from "./Employee/Register";
import employeeLogin from "./Employee/Login";
import employeeLogout from "./Employee/LogOut";
import employeeFundsRequest from "./Employee/EmployeeFundsRequest";

// TEMPLATE
import templateNavigation from "./TemplateNavigation";

// IMPERSONATE
import impersonateEmployee from "./Employee/ImpersonateEmployee";
import impersonateSalon from "./Saloon/ImpersonateSalon";
import impersonateUser from "./ImpersonateUser";

import announcements from "./Announcements";
import sidebar from "./Sidebar";
import card from "./Card";
import bookingTransaction from "./BookingTransaction";
import companyCard from "./CompanyCard";
import validateBookingTransfer from "./ValidateBookingTransfer";
import packages from "./Packages";
import formAnswer from "./FormAnswer";
import reoccuringUnavailabilties from "./ReoccuringUnavailabilties";
import receptionistAccess from "./ReceptionistAccess";
import managerAccess from "./ManagerAccess";

export const rootReducer = combineReducers({
  user,
  // CUSTOMER
  login,
  register,
  forgotPassword,
  verifyResetCode,
  resetPassword,
  socialLogin,
  logout,
  getAvailableTimeslots,
  getGymTimeslots,
  getHomePageInfo,
  getHome,
  getNotifications,
  updateNotification,
  activeBooking,
  // SALOON
  saloonLogin,
  saloonRegister,
  createSaloon,
  createSaloonPlan,
  saloonPaymentMethod,
  saloonUserId,
  createSaloonSocialMedia,
  SalonAppointment,
  saloonLogout,
  billing,
  easy1Products,
  subscription,
  allFundsRequest,
  orders,
  formBuilder,
  vendor,
  expense,
  communicationSettings,
  program,
  _class,
  student,
  studentGroup,
  coursePlanner,
  ProgramEnrollment,
  semester,
  noShowReport,

  //EMPLOYEE
  employeeRegister,
  employeeLogin,
  employeeLogout,
  employeeFundsRequest,

  // TEMPLATE
  templateNavigation,

  // IMPERSONATE
  impersonateEmployee,
  impersonateSalon,
  impersonateUser,

  announcements,
  sidebar,
  card,
  bookingTransaction,
  sidebar,
  companyCard,
  validateBookingTransfer,
  packages,
  formAnswer,
  reoccuringUnavailabilties,
  receptionistAccess,
  managerAccess,
});
