// export const baseUrl = "https://prod-q3s5v4pl4q-ue.a.run.app/api/"; // Lives
export const baseUrl = "https://dev-q3s5v4pl4q-ue.a.run.app/api/"; // Lives
// export const baseUrl = "http://192.168.2.82:8080/api/"; // H
export const place_Autocomplete_URL = (value, secret_Key) =>
  `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${value}&key=${secret_Key}`;

// SALOON AUTH API
export const login_Api = `${baseUrl}user/login`;
export const google_Api = `${baseUrl}user/social-login`;
export const register_Api = `${baseUrl}user/register`;
export const forget_password_Api = `${baseUrl}password/forget`;
export const verify_reset_code_Api = `${baseUrl}password/otpcode`;
export const reset_password_Api = `${baseUrl}password/reset`;
export const get_user_Api = `${baseUrl}user/getUser`;
export const edit_user_Api = `${baseUrl}employee/updateUserInfo`;
export const edit_company_user_Api = `${baseUrl}company/updateUserInfo`;
export const user_create_profile_picture_Api = `${baseUrl}user/createProfilepicture`;
export const logout_Api = `${baseUrl}user/logout`;
export const edit_customer_profile = `${baseUrl}user/editCustomerProfile`;
export const search_by_name = `${baseUrl}company/getCompanyByName`;
export const add_card_info = `${baseUrl}payment/cardInfo`;
export const get_card_info = `${baseUrl}payment/getCardInfo`;
export const palns_payment = `${baseUrl}payment/stripePayment`;
export const getAllBundles = `${baseUrl}bundle/getAllBundles`;
export const createBundle = `${baseUrl}bundle/createBundle`;
export const getCompanyBundles = `${baseUrl}bundle/getBundle`;
export const updateBundle = `${baseUrl}bundle/editBundle`;
export const update_site = `${baseUrl}company/updateSite`;

// SALOON AUTH API
export const saloon_login_Api = `${baseUrl}company/login`;
export const get_company_employee = `${baseUrl}company/getEmployee`;
export const saloon_register_Api = `${baseUrl}company/register`;
export const create_saloon_Api = `${baseUrl}company/createCompany`;
export const create_saloon_plan_Api = `${baseUrl}company/plan`;
export const get_saloon_plan_Api = `${baseUrl}plan/getAllPlans`;
export const get_saloon_user_id_Api = `${baseUrl}company/getCompany`;
export const create_saloon_social_links_Api = `${baseUrl}socialLinks/createLink`;
export const update_saloon_social_links_Api = `${baseUrl}socialLinks/updateLink`;
export const update_saloon_black_list_Api = `${baseUrl}company/updateBlackList`;
export const get_social_links_Api = `${baseUrl}socialLinks/getLink`;
export const saloon_payment_method_Api = `${baseUrl}payment/selectPayment`;
export const get_saloon_payment_method_Api = `${baseUrl}payment/getMethod`;
export const get_all_employees = `${baseUrl}employee/getMultipleEmployee`;
// export const get_all_employees = `${baseUrl}employee/getAllEmployee`; // change krni h baad me
export const create_saloon_schedule = `${baseUrl}company/createSchedule`;
export const edit_saloon_schedule = `${baseUrl}company/editSchedule`;
export const get_saloon_schedule = `${baseUrl}company/getSchedule`;
export const hire_employee_by_company = `${baseUrl}company/hireEmployee`;
export const get_all_employees_associate_with_company = `${baseUrl}company/getAllEmployees`;
export const get_employees_not_associate_with_company = `${baseUrl}company/getNotAssociateWithCompany`;
export const get_all_booking_by_saloon = `${baseUrl}booking/getAllBookingsBySaloon`;
export const get_all_bookings_by_user = `${baseUrl}booking/getAllBookingsByUser`;
export const get_all_bookings_by_id = `${baseUrl}booking/getBookingById`;
export const validate_booking_reference_code = `${baseUrl}booking/validateBookingReferenceCode`;
export const get_company_employee_by_id = `${baseUrl}company/getEmployeeById`;

export const quick_cancel_booking = `${baseUrl}booking/quickCancel`;
export const dismiss_cancelled_booking = `${baseUrl}booking/dismissCancelledBooking`;
export const get_all_bookings_by_saloon = `${baseUrl}booking/getCompletedAppoinment`;
export const delete_employee_company = `${baseUrl}company/deleteEmployee`;
export const saloon_get_categories_Api = `${baseUrl}service/getService`;
export const saloon_create_service_Api = `${baseUrl}saloon/createService`;
export const saloon_get_all_service_Api = `${baseUrl}saloon/getAllServices`;
export const saloon_edit_service_Api = `${baseUrl}saloon/editService`;
export const saloon_update_multiple_services_api = `${baseUrl}saloon/updateMultipleServices`;
export const saloon_delete_service_Api = `${baseUrl}saloon/deleteService`;
export const company_update_template_Api = `${baseUrl}company/updateTemplate`;
export const company_post_upload_Api = `${baseUrl}companyPost/upload`;
export const company_post_get_Api = `${baseUrl}companyPost/getPost`;
export const company_post_delete_Api = `${baseUrl}companyPost/deletepost`;
export const saloon_edit_company_Api = `${baseUrl}company/editCompany`;
export const get_saloon_plan_id = `${baseUrl}plan/getCompanyPlan`;
export const edit_saloon_plan_Api = `${baseUrl}plan/editPlan`;
export const get_Payment_Method_By_Saloon_Api = `${baseUrl}payment/getUserPaymentMethod`;
export const edit_user_payment_method_Api = `${baseUrl}payment/editUserPaymentMethod`;
export const saloon_nearby_Api = `${baseUrl}saloon/Nearby`;
export const edit_saloon_company_logo = `${baseUrl}company/createLogo`;
export const get_saloon_template_data = `${baseUrl}company/getAllCompanyData`;
export const get_all_saloon_Api = `${baseUrl}saloon/getSaloon`;
export const edit_company_employe = `${baseUrl}company/editCompanyEmployee`;
export const get_company_portfolio = `${baseUrl}company/portfolio`;
export const get_employee_by_service_company = `${baseUrl}saloon/getEmployeesBySaloonAndService`;
export const edit_booking = `${baseUrl}booking/updateBooking`;
export const git_waiting_appoinments = `${baseUrl}booking/getWaitingAppoinment`;
export const get_home_page_info = `${baseUrl}homePage/getHomePage`;
export const get_wallet = `${baseUrl}wallet/getWallet`;
export const add_custom_booking_api = `${baseUrl}walkInCustomer/walkInCustomer`;
export const get_saloons_by_category_api = `${baseUrl}saloon/getSaloonsByCategory`;
export const get_social_employee_link_api = `${baseUrl}socialLinks/getEmployeeSocialLink`;
export const create_social_employee_link_api = `${baseUrl}socialLinks/createEmployeeLink`;
export const update_social_employee_link_api = `${baseUrl}socialLinks/updateEmployeeLink`;
export const get_home_service_info = `${baseUrl}admin/home/getHome`;
export const get_privacy_policy = `${baseUrl}admin/privacyPolicy/getPrivacyPolicy`;
export const get_cookie_policy = `${baseUrl}admin/cookiePolicy/getCookiePolicy`;
export const get_legal_info = `${baseUrl}admin/legalInfo/getLegalInfo`;
export const get_social_links_for_home = `${baseUrl}admin/socialLinks/getSocialLink`;
export const get_salon_plains = `${baseUrl}plan/getSystemPlan`;
export const buy_plains = `${baseUrl}plan/buyCompanyPlan`;
export const subcribe_email = `${baseUrl}newsletter/subscribe`;
export const get_faqs = `${baseUrl}faq/getFAQ`;
export const get_methods = `${baseUrl}payment/getMethod`;
export const create_Update_template = `${baseUrl}company/createCustomTemplate`;
export const get_customer_template = `${baseUrl}company/getCustomTemplate`;
export const get_ratings_by_company = `${baseUrl}rating/getCompanyRatings`;
export const get_ratings_by_employee = `${baseUrl}rating/getRatingsByEmployee`;
export const get_employee_unavailibities = `${baseUrl}employee/getUnavailabilities`;
export const get_company_unavailibities = `${baseUrl}company/getUnavailabilities`;

export const employee_unavailibities = `${baseUrl}employeeUnavailabilities`;
export const company_unavailibities = `${baseUrl}companyUnavailabilities`;
export const live_display_settings = `${baseUrl}liveDisplaySettings`;
export const company_customer = `${baseUrl}companyCustomer`;

export const get_company_customers = `${baseUrl}company/getCustomers`;
export const get_employee_customers = `${baseUrl}employee/getCustomers`;
export const notification = `${baseUrl}notification`;
export const billing = `${baseUrl}billing`;
export const easy1_products = `${baseUrl}admin/easy1Products`;
export const get_all_employee_account_totals = `${baseUrl}company/getAllEmployeeAccountTotals`;
export const get_all_payout_request = `${baseUrl}company/getAllPayoutRequest`;
export const update_payout_request = `${baseUrl}company/updateAccountPayoutRequest`;
export const add_payout = `${baseUrl}company/createAccountPayout`;
export const update_interac_transfer_settings = `${baseUrl}company/updateInteracTransferSettings`;

//COMPANY

export const get_booking_report = `${baseUrl}reports/getEmployeeSuggestionReport`;
export const get_sales_report = `${baseUrl}reports/getSalesReport`;
export const get_product_report = `${baseUrl}reports/getProductReport`;
export const get_report = `${baseUrl}reports/getReport`;
export const get_no_show_report = `${baseUrl}reports/getNoShowReport`;

//EMPLOYEE
export const employee_register_Api = `${baseUrl}employee/register`;
export const add_Companies_By_Employee = `${baseUrl}employee/addCompaniesByEmployee`;
export const employee_login_Api = `${baseUrl}employee/login`;
export const get_employee_services_Api = `${baseUrl}service/getService`;
export const create_employee_profile_Api = `${baseUrl}employee/createProfile`;
export const get_all_booking_by_employee_Api = `${baseUrl}booking/getAllBookingsByEmployee`;
export const employee_post_upload = `${baseUrl}employeePost/upload`;
export const edit_employee_profile = `${baseUrl}employee/editEmployee`;
export const get_employee_details = `${baseUrl}employee/getEmployee`;
export const get_get_saloon_categories = `${baseUrl}saloon/getSaloonCategories`;
export const get_saloon_services = `${baseUrl}saloon/getSaloonServicesByCategory`;
export const get_employees_by_saloon_and_service = `${baseUrl}saloon/getEmployeesBySaloonAndService`;
export const get_company_by_employee = `${baseUrl}employee/getCompaniesByEmployee`;
export const delete_salon_by_employee = `${baseUrl}employee/deleteCompanyByEmployee`;
export const get_employee_reports = `${baseUrl}reports/getEmployeeReport`;
export const get_availables_spots = `${baseUrl}booking/getAvailableTimeslots`;
export const get_gym_timeslots = `${baseUrl}booking/getGymTimeslots`;
export const booking_note = `${baseUrl}bookingNote`;
export const company_taxes = `${baseUrl}companyTax`;
export const waiting_list = `${baseUrl}waitingList`;
export const products = `${baseUrl}product`;
export const cart = `${baseUrl}cart`;
export const inventory_transaction = `${baseUrl}inventoryTransaction`;
export const inventory_transaction_increase = `${inventory_transaction}/increase`;
export const inventory_transaction_reduce = `${inventory_transaction}/reduce`;
export const get_employee_by_id = `${baseUrl}/employee/getEmployeeById`;

export const company_checkin_settings = `${baseUrl}companyCheckinSettings`;
export const card = `${baseUrl}card`;
export const form_builder = `${baseUrl}formTemplate`;
export const company_card = `${baseUrl}companyCard`;
export const subscription = `${baseUrl}subscription`;
export const one_time_pay = `${baseUrl}payment/oneTimePay`;
export const booking_Transaction = `${baseUrl}bookingTransaction`;
export const order = `${baseUrl}order`;
export const toggle_online_payments = `${baseUrl}user/toggleOnlinePayments`;
export const update_refund_policy = `${baseUrl}company/updateRefundPolicy`;

//TEMPLATES
export const get_template_Api = `${baseUrl}template/getTemplate`;

//Booking
export const confirm_booking = `${baseUrl}booking/confirmBooking`;
export const add_extra_to_booking = `${baseUrl}booking/addExtra`;
export const remove_extra_to_booking = `${baseUrl}booking/removeExtra`;
export const create_booking = `${baseUrl}booking/createBooking`;

// punch in punch out employee

export const employee_punch_in = `${baseUrl}workHour/checkIn`;
export const employee_punch_out = `${baseUrl}workHour/checkOut`;
export const employee_adjust_hours = `${baseUrl}workHour/adjustHours`;
export const employee_hours_report = `${baseUrl}workHour/generateReport`;
export const employee_hours = `${baseUrl}workHour`;

//HomePage
export const get_services = `${baseUrl}service/getService`;

// Zoom demostration
export const get_demonstration_slots = `${baseUrl}demonstrationReservationSlots`;
export const demonstration = `${baseUrl}demostrationReservation`;

// Partners
export const partner_login = `${baseUrl}partner/login`;
export const announcements = `${baseUrl}admin/announcements`;

// Impersonation
export const impersonation = `${baseUrl}admin/impersonation`;
export const company_financial_access = `${baseUrl}companyFinancialAccess`;
export const stripe_account = `${baseUrl}stripeAccount`;

// Funds Request
export const request_account_payout = `${baseUrl}employee/requestAccountPayout`;
export const get_funds_requests = `${baseUrl}employee/getAllPayoutRequest`;
export const get_account_totals = `${baseUrl}employee/getAccountTotals`;

export const packages = `${baseUrl}package`;
export const formAnswer = `${baseUrl}formAnswer`;

export const reoccuring_unavailabilities = `${baseUrl}reoccuringEmployeeUnavailibilities`;
export const remove_from_black_list = `${baseUrl}companyCustomer/removeFromBlackList`;
export const add_to_black_list = `${baseUrl}companyCustomer/addToBlackList`;
export const vendor = `${baseUrl}vendor`;
export const expense = `${baseUrl}expense`;
export const communication_settings = `${baseUrl}communicationSettings`;
export const receptionist_access = `${baseUrl}receptionistAccess`;
export const manager_access = `${baseUrl}managerAccess`;
export const resend_confirmation = `${baseUrl}booking/resendConfirmation`;
export const send_invoice = `${baseUrl}booking/sendInvoice`;
export const _class = `${baseUrl}class`;
export const program = `${baseUrl}program`;
export const student = `${baseUrl}student`;
export const student_group = `${baseUrl}studentGroup`;
export const course_event = `${baseUrl}courseEvent`;
export const program_enrollment = `${baseUrl}enrollment`;
export const semester = `${baseUrl}semester`;
