import { connect } from "react-redux";
import React, { Component } from "react";
import { Translation } from 'react-i18next';
import i18n from '../../../i18n';

import { request as login_user } from "../../../redux/actions/Login";
import { DotsLoader, Navbar, Footer } from "../../../components";
import { SuccessHelper, ErrorHelper } from "../../../helpers";
import { Images } from "../../../theme/index";

import { verifyCode } from "../../../config/simpleApiCalls";

import "./styles.css";

class VerifyOtp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userCode: "",
      isloading: false,
      serverCode: "",
    };
  }

  handleVerifyCode = () => {
    const { userCode } = this.state;
    console.log("userCode: ", userCode);

    const payload = {
      userId: this.props.location.state.userObj._userId,
      token: userCode,
    };

    console.log("propss", this.props.location.state.userObj.token);

    if (userCode == this.props.location.state.userObj.token) {
      this.setState({ isloading: true });

      verifyCode(payload)
        .then((res) => {
          if (res.data.success) {
            SuccessHelper.handleSuccess(res.data.msg, true);
          }
          this.handleNavigation(
            "/saloon-update-password",
            this.props.location.state.userObj
          );
          console.log(
            "userObjuserObjuserObj",
            this.props.location.state.userObj
          );
        })
        .catch((err) => {
          ErrorHelper.handleErrors("Something Went Wrong", true);
        });

      this.setState({ isloading: false });
    } else {
      ErrorHelper.handleErrors("Wrong Code", true);
    }
  };

  handleNavigation = (route, userObj) => {
    this.props.history.push(route, { userObj });
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  onOtpChanged = (text) => {
    this.setState({
      // formError: "Please enter a valid phone no",
      userCode: text.target.value,
    });
  };

  renderInput = () => {
    const { formError, userCode } = this.state;
    return (
      <div className="form-label-group mb-4 ">
        <label for="inputemail"><Translation>{t=>t('container.employe_enter_otp')}</Translation></label>
        <input
          maxLength="6"
          type="text"
          id="inputemail"
          className="form-control mt-1"
          placeholder="<Translation>{t=>t('container.employe_enter_otp')}</Translation>..."
          onChange={(text) => this.onOtpChanged(text)}
          value={userCode}
        />
        {/* {formError ? (
          <small className="form-text text-danger ml-3">{formError}</small>
        ) : null} */}
      </div>
    );
  };

  renderLoginBtn = () => {
    return (
      <button
        className="login-btn btn btn-lg"
        type="submit"
        onClick={() => this.handleVerifyCode()}
      >
        Send
      </button>
    );
  };

  renderOtpForm = () => {
    return (
      <form action="javascript:void(0)" className="form-signin  px-4">
        {this.renderInput()}
        {this.renderLoginBtn()}
      </form>
    );
  };

  renderLoginCard = () => {
    return (
      <div className="card card-signin my-5">
        <div className="card-body px-0">
          <h5 className="card-title text-center"><Translation>{t=>t('container.employe_enter_otp')}</Translation></h5>
          <div className="row">
            <hr className="my-4 mx-3 " style={{ width: "100%" }} />
          </div>
          {this.renderOtpForm()}
        </div>
      </div>
    );
  };

  render() {
    return (
      <>
        <div className="container-fluid">
          <div className="container">
            {this.renderLoader()}
            <Navbar {...this.props} isBackgroundColor={true} />
          </div>
          <div className="container" style={{ backgroundColor: "#F8FDFF" }}>
            <div className="row mt-5">
              <div className="col-sm-9 col-md-7 col-lg-5 mx-auto mt-5">
                {this.renderLoginCard()}
              </div>
            </div>
          </div>
        </div>
        <Footer {...this.props} />
      </>
    );
  }
}

const mapStateToProps = (state) => ({ login: state.login });

const action = { login_user };

export default connect(mapStateToProps, action)(VerifyOtp);
