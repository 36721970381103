import { connect } from "react-redux";
import React, { Component, Fragment, useState } from "react";
import { RiEditFill } from "react-icons/ri";
import { DotsLoader, Header } from "../../components";
import { Images } from "../../theme";
import ReactStars from "react-rating-stars-component";
import {
  getSaloonTemplateData,
  getCompanyPorfolio,
  getSaloonCategories,
  getSaloonServices,
  getEmployeesBySaloonAndService,
  createBooking,
  createUpdateTemplate,
  getTemplateData,
} from "../../config/simpleApiCalls";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import moment from "moment";
import { SuccessHelper, ErrorHelper } from "../../helpers";
import { getNestedValue } from "../../util/objectMethods";
import Template1 from '../Templates/Template1';
import Template2 from '../Templates/Template2';
import Template3 from '../Templates/Template3';
import Template4 from '../Templates/Template4';
import Template5 from '../Templates/Template5';
import "react-datepicker/dist/react-datepicker.css";

const components = {
    Template1,
    Template2,
    Template3,
    Template4,
    Template5
}

class TemplateWrapper extends Component {
  constructor(props) {
    super(props);
    console.log('here')
    this.state = {
        companyId: undefined,
        isloading: true
    };
  }

  componentDidMount = async () => {

    const { location: { pathname } } = this.props;
    const pathPieces = pathname.split('/')
    let companyId = pathPieces[2];
    this.setState({companyId})
    if (companyId) {
        const payload = { companyId };
        getSaloonTemplateData(payload)
        .then((res) => {
            if (res.data.success) {
                let templateId = getNestedValue(res.data.data, [0, 'customTempalte', 'templateID'], undefined)
                if(!templateId) {
                    templateId = getNestedValue(res.data.data, [0, 'saloons', 0, 'templateID'], undefined)
                }
                if(templateId) {
                    let template
                    switch(templateId) {
                        case '5ed507830cb92a43b0faa567': 
                            template = 'Template1'
                            break;
                        case '5ed508020cb92a43b0faa568': 
                            template = 'Template2'
                            break;
                        case '5ed508740cb92a43b0faa569': 
                            template = 'Template3'
                            break;
                        case '5fd96f5482302ee4feb8a27a': 
                            template = 'Template4'
                            break;
                        case '6010b1de65c0767d462b20ec': 
                            template = 'Template5'
                            break;
                        default: 
                            template = 'Template1'
                            break;
                    }
                    this.setState({template})
                } else {
                    this.setState({template: 'Template1'})
                }
                this.setState({isloading: false})
                console.log(res.data.data)
            }
        })
        .catch((error) => {
        });
    } else {

    }

  };

  renderTemplate() {
      const { template, companyId } = this.state;
      const MyComponent = components[template]
      const { history, location } = this.props
      console.log(this.props)
      return <MyComponent
        history={history}
        location={location}
      />
  }

  render() {
    const { isloading, template } = this.state;
    return (
      <div>
        {!isloading && template &&
            this.renderTemplate()
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  companyData: state.templateNavigation.data,
});

const action = {};

export default connect(null, action)(TemplateWrapper);
