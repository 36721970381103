import { connect } from "react-redux";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { MenuItem, TextField } from "@material-ui/core";

import i18n from "../../../i18n";
import Swal from "sweetalert2";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import PrimaryButton from "../../../components/Core/PrimaryButton";
import moment from "moment";
import DatePicker from "react-datepicker";
import { BaseModal } from "../../../components";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../../../components/TabPanel/TabPanel.component";
import _ from "lodash";
import BasicTable from "../../../components/Core/BasicTable";
import { Checkbox } from "@material-ui/core";
import ManageScheduledStudentsModal from "./ManageScheduledStudentsModal";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function EventModal({
  open,
  setOpen,
  studentGroups,
  students,
  active = {},
  onSubmit,
  onDelete,
  setAttendance,
  employees,
}) {
  const form = useRef();
  const [data, setData] = useState({});
  const [manageScheduledModalOpen, setManageScheduledModalOpen] =
    useState(false);
  const [tab, setTab] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };
  const init = (data) => {
    setTab(0);
    setData({ ...data });
  };
  useEffect(() => {
    init(active);
  }, []);
  useEffect(() => {
    init(active);
  }, [active]);

  const filteredStudents = useMemo(() => {
    if (!data || !Object.values(data).length) {
      return [];
    }
    return students.filter((student) => {
      return student.studentClasses?.find((_class) => {
        return _class.classId == data.classId._id;
      });
    });
  }, [data, students]);

  const handleClose = () => {
    setData(null);
    setOpen(false);
  };

  const handleChange = (key, value) => {
    data[key] = value;
    setData({ ...data });
  };

  const handleDelete = () => {
    Swal.fire({
      title: i18n.t("are_you_sure_delete"),
      text: i18n.t("cannot_reverse"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ff3600",
      confirmButtonText: i18n.t("yes"),
    }).then((result) => {
      console.log(result);
      if (result.value) {
        onDelete(data._id);
      }
    });
  };

  const handleSubmit = (param = {}) => {
    const body = _.omit({ ...data, ...param }, [
      "date",
      "startTime",
      "endTime",
      "classId",
    ]);
    const { date, startTime, endTime, classId } = data;
    const extractedDate = moment(date).format("MM-DD-YYYY");
    body.startDateTime = moment(`${extractedDate} ${startTime}`);
    body.endDateTime = moment(`${extractedDate} ${endTime}`);
    body.classId = classId._id;
    onSubmit(body);
  };
  const checkTimes = () => {
    const { startTime, endTime } = data;
    if (startTime && endTime) {
      const momentStart = moment(startTime, "hh:mm");
      const momentEnd = moment(endTime, "hh:mm");
      if (momentStart.isAfter(momentEnd)) {
        setData({ ...data, startTime: endTime, endTime: startTime });
      }
    }
  };
  if (!open || !data) {
    return null;
  }
  return (
    <div>
      <BaseModal
        title="Edit Event"
        contentContainerStyle={{
          overflowY: "visible",
        }}
        content={
          <>
            <AppBar position="static">
              <Tabs
                value={tab}
                onChange={handleTabChange}
                aria-label="simple tabs example"
              >
                <Tab label={i18n.t("details")} {...a11yProps(0)} />
                <Tab label={i18n.t("students")} {...a11yProps(1)} />
              </Tabs>
            </AppBar>
            <TabPanel value={tab} index={0}>
              <ValidatorForm
                onSubmit={() => handleSubmit()}
                ref={form}
                className="w-100 row mx-0"
                onError={(error) => {}}
              >
                <TextValidator
                  containerProps={{ className: "col-12" }}
                  className="mb-1"
                  label={i18n.t("class")}
                  value={data.classId?.name}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <SelectValidator
                  containerProps={{ className: "col-12" }}
                  className="mb-1"
                  label={i18n.t("employee")}
                  onChange={(e) => handleChange("employeeId", e.target.value)}
                  value={data.employeeId || ""}
                  select
                >
                  {employees.map((employee) => {
                    return (
                      <MenuItem value={employee.value}>
                        {employee.name}
                      </MenuItem>
                    );
                  })}
                </SelectValidator>
                <DatePicker
                  wrapperClassName="col-12 w-100 mb-1"
                  selected={data.date ? moment(data.date).toDate() : ""}
                  onChange={(e) => handleChange("date", e)}
                  value={data.date ? moment(data.date).toDate() : ""}
                  customInput={
                    <TextValidator
                      label={i18n.t("date")}
                      onChange={(e) => handleChange("date", e.target.value)}
                      value={data.date}
                      validators={["required"]}
                      errorMessages={[i18n.t("this_field_is_required")]}
                    />
                  }
                  placeholderText={i18n.t("end_date")}
                />
                <TextValidator
                  containerProps={{ className: "col-6" }}
                  className="mb-1"
                  label={i18n.t("start_time")}
                  onChange={(e) => handleChange("startTime", e.target.value)}
                  onBlur={checkTimes}
                  type="time"
                  value={data.startTime || ""}
                  validators={["required"]}
                  errorMessages={[i18n.t("this_field_is_required")]}
                />
                <TextValidator
                  containerProps={{ className: "col-6" }}
                  className="mb-1"
                  label={i18n.t("end_time")}
                  onChange={(e) => handleChange("endTime", e.target.value)}
                  onBlur={checkTimes}
                  type="time"
                  value={data.endTime || ""}
                  validators={["required"]}
                  errorMessages={[i18n.t("this_field_is_required")]}
                />
                <div className="row mx-0 px-0 justify-content-end mt-2 col-12">
                  <PrimaryButton
                    label={i18n.t("delete")}
                    onClick={handleDelete}
                    className="mr-1"
                  />
                  <PrimaryButton
                    props={{
                      type: "submit",
                    }}
                    label={data._id ? i18n.t("save") : i18n.t("create")}
                  />
                </div>
              </ValidatorForm>
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <div className="row mx-0 justify-content-end mb-1">
                <PrimaryButton
                  label={i18n.t("scheduled_students")}
                  onClick={() => setManageScheduledModalOpen(true)}
                />
              </div>
              <BasicTable
                rows={data.students}
                schema={[
                  {
                    header: i18n.t("attended"),
                    style: { width: 80 },
                    field: "attended",
                    render: (val) => {
                      return (
                        <Checkbox
                          checked={val.attended}
                          onChange={() => {
                            setAttendance({
                              _id: data._id,
                              studentId: val._id,
                              attended: !val.attended,
                            });
                          }}
                          inputProps={{ "aria-label": "enrolled" }}
                        />
                      );
                    },
                  },
                  {
                    header: i18n.t("student"),
                    field: "student",
                    style: { verticalAlign: "middle" },
                    accessor: (val) => val.studentId?.name,
                  },
                  {
                    header: i18n.t("note"),
                    field: "note",
                    render: (val) => {
                      return (
                        <TextField
                          defaultValue={val.note}
                          label={i18n.t("note")}
                          fullWidth
                          onBlur={(e) => {
                            if (e.target.value != val.note) {
                              setAttendance({
                                _id: data._id,
                                studentId: val._id,
                                note: e.target.value,
                              });
                            }
                          }}
                        />
                      );
                    },
                  },
                ]}
              />
            </TabPanel>
          </>
        }
        onClose={handleClose}
        open={open}
      />
      {manageScheduledModalOpen ? (
        <ManageScheduledStudentsModal
          open={manageScheduledModalOpen}
          setOpen={setManageScheduledModalOpen}
          filteredStudents={filteredStudents}
          scheduledStudents={data.students}
          onSubmit={handleSubmit}
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  students: state.student.data,
  studentGroups: state.studentGroup.data,
  classes: state._class.data,
});

const action = {};

export default connect(mapStateToProps, action)(EventModal);
