import React, { useEffect, useState } from "react";
import i18n from "../../i18n";
import BaseModal from "../BaseModal";
import PrimaryButton from "../Core/PrimaryButton";
import CreatePackageInvoice from "./CreatePackageInvoice.component";
import CreateProductsInvoice from "./CreateProductsInvoice";
import "./style.scss";
const CreateInvoice = ({ close, companies, companyTaxMap }) => {
  const [type, setType] = useState();
  const renderType = () => {
    if (type == "package") {
      return (
        <CreatePackageInvoice
          close={close}
          companies={companies}
          companyTaxMap={companyTaxMap}
        />
      );
    } else {
      return (
        <CreateProductsInvoice
          close={close}
          companies={companies}
          companyTaxMap={companyTaxMap}
        />
      );
    }
  };
  return (
    <div className="create-invoice">
      <BaseModal
        open={true}
        containerClassName={"half-container"}
        {...{
          title: i18n.t("sale"),
          content: (
            <div>
              {type ? (
                renderType()
              ) : (
                <div className="row mx-0">
                  <div className="col-6">
                    <PrimaryButton
                      label={i18n.t("package")}
                      onClick={() => {
                        setType("package");
                      }}
                      className="full-width"
                    />
                  </div>
                  <div className="col-6">
                    <PrimaryButton
                      label={i18n.t("products")}
                      onClick={() => {
                        setType("products");
                      }}
                      className="full-width"
                    />
                  </div>
                </div>
              )}
            </div>
          ),
        }}
        onClose={close}
      />
    </div>
  );
};

export default CreateInvoice;
