import { connect } from "react-redux";
import React, { Component } from "react";
import { Images } from "../../theme";
import { request as employee_logout } from "../../redux/actions/Employee/LogOut";
import { request as saloon_logout } from "../../redux/actions/Saloon/LogOut";
import { clearLogout as clear_logout } from "../../redux/actions/LogOut";
import { request as update_notification } from "../../redux/actions/UpdateNotification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { SuccessHelper, ErrorHelper } from "../../helpers";
import NotificationCenter from "../NotificationCenter";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Annoucements from "../Announcements";

import "./style.scss";
import i18n from "../../i18n";
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropDown: false,
      access_token: "",
    };
    this.notificationIcon = React.createRef();
  }

  renderLogout = () => {
    // const { routeName } = this.state;
    return (
      <li
        className={"sidebar-inactive-list buttonColor py-2 text-center"}
        onClick={() => this.handleLogout()}
      >
        <img src={Images.saloon_drawer_log_out} style={{ width: 20 }} />
        <span style={{ marginLeft: 12 }}>{i18n.t("logout")}</span>
      </li>
    );
  };

  componentDidMount() {
    const saloon = JSON.parse(sessionStorage.getItem("saloon"));
    const isSalon = saloon ? Boolean(Object.keys(saloon).length) : false;
    const employee = JSON.parse(sessionStorage.getItem("employee"));
    const isEmployee = Boolean(employee);
    const user = { ...saloon, ...employee };
    this.setState({
      user,
      access_token: user.access_token,
      isSalon,
      isEmployee,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.saloonLogout) {
      if (
        !nextProps.saloonLogout.failure &&
        !nextProps.saloonLogout.isFetching &&
        !nextProps.saloonLogout.errorMessage &&
        nextProps.saloonLogout.data &&
        nextProps.saloonLogout.data.success
      ) {
        nextProps.clear_logout();
        SuccessHelper.handleSuccess("You have successfully logged out.", true);
        this.handleNavigation("/saloon-login");
      } else if (
        nextProps.saloonLogout.failure &&
        !nextProps.saloonLogout.isFetching &&
        nextProps.saloonLogout.errorMessage
      ) {
        this.setState({ isloading: false });
      }
    }
    if (nextProps.employeeLogout) {
      if (
        !nextProps.employeeLogout.failure &&
        !nextProps.employeeLogout.isFetching &&
        !nextProps.employeeLogout.errorMessage &&
        nextProps.employeeLogout.data &&
        nextProps.employeeLogout.data.success
      ) {
        nextProps.clear_logout();
        SuccessHelper.handleSuccess("You have successfully logged out.", true);
        this.handleNavigation("/employee-login");
      } else if (
        nextProps.employeeLogout.failure &&
        !nextProps.employeeLogout.isFetching &&
        nextProps.employeeLogout.errorMessage
      ) {
        this.setState({ isloading: false });
      }
    }
  }

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  handleLogout = () => {
    const { access_token } = this.state;
    this.props.saloon_logout({ access_token });
    this.props.employee_logout({ access_token });
    SuccessHelper.handleSuccess("You have successfully logged out.", true);
    this.props.history.push("/");
  };

  setAnchor = (e) => {
    if (e) {
      this.setState({ anchor: this.notificationIcon.current });
    } else {
      this.setState({ anchor: null });
    }
  };

  render = () => {
    const { onSetSidebarOpen, notifications = [], announcements } = this.props;
    const { showDropDown, user, anchor, isSalon, isEmployee } = this.state;
    if (!user) return null;
    return (
      <div>
        <NotificationCenter
          {...{
            notifications,
            anchor,
            setAnchor: this.setAnchor,
            isSalon,
            isEmployee,
            history: this.props.history,
          }}
        />
        <AppBar
          position="fixed"
          elevation={0}
          style={{
            backgroundColor: "#002147",
            zIndex: 1301,
            paddingTop: announcements && announcements.length ? 20 : 0,
          }}
        >
          <Annoucements />
          <Toolbar>
            <div className="row mx-0 justify-content-between align-items-center header w-100">
              <div>
                <FontAwesomeIcon
                  onClick={() => onSetSidebarOpen(true)}
                  className="ml-1"
                  color="white"
                  icon={["fas", "bars"]}
                  style={{ width: 22, height: 22, cursor: "pointer" }}
                />
              </div>
              <div className="row mx-0 align-items-center">
                <span
                  style={{ cursor: "pointer" }}
                  onClick={this.setAnchor}
                  ref={this.notificationIcon}
                >
                  <FontAwesomeIcon
                    color="white"
                    icon={["fas", "bell"]}
                    style={{ width: 20, height: 20 }}
                  />
                  {notifications.filter(
                    (notification) => notification.status == 1
                  ).length ? (
                    <span className="badge" />
                  ) : null}
                </span>
                <div
                  className="ml-3 d-flex align-items-center"
                  onClick={() =>
                    this.setState({ showDropDown: !this.state.showDropDown })
                  }
                >
                  <img src={Images.saloon_profile_icon} style={{ width: 35 }} />

                  <div className="mx-2">
                    <span className="saloon-user-name">
                      {user.firstName + " " + user.lastName}
                    </span>
                    <span className="saloon-sub-name">{user.email}</span>
                  </div>
                  <span className="ml-1">
                    <FontAwesomeIcon
                      className="ml-1"
                      color="white"
                      icon={["fas", "angle-down"]}
                      style={{ width: 20, height: 20, cursor: "pointer" }}
                    />
                  </span>
                  <div className="dropDown">
                    {!!showDropDown && this.renderLogout()}
                  </div>
                </div>
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <Toolbar
          style={{
            marginBottom: announcements && announcements.length ? 20 : 0,
          }}
        />
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  saloonLogout: state.saloonLogout,
  employeeLogout: state.employeeLogout,
  notifications: [...state.getNotifications.data],
  announcements: state.announcements.data,
});

const action = {
  employee_logout,
  saloon_logout,
  clear_logout,
  update_notification,
};

export default connect(mapStateToProps, action)(Header);
