// @flow

import { PACKAGES } from "./ActionTypes";
export function getRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: PACKAGES.GET_SAGA,
  };
}

export function get(data) {
  return {
    data,
    type: PACKAGES.GET,
  };
}

export function insertRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: PACKAGES.INSERT_SAGA,
  };
}

export function insert(data) {
  return {
    data,
    type: PACKAGES.INSERT,
  };
}

export function updateRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: PACKAGES.UPDATE_SAGA,
  };
}

export function remove(data) {
  return {
    data,
    type: PACKAGES.DELETE,
  };
}

export function removeRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: PACKAGES.DELETE_SAGA,
  };
}

export function update(data) {
  return {
    data,
    type: PACKAGES.UPDATE,
  };
}

export function removeItemsRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: PACKAGES.REMOVE_ITEMS_SAGA,
  };
}

export function removeItems(data) {
  return {
    data,
    type: PACKAGES.REMOVE_ITEMS,
  };
}

export function insertItemsRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: PACKAGES.INSERT_ITEMS_SAGA,
  };
}

export function insertItems(data) {
  return {
    data,
    type: PACKAGES.INSERT_ITEMS,
  };
}

export function setActive(data) {
  return {
    data,
    type: PACKAGES.SET_ACTIVE,
  };
}

export function getUserPackagesRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: PACKAGES.GET_USER_PACKAGES_SAGA,
  };
}

export function getUserPackages(data) {
  return {
    data,
    type: PACKAGES.GET_USER_PACKAGES,
  };
}

export function soldPackageRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: PACKAGES.SOLD_SAGA,
  };
}

export function soldPackage(data) {
  return {
    data,
    type: PACKAGES.SOLD,
  };
}

export function getSoldPackageRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: PACKAGES.GET_SOLD_SAGA,
  };
}

export function getSoldPackage(data) {
  return {
    data,
    type: PACKAGES.GET_SOLD,
  };
}

export function getUserPackagesByPhoneRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: PACKAGES.GET_USER_PACKAGE_BY_PHONE_SAGA,
  };
}

export function getUserPackagesByPhone(data) {
  return {
    data,
    type: PACKAGES.GET_USER_PACKAGE_BY_PHONE,
  };
}

export function addTransactionRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: PACKAGES.ADD_TRANSACTION_SAGA,
  };
}

export function addTransaction(data) {
  return {
    data,
    type: PACKAGES.ADD_TRANSACTION,
  };
}

export function reverseTransactionRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: PACKAGES.REVERSE_TRANSACTION_SAGA,
  };
}

export function reverseTransaction(data) {
  return {
    data,
    type: PACKAGES.REVERSE_TRANSACTION,
  };
}

export function requestFailure() {
  return {
    type: PACKAGES.REQUEST_FAILURE,
  };
}
