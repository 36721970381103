// @flow

import { EASY1_PRODUCTS } from "../ActionTypes";

export function getRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: EASY1_PRODUCTS.GET_SAGA
  };
}

export function set(data) {
  return {
    data,
    type: EASY1_PRODUCTS.SET
  };
}

export function requestFailure() {
  return {
    type: EASY1_PRODUCTS.REQUEST_FAILURE
  };
}