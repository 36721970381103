import { connect } from "react-redux";
import React, { Component, Fragment, useState } from "react";
import { Translation } from 'react-i18next';
import i18n from '../../../i18n';
import { RiEditFill } from "react-icons/ri";
import { DotsLoader, Header } from "../../../components";
import { Images } from "../../../theme";
import SideNav from "./SideNav";
import ReactStars from "react-rating-stars-component";
import {
  getSaloonTemplateData,
  getCompanyPorfolio,
  getSaloonCategories,
  getSaloonServices,
  getEmployeesBySaloonAndService,
  createBooking,
  createUpdateTemplate,
  getTemplateData,
} from "../../../config/simpleApiCalls";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import moment from "moment";
import { SuccessHelper, ErrorHelper } from "../../../helpers";
import {
  baseUrl
} from "../../../config/WebServices";

import "react-datepicker/dist/react-datepicker.css";

import "./styles.css";

class Template1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      routeName: "",
      showSidebar: false,
      showDropdownMenu: false,
      companyId: "",
      show: false,
      portfolioImages: [],
      portfolioVideos: [],
      companyCategories: [],
      companyServices: [],
      companyEmployee: [],
      companyList: [],
      isloading: false,
      arrServices: [],
      arrEmploye: [],
      arrSelectDate: [],
      arrSelectCategory: [{}],
      arrDataServices: [],
      daysMock: [
        { name: "---" },
        { name: "Sun" },
        { name: "Mon" },
        { name: "Tue" },
        { name: "Wed" },
        { name: "Thu" },
        { name: "Fri" },
        { name: "Sat" },
      ],
      selectedEmployee: [],
      arrSelectTime: [],
      selectedServices: [],
      amount: 0,
      employeData: [],

      // Edit Template Feilds
      isEditTemplate: false,
      jumbotronImgFile: null,
      jumbotronImgUrl: null,
      jumbotronFirstHeading: "Let yor hair do the",
      jumbotronSecHeading: "Talking",
      sinceText: "Since 1986",
      aboutFirstHeading: "about",
      aboutSecHeading: "barber shop",
      aboutImgUrl: null,
      aboutImgFile: null,
      priceBgImgUrl: null,
      priceBgImgFile: null,
    };

    this.services = React.createRef();
    this.aboutUs = React.createRef();
    this.pricing = React.createRef();
    this.appointment = React.createRef();
    this.term = React.createRef();
    this.footer = React.createRef();
    this.home = React.createRef();
  }

  handleClose = () => {
    this.setState({
      show: false,
    });
  };

  handleOpen = () => {
    this.setState({
      show: true,
    });
  };

  componentDidMount = async () => {

    const { location: { pathname } } = this.props;
    const pathPieces = pathname.split('/')
    let companyId = pathPieces[2];

    const user = await JSON.parse(sessionStorage.getItem("user"));
    this.setState({
      companyId,
      user: user,
    });
    if (companyId) {
      this.getAllData();
    } else {
      this.getAllTemplateData()
    }

    if (pathname === "/saloon-edit-template-1") {
      this.setState({ isEditTemplate: true });
      const { _id } = JSON.parse(sessionStorage.getItem("company"))
      companyId = _id
    }

    // this.getAllData();
    this.getPortfolioData();
    this.getCategories();

  };

  getAllTemplateData = () => {
    this.setState({ isloading: true });

    let token = JSON.parse(sessionStorage.getItem("saloon"));
    const { state } = this.props.location;

    getTemplateData(token.access_token, state).then((res) => {
      if (res.data.success) {
        this.setState({ isloading: false });

        if (res.data.data != null) {
          this.setState({
            jumbotronFirstHeading: res.data.data.headerFirstHeading,
            jumbotronSecHeading: res.data.data.headerSecondHeading,
            jumbotronImgFile: res.data.data.headerImage,
            jumbotronImgUrl: res.data.data.headerImage,
            aboutFirstHeading: res.data.data.aboutFirstHeading,
            aboutSecHeading: res.data.data.aboutSecondImg,
            aboutImgFile: res.data.data.aboutFirstImg,
            aboutImgUrl: res.data.data.aboutFirstImg,
            priceBgImgUrl: res.data.data.footerImage,
            sinceText: res.data.data.sinceText,
            priceBgImgFile: res.data.data.footerImage,
            isloading: false,
          });
        }
      }
    });
  };

  getAllData = () => {
    this.setState({
      isloading: true,
    });
    const { companyId } = this.state;
    const payload = { companyId };
    getSaloonTemplateData(payload)
      .then((res) => {
        if (res.data.success) {
          console.log("payload:www iff", res.data);

          this.setState({ isloading: false });
          if (res.data.data[0] !== null) {
            console.log("payload:www iff1", res.data);
            this.setState({ companyList: res.data.data });

            this.setState({
              isloading: false,
              employeData: res.data.data[0],
              jumbotronFirstHeading:
                res.data.data[0].customTempalte.headerFirstHeading,
              jumbotronSecHeading:
                res.data.data[0].customTempalte.headerSecondHeading,
              jumbotronImgFile: res.data.data[0].customTempalte.headerImage,
              jumbotronImgUrl: res.data.data[0].customTempalte.headerImage,
              aboutFirstHeading:
                res.data.data[0].customTempalte.aboutFirstHeading,
              aboutSecHeading: res.data.data[0].customTempalte.aboutSecondImg,
              aboutImgFile: res.data.data[0].customTempalte.aboutFirstImg,
              aboutImgUrl: res.data.data[0].customTempalte.aboutFirstImg,
              sinceText: res.data.data[0].customTempalte.sinceText,
              priceBgImgUrl: res.data.data[0].customTempalte.footerImage,
              priceBgImgFile: res.data.data[0].customTempalte.footerImage,
            });
          }
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
      });
  };

  getCategories = async () => {
    const { companyId } = this.state;

    try {
      const res = await getSaloonCategories({ companyId });
      this.setState({ companyCategories: res.data.data });
    } catch (error) {}
  };

  getServices = async (categoryId) => {
    const { companyId } = this.state;
    this.setState({ companyServices: [], selectDate: "", setTime: "" });

    try {
      const res = await getSaloonServices({ companyId, categoryId });
      this.setState({ companyServices: res.data.data });
      return res.data.data;
    } catch (error) {}
  };

  getEmployee = async (serviceId) => {
    const { companyId } = this.state;
    this.setState({ companyEmployee: [], selectDate: "", setTime: "" });

    try {
      const res = await getEmployeesBySaloonAndService({
        companyId,
        serviceId,
      });
      if (res.data.data.length) {
        const companyEmployee = res.data.data.map((val) => {
          const employe = val.employeeId.userId;
          employe._id = val.employeeId._id;
          employe.name = employe.firstName
            ? `${employe.firstName} ${employe.lastName}`
            : employe.userName || "";
          employe.weekPlans = val.weekPlans;
          return employe;
        });
        return companyEmployee;
      }
      return [{ name: "Employee Not Found", _id: false }];
    } catch (error) {}
  };

  getPortfolioData = () => {
    this.setState({ isloading: true });
    const { companyId } = this.state;
    const payload = { companyId };
    getCompanyPorfolio(payload)
      .then((res) => {
        if (res.data.success) {
          // this.setState({ isloading: false, companyPortfolio: res.data.data });
          // this.setState({ isloading: false});
          let portfolioImages = [];
          let portfolioVideos = [];
          res.data.data.map((val) => {
            if (val.post && val.post.length > 0) {
              val.post.map((val2, ind2) => {
                if (val2.images.length > 0) {
                  portfolioImages.push(val2.images[0]);
                } else if (val2.videos.length > 0) {
                  portfolioVideos.push(val2.videos[0]);
                }
              });
            }
          });
          this.setState({ isloading: false, portfolioImages, portfolioVideos });
        }
      })
      .catch((error) => {
        console.log(error, "errorrrrrrrrrrrrrrrrrrr");
        this.setState({ isloading: false });
      });
  };

  // Working for edit template "START"
  handlePublishBtn = () => {
    const {
      jumbotronImgFile,
      jumbotronFirstHeading,
      jumbotronSecHeading,
      sinceText,
      aboutFirstHeading,
      aboutSecHeading,
      aboutImgFile,
      priceBgImgFile,
    } = this.state;
    const { state } = this.props.location;
    this.setState({ isloading: true });

    let payloadFormdata = new FormData();
    payloadFormdata.append("headerImage", jumbotronImgFile);
    payloadFormdata.append("headerFirstHeading", jumbotronFirstHeading);
    payloadFormdata.append("headerSecondHeading", jumbotronSecHeading);
    payloadFormdata.append("sinceText", sinceText);
    payloadFormdata.append("aboutFirstHeading", aboutFirstHeading);
    payloadFormdata.append("aboutSecHeading", aboutSecHeading);
    payloadFormdata.append("aboutFirstImg", aboutImgFile);
    payloadFormdata.append("aboutSecondImg", "");
    payloadFormdata.append("footerImage", priceBgImgFile);
    payloadFormdata.append("templateID", state);

    let token = JSON.parse(sessionStorage.getItem("saloon"));
    console.log(token, "TOKEN");

    createUpdateTemplate(payloadFormdata, token.access_token).then((res) => {
      if (res.data.success) {
        this.setState({ isloading: false });
        SuccessHelper.handleSuccess("Published successfully", true);
      } else {
        this.setState({ isloading: false });
        ErrorHelper.handleErrors("Failed to publish", true);
      }
    });
  };

  jumbotronImgHandler = (event) => {
    const imageUrlRegex = /([a-zA-Z0-9\s_\\.\-:])+(.png|.jpeg|.jpg|.gif)$/gm;
    if (event.target.files[0]) {
      let isValidateType = event.target.files[0].type;
      if (isValidateType.match(imageUrlRegex)) {
        const file = URL.createObjectURL(event.target.files[0]);
        this.setState({
          jumbotronImgUrl: file,
          jumbotronImgFile: event.target.files[0],
        });
      } else {
        ErrorHelper.handleErrors("Image format not supported", true);
      }
    }
  };
  aboutImgHandler = (event) => {
    const imageUrlRegex = /([a-zA-Z0-9\s_\\.\-:])+(.png|.jpeg|.jpg|.gif)$/gm;
    if (event.target.files[0]) {
      let isValidateType = event.target.files[0].type;
      if (isValidateType.match(imageUrlRegex)) {
        const file = URL.createObjectURL(event.target.files[0]);
        this.setState({
          aboutImgUrl: file,
          aboutImgFile: event.target.files[0],
        });
      } else {
        ErrorHelper.handleErrors("Image format not supported", true);
      }
    }
  };
  priceBgImgHandler = (event) => {
    const imageUrlRegex = /([a-zA-Z0-9\s_\\.\-:])+(.png|.jpeg|.jpg|.gif)$/gm;
    if (event.target.files[0]) {
      let isValidateType = event.target.files[0].type;
      if (isValidateType.match(imageUrlRegex)) {
        const file = URL.createObjectURL(event.target.files[0]);
        this.setState({
          priceBgImgUrl: file,
          priceBgImgFile: event.target.files[0],
        });
      } else {
        ErrorHelper.handleErrors("Image format not supported", true);
      }
    }
  };
  onChangeJumbotronFirstHeading = (text) =>
    this.setState({ jumbotronFirstHeading: text.target.value });
  onChangeJumbotronSecHeading = (text) =>
    this.setState({ jumbotronSecHeading: text.target.value });
  onChangeSinceText = (text) => this.setState({ sinceText: text.target.value });
  onChangeAboutFirstHeading = (text) =>
    this.setState({ aboutFirstHeading: text.target.value });
  onChangeAboutSecHeading = (text) =>
    this.setState({ aboutSecHeading: text.target.value });

  renderEditButtons = (onPresEdit, onPressRemove) => {
    return (
      <span className="templateEditBtnDiv">
        <span onClick={onPresEdit} className="templateEditBtns">
          <RiEditFill color="#fff" size={17} />
        </span>
        <span onClick={onPressRemove} className="templateEditBtns">
          &times;
        </span>
      </span>
    );
  };

  renderEditTextInputs = (value, onChange, classes) => (
    <input
      type="text"
      value={value}
      onChange={(text) => onChange(text)}
      className={classes}
    />
  );

  renderEditTemplateHeader = () => (
    <div className="editTemplateHeaderDiv">
      <span
        onClick={() => this.handlePublishBtn()}
        className="editTemplateHeaderBtn"
      >
        Publish
      </span>
      <span
        onClick={() => this.handleNavigation("/saloon-choose-a-template")}
        className="editTemplateHeaderBtn"
      >
        Cancel
      </span>
    </div>
  );

  // Working for edit template "END"

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  renderLargeNavbar = () => {
    const { user, companyList } = this.state;
    let CompanyLogo = companyList[0]?.saloons[0]?.companyLogo;
    return (
      <div
        id="large-navbar"
        className="d-flex align-items-center justify-content-between"
      >
        <img
          style={{ height: "80px", marginLeft: "10px", marginTop: "10px" }}
          alt="easy1 logo 800x300"
          src={CompanyLogo}
          className="logo cursor-pointer"
          onClick={() => this.handleNavigation("/")}
        />
        {companyList.map((saloonsVal) => {
          return (
            <span className="d-flex align-items">
              <span className="navbar-link" id="navItems">
                <img
                  style={{ width: "60px", marginRight: "10px" }}
                  src={Images.saloon_template_nav_phone}
                />
                <div
                  style={{
                    marginLeft: "60px",
                    marginTop: "-50px",
                    marginRight: "20px",
                  }}
                >
                  {saloonsVal?.saloons[0]?.businessTelephone}
                </div>
              </span>
              <span className="navbar-link">
                <img
                  style={{ width: "60px", marginRight: "10px" }}
                  src={Images.saloon_template_nav_mail}
                />
                <div
                  style={{
                    marginLeft: "60px",
                    marginTop: "-50px",
                    marginRight: "20px",
                  }}
                >
                  {saloonsVal?.saloons[0]?.businessEmail}
                </div>
              </span>
              <span className="navbar-link">
                <img
                  style={{ width: "60px", marginRight: "10px" }}
                  src={Images.saloon_template_nav_time}
                />
                <div
                  style={{
                    marginLeft: "60px",
                    marginTop: "-50px",
                    marginRight: "20px",
                  }}
                >
                  {saloonsVal &&
                    saloonsVal.schedule &&
                    saloonsVal.schedule.map(
                      (val) =>
                        val &&
                        val.weekPlans &&
                        val.weekPlans.map((weekData) => {
                          return (
                            <span>
                              {weekData.dayOfWeek === 1 &&
                              weekData.availableStatus === 1 ? (
                                <span style={{ color: "#818D86" }}>Mon</span>
                              ) : null}
                              {weekData.dayOfWeek === 2 &&
                              weekData.availableStatus === 1 ? (
                                <span style={{ color: "#818D86" }}>-Tue</span>
                              ) : null}
                              {weekData.dayOfWeek === 3 &&
                              weekData.availableStatus === 1 ? (
                                <span style={{ color: "#818D86" }}>-Wed</span>
                              ) : null}
                              {weekData.dayOfWeek === 4 &&
                              weekData.availableStatus === 1 ? (
                                <span style={{ color: "#818D86" }}>-Thu</span>
                              ) : null}
                              {weekData.dayOfWeek === 5 &&
                              weekData.availableStatus === 1 ? (
                                <span style={{ color: "#818D86" }}>-Fri</span>
                              ) : null}
                              {weekData.dayOfWeek === 6 &&
                              weekData.availableStatus === 1 ? (
                                <span style={{ color: "#818D86" }}>-Sat</span>
                              ) : null}
                              {weekData.dayOfWeek === 7 &&
                              weekData.availableStatus === 1 ? (
                                <span style={{ color: "#818D86" }}>-Sun</span>
                              ) : null}
                            </span>
                          );
                        })
                    )}
                  {/* <span>Mon-Sat 9:30 </span>
                    <br />
                    <span>Sunday Closed</span> */}
                </div>
              </span>
            </span>
          );
        })}
      </div>
    );
  };
  // template_navigation

  renderSmallNavbar = () => {
    const { showSidebar } = this.state;
    return (
      <div id="small-navbar">
        <div className="py-2 d-flex align-items-center justify-content-between">
          <img
            style={{ width: "200px", marginLeft: "10px" }}
            alt="easy1 logo 800x300"
            src={Images.saloon_template_nav_img}
            className="logo cursor-pointer"
            onClick={() => this.handleNavigation("/")}
          />
          <span
            className="d-flex align-items-center"
            onClick={() =>
              this.setState({ showSidebar: showSidebar ? false : true })
            }
          >
            <img
              src={Images.saloon_navbar_menu}
              style={{ width: 35, height: 35, cursor: "pointer" }}
            />
          </span>
        </div>
        <div className="px-2">
          <SideNav
            refe={{
              services: this.services,
              aboutUs: this.aboutUs,
              pricing: this.pricing,
              appointment: this.appointment,
              term: this.term,
              footer: this.footer,
              home: this.home,
            }}
            {...this.props}
            showSidebar={showSidebar}
          />
        </div>
      </div>
    );
  };

  goToAppointmentForm = async () => {
    // const { user } = this.state;
    // const {
    //   location: { pathname },
    //   history: { push },
    // } = this.props;
    // push(`${pathname}/create-appointment`);
    const { companyId } = this.state;
    const user = await JSON.parse(sessionStorage.getItem("user"));
    window.location.assign(baseUrl+`booking/getForm?id=${companyId}&phoneNo=${user ? user.phoneNo : ''}&name=${user ? user.userName : ''}`); 
  };

  handleView = (key) => {
    this[key].current.scrollIntoView({ behavior: "smooth" });
  };

  renderHeaderLg = () => {
    const { user } = this.state;
    return (
      <div style={{ float: "right" }} className="header-lg row py-3 ">
        <span className="d-flex align-items-center">
          <div class="nav-white">
            <span
              onClick={() => this.handleView("home")}
              className="navbar-link active"
            >
              <a>Home</a>
            </span>
            <span
              onClick={() => this.handleView("services")}
              className="navbar-link"
            >
              <a><Translation>{t=>t('container.services')}</Translation></a>
            </span>
            <span
              onClick={() => this.handleView("aboutUs")}
              className="navbar-link"
            >
              <a>About Us</a>
            </span>
            <span
              onClick={() => this.handleView("term")}
              className="navbar-link"
            >
              <a>Team</a>
            </span>
            <span
              onClick={() => this.handleView("pricing")}
              className="navbar-link"
            >
              <a>Pricing</a>
            </span>
            <span
              onClick={() => this.handleView("appointment")}
              className="navbar-link"
            >
              <a>Appointment</a>
            </span>
            <span
              onClick={() => this.handleView("footer")}
              className="navbar-link"
            >
              <a>Contact</a>
            </span>
          </div>
        </span>
      </div>
    );
  };

  renderHeaderSm = () => {
    const { showSidebar } = this.state;
    return (
      <div
        className="px-4 header-sm"
        style={{ backgroundColor: showSidebar ? "#fff" : "transparent" }}
      >
        <div className="row py-3 d-flex align-items-center justify-content-between"></div>
      </div>
    );
  };

  renderAboutUs = () => {
    const {
      companyList,
      isEditTemplate,
      sinceText,
      aboutFirstHeading,
      aboutSecHeading,
      aboutImgUrl,
      aboutImgFile,
    } = this.state;

    return (
      <div ref={this.aboutUs} id="AboutUs">
        <div className="container" style={{ paddingBottom: "60px" }}>
          <div>
            {!isEditTemplate && (
              <h4
                style={{
                  textAlign: "center",
                  marginTop: "60px",
                  color: "#1F5147",
                }}
              >
                {sinceText ? sinceText : "Since 1986"}
              </h4>
            )}
            {isEditTemplate &&
              this.renderEditTextInputs(
                sinceText,
                this.onChangeSinceText,
                "sinceInp"
              )}
          </div>
          <div style={{ textAlign: "center" }}>
            {!isEditTemplate && (
              <h2>
                <span
                  style={{
                    color: "#000000",
                    fontWeight: "bold",
                    fontSize: "40px",
                  }}
                >
                  {aboutFirstHeading ? aboutFirstHeading : "ABOUT"}{" "}
                </span>
                <span
                  style={{
                    color: "#BAD26D",
                    fontWeight: "bold",
                    fontSize: "40px",
                  }}
                >
                  {aboutSecHeading ? aboutSecHeading : "BARBER SHOP"}
                </span>
              </h2>
            )}
            {isEditTemplate && (
              <>
                {this.renderEditTextInputs(
                  aboutFirstHeading,
                  this.onChangeAboutFirstHeading,
                  "aboutSecHeadingInp aboutSecFirstHeadingInp"
                )}
                {this.renderEditTextInputs(
                  aboutSecHeading,
                  this.onChangeAboutSecHeading,
                  "aboutSecHeadingInp aboutSecSecHeadingInp"
                )}
              </>
            )}
          </div>
          {/* <div>
            {companyList.map(
              (val) =>
                val.saloons &&
                val.saloons.map((saloonsVal) => {
                  return (
                    <p style={{ color: "#7C7A83" }}>
                      {saloonsVal?.companyShortDescription}
                    </p>
                  );
                })
            )}
          </div> */}
          {isEditTemplate && (
            <input
              type="file"
              style={{ display: "none" }}
              onChange={this.aboutImgHandler}
              onClick={(event) => {
                event.target.value = null;
              }}
              ref={(fileInput) => (this.aboutImgInput = fileInput)}
              accept="image/*"
            />
          )}
          <div className={isEditTemplate ? "editImg" : ""}>
            {isEditTemplate &&
              this.renderEditButtons(
                () => this.aboutImgInput.click(),
                () => this.setState({ aboutImgFile: null, aboutImgUrl: null })
              )}
            <img
              class="img-fluid"
              src={
                aboutImgFile != null
                  ? aboutImgUrl
                  : Images.saloon_template_about
              }
            />
          </div>
        </div>
      </div>
    );
  };

  renderJumbotron = () => {
    const {
      isEditTemplate,
      jumbotronImgUrl,
      jumbotronImgFile,
      jumbotronFirstHeading,
      jumbotronSecHeading,
    } = this.state;
    return (
      <div
        className={`container-fluid px-0 min-vh-100${
          isEditTemplate ? "editImg" : ""
        }`}
        style={{
          backgroundImage: `url(${
            jumbotronImgFile != null
              ? jumbotronImgUrl
              : Images.saloon_template_barber
          })`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {isEditTemplate && (
          <input
            type="file"
            style={{ display: "none" }}
            onChange={this.jumbotronImgHandler}
            onClick={(event) => {
              event.target.value = null;
            }}
            ref={(fileInput) => (this.jumbotronImgInput = fileInput)}
            accept="image/*"
          />
        )}
        {isEditTemplate &&
          this.renderEditButtons(
            () => this.jumbotronImgInput.click(),
            () =>
              this.setState({ jumbotronImgFile: null, jumbotronImgUrl: null })
          )}
        {this.renderHeaderSm()}

        {this.renderHeaderLg()}
        <div className="container">
          <div ref={this.home} id="home" className="row">
            <div className="centered">
              {!isEditTemplate && (
                <h1
                  className="text-green template-1-main-header"
                  style={{
                    textTransform: "uppercase",
                  }}
                >
                  {jumbotronFirstHeading
                    ? jumbotronFirstHeading
                    : "Let yor hair do the"}
                </h1>
              )}
              {isEditTemplate &&
                this.renderEditTextInputs(
                  jumbotronFirstHeading,
                  this.onChangeJumbotronFirstHeading,
                  "jumbotronHeadingInp jumbotronFirstHeading"
                )}
              <br />
              {!isEditTemplate && (
                <h1
                  className="text-green template-1-main-header"
                  style={{
                    textTransform: "uppercase",
                  }}
                >
                  {jumbotronSecHeading ? jumbotronSecHeading : "Talking"}
                </h1>
              )}
              {isEditTemplate &&
                this.renderEditTextInputs(
                  jumbotronSecHeading,
                  this.onChangeJumbotronSecHeading,
                  "jumbotronHeadingInp jumbotronSecHeading"
                )}
            </div>
            <div class="row">
              <div class="drop-centered">
                <a href="#home">
                  <img
                    class="text-center"
                    src={Images.saloon_template_dropdown}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderServiceItem = (img, name, duration) => {
    return (
      <Fragment>
        <img
          style={{ marginBottom: 15, width: "100px", height: "100px" }}
          src={img}
        />
        <h3 style={{ color: "#BAD26D" }} class="name">
          {name} (Estimated Time: {duration})
        </h3>
        <div class="cover">{/* <p className="cover-text">{title}</p> */}</div>
      </Fragment>
    );
  };

  renderServices = () => {
    const { companyList } = this.state;
    console.log("Template1 -> renderServices -> companyList", companyList);
    return (
      <div ref={this.services} id="Services">
        <div
          class="features-clean"
          style={{ backgroundColor: "#272e32", paddingBottom: "60px" }}
        >
          <div class="container">
            <div class="intro">
              <h2
                style={{
                  fontSize: "30px",
                  fontWeight: "bold",
                  paddingTop: "60px",
                }}
                class="text-center mb-0"
              >
                SERVICES
              </h2>
              <hr
                style={{
                  height: "10px",
                  border: "none",
                  backgroundColor: "#BAD26D",
                  width: "220px",
                }}
              />
            </div>
            <div class="row features">
              {companyList.length
                ? companyList.map(
                    (val) =>
                      val.services &&
                      val.services.map((serviceVal) => {
                        return (
                          <div
                            class="col-12 col-md-3 item d-flex justify-content-center flex-column align-items-center cursor-pointer"
                            style={{ padding: "5rem", marginBottom: 15 }}
                            onClick={this.goToAppointmentForm}
                          >
                            {this.renderServiceItem(
                              serviceVal.image,
                              serviceVal.name,
                              serviceVal?.duration
                            )}
                          </div>
                        );
                      })
                  )
                : null}
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderPriceItem = (style, price) => {
    return (
      <Fragment>
        <h5 class="price-heading">{style}</h5>
        <p className="text-center mb-0">{price}$</p>
      </Fragment>
    );
  };

  renderPrices = () => {
    const {
      companyList,
      isEditTemplate,
      priceBgImgFile,
      priceBgImgUrl,
    } = this.state;
    return (
      <div ref={this.pricing} id="Pricing">
        <div
          className={`container-fluid px-0 min-vh-100 ${
            isEditTemplate ? "editImg" : ""
          }`}
          style={{
            backgroundImage: `url(${
              priceBgImgFile != null
                ? priceBgImgUrl
                : Images.saloon_template_price
            })`,
            backgroundSize: "cover",
            paddingBottom: "60px",
          }}
        >
          {isEditTemplate && (
            <input
              type="file"
              style={{ display: "none" }}
              onChange={this.priceBgImgHandler}
              onClick={(event) => {
                event.target.value = null;
              }}
              ref={(fileInput) => (this.priceBgImgInput = fileInput)}
              accept="image/*"
            />
          )}
          {isEditTemplate &&
            this.renderEditButtons(
              () => this.priceBgImgInput.click(),
              () => this.setState({ priceBgImgFile: null, priceBgImgUrl: null })
            )}
          <div className="container" style={{ justifyContent: "center" }}>
            <div class="intro">
              <h2
                style={{
                  fontSize: "30px",
                  fontWeight: "bold",
                  color: "#FFFFFF",
                  padding: "1rem",
                  paddingTop: "60px",
                }}
                class="text-center mb-0 pb-0"
              >
                PRICES
              </h2>
              <hr
                style={{
                  height: "10px",
                  border: "none",
                  backgroundColor: "#BAD26D",
                  width: "220px",
                }}
              />
            </div>
            <div className="row" style={{ paddingTop: 50 }}>
              {companyList.length &&
                companyList.map(
                  (val) =>
                    val.services &&
                    val.services.map((serviceVal) => {
                      return (
                        <div class="col-lg-3 col-md-4" id="price-grid">
                          {this.renderPriceItem(
                            serviceVal.name,
                            serviceVal.price
                          )}
                        </div>
                      );
                    })
                )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderAppointmentForm = (type, text, key, isDisable, value) => {
    if (isDisable) {
      return (
        <input
          disabled={isDisable}
          value={value}
          onChange={this.handleInput}
          class="form-group textStyle"
          name={key}
          id="appointment-form"
          type={type}
          placeholder={text}
        />
      );
    }
    return (
      <input
        onChange={this.handleInput}
        class="form-group textStyle"
        name={key}
        id="appointment-form"
        type={type}
        placeholder={text}
      />
    );
  };

  handleInput = (e) => this.setState({ [e.target.name]: e.target.value });

  renderAppointmentCol4 = (type, text) => {
    return (
      <input
        class="form-group"
        id="appointment-form-name"
        type={type}
        placeholder={text}
      />
    );
  };

  renderSelect = (values, name) => {
    return (
      <select
        onChange={(e) => this.handleChange(e.target.value, name)}
        class="col-lg-3 col-md-6 mt-5 selectNew"
        id="appointment-grid"
      >
        <option class="form-group" value={false} id="appointment-form">
          {values.length ? `Select ${name}` : `Wait ${name} Loading ...`}
        </option>
        {values.map((cat, i) => (
          <option
            key={i}
            value={JSON.stringify(cat)}
            class="form-group"
            id="appointment-form"
          >
            {cat.name}
          </option>
        ))}
      </select>
    );
  };

  handleChange = (value, name) => {
    if (!value) return this.setState({ [`selected${name}`]: data });
    const data = JSON.parse(value);

    if (name === "Category" && data) this.getServices(data._id);
    if (name === "Service" && data) this.getEmployee(data._id);
    if (name === "Employee") this.setState({ setTime: "", selectDate: "" });
    this.setState({ [`selected${name}`]: data });
  };

  onDateChange = (selectDate, selectedEmployee, index) => {
    const { daysMock, arrSelectDate, arrServices } = this.state;
    const getDay = moment(selectDate).format("ddd");
    arrServices[index + 1] = {
      ...arrServices[index + 1],
      date: moment(selectDate).format("MM-DD-YYYY"),
    };

    const getDayTime = selectedEmployee.weekPlans.find(
      (val) => daysMock[val.dayOfWeek].name === getDay
    );
    arrSelectDate[index + 1] = { selectDate, selectDayOfTime: getDayTime };
    this.setState({ arrSelectDate });
  };

  checkDateIsAvil = (day, selectedEmployee) => {
    const { daysMock } = this.state;
    const getDay = moment(day).format("ddd");

    const companyDate = [];
    selectedEmployee.weekPlans.length &&
      selectedEmployee.weekPlans.forEach((val) => {
        if (val.availableStatus) {
          return companyDate.push(val);
        }
      });

    const availDay = [];
    companyDate.map((weekPlans) => {
      availDay.push(daysMock[weekPlans.dayOfWeek].name);
    });

    return !availDay.includes(getDay) ? "disabled-date" : undefined;
  };

  onTimeChange = (time, index) => {
    const { arrSelectTime, arrServices } = this.state;
    arrSelectTime[index + 1] = { time };
    arrServices[index + 1] = {
      ...arrServices[index + 1],
      time: moment(time).format("h:mm"),
    };

    this.setState({ arrSelectTime, arrServices });
  };

  renderTimeSlot = (time, index) => {
    const { arrSelectDate } = this.state;
    const selectDayOfTime = arrSelectDate[index + 1].selectDayOfTime;
    if (!selectDayOfTime) return;
    const selectedTime = moment(time).format("h:mm:ss a");
    document.querySelectorAll(".disabled-times").forEach(function (a) {
      a.remove();
    });

    const checkIn = new Date(`01/01/2001 ${selectDayOfTime.checkIn}`).getTime();
    const checkOut = new Date(
      `01/01/2001 ${selectDayOfTime.checkOut}`
    ).getTime();
    const a = new Date(`01/01/2001 ${selectedTime}`).getTime();

    if (
      Math.min(checkIn, checkOut) <= Math.max(a, a) &&
      Math.max(checkIn, checkOut) >= Math.min(a, a)
    )
      return undefined;

    return "disabled-times";
  };

  handleSubmit = async () => {
    const {
      selectedCategory,
      selectedService,
      selectedEmployee,
      companyId,
      phoneNo,
      postalCode,
      paymentMethod,
      arrServices,
      email,
      setTime,
      selectDate,
      amount,
      user,
    } = this.state;
    const payload = { status: 1, companyId };
    const services = [];

    // if (!postalCode) return ErrorHelper.handleErrors("Enter Postal Code", true);
    // payload.postalCode = postalCode;

    if (!email) return ErrorHelper.handleErrors("Enter email", true);
    payload.email = email;

    if (!phoneNo) return ErrorHelper.handleErrors("Enter Phone Number", true);
    payload.phoneNo = phoneNo;
    let ErrorMsg = [];

    arrServices.map((value, index) => {
      if (!value) return;
      let nth = 0;
      if (index == 1) {
        nth = "1st";
      } else if (index == 2) {
        nth = "2nd";
      } else if (index == 3) {
        nth = "3rd";
      } else {
        nth = `${index}th`;
      }

      const payloadServices = {};
      if (!value.serviceId) {
        ErrorMsg.push(false);
        ErrorHelper.handleErrors(`Select ${nth} Service`, true);
        return;
      }
      payloadServices.serviceId = value.serviceId;

      if (!value.employeeId) {
        ErrorMsg.push(false);
        ErrorHelper.handleErrors(`Select ${nth} Employ`, true);
        return;
      }
      payloadServices.employeeId = value.employeeId;

      if (!value.categoryId) {
        ErrorMsg.push(false);
        ErrorHelper.handleErrors(`Select ${nth} Category`, true);
        return;
      }
      payloadServices.categoryId = value.categoryId;

      if (!value.date) {
        ErrorMsg.push(false);
        ErrorHelper.handleErrors(`Select ${nth} Date`, true);
        return;
      }
      payloadServices.date = value.date;

      if (!value.time) {
        ErrorMsg.push(false);
        ErrorHelper.handleErrors(`Select ${nth} Time`, true);
        return;
      }
      payloadServices.time = value.time;

      services.push(payloadServices);
    });

    if (ErrorMsg.includes(false)) return;
    if (!arrServices.length)
      return ErrorHelper.handleErrors("Select Any Service", true);

    if (!this.state["selectedPayment Method"])
      return ErrorHelper.handleErrors("Select Payment Method", true);
    payload.paymentMethod = this.state["selectedPayment Method"].name;

    payload.totalAmount = amount;

    payload.services = services;
    if (!user?.access_token) {
      ErrorHelper.handleErrors("Please login first", true);
      setTimeout(() => {
        this.props.history.push("/login");
      }, 2000);
    } else {
      try {
        this.setState({ isloading: true });
        const res = await createBooking(payload, user.access_token);
        SuccessHelper.handleSuccess("Appointment Created", true);
        window.location.reload();
        this.setState({ isloading: false });
      } catch (error) {
        this.setState({ isloading: false });
        ErrorHelper.handleErrors("Somthing went wronge", true);
      }
    }
  };

  selectCategory = async (value, index) => {
    value = JSON.parse(value);
    const {
      arrSelectCategory,
      arrServices,
      arrDataServices,
      selectedServices,
      arrEmploye,
      selectedEmployee,
      arrSelectDate,
      arrSelectTime,
    } = this.state;
    if (!value._id) {
      delete arrServices[index + 1];
      delete arrSelectCategory[index + 1];
      delete arrDataServices[index + 1];
      delete selectedServices[index + 1];
      delete arrEmploye[index + 1];
      delete selectedEmployee[index + 1];
      delete arrSelectDate[index + 1];
      delete arrSelectTime[index + 1];
      this.setState({
        arrServices,
        arrEmploye,
        selectedServices,
        selectedEmployee,
        arrSelectDate,
        arrSelectTime,
      });
      return;
    }

    arrServices[index + 1] = { categoryId: value._id };
    arrSelectCategory[index + 1] = value;

    try {
      this.setState({ isloading: true });
      const allServices = await this.getServices(value._id);
      arrDataServices[index + 1] = allServices;
      this.setState({
        arrServices,
        arrSelectCategory,
        arrDataServices,
        isloading: false,
      });
    } catch (error) {
      ErrorHelper.handleErrors("Category Not selected", true);
    }
  };

  selectMultiServices = async (value, index) => {
    value = JSON.parse(value);
    const {
      arrServices,
      arrEmploye,
      selectedServices,
      selectedEmployee,
      arrSelectDate,
      arrSelectTime,
    } = this.state;
    if (!value._id) {
      arrServices[index + 1] = {
        ...arrServices[index + 1],
        serviceId: false,
        employeeId: false,
        time: false,
        date: false,
      };
      delete selectedServices[index + 1];
      delete arrEmploye[index + 1];
      delete selectedEmployee[index + 1];
      delete arrSelectDate[index + 1];
      delete arrSelectTime[index + 1];
      this.setState(
        {
          arrServices,
          arrEmploye,
          selectedServices,
          selectedEmployee,
          arrSelectDate,
          arrSelectTime,
        },
        () => {
          let amount = 0;
          this.state.selectedServices.forEach((val) => {
            if (val) amount = amount + val.price;
          });
          this.setState({ amount });
        }
      );
      return;
    }
    this.setState({ isloading: true });

    arrServices[index + 1] = {
      ...arrServices[index + 1],
      serviceId: value._id,
    };
    selectedServices[index + 1] = value;
    let amount = 0;
    selectedServices.forEach((val) => {
      if (val) {
        amount = amount + val.price;
      }
    });
    try {
      const employs = await this.getEmployee(value._id);
      arrEmploye[index + 1] = employs;
      this.setState({
        arrServices,
        isloading: false,
        arrEmploye,
        selectedServices,
        amount,
      });
    } catch (error) {
      this.setState({ isloading: false });
      ErrorHelper.handleErrors("Service Not selected", true);
    }
  };

  selectEmploy = async (value, index) => {
    value = JSON.parse(value);
    const {
      arrServices,
      selectedEmployee,
      arrSelectDate,
      arrSelectTime,
    } = this.state;
    if (!value._id) {
      arrServices[index + 1] = {
        ...arrServices[index + 1],
        employeeId: false,
        time: false,
        date: false,
      };
      delete selectedEmployee[index + 1];
      delete arrSelectDate[index + 1];
      delete arrSelectTime[index + 1];
      this.setState({
        arrServices,
        selectedEmployee,
        arrSelectDate,
        arrSelectTime,
      });
      return;
    }

    arrServices[index + 1] = {
      ...arrServices[index + 1],
      employeeId: value._id,
    };
    selectedEmployee[index + 1] = value;
    this.setState({ arrServices, selectedEmployee });
  };

  renderAppointment = () => {
    const {
      companyList,
      selectDayOfTime,
      companyCategories,
      selectedCategory,
      companyServices,
      companyEmployee,
      selectedService,
      selectedEmployee,
      arrServices,
      arrSelectCategory,
      arrDataServices,
      arrSelectDate,
      arrSelectTime,
      arrEmploye,
      amount,
    } = this.state;
    let num = 0;

    return (
      <div ref={this.appointment} id="Appointment">
        <div class="features-clean" style={{ backgroundColor: "#272e32" }}>
          <div class="container">
            <div class="intro">
              <h2
                style={{
                  fontSize: "30px",
                  fontWeight: "bold",
                  paddingTop: "100px",
                }}
                class="text-center mb-0"
              >
                APPOINTMENT
              </h2>
              <hr
                style={{
                  height: "10px",
                  border: "none",
                  backgroundColor: "#BAD26D",
                  width: "220px",
                }}
              />
              <button
                style={{ marginLeft: "35%" }}
                onClick={this.goToAppointmentForm}
                class="btn-text"
              >
                Make Appointment
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderEmployeesItem = (img, name, title) => {
    return (
      <div
        class="box"
        style={{
          backgroundImage: `url(${img})`,
          width: "200px",
          height: "200px",
        }}
      >
        <div class="employee">
          <h3 class="name">{name}</h3>
          <p class="title">{title}</p>
          <div class="social">
            <img
              style={{ width: "20px", height: "20px" }}
              src={Images.saloon_template_facebook}
            />
            <img
              style={{ width: "20px", height: "20px" }}
              src={Images.saloon_template_youtube}
            />
            <img
              style={{ width: "20px", height: "20px" }}
              src={Images.saloon_template_twitter}
            />
            <img
              style={{ width: "20px", height: "20px" }}
              src={Images.saloon_template_insta}
            />
          </div>
        </div>
      </div>
    );
  };

  renderEmployees = () => {
    const { companyList, employeData } = this.state;

    return (
      <div ref={this.term} id="Team">
        <div
          className="team-grid"
          style={{ backgroundColor: "#272e32", paddingBottom: "60px" }}
        >
          <div class="container">
            <div class="intro">
              <h2
                style={{
                  fontSize: "30px",
                  fontWeight: "bold",
                  color: "#FFFFFF",
                  paddingTop: "100px",
                }}
                class="text-center mb-0"
              >
                OUR EMPLOYEES
              </h2>
              <hr
                style={{
                  height: "10px",
                  border: "none",
                  backgroundColor: "#BAD26D",
                  width: "220px",
                }}
              />
              <div className="row" style={{ paddingTop: 50 }}>
                {employeData.employees &&
                  employeData.employees.length &&
                  employeData?.employees.filter(employee => !!employee && typeof employee.userId == 'object').map((val) => {
                    let userName = val?.employeeId?.userId?.firstName
                      ? `${val?.employeeId?.userId.firstName}${" "}${
                          val?.employeeId?.userId.lastName
                        }`
                      : val?.employeeId?.userId.userName;
                    let rating = val?.employeeId?.averageRating;

                    return (
                      <div class="col-lg-4 col-md-4" id="price-grid">
                        <Fragment>
                          <h6 class="price-heading">{userName}</h6>

                          {/* <p className="text-center mb-0">{"price"}$</p> */}
                          <div
                            className="pl-4"
                            style={{
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <ReactStars
                              count={5}
                              // onChange={ratingChanged}
                              size={24}
                              activeColor="orange"
                              value={rating}
                              edit={false}
                            />
                          </div>
                        </Fragment>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div class="row" style={{ paddingTop: 50 }}>
              {companyList.map(
                (val) =>
                  val.employeeDetails &&
                  val.employeeDetails.map((employeeVal) => {
                    return (
                      <div class="col-md-6 col-lg-4 item">
                        {this.renderEmployeesItem(
                          employeeVal.profile_img,
                          employeeVal.firstName,
                          employeeVal.lastName
                        )}
                      </div>
                    );
                  })
              )}

              {/* <div class="col-md-6 col-lg-4 item"> */}
              {/* {this.renderEmployeesItem()} */}
              {/* {this.renderEmployeesItem(Images.saloon_template_employee1,"Ben Johnson","Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s")} */}
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderBrandImage = (img) => {
    return (
      <Fragment>
        <img src={img} />
      </Fragment>
    );
  };

  renderBrands = () => {
    return (
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-lg-2 d-flex justify-content-center flex-column align-items-center">
            {this.renderBrandImage(Images.saloon_template_sponser1)}
          </div>
          <div class="col-md-4 col-lg-2 d-flex justify-content-center flex-column align-items-center">
            {this.renderBrandImage(Images.saloon_template_sponser2)}
          </div>
          <div class="col-md-4 col-lg-2 d-flex justify-content-center flex-column align-items-center">
            {this.renderBrandImage(Images.saloon_template_sponser3)}
          </div>
          <div class="col-md-4 col-lg-2 d-flex justify-content-center flex-column align-items-center">
            {this.renderBrandImage(Images.saloon_template_sponser4)}
          </div>
          <div class="col-md-4 col-lg-2 d-flex justify-content-center flex-column align-items-center">
            {this.renderBrandImage(Images.saloon_template_sponser5)}
          </div>
          <div class="col-md-4 col-lg-2 d-flex justify-content-center flex-column align-items-center">
            {this.renderBrandImage(Images.saloon_template_sponser1)}
          </div>
        </div>
      </div>
    );
  };

  renderPortfolioItem = (type, image) => {
    return (
      <div class="col-lg-3 col-md-4">
        <a href={type} data-attribute="SRL">
          <img class="img-fluid" src={image} style={{ marginBottom: 20 }} />
        </a>
      </div>
    );
  };

  renderPortfolioItemVideo = (image) => {
    return (
      <div>
        <div class="col-lg-3 col-md-4">
          <video width="320" height="240" controls>
            <source src={image} />
          </video>
        </div>
      </div>
    );
  };

  renderPortfolio = () => {
    const { portfolioImages } = this.state;
    return (
      <div class="photo-gallery" style={{ paddingBottom: "80px" }}>
        <div class="container">
          <div class="intro">
            <h2
              style={{
                fontSize: "30px",
                fontWeight: "bold",
                paddingTop: "100px",
              }}
              class="text-center mb-0"
            >
              OUR PORTFOLIO
            </h2>
            <hr
              style={{
                height: "10px",
                border: "none",
                backgroundColor: "#BAD26D",
                width: "220px",
              }}
            />
          </div>
          <SRLWrapper>
            <div class="row">
              {portfolioImages &&
                portfolioImages.map((val) => {
                  return (
                    <div class="col-lg-3 col-md-4">
                      <img class="img-fluid1" src={val.url} />
                    </div>
                  );
                })}
            </div>
          </SRLWrapper>
        </div>
      </div>
    );
  };

  // renderPortfolio = () => {
  //     return (
  //     <div class="photo-gallery" style={{paddingBottom:'80px'}}>
  //     <div class="container">
  //         <div class="intro">
  //             <h2 style={{ fontSize: '30px', fontWeight: 'bold', paddingTop:'100px'}} class="text-center mb-0">OUR PORTFOLIO</h2>
  //             <hr style={{ height: "10px", border: 'none', backgroundColor: '#BAD26D', width: '220px' }} />
  //         </div>
  //         <SRLWrapper>
  //         <div class="row">
  //             {this.renderPortfolioItem(Images.saloon_template3_portfolio1,Images.saloon_template3_portfolio1)}
  //             {this.renderPortfolioItem(Images.saloon_template3_portfolio2,Images.saloon_template3_portfolio2)}
  //             {this.renderPortfolioItem(Images.saloon_template3_portfolio3,Images.saloon_template3_portfolio3)}
  //             {this.renderPortfolioItem(Images.saloon_template3_portfolio4,Images.saloon_template3_portfolio4)}
  //             {this.renderPortfolioItem(Images.saloon_template3_portfolio5,Images.saloon_template3_portfolio5)}
  //             {this.renderPortfolioItem(Images.saloon_template3_portfolio6,Images.saloon_template3_portfolio6)}
  //             {this.renderPortfolioItem(Images.saloon_template3_portfolio7,Images.saloon_template3_portfolio7)}
  //             {this.renderPortfolioItem(Images.saloon_template3_portfolio1,Images.saloon_template3_portfolio1)}
  //             {this.renderPortfolioItem(Images.saloon_template3_portfolio9,Images.saloon_template3_portfolio9)}
  //         </div>
  //         </SRLWrapper>

  //     </div>
  // </div>
  //     )
  // }

  // Example = () => {
  //     return (
  //       <>
  //         <button variant="primary" onClick={()=>this.handleOpen()}>
  //           Launch demo modal
  //         </button>

  //         <Modal show={this.state.show} onHide={()=> this.handleClose()}>
  //           <Modal.Header closeButton>
  //             <Modal.Title>Modal heading</Modal.Title>
  //           </Modal.Header>
  //           <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
  //           <Modal.Footer>
  //             <button variant="secondary" onClick={()=>this.handleClose()}>
  //               Close
  //             </button>
  //             <button variant="primary" onClick={()=>this.handleClose()}>
  //               Save Changes
  //             </button>
  //           </Modal.Footer>
  //         </Modal>
  //       </>
  //     );
  //   }

  renderPortfolioVideo = () => {
    const { portfolioVideos } = this.state;
    return (
      <div class="photo-gallery" style={{ paddingBottom: "80px" }}>
        <div class="container">
          <div class="intro">
            <h2
              style={{
                fontSize: "30px",
                fontWeight: "bold",
                paddingTop: "100px",
              }}
              class="text-center mb-0"
            >
              OUR VIDEOS
            </h2>
            <hr
              style={{
                height: "10px",
                border: "none",
                backgroundColor: "#BAD26D",
                width: "220px",
              }}
            />
          </div>
          <div class="row">
            {portfolioVideos &&
              portfolioVideos.map((val) =>
                this.renderPortfolioItemVideo(val.url)
              )}
          </div>
        </div>
      </div>
    );
  };

  renderFooter = () => {
    const { companyList } = this.state;
    console.log(companyList, "footer");

    return (
      <div
        ref={this.footer}
        class="footer-dark"
        style={{ backgroundColor: "#272e32" }}
      >
        <footer>
          <div class="container">
            <div class="">
              {companyList &&
                companyList.map((saloonsVal) => {
                  return (
                    <div class="row">
                      <div
                        class="col-lg-4 col-md-4 item"
                        style={{ marginTop: "30px", marginBottom: "20px" }}
                      >
                        <img
                          style={{ marginBottom: 15, height: 80 }}
                          src={saloonsVal?.saloons[0]?.companyLogo}
                        />
                        <p style={{ color: "white" }}>
                          {saloonsVal?.saloons[0]?.companyShortDescription}
                        </p>
                      </div>
                      <div class="col-lg-4 col-md-4 item">
                        <div style={{ padding: "0.5rem", marginTop: "30px" }}>
                          <h4
                            style={{
                              fontSize: "25px",
                              fontWeight: "bold",
                              color: "#BAD26D",
                            }}
                          >
                            ADDRESS
                          </h4>
                          <hr
                            style={{
                              height: "10px",
                              border: "none",
                              backgroundColor: "#FFFFFF",
                              width: "120px",
                              margin: 0,
                            }}
                          />
                        </div>
                        <div class="row" style={{ marginLeft: "4px" }}>
                          <img
                            style={{
                              width: "20px",
                              height: "20px",
                              marginTop: 11,
                            }}
                            src={Images.saloon_template_footer_location}
                          />
                          <p
                            style={{
                              color: "white",
                              fontWeight: "bold",
                              marginTop: 10,
                              marginLeft: 5,
                            }}
                          >
                            {saloonsVal?.saloons[0]?.address}
                          </p>
                        </div>
                        <div class="row" style={{ marginLeft: "4px" }}>
                          <img
                            style={{
                              width: "20px",
                              height: "20px",
                              marginTop: 11,
                            }}
                            src={Images.saloon_template_footer_phone}
                          />
                          <p
                            style={{
                              color: "white",
                              fontWeight: "bold",
                              marginTop: 10,
                              marginLeft: 5,
                            }}
                          >
                            {saloonsVal?.saloons[0]?.businessTelephone}
                          </p>
                        </div>
                        <div class="row" style={{ marginLeft: "4px" }}>
                          <img
                            style={{
                              width: "20px",
                              height: "20px",
                              marginTop: 11,
                            }}
                            src={Images.saloon_template_footer_email}
                          />
                          <p
                            style={{
                              color: "white",
                              fontWeight: "bold",
                              marginTop: 10,
                              marginLeft: 5,
                            }}
                          >
                            {saloonsVal?.saloons[0]?.businessEmail}
                          </p>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 item">
                        <div style={{ padding: "0.5rem", marginTop: "30px" }}>
                          <h4
                            style={{
                              fontSize: "25px",
                              fontWeight: "bold",
                              color: "#BAD26D",
                            }}
                          >
                            INSTAGRAM FEED
                          </h4>
                          <hr
                            style={{
                              height: "10px",
                              border: "none",
                              backgroundColor: "#FFFFFF",
                              width: "220px",
                              margin: 0,
                            }}
                          />
                          {saloonsVal?.links[0]?.socialURL?.map((val) => {
                            return (
                              <div style={{ color: "#fff", marginTop: 10 }}>
                                {val.url}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </footer>
      </div>
    );
  };

  render() {
    const { isEditTemplate, aboutImgFile, isloading, companyList } = this.state;
    console.log(companyList, "RECEIVED_STATES");
    return (
      <div>
        {isEditTemplate && this.renderEditTemplateHeader()}
        {isloading && this.renderLoader()}
        {this.renderLargeNavbar()}
        {this.renderSmallNavbar()}
        {!isloading && this.renderJumbotron()}
        {!isloading && this.renderAboutUs()}
        {!isloading && this.renderServices()}
        {!isloading && this.renderPrices()}
        {!isloading && this.renderAppointment()}
        {!isloading && this.renderEmployees()}
        {/* {this.renderBrands()} */}
        <SimpleReactLightbox>
          {!isloading && this.renderPortfolio()}
        </SimpleReactLightbox>
        {!isloading && this.renderPortfolioVideo()}
        {!isloading && this.renderFooter()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  companyData: state.templateNavigation.data,
});

const action = {};

export default connect(null, action)(Template1);
