import { connect } from "react-redux";
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// import CryptoJS from "crypto-js";

import Profile from "../containers/Customer/Profile";
import Chat from "../components/Chat/chat-box.component";
import { set } from "../redux/actions/User";
import NewProfile from "../components/NewProfile/Profile.component";
import AppTemplate from "../components/AppTemplate/components/AppTemplate";
// import { secret_key } from "../config/SecretKeys";

const isUser = { isAuthenticated: false };

// const decrypt = (value) => {
//     let bytes = CryptoJS.AES.decrypt(value, secret_key);
//     let decryptedUserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
//     return decryptedUserData;
// };

const isUserAuthenticate = () => {
  const userData = JSON.parse(sessionStorage.getItem("user"));
  if (userData && userData.access_token && userData.role == 5) {
    isUser.isAuthenticated = true;
  } else {
    isUser.isAuthenticated = false;
  }
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  let user = rest.props;
  let isAuthenticated = user && user.isAdmin;
  isUserAuthenticate();
  return (
    <Route
      exact
      {...rest}
      render={(props) =>
        isAuthenticated || (isUser && isUser.isAuthenticated) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

class CustomerRoutes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.userLogin) {
      if (
        !nextProps.userLogin.failure &&
        !nextProps.userLogin.isFetching &&
        !nextProps.userLogin.errorMessage &&
        nextProps.userLogin.data &&
        nextProps.userLogin.data.data &&
        nextProps.userLogin.data.data.access_token &&
        nextProps.userLogin.data.data.role == 5
      ) {
        this.setState({ user: nextProps.userLogin.data.data });
      }
    }
    if (nextProps.impersonateUser) {
      if (
        !nextProps.impersonateUser.failure &&
        !nextProps.impersonateUser.isFetching &&
        !nextProps.impersonateUser.errorMessage &&
        nextProps.impersonateUser.data &&
        nextProps.impersonateUser.data.data &&
        nextProps.impersonateUser.data.data.access_token &&
        this.props.impersonateUser.isFetching
      ) {
        this.props.set({
          user: JSON.stringify(nextProps.impersonateUser.data.data),
        });
        this.props.history.push("/user-profile");
        this.setState({ user: nextProps.impersonateUser.data.data });
      }
    }
  }

  render() {
    const { user } = this.state;
    return (
      <Switch>
        <PrivateRoute
          exact
          path="/user-profile"
          component={Profile}
          props={user}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path="/new-user-profile"
          component={NewProfile}
          props={user}
        ></PrivateRoute>
      </Switch>
    );
  }
}

const mapStateToProps = (state) => ({
  userLogin: state.login,
  impersonateUser: state.impersonateUser,
  user: state.user.data.user,
});

const action = { set };

export default connect(mapStateToProps, action)(CustomerRoutes);
