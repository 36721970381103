import { connect } from "react-redux";
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// import CryptoJS from "crypto-js";

import Dashboard from "../containers/Saloon/Dashboard";
import EditProfile from "../containers/Saloon/EditProfile";
import AddEmployee from "../containers/Saloon/AddEmployee";
import CompanyEmployee from "../containers/Saloon/CompanyEmployee";
import ChatBox from "../containers/Saloon/ChatBox";
import AllServices from "../containers/Saloon/AllServices";
import AddService from "../containers/Saloon/AddService";
import AllProducts from "../containers/Saloon/Products/AllProducts";
import AddProduct from "../containers/Saloon/Products/AddProduct";
import ImportedCustomer from "../containers/Saloon/ImportCustomer";

import WorkingHours from "../containers/Saloon/WorkingHours";
import AddWorkingHours from "../containers/Saloon/AddWorkingHours";
import UploadMedia from "../containers/Saloon/UploadMedia";
import LinkSocialMedia from "../containers/Saloon/LinkSocialMedia";
import BlackList from "../containers/Saloon/BlackList";
import SaloonBooking from "../containers/Saloon/Booking";
import SaloonRating from "../containers/Saloon/Rating";
import BookingReport from "../containers/Saloon/BookingReport";
import TaxReport from "../containers/Saloon/TaxReport";
import SalesReport from "../containers/Saloon/SalesReport";
import EmployeeReport from "../containers/Saloon/EmployeeReport";
import MyAppointment from "../containers/Saloon/MyAppointments";
import Wallet from "../containers/Saloon/Wallet";
import LiveDisplaySettings from "../containers/Saloon/LiveDisplaySettings";
import Access from "../containers/Saloon/Security/Access";

import AllCustomers from "../containers/Saloon/AllCustomers";
import AddCustomer from "../containers/Saloon/AddCustomer";
import AllTaxes from "../containers/Saloon/AllTaxes";
import AddTax from "../containers/Saloon/AddTax";

import Template1 from "../containers/Templates/Template1";
import Template2 from "../containers/Templates/Template2";
import Template3 from "../containers/Templates/Template3";
import Template4 from "../containers/Templates/Template4";
import Template5 from "../containers/Templates/Template5";

// import { secret_key } from "../config/SecretKeys";
import ChooseTemplate from "./../containers/Saloon/ChooseTemplate/index";
import SiteSetUp from "./../containers/Saloon/SiteSetUp/index";
import MySite from "./../containers/Saloon/MySite/index";
import PricingPlan from "./../containers/Saloon/Pricing&Plan/index";
import WaitingList from "../containers/Saloon/WaitingList";
import ManageUnavailibilites from "../containers/Saloon/ManageUnavailibilites";
import Chat from "../components/Chat/chat-box.component";
import AccountSettings from "../containers/Saloon/AccountSettings";
import BillingHistory from "../containers/Saloon/BillingHistory";
import DevicesAndEquipment from "../containers/Saloon/DevicesAndEquipment";
import RequestFunds from "../containers/Saloon/RequestFunds";
import Orders from "../containers/Saloon/Orders";
import PayrollReport from "../containers/Saloon/PayrollReport/PayrollReport.container";
import RefundPolicy from "../containers/Saloon/RefundPolicy";
import Vendor from "../containers/Saloon/Vendor/Vendor.container";
import Expense from "../containers/Saloon/Expense/Expense.container";
import ExpenseReport from "../containers/Saloon/ExpenseReport/ExpenseReport.container";

// Components
import OnlinePayments from "../containers/Saloon/OnlinePayments";
import Packages from "../containers/Saloon/Packages/Packages.container";
import Annoucements from "../components/Announcements";
import Form from "../containers/Saloon/Form/Form.container";
import FormBuilder from "../containers/Saloon/FormBuilder/FormBuilder.container";
import CommunicationSettings from "../containers/Saloon/CommunicationSettings/CommunicationSettings.container";
import Roles from "../containers/Saloon/Roles/Roles.container";
import PrivateRoute from "./components/PrivateRoute.component";
import ProductReport from "../containers/Saloon/ProductReport/ProductReport.container";
import _Class from "../containers/Saloon/Class/Class";
import Program from "../containers/Saloon/Program/Program";
import Student from "../containers/Saloon/Student/Student";
import StudentGroup from "../containers/Saloon/StudentGroup/StudentGroup";
import CoursePlanner from "../containers/Saloon/CoursePlanner/CoursePlanner";
import Semester from "../containers/Saloon/Semester/Semester";
import NoShowReport from "../containers/Saloon/NoShowReport/NoShowReport";

class SaloonRoutes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
    };
  }

  componentDidMount() {
    const saloonData = JSON.parse(sessionStorage.getItem("saloon"));
    if (saloonData && saloonData.access_token) {
      this.setState({ user: saloonData });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.saloonLogin) {
      if (
        !nextProps.saloonLogin.failure &&
        !nextProps.saloonLogin.isFetching &&
        !nextProps.saloonLogin.errorMessage &&
        nextProps.saloonLogin.data &&
        nextProps.saloonLogin.data.data &&
        nextProps.saloonLogin.data.data.access_token &&
        nextProps.saloonLogin.data.data.role == 3
      ) {
        this.setState({ user: nextProps.saloonLogin.data.data });
      }
    }
    if (nextProps.impersonateSalon) {
      if (
        !nextProps.impersonateSalon.failure &&
        !nextProps.impersonateSalon.isFetching &&
        !nextProps.impersonateSalon.errorMessage &&
        nextProps.impersonateSalon.data &&
        nextProps.impersonateSalon.data.data &&
        nextProps.impersonateSalon.data.data.access_token &&
        this.props.impersonateSalon.isFetching
      ) {
        let data = nextProps.impersonateSalon.data.data;
        const { isCompanyProfile } = data;
        if (data.role && (data.role.includes("3") || data.role.includes("4"))) {
          if (isCompanyProfile) {
            !data.company.isFreeTrial
              ? this.handleNavigation("/saloon-pricing-plains")
              : this.handleNavigation("/saloon-pricing-plains");
          }
        }

        this.setState({ user: nextProps.impersonateSalon.data.data });
      }
    }
  }

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  renderNonGymRoutes = () => {
    return (
      <div>
        {this.PrivateRoute({
          path: "/saloon-rating",
          component: SaloonRating,
          resource: "Rating",
        })}
        {this.PrivateRoute({
          path: "/saloon-employee-report",
          component: EmployeeReport,
          resource: "EmployeeReport",
        })}
        {this.PrivateRoute({
          path: "/saloon-wallet",
          component: Wallet,
          resource: "Wallet",
        })}
        {this.PrivateRoute({
          path: "/saloon-live-display-settings",
          component: LiveDisplaySettings,
          resource: "LiveDisplaySettings",
        })}
        {this.PrivateRoute({
          path: "/saloon-waiting-list",
          component: WaitingList,
          resource: "WaitingList",
        })}
        {this.PrivateRoute({
          path: "/company-manage-unavailabilities",
          component: ManageUnavailibilites,
          resource: "WorkingHours",
        })}
        {this.PrivateRoute({
          path: "/saloon-online-payments",
          component: OnlinePayments,
          resource: "OnlinePayments",
        })}
        {this.PrivateRoute({
          path: "/saloon-black-listing-settings",
          component: BlackList,
          resource: "BlackListSettings",
        })}
        {this.PrivateRoute({
          path: "/saloon-packages",
          component: Packages,
          resource: "Packages",
        })}
      </div>
    );
  };

  PrivateRoute = ({ component: Component, path, resource }) => {
    return (
      <PrivateRoute
        {...this.props}
        path={path}
        Component={Component}
        type="saloon"
        resource={resource}
      />
    );
  };

  render() {
    const isGym = sessionStorage.getItem("company")
      ? JSON.parse(sessionStorage.getItem("company")).function == "gym"
      : false;
    return (
      <Switch>
        {this.PrivateRoute({
          path: "/saloon-dashboard",
          component: Dashboard,
          resource: "Dashboard",
        })}
        {this.PrivateRoute({
          path: "/saloon-edit-profile",
          component: EditProfile,
          resource: "Profile",
        })}
        {this.PrivateRoute({
          path: "/saloon-add-employee",
          component: AddEmployee,
          resource: "AddEmployee",
        })}
        {this.PrivateRoute({
          path: "/saloon-company-employee",
          component: CompanyEmployee,
          resource: "EmployeeList",
        })}
        {this.PrivateRoute({
          path: "/saloon-my-appointment",
          component: MyAppointment,
          resource: "Booking",
        })}
        {this.PrivateRoute({
          path: "/saloon-online-support",
          component: ChatBox,
          resource: "Chat",
        })}
        {this.PrivateRoute({
          path: "/saloon-add-service",
          component: AddService,
          resource: "AddService",
        })}
        {this.PrivateRoute({
          path: "/saloon-all-services",
          component: AllServices,
          resource: "AllServices",
        })}
        {this.PrivateRoute({
          path: "/saloon-add-inventory",
          component: AddProduct,
          resource: "AddInvetory",
        })}
        {this.PrivateRoute({
          path: "/saloon-my-inventory",
          component: AllProducts,
          resource: "Inventory",
        })}
        {this.PrivateRoute({
          path: "/saloon-working-hours",
          component: AddWorkingHours,
          resource: "WorkingHours",
        })}
        {this.PrivateRoute({
          path: "/saloon-upload-media",
          component: UploadMedia,
          resource: "UploadMedia",
        })}
        {this.PrivateRoute({
          path: "/saloon-link-social-accounts",
          component: LinkSocialMedia,
          resource: "SocialLinks",
        })}
        {this.PrivateRoute({
          path: "/saloon-security",
          component: Access,
          resource: "Security",
        })}
        {this.PrivateRoute({
          path: "/saloon-site-set-up",
          component: SiteSetUp,
          resource: "SiteSetUp",
        })}
        {this.PrivateRoute({
          path: "/saloon-my-site",
          component: MySite,
          resource: "SiteSetUp",
        })}
        {this.PrivateRoute({
          path: "/saloon-choose-a-template",
          component: ChooseTemplate,
          resource: "Templates",
        })}
        {this.PrivateRoute({
          path: "/saloon-booking",
          component: SaloonBooking,
          resource: "Booking",
        })}
        {this.PrivateRoute({
          path: "/saloon-booking-report",
          component: BookingReport,
          resource: "BookingReport",
        })}
        {this.PrivateRoute({
          path: "/saloon-tax-report",
          component: TaxReport,
          resource: "TaxReport",
        })}
        {this.PrivateRoute({
          path: "/saloon-sales-report",
          component: SalesReport,
          resource: "SalesReport",
        })}
        {this.PrivateRoute({
          path: "/saloon-pricing-plains",
          component: PricingPlan,
          resource: "PricingPlans",
        })}
        {this.PrivateRoute({
          path: "/saloon-customers",
          component: AllCustomers,
          resource: "Customers",
        })}
        {this.PrivateRoute({
          path: "/saloon-add-customer",
          component: AddCustomer,
          resource: "AddCustomer",
        })}
        {this.PrivateRoute({
          path: "/saloon-taxes",
          component: AllTaxes,
          resource: "TaxList",
        })}
        {this.PrivateRoute({
          path: "/saloon-add-tax",
          component: AddTax,
          resource: "TaxList",
        })}
        {this.PrivateRoute({
          path: "/saloon-import-customers",
          component: ImportedCustomer,
          resource: "ImportCustomer",
        })}
        {this.PrivateRoute({
          path: "/saloon-edit-template-1",
          component: Template1,
          resource: "Templates",
        })}
        {this.PrivateRoute({
          path: "/saloon-edit-template-2",
          component: Template2,
          resource: "Templates",
        })}
        {this.PrivateRoute({
          path: "/saloon-edit-template-3",
          component: Template3,
          resource: "Templates",
        })}
        {this.PrivateRoute({
          path: "/saloon-edit-template-4",
          component: Template4,
          resource: "Templates",
        })}
        {this.PrivateRoute({
          path: "/saloon-edit-template-5",
          component: Template5,
          resource: "Templates",
        })}
        {this.PrivateRoute({
          path: "/saloon-chat",
          component: ChatBox,
          resource: "Chat",
        })}
        {this.PrivateRoute({
          path: "/saloon-account-settings",
          component: AccountSettings,
          resource: "AccountSettings",
        })}
        {this.PrivateRoute({
          path: "/saloon-billing-history",
          component: BillingHistory,
          resource: "BillingHistory",
        })}
        {this.PrivateRoute({
          path: "/saloon-devices-and-equipment",
          component: DevicesAndEquipment,
          resource: "DevicesAndEquipment",
        })}
        {this.PrivateRoute({
          path: "/saloon-form-builder/:formId",
          component: FormBuilder,
          resource: "Form",
        })}
        {this.PrivateRoute({
          path: "/saloon-forms",
          component: Form,
          resource: "Form",
        })}

        {this.PrivateRoute({
          path: "/saloon-all-funds-requests",
          component: RequestFunds,
          resource: "FundRequests",
        })}
        {this.PrivateRoute({
          path: "/saloon-orders",
          component: Orders,
          resource: "Orders",
        })}
        {this.PrivateRoute({
          path: "/saloon-refund-policy",
          component: RefundPolicy,
          resource: "RefundPolicy",
        })}
        {this.PrivateRoute({
          path: "/saloon-payroll-report",
          component: PayrollReport,
          resource: "PayrollReport",
        })}
        {this.PrivateRoute({
          path: "/saloon-vendor",
          component: Vendor,
          resource: "Vendor",
        })}
        {this.PrivateRoute({
          path: "/saloon-expense",
          component: Expense,
          resource: "Expense",
        })}
        {this.PrivateRoute({
          path: "/saloon-expense-report",
          component: ExpenseReport,
          resource: "ExpenseReport",
        })}
        {this.PrivateRoute({
          path: "/saloon-communication-settings",
          component: CommunicationSettings,
          resource: "CommunicationSettings",
        })}
        {this.PrivateRoute({
          path: "/saloon-roles",
          component: Roles,
          resource: "Roles",
        })}
        {this.PrivateRoute({
          path: "/saloon-product-report",
          component: ProductReport,
          resource: "ProductReport",
        })}
        {this.PrivateRoute({
          path: "/saloon-no-show-report",
          component: NoShowReport,
          resource: "NoShowReport",
        })}
        {this.PrivateRoute({
          path: "/saloon-classes",
          component: _Class,
          resource: "Program",
        })}
        {this.PrivateRoute({
          path: "/saloon-programs",
          component: Program,
          resource: "Program",
        })}
        {this.PrivateRoute({
          path: "/saloon-students",
          component: Student,
          resource: "Program",
        })}
        {this.PrivateRoute({
          path: "/saloon-student-groups",
          component: StudentGroup,
          resource: "Program",
        })}
        {this.PrivateRoute({
          path: "/saloon-program-planner",
          component: CoursePlanner,
          resource: "Program",
        })}
        {this.PrivateRoute({
          path: "/saloon-semester",
          component: Semester,
          resource: "Program",
        })}

        {!isGym && this.renderNonGymRoutes()}
      </Switch>
    );
  }
}

const mapStateToProps = (state) => ({
  saloonLogin: state.saloonLogin,
  impersonateSalon: state.impersonateSalon,
  user: state.user.data.saloon
    ? JSON.parse(state.user.data.saloon)
    : state.user.data.saloon,
  docked: state.sidebar.isDocked,
  sidebarOpen: state.sidebar.isOpen,
});

const action = {};

export default connect(mapStateToProps, action)(SaloonRoutes);
