import { connect } from "react-redux";
import React, { Component } from "react";
import { Translation } from "react-i18next";
import i18n from "../../../i18n";
import { Images } from "../../../theme";
import { DotsLoader } from "../../../components";
import {
  createSaloonSchedule,
  editSaloonSchedule,
  getSaloonSchedule,
} from "../../../config/simpleApiCalls";
import { ErrorHelper, SuccessHelper } from "../../../helpers";

import "./styles.css";
import Sytles from "./sytles";

const weekNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednessday",
  "Thursday",
  "Friday",
  "Saturday",
];
class AddWorkingHours extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employeesWorkingHoursData: [
        { dayName: "Sunday", dayOfWeek: 1, availableStatus: 0 },
        { dayName: "Monday", dayOfWeek: 2, availableStatus: 0 },
        { dayName: "Tuesday", dayOfWeek: 3, availableStatus: 0 },
        { dayName: "Wednesday", dayOfWeek: 4, availableStatus: 0 },
        { dayName: "Thursday", dayOfWeek: 5, availableStatus: 0 },
        { dayName: "Friday", dayOfWeek: 6, availableStatus: 0 },
        { dayName: "Saturday", dayOfWeek: 7, availableStatus: 0 },
      ],
      // workingDays: [
      //     { day: 'Mon', isSelected: true },
      //     { day: 'Tue', isSelected: true },
      //     { day: 'Wed', isSelected: true },
      //     { day: 'Thu', isSelected: true },
      //     { day: 'Fri', isSelected: true },
      //     { day: 'Sat', isSelected: false },
      //     { day: 'Sun', isSelected: false },
      // ],
      // serviceList: [
      //     { name: 'Hair Cutting', isSelected: false },
      //     { name: 'Hair Cutting', isSelected: true },
      //     { name: 'Hair Cutting', isSelected: false },
      //     { name: 'Hair Cutting', isSelected: false },
      // ],
      isVerified: false,
      employeesWorkingHoursError: false,
      hasSchedule: false,
    };
  }

  async componentDidMount() {
    const saloonData = await JSON.parse(sessionStorage.getItem("saloon"));
    if (saloonData) {
      this.setState({ access_token: saloonData.access_token }, () => {
        this.handleGetSaloonSchedule();
      });
    }
  }

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  onChangeFacebook = (text) =>
    this.setState({ facebookLink: text.target.value });

  // handleWorkingDaysTag = (ind) => {
  //     const { workingDays } = this.state;
  //     workingDays[ind].isSelected = workingDays[ind].isSelected ? false : true;
  //     this.setState({ workingDays })
  // }

  // handleServicesTag = (ind) => {
  //     const { serviceList } = this.state;
  //     serviceList[ind].isSelected = serviceList[ind].isSelected ? false : true;
  //     this.setState({ serviceList })
  // }

  onChangeCheckbox = (e, ind) => {
    const { employeesWorkingHoursData } = this.state;
    if (e.target.checked) {
      employeesWorkingHoursData[ind].availableStatus = 1;
      this.setState({ employeesWorkingHoursData });
    } else {
      employeesWorkingHoursData[ind].availableStatus = 0;
      this.setState({ employeesWorkingHoursData });
    }
  };

  onChangeCheckIn = (text, ind) => {
    const { employeesWorkingHoursData } = this.state;
    if (text.target.value) {
      employeesWorkingHoursData[ind].checkIn = text.target.value;
    }
    this.setState({ employeesWorkingHoursData });
  };

  onChangeCheckOut = (text, ind) => {
    const { employeesWorkingHoursData } = this.state;
    if (text.target.value) {
      employeesWorkingHoursData[ind].checkOut = text.target.value;
    }
    this.setState({ employeesWorkingHoursData });
  };

  checkValidation = () => {
    let { employeesWorkingHoursData, isVerified, hasSchedule } = this.state;
    for (let i = 0; i < employeesWorkingHoursData.length; i++) {
      if (employeesWorkingHoursData[i].availableStatus === 1) {
        if (!employeesWorkingHoursData[i].checkIn) {
          isVerified = false;
          this.setState({
            isVerified,
            employeesWorkingHoursError: true,
          });
        } else if (!employeesWorkingHoursData[i].checkOut) {
          isVerified = false;
          this.setState({
            isVerified: false,
            employeesWorkingHoursError: true,
          });
        } else {
          isVerified = true;
          this.setState({
            isVerified,
            employeesWorkingHoursError: false,
          });
        }
      }
    }
    if (isVerified && !hasSchedule) {
      this.handleCreateSaloonSchedule();
    } else if (isVerified && hasSchedule) {
      this.handleEditSaloonSchedule();
    }
  };

  handleGetSaloonSchedule = () => {
    this.setState({ isloading: true });
    const { access_token } = this.state;
    getSaloonSchedule(access_token)
      .then((res) => {
        if (res.data.success && res.data.data) {
          for (let i = 0; i < res.data.data.weekPlans.length; i++) {
            res.data.data.weekPlans[i].dayName = weekNames[i];
          }
          this.setState({
            isloading: false,
            hasSchedule: true,
            employeesWorkingHoursData: res.data.data.weekPlans,
          });
        } else {
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  handleCreateSaloonSchedule = () => {
    this.setState({ isloading: true });
    const { employeesWorkingHoursData, access_token } = this.state;
    const employeesWorkingHoursSorted = employeesWorkingHoursData.map(function (
      obj
    ) {
      delete obj["dayName"]; // Delete old key
      delete obj["_id"]; // Delete old key
      return obj;
    });
    const payload = { weekPlans: employeesWorkingHoursSorted };
    createSaloonSchedule(payload, access_token)
      .then((res) => {
        if (res.data.success) {
          this.handleGetSaloonSchedule();
        } else {
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  handleEditSaloonSchedule = () => {
    this.setState({ isloading: true });
    const { employeesWorkingHoursData, access_token } = this.state;
    const employeesWorkingHoursSorted = employeesWorkingHoursData.map(function (
      obj
    ) {
      if (obj.availableStatus === 0) {
        delete obj["checkIn"]; // Delete old key
        delete obj["checkOut"]; // Delete old key
      }
      delete obj["dayName"]; // Delete old key
      delete obj["_id"]; // Delete old key
      return obj;
    });
    const payload = { weekPlans: employeesWorkingHoursSorted };

    editSaloonSchedule(payload, access_token)
      .then((res) => {
        if (res.data.success) {
          SuccessHelper.handleSuccess(i18n.t("successfully_updated"), true);
          this.handleGetSaloonSchedule();
        } else {
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  renderSaloonDashboardHeading = () => {
    return (
      <div className="row mx-0 justify-content-between">
        <h1 className="saloon-dashboard-heading align-self-center">
          <Translation>{(t) => t("container.add_working_hours")}</Translation>{" "}
          &amp; {i18n.t("container.hours")}
        </h1>
        <div className="mt-2 d-flex" style={{ justifyContent: "flex-end" }}>
          <button
            className="btn btn-light"
            type="submit"
            onClick={() =>
              this.props.history.push("/company-manage-unavailabilities")
            }
          >
            {i18n.t("container.employe_dashboard_manage_unavailabilities")}
          </button>
        </div>
      </div>
    );
  };

  renderEditWorkingHours = (ind, data) => {
    return (
      <div className="form-row working-days-table">
        <div className=" px-0 pr-md-3 d-flex align-items-center">
          <input
            value={data.checkIn && data.checkIn}
            type="time"
            className="form-control edit-employee-input"
            onChange={(text) => this.onChangeCheckIn(text, ind)}
          />
        </div>
        <div className="px-3 px-md-0 d-flex align-items-center">
          <span>{i18n.t("container.add_employee_to")}</span>
        </div>
        <div
          className="  px-0 pl-md-3 d-flex align-items-center"
          style={{ marginBottom: "0px !important" }}
        >
          <input
            value={data.checkIn && data.checkOut}
            type="time"
            className="form-control edit-employee-input"
            onChange={(text) => this.onChangeCheckOut(text, ind)}
          />
        </div>
      </div>
    );
  };

  renderServicesPrice = () => {
    return (
      <div className="">
        <div className=" px-0 pr-md-3 d-flex align-items-center">
          <input
            type="number"
            min="1.0"
            step="0.5"
            max="250000"
            className="form-control edit-employee-input"
            onChange={(text) => {}}
          />
        </div>
      </div>
    );
  };

  renderTableHead = () => {
    return (
      <thead>
        <tr>
          <th scope="col">
            <span className="working-day-th-text">
              {i18n.t("container.add_employee_working_days")}
            </span>
          </th>
          <th className="text-center" scope="col">
            <span className="working-day-th-text">
              {i18n.t("container.hours")}
            </span>
          </th>
          <th className="text-center" scope="col">
            <span className="working-day-th-text">
              <Translation>{(t) => t("container.availability")}</Translation>
            </span>
          </th>
          {/* <th scope="col"><span className="working-day-th-text "><Translation>{t=>t('container.services')}</Translation>/Speciality</span></th> */}
          {/* <th scope="col"><span className="working-day-th-text">Set Pricing</span></th> */}
        </tr>
      </thead>
    );
  };

  renderWorkingHourTableCard = () => {
    return (
      <div className="mx-0 py-3">
        <div className="table-responsive">
          <table class="table table-borderless saloon-working-hour-table">
            {this.renderTableHead()}
            {this.renderTableBody()}
          </table>
        </div>
      </div>
    );
  };

  renderTableBody = () => {
    const { employeesWorkingHoursData } = this.state;
    return (
      <tbody>
        {employeesWorkingHoursData.map((val, ind) => {
          return (
            <tr key={ind} style={{ borderTop: "3px solid #F7F7F7" }}>
              <td>
                <span className="working-days-name">{i18n.t(val.dayName)}</span>
              </td>
              <td style={{ display: "flex", justifyContent: "center" }}>
                {val.availableStatus === 1 &&
                  this.renderEditWorkingHours(ind, val)}
                {val.availableStatus === 0 && (
                  <button className="btn btn-light btn-sm" id="working-off-btn">
                    OFF
                  </button>
                )}
              </td>
              <td className="text-center">
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id={ind}
                    checked={val.availableStatus ? true : false}
                    onChange={(e) => this.onChangeCheckbox(e, ind)}
                  />
                  <label class="custom-control-label" for={ind}></label>
                </div>
              </td>
              {/* <td><span className="working-employee-timing">{val.ServicesAndSpeciality}</span></td> */}
              {/* <td style={{ display: 'flex', justifyContent: 'center' }}>
                            {val.Price &&
                                <span className="working-employee-timing">{this.renderServicesPrice()}</span>}
                            {!val.hours && <p></p>}
                        </td> */}
            </tr>
          );
        })}
      </tbody>
    );
  };

  ServicesSaveChanges = () => {
    const { employeesWorkingHoursError, hasSchedule } = this.state;
    return (
      <div
        className="col-12 px-0 px-md-3"
        style={{
          display: "flex",
          justifyContent: employeesWorkingHoursError
            ? "space-between"
            : "flex-end",
          alignItems: "center",
        }}
      >
        {employeesWorkingHoursError && (
          <span className="text-danger">Working hours are required</span>
        )}
        <button
          onClick={() => this.checkValidation()}
          type="button"
          className="btn btn-light btn-sm mb-2"
          id="saloon-save-social-link"
        >
          {hasSchedule
            ? i18n.t("container.update_schedule")
            : i18n.t("container.save_changes")}
        </button>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.renderLoader()}
        <div className="content-container">
          {this.renderSaloonDashboardHeading()}
          {this.renderWorkingHourTableCard()}
          {this.ServicesSaveChanges()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(AddWorkingHours);
