import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import FormGenerator from "./FormGenerator.component";
import {
  getByIdRequest,
  markAsViewedRequest,
  submitFilledFormRequest,
} from "../../../redux/actions/FormAnswer";
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import i18n from "../../../i18n";
import PrimaryButton from "../../Core/PrimaryButton";
import DotsLoader from "../../DotsLoader";
import styled from "styled-components";
import _ from "lodash";
const ButtonWrapper = styled.section`
  @media screen and (min-width: 768px) {
    width: 60% !important;
  }
  @media screen and (min-width: 0px) {
    width: 80%;
  }
  margin: 0 auto;
`;

function FillForm({
  match,
  getByIdRequest,
  formToFill,
  markAsViewedRequest,
  submitFilledFormRequest,
  loading,
  history,
}) {
  const {
    params: { id },
  } = match;
  useEffect(() => {
    getByIdRequest({ _id: id });
  }, []);
  const [answers, setAnswers] = useState({});
  const [errorMap, setErrorMap] = useState({});
  const [status, setStatus] = useState("");
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    if (formToFill?._id) {
      if (formToFill.status === "Submitted") {
        setStatus("submitted");
        setAnswers(formToFill.answerMap);
      } else {
        markAsViewedRequest({ _id: id });
      }
      setInitialized(true);
    }
  }, [formToFill]);
  const onSubmit = () => {
    const errorMap = {};
    formToFill.questions.map((question) => {
      if (question.required && !(question._id in answers)) {
        errorMap[question._id] = i18n.t("this_field_is_required");
      }
    });
    setErrorMap(errorMap);
    if (!Object.values(errorMap).length) {
      submitFilledFormRequest(
        { _id: id, answerMap: answers },
        {
          success: () => {
            history.replace("/");
            SuccessHelper.handleSuccess(i18n.t("form_submitted"), true);
          },
          failure: () => {
            ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
          },
        }
      );
    }
  };
  const onChange = (updatedQuestions) => {
    setAnswers(updatedQuestions);
  };
  const createAddress = (val) => {
    const items = [];
    if (val.address) {
      items.push(val.address);
    }
    if (val.city) {
      items.push(val.city);
    }
    if (val.province) {
      items.push(val.province);
    }
    return items.join(", ");
  };

  // console.log({ formToFill });

  const questions = formToFill.questions;
  const orderedQuestions = _.orderBy(questions, "displayOrder");
  const orderedForm = { ...formToFill, questions: orderedQuestions };

  return (
    <div>
      <DotsLoader isloading={loading} />
      {initialized ? (
        <div className="form_filler">
          <div className=" form-fill-top-bar">
            <div className="w-100 text-center">
              <h2 className="form-fill-header">
                {formToFill.formId.companyId.name}
              </h2>
              <h6>{createAddress(formToFill.formId.companyId)}</h6>
              <h6>{formToFill.formId.companyId.businessEmail}</h6>
              <h6>{formToFill.formId.companyId.businessTelephone}</h6>
            </div>
          </div>
          <FormGenerator
            formTitle={formToFill.formId.formTitle}
            fieldsToRender={orderedForm.questions.map((question) => ({
              ...question,
              fieldOptions: question.fieldOptions.join("-"),
            }))}
            errorMap={errorMap}
            onChange={onChange}
            readonly={status === "submitted"}
            answers={answers}
          />

          {status !== "submitted" && (
            <ButtonWrapper>
              <div className="submitButton">
                <PrimaryButton
                  className="formSubmitButton"
                  label={i18n.t("submit")}
                  onClick={onSubmit}
                />
              </div>
            </ButtonWrapper>
          )}
        </div>
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  formToFill: state.formAnswer.active,
  loading: state.formAnswer.isFetching,
});

const action = { getByIdRequest, markAsViewedRequest, submitFilledFormRequest };

export default connect(mapStateToProps, action)(FillForm);
