import React, { useEffect } from "react";
import { connect } from "react-redux";
import i18n from "../../../../i18n";
import moment from "moment";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import { IconButton } from "@material-ui/core";
import { updateRequest } from "../../../../redux/actions/Saloon/AllFundsRequest";
import Swal from "sweetalert2";
import { ErrorHelper, SuccessHelper } from "../../../../helpers";

function ListFundsRequests({
  requests,
  updateRequest,
  accessToken: access_token,
  accountDetails,
}) {
  const rejectRequest = (request) => {
    Swal.fire({
      title: i18n.t("confirm_reject"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ff3600",
      cancelButtonColor: "#354e68",
      confirmButtonText: i18n.t("sure"),
      cancelButtonText: i18n.t("no"),
    }).then((result) => {
      if (result.value) {
        updateRequest(
          { access_token, _id: request._id, status: "Rejected" },
          {
            success() {
              SuccessHelper.handleSuccess("", true);
            },
            failure() {
              ErrorHelper.handleErrors(i18n.t("someting_went_wrong"), true);
            },
          }
        );
      }
    });
  };
  const approveRequest = (request) => {
    const {
      totalAvailable = 0,
      combinedPending = 0,
      employeePending = 0,
    } = accountDetails;
    Swal.fire({
      title: i18n.t("confirm_approve"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ff3600",
      cancelButtonColor: "#354e68",
      confirmButtonText: i18n.t("yes"),
      cancelButtonText: i18n.t("no"),
    }).then((result) => {
      if (result.value) {
        updateRequest(
          { access_token, _id: request._id, status: "Approved" },
          {
            success() {
              SuccessHelper.handleSuccess("", true);
            },
            failure() {
              ErrorHelper.handleErrors(i18n.t("someting_went_wrong"), true);
            },
          }
        );
      }
    });
  };
  return (
    <table className="table table-borderless table-layout-fixed">
      <thead>
        <tr>
          <th>
            <span className="tab-heading">{i18n.t("amount")}</span>
          </th>
          <th>
            <span className="tab-heading">{i18n.t("approved_by")}</span>
          </th>
          <th>
            <span className="tab-heading">{i18n.t("created_on")}</span>
          </th>
          <th>
            <span className="tab-heading">{i18n.t("status")}</span>
          </th>
          <th style={{ width: 60 }}></th>
        </tr>
      </thead>
      <tbody className="bg-white">
        {requests.map((request) => {
          return (
            <tr key={request._id}>
              <td>
                <span className="tab-text">
                  $ {request?.amount?.toFixed(2)}
                </span>
              </td>
              <td>
                <span className="tab-text">{request.approver.firstName}</span>
              </td>
              <td>
                <span className="tab-text">
                  {moment(request.createdDate).format("MM-DD-YYYY hh:mm A")}
                </span>
              </td>
              <td>
                <span className="tab-text">{i18n.t(request.status)}</span>
              </td>
              <td style={{ width: 60 }}>
                {request.status == "Requested" ? (
                  <div className="row mx-0 flex-nowrap">
                    <div>
                      <IconButton
                        size="small"
                        onClick={() => approveRequest(request)}
                      >
                        <CheckCircleIcon className="check-btn" />
                      </IconButton>
                    </div>
                    <div>
                      <IconButton
                        size="small"
                        onClick={() => rejectRequest(request)}
                      >
                        <CancelIcon className="x-btn" />
                      </IconButton>
                    </div>
                  </div>
                ) : null}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

const mapStateToProps = (state) => ({});

const action = { updateRequest };

export default connect(mapStateToProps, action)(ListFundsRequests);
