import React from "react";
import { Route, Switch } from "react-router-dom";

import Login from "../containers/Login";
import Register from "../containers/Register";
import ForgotPassword from "../containers/ForgotPassword";
import ResetPassword from "../containers/ResetPassword";
import VerifyResetCode from "../containers/VerifyResetCode";
import VerifyCode from "../containers/Otp";
import UpdatePassword from "../containers/UpdatePassword";

import Confirm from "../containers/Confirm";
import Services from "../containers/Services";
import AvailableService from "./../containers/AvailableService/index";
import DemonstrationReservation from "./../containers/DemonstrationReservation";
import EmployeeProfile from "./../containers/EmployeeProfile";
// partner login/registration
import SalonRegister from "../containers/Saloon/Register";
import PartnerLogin from "../containers/PartnerLogin";
import SalonForgotPassword from "../containers/Saloon/ForgotPassword";
import SalonOtp from "../containers/Saloon/Otp";
import SalonUpdatePassword from "../containers/Saloon/UpdatePassword";
import EmployeeRegister from "../containers/Employee/Register";
import EmployeeForgotPassword from "./../containers/Employee/ForgotPassword";
import EmployeeVerifyOtp from "./../containers/Employee/Otp";
import EmployeeUpdatePassword from "./../containers/Employee/UpdatePassword/index";
import FillForm from "../components/FormBuilder/Components/FillForm";
import TransferDetails from "../containers/TransferDetails";
import BookingDetails from "../containers/BookingDetails/BookingDetails.container";
import InviteFromEmployee from "../containers/InviteFromEmployee/InviteFromEmployee.container";

import CustomMessage from "../containers/CustomMessage/CustomMessage.container";
import CancellationDetails from "../containers/CancellationDetails/CancellationDetails.container";
import InvoiceContainer from "../containers/Invoice/Invoice.container";
import FormsGroupContainer from "../containers/FormsGroup/FormsGroup.container";
import FlawlessAnnoucement from "../containers/FlawlessAnnoucement/FlawlessAnnoucement";
// User Routes For User Navigation
const UserRoutes = () => (
  <Switch>
    <Route exact path="/confirm" component={Confirm}></Route>
    <Route exact path="/confirm/:id" component={Confirm}></Route>
    <Route exact path="/details/:id" component={BookingDetails}></Route>
    <Route exact path="/our-services" component={Services}></Route>
    <Route exact path="/login" component={Login}></Route>
    <Route exact path="/register" component={Register}></Route>
    <Route exact path="/forgot-password" component={ForgotPassword}></Route>
    <Route exact path="/reset-password" component={ResetPassword}></Route>
    <Route exact path="/verify-reset-code" component={VerifyResetCode}></Route>
    <Route exact path="/available-service" component={AvailableService}></Route>
    <Route exact path="/verify-code" component={VerifyCode}></Route>
    <Route exact path="/update-password" component={UpdatePassword}></Route>
    <Route exact path="/update-password" component={UpdatePassword}></Route>
    <Route exact path="/careers.html" component={null}></Route>
    <Route
      exact
      path="/demonstration-reservation"
      component={DemonstrationReservation}
    ></Route>
    <Route
      exact
      path="/employee-profile/:employeeId"
      component={EmployeeProfile}
    ></Route>
    <Route exact path="/partner-login" component={PartnerLogin}></Route>
    <Route exact path="/" component={PartnerLogin}></Route>
    <Route exact path="/saloon-login" component={PartnerLogin}></Route>
    <Route exact path="/employee-login" component={PartnerLogin}></Route>
    <Route
      exact
      path="/saloon-forgot-password"
      component={SalonForgotPassword}
    ></Route>
    <Route exact path="/saloon-verify-otp" component={SalonOtp}></Route>
    <Route
      exact
      path="/saloon-update-password"
      component={SalonUpdatePassword}
    ></Route>
    <Route exact path="/saloon-register" component={SalonRegister}></Route>

    <Route exact path="/employee-register" component={EmployeeRegister}></Route>
    <Route
      exact
      path="/employee-forgot-password"
      component={EmployeeForgotPassword}
    ></Route>
    <Route
      exact
      path="/employe-verify-code"
      component={EmployeeVerifyOtp}
    ></Route>
    <Route
      exact
      path="/employe-update-password"
      component={EmployeeUpdatePassword}
    ></Route>
    <Route exact path="/form-fill/:id" component={FillForm}></Route>
    {/* <Route exact path="/transfer-details" component={TransferDetails}></Route> */}
    <Route exact path="/transfer-details/" component={TransferDetails}></Route>
    <Route
      exact
      path="/transfer-details/:id"
      component={TransferDetails}
    ></Route>
    <Route
      exact
      path="/invite/:companyEmployeeId"
      component={InviteFromEmployee}
    ></Route>
    <Route exact path="/bm/:id/:type" component={CustomMessage}></Route>
    <Route
      exact
      path="/can-details/:id"
      component={CancellationDetails}
    ></Route>
    <Route exact path="/invoice/:id" component={InvoiceContainer}></Route>
    <Route exact path="/f-group/:id" component={FormsGroupContainer}></Route>
    <Route exact path="/ann/fl" component={FlawlessAnnoucement}></Route>
  </Switch>
);

export default UserRoutes;
