import React from "react";
import { Translation } from "react-i18next";

const SidebarContent = ({
  employees,
  services,
  onChange = () => {},
  viewWorkingHours,
  viewUnavailabilities,
  setViewWorkingHoursUnavailabilities,
  setViewUnavailabilities,
}) => {
  const statuses = [
    { text: "Waiting", value: 1 },
    { text: "Now Serving", value: 2 },
    { text: "Done", value: 4 },
    { text: "Cancel", value: 3 },
  ];
  return (
    <div className="content">
      <div className="container">
        <div className="box-wrapper">
          <input
            type="checkbox"
            className="box"
            checked={viewWorkingHours}
            onChange={() =>
              setViewWorkingHoursUnavailabilities(!viewWorkingHours)
            }
          ></input>
          <label className="box-label mb-0">
            <Translation>{(t) => t("show_working_hours")}</Translation>
          </label>
        </div>
      </div>
      <div className="container">
        <div className="box-wrapper">
          <input
            type="checkbox"
            className="box"
            checked={viewUnavailabilities}
            onChange={() => setViewUnavailabilities(!viewUnavailabilities)}
          ></input>
          <label className="box-label mb-0">
            <Translation>{(t) => t("show_unavailiblities")}</Translation>
          </label>
        </div>
      </div>
      <div className="container">
        <label className="title">
          <Translation>
            {(t) => t("container.advancedSearchDialog_name")}
          </Translation>
        </label>

        <div className="box-wrapper">
          <input
            className="form-control mt-1 mr-3"
            type="text"
            onChange={(e) => onChange("name", e)}
          ></input>
          <i className="fas fa-search"></i>
        </div>
      </div>
      <div className="container">
        <label className="title">
          <Translation>
            {(t) => t("container.advancedSearchDialog_phone_number")}
          </Translation>
        </label>

        <div className="box-wrapper">
          <input
            className="form-control mt-1 mr-3"
            type="text"
            onChange={(e) => onChange("phoneNumber", e)}
          ></input>
          <i className="fas fa-search"></i>
        </div>
      </div>
      <div className="container">
        <label className="title">
          <Translation>{(t) => t("reference_code")}</Translation>
        </label>

        <div className="box-wrapper">
          <input
            className="form-control mt-1 mr-3"
            type="text"
            onChange={(e) => onChange("shortenedId", e)}
          ></input>
          <i className="fas fa-search"></i>
        </div>
      </div>
      <div className="container">
        {statuses && statuses.length ? (
          <>
            <label className="title">
              <Translation>{(t) => t("container.profile_status")}</Translation>
            </label>

            {statuses.map((status) => (
              <div className="box-wrapper">
                <input
                  type="checkbox"
                  className="box"
                  defaultChecked={true}
                  onChange={() => onChange("status", status.value)}
                ></input>
                <label className="box-label mb-0">{status.text}</label>
              </div>
            ))}
          </>
        ) : null}
      </div>
      <div className="container">
        {employees && employees.length ? (
          <>
            <label className="title">
              <Translation>
                {(t) => t("container.add_employe_employees")}
              </Translation>
            </label>

            {employees.map((employee) => (
              <div className="box-wrapper">
                <input
                  type="checkbox"
                  className="box"
                  defaultChecked={true}
                  onChange={() => onChange("employee", employee.value)}
                ></input>
                <label className="box-label mb-0">{employee.text}</label>
              </div>
            ))}
          </>
        ) : null}
      </div>
      <div className="container">
        {services && services.length ? (
          <>
            <label className="title">
              <Translation>{(t) => t("container.profile_service")}</Translation>
            </label>
            {services.map((service) => (
              <div className="box-wrapper">
                <input
                  type="checkbox"
                  className="box"
                  defaultChecked={true}
                  onChange={() => onChange("service", service)}
                ></input>
                <label className="box-label mb-0">{service}</label>
              </div>
            ))}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default SidebarContent;
