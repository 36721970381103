import { connect } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import i18n from "../../../i18n";
import BaseModal from "../../BaseModal";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import MenuItem from "@material-ui/core/MenuItem";
import PrimaryButton from "../../Core/PrimaryButton";
import DatePicker from "react-datepicker";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import { getNestedValue } from "../../../util/objectMethods";
import moment from "moment";
import InputAdornment from "@material-ui/core/InputAdornment";
import {
  getRequest as getExpensesRequest,
  insertRequest as insertExpenseRequest,
} from "../../../redux/actions/Saloon/expense";
import ExpenseTable from "../Expense/ExpenseTable.component";
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import DotsLoader from "../../DotsLoader";
import ExpenseUpsert from "../Expense/ExpenseUpsert.component";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      className="light-border"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={2}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function VendorUpsert({
  open,
  onClose,
  onSave,
  allServices = [],
  active = {},
  getExpensesRequest,
  accessToken,
  insertExpenseRequest,
  companyId,
  taxes,
  expenses,
}) {
  const form = useRef();
  const [data, setData] = useState({});
  const [addExpenseOpen, setAddExpenseOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [expenseReadOnly, setExpenseReadOnly] = useState(false);
  const [activeExpense, setActiveExpense] = useState({});

  const init = (data) => {
    setData({ ...data });
    setTab(0);
    getExpensesRequest({ vendorId: data._id, access_token: accessToken });
  };
  const onSaveExpense = (params) => {
    setLoading(true);
    insertExpenseRequest(
      { access_token: accessToken, companyId, vendorId: data._id, ...params },
      {
        success: (data) => {
          SuccessHelper.handleSuccess(
            i18n.t(data.id ? "successfully_updated" : "successfully_added"),
            true
          );
          setAddExpenseOpen(false);
          setLoading(false);
        },
        failure: () => {
          ErrorHelper.handleErrors(
            i18n.t(data.id ? "failed_to_update" : "failed_to_add"),
            true
          );
          setLoading(false);
        },
      }
    );
  };
  useEffect(() => {
    init(active);
  }, []);
  useEffect(() => {
    if (active._id != data._id) {
      init(active);
    }
  }, [active]);
  const handleChange = (key, value) => {
    data[key] = value;
    setData({ ...data });
  };
  const handleSubmit = () => {
    onSave(data);
  };
  const [tab, setTab] = React.useState(-1);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };
  const onView = (data) => {
    data.taxes = data.taxes
      .map((setTax) => {
        return taxes.find((tax) => tax.name == setTax.type);
      })
      .filter((tax) => !!tax);
    setActiveExpense({ ...data });
    setExpenseReadOnly(true);
    setAddExpenseOpen(true);
  };

  return (
    <div>
      <DotsLoader isloading={loading} />
      {open ? (
        <BaseModal
          open={open}
          title={data._id ? i18n.t("edit_vendor") : i18n.t("add_vendor")}
          containerClassName="container"
          content={
            <>
              <AppBar position="static">
                <Tabs
                  value={tab}
                  onChange={handleTabChange}
                  aria-label="simple tabs example"
                >
                  <Tab label={i18n.t("details")} {...a11yProps(0)} />
                  <Tab
                    label={i18n.t("expenses")}
                    {...a11yProps(1)}
                    disabled={!data._id}
                  />
                </Tabs>
              </AppBar>
              <TabPanel value={tab} index={0}>
                <div className="d-flex">
                  <ValidatorForm
                    onSubmit={handleSubmit}
                    ref={form}
                    className="w-100 row mx-0"
                    onError={(error) => {
                      if (!error.length) {
                        onSave(data);
                      }
                    }}
                  >
                    <TextValidator
                      containerProps={{ className: "col-6" }}
                      className="mb-1"
                      label={i18n.t("name")}
                      onChange={(e) =>
                        handleChange("vendorName", e.target.value)
                      }
                      value={data.vendorName || ""}
                      validators={["required"]}
                      errorMessages={[i18n.t("name_is_required")]}
                    />
                    <TextValidator
                      containerProps={{ className: "col-6" }}
                      className="mb-1"
                      label={i18n.t("primary_contact")}
                      onChange={(e) =>
                        handleChange("primaryContact", e.target.value)
                      }
                      value={data.primaryContact || ""}
                      validators={["required"]}
                      errorMessages={[i18n.t("primary_contact_is_required")]}
                    />
                    <TextValidator
                      containerProps={{ className: "col-6" }}
                      className="mb-1"
                      label={i18n.t("container.profile_email")}
                      onChange={(e) => handleChange("email", e.target.value)}
                      value={data.email || ""}
                      validators={["isEmail"]}
                      errorMessages={[i18n.t("invalid_email")]}
                    />
                    <TextValidator
                      containerProps={{ className: "col-6" }}
                      className="mb-1"
                      label={i18n.t("phoneNo")}
                      onChange={(e) => handleChange("phoneNo", e.target.value)}
                      value={data.phoneNo || ""}
                    />
                    <TextValidator
                      containerProps={{ className: "col-6" }}
                      className="mb-1"
                      label={i18n.t("mobile_phone_no")}
                      onChange={(e) =>
                        handleChange("mobilePhoneNo", e.target.value)
                      }
                      value={data.mobilePhoneNo || ""}
                    />
                    <TextValidator
                      containerProps={{ className: "col-6" }}
                      className="mb-1"
                      label={i18n.t("attention")}
                      onChange={(e) =>
                        handleChange("attention", e.target.value)
                      }
                      value={data.attention}
                    />
                    <TextValidator
                      containerProps={{ className: "col-6" }}
                      className="mb-1"
                      label={i18n.t("address")}
                      onChange={(e) => handleChange("address", e.target.value)}
                      value={data.address || ""}
                    />
                    <TextValidator
                      containerProps={{ className: "col-6" }}
                      className="mb-1"
                      label={i18n.t("postal_code")}
                      onChange={(e) =>
                        handleChange("postalCode", e.target.value)
                      }
                      value={data.postalCode || ""}
                    />
                    <TextValidator
                      containerProps={{ className: "col-6" }}
                      className="mb-1"
                      label={i18n.t("city")}
                      onChange={(e) => handleChange("city", e.target.value)}
                      value={data.city || ""}
                    />
                    <TextValidator
                      containerProps={{ className: "col-6" }}
                      className="mb-1"
                      label={i18n.t("province")}
                      onChange={(e) => handleChange("province", e.target.value)}
                      value={data.province || ""}
                    />
                    <TextValidator
                      containerProps={{ className: "col-6" }}
                      className="mb-1"
                      label={i18n.t("country")}
                      onChange={(e) => handleChange("country", e.target.value)}
                      value={data.country || ""}
                    />
                    <div className="row mx-0 px-0 justify-content-end mt-2 col-12">
                      <PrimaryButton
                        props={{
                          type: "submit",
                        }}
                        label={data._id ? i18n.t("save") : i18n.t("create")}
                      />
                    </div>
                  </ValidatorForm>
                </div>
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <div className="row mb-2 mx-0 px-0 justify-content-end">
                  <PrimaryButton
                    label={i18n.t("add")}
                    onClick={() => {
                      setAddExpenseOpen(true);
                    }}
                  />
                </div>
                <div className="light-border-b">
                  <ExpenseTable
                    rows={expenses}
                    accessToken={accessToken}
                    onView={onView}
                  />
                </div>
              </TabPanel>
            </>
          }
          onClose={onClose}
        />
      ) : null}
      {addExpenseOpen && (
        <ExpenseUpsert
          open={addExpenseOpen}
          element={activeExpense}
          onClose={() => {
            setAddExpenseOpen(false);
            setActiveExpense({});
          }}
          onSave={onSaveExpense}
          accessToken={accessToken}
          taxes={taxes}
          presetVendor
          readOnly={expenseReadOnly}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  active: state.vendor.active,
  expenses: state.expense.data,
  loading: state.vendor.isFetching,
});

const action = { getExpensesRequest, insertExpenseRequest };

export default connect(mapStateToProps, action)(VendorUpsert);
