// @flow
import Immutable from "seamless-immutable";
import * as types from "../actions/ActionTypes";

const initialState = Immutable({
  failure: false,
  isFetching: false,
  errorMessage: "",
  data: [],
});

export default (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case types.GET_NOTIFICATIONS.REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case types.GET_NOTIFICATIONS.SUCCESS:
      return Immutable.merge(state, {
        failure: false,
        isFetching: false,
        errorMessage: "",
        data: action.data,
      });
    case types.GET_NOTIFICATIONS.UPDATE_BY_ID:
      const data = [...state.data]
      const index = data.findIndex(element => element._id == action.data._id)
      if(index !== -1) {
        data[index] = {...data[index], ...action.data}
      }
      return Immutable.merge(state, {
        failure: false,
        isFetching: false,
        errorMessage: "",
        data: [...data],
      });
    case types.GET_NOTIFICATIONS.FAILURE:
      return Immutable.merge(state, {
        failure: true,
        isFetching: false,
        errorMessage: action.errorMessage,
      });
    case types.LOGOUT:
      return initialState;
    case types.CLEAR_LOGOUT:
      return initialState;
    default:
      return state;
  }
};
