import { connect } from "react-redux";
import React, { Component } from "react";

import { DotsLoader, Core } from "../../../components";
import PercentInput from "../../../components/PercentInput";
import { SuccessHelper, ErrorHelper } from "../../../helpers";

import i18n from "../../../i18n";
import {
  updateRefundPolicy,
  getSaloonById,
} from "../../../config/simpleApiCalls";
import TextField from "@material-ui/core/TextField";
import styled from "styled-components";
const { Select, PrimaryButton } = Core;

const TextArea = styled(TextField)`
  .MuiInputBase-root {
    padding: 12px 14px;
  }
  .MuiInputBase-root legend {
    display: none;
  }
`;
class RefundPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      isLoading: false,
      initialized: false,
    };
  }

  async componentDidMount() {
    const saloonData = await JSON.parse(sessionStorage.getItem("saloon"));
    const token = saloonData.access_token;
    this.setState({ isLoading: true, token });
    Promise.all([
      getSaloonById(token)
        .then(({ data }) => {
          const { refundPolicy, name, noShowFeePercentage } = data.data;
          this.setState({
            noShowFeePercentage,
            refundPolicy,
            companyName: name,
          });
        })
        .catch(() => {
          this.setState({ isLoading: false });
        }),
    ]).then(() => {
      this.setState({ isLoading: false, initialized: true });
    });
  }

  renderLoader = () => {
    const { isLoading } = this.state;
    return <DotsLoader isloading={isLoading} />;
  };

  handleSettingsSave = async () => {
    const { refundPolicy, noShowFeePercentage, token } = this.state;
    this.setState({ isLoading: true });
    try {
      updateRefundPolicy(
        {
          refundPolicy,
          noShowFeePercentage,
        },
        token
      )
        .then(({ data }) => {
          if (data.success) {
            SuccessHelper.handleSuccess(i18n.t("successfully_updated"), true);
          }
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });
    } catch (e) {
      ErrorHelper.handleErrors(e, true);
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { refundPolicy, initialized, noShowFeePercentage } = this.state;

    return (
      <div className="refund-policy">
        {this.renderLoader()}
        <div className="p-3">
          <div>
            <h1 className="saloon-dashboard-heading pb-3">
              {i18n.t("refund_policy")}
            </h1>
          </div>

          {initialized && (
            <div>
              <div className="row">
                <div className="col-12 mb-2">
                  <TextArea
                    value={refundPolicy}
                    onChange={(e) => {
                      this.setState({ refundPolicy: e.target.value });
                    }}
                    multiline
                    rows={5}
                    variant="outlined"
                  />
                </div>
                <div className="col-12 mb-2">
                  <PercentInput
                    value={noShowFeePercentage}
                    onChange={(e) => {
                      this.setState({ noShowFeePercentage: e });
                    }}
                    label={i18n.t("no_show_percent_fee")}
                    variant="outlined"
                  />
                </div>
              </div>
              <div className="row mx-0 justify-content-end pt-2">
                <PrimaryButton
                  label={i18n.t("save")}
                  onClick={this.handleSettingsSave}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(RefundPolicy);
