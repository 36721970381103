import React, { useMemo, useRef } from "react";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimeline from "@fullcalendar/resource-timeline";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import resourceDayGridPlugin from "@fullcalendar/resource-daygrid";

import "./PlanView.scss";
import "./PlanCalendar.scss";
const PlanCalendar = ({
  planData,
  start,
  onDateClick,
  renderEventContent,
  selectEvent,
  calendarRef,
  employees,
}) => {
  let slotMinTime = `${start ? start : "00"}:00:00`;
  const resources = useMemo(() => {
    return employees.map((employee) => ({
      id: employee.value,
      title: employee.text,
    }));
  }, [employees]);
  return (
    <div className="plan-calendar">
      <FullCalendar
        locale="en"
        plugins={[
          dayGridPlugin,
          timeGridPlugin,
          resourceTimeGridPlugin,
          resourceTimeline,
          resourceDayGridPlugin,
          interactionPlugin,
        ]}
        initialView="resourceTimeGrid"
        datesAboveResources
        resources={resources}
        events={planData}
        headerToolbar={false}
        weekends={true}
        slotEventOverlap={false}
        eventClick={selectEvent}
        eventContent={renderEventContent}
        expandRows={true}
        contentHeight={2000}
        slotMinTime={slotMinTime}
        ref={calendarRef}
        allDaySlot={false}
        stickyHeaderDates={true}
        slotDuration={"00:15:00"}
        dateClick={(e) => {
          const {
            date,
            resource: { id },
          } = e;
          onDateClick(date, id);
        }}
      />
    </div>
  );
};

export default PlanCalendar;
