import React, { Component, Fragment } from "react";
import { Images } from "../../../theme";
import { RiEditFill } from "react-icons/ri";
import { DotsLoader } from "../../../components";
import {
  getSaloonTemplateData,
  getCompanyPorfolio,
  getEmployeeByServiceCompany,
  createBooking,
  createUpdateTemplate,
  getTemplateData,
} from "../../../config/simpleApiCalls";
import ReactStars from "react-rating-stars-component";

import SideNav from "../Template2/SideNav";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import Modal from "react-bootstrap/Modal";
import Lightbox from "react-lightbox-component";
import { SuccessHelper, ErrorHelper } from "../../../helpers";
import {
  baseUrl
} from "../../../config/WebServices";
import templateImages from "./img/index";
import "./css/template4.scoped.scss";
import "./css/bootstrap.scoped.scss";

class Template4 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      routeName: "",
      showSidebar: false,
      showDropdownMenu: false,
      companyId: "",
      access_token: "",
      portfolioImages: [],
      portfolioVideos: [],
      employeeTimings: [],
      selectedCategoryId: "",
      selecteedEmployeeId: "",
      selectedValue: "",
      selectedServiceId: "",
      selectedTime: "",
      selectedPrice: "",
      jumbotronImgFile: null,
      jumbotronImgUrl: null,
      aboutImgUrl: null,
      aboutImgFile: null,
      aboutFirstHeading: "GET YOUR STYLE",
      aboutSecondHeading: "YOU DESERVE",
      jumbotronFirstHeading: "GET YOUR STYLE",
      jumbotronSecHeading: "YOU DESERVE",
      isEditTemplate: false,
      serviceImgUrl: null,
      serviceImgFile: null,

      companyList: [],
      companyPortfolio: [],
      employeeData: [],
      checkInTime: "",
      isloading: false,
      employeData: [],
      showNav: false,
      width: 0,
      height: 0
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    this.services = React.createRef();
    this.aboutUs = React.createRef();
    this.home = React.createRef();
    this.appointment = React.createRef();
    this.contact = React.createRef();
    this.gallery = React.createRef();
    
  }

  componentDidMount = async () => {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    this.setState({ isLoading: true })
    const { location: { pathname } } = this.props;
    const pathPieces = pathname.split('/')
    let companyId = pathPieces[2];

    if (pathname === '/saloon-edit-template-4') {
        this.setState({ isEditTemplate: true });
        const { _id } = JSON.parse(sessionStorage.getItem("company"))
        companyId = _id
    }

    const user = await JSON.parse(sessionStorage.getItem("user"));
    this.setState({
      companyId,
      user: user,
    });
    if (companyId) {
      this.getAllData();
    } else {
      this.getAllTemplateData()
    }

    this.getPortfolioData();
  };
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  
  onChangeaboutFirstHeading = (text) =>
    this.setState({ aboutFirstHeading: text.target.value });
  onChangeaboutSecondHeading = (text) =>
    this.setState({ aboutSecondHeading: text.target.value });
  onChangeJumbotronFirstHeading = (text) =>
    this.setState({ headerFirstHeading: text.target.value });
  onChangeJumbotronSecHeading = (text) =>
    this.setState({ headerSecondHeading: text.target.value });

  getAllTemplateData = () => {
    let token = JSON.parse(sessionStorage.getItem('saloon'))
    const { state } = this.props.location

    getTemplateData(token.access_token, state).then((res) => {
      this.setState({ isloading: true })
      if (res.data.success) {
        if (res.data.data != null) {
          this.setState({
            headerFirstHeading: res.data.data.headerFirstHeading,
            headerSecondHeading: res.data.data.headerSecondHeading,
            jumbotronImgFile: res.data.data.headerImage,
            jumbotronImgUrl: res.data.data.headerImage,
            aboutFirstHeading: res.data.data.aboutFirstHeading,
            aboutSecHeading: res.data.data.aboutSecondHeading,
            aboutImgFile: res.data.data.aboutFirstImg,
            aboutImgUrl: res.data.data.aboutFirstImg,
            aboutSecBgImgUrl: res.data.data.aboutSecondImg,
            aboutSecBgImgFile: res.data.data.aboutSecondImg,
            priceBgImgUrl: res.data.data.footerImage,
            sinceText: res.data.data.sinceText,
            
            priceBgImgFile: res.data.data.footerImage,
            isloading: false

          })
        }else{
          this.setState({isloading: false})
        }
      }
    }).catch((err) => { this.setState({ isloading: false }) })
  }

  getAllData = () => {
    this.setState({
      isloading: true,
    });
    const { companyId } = this.state;
    const payload = { companyId };
    getSaloonTemplateData(payload)
      .then((res) => {

        console.log(res.data)
        if (res.data.success) {
          if (res.data.data[0] != null) {
            this.setState({companyList: res.data.data})

            this.setState({

              companyList: res.data.data,
              employeData: res.data.data[0],
              headerFirstHeading: res.data.data[0].customTempalte.headerFirstHeading,
              headerSecondHeading: res.data.data[0].customTempalte.headerSecondHeading,
              jumbotronImgFile: res.data.data[0].customTempalte.headerImage,
              jumbotronImgUrl: res.data.data[0].customTempalte.headerImage,
              aboutFirstHeading: res.data.data[0].customTempalte.aboutFirstHeading,
              aboutSecHeading: res.data.data[0].customTempalte.aboutSecondHeading,
              aboutImgFile: res.data.data[0].customTempalte.aboutFirstImg,
              aboutImgUrl: res.data.data[0].customTempalte.aboutFirstImg,
              aboutSecBgImgUrl: res.data.data[0].customTempalte.aboutSecondImg,
              sinceText:res.data.data[0].customTempalte.sinceText,
              aboutSecBgImgFile: res.data.data[0].customTempalte.aboutSecondImg,
              priceBgImgUrl: res.data.data[0].customTempalte.footerImage,
              priceBgImgFile: res.data.data[0].customTempalte.footerImage,
              isloading: false
            });
          }
        }
      })
      .catch((error) => {
        console.log(error, "errorrrrrrrrrrrrrrrrrrr");
      });
  };


  getPortfolioData = () => {
    this.setState({ isloading: true });
    const { companyId } = this.state;
    const payload = { companyId };
    getCompanyPorfolio(payload)
      .then((res) => {
        if (res.data.success) {
          // this.setState({ isloading: false, companyPortfolio: res.data.data });
          let portfolioImages = [];
          let portfolioVideos = [];
          console.log('res.data.data', res.data.data)
          res.data.data.map((val) => {
            if (val.post && val.post.length > 0) {
              val.post.map((val2, ind2) => {
                if (val2.images.length > 0) {
                  portfolioImages.push(val2.images[0]);
                } else if (val2.videos.length > 0) {
                  portfolioVideos.push(val2.videos[0]);
                }
              });
            } 
          });
          this.setState({ isloading: false, portfolioImages, portfolioVideos });
        }
      })
      .catch((error) => {
        console.log(error, "errorrrrrrrrrrrrrrrrrrr");
      });
  };
  
  goToAppointmentForm = async () => {
    // const { user } = this.state;
    // const {
    //   location: { pathname },
    //   history: { push },
    // } = this.props;
    // push(`${pathname}/create-appointment`);
    const { companyId } = this.state;
    const user = await JSON.parse(sessionStorage.getItem("user"));
    window.location.assign(baseUrl+`booking/getForm?id=${companyId}&phoneNo=${user ? user.phoneNo : ''}&name=${user ? user.userName : ''}`); 
  };

  jumbotronImgHandler = (event) => {
    const imageUrlRegex = /([a-zA-Z0-9\s_\\.\-:])+(.png|.jpeg|.jpg|.gif)$/gm;
    if (event.target.files[0]) {
      let isValidateType = event.target.files[0].type;
      if (isValidateType.match(imageUrlRegex)) {
        const file = URL.createObjectURL(event.target.files[0]);
        this.setState({
          jumbotronImgUrl: file,
          jumbotronImgFile: event.target.files[0],
        });
      } else {
        ErrorHelper.handleErrors("Image format not supported", true);
      }
    }
  };

  handleNavigation = (route) => {
    this.props.history.push(route);
  };
  
  handleView = (key) => {
    this.setState({ showNav: false })
    this[key].current.scrollIntoView({ behavior: "smooth" });
  };

  handlePublishBtn = () => {
    const {
      jumbotronImgFile,
      headerFirstHeading,
      headerSecondHeading,
      aboutFirstHeading,
      aboutSecondHeading,
      serviceImgFile,
      aboutImgFile,
    } = this.state;
    const { state } = this.props.location;
    this.setState({ isloading: true });

    let payloadFormdata = new FormData();
    if(jumbotronImgFile) {
        payloadFormdata.append("headerImage", jumbotronImgFile);
    }
    payloadFormdata.append("headerFirstHeading", headerFirstHeading);
    payloadFormdata.append("headerSecondHeading", headerSecondHeading);
    payloadFormdata.append("sinceText", "");
    payloadFormdata.append("aboutFirstHeading", aboutFirstHeading);
    payloadFormdata.append("aboutSecondHeading", aboutSecondHeading);
    payloadFormdata.append("aboutFirstImg", aboutImgFile);
    payloadFormdata.append("aboutSecondImg", "");
    payloadFormdata.append("footerImage", serviceImgFile);
    payloadFormdata.append("templateID", state);

    let token = JSON.parse(sessionStorage.getItem("saloon"));
    createUpdateTemplate(payloadFormdata, token.access_token).then((res) => {
      if (res.data.success) {
        this.setState({ isloading: false });

        SuccessHelper.handleSuccess("Published successfully", true);
      } else {
        this.setState({ isloading: false });

        ErrorHelper.handleErrors("Failed to publish", true);
      }
    });

    // for (var key of payloadFormdata.entries()) {
    //   console.log(key[0] + ', ' + key[1]);
    // }
  };
  
  renderEditButtons = (onPresEdit, onPressRemove) => {
    return (
      <span className="templateEditBtnDiv">
        <span onClick={onPresEdit} className="templateEditBtns">
          <RiEditFill color="#fff" size={17} />
        </span>
        <span onClick={onPressRemove} className="templateEditBtns">
          &times;
        </span>
      </span>
    );
  };

  renderPortfolioItemVideo = (image) => {
    return (
      <div>
        <figure className="col-md-4">
            <div className="gallery-image-padding">
                <source src={image} />
            </div>
        </figure>
      </div>
    );
  };

  renderPortfolioVideo = () => {
    const { portfolioVideos, sinceText, isEditTemplate } = this.state;
    return (
        <div className="row portfolio-items">
            {portfolioVideos &&
              portfolioVideos.map((val) => this.renderPortfolioItemVideo(val.url)
            )}
        </div>
    );
  };

  renderPortfolio = () => {
    const { portfolioImages } = this.state;
    return (
        <div className="row portfolio-items">
            {portfolioImages &&
                portfolioImages.map((val) => {
                    return (
                        <figure className="col-md-4 col-sm-6">
                            <SRLWrapper>
                                <div className="gallery-image-padding">
                                    <img className="img-fluid1" src={val.url} alt="" />
                                </div>
                            </SRLWrapper>
                        </figure>
                    )
                })
            }
        </div>
    );
  };
  
  renderServiceComponent = (img, heading, text, duration) => {
    return (
        
        <div 
            className="col-12 col-md-4 col-sm-6" 
            key={heading}
            onClick={this.goToAppointmentForm}
        >
            <figure>
                <img className="img-fluid1" src={img} alt=""/>
            </figure>
            <div className="caption">
                <div className="caption-label">
                    <h4>{heading}</h4>
                </div>
            </div>
        </div>
    );
  };
  
  renderEditTemplateHeader = () => (
    <div className="editTemplateHeaderDiv">
      <span
        onClick={() => this.handlePublishBtn()}
        className="editTemplateHeaderBtn"
      >
        Publish
      </span>
      <span
        onClick={() => this.handleNavigation("/saloon-choose-a-template")}
        className="editTemplateHeaderBtn"
      >
        Cancel
      </span>
    </div>
  );

  renderEditTextInputs = (value, onChange, classes) => (
    <input
      type="text"
      value={value}
      onChange={(text) => onChange(text)}
      className={classes}
    />
  );

  render() {
    const { 
        isEditTemplate, 
        isloading, 
        companyList, 
        showNav, 
        headerFirstHeading, 
        headerSecondHeading,
        aboutFirstHeading,
        aboutSecondHeading,
        jumbotronImgUrl,
    } = this.state;
    console.log(
        headerFirstHeading, 
        headerSecondHeading,
        aboutFirstHeading,
        aboutSecondHeading,
        jumbotronImgUrl,)
    console.log('jumbotronImgUrl', jumbotronImgUrl, typeof jumbotronImgUrl)
    let CompanyLogo = companyList[0]?.saloons[0]?.companyLogo;
    let companyName = companyList[0]?.saloons[0]?.name
    const saloonsVal = companyList[0];
    let shouldShowNav = showNav
    if(this.state.width > 768) {
        shouldShowNav = true
    }
    return (
        <div>
            {isEditTemplate && this.renderEditTemplateHeader()}
            <div className='template4 bootstrap'>
                <header>
                    <nav style={{marginTop: isEditTemplate ? 56 : 0}}>
                        {isEditTemplate && (
                            <input
                                type="file"
                                style={{ display: "none" }}
                                onChange={this.jumbotronImgHandler}
                                onClick={(event) => {
                                event.target.value = null;
                                }}
                                ref={(fileInput) => (this.jumbotronImgInput = fileInput)}
                                accept="image/*"
                            />
                        )}
                        {isEditTemplate &&
                            this.renderEditButtons(
                                () => this.jumbotronImgInput.click(),
                                () =>
                                this.setState({ jumbotronImgFile: null, jumbotronImgUrl: null })
                            )
                        }
                        <div className="container">
                            <hr className="top"/>
                            <nav className="navbar navbar-expand-lg">
                            <a className="navbar-brand" onClick={() => this.handleNavigation("/")}>
                                <div className="logo-brand">
                                    <img src={CompanyLogo} alt=""/>
                                </div>
                            </a>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar1" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon" onClick={() => { this.setState({showNav: !showNav}) }}>
                                <img src={templateImages.bars} alt=""/>
                            </span>
                            </button>
                            { shouldShowNav && 
                                <div className="navbar-collapse" id="navbar1">
                                    <ul className="navbar-nav ml-auto"> 
                                        <li className="nav-item">
                                            <a className="nav-link" onClick={() => this.handleView('home')}>HOME</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" onClick={() => this.handleView('aboutUs')}>ABOUT US</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" onClick={() => this.handleView('services')}>SERVICES</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" onClick={() => this.handleView('appointment')}>APPOINTMENT</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" onClick={() => this.handleView('gallery')}>GALLERY</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" onClick={() => this.handleView('contact')}>CONTACT</a>
                                        </li>
                                    </ul>
                                </div>
                            }
                            </nav>
                        </div>
                    </nav>
                    
                    <div ref={this.home} className="carousel">
                        <div id="carouselExampleIndicators" className="carousel slide carousel-fade" data-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <div className="carousel-img-1" style={{ 'background-image': `url(${jumbotronImgUrl ? jumbotronImgUrl : templateImages.slider})`}}>
                                    
                                </div>
                                <div className="carousel-caption">
                                    <h1 className="animated fadeInLeft">
                                        {!isEditTemplate && headerFirstHeading}
                                        {isEditTemplate &&
                                            this.renderEditTextInputs(
                                                headerFirstHeading,
                                                this.onChangeJumbotronFirstHeading,
                                                "jumbotronHeadingInp jumbotronFirstHeading"
                                        )}
                                    </h1>
                                    <h5 className="animated fadeInUp">
                                        {!isEditTemplate && headerSecondHeading}
                                        {isEditTemplate &&
                                            this.renderEditTextInputs(
                                                headerSecondHeading,
                                                this.onChangeJumbotronSecHeading,
                                                "jumbotronHeadingInp jumbotronFirstHeading"
                                        )}
                                    </h5>
                                    <div className="slider-btn">
                                        <div className="animated inner-btn fadeInUp"><a onClick={this.goToAppointmentForm} className="btn btn-custom">Make Appointment</a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </header>
        
                <section>
                    <div className="container">
                        <div ref={this.aboutUs} className="section-title">
                            <h2>Welcome to {companyName}</h2>
                            <hr className="center"/>
                            {!isEditTemplate && (
                                <p>{aboutFirstHeading}</p>
                            )}
                            {isEditTemplate &&
                                <p>
                                    {this.renderEditTextInputs(
                                        aboutFirstHeading,
                                        this.onChangeaboutFirstHeading,
                                        "aboutHeadingInput"
                                    )}
                                </p>
                            }
                            {!isEditTemplate && (
                                <p>{aboutSecondHeading}</p>
                            )}
                            {isEditTemplate &&
                                <p>
                                    {this.renderEditTextInputs(
                                        aboutSecondHeading,
                                        this.onChangeaboutSecondHeading,
                                        "aboutHeadingInput"
                                    )}
                                </p>
                            }
                        </div>
                    </div>
                    
                    <hr/>
                    <div className="container">
                        <div ref={this.aboutUs} className="section-title">
                            <h2>Our Services</h2>
                        </div>
                        <div ref={this.services} className="row hover-effects image-hover">
                            {companyList.map(
                                (val) =>
                                    val.services &&
                                    val.services.map((serviceVal) => {
                                        return this.renderServiceComponent(
                                            serviceVal.image,
                                            serviceVal.name,
                                            serviceVal?.duration
                                        )
                                    })
                                )
                            }
                        </div>
                    </div>
                    
                    <div className="container-fluid inner-color">
                        <hr/>
                        <div className="container">
                            <div className="row">
                            <div className="col-lg-6">
                                <div className="aside-left">
                                    <div className="icon-lists">
                                        <div className="icon-circle">
                                            <figure className="icon-center"><img src={templateImages.dumbbell} alt=""/></figure>
                                        </div>
                                        <div className="icon-text">
                                            <h3>Lose Weight</h3> 
                                        </div>
                                    </div>
                                    <div className="icon-lists">
                                        <div className="icon-circle">
                                            <figure className="icon-center"><img src={templateImages["006-gym"]} alt=""/></figure>
                                        </div>
                                        <div className="icon-text">
                                            <h3>Build Muscle</h3> 
                                        </div>
                                    </div>
                                    <div className="icon-lists">
                                        <div className="icon-circle">
                                            <figure className="icon-center"><img src={templateImages.exercise} alt=""/></figure>
                                        </div>
                                        <div className="icon-text">
                                            <h3>Stay Fit</h3> 
                                        </div>
                                    </div>
                                </div> 
                            </div>
                            <div className="col-lg-6">
                                <div className="aside-right">
                                    <div className="icon-lists">
                                        <div className="icon-circle">
                                            <figure className="icon-center"><img src={templateImages["gym-1"]} alt=""/></figure>
                                        </div>
                                        <div className="icon-text">
                                            <h3>Protein Plan</h3> 
                                        </div>
                                    </div>
                                    <div className="icon-lists">
                                        <div className="icon-circle">
                                            <figure className="icon-center"><img src={templateImages.weightlifting} alt=""/></figure>
                                        </div>
                                        <div className="icon-text">
                                            <h3>Body Improve</h3> 
                                        </div>
                                    </div>
                                    <div className="icon-lists last-item-list">
                                        <div className="icon-circle">
                                            <figure className="icon-center"><img src={templateImages["gym-2"]} alt=""/></figure>
                                        </div>
                                        <div className="icon-text">
                                            <h3>Personal Trainer</h3> 
                                            {/* <p>Galley of type and scrambled it to make a type.</p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <hr/>
                    </div>
                                
                    <div ref={this.appointment} className="container">
                        <div className="section-title">
                            <h2>Make An Appointment</h2>
                            <hr className="center"/>
                        </div>
                        <div className="row hover-effects image-hover">
                            <div className='book-now-btn'>
                                <div className="animated inner-btn fadeInUp"><a onClick={this.goToAppointmentForm} className="btn btn-custom">Make Appointment</a></div>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    
                    <div ref={this.gallery} className="container filterable-portfolio">
                        <div className="section-title">
                            <h2>Gallery</h2>
                            <hr className="center"/>
                        </div>
                        <SimpleReactLightbox>{!isloading && this.renderPortfolio()}</SimpleReactLightbox>
                        {!isloading && this.renderPortfolioVideo()}
                    </div>
                </section>
                
                <footer ref={this.contact}>
                    <div className="">
                        <div className="row">
                        <div className="col-sm-6" style={{  }}>
                            <div className="container">
                                <p
                                    style={{
                                    color: "#FFFFFF",
                                    fontWeight: "bold",
                                    fontSize: "18px",
                                    }}
                                >
                                    Contact us
                                </p>
                                <div>
                                    <p style={{ color: "#818D86" }}>
                                    Address: {saloonsVal?.saloons[0]?.address}
                                    </p>
                                </div>
                                <div>
                                    <p style={{ color: "#818D86" }}>
                                        E-mail: {saloonsVal?.saloons[0]?.businessEmail}
                                    </p>
                                    <p style={{ color: "#818D86" }}>
                                        Phone Number: {saloonsVal?.saloons[0]?.businessTelephone}
                                    </p>
                                </div>
                                </div>
                            </div>
                            <div className="col-sm-6" style={{  }}>
                                <div className="container">
                                <p
                                    style={{
                                    color: "#FFFFFF",
                                    fontWeight: "bold",
                                    fontSize: "18px",
                                    }}
                                >
                                    Opening Hours
                                </p>
                                {saloonsVal &&
                                    saloonsVal.schedule &&
                                    saloonsVal.schedule.map(
                                    (val) =>
                                        val &&
                                        val.weekPlans &&
                                        val.weekPlans.map((weekData) => {
                                        return (
                                            <div key={weekData.dayOfWeek}>
                                            {weekData.dayOfWeek === 1 ? (
                                                <p style={{ color: "#818D86" }}>
                                                Monday
                                                {weekData.availableStatus === 1 ? (
                                                    <span>
                                                    <span style={{ paddingLeft: 20 }}>
                                                        {weekData.checkIn}
                                                    </span>
                                                    -<span>{weekData.checkOut}</span>
                                                    </span>
                                                ) : (
                                                    <span
                                                        style={{ paddingLeft: 10, color: "red" }}
                                                    >
                                                        Off
                                                    </span>
                                                    )}
                                                </p>
                                            ) : null}
                                            {weekData.dayOfWeek === 2 ? (
                                                <p style={{ color: "#818D86" }}>
                                                Tuesday
                                                {weekData.availableStatus === 1 ? (
                                                    <span>
                                                    <span style={{ paddingLeft: 20 }}>
                                                        {weekData.checkIn}
                                                    </span>
                                                    -<span>{weekData.checkOut}</span>
                                                    </span>
                                                ) : (
                                                    <span
                                                        style={{ paddingLeft: 10, color: "red" }}
                                                    >
                                                        Off
                                                    </span>
                                                    )}
                                                </p>
                                            ) : null}
                                            {weekData.dayOfWeek === 3 ? (
                                                <p style={{ color: "#818D86" }}>
                                                Wednesday
                                                {weekData.availableStatus === 1 ? (
                                                    <span>
                                                    <span style={{ paddingLeft: 20 }}>
                                                        {weekData.checkIn}
                                                    </span>
                                                    -<span>{weekData.checkOut}</span>
                                                    </span>
                                                ) : (
                                                    <span
                                                        style={{ paddingLeft: 10, color: "red" }}
                                                    >
                                                        Off
                                                    </span>
                                                    )}
                                                </p>
                                            ) : null}
                                            {weekData.dayOfWeek === 4 ? (
                                                <p style={{ color: "#818D86" }}>
                                                Thursday
                                                {weekData.availableStatus === 1 ? (
                                                    <span>
                                                    <span style={{ paddingLeft: 20 }}>
                                                        {weekData.checkIn}
                                                    </span>
                                                    -<span>{weekData.checkOut}</span>
                                                    </span>
                                                ) : (
                                                    <span
                                                        style={{ paddingLeft: 10, color: "red" }}
                                                    >
                                                        Off
                                                    </span>
                                                    )}
                                                </p>
                                            ) : null}
                                            {weekData.dayOfWeek === 5 ? (
                                                <p style={{ color: "#818D86" }}>
                                                Friday
                                                {weekData.availableStatus === 1 ? (
                                                    <span>
                                                    <span style={{ paddingLeft: 20 }}>
                                                        {weekData.checkIn}
                                                    </span>
                                                    -<span>{weekData.checkOut}</span>
                                                    </span>
                                                ) : (
                                                    <span
                                                        style={{ paddingLeft: 10, color: "red" }}
                                                    >
                                                        Off
                                                    </span>
                                                    )}
                                                </p>
                                            ) : null}
                                            {weekData.dayOfWeek === 6 ? (
                                                <p style={{ color: "#818D86" }}>
                                                Saturday
                                                {weekData.availableStatus === 1 ? (
                                                    <span>
                                                    <span style={{ paddingLeft: 20 }}>
                                                        {weekData.checkIn}
                                                    </span>
                                                    -<span>{weekData.checkOut}</span>
                                                    </span>
                                                ) : (
                                                    <span
                                                        style={{ paddingLeft: 10, color: "red" }}
                                                    >
                                                        Off
                                                    </span>
                                                    )}
                                                </p>
                                            ) : null}
                                            {weekData.dayOfWeek === 7 ? (
                                                <p style={{ color: "#818D86" }}>
                                                Sunday
                                                {weekData.availableStatus === 1 ? (
                                                    <span>
                                                    <span style={{ paddingLeft: 20 }}>
                                                        {weekData.checkIn}
                                                    </span>
                                                    -<span>{weekData.checkOut}</span>
                                                    </span>
                                                ) : (
                                                    <span
                                                        style={{ paddingLeft: 10, color: "red" }}
                                                    >
                                                        Off
                                                    </span>
                                                    )}
                                                </p>
                                            ) : null}
                                            </div>
                                        );
                                        })
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bottom-footer">
                        <p>2020 copyright EASY1. All Rights Reserved</p>
                    </div>
                </footer>
            </div>
        </div>
    );
  }
}

export default Template4;
