import { connect } from "react-redux";
import React, { Component } from "react";
import { Translation } from "react-i18next";
import i18n from "../../../i18n";
import {
  requestFinancialAccess,
  validateFinancialAccess,
} from "../../../config/simpleApiCalls";
import {
  getRequest,
  removeRequest,
  updateRequest,
  insertRequest,
  setDefaultRequest,
} from "../../../redux/actions/CompanyCard";
import { getRequest as getEasy1Products } from "../../../redux/actions/Saloon/Easy1Products";
import { getRequest as getSubscriptions } from "../../../redux/actions/Saloon/Subscription";
import { Images } from "../../../theme";
import {
  DotsLoader,
  Header,
  AdvancedSearchDialog,
  BookingNotes,
} from "../../../components";
import CardManagment from "../../../components/Card/CardManagment";
import SubscriptionManagement from "../../../components/SubscriptionManagement";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import BaseModal from "../../../components/BaseModal";
import moment from "moment";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { TextField } from "@material-ui/core";
import { ErrorHelper, SuccessHelper } from "../../../helpers";

// import Sytles from "./sytles";
class AccountSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      access_token: "",
      activeTab: 0,
      accessCode: "",
    };
  }

  componentDidMount = async () => {
    const saloonData = await JSON.parse(sessionStorage.getItem("saloon"));
    const financialAccessId = sessionStorage.getItem("financialAccessId");
    this.setState(
      {
        financialAccessId,
        access_token: saloonData.access_token,
        email: saloonData.email,
        phoneNo: saloonData.phoneNo,
      },
      () => {
        const financialAccessId = sessionStorage.getItem("financialAccessId");
        if (!financialAccessId) {
          this.setState({ showUnauthorizedModal: true });
        } else {
          this.retrieveData();
        }
      }
    );
  };

  retrieveData = () => {
    const { access_token } = this.state;
    this.setState({ isloading: true });
    Promise.all([
      new Promise((success, failure) => {
        this.props.getEasy1Products({}, { success, failure });
      }),
      new Promise((success, failure) => {
        this.props.getSubscriptions({ access_token }, { success, failure });
      }),
      new Promise((success, failure) => {
        this.props.getRequest({ access_token }, { success, failure });
      }),
    ])
      .then(() => {
        this.setState({ isloading: false });
      })
      .catch((err) => {
        this.setState({ isloading: false });
        ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
      });
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  handleClosePopup = () => {
    this.setState({ showUnauthorizedModal: false });
  };

  renderRequest = () => {
    const { email, phoneNo, access_token } = this.state;
    const sendRequest = (type) => {
      requestFinancialAccess({ type }, access_token).then(({ data }) => {
        if (data.success) {
          this.setState({
            requestSent: true,
            financialAccessId: data.data._id,
          });
          SuccessHelper.handleSuccess(
            i18n.t("security_request_sent_to", {
              destination: type == "email" ? email : phoneNo,
            }),
            true
          );
        }
      });
    };
    return {
      actions: (
        <div className="row justify-content-end mx-0">
          <Button
            className="primary-button"
            onClick={() => sendRequest("email")}
          >
            {i18n.t("yes_email")}
          </Button>
          <Button onClick={() => sendRequest("SMS")}>
            {i18n.t("yes_sms")}
          </Button>
          <Button onClick={this.handleClosePopup}>{i18n.t("no")}</Button>
        </div>
      ),
      content: (
        <div className="row flex-column mx-0">
          <span className="">{i18n.t("want_to_gain_access")}</span>
        </div>
      ),
    };
  };

  renderValidate = () => {
    const { accessCode, financialAccessId, access_token } = this.state;
    const validate = () => {
      validateFinancialAccess(
        { _id: financialAccessId, code: accessCode },
        access_token
      ).then(({ data }) => {
        if (data.success) {
          sessionStorage.setItem("financialAccessId", financialAccessId);
          SuccessHelper.handleSuccess(i18n.t("successfully_validated"), true);
          this.setState({ showUnauthorizedModal: false });
          this.retrieveData();
        }
      });
    };
    return {
      actions: (
        <div className="row justify-content-end mx-0">
          <Button className="primary-button" onClick={() => validate("email")}>
            {i18n.t("submit")}
          </Button>
        </div>
      ),
      content: (
        <div className="row flex-column mx-0">
          <TextField
            label={i18n.t("access_code")}
            value={accessCode}
            onChange={(e) => {
              this.setState({ accessCode: e.target.value });
            }}
          />
        </div>
      ),
    };
  };

  renderUnauthorizedModal() {
    const { showUnauthorizedModal, requestSent } = this.state;
    return (
      <BaseModal
        open={showUnauthorizedModal}
        {...{
          title: i18n.t("accessing_sensitive_data"),
          ...(requestSent ? this.renderValidate() : this.renderRequest()),
        }}
        onClose={() => this.handleClosePopup()}
      />
    );
  }

  renderSubscriptionTab = (value, index) => {
    const { access_token } = this.state;
    const { productMap, subscription, history } = this.props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        className="bg-white"
      >
        {value === index && (
          <SubscriptionManagement
            subscriptions={subscription}
            access_token={access_token}
            history={history}
            productMap={productMap}
          />
        )}
      </div>
    );
  };

  renderCardTab = (value, index) => {
    const { access_token } = this.state;
    const {
      getRequest,
      removeRequest,
      updateRequest,
      insertRequest,
      defaultCard,
      cards,
      setDefaultRequest,
    } = this.props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        className="bg-white"
      >
        {value === index && (
          <CardManagment
            removeDefault={false}
            defaultCard={defaultCard}
            cards={cards}
            access_token={access_token}
            getRequest={getRequest}
            removeRequest={removeRequest}
            updateRequest={updateRequest}
            insertRequest={insertRequest}
            setDefaultRequest={setDefaultRequest}
          />
        )}
      </div>
    );
  };

  render() {
    const { activeTab, financialAccessId } = this.state;
    return (
      <div>
        {this.renderLoader()}
        {this.renderUnauthorizedModal()}
        <div className="content-container">
          <div className="row mx-0">
            <div className="col-12">
              <span className="saloon-dashboard-heading">
                {i18n.t("container.account_settings")}
              </span>
            </div>
            {financialAccessId ? (
              <span className="p-3 w-100">
                {/* Will be required when we add more to this */}

                <AppBar position="static">
                  <Tabs
                    value={activeTab}
                    onChange={(ev, activeTab) => this.setState({ activeTab })}
                    aria-label="simple tabs example"
                  >
                    <Tab label={i18n.t("cards")} {...this.a11yProps(0)} />
                    <Tab
                      label={i18n.t("subscriptions")}
                      {...this.a11yProps(1)}
                    />
                  </Tabs>
                </AppBar>
                {this.renderCardTab(activeTab, 0)}
                {this.renderSubscriptionTab(activeTab, 1)}
              </span>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const products = state.easy1Products.data;
  const productMap = {};
  Object.values(products).map((group) =>
    group.map((product) => {
      productMap[product.id] = product;
    })
  );
  return {
    cards: state.card.data,
    defaultCard: state.card.defaultCard,
    subscription: state.subscription.data,
    productMap,
  };
};

const action = {
  getRequest,
  removeRequest,
  updateRequest,
  insertRequest,
  getSubscriptions,
  getEasy1Products,
  setDefaultRequest,
};

export default connect(mapStateToProps, action)(AccountSettings);
