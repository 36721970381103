import { Button } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import i18n from "../../../../i18n";

function ListFundsRequests({
  available = 0,
  pending = 0,
  total = 0,
  buttonText,
  buttonClickCallback = () => {},
  pendingColor = "#DDDDDD",
  availableColor = "#DDDDDD",
}) {
  const getContrastYIQ = (hexcolor) => {
    if (hexcolor) {
      hexcolor = hexcolor.replace("#", "");
      var r = parseInt(hexcolor.substr(0, 2), 16);
      var g = parseInt(hexcolor.substr(2, 2), 16);
      var b = parseInt(hexcolor.substr(4, 2), 16);
      var yiq = (r * 299 + g * 587 + b * 114) / 1000;
      return yiq >= 128 ? "black" : "white";
    } else {
      return "black";
    }
  };
  return (
    <div className="row mx-0 col-12 pb-3 px-0">
      <div className="text-center balance-card">
        <div
          className="balance-card-body row mx-0"
          style={{
            backgroundColor: pendingColor,
            color: getContrastYIQ(pendingColor),
          }}
        >
          <div className="balance-card-body-title">
            {i18n.t("pending")}: $ {pending.toFixed(2)}
          </div>
        </div>
      </div>
      <div className="text-center balance-card pl-0">
        <div
          className="balance-card-body row mx-0"
          style={{
            backgroundColor: availableColor,
            color: getContrastYIQ(pendingColor),
          }}
        >
          <div className="balance-card-body-title">
            {i18n.t("available")}: $ {available.toFixed(2)}
          </div>
        </div>
      </div>
      {buttonText ? (
        <Button className="primary-button" onClick={buttonClickCallback}>
          {buttonText}
        </Button>
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(ListFundsRequests);
