// @flow

import { GET_SALOON_APPOINTMENT } from "../ActionTypes";

export function request(payload) {
    return {
        payload,
        type: GET_SALOON_APPOINTMENT.REQUEST
    };
}

export function success(data: Object) {
    return {
        data,
        type: GET_SALOON_APPOINTMENT.SUCCESS
    };
}

export function failure(errorMessage: Object) {
    return {
        errorMessage,
        type: GET_SALOON_APPOINTMENT.FAILURE
    };
}
