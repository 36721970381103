import { take, put, call, fork } from "redux-saga/effects";

import * as types from "../../actions/ActionTypes";
import { success, failure } from "../../actions/Saloon/CreateSaloonSocialMedia";
import axios from "axios";
import { create_saloon_social_links_Api } from "../../../config/WebServices";
import { ErrorHelper } from "../../../helpers";

function callRequest(data) {
  const access_token = data.access_token;
  delete data.access_token;
  return axios.post(create_saloon_social_links_Api, data, {
    headers: {
      Authorization: access_token,
    },
  });
}
function* watchRequest() {
  while (true) {
    const { payload } = yield take(types.CREATE_SALOON_SOCIAL_MEDIA.REQUEST);
    try {
      const { data } = yield call(callRequest, payload);
      yield put(success(data));
    } catch (err) {
      yield put(failure(err));
      ErrorHelper.handleErrors(err, true);
    }
  }
}

export default function* root() {
  yield fork(watchRequest);
}
