import * as types from "../../actions/ActionTypes";
import { success, failure } from "../../actions/Saloon/CreateSaloon";
import { take, put, call, fork } from "redux-saga/effects";
import ApiSauce from "../../../services/apiSauce";
import { create_saloon_Api } from "../../../config/WebServices";
import { ErrorHelper } from "../../../helpers";

function callRequest(data) {
  const access_token = data.access_token;
  delete data.access_token;
  return ApiSauce.postWithToken(create_saloon_Api, data.formData, access_token);
}
function* watchRequest() {
  while (true) {
    const { payload } = yield take(types.CREATE_SALOON.REQUEST);
    try {
      const data = yield call(callRequest, payload);
      yield put(success(data));
    } catch (err) {
      yield put(failure(err));
      ErrorHelper.handleErrors(err, true);
    }
  }
}

export default function* root() {
  yield fork(watchRequest);
}
