import { connect } from "react-redux";
import React, { Component } from "react";
import Translation from "react-i18next";
import i18n from "../../../i18n";
import { DotsLoader } from "../../../components";
import { oneTimePayment } from "../../../config/simpleApiCalls";
import { getRequest as getEasy1Products } from "../../../redux/actions/Saloon/Easy1Products";
import { getRequest as getCards } from "../../../redux/actions/Card";
import { getRequest as getBilling } from "../../../redux/actions/Saloon/Billing";
import {
  insertRequest as createSubscription,
  getRequest as getSubscriptions,
  updateRequest as updateSubscription,
  removeRequest as deleteSubscription,
} from "../../../redux/actions/Saloon/Subscription";
// import {} from "../../config/WebServices";
import { Images } from "../../../theme";
import { SuccessHelper, ErrorHelper } from "../../../helpers";
import Swal from "sweetalert2";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import Sytles from "./styles";
import "./styles.css";
import { getNestedValue } from "../../../util/objectMethods";
import DevicesAndEquipmentOrder from "../../../components/DevicesAndEquipmentOrder";
import AddCard from "../../../components/Card/AddCard";
import PricingFooterCard from "../../../components/PricingFooterCard";
import moment from "moment";

const GST_rate = 5;
const QST_rate = 9.975;
class PricingPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isloading: false,
      eQuipmentTotalPrice: 0,
      totalPrice: 0,
      licensingTotalPrice: 0,
      package_planTotalPrice: 0,
      showPopUp: false,
      superAdminData: null,
      categoryName: "",
      title: "",
      categroyImageUrl: null,
      categroyImageFile: null,
      categroyImageName: "",
      formErrorText: "",
      freeTrial: false,
      twoToFive: false,
      fiveToTen: false,
      tenToFifteen: false,
      allPlans: [],
      showModal: false,
      isloading: true,
      paymentMethods: [],
      customPlans: true,
      plans: [],
      device_equipment: [],
      package_plan: [],
      myBundle: [],
      pricing_plans: {},
      planPricing: 0,
      taxPrices: {},
      timePeriod: "monthly",
      numberOfAdditionalEmployees: 0,
      subscriptionTotal: 0,
      subtotal: 0,
      gst: 0,
      qst: 0,
      grandTotal: 0,
      equipementTotal: 0,
      selectDeviceQuantityMap: {},
      eligibleForFreeLicense: false,
      paymentMap: {},
      licenseSelected: false,
    };
  }

  async componentDidMount() {
    const salonInfo = await JSON.parse(sessionStorage.getItem("saloon"));
    const companyInfo = await JSON.parse(sessionStorage.getItem("company"));
    // this.getPricingPlans(false);
    Promise.all([
      new Promise((resolve) => {
        this.props.getBilling(
          { access_token: salonInfo.access_token, type: "equipment" },
          { success: resolve }
        );
      }),
      new Promise((resolve) => {
        this.props.getEasy1Products({}, { success: resolve });
      }),
      new Promise((resolve) => {
        this.props.getSubscriptions(
          { access_token: salonInfo.access_token },
          { success: resolve }
        );
      }),
      new Promise((resolve) => {
        this.setState({ salonInfo }, () => {
          this.props.getCards(
            { access_token: salonInfo.access_token },
            { success: resolve, failure: resolve }
          );
        });
      }),
    ])
      .then(() => {
        // Map throught billing, find purchased products and determine if user is eligible for free license
        setTimeout(() => {
          const {
            currentSubscriptions,
            powerUser,
            subscription,
            additionalStaff,
            billing = [],
            equipement,
          } = this.props;
          const equipementMap = {};
          equipement.map((product) => {
            equipementMap[product.id] = product;
          });
          const toSet = {};
          toSet.eligibleForFreeLicense = false;
          billing.map((invoice) => {
            if (invoice.status === "paid") {
              const { lineItems = [] } = invoice;
              lineItems.map((item) => {
                let boughtTablet = getNestedValue(
                  equipementMap,
                  [item.price.product, "metadata", "free_license"],
                  false
                );
                if (boughtTablet) {
                  toSet.eligibleForFreeLicense = true;
                }
              });
            }
          });
          currentSubscriptions.map((element) => {
            if (element.metadata.type == "license") {
              toSet.nextLicensePaymentOn = moment
                .unix(element.current_period_end)
                .format("MM-DD-YYYY");
              toSet.eligibleForFreeLicense = false;
              toSet.licenseSelected = true;
            } else {
              toSet.nextBundlePaymentOn = moment
                .unix(element.current_period_end)
                .format("MM-DD-YYYY");
              element.items.data.map((item) => {
                const { price, quantity } = item;
                const { id, product } = price;
                if (product == powerUser.id) {
                  Object.keys(powerUser.priceMap).map((powerUserPriceKey) => {
                    if (powerUser.priceMap[powerUserPriceKey].id == id) {
                      toSet.timePeriod = powerUserPriceKey;
                    }
                  });
                } else if (product == additionalStaff.id) {
                  toSet.numberOfAdditionalEmployees = quantity;
                } else {
                  toSet.selectedPlan = subscription.find(
                    (element) => element.id == product
                  );
                }
              });
            }
          });
          this.setState({ ...toSet }, this.computeTotals);
        }, 0);
        this.setState({ isloading: false, companyInfo, salonInfo });
      })
      .catch((err) => {
        console.log("err", err);
      });
  }
  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  closeModal() {
    this.setState({ showPopUp: false });
  }

  handleCheckBox = (e) => {
    this.setState(
      {
        timePeriod: e.target.value,
      },
      this.computeTotals
    );
  };

  renderFooter = () => {
    const { paymentMap } = this.state;
    return (
      <div>
        <div className="row mx-0 justify-content-end">
          {Object.keys(paymentMap).map((timePeriodKey) => {
            let subtotal = 0;
            let items = [
              ...paymentMap[timePeriodKey].map((element) => {
                subtotal += element.amount;
                return {
                  ...element,
                  amount: `$${element.amount.toFixed(2)}`,
                };
              }),
            ];
            const gst = subtotal * (GST_rate / 100);
            const qst = subtotal * (QST_rate / 100);
            const grandTotal = subtotal + gst + qst;
            items = items.concat([
              { label: i18n.t("subtotal"), amount: `$${subtotal.toFixed(2)}` },
              { label: "TPS / GST", amount: `$${gst.toFixed(2)}` },
              { label: "TVQ / QST:", amount: `$${qst.toFixed(2)}` },
              {
                label: i18n.t("grand_total"),
                amount: `$${grandTotal.toFixed(2)}`,
              },
            ]);
            if (paymentMap[timePeriodKey].length) {
              return (
                <div className="col-4 col-xs-12">
                  <PricingFooterCard
                    items={items}
                    header={i18n.t(timePeriodKey)}
                  />
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>

        <div className="row mx-0 justify-content-end">
          <button
            onClick={this.handleCreateSubscription}
            type="button"
            className="btn btn-light mb-2 m-2 p-"
          >
            {i18n.t("submit")}
          </button>
        </div>
      </div>
    );
  };

  computeTotals = () => {
    const {
      selectedPlan,
      timePeriod,
      numberOfAdditionalEmployees,
      selectDeviceQuantityMap,
      licenseSelected,
    } = this.state;
    const { powerUser, subscription, additionalStaff, equipement, license } =
      this.props;
    let subtotal = 0;
    let subscriptionTotal = 0;
    let additionalStaffTotal = 0;
    let equipementTotal = 0;
    subscriptionTotal += powerUser.priceMap[timePeriod]?.unit_amount;
    const paymentMap = {
      monthly: [],
      quarterly: [],
      yearly: [],
      now: [],
    };
    const licenseTotal = getNestedValue(
      license,
      ["prices", 0, "unit_amount"],
      0
    );
    if (licenseSelected) {
      paymentMap.yearly.push({
        label: i18n.t("license_cost"),
        amount: licenseTotal / 100,
      });
    }
    if (selectedPlan?.id) {
      subscriptionTotal += getNestedValue(
        subscription.find((element) => element.id == selectedPlan.id),
        ["priceMap", timePeriod, "unit_amount"],
        0
      );
    }
    if (numberOfAdditionalEmployees) {
      additionalStaffTotal =
        getNestedValue(
          additionalStaff,
          ["priceMap", timePeriod, "unit_amount"],
          0
        ) * numberOfAdditionalEmployees;
      subscriptionTotal += additionalStaffTotal;
      additionalStaffTotal = additionalStaffTotal / 100;
    }
    equipement.map((element) => {
      if (
        selectDeviceQuantityMap[getNestedValue(element, ["prices", 0, "id"])]
      ) {
        equipementTotal +=
          selectDeviceQuantityMap[
            getNestedValue(element, ["prices", 0, "id"])
          ] * getNestedValue(element, ["prices", 0, "unit_amount"], 0);
      }
    });
    if (equipementTotal) {
      equipementTotal = equipementTotal / 100;
      paymentMap.now.push({
        label: i18n.t("equipment_total"),
        amount: equipementTotal,
      });
    }
    subscriptionTotal = subscriptionTotal / 100;
    paymentMap[timePeriod].push({
      label: i18n.t("subscription_total"),
      amount: subscriptionTotal,
    });
    const gst = subtotal * (GST_rate / 100);
    const qst = subtotal * (QST_rate / 100);
    this.setState({
      paymentMap,
      subtotal,
      additionalStaffTotal,
      subscriptionTotal,
      equipementTotal,
      grandTotal: subtotal + equipementTotal + gst + qst,
    });
  };

  handleSelectedPlan = (selectedPlan) => {
    this.setState({ selectedPlan }, this.computeTotals);
  };

  handleAdditionalEmployeeChange = (numberOfAdditionalEmployees) => {
    if (numberOfAdditionalEmployees < 0) {
      numberOfAdditionalEmployees = 0;
    }
    this.setState({ numberOfAdditionalEmployees }, this.computeTotals);
  };

  handleCreateSubscription = () => {
    const { companyInfo, equipementTotal, selectDeviceQuantityMap } =
      this.state;
    const { cards } = this.props;
    if (!cards.length && (!companyInfo.isFreeTrial || equipementTotal)) {
      ErrorHelper.handleErrors(i18n.t("no_card_on_file"), true);
      this.setState({ showAddCardModal: true });
    } else {
      this.setState({ isloading: true });
      const promises = [];
      const {
        powerUser,
        additionalStaff,
        license,
        currentSubscriptions = [],
      } = this.props;
      const {
        numberOfAdditionalEmployees,
        timePeriod,
        selectedPlan,
        salonInfo,
        licenseSelected,
        eligibleForFreeLicense,
      } = this.state;
      if (Object.keys(selectDeviceQuantityMap).length) {
        promises.push(
          oneTimePayment(
            {
              prices: Object.entries(selectDeviceQuantityMap).map(
                ([key, value]) => {
                  return {
                    id: key,
                    quantity: value,
                  };
                }
              ),
            },
            salonInfo.access_token
          ).then(({ data }) => {
            if (data.success) {
              this.setState({ selectDeviceQuantityMap: {} });
            }
          })
        );
      }
      const licenseSubscriptionIndex = currentSubscriptions.findIndex(
        (element) => element.metadata?.type == "license"
      );
      const licenseSubscription = {
        items: [
          {
            price: license.prices[0].id,
            product: license.prices[0].product,
          },
        ],
        eligibleForFreeLicense,
        type: "license",
      };
      if (licenseSubscriptionIndex != -1) {
        if (licenseSelected) {
          promises.push(
            new Promise((resolve) => {
              this.props.updateSubscription(
                {
                  id: currentSubscriptions[licenseSubscriptionIndex].id,
                  subscription: licenseSubscription,
                  access_token: salonInfo.access_token,
                },
                {
                  success: (data) => {
                    resolve();
                  },
                  failure: (data) => {
                    resolve();
                  },
                }
              );
            })
          );
        } else {
          promises.push(
            new Promise((resolve) => {
              this.props.deleteSubscription(
                {
                  id: currentSubscriptions[licenseSubscriptionIndex].id,
                  access_token: salonInfo.access_token,
                },
                {
                  success: (data) => {
                    resolve();
                  },
                  failure: (data) => {
                    resolve();
                  },
                }
              );
            })
          );
        }
      } else if (licenseSelected) {
        promises.push(
          new Promise((resolve) => {
            this.props.createSubscription(
              {
                subscriptions: [licenseSubscription],
                access_token: salonInfo.access_token,
              },
              {
                success: (data) => {
                  resolve();
                },
                failure: (data) => {
                  resolve();
                },
              }
            );
          })
        );
      }
      const baseSubscription = {
        items: [
          {
            price: powerUser.priceMap[timePeriod].id,
            product: powerUser.priceMap[timePeriod].product,
          },
        ],
      };
      if (selectedPlan) {
        baseSubscription.items.push({
          price: selectedPlan.priceMap[timePeriod].id,
          product: selectedPlan.priceMap[timePeriod].product,
        });
      }
      if (numberOfAdditionalEmployees) {
        baseSubscription.items.push({
          price: additionalStaff.priceMap[timePeriod].id,
          product: additionalStaff.priceMap[timePeriod].product,
          quantity: numberOfAdditionalEmployees,
        });
      }
      const subscriptionToUpdate = currentSubscriptions.find(
        (element) => element.metadata.type != "license"
      );
      if (subscriptionToUpdate) {
        promises.push(
          new Promise((resolve) => {
            this.props.updateSubscription(
              {
                id: subscriptionToUpdate.id,
                subscription: baseSubscription,
                access_token: salonInfo.access_token,
              },
              {
                success: (data) => {
                  resolve();
                },
                failure: ({ error }) => {
                  error = JSON.parse(error);
                  ErrorHelper.handleErrors(
                    i18n.t(error.code, {
                      totalEmployees: error.total_employees,
                    }),
                    true
                  );
                  resolve();
                },
              }
            );
          })
        );
      } else {
        promises.push(
          new Promise((resolve) => {
            this.props.createSubscription(
              {
                subscriptions: [baseSubscription],
                access_token: salonInfo.access_token,
              },
              {
                success: (data) => {
                  resolve();
                },
                failure: (data) => {
                  resolve();
                },
              }
            );
          })
        );
      }
      Promise.all(promises).then(() => {
        this.setState({ isloading: false });
        SuccessHelper.handleSuccess(i18n.t("successfully_updated"), true);
      });
    }
  };

  renderBundle = () => {
    const {
      timePeriod,
      selectedPlan,
      subscriptionTotal,
      numberOfAdditionalEmployees,
      nextBundlePaymentOn,
    } = this.state;
    const { subscription, powerUser, additionalStaff } = this.props;
    const powerUserUnitAmount = getNestedValue(
      powerUser,
      ["priceMap", timePeriod, "unit_amount"],
      0
    );
    const powerUserFormattedUnitValue = powerUserUnitAmount
      ? `$${(powerUserUnitAmount / 100).toFixed(2)}`
      : 0;
    const additionalStaffUnitAmount = getNestedValue(
      additionalStaff,
      ["priceMap", timePeriod, "unit_amount"],
      0
    );
    const additionalStaffFormattedUnitValue = additionalStaffUnitAmount
      ? `$${(additionalStaffUnitAmount / 100).toFixed(2)}`
      : 0;
    return (
      <div className="col-12 px-0">
        <h3 className="form-heading">
          {i18n.t("container.pricing_plans_bundle_up")}
        </h3>
        {nextBundlePaymentOn && (
          <h6>
            {i18n.t("next_bundle_payment_on", {
              nextPaymentOn: nextBundlePaymentOn,
            })}
          </h6>
        )}
        <RadioGroup
          name="duration"
          row
          value={timePeriod}
          onChange={this.handleCheckBox}
          className="justify-content-between"
        >
          <FormControlLabel
            value="monthly"
            control={<Radio />}
            label={i18n.t("container.pricing_plans_bundle_up_monthly")}
          />
          <FormControlLabel
            value="quarterly"
            control={<Radio />}
            label={i18n.t("container.pricing_plans_bundle_up_quartely", {
              save: `$${(subscriptionTotal * 0.05).toFixed(2)}`,
            })}
          />
          <FormControlLabel
            value="yearly"
            control={<Radio />}
            label={i18n.t("container.pricing_plans_bundle_up_yearly", {
              save: `$${(subscriptionTotal * 0.1).toFixed(2)}`,
            })}
          />
        </RadioGroup>
        <div className="col-12 px-0" id="plan-form">
          <table class="table table-borderless table-layout-fixed">
            <thead>
              <tr>
                <th scope="col">
                  {" "}
                  <span className="tab-heading">{i18n.t("items")}</span>
                </th>
                <th scope="col">
                  <span className="tab-heading">{i18n.t("by_price")}</span>
                </th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span className="tab-text">{powerUser.name}</span>
                </td>
                <td>
                  <span className="tab-text">
                    {powerUserFormattedUnitValue}
                  </span>
                </td>
                <td>
                  <Checkbox checked={true} disabled={true} />
                </td>
              </tr>
              {subscription.map((val, ind) => {
                const unitAmount = getNestedValue(
                  val,
                  ["priceMap", timePeriod, "unit_amount"],
                  0
                );
                const formattedUnitValue = unitAmount
                  ? `$${(unitAmount / 100).toFixed(2)}`
                  : 0;
                return (
                  <tr key={val.id}>
                    <td>
                      <span className="tab-text">{val.name}</span>
                    </td>
                    <td>
                      <span className="tab-text">{formattedUnitValue}</span>
                    </td>
                    <td>
                      <Checkbox
                        checked={selectedPlan?.id === val.id}
                        onChange={() =>
                          this.handleSelectedPlan(
                            selectedPlan?.id === val.id ? null : val
                          )
                        }
                      />
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td>
                  <span className="tab-text">{additionalStaff.name}</span>
                </td>
                <td>
                  <span className="tab-text">
                    {additionalStaffFormattedUnitValue}
                  </span>
                </td>
                <td>
                  <span
                    className="qty-btn"
                    onClick={() =>
                      this.handleAdditionalEmployeeChange(
                        numberOfAdditionalEmployees - 1
                      )
                    }
                  >
                    &minus;
                  </span>
                  <span className="mx-2 tab-text">
                    {numberOfAdditionalEmployees}
                  </span>
                  <span
                    className="qty-btn"
                    onClick={() =>
                      this.handleAdditionalEmployeeChange(
                        numberOfAdditionalEmployees + 1
                      )
                    }
                  >
                    &#43;
                  </span>
                </td>
              </tr>
            </tbody>
            <tfoot style={{ borderTop: "1px solid #000" }}>
              <td colspan="1"></td>
              <td>
                <span className="tab-foot">Total</span>
              </td>
              <td>
                <span className="tab-foot">${subscriptionTotal}</span>
              </td>
            </tfoot>
          </table>
        </div>
        {this.handleLicense()}
        {this.handleDeviceAndEquipment()}
        {this.renderFooter()}
      </div>
    );
  };

  handleDeviceQuantityChange = (id, numberOfDevices) => {
    const { selectDeviceQuantityMap } = this.state;
    if (numberOfDevices < 0) {
      numberOfDevices = 0;
    }
    selectDeviceQuantityMap[id] = numberOfDevices;
    this.setState({ selectDeviceQuantityMap }, this.computeTotals);
  };

  handleDeviceAndEquipment() {
    const {
      totalPrice,
      myPlanError,
      plans,
      subtotal,
      device_equipment,
      eQuipmentTotalPrice,
      selectDeviceQuantityMap,
      equipementTotal,
    } = this.state;
    const { equipement = [] } = this.props;

    return (
      <div className="col-12 px-0">
        <h3 className="form-heading">
          {i18n.t("container.pricing_plans_bundle_up_device_equipments")}
        </h3>
        <div className="col-12 px-0" id="plan-form">
          <DevicesAndEquipmentOrder
            {...{
              selectDeviceQuantityMap,
              equipement,
            }}
            handleDeviceQuantityChange={this.handleDeviceQuantityChange}
          />
        </div>
      </div>
    );
  }

  handleLicenseToggles = (licenseSelected) => {
    this.setState({ licenseSelected }, this.computeTotals);
  };

  handleLicense() {
    const { eligibleForFreeLicense, nextLicensePaymentOn, licenseSelected } =
      this.state;
    const { license } = this.props;
    const unitAmount = getNestedValue(license, ["prices", 0, "unit_amount"], 0);
    const formattedUnitValue = unitAmount
      ? `$${(unitAmount / 100).toFixed(2)}`
      : 0;

    return (
      <div className="col-12 px-0">
        {license && (
          <>
            <h3 className="form-heading">
              {i18n.t("container.pricing_plans_bundle_up_licensing")}
            </h3>
            {nextLicensePaymentOn && (
              <h6>
                {i18n.t("next_license_payment_on", {
                  nextPaymentOn: nextLicensePaymentOn,
                })}
              </h6>
            )}
            <div className="col-12 px-0" id="plan-form">
              <table class="table table-borderless table-layout-fixed">
                <thead>
                  <tr>
                    <th scope="col">
                      {" "}
                      <span className="tab-heading">{i18n.t("items")}</span>
                    </th>

                    <th scope="col">
                      <span className="tab-heading">{i18n.t("by_price")}</span>
                    </th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <span className="tab-text">{license.name}</span>
                    </td>

                    <td>
                      <span className="tab-text">{formattedUnitValue}</span>
                    </td>
                    <td>
                      <Checkbox
                        checked={licenseSelected}
                        onChange={() =>
                          this.handleLicenseToggles(!licenseSelected)
                        }
                      />
                    </td>
                  </tr>
                  {/* Must look for tablet in current or past subscriptions, only visible if subscription doesnt exist */}
                  {eligibleForFreeLicense && (
                    <tr>
                      <td>
                        <span className="tab-text">
                          {i18n.t("free_first_year")}
                        </span>
                      </td>

                      <td>
                        <span className="tab-text"></span>
                      </td>
                      <td>
                        <Checkbox checked={true} disabled={true} />
                      </td>
                    </tr>
                  )}
                </tbody>
                <tfoot style={{ borderTop: "1px solid #000" }}>
                  <td colspan="1"></td>
                  <td>
                    <span className="tab-foot">Total</span>
                  </td>
                  <td>
                    <span className="tab-foot">{formattedUnitValue}</span>
                  </td>
                </tfoot>
              </table>
            </div>
          </>
        )}
      </div>
    );
  }

  renderAddCardModal = () => {
    const { showAddCardModal, salonInfo } = this.state;
    return (
      <AddCard
        open={showAddCardModal}
        access_token={salonInfo?.access_token}
        handleClose={() => this.setState({ showAddCardModal: false })}
      />
    );
  };

  render() {
    let { customPlans, salonInfo, myBundle } = this.state;

    return (
      <div>
        {this.renderLoader()}
        <div className="content-container">
          <div className="row mx-0">
            <div className="col-12 bg-white shadow rounded p-3">
              {customPlans && myBundle?._id && this.renderMyBundles()}
              {!customPlans ? this.pricingCard() : this.renderBundle()}
              {this.renderAddCardModal()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const products = state.easy1Products.data;
  const mapPrice = (element) => {
    const priceMap = {};
    element.prices.map((price) => {
      const recurring = price.recurring;
      if (recurring.interval == "month") {
        if (recurring.interval_count == 1) {
          priceMap.monthly = price;
        } else if (recurring.interval_count == 3) {
          priceMap.quarterly = price;
        }
      } else if (recurring.interval == "year") {
        priceMap.yearly = price;
      }
    });
    return {
      ...element,
      priceMap,
    };
  };
  const license = getNestedValue(products, ["license", 0], []);
  const equipement = getNestedValue(products, ["equipement"], []);
  const subscription = [
    ...getNestedValue(products, ["subscription"], []).map(mapPrice),
  ];
  const additionalStaffIndex = subscription.findIndex(
    (element) =>
      getNestedValue(element, ["metadata", "subtype"]) == "additional_employee"
  );
  let additionalStaff = {};
  if (additionalStaffIndex != -1) {
    additionalStaff = subscription[additionalStaffIndex];
    subscription.splice(additionalStaffIndex, 1);
  }
  const powerUserIndex = subscription.findIndex(
    (element) =>
      getNestedValue(element, ["metadata", "subtype"]) == "power_user"
  );
  let powerUser = {};
  if (powerUserIndex != -1) {
    powerUser = subscription[powerUserIndex];
    subscription.splice(powerUserIndex, 1);
  }
  return {
    cards: state.card.data,
    license,
    equipement,
    subscription,
    powerUser,
    additionalStaff,
    billing: state.billing.data,
    currentSubscriptions: state.subscription.data,
  };
};

const action = {
  getEasy1Products,
  getCards,
  getBilling,
  createSubscription,
  getSubscriptions,
  updateSubscription,
  deleteSubscription,
};

export default connect(mapStateToProps, action)(PricingPlan);
