import { connect } from "react-redux";
import React, { Component } from "react";
import i18n from "../../../i18n";
import { getTaxReports } from "../../../config/simpleApiCalls";
import { DotsLoader } from "../../../components";
import Avatar from "react-avatar";
// import { adminGetUsers, adminDeleteUser } from '../../../config/simpleApiCalls';
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import ProgressBar from "react-bootstrap/ProgressBar";
import Button from "@material-ui/core/Button";
import moment from "moment";
import Sytles from "./sytles";
import DatePicker from "react-datepicker";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class TaxReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      access_token: "",
      user: null,

      showEditDelete: "",
      searchText: "",
      activePage: 1,
      usersList: [],
      startDate: new Date(),
      endDate: new Date(),
      // employeeList: [],
      filterUsersList: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    this.retrieveReports();
  }

  formatReportData = (data) => {
    const masterTaxMap = {};
    let formatted = data.map((element) => {
      const taxMap = {
        total: 0,
      };
      element.taxes.map((tax) => {
        if (tax.type) {
          taxMap[tax.type] = tax.amount;
          taxMap.total += tax.amount;
          if (!masterTaxMap[tax.type]) {
            masterTaxMap[tax.type] = 0;
          }
          masterTaxMap[tax.type] += tax.amount;
        }
      });
      return { ...element, taxMap };
    });
    this.setState({ masterTaxMap });
    return formatted;
  };

  setStartDate = (val) => {
    const { endDate } = this.state;
    if (val > endDate) {
      this.setState({
        startDate: endDate,
        endDate: val,
      });
    } else {
      this.setState({
        startDate: val,
      });
    }
  };

  setEndDate = (val) => {
    const { startDate } = this.state;
    if (val < startDate) {
      this.setState({
        startDate: val,
        endDate: startDate,
      });
    } else {
      this.setState({
        endDate: val,
      });
    }
  };

  TemplateOption = () => {
    const { startDate, endDate } = this.state;
    return (
      <div
        style={{ flex: 1, display: "flex", justifyContent: "space-between" }}
        className="employee-report-filters"
      >
        <div>{i18n.t('date_range')}</div>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <div className="mx-1">
            <DatePicker
              className="form-control saloon-form-input"
              placeholderText="Select Start Date"
              selected={startDate}
              onChange={(data) => this.setStartDate(data)}
              dateFormat="MM/dd/yyyy"
            />
          </div>
          <div className="mx-1">
            <DatePicker
              className="form-control saloon-form-input"
              placeholderText="Select End Date"
              selected={endDate}
              onChange={(data) => this.setEndDate(data)}
              dateFormat="MM/dd/yyyy"
            />
          </div>
        </div>
      </div>
    );
  };

  renderSearchBar = () => {
    const { searchText } = this.state;
    return (
      <>
        <div className="col-12 col-lg-4 col-md-4 mt-4">
          <span>
            <span className="saloon-dashboard-heading">
              {i18n.t("tax_report")}
            </span>
            {/* <p className="saloon-dashboard-sub-heading">
              Lorem ipsum dolor sit amet
            </p> */}
          </span>
        </div>
        <div className="col-12 col-lg-8 col-md-8 mt-4">
          <div className=" d-flex justify-content-end row">
            <div className="col-12 col-lg-7 col-md-6 "></div>
            {/* <div className="col-12 col-lg-5 col-md-6 mt-2 ">
                            <span className="add_employee_btn px-5 px-lg-5 px-md-4  py-2 mr-2 ">
                                <img src={Images.saloon_addEmployee_plus} style={{ width: 20, height: 20, color: "white" }}></img>
                                Add Employee
                            </span>
                        </div> */}
          </div>
        </div>
      </>
    );
  };

  renderTableBody = () => {
    const { data, isLoading, masterTaxMap } = this.state;
    if (!isLoading) {
      if (data) {
        return (
          <tbody>
            <tr>
              <td>
                <strong>{i18n.t("summary")}: </strong>
              </td>
              {Object.keys(masterTaxMap).map((tax) => {
                return (
                  <td className="px-0">
                    <Button
                      disabled={true}
                      style={{
                        backgroundColor: "#ff3600",
                        color: "white",
                        width: "7rem",
                      }}
                    >
                      $ {masterTaxMap[tax].toFixed(2)}
                    </Button>
                  </td>
                );
              })}
              <td className="px-0">
                <Button
                  disabled={true}
                  style={{
                    backgroundColor: "#ff3600",
                    color: "white",
                    width: "7rem",
                  }}
                >
                  ${" "}
                  {Object.values(masterTaxMap)
                    .reduce((accum, element) => accum + element, 0)
                    .toFixed(2)}
                </Button>
              </td>
            </tr>
            {data.map((val, ind) => {
              return (
                <tr key={val._id} style={{ borderTop: "3px solid #F7F7F7" }}>
                  <td className=" Table_data">
                    <div className="d-flex align-items-center">
                      <span className="appointment-details-employee-name">
                        {moment(val.createdDate).format("MM-DD-YYYY hh:mm a")}
                      </span>
                    </div>
                  </td>
                  {Object.keys(masterTaxMap).map((tax) => {
                    return (
                      <td className=" Table_data">
                        <div className="d-flex align-items-center mt-1">
                          <span className="appointment-details-employee-name">
                            $ {val.taxMap[tax] ? val.taxMap[tax].toFixed(2) : 0}
                          </span>
                        </div>
                      </td>
                    );
                  })}
                  <td className=" Table_data">
                    <div className="d-flex align-items-center mt-1">
                      <span className="appointment-details-employee-name">
                        $ {val.taxMap.total.toFixed(2)}
                      </span>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        );
      }
    } else {
      return <DotsLoader isloading={this.state.isloading} />;
    }
  };

  renderLoader = () => {
    const { isLoading } = this.state;
    return <DotsLoader isloading={isLoading} />;
  };

  renderUserTable = () => {
    const { masterTaxMap = {}, data } = this.state;
    if (data && data.length) {
      return (
        <div className="col-12 p-3">
          <div className="table-responsive sticky-header">
            <table className="table table-borderless appointment-details-table">
              <thead>
                <tr>
                  {/* <th scope="col">
                                      <span>
                                              <div class="custom-control custom-checkbox">
                                                  <input
                                                      type="checkbox"
                                                      class="custom-control-input"
                                                  />
                                                  <label class="custom-control-label"></label>
                                              </div>
                                  </span>
                                  </th> */}
                  <th scope="col">
                    <span className="appointment-details-th-text">
                      {i18n.t("date")}
                    </span>
                  </th>
                  {Object.keys(masterTaxMap).map((tax) => {
                    return (
                      <th scope="col">
                        <div
                          className="row"
                          style={{ flexDirection: "column" }}
                        >
                          <span className="appointment-details-th-text">
                            {tax}
                          </span>
                        </div>
                      </th>
                    );
                  })}
                  <th scope="col">
                    <div className="row" style={{ flexDirection: "column" }}>
                      <span className="appointment-details-th-text">
                        {i18n.t("total")}
                      </span>
                    </div>
                  </th>
                </tr>
              </thead>
              {this.renderTableBody()}
            </table>
          </div>
        </div>
      );
    } else {
      return (
        <div className="row full-width mx-2 justify-content-center mt-1">
          {i18n.t("nothing_found")}
        </div>
      );
    }
  };

  renderButton = () => {
    return (
      <div
        style={{
          justifyContent: "flex-end",
          flex: 1,
          display: "flex",
        }}
      >
        <span onClick={() => alert("")} className="generateButton">
          Generate Report
        </span>
      </div>
    );
  };

  // getDataAgain = () =>{

  // }
  setSelectedValue = (val) => {
    let currentDate = moment(Date.now());
    if (val == 1) {
      const startOfMonth = moment(currentDate).startOf("month");
      const endOfMonth = moment(currentDate).endOf("month");
      this.setState({
        startDate: startOfMonth.toDate(),
        endDate: endOfMonth.toDate(),
      });
    } else if (val == 2) {
      const startOfQuarter = moment(currentDate).startOf("quarter");
      const endOfQuarter = moment(currentDate).endOf("quarter");
      this.setState({
        startDate: startOfQuarter.toDate(),
        endDate: endOfQuarter.toDate(),
      });
    } else if (val == 3) {
      const convertedStart = moment(
        `01-01-${currentDate.year()}`,
        "MM-DD-YYYY"
      );
      const convertedEnd = moment(`12-31-${currentDate.year()}`, "MM-DD-YYYY");
      this.setState({
        startDate: convertedStart.toDate(),
        endDate: convertedEnd.toDate(),
      });
    }
  };

  retrieveReports = (exportToXlsx) => {
    let token = JSON.parse(sessionStorage.getItem("saloon"));
    this.setState({ isLoading: true }, () => {});
    const { startDate, endDate } = this.state;
    let payload = {
      start: moment(startDate).format("MM-DD-YYYY"),
      end: moment(endDate).format("MM-DD-YYYY"),
      exportToXlsx,
    };
    getTaxReports(payload, token.access_token)
      .then((res) => {
        this.setState({ isLoading: false });
        this.setState({ data: this.formatReportData(res.data.data) }, () => {});
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  };

  ShowMenuValues = () => {
    return (
      <div class="px-0">
        <div class="row employee-report-filters pt-3 px-0 mx-0">
          <div className="col-12 col-sm-12 col-md-3 d-flex px-0">
            {i18n.t("container.quick_filter")}
          </div>
          <div className="col-12 col-md-9 px-0 justify-content-end">
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-light btn-sm my-1"
                onClick={() => this.setSelectedValue(1)}
              >
                {i18n.t("container.month")}
              </button>
              <button
                type="button"
                className="btn btn-light btn-sm my-1"
                onClick={() => this.setSelectedValue(2)}
              >
                {i18n.t("container.quarter")}
              </button>
              <button
                type="button"
                className="btn btn-light btn-sm my-1"
                onClick={() => this.setSelectedValue(3)}
              >
                {i18n.t("container.year")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  retrieveButton = () => {
    return (
      <div class="px-0 row mx-0 justify-content-end">
        <button
          type="button"
          className="btn btn-accept btn-sm"
          onClick={() => this.retrieveReports(true)}
        >
          {i18n.t("export")}
          <FontAwesomeIcon className="ml-1" icon={["far", "file-excel"]} />
        </button>
        <button
          type="button"
          className="btn btn-light btn-sm"
          onClick={() => this.retrieveReports()}
        >
          {i18n.t("search")}
        </button>
      </div>
    );
  };

  render() {
    const { isLoading } = this.state;
    return (
      <>
        {isLoading ? this.renderLoader() : null}

        <div>
          <div className="content-container">
            <div className="row mx-0">
              <div className="full-width">
                {this.renderSearchBar()}
                <div className="mx-2">
                  {this.TemplateOption()}
                  {this.ShowMenuValues()}
                  {this.retrieveButton()}
                </div>
              </div>
              {this.renderUserTable()}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(TaxReport);
