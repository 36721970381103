import { connect } from "react-redux";
import React, { Component } from "react";
import { Translation } from "react-i18next";
import i18n from "../../../i18n";
import { DotsLoader } from "../../../components";
import { getRequest as getOrders } from "../../../redux/actions/Saloon/Orders";
import { getSoldPackageRequest } from "../../../redux/actions/Packages";
import { set } from "../../../redux/actions/ActiveBooking";
import { getBookingById } from "../../../config/simpleApiCalls";

import "./styles.css";
import { getNestedValue } from "../../../util/objectMethods";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CollapsibleTable from "../../../components/CollapsibleTable";
import moment from "moment";
import PrimaryButton from "../../../components/Core/PrimaryButton";
import CollectPaymentModal from "../../../components/CreateInvoice/CollectPaymentModal";
const statusMap = {
  1: "pending",
  2: "completed",
  3: "cancelled",
  4: "paid",
};
class Orders extends Component {
  state = {
    collectPaymentModalOpen: false,
    packageToCollect: {},
  };

  componentDidMount = async () => {
    const saloonData = await JSON.parse(sessionStorage.getItem("saloon"));
    if (saloonData) {
      this.setState({
        access_token: saloonData.access_token,
      });
      this.props.getSoldPackageRequest({
        access_token: saloonData.access_token,
      });
      this.props.getOrders({ access_token: saloonData.access_token });
    }
  };

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading || this.props.isLoading} />;
  };

  handleOrderView = async ({ bookingId }) => {
    const { data } = await getBookingById({
      id: bookingId,
      relations: ["extras", "cart"],
    });
    if (data.success) {
      this.props.set(data.data);
    }
    this.props.history.push("saloon-my-appointment");
  };

  renderTable = () => {
    const { orders = [] } = this.props;
    return (
      <>
        <div className="col-12 p-3">
          <h1 className="saloon-dashboard-heading">
            <Translation>{(t) => t("container.orders")}</Translation>
          </h1>
        </div>
        <div
          className="table-responsive sticky-header half"
          style={{ borderRadius: "1rem", border: "1px solid #E0E2E4" }}
        >
          <CollapsibleTable
            rows={orders}
            schema={[
              {
                key: "name",
                accessor: (val) => val.userId?.firstName || val.name,
              },
              {
                key: "container.profile_email",
                accessor: (val) => val.userId?.email || val.email,
              },
              {
                key: "phoneNo",
                accessor: (val) => val.userId?.phoneNo || val.phoneNo,
              },
              {
                key: "number_of_items",
                accessor: (val) => val.products.length,
              },
              {
                key: "date",
                accessor: (val) =>
                  moment(val.closedOn ? val.closedOn : val.createdDate).format(
                    "MM-DD-YYYY hh:mm A"
                  ),
              },
              {
                key: "status",
                accessor: (val) => {
                  if (val.status != 1) {
                    return i18n.t(statusMap[val.status]);
                  } else {
                    const hasPaidProducts = val.products.find(
                      (product) => !!product.reserved
                    );
                    if (hasPaidProducts) {
                      return `${i18n.t(statusMap[1])}/${i18n.t(statusMap[4])}`;
                    } else {
                      return i18n.t(statusMap[1]);
                    }
                  }
                },
              },
              {
                key: "action",
                header: "",
                render: (val) => {
                  return (
                    <div className="row mx-0">
                      {/* <FontAwesomeIcon
                        className="view-icon"
                        color="white"
                        icon={["fas", "file-download"]}
                        onClick={() => this.handleOrderView(val)}
                      /> */}
                      {val.bookingId ? (
                        <FontAwesomeIcon
                          className="view-icon ml-1"
                          color="white"
                          icon={["fas", "eye"]}
                          onClick={() => this.handleOrderView(val)}
                        />
                      ) : null}
                    </div>
                  );
                },
              },
            ]}
            childSchema={{
              title: i18n.t("products"),
              columns: [
                {
                  key: "name",
                  accessor: (val) =>
                    getNestedValue(val, ["productId", "name"], ""),
                },
                { key: "quantity" },
                {
                  key: "status",
                  accessor: (val) => i18n.t(val.reserved ? "paid" : "pending"),
                },
              ],
            }}
          />
        </div>
      </>
    );
  };

  renderPackagesTable = () => {
    const { collectPaymentModalOpen, packageToCollect, access_token } =
      this.state;
    const { soldPackages = [] } = this.props;
    return (
      <>
        <div className="col-12 p-3">
          <h1 className="saloon-dashboard-heading">
            <Translation>{(t) => t("container.sold_packages")}</Translation>
          </h1>
        </div>
        <div
          className="table-responsive sticky-header half"
          style={{ borderRadius: "1rem", border: "1px solid #E0E2E4" }}
        >
          <CollapsibleTable
            rows={soldPackages}
            childKey="services"
            schema={[
              {
                key: "name",
                accessor: (val) =>
                  getNestedValue(val, ["userId", "firstName"], "") ||
                  getNestedValue(val, ["userId", "userName"], ""),
              },
              {
                key: "container.profile_email",
                accessor: (val) => getNestedValue(val, ["userId", "email"], ""),
              },
              {
                key: "phoneNo",
                accessor: (val) =>
                  getNestedValue(val, ["userId", "phoneNo"], ""),
              },
              {
                key: "package",
                accessor: (val) =>
                  getNestedValue(val, ["packageId", "name"], ""),
              },
              {
                key: "numberOfSessions",
                accessor: (val) =>
                  val.numberOfSessions -
                  val.sessionBooked -
                  val.sessionCompleted,
              },
              {
                key: "purchased_date",
                accessor: (val) =>
                  moment(val.createdDate).format("MM-DD-YYYY hh:mm A"),
              },
              {
                key: "balance_due",
                accessor: (val) => val.balanceDue.toFixed(2),
              },
              {
                key: "expiryDate",
                accessor: (val) =>
                  moment(val.expiryDate).format("MM-DD-YYYY hh:mm A"),
              },
              {
                key: "expiryDate",
                accessor: (val) =>
                  moment(val.expiryDate).format("MM-DD-YYYY hh:mm A"),
              },
              {
                key: "action",
                header: "",
                style: { width: 60 },
                render: (val) => {
                  return (
                    <div className="row mx-0">
                      <FontAwesomeIcon
                        className="view-icon"
                        style={{ backgroundColor: "green" }}
                        color="white"
                        icon={["fas", "hand-holding-usd"]}
                        onClick={() => {
                          this.setState({
                            packageToCollect: val,
                            collectPaymentModalOpen: true,
                          });
                        }}
                      />
                    </div>
                  );
                },
              },
            ]}
            childSchema={{
              title: i18n.t("services"),
              columns: [
                {
                  key: "name",
                  accessor: (val) =>
                    getNestedValue(val, ["serviceId", "name"], ""),
                },
                {
                  key: "action",
                  header: "",
                  render: (val, row) => {
                    const available =
                      row.numberOfSessions -
                      row.sessionBooked -
                      row.sessionCompleted;
                    if (available > 0) {
                      return (
                        <PrimaryButton
                          label={i18n.t("redeem")}
                          onClick={() => {
                            this.props.history.push("/saloon-my-appointment");
                          }}
                        />
                      );
                    }
                  },
                },
              ],
            }}
          />
        </div>
        {collectPaymentModalOpen ? (
          <CollectPaymentModal
            open={collectPaymentModalOpen}
            setOpen={() => {
              this.setState({
                collectPaymentModalOpen: false,
                packageToCollect: {},
              });
            }}
            userPackage={packageToCollect}
            accessToken={access_token}
            onSuccess={() => {}}
          />
        ) : null}
      </>
    );
  };

  render() {
    return (
      <div>
        {this.renderLoader()}

        <div className="content-container">
          {this.renderTable()}
          {this.renderPackagesTable()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  orders: getNestedValue(state.orders, "data", []).map((element) => ({
    ...element,
    children: element.products,
  })),
  soldPackages: state.packages.soldPackages,
  isLoading: state.orders.isFetching || state.packages.isFetching,
});

const action = {
  getOrders,
  getSoldPackageRequest,
  set,
};

export default connect(mapStateToProps, action)(Orders);
