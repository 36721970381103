import { connect } from "react-redux";
import React, { Component } from "react";
import { SidebarContent, Footer } from "../../../components/Saloon";
import { DotsLoader, Header } from "../../../components";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import { Images } from "../../../theme";
import { Translation } from "react-i18next";
import { SuccessHelper, ErrorHelper } from "../../../helpers";
import { updateSite, getSaloonById } from "../../../config/simpleApiCalls";
import "./styles.css";

import i18n from "../../../i18n";

class SiteSetUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      urlError: "",
      websiteUrl: "",
    };
  }

  async componentDidMount() {
    const saloonData = await JSON.parse(sessionStorage.getItem("saloon"));
    const companyData = await JSON.parse(sessionStorage.getItem("company"));
    this.setState({ isloading: true });
    getSaloonById(saloonData.access_token)
      .then((res) => {
        if (res.data.success) {
          const { previewImageUrl, websiteUrl, _id } = res.data.data;
          this.setState({
            previewImageUrl: `${previewImageUrl?.url}`,
            websiteUrl,
            companyId: _id,
          });
        }
      })
      .finally(() => {
        this.setState({ isloading: false });
      });
    if (saloonData && companyData) {
      this.setState({ access_token: saloonData.access_token });
    }
  }

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  handleSubmit = () => {
    const { access_token, previewImageUrl, imageFile, websiteUrl } = this.state;
    this.setState({ isloading: false });
    if (
      !websiteUrl ||
      websiteUrl.match(
        "^(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$"
      )
    ) {
      let payloadFormdata = new FormData();
      payloadFormdata.append("websiteUrl", websiteUrl);
      if (imageFile) {
        payloadFormdata.append("image", imageFile);
      }
      updateSite(payloadFormdata, access_token)
        .then(({ data }) => {
          if (data.success) {
            SuccessHelper.handleSuccess(
              "Successfully saved site changes",
              true
            );
          } else {
            ErrorHelper.handleErrors("Failed to save site changes", true);
          }
          this.setState({ isloading: false });
        })
        .catch(() => {
          ErrorHelper.handleErrors("An Error Occured", true);
          this.setState({ isloading: false });
        });
    }
  };

  onUrlChange = (e) => {
    const url = e.target.value;
    if (url) {
      if (
        !url.match(
          "^(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$"
        )
      ) {
        this.setState({ urlError: i18n.t("invalid_url") });
      } else {
        this.setState({ urlError: "" });
      }
    }

    this.setState({ websiteUrl: e.target.value });
  };

  renderTemplates = () => {
    const { templateList, activeTemplateId, urlError, companyId } = this.state;
    return (
      <div className="row mx-0">
        <div className="form-label-group mb-4 col-12">
          <label for="inputusername">
            <div>{i18n.t("booking_link")}</div>
            <div>{i18n.t("booking_link_description")}</div>
          </label>

          <input
            type="text"
            className="form-control mt-1"
            readOnly={true}
            value={`book.easy1booking.com/${companyId}`}
          />
          {urlError && (
            <small className="form-text text-danger ml-3">{urlError}</small>
          )}
        </div>
        <div className="form-label-group mb-4 col-12">
          <label for="inputusername">
            {i18n.t("container.your_website_url")}
          </label>
          <input
            type="text"
            id="inputusername"
            className="form-control mt-1"
            placeholder={i18n.t("container.your_website_url_placeholder")}
            onChange={this.onUrlChange}
            value={this.state.websiteUrl}
          />
          {urlError && (
            <small className="form-text text-danger ml-3">{urlError}</small>
          )}
        </div>
        <div className="form-label-group mb-4 col-12">
          {this.renderUploadFileCard()}
        </div>
        <div className="col-12 px-2 text-right">
          <button
            type="button"
            onClick={() => this.handleSubmit()}
            className="btn btn-light btn-sm mr-1"
          >
            Save
          </button>
        </div>
      </div>
    );
  };

  fileSelectedHandler = (event) => {
    const imageUrlRegex = /([a-zA-Z0-9\s_\\.\-:])+(.png|.jpeg|.jpg|.gif)$/gm;
    console.log(event.target.files, "event");
    if (event.target.files[0]) {
      let checkFile = event.target.files[0].type.split("/");
      let isValidateType = event.target.files[0].type;
      if (checkFile[0] === "image") {
        if (isValidateType.match(imageUrlRegex)) {
          const file = URL.createObjectURL(event.target.files[0]);
          this.setState({
            mediaType: event.target.files[0].type.split("/")[0],
            previewImageUrl: file,
            imageFile: event.target.files[0],
            imageName: event.target.files[0].name,
            imageTitle: event.target.files[0].name,
            imageDescription: event.target.files[0].name,
          });
        } else {
          ErrorHelper.handleErrors("Image Format not supported", true);
        }
      }
    }
  };

  renderUploadFileCard = () => {
    const { imageUrl, previewImageUrl } = this.state;
    console.log(previewImageUrl);
    return (
      <div className="col-12 px-0">
        <div
          className="col-12 bg-white py-2 p-md-3"
          style={{ border: "1px solid #E0E2E4", borderRadius: "1rem" }}
        >
          <div className="col-12 px-0">
            <p id="upload-file-text">{i18n.t("container.preview_picture")}</p>
            <input
              type="file"
              style={{ display: "none" }}
              onChange={this.fileSelectedHandler}
              ref={(fileInput) => (this.fileInput = fileInput)}
              accept="image/*"
            />
            <div className="col-12 row mx-0 px-0">
              {previewImageUrl && (
                <>
                  <div className="col-12 px-2 mt-4 justify-content-center">
                    <h4>Current Image</h4>
                  </div>
                  <div className="col-12 p-3 mt-2 justify-content-center text-center preview-image-wrapper">
                    <img src={previewImageUrl} style={{ height: 170 }} />
                  </div>
                </>
              )}
              <div className="col-12 px-2 mt-4 justify-content-center">
                <h4>Upload New Image</h4>
              </div>
              <div
                className="col-12 px-2 mt-2"
                id="upload-box"
                onClick={() => this.fileInput.click()}
              >
                <img
                  src={Images.saloon_upload_media_icon}
                  style={{ width: 120 }}
                />
                <p id="drop-file-text">
                  {i18n.t("container.dashboard_drop_files")}{" "}
                  <span style={{ color: "#84B2F9" }}>
                    <u>{i18n.t("container.dashboard_browse")}</u>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  renderSaloonDashboardHeading = () => {
    return (
      <div className="col-12 px-3 pt-3">
        <div className="row">
          <div className="col-md-8 col-lg-9 d-flex flex-column justify-content-center">
            <h1 className="saloon-dashboard-heading">
              {i18n.t("container.site_set_up")}
            </h1>
            {/* <p className="saloon-dashboard-sub-heading">
              Lorem ipsum dolor sit amet, consectetur
            </p> */}
          </div>
        </div>
      </div>
    );
  };

  onSetSidebarOpen = (open) => {
    this.setState({ sidebarOpen: open });
  };

  render() {
    return (
      <div>
        {this.renderLoader()}
        <div className="container">
          <div className="row mx-0 overflow-auto">
            {this.renderSaloonDashboardHeading()}
            <div
              className="col-12 p-3 mt-4 bg-white mb-4"
              style={{ borderRadius: "1rem" }}
            >
              {/* {this.TemplateOptionheader()} */}
              {this.renderTemplates()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(SiteSetUp);
