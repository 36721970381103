import React, { Component } from "react";
import { connect } from "react-redux";
import { logout as user_logout } from "../../redux/actions/Login";
import { set } from "../../redux/actions/User";
import { clear } from "../../redux/actions/ActiveBooking";
import moment from "moment";
import firebase from "../../dataBase/firebase";
import {
  getAllSaloonsForEmployee,
  getAllEmployee,
} from "../../config/simpleApiCalls";
import "./chatBoxstyles.sass";
import { firestore } from "firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { Images } from "../../theme/index";
import { SuccessHelper } from "../../helpers";

// components
import SearchPage from "../SimpleSearch/SearchPage";
import AdminChat from "./components/admin.component";
import CreateRoom from "./components/CreateRoom.component";
import SendMessage from "./components/SendMessage.component";

const fireStore = firebase.firestore();

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {}, // the current signed in user
      saloonList: {}, // list of all saloons - used to search saloons and select salon as chat participants
      employeeList: {}, // list of all saloons - used to search saloons and select salon as chat participants
      messageToSend: "", // current message to send
      placeHolder: "Add Saloon to Conversation", // search box placeholder
      newParticipants: {}, // use to add participants to a chatroom
      roomsStructure: null, // use to display rooms in UI
      currentRoomID: "", //  the current selected room by user
      allUserMessages: null, // all messages of the current room
      messagesStructure: null,
      toggleHideShow: "hideSearch", // to handle showing search bar, the default is to hide the bar
      roomsMap: null,
      adminID: "5fb7eab0c1f01f001768587d",
      roomUsers: new Set(),
      messagePlaceHolder: (
        <div className="messagrePlaceHolder">
          Please select a conversation to start chatting or contact Admin in
          case you need help.
        </div>
      ),
      noMessagePlaceHolder: (
        <div className="messagrePlaceHolder">
          Please select a conversation to start chatting or contact Admin in
          case you need help.
        </div>
      ),
    };

    this.sendMessageRef = React.createRef();
    this.messagesEnd = React.createRef();
    this.messagesTop = React.createRef();
    this.handleParticipants = this.handleParticipants.bind(this);
    this.displayRooms = this.displayRooms.bind(this);
    this.fetchRoomMessages = this.fetchRoomMessages.bind(this);
    this.renderRoomMessages = this.renderRoomMessages.bind(this);
    this.contactAdmin = this.contactAdmin.bind(this);
    this.newSaloonChat = this.newSaloonChat.bind(this);
    this.newEmployeeChat = this.newEmployeeChat.bind(this);
    this.addDefaultSrc = this.addDefaultSrc.bind(this);
    this.renderSmallNavbar = this.renderSmallNavbar.bind(this);
    this.handleNavigation = this.handleNavigation.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.setAnchor = this.setAnchor.bind(this);
    this.notificationIcon = React.createRef();
  }

  // as the component loads we do the ff:
  // get the user from sessionStorage
  // calls backend to get the list of employees and saloons
  // sets the state with user, saloons and employees

  async componentDidMount() {
    // gets the current user from session storage and set the needed information to state

    const userPath = this.props.history.location.pathname;
    let user = await JSON.parse(sessionStorage.getItem("user"));

    if (userPath == "/employee-chat") {
      user = await JSON.parse(sessionStorage.getItem("employee"));
      if (this.props.location.state) {
        await this.setUserData(user);
        await this.getAllRooms();
        this.newEmployeeChat();
      } else {
        await this.setUserData(user);
        await this.getAllRooms();
      }
    } else if (userPath == "/saloon-chat") {
      user = await JSON.parse(sessionStorage.getItem("saloon"));
      if (this.props.location.state) {
        await this.setUserData(user);
        await this.getAllRooms();
        this.newSaloonChat();
      } else {
        await this.setUserData(user);
        await this.getAllRooms();
      }
    } else if (userPath == "/user-profile-chat") {
      await this.setUserData(user);
      await this.getAllRooms();
    } else if (userPath == "/chat-test") {
      if (this.props.location.state) {
        await this.setUserData(user);
        await this.getAllRooms();
        this.newEmployeeChat();
      } else {
        await this.setUserData(user);
        await this.getAllRooms();
      }
    } else {
      await this.setUserData(user);
      await this.getAllRooms();
      this.newEmployeeChat();
    }
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: "smooth" });
  };

  setUserData = async (userData) => {
    if (userData && userData.access_token) {
      // fetching the list of saloons and employees from backend

      // commented out to prevent long fetching waiting time - decomment for production
      // these two functions are to get saloons a and employees for searchBox feature
      // Add employeeList to set state at the end of this function

      // let emps = await getAllEmployee();
      // let employees = emps.data.data;
      // let employeeList = [];

      // employees.map((employee) => {
      //   employee = employee.user;
      //   if (employee) {
      //     let employeesData = {};

      //     employeesData.employeeName = `${employee.firstName} ${employee.lastName}`;
      //     employeesData._id = employee._id;
      //     employeeList.push(employeesData);
      //   }
      // });

      // let saloons = await getAllSaloonsForEmployee();
      // saloons = saloons.data.saloons;
      // let saloonList = [];
      // saloons.map((saloon) => {

      //   let saloonData = {};

      //   saloonData.saloonName = saloon.name;
      //   saloonData._id = saloon._id;
      //   saloonData.role = 1;
      //   saloonList.push(saloonData);
      // });

      return new Promise((resolve) => {
        this.setState({ user: userData }, resolve);
      });
    }
  };

  // sends message to database
  sendMessageToDb = async () => {
    const { user, messageToSend, currentRoomID } = this.state;
    this.sendMessageRef.current.value = "";
    let data = {
      createdAt: firestore.Timestamp.now(),
      type: "text",
      msg: messageToSend,
      senderID: user._id,
    };
    try {
      await fireStore
        .collection("rooms")
        .doc(currentRoomID)
        .collection("messages")
        .add(data);
      this.setState({ messageToSend: "" });
    } catch (error) {
      console.log("can't send message", error);
    }
  };

  // create a room which has the user as the first participant
  createRoom = async () => {
    // state destructuring
    const { user } = this.state;

    // creating participant field of database by initiating the user as the first participant of the room
    let participantfields = {
      participantName: user.firstName,
      role: user.role,
    };
    let participant = { [user._id]: participantfields };

    // the data that the database gets generated by
    let data = {
      createdAt: firestore.Timestamp.now(),
      createdBy: user._id,
      participants: participant,
    };
    try {
      console.log(fireStore.collection("rooms").add(data));
      console.log("hello");
    } catch (error) {
      console.log("can't create room", error);
    }
  };

  // to message admin
  contactAdmin = async (e) => {
    // state destructuring
    const { user, adminID, currentRoomID } = this.state;
    let roomID = user._id + adminID;
    var docRef = fireStore.collection("rooms").doc(roomID);

    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          this.setState({ currentRoomID: roomID });
          this.fetchRoomMessages(e, roomID);
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });

    // creating participant field of database by initiating the user as the first participant of the room
    let participantfields = {
      participantName: user.firstName,
      role: user.role,
    };
    let participant = { [user._id]: participantfields };

    participantfields = {
      participantName: "admin",
      role: 1,
    };

    participant[adminID] = participantfields;

    // the data that the database gets generated by
    let data = {
      createdAt: firestore.Timestamp.now(),
      createdBy: user._id,
      participants: participant,
    };
    console.log(data);
    try {
      fireStore.collection("rooms").doc(roomID).set(data, { merge: true });
    } catch (error) {
      console.log("can't create room", error);
    }
  };

  // this function get calls in compounentdidmount to get all rooms that the
  // user is a participant and show them in UI dynamically
  getAllRooms = async () => {
    const { user } = this.state;
    let query = `participants.${user._id}`;

    return new Promise((resolve) => {
      let rooms = fireStore.collection("rooms").where(query, "!=", null);
      rooms.onSnapshot((room) => {
        let allRooms = [];
        room.forEach(function (doc) {
          allRooms.push({ ...doc.data(), _id: doc.id });
        });
        allRooms = allRooms.sort((a, b) => a.createdAt - b.createdAt);

        let roomsMap = {};
        allRooms.map((r) => {
          roomsMap[r._id] = r.participants;
        });
        this.setState({ allRooms, roomsMap }, resolve);

        this.displayRooms();
      });
    });
  };

  // by clicking on searched user's name, user's information pass to this function
  // the function creates a new object with participant's information and add it to
  // the room participants
  // participant = object

  handleParticipants = (participant) => {
    const { currentRoomID } = this.state;
    let participantFields = {
      participantName: participant.saloonName,
      role: participant.role,
    };

    fireStore
      .collection("rooms")
      .doc(currentRoomID)
      .update({ [`participants.${participant._id}`]: participantFields });
  };

  // this function calls within "getAllRooms" function to dynamically
  // display the list of user's chatrooms in UI
  displayRooms = () => {
    let { roomsStructure, allRooms, roomUsers, user } = this.state;
    let roomMembersForSearch = new Set();
    roomsStructure = allRooms.map((room) => {
      let roomP = [];
      let secondKey = Object.keys(room.participants)[1];

      return (
        <div
          onClick={(e) => {
            this.fetchRoomMessages(e);
          }}
          id={room._id}
          className="chatRoomItem"
        >
          {console.log("secopnd", secondKey)}
          {console.log("Asdsadsad", user)}
          <span>
            {secondKey == user._id ? (
              <img
                className="roomIMG"
                onError={this.addDefaultSrc}
                src={Images.user_profile_image}
              />
            ) : (
              <img
                className="roomIMG"
                onError={this.addDefaultSrc}
                src={room.participants[secondKey]?.participantIMG}
              />
            )}
          </span>
          <span className="chatList">
            {Object.keys(room.participants).map((r) => {
              roomP.push(room.participants[r].participantName);
              roomMembersForSearch.add(room.participants[r].participantName);
            })}

            {roomP.join(",")}
          </span>
        </div>
      );
    });
    var merged = new Set([...roomMembersForSearch, ...roomUsers]);
    this.setState({ roomsStructure, roomUsers: merged });
  };

  // e => the click event to prevent default
  // roomID is the current selected room and the function use it to fetch room's messages
  // returns all messages belonging to the selected rooms and save it in allUserMessages field in the state

  fetchRoomMessages = async (e, roomID) => {
    if (e) {
      e.preventDefault();
      roomID = e.target.id;
    }

    this.setState({
      currentRoomID: roomID,
      toggleHideShow: "showSearch",
      roomIsSelected: "roomSelected",
    });

    const { user } = this.state;
    if (!user || !roomID) return;

    try {
      let recievedMessages = fireStore
        .collection("rooms")
        .doc(roomID)
        .collection("messages");

      recievedMessages.onSnapshot((messages) => {
        let allUserMessages = [];
        messages.forEach((userMessage) => {
          allUserMessages.push(userMessage.data());
        });

        allUserMessages = allUserMessages.sort(
          (a, b) => a.createdAt - b.createdAt
        );

        console.log("allUserMessages", allUserMessages);
        this.renderRoomMessages(allUserMessages);
        // this.setState({ allUserMessages });
      });
    } catch (error) {
      console.log("could not recieve messages", error);
    }
  };

  // renders message date
  renderDate = (d) => {
    let res = moment(d.toDate()).fromNow();
    return res;
  };

  // get all user messages and render the messages
  renderRoomMessages = (allUserMessages) => {
    const { user, roomsMap, currentRoomID } = this.state;

    let currentRoomParticipants = roomsMap[currentRoomID];

    let messagesStructure = allUserMessages.map((message) => {
      let res = "";

      if (message.senderID == user._id) {
        res = (
          <div className="conversationMessage sentMessage">
            <p className="messageInfo">
              {currentRoomParticipants[message.senderID].participantName} -{" "}
              {this.renderDate(message.createdAt)}
            </p>
            <p className="displayedMessageContent">{message.msg}</p>
          </div>
        );
      } else {
        res = (
          <div className="conversationMessage recivedMessage">
            <p className="messageInfo">
              {currentRoomParticipants[message.senderID].participantName} -{" "}
              {this.renderDate(message.createdAt)}
            </p>
            <p className="displayedMessageContent">{message.msg}</p>
          </div>
        );
      }

      return res;
    });

    this.setState({ messagesStructure, testNamess: "displayRoom" });
  };
  newEmployeeChat = () => {
    const { allRooms, user } = this.state;

    let foundRoom = null;

    if (allRooms) {
      let done = true;

      allRooms.forEach((room) => {
        let numberOfParticipants = Object.keys(room.participants).length;
        let includesSalon = room.participants.hasOwnProperty(
          this.props.location.state._id
        );
        let incloudesUser = room.participants.hasOwnProperty(
          this.state.user._id
        );
        if (numberOfParticipants == 2 && done) {
          if (includesSalon & incloudesUser) {
            done = false;
            foundRoom = room;
          }
        }
      });
    }
    if (foundRoom) {
      this.fetchRoomMessages("", foundRoom._id);
    } else if (this.props.location.state) {
      // creating participant field of database by initiating the user as the first participant of the room

      let participantfields = {
        participantName: user.firstName,
        _id: user._id,
        role: user.role,
      };
      let participant = { [user._id]: participantfields };

      participantfields = {
        participantName: this.props.location.state.name,
        participantIMG: !!this.props.location.state.imgURL
          ? this.props.location.state.imgURL
          : "",
        _id: this.props.location.state._id,
        role: "1",
      };
      participant[this.props.location?.state?._id] = participantfields;
      // the data that the database gets generated by
      let data = {
        createdAt: firestore.Timestamp.now(),
        createdBy: user._id,
        participants: participant,
      };
      console.log("data", data);

      try {
        fireStore.collection("rooms").add(data);
      } catch (error) {
        console.log("can't create room", error);
      }
    }
  };

  addDefaultSrc(ev) {
    ev.target.src = Images.user_profile_image;
  }
  newSaloonChat = () => {
    const { allRooms, user } = this.state;

    let foundRoom = null;

    if (allRooms) {
      let done = true;

      allRooms.forEach((room) => {
        let numberOfParticipants = Object.keys(room.participants).length;
        let includesSalon = room.participants.hasOwnProperty(
          this.props.location.state._id
        ); //   let saloonData = {};

        //   saloonData.saloonName = saloon.name;
        //   saloonData._id = saloon._id;
        //   saloonData.role = 1;
        //   saloonList.push(saloonData);
        // });
        let incloudesUser = room.participants.hasOwnProperty(
          this.state.user._id
        );
        if (numberOfParticipants == 2 && done) {
          if (includesSalon & incloudesUser) {
            done = false;
            foundRoom = room;
          }
        }
      });
      console.log("yek");
    }
    if (foundRoom) {
      this.fetchRoomMessages("", foundRoom._id);
      console.log("do");
    } else if (this.props.location.state) {
      // creating participant field of database by initiating the user as the first participant of the room

      let participantfields = {
        participantName: user.firstName,
        _id: user._id,
        role: user.role,
      };
      let participant = { [user._id]: participantfields };

      participantfields = {
        participantName: this.props.location.state.name,
        participantIMG: !!this.props.location.state.imgURL
          ? this.props.location.state.imgURL
          : "",
        _id: this.props.location.state._id,
        role: "1",
      };

      participant[this.props.location?.state?._id] = participantfields;
      // the data that the database gets generated by
      let data = {
        createdAt: firestore.Timestamp.now(),
        createdBy: user._id,
        participants: participant,
      };
      try {
        fireStore
          .collection("rooms")
          .add(data)
          .then((docRef) => {
            this.fetchRoomMessages("", docRef.id);
          });
        console.log("done");
      } catch (error) {
        console.log("can't create room", error);
      }
    }
  };
  handleNavigation = (route) => {
    this.props.history.push(route);
  };
  handleLogout = () => {
    this.props.user_logout();
    SuccessHelper.handleSuccess("You have successfully logged out.", true);
    this.setState({ user: null });
    this.handleNavigation("/");
  };
  setAnchor = (e) => {
    if (e) {
      this.setState({ anchor: this.notificationIcon.current });
    } else {
      this.setState({ anchor: null });
    }
  };
  renderSmallNavbar = () => {
    const { notifications } = this.props;
    const { showSidebar, userEmail, user } = this.state;
    return (
      <div id="small-navbar" className="header px-2">
        <div className="py-2 px-2 d-flex align-items-center justify-content-between">
          <img
            alt="easy1 logo 800x300"
            src={Images.easy1_logo_800x300}
            className="logo cursor-pointer"
            onClick={() => this.handleNavigation("/")}
          />
          <div className="row mx-0 align-items-center">
            <span
              style={{ cursor: "pointer" }}
              className="mr-1"
              onClick={this.setAnchor}
              ref={this.notificationIcon}
            >
              <FontAwesomeIcon
                color="#A2A2A2"
                icon={["fas", "bell"]}
                style={{ width: 20, height: 20 }}
              />
              {notifications.filter((notification) => notification.status == 1)
                .length ? (
                <span className="badge" />
              ) : null}
            </span>
            <span
              className="d-flex align-items-center"
              onClick={() =>
                this.setState({ showSidebar: showSidebar ? false : true })
              }
            >
              <div className="pr-2" onClick={this.handleLogout}>
                <p
                  style={{
                    color: "#8E8E8E",
                    fontSize: "18px",
                    cursor: "pointer",
                  }}
                >
                  Logout
                </p>
              </div>
            </span>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      roomsStructure,
      messagesStructure,
      toggleHideShow,
      user,
      roomsMap,
      adminID,
      allRooms,
      messagePlaceHolder,
    } = this.state;

    return (
      <div>
        {this.renderSmallNavbar()}
        <div className="containsChatBox">
          <div className="chatBoxTitle">Chat</div>
          <div className="chatContainer">
            <div className="chatPanel">
              <div className="rooms">
                {/* <AdminChat onClick={() => this.contactAdmin()} />
                <CreateRoom onClick={() => this.createRoom()} /> */}
                <div className="userChatSection">
                  <div className="chatAvatar">
                    <img
                      className="userProfileImg"
                      src={user.profile_img}
                      onError={this.addDefaultSrc}
                    />
                  </div>
                  <span>
                    {user.firstName} {user.lastName}
                  </span>
                </div>
                <div className="chatButtons">
                  <span>Contact Admin</span>
                  <FontAwesomeIcon
                    onClick={() => this.contactAdmin()}
                    size="1x"
                    icon={faPlusCircle}
                    className="contactAdmin"
                  />
                </div>

                {roomsStructure}
              </div>
            </div>
            <div className="chatRoom">
              <div className="conversationBox">
                {/* <SearchPage
                toggleSearch={toggleHideShow}
                saloons={this.state.saloonList}
                addParticipantToMessageState={(idd) =>
                  this.handleParticipants(idd)
                }
              /> */}

                {messagesStructure === null
                  ? messagePlaceHolder
                  : messagesStructure}

                <div
                  style={{ float: "left", clear: "both" }}
                  ref={(el) => {
                    this.messagesEnd = el;
                  }}
                ></div>
              </div>

              <SendMessage
                inputRef={this.sendMessageRef}
                toggleAppearance={toggleHideShow}
                onChange={(e) =>
                  this.setState({ messageToSend: e.target.value })
                }
                onClick={this.sendMessageToDb}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  logout: state.logout,
  notifications: [...state.getNotifications.data],
  booking: state.activeBooking.data,
});

const action = { user_logout, clear, set };
export default connect(mapStateToProps, action)(Chat);
