import { connect } from "react-redux";
import React, { Component } from "react";
import { Translation } from "react-i18next";
import i18n from "../../../i18n";
import axios from "axios";
import { Images } from "../../../theme";
import {
  getEmployeeSocialLinks,
  UpdateEmployeeSocialMediaLink,
  createEmployeeSocialMediaLink,
} from "../../../config/simpleApiCalls";
import Swal from "sweetalert2";

import { DotsLoader } from "../../../components";
// import { adminGetUsers } from '../../../config/simpleApiCalls';
import { SuccessHelper, ErrorHelper } from "../../../helpers";
import { request as create_social_media } from "../../../redux/actions/Saloon/CreateSaloonSocialMedia";

import "./styles.css";
import Sytles from "./sytles";

class LinkSocialMedia extends Component {
  constructor(props) {
    super(props);
    this.state = {
      facebookLink: "",
      instagramLink: "",
      twitterLink: "",
      youtubeLink: "",
      snapchatLink: "",
      linkedinLink: "",
      // facebookLink: 'https://www.facebook.com/test-user-91',
      // instagramLink: 'https://www.instagram.com/test-user-91',
      // twitterLink: 'https://twitter.com/test-user-91',
      // youtubeLink: 'https://www.youtube.com/test-user-91',
      // snapchatLink: 'https://www.snapchat.com/test-user-91',
      // linkedinLink: 'https://www.linkedin.com/test-user-91',
      hasLink: false,
      access_token: "",
      isloading: false,
    };
  }

  componentDidMount = async () => {
    const employeeData = await JSON.parse(sessionStorage.getItem("employee"));

    if (employeeData) {
      this.setState({
        access_token: employeeData.access_token,
        employeeId: employeeData._id,
      });
      this.getAllSocialMedia();
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.createSaloonSocialMedia) {
      console.log(
        nextProps.createSaloonSocialMedia,
        "nextProps.createSaloonSocialMedia"
      );
      if (
        !nextProps.createSaloonSocialMedia.failure &&
        !nextProps.createSaloonSocialMedia.isFetching &&
        !nextProps.createSaloonSocialMedia.errorMessage &&
        nextProps.createSaloonSocialMedia.data &&
        nextProps.createSaloonSocialMedia.data.data &&
        nextProps.createSaloonSocialMedia.data.success
      ) {
        this.getAllSocialMedia();
        SuccessHelper.handleSuccess("You have successfully added.", true);
      } else if (
        nextProps.createSaloonSocialMedia.failure &&
        !nextProps.createSaloonSocialMedia.isFetching &&
        nextProps.createSaloonSocialMedia.errorMessage
      ) {
        this.setState({ isloading: false });
      }
    }
  }

  getAllSocialMedia = () => {
    this.setState({ isloading: true });
    const { access_token } = this.state;
    getEmployeeSocialLinks(access_token)
      .then((res) => {
        console.log("LinkSocialMedia -> getAllSocialMedia -> res", res);
        const linkMap = {};
        res.data.data.socialURL.map((socialUrl) => {
          linkMap[socialUrl.socialType] = socialUrl.url;
        });
        if (res.data.success && res.data.data) {
          this.setState({
            isloading: false,
            hasLink: true,
            facebookLink: linkMap.facebook,
            instagramLink: linkMap.instagram,
            twitterLink: linkMap.twitter,
            youtubeLink: linkMap.youtube,
            snapchatLink: linkMap.snapchat,
            linkedinLink: linkMap.linkedin,
          });
        } else {
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: error,
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#ff3600",
          cancelButtonColor: "#354e68",
          confirmButtonText: "Yes, delete it!",
        });
        console.log(error, "errorrrrrrrrrrrrrrrrrrr");
      });
  };

  onCreateSocialMedia = async () => {
    this.setState({ isloading: true });
    const {
      access_token,
      facebookLink,
      instagramLink,
      twitterLink,
      youtubeLink,
      linkedinLink,
      snapchatLink,
      employeeId,
    } = this.state;
    const payload = {
      socialURL: [],
      employeeId,
    };
    if (facebookLink)
      payload.socialURL.push({ socialType: "facebook", url: facebookLink });
    if (twitterLink)
      payload.socialURL.push({ socialType: "twitter", url: twitterLink });
    if (snapchatLink)
      payload.socialURL.push({ socialType: "snapchat", url: snapchatLink });
    if (instagramLink)
      payload.socialURL.push({ socialType: "instagram", url: instagramLink });
    if (youtubeLink)
      payload.socialURL.push({ socialType: "youtube", url: youtubeLink });
    if (linkedinLink)
      payload.socialURL.push({ socialType: "linkedin", url: linkedinLink });
    // this.props.create_social_media(payload)
    createEmployeeSocialMediaLink(payload, access_token)
      .then((res) => {
        if (res.data.success) {
          this.getAllSocialMedia();
        } else {
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
        alert(error?.msg || error?.data?.msg);
        console.log(error, "errorrrrrrrrrrrrrrrrrrr");
      });
  };

  onUpdateSocialMedia = async () => {
    this.setState({ isloading: true });
    const {
      access_token,
      facebookLink,
      instagramLink,
      twitterLink,
      youtubeLink,
      linkedinLink,
      snapchatLink,
    } = this.state;
    const payload = {
      socialURL: [],
    };
    if (facebookLink)
      payload.socialURL.push({ socialType: "facebook", url: facebookLink });
    if (twitterLink)
      payload.socialURL.push({ socialType: "twitter", url: twitterLink });
    if (snapchatLink)
      payload.socialURL.push({ socialType: "snapchat", url: snapchatLink });
    if (instagramLink)
      payload.socialURL.push({ socialType: "instagram", url: instagramLink });
    if (youtubeLink)
      payload.socialURL.push({ socialType: "youtube", url: youtubeLink });
    if (linkedinLink)
      payload.socialURL.push({ socialType: "linkedin", url: linkedinLink });
    UpdateEmployeeSocialMediaLink(payload, access_token)
      .then((res) => {
        if (res.data.success) {
          this.getAllSocialMedia();
        } else {
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });

        alert(error?.msg || error?.data?.msg);
        console.log(error, "errorrrrrrrrrrrrrrrrrrr");
      });
  };

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  onChangeFacebook = (text) =>
    this.setState({ facebookLink: text.target.value });
  onChangeInstagram = (text) =>
    this.setState({ instagramLink: text.target.value });
  onChangeTwitter = (text) => this.setState({ twitterLink: text.target.value });
  onChangeYoutube = (text) => this.setState({ youtubeLink: text.target.value });
  onChangeSnapchat = (text) =>
    this.setState({ snapchatLink: text.target.value });
  onChangeLinkedin = (text) =>
    this.setState({ linkedinLink: text.target.value });

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  renderSaloonDashboardHeading = () => {
    return (
      <div className="col-12 p-3">
        <h1 className="saloon-dashboard-heading">
          {i18n.t("container.dashboard_social_media_icons")}:
        </h1>
        {/* <p className="saloon-dashboard-sub-heading">Lorem ipsum dolor sit amet, consectetur</p> */}
      </div>
    );
  };

  renderInputCol6 = (socialIcon, inputType, placeholder, onChange, value) => {
    return (
      <div class="form-group col-md-6 px-0 px-md-3 d-flex align-items-center">
        <img class="mr-2 social-media-icons" src={socialIcon} />
        <input
          value={value}
          type={inputType}
          placeholder={placeholder}
          class="form-control saloon-link-social-input"
          onChange={(text) => onChange(text)}
        />
      </div>
    );
  };

  renderSocialLinkCard = () => {
    const {
      hasLink,
      facebookLink,
      twitterLink,
      instagramLink,
      youtubeLink,
      snapchatLink,
      linkedinLink,
    } = this.state;
    return (
      <div className="col-12 p-3">
        <div className="col-12 p-3 bg-white" style={{ borderRadius: "1rem" }}>
          <div className="col-12 px-0 px-md-3">
            <p id="social-media-text" className="mb-3">
              {i18n.t("container.dashboard_social_media_icons")}
            </p>
          </div>
          <div class="form-row mb-3">
            {this.renderInputCol6(
              Images.saloon_link_social_facebook,
              "text",
              "https://www.facebook.com/",
              this.onChangeFacebook,
              facebookLink
            )}
            {this.renderInputCol6(
              Images.saloon_link_social_instagram,
              "text",
              "https://www.instagram.com/",
              this.onChangeInstagram,
              instagramLink
            )}
            {this.renderInputCol6(
              Images.saloon_link_social_twitter,
              "text",
              "https://twitter.com/",
              this.onChangeTwitter,
              twitterLink
            )}
            {this.renderInputCol6(
              Images.saloon_link_social_youtube,
              "text",
              "https://www.youtube.com/",
              this.onChangeYoutube,
              youtubeLink
            )}
            {this.renderInputCol6(
              Images.saloon_link_social_snapchat,
              "text",
              "https://www.snapchat.com/",
              this.onChangeSnapchat,
              snapchatLink
            )}
            {this.renderInputCol6(
              Images.saloon_link_social_linkedin,
              "text",
              "https://www.linkedin.com/",
              this.onChangeLinkedin,
              linkedinLink
            )}
          </div>
          <div className="col-12 px-0 px-md-3">
            {!hasLink && (
              <button
                type="button"
                onClick={() => this.onCreateSocialMedia()}
                className="btn btn-light btn-sm mb-2 "
                id="saloon-save-social-link"
              >
                <Translation>{(t) => t("container.save_changes")}</Translation>
              </button>
            )}
            {hasLink && (
              <button
                type="button"
                onClick={() => this.onUpdateSocialMedia()}
                className="btn btn-light btn-sm mb-2 "
                id="saloon-save-social-link"
              >
                <Translation>
                  {(t) => t("container.update_changes")}
                </Translation>
              </button>
            )}
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.renderLoader()}
        <div className="container">
          <div className="row">
            {this.renderSaloonDashboardHeading()}
            {this.renderSocialLinkCard()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  createSaloonSocialMedia: state.createSaloonSocialMedia,
});

const action = {
  create_social_media,
};

export default connect(mapStateToProps, action)(LinkSocialMedia);
