import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";

import Template5 from "../containers/Templates/Template5";
import TemplateWrapper from "../containers/TemplateWrapper";
import CreateAppointments from "../containers/Templates/CreateAppointments";

const isUser = { isAuthenticated: false };

const isUserAuthenticate = (rest) => {
  const user = JSON.parse(sessionStorage.getItem("template_navigation"));
  const saloonName =
    user && user.saloon && user.saloon.name.toLowerCase().split(" ").join("-");
  const templateName =
    user && user.saloon && user.template.name.toLowerCase().split(" ")[0];
  const { companyName, tempId } = rest.computedMatch.params;
  const { path } = rest.computedMatch;
  const pathName = path.toLowerCase().split("/")[1];
  if (
    pathName === templateName &&
    saloonName === companyName &&
    user.template._id === tempId
  ) {
    isUser.isAuthenticated = true;
  } else {
    isUser.isAuthenticated = false;
  }
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  isUserAuthenticate(rest);
  return <Route exact {...rest} render={(props) => <Component {...props} />} />;
};
class TemplateRoutes extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  //Standard
  //Premium

  render() {
    const { company } = this.props;
    return (
      <Switch>
        <PrivateRoute
          exact
          path="/template5/:companyId"
          component={Template5}
          props={company}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path="/template/:companyName/:companyId"
          component={TemplateWrapper}
          props={company}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path="/template/:companyName/:companyId/create-appointment"
          component={CreateAppointments}
          props={company}
        ></PrivateRoute>

        {/* <PrivateRoute exact path="/dummy/:companyName/:tempId" component={Template2} props={company} ></PrivateRoute> */}
        {/* <Route exact path="/saloon-template2" component={Template2}></Route>
        <Route exact path="/template3" component={Template3}></Route> */}
      </Switch>
    );
  }
}

const mapStateToProps = (state) => ({ company: state.templateNavigation.data });
const action = {};
export default connect(mapStateToProps, action)(TemplateRoutes);
