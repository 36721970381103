import React from "react";
import DotsLoader from "../DotsLoader";
import { connect } from "react-redux";
import { soldPackageRequest, getRequest } from "../../redux/actions/Packages";
import { useEffect } from "react";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import MenuItem from "@material-ui/core/MenuItem";
import PrimaryButton from "../Core/PrimaryButton";
import i18n from "../../i18n";
import { useState } from "react";
import { ErrorHelper, SuccessHelper } from "../../helpers";
import InsertPayment from "./components/InsertPayment.component";
import {
  getAllEmployeeAssociatedWithCompany,
  getCompanyCustomers,
} from "../../config/simpleApiCalls";
import Autocomplete from "react-autocomplete";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";

const telephoneRegex = /[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]/g;

const CreatePackageInvoice = ({
  close,
  loading,
  companies,
  companyTaxMap = {},
  getRequest,
  soldPackageRequest,
}) => {
  const access_token = sessionStorage.getItem("access_token");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [selectedPackage, setSelectedPackage] = useState("");
  const [selectedPackageIndex, setSelectedPackageIndex] = useState("");
  const [companyPackages, setCompanyPackages] = useState([]);
  const [company, setCompany] = useState("");
  const [name, setName] = useState("");
  const [taxes, setTaxes] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [isAddTransactionOpen, setIsAddTransactionOpen] = useState(false);
  const [includeTaxes, setIncludeTaxes] = useState(true);
  const [phoneNoError, setPhoneNoError] = useState("");
  const [employees, setEmployees] = useState([]);
  const [employee, setEmployee] = useState();

  useEffect(() => {
    if (companies.length == 1) {
      onCompanySelect(companies[0]._id);
    }
  }, []);
  const onCompanySelect = async (companyId) => {
    setCompany(companyId);
    setTaxes(companyTaxMap[companyId] ? companyTaxMap[companyId] : []);
    setCompanyPackages([]);
    setSelectedPackage("");
    setSelectedPackageIndex("");
    getAllEmployeeAssociatedWithCompany(access_token).then((res) => {
      if (res.data.success) {
        setEmployees(
          res.data.Data.map((employee) => ({
            value: employee.employee._id,
            name: employee.employee.userId.firstName,
          }))
        );
      }
    });
    getRequest(
      { companyId, access_token },
      {
        success: ({ data }) => {
          console.log(data);
          setCompanyPackages(data);
        },
      }
    );
    getCompanyCustomers({ companyId }, access_token).then((res) => {
      setCustomers(res);
    });
  };
  const onSave = ({ note, cash, creditDebit, tip, discount }) => {
    soldPackageRequest(
      {
        access_token,
        email,
        phoneNo,
        packageSelectedId: selectedPackage._id,
        name,
        note,
        cash,
        creditDebit,
        includeTaxes,
        tip,
        discount,
        employeeId: employee,
      },
      {
        success: () => {
          close();
          SuccessHelper.handleSuccess(i18n.t("successfully_added"), true);
        },
        failure: () => {
          ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
        },
      }
    );
  };
  const validatePhoneNo = () => {
    console.log(phoneNo);
    if (!phoneNo || !phoneNo.replace(/\s/g, "").match(telephoneRegex)) {
      setPhoneNoError(i18n.t("invalid_phone_number"));
      return;
    }
    setPhoneNoError("");
    return true;
  };
  const handleSubmit = () => {
    if (validatePhoneNo()) {
      setIsAddTransactionOpen(true);
    }
  };
  const cummulativeTaxRate =
    taxes.reduce((accum, tax) => accum + tax.percentage, 0) / 100;
  const items = [
    {
      name: selectedPackage.name,
      price: selectedPackage.price,
    },
  ];
  const subtotal = selectedPackage.price;
  const { price = 0 } = selectedPackage;
  const taxDue = cummulativeTaxRate * price;
  const grandTotal = (1 + (includeTaxes ? cummulativeTaxRate : 0)) * price;
  return (
    <>
      <DotsLoader isloading={loading} />
      <ValidatorForm
        onSubmit={handleSubmit}
        className="w-100"
        onError={() => {
          validatePhoneNo();
        }}
      >
        <div className="row mx-0 px-0">
          <div className="col-6 px-0">
            {employees.length ? (
              <SelectValidator
                className="mb-1"
                label={i18n.t("sold_by")}
                onChange={(e) => {
                  setEmployee(e.target?.value);
                }}
                value={employee}
                select
              >
                {employees.map((employee) => {
                  return (
                    <MenuItem value={employee.value}>{employee.name}</MenuItem>
                  );
                })}
              </SelectValidator>
            ) : null}
            {companies.length && companies.length != 1 ? (
              <SelectValidator
                className="mb-1"
                label={i18n.t("company")}
                onChange={(e) => {
                  onCompanySelect(e.target?.value);
                }}
                value={company}
                select
              >
                {companies.map((company) => {
                  return (
                    <MenuItem value={company._id}>{company.name}</MenuItem>
                  );
                })}
              </SelectValidator>
            ) : null}
            <SelectValidator
              className="mb-1"
              label={i18n.t("package")}
              onChange={(e) => {
                setSelectedPackage(companyPackages[e.target?.value]);
                setSelectedPackageIndex(e.target?.value);
              }}
              value={`${selectedPackageIndex}`}
              validators={["required"]}
              errorMessages={[i18n.t("status_is_required")]}
              select
            >
              {companyPackages.map((companyPackage, index) => {
                return (
                  <MenuItem value={`${index}`}>{companyPackage.name}</MenuItem>
                );
              })}
            </SelectValidator>
            <Autocomplete
              placeholder={i18n.t("enter_phone_number_placeholder_type")}
              renderInput={(props) => {
                return (
                  <TextField
                    id="phoneNumber"
                    label={i18n.t("container.employe_phone_number")}
                    error={!!phoneNoError}
                    {...props}
                  />
                );
              }}
              items={
                phoneNo
                  ? customers.map(
                      ({
                        name = "",
                        phoneNo = "",
                        email = "",
                        userId,
                        isBlackListed,
                      }) => ({
                        name,
                        phoneNo,
                        email,
                        userId,
                        isBlackListed,
                      })
                    )
                  : []
              }
              shouldItemRender={(item, value) =>
                item.phoneNo.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
                item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
              }
              getItemValue={(item) => item.phoneNo}
              renderItem={(item, highlighted) => (
                <MenuItem key={item._id}>{item.name}</MenuItem>
              )}
              menuStyle={{
                zIndex: "9998",
                position: "absolute",
                background: "white",
                top: 64,
                left: 0,
              }}
              wrapperStyle={{
                width: "100%",
              }}
              renderMenu={(children) => {
                return <div className="menu">{children.slice(0, 15)}</div>;
              }}
              value={phoneNo}
              onChange={(e) => setPhoneNo(e.target.value)}
              onSelect={(value, { phoneNo, email, name }) => {
                setPhoneNo(phoneNo);
                setEmail(email);
                setName(name);
              }}
            />
            {!!phoneNoError && (
              <FormHelperText error>{phoneNoError}</FormHelperText>
            )}
            <TextValidator
              className="mb-1"
              label={i18n.t("name")}
              onChange={(e) => setName(e.target.value)}
              value={name}
              validators={["required"]}
              errorMessages={[i18n.t("name_required")]}
            />
            <TextValidator
              className="mb-1"
              label={i18n.t("email_to_invoice")}
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              validators={["required", "isEmail"]}
              errorMessages={[
                i18n.t("email_required"),
                i18n.t("invalid_email"),
              ]}
            />
          </div>
          {selectedPackage ? (
            <div className="px-2 row mx-0 flex-column justify-content-end col-6">
              <table>
                <tr>
                  <td>{selectedPackage.name}</td>
                  <td className="text-right">$ {subtotal.toFixed(2)}</td>
                </tr>
                {cummulativeTaxRate && (
                  <tr>
                    <td>{i18n.t("tax_total")}</td>
                    <td className="text-right">$ {taxDue.toFixed(2)}</td>
                  </tr>
                )}
                <tr className="font-weight-bold">
                  <td>{i18n.t("grand_total")}</td>
                  <td className="text-right">$ {grandTotal.toFixed(2)}</td>
                </tr>
              </table>
            </div>
          ) : null}
        </div>
        <div className="row mx-0 px-0 justify-content-end mt-2">
          {selectedPackage ? (
            <InsertPayment
              defaultAmount={grandTotal}
              open={isAddTransactionOpen}
              onSubmit={onSave}
              handleClose={() => setIsAddTransactionOpen(false)}
              toggleTaxes={() => setIncludeTaxes(!includeTaxes)}
              items={items}
              subtotal={selectedPackage.price}
              taxDue={taxDue}
              includeTaxes={includeTaxes}
              grandTotal={grandTotal}
            />
          ) : null}
          <PrimaryButton
            props={{
              type: "submit",
            }}
            label={i18n.t("collect_payment")}
          />
        </div>
      </ValidatorForm>
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.packages.isFetching,
});

const action = { soldPackageRequest, getRequest };

export default connect(mapStateToProps, action)(CreatePackageInvoice);
