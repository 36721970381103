// @flow

import { GETGYMTIMESLOTS } from "./ActionTypes";

export function request(payload, index) {
  return {
    payload,
    index,
    type: GETGYMTIMESLOTS.REQUEST
  };
}

export function success(data: Object, index) {
  console.log('here')
  return {
    data,
    index,
    type: GETGYMTIMESLOTS.SUCCESS
  };
}

export function failure(errorMessage: Object) {
  return {
    errorMessage,
    type: GETGYMTIMESLOTS.FAILURE
  };
}

