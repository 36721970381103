import { connect } from "react-redux";
import React, { Component } from "react";
import i18n from "../../i18n";
import { Images } from "../../theme";
import { DotsLoader } from "../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Translation } from "react-i18next";
import {
  getBookingNote,
  deleteBookingNote,
  createBookingNote,
  updateBookingNote,
} from "../../config/simpleApiCalls";
import { ErrorHelper, SuccessHelper } from "../../helpers";
import moment from "moment";
import "./styles.scss";
// import Sytles from "./sytles";
import Lightbox from "../LightBox/index";

class BookingNotes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mediaType: null,
      imageUrl: null,
      imageFile: null,
      imageName: null,
      imageTitle: null,
      imageDescription: null,
      access_token: null,
      isloading: false,
    };
  }

  async componentDidMount() {
    const { access_token, bookingId, notes = [] } = this.props;
    if (bookingId) {
      this.setState({ isloading: true });
      getBookingNote({ bookingId }, access_token).then(({ data }) => {
        this.setState({ isloading: false });
        if (data.success) {
          this.setState({ notes: data.data ? data.data.reverse() : [] });
        } else {
          this.setState({ notes: [] });
        }
      });
    } else {
      this.setState({ notes });
    }
  }

  onChangeTitle = (text) => this.setState({ imageTitle: text.target.value });
  onChangeDescription = (text) =>
    this.setState({ imageDescription: text.target.value });

  handleNavigation = (route) => {
    this.props.history.push(route);
  };
  fileSelectedHandler = (event) => {
    const { files = [] } = this.state;
    const newFiles = Object.values(event.target.files).map((file) => {
      return {
        imageUrl: URL.createObjectURL(file),
        imageFile: file,
      };
    });
    files.push(...newFiles);
    this.setState({ files });
  };

  handleSave = () => {
    const { text = "", files = [], notes = [] } = this.state;
    const { access_token, bookingId } = this.props;
    if (!text && (!files || !files.length)) {
      ErrorHelper.handleErrors(
        "Please Enter a Comment or Attach a Picture",
        true
      );
    } else {
      this.setState({ isloading: true });
      let payloadFormdata = new FormData();
      files.map((file) => payloadFormdata.append("image", file.imageFile));
      payloadFormdata.append("text", text);
      payloadFormdata.append("bookingId", bookingId);
      createBookingNote(payloadFormdata, access_token)
        .then(({ data }) => {
          this.setState({ isloading: false });
          if (data.success) {
            this.setState({
              notes: [data.data, ...notes],
              files: [],
              text: "",
            });
            SuccessHelper.handleSuccess(i18n.t("successfully_added"), true);
          } else {
            ErrorHelper.handleErrors(i18n.t("failed_to_add"), true);
          }
        })
        .catch(() => {
          this.setState({ isloading: false });
          ErrorHelper.handleErrors("An Error Occured", true);
        });
    }
  };

  handleUpdate = (note, index) => {
    const { text = "", _id } = note;
    const { access_token } = this.props;
    const { notes } = this.state;
    this.setState({ isloading: true });
    updateBookingNote({ text, _id }, access_token)
      .then(({ data }) => {
        this.setState({ isloading: false });
        if (data.success) {
          notes[index] = data.data;
          this.setState({ notes });
          SuccessHelper.handleSuccess(i18n.t("successfully_updated"), true);
        } else {
          ErrorHelper.handleErrors(i18n.t("failed_to_update"), true);
        }
      })
      .catch((e) => {
        this.setState({ isloading: false });
        ErrorHelper.handleErrors("An Error Occured", true);
      });
  };

  handleDelete = (id, index) => {
    const { access_token } = this.props;
    const { notes } = this.state;
    this.setState({ isloading: true });
    deleteBookingNote({ id }, access_token)
      .then(({ data }) => {
        this.setState({ isloading: false });
        if (data.success) {
          notes.splice(index, 1);
          this.setState({ notes });
          SuccessHelper.handleSuccess(i18n.t("successfully_deleted"), true);
        } else {
          ErrorHelper.handleErrors(i18n.t("failed_to_delete"), true);
        }
      })
      .catch(() => {
        this.setState({ isloading: false });
        ErrorHelper.handleErrors("An Error Occured", true);
      });
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  renderUploadFileCard = () => {
    const { files = [] } = this.state;
    const { readonly } = this.props;
    return (
      <div className="col-12">
        <div className="bg-white ">
          <div className="row mx-2">
            {files.map((file, index) => {
              return (
                <div className="m-1" key={file.imageUrl}>
                  <FontAwesomeIcon
                    onClick={() => {
                      files.splice(index, 1);
                      this.setState({ files });
                    }}
                    style={{
                      width: 20,
                      height: 20,
                      cursor: "pointer",
                      position: "relative",
                      top: "16px",
                      right: "-68px",
                      display: "inline-block",
                    }}
                    color="red"
                    icon={["fas", "minus-circle"]}
                  />
                  <div style={{ backgroundColor: "black" }}>
                    <img
                      src={file.imageUrl}
                      style={{
                        width: "80px",
                        height: "80px",
                        objectFit: "contain",
                      }}
                    ></img>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="full-width text-right d-flex">
            <textarea
              className="pt-1 full-width"
              style={{ paddingRight: "44px", marginRight: "-24px" }}
              onChange={(e) => this.setState({ text: e.target.value })}
              readOnly={readonly}
            ></textarea>
            <FontAwesomeIcon
              onClick={() => this.fileInput.click()}
              style={{
                width: 25,
                height: 25,
                cursor: "pointer",
                position: "relative",
                top: "14px",
                right: "24px",
              }}
              icon={["fas", "images"]}
            />
          </div>
        </div>
        <div className="row text-right d-block mx-1 mt-2">
          <button
            type="button"
            onClick={() => this.handleSave()}
            className="btn btn-light btn-sm ml-1"
            id="saloon-save-social-link"
          >
            <Translation>{(t) => t("add")}</Translation>
          </button>
        </div>
      </div>
    );
  };

  renderExistingNotes = () => {
    const { notes = [], activeLightBoxIndex } = this.state;
    const { hasEditPermission, readonly } = this.props;
    return (
      <div className="full-width notes">
        {notes.map((note, index) => {
          return (
            <div className="row mx-2 p-3 mt-3 note-container" key={note._id}>
              <strong className="col-12 stamp">
                Created by{" "}
                {`${note.createdBy.firstName} ${note.createdBy.lastName}`} at{" "}
                {moment(note.createdDate).format("MM-DD-YYYY hh:mm")}
              </strong>
              <strong className="col-12 stamp">
                Update by{" "}
                {`${note.updatedBy.firstName} ${note.updatedBy.lastName}`} at{" "}
                {moment(note.updatedDate).format("MM-DD-YYYY hh:mm")}
              </strong>
              {note.images.map((image) => {
                return (
                  <div
                    className="m-1"
                    key={image.url}
                    onClick={() =>
                      this.setState({ activeLightBoxIndex: index })
                    }
                  >
                    <div style={{ backgroundColor: "black" }}>
                      <img
                        src={image.url}
                        style={{
                          width: "80px",
                          height: "80px",
                          objectFit: "contain",
                        }}
                      ></img>
                    </div>
                  </div>
                );
              })}
              {activeLightBoxIndex === index ? (
                <Lightbox
                  startIndex={0}
                  onClose={() => {
                    this.setState({ activeLightBoxIndex: undefined });
                  }}
                  images={note.images.map((image, index) => {
                    return {
                      url: image.url,
                      title: `Note ${index + 1}`,
                    };
                  })}
                />
              ) : null}
              <textarea
                className="pt-1 full-width"
                value={note.text}
                readOnly={true}
                onChange={(e) => {
                  notes[index].text = e.target.value;
                  this.setState({ notes });
                }}
              ></textarea>
              {hasEditPermission && (
                <div className="row mx-0 mt-2 text-right d-block full-width">
                  <button
                    type="button"
                    onClick={() => this.handleDelete(note._id, index)}
                    className="btn btn-outline-light btn-sm"
                  >
                    <Translation>{(t) => t("delete")}</Translation>
                  </button>
                  <button
                    type="button"
                    onClick={() => this.handleUpdate(note, index)}
                    className="btn btn-light btn-sm mr-1"
                    id="saloon-save-social-link"
                  >
                    <Translation>{(t) => t("update")}</Translation>
                  </button>
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    const { imageFile } = this.state;
    const { readonly } = this.props;
    return (
      <div>
        {this.renderLoader()}
        <div>
          <div className="row mx-0">
            {!readonly && (
              <>
                <input
                  type="file"
                  style={{ display: "none" }}
                  onChange={this.fileSelectedHandler}
                  ref={(fileInput) => (this.fileInput = fileInput)}
                  accept="image/*,video/*"
                  multiple
                />
                {this.renderUploadFileCard()}
              </>
            )}
            {this.renderExistingNotes()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(BookingNotes);
