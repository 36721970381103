import { connect } from "react-redux";
import React, { Component } from "react";
import MyRoutes from "./routes";
import "bootstrap/dist/css/bootstrap.min.css";
import { messaging } from "./init-fcm";
import { SuccessHelper } from "./helpers";
import { request as get_notifications } from "./redux/actions/GetNotifications";
import { set } from "./redux/actions/User";
import "./i18n";
import "./App.css";
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  async componentDidMount() {
    this.props.set({
      user: sessionStorage.getItem("user"),
      employee: sessionStorage.getItem("employee"),
      saloon: sessionStorage.getItem("saloon"),
      company: sessionStorage.getItem("company"),
      access_token: sessionStorage.getItem("access_token"),
      impersonationId: sessionStorage.getItem("impersonationId"),
    });
    setTimeout(() => {
      this.props.get_notifications();
      this.setState({ isInitialized: true });
    }, 0);

    if (!messaging) return;
    messaging
      .requestPermission()
      .then(async function () {
        const token = await messaging.getToken();
        sessionStorage.setItem("messaging_token", token);
      })
      .catch(function (err) {
        console.log("Unable to get permission to notify.", err);
      });
    navigator.serviceWorker.addEventListener("message", (message) => {
      SuccessHelper.handleToastSuccess(
        `${message.data["firebase-messaging-msg-data"].notification.body}`,
        true
      );
      this.props.get_notifications();
    });
  }

  render() {
    const { isInitialized } = this.state;
    return isInitialized ? <MyRoutes /> : null;
  }
}

const mapStateToProps = (state) => ({});

const action = { get_notifications, set };

export default connect(mapStateToProps, action)(App);
