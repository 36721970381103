import { CREATE_SALOON_SOCIAL_MEDIA, SALOON_LOGOUT } from '../ActionTypes'

export function request(payload) {
    return {
        payload,
        type: CREATE_SALOON_SOCIAL_MEDIA.REQUEST
    }
}

export function success(data: Object) {
    return {
        data,
        type: CREATE_SALOON_SOCIAL_MEDIA.SUCCESS
    }
}

export function failure(errorMessage: Object) {
    return {
        errorMessage,
        type: CREATE_SALOON_SOCIAL_MEDIA.FAILURE
    }
}

export function logout() {
    return {
        type: SALOON_LOGOUT
    };
}