import React from "react";
import DotsLoader from "../DotsLoader";
import { connect } from "react-redux";
import { soldPackageRequest, getRequest } from "../../redux/actions/Packages";
import { useEffect } from "react";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import MenuItem from "@material-ui/core/MenuItem";
import PrimaryButton from "../Core/PrimaryButton";
import i18n from "../../i18n";
import { useState } from "react";
import { ErrorHelper, SuccessHelper } from "../../helpers";
import InsertPayment from "./components/InsertPayment.component";
import {
  createCartSale,
  getAllEmployeeAssociatedWithCompany,
  getCompanyCustomers,
  getProduct,
} from "../../config/simpleApiCalls";
import Autocomplete from "react-autocomplete";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "../Core/Select";
import BasicTable from "../Core/BasicTable";
import { TableRow } from "@material-ui/core";
import { TableCell } from "@material-ui/core";
import { Images } from "../../theme";
import MUAutocomplete from "@material-ui/lab/Autocomplete";

const telephoneRegex = /[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]/g;

const CreatePackageInvoice = ({
  close,
  loading,
  companies,
  companyTaxMap = {},
  getRequest,
  soldPackageRequest,
}) => {
  const access_token = sessionStorage.getItem("access_token");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [productMap, setProductsMap] = useState({});
  const [company, setCompany] = useState("");
  const [taxes, setTaxes] = useState([]);
  const [isAddTransactionOpen, setIsAddTransactionOpen] = useState(false);
  const [includeTaxes, setIncludeTaxes] = useState(true);
  const [activeProduct, setActiveProduct] = useState();
  const [cart, setCart] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [phoneNoError, setPhoneNoError] = useState("");
  const [name, setName] = useState("");
  const [showCollection, setShowCollection] = useState("");
  const [employees, setEmployees] = useState([]);
  const [employee, setEmployee] = useState();

  useEffect(() => {
    if (companies.length == 1) {
      onCompanySelect(companies[0]._id);
    }
  }, []);
  const onCompanySelect = async (companyId) => {
    setCompany(companyId);
    setTaxes(companyTaxMap[companyId] ? companyTaxMap[companyId] : []);
    setProductsMap({});
    setActiveProduct();
    getAllEmployeeAssociatedWithCompany(access_token).then((res) => {
      if (res.data.success) {
        setEmployees(
          res.data.Data.map((employee) => ({
            value: employee.employee._id,
            name: employee.employee.userId.firstName,
          }))
        );
      }
    });
    getProduct({ companyId }, access_token).then((res) => {
      if (res.data.success) {
        const productMap = {};
        res.data.data.map((element) => {
          productMap[element._id] = {
            value: element._id,
            ...element,
          };
        });
        setProductsMap(productMap);
      }
    });
    getCompanyCustomers({ companyId }, access_token).then((res) => {
      setCustomers(res);
    });
  };
  const onSave = ({ note, cash, creditDebit, tip, discount }) => {
    createCartSale(
      {
        email,
        phoneNo,
        name,
        note,
        cash,
        creditDebit,
        includeTaxes,
        companyId: company,
        products: cart,
        tip,
        discount,
        employeeId: employee,
      },
      access_token
    )
      .then(() => {
        close();
        SuccessHelper.handleSuccess(i18n.t("successfully_added"), true);
      })
      .catch(() => {
        ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
      });
  };
  const validatePhoneNo = () => {
    if (phoneNo && !phoneNo.replace(/\s/g, "").match(telephoneRegex)) {
      setPhoneNoError(i18n.t("invalid_phone_number"));
      return;
    }
    setPhoneNoError("");
    return true;
  };
  const handleSubmit = () => {
    if (validatePhoneNo()) {
      setIsAddTransactionOpen(true);
    }
  };
  const cummulativeTaxRate =
    taxes.reduce((accum, tax) => accum + tax.percentage, 0) / 100;
  let taxableAmount = 0;
  const subtotal = cart.reduce((accum, element) => {
    const amount = element.quantity * element.price;
    if (element.taxable) {
      taxableAmount += amount;
    }
    return accum + amount;
  }, 0);
  const taxDue = cummulativeTaxRate * taxableAmount;
  const grandTotal =
    (includeTaxes ? cummulativeTaxRate : 0) * taxableAmount + subtotal;
  return (
    <>
      <DotsLoader isloading={loading} />
      <div>
        <ValidatorForm
          onSubmit={handleSubmit}
          className="w-100"
          onError={() => {}}
        >
          {employees.length ? (
            <SelectValidator
              className="mb-1"
              label={i18n.t("sold_by")}
              onChange={(e) => {
                setEmployee(e.target?.value);
              }}
              value={employee}
              select
            >
              {employees.map((employee) => {
                return (
                  <MenuItem value={employee.value}>{employee.name}</MenuItem>
                );
              })}
            </SelectValidator>
          ) : null}
          {companies.length && companies.length != 1 ? (
            <SelectValidator
              className="mb-1"
              label={i18n.t("company")}
              onChange={(e) => {
                onCompanySelect(e.target?.value);
              }}
              value={company}
              select
            >
              {companies.map((company) => {
                return <MenuItem value={company._id}>{company.name}</MenuItem>;
              })}
            </SelectValidator>
          ) : null}
          <div className="row mx-0 px-0">
            <Autocomplete
              placeholder={i18n.t("enter_phone_number_placeholder_type")}
              renderInput={(props) => {
                return (
                  <TextField
                    id="phoneNumber"
                    label={i18n.t("container.employe_phone_number")}
                    error={!!phoneNoError}
                    {...props}
                  />
                );
              }}
              items={
                phoneNo
                  ? customers.map(
                      ({
                        name = "",
                        phoneNo = "",
                        email = "",
                        userId,
                        isBlackListed,
                      }) => ({
                        name,
                        phoneNo,
                        email,
                        userId,
                        isBlackListed,
                      })
                    )
                  : []
              }
              shouldItemRender={(item, value) =>
                item.phoneNo.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
                item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
              }
              getItemValue={(item) => item.phoneNo}
              renderItem={(item, highlighted) => (
                <MenuItem key={item._id}>{item.name}</MenuItem>
              )}
              menuStyle={{
                zIndex: "9998",
                position: "absolute",
                background: "white",
                top: 64,
                left: 0,
              }}
              wrapperStyle={{
                width: "100%",
              }}
              renderMenu={(children) => {
                return <div className="menu">{children.slice(0, 15)}</div>;
              }}
              value={phoneNo}
              onChange={(e) => setPhoneNo(e.target.value)}
              onSelect={(value, { phoneNo, email, name }) => {
                setPhoneNo(phoneNo);
                setEmail(email);
                setName(name);
              }}
            />
            {!!phoneNoError && (
              <FormHelperText error>{phoneNoError}</FormHelperText>
            )}
            <div className="mb-1 col-12 px-0">
              <TextValidator
                label={i18n.t("name")}
                onChange={(e) => setName(e.target.value)}
                value={name}
                validators={["required"]}
                errorMessages={[i18n.t("name_required")]}
              />
            </div>
            <div className="mb-1 col-12 px-0">
              <TextValidator
                label={i18n.t("email_to_invoice")}
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                validators={["required", "isEmail"]}
                errorMessages={[
                  i18n.t("email_required"),
                  i18n.t("invalid_email"),
                ]}
              />
            </div>
            <div className="col-12 row mx-0 px-0 align-items-end mb-1">
              <div style={{ flex: 11 }} className="pr-2">
                <MUAutocomplete
                  options={Object.values(productMap)}
                  groupBy={(option) => {
                    return option.subcategory;
                  }}
                  blurOnSelect
                  value={activeProduct}
                  onChange={(e, value) => {
                    setActiveProduct(value);
                  }}
                  getOptionLabel={(option) => option.name}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={i18n.t("products")}
                      fullWidth
                    />
                  )}
                />
              </div>
              <PrimaryButton
                label={i18n.t("add")}
                onClick={() => {
                  if (activeProduct) {
                    const product = productMap[activeProduct._id];
                    setCart([
                      ...cart,
                      {
                        quantity: 1,
                        name: product.name,
                        _id: activeProduct._id,
                        price: product.charge,
                        taxable: product.taxable,
                      },
                    ]);
                  }
                }}
              />
            </div>
          </div>
        </ValidatorForm>
        <div className="mt-1">
          <BasicTable
            rows={cart}
            schema={[
              {
                header: i18n.t("name"),
                field: "name",
              },
              {
                header: i18n.t("quantity"),
                field: "quantity",
                render: (row, index) => {
                  return (
                    <input
                      type="number"
                      style={{ width: "60px" }}
                      defaultValue={row.quantity}
                      min={1}
                      onBlur={(e) => {
                        let quantity = parseInt(e.target.value);
                        if (quantity < 1) {
                          quantity = 1;
                        }
                        let newCart = [...cart];
                        newCart[index] = {
                          ...newCart[index],
                          quantity,
                        };
                        setCart(newCart);
                      }}
                    />
                  );
                },
              },
              {
                header: i18n.t("price"),
                field: "price",
              },
              {
                header: i18n.t("total"),
                field: "price",
                accessor: (row) => (row.price * row.quantity).toFixed(2),
              },
              {
                header: "",
                field: "delete",
                style: { width: 30 },
                render: (data, index) => {
                  return (
                    <img
                      onClick={() => {
                        let newCart = [...cart];
                        newCart.splice(index, 1);
                        setCart(newCart);
                      }}
                      className="category-table-action-icon"
                      src={Images.saloon_working_hour_delete}
                    />
                  );
                },
              },
            ]}
            appendRows={
              subtotal ? (
                <>
                  <TableRow>
                    <TableCell className="font-weight-bold">
                      {i18n.t("total")}
                    </TableCell>
                    <TableCell />
                    <TableCell />

                    <TableCell className="font-weight-bold">
                      {subtotal.toFixed(2)}
                    </TableCell>
                    <TableCell style={{ width: 30 }} />
                  </TableRow>
                  {taxDue ? (
                    <TableRow>
                      <TableCell className="font-weight-bold">
                        {i18n.t("tax_total")}
                      </TableCell>
                      <TableCell />
                      <TableCell />

                      <TableCell className="font-weight-bold">
                        {taxDue.toFixed(2)}
                      </TableCell>
                      <TableCell style={{ width: 30 }} />
                    </TableRow>
                  ) : null}
                  <TableRow>
                    <TableCell className="font-weight-bold">
                      {i18n.t("grand_total")}
                    </TableCell>
                    <TableCell />
                    <TableCell />

                    <TableCell className="font-weight-bold">
                      {grandTotal.toFixed(2)}
                    </TableCell>
                    <TableCell style={{ width: 30 }} />
                  </TableRow>
                </>
              ) : null
            }
          />
        </div>
      </div>
      <div className="row mx-0 px-0 justify-content-end mt-2">
        {showCollection ? (
          <InsertPayment
            defaultAmount={grandTotal}
            open={showCollection}
            onSubmit={onSave}
            handleClose={() => setShowCollection(false)}
            toggleTaxes={() => setIncludeTaxes(!includeTaxes)}
            items={cart}
            subtotal={subtotal}
            taxDue={taxDue}
            includeTaxes={includeTaxes}
            grandTotal={grandTotal}
          />
        ) : null}
        <PrimaryButton
          props={{
            type: "submit",
          }}
          onClick={() => {
            setShowCollection(!!cart.length);
          }}
          label={i18n.t("collect_payment")}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.packages.isFetching,
});

const action = { soldPackageRequest, getRequest };

export default connect(mapStateToProps, action)(CreatePackageInvoice);
