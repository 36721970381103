import React from "react";
import i18n from '../../i18n';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { getNestedValue } from "../../util/objectMethods";

export default function InvoiceTable({
  invoices = []
}) {
  
  const downloadPdf = (downloadUrl) => {
    window.open(downloadUrl, '_blank');
  }
  return (
    <div className="table-responsive sticky-header">
      <table className="table table-borderless appointment-details-table">
        <thead>
          <tr>
            <th scope="col">
              <span className="appointment-details-th-text">{i18n.t('date')}</span>
            </th>
            <th scope="col">
              <span className="appointment-details-th-text">{i18n.t('status')}</span>
            </th>
            <th scope="col">
              <span className="appointment-details-th-text">{i18n.t('amount')}</span>
            </th>
            <th scope="col" style={{width:"35px"}}>
              <span className="appointment-details-th-text">{i18n.t('download')}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {invoices.map((val) => {
            const downloadUrl = getNestedValue(val, 'pdfUrl', '')
            return (
              <tr key={val.pdfUrl} style={{ borderTop: "3px solid #F7F7F7" }}>
                <td>
                  <div className="d-flex align-items-center">
                    {moment.unix(val.created).format('MM-DD-YYYY HH:mmA')}
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    {i18n.t(val.status)}
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    {val.totalDue ? `$${(val.totalDue/100).toFixed(2)}` : ''}
                  </div>
                </td>
                <td style={{width:"35px"}}>
                  <div className="row justify-content-center">
                    { downloadUrl ? 
                      <FontAwesomeIcon
                        className="view-icon"
                        color="white"
                        icon={["fas", "file-download"]}
                        onClick={() => downloadPdf(downloadUrl)}
                      /> :
                      null
                    }
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}