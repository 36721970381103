import {take, put, call, fork} from 'redux-saga/effects';

import ApiSauce from '../../services/apiSauce';
import {get_gym_timeslots} from '../../config/WebServices';
import * as types from '../actions/ActionTypes';

import {success, failure} from '../actions/GetGymTimeslots';

import {ErrorHelper} from '../../helpers';

async function callRequest(data) {
  return ApiSauce.get(get_gym_timeslots, {
    ...data
  });
}

function* watchRequest() {
  while (true) {
    const {payload, index} = yield take(types.GETGYMTIMESLOTS.REQUEST);

    // const { targetView } = payload;
    // delete payload.targetView;
    try {
      const response = yield call(callRequest, payload);
      console.log('response', response)
      yield put(success(response, index));
    } catch (err) {
      yield put(failure(err));
      ErrorHelper.handleErrors(err, true);
    }
  }
}

export default function* root() {
  yield fork(watchRequest);
}
