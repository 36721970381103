import React from "react";
import TextField from "@material-ui/core/TextField";

const DollarInput = ({
  value,
  onChange = () => {},
  error,
  readOnly,
  label,
  limits,
}) => {
  const handleChange = (text) => {
    onChange(text.target.value);
  };
  const handleBlur = (text) => {
    let num = parseFloat(text.target.value);
    if (isNaN(num)) {
      num = 0;
    }
    if (limits) {
      if (limits.max) {
        if (num > limits.max) {
          return onChange(limits.max);
        }
      }
      if (limits.min) {
        if (num < limits.min) {
          return onChange(limits.min);
        }
      }
    }

    onChange(num);
  };
  return (
    <TextField
      type="numeric"
      label={label}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={(e) => {
        if (!readOnly) {
          e.target.select();
        }
      }}
      value={value}
      InputProps={{
        readOnly,
      }}
      helperText={error}
      error={Boolean(error)}
    />
  );
};

export default DollarInput;
