// @flow

import { COMMUNICATION_SETTINGS } from "../ActionTypes";

export function getRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: COMMUNICATION_SETTINGS.GET_SAGA,
  };
}

export function get(data) {
  return {
    data,
    type: COMMUNICATION_SETTINGS.GET,
  };
}

export function upsertRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: COMMUNICATION_SETTINGS.UPSERT_SAGA,
  };
}

export function upsert(data) {
  return {
    data,
    type: COMMUNICATION_SETTINGS.UPSERT,
  };
}

export function requestFailure() {
  return {
    type: COMMUNICATION_SETTINGS.REQUEST_FAILURE,
  };
}
