import React, { Component } from "react";

import "../Styles/formGenerator.sass";
import GenerateCheckboxField from "./GenerateCheckboxField.component";
import GenerateDateField from "./GenerateDateField.component";
import GenerateRadioField from "./GenerateRadioField.component";
import GenerateSelect from "./GenerateSelect.component";
import GenerateTextField from "./GenerateTextField.component";
import GenerateMultipleChoiceField from "./GenerateMultipleChoiceField";
import GenerateSectionField from "./GenerateSectionField.component";

import styled from "styled-components";

const FormGeneratorSection = styled.section`
  border: 2px solid #c4c4c4;
  border-radius: 5px;
  @media screen and (min-width: 768px) {
    width: 60% !important;
  }
  @media screen and (min-width: 0px) {
    width: 80%;
  }
  margin: ${(props) =>
    props.preview ? "5vh auto 0 auto" : "20vh auto 0 auto"};

  padding: 1em;

  display: grid;
  grid-template-columns: 1fr;
  gap: 2vh 0px;
  grid-template-areas:
    "."
    "."
    "."
    "."
    ".";
  justify-items: center;
  background-color: white;
`;
class FormGenerator extends Component {
  state = { submit: false, questions: {} };

  handleSubmit = (field, id, type) => {
    if (field !== undefined || field !== null) {
      const { onChange = () => {} } = this.props;
      let answers = { ...this.props.answers };
      answers[id] = { ...answers[id], [type]: field };
      onChange(answers);
    }
  };

  render() {
    const {
      fieldsToRender,
      errorMap = {},
      readonly,
      answers = {},
      preview,
      formTitle,
    } = this.props;
    return (
      <div className="form-generator">
        <FormGeneratorSection preview={preview}>
          <h4>{formTitle}</h4>
          {Object.keys(fieldsToRender).map((key) => {
            switch (fieldsToRender[key].type) {
              case "text":
                return (
                  <>
                    <GenerateTextField
                      handleSubmit={(field, id, type) =>
                        this.handleSubmit(field, id, type)
                      }
                      field={fieldsToRender[key]}
                      answer={answers[fieldsToRender[key]._id]?.answer}
                      details={answers[fieldsToRender[key]._id]?.details}
                      error={errorMap[fieldsToRender[key]._id]}
                      readonly={readonly}
                      detailsRequired={fieldsToRender[key].details}
                    />
                  </>
                );
              case "select":
                return (
                  <GenerateSelect
                    handleSubmit={(field, id, type) =>
                      this.handleSubmit(field, id, type)
                    }
                    field={fieldsToRender[key]}
                    answer={answers[fieldsToRender[key]._id]?.answer}
                    error={errorMap[fieldsToRender[key]._id]}
                    readonly={readonly}
                    detailsRequired={fieldsToRender[key].details}
                    details={answers[fieldsToRender[key]._id]?.details}
                  />
                );
              case "date":
                return (
                  <GenerateDateField
                    handleSubmit={(field, id, type) =>
                      this.handleSubmit(field, id, type)
                    }
                    field={fieldsToRender[key]}
                    answer={answers[fieldsToRender[key]._id]?.answer}
                    error={errorMap[fieldsToRender[key]._id]}
                    readonly={readonly}
                    detailsRequired={fieldsToRender[key].details}
                    details={answers[fieldsToRender[key]._id]?.details}
                  />
                );
              case "time":
                return (
                  <GenerateDateField
                    handleSubmit={(field, id, type) =>
                      this.handleSubmit(field, id, type)
                    }
                    field={fieldsToRender[key]}
                    answer={answers[fieldsToRender[key]._id]?.answer}
                    error={errorMap[fieldsToRender[key]._id]}
                    readonly={readonly}
                    detailsRequired={fieldsToRender[key].details}
                    details={answers[fieldsToRender[key]._id]?.details}
                  />
                );
              case "email":
                return (
                  <GenerateTextField
                    handleSubmit={(field, id, type) =>
                      this.handleSubmit(field, id, type)
                    }
                    field={fieldsToRender[key]}
                    answer={answers[fieldsToRender[key]._id]?.answer}
                    error={errorMap[fieldsToRender[key]._id]}
                    readonly={readonly}
                    detailsRequired={fieldsToRender[key].details}
                    details={answers[fieldsToRender[key]._id]?.details}
                  />
                );
              case "radio":
                return (
                  <GenerateRadioField
                    handleSubmit={(field, id, type) =>
                      this.handleSubmit(field, id, type)
                    }
                    field={fieldsToRender[key]}
                    answer={answers[fieldsToRender[key]._id]?.answer}
                    error={errorMap[fieldsToRender[key]._id]}
                    readonly={readonly}
                    detailsRequired={fieldsToRender[key].details}
                    details={answers[fieldsToRender[key]._id]?.details}
                  />
                );
              case "checkbox":
                return (
                  <GenerateCheckboxField
                    handleSubmit={(field, id, type) =>
                      this.handleSubmit(field, id, type)
                    }
                    field={fieldsToRender[key]}
                    answer={answers[fieldsToRender[key]._id]?.answer}
                    error={errorMap[fieldsToRender[key]._id]}
                    readonly={false}
                    detailsRequired={fieldsToRender[key].details}
                    details={answers[fieldsToRender[key]._id]?.details}
                  />
                );
              case "multipleChoice":
                return (
                  <GenerateMultipleChoiceField
                    field={fieldsToRender[key]}
                    handleSubmit={(field, id, type) =>
                      this.handleSubmit(field, id, type)
                    }
                    answer={answers[fieldsToRender[key]._id]?.answer}
                    error={errorMap[fieldsToRender[key]._id]}
                    readonly={readonly}
                    detailsRequired={fieldsToRender[key].details}
                    details={answers[fieldsToRender[key]._id]?.details}
                  />
                );
              case "section":
                return <GenerateSectionField field={fieldsToRender[key]} />;

              default:
            }
          })}
        </FormGeneratorSection>
      </div>
    );
  }
}

export default FormGenerator;
