import { put, takeEvery } from "redux-saga/effects";

import * as types from "../../actions/ActionTypes";
import { set, requestFailure } from "../../actions/Saloon/Easy1Products";

import axios from "axios";
import { easy1_products } from "../../../config/WebServices";


function* getSaga(action) {
  const { payload, callbackMap = {} } = action
  let data
  try {
    data = yield axios.get(easy1_products, {
      params: payload,
    });
    data = data.data
    
    if(data.success) {
      if(callbackMap.success && typeof callbackMap.success == 'function') {
        callbackMap.success(data)
      }
      yield put(set(data.data));
    } else {
      if(callbackMap.failure && typeof callbackMap.failure == 'function') {
        callbackMap.failure(data)
      }
      yield put(requestFailure(data));
    }
  } catch (err) {
    if(callbackMap.failure && typeof callbackMap.failure == 'function') {
      callbackMap.success(data)
    }
    yield put(requestFailure(data));
  }
}

export default function* root() {
  yield takeEvery(types.EASY1_PRODUCTS.GET_SAGA, getSaga);
}
