import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  makeStyles,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";

export default function BasicTable({
  schema = [],
  rows = [],
  prependRows = [],
  appendRows = [],
}) {
  const renderTableHead = () => {
    return (
      <TableHead>
        <TableRow>
          {schema.map(({ header, style }) => (
            <TableCell key={`table-head-${header}`} style={style}>
              {header}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  const renderTableBody = (row, index) => {
    return schema.map(({ field, accessor, render, style = {} }) => {
      let content;
      if (render) {
        content = render(row, index);
      } else {
        if (!accessor) {
          accessor = () => row[field];
        }
        content = accessor(row, field);
      }
      return (
        <TableCell key={`table-body-${field}`} style={style}>
          {content}
        </TableCell>
      );
    });
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="activities Table" className="table">
        {renderTableHead(schema)}
        <TableBody>
          {prependRows}
          {rows.map((element, index) => (
            <TableRow key={`${element._id}-${index}`}>
              {renderTableBody(element, index)}
            </TableRow>
          ))}
          {appendRows}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
