import React, { useEffect, useRef, useState } from "react";
import Button from "@material-ui/core/Button";

import i18n from "../../../i18n";
import Select from "../../Core/Select";
import DollarInput from "../../DollarInput";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DepositeOptions from "../../../components/DepositeOptions/DepositeOptions.component";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import { Images } from "../../../theme";
import PrimaryButton from "../../Core/PrimaryButton";

const hoursOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
  (element) => ({ name: element, value: element })
);

export default ({
  defaultValues = {},
  timeSlotLength = 5,
  categories = [],
  products = [],
  formOptions = [],
  isGym,
  onSubmit = () => {},
}) => {
  let fileInput = useRef();
  const [name, setName] = useState("");
  const [hours, setHours] = useState();
  const [minutes, setMinutes] = useState();
  const [price, setPrice] = useState(0);
  const [category, setCategory] = useState("");
  const [subcategory, setSubcategory] = useState("");
  const [status, setStatus] = useState(1);
  const [shouldSendTextReminders, setShouldSendTextReminders] = useState(false);
  const [textReminderFrequency, setTextReminderFrequency] = useState(2);
  const [requireDeposit, setRequireDeposit] = useState(false);
  const [depositTarget, setDepositTarget] = useState("everyone");
  const [depositAmount, setDepositAmount] = useState(0);
  const [relatedProductIds, setRelatedProductIds] = useState([]);
  const [forms, setForms] = useState([]);
  const [imageFile, setImageFile] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [description, setDescription] = useState("");
  const [hiddenToCustomers, setHiddenToCustomers] = useState(false);
  const [hidePrice, setHidePrice] = useState(false);

  useEffect(() => {
    console.log(defaultValues);
    setName(defaultValues.name || "");
    setHours(defaultValues.hours);
    setMinutes(defaultValues.minutes);
    setPrice(defaultValues.price || 0);
    setCategory(defaultValues.category || "");
    setSubcategory(defaultValues.subcategory || "");
    setShouldSendTextReminders(defaultValues.shouldSendTextReminders || false);
    setTextReminderFrequency(defaultValues.textReminderFrequency || 2);
    setRequireDeposit(defaultValues.requireDeposit || false);
    setDepositTarget(defaultValues.depositTarget || "everyone");
    setDepositAmount(defaultValues.depositAmount || 0);
    setRelatedProductIds(defaultValues.relatedProductIds || []);
    setForms(defaultValues.forms || []);
    setImageFile(defaultValues.imageFile);
    setImageUrl(defaultValues.imageUrl);
    setDescription(defaultValues.description || "");
    setHiddenToCustomers(defaultValues.hiddenToCustomers || false);
    setHidePrice(defaultValues.hidePrice || false);
  }, [defaultValues]);

  const frequencies = [];
  for (let i = 2; i < 26; i++) {
    frequencies.push({
      name: i18n.t("container.every_x_weeks", { value: i }),
      value: i,
    });
  }
  const minuteOptions = [];
  for (let i = 1; i < parseInt(60 / timeSlotLength); i++) {
    minuteOptions.push({ value: i * timeSlotLength, name: i * timeSlotLength });
  }
  const statuses = [
    { value: 1, name: i18n.t("container.active") },
    { value: 0, name: i18n.t("container.inactive") },
  ];

  const [formErrors, setFormErrors] = useState({});

  const validate = () => {
    const newFormErrors = {};
    if (!name) {
      newFormErrors.nameError = i18n.t("container.add_service_name_required");
    }
    if (!isGym && !hours && !minutes) {
      newFormErrors.minimumTimeError = i18n.t(
        "container.add_service_minimum_time_required"
      );
    }
    if (!category) {
      newFormErrors.categoryError = i18n.t(
        "container.add_service_select_category"
      );
    }
    if (requireDeposit == true && !depositAmount) {
      newFormErrors.depositAmountError = i18n.t("requireDepositError");
    }
    setFormErrors(newFormErrors);
    if (!Object.keys(newFormErrors).length) {
      let minimumTime = parseInt((hours || 0) * 60) + parseInt(minutes || "0");
      let payload = {
        name,
        price,
        category,
        status,
        minimumTime,
        imageFile,
        shouldSendTextReminders,
        textReminderFrequency,
        subcategory,
        relatedProductIds,
        description,
        depositAmount,
        requireDeposit,
        forms,
        depositTarget,
        hiddenToCustomers,
        hidePrice,
      };
      onSubmit(payload);
    } else {
      setTimeout(() => {
        setFormErrors({});
      }, 6000);
    }
  };
  return (
    <div className="row mx-0">
      <div className="col-12 d-md-flex align-items-md-center text-center mb-3">
        <input
          type="file"
          style={{ display: "none" }}
          onChange={(event) => {
            const file = URL.createObjectURL(event.target.files[0]);
            setImageUrl(file);
            setImageFile(event.target.files[0]);
          }}
          ref={fileInput}
          accept="image/*"
        />
        <span
          onClick={() => fileInput.current.click()}
          style={{
            backgroundImage: `url(${
              imageUrl ? imageUrl : Images.saloon_upload_media_icon
            })`,
          }}
          id="saloon-edit-profile"
        />
      </div>
      <div className="col-md-4 mb-1">
        <TextField
          field="name"
          autoFocus
          value={name}
          label={i18n.t("container.profile_service")}
          variant="standard"
          onChange={(e) => setName(e.target.value)}
          error={formErrors.nameError}
        />
      </div>
      <div className="col-md-4 mb-1">
        <div className="row mx-0 px-0 align-items-end">
          <div className="col-6 pr-1 px-0">
            <Select
              options={hoursOptions}
              value={hours}
              label={i18n.t("container.add_service_estimate_time")}
              onChange={(e) => setHours(e.target.value)}
              error={formErrors.minimumTimeError}
            />
          </div>
          <div className="col-6 pl-1 px-0">
            <Select
              options={minuteOptions}
              value={minutes}
              onChange={(e) => setMinutes(e.target.value)}
              error={!!formErrors.minimumTimeError}
            />
          </div>
        </div>
      </div>
      <div className="col-md-4 mb-1">
        <DollarInput
          value={price}
          label={i18n.t("starting_price")}
          onChange={(e) => setPrice(e)}
        />
      </div>
      <div className="col-md-4 mb-2 align-self-center">
        <Select
          options={categories}
          label={i18n.t("container.categories")}
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          error={formErrors.categoryError}
        />
      </div>
      <div className="col-md-4 mb-2">
        <TextField
          field="subcategory"
          value={subcategory}
          label={i18n.t("subcategory")}
          variant="standard"
          onChange={(e) => setSubcategory(e.target.value)}
        />
      </div>
      <div className="col-md-4 mb-2 align-self-center">
        <Select
          options={statuses}
          label={i18n.t("status")}
          value={status}
          onChange={(e) => setStatus(e.target.value)}
        />
      </div>
      <div className="col-md-6 mb-2 align-self-center">
        <Select
          options={[
            { value: true, name: i18n.t("yes") },
            { value: false, name: i18n.t("no") },
          ]}
          label={i18n.t("container.services_send_text_reminders")}
          value={shouldSendTextReminders}
          onChange={(e) => setShouldSendTextReminders(e.target.value)}
        />
      </div>
      <div className="col-md-6 mb-2 align-self-center">
        <Select
          options={frequencies}
          label={i18n.t("text_reminder_frequency")}
          value={textReminderFrequency}
          onChange={(e) => setTextReminderFrequency(e.target.value)}
        />
      </div>
      <div className="col-12 px-0">
        <DepositeOptions
          requireDeposit={requireDeposit}
          depositTarget={depositTarget}
          depositAmount={depositAmount}
          handleDepositAmount={(e) => setDepositAmount(e)}
          handleRequireDeposite={(e) => setRequireDeposit(e.target.value)}
          requireDepositError={formErrors.depositAmountError}
          handleDepositTarget={(e) => setDepositTarget(e.target.value)}
          depositAmountError={""}
          labels={[
            i18n.t("does_require_deposit"),
            i18n.t("deposit_target"),
            i18n.t("deposit_amount"),
          ]}
        />
      </div>
      <div className="col-md-6 mb-2">
        <Autocomplete
          multiple
          id="related-products-standard"
          options={products}
          getOptionLabel={(option) => option.text}
          value={relatedProductIds}
          onChange={(e, value) => {
            setRelatedProductIds(value);
          }}
          getOptionDisabled={(e) => relatedProductIds.length >= 3}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label={`${i18n.t("related_products")} (${i18n.t("maximum_3")})`}
            />
          )}
        />
      </div>
      <div className="col-md-6 mb-2">
        <Autocomplete
          multiple
          id="forms-standard"
          options={formOptions}
          getOptionLabel={(option) => option.formTitle}
          value={forms}
          onChange={(e, value) => setForms(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label={i18n.t("select_form")}
            />
          )}
        />
      </div>
      <div className="col-md-6 mb-2">
        <TextField
          value={description}
          multiline
          rows={3}
          rowsMax={4}
          label={i18n.t("description")}
          variant="standard"
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>
      <div className="col-md-6 mb-2 align-self-center">
        <Select
          options={[
            { value: true, name: i18n.t("yes") },
            { value: false, name: i18n.t("no") },
          ]}
          label={i18n.t("hidden_to_customers")}
          value={hiddenToCustomers}
          onChange={(e) => {
            console.log(e.target.value);
            setHiddenToCustomers(e.target.value);
          }}
        />
      </div>
      <div className="col-md-6 mb-2 align-self-center">
        <Select
          options={[
            { value: true, name: i18n.t("yes") },
            { value: false, name: i18n.t("no") },
          ]}
          label={i18n.t("hide_price")}
          value={hidePrice}
          onChange={(e) => {
            setHidePrice(e.target.value);
          }}
        />
      </div>
      <div className="col-12 row mx-0 justify-content-end">
        <PrimaryButton label={i18n.t("submit")} onClick={validate} />
      </div>
    </div>
  );
};
