import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from "react";
import { useSelector } from "react-redux";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Images } from "../../../theme";
import EmojiFlagsSharpIcon from "@material-ui/icons/EmojiFlagsSharp";
import "./PlanView.scss";
import PlanModal from "./PlanModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import PlanCalendarVerticalView from "./PlanCalendarVerticalView";
import "./PlanCalendar.scss";
const PlanCalendar = ({
  calView,
  calDate,
  planData,
  onEventClick = () => {},
  start,
  onDateClick,
  onDismiss,
  employees,
}) => {
  const [open, setOpen] = useState(false);
  const [showEvents, setShowEvents] = useState([]);
  const calendarRef = useRef(null);

  const selectEvent = useCallback(
    (event) => {
      const props = event.event._def.extendedProps;
      if (props.type == "booking") {
        onEventClick(event.event._def.extendedProps);
      }
    },
    [planData]
  );

  const renderEventContent = useCallback((eventInfo) => {
    const { timeText, event } = eventInfo;
    const isInSalon = event._def.extendedProps.isInSalon;
    const isConfirmed = event._def.extendedProps.isConfirmed;
    const transferValidated = event._def.extendedProps.transferValidated;
    const status = event._def.extendedProps.status;
    const index = event._def.extendedProps.index;
    const _id = event._def.extendedProps._id;
    const employeeName = event._def.extendedProps.employeeName;
    return (
      <>
        {isInSalon && status == 1 ? (
          <div className="flag-wrapper">
            <EmojiFlagsSharpIcon
              className="flag-icon"
              color="white"
              icon={["fas", "flag"]}
            />
          </div>
        ) : null}
        {isConfirmed && status != 3 ? (
          <FontAwesomeIcon
            className="icon"
            color="green"
            icon={["fas", "check-circle"]}
          />
        ) : null}
        {status == 3 ? (
          <span className="trash-icon">
            <FontAwesomeIcon
              color={event.textColor}
              icon={["fas", "trash"]}
              onClick={() => {
                onDismiss && onDismiss(_id, index);
              }}
            />
          </span>
        ) : null}
        {transferValidated && !isInSalon && status == 1 ? (
          <MonetizationOnIcon
            className="money-icon"
            // style={{ fill: event.textColor }}
          />
        ) : null}

        <div className="text-wrapper">
          {employeeName ? (
            <>
              <b>{employeeName}</b>
              <br />
            </>
          ) : null}
          <b>{timeText}</b>&nbsp;
          <i>{event.title}</i>
        </div>
      </>
    );
  }, []);

  useEffect(() => {
    const view = calView === "timeGridWorkWeek" ? "timeGridWeek" : calView;
    calendarRef.current.getApi().changeView(view, calDate);
  }, [calView, calDate]);

  const visibleWeekEnds = useMemo(() => {
    return calView === "timeGridWorkWeek" ? false : true;
  }, [calView]);

  // Hacky way to force full calendar to re render
  const { isDocked, isOpen } = useSelector((state) => ({
    isDocked: state.sidebar.isDocked,
    isOpen: state.sidebar.isOpen,
  }));
  useEffect(() => {
    setTimeout(() => {
      if (calendarRef.current) {
        calendarRef.current.getApi().changeView(calView, calDate);
      }
    }, 300);
  }, [isDocked, isOpen]);
  let slotMinTime = `${start ? start : "00"}:00:00`;
  return (
    <div className="plan-calendar">
      {calView == "resourceTimeGrid" ? (
        <PlanCalendarVerticalView
          planData={planData}
          start={start}
          onDateClick={onDateClick}
          renderEventContent={renderEventContent}
          selectEvent={selectEvent}
          visibleWeekEnds={visibleWeekEnds}
          calendarRef={calendarRef}
          employees={employees}
        />
      ) : (
        <FullCalendar
          locale="en"
          plugins={[timeGridPlugin, interactionPlugin]}
          initialView="timeGridWeek"
          events={planData}
          headerToolbar={false}
          weekends={visibleWeekEnds}
          slotEventOverlap={false}
          eventClick={selectEvent}
          eventContent={renderEventContent}
          expandRows={true}
          contentHeight={2000}
          slotMinTime={slotMinTime}
          ref={calendarRef}
          allDaySlot={false}
          stickyHeaderDates={true}
          slotDuration={"00:15:00"}
          dateClick={({ date }) => onDateClick(date)}
        />
      )}
      <PlanModal open={open} setOpen={setOpen} events={showEvents} />
    </div>
  );
};

export default PlanCalendar;
