import { connect } from "react-redux";
import React, { useEffect, useMemo, useRef, useState } from "react";
import i18n from "../../../i18n";
import BaseModal from "../../BaseModal";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import PrimaryButton from "../../Core/PrimaryButton";
import CustomPlacesAutocomplete from "../../PlacesAutocomplete";
import { MenuItem } from "@material-ui/core";
import Autocomplete from "react-autocomplete";
import TextField from "@material-ui/core/TextField";
import { FormHelperText } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PurchaseClassModal from "../../PurchaseClassModal/PurchaseClassModal";
import { getRequest as getCourseRequest } from "../../../redux/actions/Saloon/Program";
import { removeRequest as removeEnrollmentRequest } from "../../../redux/actions/Saloon/ProgramEnrollment";
import { Box } from "@material-ui/core";
import BasicTable from "../../Core/BasicTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import { setActive } from "../../../redux/actions/Packages";
import CollectPaymentModal from "../../PurchaseClassModal/CollectPaymentModal/CollectPaymentModal";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      className="light-border"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={2}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const telephoneRegex = /[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]/g;

function StudentUpsert({
  open,
  onClose,
  onSave,
  active = {},
  customers,
  getCourseRequest,
  removeEnrollmentRequest,
  accessToken,
  taxes,
}) {
  const form = useRef();
  const [data, setData] = useState({});
  const [phoneNoError, setPhoneNoError] = useState("");
  const [initialized, setInitialized] = useState(false);
  const [addEnrollmentOpen, setAddEnrollmentOpen] = useState(false);
  const [collectPaymentModalOpen, setCollectPaymentModalOpen] = useState(false);
  const [enrollmentToCollect, setEnrollmentToCollect] = useState();
  const [tab, setTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const init = (data) => {
    setData({ ...data });
  };
  useEffect(() => {
    init(active);
    setInitialized(true);
    getCourseRequest({ access_token: accessToken });
  }, []);
  useEffect(() => {
    if (active._id != data._id) {
      init(active);
    }
  }, [active]);
  const handleChange = (key, value) => {
    data[key] = value;
    setData({ ...data });
  };
  const handleSubmit = () => {
    if (validatePhoneNo()) {
      onSave(data);
    }
  };
  const handleEnrollmentDelete = (_id) => {
    Swal.fire({
      title: i18n.t("are_you_sure_delete"),
      text: i18n.t("cannmot_reverse"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ff3600",
      cancelButtonColor: "#354e68",
      confirmButtonText: i18n.t("yes"),
    }).then((result) => {
      if (result.value) {
        removeEnrollmentRequest(
          {
            ids: _id,
            access_token: accessToken,
          },
          {
            success: (result) => {
              SuccessHelper.handleSuccess(i18n.t("successfully_deleted"), true);
              data.enrollments = data.enrollments.filter(
                (element) => element._id != result.data._id
              );
              setData({ ...active });
            },
            failure: () => {
              ErrorHelper.handleErrors(i18n.t("failed_to_delete"), true);
            },
          }
        );
      }
    });
  };
  const handleTransactionAdd = (newEnrollement) => {
    const index = data.enrollments.findIndex(
      (element) => element._id == newEnrollement._id
    );
    if (index != -1) {
      const newEnrollments = [...data.enrollments];
      newEnrollments[index] = newEnrollement;
      setData({ ...data, enrollments: newEnrollments });
    }
  };
  const validatePhoneNo = () => {
    if (
      !data.phoneNo ||
      !data.phoneNo.replace(/\s/g, "").match(telephoneRegex)
    ) {
      setPhoneNoError(i18n.t("invalid_phone_number"));
      return;
    }
    setPhoneNoError("");
    return true;
  };

  if (!initialized) {
    return null;
  }

  return (
    <div>
      {open ? (
        <BaseModal
          open={open}
          title={data._id ? i18n.t("edit_student") : i18n.t("add_student")}
          containerClassName="container"
          content={
            <>
              <AppBar position="static">
                <Tabs
                  value={tab}
                  onChange={handleTabChange}
                  aria-label="simple tabs example"
                >
                  <Tab label={i18n.t("details")} {...a11yProps(0)} />
                  <Tab
                    label={i18n.t("enrollments")}
                    {...a11yProps()}
                    disabled={!data._id}
                  />
                </Tabs>
              </AppBar>
              <TabPanel value={tab} index={0}>
                <div className="d-flex">
                  <ValidatorForm
                    onSubmit={handleSubmit}
                    ref={form}
                    className="w-100 row mx-0"
                    onError={(error) => {
                      validatePhoneNo();
                    }}
                  >
                    <div className="col-12 mb-1">
                      <Autocomplete
                        placeholder={i18n.t(
                          "enter_phone_number_placeholder_type"
                        )}
                        renderInput={(props) => {
                          return <TextField id="phoneNumber" {...props} />;
                        }}
                        items={data.phoneNo ? customers : []}
                        shouldItemRender={(item, value) =>
                          item.phoneNo
                            .toLowerCase()
                            .indexOf(value.toLowerCase()) > -1 ||
                          item.name.toLowerCase().indexOf(value.toLowerCase()) >
                            -1
                        }
                        getItemValue={(item) => item.phoneNo}
                        renderItem={(item, highlighted) => (
                          <MenuItem key={item._id}>{item.name}</MenuItem>
                        )}
                        menuStyle={{
                          zIndex: "9998",
                          position: "absolute",
                          background: "white",
                          top: 64,
                          left: 0,
                        }}
                        inputProps={{
                          className: "full-width",
                          label: i18n.t("container.employe_phone_number"),
                          error: !!phoneNoError,
                        }}
                        wrapperStyle={{
                          width: "100%",
                        }}
                        renderMenu={(children) => {
                          return (
                            <div className="menu">{children.slice(0, 15)}</div>
                          );
                        }}
                        value={data.phoneNo}
                        onChange={(e) =>
                          handleChange("phoneNo", e.target.value)
                        }
                        onSelect={(value, { phoneNo, email, name, userId }) => {
                          setData({
                            ...data,
                            name,
                            phoneNo,
                            email,
                            userId,
                          });
                        }}
                      />
                      {!!phoneNoError && (
                        <FormHelperText error>{phoneNoError}</FormHelperText>
                      )}
                    </div>
                    <TextValidator
                      containerProps={{ className: "col-12 mb-1" }}
                      label={i18n.t("name")}
                      onChange={(e) => handleChange("name", e.target.value)}
                      value={data.name || ""}
                      validators={["required"]}
                      errorMessages={[i18n.t("name_required")]}
                    />
                    <TextValidator
                      containerProps={{ className: "col-12 mb-1" }}
                      label={i18n.t("profile_email")}
                      onChange={(e) => handleChange("email", e.target.value)}
                      value={data.email || ""}
                      validators={["isEmail"]}
                      errorMessages={[i18n.t("invalid_email")]}
                    />
                    <div className="col-12 mb-1">
                      <CustomPlacesAutocomplete
                        defaultAddress={data.addressText || ""}
                        addressSelected={(newMap) =>
                          handleChange("address", newMap)
                        }
                      />
                    </div>
                    <div className="col-12 row mx-0 justify-content-end mt-2 col-12">
                      <PrimaryButton
                        props={{
                          type: "submit",
                        }}
                        label={data._id ? i18n.t("save") : i18n.t("create")}
                      />
                    </div>
                  </ValidatorForm>
                </div>
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <div className="row mx-0 px-0 justify-content-end mb-2">
                  <PrimaryButton
                    label={i18n.t("add")}
                    onClick={() => {
                      setAddEnrollmentOpen(true);
                    }}
                  />
                </div>
                <BasicTable
                  rows={data.enrollments}
                  schema={[
                    {
                      header: i18n.t("program"),
                      field: "program",
                      accessor: (val) =>
                        val.programId?.name || i18n.t("custom"),
                    },
                    {
                      header: i18n.t("collected"),
                      field: "collected",
                      accessor: (val) =>
                        (val.amount - val.balanceDue).toFixed(2),
                    },
                    {
                      header: i18n.t("balance_due"),
                      field: "balanceDue",
                      accessor: (val) => val.balanceDue.toFixed(2),
                    },
                    {
                      key: "action",
                      header: "",
                      style: { width: 120 },
                      render: (val) => {
                        return (
                          <div className="row mx-0">
                            <FontAwesomeIcon
                              className="view-icon"
                              style={{ backgroundColor: "green" }}
                              color="white"
                              icon={["fas", "hand-holding-usd"]}
                              onClick={() => {
                                setEnrollmentToCollect(val);
                                setCollectPaymentModalOpen(true);
                              }}
                            />
                            <FontAwesomeIcon
                              className="view-icon"
                              style={{ backgroundColor: "red" }}
                              color="white"
                              icon={["fas", "trash"]}
                              onClick={() => {
                                handleEnrollmentDelete(val._id);
                              }}
                            />
                          </div>
                        );
                      },
                    },
                  ]}
                />
              </TabPanel>
            </>
          }
          onClose={onClose}
        />
      ) : null}
      {addEnrollmentOpen && (
        <PurchaseClassModal
          open={addEnrollmentOpen}
          setOpen={setAddEnrollmentOpen}
          onClose={() => {
            setAddEnrollmentOpen(false);
          }}
          accessToken={accessToken}
          student={data}
          taxes={taxes}
        />
      )}
      {collectPaymentModalOpen ? (
        <CollectPaymentModal
          open={collectPaymentModalOpen}
          setOpen={setCollectPaymentModalOpen}
          enrollment={enrollmentToCollect}
          accessToken={accessToken}
          onSuccess={handleTransactionAdd}
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  loading: state.vendor.isFetching,
});

const action = { getCourseRequest, removeEnrollmentRequest };

export default connect(mapStateToProps, action)(StudentUpsert);
