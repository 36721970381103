import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  ExpandLess,
  ExpandMore,
  Send as SendIcon,
  DashboardOutlined,
  AccountCircleOutlined,
  ChatBubbleOutlineOutlined,
  PeopleAltOutlined,
  DateRangeOutlined,
  EventOutlined,
  HistoryOutlined,
  HourglassEmptyOutlined,
  AddCircleOutlineOutlined,
  LocalOfferOutlined,
  ListAltOutlined,
  ImportExportOutlined,
  AccountBalanceOutlined,
  BusinessCenterOutlined,
  AccessTimeOutlined,
  PermMediaOutlined,
  LinkOutlined,
  LanguageOutlined,
  AssessmentOutlined,
  AttachMoneyOutlined,
  StarBorderOutlined,
  AccountBalanceWalletOutlined,
  SettingsOutlined,
  DynamicFeedOutlined,
  HttpsOutlined,
  DescriptionOutlined,
  MenuBookOutlined,
  DevicesOutlined,
  MonetizationOnOutlined,
  SendOutlined,
  ShoppingBasketOutlined,
  PolicyOutlined,
  FormatAlignJustifyOutlined,
  LibraryBooksOutlined,
  AssignmentIndOutlined,
  ReceiptOutlined,
  ExitToAppOutlined,
  SecurityOutlined,
  SchoolOutlined,
  GroupOutlined,
  PersonOutlineOutlined,
  ClassOutlined,
  TodayOutlined,
} from "@material-ui/icons";
import i18n from "../../../i18n";
import { Images } from "../../../theme";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import { SuccessHelper } from "../../../helpers";
import { request as saloon_logout } from "../../../redux/actions/Saloon/LogOut";
import { request as employee_logout } from "../../../redux/actions/Employee/LogOut";
import { clearLogout as clear_logout } from "../../../redux/actions/LogOut";
import { getRequest as getReceptionistAccess } from "../../../redux/actions/ReceptionistAccess";
import { getRequest as getManagerAccess } from "../../../redux/actions/ManagerAccess";
import { Translation, withTranslation } from "react-i18next";
import MenuItem from "@material-ui/core/MenuItem";
import Popover from "@material-ui/core/Popover";

const routeParentMap = {
  "/saloon-add-employee": "employee",
  "/saloon-company-employee": "employee",
  "/saloon-all-funds-requests": "employee",
  "/saloon-roles": "employee",
  "/saloon-my-inventory": "products_and_services",
  "/saloon-orders": "products_and_services",
  "/saloon-add-inventory": "products_and_services",
  "/saloon-all-services": "products_and_services",
  "/saloon-add-service": "products_and_services",
  "/saloon-packages": "products_and_services",
  "/saloon-customers": "customers",
  "/saloon-add-customer": "customers",
  "/saloon-import-customers": "customers",
  "/saloon-taxes": "taxes",
  "/saloon-add-tax": "taxes",
  "/saloon-employee-report": "reports",
  "/saloon-tax-report": "reports",
  "/saloon-booking-report": "reports",
  "/saloon-sales-report": "reports",
  "/saloon-payroll-report": "reports",
  "/saloon-expense-report": "reports",
  "/saloon-product-report": "reports",
  "/saloon-no-show-report": "reports",
  "/saloon-live-display-settings": "myBusiness",
  "/saloon-security": "myBusiness",
  "/saloon-my-appointment": "appointments",
  "/saloon-booking": "appointments",
  "/saloon-waiting-list": "appointments",
  "/saloon-working-hours": "myBusiness",
  "/saloon-upload-media": "myBusiness",
  "/saloon-link-social-accounts": "myBusiness",
  "/saloon-my-site": "myBusiness",
  "/saloon-refund-policy": "myBusiness",
  "/saloon-online-payments": "myBusiness",
  "/saloon-black-listing-settings": "myBusiness",
  "/saloon-communication-settings": "myBusiness",
  "/saloon-account-settings": "billing",
  "/saloon-pricing-plains": "billing",
  "/saloon-billing-history": "billing",
  "/saloon-devices-and-equipment": "billing",
  "/saloon-expense": "purchase",
  "/saloon-vendor": "purchase",
  "/employee-my-appointment": "appointments",
  "/employee-booking": "appointments",
  "/saloon-classes": "programs",
  "/saloon-programs": "programs",
  "/saloon-students": "programs",
  "/saloon-student-groups": "programs",
  "/saloon-program-planner": "programs",
  "/saloon-semester": "programs",
};

export const resourceToConfig = {
  Dashboard: ["/saloon-dashboard", DashboardOutlined, "container.dashboard"],
  Profile: ["/saloon-edit-profile", AccountCircleOutlined, "container.profile"],
  WorkingHours: [
    "/saloon-working-hours",
    AccessTimeOutlined,
    "container.working_hours",
  ],
  UploadMedia: [
    "/saloon-upload-media",
    PermMediaOutlined,
    "container.upload_media",
  ],
  SocialLinks: [
    "/saloon-link-social-accounts",
    LinkOutlined,
    "container.dashboard_social_media_icons",
  ],
  SiteSetUp: ["/saloon-my-site", LanguageOutlined, "container.site_set_up"],
  RefundPolicy: ["/saloon-refund-policy", PolicyOutlined, "refund_policy"],
  OnlinePayments: [
    "/saloon-online-payments",
    MonetizationOnOutlined,
    "container.online_payments",
  ],
  BlackListSettings: [
    "/saloon-black-listing-settings",
    ListAltOutlined,
    "container.black_listing_settings",
  ],
  LiveDisplaySettings: [
    "/saloon-live-display-settings",
    DynamicFeedOutlined,
    "container.live_display_settings",
  ],
  Security: ["/saloon-security", HttpsOutlined, "container.security"],
  CommunicationSettings: [
    "/saloon-communication-settings",
    ChatBubbleOutlineOutlined,
    "container.communication_settings",
  ],
  EmployeeList: [
    "/saloon-company-employee",
    LocalOfferOutlined,
    "container.dashboard_employe_list",
  ],
  AddEmployee: [
    "/saloon-add-employee",
    AddCircleOutlineOutlined,
    "container.add_employee_add_employe",
  ],
  FundRequests: ["/saloon-all-funds-requests", SendOutlined, "funds_requests"],
  Roles: ["/saloon-roles", SecurityOutlined, "container.roles"],
  Calendar: ["/saloon-my-appointment", EventOutlined, "container.my_calendar"],
  BookingHistory: [
    "/saloon-booking",
    HistoryOutlined,
    "container.profile_booking_history",
  ],
  WaitingList: ["/saloon-waiting-list", HourglassEmptyOutlined, "waiting_list"],
  Inventory: [
    "/saloon-my-inventory",
    ListAltOutlined,
    "container.dashboard_inventory_list",
  ],
  AddInvetory: [
    "/saloon-add-inventory",
    AddCircleOutlineOutlined,
    "container.add_product",
  ],
  AllServices: [
    "/saloon-all-services",
    ListAltOutlined,
    "container.dashboard_services_list",
  ],
  AddService: [
    "/saloon-add-service",
    AddCircleOutlineOutlined,
    "container.add_service",
  ],
  Packages: ["/saloon-packages", LibraryBooksOutlined, "packages_classes"],
  Orders: ["/saloon-orders", ShoppingBasketOutlined, "container.orders"],
  Vendor: ["/saloon-vendor", AssignmentIndOutlined, "vendor"],
  Expense: ["/saloon-expense", ReceiptOutlined, "expense"],
  Form: ["/saloon-forms", FormatAlignJustifyOutlined, "forms"],
  Customers: ["/saloon-customers", ListAltOutlined, "container.customers_list"],
  AddCustomer: [
    "/saloon-add-customer",
    AddCircleOutlineOutlined,
    "container.add_customer",
  ],
  ImportCustomer: [
    "/saloon-import-customers",
    ImportExportOutlined,
    "container.customer_import",
  ],
  Chat: [
    "/saloon-online-support",
    ChatBubbleOutlineOutlined,
    "container.chat_box",
  ],
  BookingReport: [
    "/saloon-booking-report",
    EventOutlined,
    "container.booking_report",
  ],
  EmployeeReport: [
    "/saloon-employee-report",
    PeopleAltOutlined,
    "container.employee_report",
  ],
  TaxReport: ["/saloon-tax-report", AccountBalanceOutlined, "tax_report"],
  SalesReport: ["/saloon-sales-report", AttachMoneyOutlined, "sales_report"],
  PayrollReport: [
    "/saloon-payroll-report",
    AttachMoneyOutlined,
    "payroll_report",
  ],
  ExpenseReport: [
    "/saloon-expense-report",
    AttachMoneyOutlined,
    "expense_report",
  ],
  ProductReport: [
    "/saloon-product-report",
    LocalOfferOutlined,
    "product_report",
  ],
  NoShowReport: [
    "/saloon-no-show-report",
    AttachMoneyOutlined,
    "no_show_report",
  ],
  Rating: ["/saloon-rating", StarBorderOutlined, i18n.t("container.rating")],
  Wallet: ["/saloon-wallet", AccountBalanceWalletOutlined, "container.wallet"],
  PricingPlans: [
    "/saloon-pricing-plains",
    MenuBookOutlined,
    "container.pricing_plans",
  ],
  BillingHistory: [
    "/saloon-billing-history",
    HistoryOutlined,
    "container.billing_history",
  ],
  AccountSettings: [
    "/saloon-account-settings",
    SettingsOutlined,
    "container.account_settings",
  ],
  DevicesAndEquipement: [
    "/saloon-devices-and-equipment",
    DevicesOutlined,
    "container.devices_and_equipment",
  ],
  TaxList: ["/saloon-taxes", ListAltOutlined, "container.taxe_list"],
  AddTax: ["/saloon-add-tax", AddCircleOutlineOutlined, "container.add_tax"],
  Class: ["/saloon-classes", ClassOutlined, "classes"],
  Program: ["/saloon-programs", LibraryBooksOutlined, "programs"],
  Student: ["/saloon-students", PersonOutlineOutlined, "students"],
  StudentGroup: ["/saloon-student-groups", GroupOutlined, "student_groups"],
  CoursePlanner: ["/saloon-program-planner", TodayOutlined, "course_planner"],
  Semester: ["/saloon-semester", TodayOutlined, "semesters"],
};

function SaloonSidebarMenu(props) {
  const {
    receptionistAllowedResource,
    managerAllowedResource,
    clear_logout,
    saloonLogout,
    employeeLogout,
    getReceptionistAccess,
    getManagerAccess,
    type,
  } = props;
  const [openDropdown, setOpenDropdown] = useState("");
  const [routeName, setRouteName] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [isGym, setIsGym] = useState(false);
  const [hasEmployeeAccess, setHasEmployeeAccess] = useState(false);
  const [hasSaloonAccess, setHasSaloonAccess] = useState(false);
  const [hasReceptionAccess, setHasReceptionAccess] = useState(false);
  const [hasManagerAccess, setHasManagerAccess] = useState(false);
  const [current, setCurrent] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuItems, setMenuItems] = useState([]);
  const handleModuleSelectorClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleModuleSelectorClose = () => {
    setAnchorEl(null);
  };
  const onCurrentChange = (current) => {
    setCurrent(current);
    localStorage.setItem("menu_type", current);
    handleModuleSelectorClose();
  };
  useEffect(() => {
    const saloonData = JSON.parse(sessionStorage.getItem("saloon"));
    setAccessToken(saloonData?.access_token);
    setRouteName(props.history.location.pathname);
    setIsGym(JSON.parse(sessionStorage.getItem("company"))?.function == "gym");
    const hasEmployeeAccess =
      sessionStorage.getItem("employee") &&
      !!Object.keys(JSON.parse(sessionStorage.getItem("employee"))).length;
    setHasEmployeeAccess(hasEmployeeAccess);

    const employeeData = JSON.parse(sessionStorage.getItem("employee"));
    let hasSaloonAccess =
      saloonData &&
      !!Object.keys(saloonData).length &&
      saloonData.role.includes("3");
    setHasSaloonAccess(hasSaloonAccess);
    let hasReceptionAccess = employeeData.role.includes("6");
    setHasReceptionAccess(hasReceptionAccess);
    let hasManagerAccess = employeeData.role.includes("7");
    setHasManagerAccess(hasManagerAccess);
    let current = "";
    const menuItems = [];
    if (hasEmployeeAccess) {
      current = "employee";
      menuItems.push("employee");
    }
    if (hasReceptionAccess) {
      current = "receptionist";
      menuItems.push("receptionist");
    }
    if (hasManagerAccess) {
      current = "manager";
      menuItems.push("manager");
    }
    if (hasSaloonAccess) {
      current = "salon";
      menuItems.push("salon");
    }
    setMenuItems(menuItems.reverse());
    onCurrentChange(
      menuItems.includes(localStorage.getItem("menu_type"))
        ? localStorage.getItem("menu_type")
        : current
    );
    const access_token = saloonData?.access_token || employeeData?.access_token;
    setAccessToken(access_token);
    if (!receptionistAllowedResource) {
      getReceptionistAccess({ access_token });
    }
    if (!managerAllowedResource) {
      getManagerAccess({ access_token });
    }
  }, []);

  useEffect(() => {
    setOpenDropdown(routeParentMap[routeName]);
  }, [routeName]);
  const handleNavigation = (routeName) => {
    props.history.push(routeName);
  };

  useEffect(() => {
    setRouteName(`/${props.path.split("/")?.[1]}`);
  }, [props.path]);

  useEffect(() => {
    if (saloonLogout) {
      if (
        !saloonLogout.failure &&
        !saloonLogout.isFetching &&
        !saloonLogout.errorMessage &&
        saloonLogout.data &&
        saloonLogout.data.success
      ) {
        clear_logout();
        SuccessHelper.handleSuccess("You have successfully logged out.", true);
        handleNavigation("/saloon-login");
      }
    }
  }, [saloonLogout]);

  useEffect(() => {
    if (employeeLogout) {
      if (
        !employeeLogout.failure &&
        !employeeLogout.isFetching &&
        !employeeLogout.errorMessage &&
        employeeLogout.data &&
        employeeLogout.data.success
      ) {
        clear_logout();
        SuccessHelper.handleSuccess("You have successfully logged out.", true);
        handleNavigation("/employee-login");
      }
    }
  }, [employeeLogout]);

  const handleClick = (open) => {
    setOpenDropdown(open);
  };
  const renderItem = (route, Icon = SendIcon, title, className) => {
    return (
      <ListItem
        key={route}
        button
        className={`side-bar-item ${className} ${
          routeName == route ? "active" : ""
        }`}
        onClick={() => handleNavigation(route)}
      >
        <ListItemIcon>{Icon && <Icon />}</ListItemIcon>
        <ListItemText primary={i18n.t(title)} />
      </ListItem>
    );
  };
  const renderListItem = (a, className) => {
    if (Array.isArray(a)) {
      return renderItem(...a, className);
    } else {
      let b = a;
      if (typeof a == "object") {
        b = a.resource;
        a = a.key;
      }
      if (
        hasSaloonAccess ||
        (hasReceptionAccess && receptionistAllowedResource?.[b]) ||
        (hasManagerAccess && managerAllowedResource?.[b])
      ) {
        return renderItem(...resourceToConfig[a], className);
      }
      return null;
    }
  };

  const renderDropdownListItem = (
    key,
    Icon = SendIcon,
    title,
    children = []
  ) => {
    children = children
      .map((element, ind) => {
        return renderListItem(element, "nested");
      })
      .filter((element) => !!element);
    if (children.length) {
      return (
        <>
          <ListItem
            button
            onClick={() => handleClick(openDropdown == key ? "" : key)}
            className={`side-bar-item ${openDropdown == key ? "active" : ""}`}
          >
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            <ListItemText primary={title} />
            {openDropdown == key ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openDropdown == key} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {children}
            </List>
          </Collapse>
        </>
      );
    }
    return null;
  };
  const handleLogout = () => {
    props.saloon_logout({ access_token: accessToken });
    SuccessHelper.handleSuccess("You have successfully logged out.", true);
    props.history.push("/");
  };
  const renderCurrentMenu = () => {
    switch (current) {
      case "salon":
      case "receptionist":
      case "manager": {
        return (
          <ul className="sidebar-list">
            {renderListItem("Dashboard")}
            {renderListItem("Profile")}
            {renderDropdownListItem(
              "myBusiness",
              BusinessCenterOutlined,
              i18n.t("container.my_business"),
              [
                "WorkingHours",
                "UploadMedia",
                "SocialLinks",
                "SiteSetUp",
                "RefundPolicy",
                "OnlinePayments",
                "BlackListSettings",
                "LiveDisplaySettings",
                "Security",
                "CommunicationSettings",
              ]
            )}
            {renderDropdownListItem(
              "employee",
              PeopleAltOutlined,
              i18n.t("container.employee"),
              ["EmployeeList", "AddEmployee", "FundRequests", "Roles"]
            )}
            {renderDropdownListItem(
              "appointments",
              DateRangeOutlined,
              i18n.t("container.my_appointments"),
              [
                { key: "Calendar", resource: "Booking" },
                { key: "BookingHistory", resource: "Booking" },
                ...(!isGym ? ["WaitingList"] : []),
              ]
            )}
            {renderDropdownListItem(
              "products_and_services",
              LocalOfferOutlined,
              i18n.t("container.products_and_services"),
              [
                "Inventory",
                "AddInvetory",
                "AllServices",
                "AddService",
                "Packages",
                "Orders",
              ]
            )}
            {renderDropdownListItem(
              "programs",
              SchoolOutlined,
              i18n.t("programs"),
              [
                { key: "Program", resource: "Program" },
                { key: "Class", resource: "Program" },
                { key: "Semester", resource: "Program" },
                { key: "StudentGroup", resource: "Program" },
                { key: "Student", resource: "Program" },
                { key: "CoursePlanner", resource: "Program" },
              ]
            )}
            {renderDropdownListItem(
              "purchase",
              AttachMoneyOutlined,
              i18n.t("purchase"),
              ["Vendor", "Expense"]
            )}
            {renderDropdownListItem(
              "customers",
              PeopleAltOutlined,
              i18n.t("container.customers"),
              ["Customers", "AddCustomer", "ImportCustomer"]
            )}
            {renderListItem("Form")}
            {renderListItem("Chat")}
            {renderDropdownListItem(
              "reports",
              AssessmentOutlined,
              i18n.t("container.reports"),
              [
                "BookingReport",
                ...(isGym
                  ? []
                  : [
                      "EmployeeReport",
                      "TaxReport",
                      "SalesReport",
                      "PayrollReport",
                    ]),
                "ExpenseReport",
                "ProductReport",
                "NoShowReport",
              ]
            )}
            {!isGym && renderListItem("Rating")}
            {!isGym && renderListItem("Wallet")}
            {renderDropdownListItem(
              "billing",
              DescriptionOutlined,
              i18n.t("container.billing"),
              [
                "PricingPlans",
                "BillingHistory",
                "AccountSettings",
                "DevicesAndEquipement",
              ]
            )}
            {renderDropdownListItem(
              "taxes",
              AccountBalanceOutlined,
              i18n.t("container.taxes"),
              ["TaxList", "AddTax"]
            )}
            <ListItem
              button
              className={`side-bar-item`}
              onClick={() => handleLogout()}
            >
              <ListItemIcon>
                <ExitToAppOutlined />
              </ListItemIcon>
              <ListItemText primary={i18n.t("logout")} />
            </ListItem>
          </ul>
        );
      }
      case "employee": {
        return (
          <ul className="sidebar-list">
            {renderListItem([
              "/employee-dashboard",
              DashboardOutlined,
              i18n.t("container.dashboard"),
            ])}
            {renderListItem([
              "/employee-edit-profile",
              AccountCircleOutlined,
              i18n.t("container.profile"),
            ])}
            {renderListItem([
              "/employee-chatbox",
              ChatBubbleOutlineOutlined,
              i18n.t("container.chat_box"),
            ])}
            {renderDropdownListItem(
              "appointments",
              DateRangeOutlined,
              i18n.t("container.my_appointments"),
              [
                [
                  "/employee-my-appointment",
                  EventOutlined,
                  i18n.t("container.my_calendar"),
                ],
                [
                  "/employee-booking",
                  HistoryOutlined,
                  i18n.t("container.profile_booking_history"),
                ],
              ]
            )}
            {renderListItem([
              "/employee-add-working-hour",
              AccessTimeOutlined,
              i18n.t("container.working_hours"),
            ])}
            {renderListItem([
              "/employee-rating",
              StarBorderOutlined,
              i18n.t("container.rating"),
            ])}
            {renderListItem([
              "/employee-active-saloon",
              BusinessCenterOutlined,
              i18n.t("container.active_salon"),
            ])}
            {renderListItem([
              "/employee-customers",
              PeopleAltOutlined,
              "Customers",
            ])}
            {renderListItem([
              "/employee-social-media",
              LinkOutlined,
              i18n.t("container.dashboard_social_media_icons"),
            ])}
            {renderListItem([
              "/employee-upload-media",
              PermMediaOutlined,
              i18n.t("container.upload_media"),
            ])}
            {renderListItem([
              "/employee-wallet",
              AccountBalanceWalletOutlined,
              i18n.t("container.wallet"),
            ])}
            {renderListItem([
              "/employee-request-funds",
              SendOutlined,
              i18n.t("container.request_funds"),
            ])}
            {/* {this.renderLogout()} */}
          </ul>
        );
      }
    }
  };
  return (
    <>
      <div
        className="text-center py-1"
        style={{ zIndex: 3, borderBottom: "1px solid rgba(0, 0, 0, .15)" }}
      >
        <img src={Images.easy1_logo} style={{ height: 40 }} />
      </div>
      {menuItems.length && menuItems.length != 1 ? (
        <>
          <ListItem
            button
            className={`side-bar-item flex-column justify-content-center module-selector`}
            onClick={handleModuleSelectorClick}
          >
            <ListItemText
              className="text-center font-bold"
              primary={i18n.t(current)}
            />
            <ExpandMore />
          </ListItem>

          <Popover
            open={Boolean(anchorEl)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            PaperProps={{
              className: "makeStyles-drawerPaper-2 module-selector-popover",
            }}
            anchorEl={anchorEl}
            onClose={handleModuleSelectorClose}
          >
            {menuItems.map((menuItem) => (
              <MenuItem
                key={menuItem}
                onClick={() => {
                  onCurrentChange(menuItem);
                }}
              >
                {i18n.t(menuItem)}
              </MenuItem>
            ))}
          </Popover>
        </>
      ) : null}
      {renderCurrentMenu()}
    </>
  );
}

const mapStateToProps = (state) => ({
  saloonLogout: state.saloonLogout,
  employeeLogout: state.employeeLogout,
  receptionistAllowedResource: state.receptionistAccess.data?.resourceMap,
  managerAllowedResource: state.managerAccess.data?.resourceMap,
});

const action = {
  employee_logout,
  saloon_logout,
  clear_logout,
  getReceptionistAccess,
  getManagerAccess,
};

export default connect(mapStateToProps, action)(SaloonSidebarMenu);
