import { connect } from "react-redux";
import React, { Component } from "react";
import { Translation } from "react-i18next";
import i18n from "../../../i18n";
import Pagination from "react-js-pagination";
import axios from "axios";

import { employee_punch_in } from "../../../config/WebServices";
import { employee_punch_out } from "../../../config/WebServices";
import { employee_adjust_hours } from "../../../config/WebServices";

import {
  getAllEmployeeAssociatedWithCompany,
  hireCompanyEmployee,
  saloonGetAllService,
  editCompanyEmploye,
  getCompanyEmployeeInfo,
} from "../../../config/simpleApiCalls";
import { deleteEmployeeFromCompany } from "../../../config/simpleApiCalls";
import { Images } from "../../../theme";
import { DotsLoader } from "../../../components";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
// import { adminGetUsers, adminDeleteUser } from '../../../config/simpleApiCalls';
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import moment from "moment";
import Swal from "sweetalert2";

import Styles from "./sytles";
import { getNestedValue } from "../../../util/objectMethods";
// import { getCompanyEmployeeInfo } from "../../../config/simpleApiCalls";
// import { edit_company_employe } from "../../../config/WebServices";
import _ from "lodash";
import { SketchPicker } from "react-color";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from "@fortawesome/free-solid-svg-icons";

import TextField from "@material-ui/core/TextField";

import styled from "styled-components";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import BaseModal from "../../../components/BaseModal/index";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import FavoriteBorder from "@material-ui/icons/FavoriteBorder";

const weekNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const WorkingHours = styled.span`
  cursor: pointer;
`;

const TimeSheetButtons = styled(Button)`
  margin: 0 0.7em;
`;
const DayTimePicker = styled(TextField)`
  width: 35%;
`;

const TimeSheetIcon = styled(WatchLaterIcon)`
  width: 30px;
  height: 30px;
  color: #175722;
`;
const PinInput = styled.input`
  background-color: #ffffff;
  border-color: #ebebeb;
`;

class CompanyUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      access_token: "",
      user: null,
      isloading: false,
      showEditDelete: "",
      searchText: "",
      activePage: 1,
      usersList: [],
      // employeeList: [],
      filterUsersList: [],
      employeesWorkingHoursData: [],
      employeesData: [],
      selectedEmployeinpopup: null,
      splitError: "",
      split: 0,
      workingHoursPopupOpen: false,
      employeeSchedule: false,
      modalEmployeeForTimeSheet: "",
      punchMap: {},
    };
    this.handleRoutToChat = this.handleRoutToChat.bind(this);
  }

  componentDidMount = async () => {
    const saloonData = await JSON.parse(sessionStorage.getItem("saloon"));
    if (saloonData) {
      this.setState({
        userId: getNestedValue(saloonData, ["_id"]),
        companyId: saloonData.companyId,
        access_token: saloonData.access_token,
      });
      this.getAllCompanyEmployees();
      this.handleSaloonGetAllService();
      // const companydata = await JSON.parse(sessionStorage.getItem('company'))
    }
  };

  onChangePrice = (text, ind) => {
    const { serviceList } = this.state;
    serviceList[ind].price = parseFloat(text.target.value);
    this.setState({ serviceList });
  };

  gettingCompanyEmployeeInfo = async (e) => {
    const { serviceList } = this.state;
    this.setState({ selectedEmployeinpopup: e.employee.weekPlans });
    let allServices = [];
    let settings = {};

    const payload = {
      employeeId: e.employee._id,
    };

    const { access_token, companyId } = this.state;
    this.setState({ showAddEmployeePopup: true });

    getCompanyEmployeeInfo(payload, access_token)
      .then((res) => {
        if (res.data.success) {
          const employeePotentialServices = [];
          const companyServices = res.data.result.companyServices;
          serviceList.map((companyService) => {
            const result = res.data.result.employeeId.services.find(
              (service) => {
                return companyService.serviceId._id === service.serviceId;
              }
            );
            if (result) {
              companyService.isSelected = !!companyServices.find(
                (element) => element._id == companyService._id
              );
              employeePotentialServices.push(companyService);
            }
          });

          for (let i = 0; i < res.data.result.weekPlans.length; i++) {
            res.data.result.weekPlans[i].dayName = weekNames[i];
          }

          settings.isEmployeeIndependent =
            res.data.result.isEmployeeIndependent || false;
          settings.displayWorkingHours =
            res.data.result.displayWorkingHours || false;
          settings.color = res.data.result.color || "#000000";
          settings.seat = res.data.result.seat;
          settings.split = res.data.result.split;
          settings.allowCheckIn = res.data.result.allowCheckIn;
          settings.pinCode = res.data.result.pinCode;
          settings.isReceptionist = res.data.result.isReceptionist;
          settings.isManager = res.data.result.isManager;
          settings.baseProductCommission =
            res.data.result.baseProductCommission;

          res.data.result.companyServices.map((v) => {
            res.data.result.companyServices.map((services) => {
              if (!v.isActive) {
                allServices.push(services);
              }
            });
          });

          for (let i = 0; i < allServices.length; i++) {
            allServices[i].isSelected = allServices[i]["isActive"];
          }
          const currentEmployeeServiceList = [];
          res.data.result.companyServices.forEach((mainCompany, index) => {
            res.data.result.companyServices.forEach((companyService) => {
              if (mainCompany._id === companyService.serviceId._id) {
                // serviceList[index].price = companyServices.price;
                currentEmployeeServiceList.push(companyService);
              }
            });
          });
          this.setState({
            isloading: false,
            currentEmployeeServiceList,
            employeesWorkingHoursData: res.data.result.weekPlans,
            companyServices: allServices,
            selectedEmployeeId: e.employee._id,
            companyServices: res.data.result.companyServices,
            employeePotentialServices,
            settings,
          });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
      });
  };

  updateCompanyEmployeInfo = () => {
    const {
      selectedEmployeeId,
      serviceList,
      employeesWorkingHoursData,
      companyServices,
      access_token,
      settings,
      employeePotentialServices,
    } = this.state;
    let selectedServices = [];
    employeePotentialServices.map((v, i) => {
      let obj = {};
      if (v.isSelected) {
        obj.serviceId = v._id;
        obj.price = v.price;

        selectedServices.push(obj);
      }
    });

    for (let i = 0; i < employeesWorkingHoursData.length; i++) {
      if (employeesWorkingHoursData[i].availableStatus === 0) {
        delete employeesWorkingHoursData[i]["checkIn"];
        delete employeesWorkingHoursData[i]["checkOut"];
      }
      delete employeesWorkingHoursData[i]["dayName"];
    }

    const payload = {
      employeeId: selectedEmployeeId,
      companyServices: selectedServices,
      weekPlans: employeesWorkingHoursData,
      ...settings,
    };
    this.setState({ isloading: true });
    editCompanyEmploye(payload, access_token)
      .then((res) => {
        if (res.data.success) {
          this.getAllCompanyEmployees();
          this.setState({
            isloading: false,
            showAddEmployeePopup: false,
            employeesWorkingHoursData: [],
          });
          SuccessHelper.handleSuccess(
            "Employee has been successfully Updated!",
            true
          );
        }
      })
      .catch((error) => {
        ErrorHelper.handleErrors(i18n.t(error.data.code), true);
        this.setState({ isloading: false });
      });
  };

  onChangeServiceCheckbox = (e, ind) => {
    const { employeePotentialServices } = this.state;
    if (e.target.checked) {
      employeePotentialServices[ind].isSelected = true;
      this.setState({ employeePotentialServices });
    } else {
      employeePotentialServices[ind].isSelected = false;
      this.setState({ employeePotentialServices });
    }
  };

  getAllCompanyEmployees = () => {
    this.setState({ isloading: true });
    const { access_token } = this.state;
    let workingHours = {};

    getAllEmployeeAssociatedWithCompany(access_token)
      .then((res) => {
        if (res.data.success) {
          res.data.Data.map((val) => {
            let schedules = [];
            val.employee.weekPlans.map((day) => {
              if (day.availableStatus === 1) {
                let schedule = {};
                schedule.start = day.checkIn;
                schedule.end = day.checkOut;
                schedule.weekDay = weekNames[day.dayOfWeek - 1];
                schedules[schedules.length] = schedule;

                // console.log("yes");
              }
            });
            workingHours[val._id] = schedules;
          });
          const punchMap = {};
          res.data.Data.map((employee) => (punchMap[employee._id] = false));
          console.log({ punchMap });
          this.setState({
            isloading: false,
            usersList: res.data.Data,
            workingHours,
            punchMap,
          });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
      });
  };

  deleteCompanyEmployee = (employeeId) => {
    const { access_token } = this.state;
    const payload = { employeeId };
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#354e68",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        // ADD YOUR CODE HERE
        this.setState({ isloading: true });
        deleteEmployeeFromCompany(payload, access_token)
          .then((res) => {
            if (res.data.success) {
              SuccessHelper.handleSuccess("Successfully Deleted", true);
              this.getAllCompanyEmployees();
            }
          })
          .catch((error) => {
            this.setState({ isloading: false });
          });
      }
    });
  };

  handleNavigation = (route) => {
    this.props.history.push(route);
  };
  handleRoutToChat = (firstName, salonId, imgURL) => {
    this.props.history.push({
      pathname: "/saloon-chat",
      state: { name: firstName, _id: salonId, imgURL },
    });
  };
  onChangeSearchText = (text) => {
    const { usersList } = this.state;
    let filterUsersList =
      usersList &&
      usersList.filter((val) => {
        if (!val.user) {
          return false;
        }
        let firstName = val.user.firstName.toUpperCase();
        let lastName = val.user.lastName.toUpperCase();
        let email = val.user.email.toUpperCase();
        let phoneNo = `${val.user.phoneNo}`;
        let searchText = text.target.value.toUpperCase();
        return (
          firstName.indexOf(searchText) > -1 ||
          lastName.indexOf(searchText) > -1 ||
          phoneNo.indexOf(searchText) > -1 ||
          email.indexOf(searchText) > -1
        );
      });
    this.setState({
      filterUsersList,
      searchText: text.target.value,
      activePage: 1,
    });
  };

  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber });
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  renderSearchBar = () => {
    const { searchText } = this.state;
    return (
      <>
        <div className="col-12 col-lg-4 col-md-4 mt-4">
          <span>
            <span className="saloon-dashboard-heading">
              {i18n.t("container.employee")}
            </span>
            {/* <p className="saloon-dashboard-sub-heading">2 Employees selected</p> */}
          </span>
        </div>
        <div className="col-12 col-lg-8 col-md-8 mt-4">
          <div className=" d-flex justify-content-end row">
            <div className="col-12 col-lg-7 col-md-6">
              <div className="form-group">
                <input
                  style={{ backgroundColor: "#FFFFFF", borderColor: "#EBEBEB" }}
                  type="text"
                  id="addEmployee_search_bar"
                  className="form-control form-control-sm pl-5"
                  placeholder={i18n.t(
                    "container.add_employee_search_placeholder"
                  )}
                  onChange={(text) => this.onChangeSearchText(text)}
                  value={searchText}
                />
                <img src={Images.saloon_search} className="search-bar-icon" />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  renderTableBody = (usersList) => {
    const { activePage, userId } = this.state;
    let previousPageNumber = activePage - 1;
    let startDay = null;
    let endDay = null;
    let currentWorkingHr = { checkIn: "", checkOut: "" };

    return (
      <>
        <tbody>
          {usersList.length ? (
            usersList.map((val, ind) => {
              if (ind >= previousPageNumber * 10 && ind < activePage * 10) {
                for (let i = 0; i < val.employee.weekPlans.length; i++) {
                  if (
                    !startDay &&
                    parseInt(val.employee.weekPlans[i].availableStatus)
                  ) {
                    startDay = val.employee.weekPlans[i].dayOfWeek;
                  } else {
                    endDay = val.employee.weekPlans[i].dayOfWeek;
                  }
                }
                if (
                  val.employee.weekPlans[new Date().getDay()] &&
                  val.employee.weekPlans[new Date().getDay()].availableStatus
                ) {
                  currentWorkingHr.checkIn =
                    val.employee.weekPlans[new Date().getDay()].checkIn;
                  currentWorkingHr.checkOut =
                    val.employee.weekPlans[new Date().getDay()].checkOut;
                }
                const workingHoursHaveChanged =
                  JSON.stringify(
                    val.companyAssociateHours.filter(
                      (element) => !!element.availableStatus
                    )
                  ) !==
                  JSON.stringify(
                    val.employee.weekPlans.filter(
                      (element) => !!element.availableStatus
                    )
                  );
                return (
                  <tr key={val._id} style={{ borderTop: "3px solid #F7F7F7" }}>
                    <td>
                      <div className="d-flex align-items-center">
                        {val.user && (
                          <a
                            style={
                              workingHoursHaveChanged ? Styles.notification : {}
                            }
                          >
                            <span>
                              <img
                                src={val.user.profile_img}
                                className="appointment-details-employee-pic"
                                style={{ width: 30, height: 30 }}
                              />
                            </span>
                            <span
                              style={
                                workingHoursHaveChanged ? Styles.badge : {}
                              }
                            />
                          </a>
                        )}
                        {val.user && (
                          <span className="appointment-details-employee-name">
                            {val.user.firstName + " " + val.user.lastName}
                          </span>
                        )}
                      </div>
                    </td>
                    <td className=" working_day_data ">
                      <div className="d-flex align-items-center mt-1">
                        <span className="appointment-details-employee-other">
                          <WorkingHours
                            onClick={() => this.openWorkingHoursPopup(val._id)}
                          >
                            {i18n.t("see_working_hours")}
                          </WorkingHours>
                          {/* {val.companyAssociateHours.map(
                          (v, i) =>
                            v.checkIn &&
                            `${weekNames[v.dayOfWeek - 1]}, (${v.checkIn}-${
                              v.checkOut
                            }) `
                        )} */}

                          {/* {`${
                      
                      
                      weekNames[startDay - 1]
                      } - ${weekNames[endDay - 1]} (${
                        currentWorkingHr.checkIn
                      } - ${currentWorkingHr.checkOut})`}
                       */}
                        </span>
                      </div>
                    </td>
                    <td className="Table_data ">
                      <div className="d-flex align-items-center mt-1">
                        {val.user && (
                          <span className="appointment-details-employee-other">
                            {val.user.phoneNo}
                          </span>
                        )}
                      </div>
                    </td>
                    <td className="Table_data ">
                      <div className="d-flex align-items-center mt-1">
                        {val.user && (
                          <span className="appointment-details-employee-other">
                            {val.user.email}
                          </span>
                        )}
                      </div>
                    </td>
                    {/* <td>
                                    <span className="add_employee_btn_active">Add Employee</span>
                                </td> */}
                    <td>
                      <div style={{ width: "100px" }}>
                        {/* <img className="working-hour-action-icon" src={Images.saloon_working_hour_edit} /> */}
                        <TimeSheetIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => this.openSchedulPopup(val)}
                        />

                        <span
                          onClick={
                            () => this.gettingCompanyEmployeeInfo(val)

                            // () => this.editEmploye(val)
                          }
                        >
                          {/* <FontAwesomeIcon
                          onClick={() =>
                            this.handleRoutToChat(
                              val.user.firstName,
                              val.user._id,
                              val.employee.userId.profile_img
                            )
                          }
                          size="2x"
                          icon={faComment}
                          className="chatSystemIcon"
                        /> */}
                          <img
                            className="working-hour-action-icon"
                            src={Images.saloon_working_hour_edit}
                          />
                        </span>
                        {(!userId ||
                          userId != getNestedValue(val, ["user", "_id"])) && (
                          <span
                            onClick={() =>
                              this.deleteCompanyEmployee(val.employee._id)
                            }
                          >
                            <img
                              className="working-hour-action-icon"
                              src={Images.saloon_working_hour_delete}
                            />
                          </span>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              }
            })
          ) : (
            <div style={{ padding: 20 }}>
              <span className="form-heading">
                {i18n.t("container.add_employe_list_no_employe")}
              </span>
            </div>
          )}
        </tbody>
      </>
    );
  };

  editEmploye = (e) => {
    const { employeesWorkingHoursData, serviceList } = this.state;

    // selectedEmployeeId

    e.employee.weekPlans.length &&
      e.employee.weekPlans.map((v, ind) => {
        if (!employeesWorkingHoursData[ind]) {
          employeesWorkingHoursData[ind] = {};
        }
        employeesWorkingHoursData[ind].availableStatus = v.availableStatus;
        employeesWorkingHoursData[ind].checkIn = v.checkIn;
        employeesWorkingHoursData[ind].checkOut = v.checkOut;
      });

    serviceList &&
      serviceList.map((v, ind) => {
        serviceList[ind].isSelected = v.isSelected;
        serviceList[ind].price = v.price;
      });

    let employeesData = [];

    employeesData.push(e);

    this.setState({
      showAddEmployeePopup: true,
      employeesData,
      selectedEmployeeId: e.employee.userId,
    });
  };

  renderFilterTableBody = () => {
    const { activePage, filterUsersList, userId } = this.state;
    let previousPageNumber = activePage - 1;
    let startDay = null;
    let endDay = null;
    let currentWorkingHr = { checkIn: "", checkOut: "" };
    return (
      <tbody>
        {filterUsersList.map((val, ind) => {
          if (ind >= previousPageNumber * 10 && ind < activePage * 10) {
            for (let i = 0; i < val.employee.weekPlans.length; i++) {
              if (
                !startDay &&
                parseInt(val.employee.weekPlans[i].availableStatus)
              ) {
                startDay = val.employee.weekPlans[i].dayOfWeek;
              } else {
                endDay = val.employee.weekPlans[i].dayOfWeek;
              }
            }
            if (val.employee.weekPlans[new Date().getDay()].availableStatus) {
              currentWorkingHr.checkIn =
                val.employee.weekPlans[new Date().getDay()].checkIn;
              currentWorkingHr.checkOut =
                val.employee.weekPlans[new Date().getDay()].checkOut;
            }
            return (
              <tr key={val._id} style={{ borderTop: "3px solid #F7F7F7" }}>
                <td>
                  <div className="d-flex align-items-center">
                    {val.user && (
                      <img
                        src={val.user.profile_img}
                        className="appointment-details-employee-pic"
                        style={{ width: 30, height: 30 }}
                      />
                    )}
                    {val.user && (
                      <span className="appointment-details-employee-name">
                        {val.user.firstName + " " + val.user.lastName}
                      </span>
                    )}
                  </div>
                </td>
                <td className=" working_day_data ">
                  <div className="d-flex align-items-center mt-1">
                    <span className="appointment-details-employee-other">
                      {val.companyAssociateHours.map(
                        (v, i) =>
                          v.checkIn &&
                          `${weekNames[v.dayOfWeek - 1]}, (${v.checkIn}-${
                            v.checkOut
                          }) `
                      )}
                    </span>
                  </div>
                </td>
                <td className="Table_data ">
                  <div className="d-flex align-items-center mt-1">
                    {val.user && (
                      <span className="appointment-details-employee-other">
                        {val.user.phoneNo}
                      </span>
                    )}
                  </div>
                </td>
                <td className="Table_data ">
                  <div className="d-flex align-items-center mt-1">
                    {val.user && (
                      <span className="appointment-details-employee-other">
                        {val.user.email}
                      </span>
                    )}
                  </div>
                </td>
                <td>
                  <div style={{ width: 70 }}>
                    <span
                      onClick={
                        () => this.gettingCompanyEmployeeInfo(val)

                        // () => this.editEmploye(val)
                      }
                    >
                      <img
                        className="working-hour-action-icon"
                        src={Images.saloon_working_hour_edit}
                      />
                    </span>
                    {(!userId ||
                      userId != getNestedValue(val, ["user", "_id"])) && (
                      <span
                        onClick={() =>
                          this.deleteCompanyEmployee(val.employee._id)
                        }
                      >
                        <img
                          className="working-hour-action-icon"
                          src={Images.saloon_working_hour_delete}
                        />
                      </span>
                    )}
                  </div>
                </td>
              </tr>
            );
          }
        })}
      </tbody>
    );
  };

  onChangeCheckIn = (text, ind) => {
    const { employeesWorkingHoursData } = this.state;
    if (text.target.value) {
      employeesWorkingHoursData[ind].checkIn = text.target.value;
    }
    this.setState({ employeesWorkingHoursData });
  };

  renderWorkingHoursInput = (ind, data, disabled) => {
    return (
      <div className="form-row working-days-table">
        <div className=" px-0 pr-md-3 d-flex align-items-center">
          <input
            value={data.checkIn}
            type="time"
            disabled={disabled}
            className="form-control edit-employee-input"
            onChange={(text) => this.onChangeCheckIn(text, ind)}
          />
        </div>
        <div className="px-3 px-md-0 d-flex align-items-center">
          <span>to</span>
        </div>
        <div
          className="  px-0 pl-md-3 d-flex align-items-center"
          style={{ marginBottom: "0px !important" }}
        >
          <input
            value={data.checkOut}
            disabled={disabled}
            type="time"
            className="form-control edit-employee-input"
            onChange={(text) => this.onChangeCheckOut(text, ind)}
          />
        </div>
      </div>
    );
  };

  onChangeCheckOut = (text, ind) => {
    const { employeesWorkingHoursData } = this.state;
    if (text.target.value) {
      employeesWorkingHoursData[ind].checkOut = text.target.value;
    }
    this.setState({ employeesWorkingHoursData });
  };

  onChangeCustomerDetailsVisibility = (e) => {
    const { settings } = this.state;
    settings.isEmployeeIndependent = e.target.checked;
    this.setState({
      settings,
    });
  };

  onChangeReceptionist = (e) => {
    const { settings } = this.state;
    settings.isReceptionist = e.target.checked;
    this.setState({
      settings,
    });
  };

  onChangeManager = (e) => {
    const { settings } = this.state;
    settings.isManager = e.target.checked;
    this.setState({
      settings,
    });
  };

  onChangeCustomerCheckInSetting = (e) => {
    const { settings } = this.state;
    settings.allowCheckIn = e.target.checked;
    this.setState({
      settings,
    });
  };

  onChangeDisplayWorkHours = (e) => {
    const { settings } = this.state;
    settings.displayWorkingHours = e.target.checked;
    this.setState({
      settings,
    });
  };

  newHireCompanyEmployees = () => {
    this.setState({ isloading: true });
    const {
      access_token,
      serviceList,
      employeesWorkingHoursData,
      selectedEmployeeId,
    } = this.state;
    const employeesWorkingHoursSorted =
      this.state.employeesWorkingHoursData.map(function (obj) {
        if (obj.availableStatus === 0) {
          delete obj["checkIn"]; // Delete old key
          delete obj["checkOut"]; // Delete old key
        }
        delete obj["dayName"]; // Delete old key
        delete obj["_id"]; // Delete old key
        return obj;
      });
    let serviceListSorted = [];
    for (let i = 0; i < serviceList.length; i++) {
      if (serviceList[i].isSelected) {
        let myService = {
          price: serviceList[i].price,
          serviceId: serviceList[i]._id,
        };
        serviceListSorted.push(myService);
      }
    }
    const payload = {
      employeeId: selectedEmployeeId,
      companyServices: serviceListSorted,
      weekPlans: employeesWorkingHoursSorted,
    };
    hireCompanyEmployee(payload, access_token)
      .then((res) => {
        if (res.data.success) {
          SuccessHelper.handleSuccess(
            "Employee has been successfully added",
            true
          );
          this.handleNavigation("/saloon-company-employee");
          this.setState({ isloading: false, employeeList: res.data.data });
        }
      })
      .catch((error) => {
        if (!error.data.success) {
          ErrorHelper.handleErrors(error.data.msg, true);
        }
        this.setState({ isloading: false });
      });
  };

  renderWorkingHoursTable = (selectedEmploye) => {
    const { employeesWorkingHoursData } = this.state;
    let employeDays = [];
    selectedEmploye != null &&
      selectedEmploye.map((val, index) => {
        if (
          val.availableStatus === 1 ||
          (employeesWorkingHoursData[index] &&
            employeesWorkingHoursData[index].availableStatus === 1)
        ) {
          employeDays.push(val.dayOfWeek);
        }
      });

    return (
      <div className="col-12 p-0">
        <div className="table-responsive">
          <table class="table table-borderless saloon-working-hour-table">
            <thead>
              <tr>
                <th scope="col">
                  <span className="working-day-th-text">
                    <Translation>
                      {(t) => t("container.add_employee_working_days")}
                    </Translation>
                  </span>
                </th>
                <th className="text-center" scope="col">
                  <span className="working-day-th-text">
                    <Translation>{(t) => t("container.hours")}</Translation>
                  </span>
                </th>
                <th className="text-center" scope="col">
                  <span className="working-day-th-text">
                    <Translation>
                      {(t) => t("container.availability")}
                    </Translation>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {employeesWorkingHoursData.map((val, ind) => {
                if (employeDays.includes(val.dayOfWeek)) {
                  return (
                    <tr key={ind} style={{ borderTop: "3px solid #F7F7F7" }}>
                      <td>
                        <span className="working-days-name">{val.dayName}</span>
                      </td>
                      <td>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {val.availableStatus === 1 && (
                            <span style={{ alignItems: "center" }}>
                              {this.renderWorkingHoursInput(ind, val)}
                            </span>
                          )}
                          {val.availableStatus === 0 && (
                            <button
                              className="btn btn-light btn-sm"
                              id="working-off-btn"
                            >
                              OFF
                            </button>
                          )}
                        </div>
                        {val &&
                          selectedEmploye &&
                          JSON.stringify(
                            _.pick(val, [
                              "availableStatus",
                              "checkIn",
                              "checkOut",
                            ])
                          ) !==
                            JSON.stringify(
                              _.pick(selectedEmploye[ind], [
                                "availableStatus",
                                "checkIn",
                                "checkOut",
                              ])
                            ) && (
                            <div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  padding: "9px 0px",
                                }}
                              >
                                Requested
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {selectedEmploye[ind].availableStatus === 1 && (
                                  <span style={{ alignItems: "center" }}>
                                    {this.renderWorkingHoursInput(
                                      ind,
                                      selectedEmploye[ind],
                                      true
                                    )}
                                  </span>
                                )}
                                {selectedEmploye[ind].availableStatus === 0 && (
                                  <button
                                    className="btn btn-light btn-sm"
                                    id="working-off-btn"
                                  >
                                    OFF
                                  </button>
                                )}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  padding: "9px 0px",
                                }}
                              >
                                <button
                                  className="btn-sm btn-accept"
                                  onClick={() => {
                                    if (
                                      selectedEmploye[ind].availableStatus === 0
                                    ) {
                                      employeesWorkingHoursData[ind] = {
                                        ...selectedEmploye[ind],
                                      };
                                    } else {
                                      employeesWorkingHoursData[ind] = {
                                        ...employeesWorkingHoursData[ind],
                                        ...selectedEmploye[ind],
                                      };
                                    }
                                    this.setState({
                                      employeesWorkingHoursData,
                                    });
                                  }}
                                >
                                  Accept
                                </button>
                              </div>
                            </div>
                          )}
                      </td>
                      <td className="text-center">
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id={ind}
                            checked={val.availableStatus ? true : false}
                            onChange={(e) =>
                              this.onChangeWorkingHrCheckbox(e, ind)
                            }
                          />
                          <label class="custom-control-label" for={ind}></label>
                        </div>
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  handleSaloonGetAllService = () => {
    this.setState({ isloading: true });
    const { access_token } = this.state;
    saloonGetAllService(access_token)
      .then((res) => {
        if (res.data.success) {
          this.setState({
            isloading: false,
            serviceList: res.data.data.filter(
              (service) => service.isActive === 1 && service.serviceId
            ),
          });
        }
      })
      .catch((error) => {});
  };

  renderServicesTable = () => {
    const { employeePotentialServices } = this.state;
    return (
      <div className="col-12 p-0">
        <div className="table-responsive">
          <table class="table table-borderless saloon-working-hour-table">
            <thead>
              <tr>
                <th scope="col">
                  <span className="working-day-th-text">
                    <Translation>
                      {(t) => t("container.profile_service")}
                    </Translation>
                  </span>
                </th>
                <th className="text-center" scope="col">
                  <span className="working-day-th-text">
                    <Translation>{(t) => t("by_price")}</Translation>
                  </span>
                </th>
                <th className="text-center" scope="col">
                  <span className="working-day-th-text">
                    <Translation>{(t) => t("container_choose")}</Translation>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {employeePotentialServices &&
                employeePotentialServices.map((val, ind) => {
                  return (
                    <tr key={ind} style={{ borderTop: "3px solid #F7F7F7" }}>
                      <td>
                        <span className="working-days-name text-capitalize">
                          {val.name}
                        </span>
                      </td>
                      <td style={{ display: "flex", justifyContent: "center" }}>
                        {val.isSelected ? (
                          <div className=" px-0 pr-md-3 d-flex align-items-center">
                            <input
                              placeholder="Price"
                              value={val.price}
                              type="number"
                              min="1.0"
                              step="0.5"
                              max="250000"
                              className="form-control edit-employee-input"
                              onChange={(text) => this.onChangePrice(text, ind)}
                            />
                          </div>
                        ) : null}
                        {!val.isSelected && (
                          <button
                            className="btn btn-light btn-sm"
                            id="working-off-btn"
                          >
                            <Translation>
                              {(t) => t("container.not_selected")}
                            </Translation>
                          </button>
                        )}
                      </td>
                      <td className="text-center">
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id={val._id}
                            checked={val.isSelected ? true : false}
                            onChange={(e) =>
                              this.onChangeServiceCheckbox(e, ind)
                            }
                          />
                          <label
                            class="custom-control-label"
                            for={val._id}
                          ></label>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  setCustomerPinCode = (e) => {
    const { settings } = this.state;
    settings.pinCode = e.target.value;
    this.setState({
      settings,
    });
  };

  renderPinCodeSetting = () => {
    const { settings = {} } = this.state;
    return (
      <div className="form-group row mx-0 px-0 pt-2">
        <div>
          <label style={{ display: "block" }} htmlFor="addEmployee_pin_code">
            {i18n.t("container.add_employee_enter_pincode")}:
          </label>
          <PinInput
            type="number"
            id="addEmployee_pin_code"
            className="form-control form-control-sm pl-2"
            value={settings.pinCode}
            onChange={(e) => this.setCustomerPinCode(e)}
          />
        </div>
        <div />
      </div>
    );
  };

  renderSettings = () => {
    const {
      selectedEmployeinpopup,
      settings,
      showColorPopup,
      newColor,
      splitError,
      baseCommissionError,
    } = this.state;
    let color = settings ? settings.color : "#000000";
    return (
      <div className="col-12 pb-3">
        <div class="custom-control custom-checkbox">
          <input
            type="checkbox"
            id="allowCheckIn"
            className="custom-control-input"
            checked={settings ? settings.allowCheckIn : false}
            onChange={(e) => this.onChangeCustomerCheckInSetting(e)}
          />
          <label class="custom-control-label" for="allowCheckIn">
            Allow Clock in Clock out
          </label>
        </div>
        {settings && settings.allowCheckIn ? this.renderPinCodeSetting() : null}
        <div class="custom-control custom-checkbox">
          <input
            type="checkbox"
            id="isEmployeeIndependent"
            className="custom-control-input"
            checked={settings ? settings.isEmployeeIndependent : false}
            onChange={(e) => this.onChangeCustomerDetailsVisibility(e)}
          />
          <label class="custom-control-label" for="isEmployeeIndependent">
            Employee is independent
          </label>
        </div>
        <div class="custom-control custom-checkbox">
          <input
            type="checkbox"
            id="isReceptionist"
            className="custom-control-input"
            checked={settings ? settings.isReceptionist : false}
            onChange={(e) => this.onChangeReceptionist(e)}
          />
          <label class="custom-control-label" for="isReceptionist">
            {i18n.t("is_receptionist")}
          </label>
        </div>
        <div class="custom-control custom-checkbox">
          <input
            type="checkbox"
            id="isManager"
            className="custom-control-input"
            checked={settings ? settings.isManager : false}
            onChange={(e) => this.onChangeManager(e)}
          />
          <label class="custom-control-label" for="isManager">
            {i18n.t("is_manager")}
          </label>
        </div>
        <div class="custom-control custom-checkbox">
          <input
            type="checkbox"
            id="displayWorkingHours"
            className="custom-control-input"
            checked={settings ? settings.displayWorkingHours : false}
            onChange={(e) => this.onChangeDisplayWorkHours(e)}
          />
          <label class="custom-control-label" for="displayWorkingHours">
            {i18n.t("display_working_hours_on_calendar")}
          </label>
        </div>
        <div class="form-group row mx-0 pt-2">
          <div>
            <label htmlFor="seat">{i18n.t("seat/room")}:</label>
            <input
              class="form-control"
              id="seat"
              onChange={(e) =>
                this.setState({
                  settings: { ...settings, seat: e.target.value },
                })
              }
              value={settings ? settings.seat : ""}
            />
          </div>
          <div style={Styles.split}>
            <label style={{ display: "block" }} htmlFor="split">
              {i18n.t("staff_split")}:
            </label>

            <input
              class="form-control"
              id="split"
              ref={(input) => (this.inputField = input)}
              onFocus={() => (this.inputField.value = "")}
              onChange={(e) => {
                let res = parseInt(e.target.value);

                if (res <= 100 && res >= 0) {
                  this.setState({
                    settings: { ...settings, split: e.target.value },
                    splitError: "",
                  });
                } else {
                  this.setState({
                    settings: {
                      ...settings,
                      split: "",
                    },
                    splitError: i18n.t("splitError"),
                  });
                }
              }}
              value={settings ? settings.split : ""}
            />
            <span style={{ color: "red" }}>{splitError}</span>
          </div>
          <div style={Styles.split}>
            <label
              style={{ display: "block" }}
              htmlFor="base_product_commission"
            >
              {i18n.t("base_product_commission")} (%):
            </label>

            <input
              class="form-control"
              id="base_product_commission"
              ref={(input) => (this.inputField = input)}
              onFocus={() => (this.inputField.value = "")}
              onChange={(e) => {
                let res = parseInt(e.target.value);

                if (res >= 0) {
                  this.setState({
                    settings: {
                      ...settings,
                      baseProductCommission: e.target.value,
                    },
                    baseCommissionError: "",
                  });
                } else {
                  this.setState({
                    settings: {
                      ...settings,
                      baseProductCommission: "",
                    },
                    baseCommissionError: i18n.t("baseCommissionError"),
                  });
                }
              }}
              value={settings ? settings.baseProductCommission : ""}
            />
            <span style={{ color: "red" }}>{baseCommissionError}</span>
          </div>
        </div>
        <div class="form-group col-12 px-0 mx-0 row pt-2">
          <div>
            <label htmlFor="color">{i18n.t("color")}:</label>
            <div
              style={{
                width: "70px",
                height: "26px",
                border: "3px solid #E1E1E1",
                cursor: "pointer",
                backgroundColor: color,
              }}
              className="ml-1"
              onClick={() =>
                this.setState({ showColorPopup: true, newColor: color })
              }
            ></div>
          </div>
          {showColorPopup && (
            <div
              className="employee-service-card-wrapper "
              style={{ display: "flex" }}
            >
              <div className="container ">
                <div
                  className="col-8 p-3 my-5 bg-white pop_container"
                  style={{ borderRadius: "1rem", margin: "auto" }}
                >
                  <div
                    className="py-0 px-2"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span>
                      <b>{i18n.t("employee_color")}</b>
                    </span>
                    <span
                      onClick={() => this.setState({ showColorPopup: false })}
                      className="h4 cursor-pointer"
                    >
                      &times;
                    </span>
                  </div>
                  <div
                    className="py-0 row mx-2"
                    style={{
                      alignItems: "center",
                    }}
                  >
                    <SketchPicker
                      id="color"
                      width="100%"
                      color={newColor}
                      presetColors={[
                        "#D0021B",
                        "#F5A623",
                        "#F8E71C",
                        "#8B572A",
                        "#7ED321",
                        "#417505",
                        "#BD10E0",
                        "#9013FE",
                        "#4A90E2",
                        "#50E3C2",
                        "#B8E986",
                        "#000000",
                        "#4A4A4A",
                        "#9B9B9B",
                      ]}
                      disableAlpha={true}
                      onChange={({ hex }) => this.setState({ newColor: hex })}
                    />
                    <div className="col-12 text-right mt-2">
                      <button
                        // onClick={() => }
                        onClick={() => {
                          this.setState({
                            settings: { ...settings, color: newColor },
                            showColorPopup: false,
                          });
                        }}
                        type="button"
                        className="btn btn-light btn-sm mb-2"
                        id="saloon-save-social-link"
                      >
                        {i18n.t("update")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  renderServicesWorkingHours = (selectedEmploye) => {
    return (
      <div className="col-12 px-0 px-md-3">
        <h3 className="form-heading">
          <Translation>{(t) => t("container.working_hours")}</Translation>:
        </h3>
        {this.renderWorkingHoursTable(selectedEmploye)}
        <h3 className="form-heading">
          <Translation>{(t) => t("container.services")}</Translation>:
        </h3>
        {this.renderServicesTable()}
        <h3 className="form-heading">Employee Settings:</h3>
        {this.renderSettings()}
        <div className="col-12 text-right">
          <button
            // onClick={() => }
            onClick={() => this.updateCompanyEmployeInfo()}
            type="button"
            className="btn btn-light btn-sm mb-2"
            id="saloon-save-social-link"
          >
            Update Employee
          </button>
        </div>
      </div>
    );
  };

  onChangeWorkingHrCheckbox = (e, ind) => {
    const { employeesWorkingHoursData } = this.state;
    if (e.target.checked) {
      employeesWorkingHoursData[ind].availableStatus = 1;
      this.setState({ employeesWorkingHoursData });
    } else {
      employeesWorkingHoursData[ind].availableStatus = 0;
      this.setState({ employeesWorkingHoursData });
    }
  };

  closeEditServiceCard = () => {
    this.setState({
      employeesWorkingHoursData: [],
      showAddEmployeePopup: false,
    });
  };

  renderAddEmployeePopup = () => {
    const { showAddEmployeePopup, selectedEmployeinpopup } = this.state;
    return (
      <div
        className="employee-service-card-wrapper"
        style={{ display: showAddEmployeePopup ? "flex" : "none" }}
      >
        <div className="container">
          <div
            className="col-12 p-3 my-5 bg-white"
            style={{ borderRadius: "1rem" }}
          >
            <div className="py-0 px-2 text-right">
              <span
                onClick={() => this.closeEditServiceCard()}
                className="h4 cursor-pointer"
              >
                &times;
              </span>
            </div>
            {this.renderServicesWorkingHours(selectedEmployeinpopup)}
          </div>
        </div>
      </div>
    );
  };

  renderUserTable = () => {
    const { searchText, filterUsersList, usersList } = this.state;
    return (
      <div className="col-12 p-3">
        <div className="table-responsive">
          <table className="table table-borderless appointment-details-table">
            <thead>
              <tr>
                <th scope="col">
                  <span className="appointment-details-th-text">
                    {i18n.t("container.advancedSearchDialog_name")}
                  </span>
                </th>
                {/* <th scope="col" className="table_heading">SERVICES</th> */}
                <th scope="col">
                  <span className="appointment-details-th-text">
                    {i18n.t("container.add_employee_working_days")}y/
                    <Translation>{(t) => t("container.hours")}</Translation>
                  </span>
                </th>
                <th scope="col">
                  <span className="appointment-details-th-text">
                    <Translation>
                      {(t) => t("container.employe_phone_number")}
                    </Translation>
                  </span>
                </th>
                <th scope="col">
                  <span className="appointment-details-th-text">
                    <Translation>
                      {(t) => t("container.profile_email")}
                    </Translation>
                  </span>
                </th>
                <th />
                {/* <th scope="col"><span className="appointment-details-th-text"></span></th> */}
              </tr>
            </thead>
            {usersList && (
              <>
                {this.renderTableBody(searchText ? filterUsersList : usersList)}
              </>
            )}
          </table>
        </div>
      </div>
    );
  };

  renderPagination = () => {
    const { searchText, usersList, filterUsersList } = this.state;
    return (
      <div className="col-12 py-2 mb-3 d-flex justify-content-center">
        <Pagination
          hideNavigation
          activePage={this.state.activePage}
          itemsCountPerPage={10}
          totalItemsCount={
            searchText
              ? filterUsersList.length
              : usersList.length && usersList.length
          }
          // pageRangeDisplayed={3}
          onChange={(val) => this.handlePageChange(val)}
          firstPageText={
            <img src={Images.pagination_back} style={{ width: 15 }} />
          }
          lastPageText={
            <img src={Images.pagination_forward} style={{ width: 15 }} />
          }
          itemClass="page-item"
          linkClass="page-link"
        />
      </div>
    );
  };

  handleWorkingHoursPopup = () => {
    this.setState({ workingHoursPopupOpen: false });
  };
  openWorkingHoursPopup = (_id) => {
    this.setState({
      workingHoursPopupOpen: true,
      workingHoursPopupData: this.state.workingHours[_id],
    });
  };

  openSchedulPopup = (val) => {
    this.setState({
      employeeSchedule: true,
      employeePinCode: val.pinCode,
      currentUserName: val.user.firstName,
      modalEmployeeForTimeSheet: val._id,
      empCompanyId: val.companyId,
    });
  };
  closeSchedulePopup = () => {
    this.setState({ employeeSchedule: false });
  };

  employeePunchIn = async () => {
    const {
      employeePinCode,
      access_token,
      currentUserName,
      punchMap,
      modalEmployeeForTimeSheet,
      empCompanyId,
    } = this.state;

    let checkinDate = new Date();
    if (employeePinCode) {
      Swal.fire({
        title: "Are you sure?",
        text: i18n.t("confirm_check_in", { currentUserName, checkinDate }),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#354e68",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Check in!",
      }).then(async (result) => {
        if (result.value) {
          const checkedIn = await axios.post(
            employee_punch_in,
            { pinCode: employeePinCode, checkinDate, companyId: empCompanyId },
            {
              headers: {
                Authorization: access_token,
              },
            }
          );
          if (checkedIn.data.success) {
            const newPunchMap = punchMap;
            newPunchMap[modalEmployeeForTimeSheet] = true;
            this.setState({ punchMap: newPunchMap });
            Swal.fire({
              text: currentUserName + i18n.t("container.punched_in"),
              icon: "success",
              confirmButtonText: "Ok",
            });
          } else {
            if (checkedIn.data.errorCode == "already_punched_in") {
              // employee already checked in
              Swal.fire({
                text: currentUserName + i18n.t("container.already_punched_in"),
                icon: "error",
                confirmButtonText: "Ok",
              });
            } else {
              // general checkedIn.error
              Swal.fire({
                text: i18n.t("someting_went_wrong"),
                icon: "error",
                confirmButtonText: "Ok",
              });
            }
          }
        }
      });
    } else {
      Swal.fire({
        text: i18n.t("container.set_the_pin_code"),
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  };
  employeePunchOut = async () => {
    const {
      employeePinCode,
      access_token,
      currentUserName,
      punchMap,
      modalEmployeeForTimeSheet,
      empCompanyId,
    } = this.state;

    if (employeePinCode) {
      const checkedIn = await axios.post(
        employee_punch_out,
        { pinCode: employeePinCode, companyId: empCompanyId },
        {
          headers: {
            Authorization: access_token,
          },
        }
      );
      if (checkedIn.data.success) {
        const newPunchMap = punchMap;
        newPunchMap[modalEmployeeForTimeSheet] = false;
        this.setState({ punchMap: newPunchMap });
        Swal.fire({
          text: currentUserName + i18n.t("container.punched_out"),
          icon: "success",
          confirmButtonText: "Ok",
        });
      } else {
        if (checkedIn.data.errorCode == "not_punched_in") {
          // employee already checked in
          Swal.fire({
            text: currentUserName + i18n.t("container.not_punched_in"),
            icon: "error",
            confirmButtonText: "Ok",
          });
        } else {
          // general checkedIn.error
          Swal.fire({
            text: i18n.t("someting_went_wrong"),
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      }
    } else {
      Swal.fire({
        text: i18n.t("container.set_the_pin_code"),
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  };

  render() {
    const { workingHoursPopupData, modalEmployeeForTimeSheet, punchMap } =
      this.state;

    return (
      <div>
        {this.renderLoader()}
        <div className="content-container">
          <div className="row">
            {this.renderAddEmployeePopup()}
            {this.renderSearchBar()}
            {this.renderUserTable()}
            {this.renderPagination()}
            <div className="col-12 text-right">
              <button
                // onClick={() => }
                onClick={() => this.props.history.push("saloon-add-employee")}
                type="button"
                className="btn btn-light btn-sm mb-2"
                id="saloon-save-social-link"
              >
                {i18n.t("container.add_employee_add_employe")}
              </button>
            </div>
          </div>
        </div>
        <Dialog
          onClose={this.handleWorkingHoursPopup}
          aria-labelledby="working-hours-popup"
          open={this.state.workingHoursPopupOpen}
        >
          <DialogTitle id="working-hours-popup">Working Hours</DialogTitle>
          <TableContainer style={{ padding: "20px", paddingTop: "0" }}>
            <Table aria-label="working hours popup">
              <TableHead style={{ backgroundColor: "#002147" }}>
                <TableRow>
                  <TableCell style={{ color: "white" }}>Day</TableCell>
                  <TableCell style={{ color: "white" }}>Start Time</TableCell>
                  <TableCell style={{ color: "white" }}>End Time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {workingHoursPopupData?.map((shift) => (
                  <TableRow>
                    <TableCell align="right">{shift.weekDay}</TableCell>
                    <TableCell align="right">{shift.start}</TableCell>
                    <TableCell align="right">{shift.end}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Dialog>

        <BaseModal
          onClose={this.closeSchedulePopup}
          open={this.state.employeeSchedule}
          containerClassName={"small-modal"}
          {...{
            title: i18n.t("TimeSheet"),
            actions: (
              <div className="mx-0 px-0 row">
                <TimeSheetButtons
                  variant="outlined"
                  style={{ borderColor: "green", borderWidth: 2 }}
                  onClick={() => this.employeePunchIn()}
                  disabled={punchMap[modalEmployeeForTimeSheet]}
                >
                  Punch-in
                </TimeSheetButtons>
                <TimeSheetButtons
                  variant="outlined"
                  style={{ borderColor: "red", borderWidth: 2 }}
                  onClick={() => this.employeePunchOut()}
                  disabled={!punchMap[modalEmployeeForTimeSheet]}
                >
                  Punch-out
                </TimeSheetButtons>
              </div>
            ),
          }}
        />
        {/* <DialogTitle style={{ padding: "20px" }} id="working-hours-popup">
            TimeSheet
          </DialogTitle>
         
        </BaseModal> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(CompanyUsers);
