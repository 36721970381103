import { connect } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import i18n from "../../../i18n";
import BaseModal from "../../BaseModal";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import PrimaryButton from "../../Core/PrimaryButton";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import {
  getRequest as getStudentsRequest,
  upsertRequest as upsertStudentRequest,
} from "../../../redux/actions/Saloon/Student";
import {
  addStudentRequest,
  removeStudentRequest,
} from "../../../redux/actions/Saloon/StudentGroup";
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import DotsLoader from "../../DotsLoader";
import StudentUpsert from "../Student/StudentUpsert";
import { MenuItem } from "@material-ui/core";
import { InputAdornment } from "@material-ui/core";
import BasicTable from "../../Core/BasicTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      className="light-border"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={2}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function StudentGroupUpsert({
  open,
  onClose,
  onSave,
  active = {},
  setActive,
  getStudentsRequest,
  accessToken,
  upsertStudentRequest,
  addStudentRequest,
  removeStudentRequest,
  companyId,
  students,
}) {
  const form = useRef();
  const newClassForm = useRef();
  const [data, setData] = useState({});
  const [addStudentOpen, setAddStudentOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeStudent, setActiveStudent] = useState({});
  const [newGroupStudent, setNewGroupStudent] = useState({ numberOfTimes: 1 });

  const init = (data) => {
    setData({ ...data });
    setTab(0);
    getStudentsRequest({ access_token: accessToken });
  };
  const onSaveClass = (params) => {
    setLoading(true);
    upsertStudentRequest(
      { access_token: accessToken, companyId, vendorId: data._id, ...params },
      {
        success: (data) => {
          SuccessHelper.handleSuccess(
            i18n.t(data.id ? "successfully_updated" : "successfully_added"),
            true
          );
          setAddStudentOpen(false);
          setLoading(false);
        },
        failure: () => {
          ErrorHelper.handleErrors(
            i18n.t(data.id ? "failed_to_update" : "failed_to_add"),
            true
          );
          setLoading(false);
        },
      }
    );
  };
  useEffect(() => {
    init(active);
  }, []);
  useEffect(() => {
    if (active._id != data._id) {
      init(active);
    }
  }, [active]);
  const handleChange = (key, value) => {
    data[key] = value;
    setData({ ...data });
  };
  const handleSubmit = () => {
    onSave(data);
  };
  const handleStudentAdd = () => {
    addStudentRequest(
      {
        _id: active._id,
        studentId: newGroupStudent,
        access_token: accessToken,
      },
      {
        success: (data) => {
          SuccessHelper.handleSuccess(i18n.t("successfully_added"), true);
          setActive(data.data);
          setNewGroupStudent();
        },
        failure: () => {
          ErrorHelper.handleErrors(i18n.t("failed_to_add"), true);
        },
      }
    );
  };
  const handleStudentRemoval = (studentId) => {
    Swal.fire({
      title: i18n.t("are_you_sure_delete"),
      text: i18n.t("cannmot_reverse"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ff3600",
      cancelButtonColor: "#354e68",
      confirmButtonText: i18n.t("yes"),
    }).then((result) => {
      if (result.value) {
        removeStudentRequest(
          {
            _id: active._id,
            studentId,
            access_token: accessToken,
          },
          {
            success: (data) => {
              SuccessHelper.handleSuccess(i18n.t("successfully_deleted"), true);
              setActive(data.data);
            },
            failure: () => {
              ErrorHelper.handleErrors(i18n.t("failed_to_delete"), true);
            },
          }
        );
      }
    });
  };
  const [tab, setTab] = React.useState(-1);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <div>
      <DotsLoader isloading={loading} />
      {open ? (
        <BaseModal
          open={open}
          title={data._id ? i18n.t("edit_group") : i18n.t("add_group")}
          containerClassName="container"
          content={
            <>
              <AppBar position="static">
                <Tabs
                  value={tab}
                  onChange={handleTabChange}
                  aria-label="simple tabs example"
                >
                  <Tab label={i18n.t("details")} {...a11yProps(0)} />
                  <Tab
                    label={i18n.t("students")}
                    {...a11yProps(1)}
                    disabled={!data._id}
                  />
                </Tabs>
              </AppBar>
              <TabPanel value={tab} index={0}>
                <div>
                  <ValidatorForm
                    onSubmit={handleSubmit}
                    ref={form}
                    className="w-100"
                    onError={(error) => {
                      if (!error.length) {
                        onSave(data);
                      }
                    }}
                  >
                    <TextValidator
                      className="mb-1"
                      label={i18n.t("name")}
                      onChange={(e) => handleChange("name", e.target.value)}
                      value={data.name || ""}
                      validators={["required"]}
                      errorMessages={[i18n.t("name_is_required")]}
                    />
                    <TextValidator
                      className="mb-1"
                      label={i18n.t("description")}
                      onChange={(e) =>
                        handleChange("description", e.target.value)
                      }
                      value={data.description || ""}
                      validators={["required"]}
                      errorMessages={[i18n.t("description_is_required")]}
                      multiline
                      rows={3}
                    />
                    <div className="row mx-0 px-0 justify-content-end mt-2 col-12">
                      <PrimaryButton
                        props={{
                          type: "submit",
                        }}
                        label={data._id ? i18n.t("save") : i18n.t("create")}
                      />
                    </div>
                  </ValidatorForm>
                </div>
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <ValidatorForm
                  onSubmit={() => {
                    console.log("class add submit", newGroupStudent);
                    handleStudentAdd();
                  }}
                  ref={newClassForm}
                  className="w-100"
                >
                  <div className="row mb-2 mx-0 px-0 align-items-end">
                    <div style={{ flex: 11 }} className="pr-1">
                      <SelectValidator
                        label={i18n.t("student")}
                        onChange={(e) => {
                          setNewGroupStudent(e.target.value);
                        }}
                        value={newGroupStudent || ""}
                        select
                        validators={["required"]}
                        errorMessages={[i18n.t("required")]}
                      >
                        <MenuItem disabled={true}>{i18n.t("student")}</MenuItem>
                        {students.map((_class) => {
                          return (
                            <MenuItem value={_class._id}>
                              {_class.name}
                            </MenuItem>
                          );
                        })}
                      </SelectValidator>
                    </div>
                    <PrimaryButton
                      label={i18n.t("new")}
                      onClick={() => {
                        setAddStudentOpen(true);
                      }}
                    />
                  </div>
                  <div className="row mx-0 px-0 justify-content-end mt-2 col-12">
                    <PrimaryButton
                      props={{
                        type: "submit",
                      }}
                      label={i18n.t("add")}
                    />
                  </div>
                </ValidatorForm>
                <BasicTable
                  rows={active.students}
                  schema={[
                    {
                      header: i18n.t("phoneNo"),
                      field: "phoneNo",
                    },
                    {
                      header: i18n.t("name"),
                      field: "name",
                    },
                    {
                      header: i18n.t("profile_email"),
                      field: "email",
                    },
                    {
                      key: "action",
                      header: "",
                      render: (val) => {
                        return (
                          <FontAwesomeIcon
                            className="view-icon"
                            style={{ backgroundColor: "red" }}
                            color="white"
                            icon={["fas", "trash"]}
                            onClick={() => handleStudentRemoval(val._id)}
                          />
                        );
                      },
                    },
                  ]}
                />
              </TabPanel>
            </>
          }
          onClose={onClose}
        />
      ) : null}
      {addStudentOpen && (
        <StudentUpsert
          open={addStudentOpen}
          element={activeStudent}
          onClose={() => {
            setAddStudentOpen(false);
            setActiveStudent({});
          }}
          onSave={onSaveClass}
          accessToken={accessToken}
          presetVendor
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  students: state.student.data,
  loading: state.program.isFetching,
});

const action = {
  getStudentsRequest,
  upsertStudentRequest,
  addStudentRequest,
  removeStudentRequest,
};

export default connect(mapStateToProps, action)(StudentGroupUpsert);
