import { put, takeEvery } from "redux-saga/effects";

import * as types from "../../actions/ActionTypes";
import {
  upsert,
  get,
  remove,
  requestFailure,
} from "../../actions/Saloon/vendor";

import axios from "axios";
import { vendor } from "../../../config/WebServices";

function* getSaga(action) {
  const { payload, callbackMap = {} } = action;
  let data;
  try {
    const token = payload.access_token;
    delete payload.access_token;
    data = yield axios.get(vendor, {
      params: payload,
      headers: {
        Authorization: token,
      },
    });
    data = data.data;

    if (data.success) {
      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data);
      }
      yield put(get(data.data));
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data);
      }
      yield put(requestFailure(data));
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data);
    }
    yield put(requestFailure(data));
  }
}

function* upsertSaga(action) {
  const { payload, callbackMap = {} } = action;
  let data;
  try {
    const token = payload.access_token;
    delete payload.access_token;
    data = yield axios.post(vendor, payload, {
      headers: {
        Authorization: token,
      },
    });
    data = data.data;
    if (data.success) {
      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data);
      }
      yield put(upsert(data.data));
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data);
      }
      yield put(requestFailure(data));
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data);
    }
    yield put(requestFailure(data));
  }
}

function* removeSaga(action) {
  const { payload, callbackMap = {} } = action;
  let data;
  try {
    const token = payload.access_token;
    delete payload.access_token;
    data = yield axios.delete(vendor, {
      params: payload,
      headers: {
        Authorization: token,
      },
    });
    data = data.data;
    if (data.success) {
      if (callbackMap.success && typeof callbackMap.success == "function") {
        callbackMap.success(data);
      }
      yield put(remove(data.data));
    } else {
      if (callbackMap.failure && typeof callbackMap.failure == "function") {
        callbackMap.failure(data);
      }
      yield put(requestFailure(data));
    }
  } catch (err) {
    if (callbackMap.failure && typeof callbackMap.failure == "function") {
      callbackMap.failure(data);
    }
    yield put(requestFailure(data));
  }
}

export default function* root() {
  yield takeEvery(types.VENDOR.GET_SAGA, getSaga);
  yield takeEvery(types.VENDOR.UPSERT_SAGA, upsertSaga);
  yield takeEvery(types.VENDOR.DELETE_SAGA, removeSaga);
}
