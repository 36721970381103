import { connect } from "react-redux";
import React, { Component, useEffect, useRef, useState } from "react";
import i18n from "../../../i18n";
import BasicTable from "../../Core/BasicTable";
import moment from "moment";
import CustomerCollectPayment from "./CustomerCollectPayment";
import PrimaryButton from "../../Core/PrimaryButton";

export default function CustomerAccountTransactions({
  active = {},
  access_token,
  accountBalance,
  setAccountBalance,
}) {
  const [data, setData] = useState({});
  const [collectPaymentOpen, setCollectPaymentOpen] = useState(false);
  const init = (data) => {
    setData({ ...data });
  };
  useEffect(() => {
    init(active);
  }, []);
  useEffect(() => {
    if (active._id != data._id) {
      init(active);
    }
  }, [active]);
  return (
    <>
      <div className="row mx-0 justify-content-end mb-1">
        <PrimaryButton
          label={i18n.t("collect")}
          onClick={() => setCollectPaymentOpen(true)}
        />
      </div>
      <BasicTable
        rows={data.transactions || []}
        schema={[
          {
            header: i18n.t("amount"),
            field: "amount",
            accessor: (val) => {
              return `$ ${val.amount}`;
            },
          },
          {
            header: i18n.t("created_by"),
            field: "created_by",
            accessor: (val) => {
              return val.createdBy?.firstName;
            },
          },
          {
            header: i18n.t("created_date"),
            field: "created_date",
            accessor: (val) => {
              return moment(val.createdAt).format("MM-DD-YYYY hh:mm");
            },
          },
        ]}
      />
      {collectPaymentOpen ? (
        <CustomerCollectPayment
          open={collectPaymentOpen}
          handleClose={() => {
            setCollectPaymentOpen(false);
          }}
          onSuccess={(result) => {
            setCollectPaymentOpen(false);
            setAccountBalance(result.companyCustomer?.accountBalance);
          }}
          userId={data.userId}
          accountBalance={accountBalance}
          access_token={access_token}
        />
      ) : null}
    </>
  );
}
