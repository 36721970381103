import { connect } from "react-redux";
import React, { Component } from "react";
import i18n from "../../../i18n";
import {
  getAllEmployeeAssociatedWithCompany,
  getSalesReport,
} from "../../../config/simpleApiCalls";
import { DotsLoader } from "../../../components";
import Avatar from "react-avatar";
// import { adminGetUsers, adminDeleteUser } from '../../../config/simpleApiCalls';
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import ProgressBar from "react-bootstrap/ProgressBar";
import Button from "@material-ui/core/Button";
import moment from "moment";
import Sytles from "./sytles";
import DatePicker from "react-datepicker";
import "./styles.css";
import { create } from "apisauce";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "../../../components/Core/Select";

class SalesReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      access_token: "",
      usersList: [],
      startDate: new Date(),
      endDate: new Date(),
      // employeeList: [],
      filterUsersList: [],
      isLoading: false,
      employeeId: "",
    };
  }

  componentDidMount() {
    this.getSalesReport();
    let saloonData = JSON.parse(sessionStorage.getItem("saloon"));
    getAllEmployeeAssociatedWithCompany(saloonData.access_token).then((res) => {
      if (res.data.success) {
        this.setState({
          employees: res.data.Data.map((employee) => ({
            value: employee.employee._id,
            name: employee.employee.userId.firstName,
          })),
        });
      }
    });
  }

  getSalesReport = (exportToXlsx) => {
    this.setState({ isLoading: true }, () => {});
    const convertedStart = moment(this.state.startDate).format("MM-D-YYYY");
    const convertedEnd = moment(this.state.endDate).format("MM-D-YYYY");
    let data = {
      start: convertedStart,
      end: convertedEnd,
      exportToXlsx,
      employeeId: this.state.employeeId,
    };

    let token = JSON.parse(sessionStorage.getItem("saloon"));
    getSalesReport(data, token.access_token)
      .then((res) => {
        if (res.data.success) {
          this.setState({ isLoading: false });
        }
        this.setState({
          data: res.data.data.transactions,
          summary: res.data.data.summary,
        });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  };

  setStartDate = (val) => {
    const { endDate } = this.state;
    if (val > endDate) {
      this.setState({
        startDate: endDate,
        endDate: val,
      });
    } else {
      this.setState({
        startDate: val,
      });
    }
  };

  setEndDate = (val) => {
    const { startDate } = this.state;
    if (val < startDate) {
      this.setState({
        startDate: val,
        endDate: startDate,
      });
    } else {
      this.setState({
        endDate: val,
      });
    }
  };

  TemplateOption = () => {
    const { startDate, endDate } = this.state;
    return (
      <div
        style={{ flex: 1, display: "flex", justifyContent: "space-between" }}
        className="employee-report-filters"
      >
        <div>{i18n.t("date_range")}</div>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <div className="mx-1">
            <DatePicker
              className="form-control saloon-form-input"
              placeholderText="Select Start Date"
              selected={startDate}
              onChange={(data) => this.setStartDate(data)}
              dateFormat="MM/dd/yyyy"
            />
          </div>
          <div className="mx-1">
            <DatePicker
              className="form-control saloon-form-input"
              placeholderText="Select End Date"
              selected={endDate}
              onChange={(data) => this.setEndDate(data)}
              dateFormat="MM/dd/yyyy"
            />
          </div>
        </div>
      </div>
    );
  };

  renderSearchBar = () => {
    const { searchText } = this.state;
    return (
      <>
        <div className="col-12 col-lg-4 col-md-4 mt-4">
          <span>
            <span className="saloon-dashboard-heading">
              {i18n.t("sales_report")}
            </span>
          </span>
        </div>
      </>
    );
  };

  renderTableBody = () => {
    const { data, isLoading, summary = {} } = this.state;
    if (!isLoading) {
      if (data) {
        return (
          <tbody>
            <tr>
              <td className="px-0">
                <Button
                  disabled={true}
                  style={{
                    backgroundColor: "#ff3600",
                    color: "white",
                    width: "7rem",
                  }}
                >
                  $ {summary.servicesTotal}
                </Button>
              </td>
              <td className="px-0">
                <Button
                  disabled={true}
                  style={{
                    backgroundColor: "#ff3600",
                    color: "white",
                    width: "7rem",
                  }}
                >
                  $ {summary.productsTotal}
                </Button>
              </td>
              <td className="px-0">
                <Button
                  disabled={true}
                  style={{
                    backgroundColor: "#ff3600",
                    color: "white",
                    width: "7rem",
                  }}
                >
                  $ {summary.extrasTotal}
                </Button>
              </td>
              <td className="px-0">
                <Button
                  disabled={true}
                  style={{
                    backgroundColor: "#ff3600",
                    color: "white",
                    width: "7rem",
                  }}
                >
                  $ {summary.packagesTotal}
                </Button>
              </td>
              <td className="px-0">
                <Button
                  disabled={true}
                  style={{
                    backgroundColor: "#ff3600",
                    color: "white",
                    width: "7rem",
                  }}
                >
                  $ {summary.subtotal}
                </Button>
              </td>
              <td className="px-0">
                <Button
                  disabled={true}
                  style={{
                    backgroundColor: "#ff3600",
                    color: "white",
                    width: "7rem",
                  }}
                >
                  $ {summary.taxTotal}
                </Button>
              </td>
              <td className="px-0">
                <Button
                  disabled={true}
                  style={{
                    backgroundColor: "#ff3600",
                    color: "white",
                    width: "7rem",
                  }}
                >
                  $ {summary.discount}
                </Button>
              </td>
              <td className="px-0">
                <Button
                  disabled={true}
                  style={{
                    backgroundColor: "#ff3600",
                    color: "white",
                    width: "7rem",
                  }}
                >
                  $ {summary.tip}
                </Button>
              </td>
              <td className="px-0">
                <Button
                  disabled={true}
                  style={{
                    backgroundColor: "#ff3600",
                    color: "white",
                    width: "7rem",
                  }}
                >
                  $ {summary.grandTotal}
                </Button>
              </td>
              <td />
              <td />
            </tr>
            {data.map((val, ind) => {
              return (
                <tr key={val._id} style={{ borderTop: "3px solid #F7F7F7" }}>
                  <td className=" Table_data">
                    <div className="d-flex align-items-center mt-1">
                      <span className="appointment-details-employee-name">
                        $ {val.servicesTotal}
                      </span>
                    </div>
                  </td>
                  <td className=" Table_data">
                    <div className="d-flex align-items-center">
                      <span className="appointment-details-employee-name">
                        $ {val.productsTotal}
                      </span>
                    </div>
                  </td>
                  <td className=" Table_data">
                    <div className="d-flex align-items-center mt-1">
                      <span className="appointment-details-employee-name">
                        $ {val.extrasTotal}
                      </span>
                    </div>
                  </td>
                  <td className=" Table_data">
                    <div className="d-flex align-items-center mt-1">
                      <span className="appointment-details-employee-name">
                        $ {val.packagesTotal}
                      </span>
                    </div>
                  </td>
                  <td className=" Table_data">
                    <div className="d-flex align-items-center mt-1">
                      <span className="appointment-details-employee-name">
                        $ {val.subtotal}
                      </span>
                    </div>
                  </td>
                  <td className=" Table_data">
                    <div className="d-flex align-items-center mt-1">
                      <span className="appointment-details-employee-name">
                        $ {val.taxTotal}
                      </span>
                    </div>
                  </td>
                  <td className=" Table_data">
                    <div className="d-flex align-items-center mt-1">
                      <span className="appointment-details-employee-name">
                        $ {val.discount}
                      </span>
                    </div>
                  </td>
                  <td className=" Table_data">
                    <div className="d-flex align-items-center mt-1">
                      <span className="appointment-details-employee-name">
                        $ {val.tip}
                      </span>
                    </div>
                  </td>
                  <td className=" Table_data">
                    <div className="d-flex align-items-center mt-1">
                      <span className="appointment-details-employee-name">
                        $ {val.grandTotal}
                      </span>
                    </div>
                  </td>
                  <td className=" Table_data">
                    <div className="d-flex align-items-center mt-1">
                      <span className="appointment-details-employee-name">
                        {val.soldBy}
                      </span>
                    </div>
                  </td>
                  <td className=" Table_data">
                    <div className="d-flex align-items-center mt-1">
                      <span className="appointment-details-employee-name">
                        {moment(val.createdDate).format("MM-DD-YYYY HH:mm")}
                      </span>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        );
      }
    } else {
      return <DotsLoader isloading={this.state.isloading} />;
    }
  };

  renderLoader = () => {
    const { isLoading } = this.state;
    return <DotsLoader isloading={isLoading} />;
  };

  renderUserTable = () => {
    const { data } = this.state;
    if (data && data.length) {
      return (
        <div className="col-12 p-3">
          <div className="row mx-0 py-2">
            <div style={{ flex: 11 }} className="pr-2">
              <Select
                value={this.state.employeeId}
                onChange={(e) => this.setState({ employeeId: e.target.value })}
                label={i18n.t("container.employee")}
                options={this.state.employees}
              />
            </div>
            <Button
              onClick={() => {
                this.setState({ employeeId: "" });
              }}
              size="small"
              style={{
                backgroundColor: "#c6c6c6",
                color: "black",
                flex: 1,
              }}
            >
              {i18n.t("clear")}
            </Button>
          </div>
          <div className="table-responsive sticky-header">
            <table className="table table-borderless appointment-details-table">
              <thead>
                <tr>
                  <th scope="col">
                    <span className="appointment-details-th-text">
                      {i18n.t("service_total")}
                    </span>
                  </th>
                  <th scope="col">
                    <span className="appointment-details-th-text">
                      {i18n.t("product_total")}
                    </span>
                  </th>
                  <th scope="col">
                    <span className="appointment-details-th-text">
                      {i18n.t("extras_total")}
                    </span>
                  </th>
                  <th scope="col">
                    <span className="appointment-details-th-text">
                      {i18n.t("packages_total")}
                    </span>
                  </th>
                  <th scope="col">
                    <span className="appointment-details-th-text">
                      {i18n.t("subtotal")}
                    </span>
                  </th>
                  <th scope="col">
                    <span className="appointment-details-th-text">
                      {i18n.t("tax_total")}
                    </span>
                  </th>
                  <th scope="col">
                    <span className="appointment-details-th-text">
                      {i18n.t("discount")}
                    </span>
                  </th>
                  <th scope="col">
                    <span className="appointment-details-th-text">
                      {i18n.t("tip")}
                    </span>
                  </th>
                  <th scope="col">
                    <span className="appointment-details-th-text">
                      {i18n.t("grand_total")}
                    </span>
                  </th>
                  <th scope="col">
                    <span className="appointment-details-th-text">
                      {i18n.t("sold_by")}
                    </span>
                  </th>
                  <th scope="col">
                    <span className="appointment-details-th-text">
                      {i18n.t("created_at")}
                    </span>
                  </th>
                </tr>
              </thead>
              {this.renderTableBody()}
            </table>
          </div>
        </div>
      );
    } else {
      return (
        <div className="row full-width mx-2 justify-content-center mt-1">
          {i18n.t("nothing_found")}
        </div>
      );
    }
  };

  setSelectedValue = (val) => {
    let currentDate = moment(Date.now());
    if (val == 1) {
      const startOfMonth = moment(currentDate).startOf("month");
      const endOfMonth = moment(currentDate).endOf("month");
      this.setState({
        startDate: startOfMonth.toDate(),
        endDate: endOfMonth.toDate(),
      });
    } else if (val == 2) {
      const startOfQuarter = moment(currentDate).startOf("quarter");
      const endOfQuarter = moment(currentDate).endOf("quarter");
      this.setState({
        startDate: startOfQuarter.toDate(),
        endDate: endOfQuarter.toDate(),
      });
    } else if (val == 3) {
      const convertedStart = moment(
        `01-01-${currentDate.year()}`,
        "MM-DD-YYYY"
      );
      const convertedEnd = moment(`12-31-${currentDate.year()}`, "MM-DD-YYYY");
      this.setState({
        startDate: convertedStart.toDate(),
        endDate: convertedEnd.toDate(),
      });
    }
  };

  ShowMenuValues = () => {
    return (
      <div class="px-0">
        <div class="row employee-report-filters pt-3 px-0 mx-0">
          <div className="col-12 col-sm-12 col-md-3 d-flex px-0">
            {i18n.t("container.quick_filter")}
          </div>
          <div className="col-12 col-md-9 px-0 justify-content-end">
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-light btn-sm my-1"
                onClick={() => this.setSelectedValue(1)}
              >
                {i18n.t("container.month")}
              </button>
              <button
                type="button"
                className="btn btn-light btn-sm my-1"
                onClick={() => this.setSelectedValue(2)}
              >
                {i18n.t("container.quarter")}
              </button>
              <button
                type="button"
                className="btn btn-light btn-sm my-1"
                onClick={() => this.setSelectedValue(3)}
              >
                {i18n.t("container.year")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  retrieveButton = () => {
    return (
      <div class="px-0 row mx-0 justify-content-end">
        <button
          type="button"
          className="btn btn-accept btn-sm"
          onClick={() => this.getSalesReport(true)}
        >
          {i18n.t("export")}
          <FontAwesomeIcon className="ml-1" icon={["far", "file-excel"]} />
        </button>
        <button
          type="button"
          className="btn btn-light btn-sm"
          onClick={() => this.getSalesReport()}
        >
          {i18n.t("search")}
        </button>
      </div>
    );
  };

  render() {
    const { isLoading } = this.state;
    return (
      <>
        {isLoading ? this.renderLoader() : null}

        <div>
          <div className="content-container ">
            <div className="row">
              <div className="px-2 full-width">
                {this.renderSearchBar()}
                <div className="mx-2">
                  {this.TemplateOption()}
                  {this.ShowMenuValues()}
                  {this.retrieveButton()}
                </div>
              </div>
              {this.renderUserTable()}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(SalesReport);
