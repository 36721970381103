import { connect } from "react-redux";
import React, { Component } from "react";
import { Images } from "../../theme";
import { request as employee_logout } from "../../redux/actions/Employee/LogOut";
import { request as saloon_logout } from "../../redux/actions/Saloon/LogOut";
import { clearLogout as clear_logout } from "../../redux/actions/LogOut";
import { request as update_notification } from "../../redux/actions/UpdateNotification";
import { set } from "../../redux/actions/ActiveBooking";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Popover from "@material-ui/core/Popover";
import Tooltip from "@material-ui/core/Tooltip";
import Fade from "@material-ui/core/Fade";
import { getBookingById } from "../../config/simpleApiCalls";

import { SuccessHelper, ErrorHelper } from "../../helpers";

import "./style.scss";
import i18n from "../../i18n";
import { getNestedValue } from "../../util/objectMethods";
import moment from "moment";
const statusMap = {
  1: "sent",
  2: "opened",
};
class NotificationCenter extends Component {
  constructor(props) {
    super(props);
  }

  generateMessage = (notification) => {
    const { isSalon, isEmployee } = this.props;
    let append = "";
    if (isSalon) {
      append = "salon";
    } else if (isEmployee) {
      append = "employee";
    } else {
      append = "user";
    }
    switch (notification.typeId.code) {
      case "BCAN":
      case "BSU":
      case "BC":
      case "CCI":
        return i18n.t(`${notification.typeId.code}_message_${append}`, {
          name: notification.metadata.bookingId?.name,
          time: getNestedValue(
            notification.metadata.bookingId,
            ["services", 0, "time"],
            ""
          ),
          date: getNestedValue(
            notification.metadata.bookingId,
            ["services", 0, "date"],
            ""
          ),
          employeeName: getNestedValue(
            notification.metadata.bookingId,
            ["services", 0, "employeeId", "userId", "firstName"],
            ""
          ),
          companyName: getNestedValue(
            notification.metadata.bookingId,
            ["companyId", "name"],
            ""
          ),
        });
      case "TSU":
        return i18n.t(`${notification.typeId.code}_message_${append}`, {
          name: notification.metadata.payoutId?.requester?.firstName,
          status: i18n.t(notification.metadata.status),
        });
      case "VT":
        if (append == "salon") {
          return i18n.t(`${notification.typeId.code}_message_${append}`, {
            name: notification.metadata.bookingId?.name,
          });
        } else {
          return null;
        }
    }
  };

  handleCancelledNotificationClick = (notification) => {
    this.loadCalendarPage(notification);
  };

  handleCompletedNotificationClick = (notification) => {
    this.loadCalendarPage(notification);
  };

  handleCreatedNotificationClick = (notification) => {
    this.loadCalendarPage(notification);
  };

  loadCalendarPage = async (notification) => {
    const { isSalon, isEmployee } = this.props;
    let route = "";
    const { data } = await getBookingById({
      id: notification.metadata.bookingId._id,
      relations: ["extras", "cart"],
    });
    if (isSalon) {
      route = "saloon-my-appointment";
    } else if (isEmployee) {
      route = "employee-my-appointment";
    } else {
      route = "user-profile";
    }
    if (data.success) {
      this.props.set(data.data);
    }
    this.props.setAnchor();
    if (this.props.history.location !== route) {
      this.props.history.push(route);
    }
  };

  openNotification = (notification) => {
    this.markAsRead(notification);
    switch (notification.typeId.code) {
      case "BCAN":
        this.handleCancelledNotificationClick(notification);
        return;
      case "BSU":
        this.handleCompletedNotificationClick(notification);
        return;
      case "BC":
        this.handleCreatedNotificationClick(notification);
        return;
      case "VT":
      case "CCI":
        this.handleCreatedNotificationClick(notification);
        return;
      default:
        return;
    }
  };

  markAsRead = (notification, e) => {
    if (notification.status == 1) {
      this.props.update_notification({ _id: notification._id, status: 2 });
    }
    if (e) {
      e.stopPropagation();
    }
  };

  render = () => {
    const { notifications = [], anchor, setAnchor } = this.props;
    return (
      <div>
        <Popover
          open={Boolean(anchor)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          anchorEl={anchor}
          onClose={() => setAnchor()}
          PaperProps={{
            className: "header-notification-center",
          }}
        >
          <h4 className="p-2 mb-0 notification-header">
            {i18n.t("notifications")}
          </h4>
          <div className="items">
            {notifications.map((notification) => {
              return (
                <div
                  key={notification._id}
                  className={`header-notification p-2 ${
                    statusMap[notification.status]
                  }`}
                  onClick={() => this.openNotification(notification)}
                >
                  <div className="row mx-0">
                    <div className="header-notification-content">
                      <strong>{i18n.t(notification.typeId.code)}:</strong>{" "}
                      {this.generateMessage(notification)}
                    </div>
                    {notification.status == 1 ? (
                      <div
                        className="header-dismiss"
                        onMouseEnter={this.handlePopoverOpen}
                        onMouseLeave={this.handlePopoverClose}
                      />
                    ) : null}
                  </div>
                  <div className="row mx-0 date pt-1">
                    {moment(notification.createdDate).format(
                      "MM-DD-YYYY hh:mm a"
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </Popover>
      </div>
    );
  };
}

const mapStateToProps = (state) => ({});

const action = { update_notification, set };

export default connect(mapStateToProps, action)(NotificationCenter);
