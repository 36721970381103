import React, { useEffect, useState } from "react";
import i18n from "../../../i18n";
import TextField from "@material-ui/core/TextField";
import DollarInput from "../../DollarInput";
import { Button, Checkbox } from "@material-ui/core";
import PercentInput from "../../PercentInput";
import "./TransactionDetails.scss";
import { useMemo } from "react";
const roundTo2Decimals = (x) => Math.round((x + Number.EPSILON) * 100) / 100;

export default function PaymentForm({ handleChange, data, colClass }) {
  const {
    subtotal = 0,
    tip = 0,
    program = {},
    classes = [],
    discountAmount = 0,
    collected = 0,
    includeTaxes,
    taxDue = 0,
  } = data;
  const items = useMemo(() => {
    if (classes.length) {
      return classes;
    } else {
      const { name, price } = program;
      return [{ name, price }];
    }
  }, [data]);

  const grandTotal = parseFloat(subtotal) + (includeTaxes ? taxDue : 0);
  const toggleTaxes = (includeTaxes) => {
    handleChange("includeTaxes", includeTaxes);
  };
  return (
    <div className={`${colClass}`}>
      <div className="row px-0 flex-column justify-content-between program-transaction-details">
        <div>
          <div className="row mx-0 justify-content-center transaction-details-header">
            <strong>{i18n.t("transaction_details")}</strong>
          </div>
          <table className="w-100 px-2 transaction-details-table">
            <tbody>
              {items.map((item) => {
                const { name, price } = item;
                return (
                  <tr className="summary-row">
                    <td className="name" colSpan={2}>
                      {name || i18n.t("custom")}
                    </td>
                    <td />
                    <td className="money-value">${price.toFixed(2)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div>
          <table className="w-100 px-2 transaction-details-table">
            <tbody>
              <tr className="summary-row">
                <td colSpan={2}>{i18n.t("subtotal")}</td>
                <td />
                <td className="money-value">${subtotal.toFixed(2)}</td>
              </tr>
              {taxDue ? (
                <tr>
                  <td colSpan={2}>
                    <Checkbox
                      className="p-0 pr-1"
                      onChange={() => toggleTaxes(!includeTaxes)}
                      checked={Boolean(includeTaxes)}
                      disabled={Boolean(collected)}
                    />
                    {i18n.t("tax_total")}
                  </td>
                  <td />
                  <td className="money-value">${taxDue.toFixed(2)}</td>
                </tr>
              ) : null}
              {tip ? (
                <tr>
                  <td colSpan={2}>{i18n.t("tip")}</td>
                  <td />
                  <td className="money-value">${tip.toFixed(2)}</td>
                </tr>
              ) : null}
              {discountAmount ? (
                <tr>
                  <td colSpan={2}>{i18n.t("discount")}</td>
                  <td />
                  <td className="money-value">
                    $ -{discountAmount.toFixed(2)}
                  </td>
                </tr>
              ) : null}
              {collected ? (
                <tr>
                  <td colSpan={2}>{i18n.t("collected")}</td>
                  <td />
                  <td className="money-value">$ -{collected.toFixed(2)}</td>
                </tr>
              ) : null}
              {grandTotal ? (
                <tr className="summary-row">
                  <td colSpan={2}>{i18n.t("grand_total")}</td>
                  <td />
                  <td className="money-value">
                    $
                    {(grandTotal + tip - discountAmount - collected).toFixed(2)}
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
