import { connect } from "react-redux";
import React, { Component } from "react";
import { Translation } from "react-i18next";
import i18n from "../../../i18n";
import axios from "axios";
import { Images } from "../../../theme";
import Swal from "sweetalert2";
import {
  UpdateSaloonBlackList,
  getSaloonById,
} from "../../../config/simpleApiCalls";

import { DotsLoader } from "../../../components";
// import { adminGetUsers } from '../../../config/simpleApiCalls';
import { SuccessHelper, ErrorHelper } from "../../../helpers";
import { request as create_social_media } from "../../../redux/actions/Saloon/CreateSaloonSocialMedia";
import TextField from "../../../components/Core/TextField";
import "./styles.css";
import PrimaryButton from "../../../components/Core/PrimaryButton";
import Select from "../../../components/Core/Select";

class BlackList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasLink: false,
      companyId: "",
      isloading: true,
      intialized: false,
    };
  }

  componentDidMount = async () => {
    const saloonData = await JSON.parse(sessionStorage.getItem("saloon"));
    const companyData = await JSON.parse(sessionStorage.getItem("company"));
    const companyUpdatedData = await getSaloonById(saloonData.access_token);
    console.log(companyUpdatedData);
    if (saloonData && companyData) {
      this.setState({
        access_token: saloonData.access_token,
        companyId: companyData._id,
        blackListThreshold: companyUpdatedData.data.data.blackListThreshold,
        blackListLimit: companyUpdatedData.data.data.blackListLimit,
        isloading: false,
        intialized: true,
      });
    }
  };

  onUpdateBlackList = async () => {
    this.setState({ isloading: true });
    const { access_token, blackListLimit, blackListThreshold, companyId } =
      this.state;
    const payload = { companyId, blackListLimit, blackListThreshold };

    UpdateSaloonBlackList(payload, access_token)
      .then((res) => {
        this.setState({ isloading: false });
        if (res.data.success) {
          SuccessHelper.handleSuccess(i18n.t("successfully_updated"), true);
        } else {
          ErrorHelper.handleErrors(i18n.t("failed_to_update"), true);
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
      });
  };

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  onChangeBookingLimit = (text) =>
    this.setState({ blackListLimit: text.target.value });
  onChangeBookingThreshold = (text) => {
    let threshold = parseInt(text.target.value);
    const { blackListLimit = 0 } = this.state;
    if (threshold > blackListLimit) {
      threshold = blackListLimit;
    }
    this.setState({ blackListThreshold: threshold });
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  renderSaloonDashboardHeading = () => {
    return (
      <div className="">
        <h1 className="saloon-dashboard-heading">
          {i18n.t("container.dashboard_black_list_config")}
        </h1>
      </div>
    );
  };

  renderSocialLinkCard = () => {
    const { blackListLimit, blackListThreshold } = this.state;
    return (
      <div className="col-12 py-3 px-0">
        <div className="col-12 p-3 bg-white" style={{ borderRadius: "1rem" }}>
          <div className="col-12 px-0 px-md-3"></div>
          <div className="form-row mb-3">
            <div className="col-6 d-flex align-items-center">
              {this.state.intialized ? (
                <Select
                  label={i18n.t("number_of_bookings")}
                  value={blackListLimit}
                  options={[
                    { name: 5, value: "5" },
                    { name: 10, value: "10" },
                    { name: 15, value: "15" },
                  ]}
                  onChange={this.onChangeBookingLimit}
                />
              ) : null}
            </div>
            <div className="col-6">
              <TextField
                label={i18n.t("cancellation_limit")}
                onChange={this.onChangeBookingThreshold}
                props={{ value: blackListThreshold || "" }}
              />
            </div>
          </div>
          <div className="col-12 d-flex px-0 justify-content-end">
            <PrimaryButton
              label={i18n.t("container.update_changes")}
              onClick={this.onUpdateBlackList}
            />
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.renderLoader()}
        <div className="content-container">
          <div className="row mx-0">
            {this.renderSaloonDashboardHeading()}
            {this.renderSocialLinkCard()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  createSaloonSocialMedia: state.createSaloonSocialMedia,
});

const action = {
  create_social_media,
};

export default connect(mapStateToProps, action)(BlackList);
