import { take, put, call, fork } from "redux-saga/effects";

import * as types from "../../actions/ActionTypes";
import { success, failure } from "../../actions/Saloon/SaloonAppointment";

import ApiSauce from "../../../services/apiSauce";
import { get_all_booking_by_saloon } from "../../../config/WebServices";
import { ErrorHelper } from "../../../helpers";

function callRequest(data) {
    return ApiSauce.getData(get_all_booking_by_saloon, data);
}
function* watchRequest() {
    while (true) {
        const { payload } = yield take(types.GET_SALOON_APPOINTMENT.REQUEST);
    
        try {
            const response = yield call(callRequest, payload);
            yield put(success(response));
        } catch (err) {
            yield put(failure(err));
            ErrorHelper.handleErrors(err, true);
        }
    }
}

export default function* root() {
    yield fork(watchRequest);
}
