import { connect } from "react-redux";
import React, { Component } from "react";
import i18n from "../../../i18n";
import {
  getReports,
  saloonGetAllService,
  getAllEmployeeAssociatedWithCompany,
} from "../../../config/simpleApiCalls";
import { DotsLoader } from "../../../components";
import Avatar from "react-avatar";
import moment from "moment";
import DatePicker from "react-datepicker";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { getNestedValue } from "../../../util/objectMethods";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class BookingReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      access_token: "",
      user: null,
      showEditDelete: "",
      searchText: "",
      activePage: 1,
      usersList: [],
      startDate: new Date(),
      endDate: new Date(),
      // employeeList: [],
      filterUsersList: [],
      isLoading: false,
      selectedEmployee: "",
      selectedService: "",
    };
  }

  async componentDidMount() {
    this.setState({ isloading: true });
    await Promise.all([this.getAllEmployees(), this.getAllServices()]);
    this.retrieveReports();
  }

  getAllEmployees = async () => {
    let token = JSON.parse(sessionStorage.getItem("saloon"));
    return await getAllEmployeeAssociatedWithCompany(token.access_token)
      .then((res) => {
        if (res.data.success) {
          this.setState({
            isloading: false,
            employees: res.data.Data.map((employee) => ({
              value: employee.employee._id,
              label: employee.employee.userId.firstName,
            })),
          });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
      });
  };

  getAllServices = () => {
    let token = JSON.parse(sessionStorage.getItem("saloon"));
    return new Promise((resolve, reject) => {
      saloonGetAllService(token.access_token)
        .then((res) => {
          if (res.data.success) {
            const allServiceMap = {};
            const services = res.data.data.map((service) => {
              allServiceMap[service._id] = service;
              return {
                value: service._id,
                label: service.name,
              };
            });
            this.setState(
              { isloading: false, services, allServiceMap },
              resolve
            );
          }
        })
        .catch((error) => {
          this.setState({ isloading: false }, reject());
        });
    });
  };

  setStartDate = (val) => {
    const { endDate } = this.state;
    if (val > endDate) {
      this.setState({
        startDate: endDate,
        endDate: val,
      });
    } else {
      this.setState({
        startDate: val,
      });
    }
  };

  setEndDate = (val) => {
    const { startDate } = this.state;
    if (val < startDate) {
      this.setState({
        startDate: val,
        endDate: startDate,
      });
    } else {
      this.setState({
        endDate: val,
      });
    }
  };

  TemplateOption = () => {
    const { startDate, endDate } = this.state;
    return (
      <div
        style={{ flex: 1, display: "flex", justifyContent: "space-between" }}
        className="employee-report-filters"
      >
        <div>{i18n.t("date_range")}</div>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <div className="mx-1">
            <DatePicker
              className="form-control saloon-form-input"
              placeholderText="Select Start Date"
              selected={startDate}
              onChange={(data) => this.setStartDate(data)}
              dateFormat="MM/dd/yyyy"
            />
          </div>
          <div className="mx-1">
            <DatePicker
              className="form-control saloon-form-input"
              placeholderText="Select End Date"
              selected={endDate}
              onChange={(data) => this.setEndDate(data)}
              dateFormat="MM/dd/yyyy"
            />
          </div>
        </div>
      </div>
    );
  };

  renderSearchBar = () => {
    const { searchText } = this.state;
    return (
      <>
        <div className="col-12 col-lg-4 col-md-4 mt-4">
          <span>
            <span className="saloon-dashboard-heading">
              {i18n.t("container.booking_report")}
            </span>
          </span>
        </div>
        <div className="col-12 col-lg-8 col-md-8 mt-4">
          <div className=" d-flex justify-content-end row">
            <div className="col-12 col-lg-7 col-md-6 "></div>
          </div>
        </div>
      </>
    );
  };

  renderTableBody = () => {
    let { data, filteredData, isLoading, allServiceMap } = this.state;
    const toRender = filteredData ? filteredData : data;

    if (!isLoading) {
      if (toRender) {
        const summary = {
          total: 0,
          tip: 0,
          grandTotal: 0,
          discount: 0,
        };
        toRender.map((val) => {
          const { booking, employee } = val;
          summary.total += booking.totalAmount;
          summary.tip += booking.tip || 0;
          summary.discount += booking.discount || 0;
          summary.grandTotal += booking.total - booking.discount;
          val.booking = booking;
          return val;
        });
        return (
          <tbody>
            {!!toRender.length && (
              <tr>
                <td>
                  <strong>{i18n.t("summary")}: </strong>
                </td>
                <td>
                  <strong>
                    {i18n.t("number_of_bookings")}: {toRender.length}
                  </strong>
                </td>
                <td className="px-0">
                  <Button
                    disabled={true}
                    style={{
                      backgroundColor: "#ff3600",
                      color: "white",
                      width: "7rem",
                    }}
                  >
                    $ {summary.total.toFixed(2)}
                  </Button>
                </td>
                <td className="px-0">
                  <Button
                    disabled={true}
                    style={{
                      backgroundColor: "#ff3600",
                      color: "white",
                      width: "7rem",
                    }}
                  >
                    $ {summary.tip.toFixed(2)}
                  </Button>
                </td>
                <td className="px-0">
                  <Button
                    disabled={true}
                    style={{
                      backgroundColor: "#ff3600",
                      color: "white",
                      width: "7rem",
                    }}
                  >
                    $ {summary.discount.toFixed(2)}
                  </Button>
                </td>
                <td className="px-0">
                  <Button
                    disabled={true}
                    style={{
                      backgroundColor: "#ff3600",
                      color: "white",
                      width: "7rem",
                    }}
                  >
                    $ {summary.grandTotal.toFixed(2)}
                  </Button>
                </td>
              </tr>
            )}
            {toRender.map((val, ind) => {
              let totalAmount = val.booking.totalAmount
                ? val.booking.totalAmount
                : 0;
              let totalTip = val.booking.tip ? val.booking.tip : 0;
              let grandTotal = totalAmount + totalTip - val.booking.discount;
              const { firstName } = val.employee.userId;
              return (
                <tr key={ind} style={{ borderTop: "3px solid #F7F7F7" }}>
                  <td>
                    <div className="d-flex align-items-center">
                      <Avatar
                        name={firstName}
                        size="30"
                        color="#4862FF"
                        round={true}
                        textSizeRatio={2.0}
                      />
                      {/* {<img src={val.user.profile_img} className="appointment-details-employee-pic" style={{ width: 30, height: 30 }} />} */}
                      <div>
                        {
                          <span
                            style={{ marginLeft: "10px" }}
                            className="appointment-details-employee-name"
                          >
                            {firstName}
                          </span>
                        }
                      </div>
                    </div>
                  </td>
                  <td className=" Table_data">
                    <div className="d-flex align-items-center mt-1">
                      <span className="appointment-details-employee-name">
                        {getNestedValue(
                          allServiceMap,
                          [val.booking.services[0].serviceId, "name"],
                          ""
                        )}
                      </span>
                    </div>
                  </td>
                  <td className=" Table_data">
                    <div className="d-flex align-items-center mt-1">
                      <span className="appointment-details-employee-name">
                        {" "}
                        ${totalAmount.toFixed(2)}
                      </span>
                    </div>
                  </td>
                  <td className=" Table_data">
                    <div className="d-flex align-items-center mt-1">
                      <span className="appointment-details-employee-name">
                        {" "}
                        ${totalTip.toFixed(2)}
                      </span>
                    </div>
                  </td>
                  <td className=" Table_data">
                    <div className="d-flex align-items-center mt-1">
                      <span className="appointment-details-employee-name">
                        {" "}
                        ${val.booking.discount.toFixed(2)}
                      </span>
                    </div>
                  </td>
                  <td className=" Table_data">
                    <div className="d-flex align-items-center mt-1">
                      <span className="appointment-details-employee-name">
                        {" "}
                        ${grandTotal.toFixed(2)}
                      </span>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        );
      }
    } else {
      return <DotsLoader isloading={this.state.isloading} />;
    }
  };

  renderLoader = () => {
    const { isLoading } = this.state;
    return <DotsLoader isloading={isLoading} />;
  };

  onChangeSelected = ({ service = "", employee = "" }) => {
    const { data } = this.state;
    this.setState({
      selectedEmployee: employee,
      selectedService: service,
      data,
    });
  };

  renderUserTable = () => {
    return (
      <div className="col-12 p-2 bg-white">
        <div className="table-responsive sticky-header max-vh">
          <table className="table table-borderless appointment-details-table mb-0">
            <thead>
              <tr>
                <th scope="col">
                  <span className="appointment-details-th-text">
                    {i18n.t("container.advancedSearchDialog_name")}
                  </span>
                </th>
                <th scope="col">
                  <span className="appointment-details-th-text">
                    {i18n.t("service")}
                  </span>
                </th>
                <th scope="col">
                  <span className="appointment-details-th-text">
                    {i18n.t("container.amount")}
                  </span>
                </th>
                <th scope="col">
                  <span className="appointment-details-th-text">
                    {i18n.t("container.tip")}
                  </span>
                </th>
                <th scope="col">
                  <span className="appointment-details-th-text">
                    {i18n.t("discount")}
                  </span>
                </th>
                <th scope="col">
                  <span className="appointment-details-th-text">
                    {i18n.t("grand_total")}
                  </span>
                </th>
              </tr>
            </thead>
            {this.renderTableBody()}
          </table>
        </div>
      </div>
    );
  };

  setSelectedValue = (val) => {
    const { startDate, endDate } = this.state;
    let currentDate = moment(Date.now());
    if (val == 1) {
      let day = currentDate.day();
      const convertedStart = moment(currentDate).subtract(day, "days");
      const convertedEnd = moment(convertedStart).add(6, "days");
      this.setState({
        startDate: convertedStart.toDate(),
        endDate: convertedEnd.toDate(),
      });
    } else if (val == 2) {
      const startOfMonth = moment(currentDate).startOf("month");
      const endOfMonth = moment(currentDate).endOf("month");
      this.setState({
        startDate: startOfMonth.toDate(),
        endDate: endOfMonth.toDate(),
      });
    } else if (val == 3) {
      const convertedStart = moment(
        `01-01-${currentDate.year()}`,
        "MM-DD-YYYY"
      );
      const convertedEnd = moment(`12-31-${currentDate.year()}`, "MM-DD-YYYY");
      this.setState({
        startDate: convertedStart.toDate(),
        endDate: convertedEnd.toDate(),
      });
    }
  };

  retrieveReports = (exportToXlsx) => {
    const { selectedEmployee, selectedService } = this.state;
    let token = JSON.parse(sessionStorage.getItem("saloon"));
    this.setState({ isLoading: true }, () => {});
    const { startDate, endDate, allServiceMap = {} } = this.state;
    let payload = {
      start: moment(startDate).format("MM-DD-YYYY"),
      end: moment(endDate).format("MM-DD-YYYY"),
      exportToXlsx,
    };
    if (selectedEmployee) {
      payload.employeeId = selectedEmployee;
    }
    if (selectedService) {
      payload.serviceId = selectedService;
    }
    getReports(payload, token.access_token)
      .then((res) => {
        if (res.data.success) {
          this.setState({ isLoading: false });
        }
        let data = res.data.data;
        data = data.map((val) => {
          const { booking, employee } = val;
          booking.total = booking.totalAmount + (booking.tip || 0);
          val.booking = booking;
          return val;
        });
        this.setState(
          {
            data,
            filteredData: undefined,
          },
          () => {
            this.onChangeSelected({
              employee: selectedEmployee,
              service: selectedService,
            });
          }
        );
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  };

  ShowMenuValues = () => {
    return (
      <div class="px-0">
        <div class="row employee-report-filters pt-3 px-0 mx-0">
          <div className="col-12 col-sm-12 col-md-3 d-flex px-0">
            {i18n.t("container.quick_filter")}
          </div>
          <div className="col-12 col-md-9 px-0 justify-content-end">
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-light btn-sm my-1"
                onClick={() => this.setSelectedValue(1)}
              >
                {i18n.t("container.week")}
              </button>
              <button
                type="button"
                className="btn btn-light btn-sm my-1"
                onClick={() => this.setSelectedValue(2)}
              >
                {i18n.t("container.month")}
              </button>
              <button
                type="button"
                className="btn btn-light btn-sm my-1"
                onClick={() => this.setSelectedValue(3)}
              >
                {i18n.t("container.year")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  retrieveButton = () => {
    return (
      <div class="px-0 row mx-0 justify-content-end">
        <button
          type="button"
          className="btn btn-postitive btn-sm "
          onClick={() => this.retrieveReports(true)}
        >
          {i18n.t("export")}
          <FontAwesomeIcon className="ml-1" icon={["far", "file-excel"]} />
        </button>
        <button
          type="button"
          className="btn btn-light btn-sm"
          onClick={() => this.retrieveReports()}
        >
          {i18n.t("search")}
        </button>
      </div>
    );
  };

  render() {
    const {
      isLoading,
      selectedEmployee,
      selectedService,
      employees = [],
      services = [],
    } = this.state;
    return (
      <>
        {isLoading ? this.renderLoader() : null}

        <div>
          <div className="content-container">
            <div className="row">
              <div className="px-2 pb-2 full-width">
                {this.renderSearchBar()}
                <div className="mx-2">
                  {this.TemplateOption()}
                  {this.ShowMenuValues()}
                  {this.retrieveButton()}
                  <div className="row mx-0 py-2">
                    <FormControl style={{ flex: 11 }} className="pr-2">
                      <InputLabel id="demo-simple-select-helper-label">
                        {i18n.t("container.employee")}
                      </InputLabel>
                      <Select
                        value={selectedEmployee}
                        onChange={(e) =>
                          this.onChangeSelected({
                            employee: e.target.value,
                            service: selectedService,
                          })
                        }
                      >
                        {employees.map((employee) => (
                          <MenuItem value={employee.value}>
                            {employee.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl style={{ flex: 11 }} className="pr-2">
                      <InputLabel id="demo-simple-select-helper-label">
                        {i18n.t("service")}
                      </InputLabel>
                      <Select
                        value={selectedService}
                        onChange={(e) =>
                          this.onChangeSelected({
                            employee: selectedEmployee,
                            service: e.target.value,
                          })
                        }
                      >
                        {services.map((service) => (
                          <MenuItem value={service.value}>
                            {service.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Button
                      onClick={() => this.onChangeSelected({})}
                      size="small"
                      style={{
                        backgroundColor: "#c6c6c6",
                        color: "black",
                        flex: 1,
                      }}
                    >
                      {i18n.t("clear")}
                    </Button>
                  </div>
                </div>
              </div>
              {this.renderUserTable()}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(BookingReport);
