// @flow

import { GET_NOTIFICATIONS, LOGOUT } from "./ActionTypes";

export function request(payload) {
    return {
        payload,
        type: GET_NOTIFICATIONS.REQUEST
    };
}

export function success(data: Object) {
    return {
        data,
        type: GET_NOTIFICATIONS.SUCCESS
    };
}

export function failure(errorMessage: Object) {
    return {
        errorMessage,
        type: GET_NOTIFICATIONS.FAILURE
    };
}

export function updateById(data) {
    return {
        data,
        type: GET_NOTIFICATIONS.UPDATE_BY_ID
    };
}

export function logout() {
    return {
        type: LOGOUT
    };
}
