import React, { useState } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import BalanceCards from "./BalanceCards.component";
import SendModal from "./SendModal.component";
import i18n from "../../../../i18n";
import { Translation } from "react-i18next";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { getNestedValue } from "../../../../util/objectMethods";

const statuses = [
  { value: undefined, label: i18n.t("all") },
  { value: "Approved", label: i18n.t("Approved") },
  { value: "Rejected", label: i18n.t("Rejected") },
  { value: "Requested", label: i18n.t("Requested") },
];

function Header({
  setStatus,
  setEmployee,
  employees = [],
  totalPending = 0,
  totalAvailable = 0,
  totalPendingFromEmployee = 0,
  totalAvailableFromEmployee = 0,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  return (
    <>
      <div className="col-12 p-3">
        <div className="row mx-0 justify-content-between">
          <div>
            <h1 className="saloon-dashboard-heading">
              <Translation>{(t) => t("container.request_funds")}</Translation>
            </h1>
          </div>
          <div>
            <>
              <Button
                onClick={(ev) => setAnchorEl(ev.currentTarget)}
                style={{
                  backgroundColor: "#ff3600",
                  color: "white",
                }}
                className="mr-1 mt-1"
                aria-controls="status-menu"
                aria-haspopup="true"
              >
                {i18n.t("status")}
              </Button>
              <Menu
                id="status-menu"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                keepMounted
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(undefined)}
              >
                {statuses.map((item) => (
                  <MenuItem
                    key={item.value}
                    onClick={() => {
                      setAnchorEl(undefined);
                      setStatus(item.value);
                    }}
                  >
                    {item.label}
                  </MenuItem>
                ))}
              </Menu>
            </>
          </div>
        </div>
        <h4>{i18n.t("total_owed_to_employees")}</h4>
        <BalanceCards
          pending={totalPending}
          available={totalAvailable}
          pendingColor="#f9902f"
          availableColor="#829f61"
        />
        <h4>{i18n.t("total_owed_by_employees")}</h4>
        <BalanceCards
          pending={totalPendingFromEmployee}
          available={totalAvailableFromEmployee}
          pendingColor="#f9902f"
          availableColor="#829f61"
        />
        <FormControl className="pr-2">
          <InputLabel>{i18n.t("container.employee")}</InputLabel>
          <Select onChange={(e) => setEmployee(e.target.value)}>
            <MenuItem value={""}>{i18n.t("all")}</MenuItem>
            {employees.map((employee) => (
              <MenuItem key={employee._id} value={employee._id}>
                {getNestedValue(employee, [
                  "employeeId",
                  "userId",
                  "firstName",
                ])}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(Header);
