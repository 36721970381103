import React, { useMemo, useRef } from "react";
import { DropdownList, DateTimePicker } from "react-widgets";
import DatePicker from "react-datepicker";
import moment from "moment";
import ButtonGroup from "@material-ui/core/ButtonGroup";

import "./PlanFilter.scss";
import i18n from "../../../i18n";
import PrimaryButton from "../../Core/PrimaryButton";
import CreateInvoice from "../../CreateInvoice/CreateInvoice.component";
import { useState } from "react";

moment.locale("en");

const PlanFilter = ({
  handleCalendarView,
  handleCalendarDate,
  calDate,
  slideSideNav,
  createAppointment,
  companies,
  companyTaxMap,
}) => {
  const calViews = ["day", "work week", "week"];
  const [isCreateInvoiceModalOpen, setIsCreateInvoiceModalOpen] =
    useState(false);
  // new momentLocalizer();

  return (
    <div className="plan-filter">
      <div className="toggle-nav">
        <span className="calendar-toggle-nav-slide" onClick={slideSideNav}>
          &#9776;
        </span>
      </div>
      <div className="flex-end row mx-0" style={{ alignItems: "center" }}>
        <DatePicker
          className="form-control"
          selected={calDate}
          onChange={(date) => {
            console.log(date);
            handleCalendarDate(date);
          }}
        />
        <div className="plan-filter-option-item">
          <select
            className="form-control"
            defaultValue={calViews[0]}
            onChange={(value) => {
              handleCalendarView(value.target.value);
            }}
          >
            {calViews.map((calView) => (
              <option className="form-group" value={calView} id={calView}>
                {i18n.t(calView)}
              </option>
            ))}
          </select>
        </div>
        <div className="row mx-0 px-2 flex-column text-white text-left">
          <p className="text-uppercase pb-0 mb-0">{i18n.t("create")}</p>
          <div className="row mx-0 px-0">
            <div className="mr-1">
              <PrimaryButton
                label={i18n.t("appointment")}
                onClick={createAppointment}
                props={{ size: "small" }}
              />
            </div>
            <PrimaryButton
              label={i18n.t("sale")}
              onClick={() => setIsCreateInvoiceModalOpen(true)}
              props={{ size: "small" }}
            />
          </div>
        </div>
      </div>
      {isCreateInvoiceModalOpen && (
        <CreateInvoice
          close={() => {
            setIsCreateInvoiceModalOpen(false);
          }}
          companies={companies}
          companyTaxMap={companyTaxMap}
        />
      )}
    </div>
  );
};

export default PlanFilter;
