import { connect } from "react-redux";
import React, { Component } from "react";
import i18n from "../../../i18n";
import { DotsLoader } from "../../../components";
import { upsertCustomer } from "../../../config/simpleApiCalls";
import { ErrorHelper, SuccessHelper } from "../../../helpers";

import "./styles.css";
import CustomerDetails from "../../../components/Saloon/Customer/CustomerDetails";

class AddCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isloading: false,
    };
  }

  componentDidMount = async () => {
    const saloonData = await JSON.parse(sessionStorage.getItem("saloon"));
    if (saloonData) {
      this.setState({
        access_token: saloonData.access_token,
      });
    }
  };

  handleSaloonCreateCustomer = ({ name, email, phoneNo }) => {
    this.setState({ isloading: true });
    const { access_token } = this.state;
    const payload = {
      name,
      email,
      phoneNo,
    };

    return upsertCustomer(payload, access_token)
      .then((res) => {
        if (res.data.success) {
          SuccessHelper.handleSuccess("Customer successfully added.", true);
          this.setState({
            isloading: false,
          });
        } else {
          ErrorHelper.handleErrors(res.data.msg, true);
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        ErrorHelper.handleErrors("An error occured", true);
        this.setState({ isloading: false });
      });
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  renderSaloonDashboardHeading = () => {
    return (
      <div className="col-12 p-3">
        <h1 className="saloon-dashboard-heading">
          {i18n.t("container.add_customer")}:
        </h1>
      </div>
    );
  };

  renderAddCustomerForm = () => {
    return (
      <div className="col-12 p-3">
        <div className="col-12 p-3 bg-white" style={{ borderRadius: "1rem" }}>
          <CustomerDetails onSave={this.handleSaloonCreateCustomer} />
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.renderLoader()}
        <div className="container">
          <div className="row">
            {this.renderSaloonDashboardHeading()}
            {this.renderAddCustomerForm()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(AddCustomer);
