import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import PayrollReportTable from "../../../components/Saloon/PayrollReport/PayrollReportTable.component";
import i18n from "../../../i18n";
import PrimaryButton from "../../../components/Core/PrimaryButton";
import { DotsLoader } from "../../../components";
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import {
  getPayrollReport,
  getAllEmployeeAssociatedWithCompany,
  adjustWorkingHours,
  addWorkingHours,
} from "../../../config/simpleApiCalls";
import DatePicker from "react-datepicker";
import moment from "moment";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const PayrollReport = () => {
  const [accessToken, setAccessToken] = useState("");
  const [allEmployees, setAllEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(
    moment(moment.now()).subtract(7, "d").toDate()
  );
  const [endDate, setEndDate] = useState(moment(moment.now()).toDate());
  const [adjustedMap, setAdjustedMap] = useState({});
  const [toAddMap, setToAddMap] = useState({});
  const [employeeDataMap, setEmployeeDataMap] = useState({});
  const getReport = async (exportToXlsx) => {
    setLoading(true);
    setToAddMap({});
    setAdjustedMap({});
    const copyStartDate = moment(startDate);
    const range = [];
    while (!copyStartDate.isAfter(endDate)) {
      range.push(copyStartDate.format("MM-DD-YYYY"));
      copyStartDate.add(1, "d");
    }
    const employeeDataMap = {};
    allEmployees.map((employee) => {
      employeeDataMap[employee.value] = {};
      range.map((date) => {
        employeeDataMap[employee.value][date] = {
          details: [],
          date: `${date}`,
          totalWorkMinutes: 0,
        };
      });
    });

    return await getPayrollReport(
      { from: startDate, to: endDate, exportToXlsx },
      accessToken
    ).then(({ data = {} }) => {
      if (data.success) {
        data.data.map((element) => {
          const {
            _id: { employeeId },
            entries,
          } = element;
          entries.map((element) => {
            const { checkIns, checkOuts, createdDate } = element;
            const date = moment(createdDate).format("MM-DD-YYYY");
            if (!employeeDataMap[employeeId]) {
              employeeDataMap[employeeId] = {};
            }
            employeeDataMap[employeeId][date] = {
              details: checkIns.map((checkIn, index) => [
                checkIn,
                checkOuts[index],
              ]),
              date,
              ...element,
            };
          });
        });

        setEmployeeDataMap(employeeDataMap);
      }
      setLoading(false);
    });
  };

  const getAllEmployees = async () => {
    return await getAllEmployeeAssociatedWithCompany(accessToken)
      .then((res) => {
        if (res.data.success) {
          setAllEmployees(
            res.data.Data.map((employee) => ({
              value: employee._id,
              label: employee.employee.userId.firstName,
            }))
          );
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const saloonData = JSON.parse(sessionStorage.getItem("saloon"));
    setAccessToken(saloonData.access_token);
  }, []);

  useEffect(async () => {
    if (accessToken) {
      setLoading(true);
      await getAllEmployees();
      setLoading(false);
    }
  }, [accessToken]);

  useEffect(async () => {
    if (allEmployees.length) {
      setLoading(true);
      await getReport();
      setLoading(false);
    }
  }, [allEmployees]);

  const setSelectedValue = (val) => {
    let currentDate = moment(Date.now());
    if (val == 1) {
      let day = currentDate.day();
      const convertedStart = moment(currentDate).subtract(day, "days");
      const convertedEnd = moment(convertedStart).add(6, "days");
      setStartDate(convertedStart.toDate());
      setEndDate(convertedEnd.toDate());
    } else if (val == 2) {
      const startOfMonth = moment(currentDate).startOf("month");
      const endOfMonth = moment(currentDate).endOf("month");
      setStartDate(startOfMonth.toDate());
      setEndDate(endOfMonth.toDate());
    } else if (val == 3) {
      const convertedStart = moment(
        `01-01-${currentDate.year()}`,
        "MM-DD-YYYY"
      );
      const convertedEnd = moment(`12-31-${currentDate.year()}`, "MM-DD-YYYY");
      setStartDate(convertedStart.toDate());
      setEndDate(convertedEnd.toDate());
    }
  };

  const onAdjust = (employeeId, overriddenTotalWorkMinutes, row, index) => {
    if (row._id) {
      adjustedMap[row._id] = overriddenTotalWorkMinutes;
      setAdjustedMap(adjustedMap);
    } else {
      if (!toAddMap[employeeId]) {
        toAddMap[employeeId] = {};
      }
      toAddMap[employeeId][row.date] = {
        ...row,
        overriddenTotalWorkMinutes,
        totalWorkMinutes: overriddenTotalWorkMinutes,
      };
      setToAddMap(toAddMap);
    }
    const copy = { ...employeeDataMap };
    copy[employeeId][row.date] = {
      ...row,
      overriddenTotalWorkMinutes,
      totalWorkMinutes: overriddenTotalWorkMinutes,
    };
    setEmployeeDataMap(copy);
  };
  const onSave = () => {
    const toAdjust = { ...adjustedMap };
    setLoading(true);
    const addPromises = [];
    Object.keys(toAddMap).map((employeeId) => {
      Object.keys(toAddMap[employeeId]).map((currentDate) => {
        const { date, totalWorkMinutes } = toAddMap[employeeId][currentDate];
        addPromises.push(
          addWorkingHours(
            {
              employeeId,
              totalWorkMinutes,
              createdDate: moment(date, "MM-DD-YYYY").startOf("d").toDate(),
            },
            accessToken
          )
        );
      });
    });
    Promise.all([
      ...Object.keys(adjustedMap).map((workingDayId) => {
        adjustWorkingHours(
          {
            _id: workingDayId,
            adjustment: adjustedMap[workingDayId],
          },
          accessToken
        );
      }),
      ...addPromises,
    ])
      .then(() => {
        setAdjustedMap(toAdjust);
        setToAddMap(toAddMap);
        SuccessHelper.handleSuccess(i18n.t("successfully_updated"), true);
        getReport();
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const onStartDateChange = (val) => {
    if (val > endDate) {
      setStartDate(endDate);
      setEndDate(val);
    } else {
      setStartDate(val);
    }
  };

  const onEndDateChange = (val) => {
    if (val < startDate) {
      setStartDate(val);
      setEndDate(startDate);
    } else {
      setEndDate(val);
    }
  };

  const employeeTotalHoursMap = {};
  Object.keys(employeeDataMap).map((employeeId) => {
    let totalMinutes = 0;
    Object.keys(employeeDataMap[employeeId]).map((date) => {
      totalMinutes += employeeDataMap[employeeId][date].totalWorkMinutes;
    });
    employeeTotalHoursMap[employeeId] =
      Math.round((totalMinutes / 60 + Number.EPSILON) * 100) / 100;
  });
  return (
    <div className="content-container">
      <DotsLoader isloading={loading} />
      <div className="d-flex justify-content-between align-items-center">
        <h1 className="saloon-dashboard-heading pb-3">
          {i18n.t("payroll_report")}
        </h1>
        {Object.keys(adjustedMap).length || Object.keys(toAddMap).length ? (
          <PrimaryButton label={i18n.t("save_adjustments")} onClick={onSave} />
        ) : null}
      </div>
      <div
        style={{ flex: 1, display: "flex", justifyContent: "space-between" }}
        className="employee-report-filters"
      >
        <div>{i18n.t('date_range')}</div>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <div className="mx-1">
            <DatePicker
              className="form-control saloon-form-input"
              placeholderText="Select Start Date"
              selected={startDate}
              onChange={onStartDateChange}
              dateFormat="MM/dd/yyyy"
            />
          </div>
          <div className="mx-1">
            <DatePicker
              className="form-control saloon-form-input"
              placeholderText="Select End Date"
              selected={endDate}
              onChange={onEndDateChange}
              dateFormat="MM/dd/yyyy"
            />
          </div>
        </div>
      </div>
      <div class="px-0">
        <div class="row employee-report-filters pt-3 px-0 mx-0">
          <div className="col-12 col-sm-12 col-md-3 d-flex px-0">
            {i18n.t("container.quick_filter")}
          </div>
          <div className="col-12 col-md-9 px-0 justify-content-end">
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-light btn-sm my-1"
                onClick={() => setSelectedValue(1)}
              >
                {i18n.t("container.week")}
              </button>
              <button
                type="button"
                className="btn btn-light btn-sm my-1"
                onClick={() => setSelectedValue(2)}
              >
                {i18n.t("container.month")}
              </button>
              <button
                type="button"
                className="btn btn-light btn-sm my-1"
                onClick={() => setSelectedValue(3)}
              >
                {i18n.t("container.year")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="px-0 row mx-0 justify-content-end">
        <button
          type="button"
          className="btn btn-accept btn-sm"
          onClick={() => getReport(true)}
        >
          {i18n.t("export")}
          <FontAwesomeIcon className="ml-1" icon={["far", "file-excel"]} />
        </button>
        <button
          type="button"
          className="btn btn-light btn-sm"
          onClick={() => getReport()}
        >
          {i18n.t("search")}
        </button>
      </div>
      <div className="row mx-0 py-2">
        <FormControl style={{ flex: 11 }} className="pr-2">
          <InputLabel id="demo-simple-select-helper-label">
            {i18n.t("container.employee")}
          </InputLabel>
          <Select
            value={selectedEmployee}
            onChange={(e) => setSelectedEmployee(e.target.value)}
          >
            {allEmployees.map((employee) => (
              <MenuItem value={employee.value}>{employee.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          onClick={() => setSelectedEmployee()}
          size="small"
          style={{
            backgroundColor: "#c6c6c6",
            color: "black",
            flex: 1,
          }}
        >
          {i18n.t("clear")}
        </Button>
      </div>
      {allEmployees.map((employee) => {
        if (employeeDataMap[employee.value]) {
          if (selectedEmployee) {
            if (employee.value == selectedEmployee) {
              return (
                <div className="mb-1 mt-3">
                  <div className="row mx-0 px-0 justify-content-between align-items-center">
                    <h4>{employee.label}</h4>
                    <div>
                      {i18n.t("total_hours_worked", {
                        totalHoursWorked: employeeTotalHoursMap[employee.value],
                      })}
                    </div>
                  </div>
                  <PayrollReportTable
                    data={Object.values(employeeDataMap[employee.value])}
                    onAdjust={(...params) =>
                      onAdjust(employee.value, ...params)
                    }
                  />
                </div>
              );
            }
          } else {
            return (
              <div className="mb-1 mt-3">
                <div className="row mx-0 px-0 justify-content-between align-items-center">
                  <h4>{employee.label}</h4>
                  <div>
                    {i18n.t("total_hours_worked", {
                      totalHoursWorked: employeeTotalHoursMap[employee.value],
                    })}
                  </div>
                </div>
                <PayrollReportTable
                  data={Object.values(employeeDataMap[employee.value])}
                  onAdjust={(...params) => onAdjust(employee.value, ...params)}
                />
              </div>
            );
          }
        }
      })}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(PayrollReport);
