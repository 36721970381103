import { take, put, call, fork } from "redux-saga/effects";

import * as types from "../../actions/ActionTypes";
import { success, failure } from "../../actions/Employee/ImpersonateEmployee";

import axios from 'axios'
import { impersonation } from "../../../config/WebServices";
import { ErrorHelper } from "../../../helpers";

function callRequest(data) {
  return axios
    .get(
      impersonation, 
      {
        params: {
          id: data,
          type: 'employee'
        }
      }
    )
}

function* watchRequest() {
  while (true) {
    const { payload } = yield take(types.IMPERSONATE_EMPLOYEE.REQUEST);
    try {
      const { data } = yield call(callRequest, payload);
      yield put(success(data));
    } catch (err) {
      yield put(failure(err));
      ErrorHelper.handleErrors(err, true);
    }
  }
}

export default function* root() {
  yield fork(watchRequest);
}
