// @flow
import Immutable from "seamless-immutable";
import * as types from "../actions/ActionTypes";

const initialState = Immutable({
  isFetching: false,
  errorMessage: "",
  active: {},
  data: [],
});

export default (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case types.FORM_ANSWER.GET_BY_ID_SAGA:
      return Immutable.merge(state, {
        active: {},
        isFetching: true,
      });
    case types.FORM_ANSWER.GET_BY_ID:
      return Immutable.merge(state, {
        isFetching: false,
        active: action.data,
      });
    case types.FORM_ANSWER.GET_SAGA:
      return Immutable.merge(state, {
        data: [],
        isFetching: true,
      });
    case types.FORM_ANSWER.GET:
      return Immutable.merge(state, {
        isFetching: false,
        data: action.data,
      });
    case types.FORM_ANSWER.SUBMIT_FILLED_FORM_SAGA:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case types.FORM_ANSWER.SUBMIT_FILLED_FORM:
      return Immutable.merge(state, {
        isFetching: false,
      });
    case types.FORM_ANSWER.MARK_AS_VIEWED_SAGA:
      return Immutable.merge(state, {
        isFetching: true,
      });
    case types.FORM_ANSWER.MARK_AS_VIEWED:
      return Immutable.merge(state, {
        isFetching: false,
      });
    case types.FORM_ANSWER.REQUEST_FAILURE:
      return Immutable.merge(state, {
        isFetching: false,
      });

    case types.LOGOUT:
      return initialState;
    case types.CLEAR_LOGOUT:
      return initialState;
    default:
      return state;
  }
};
