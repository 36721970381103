import React, { Component, Fragment } from "react";
import { Images } from "../../../theme";
import { RiEditFill } from "react-icons/ri";
import { DotsLoader } from "../../../components";
import {
  getSaloonTemplateData,
  getCompanyPorfolio,
  getEmployeeByServiceCompany,
  createBooking,
  createUpdateTemplate,
  getTemplateData,
} from "../../../config/simpleApiCalls";
import ReactStars from "react-rating-stars-component";
import { getNestedValue } from "../../../util/objectMethods";

import SideNav from "../Template2/SideNav";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import Modal from "react-bootstrap/Modal";
import Lightbox from "react-lightbox-component";
import { SuccessHelper, ErrorHelper } from "../../../helpers";
import {
  baseUrl
} from "../../../config/WebServices";
// import templateImages from "./img/index";
import "./css/bootstrap.scoped.scss";
import "./css/default.scoped.scss";
import "./css/style.scoped.scss";
import templateImages from "./img/index";

class Template5 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      routeName: "",
      showSidebar: false,
      showDropdownMenu: false,
      companyId: "",
      access_token: "",
      portfolioImages: [],
      portfolioVideos: [],
      employeeTimings: [],
      selectedCategoryId: "",
      selecteedEmployeeId: "",
      selectedValue: "",
      selectedServiceId: "",
      selectedTime: "",
      selectedPrice: "",
      jumbotronImgFile: null,
      jumbotronImgUrl: null,
      aboutImgUrl: null,
      aboutImgFile: null,
      aboutFirstHeading: "GET YOUR STYLE",
      aboutSecondHeading: "YOU DESERVE",
      jumbotronFirstHeading: "GET YOUR STYLE",
      jumbotronSecHeading: "YOU DESERVE",
      isEditTemplate: false,
      serviceImgUrl: null,
      serviceImgFile: null,

      companyList: [],
      companyPortfolio: [],
      employeeData: [],
      checkInTime: "",
      isloading: false,
      employeData: [],
      showNav: false,
      width: 0,
      height: 0
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    this.services = React.createRef();
    this.aboutUs = React.createRef();
    this.home = React.createRef();
    this.appointment = React.createRef();
    this.contact = React.createRef();
    this.gallery = React.createRef();
    this.team = React.createRef();
    
  }

  componentDidMount = async () => {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    this.setState({ isLoading: true })
    const { location: { pathname } } = this.props;
    const pathPieces = pathname.split('/')
    let companyId = pathPieces[2];

    if (pathname === '/saloon-edit-template-5') {
        this.setState({ isEditTemplate: true });
        const { _id } = JSON.parse(sessionStorage.getItem("company"))
        companyId = _id
    }

    const user = await JSON.parse(sessionStorage.getItem("user"));
    this.setState({
      companyId,
      user: user,
    });
    if (companyId) {
      this.getAllData();
    } else {
      this.getAllTemplateData()
    }

    this.getPortfolioData();
  };
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  onChangeaboutSecondHeading = (text) =>
    this.setState({ aboutSecondHeading: text.target.value });
  onChangeaboutFirstHeading = (text) =>
    this.setState({ aboutFirstHeading: text.target.value });
  onChangeJumbotronFirstHeading = (text) =>
    this.setState({ jumbotronFirstHeading: text.target.value });


  getAllTemplateData = () => {
    let token = JSON.parse(sessionStorage.getItem('saloon'))
    const { state } = this.props.location

    getTemplateData(token.access_token, state).then((res) => {
      this.setState({ isloading: true })
      if (res.data.success) {
        if (res.data.data != null) {
          this.setState({
            headerFirstHeading: res.data.data.headerFirstHeading,
            headerSecondHeading: res.data.data.headerSecondHeading,
            jumbotronImgFile: res.data.data.headerImage,
            jumbotronImgUrl: res.data.data.headerImage,
            aboutFirstHeading: res.data.data.aboutFirstHeading,
            aboutSecHeading: res.data.data.aboutSecondHeading,
            aboutImgFile: res.data.data.aboutFirstImg,
            aboutImgUrl: res.data.data.aboutFirstImg,
            aboutSecBgImgUrl: res.data.data.aboutSecondImg,
            aboutSecBgImgFile: res.data.data.aboutSecondImg,
            priceBgImgUrl: res.data.data.footerImage,
            sinceText: res.data.data.sinceText,
            
            priceBgImgFile: res.data.data.footerImage,
            isloading: false

          })
        }else{
          this.setState({isloading: false})
        }
      }
    }).catch((err) => { this.setState({ isloading: false }) })
  }

  getAllData = () => {
    this.setState({
      isloading: true,
    });
    const { companyId } = this.state;
    const payload = { companyId };
    getSaloonTemplateData(payload)
      .then((res) => {

        console.log(res.data)
        if (res.data.success) {
          if (res.data.data[0] != null) {
            this.setState({companyList: res.data.data})

            this.setState({

              companyList: res.data.data,
              employeData: res.data.data[0],
              headerFirstHeading: res.data.data[0].customTempalte.headerFirstHeading,
              headerSecondHeading: res.data.data[0].customTempalte.headerSecondHeading,
              jumbotronImgFile: res.data.data[0].customTempalte.headerImage,
              jumbotronImgUrl: res.data.data[0].customTempalte.headerImage,
              aboutFirstHeading: res.data.data[0].customTempalte.aboutFirstHeading,
              aboutSecHeading: res.data.data[0].customTempalte.aboutSecondHeading,
              aboutImgFile: res.data.data[0].customTempalte.aboutFirstImg,
              aboutImgUrl: res.data.data[0].customTempalte.aboutFirstImg,
              aboutSecBgImgUrl: res.data.data[0].customTempalte.aboutSecondImg,
              sinceText:res.data.data[0].customTempalte.sinceText,
              aboutSecBgImgFile: res.data.data[0].customTempalte.aboutSecondImg,
              priceBgImgUrl: res.data.data[0].customTempalte.footerImage,
              priceBgImgFile: res.data.data[0].customTempalte.footerImage,
              isloading: false
            });
          }
        }
      })
      .catch((error) => {
        console.log(error, "errorrrrrrrrrrrrrrrrrrr");
      });
  };


  getPortfolioData = () => {
    this.setState({ isloading: true });
    const { companyId } = this.state;
    const payload = { companyId };
    getCompanyPorfolio(payload)
      .then((res) => {
        if (res.data.success) {
          // this.setState({ isloading: false, companyPortfolio: res.data.data });
          let portfolioImages = [];
          let portfolioVideos = [];
          console.log('res.data.data', res.data.data)
          res.data.data.map((val) => {
            if (val.post && val.post.length > 0) {
              val.post.map((val2, ind2) => {
                if (val2.images.length > 0) {
                  portfolioImages.push(val2.images[0]);
                } else if (val2.videos.length > 0) {
                  portfolioVideos.push(val2.videos[0]);
                }
              });
            }
          });
          this.setState({ isloading: false, portfolioImages, portfolioVideos });
        }
      })
      .catch((error) => {
        console.log(error, "errorrrrrrrrrrrrrrrrrrr");
      });
  };
  
  goToAppointmentForm = async () => {
    // const { user } = this.state;
    // const {
    //   location: { pathname },
    //   history: { push },
    // } = this.props;
    // push(`${pathname}/create-appointment`);
    const { companyId } = this.state;
    const user = await JSON.parse(sessionStorage.getItem("user"));
    window.location.assign(baseUrl+`booking/getForm?id=${companyId}&phoneNo=${user ? user.phoneNo : ''}&name=${user ? user.userName : ''}`); 
  };

  jumbotronImgHandler = (event) => {
    const imageUrlRegex = /([a-zA-Z0-9\s_\\.\-:])+(.png|.jpeg|.jpg|.gif)$/gm;
    if (event.target.files[0]) {
      let isValidateType = event.target.files[0].type;
      if (isValidateType.match(imageUrlRegex)) {
        const file = URL.createObjectURL(event.target.files[0]);
        this.setState({
          jumbotronImgUrl: file,
          jumbotronImgFile: event.target.files[0],
        });
      } else {
        ErrorHelper.handleErrors("Image format not supported", true);
      }
    }
  };

  aboutImgHandler = (event) => {
    const imageUrlRegex = /([a-zA-Z0-9\s_\\.\-:])+(.png|.jpeg|.jpg|.gif)$/gm;
    // const imageUrlRegex = (/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i)

    if (event.target.files[0]) {
        let isValidateType = event.target.files[0].type;
        if (isValidateType.match(imageUrlRegex)) {
            const file = URL.createObjectURL(event.target.files[0]);
            this.setState({
                aboutImgUrl: file,
                aboutImgFile: event.target.files[0],
            });
        } else {
            ErrorHelper.handleErrors("Image format not supported", true);
        }
    }
  };

  handleNavigation = (route) => {
    this.props.history.push(route);
  };
  
  handleView = (key) => {
    this.setState({ showNav: false })
    this[key].current.scrollIntoView({ behavior: "smooth" });
  };

  handlePublishBtn = () => {
    const {
      jumbotronImgFile,
      jumbotronFirstHeading,
      jumbotronSecHeading,
      aboutFirstHeading,
      aboutSecondHeading,
      serviceImgFile,
      aboutImgFile,
    } = this.state;
    const { state } = this.props.location;
    this.setState({ isloading: true });

    let payloadFormdata = new FormData();
    if(jumbotronImgFile) {
        payloadFormdata.append("headerImage", jumbotronImgFile);
    }
    payloadFormdata.append("headerFirstHeading", jumbotronFirstHeading);
    payloadFormdata.append("headerSecondHeading", jumbotronSecHeading);
    payloadFormdata.append("sinceText", "");
    payloadFormdata.append("aboutFirstHeading", aboutFirstHeading);
    payloadFormdata.append("aboutSecondHeading", aboutSecondHeading);
    payloadFormdata.append("aboutFirstImg", aboutImgFile);
    payloadFormdata.append("aboutSecondImg", "");
    payloadFormdata.append("footerImage", serviceImgFile);
    payloadFormdata.append("templateID", state);

    let token = JSON.parse(sessionStorage.getItem("saloon"));
    createUpdateTemplate(payloadFormdata, token.access_token).then((res) => {
      if (res.data.success) {
        this.setState({ isloading: false });

        SuccessHelper.handleSuccess("Published successfully", true);
      } else {
        this.setState({ isloading: false });

        ErrorHelper.handleErrors("Failed to publish", true);
      }
    });

    // for (var key of payloadFormdata.entries()) {
    //   console.log(key[0] + ', ' + key[1]);
    // }
  };
  
  renderEditButtons = (onPresEdit, onPressRemove) => {
    return (
      <span className="templateEditBtnDiv">
        <span onClick={onPresEdit} className="templateEditBtns">
          <RiEditFill color="#fff" size={17} />
        </span>
        <span onClick={onPressRemove} className="templateEditBtns">
          &times;
        </span>
      </span>
    );
  };

  renderPortfolioItemVideo = (image) => {
    return (
      <div>
        <figure className="col-md-4">
            <div className="gallery-image-padding">
              <video width="320" height="240" controls>
                <source src={image} />
              </video>
            </div>
        </figure>
      </div>
    );
  };

  renderPortfolioVideo = () => {
    const { portfolioVideos, sinceText, isEditTemplate } = this.state;
    return (
        <div className="row portfolio-items">
            {portfolioVideos &&
              portfolioVideos.map((val) => this.renderPortfolioItemVideo(val.url)
            )}
        </div>
    );
  };

  renderPortfolio = () => {
    const { portfolioImages } = this.state;
    return (
        <div className="row portfolio-items">
            {portfolioImages &&
                portfolioImages.map((val) => {
                    return (
                        <figure className="col-md-4 col-sm-6">
                            <SRLWrapper>
                                <div className="gallery-image-padding">
                                    <img className="img-fluid1" src={val.url} alt="" />
                                </div>
                            </SRLWrapper>
                        </figure>
                    )
                })
            }
        </div>
    );
  };
  
  renderServiceComponent = (img, heading, price, duration) => {
    return (
        <div class="col-md-3 col-xs-12 service" onClick={this.goToAppointmentForm}>
            <div class="offerContent">
                <img src={img} alt={heading}/>
                <div class="offerMasking">
                    <div class="offerTitle"><h4><a>{heading}</a></h4></div>
                </div>
                <div class="offerPrice"><h5>${price}</h5></div>
            </div>
        </div>
    );
  };

  
  renderEmployeeComponent = (img, name) => {
    return (
  
        <div class="col-12">
            <div class="owl-carousel expartSlider">
                <div class="slide">
                    <div class="expertBox">
                        <div class="expertImage">
                        <img src={img} alt={name}/>
                        </div>
                        <div class="expertCaption ">
                            <h2>{name}</h2>
                        </div>
                    </div>
                </div> 
        
            </div>
        </div>
    );
  };


  
  renderEditTemplateHeader = () => (
    <div className="editTemplateHeaderDiv">
      <span
        onClick={() => this.handlePublishBtn()}
        className="editTemplateHeaderBtn"
      >
        Publish
      </span>
      <span
        onClick={() => this.handleNavigation("/saloon-choose-a-template")}
        className="editTemplateHeaderBtn"
      >
        Cancel
      </span>
    </div>
  );

  renderEditTextInputs = (value, onChange, classes, style = {}) => (
    <input
      type="text"
      value={value}
      onChange={(text) => onChange(text)}
      className={classes}
      style={style}
    />
  );

  render() {
    const { 
        isEditTemplate, 
        isloading, 
        companyList, 
        showNav, 
        jumbotronFirstHeading,
        headerFirstHeading, 
        headerSecondHeading,
        aboutFirstHeading,
        aboutSecondHeading,
        jumbotronImgUrl,
        aboutImgUrl,
    } = this.state;
    console.log(companyList[0], aboutImgUrl)
    let CompanyLogo = companyList[0]?.saloons[0]?.companyLogo;
    let companyName = companyList[0]?.saloons[0]?.name
    let tagLine = getNestedValue(companyList, [0, 'saloons', 0, 'companyTagLine'], '')
    let shortDesc = getNestedValue(companyList, [0, 'saloons', 0, 'companyShortDescription'], '')
    const saloonsVal = companyList[0];
    let shouldShowNav = showNav
    if(this.state.width > 768) {
        shouldShowNav = true
    }
    return (
        <div>
            {isEditTemplate && this.renderEditTemplateHeader()}
            <div className='template5 bootstrap'>

            <body id="body" class="body-wrapper static">
                <div class="se-pre-con"></div>
                <div class="main-wrapper">
                <header id="pageTop" class="header">

                    <nav class="navbar navbar-expand-md main-nav navbar-light">
                    <div class="top-bar">
                        <a class="navbar-brand" onClick={() => this.handleNavigation("/")}>
                            <img src={CompanyLogo} alt="logo"/>
                        </a>
                
                        <button class="navbar-toggler" type="button" onClick={() => { this.setState({showNav: !showNav}) }} aria-label="Toggle navigation">
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                        </button>

                    </div>

                    { shouldShowNav && 
                        <div class="navbar-collapse">
                            <ul class="navbar-nav ml-auto">
                                <li class="nav-item">
                                    <a class="nav-link" onClick={() => this.handleView('home')}>Home</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" onClick={() => this.handleView('aboutUs')}>About Us</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" onClick={() => this.handleView('services')}>Services</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" onClick={() => this.handleView('gallery')}>Gallery</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" onClick={() => this.handleView('team')}>Team</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" onClick={() => this.handleView('appointment')}>Appointment</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" onClick={() => this.handleView('contact')}>Contact</a>
                                </li>
                            </ul>
                        </div>
                    }
                    </nav>
                    
                </header>

                <section ref={this.home} class="main-slider" data-loop="true" data-autoplay="true" data-interval="7000">
                      {isEditTemplate && (
                          <input
                              type="file"
                              style={{ display: "none" }}
                              onChange={this.jumbotronImgHandler}
                              onClick={(event) => {
                              event.target.value = null;
                              }}
                              ref={(fileInput) => (this.jumbotronImgInput = fileInput)}
                              accept="image/*"
                          />
                      )}
                    <div class="inner">
                        <div class="slide slide1"
                            style={{ 'background-image': `url(${jumbotronImgUrl ? jumbotronImgUrl : templateImages.background})`}}
                        >
                            {isEditTemplate &&
                                this.renderEditButtons(
                                    () => this.jumbotronImgInput.click(),
                                    () =>
                                    this.setState({ jumbotronImgFile: null, jumbotronImgUrl: null })
                                )
                            }
                            <div class="container">
                            <div class="slide-inner1 common-inner">
                                <span class="h1 from-bottom">Welcome to {companyName}</span><br/>
                                {!isEditTemplate && (
                                  <span class="h4 from-bottom">{jumbotronFirstHeading}</span>
                                )}
                                {isEditTemplate &&
                                    <span class="h4 from-bottom">
                                        {this.renderEditTextInputs(
                                            jumbotronFirstHeading,
                                            this.onChangeJumbotronFirstHeading,
                                            "aboutHeadingInput h4 from-bottom",
                                            {textAlign: 'left'}
                                        )}
                                    </span>
                                }
                                <button onClick={this.goToAppointmentForm} class="btn btn-primary first-btn px-1">Make Appointment</button>
                            </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section ref={this.aboutUs} class="container-fluid clearfix aboutSection patternbg divider" >
                    <div class="aboutInner">
                    <div class="sepcialContainer">
                        <div class="row">
                        <div class="col-sm-6 col-xs-12 rightPadding">
                            {isEditTemplate && (
                              <input
                                type="file"
                                style={{ display: "none" }}
                                onChange={this.aboutImgHandler}
                                onClick={(event) => {
                                  event.target.value = null;
                                }}
                                ref={(fileInput) => (this.aboutImgInput = fileInput)}
                                accept="image/*"
                              />
                            )}
                            <div class="imagebox ">
                              {isEditTemplate &&
                                this.renderEditButtons(
                                  () => this.aboutImgInput.click(),
                                  () =>
                                    this.setState({
                                      aboutImgFile: null,
                                      aboutImgUrl: null,
                                    })
                                )}
                                <img class="img-responsive" src={aboutImgUrl && aboutImgUrl != 'null' ? aboutImgUrl : CompanyLogo} alt="Image About"/>
                            </div>
                        </div>
                        <div class="col-sm-6 col-xs-12">
                            <div class="aboutInfo">
                            <h2>{companyName}</h2>
                            {!isEditTemplate && (
                              <h3>{aboutFirstHeading}</h3>
                            )}
                            {isEditTemplate &&
                                <h3>
                                    {this.renderEditTextInputs(
                                        aboutFirstHeading,
                                        this.onChangeaboutFirstHeading,
                                        "aboutHeadingInput",
                                        {textAlign: 'left'}
                                    )}
                                </h3>
                            }
                            {!isEditTemplate && (
                                <p>{aboutSecondHeading}</p>
                            )}
                            {isEditTemplate &&
                                <p>
                                    {this.renderEditTextInputs(
                                        aboutSecondHeading,
                                        this.onChangeaboutSecondHeading,
                                        "aboutHeadingInput",
                                        {textAlign: 'left'}
                                    )}
                                </p>
                            }
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </section>

                <section ref={this.services} class="clearfix divider">
                    <div class="container">
                    <div class="secotionTitle">
                        <h2><span>Discover</span>our services</h2>
                    </div>
                    <div class="row service-row">
                        {getNestedValue(companyList, [0, 'services'], []).filter(service => service.isActive == 1).map(service => {
                            return this.renderServiceComponent(service.image, service.name, service.price)
                        })}
                    </div>
                    </div>
                </section>

                <section ref={this.gallery} class="clearfix divider">
                    <div class="clearfix homeGalleryTitle">
                        <div class="container">
                            <div class="secotionTitle">
                                <h2><span>Explore </span>Our gallery</h2>
                            </div>
                            <div>
                                <h3><span>Pictures </span></h3>
                            </div>
                            <SimpleReactLightbox>{!isloading && this.renderPortfolio()}</SimpleReactLightbox>
                            <div>
                                <h3><span>Videos </span></h3>
                            </div>
                            {!isloading && this.renderPortfolioVideo()}
                        </div>
                    </div>
                </section>

                <section ref={this.appointment} class="clearfix divider">
                    <div class="clearfix homeGalleryTitle">
                        <div class="container">
                            <div class="secotionTitle">
                                <h2><span>Book Today</span></h2>
                            </div>
                            <div class="expertBox">
                                <button onClick={this.goToAppointmentForm} class="btn btn-primary first-btn px-1">Make Appointment</button>
                            </div>
                        </div>
                    </div>
                </section>


                <section ref={this.team} class="clearfix expertSection divider">
                <div class="container">

                    <div class="secotionTitle">
                        <h2><span>Meet </span>our experts</h2>
                    </div>

                    <div class="row">
                        {getNestedValue(companyList, [0, 'employees'], [])
                            .filter(employee => employee.isActive)
                            .map(employee => {
                                let image = getNestedValue(employee,  ['employeeId', 'userId', 'profile_img'], '')
                                let name = getNestedValue(employee,  ['employeeId', 'userId', 'firstName'], '')
                                return this.renderEmployeeComponent(image, name)
                            })
                        }
                    </div>
                </div>
                </section>

                <section ref={this.contact} class="clearfix contactSection divider">
                    <div class="container">

                        <div class="secotionTitle">
                            <h2><span>Get in touch</span></h2>
                        </div>
                        <div class="row">
                        <div class="col-12 expertBox">
                            <div class="contactForm">
                            <div id="alert"></div>
                            <form action="mail/contact-form.php" id="angelContactForm" method="post">
                            <div class="form-group">
                                <input type="text" name="contact-form-name" class="form-control" placeholder="Your Name" required/>
                                </div>
                                <div class="form-group">
                                <input type="email" name="contact-form-email" class="form-control" placeholder="Your Email" required/>
                                </div>
                            <div class="form-group">
                                <input type="text" name="contact-form-mobile" class="form-control" placeholder="Your Mobile" required/>
                                </div>
                                <div class="form-group">
                                <textarea class="form-control" name="contact-form-message" placeholder="Your Message" required></textarea>
                                </div>
                                <div class="form-group">
                                <button type="submit" id="contact-submit-btn" class="btn btn-primary first-btn">send Message</button>

                                </div>
                            </form>
                            </div>
                        </div>
                        </div>
                    </div>
                </section>

                <footer 
                    class="divider"
                    // style="background-image: url(img/home/footer-bg.jpg);"
                >

                    <a href="#pageTop" class="backToTop"><i class="fa fa-angle-up" aria-hidden="true"></i></a>

                    <div className="">
                        <div className="row">
                        <div className="col-sm-6" style={{  }}>
                            <div className="container">
                                <p
                                    style={{
                                    color: "#FFFFFF",
                                    fontWeight: "bold",
                                    fontSize: "18px",
                                    }}
                                >
                                    Contact us
                                </p>
                                <div>
                                    <p style={{ color: "#818D86" }}>
                                    Address: {saloonsVal?.saloons[0]?.address}
                                    </p>
                                </div>
                                <div>
                                    <p style={{ color: "#818D86" }}>
                                        E-mail: {saloonsVal?.saloons[0]?.businessEmail}
                                    </p>
                                    <p style={{ color: "#818D86" }}>
                                        Phone Number: {saloonsVal?.saloons[0]?.businessTelephone}
                                    </p>
                                </div>
                                </div>
                            </div>
                            <div className="col-sm-6" style={{  }}>
                                <div className="container">
                                <p
                                    style={{
                                    color: "#FFFFFF",
                                    fontWeight: "bold",
                                    fontSize: "18px",
                                    }}
                                >
                                    Opening Hours
                                </p>
                                {saloonsVal &&
                                    saloonsVal.schedule &&
                                    saloonsVal.schedule.map(
                                    (val) =>
                                        val &&
                                        val.weekPlans &&
                                        val.weekPlans.map((weekData) => {
                                        return (
                                            <div key={weekData.dayOfWeek}>
                                            {weekData.dayOfWeek === 1 ? (
                                                <p style={{ color: "#818D86" }}>
                                                Monday
                                                {weekData.availableStatus === 1 ? (
                                                    <span>
                                                    <span style={{ paddingLeft: 20 }}>
                                                        {weekData.checkIn}
                                                    </span>
                                                    -<span>{weekData.checkOut}</span>
                                                    </span>
                                                ) : (
                                                    <span
                                                        style={{ paddingLeft: 10, color: "red" }}
                                                    >
                                                        Off
                                                    </span>
                                                    )}
                                                </p>
                                            ) : null}
                                            {weekData.dayOfWeek === 2 ? (
                                                <p style={{ color: "#818D86" }}>
                                                Tuesday
                                                {weekData.availableStatus === 1 ? (
                                                    <span>
                                                    <span style={{ paddingLeft: 20 }}>
                                                        {weekData.checkIn}
                                                    </span>
                                                    -<span>{weekData.checkOut}</span>
                                                    </span>
                                                ) : (
                                                    <span
                                                        style={{ paddingLeft: 10, color: "red" }}
                                                    >
                                                        Off
                                                    </span>
                                                    )}
                                                </p>
                                            ) : null}
                                            {weekData.dayOfWeek === 3 ? (
                                                <p style={{ color: "#818D86" }}>
                                                Wednesday
                                                {weekData.availableStatus === 1 ? (
                                                    <span>
                                                    <span style={{ paddingLeft: 20 }}>
                                                        {weekData.checkIn}
                                                    </span>
                                                    -<span>{weekData.checkOut}</span>
                                                    </span>
                                                ) : (
                                                    <span
                                                        style={{ paddingLeft: 10, color: "red" }}
                                                    >
                                                        Off
                                                    </span>
                                                    )}
                                                </p>
                                            ) : null}
                                            {weekData.dayOfWeek === 4 ? (
                                                <p style={{ color: "#818D86" }}>
                                                Thursday
                                                {weekData.availableStatus === 1 ? (
                                                    <span>
                                                    <span style={{ paddingLeft: 20 }}>
                                                        {weekData.checkIn}
                                                    </span>
                                                    -<span>{weekData.checkOut}</span>
                                                    </span>
                                                ) : (
                                                    <span
                                                        style={{ paddingLeft: 10, color: "red" }}
                                                    >
                                                        Off
                                                    </span>
                                                    )}
                                                </p>
                                            ) : null}
                                            {weekData.dayOfWeek === 5 ? (
                                                <p style={{ color: "#818D86" }}>
                                                Friday
                                                {weekData.availableStatus === 1 ? (
                                                    <span>
                                                    <span style={{ paddingLeft: 20 }}>
                                                        {weekData.checkIn}
                                                    </span>
                                                    -<span>{weekData.checkOut}</span>
                                                    </span>
                                                ) : (
                                                    <span
                                                        style={{ paddingLeft: 10, color: "red" }}
                                                    >
                                                        Off
                                                    </span>
                                                    )}
                                                </p>
                                            ) : null}
                                            {weekData.dayOfWeek === 6 ? (
                                                <p style={{ color: "#818D86" }}>
                                                Saturday
                                                {weekData.availableStatus === 1 ? (
                                                    <span>
                                                    <span style={{ paddingLeft: 20 }}>
                                                        {weekData.checkIn}
                                                    </span>
                                                    -<span>{weekData.checkOut}</span>
                                                    </span>
                                                ) : (
                                                    <span
                                                        style={{ paddingLeft: 10, color: "red" }}
                                                    >
                                                        Off
                                                    </span>
                                                    )}
                                                </p>
                                            ) : null}
                                            {weekData.dayOfWeek === 7 ? (
                                                <p style={{ color: "#818D86" }}>
                                                Sunday
                                                {weekData.availableStatus === 1 ? (
                                                    <span>
                                                    <span style={{ paddingLeft: 20 }}>
                                                        {weekData.checkIn}
                                                    </span>
                                                    -<span>{weekData.checkOut}</span>
                                                    </span>
                                                ) : (
                                                    <span
                                                        style={{ paddingLeft: 10, color: "red" }}
                                                    >
                                                        Off
                                                    </span>
                                                    )}
                                                </p>
                                            ) : null}
                                            </div>
                                        );
                                        })
                                    )}
                                </div>
                            </div>
                        </div>

                        <div class="clearfix copyRight">
                            <div class="container">
                                <div class="row">
                                <div class="col-md-5 order-md-1">
                                    <ul class="list-inline socialLink">
                                    <li><a href="javascript:void(0)"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                                    <li><a href="javascript:void(0)"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                    <li><a href="javascript:void(0)"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                                    <li><a href="javascript:void(0)"><i class="fa fa-skype" aria-hidden="true"></i></a></li>
                                    <li><a href="javascript:void(0)"><i class="fa fa-pinterest-p" aria-hidden="true"></i></a></li>
                                    </ul>
                                </div>

                                <div class="col-md-7">
                                    <div class="copyRightText">
                                        <p>&copy; 2020 copyright EASY1. All Rights Reserved</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
                </div>

                </body>
            </div>
        </div>
    );
  }
}

export default Template5;
