import { connect } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import i18n from "../../../i18n";
import BaseModal from "../../BaseModal";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import PrimaryButton from "../../Core/PrimaryButton";
import ColorPicker from "material-ui-color-picker";
import "./ClassUpsert.scss";
import ClassGrades from "./ClassGrades";
import DollarInput from "../../DollarInput";
const colorRegex = /^#([0-9a-f]{3}|[0-9a-f]{6})$/i;

function ClassUpsert({ open, onClose, onSave, active = {}, accessToken }) {
  const form = useRef();
  const [data, setData] = useState({});

  const init = (data) => {
    setData({ ...data });
  };
  useEffect(() => {
    init(active);
  }, []);
  useEffect(() => {
    if (active._id != data._id) {
      init(active);
    }
  }, [active]);
  const handleChange = (key, value) => {
    data[key] = value;
    setData({ ...data });
  };
  const handleSubmit = () => {
    onSave(data);
  };

  return (
    <div className="class-upsert">
      {open ? (
        <BaseModal
          open={open}
          title={data._id ? i18n.t("edit_class") : i18n.t("add_class")}
          contentClassName="overflow-visible"
          content={
            <>
              <div className="d-flex">
                <ValidatorForm
                  onSubmit={handleSubmit}
                  ref={form}
                  className="w-100 row mx-0"
                  onError={(error) => {
                    if (!error.length) {
                      onSave(data);
                    }
                  }}
                >
                  <TextValidator
                    containerProps={{ className: "col-12" }}
                    className="mb-1"
                    label={i18n.t("name")}
                    onChange={(e) => handleChange("name", e.target.value)}
                    value={data.name || ""}
                    validators={["required"]}
                    errorMessages={[i18n.t("name_is_required")]}
                  />
                  <TextValidator
                    containerProps={{ className: "col-6" }}
                    className="mb-1"
                    label={i18n.t("duration_in_hours")}
                    onChange={(e) => handleChange("duration", e.target.value)}
                    type="number"
                    value={data.duration || ""}
                  />
                  <TextValidator
                    containerProps={{ className: "col-6" }}
                    className="mb-1"
                    label={i18n.t("passing_grade_in_percent")}
                    onChange={(e) =>
                      handleChange("passingGrade", e.target.value)
                    }
                    onBlur={(text) => {
                      let num = parseFloat(text.target.value);
                      if (isNaN(num) || num < 0) {
                        num = 0;
                      }
                      if (num > 100) {
                        num = 100;
                      }
                      num = Math.round((num + Number.EPSILON) * 100) / 100;
                      handleChange("passingGrade", num);
                    }}
                    type="number"
                    value={data.passingGrade || ""}
                    validators={["required"]}
                    errorMessages={[i18n.t("required")]}
                  />
                  <TextValidator
                    containerProps={{ className: "col-12" }}
                    className="mb-1"
                    label={i18n.t("description")}
                    onChange={(e) =>
                      handleChange("description", e.target.value)
                    }
                    value={data.description || ""}
                    multiline
                    rows={3}
                  />
                  <div className="col-6 mb-1">
                    <DollarInput
                      label={i18n.t("price")}
                      onChange={(e) => handleChange("price", e)}
                      value={data.price || ""}
                    />
                  </div>
                  <div className="col-6">
                    <ColorPicker
                      label={i18n.t("color")}
                      className="mb-1"
                      defaultValue={data.color || "#000000"}
                      onChange={(e) => {
                        if (e) {
                          handleChange("color", e);
                        }
                      }}
                      onBlur={(e) => {
                        let color = e.target.value;
                        if (!color.match(colorRegex)) {
                          handleChange("color", "#000000");
                        }
                      }}
                      InputProps={{ value: data.color || "#000000" }}
                      fullWidth
                    />
                  </div>
                  <div className="row mx-0 px-0 justify-content-end mt-2 col-12 mb-1">
                    <PrimaryButton
                      props={{
                        type: "submit",
                      }}
                      label={data._id ? i18n.t("save") : i18n.t("create")}
                    />
                  </div>
                </ValidatorForm>
              </div>
              <h4>{i18n.t("grades")}</h4>
              {data._id ? (
                <ClassGrades
                  _class={data}
                  accessToken={accessToken}
                  updateClass={setData}
                />
              ) : null}
            </>
          }
          onClose={onClose}
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  expenses: state.expense.data,
  loading: state.vendor.isFetching,
});

const action = {};

export default connect(mapStateToProps, action)(ClassUpsert);
