import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import i18n from "../../../i18n";
import { getNestedValue } from "../../../util/objectMethods";
import moment from "moment";
import PrimaryButton from "../../Core/PrimaryButton";
import BaseModal from "../../BaseModal";
import DotsLoader from "../../DotsLoader";
import VendorUpsert from "./VendorUpsert.component";
import Swal from "sweetalert2";
import { ErrorHelper, SuccessHelper } from "../../../helpers";
import { removeRequest } from "../../../redux/actions/Saloon/vendor";
import BasicTable from "../../Core/BasicTable";
function PackagesTable({
  accessToken,
  rows,
  onEdit,
  allServices,
  removeRequest,
}) {
  const [companyId, setCompanyId] = useState("");
  const [open, setOpen] = useState(false);
  const [element, setElement] = useState();
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState();
  const onClose = () => {
    setElement();
    setOpen(false);
  };
  const handleDelete = (_id) => {
    Swal.fire({
      title: i18n.t("are_you_sure_delete"),
      text: i18n.t("cannmot_reverse"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ff3600",
      cancelButtonColor: "#354e68",
      confirmButtonText: i18n.t("yes"),
    }).then((result) => {
      if (result.value) {
        setLoading(true);
        removeRequest(
          { access_token: accessToken, ids: _id },
          {
            success: () => {
              setLoading(false);
              SuccessHelper.handleSuccess(
                "Your service is successfully deleted.",
                true
              );
            },
            failure: () => {
              setLoading(false);
              ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
            },
          }
        );
      }
    });
  };
  return (
    <>
      <DotsLoader isloading={loading} />
      <BasicTable
        rows={rows}
        schema={[
          {
            header: i18n.t("name"),
            field: "vendorName",
          },
          {
            header: i18n.t("contact"),
            field: "primaryContact",
          },
          {
            header: i18n.t("container.profile_email"),
            field: "email",
          },
          {
            header: i18n.t("phoneNo"),
            field: "phoneNo",
          },
          {
            header: i18n.t("mobile_phone_no"),
            field: "mobilePhoneNo",
          },
          {
            key: "action",
            header: i18n.t("actions"),
            render: (val) => {
              return (
                <>
                  <FontAwesomeIcon
                    className="view-icon"
                    color="white"
                    icon={["fas", "eye"]}
                    onClick={() => onEdit(val)}
                  />
                  <FontAwesomeIcon
                    className="view-icon"
                    style={{ backgroundColor: "red" }}
                    color="white"
                    icon={["fas", "trash"]}
                    onClick={() => handleDelete(val._id)}
                  />
                </>
              );
            },
          },
        ]}
      />
      {open && (
        <VendorUpsert
          open={open}
          onClose={onClose}
          element={element}
          allServices={allServices}
          onSave={() => {}}
          id={id}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({});

const action = {
  removeRequest,
};

export default connect(mapStateToProps, action)(PackagesTable);
