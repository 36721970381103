import React from "react";
import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import { connect, useSelector } from "react-redux";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },
}));

export default function AppSidebar({
  children,
  open,
  onSetOpen,
  docked,
  content,
}) {
  const classes = useStyles();

  const { announcements } = useSelector((state) => ({
    announcements: state.announcements.data,
  }));
  return (
    <>
      <Drawer
        variant={docked ? "permanent" : "temporary"}
        open={open}
        onClose={onSetOpen}
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar
          style={{
            marginBottom: announcements && announcements.length ? 20 : 0,
          }}
        />
        {children}
      </Drawer>
      <div
        className={
          open && docked ? `${classes.contentShift} is-docked` : classes.content
        }
      >
        {content}
      </div>
    </>
  );
}
