import { connect } from "react-redux";
import React, { Component } from "react";
import { Translation } from "react-i18next";
import i18n from "../../i18n";
import firebase from "firebase";
import { Images } from "../../theme/index";
import { request as register_user } from "../../redux/actions/Register";
import { DotsLoader, Navbar, Footer } from "../../components";
import { ErrorHelper, SuccessHelper } from "../../helpers";
import { googleLogin, locationSuggestions } from "../../config/simpleApiCalls";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.css";
import NumberFormat from "react-number-format";
import { set } from "../../redux/actions/User";

const Input = ({ onChange, placeholder, value, isSecure, id, onClick }) => {
  // const { formErrors } = this.state
  return (
    <div class="form-label-group ">
      <input
        class="form-control px-4 px-md-4 px-lg-4"
        placeholder={i18n.t("enter_dob_placeholder")}
        value={value}
        onChange={onChange}
        type="date"
      />
      {/* {formErrors.birthDateError ? <small class="form-text text-danger ml-3">{formErrors.birthDateError}</small> : null} */}
    </div>
  );
};

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      DateOfBirth: "",
      // date: "",
      email: "",
      password: "",
      confirmPassword: "",
      addressMap: {},

      isloading: false,
      formErrors: {
        firstNameError: "",
        lastNameError: "",
        provider: "email",
        birthDateError: "",
        postalCodeError: "",
        emailError: "",
        passwordError: "",
        confirmPasswordError: "",
        telephoneError: "",
        telephone: "",
        gmailuid: "",
      },
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.register) {
      console.log(nextProps.register, "NextProps User");
      if (
        !nextProps.register.failure &&
        !nextProps.register.isFetching &&
        !nextProps.register.errorMessage &&
        nextProps.register.data &&
        nextProps.register.data.data &&
        nextProps.register.data.data.access_token
      ) {
        SuccessHelper.handleSuccess(
          "Thank you! Your account has been successfully created.",
          true
        );
        this.props.history.push("/");
        this.props.set({
          user: JSON.stringify(nextProps.register.data.data),
        });
        this.setState({
          isloading: false,
          month: 0,
          day: 0,
          year: 0,
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          confirmPassword: "",
        });
      } else if (
        nextProps.register.failure &&
        !nextProps.register.isFetching &&
        nextProps.register.errorMessage
      ) {
        this.setState({ isloading: false });
      }
    }
  }

  componentDidMount() {
    const user = JSON.parse(sessionStorage.getItem("user"));
    if (user && user.access_token) {
      this.handleNavigation("/");
    }
    const urlParams = new URLSearchParams(window.location.search);
    const toSet = {};
    if (urlParams.get("email")) {
      toSet.email = urlParams.get("email");
    }
    if (urlParams.get("telephone")) {
      toSet.telephone = urlParams.get("telephone");
    }
    if (urlParams.get("firstName")) {
      toSet.firstName = urlParams.get("firstName");
    }
    if (urlParams.get("lastName")) {
      toSet.lastName = urlParams.get("lastName");
    }
    this.setState({
      ...toSet,
    });
  }

  handleChange(date) {
    this.setState({
      DateOfBirth: date,
    });
  }
  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  onChangeFirstName = (text) => this.setState({ firstName: text.target.value });
  onChangeLastName = (text) => this.setState({ lastName: text.target.value });
  onChangeEmail = (text) => this.setState({ email: text.target.value });
  onChangePassword = (text) => this.setState({ password: text.target.value });
  onChangeConfirmPassword = (text) =>
    this.setState({ confirmPassword: text.target.value });
  onDateChange = (date) => this.setState({ date: date.target.value });

  checkValidation() {
    const {
      firstName,
      lastName,
      // DateOfBirth,
      email,
      password,
      confirmPassword,
      telephoneError,
      telephone,
      provider,
    } = this.state;
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const nameRegex =
      /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{2,}$/u;
    const telephoneRegex =
      /[0-9][0-9][0-9] [0-9][0-9][0-9] [0-9][0-9][0-9][0-9]/g;

    if (!firstName) {
      this.setState({
        formErrors: {
          firstNameError: (
            <Translation>{(t) => t("first_name_required")}</Translation>
          ),
        },
      });
      setTimeout(() => {
        this.setState({
          formErrors: {
            firstNameError: "",
          },
        });
      }, 8000);
    } else if (!firstName.match(nameRegex)) {
      this.setState({
        formErrors: {
          firstNameError: <Translation>{(t) => t("invalid_name")}</Translation>,
        },
      });
      setTimeout(() => {
        this.setState({
          formErrors: {
            firstNameError: "",
          },
        });
      }, 6000);
    } else if (!email) {
      this.setState({
        formErrors: {
          emailError: <Translation>{(t) => t("email_required")}</Translation>,
        },
      });
      setTimeout(() => {
        this.setState({
          formErrors: {
            emailError: "",
          },
        });
      }, 6000);
    } else if (!email.match(emailRegex)) {
      this.setState({
        formErrors: {
          emailError: <Translation>{(t) => t("invalid_email")}</Translation>,
        },
      });
      setTimeout(() => {
        this.setState({
          formErrors: {
            emailError: "",
          },
        });
      }, 6000);
    } else if (!telephone) {
      this.setState({
        formErrors: {
          telephoneError: (
            <Translation>{(t) => t("telephone_required")}</Translation>
          ),
        },
      });

      setTimeout(() => {
        this.setState({
          telephoneError: "",
        });
      }, 6000);
    } else if (!telephone.match(telephoneRegex)) {
      this.setState({
        formErrors: {
          telephoneError: (
            <Translation>{(t) => t("invalid_phone_number")}</Translation>
          ),
        },
      });

      setTimeout(() => {
        this.setState({
          telephoneError: "",
        });
      }, 6000);
    } else if (provider != "google" && !password) {
      this.setState({
        formErrors: {
          passwordError: (
            <Translation>{(t) => t("password_required")}</Translation>
          ),
        },
      });
      setTimeout(() => {
        this.setState({
          formErrors: {
            passwordError: "",
          },
        });
      }, 6000);
    } else if (provider != "google" && password.length < 6) {
      this.setState({
        formErrors: {
          passwordError: (
            <Translation>{(t) => t("password_min_length")}</Translation>
          ),
        },
      });
      setTimeout(() => {
        this.setState({
          formErrors: {
            passwordError: "",
          },
        });
      }, 6000);
    } else if (provider != "google" && !confirmPassword) {
      this.setState({
        formErrors: {
          confirmPasswordError: (
            <Translation>{(t) => t("confirm_password_required")}</Translation>
          ),
        },
      });
      setTimeout(() => {
        this.setState({
          formErrors: {
            confirmPasswordError: "",
          },
        });
      }, 6000);
    } else if (provider != "google" && confirmPassword !== password) {
      this.setState({
        formErrors: {
          confirmPasswordError: (
            <Translation>{(t) => t("confirm_password_not_match")}</Translation>
          ),
        },
      });
      setTimeout(() => {
        this.setState({
          formErrors: {
            confirmPasswordError: "",
          },
        });
      }, 6000);
    }
    // else if (city.length < 1) {
    //     this.setState({
    //         formErrors: {
    //             addressError: "City is required!"
    //         }
    //     })
    //     setTimeout(() => {
    //         this.setState({
    //             formErrors: {
    //                 addressError: ""
    //             }
    //         })
    //     }, 6000)
    // }
    else {
      this.handleRegister();
    }
  }

  handleDateDifference = () => {
    const { month, day, year } = this.state;
    console.log(month, day, year);
    if (month && day && year) {
      let today = new Date();
      let currentDate = today.getDate();
      let currentMonth = today.getMonth();
      let currentYear = today.getFullYear();
      let curr = moment([currentYear, currentMonth, currentDate]);
      let userPre = moment([year, month - 1, day]);
      if (curr.diff(userPre) < 0) {
        this.setState({
          formErrors: {
            birthDateError: (
              <Translation>{(t) => t("date_invalid_must_be_less")}</Translation>
            ),
          },
        });
        setTimeout(() => {
          this.setState({
            formErrors: {
              birthDateError: "",
            },
          });
        }, 6000);
      } else {
        this.handleRegister();
      }
    }
  };

  handleRegister = () => {
    this.setState({ isloading: true });
    const {
      firstName,
      lastName,
      email,
      password,
      telephone,
      provider,
      gmailuid,
      addressMap,
    } = this.state;
    var payload = {
      firstName,
      lastName,
      userName: `${firstName} ${lastName}`,
      email,
      phoneNo: telephone,
      platform: navigator.platform,
      role: "5",
    };
    if (Object.keys(addressMap).length) {
      payload = { ...payload, ...addressMap };
    }
    if (provider === "google") {
      googleLogin({ ...payload, provider, idToken: gmailuid })
        .then((res) => {
          this.setState({
            isloading: false,
            provider: "",
            firstName: "",
            lastName: "",
            email: "",
            telephone: "",
          });
          const updatedUser = JSON.stringify({ ...res.data.data });
          this.props.set({
            user: updatedUser,
          });
          SuccessHelper.handleSuccess("Successfully Registered", true);
          this.handleNavigation("/");
        })
        .catch((err) => {
          ErrorHelper.handleErrors(err.message, true);
        });
    } else {
      payload.password = password;
      this.props.register_user(payload);
    }

    // payload = provider == "google" ? payload : { ...payload, password: password }
    // provider == "google" ? : this.props.register_user(payload)
    // console.log(payload, "payloadpayloadpayloadpayloadpayload")

    // this.props.register_user(payload)
  };

  GoogleLogin = () => {};

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };
  signInWithGoogle = () => {
    const auth = firebase.auth();
    const googleProvider = new firebase.auth.GoogleAuthProvider();
    auth
      .signInWithPopup(googleProvider)
      .then((res) => {
        console.log("rezzzzzzzz", res);
        SuccessHelper.handleSuccess(
          "Kindly Add missing fields to confirm Registration",
          true
        );
        const data = res.user;
        this.setState({
          firstName: data.displayName,
          email: data.email,
          gmailuid: data.uid,
        });
        if (data.phoneNumber) {
          this.setState({ telephone: data.phoneNumber });
        }
        if (data.providerData[0].providerId == "google.com") {
          this.setState({ provider: "google" });
        }
      })
      .catch((error) => {
        ErrorHelper.handleErrors(error.message, true);
      });
  };
  signInFacebook() {
    var provider = new firebase.auth.FacebookAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(function (result) {
        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        var token = result.credential.accessToken;
        // The signed-in user info.
        var user = result.user;
        console.log(user);
        // ...
      })
      .catch(function (error) {
        // Handle Errors here.
        console.log(error);
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ...
      });
  }

  renderSocialBtn = () => {
    return (
      <>
        <div
          className="row px-3"
          style={{
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          <span className=" mb-2 google-btn">
            <img className="ml-0 google-btn-img" src={Images.google_signin} />
            <span className="google-btn-text" onClick={this.signInWithGoogle}>
              <Translation>{(t) => t("login_with_google")}</Translation>
            </span>
          </span>
        </div>
        {/* <div
                    className="row px-3"
                    style={{ justifyContent: "center", alignItems: "center" }}
                >
                    <span className=" mb-2 facebook-btn">
                        <img className="facebook-btn-img" src={Images.facebook_signin} />
                        <span className="facebook-btn-text"  onClick={this.signInFacebook}  >Facebook</span>
                    </span>
                </div> */}
        {/* <div
          className="row px-3"
          style={{ justifyContent: "center", alignItems: "center" }}
        >
          <span className="  py-1 py-md-2 py-lg-2  email-btn">
            <img className="email-btn-img" src={Images.mail_signin} />
            <span className="email-btn-text">
              <Translation>{(t) => t("login_with_email")}</Translation>
            </span>
          </span>
        </div> */}
      </>
    );
  };

  renderFirstName = () => {
    const { formErrors } = this.state;
    return (
      <div className="form-label-group ">
        <input
          type="text"
          className="form-control px-4 px-md-4 px-lg-4"
          placeholder={i18n.t("enter_first_name_placeholder")}
          onChange={(text) => {
            this.setState({ firstName: text.target.value });
          }}
          value={this.state.firstName}
        />
        {formErrors.firstNameError ? (
          <small className="form-text text-danger ml-3">
            {formErrors.firstNameError}
          </small>
        ) : null}
      </div>
    );
  };

  renderLastName = () => {
    const { formErrors } = this.state;
    return (
      <div className="form-label-group ">
        <input
          type="text"
          className="form-control px-4 px-md-4 px-lg-4"
          placeholder={i18n.t("enter_last_name_placeholder")}
          onChange={(text) => {
            this.setState({ lastName: text.target.value });
          }}
          value={this.state.lastName}
        />
        {formErrors.lastNameError ? (
          <small className="form-text text-danger ml-3">
            {formErrors.lastNameError}
          </small>
        ) : null}
      </div>
    );
  };

  CustomExampleInput = (value, onChange) => {
    const { formErrors } = this.state;
    return (
      <div className="form-label-group ">
        <input
          className="form-control px-4 px-md-4 px-lg-4 "
          placeholder={i18n.t("enter_dob_placeholder")}
          value={value}
          onChange={onChange}
        />

        {formErrors.birthDateError ? (
          <small className="form-text text-danger ml-3">
            {formErrors.birthDateError}
          </small>
        ) : null}
      </div>
    );
  };

  renderEmail = () => {
    const { formErrors } = this.state;
    return (
      <div className="form-label-group ">
        <input
          type="email"
          className="form-control px-4 px-md-4 px-lg-4"
          placeholder={i18n.t("container.profile_email")}
          onChange={(text) => {
            this.setState({ email: text.target.value });
          }}
          disabled={this.state.provider == "google" ? true : false}
          value={this.state.email}
        />
        {formErrors.emailError ? (
          <small className="form-text text-danger ml-3">
            {formErrors.emailError}
          </small>
        ) : null}
      </div>
    );
  };

  renderPhone = () => {
    const { formErrors } = this.state;
    return (
      <div className="form-label-group ">
        <NumberFormat
          type="telephone"
          placeholder="Telephone"
          className="form-control px-4 px-md-4 px-lg-4"
          onChange={(text) => {
            this.setState({ telephone: text.target.value });
          }}
          format="### ### ####"
        />

        {formErrors.telephoneError ? (
          <small className="form-text text-danger ml-3">
            {formErrors.telephoneError}
          </small>
        ) : null}
      </div>
    );
  };

  renderPassword = () => {
    const { formErrors } = this.state;
    return (
      <div className="form-label-group">
        <input
          type="password"
          className="form-control px-4 px-md-4 px-lg-4"
          placeholder={i18n.t("password")}
          onChange={(text) => {
            this.setState({ password: text.target.value });
          }}
          value={this.state.password}
        />
        {formErrors.passwordError ? (
          <small className="form-text text-danger ml-3">
            {formErrors.passwordError}
          </small>
        ) : null}
      </div>
    );
  };

  renderConfirmPassword = () => {
    const { formErrors } = this.state;
    return (
      <div className="form-label-group ">
        <input
          type="password"
          className="form-control px-4 px-md-4 px-lg-4"
          placeholder={i18n.t("container.confirm_password")}
          onChange={(text) => {
            this.setState({ confirmPassword: text.target.value });
          }}
          value={this.state.confirmPassword}
        />
        {formErrors.confirmPasswordError ? (
          <small className="form-text text-danger ml-3">
            {formErrors.confirmPasswordError}
          </small>
        ) : null}
      </div>
    );
  };

  renderRegisterbtn = () => {
    return (
      <button
        className="btn btn-lg register_btn"
        type="submit"
        onClick={() => this.checkValidation()}
      >
        <Translation>{(t) => t("container.register_now_account")}</Translation>
      </button>
    );
  };

  renderRegistetrForm = () => {
    return (
      <>
        <h3
          style={{ width: "100%", textAlign: "center", marginBottom: "30px" }}
        >
          <Translation>{(t) => t("login_with_email")}</Translation>
        </h3>
        <form action="javascript:void(0)" className="form-signin px-4">
          {this.renderFirstName()}
          {this.renderLastName()}
          {this.renderEmail()}
          {this.renderPhone()}
          {this.state.provider != "google" ? this.renderPassword() : null}
          {this.state.provider != "google"
            ? this.renderConfirmPassword()
            : null}

          {this.renderRegisterbtn()}
        </form>
      </>
    );
  };

  renderRegisterCard = () => {
    return (
      <div className="card card-signin my-5 ">
        <div className="card-body px-0">
          <h5 className="card-title text-center mb-4">
            <Translation>
              {(t) => t("container.register_create_account")}
            </Translation>
          </h5>
          {this.renderSocialBtn()}
          <div className="row">
            <hr className="my-4 mx-3 " style={{ width: "100%" }} />
          </div>
          {this.renderRegistetrForm()}
        </div>
      </div>
    );
  };

  render() {
    console.log(this.state);
    return (
      <>
        <div className="container-fluid">
          <div className="container">
            {this.renderLoader()}
            <Navbar {...this.props} isBackgroundColor={true} />
          </div>
          <div className="container" style={{ backgroundColor: "#F8FDFF" }}>
            <div className="row mt-5">
              <div className="col-sm-9 col-md-7 col-lg-5 mx-auto  mt-5">
                {this.renderRegisterCard()}
              </div>
            </div>
          </div>
        </div>
        <Footer {...this.props} />
      </>
    );
  }
}

const mapStateToProps = (state) => ({ register: state.register });

const action = { register_user, set };

export default connect(mapStateToProps, action)(Register);
