import { Button, Checkbox } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import i18n from "../../i18n";
import CollectPaymentButton from "./components/CollectPaymentButton";
import "./style.scss";
import { IconButton } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import PaymentList from "./components/PaymentList";
import { getNestedValue } from "../../util/objectMethods";
import PrimaryButton from "../Core/PrimaryButton";
const roundTo2Decimals = (x) => Math.round((x + Number.EPSILON) * 100) / 100;
const TransactionDetails = (props) => {
  let {
    bookingId,
    products,
    services,
    service,
    serviceAmount,
    depositRequired,
    depositAmount,
    transactions = [],
    companyTaxes = [],
    toggleDepositRequired,
    toggleTaxes,
    includeTaxes,
    tip,
    showInsertPayment,
    paymentCompleteCallback = () => {},
    readOnly,
    setData = () => {},
    manualPayment,
    onBalanceDueChange = () => {},
    manualPaymentMapChange,
    onChangeTip,
    total,
    onCompleteStatusUpdate,
    discount,
    accountBalance,
    accountBalanceToCollect,
  } = props;
  const parsedTip = useMemo(() => (tip ? parseFloat(tip) : 0), [tip]);

  depositAmount = depositAmount ? depositAmount : 0;
  const cumulativeTaxRate =
    companyTaxes.reduce((accum, tax) => accum + tax.percentage, 0) / 100;
  const [toPayMap, setToPayMap] = useState({
    deposit: 0,
    tip: 0,
    service: { price: 0, fullPrice: serviceAmount },
    products: {},
    extras: {},
  });
  const resetToPayMap = () => {
    setToPayMap({
      deposit: 0,
      service: { price: 0, fullPrice: serviceAmount },
      products: {},
      extras: {},
    });
  };
  const handlePaymentCheck = (type, data, state) => {
    const { _id, price, quantity = 1 } = data;
    if (toPayMap[type][_id] && !state) {
      delete toPayMap[type][_id];
    } else {
      toPayMap[type][_id] = { price, quantity };
    }
    setToPayMap({ ...toPayMap });
  };

  const handleServiceRemainderToggle = (amount, state) => {
    if (toPayMap.service.price && !state) {
      toPayMap.service.price = 0;
    } else {
      toPayMap.service.price = amount;
    }
    setToPayMap({ ...toPayMap });
  };

  const toggleTip = (state) => {
    if (toPayMap.tip && !state) {
      toPayMap.tip = 0;
    } else {
      toPayMap.tip = parsedTip;
    }
    setToPayMap({ ...toPayMap });
  };

  let [collectedMap, setCollectedMap] = useState({
    service: 0,
  });
  const [collectedTotal, setCollectedTotal] = useState(0);
  let [collectedTax, setCollectedTax] = useState(0);
  let [taxToCollect, setTaxToCollect] = useState(0);
  useEffect(() => {
    collectedMap = {
      deposit: 0,
      service: 0,
      tip: 0,
      discount: 0,
    };
    collectedTax = 0;
    setCollectedTotal(
      roundTo2Decimals(
        transactions.reduce((accum, transaction) => {
          const { amount, metadata = {}, wasReversed, type } = transaction;
          if (!wasReversed && type !== "Reverse") {
            Object.keys(metadata).map((key) => {
              let mappedKey;
              switch (key) {
                case "ServiceRemainder": {
                  mappedKey = "service";
                  collectedMap[mappedKey] += metadata[key];
                  break;
                }
                case "tip":
                case "deposit": {
                  mappedKey = key.toLowerCase();
                  collectedMap[mappedKey] += metadata[key];
                  break;
                }
                case "discount": {
                  collectedMap[key] += metadata[key];
                  break;
                }
                default: {
                  mappedKey = key.toLowerCase();
                  if (!collectedMap[mappedKey]) {
                    collectedMap[mappedKey] = {};
                  }
                  if (!collectedMap[mappedKey][metadata[key]]) {
                    collectedMap[mappedKey][metadata[key]] = 0;
                  }
                  let taxTotal = 0;
                  if (metadata.taxes) {
                    taxTotal = metadata.taxes.reduce(
                      (accum, tax) => accum + tax.amount,
                      0
                    );
                  }
                  collectedMap[mappedKey][metadata[key]] += roundTo2Decimals(
                    amount - taxTotal
                  );

                  break;
                }
              }
            });
            const { taxes = [] } = metadata;
            taxes &&
              taxes.map((tax) => {
                collectedTax += tax.amount;
              });
          }
          return accum + (typeof amount == "number" ? amount : 0);
        }, 0)
      )
    );
    setCollectedTax(collectedTax);
    setCollectedMap(collectedMap);
  }, [transactions]);
  const computedServiceAmount =
    serviceAmount - (depositRequired && !!depositAmount ? depositAmount : 0);
  useEffect(() => {
    if (toPayMap.service.price) {
      toPayMap.service.price = computedServiceAmount;
    }
    toPayMap.deposit =
      depositRequired && !collectedMap.deposit ? depositAmount : 0;
    setToPayMap({ ...toPayMap });
  }, [depositRequired, collectedMap]);

  let subtotal = 0;
  let taxDue = 0;
  if (depositRequired) {
    subtotal += depositAmount;
  } else if (collectedMap.deposit) {
    subtotal += depositAmount;
  }
  const serviceRemaining = computedServiceAmount;
  const remainderTax = roundTo2Decimals(serviceAmount * cumulativeTaxRate);
  if (toPayMap.service.price) {
    taxDue += remainderTax;
    subtotal += serviceRemaining;
  } else if (collectedMap.service) {
    taxDue += remainderTax;
    subtotal += serviceRemaining;
  }
  products.map((product) => {
    let computedPrice = product.price * product.quantity;
    const remaining = computedPrice;
    const tax = roundTo2Decimals(computedPrice * cumulativeTaxRate);
    if (toPayMap.products[product._id]) {
      if (product.productId.taxable) {
        taxDue += tax;
      }
      subtotal += remaining;
    } else if (collectedMap.products?.[product._id]) {
      if (product.productId.taxable) {
        taxDue += tax;
      }
      subtotal += remaining;
    }
  });
  services.map((service) => {
    const tax = roundTo2Decimals(service.price * cumulativeTaxRate);
    if (toPayMap.extras[service._id]) {
      taxDue += tax;
      subtotal += service.price;
    } else if (collectedMap?.extras?.[service._id]) {
      taxDue += tax;
      subtotal += service.price;
    }
  });
  subtotal = roundTo2Decimals(subtotal);
  collectedTax = roundTo2Decimals(collectedTax);
  taxDue = roundTo2Decimals(taxDue);
  let grandTotal =
    subtotal + (includeTaxes ? taxDue : 0) - (collectedMap.discount || 0);
  if (toPayMap.tip || collectedMap.tip) {
    grandTotal += parsedTip;
  }

  useEffect(() => {
    if (!collectedMap.tip) {
      toPayMap.tip = parsedTip;
      setToPayMap({ ...toPayMap });
    }
  }, [parsedTip, collectedMap]);

  const [paymentListOpen, setPaymentListOpen] = useState(false);
  const balanceDue = roundTo2Decimals(
    grandTotal + (accountBalanceToCollect || 0) - collectedTotal
  );
  onBalanceDueChange(balanceDue);
  const selectAll = () => {
    if (computedServiceAmount && collectedMap.service < computedServiceAmount) {
      handleServiceRemainderToggle(computedServiceAmount, true);
    }
    products.map((product) => {
      if (
        getNestedValue(collectedMap.products, product._id, 0) <
        product.quantity * product.price
      ) {
        handlePaymentCheck("products", product, true);
      }
    });
    services.map((service) => {
      if (getNestedValue(collectedMap.extras, service._id, 0) < service.price) {
        handlePaymentCheck("extras", service, true);
      }
    });
    if (parsedTip && collectedMap.tip < parsedTip) {
      toggleTip(true);
    }
  };
  useEffect(() => {
    if (depositRequired && !collectedMap.deposit) {
      toPayMap.deposit = depositAmount;
      setToPayMap({ ...toPayMap });
    }

    resetToPayMap();
    selectAll();
  }, [collectedMap]);
  useEffect(() => {
    if (showInsertPayment) {
      selectAll();
    }
  }, [showInsertPayment]);
  const [openCollectModal, setOpenCollectModal] = useState(false);
  useEffect(() => {
    if (showInsertPayment) {
      if (balanceDue) {
        setOpenCollectModal(true);
      } else {
        paymentCompleteCallback();
      }
    } else {
      setOpenCollectModal(false);
    }
  });
  useEffect(() => {
    Object.keys(toPayMap.products).map((productId) => {
      if (!products.find(({ _id }) => _id == productId)) {
        delete toPayMap.products[productId];
      }
    });
    products.map((product) => {
      if (!collectedMap?.products?.[product._id]) {
        handlePaymentCheck("products", product, true);
      }
    });
    setToPayMap({ ...toPayMap });
  }, [products, collectedMap]);
  useEffect(() => {
    Object.keys(toPayMap.extras).map((extraId) => {
      if (!services.find(({ _id }) => _id == extraId)) {
        delete toPayMap.extras[extraId];
      }
    });
    services.map((service) => {
      if (!collectedMap?.extras?.[service._id]) {
        handlePaymentCheck("extras", service, true);
      }
    });
    setToPayMap({ ...toPayMap });
  }, [services, collectedMap]);
  useEffect(() => {
    if (manualPaymentMapChange) {
      taxToCollect = 0;
      let totalBalanceDue = 0;
      manualPaymentMapChange(toPayMap);
      setTaxToCollect(taxToCollect);
      onBalanceDueChange(totalBalanceDue);
    }
  }, [toPayMap]);
  return (
    <div className="transaction-details">
      <PaymentList
        open={paymentListOpen}
        handleClose={() => setPaymentListOpen(false)}
        transactions={transactions}
        readOnly={readOnly}
        setData={setData}
        products={products}
        services={services}
      />
      <div className="row mx-0 flex-column justify-content-between transaction-details-body">
        <div>
          <div className="row mx-0 justify-content-center transaction-details-header">
            <strong>{i18n.t("transaction_details")}</strong>
          </div>
          <table className="w-100 px-2 transaction-details-table">
            <tbody>
              <tr>
                <td className="name" colSpan={2}>
                  {service.name}
                </td>
                <td />
                <td className="money-value">
                  {serviceAmount
                    ? `$${(
                        serviceAmount -
                        getNestedValue(collectedMap, ["deposit"], 0) -
                        getNestedValue(collectedMap, ["service"], 0)
                      ).toFixed(2)}`
                    : 0}
                </td>
              </tr>
              {depositAmount ? (
                <tr>
                  <td className="sub-check" colSpan={2}>
                    <Checkbox
                      className="p-0 pr-1"
                      onChange={() => toggleDepositRequired(!depositRequired)}
                      checked={Boolean(depositRequired)}
                      disabled={collectedMap.deposit}
                    />
                    {i18n.t("deposit")}
                  </td>
                  <td />
                  <td className="money-value">${depositAmount.toFixed(2)}</td>
                </tr>
              ) : null}
              {computedServiceAmount ? (
                <tr>
                  <td className="sub-check" colSpan={2}>
                    {manualPayment && (
                      <Checkbox
                        className="p-0 pr-1"
                        onChange={() => {
                          handleServiceRemainderToggle(computedServiceAmount);
                        }}
                        checked={Boolean(
                          toPayMap.service.price || collectedMap.service
                        )}
                        disabled={collectedMap.service}
                      />
                    )}
                    {i18n.t("remainder")}
                  </td>
                  <td />
                  <td className="money-value">
                    {`$${computedServiceAmount.toFixed(2)}`}
                  </td>
                </tr>
              ) : null}
              {products.map((product) => {
                const { _id, price } = product;
                if (
                  !manualPayment ||
                  product.price * product.quantity -
                    getNestedValue(collectedMap, ["products", _id], 0)
                ) {
                  return (
                    <tr key={product._id}>
                      <td className="name" colSpan={2}>
                        {manualPayment && (
                          <Checkbox
                            className="p-0 pr-1"
                            onChange={() =>
                              handlePaymentCheck("products", product)
                            }
                            checked={Boolean(
                              toPayMap.products[_id] ||
                                collectedMap.products?.[_id]
                            )}
                            disabled={collectedMap.products?.[_id]}
                          />
                        )}
                        {product.name}
                      </td>
                      <td>{product.quantity}</td>
                      <td className="money-value">
                        {product.price
                          ? `$${(product.price * product.quantity).toFixed(2)}`
                          : 0}
                      </td>
                    </tr>
                  );
                } else {
                  return null;
                }
              })}
              {services.map((service) => {
                const { _id, price } = service;
                if (
                  !manualPayment ||
                  service.price -
                    getNestedValue(collectedMap, ["extras", _id], 0)
                ) {
                  return (
                    <tr key={service._id}>
                      <td className="name" colSpan={2}>
                        {manualPayment && (
                          <Checkbox
                            className="p-0 pr-1"
                            onChange={() =>
                              handlePaymentCheck("extras", service)
                            }
                            checked={Boolean(
                              toPayMap.extras[_id] ||
                                collectedMap.extras?.[_id] >= price
                            )}
                            disabled={collectedMap.extras?.[_id] >= price}
                          />
                        )}
                        {service.serviceId
                          ? service.serviceId.name
                          : service.name}
                      </td>
                      <td>1</td>
                      <td className="money-value">
                        {service.price ? `$${service.price.toFixed(2)}` : 0}
                      </td>
                    </tr>
                  );
                } else {
                  return null;
                }
              })}
            </tbody>
          </table>
        </div>
        <div>
          <table className="w-100 px-2 transaction-details-table">
            {subtotal ? (
              <tr className="summary-row">
                <td colSpan={2}>{i18n.t("subtotal")}</td>
                <td />
                <td className="money-value">${subtotal.toFixed(2)}</td>
              </tr>
            ) : null}
            {taxDue ? (
              <tr>
                <td colSpan={2}>
                  <Checkbox
                    className="p-0 pr-1"
                    onChange={() => toggleTaxes(!includeTaxes)}
                    checked={Boolean(includeTaxes)}
                    disabled={collectedTotal > 0}
                  />
                  {i18n.t("tax_total")}
                </td>
                <td />
                <td className="money-value">
                  ${(taxDue - collectedTax).toFixed(2)}
                </td>
              </tr>
            ) : null}
            {collectedTax ? (
              <tr>
                <td colSpan={2}>{i18n.t("collected_tax")}</td>
                <td />
                <td className="money-value">${collectedTax.toFixed(2)}</td>
              </tr>
            ) : null}
            {parsedTip ? (
              <tr>
                <td colSpan={2}>
                  {manualPayment && (
                    <Checkbox
                      className="p-0 pr-1"
                      onChange={() => toggleTip()}
                      checked={Boolean(toPayMap.tip || collectedMap.tip)}
                      disabled={!!collectedMap.tip}
                    />
                  )}
                  {i18n.t("tip")}
                </td>
                <td />
                <td className="money-value">${parsedTip.toFixed(2)}</td>
              </tr>
            ) : null}
            {discount ? (
              <tr className="summary-row">
                <td colSpan={2}>{i18n.t("discount")}</td>
                <td />
                <td className="money-value">${discount.toFixed(2)}</td>
              </tr>
            ) : null}
            {grandTotal ? (
              <tr className="summary-row">
                <td colSpan={2}>{i18n.t("grand_total")}</td>
                <td />
                <td className="money-value">${grandTotal.toFixed(2)}</td>
              </tr>
            ) : null}
          </table>
        </div>
        <div>
          <table className="w-100 px-2 transaction-details-table">
            {collectedTotal ? (
              <tr className="collected-row">
                <td colSpan={2}>
                  {i18n.t("collected")}
                  {!manualPayment && (
                    <IconButton aria-label="details">
                      <VisibilityIcon
                        onClick={() => setPaymentListOpen(true)}
                      />
                    </IconButton>
                  )}
                </td>
                <td />
                <td className="money-value">
                  $ {(collectedTotal * -1).toFixed(2)}
                </td>
              </tr>
            ) : null}
            {accountBalanceToCollect ? (
              <tr className="summary-row">
                <td colSpan={2}>{i18n.t("account_balance_to_collect")}</td>
                <td />
                <td className="money-value">
                  ${accountBalanceToCollect.toFixed(2)}
                </td>
              </tr>
            ) : null}
          </table>
        </div>

        <div>
          <table className="w-100 px-2 transaction-details-table">
            <tr className="summary-row">
              <td colSpan={2}>{i18n.t("balance_due")}</td>
              <td />
              <td className="money-value">${balanceDue.toFixed(2)}</td>
            </tr>
          </table>
          {!readOnly && !manualPayment && (
            <div className="row mx-0 justify-content-center my-1 mt-3">
              {balanceDue ? (
                <CollectPaymentButton
                  bookingId={bookingId}
                  defaultAmount={balanceDue}
                  amount={balanceDue}
                  toPayMap={toPayMap}
                  includeTaxes={includeTaxes}
                  successCallback={(data) => {
                    setData(data);
                    paymentCompleteCallback();
                    resetToPayMap();
                  }}
                  openCollectModal={openCollectModal}
                  transactionDetailsProps={{
                    ...props,
                    defaultToPayMap: toPayMap,
                  }}
                  tip={parsedTip}
                  onChangeTip={onChangeTip}
                  total={total}
                  accountBalance={accountBalance}
                />
              ) : (
                <>
                  {onCompleteStatusUpdate ? (
                    <PrimaryButton
                      label={i18n.t("complete_booking")}
                      style={{ backgroundColor: "green", color: "white" }}
                      onClick={onCompleteStatusUpdate}
                    />
                  ) : null}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  transactions: state.bookingTransaction.data,
});

const action = {};

export default connect(mapStateToProps, action)(TransactionDetails);
