import { connect } from "react-redux";
import React, { Component } from "react";
import i18n from "../../../i18n";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import { Images } from "../../../theme";
import { DotsLoader } from "../../../components";
import { companyPostUpload } from "../../../config/simpleApiCalls";
import { SuccessHelper, ErrorHelper } from "../../../helpers";
import {
  getCompanyPost,
  deleteCompanyPost,
} from "../../../config/simpleApiCalls";
import "./styles.css";
import Sytles from "./sytles";

class UploadMedia extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mediaType: null,
      imageUrl: null,
      imageFile: null,
      imageName: null,
      imageTitle: null,
      imageDescription: null,
      access_token: null,
      isloading: false,
      companyPortfolioImages: [],
      videoFile: null,
    };
  }

  async componentDidMount() {
    const saloonData = await JSON.parse(sessionStorage.getItem("saloon"));
    const companyData = await JSON.parse(sessionStorage.getItem("company"));
    if (saloonData && companyData) {
      this.setState({ access_token: saloonData.access_token }, () => {
        this.getAllData();
      });
    }
  }

  getAllData = () => {
    this.setState({ isloading: true });
    const { access_token } = this.state;
    getCompanyPost(access_token)
      .then((res) => {
        if (res.data.success) {
          this.setState({
            companyPortfolioImages: res.data.data,
            isloading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
      });
  };

  onChangeTitle = (text) => this.setState({ imageTitle: text.target.value });
  onChangeDescription = (text) =>
    this.setState({ imageDescription: text.target.value });

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  fileSelectedHandler = (event) => {
    const imageUrlRegex = /([a-zA-Z0-9\s_\\.\-:])+(.png|.jpeg|.jpg|.gif)$/gm;
    const videoUrlRegex = /([a-zA-Z0-9\s_\\.\-:])+(.mp4)$/gm;
    if (event.target.files[0]) {
      let checkFile = event.target.files[0].type.split("/");
      let isValidateType = event.target.files[0].type;
      if (checkFile[0] === "image") {
        if (isValidateType.match(imageUrlRegex)) {
          const file = URL.createObjectURL(event.target.files[0]);
          this.setState({
            mediaType: event.target.files[0].type.split("/")[0],
            imageUrl: file,
            imageFile: event.target.files[0],
            imageName: event.target.files[0].name,
            imageTitle: event.target.files[0].name,
            imageDescription: event.target.files[0].name,
          });
        } else {
          ErrorHelper.handleErrors("Image Format not supported", true);
        }
      } else if (checkFile[0] === "video") {
        if (isValidateType.match(videoUrlRegex)) {
          const file = URL.createObjectURL(event.target.files[0]);
          this.setState({
            videoFile: event.target.files,
            mediaType: event.target.files[0].type.split("/")[0],
            imageUrl: file,
            imageFile: event.target.files[0],
            imageName: event.target.files[0].name,
            imageTitle: event.target.files[0].name,
            imageDescription: event.target.files[0].name,
          });
        } else {
          ErrorHelper.handleErrors(
            "Video Format not supported,Please upload an mp4 file",
            true
          );
        }
      }
    }
  };

  handleDelete = (e) => {
    this.setState({
      imageUrl: null,
      imageFile: null,
      imageName: null,
      imageTitle: null,
      imageDescription: null,
    });
  };

  handleUploadMedia = () => {
    this.setState({ isloading: true });
    const {
      mediaType,
      imageFile,
      imageTitle,
      imageDescription,
      access_token,
      videoFile,
    } = this.state;
    console.log("imageFile", this.state);
    let payloadFormdata = new FormData();
    payloadFormdata.append(
      `${mediaType === "image" ? "image" : "video"}`,
      imageFile
    );
    // payloadFormdata.append('video', videoFile);
    payloadFormdata.append("title", imageTitle);
    payloadFormdata.append("description", imageDescription);

    companyPostUpload(payloadFormdata, access_token)
      .then((res) => {
        if (res.data.success) {
          SuccessHelper.handleSuccess(
            "Your image/video has been successfully added",
            true
          );
          this.setState({
            mediaType: null,
            imageUrl: null,
            imageFile: null,
            imageName: null,
            imageTitle: null,
            imageDescription: null,
            isloading: false,
          });
          this.getAllData();
        }
      })
      .catch((error) => {
        console.log(error, "errorrrrrrrrrrrrrrrrrrr");
        this.setState({ isloading: false });
      });
  };

  // saveTitleDescription = (id) => {
  //   const { imageFile, imageTitle, imageDescription } = this.state;
  //   let objIndex = imageFile.findIndex((obj => obj.imageId === id));
  //   imageFile[objIndex].imageTitle = selectedImageTitle
  //   imageFile[objIndex].imageDescription = selectedImageDescription
  //   this.setState({ imageFile });
  // }

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  renderSaloonDashboardHeading = () => {
    return (
      <div className="col-12 p-3">
        <h1 className="saloon-dashboard-heading">
          {i18n.t("container.dashboard_upload_videos")}:
        </h1>
      </div>
    );
  };
  handleDeleteImage = (val) => {
    const { access_token, companyPortfolioImages } = this.state;
    this.setState({ isloading: true });
    deleteCompanyPost(val, access_token)
      .then((res) => {
        if (res.data.success) {
          let arr = companyPortfolioImages.filter(
            (value) => value._id !== val._id
          );
          this.setState({ companyPortfolioImages: arr, isloading: false });
          SuccessHelper.handleSuccess("Deleted Succesfully", true);
        } else {
          ErrorHelper.handleErrors("Failed to delete image", true);
        }
      })
      .catch((error) => {
        ErrorHelper.handleErrors("Failed to delete image", true);
      });
  };

  renderSaloonMediaCard = () => {
    const { companyPortfolioImages, isloading, imageFile } = this.state;
    return (
      // <div>hiii</div>
      <SRLWrapper>
        <div class="row" style={{ marginTop: "10px" }}>
          {companyPortfolioImages &&
            imageFile === null &&
            companyPortfolioImages.map((val) => {
              return (
                <>
                  {!!val.images.length && (
                    <div
                      key={val._id}
                      class="imageParent"
                      style={{ marginTop: "8px" }}
                    >
                      {!!val.images.length && (
                        <>
                          <img className="img-fluid1" src={val.images[0].url} />
                          <div
                            class="middle"
                            onClick={() => {
                              this.handleDeleteImage(val);
                            }}
                          >
                            <div class="text">Delete</div>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                  {!!val.videos.length && val.videos[0] !== null && (
                    <div
                      key={val._id}
                      class="col-lg-3 col-md-4 imageParent"
                      style={{ marginTop: "8px" }}
                    >
                      {!!val.videos.length && (
                        <>
                          <video width="100%" height="145px" controls>
                            <source src={val.videos[0].url} />
                          </video>
                          <div
                            class="middle"
                            onClick={() => {
                              this.handleDeleteImage(val);
                            }}
                          >
                            <div class="text">Delete</div>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </>
              );
            })}
        </div>
      </SRLWrapper>
    );
  };

  renderUploadFileCard = () => {
    return (
      <div className="col-12">
        <div
          className="col-12 bg-white py-2 p-md-3"
          style={{ border: "1px solid #E0E2E4", borderRadius: "1rem" }}
        >
          {/* <p className="pr-md-4" id="saloon-card-close-btn">
            &times;
          </p> */}
          <div className="col-12 col-md-10 mx-auto">
            <p id="upload-file-text">
              {i18n.t("container.dashboard_upload_files")}
            </p>
            {/* <p id="problem-text">
              Having problem? Try the{" "}
              <span style={{ color: "#84B2F9", cursor: "pointer" }}>
                <u>basic uploader</u>
              </span>
            </p> */}
            <input
              type="file"
              style={{ display: "none" }}
              onChange={this.fileSelectedHandler}
              ref={(fileInput) => (this.fileInput = fileInput)}
              accept="image/*,video/*"
            />
            <div
              className="col-12 p-3 mt-4"
              id="upload-box"
              onClick={() => this.fileInput.click()}
            >
              <img
                src={Images.saloon_upload_media_icon}
                style={{ width: 120 }}
              />
              <p id="drop-file-text">
                {i18n.t("container.dashboard_drop_files")}{" "}
                <span style={{ color: "#84B2F9" }}>
                  <u>{i18n.t("container.dashboard_browse")}</u>
                </span>
              </p>
            </div>
          </div>
          {/* <div className="col-12 col-lg-11 mx-auto px-0 px-md-3">
            <p className="my-4" id="learn-more-text">
              By submitting your videos to YouTube, you acknowledge that you
              agree to Youtube's Term of Services and Community Guidelines.
              Please be sure not to voilate other copyright or privacy rights.
              <span style={{ color: "#84B2F9", cursor: "pointer", marginLeft: 5 }}> Learn more</span>
            </p>
          </div> */}
        </div>
        {this.renderSaloonMediaCard()}
      </div>
    );
  };

  renderFileSection = () => {
    const { mediaType } = this.state;
    return (
      <div className="col-md-9 p-3">
        <div className="row px-3">
          {mediaType === "image"
            ? this.renderImageFile()
            : this.renderVideoFile()}
          <input
            type="file"
            style={{ display: "none" }}
            onChange={this.fileSelectedHandler}
            ref={(fileInput) => (this.fileInput = fileInput)}
            accept="image/*,video/*"
          />
          {this.renderFileAddBtn()}
        </div>
      </div>
    );
  };

  renderImageFile = () => {
    const { imageUrl } = this.state;
    return (
      <div
        className=""
        style={{
          backgroundImage: `url(${imageUrl})`,
          width: 200,
          height: 200,
          margin: "1rem",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          borderRadius: "0.5rem",
          border: "2px solid #448EF7",
        }}
      />
    );
  };

  renderVideoFile = () => {
    const { imageUrl } = this.state;
    return (
      <div
        className=""
        style={{
          overflow: "hidden",
          width: 200,
          height: 200,
          margin: "1rem",
          borderRadius: "0.5rem",
          border: "2px solid #448EF7",
        }}
      >
        <video style={{ width: "200px", height: "200px" }} controls>
          <source src={imageUrl} />
        </video>
      </div>
    );
  };

  renderFileAddBtn = () => {
    return (
      <div
        onClick={() => this.fileInput.click()}
        style={{
          width: 200,
          height: 200,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "1rem",
          backgroundColor: "#EEEEEE ",
          borderRadius: "0.5rem",
        }}
      >
        <img src={Images.saloon_add} style={{ width: 50, height: 50 }} />
      </div>
    );
  };

  renderPreviewSection = () => {
    return (
      <div className="col-md-3 p-3" style={{ backgroundColor: "#EEEEEE" }}>
        {this.renderAttachmentDetails()}
        {this.renderForm()}
        <div className="mt-3">
          <button
            type="button"
            id="saloon-upload"
            onClick={() => this.handleUploadMedia()}
            className="btn btn-light btn-sm ml-1 saloon-category-service-btn saloon-upload"
          >
            Upload
          </button>
        </div>
      </div>
    );
  };

  renderAttachmentDetails = () => {
    const { imageName, mediaType } = this.state;
    return (
      <>
        <h6>Attachment Details</h6>
        <div className="col-12 pt-3">
          <div className="row">
            {mediaType === "image"
              ? this.renderPreviewImageFile()
              : this.renderPreviewVideoFile()}
            <div className="col-6">
              <p className="saloon-image-type-text">{imageName}</p>
              <div className="d-flex pt-1">
                <img
                  onClick={() => this.handleDelete()}
                  src={Images.saloon_working_hour_delete}
                  style={{ width: 25 }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  renderPreviewImageFile = () => {
    const { imageUrl } = this.state;
    return (
      <div
        className=""
        style={{
          backgroundImage: `url(${imageUrl})`,
          width: 130,
          height: 130,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          borderRadius: "0.5rem",
          border: "2px solid #448EF7",
        }}
      />
    );
  };

  renderPreviewVideoFile = () => {
    const { imageUrl } = this.state;
    return (
      <div
        className=""
        style={{
          overflow: "hidden",
          width: 130,
          height: 130,
          borderRadius: "0.5rem",
          border: "2px solid #448EF7",
        }}
      >
        <video style={{ width: "130px", height: "130px" }} controls>
          <source src={imageUrl} />
        </video>
      </div>
    );
  };

  renderForm = () => {
    const { imageTitle, imageDescription } = this.state;
    return (
      <div className="col-12 pl-0">
        <h6 className="mt-2" style={{ color: "#424242" }}>
          Title
        </h6>
        <input
          className="form-control saloon-link-social-input"
          onChange={this.onChangeTitle}
          value={imageTitle}
        />
        <h6 className="mt-4" style={{ color: "#424242" }}>
          Description
        </h6>
        <textarea
          className="form-control saloon-link-social-input"
          rows="4"
          onChange={this.onChangeDescription}
          value={imageDescription}
        />
        <div className="mt-3"></div>
      </div>
    );
  };

  renderAddedFilesCard = () => {
    return (
      <div
        className="col-12 px-0"
        style={{ borderRadius: "1rem", overflow: "hidden" }}
      >
        <div className="row">
          {this.renderFileSection()}
          {this.renderPreviewSection()}
        </div>
      </div>
    );
  };

  render() {
    const { imageFile } = this.state;
    return (
      <div>
        {this.renderLoader()}
        <div className="content-container">
          <div className="row mx-0">
            {this.renderSaloonDashboardHeading()}
            {imageFile
              ? this.renderAddedFilesCard()
              : this.renderUploadFileCard()}
            {/* {imageFile === null && this.renderSaloonMediaCard()} */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(UploadMedia);
