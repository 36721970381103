import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import EmployeeHeader from "./components/EmployeeHeader.component";
import Header from "./components/Header.component";
import ListFundsRequests from "./components/ListFundsRequests.component";
import BalanceCards from "./components/BalanceCards.component";
import {
  getEmployeeAccountDetailsRequest,
  getRequest,
} from "../../../redux/actions/Saloon/AllFundsRequest";
import "./styles.scss";
import DotsLoader from "../../DotsLoader";
import { getNestedValue } from "../../../util/objectMethods";
import { Translation } from "react-i18next";
import SendModal from "./components/SendModal.component";
import i18n from "../../../i18n";
function RequestFunds({
  accessToken,
  userId,
  accountMap = {},
  getEmployeeAccountDetailsRequest,
  getRequest,
  isloading,
}) {
  const [status, setStatus] = useState(undefined);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  let [totalPending, setTotalPending] = useState(0);
  let [totalAvailable, setTotalAvailable] = useState(0);
  let [totalPendingFromEmployee, setTotalPendingFromEmployee] = useState(0);
  let [totalAvailableFromEmployee, setTotalAvailableFromEmployee] = useState(0);
  useEffect(() => {
    if (accessToken) {
      getEmployeeAccountDetailsRequest({ access_token: accessToken });
      getRequest({ access_token: accessToken });
    }
  }, [accessToken]);
  delete accountMap[userId];
  const employees = Object.values(accountMap);
  useEffect(() => {
    totalPending = 0;
    totalAvailable = 0;
    totalPendingFromEmployee = 0;
    totalAvailableFromEmployee = 0;
    employees.map(
      ({
        accountTotals: {
          employeePending = 0,
          totalAvailable: available = 0,
          employeeToClientAvailable = 0,
          employeeToClientPending = 0,
        },
      }) => {
        totalPending += employeePending;
        totalAvailable += available;
        totalPendingFromEmployee += employeeToClientPending;
        totalAvailableFromEmployee += employeeToClientAvailable;
      }
    );
    setTotalPending(totalPending);
    setTotalAvailable(totalAvailable);
    setTotalPendingFromEmployee(totalPendingFromEmployee);
    setTotalAvailableFromEmployee(totalAvailableFromEmployee);
  }, [employees]);
  const [sendModalProps, setSendModalProps] = useState({});
  const onAddClick = (props) => {
    setSendModalProps({
      open: true,
      handleClose: () => setSendModalProps({ open: false }),
      ...props,
    });
  };
  return (
    <div className="all-request-funds">
      <SendModal accessToken={accessToken} {...sendModalProps} />
      <DotsLoader isloading={isloading} />
      <Header
        employees={employees}
        status={status}
        setStatus={setStatus}
        setEmployee={setSelectedEmployee}
        totalPending={totalPending}
        totalAvailable={totalAvailable}
        totalPendingFromEmployee={totalPendingFromEmployee}
        totalAvailableFromEmployee={totalAvailableFromEmployee}
      />
      {employees.map((employee) => {
        const { accountTotals = {}, requests = [], employeeId, _id } = employee;
        const filteredRequests = status
          ? requests.filter((request) => request.status == status)
          : requests;
        if (!selectedEmployee || selectedEmployee == employee._id) {
          const {
            totalAvailable = 0,
            combinedPending = 0,
            employeePending = 0,
            employeeToClientAvailable = 0,
            employeeToClientPending = 0,
          } = accountTotals;
          if (!status || filteredRequests.length) {
            return (
              <div className="employee-card" key={employee._id}>
                <EmployeeHeader
                  name={getNestedValue(employeeId, ["userId", "firstName"], "")}
                />
                {i18n.t("owed_to_employee")}
                <BalanceCards
                  pending={employeePending}
                  available={totalAvailable}
                  total={totalAvailable + employeePending}
                  buttonText={i18n.t("send")}
                  buttonClickCallback={() =>
                    onAddClick({
                      available: totalAvailable,
                      companyEmployeeId: _id,
                      type: "send",
                    })
                  }
                />
                {i18n.t("owed_by_employee")}
                <BalanceCards
                  pending={employeeToClientPending}
                  available={employeeToClientAvailable}
                  total={employeeToClientPending + employeeToClientAvailable}
                  buttonText={i18n.t("receive")}
                  buttonClickCallback={() =>
                    onAddClick({
                      available: employeeToClientAvailable,
                      companyEmployeeId: _id,
                      type: "receive",
                    })
                  }
                />
                <ListFundsRequests
                  requests={filteredRequests}
                  accessToken={accessToken}
                  accountDetails={accountTotals}
                />
              </div>
            );
          }
        } else {
          return null;
        }
      })}
    </div>
  );
}

const mapStateToProps = (state) => {
  const payouts = state.allFundsRequest.data;
  const accountDetails = state.allFundsRequest.accountDetails;
  const accountMap = {};
  accountDetails.map((accountDetail) => {
    accountMap[getNestedValue(accountDetail, ["employeeId", "userId", "_id"])] =
      { ...accountDetail, requests: [] };
  });
  payouts.map((payout) => {
    if (accountMap[payout.requester]) {
      accountMap[payout.requester].requests.push(payout);
    }
  });
  return {
    accountMap,
    isloading: state.allFundsRequest.isFetching,
  };
};

const action = { getEmployeeAccountDetailsRequest, getRequest };

export default connect(mapStateToProps, action)(RequestFunds);
