import { take, put, call, fork } from "redux-saga/effects";

import * as types from "../../actions/ActionTypes";
import { success, failure } from "../../actions/Employee/LogOut";

import axios from 'axios';
import { logout_Api } from "../../../config/WebServices";
import { ErrorHelper } from "../../../helpers";

function callRequest(data) {
  const gcm_id = sessionStorage.getItem("messaging_token")
  const { access_token } = data
  return  axios.post(`${logout_Api}`, { gcm_id }, {
    headers: {
      Authorization: access_token,
    },
  })
}

function* watchRequest() {
  while (true) {
    const { payload } = yield take(types.EMPLOYEE_LOGOUT.REQUEST);
    try {
      const response = yield call(callRequest, payload);
      yield put(success(response));
    } catch (err) {
      yield put(failure(err));
      ErrorHelper.handleErrors(err, true);
    }
  }
}

export default function* root() {
  yield fork(watchRequest);
}
