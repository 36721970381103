import { connect } from "react-redux";
import React, { Component } from "react";

import { DotsLoader } from "../../../components";
import {
  getUser,
  addAccount,
  startAccountOnBoarding,
  toggleOnlinePayments,
} from "../../../config/simpleApiCalls";
import { getNestedValue } from "../../../util/objectMethods";
import { SuccessHelper, ErrorHelper } from "../../../helpers";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import i18n from "../../../i18n";
import { Button } from "@material-ui/core";
import Swal from "sweetalert2";
import { withStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./styles.scss";
import { TextField } from "@material-ui/core";
import { Images } from "../../../theme/index";
import {
  updateInteracTransferSettings,
  getSaloonById,
} from "../../../config/simpleApiCalls";
import PrimaryButton from "../../../components/Core/PrimaryButton";

const GreenSwitch = withStyles({
  switchBase: {
    color: "#E6E6E6",
    "&$checked": {
      color: "green",
    },
    "&$checked + $track": {
      backgroundColor: "green",
    },
  },
  checked: {},
  track: {},
})(Switch);

const ccStyle = {
  width: 36,
  height: 36,
  cursor: "pointer",
  margin: "0px 6px",
};

class MyAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      isLoading: false,
      accountCreated: false,
      isOnboarded: false,
      recipientEmail: "",
      recipientPhoneNo: "",
      securityAnswer: "",
      securityQuestion: "",
      enableOnlinePayments: false,
      enableInteracTransfer: false,
      refundPolicy: undefined,
    };
  }

  async componentDidMount() {
    const saloonData = await JSON.parse(sessionStorage.getItem("saloon"));
    const token = saloonData.access_token;
    this.setState({ isLoading: true, token });
    Promise.all([
      getSaloonById(token)
        .then(({ data }) => {
          const {
            recipientEmail,
            recipientPhoneNo,
            securityAnswer,
            securityQuestion,
            refundPolicy,
          } = data.data;
          this.setState({
            recipientEmail,
            recipientPhoneNo,
            securityAnswer,
            securityQuestion,
            refundPolicy,
          });
        })
        .catch(() => {
          this.setState({ isLoading: false });
        }),
      getUser(token).then(({ data }) => {
        if (data.success) {
          const { user } = data.data;
          this.setState({
            accountCreated: !!user.stripeAccountId,
            isOnboarded: user.userOnboarded,
            enableOnlinePayments: user.enableOnlinePayments,
            enableInteracTransfer: user.enableInteracTransfer,
            enableStripe: user.enableStripe,
            isLoading: false,
          });
        }
      }),
    ]).then(() => {
      this.setState({ isLoading: false });
    });
  }

  renderLoader = () => {
    const { isLoading } = this.state;
    return <DotsLoader isloading={isLoading} />;
  };

  startAccountOnBoarding = () => {
    const { token } = this.state;
    startAccountOnBoarding(token).then(({ data }) => {
      if (data.success) {
        this.setState({ isLoading: false });
        window.open(data.accountLinks, "_blank");
      } else {
        if (data.code !== "already_onboarded") {
          ErrorHelper.handleErrors(i18n.t("someting_went_wrong"), true);
        }
        this.setState({ isLoading: false });
      }
    });
  };

  createAccount = () => {
    const { token } = this.state;
    addAccount({}, token).then(({ data }) => {
      if (data.success) {
        this.startAccountOnBoarding();
      } else {
        ErrorHelper.handleErrors(i18n.t("someting_went_wrong"), true);
        this.setState({ isLoading: false });
      }
    });
  };

  connect = () => {
    const { accountCreated } = this.state;
    this.setState({ isLoading: true });
    if (!accountCreated) {
      this.createAccount();
    } else {
      this.startAccountOnBoarding();
    }
  };

  handleToggle = (body = {}) => {
    const { token, enableOnlinePayments, enableInteracTransfer, enableStripe } =
      this.state;
    const sendRequest = () => {
      this.setState({ isLoading: true });
      toggleOnlinePayments(
        { enableOnlinePayments, enableInteracTransfer, enableStripe, ...body },
        token
      )
        .then(({ data }) => {
          this.setState({ isLoading: false });
          if (data.success) {
            const {
              enableOnlinePayments,
              enableInteracTransfer,
              enableStripe,
            } = data.data;
            this.setState(
              {
                enableOnlinePayments,
                enableInteracTransfer,
                enableStripe,
                forceRerender: true,
              },
              () =>
                this.setState({
                  forceRerender: false,
                })
            );
            SuccessHelper.handleSuccess(i18n.t("successfully_updated"), true);
          } else {
            ErrorHelper.handleErrors(i18n.t("failed_to_update"), true);
          }
        })
        .catch(() => {
          ErrorHelper.handleErrors(i18n.t("failed_to_update"), true);
          this.setState({ isLoading: false });
        });
    };
    if (body.enableOnlinePayments) {
      Swal.fire({
        title: i18n.t("payment_charge_message"),
        showCancelButton: true,
        cancelButtonText: i18n.t("cancel"),
        confirmButtonText: "Okay",
      }).then((result) => {
        if (result.value) {
          sendRequest();
        } else {
          this.setState(
            {
              isLoading: false,
              forceRerender: true,
            },
            () =>
              this.setState({
                forceRerender: false,
              })
          );
        }
      });
    } else {
      sendRequest();
    }
  };

  handleSettingsSave = async () => {
    const {
      recipientEmail,
      recipientPhoneNo,
      securityAnswer,
      securityQuestion,
      token,
    } = this.state;
    this.setState({ isloading: true });
    try {
      const errorMap = {};
      if (!recipientEmail && !recipientPhoneNo) {
        errorMap.recipientEmail = i18n.t("email_or_phone_is_required");
        errorMap.recipientPhoneNo = i18n.t("email_or_phone_is_required");
      }
      if (!securityQuestion) {
        errorMap.securityQuestion = i18n.t("security_answer_is_required");
      }
      if (!securityAnswer) {
        errorMap.securityAnswer = i18n.t("security_question_is_required");
      }

      if (Object.keys(errorMap).length) {
        this.setState({ errorMap, isloading: false });
      } else {
        updateInteracTransferSettings(
          {
            recipientEmail,
            recipientPhoneNo,
            securityAnswer,
            securityQuestion,
          },
          token
        )
          .then(({ data }) => {
            if (data.success) {
              SuccessHelper.handleSuccess(i18n.t("successfully_updated"), true);
            } else {
              ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
            }
          })
          .finally(() => {
            this.setState({ isloading: false });
          });
      }
    } catch (e) {
      ErrorHelper.handleErrors(e, true);
      this.setState({ isloading: false });
    }
  };

  render() {
    const {
      isOnboarded,
      enableOnlinePayments,
      enableInteracTransfer,
      enableStripe,
      isLoading,
      recipientEmail,
      recipientPhoneNo,
      securityAnswer,
      securityQuestion,
      forceRerender,
      errorMap = {},
      refundPolicy,
    } = this.state;
    const hasSetPolicy = Boolean(refundPolicy);
    return (
      <div className="online-payments">
        {this.renderLoader()}
        <div className="p-3">
          <div>
            <h1 className="saloon-dashboard-heading pb-3">
              {i18n.t("container.online_payments")}
            </h1>
          </div>
          {!hasSetPolicy && (
            <div className="row mx-0 px-1 align-items-center pb-3">
              <h6 className="pr-2 text-danger">
                {i18n.t("must_set_refund_policy")}
              </h6>
              <PrimaryButton
                label={i18n.t("here")}
                onClick={() => {
                  this.props.history.push("/saloon-refund-policy");
                }}
              />
            </div>
          )}
          {!forceRerender && (
            <FormControlLabel
              control={
                <GreenSwitch
                  disabled={!hasSetPolicy}
                  checked={enableOnlinePayments}
                  onChange={() =>
                    this.handleToggle({
                      enableOnlinePayments: !enableOnlinePayments,
                    })
                  }
                />
              }
              label={i18n.t("enable_online_payments")}
            />
          )}
          <fieldset>
            <legend>
              {i18n.t("powered_by")}&nbsp;
              <strong className="header-stripe">Stripe</strong>
              &nbsp;-
              {!forceRerender && (
                <GreenSwitch
                  checked={Boolean(enableStripe && enableOnlinePayments)}
                  disabled={!hasSetPolicy || !enableOnlinePayments}
                  onChange={() =>
                    this.handleToggle({ enableStripe: !enableStripe })
                  }
                />
              )}
            </legend>
            <div className="row mx-0 mb-1">
              <FontAwesomeIcon
                style={{ ...ccStyle, marginLeft: 0 }}
                icon={["fab", "cc-visa"]}
              />
              <FontAwesomeIcon
                style={ccStyle}
                icon={["fab", "cc-mastercard"]}
              />
              <FontAwesomeIcon style={ccStyle} icon={["fab", "cc-amex"]} />
              <FontAwesomeIcon style={ccStyle} icon={["fab", "cc-discover"]} />
            </div>
            {isOnboarded ? (
              <div>{i18n.t("already_connected")}</div>
            ) : (
              <div>
                <Button className="primary-button" onClick={this.connect}>
                  {i18n.t("connect_now")}
                </Button>
              </div>
            )}
          </fieldset>
          <fieldset>
            <legend>
              {i18n.t("interac_transfer")}
              <img src={Images.interac_etransfer_logo} style={{ width: 100 }} />
              -
              {!forceRerender && (
                <GreenSwitch
                  checked={enableInteracTransfer && enableOnlinePayments}
                  disabled={!hasSetPolicy || !enableOnlinePayments}
                  onChange={() =>
                    this.handleToggle({
                      enableInteracTransfer: !enableInteracTransfer,
                    })
                  }
                />
              )}
            </legend>
            <div className="row">
              <div className="col-md-3 col-sm-6">
                <TextField
                  label={i18n.t("email_recipent")}
                  value={recipientEmail}
                  onChange={(e) =>
                    this.setState({ recipientEmail: e.target.value })
                  }
                  error={!!errorMap.recipientEmail}
                  helperText={errorMap.recipientEmail}
                />
              </div>
              <div className="col-md-3 col-sm-6">
                <TextField
                  label={i18n.t("phone_recipent")}
                  value={recipientPhoneNo}
                  onChange={(e) =>
                    this.setState({ recipientPhoneNo: e.target.value })
                  }
                  error={!!errorMap.recipientPhoneNo}
                  helperText={errorMap.recipientPhoneNo}
                />
              </div>
              <div className="col-md-3 col-sm-6">
                <TextField
                  label={i18n.t("security_question")}
                  value={securityQuestion}
                  onChange={(e) =>
                    this.setState({ securityQuestion: e.target.value })
                  }
                  error={!!errorMap.securityQuestion}
                  helperText={errorMap.securityQuestion}
                />
              </div>
              <div className="col-md-3 col-sm-6">
                <TextField
                  label={i18n.t("security_answer")}
                  value={securityAnswer}
                  onChange={(e) =>
                    this.setState({ securityAnswer: e.target.value })
                  }
                  error={!!errorMap.securityAnswer}
                  helperText={errorMap.securityAnswer}
                />
              </div>
            </div>
            <div className="row mx-0 justify-content-end mt-2">
              <Button
                className="primary-button"
                onClick={this.handleSettingsSave}
              >
                {i18n.t("save")}
              </Button>
            </div>
          </fieldset>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(MyAccount);
