import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import i18n from "../../i18n";
import { getNestedValue } from "../../util/objectMethods";
import BasicTable from "../Core/BasicTable/index";
import moment from "moment";
import PrimaryButton from "../Core/PrimaryButton";
import BaseModal from "../BaseModal";
import DotsLoader from "../DotsLoader";
import PackaageItemUpsert from "./PackageItems/PackageItemUpsert.component";
import Swal from "sweetalert2";
import { ErrorHelper, SuccessHelper } from "../../helpers";
import { removeRequest } from "../../redux/actions/Packages";
function PackagesTable({
  accessToken,
  rows,
  onEdit,
  onDelete,
  onAddItem,
  onEditItem,
  allServices,
  removeRequest,
}) {
  const [companyId, setCompanyId] = useState("");
  const [open, setOpen] = useState(false);
  const [element, setElement] = useState();
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState();
  const onClose = () => {
    setElement();
    setOpen(false);
  };
  const handleDelete = (_id) => {
    Swal.fire({
      title: i18n.t("are_you_sure_delete"),
      text: i18n.t("cannmot_reverse"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ff3600",
      cancelButtonColor: "#354e68",
      confirmButtonText: i18n.t("yes"),
    }).then((result) => {
      if (result.value) {
        setLoading(true);
        removeRequest(
          { access_token: accessToken, _id },
          {
            success: () => {
              setLoading(false);
              SuccessHelper.handleSuccess(
                "Your service is successfully deleted.",
                true
              );
            },
            failure: () => {
              setLoading(false);
              ErrorHelper.handleErrors(i18n.t("something_went_wrong"), true);
            },
          }
        );
      }
    });
  };
  return (
    <>
      <DotsLoader isloading={loading} />
      <BasicTable
        rows={rows}
        schema={[
          {
            header: i18n.t("name"),
            field: "name",
          },
          {
            header: i18n.t("number_of_sessions"),
            field: "number_of_sessions",
            accessor: (val) => val.numberOfSessions,
          },
          {
            header: i18n.t("created_date"),
            field: "createdDate",
            accessor: (val) =>
              moment(val.createdDate).format("MM-DD-YYYY hh:mm A"),
          },
          {
            header: i18n.t("status"),
            field: "status",
          },
          {
            key: "action",
            header: i18n.t("actions"),
            render: (val) => {
              return (
                <>
                  <FontAwesomeIcon
                    className="view-icon"
                    color="white"
                    icon={["fas", "eye"]}
                    onClick={() => onEdit(val)}
                  />
                  <FontAwesomeIcon
                    className="view-icon"
                    style={{ backgroundColor: "red" }}
                    color="white"
                    icon={["fas", "trash"]}
                    onClick={() => handleDelete(val._id)}
                  />
                </>
              );
            },
          },
        ]}
        childSchema={{
          title: (
            <div className="row mx-0 justify-content-between">
              {i18n.t("items")}
              <PrimaryButton
                onClick={() => {
                  setOpen(true);
                  setElement({});
                }}
                label={i18n.t("add")}
              />
            </div>
          ),
          columns: [
            {
              key: "item",
              accessor: (val) => {
                const product = getNestedValue(val, ["productId", "name"], "");
                if (product) {
                  return product;
                } else {
                  return getNestedValue(val, ["serviceId", "name"], "");
                }
              },
            },
            { key: "quantity" },
            {
              key: "status",
              accessor: (val) => i18n.t(val.status),
            },
          ],
        }}
      />
      {open && (
        <PackaageItemUpsert
          open={open}
          onClose={onClose}
          element={element}
          allServices={allServices}
          onSave={() => {}}
          id={id}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({});

const action = {
  removeRequest,
};

export default connect(mapStateToProps, action)(PackagesTable);
