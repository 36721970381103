// @flow

import { COMPANY_CARD } from "./ActionTypes";

export function getRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: COMPANY_CARD.GET_SAGA,
  };
}

export function set({ cards, defaultCard }) {
  return {
    data: cards,
    defaultCard,
    type: COMPANY_CARD.SET,
  };
}

export function removeRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: COMPANY_CARD.DELETE_SAGA,
  };
}

export function remove(data) {
  return {
    data,
    type: COMPANY_CARD.DELETE,
  };
}

export function insertRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: COMPANY_CARD.INSERT_SAGA,
  };
}

export function insert(data) {
  return {
    data,
    type: COMPANY_CARD.INSERT,
  };
}

export function updateRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: COMPANY_CARD.UPDATE_SAGA,
  };
}

export function update(data) {
  return {
    data,
    type: COMPANY_CARD.UPDATE,
  };
}

export function requestFailure() {
  return {
    type: COMPANY_CARD.REQUEST_FAILURE,
  };
}

export function setDefaultRequest(payload, callbackMap) {
  return {
    payload,
    callbackMap,
    type: COMPANY_CARD.SET_DEFAULT_SAGA,
  };
}
