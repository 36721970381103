import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import i18n from '../../../i18n';
import { Images } from '../../../theme'
import { DotsLoader } from "../../../components";
// import { adminGetUsers } from '../../../config/simpleApiCalls';
import { ErrorHelper } from '../../../helpers';

import './styles.css'
import Sytles from './sytles'

const employeesWorkingHoursData = [
    { img: Images.pic1, name: "Lorem Ipsum", services: 'Lorem Ipsum', timing: 'Mon - Fri (13:00 - 19:00)' },
    { img: Images.pic1, name: "Lorem Ipsum", services: 'Lorem Ipsum', timing: 'Mon - Fri (13:00 - 19:00)' },
    { img: Images.pic1, name: "Lorem Ipsum", services: 'Lorem Ipsum', timing: 'Mon - Fri (13:00 - 19:00)' },
]

class WorkingHours extends Component {
    constructor(props) {
        super(props);
        this.state = {
            employeesWorkingHoursData: employeesWorkingHoursData
        }
    }

    // componentDidMount() {
    //     const user = JSON.parse(sessionStorage.getItem('admin'));
    //     if (user && user.access_token) {
    //         this.setState({ user }, this.getAllUsers(user.access_token));
    //     } else {
    //         ErrorHelper.handleErrors("Please login your account!", true)
    //     }
    // }

    handleNavigation = (route) => {
        this.props.history.push(route)
    }

    renderLoader = () => {
        const { isloading } = this.state;
        return <DotsLoader isloading={isloading} />;
    };

    renderSaloonDashboardHeading = () => {
        return (
            <div className="col-12 p-3">
                <h1 className="saloon-dashboard-heading">{i18n.t('container.employee')} <Translation>{t=>t('container.working_hours')}</Translation>/Days:</h1>
                {/* <p className="saloon-dashboard-sub-heading">Lorem ipsum dolor sit amet, consectetur</p> */}
            </div>
        )
    }

    renderTableHead = () => {
        return (
            <thead>
                <tr>
                    <th scope="col"><span className="working-hour-th-text"><Translation>{t=>t('container.advancedSearchDialog_name')}</Translation></span></th>
                    <th scope="col"><span className="working-hour-th-text"><Translation>{t=>t('container.services')}</Translation></span></th>
                    <th scope="col"><span className="working-hour-th-text"><Translation>{t=>t('container.add_employee_working_days')}</Translation>/<Translation>{t=>t('container.hours')}</Translation></span></th>
                </tr>
            </thead>
        )
    }

    renderTableBody = () => {
        const { employeesWorkingHoursData } = this.state;
        return (
            <tbody>{employeesWorkingHoursData.map((val, ind) => {
                return (
                    <tr key={ind} style={{ borderTop: '3px solid #F7F7F7' }}>
                        <td>
                            <div className="d-flex align-items-center">
                                <img className="working-employee-pic" src={Images.pic1} />
                                <span className="working-employee-name">{val.name}</span>
                            </div>
                        </td>
                        <td>
                            <span className="working-employee-services">{val.services}</span>
                        </td>
                        <td>
                            <span className="working-employee-timing">{val.timing}</span>
                        </td>
                        <td>
                            <div style={{ width: 70 }}>
                                <img className="working-hour-action-icon" src={Images.saloon_working_hour_edit} />
                                <img className="working-hour-action-icon" src={Images.saloon_working_hour_delete} />
                            </div>
                        </td>
                    </tr>
                )
            })}</tbody>
        )
    }

    renderWorkingHourTableCard = () => {
        return (
            <div className="py-3">
                <div className="table-responsive" >
                    <table class="table table-borderless saloon-working-hour-table">
                        {this.renderTableHead()}
                        {this.renderTableBody()}
                    </table>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div>
                {this.renderLoader()}
                <div className="content-container">
                    <div className="row" >
                        {this.renderSaloonDashboardHeading()}
                        {this.renderWorkingHourTableCard()}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(WorkingHours);