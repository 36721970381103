import { connect } from "react-redux";
import React, { Component } from "react";
import i18n from "../../i18n";
import { Translation, withTranslation } from "react-i18next";
import { request as user_logout } from "../../redux/actions/LogOut";
import { clearLogout as clear_logout } from "../../redux/actions/LogOut";
import { Images } from "../../theme";
import { Sidebar } from "../../components";
import { SuccessHelper } from "../../helpers";
import { Link } from "react-router-dom";
import frenchIcon from "../../../src/assets/Images/france.png";
import englishIcon from "../../../src/assets/Images/eng.png";
import { Button, MenuList, MenuItem } from "@material-ui/core";

import "./styles.css";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      routeName: "",
      showSidebar: false,
      showDropdownMenu: false,
    };
  }

  componentDidMount() {
    const userData = JSON.parse(sessionStorage.getItem("user"));
    if (userData && userData.access_token) {
      this.setState({ user: userData });
    }
    this.setState({ language: i18n.language });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.logout) {
      if (
        !nextProps.logout.failure &&
        !nextProps.logout.isFetching &&
        !nextProps.logout.errorMessage &&
        nextProps.logout.data &&
        nextProps.logout.data.success
      ) {
        nextProps.clear_logout();
        SuccessHelper.handleSuccess(
          <Translation>{(t) => t("logout_succedded")}</Translation>,
          true
        );
        this.setState({ user: null });
      } else if (
        nextProps.logout.failure &&
        !nextProps.logout.isFetching &&
        nextProps.logout.errorMessage
      ) {
        this.setState({ isloading: false });
      }
    }
  }

  LanguageSelector = () => {
    const changeLanguage = (language) => {
      i18n.changeLanguage(language);
      this.setState({ language });
      sessionStorage.setItem("language", language);
    };

    return (
      <Button
        onClick={() =>
          changeLanguage(this.state.language == "en" ? "fr" : "en")
        }
      >
        {this.state.language == "en" ? "fr" : "en"}
      </Button>
    );
  };

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  handleLogout = () => {
    const { user } = this.state;
    this.props.user_logout({ access_token: user.access_token });
  };

  openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  renderLargeNavbar = () => {
    const { user, showSidebar } = this.state;
    return (
      <div
        id="large-navbar"
        className="d-flex align-items-center justify-content-between"
      >
        <img
          alt="easy1 logo 800x300"
          src={Images.easy1_logo_800x300}
          className="logo cursor-pointer"
          onClick={() => this.handleNavigation("/")}
        />

        <span className="d-flex align-items-center">
          <Button
            onClick={() =>
              this.openInNewTab("https://www.easy1booking-business.com/")
            }
          >
            {i18n.language == "en" ? "fr" : "en"}
          </Button>
          <span className="navbar-link">
            <Translation>{(t) => t("home.for_business")}</Translation>
          </span>
          <span>{this.LanguageSelector()}</span>

          {!user && (
            <button
              type="button"
              className="btn btn-outline-light btn-sm"
              onClick={() => this.handleNavigation("/partner-login")}
            >
              <Translation>{(t) => t("partner_login")}</Translation>
            </button>
          )}
          {!user && (
            <button
              type="button"
              className="btn btn-light btn-sm"
              onClick={() => this.handleNavigation("/login")}
            >
              <Translation>{(t) => t("login")}</Translation>
            </button>
          )}
          {user && user.access_token && (
            <div class="dropdown show" style={{ paddingBottom: "0px" }}>
              <a
                style={{
                  backgroundColor: "#ff3600",
                  borderColor: "#ff3600",
                  marginBottom: "0px",
                }}
                class="btn btn-secondary dropdown-toggle"
                href="#"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {user.firstName
                  ? user.firstName + " " + user.lastName
                  : user.userName}
              </a>

              <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <ul styles={{ marginBottom: "0px", padding: "0px" }}>
                  {this.renderLogout(Images.white_profile, "Profile", () =>
                    this.handleNavigation("/user-profile")
                  )}
                  {this.renderLogout(
                    Images.saloon_drawer_log_out,
                    <Translation>{(t) => t("log_out")}</Translation>,
                    this.handleLogout
                  )}
                </ul>
              </div>
            </div>
          )}
          <span
            className="d-flex align-items-center"
            onClick={() => this.setState({ showSidebar: !showSidebar })}
          >
            <img
              src={Images.saloon_navbar_menu}
              style={{ width: 32, height: 44, cursor: "pointer" }}
            />
            {showSidebar && (
              <div className="dropdown-nav-menu">
                <ul className="navbar-nav ml-auto">
                  <li
                    className="home-navbar-link"
                    onClick={() =>
                      this.handleNavigation("/demonstration-reservation")
                    }
                  >
                    <Translation>{(t) => t("book_a_demo")}</Translation>
                  </li>
                  <li
                    className="home-navbar-link"
                    onClick={() => this.handleNavigation("/careers.html")}
                  >
                    <Translation>{(t) => t("careers")}</Translation>
                  </li>
                  <li
                    className="home-navbar-link"
                    onClick={() =>
                      this.handleNavigation("/our-services", {
                        state: this.state.homeInfo,
                      })
                    }
                  >
                    <Translation>{(t) => t("our_services")}</Translation>
                  </li>
                </ul>
              </div>
            )}
          </span>
        </span>
      </div>
    );
  };

  renderSmallNavbar = () => {
    const { showSidebar } = this.state;
    return (
      <div id="small-navbar">
        <div className="py-2 d-flex align-items-center justify-content-between">
          <img
            alt="easy1 logo 800x300"
            src={Images.easy1_logo_800x300}
            className="logo cursor-pointer"
            onClick={() => this.handleNavigation("/")}
          />
          <span className="d-flex align-items-center">
            <span>{this.LanguageSelector()}</span>
            <Button
              type="button"
              style={{
                backgroundColor: "#ff3600",
                color: "white",
              }}
              onClick={() => {
                console.log("hereee");
                this.setState({ isLoginMenuOpen: true, showSidebar: false });
              }}
            >
              <Translation>{(t) => t("login")}</Translation>
            </Button>
            {this.state.isLoginMenuOpen && (
              <div
                className="dropdown-nav-menu"
                style={{
                  right: 45,
                }}
              >
                <MenuList>
                  <MenuItem onClick={() => this.handleNavigation("/login")}>
                    {i18n.t("user")}
                  </MenuItem>
                  <MenuItem
                    onClick={() => this.handleNavigation("/partner-login")}
                  >
                    {i18n.t("staff")}
                  </MenuItem>
                  <MenuItem
                    onClick={() => this.handleNavigation("/partner-login")}
                  >
                    {i18n.t("owner")}
                  </MenuItem>
                </MenuList>
              </div>
            )}

            <span
              className="pl-2"
              onClick={() =>
                this.setState({ showSidebar: showSidebar ? false : true })
              }
            >
              <img
                src={Images.saloon_navbar_menu}
                style={{ width: 35, height: 35, cursor: "pointer" }}
              />
            </span>
          </span>
        </div>
        <div className="px-2">
          <Sidebar {...this.props} showSidebar={showSidebar} />
        </div>
      </div>
    );
  };

  render() {
    return (
      <div
        className="container-fluid fixed-top shadow px-3 py-2 mb-5"
        style={{
          backgroundColor: this.props.isBackgroundColor
            ? "#fff"
            : "transparent",
          transition: "background-color 300ms linear",
        }}
      >
        {this.renderLargeNavbar()}
        {this.renderSmallNavbar()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ logout: state.logout });

const action = { user_logout, clear_logout };

export default connect(mapStateToProps, action)(Navbar);
