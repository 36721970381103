import { connect } from "react-redux";
import React from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";

import UserRoutes from "./userRoutes";
import SaloonRoutes from "./saloonRoutes";
import EmployeeRoutes from "./employeeRoutes";
import TemplateRoutes from "./templateRoutes";
import CustomerRoutes from './customerRoutes'

import { setDocked } from "../redux/actions/Sidebar";

const customHistory = createBrowserHistory();
customHistory.listen(_ => {
  window.scrollTo(0, 0)  
})

const mql = window.matchMedia(`(min-width: 1200px)`);

// Routes For Navigation
class MyRoutes extends React.Component {
  constructor(props) {
    super(props);
 
    this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
    props.setDocked(mql.matches)
  }
 
  componentWillMount() {
    mql.addListener(this.mediaQueryChanged);
  }
 
  componentWillUnmount() {
    mql.removeListener(this.mediaQueryChanged);
  }

  mediaQueryChanged() {
    this.props.setDocked(mql.matches)
  }

  render() {
    return (
      <Router history={customHistory}>
        <CustomerRoutes history={customHistory}/>
        <SaloonRoutes history={customHistory}/>
        <EmployeeRoutes history={customHistory}/>
        <TemplateRoutes history={customHistory}/>
        <UserRoutes history={customHistory}/>
      </Router>
    )
  }
}


const mapStateToProps = (state) => ({});

const action = { setDocked };

export default connect(mapStateToProps, action)(MyRoutes);