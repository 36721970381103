import {take, put, call, fork} from 'redux-saga/effects';

import ApiSauce from '../../services/apiSauce';
import {get_availables_spots} from '../../config/WebServices';
import * as types from '../actions/ActionTypes';

import {success, failure} from '../actions/GetAvailableTimeslots';

import {ErrorHelper} from '../../helpers';

async function callRequest(data) {
  return ApiSauce.get(get_availables_spots, {
    ...data
  });
}

function* watchRequest() {
  while (true) {
    const e = yield take(types.GETAVAILABLETIMESLOTS.REQUEST);
    const { payload: { data, resolve }, index } = e

    // const { targetView } = payload;
    // delete payload.targetView;
    try {
      const response = yield call(callRequest, data);
      if(resolve) {
        resolve(response)
      }
      yield put(success(response, index));
    } catch (err) {
      yield put(failure(err));
      ErrorHelper.handleErrors(err, true);
    }
  }
}

export default function* root() {
  yield fork(watchRequest);
}
