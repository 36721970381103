import React from "react";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

import "../Styles/selectInput.sass";
import i18n from "../../../i18n";
// TODO:
// refactor this to dynamically render inputTypeOptions
const inputTypeOptions = [
  { value: "text", label: "Text" },
  {
    value: "multipleChoice",
    label: "Multiple Choice(different selection allowed)",
  },
  { value: "radio", label: "Multiple Choice(only one true)" },
  { value: "date", label: "Date" },
  { value: "checkbox", label: "Checkbox" },
  { value: "email", label: "Email" },
  { value: "time", label: "Time" },
  { value: "select", label: "Select" },
  { value: "section", label: "Section" },
];

const SelectInput = ({
  handleChange,
  inputTypeError,
  inputType,
  selectOptions,
  selectLabel,
}) => {
  const renderError = (fieldError) => {
    return fieldError ? <div className="fieldError">{fieldError}</div> : "";
  };
  const optionsToRender = selectOptions ? selectOptions : inputTypeOptions;

  return (
    // asks user to select the type of input field
    // user can select from above options
    <React.Fragment>
      <h6>{selectLabel}</h6>
      <FormControl variant="outlined">
        <Select value={inputType} onChange={handleChange}>
          {optionsToRender.map((type, i) => {
            return (
              <MenuItem key={`${type}-${i}`} value={type.value}>
                {type.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {renderError(inputTypeError)}
    </React.Fragment>
  );
};

export default SelectInput;
